import { Grid, TextField, BodySmall, TransitionsColorPicker, Switch, Box } from '@parspec/pixel';

import TextFontType from './TextFontType';

import { IMultilineTextWithFontAndColor, NOTES_PLACEHOLDER } from '../../constants';
import Title from '../../shared/Title';
import ApplyToAllBomsButton from '../../shared/ApplyToAllBomsButton';

function MultilineTextWithFontAndColor({
    maxRows,
    onBlur,
    onTextFieldChange,
    numberOfRows,
    selectedFontValue,
    onSelect,
    textFieldValue,
    color,
    onColorChange,
    switchValue,
    onToggle,
    title,
    maxLength = 256,
    onFocus,
    onClick = () => {},
    sectionName,
    subSectionName
}: IMultilineTextWithFontAndColor) {
    return (
        <Grid container columnSpacing={3}>
            <Grid item xs={12}>
                <Grid container columnSpacing={3} mb={3}>
                    <Grid item>
                        <Title title={title} />
                    </Grid>

                    <Grid item>
                        <Box display="flex" alignItems="center">
                            <Switch checked={switchValue} onChange={onToggle} />
                            <ApplyToAllBomsButton sectionName={sectionName} subSectionName={subSectionName} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={1} xs={9}>
                <TextField
                    inputProps={{
                        maxLength: maxLength
                    }}
                    value={textFieldValue}
                    label=""
                    placeholder={NOTES_PLACEHOLDER}
                    maxRows={maxRows}
                    multiline
                    onBlur={() => onBlur()}
                    onFocus={onFocus}
                    onChange={onTextFieldChange}
                    rows={numberOfRows}
                />
            </Grid>

            <Grid item xs={3}>
                <Grid container direction={'column'} rowSpacing={2}>
                    <Grid item>
                        <BodySmall color={'secondary.light'} fontWeight={500}>
                            Text
                        </BodySmall>
                    </Grid>
                    <Grid item>
                        <TextFontType selectFieldValue={selectedFontValue} onSelect={onSelect} />
                    </Grid>

                    <Grid item>
                        <BodySmall color={'secondary.light'} fontWeight={500}>
                            Font Color
                        </BodySmall>
                    </Grid>

                    <Grid item>
                        <TransitionsColorPicker onClick={(open) => onClick(open)} onClickAway={() => onBlur(true)} color={color} onChange={onColorChange} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MultilineTextWithFontAndColor;
