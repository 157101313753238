import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { useGetBomDataQuery, useGetBomTabStatsQuery } from '../queries';
import { getStats } from './helper';
import Footer from '../Footer';
import BomTabHeader from './BomTabHeader';
import BomTable from './BomTable';
import TabContent from '../shared/TabContent';
import { useCustomisationColumn, useGetBomData, useProjectBomsMappedData } from '../shared/hooks';
import { getTableData, prepareKitsAndComponentsData } from '../shared/utils';
import LinkedBomsPopup from '../shared/LinkedBomsPopup';

const BillOfMaterialsTable = () => {
    const { bomId = '', projectId = 0 } = useParams();
    const selectedRowsRef = useRef<Set<number>>(new Set());

    const { handleTablesReordering, sectionRefList, handleTablesWidthChanged } = useCustomisationColumn();

    const {
        data: bomData,
        isLoading,
        isFetching
    } = useGetBomDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });

    const { mainProductOptionsInfo } = useGetBomData(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: bomTabStats } = useGetBomTabStatsQuery(bomId!, {
        enabled: Boolean(bomId),
        staleTime: 0
    });
    const { projectBomsAndPrimaryData } = useProjectBomsMappedData(Number(projectId));
    const { projectBomsMappedData, primaryBomId } = projectBomsAndPrimaryData;
    const statsData = getStats(bomTabStats);

    const tableData = useMemo(() => {
        if (bomData?.data) {
            const result = prepareKitsAndComponentsData(bomData?.data as any);
            return getTableData(result as any);
        }
        return [];
    }, [isLoading, bomData?.data]);

    const isBomLinked = projectBomsMappedData.get(Number(bomId));

    return (
        <>
            {isBomLinked ? (
                <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />
            ) : (
                <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                    <Box flex={1} minHeight={0} pt={4} display="flex" flexDirection="column">
                        <TabContent tableData={tableData} tabCta={<BomTabHeader />} ref={sectionRefList}>
                            <BomTable
                                dataLoading={isLoading}
                                tableData={undefined as never}
                                dataFetching={isFetching}
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />
                            <BomTable
                                dataLoading={isLoading}
                                tableData={undefined as never}
                                dataFetching={isFetching}
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                isOptionTable
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />
                        </TabContent>
                    </Box>
                    <Footer statsData={statsData} />
                </Box>
            )}
        </>
    );
};

export default BillOfMaterialsTable;
