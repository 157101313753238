import { Skeleton, Box } from '@parspec/pixel';

import { ORIENTATION, ORIENTATION_TYPE } from './types';
import { useCustomerTabContext } from './CustomerTabContext';

interface ICustomerTabSkeleton {
    orientation: ORIENTATION;
}

function CustomerTabSkeleton({ orientation }: ICustomerTabSkeleton) {
    const { showCheckbox, showMenu } = useCustomerTabContext();
    return (
        <Box display="flex" justifyContent="space-evenly" borderBottom="1px solid" borderColor={'neutral.main'} flexDirection={orientation === ORIENTATION_TYPE.HORIZONTAL ? 'row' : 'column'}>
            {Array.from({ length: 5 }, (_, index) => (
                <Box width="260px" display="flex" justifyContent="space-between" key={index}>
                    <Box display="flex" gap="12px" p="12px">
                        {showCheckbox && (
                            <Box pt="1px">
                                <Skeleton variant="rectangular" height="34px" width="34px" />
                            </Box>
                        )}

                        <Box display="flex" flexDirection="column" gap="2px" alignItems="flex-start">
                            <Skeleton variant="rectangular" height="15px" width="150px" />
                            <Skeleton variant="rectangular" height="18px" width="150px" />
                        </Box>
                    </Box>

                    {showMenu && (
                        <Box pt="13px" ml="8px">
                            <Skeleton variant="rectangular" height="34px" width="34px" />
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
}

export default CustomerTabSkeleton;
