import { useState } from 'react';

export function useSnackbar() {
    const [snackbarInfo, setSnackbarInfoArray] = useState<{ open: boolean; message: string; autoHideDuration?: number | null }>({ open: false, message: '' });

    function setSnackbarClose() {
        setSnackbarInfoArray({ ...snackbarInfo, open: false });
    }

    function setSnackbarOpen(message: string, autoHideDuration?: number | null) {
        setSnackbarInfoArray({ open: true, message, autoHideDuration });
    }

    return { snackbarInfo, setSnackbarOpen, setSnackbarClose };
}
