import { Box, BodySmall } from '@parspec/pixel';

interface ListItemProps {
    listTitle?: string;
    onClick?: () => void;
    isSelected?: boolean;
    isLogout?: boolean;
}

export const ListItem = (props: ListItemProps) => {
    const { listTitle, onClick, isSelected, isLogout } = props;
    return (
        <Box onClick={onClick}>
            <BodySmall
                limit={false}
                px={3}
                py={2}
                fontWeight={500}
                sx={{
                    lineHeight: '20px',
                    color: isLogout ? 'primary.main' : null,
                    backgroundColor: isSelected ? 'primary.light' : null,
                    borderRadius: isSelected ? 1 : null,
                    '&:hover': {
                        bgcolor: isSelected ? null : 'neutral.main',
                        borderRadius: 1,
                        cursor: 'pointer'
                    }
                }}
            >
                {listTitle}
            </BodySmall>
        </Box>
    );
};
