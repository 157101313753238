import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RowNode } from 'ag-grid-community';

import { ModalHeader, Modal, ModalFooter, Box, BodySmall, Autocomplete } from '@parspec/pixel';

import { useGetBomDataQuery } from '../queries';
import { useUpdateBomDataMutation } from '../queries';
import { usePricingBodPatchMutation } from '../pricing&LeadTime/queries';
import { SUCCESSFULY_MOVED_PRODUCT } from '../../shared/constants';
import { BodResponse } from '../queries/apiTypes';

interface IMoveProductDialog {
    open: boolean;
    handleClose: (afterSubmission?: boolean) => void;
    currentBodId: number;
    getTableMetaData: () => any;
    setSnackbarOpen: (message: string) => void;
    sectionId: number;

    isOptionTable: boolean;
}
const MODAL_HEADER = 'Existing Kits';

const MoveProductDialog = ({ open, handleClose, currentBodId, getTableMetaData, sectionId, isOptionTable, setSnackbarOpen }: IMoveProductDialog) => {
    const { bomId = '' } = useParams();
    const [value, setValue] = useState({ id: 0, name: '' });

    const { data: bomData } = useGetBomDataQuery(bomId, {
        enabled: Boolean(bomId)
    });

    const { mutateAsync: updateBomData, isLoading } = useUpdateBomDataMutation();
    const { mutateAsync: updatePricingData } = usePricingBodPatchMutation();

    const autoCompleteOptions = useMemo(() => {
        if (bomData?.data) {
            return bomData.data
                .filter((item: BodResponse) => {
                    const compareSectionId = isOptionTable ? item.substitute_section : item.section;
                    return (
                        item.id !== currentBodId &&
                        !item.kit &&
                        item.is_kit &&
                        compareSectionId &&
                        compareSectionId === sectionId &&
                        (item?.category?.length || item?.model_number?.length || item?.manufacturer?.length)
                    );
                })
                .map((item: BodResponse) => {
                    let optionNameStr = '';
                    const optionId = item.id;

                    if (item?.category) {
                        optionNameStr += `${item.category.toUpperCase()}`;
                    }

                    if (item?.model_number) {
                        optionNameStr += optionNameStr.length ? ` | ${item.model_number.toUpperCase()}` : `${item.model_number.toUpperCase()}`;
                    }

                    if (item?.manufacturer) {
                        optionNameStr += optionNameStr.length ? ` | ${item.manufacturer.toUpperCase()}` : `${item.manufacturer.toUpperCase()}`;
                    }

                    return {
                        id: optionId,
                        name: optionNameStr
                    };
                });
        }

        return [{ id: 0, name: 'No options' }];
    }, [bomData?.data]);

    function handleOnChange(e: React.SyntheticEvent<Element, Event>) {
        const target = e.target as HTMLInputElement;
        if (typeof target.value !== 'string') setValue(target.value);
    }

    async function handleSubmit() {
        const {
            props: { rowData },
            api
        } = getTableMetaData();

        const destinationBod = rowData.find((item: { id: number }) => item.id === value.id);
        const currentBod = rowData.find((item: { id: number }) => item.id === currentBodId);
        if (destinationBod?.lotId && currentBod?.id) {
            await updatePricingData({
                bomId: Number(bomId),
                pricingBod: { cost_cents: null, discounted_cost_cents: null, discount_percentage: null, margin_percentage: null, sell_price_cents: null },
                bodId: currentBod.id
            });
        }
        await updateBomData({ bomId: String(bomId), input: { kit: destinationBod.id, id: currentBod.id } });
        handleClose();
        api.forEachNode((node: RowNode) => {
            const { id } = node;
            if (Number(id) === destinationBod.id) {
                node.setExpanded(true);
            }
        });
        setSnackbarOpen(SUCCESSFULY_MOVED_PRODUCT);
    }

    return (
        <>
            <Modal
                open={open}
                header={<ModalHeader title={'Move Product'} onClose={handleClose} />}
                footer={<ModalFooter continueButtonLabel={'Move'} onAccept={handleSubmit} onReject={handleClose} isLoading={isLoading} />}
            >
                <Box width="25vw" my={4}>
                    <BodySmall limit={false} color="neutral.dark">
                        {MODAL_HEADER}
                    </BodySmall>

                    <Box mt={5}>
                        <Autocomplete value={value} options={autoCompleteOptions} onChange={handleOnChange} id={`move-product-dialog`} label={'List of Kits'} optionlabelkeyname="name" />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default MoveProductDialog;
