import { useEffect } from 'react';

import { BodyMedium, BodyXS, Box, Grid, H6, Select, SelectChangeEvent, Skeleton, TextField } from '@parspec/pixel';

import { validateEmail } from '../../../UserManagement/utils/utils';
import { USA_STATES, CANADA_STATES } from 'src/features/shared/constants';
import { trimEmptySpacesHandler, removeAllSpacesHandler, countryOptions, USA, CANADA, modifiedStateList } from '../../utils/utils';
import { BranchDetailsData, BranchDetailsErrorState } from './BranchDetails.types';
import { sanitizePhoneNumber } from '../../utils/utils';
import { BranchDetailsReponse } from '../../queries/apiTypes';
import { CompanyDetailsForAddLocationInterface } from '../AddEditLocation.types';

interface BranchDetailsProps {
    companyDetails?: BranchDetailsReponse | CompanyDetailsForAddLocationInterface;
    companyDetailsLoading: boolean;
    branchDetailsTemplate: BranchDetailsData;
    setBranchDetailsTemplate: React.Dispatch<React.SetStateAction<BranchDetailsData>>;
    branchDetailsErrorState: BranchDetailsErrorState;
    setBranchDetailsErrorState: React.Dispatch<React.SetStateAction<BranchDetailsErrorState>>;
    onSubmitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
    formRef: any;
    stateListOptions: { label: string; value: string }[];
    setStateListOptions: React.Dispatch<React.SetStateAction<{ label: string; value: string }[]>>;
}

export const BranchDetails = (props: BranchDetailsProps) => {
    const {
        companyDetails,
        companyDetailsLoading,
        branchDetailsTemplate,
        setBranchDetailsTemplate,
        branchDetailsErrorState,
        setBranchDetailsErrorState,
        onSubmitHandler,
        formRef,
        stateListOptions,
        setStateListOptions
    } = props;

    useEffect(() => {
        if (!companyDetails) {
            return;
        }

        setBranchDetailsTemplate(() => ({
            ...branchDetailsTemplate,
            name: companyDetails.name,
            email: companyDetails.email,
            phone1: companyDetails.phone1,
            website: companyDetails.website ? companyDetails.website : '',
            address1: companyDetails.address1 ? companyDetails.address1 : '',
            address2: companyDetails.address2 ? companyDetails.address2 : '',
            city: companyDetails.city ? companyDetails.city : '',
            country: companyDetails.country ? companyDetails.country : '',
            state: companyDetails.state ? companyDetails.state : '',
            zipcode: companyDetails.zipcode ? companyDetails.zipcode : ''
        }));

        if (companyDetails?.country && companyDetails.country.toUpperCase() === USA) {
            setStateListOptions(modifiedStateList(USA_STATES));
        } else if (companyDetails.country === CANADA) {
            setStateListOptions(modifiedStateList(CANADA_STATES));
        }
    }, [companyDetails]);

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const requiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value as string;
        if (!Object.prototype.hasOwnProperty.call(branchDetailsErrorState, fieldName)) {
            return;
        }

        if (fieldName === 'country') {
            setBranchDetailsTemplate(() => ({
                ...branchDetailsTemplate,
                country: fieldValue,
                state: ''
            }));
            setBranchDetailsErrorState(() => ({
                ...branchDetailsErrorState,
                country: { ...branchDetailsErrorState.country, isError: false, title: '' },
                state: { ...branchDetailsErrorState.state, isError: true, title: 'Required' }
            }));

            let stateOptions: { label: string; value: string }[] = [];

            if (fieldValue === USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (fieldValue === CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            return setStateListOptions(stateOptions);
        }

        if (branchDetailsErrorState[fieldName].isError) {
            setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                [fieldName]: { ...branchDetailsErrorState[fieldName], isError: false, title: '' }
            });
        }
        if (fieldValue === '') {
            setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                [fieldName]: { ...branchDetailsErrorState[fieldName], isError: true, title: 'Required' }
            });
        }

        if (fieldName === 'zipcode') {
            if (fieldValue.trim().length > 10) {
                setBranchDetailsErrorState({
                    ...branchDetailsErrorState,
                    zipcode: { ...branchDetailsErrorState.zipcode, isError: true, title: 'Max length allowed is 10' }
                });
            }
            return setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, zipcode: fieldValue }));
        }

        setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: fieldValue }));
    };

    const unRequiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (branchDetailsErrorState[fieldName].isError) {
            setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                [fieldName]: { ...branchDetailsErrorState[fieldName], isError: false, title: '' }
            });
        }

        if (fieldValue.trim().length === 0) {
            return setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: '' }));
        }

        if (fieldName === 'phone1') {
            const newFieldValue = sanitizePhoneNumber(event.target.value);
            return setBranchDetailsTemplate(() => ({
                ...branchDetailsTemplate,
                phone1: newFieldValue
            }));
        }

        setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: fieldValue }));
    };

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(branchDetailsTemplate, fieldName)) {
            return;
        }

        if (branchDetailsTemplate[fieldName].length > 0 && eventType === 'blur') {
            if (fieldName === 'email') {
                if (!validateEmail(branchDetailsTemplate.email)) {
                    return setBranchDetailsErrorState({
                        ...branchDetailsErrorState,
                        email: { ...branchDetailsErrorState.email, isError: true, title: 'Please provide a valid email address' }
                    });
                }
            }

            if (fieldName === 'address2') {
                const trimmedValue = trimEmptySpacesHandler(branchDetailsTemplate[fieldName]);
                return setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: trimmedValue }));
            }

            if (fieldName === 'website') {
                const trimmedValue = removeAllSpacesHandler(branchDetailsTemplate[fieldName]);
                return setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: trimmedValue }));
            }

            if (branchDetailsTemplate[fieldName].trim().length === 0) {
                return setBranchDetailsErrorState({ ...branchDetailsErrorState, [fieldName]: { ...branchDetailsErrorState[fieldName], isError: true, title: 'Required' } });
            }

            const trimmedValue = trimEmptySpacesHandler(branchDetailsTemplate[fieldName]);
            setBranchDetailsTemplate(() => ({ ...branchDetailsTemplate, [fieldName]: trimmedValue }));

            if (fieldName === 'zipcode') {
                if (trimmedValue.length <= 10) {
                    return setBranchDetailsErrorState({ ...branchDetailsErrorState, zipcode: { ...branchDetailsErrorState.zipcode, isError: false, title: '' } });
                }
            }
        }
    };

    return (
        <Box>
            <Box>
                <H6>Branch Details</H6>
            </Box>

            <form onSubmit={onSubmitHandler} ref={formRef}>
                <Box pt={6}>
                    <Box display="flex " position="relative" height={'100%'} justifyContent={'space-between'}>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="text.secondary" fontWeight={500} mb={8}>
                                Branch Information
                            </BodyMedium>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        required
                                        label="Branch Name"
                                        name="name"
                                        variant="outlined"
                                        value={branchDetailsTemplate.name}
                                        autoComplete="off"
                                        onBlur={onBlurHandler}
                                        onChange={requiredFieldChangeHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.name.title}
                                        error={branchDetailsErrorState.name.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        label="Branch Primary Email"
                                        name="email"
                                        variant="outlined"
                                        value={branchDetailsTemplate.email}
                                        autoComplete="off"
                                        onBlur={onBlurHandler}
                                        onChange={unRequiredFieldChangeHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.email.title}
                                        error={branchDetailsErrorState.email.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        label="Branch Primary Phone"
                                        name="phone1"
                                        variant="outlined"
                                        type={'tel'}
                                        value={branchDetailsTemplate.phone1}
                                        autoComplete="off"
                                        onChange={unRequiredFieldChangeHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.phone1.title}
                                        error={branchDetailsErrorState.phone1.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        label="Branch Website"
                                        name="website"
                                        variant="outlined"
                                        value={branchDetailsTemplate.website}
                                        autoComplete="off"
                                        onChange={unRequiredFieldChangeHandler}
                                        onBlur={onBlurHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.website.title}
                                        error={branchDetailsErrorState.website.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                        </Box>
                        <Box width="50%">
                            <BodyMedium color="text.secondary" fontWeight={500} mb={8}>
                                Branch Address
                            </BodyMedium>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        required
                                        label="Address Line 1"
                                        name="address1"
                                        variant="outlined"
                                        value={branchDetailsTemplate.address1}
                                        autoComplete="off"
                                        onChange={requiredFieldChangeHandler}
                                        onBlur={onBlurHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.address1.title}
                                        error={branchDetailsErrorState.address1.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        label="Address Line 2"
                                        name="address2"
                                        variant="outlined"
                                        value={branchDetailsTemplate.address2}
                                        autoComplete="off"
                                        onChange={unRequiredFieldChangeHandler}
                                        onBlur={onBlurHandler}
                                        onKeyDown={keydownHandler}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                {!companyDetailsLoading ? (
                                    <TextField
                                        required
                                        label="City"
                                        name="city"
                                        variant="outlined"
                                        value={branchDetailsTemplate.city}
                                        autoComplete="off"
                                        onChange={requiredFieldChangeHandler}
                                        onBlur={onBlurHandler}
                                        onKeyDown={keydownHandler}
                                        helperText={branchDetailsErrorState.city.title}
                                        error={branchDetailsErrorState.city.isError}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                            <Box height={'72px'}>
                                <Grid item md={6}>
                                    {!companyDetailsLoading && (
                                        <>
                                            <Select
                                                required
                                                label="Country*"
                                                name="country"
                                                id="country"
                                                labelId="country"
                                                optionLabelKeyname="label"
                                                optionValueKeyname="value"
                                                options={countryOptions}
                                                value={
                                                    branchDetailsTemplate.country === 'usa' || branchDetailsTemplate.country === 'Usa'
                                                        ? branchDetailsTemplate.country.toUpperCase()
                                                        : branchDetailsTemplate.country
                                                }
                                                onChange={requiredFieldChangeHandler}
                                                error={branchDetailsErrorState.country.isError}
                                            />
                                            {branchDetailsErrorState.country.isError && (
                                                <Box my={2}>
                                                    <BodyXS color={'error.main'}>{branchDetailsErrorState.country.title}</BodyXS>
                                                </Box>
                                            )}
                                        </>
                                    )}{' '}
                                    {companyDetailsLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                </Grid>
                            </Box>
                            <Box height={'72px'} display="flex " position="relative">
                                <Box width={'50%'} mr={6}>
                                    <Grid item md={6}>
                                        {!companyDetailsLoading && stateListOptions.length > 0 && (
                                            <>
                                                <Select
                                                    required
                                                    id="state"
                                                    labelId="state"
                                                    label="State / Province*"
                                                    name="state"
                                                    value={branchDetailsTemplate.state}
                                                    onChange={requiredFieldChangeHandler}
                                                    options={stateListOptions}
                                                    error={branchDetailsErrorState.state.isError}
                                                    optionLabelKeyname="label"
                                                    optionValueKeyname="value"
                                                />

                                                {branchDetailsErrorState.state.isError && (
                                                    <Box my={2}>
                                                        <BodyXS color={'error.main'}>{branchDetailsErrorState.state.title}</BodyXS>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        {companyDetailsLoading && stateListOptions.length > 0 && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                    </Grid>
                                </Box>
                                <Box width={'50%'}>
                                    {!companyDetailsLoading ? (
                                        <TextField
                                            required
                                            label="Zip Code"
                                            name="zipcode"
                                            variant="outlined"
                                            value={branchDetailsTemplate.zipcode}
                                            autoComplete="off"
                                            onChange={requiredFieldChangeHandler}
                                            onBlur={onBlurHandler}
                                            onKeyDown={keydownHandler}
                                            helperText={branchDetailsErrorState.zipcode.title}
                                            error={branchDetailsErrorState.zipcode.isError}
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};
