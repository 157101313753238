import { useState } from 'react';

import { Box, Modal, ModalFooter, ModalHeader, CustomDateTimePicker, Dayjs, BodySmall } from '@parspec/pixel';

import { FIELD_NAMES, getIfWeekendDates } from '../../shared/CreateAndEditProjectModal/utils';

interface IUpdateQuoteDueDateModalProps {
    showPopup: boolean;
    onClose: () => void;
    onAcceptDate: (value: Dayjs | null) => void;
    initialDateValue: Dayjs | null;
}

export const UpdateQuoteDueDateModal: React.FC<IUpdateQuoteDueDateModalProps> = ({ showPopup, onClose, onAcceptDate, initialDateValue }) => {
    const [date, setDate] = useState<Dayjs | null>(initialDateValue);

    const handleOnDateChange = (value: Dayjs | null) => {
        setDate(value);
    };

    const header = <ModalHeader title="Update Quote Due Date" onClose={onClose} />;
    const footer = (
        <ModalFooter
            onAccept={() => {
                if (date?.isValid()) {
                    onAcceptDate(date);
                } else {
                    return;
                }
            }}
            onReject={onClose}
        />
    );

    return (
        <Modal open={showPopup} header={header} footer={footer}>
            <Box width={565} display="flex" flexDirection="column" alignItems={'flex-start'} py={4}>
                <Box mt="-20px">
                    <BodySmall color={'neutral.dark'}>Take a moment to adjust the quote due date to reflect the rebid date.</BodySmall>
                </Box>
                <Box width={206} mt="34px">
                    <CustomDateTimePicker
                        value={date}
                        label={FIELD_NAMES.quote_due_date.label}
                        onAccept={(value: Dayjs | null) => handleOnDateChange(value)}
                        onChange={(value: Dayjs | null) => handleOnDateChange(value)}
                        shouldDisableDate={getIfWeekendDates}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
