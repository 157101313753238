import { useNavigate } from 'react-router-dom';

import { Box, Button, H5, BodySmall, Skeleton } from '@parspec/pixel';

import { useCheckAccessForProductFinderOrQuoting } from '../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useFeatureFlag } from '../../../shared/UserActivityTracking/useFeatureFlag';

interface AddEditLocationHeaderProps {
    onSubmitHandler: (event: React.MouseEvent<HTMLElement>) => void;
    saveBtnLoading: boolean;
    currentHeaderTab: string;
}

const HEADER_NAV_TABS = [
    { label: 'Branch Details', value: 'branchDetails' },
    { label: 'Branch Branding', value: 'branchBranding' },
    { label: 'Quote Customization', value: 'quoteCustomization' },
    { label: 'Submittal Customization', value: 'submittalCustomization' },
    { label: 'O&M Customization', value: 'omCustomization' }
];

export const AddEditLocationHeader = (props: AddEditLocationHeaderProps) => {
    const { onSubmitHandler, saveBtnLoading, currentHeaderTab } = props;
    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess, isLoading: isUserLevelQuotingAccessCheckLoading } = useCheckAccessForProductFinderOrQuoting();

    const navigate = useNavigate();

    const resetDataHandler = () => {
        navigate(`/v2/settings/company-locations`);
    };

    const handleZoneClick = (zone: string) => {
        const section = document.getElementById(zone);

        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Box display="flex" p={6}>
                <Box>
                    <H5>Branch Location</H5>
                </Box>
                <Box ml="auto" display="flex">
                    <Box display="flex">
                        <Box mr={2}>
                            <Button variant="outlined" color="secondary" onClick={resetDataHandler}>
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="contained" onClick={onSubmitHandler} isLoading={saveBtnLoading}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {!isUserLevelQuotingAccessCheckLoading ? (
                <Box px={6} display="flex" gap={'24px'} borderBottom={'2px solid'} borderTop={'2px solid'} borderColor={'neutral.main'}>
                    {HEADER_NAV_TABS.map((tabData: { label: string; value: string }) => {
                        if (tabData.value === 'quoteCustomization') {
                            return (
                                enable_quoting &&
                                quotingAccess && (
                                    <Box
                                        key={tabData.value}
                                        py={6}
                                        sx={{ cursor: 'pointer' }}
                                        borderBottom={tabData.value === currentHeaderTab ? '4px solid' : '0px'}
                                        borderColor={tabData.value === currentHeaderTab ? 'primary.main' : 'neutral.main'}
                                        onClick={() => {
                                            handleZoneClick(tabData.value);
                                        }}
                                    >
                                        <BodySmall limit={false} color={tabData.value === currentHeaderTab ? 'primary.main' : 'text.secondary'}>
                                            {tabData.label}
                                        </BodySmall>
                                    </Box>
                                )
                            );
                        } else {
                            return (
                                <Box
                                    key={tabData.value}
                                    py={6}
                                    sx={{ cursor: 'pointer' }}
                                    borderBottom={tabData.value === currentHeaderTab ? '4px solid' : '0px'}
                                    borderColor={tabData.value === currentHeaderTab ? 'primary.main' : 'neutral.main'}
                                    onClick={() => {
                                        handleZoneClick(tabData.value);
                                    }}
                                >
                                    <BodySmall limit={false} color={tabData.value === currentHeaderTab ? 'primary.main' : 'text.secondary'}>
                                        {tabData.label}
                                    </BodySmall>
                                </Box>
                            );
                        }
                    })}
                </Box>
            ) : (
                <Box px={6} display="flex" gap={'24px'} borderBottom={'2px solid'} borderTop={'2px solid'} borderColor={'neutral.main'}>
                    {Array.from({ length: 4 }, (_, index) => (
                        <Box key={index} mx={4} py={6} px={4}>
                            <Skeleton variant="rectangular" height={'24px'} width={'95px'} />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};
