import { useEffect, useState, useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { CellValueChangedEvent, ColDef, ICellRendererParams, ProcessCellForExportParams, RowDragEvent, ValueGetterParams } from 'ag-grid-community';
import 'ag-grid-enterprise';

import { BodySmall, Box, Menu, Modal, ModalFooter, ModalHeader, Select, SelectChangeEvent } from '@parspec/pixel';

import { useServicePutMutation, useServiceQuery } from './queries';
import {
    TextCellParams,
    getAmountForUi,
    getCellAmountFormatter,
    getRowId,
    pricingCellParams,
    getTableHeight,
    getParsedContentFromClipboard,
    getAdjacentColumnNames,
    handleOnRangeSelectionChanged
} from '../../shared/utils';
import { convertToCents, convertToDollar } from '../../../shared/utils/utils';
import { ServiceCalculationMethod } from './queries/apiTypes';
import { SERVICES_CALC_OPTIONS } from './constants';
import { getFooterDisabled, getPercentageAmount, getTemplateCellStyle } from '../shared/utils';
import { MenuHeaderTemplate, getMagnitudeFormatToDisplay } from './utils';
import { CALC_METHODS, priceRelatedCols, PRICING_TABLE_ENUM, serviceRelatedCols } from '../shared/constants';
import { useNinetyViewportHeight } from '../../shared/hooks';
import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import { NoRowsOverlayComponent } from '../../shared/commonTemplates';
import { useUserEventCount } from '../../../shared/UserActivityTracking/TrackActivityCount';

interface ServicesModalInterface {
    open: boolean;
    totalExtendedPrice: number;
    onClose: () => void;
}

interface ServiceChargeRow {
    name: string | null;
    calculationMethod: ServiceCalculationMethod;
    flatFee: number | null;
    percentage: number | null;
    amount: number | null;
    id: number;
    totalExtendedPrice?: number;
    magnitude: number | null;
    newRow?: boolean;
}

export default function ServicesModal({ open, onClose, totalExtendedPrice }: ServicesModalInterface) {
    const { bomId } = useParams();
    const { pushUserEventCount } = useUserEventCount();
    const { data: servicesData, isFetching: isServicesLoading } = useServiceQuery(Number(bomId), { enabled: Boolean(bomId) });
    const { mutateAsync: saveServices, isLoading: isServicesSaving } = useServicePutMutation();
    const orderTotalForUi = getAmountForUi(totalExtendedPrice);
    const [selectedServices, setSelectedItems] = useState<Array<Record<string, any>>>([]);
    const [startServicesSave, setStartServicesSave] = useState(false);
    const [services, updateServices] = useState<ServiceChargeRow[]>([]);
    const [servicesTableData, updateServicesTableData] = useState<ServiceChargeRow[] | undefined>();
    const [deletedRows, setDeletedRows] = useState(0);
    const selectedRowsSet = useRef<Set<number>>(new Set());
    const tableRef = useRef<any>(null);

    {
        /** maxHeight is kept 90vh - 333px so that modal occupies 90vh & 359px is the height in the modal occupied by other elements*/
    }

    const rowHeight = 40;
    const modalMaxHeight = useNinetyViewportHeight();
    const tableMaxHeight = modalMaxHeight - 359;
    const tableHeight = getTableHeight({ rowHeight, rows: services.length, tableMaxHeight, hasScroll: false });
    const modules = useMemo(() => [ClipboardModule, GridChartsModule], []);

    // this is used to fix scenario where table is in edit state and modal save is clicked
    useEffect(() => {
        if (startServicesSave) {
            handleSave();
        }
    }, [startServicesSave]);

    useLayoutEffect(() => {
        if (servicesData?.data) {
            const serviceChargesRowsInfo = servicesData.data.map((serviceData, index) => {
                const flatFee = serviceData.flat_fee_cents || serviceData.flat_fee_cents === 0 ? convertToDollar(serviceData.flat_fee_cents) : null;
                const calculationMethod = serviceData.calculation_method;
                const percentage = serviceData.percentage_of_order;
                const amount = calculationMethod === CALC_METHODS.PERCENTAGE_OF_ORDER ? (percentage !== null ? getPercentageAmount(totalExtendedPrice, percentage) : null) : flatFee;
                const magnitude = calculationMethod === CALC_METHODS.PERCENTAGE_OF_ORDER ? percentage : flatFee;
                return {
                    name: serviceData.name,
                    calculationMethod,
                    flatFee,
                    percentage,
                    amount,
                    id: Date.now() + index,
                    totalExtendedPrice,
                    magnitude
                };
            });
            updateServices(serviceChargesRowsInfo);
            updateServicesTableData(serviceChargesRowsInfo);
        }
    }, [servicesData?.data, isServicesLoading, totalExtendedPrice]);

    function addNewRow(newServiceChargeRow: ServiceChargeRow | Array<ServiceChargeRow>, belowId?: number) {
        const newServiceCharges = [...services];
        const belowIndexToAppendData = belowId || belowId === 0 ? newServiceCharges.findIndex((serviceCharge) => serviceCharge.id === belowId) + 1 : newServiceCharges.length;
        if (Array.isArray(newServiceChargeRow)) {
            newServiceCharges.splice(belowIndexToAppendData, 0, ...newServiceChargeRow);
        } else {
            newServiceCharges.splice(belowIndexToAppendData, 0, newServiceChargeRow);
        }

        updateServices(newServiceCharges);
        updateServicesTableData(newServiceCharges);
    }

    function handleAddServiceCharge() {
        const newServiceChargeRow = {
            name: null,
            calculationMethod: CALC_METHODS.FLAT_FEE as CALC_METHODS.FLAT_FEE,
            flatFee: null,
            percentage: null,
            amount: null,
            id: Date.now(),
            magnitude: null,
            totalExtendedPrice,
            newRow: true
        };
        addNewRow(newServiceChargeRow);
    }

    function handleAddSingleServiceBelow(belowId: number) {
        updateServices((services) => {
            const newServiceCharges = [...services];
            const belowIndexToAppendData = newServiceCharges.findIndex((serviceCharge) => serviceCharge.id === belowId) + 1;
            const newServiceChargeRow = {
                name: null,
                calculationMethod: CALC_METHODS.FLAT_FEE as CALC_METHODS.FLAT_FEE,
                flatFee: null,
                percentage: null,
                amount: null,
                id: Date.now(),
                magnitude: null,
                totalExtendedPrice,
                newRow: true
            };
            newServiceCharges.splice(belowIndexToAppendData, 0, newServiceChargeRow);
            updateServicesTableData(newServiceCharges);
            return newServiceCharges;
        });
    }

    function handleDuplicate() {
        const duplicateBodsIdSet: any = selectedServices.reduce((acc, curr) => {
            acc.add(curr?.id);
            return acc;
        }, new Set());
        const newServices = [...services].flatMap((serviceInfo, index) => {
            if (duplicateBodsIdSet.has(serviceInfo.id)) {
                return [{ ...serviceInfo }, { ...serviceInfo, id: Date.now() + index, newRow: true }];
            }
            return { ...serviceInfo };
        });
        updateServices(newServices);
        updateServicesTableData(newServices);
        handleClearSelection();
    }

    function handleSingleDuplicate(rowId: number) {
        updateServices((services) => {
            const newServices = services.flatMap((serviceCharge) => {
                if (serviceCharge.id === rowId) {
                    return [serviceCharge, { ...serviceCharge, id: Date.now(), newRow: true }];
                }

                return serviceCharge;
            });
            updateServicesTableData(newServices);
            return newServices;
        });
    }

    const handleMultipleDelete = () => {
        const idsToDelete = new Set(selectedServices.map((row) => row?.id));
        let existingDeletedRows = deletedRows;

        const updateService = services.filter((row) => !idsToDelete.has(row?.id));
        services.map((row) => {
            if (!row.newRow && idsToDelete.has(row.id)) ++existingDeletedRows;
        });
        handleClearSelection();
        setDeletedRows(existingDeletedRows);
        updateServices(updateService);
        updateServicesTableData(updateService);
    };

    function handleSingleDelete(props: ServiceChargeRow) {
        updateServices((services) => {
            const newServices = services.filter((serviceCharge) => serviceCharge.id !== props.id);
            updateServicesTableData(newServices);
            return newServices;
        });
        setDeletedRows((deletedRows) => {
            let existingDeletedRows = deletedRows;
            if (!props.newRow) ++existingDeletedRows;
            return existingDeletedRows;
        });
    }

    async function handleSave() {
        let newRows = 0;
        const servicesDataToUpdate = services.map((serviceCharge) => {
            if (serviceCharge.newRow) {
                ++newRows;
            }
            const calculationMethod = serviceCharge.calculationMethod;
            const percentage = calculationMethod === CALC_METHODS.PERCENTAGE_OF_ORDER ? serviceCharge.percentage : null;
            const flatFee = calculationMethod === CALC_METHODS.FLAT_FEE && (serviceCharge.flatFee || serviceCharge.flatFee === 0) ? convertToCents(serviceCharge.flatFee) : null;
            return {
                name: serviceCharge.name || '',
                percentage_of_order: percentage,
                flat_fee_cents: flatFee,
                calculation_method: calculationMethod
            };
        });
        await saveServices({ bomId: Number(bomId), reqBody: servicesDataToUpdate });
        pushUserEventCount({ eventType: 'service_line_created', needCummulation: false, incrementBy: newRows });
        pushUserEventCount({ eventType: 'service_line_deleted', needCummulation: false, incrementBy: deletedRows });
        onClose();
    }

    function handleSaveClick() {
        setTimeout(() => {
            setStartServicesSave(true);
        });
    }
    const CalculationTemplate = useCallback((props: ICellRendererParams) => {
        if (props.node.rowPinned === 'bottom') {
            return null;
        }

        function handleOnChange(event: SelectChangeEvent<unknown>) {
            const value = event.target.value as CALC_METHODS.FLAT_FEE | CALC_METHODS.PERCENTAGE_OF_ORDER;
            const { flatFee, percentage, totalExtendedPrice } = props.data || {};
            const amount = value === CALC_METHODS.FLAT_FEE ? flatFee : percentage != null ? getPercentageAmount(totalExtendedPrice, percentage) : null;
            const magnitude = value === CALC_METHODS.FLAT_FEE ? flatFee : percentage;
            const newData = { ...props.data, calculationMethod: value, amount, magnitude };
            props.node.setData(newData);
            updateRow(props.data.id, newData);
        }
        return <Select value={props?.data?.calculationMethod} onChange={handleOnChange} options={SERVICES_CALC_OPTIONS} />;
    }, []);

    function updateRow(rowId: number, rowInfo: Partial<ServiceChargeRow>) {
        updateServices((services) => {
            const updatedServiceCharges = services.map((serviceRow) => {
                if (serviceRow.id === rowId) {
                    return {
                        ...serviceRow,
                        ...rowInfo
                    };
                }

                return { ...serviceRow };
            });

            return updatedServiceCharges;
        });
    }

    function handleEdit(props: CellValueChangedEvent) {
        const fieldName = props?.column?.getColId();
        let { flatFee, percentage, amount } = props.data;
        const { id, totalExtendedPrice, calculationMethod } = props.data;
        if (fieldName === 'magnitude' && calculationMethod === CALC_METHODS.FLAT_FEE) {
            flatFee = props.data[fieldName];
            amount = props.data[fieldName];
        } else if (fieldName === 'magnitude' && calculationMethod === CALC_METHODS.PERCENTAGE_OF_ORDER) {
            percentage = props.data[fieldName];
            amount = props.data[fieldName] !== null ? getPercentageAmount(totalExtendedPrice, props.data[fieldName]) : null;
        }
        const newData = { ...props.data, flatFee, percentage, amount };
        props.node.setData(newData);
        updateRow(id, newData);
    }

    const MenuTemplate = useCallback((props: ICellRendererParams) => {
        if (props?.node?.rowPinned === 'bottom') {
            return null;
        }
        const options = [
            { label: 'Add service below', onClick: () => handleAddSingleServiceBelow(props?.data?.id) },
            { label: 'Duplicate', onClick: () => handleSingleDuplicate(props?.data?.id) },
            { label: 'Delete', onClick: () => handleSingleDelete(props?.data), color: 'error.main' }
        ];
        return (
            <Box display="flex" justifyContent="center">
                <Menu options={options} />
            </Box>
        );
    }, []);

    function handleRowDragEnd(params: RowDragEvent<any, any>) {
        const { api } = params;
        const updatedRowData: ServiceChargeRow[] = [];

        api.forEachNode((node) => {
            updatedRowData.push({ ...node.data });
        });
        updateServices(updatedRowData);
    }

    function handleCheckboxChange(args: any) {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedItems(selectedBods);
    }

    const ServicesTableColumnDefs: ColDef[] = useMemo(
        () => [
            { headerName: 'id', field: 'id', hide: true },
            { field: 'drag', rowDrag: true, maxWidth: 30, minWidth: 20, colSpan: (params) => (params.node?.rowPinned === 'bottom' ? 3 : 0), cellStyle: getTemplateCellStyle },
            {
                headerCheckboxSelection: true,
                showDisabledCheckboxes: true,
                maxWidth: 40,
                minWidth: 20,
                enableRowGroup: true,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                cellStyle: getTemplateCellStyle
            },
            {
                headerName: 'Services Name',
                field: 'name',
                editable: (params: any) => !params.node.rowPinned,
                width: 200,
                minWidth: 200,
                resizable: true,
                sortable: false,
                cellEditorParams: TextCellParams,
                cellDataType: 'text'
            },
            {
                headerName: 'Calculation Method',
                field: 'calculationMethod',
                editable: false,
                width: 152,
                minWidth: 152,
                cellRenderer: CalculationTemplate,
                cellStyle: getTemplateCellStyle,
                resizable: true,
                sortable: false,
                suppressFillHandle: true
            },
            {
                headerName: 'Magnitude',
                field: 'magnitude',
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.calculationMethod === CALC_METHODS.FLAT_FEE ? params.data.flatFee : params.data.percentage;
                },
                editable: (params: any) => !params.node.rowPinned,
                width: 140,
                minWidth: 140,
                resizable: true,
                sortable: false,
                cellDataType: 'number',
                cellEditor: 'agNumberCellEditor',
                cellEditorParams: pricingCellParams,
                valueFormatter: getMagnitudeFormatToDisplay
            },
            {
                headerName: 'Amount',
                field: 'amount',
                editable: false,
                width: 150,
                minWidth: 150,
                cellDataType: 'number',
                resizable: true,
                sortable: false,
                cellRenderer: 'agAnimateShowChangeCellRenderer',
                valueFormatter: getCellAmountFormatter
            },
            {
                minWidth: 50,
                maxWidth: 50,
                editable: false,
                pinned: 'right',
                cellRenderer: MenuTemplate,
                headerComponent: MenuHeaderTemplate,
                cellStyle: getTemplateCellStyle
            }
        ],
        []
    );
    const defaultColDef: any = { menuTabs: [], flex: 1, cellStyle: getFooterDisabled, suppressFillHandle: true };

    const totalAmount = useMemo(() => {
        return services.reduce((acc, service) => acc + (service?.amount || 0), 0);
    }, [services]);

    const handleProcessCellPasteForPricing = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column?.getColId();

        async function generateNewRowsForTable() {
            const rowsFromClipBoard = await getParsedContentFromClipboard();
            if (selectedRowsSet.current.size && rowsFromClipBoard.length && fieldName) {
                const newRowData = rowsFromClipBoard.slice(selectedRowsSet.current.size);
                if (newRowData.length) {
                    const selectedColArr = getAdjacentColumnNames(tableRef, fieldName, ['id']);
                    const newFromattedRows = newRowData.flatMap((valueArr: string[], i: number) =>
                        valueArr.reduce((acc: any, value: string, index: number) => {
                            if (selectedColArr[index]['cellDataType'] === 'number') {
                                acc[selectedColArr[index]['field']] = Number(value.replace(/[,$%a-zA-Z]/g, '')) || null;
                            } else {
                                acc[selectedColArr[index]['field']] = value;
                            }
                            acc['id'] = Date.now() + i;
                            acc['newRow'] = true;
                            acc['totalExtendedPrice'] = totalExtendedPrice;
                            acc['calculationMethod'] = acc['calculationMethod'] || CALC_METHODS.FLAT_FEE;
                            return acc;
                        }, {})
                    );
                    const newServices = [...services, ...newFromattedRows];
                    for (let service of newServices) {
                        if (service.calculationMethod === CALC_METHODS.FLAT_FEE) {
                            service.flatFee = service.magnitude;
                            service.amount = service.magnitude;
                        } else if (service.calculationMethod === CALC_METHODS.PERCENTAGE_OF_ORDER) {
                            service.percentage = service.magnitude || null;
                            service.amount = service.percentage !== null ? getPercentageAmount(totalExtendedPrice, service.percentage) : null;
                        }
                    }
                    updateServices(newServices);
                    updateServicesTableData(newServices);
                    selectedRowsSet.current.clear();
                }
            }
        }
        generateNewRowsForTable();

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        } else if (value === '' || value == null) {
            return fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE ? null : '';
        }

        const processCellPasteCols = new Set([...priceRelatedCols, ...serviceRelatedCols]);

        if (processCellPasteCols.has(fieldName)) {
            const newValue: string = value.replace(/\$|,|%/g, '');
            if (newValue !== '' && !isNaN(newValue as unknown as number)) {
                return parseFloat(newValue);
            }
            return null;
        }
        return value;
    };

    function handleClearSelection() {
        tableRef?.current?.api?.deselectAll();
        setSelectedItems([]);
    }

    return (
        <Modal
            open={open}
            header={<ModalHeader onClose={onClose} title="Services" />}
            footer={<ModalFooter onAccept={handleSaveClick} onReject={onClose} continueButtonLabel="Save" isLoading={isServicesSaving} />}
        >
            <Box py={6} width="900px">
                <Box>
                    <BodySmall color="secondary.light" paddingBottom={6}>
                        The following are your services created for this quote.
                    </BodySmall>
                    <BodySmall color="neutral.dark">Order Total (Extended Sell Price)</BodySmall>
                    <BodySmall color="secondary.light" paddingBottom={6}>
                        ${orderTotalForUi}
                    </BodySmall>
                </Box>
                <Box pt={3}>
                    <CustomToolBarPanel
                        loading={isServicesLoading || isServicesSaving}
                        toolBarOptions={['add', 'delete', 'duplicate', 'selectedItems']}
                        selectedRowCount={selectedServices.length}
                        disabledToolBarButton={selectedServices.length === 0}
                        onAdd={handleAddServiceCharge}
                        onDelete={handleMultipleDelete}
                        onAddDuplicates={handleDuplicate}
                        onCloseBanner={handleClearSelection}
                        title="Service Charges"
                    ></CustomToolBarPanel>
                    <Box style={{ height: tableHeight, width: '100%' }} className="ag-theme-alpine">
                        <AgGridReact
                            ref={tableRef}
                            rowData={servicesTableData}
                            getRowId={getRowId}
                            gridOptions={{
                                rowHeight,
                                suppressRowClickSelection: true,
                                enableRangeSelection: true,
                                enableFillHandle: true,
                                undoRedoCellEditing: true,
                                enterNavigatesVerticallyAfterEdit: true,
                                enterNavigatesVertically: true,
                                rowDragManaged: true,
                                valueCache: true,
                                rowSelection: 'multiple',
                                suppressContextMenu: true,
                                stopEditingWhenCellsLoseFocus: true,
                                getRowStyle: (params) => {
                                    if (params.node.rowPinned) {
                                        return { backgroundColor: '#f8f8f8', fontWeight: 700 };
                                    }
                                    return undefined;
                                }
                            }}
                            columnDefs={ServicesTableColumnDefs}
                            rowSelection={'multiple'}
                            isRowSelectable={(params) => !params.rowPinned}
                            modules={modules}
                            defaultColDef={defaultColDef}
                            onCellValueChanged={handleEdit}
                            pinnedBottomRowData={[{ drag: 'Subtotal', amount: totalAmount }]}
                            onRowDragEnd={handleRowDragEnd}
                            onRowDragLeave={handleRowDragEnd}
                            onSelectionChanged={handleCheckboxChange}
                            noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                            noRowsOverlayComponentParams={{
                                isTableHaveFooter: true
                            }}
                            processCellFromClipboard={handleProcessCellPasteForPricing}
                            onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsSet)}
                        />
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
