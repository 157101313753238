import { axios } from 'src/app/Axios';
import { CustomRubberStamp, DatasheetResponse, SaveAndConfirmAnnotationRequest, SaveOrConfirmAnnotationResponse, SelectedDocumentResponse, UserPresetTools } from './apiTypes';

export const endPoints = {
    toolPresets: 'api/user_tool_preset/',
    customRubberStamps: 'api/companygroup_preset/',
    showSuggestionState: 'user/annotation_suggestion_status/',
    selectedDocument: (bom_id: string, bod_id: string, documentType: string, subDocumentType: string) => `/bom/${bom_id}/${documentType}_metadata/${bod_id}/selected_${subDocumentType}/?latest=true`,
    datasheet: (bomId: string, bodId: string, selectedDatasheetID: number, datasheetID: number, documentType: string, subDocumentType: string) =>
        `/bom/${bomId}/${documentType}_metadata/${bodId}/selected_${subDocumentType}/${selectedDatasheetID}/${subDocumentType}/${datasheetID}`,
    saveOrConfirmAnnotation: (bomId: string, bodId: string, selectedDocumentId: number, documentType: string, subDocumentType: string) =>
        `/bom/${bomId}/${documentType}_metadata/${bodId}/selected_${subDocumentType}/${selectedDocumentId}`,
    mutateHiddenPagesUrl: (bomId: string, bodId: string, documentType: string, subDocumentType: string, selectedDocumentId: number) =>
        `/bom/${bomId}/${documentType}_metadata/${bodId}/selected_${subDocumentType}/${selectedDocumentId}`
};

export const getToolPresetStyles = () => {
    return axios.get<Array<UserPresetTools>>(endPoints.toolPresets);
};

export const setToolPresetStyles = (payload: any) => {
    return axios.patch<{ message: string }>(endPoints.toolPresets, payload);
};

export const getCustomRubberStamp = () => {
    return axios.get<Array<CustomRubberStamp>>(endPoints.customRubberStamps);
};

export const setCustomRubberStamp = (payload: any) => {
    return axios.patch<CustomRubberStamp>(endPoints.customRubberStamps, payload);
};

export const getShowSuggestionState = () => {
    return axios.get<{ annotation_suggestions_status: boolean }>(endPoints.showSuggestionState);
};

export const setShowSuggestionState = (payload: { annotation_suggestions_status: boolean }) => {
    return axios.patch(endPoints.showSuggestionState, payload);
};

export const getSelectedDocument = (bom_id: string, bod_id: string, documentType: string, subDocumentType: string) => {
    const apiUrl = `${endPoints.selectedDocument(bom_id, bod_id, documentType, subDocumentType)}`;
    return axios.get<Array<SelectedDocumentResponse>>(apiUrl);
};
export const getDatasheet = (bomId: string, bodId: string, selectedDatasheetID: number, datasheetID: number, documentType: string, subDocumentType: string) => {
    return axios.get<DatasheetResponse>(endPoints.datasheet(bomId, bodId, selectedDatasheetID, datasheetID, documentType, subDocumentType));
};

export const saveAnnotation = (data: SaveAndConfirmAnnotationRequest) => {
    const { documentType, bomId, bodId, selectedDocumentId, xfdf, subDocumentType } = data;
    const apiUrl = endPoints.saveOrConfirmAnnotation(bomId, bodId, selectedDocumentId, documentType, subDocumentType);
    return axios.patch<SaveOrConfirmAnnotationResponse>(apiUrl, { draft_xfdf: xfdf, draft_xfdf_timestamp: new Date().toUTCString() });
};

export const confirmAnnotation = (data: SaveAndConfirmAnnotationRequest) => {
    const { documentType, bomId, bodId, selectedDocumentId, xfdf, subDocumentType } = data;
    const apiUrl = endPoints.saveOrConfirmAnnotation(bomId, bodId, selectedDocumentId, documentType, subDocumentType);
    return axios.patch<SaveOrConfirmAnnotationResponse>(apiUrl, { xfdf: xfdf, xfdf_timestamp: new Date().toUTCString() });
};
