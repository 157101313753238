import { AutocompleteChangeReason, Box, GroupedAutoComplete, OptionType, GroupedOptionType } from '@parspec/pixel';

import { FormFieldAction } from '../FormFieldAction';
import { RowContainer } from '../RowContainer';
import { searchManufacturer } from 'src/features/shared/utils/utils';
import { ManufacturerDropdownType, ManufacturerDropdownValType, ManufacturerGroupType } from '../../types';

interface ManufactererRowProps {
    options: ManufacturerDropdownType[];
    manufacturerGroup: ManufacturerGroupType[];
    selectedOptions: ManufacturerDropdownValType;
    onSelect: (value: ManufacturerDropdownType[], reason: AutocompleteChangeReason) => void;
    label: string;
    id: string;
    onBlur: () => void;
    lastRow?: boolean;
    error?: boolean;
    disableRow?: boolean;
}

export function ManufactererRow({ label, options, selectedOptions, id, onSelect, onBlur, lastRow = false, error, disableRow, manufacturerGroup }: ManufactererRowProps) {
    function handleSelect(_event: React.SyntheticEvent<Element, Event>, value: GroupedOptionType[], reason: AutocompleteChangeReason) {
        onSelect(value as ManufacturerDropdownType[], reason);
    }

    // Temporary type assertion added to fix type issue. To be fixed from pixel later
    function filteredOptionsCallback(options: GroupedOptionType[], params: any) {
        const filteredOption = (options as unknown as OptionType<ManufacturerDropdownType>[]).filter((option: any) => option.type === 'options');
        return searchManufacturer(filteredOption, params.inputValue) as unknown as GroupedOptionType[];
    }

    return (
        <RowContainer lastRow={lastRow}>
            <GroupedAutoComplete
                id={id}
                label={label + '*'}
                options={options}
                selectedOptions={selectedOptions.values}
                filterOptionsCallBack={filteredOptionsCallback}
                onChange={handleSelect}
                staticFilters={manufacturerGroup}
                size="small"
                multiple
                onBlur={onBlur}
                error={error}
                limitTags={4}
                optionlabelkeyname="label"
                disabled={disableRow}
                helperText={error ? 'Required' : undefined}
            />
            {/* fix for ui when error is shown */}
            <Box mb={error ? '21px' : undefined}>
                <FormFieldAction showClear={false} isLockDisabled isLocked tooltip="This attribute will use strict searching and can’t be disabled" />
            </Box>
        </RowContainer>
    );
}
