import { Box } from '@parspec/pixel';

import { TableLinkTemplate } from 'src/features/shared/utils/utils';
import { IBomCustomer } from '../queries/apiTypes';

export const Customer = (customerInfo: IBomCustomer) => {
    const { business_type, role, company_id, company_name, contact_id, contact_name, company_is_deleted: isCompanyDeleted, contact_is_deleted: isContactDeleted } = customerInfo;

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            {!company_id && !company_id ? '-' : ''}
            {company_id ? (
                <Box component={'span'} display="flex" gap={1} alignItems="center" mt={-1}>
                    {!isCompanyDeleted ? (
                        <Box component={'span'}>
                            <TableLinkTemplate to={`/v2/company/${company_id}`} label={company_name || ''} />
                        </Box>
                    ) : (
                        <Box component="span">{company_name}</Box>
                    )}
                    {business_type ? (
                        <Box color="secondary.light" component="span" fontSize={12}>
                            ({business_type})
                        </Box>
                    ) : (
                        <Box component="span" />
                    )}
                </Box>
            ) : null}
            {contact_id ? (
                <Box component={'span'} display="flex" gap={1} alignItems="center" mt={-6}>
                    {!isContactDeleted ? (
                        <Box component={'span'}>
                            <TableLinkTemplate to={`/v2/company/${company_id}/contacts/${contact_id}`} label={contact_name || ''} />
                        </Box>
                    ) : (
                        <Box component="span">{contact_name}</Box>
                    )}
                    {role ? (
                        <Box color="secondary.light" component="span" fontSize={12}>
                            ({role})
                        </Box>
                    ) : (
                        <Box component="span" />
                    )}
                </Box>
            ) : null}
        </Box>
    );
};
