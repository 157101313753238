import { useState, useEffect, useRef } from 'react';

import { Box, Snackbar, Skeleton, H5, BodySmall, ErrorIcon, AlertBanner } from '@parspec/pixel';

import { Header } from './Header';
import { SubscriptionInfo } from './SubscriptionInfo';
import { BillingInfo } from './BillingInfo';
import { SnackbarData, ErrorState, BillingData, SelectFieldOptions } from './PlanAndPayment.types';
import { useGetUserProfileInfoQuery } from '../MyProfile/queries';
import { initialBillingDataState, initialErrorState } from './utils/utils';
import { trimEmptySpaces } from './utils/utils';
import { validateEmail } from '../UserManagement/utils/utils';
import { useEditBillingDetailsMutation, useSubscriptionInfo } from './queries';

import { NO_CHANGES_TO_SAVE_MSG } from '../../shared/constants';

export const PlanAndPayment = () => {
    const [snackbarData, setSnackbarData] = useState<SnackbarData>({
        isActive: false,
        message: ''
    });

    const [billingData, setBillingData] = useState<BillingData>(initialBillingDataState);

    // Adding this state because if we put billingInfo api here, we will get a 403 error if user is non-admin
    const [unmodifiedBillingData, setUnmodifiedBillingData] = useState<BillingData>(initialBillingDataState);

    const [errorState, setErrorState] = useState<ErrorState>(initialErrorState);
    const [stateListOptions, setStateListOptions] = useState<SelectFieldOptions>([{ label: '', value: '' }]);

    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();
    const { data: subscriptionInfoData, isLoading: subscriptionInfoLoading } = useSubscriptionInfo();
    const { mutateAsync: modifyBillingDetails, isLoading: modifyBillingDetailsLoading } = useEditBillingDetailsMutation();
    const [showPaymentProcessingBanner, setShowPaymentProcessingBanner] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (
            unmodifiedBillingData.address1 === billingData.address1 &&
            unmodifiedBillingData.address2 === billingData.address2 &&
            unmodifiedBillingData.city === billingData.city &&
            unmodifiedBillingData.companyName === billingData.companyName &&
            unmodifiedBillingData.country === billingData.country &&
            unmodifiedBillingData.email === billingData.email &&
            unmodifiedBillingData.state === billingData.state &&
            unmodifiedBillingData.zipcode === billingData.zipcode
        ) {
            return setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: NO_CHANGES_TO_SAVE_MSG
            });
        }

        const trimmedAddress1 = trimEmptySpaces(billingData.address1);
        const trimmedAddress2 = billingData.address2 ? trimEmptySpaces(billingData.address2) : '';
        const trimmedcity = trimEmptySpaces(billingData.city);
        const trimmedZipcode = trimEmptySpaces(billingData.zipcode);

        const emailFlag = billingData.email.length === 0;
        const address1Flag = trimmedAddress1.length === 0;
        const cityFlag = trimmedcity.length === 0;
        const countryFlag = billingData.country.length === 0;
        const stateFlag = billingData.state.length === 0;
        const zipcodeFlag = billingData.zipcode.trim().length === 0 || billingData.zipcode.trim().length > 10;

        if (emailFlag || address1Flag || cityFlag || countryFlag || stateFlag || zipcodeFlag) {
            let zipCodeErrorMsg = '';
            if (billingData.zipcode.trim().length === 0) {
                zipCodeErrorMsg = 'Required';
            }
            if (billingData.zipcode.trim().length > 10) {
                zipCodeErrorMsg = 'Max length allowed is 10';
            }

            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: emailFlag, title: `${emailFlag ? 'Required' : ''}` },
                address1: { ...errorState.address1, isError: address1Flag, title: `${address1Flag ? 'Required' : ''}` },
                city: { ...errorState.city, isError: cityFlag, title: `${cityFlag ? 'Required' : ''}` },
                country: { ...errorState.country, isError: countryFlag, title: `${countryFlag ? 'Required' : ''}` },
                state: { ...errorState.state, isError: stateFlag, title: `${stateFlag ? 'Required' : ''}` },
                zipcode: { ...errorState.zipcode, isError: zipcodeFlag, title: zipCodeErrorMsg }
            });
        }

        if (!validateEmail(billingData.email)) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
            });
        }

        const payload = {
            address1: trimmedAddress1,
            address2: trimmedAddress2,
            city: trimmedcity,
            state: billingData.state,
            zipcode: trimmedZipcode,
            country: billingData.country,
            email: billingData.email,
            company_name: billingData.companyName
        };

        await modifyBillingDetails(payload);
        setSnackbarData({
            ...snackbarData,
            isActive: true,
            message: 'Saved successfully'
        });
    };

    const onClosePaymentProcessingBannerHandler = () => {
        setShowPaymentProcessingBanner(() => false);
    };

    useEffect(() => {
        if (!subscriptionInfoData?.data) {
            return;
        }

        setShowPaymentProcessingBanner(() => subscriptionInfoData?.data.payment_processing);
    }, [subscriptionInfoData?.data]);

    return (
        <Box mb={8} bgcolor={'#f3f5fa'} display={'flex'} flexDirection={'column'} width="100%">
            <Box width="calc(100vw - 390px)">
                {!subscriptionInfoLoading && subscriptionInfoData?.data.active && !subscriptionInfoData?.data.canceled && showPaymentProcessingBanner && (
                    <Box mb={4}>
                        <AlertBanner
                            text={`Your payment is currently being processed. Check back in a few moments.`}
                            variant="filled"
                            severity="warning"
                            onClose={onClosePaymentProcessingBannerHandler}
                        />
                    </Box>
                )}

                <Box maxHeight={'75vh'} position={'relative'} overflow={'hidden'} bgcolor="secondary.contrastText">
                    <Box bgcolor={'secondary.contrastText'} position={'absolute'} zIndex={10} width="100%" p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                        {isAdminLoading && <Skeleton variant="rectangular" height={36} />}
                        {!isAdminLoading && userProfile?.data.role !== 'admin' && <H5>Plan and Payment</H5>}
                        {!isAdminLoading && userProfile?.data.role === 'admin' && (
                            <Header
                                billingData={billingData}
                                setBillingData={setBillingData}
                                errorState={errorState}
                                setErrorState={setErrorState}
                                initialErrorState={initialErrorState}
                                modifyBillingDetailsLoading={modifyBillingDetailsLoading}
                                onSubmitHandler={onSubmitHandler}
                                setStateListOptions={setStateListOptions}
                            />
                        )}
                    </Box>
                    <Box p={6} sx={{ overflowY: 'scroll' }} mt={20} maxHeight={'67vh'}>
                        <SubscriptionInfo snackbarData={snackbarData} setSnackbarData={setSnackbarData} />

                        {isAdminLoading && (
                            <Box mt={'20px'}>
                                <Skeleton variant="rectangular" height={36} />
                            </Box>
                        )}
                        {!isAdminLoading && userProfile?.data.role !== 'admin' && (
                            <Box mt={'20px'} display={'flex'} alignItems="center" height={'36px'} bgcolor={'tertiary.light'} p={1} mb={10}>
                                <Box pl={'10px'}>
                                    <ErrorIcon color="tertiary" fontSize="small" />
                                </Box>
                                <BodySmall limit={false} pl={2} color={'tertiary.main'}>
                                    Admin access is required to manage or refresh your account’s subscription, or to enroll/unenroll in subscription renewal.
                                </BodySmall>
                            </Box>
                        )}

                        {!isAdminLoading && userProfile?.data.role === 'admin' && (
                            <BillingInfo
                                billingData={billingData}
                                setBillingData={setBillingData}
                                errorState={errorState}
                                setErrorState={setErrorState}
                                unmodifiedBillingData={unmodifiedBillingData}
                                setUnmodifiedBillingData={setUnmodifiedBillingData}
                                onSubmitHandler={onSubmitHandler}
                                formRef={formRef}
                                stateListOptions={stateListOptions}
                                setStateListOptions={setStateListOptions}
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={2000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </Box>
    );
};
