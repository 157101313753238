import React, { FC, useState, useEffect, FocusEvent } from 'react';

import { BodyMedium, Box, Modal, ModalFooter, ModalHeader, RadioGroup, Select, SelectChangeEvent, TextField } from '@parspec/pixel';

import { CANADA, modifiedStateList, USA } from '../../../Settings/BranchLocations/utils/utils';
import { CANADA_STATES, COUNTRY_OPTIONS, EMAIL_FORMAT_INVALID, REQUIRED_FIELD, USA_STATES } from '../../../shared/constants';
import { addressOptions, checkFormValidation, Company, ContactFormErrorType, ContactFormFields, FORM_FIELDS, getInitialState } from '../utils';
import { useAddContactMutation } from '../queries';
import { Contact } from '../queries/apiTypes';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import CompanySelector from '../../shared/CompanySelector';
import { CONTACT_NUMBER_REQEX } from '../../shared/constant';
import useBroadCaster, { BROADCAST_MESSAGES } from '../../../shared/hooks/useBroadCaster';

interface AddContactDialogProps {
    open: boolean;
    onClose: () => void;
    handleAddContactSuccess: (data: Contact) => void;
    preSelectedCompany?: Company;
}

const AddContactDialog: FC<AddContactDialogProps> = ({ open, onClose, handleAddContactSuccess, preSelectedCompany }) => {
    const [formValues, setFormValues] = useState<ContactFormFields>(getInitialState());
    const [stateOptions, setStateOptions] = useState<
        {
            value: string;
            label: string;
        }[]
    >([{ value: '', label: '' }]);
    const [formErrors, setFormErrors] = useState<ContactFormErrorType>({});
    const isAddressInherited = formValues.address_inherited_from_company;
    const { postMessage } = useBroadCaster();

    const { mutateAsync: addContact, isLoading } = useAddContactMutation();

    useEffect(() => {
        if (preSelectedCompany) {
            setFormValues((old) => ({ ...old, [FORM_FIELDS.COMPANY]: preSelectedCompany }));
        }
    }, [preSelectedCompany]);

    useEffect(() => {
        if (formValues?.country) {
            if (formValues.country.toUpperCase() === USA) {
                setStateOptions(modifiedStateList(USA_STATES));
            } else if (formValues.country === CANADA) {
                setStateOptions(modifiedStateList(CANADA_STATES));
            }
        }
    }, [formValues?.country]);

    const handleEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name, value } = event.target;
        if (name === FORM_FIELDS.EMAIL && value.trim()) {
            setFormErrors((old) => ({ ...old, [FORM_FIELDS.EMAIL]: !validateEmail(value) ? EMAIL_FORMAT_INVALID : '' }));
        }
    };

    const onChangeAddressType = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setFormValues((old: ContactFormFields) => ({ ...old, [FORM_FIELDS.ADDRESS_INHERITED]: value === 'true' }));
    };

    const onChangeFormValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
        const { name, value } = (e?.target as HTMLInputElement) || {};
        if (name === FORM_FIELDS.PHONE) {
            if (!CONTACT_NUMBER_REQEX.test(value)) {
                return;
            }
        }
        if (name === 'country') {
            setFormValues((old: ContactFormFields) => ({ ...old, [name]: value, state: '' }));
        } else setFormValues((old: ContactFormFields) => ({ ...old, [name]: value }));
        if (name === FORM_FIELDS.FIRST_NAME) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.FIRST_NAME]: !value.trim() ? REQUIRED_FIELD : '' });
        }
        if (name === FORM_FIELDS.LAST_NAME) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.LAST_NAME]: !value.trim() ? REQUIRED_FIELD : '' });
        }
        if (name === FORM_FIELDS.EMAIL) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.EMAIL]: !value.trim() ? '' : formErrors[FORM_FIELDS.EMAIL] });
        }
    };

    const handleCompanyChange = (value: Company) => {
        setFormValues((old: ContactFormFields) => ({ ...old, company_info: value }));
        setFormErrors((old: ContactFormErrorType) => ({ ...old, company_info: undefined }));
    };

    const handleSubmit = async () => {
        const errors = checkFormValidation(formValues);
        const isError = Object.keys(errors).some((item) => Boolean(errors[item as keyof ContactFormErrorType]));
        if (isError) {
            return setFormErrors(errors);
        }
        const input = {
            company: formValues.company_info!.id,
            first_name: formValues.first_name,
            role: formValues.role || '',
            email: formValues.email || '',
            phone: formValues.phone || '',
            last_name: formValues.last_name,
            notes: formValues.notes || '',
            address_inherited_from_company: formValues.address_inherited_from_company,
            line1: formValues.line1 || '',
            line2: formValues.line2 || '',
            city: formValues.city || '',
            country: formValues.country || '',
            state: formValues.state || '',
            zip: formValues.zip || ''
        };
        const response = await addContact(input);
        postMessage(BROADCAST_MESSAGES.INVALIDATE_CONTACTS + new Date().toISOString());
        handleAddContactSuccess(response?.data);
        onClose();
    };

    return (
        <Modal
            open={open}
            header={<ModalHeader onClose={onClose} title="Create Contact" />}
            footer={<ModalFooter onReject={onClose} onAccept={handleSubmit} continueButtonLabel="Confirm" isLoading={isLoading} />}
        >
            <Box width="550px" maxHeight="75vh" pt={4} pb={2} display="flex" flexDirection="column" overflow="scroll" pr={3}>
                <form>
                    <Box display="flex" flexDirection="column" gap={4}>
                        <BodyMedium>Details</BodyMedium>
                        <Box display="flex" gap={3}>
                            <TextField
                                label="First Name*"
                                name={FORM_FIELDS.FIRST_NAME}
                                fullWidth
                                value={formValues?.[FORM_FIELDS.FIRST_NAME] || ''}
                                onChange={onChangeFormValue}
                                error={Boolean(formErrors?.[FORM_FIELDS.FIRST_NAME])}
                                helperText={formErrors?.[FORM_FIELDS.FIRST_NAME]}
                            />
                            <TextField
                                label="Last Name*"
                                name={FORM_FIELDS.LAST_NAME}
                                fullWidth
                                value={formValues?.[FORM_FIELDS.LAST_NAME] || ''}
                                onChange={onChangeFormValue}
                                error={Boolean(formErrors?.[FORM_FIELDS.LAST_NAME])}
                                helperText={formErrors?.[FORM_FIELDS.LAST_NAME]}
                            />
                        </Box>
                        <Box>
                            <CompanySelector
                                companyInfo={preSelectedCompany || formValues?.[FORM_FIELDS.COMPANY]}
                                companyError={formErrors?.[FORM_FIELDS.COMPANY]}
                                setCompanyInfo={handleCompanyChange}
                                isCompanySelectorDisabled={Boolean(preSelectedCompany)}
                            />
                        </Box>
                        <TextField label="Job Title" name={FORM_FIELDS.ROLE} value={formValues?.[FORM_FIELDS.ROLE] || ''} onChange={onChangeFormValue} />
                        <TextField
                            label="Email"
                            name={FORM_FIELDS.EMAIL}
                            value={formValues?.[FORM_FIELDS.EMAIL] || ''}
                            onChange={onChangeFormValue}
                            error={Boolean(formErrors?.[FORM_FIELDS.EMAIL])}
                            helperText={formErrors?.[FORM_FIELDS.EMAIL]}
                            onBlur={handleEmailBlur}
                        />
                        <TextField label="Phone Number" name={FORM_FIELDS.PHONE} value={formValues?.[FORM_FIELDS.PHONE] || ''} onChange={onChangeFormValue} />
                    </Box>
                    <Box display="flex" flexDirection="column" gap={4} mt={6} sx={{ display: 'none' }}>
                        <BodyMedium mb={-4}>Address</BodyMedium>
                        <RadioGroup name="address-type" label={``} options={addressOptions} value={String(formValues?.[FORM_FIELDS.ADDRESS_INHERITED])} onChange={onChangeAddressType} />
                        {!isAddressInherited ? (
                            <Box display="flex" flexDirection="column" gap={4}>
                                <TextField label="Address Line 1" name={FORM_FIELDS.ADDRESS_LINE_1} fullWidth value={formValues?.[FORM_FIELDS.ADDRESS_LINE_1] || ''} onChange={onChangeFormValue} />
                                <TextField label="Address Line 2" name={FORM_FIELDS.ADDRESS_LINE_2} fullWidth value={formValues?.[FORM_FIELDS.ADDRESS_LINE_2] || ''} onChange={onChangeFormValue} />
                                <Box display="flex" gap={3}>
                                    <Box flex={1}>
                                        <Select
                                            label="Country"
                                            name={FORM_FIELDS.COUNTRY}
                                            optionLabelKeyname={'name'}
                                            optionValueKeyname={'id'}
                                            options={COUNTRY_OPTIONS}
                                            value={formValues?.[FORM_FIELDS.COUNTRY] || ''}
                                            onChange={onChangeFormValue}
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <TextField label="City" name={FORM_FIELDS.CITY} fullWidth value={formValues?.[FORM_FIELDS.CITY] || ''} onChange={onChangeFormValue} />
                                    </Box>
                                </Box>
                                <Box display="flex" gap={3}>
                                    <Box flex={1}>
                                        <Select
                                            label="State"
                                            name={FORM_FIELDS.STATE}
                                            optionLabelKeyname={'label'}
                                            optionValueKeyname={'value'}
                                            options={stateOptions}
                                            value={formValues?.[FORM_FIELDS.STATE] || ''}
                                            onChange={onChangeFormValue}
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <TextField label="Zip" name={FORM_FIELDS.ZIP} fullWidth value={formValues?.[FORM_FIELDS.ZIP] || ''} onChange={onChangeFormValue} />
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                    <Box display="flex" flexDirection="column" gap={4} mt={6}>
                        <BodyMedium>Notes</BodyMedium>
                        <TextField label="Contact Notes" name={FORM_FIELDS.NOTES} rows={4} multiline value={formValues?.[FORM_FIELDS.NOTES] || ''} onChange={onChangeFormValue} />
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default React.memo(AddContactDialog);
