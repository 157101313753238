import { Box, Modal, BodyBig, BodySmall } from '@parspec/pixel';

interface ExtractingAttribuesLoaderModalProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    subTitle?: string;
    helperLink?: string;
}

export const ExtractingAttribuesLoaderModal = (props: ExtractingAttribuesLoaderModalProps) => {
    const { open, onClose, title, subTitle, helperLink } = props;

    return (
        <Modal open={open} onClose={onClose}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width="410px" p={4} pb={0} pt={2}>
                <Box width={'100%'} textAlign="center">
                    <BodyBig color={'secondary.main'}>{title}</BodyBig>
                    <Box mt={4}>
                        <BodySmall color={'neutral.dark'} lines={2} sx={{ lineHeight: '20px' }}>
                            {subTitle} {helperLink ? helperLink : ''}
                        </BodySmall>
                    </Box>
                </Box>
                <img src="assets/images/view-files/compile.gif" alt="loader" height="240px" />
            </Box>
        </Modal>
    );
};

ExtractingAttribuesLoaderModal.defaultProps = {
    title: 'Extracting Attributes',
    subTitle: 'We are currently extracting attributes from the selected datasheet.'
};
