import SectionLogo from '../../../shared/SectionLogo';
import { useQuoteContext } from '../../QuoteContext';
import { useHeaderDocumentLogoDeleteMutation, useHeaderDocumentLogoQuery, useHeaderDocumentLogoUploadMutation } from '../../queries';
import { FileUploadPayloadType } from '../../../constants';
import { HEADER_LOGO_SUB_SECTION, HEADER_SECTION_NAME } from '../../shared/constants';

function HeaderLogo() {
    const { handlePdfPreview, bomId, handleUpdateChangedSections } = useQuoteContext();

    const { data: documentLogo, isLoading: isDocumentLogoLoading } = useHeaderDocumentLogoQuery(bomId);

    const { mutateAsync: deleteLogo, isLoading: isDeleting } = useHeaderDocumentLogoDeleteMutation();
    const { mutateAsync: uploadLogo, isLoading: isUpdating } = useHeaderDocumentLogoUploadMutation();

    const pushChangedFields = () => {
        handleUpdateChangedSections?.({ sectionName: HEADER_SECTION_NAME, subSectionName: HEADER_LOGO_SUB_SECTION, value: true });
    };

    const fileUploadHandler = async (data: File[] | null, label?: string | null) => {
        const payload: Omit<FileUploadPayloadType, 'fieldName' | 'documentType'> = {
            bomId: bomId,
            logo: null,
            source: null
        };
        if (data && data.length) {
            const formData = new FormData();
            formData.append('logo', data[0]);

            payload.logo = formData;
            payload.source = null;
        } else if (!data && label) {
            payload.logo = null;
            payload.source = label;
        }

        if (payload.logo !== null || payload.source !== null) {
            await uploadLogo(payload);
            handlePdfPreview(true);
            pushChangedFields();
        }
    };

    const handleFileDelete = async () => {
        await deleteLogo(bomId);
        handlePdfPreview(true);
        pushChangedFields();
    };

    return (
        <SectionLogo
            isDocumentLogoLoading={isDocumentLogoLoading}
            logo={documentLogo?.data?.logo}
            onDelete={handleFileDelete}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onFileUpload={fileUploadHandler}
            title={'Header Logo'}
            sectionName={HEADER_SECTION_NAME}
            subSectionName={HEADER_LOGO_SUB_SECTION}
        />
    );
}

export default HeaderLogo;
