import { Box, PixIcon, BodySmall } from '@parspec/pixel';

import { useDragLayer } from 'react-dnd';

import { TAB_VALUES } from './constants';
import { DRAGABLE_TYPES } from './utils';

const CustomDragLayer = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const { item, isDragging, currentOffset, type } = useDragLayer((monitor) => ({
        type: monitor.getItemType(),
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset()
    }));

    let adjustOffsetValue = 0;

    if (searchParams.get('tab') === TAB_VALUES.PRICING_AND_LEAD_TIME) {
        adjustOffsetValue = 100;
    }

    function renderItem() {
        if (!isDragging || !type?.toString()?.startsWith(DRAGABLE_TYPES.SECTION)) {
            return null;
        }

        return (
            <Box
                sx={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 2,
                    zIndex: 100,
                    left: currentOffset ? currentOffset.x - adjustOffsetValue : 0,
                    top: currentOffset ? currentOffset.y - adjustOffsetValue : 0,
                    border: '1px solid',
                    borderColor: 'neutal.main',
                    backgroundColor: 'white',
                    width: 120,
                    gap: 1,
                    borderRadius: 1
                }}
            >
                <PixIcon />
                <BodySmall fontWeight={600} limit>
                    {item.name}
                </BodySmall>
            </Box>
        );
    }

    return renderItem();
};

export default CustomDragLayer;
