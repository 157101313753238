import { BodySmall, Box, Modal, ModalFooter, ModalHeader } from '@parspec/pixel';

interface DeleteConfirmationPropTypes {
    open: boolean;
    onCloseClick: () => void;
    onSuccess: () => void;
    isLoading: boolean;
    handleSubmit: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationPropTypes> = ({ open, onCloseClick, handleSubmit, isLoading }) => {
    const onSubmitHandler = () => {
        handleSubmit();
    };

    const header = <ModalHeader title={'Delete Document?'} onClose={onCloseClick} />;
    const footer = <ModalFooter onAccept={onSubmitHandler} onReject={onCloseClick} continueButtonLabel="Delete" continueButtonColor="error" isLoading={isLoading} />;

    return (
        <Modal header={header} footer={footer} open={open}>
            <Box width={515}>
                <BodySmall lines={2}>
                    <strong>Are you sure you want to delete this document?</strong> The selected version will be deleted, along with any associated version notes.
                </BodySmall>
            </Box>
        </Modal>
    );
};

export default DeleteConfirmation;
