import { cloneElement, useMemo, useState, useCallback, forwardRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, CircularProgress, H5, Snackbar } from '@parspec/pixel';
import GlobalDndContext from '@parspec/pixel/dist/esm/Shared/globalDndContext';

import { useAddBomDataMutation, useGetBomSectionsQuery, useOptionSectionQuery, useUpdateSectionOrderMutation } from '../queries';
import { SectionApiResponseType, UpdateSectionOrderTypes } from '../queries/apiTypes';
import NoSectionUi from './NoSectionUi';
import Section from './Section';
import { SECTION_TYPE, TAB_VALUES, SECTION_TYPE_FOR_PAYLOAD } from './constants';
import { BooleanObjType } from './types';

interface BOMTabContentProps<T> {
    children: [JSX.Element, JSX.Element];
    tableData: T[];
    tabCta?: React.ReactNode;
}

const TabContent = forwardRef(<T extends { section?: number; substitute_section?: number; id: number }>({ children, tableData, tabCta }: BOMTabContentProps<T>, ref: any) => {
    const { bomId = '0' } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [tableMaxHeight, setTableMaxHeight] = useState<number>();
    const [collapseAll, setCollapseAll] = useState(false);
    const [sectionExpansionObj, setSectionExpansionObj] = useState<BooleanObjType>({});
    const [sectionExpansioLoading, setSectionExpansionLoading] = useState(true);

    const { data: sectionData, isLoading: sectionLoading } = useGetBomSectionsQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { data: optionSectionData, isLoading: optionSectionLoading } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { mutateAsync: addData, isLoading: isAddOptionLoading } = useAddBomDataMutation(bomId);

    const { mutateAsync: reOrderSectionMutation } = useUpdateSectionOrderMutation();

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem(`${bomId}-section`) || '{}');
        setSectionExpansionObj(obj);
        setSectionExpansionLoading(false);
    }, [bomId]);

    const sectionsTableData = useMemo(() => {
        type sectionType = { [key: number]: typeof tableData };
        const data: sectionType = {};
        if (tableData?.length) {
            tableData.forEach((item) => {
                const sectionId = item.section || item.substitute_section || 0;
                if (data[sectionId] && Array.isArray(data[sectionId])) {
                    data[sectionId].push(item);
                } else {
                    data[sectionId] = [item];
                }
            });
        }
        return data;
    }, [tableData]);

    async function handleAddOption({ sectionId, type, qty, productToReplaceId }: { sectionId: number; type: string; qty: number; productToReplaceId: number }) {
        const previous_bod_id = Array.isArray(sectionsTableData[sectionId]) ? sectionsTableData[sectionId][sectionsTableData[sectionId].length - 1].id : null;
        const requestPayload = { section_type: SECTION_TYPE.OPTION, section_id: sectionId, category: type, qty, product_to_replace: productToReplaceId, previous_bod_id };
        await addData({ bomId, input: requestPayload });
    }

    const getSectionTableHeight = useCallback(
        (sectionId: number) => {
            if (tableMaxHeight) {
                const sectionTableDataLength = (sectionsTableData?.[sectionId] || []).length;
                //40 is the row height, 180 is aggregate of section header, table header and paddings,
                //148 is aggregate of section header, footer and paddings
                if (!sectionTableDataLength) return 110;
                return (sectionTableDataLength || 1) * 40 > tableMaxHeight - 255 ? tableMaxHeight - 230 : 40 * (sectionTableDataLength || 1) + 51;
            }
            return 'auto';
        },
        [sectionsTableData, tableMaxHeight]
    );

    const handleMoveSection = useCallback(
        async (sourceIndex = 0, destinationIndex = 0, sectionType = 'primary') => {
            if (sourceIndex === destinationIndex) {
                return;
            }
            let currentSectionState: Array<SectionApiResponseType> = [];
            if (sectionType === SECTION_TYPE.MAIN && sectionData?.data?.length) {
                currentSectionState = [...sectionData.data];
            } else if (sectionType === SECTION_TYPE.OPTION && optionSectionData?.data?.length) {
                currentSectionState = [...optionSectionData.data];
            }

            let previousSectionId: number | null = currentSectionState[destinationIndex].id;

            if (sourceIndex > destinationIndex) {
                previousSectionId = destinationIndex - 1 >= 0 ? currentSectionState[destinationIndex - 1].id : null;
            }

            let payload: UpdateSectionOrderTypes = {
                bomId,
                sourceIndex,
                destinationIndex
            };
            if (sectionType === SECTION_TYPE.MAIN) {
                payload = { ...payload, sectionType: SECTION_TYPE_FOR_PAYLOAD.MAIN, previous_section_id: previousSectionId, section_id: [currentSectionState[sourceIndex].id] };
            } else {
                payload = {
                    ...payload,
                    sectionType: SECTION_TYPE_FOR_PAYLOAD.OPTION,
                    previous_substitute_section_id: previousSectionId,
                    substitute_section_id: [currentSectionState[sourceIndex].id]
                };
            }

            await reOrderSectionMutation(payload);
        },
        [sectionData?.data, optionSectionData?.data]
    );

    const updateSectionExpansionObj = (obj: BooleanObjType) => {
        setSectionExpansionObj(obj);
        localStorage.setItem(`${bomId}-section`, JSON.stringify(obj));
    };

    let adjustedWidth = '482px';
    const currentTab = searchParams.get('tab');

    if (currentTab === TAB_VALUES.DATASHEET_TAB_VALUE || currentTab === TAB_VALUES.OM_DOCUMENTS_TAB_VALUE) {
        adjustedWidth = '820px';
    }

    function renderSection({
        sectionData,
        title,
        children,
        props = {},
        isOptionSection,
        type
    }: {
        sectionData: SectionApiResponseType[];
        title: string;
        children: JSX.Element;
        props?: Record<string, any>;
        isOptionSection?: boolean;
        type: string;
    }) {
        return (
            <Box>
                <Box pl={2} position="sticky" top="0" zIndex="3" width={`calc(100% - ${adjustedWidth})`}>
                    <H5>{title}</H5>
                </Box>
                {sectionData.map((section, index) => (
                    <Section
                        collapseAll={collapseAll}
                        setCollapseAll={setCollapseAll}
                        section={section}
                        key={section.id}
                        index={index}
                        sectionType={type}
                        onMoveSection={handleMoveSection}
                        setSnackbarMessage={setSnackbarMessage}
                        isOptionSection={isOptionSection}
                        isSectionEmpty={!sectionsTableData?.[section.id]}
                        sectionExpansionObj={sectionExpansionObj}
                        updateSectionExpansionObj={updateSectionExpansionObj}
                    >
                        {cloneElement(children, {
                            ...props,
                            section,
                            tableData: sectionsTableData?.[section.id] || [],
                            ref: (inst: any) => {
                                if (inst) ref.current[section.id] = inst;
                            },
                            tableHeight: getSectionTableHeight(section.id),
                            isAddOptionLoading: isAddOptionLoading
                        })}
                    </Section>
                ))}
            </Box>
        );
    }

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {tabCta && (
                <Box position="absolute" width="100%" height="49px" bgcolor="white" right="12px" zIndex="2" px={7}>
                    {tabCta}
                </Box>
            )}

            <Box flex={1} px={5} mt={1} overflow="auto" ref={(ref) => setTableMaxHeight(ref?.offsetHeight || 300)} display="flex" flexDirection="column" rowGap={4}>
                {sectionLoading || optionSectionLoading || sectionExpansioLoading ? (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
                        <CircularProgress color="primary" size="xxl" />
                    </Box>
                ) : (sectionData?.data && sectionData?.data?.length > 0) || (optionSectionData?.data && optionSectionData?.data?.length > 0) ? (
                    <GlobalDndContext>
                        {sectionData?.data &&
                            sectionData?.data?.length > 0 &&
                            renderSection({
                                sectionData: sectionData.data,
                                title: 'Main Products',
                                type: SECTION_TYPE.MAIN,
                                children: children[0],
                                isOptionSection: false,
                                props: { optionSection: optionSectionData?.data, onAddOption: handleAddOption }
                            })}
                        {optionSectionData?.data &&
                            optionSectionData?.data?.length > 0 &&
                            renderSection({
                                isOptionSection: true,
                                sectionData: optionSectionData.data,
                                type: SECTION_TYPE.OPTION,
                                title: 'Options',
                                children: children[1]
                            })}
                    </GlobalDndContext>
                ) : (
                    <NoSectionUi />
                )}
            </Box>
        </>
    );
});

export default TabContent;
