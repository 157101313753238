import { axios } from 'src/app/Axios';

import {
    ManufacturerPair,
    StoredManufacturerPairResponse,
    TogglePayload,
    IUpdateToggleParams,
    IUpdateText,
    IUploadLogo,
    ILogoResponse,
    IQueryResponse,
    IAttachmentDocumentRequest,
    PushChangesRequest
} from './apiTypes';

export const endPoints = {
    // Manufacture TOC
    getManufacturers: (bomId: string) => `/bom/${bomId}/quote_preset/manufacturer_tnc/`,
    addManufacturer: (bomId: string) => `/bom/${bomId}/quote_preset/manufacturer_tnc/`,
    updateManufacturer: (bomId: string, mfgId: number) => `/bom/${bomId}/quote_preset/manufacturer_tnc/${mfgId}/`,
    updateManufacturerToggle: (bomId: string) => `/bom/${bomId}/quote_preset/manufacturer_tnc/configuration/manufacturer_tnc_section/`,

    // Header Footer
    headerDocumentLogo: (bomId: string) => `/bom/${bomId}/quote_preset/header_footer/data/logo/`,
    headerFooterData: (bomId: string) => `/bom/${bomId}/quote_preset/header_footer/`,
    updateHeaderFooterToggle: (bomId: string, field_name: string) => `/bom/${bomId}/quote_preset/header_footer/configuration/${field_name}/`,
    updateHeaderFooterText: (bomId: string, field_name: string) => `/bom/${bomId}/quote_preset/header_footer/data/${field_name}/`,

    // quote preset API
    quotePreset: (bomId: string) => `/bom/${bomId}/quote_preset/`,
    // get pdf url
    quotePdfUrl: (bomId: string) => `/bom/${bomId}/quote_preset/preview/`,

    // price and lead time
    priceAndLeadTimeData: (bomId: string) => `/bom/${bomId}/quote_preset/price_and_lead_time/`,
    priceAndLeadTimeToggle: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/price_and_lead_time/configuration/${fieldName}/`,
    priceAndLeadTimeText: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/price_and_lead_time/data/${fieldName}/`,
    priceAndLeadTimeOptionsPreset: (bomId: string) => `/bom/${bomId}/quote_preset/pal_other_option/`,
    priceAndLeadTimeOptionsUpdate: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/pal_other_option/configuration/${fieldName}/`,
    priceAndLeadTimeOptionsText: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/pal_other_option/data/${fieldName}/`,

    // cover page
    coverPagePresetData: (bomId: string) => `/bom/${bomId}/quote_preset/cover_page/`,
    coverPageToggle: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/cover_page/configuration/${fieldName}/`,
    coverPageText: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/cover_page/data/${fieldName}/`,
    coverPageLogo: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/cover_page/data/${fieldName}/logo/`,

    // general terms and condition
    generalTermsAndCondition: (bomId: string) => `/bom/${bomId}/quote_preset/general_tnc/`,
    generalTermsAndConditionConfiguration: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/general_tnc/configuration/${fieldName}/`,
    generalTermsAndConditionText: (bomId: string, fieldName: string) => `/bom/${bomId}/quote_preset/general_tnc/data/${fieldName}/`,

    // attachments
    quoteAttachmentsData: (bomId: string) => `/bom/${bomId}/quote_preset/attachments/`,
    quoteAttachmentConfiguration: (bomId: string) => `/bom/${bomId}/quote_preset/attachments/configuration/is_enable/`,
    quoteAttachmentDelete: (bomId: string, attachmentId: number) => `/bom/${bomId}/quote_preset/attachments/${attachmentId}`,

    //pushToBoms
    pushToBoms: (bomId: string, sectionName: string, subSectionName: string) => `/bom/${bomId}/quote_preset/${sectionName}/apply_to_all/${subSectionName ? `${subSectionName}/` : ''}
    `
};

// Manufacturer TOC apis

export const getManufacturers = async (bomId: string) => {
    return axios.get<StoredManufacturerPairResponse[]>(endPoints.getManufacturers(bomId));
};

export const addManufacturer = async (data: { bomId: string; payload: ManufacturerPair }) => {
    const { bomId, payload } = data;
    return axios.post<StoredManufacturerPairResponse>(endPoints.addManufacturer(bomId), payload);
};

export const updateManufacturer = async (data: { bomId: string; mfgId: number; payload: ManufacturerPair }) => {
    const { bomId, mfgId, payload } = data;
    return axios.patch<{ message: string }>(endPoints.updateManufacturer(bomId, mfgId), payload);
};

export const deleteManufacturer = async (data: { bomId: string; mfgId: number }) => {
    const { bomId, mfgId } = data;
    return axios.delete<{ message: string }>(endPoints.updateManufacturer(bomId, mfgId));
};
export const updateManufacturerToggleData = async (data: { bomId: string; payload: TogglePayload; fieldName: string }) => {
    const { bomId, payload } = data;
    return axios.patch<{ message: string }>(endPoints.updateManufacturerToggle(bomId), payload);
};
// Header footer apis

export const getHeaderDocumentLogo = (bomId: string) => axios.get<Omit<ILogoResponse, 'fieldName'>>(endPoints.headerDocumentLogo(bomId));

export const deleteHeaderDocumentLogo = async (bomId: string) => axios.delete<Omit<ILogoResponse, 'fieldName'>>(endPoints.headerDocumentLogo(bomId));

export const updateHeaderDocumentLogo = async (data: Omit<IUploadLogo, 'fieldName'>) => {
    const { bomId, logo, source } = data;
    if (logo) {
        return axios.patch<ILogoResponse>(endPoints.headerDocumentLogo(bomId), logo);
    }
    return axios.patch<ILogoResponse>(endPoints.headerDocumentLogo(bomId), { source });
};

export const getHeaderFooterPresetData = async (bomId: string) => {
    return axios.get<IQueryResponse[]>(endPoints.headerFooterData(bomId));
};

export const updateHeaderFooterToggleData = async ({ bomId, fieldName, is_active }: IUpdateToggleParams) => {
    return axios.patch(endPoints.updateHeaderFooterToggle(bomId, fieldName), { is_active });
};
export const updateHeaderFooterTextData = async ({ bomId, fieldName, field_value }: IUpdateText) =>
    axios.patch<{ message: string }>(endPoints.updateHeaderFooterText(bomId, fieldName), {
        field_value:
            fieldName === 'quote_number' || fieldName === 'addressed_to'
                ? field_value?.value
                    ? { pre_fill: field_value.pre_fill, value: field_value.value }
                    : { pre_fill: field_value.pre_fill }
                : field_value
    });

/** Quote preset api (called at the very begining, and once this call is successfull only then the other API calls will proceed) */
export const createQuotePreset = ({ bomId, timezone }: { bomId: string; timezone: string }) => axios.post<{ message: 'preset created' }>(endPoints.quotePreset(bomId), { timezone });

/** Quote pdf preview */

export const getQuotePdfUrl = (bomId: string) => axios.post<{ url: string }>(endPoints.quotePdfUrl(bomId));

/** PRICE AND LEAD TIME */
export const getPriceAndLeadTimeData = (bomId: string) => axios.get<IQueryResponse[]>(endPoints.priceAndLeadTimeData(bomId));

export const updatePriceAndLeadTimeToggle = ({ bomId, fieldName, is_active }: IUpdateToggleParams) =>
    axios.patch<{ message: string }>(endPoints.priceAndLeadTimeToggle(bomId, fieldName), { is_active });

export const updatePriceAndLeadTimeText = ({ bomId, fieldName, field_value }: IUpdateText) => axios.patch<{ message: string }>(endPoints.priceAndLeadTimeText(bomId, fieldName), { field_value });

export const getPriceAndLeadTimeOptionsPreset = (bomId: string) => axios.get<IQueryResponse[]>(endPoints.priceAndLeadTimeOptionsPreset(bomId));

export const updatePriceAndLeadTimeOptions = ({ bomId, fieldName, is_active }: IUpdateToggleParams) => axios.patch(endPoints.priceAndLeadTimeOptionsUpdate(bomId, fieldName), { is_active });

export const updatePriceAndLeadTimeOptionsText = ({ bomId, fieldName, field_value }: IUpdateText) =>
    axios.patch<{ message: string }>(endPoints.priceAndLeadTimeOptionsText(bomId, fieldName), { field_value });

/** COVER PAGE */
export const getCoverPageData = (bomId: string) => axios.get<IQueryResponse[]>(endPoints.coverPagePresetData(bomId));

export const updateCoverPageToggle = ({ bomId, fieldName, is_active }: IUpdateToggleParams) => axios.patch<{ message: string }>(endPoints.coverPageToggle(bomId, fieldName), { is_active });

export const updateCoverPageText = ({ bomId, fieldName, field_value }: IUpdateText) => axios.patch<{ message: string }>(endPoints.coverPageText(bomId, fieldName), { field_value });

export const getCoverPageLogo = (bomId: string, fieldName: string) => axios.get<ILogoResponse>(endPoints.coverPageLogo(bomId, fieldName));

export const updateCoverPageLogo = ({ bomId, fieldName, logo, source }: IUploadLogo) => {
    if (logo) {
        return axios.patch<ILogoResponse>(endPoints.coverPageLogo(bomId, fieldName), logo);
    }
    return axios.patch<ILogoResponse>(endPoints.coverPageLogo(bomId, fieldName), { source });
};

export const deleteCoverPageLogo = ({ bomId, fieldName }: { bomId: string; fieldName: string }) => axios.delete<ILogoResponse>(endPoints.coverPageLogo(bomId, fieldName));

/** General terms and condition */
export const getGeneralTermsAndCondition = (bomId: string) => axios.get<IQueryResponse[]>(endPoints.generalTermsAndCondition(bomId));

export const updateGeneralTermsAndConditionToggle = ({ bomId, fieldName, is_active }: IUpdateToggleParams) =>
    axios.patch<{ message: string }>(endPoints.generalTermsAndConditionConfiguration(bomId, fieldName), { is_active });

export const updateGeneralTermsAndConditionText = ({ bomId, fieldName, field_value }: IUpdateText) =>
    axios.patch<{ message: string }>(endPoints.generalTermsAndConditionText(bomId, fieldName), { field_value });

/** Attachments */
export const getQuoteAttachmentsData = (bomId: string) => axios.get<any>(endPoints.quoteAttachmentsData(bomId));

export const updateQuoteAttachmentToggle = ({ bomId, payload }: { bomId: string; payload: { is_enable: boolean } }) =>
    axios.patch<{ message: string }>(endPoints.quoteAttachmentConfiguration(bomId), payload);

export const updateQuoteAttachmentDocuments = (payload: { bomId: string; data: IAttachmentDocumentRequest[] }) =>
    axios.post<{ message: string }>(endPoints.quoteAttachmentsData(payload.bomId), payload.data);

export const deleteAttachment = ({ bomId, attachmentId }: { bomId: string; attachmentId: number }) => axios.delete<{ message: string }>(endPoints.quoteAttachmentDelete(bomId, attachmentId));

export const pushChangesToBoms = ({ bomId, sectionName, subSectionName }: PushChangesRequest) => axios.patch<{ message: string }>(endPoints.pushToBoms(bomId, sectionName, subSectionName));
