import { useCallback, useEffect, useRef, useState } from 'react';

import { ColumnDirective, SearchSettingsModel } from '@syncfusion/ej2-react-treegrid';

import { BodyXS, BodySmall, Box, Button, H5, Link, Snackbar, Status, Table, ToolbarType, Tooltip, IconButton, AddIcon, CloseIcon, CheckIcon } from '@parspec/pixel';

import { useAddMfgToCompanyMutation, useParspecMfg, useRemoveMfgFromCompanyMutation } from '../queries';
import { getMfgStatusColor } from '../../../shared/constants';
import { ParspecMfgDetailsResponse } from '../queries/apiTypes';
import { useGetUserProfileInfoQuery } from '../../MyProfile/queries';
import EditManufacturers from '../EditManufacturers';

const getTableProps = (args: any) => {
    const toolBarItems: ToolbarType = ['search', 'selectedItems'];
    const searchSettings: SearchSettingsModel = {
        fields: ['parspec_id', 'website', 'status'],
        hierarchyMode: 'Both'
    };
    return {
        allowFiltering: false,
        toolBarOptions: toolBarItems,
        allowPaging: true,
        allowRowDragAndDrop: false,
        ...args,
        searchSettings
    };
};
export const Catalog = () => {
    const { data: mfgData, isFetching } = useParspecMfg();
    const { data: userProfile, isFetching: adminFetching } = useGetUserProfileInfoQuery();
    const { mutateAsync: addRepresentedMfg, isLoading: addLoading } = useAddMfgToCompanyMutation();
    const { mutateAsync: removeRepresentedMfg, isLoading: removeLoading } = useRemoveMfgFromCompanyMutation();

    const tableRef = useRef<any>();

    const [selectedItems, setSelectedItems] = useState<ParspecMfgDetailsResponse[]>([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [tableKey, setTableKey] = useState(0);
    const [openVerifyUploadDialog, setOpenVerifyUploadDialog] = useState(false);

    const onCheckboxChange = (args: ParspecMfgDetailsResponse[]) => {
        setSelectedItems([...args]);
    };

    useEffect(() => {
        if (userProfile?.data && mfgData?.data) {
            if (tableKey === 0) setTableKey(1);
        }
    }, [userProfile?.data, mfgData?.data]);

    function useHover() {
        const [hovering, setHovering] = useState(false);
        const onHoverProps = {
            onMouseEnter: () => setHovering(true),
            onMouseLeave: () => setHovering(false)
        };
        return { hovering, onHoverProps };
    }

    const getRepresentedContent = useCallback(
        (props: ParspecMfgDetailsResponse) => {
            const { represented, company_group_man_id } = props;
            // This will be refactored with ag grid table changes in this component.
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { hovering, onHoverProps } = useHover();
            if (represented) {
                return (
                    <>
                        {userProfile?.data?.role === 'admin' && (
                            <Tooltip title={'Remove'} placement={'top'}>
                                <IconButton {...onHoverProps} onClick={() => removeRepresented(company_group_man_id)}>
                                    {hovering ? <CloseIcon color={'error'} sx={{ fontSize: 24 }} /> : <CheckIcon color={'success'} sx={{ fontSize: 24 }} />}
                                </IconButton>
                            </Tooltip>
                        )}
                        {userProfile?.data?.role === 'user' && <CheckIcon color={'success'} sx={{ fontSize: 24 }} />}
                    </>
                );
            }
            return (
                <>
                    {userProfile?.data?.role === 'admin' && (
                        <Tooltip title={'Add'} placement={'top'}>
                            <IconButton onClick={() => bulkAddRepresented([props])}>
                                <AddIcon sx={{ fontSize: 24 }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            );
        },
        [userProfile?.data, mfgData?.data]
    );

    const getWebsiteContent = useCallback(
        (props: ParspecMfgDetailsResponse) => {
            const { website } = props;
            return (
                <Link href={website} target="_blank">
                    {website}
                </Link>
            );
        },
        [mfgData?.data]
    );

    const getStatusContent = useCallback(
        (props: ParspecMfgDetailsResponse) => {
            const { status } = props;
            return (
                <Status color={getMfgStatusColor(status)}>
                    <BodyXS color="inherit">{status}</BodyXS>
                </Status>
            );
        },
        [mfgData?.data]
    );

    const removeRepresented = async (company_group_man_id: number) => {
        const payload = {
            company_group_man_ids: [company_group_man_id]
        };
        await removeRepresentedMfg({ payload, catalogTab: true });
        setSnackbarMessage('Company Manufacturers Updated');
    };

    const bulkAddRepresented = async (singleItem?: ParspecMfgDetailsResponse[]) => {
        const manufacturers = singleItem || selectedItems;
        const payload = {
            abbreviation_merge_issue: false,
            data: manufacturers.map((mfg: ParspecMfgDetailsResponse) => {
                return {
                    manufacturer_name: mfg.parspec_id,
                    abbreviations: [],
                    parspec_id: mfg.parspec_id,
                    website: mfg.website,
                    manufacturer_id: mfg.manufacturer_id,
                    preferred_locations: [],
                    status: mfg.status,
                    erp_code: null
                };
            })
        };
        await addRepresentedMfg(payload);
        tableRef?.current?.clearSelection();
        setSnackbarMessage('Company Manufacturers Updated');
    };

    const addToCompanyMfg = () => {
        tableRef?.current?.clearSelection();
        setOpenVerifyUploadDialog(false);
        setSnackbarMessage('Company Manufacturers Updated');
    };

    const toolbarRightSection = (
        <>
            {userProfile?.data?.role === 'admin' && (
                <Box display="flex" width="100%" gap={2} alignItems="center">
                    <Tooltip title={selectedItems.length ? '' : 'Select Manufacturer(s) First'} placement={'top'}>
                        <span>
                            <Button disabled={!selectedItems.length} onClick={() => bulkAddRepresented()}>
                                Add to Company Manufacturers
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            )}
        </>
    );

    const loadFlag = isFetching || removeLoading || addLoading || adminFetching;

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box>
                <Box mt={1} mb={8}>
                    <H5>Parspec Catalog</H5>
                    <BodySmall lines={2}>Search and add manufacturers your organization uses. To request support for a new manufacturer, select ‘Add manually’ under company manufacturers.</BodySmall>
                </Box>
                <Box height={'calc(100vh - 262px)'}>
                    <Table {...getTableProps({ onCheckboxChange })} tableKey={tableKey} data={mfgData?.data || []} loading={loadFlag} ref={tableRef} toolbarRightSection={toolbarRightSection}>
                        {userProfile?.data?.role === 'admin' && <ColumnDirective type="checkbox" width="50" headerTextAlign="Center" textAlign="Center" allowEditing={false} />}
                        <ColumnDirective allowEditing={false} template={getRepresentedContent} headerText="Represented" width="90" textAlign="Center" minWidth="85" />
                        <ColumnDirective allowEditing={false} field="parspec_id" headerText="Manufacturer (Parspec ID)" isPrimaryKey allowSorting={false} />
                        <ColumnDirective allowEditing={false} template={getWebsiteContent} headerText="Website" allowSorting={false} />
                        <ColumnDirective allowEditing={false} template={getStatusContent} headerText="Status" allowSorting={false} width="125" />
                    </Table>
                </Box>
            </Box>
            {openVerifyUploadDialog && (
                <EditManufacturers
                    catalogPopup={true}
                    open={openVerifyUploadDialog}
                    handleDialog={() => setOpenVerifyUploadDialog(false)}
                    confirmAction={addToCompanyMfg}
                    verifyData={selectedItems}
                    actionEdit={false}
                />
            )}
        </>
    );
};
