export const sharedLink = {
  Id: 4753,
  shared_link:
    'https://review.parspec.io/view/646be111a2badef577251ccb453098a3/',
  Is_active: true,
  email_access_list: ['dsds@xyz.com', 'ABC@ABC.COM', 'ARSH@ABC.COM'],
  Can_download: true,
  Who_has_access: 'users',
  password: 'Lovish',
};
