import { PRODUCT_CATEGORY_SET } from '../../../shared/utils/constants';
import { MANUFACTURER_FIELD_CONSTANT, MULTI_SELECT_FIELDS_CONSTANT } from '../setRequirements/constants';
import { ProductRequirementsResponse } from '../setRequirements/queries/apiTypes';
import { ALL_MANUFACTURERS_SEARCH_KEYWORD } from '../shared/constants';
import { ManufacturerInfo, ManufacturersInfoType } from '../shared/types';

export function getProductRequirementsForUi(manufacturersInfo: ManufacturersInfoType, productRequirements?: ProductRequirementsResponse) {
    if (!productRequirements) {
        return [];
    }
    const requirementInfoForUi = productRequirements.map((requirement) => {
        let fieldValue = requirement.field_value;
        if (requirement.field_name === MANUFACTURER_FIELD_CONSTANT && Array.isArray(fieldValue) && fieldValue.length === 1 && fieldValue[0] === ALL_MANUFACTURERS_SEARCH_KEYWORD) {
            fieldValue = manufacturersInfo.map((manufacturerInfo) => manufacturerInfo.label);
        } else if (requirement.field_name === MANUFACTURER_FIELD_CONSTANT && Array.isArray(fieldValue)) {
            const manufacturerInfoMap = manufacturersInfo.reduce((manufacturerInfoMap, manufacturerInfo) => {
                manufacturerInfoMap.set(manufacturerInfo.value, manufacturerInfo);
                return manufacturerInfoMap;
            }, new Map<string, ManufacturerInfo>());
            fieldValue = (requirement.field_value as string[])
                .map((mfgUniqueId) => {
                    const manufacturerInfo = manufacturerInfoMap.get(mfgUniqueId);
                    return manufacturerInfo?.label || '';
                })
                .filter((manufacturer) => Boolean(manufacturer));
        } else if (requirement.field_name === MULTI_SELECT_FIELDS_CONSTANT.CATEGORY && Array.isArray(fieldValue)) {
            fieldValue = (requirement.field_value as string[]).filter((categoryVal) => PRODUCT_CATEGORY_SET.has(categoryVal));
        }

        return { fieldValue: fieldValue, fieldName: requirement.field_name, isLocked: requirement.is_locked };
    });

    return requirementInfoForUi;
}
