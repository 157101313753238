import { useMutation } from '@tanstack/react-query';

import { patchBulkEdit } from './apis';
import { invalidatePricingBodQuery } from '../../../queries';
import { invalidateBomDataQuery } from '../../../../queries';
import { invalidaLotQueries } from '../../../lotPriceModal/queries';

export const useBulkEditPatchMutation = () =>
    useMutation(patchBulkEdit, {
        onSuccess: (_data, variables) => {
            const { bomId } = variables;
            invalidaLotQueries(String(bomId));
            invalidateBomDataQuery(bomId);
            invalidatePricingBodQuery(bomId);
        }
    });
