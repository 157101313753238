import { RangeSliderValType } from './types';

export enum MULTI_SELECT_ACTIONS {
    UPDATE = 'UPDATE',
    LOCK = 'LOCK',
    CLEAR = 'CLEAR',
    INIT_SET = 'INIT_SET',
    UPDATE_ERROR = 'UPDATE_ERROR'
}

export enum SLIDER_ACTIONS {
    UPDATE = 'UPDATE',
    LOCK = 'LOCK',
    CLEAR = 'CLEAR',
    UPDATE_MIN_MAX = 'UPDATE_MIN_MAX',
    INIT_SET = 'INIT_SET'
}

export const MANUFACTURER_FIELD_CONSTANT = 'acceptable_manufacturers';

export const MULTI_SELECT_FIELDS_CONSTANT = {
    CATEGORY: 'product_category',
    MOUNTING_TYPE: 'mounting_type',
    ENVIRONMENT: 'environment',
    DIMMING_PROTOCOL: 'dimming_protocol',
    DIMMING_RANGE: 'dimming_range',
    VOLTAGE: 'voltage',
    APPLICATION: 'application'
} as const;

export const SLIDERFIELD_CONSTANT = {
    LUMENS: 'lumens',
    CCT: 'temperature',
    CRI: 'cri'
} as const;

export const SELECT_FIELD_CONSTANT = {
    LUMENS_UNIT: 'lumens_unit',
    CCT_UNIT: 'temperature_unit'
} as const;

export const ADDITIONAL_CRITERIA_CONSTANT = 'additional_criteria';

export const MULTI_SELECT_INIT_STATE = {
    values: [],
    locked: false,
    fieldId: 0,
    error: false
};

export const SLIDER_INIT_STATE: RangeSliderValType = {
    values: [20, 80],
    locked: false,
    minVal: 0,
    maxVal: 100,
    fieldId: 0
};

export const ERROR_MESSAGES = {
    NOT_ENOUGH_ATTRIBUTE: 'Please enter at least one acceptable manufacturer, and one attribute before triggering a product search.'
};

export enum MANUFACTURER_GROUPS {
    COMPANY_PREFERRED = -1,
    ALL_PARSPEC_PREFERRED = -2
}

export const MANUFACTURER_GROUP_FOR_MULTI_SELECT = [
    { value: MANUFACTURER_GROUPS.COMPANY_PREFERRED, label: 'Company Manufacturers' },
    { value: MANUFACTURER_GROUPS.ALL_PARSPEC_PREFERRED, label: 'All Parspec Supported Manufacturers' }
];

export const ADDITIONAL_CRITERIA = 'additional_criteria';

export const ACCEPTED_REFERENCE_IMAGE_TYPE = ['.jpg', '.png'];

export const SUPPORTED_FILE_TYPE_FOR_REFERENCE_IMAGE = 'Supported file types: PNG, JPG';

export const SEARCH_IMAGES = 'reference_images';

export enum SEARCH_IMAGE_ACTION {
    SELECT = 'SELECT',
    UPLOAD = 'UPLOAD'
}

export const BEST_PRACTICES_URL = 'https://knowledgebase.parspec.io/knowledge/product-finder';
