import { useContext } from 'react';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosResponse } from 'axios';

import { TableMenu } from '../shared/utils';
import { copyMsg, DOWNLOAD_FAILED_MSG } from '../../shared/constants';
import { MenuContext } from './context';
import { DownloadByVersionIdTypes } from '../../BOM/FinalDocuments/queries/apiTypes';
import { DocType, SnackbarMessages } from '../../BOM/FinalDocuments/constants';
import { useDownloadMutation } from '../../BOM/FinalDocuments/queries';
import { callProxyURL } from '../../BOM/FinalDocuments/utils/downloadFile';

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
};

export function MenuOption(props: ICellRendererParams) {
    const { data } = props;

    const docLink = data?.shareLink ? data.shareLink : '';

    const { isActiveSubscription, setSnackbarMessage, handleSubscriptionPopup } = useContext(MenuContext);

    const { mutateAsync: downloadMutation } = useDownloadMutation();

    const handleDownload = async (bomId: string, documentId: number, fileType?: DownloadByVersionIdTypes['type']) => {
        try {
            setSnackbarMessage(SnackbarMessages.DOWNLOADING);
            const response: AxiosResponse = await downloadMutation({ bomId, id: documentId || 0, type: fileType ? fileType : 'flattened', documentType: DocType.SUBMITTAL });
            await callProxyURL(response?.data?.url, fileType ? fileType : 'pdf', response?.data?.filename);
            setSnackbarMessage(SnackbarMessages.DOWNLOAD_SUCCESS);
        } catch (e: any) {
            setSnackbarMessage(DOWNLOAD_FAILED_MSG);
        }
    };

    const options: Array<{ label: string; onClick: () => void; color?: string }> = [
        {
            label: 'Copy Link',
            onClick: () => {
                if (isActiveSubscription) {
                    navigator.clipboard.writeText(docLink);
                    setSnackbarMessage(copyMsg);
                } else handleSubscriptionPopup();
            }
        },
        {
            label: 'Download',
            onClick: () => handleDownload(data.bomId, data.submittalId)
        }
    ];

    return <TableMenu options={options} />;
}
