import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Autocomplete, BodyMedium, Grid, SelectChangeEvent, Switch } from '@parspec/pixel';

import SwitchTextFiled from '../../shared/SwitchTextField';
import SwitchAddress from '../../shared/SwitchAddress';
import SwitchDatePicker from '../../shared/SwitchDatePicker';
import PreviewContext from '../../Context';

import { CoverPageTextField, TransformedCoverPageToggleConfigurationTypes, UpdateTextFieldProps } from '../../queries/apiTypes';

import { useDocumentTextFieldMutation, useDocumentToggleConfigurationMutation } from '../../queries';
import { ENTITY } from '../../../constants';
import TokenForFields from 'src/features/BOM/FinalDocuments/Compile/shared/TokenForFields';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { OPTIONS_FOR_NAME } from '../../../shared/utils/constants';

interface GeneralInformation {
    generalInfo: CoverPageTextField;
    toggleConfigData?: TransformedCoverPageToggleConfigurationTypes;
}

const GeneralInformation: React.FC<GeneralInformation> = ({ generalInfo, toggleConfigData }) => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);
    const { mutate: toggleFieldMutation } = useDocumentToggleConfigurationMutation();

    const { mutate: updateTextField } = useDocumentTextFieldMutation();

    const { data: bomData } = useGetBomDetails(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: projectData } = useGetProjectDetailsQuery(Number(bomData?.data.project)!, {
        enabled: Boolean(bomData?.data.project)
    });

    const [dateSwitch, setDateSwitch] = useState<boolean>();
    const [primaryTitle, setPrimaryTitle] = useState<boolean>();
    const [secondaryTitle, setSecondaryTitle] = useState<boolean>();
    const [isProjectNumber, setIsProjectNumber] = useState<boolean>();
    const [isQuoteNumber, setIsQuoteNumber] = useState<boolean>();
    const [isAddress, setIsAddress] = useState<boolean>();

    const [secondaryTitleGenerationRule, setSecondaryTitleGenerationRule] = useState<string>('');

    const [primaryTitleGenerationRule, setPrimaryTitleGenerationRule] = useState<string>('');

    const [primaryTitleText, setPrimaryTitleText] = useState<string>('');

    const [secondaryTitleText, setSecondaryTitleText] = useState<string>('');

    const [quoteValue, setQuoteValue] = useState<string>('');

    useEffect(() => {
        setDateSwitch(toggleConfigData?.date);
        setPrimaryTitle(toggleConfigData?.primary_title);
        setSecondaryTitle(toggleConfigData?.secondary_title);
        setIsProjectNumber(toggleConfigData?.project_number);
        setIsQuoteNumber(toggleConfigData?.quote_number);
        setIsAddress(toggleConfigData?.project_address);
    }, [toggleConfigData]);

    useEffect(() => {
        //@ts-ignore
        if (generalInfo?.primary_title?.pre_fill === 'Custom Entry') {
            //@ts-ignore
            setPrimaryTitleText(generalInfo?.primary_title?.value);
        }
        //@ts-ignore
        else if (generalInfo?.primary_title?.pre_fill === 'Project Name') {
            setPrimaryTitleText(projectData?.data?.name || '');
        } else {
            setPrimaryTitleText(bomData?.data?.name || '');
        }

        //@ts-ignore
        if (generalInfo?.secondary_title?.pre_fill === 'Custom Entry') {
            //@ts-ignore
            setSecondaryTitleText(generalInfo?.secondary_title?.value);
        }
        //@ts-ignore
        else if (generalInfo?.secondary_title?.pre_fill === 'Project Name') {
            setSecondaryTitleText(projectData?.data?.name || '');
        } else {
            setSecondaryTitleText(bomData?.data?.name || '');
        }

        //@ts-ignore
        setPrimaryTitleGenerationRule(generalInfo?.primary_title?.pre_fill);
        //@ts-ignore
        setSecondaryTitleGenerationRule(generalInfo?.secondary_title?.pre_fill);
        setQuoteValue(generalInfo?.quote_number?.value);
    }, [generalInfo, projectData?.data, bomData?.data]);

    const handleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateSwitch(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'date',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handlePrimaryTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrimaryTitle(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'primary_title',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleSecondaryTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondaryTitle(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'secondary_title',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleProjectNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsProjectNumber(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'project_number',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleQuoteNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsQuoteNumber(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'quote_number',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleProjectAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAddress(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: 'project_address',
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleSecondaryTitleNumberGenerationRule = (event: SelectChangeEvent<unknown>) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'secondary_title',
            field_value: '',
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'cover_page'
        };
        if (event.target.value === 'Project Name') {
            setSecondaryTitleText(projectData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else if (event.target.value === 'BOM Name') {
            setSecondaryTitleText(bomData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else {
            setSecondaryTitleText(secondaryTitleGenerationRule === 'BOM Name' ? bomData?.data.name || '' : projectData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value as string, value: secondaryTitleGenerationRule === 'BOM Name' ? bomData?.data.name : projectData?.data.name };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        }
        setSecondaryTitleGenerationRule(event.target.value as string);
    };

    const handlePrimaryTitleGenerationRule = (event: SelectChangeEvent<unknown>) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'primary_title',
            field_value: '',
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'cover_page'
        };
        if (event.target.value === 'Project Name') {
            setPrimaryTitleText(projectData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else if (event.target.value === 'BOM Name') {
            setPrimaryTitleText(bomData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else {
            setPrimaryTitleText(primaryTitleGenerationRule === 'BOM Name' ? bomData?.data.name || '' : projectData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value as string, value: primaryTitleGenerationRule === 'BOM Name' ? bomData?.data.name : projectData?.data.name };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        }
        setPrimaryTitleGenerationRule(event.target.value as string);
    };

    const handleOnBlurPrimaryTitle = (val: string) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'primary_title',
            field_value: { pre_fill: 'Custom Entry', value: val },
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'cover_page'
        };
        updateTextField(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };

    const handleOnBlurSecondaryTitle = (val: string) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'secondary_title',
            field_value: { pre_fill: 'Custom Entry', value: val },
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'cover_page'
        };
        updateTextField(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };

    const handleChangeQuoteNumber = (e: React.SyntheticEvent<Element, Event>) => {
        const target = e?.target as HTMLInputElement;
        const value = target?.value;
        if (value === null) {
            handleOnBlurQuotesNumber('');
        }
    };

    const handleOnBlurQuotesNumber = (args: string | { [index: string]: string | number }) => {
        const payload: UpdateTextFieldProps = {
            bomId: bomId!,
            fieldName: 'quote_number',
            field_value: { pre_fill: 'Custom Entry', value: typeof args === 'string' ? args : args.value, options: generalInfo?.quote_number.options },
            documentType: documentType!,
            entity: 'cover_page'
        };
        updateTextField(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };
    const handleQuoteField = (_e: React.SyntheticEvent<Element, Event>, value: string) => {
        setQuoteValue(value);
    };
    return (
        <Grid container direction={'column'}>
            <Grid item>
                <BodyMedium color={'secondary.main'} fontWeight={500}>
                    General Information
                </BodyMedium>
            </Grid>

            {/* Date  */}

            <Grid item>
                <SwitchDatePicker fieldName="date" textValue={generalInfo?.date} checked={dateSwitch} switchLabel="Date" action={handleDate} />
            </Grid>

            {/* Primary Title */}

            <Grid item my={1}>
                <TokenForFields
                    toggleName="primary_title"
                    options={OPTIONS_FOR_NAME}
                    fieldValue={primaryTitleText}
                    handleFieldGenerationRule={handlePrimaryTitleGenerationRule}
                    selectedOption={primaryTitleGenerationRule}
                    handleOnBlur={handleOnBlurPrimaryTitle}
                    showTextAndInput
                    maxLength={128}
                    onToggle={handlePrimaryTitle}
                    toggleLabel="Primary Title"
                    isToggled={primaryTitle}
                />
            </Grid>

            {/* secondary title */}
            <Grid item my={2}>
                <TokenForFields
                    options={OPTIONS_FOR_NAME}
                    fieldValue={secondaryTitleText}
                    handleFieldGenerationRule={handleSecondaryTitleNumberGenerationRule}
                    selectedOption={secondaryTitleGenerationRule}
                    handleOnBlur={handleOnBlurSecondaryTitle}
                    showTextAndInput
                    maxLength={128}
                    isToggled={secondaryTitle}
                    toggleLabel="Secondary Title"
                    onToggle={handleSecondaryTitle}
                    toggleName="secondary_title"
                />
            </Grid>

            {/* Project Number  */}

            <Grid item>
                <SwitchTextFiled
                    checked={isProjectNumber}
                    switchLabel={'Project Number'}
                    action={handleProjectNumber}
                    placeholder={'Project Number'}
                    textValue={generalInfo?.project_number}
                    fieldName={'project_number'}
                    maxLength={50}
                />
            </Grid>

            {/* Quote Number */}

            <Grid item>
                <Grid container>
                    <Grid item xs={4}>
                        <Switch checked={isQuoteNumber} label={'Quote Number'} onChange={handleQuoteNumber} />
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            value={quoteValue}
                            options={generalInfo?.quote_number?.options}
                            onChange={handleChangeQuoteNumber}
                            onBlur={handleOnBlurQuotesNumber}
                            label={''}
                            id="quoteNumber"
                            placeholder="Quote Number"
                            optionlabelkeyname="value"
                            freeSolo
                            onTextFieldChange={handleQuoteField}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <SwitchAddress checked={isAddress} switchLabel="Project Address" action={handleProjectAddress} address={generalInfo?.project_address} fieldName={'project_address'} />
            </Grid>
        </Grid>
    );
};

export default GeneralInformation;
