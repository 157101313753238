import { Box, Skeleton } from '@parspec/pixel';

export const SelectProductSkelton = () => {
    return (
        <>
            <Box display={'flex'} flexDirection={'column'} gap={'4px'} minWidth={'fit-content'} bgcolor={'#F8FAFC'} marginLeft={'5px'} key={1}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Box display={'flex'} flexDirection={'column'} width={'522px'} marginLeft={'17px'}>
                        <Box display={'flex'} alignItems={'center'} height={'65px'}>
                            <Skeleton variant="rectangular" height="26px" width={'164px'}></Skeleton>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={'2px'} minWidth={'522px'}>
                            <Skeleton variant="rectangular" height="536px" width={'522px'} />
                            <Skeleton variant="rectangular" height="52px" width={'522px'} />
                            {[...new Array(2)].map((_, index: number) => (
                                <Box display={'flex'} gap={'2px'} key={index}>
                                    <Skeleton variant="rectangular" height="51px" width={'522px'} />
                                    <Skeleton variant="rectangular" height="51px" width={'522px'} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} marginLeft={'17px'} width={'100%'}>
                        <Box display={'flex'} alignItems={'center'} height={'65px'} width={'100%'} pr={6}>
                            <Skeleton variant="rectangular" height="26px" width={'100%'}></Skeleton>
                        </Box>
                        <Box display={'flex'} gap={'2px'}>
                            {[...new Array(2)].map((_, index: number) => (
                                <Box display={'flex'} flexDirection={'column'} gap={'2px'} key={index}>
                                    <Skeleton variant="rectangular" height="536px" width={'308px'} />
                                    <Skeleton variant="rectangular" height="52px" width={'308px'} />

                                    {[...new Array(2)].map((_, index: number) => (
                                        <Skeleton variant="rectangular" height="51px" width={'308px'} key={index} />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
