import { Button, IconButton, LockIcon, LockOpenIcon, Box, Tooltip } from '@parspec/pixel';

interface FormFieldActions {
    isLocked?: boolean;
    onLockChange?: (isLocked: boolean) => void;
    onClear?: () => void;
    isLockDisabled?: boolean;
    showClear: boolean;
    isDisabled?: boolean;
    isClearDisabled?: boolean;
    tooltip?: string;
}

export function FormFieldAction({ isLocked = false, onLockChange, onClear, isLockDisabled = false, showClear, isDisabled = false, isClearDisabled = false, tooltip }: FormFieldActions) {
    function getIconComponent() {
        return (
            <Box>
                <IconButton onClick={() => onLockChange?.(!isLocked)} disabled={isDisabled || isLockDisabled}>
                    {isLocked ? <LockIcon color="tertiary"></LockIcon> : <LockOpenIcon color="neutral"></LockOpenIcon>}
                </IconButton>
            </Box>
        );
    }
    return (
        <>
            <Box display="flex" alignItems="center" px={2}>
                {isLocked && tooltip ? <Tooltip title={tooltip}>{getIconComponent()}</Tooltip> : getIconComponent()}
            </Box>
            {showClear && onClear && (
                <Box display="flex" alignItems="center">
                    <Button size="small" variant="text" color="primary" onClick={onClear} disabled={isDisabled || isClearDisabled}>
                        Clear
                    </Button>
                </Box>
            )}
        </>
    );
}
