import * as FileSaver from 'file-saver';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';

export function downloadZip(fileUrl: string, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([fileUrl]));
    const link = document.createElement('a');
    link.href = url;
    if (fileName === 'submittal') {
        link.setAttribute('download', `${fileName}.pdf`);
    } else {
        link.setAttribute('download', `${fileName}.zip`);
    }

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
}

const FILE_FORMATS = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml',
    csv: 'text/csv',
    xls: 'application/vnd.ms-excel',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    zip: 'application/zip',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    eml: 'message/rfc822',
    msg: 'application/vnd.ms-outlook'
};

export const callProxyURL = async (data: string, _type: string, filename?: string) => {
    const proxy_file = getProxyFileUrl(data);
    // const fileName = filename;

    let fileName = filename;

    if (_type === 'msg') {
        fileName = `${fileName}.${_type}`;
    } else {
        fileName = filename;
    }

    const response = await fetch(proxy_file);
    if (response.ok) {
        const blob = await response.blob();
        const fileType = FILE_FORMATS[_type as keyof typeof FILE_FORMATS];
        const file = new Blob([blob], { type: `${fileType}` });
        FileSaver.saveAs(file, fileName);
    }
};
