import { useMutation, useQuery, UseMutateAsyncFunction } from '@tanstack/react-query';

import { AxiosError, AxiosResponse } from 'axios';

import { ProdRequirementPostArgType, endpoints, getProductRequirements, postProductRequirementsSearch, patchProductRequirements, postExtractAttributes, postProductRequirements } from './api';
import { queryClient } from 'src/app/queryClient';
import { ProductRequirementsResponse } from './apiTypes';
import { invalidateGetProductDetailsQuery } from '../../createProduct/queries';
import { getParsecMfgListAndMfgQueryData } from 'src/features/Settings/Manufacturer/queries';
import { MANUFACTURER_FIELD_CONSTANT } from '../constants';

const getProductRequirementQueryKey = (bomId?: number, productId?: number) => [endpoints.getProductRequirementsUrl(bomId, productId)];

function invalidateProductRequirementQuery(bomId?: number, productId?: number) {
    queryClient.invalidateQueries(getProductRequirementQueryKey(bomId, productId), { exact: true });
}

export const useProductRequirementGetQuery = (
    bomId?: number,
    productId?: number,
    mutateProductRequirements?: UseMutateAsyncFunction<AxiosResponse<ProductRequirementsResponse, any>, unknown, ProdRequirementPostArgType, unknown>
) => {
    return useQuery(getProductRequirementQueryKey(bomId, productId), () => getProductRequirements(bomId, productId), {
        enabled: Boolean(bomId) && Boolean(productId),
        staleTime: 0,
        onError: (error: AxiosError) => {
            if (error.response?.status === 404 && mutateProductRequirements) {
                mutateProductRequirements({ bomId: bomId || 0, productId: productId || 0 });
            }
        }
    });
};

export const useProductRequirementQuery = (bomId?: number, productId?: number) => {
    const { mutateAsync: mutateProductRequirements, isLoading: isPostReqLoading } = useProductRequirementPostMutation();
    const productReqQuery = useProductRequirementGetQuery(bomId, productId, mutateProductRequirements);
    return {
        ...productReqQuery,
        isPostReqLoading: isPostReqLoading
    };
};

export const useProductRequirementPatchMutation = () => {
    return useMutation(patchProductRequirements, {
        onSuccess: (_, context) => {
            const { bomId, productId, payload } = context;

            if (payload.field_name === 'attribute_overwrite_popup') {
                queryClient.setQueryData(getProductRequirementQueryKey(bomId, productId), (oldData: any) => {
                    const newData = oldData?.data.map((el: any) => {
                        return el.field_name === 'attribute_overwrite_popup' ? { ...el, field_value: payload.field_value } : el;
                    });
                    return { ...oldData, data: newData };
                });
            }
        }
    });
};

const getStarredManufacturerNames = () => {
    const { mfgListQueryData, mfgQueryData } = getParsecMfgListAndMfgQueryData();

    const starredManufacturerNamesArray: Array<string> = [];
    if (mfgQueryData?.data && mfgListQueryData?.data) {
        const starredMfgList = mfgListQueryData.data.find((list) => list.is_starred);
        if (starredMfgList) {
            const manufacturerIds = starredMfgList.company_group_man_id;
            const starredMaufactuerers = mfgQueryData.data.filter((mfgObj) => manufacturerIds.includes(mfgObj.company_group_man_id));
            if (Array.isArray(starredMaufactuerers) && starredMaufactuerers.length) {
                starredMaufactuerers.forEach((mfgObj) => {
                    starredManufacturerNamesArray.push(mfgObj.unique_mfg_id);
                });
            }
        }
    }
    return starredManufacturerNamesArray;
};

export const useProductRequirementPostMutation = () => {
    const { mutateAsync: updateProductRequirement } = useProductRequirementPatchMutation();
    return useMutation(postProductRequirements, {
        onSuccess: async (data, context) => {
            const { bomId, productId } = context;
            const starredManufacturerNames = getStarredManufacturerNames();
            const fieldId = data?.data.find((requirementInfo) => requirementInfo.field_name === MANUFACTURER_FIELD_CONSTANT)?.id;
            if (fieldId) {
                await updateProductRequirement({
                    payload: {
                        id: fieldId,
                        field_name: MANUFACTURER_FIELD_CONSTANT,
                        field_value: starredManufacturerNames
                    },
                    bomId,
                    productId
                });

                if (data?.data) {
                    const updatedProductRequirementData = data.data.map((req) => (req.field_name === MANUFACTURER_FIELD_CONSTANT ? { ...req, field_value: starredManufacturerNames } : { ...req }));
                    queryClient.setQueryData(getProductRequirementQueryKey(bomId, productId), { ...data, data: updatedProductRequirementData });
                }
            }
        }
    });
};

export const useSearchProductRequirementsMutation = () => {
    return useMutation(postProductRequirementsSearch, {
        onSuccess: (_data, variables) => {
            const { bomId = 0, productId = 0 } = variables;
            invalidateGetProductDetailsQuery(String(bomId), String(productId));
        }
    });
};

export const useExtractRequirementsMutation = () => {
    return useMutation(postExtractAttributes, {
        onSuccess: (_data, context) => {
            const { bomId, productId } = context;
            invalidateProductRequirementQuery(bomId, productId);
        }
    });
};
