import { useEffect } from 'react';
import * as React from 'react';

import { BodyBig, BodySmall, BodyXS, Box, CloseIcon, FileSelector, H6, Grid, IconButton, InfoIcon, Tooltip, Skeleton } from '@parspec/pixel';

import { BranchBrandingInterface } from '../AddEditLocation.types';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { BranchDetailsReponse } from '../../queries/apiTypes';
import { CompanyDetailsForAddLocationInterface } from '../AddEditLocation.types';

interface BranchBrandingProps {
    companyDetails?: BranchDetailsReponse | CompanyDetailsForAddLocationInterface;
    companyDetailsLoading: boolean;
    branchBrandingTemplate: BranchBrandingInterface;
    setBranchBrandingTemplate: React.Dispatch<React.SetStateAction<BranchBrandingInterface>>;
}

const ACCEPTED_FILE_TYPES = ['.png', '.jpg'];

const PRIMARY_LOGO_TOOLTIP_TEXT = 'The primary logo is used as the default image on the cover page and table of contents. Wide logos and wordmarks materialize best as primary logo.';

const SECONDARY_LOGO_TOOLTIP_TEXT =
    'The secondary logo is used as the default image for the ‘prepared by’ section on the cover page, and for the datasheet headers. Square logos materialize best as secondary logo';

const SAMPLE_SUBMITTAL_PREVIEW_FIRST_PARA = 'Logos and colors are used to create documents in no time and maintain consistency across projects of the same branch.';

const SAMPLE_SUBMITTAL_PREVIEW_SECOND_PARA =
    'Below is an example of how your brand will be applied to newly created documents by default. You can always customize the layout during the document creation process.';

const LOGO_TYPES: { label: string; value: 'primary' | 'secondary' }[] = [
    { label: 'Primary Logo', value: 'primary' },
    { label: 'Secondary Logo', value: 'secondary' }
];

const BranchBranding = (props: BranchBrandingProps) => {
    const { companyDetails, branchBrandingTemplate, setBranchBrandingTemplate, companyDetailsLoading } = props;

    const handleLogoSelect = (files: File[], logoType: 'primary' | 'secondary') => {
        if (!files || files.length === 0) {
            return;
        }
        const imgFile = files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imgFile); // read file as data url
        fileReader.onload = () => {
            const imgFileURL = fileReader.result as string;

            if (logoType === 'primary') {
                setBranchBrandingTemplate({ ...branchBrandingTemplate, primary: { ...branchBrandingTemplate.primary, url: imgFileURL, data: imgFile, isChanged: true } });
            }
            if (logoType === 'secondary') {
                setBranchBrandingTemplate({ ...branchBrandingTemplate, secondary: { ...branchBrandingTemplate.secondary, url: imgFileURL, data: imgFile, isChanged: true } });
            }
        };
    };

    const handleDeleteLogo = (logoType: 'primary' | 'secondary') => {
        if (logoType === 'primary') {
            setBranchBrandingTemplate({ ...branchBrandingTemplate, primary: { ...branchBrandingTemplate.primary, url: null, data: null, isChanged: true } });
        }
        if (logoType === 'secondary') {
            setBranchBrandingTemplate({ ...branchBrandingTemplate, secondary: { ...branchBrandingTemplate.secondary, url: null, data: null, isChanged: true } });
        }
    };

    useEffect(() => {
        if (!companyDetails) {
            return;
        }

        setBranchBrandingTemplate({
            ...branchBrandingTemplate,
            primary: { ...branchBrandingTemplate.primary, url: companyDetails.logo },
            secondary: { ...branchBrandingTemplate.secondary, url: companyDetails.picture }
        });
    }, [companyDetails]);

    const primaryImageUrl = branchBrandingTemplate.primary?.isChanged
        ? branchBrandingTemplate.primary?.url
        : branchBrandingTemplate.primary?.url
        ? getProxyFileUrl(branchBrandingTemplate.primary?.url)
        : '';
    const secondaryImageUrl = branchBrandingTemplate.secondary?.isChanged
        ? branchBrandingTemplate.secondary?.url
        : branchBrandingTemplate.secondary?.url
        ? getProxyFileUrl(branchBrandingTemplate.secondary?.url)
        : '';

    return (
        <Box bgcolor="primary.contrastText" min-width={'1000px'}>
            <Box>
                <H6>Branch Branding</H6>
            </Box>
            <Box>
                <Box display="flex " height={'100%'} width={'100%'}>
                    <Box width="25%" minWidth={'250px'}>
                        {LOGO_TYPES.map((logoData) => {
                            return (
                                <Box mt={6} key={logoData.value}>
                                    <Box display="flex " position="relative" alignItems="center">
                                        <BodySmall color="text.secondary" fontWeight={500}>
                                            {logoData.label}
                                        </BodySmall>

                                        <Box>
                                            <Tooltip placement="top" title={logoData.value === 'primary' ? PRIMARY_LOGO_TOOLTIP_TEXT : SECONDARY_LOGO_TOOLTIP_TEXT}>
                                                <Box color={'text.secondary'}>
                                                    <InfoIcon color={'inherit'} sx={{ ml: 1 }} fontSize="small" />
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {!companyDetailsLoading ? (
                                            <Box mt={3} width="250px" height="90px">
                                                {!branchBrandingTemplate[logoData.value].url ? (
                                                    <FileSelector
                                                        borderColor="secondary"
                                                        placeholder="Drop a file or click to browse"
                                                        onSelect={(files: any) => handleLogoSelect(files, logoData.value)}
                                                        onDeleteFile={() => handleDeleteLogo(logoData.value)}
                                                        acceptedFormats={ACCEPTED_FILE_TYPES}
                                                    />
                                                ) : (
                                                    <Grid
                                                        height={'100%'}
                                                        width={'100%'}
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        rowSpacing={1}
                                                        sx={{
                                                            border: '1px',
                                                            backgroundColor: 'neutral.light',
                                                            borderRadius: '10px',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={() => handleDeleteLogo(logoData.value)}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '0',
                                                                right: '0',
                                                                zIndex: 1,
                                                                backgroundColor: 'neutral.light'
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>
                                                        <Grid item p={2}>
                                                            <Grid container justifyContent="center" alignItems="center">
                                                                <Box mt={1} width={'100%'} height={72} color="secondary.main">
                                                                    <img
                                                                        src={logoData.value === 'primary' ? primaryImageUrl || '' : secondaryImageUrl || ''}
                                                                        alt="Preview"
                                                                        style={{ width: '100%', height: '100%', objectFit: 'contain', verticalAlign: 'middle' }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Box>
                                        ) : (
                                            <Skeleton width={250} height={110} variant="rectangular" sx={{ borderRadius: 1 }} />
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box width="75%" ml={12} minWidth={'595px'}>
                        <Box marginTop="20px" bgcolor="neutral.light">
                            <Box p={4}>
                                <Box>
                                    <BodyBig fontWeight={500} color={'secondary.light'}>
                                        Sample Documents Preview
                                    </BodyBig>
                                    <Box pt={4}>
                                        <BodyXS limit={false} color={'text.secondary'}>
                                            {SAMPLE_SUBMITTAL_PREVIEW_FIRST_PARA} <br />
                                            <br />
                                            {SAMPLE_SUBMITTAL_PREVIEW_SECOND_PARA}
                                        </BodyXS>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box display="flex " position="relative" justifyContent={'space-evenly'} height={'100%'} mt={4}>
                                        <Box width="33%" ml={1} mr={1} display="flex" flexDirection="column" alignItems="center">
                                            <BodySmall color="text.secondary" fontWeight={500} mb={2}>
                                                Cover Page
                                            </BodySmall>
                                            <Box>
                                                <Box width={161} height={209} p={5} border={`1px solid`} borderColor={'neutral.main'} bgcolor={'primary.contrastText'}>
                                                    <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                                        <Box width={'122px'} height={'10px'} bgcolor="neutral.main" marginTop={3}></Box>
                                                        <Box width={'78px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>

                                                        <Box width={'78px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>

                                                        <Box width={'100px'} height={'35px'} marginTop={4}>
                                                            {branchBrandingTemplate.primary.url && (
                                                                <img
                                                                    src={
                                                                        branchBrandingTemplate.primary.isChanged
                                                                            ? branchBrandingTemplate.primary.url
                                                                            : getProxyFileUrl(branchBrandingTemplate.primary.url)
                                                                    }
                                                                    alt="Preview"
                                                                    style={{ width: '100%', height: '100%', objectFit: 'contain', verticalAlign: 'middle' }}
                                                                />
                                                            )}
                                                        </Box>

                                                        <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={6}></Box>
                                                        <Box width={'122px'} height={'4px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                        <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                        <Box width={'122px'} height={'4px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                        <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box width="33%" ml={1} mr={1} display="flex" flexDirection="column" alignItems="center">
                                            <BodySmall color="text.secondary" fontWeight={500} mb={2}>
                                                Table of Contents
                                            </BodySmall>
                                            <Box>
                                                <Box width={161} height={209} p={5} border={`1px solid`} borderColor={'neutral.main'} bgcolor={'primary.contrastText'}>
                                                    <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                                        <Box width={'100px'} height={'35px'} marginTop={4}>
                                                            {branchBrandingTemplate.primary.url && (
                                                                <img
                                                                    src={
                                                                        branchBrandingTemplate.primary.isChanged
                                                                            ? branchBrandingTemplate.primary.url
                                                                            : getProxyFileUrl(branchBrandingTemplate.primary.url)
                                                                    }
                                                                    alt="Preview"
                                                                    style={{ width: '100%', height: '100%', objectFit: 'contain', verticalAlign: 'middle' }}
                                                                />
                                                            )}
                                                        </Box>
                                                        <Box width={'122px'} height={'10px'} bgcolor="neutral.main" marginTop={3}></Box>

                                                        {[...Array(4)].map((_, index) => {
                                                            return (
                                                                <Box key={index}>
                                                                    <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                                    <Box width={'122px'} height={'4px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                                </Box>
                                                            );
                                                        })}

                                                        <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box width="33%" ml={1} mr={1} display="flex" flexDirection="column" alignItems="center">
                                            <BodySmall color="text.secondary" fontWeight={500} mb={2}>
                                                Header & Footer
                                            </BodySmall>
                                            <Box>
                                                <Box width={161} height={209} p={5} border={`1px solid`} borderColor={'neutral.main'} bgcolor={'primary.contrastText'}>
                                                    <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                                        <Box width={'100%'} height={32} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Box mr={1}>
                                                                {branchBrandingTemplate.secondary.url && (
                                                                    <img
                                                                        src={
                                                                            branchBrandingTemplate.secondary.isChanged
                                                                                ? branchBrandingTemplate.secondary.url
                                                                                : getProxyFileUrl(branchBrandingTemplate.secondary.url)
                                                                        }
                                                                        alt="Preview"
                                                                        style={{ width: '20px', height: '20px', objectFit: 'contain', verticalAlign: 'middle' }}
                                                                    />
                                                                )}
                                                            </Box>

                                                            <Box width={'100px'} height={'18px'} mt={1} bgcolor={'neutral.main'} display={'flex'} flex={1}></Box>
                                                        </Box>
                                                        <Box width={'122px'} height={'10px'} bgcolor="ineutral.main" marginTop={3}></Box>

                                                        {[...Array(5)].map((_, index) => {
                                                            return (
                                                                <Box key={index}>
                                                                    <Box width={'122px'} height={'6px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                                    <Box width={'122px'} height={'4px'} bgcolor="neutral.main" marginTop={1}></Box>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default React.memo(BranchBranding);
