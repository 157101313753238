import { Internationalization } from '@syncfusion/ej2-base';

import { BodySmall, Box, TableHeaderMenuIcon } from '@parspec/pixel';

import { CALC_METHODS } from '../shared/constants';
import { ValueFormatterParams } from 'ag-grid-community';
import { getCellAmountFormatter, getCellPercentFormatter } from '../../shared/utils';

export function SumTemplate(props: any) {
    const intl = new Internationalization();
    const valueForUi = intl.formatNumber(props.Sum, { format: 'C2', useGrouping: true, minimumFractionDigits: 2 });
    return (
        <Box px="13px">
            <BodySmall fontWeight="700">{valueForUi}</BodySmall>
        </Box>
    );
}

export function getMagnitudeFormatToDisplay(params: ValueFormatterParams) {
    if (params?.node?.rowPinned) return '';
    if (params.value === null) return '';
    if (params?.data?.calculationMethod === CALC_METHODS.FLAT_FEE) {
        return getCellAmountFormatter(params);
    } else {
        return getCellPercentFormatter(params);
    }
}

export function MenuHeaderTemplate() {
    return (
        <Box mt={1}>
            <TableHeaderMenuIcon color="secondary" fontSize="medium" />
        </Box>
    );
}

export function getTotalTemplate() {
    return (
        <Box position="relative" width="64px" height="30px" display="flex" alignItems="center">
            <BodySmall fontWeight="700" lines={1}>
                Total
            </BodySmall>
        </Box>
    );
}
