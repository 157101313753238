import { rest } from 'msw';

import { serviceGetJson } from './mockGetService';
import { ServicesResponseType } from '../apiTypes';

const serviceMockHandlers = [
    rest.get('*/bom/:bomId/pricing/service/', (_req, res, ctx) => {
        return res(ctx.delay(10000), ctx.json(serviceGetJson));
    }),
    rest.put('*/bom/:bomId/pricing/service/', async (req, res, ctx) => {
        const reqBody = (await (req.json() as Promise<ServicesResponseType>)).map((obj, index) => ({ ...obj, id: index + 1 }));
        return res(ctx.json(reqBody));
    })
];

export { serviceMockHandlers };
