import { rest } from 'msw';
import { activityLogs } from './activityLogs';
import { bomDetails } from './bomDetails';
import { final_submittal } from './finalSubmittal';
import { sharedLink } from './sharedLinkDetails';

export const viewDocumentHandlers = [
    rest.get('*/bom/:bom_id/submittal/:version_id', (req, res, ctx) => {
        console.log(req);
        if (req.params.version_id === 'share_link') {
            return res(ctx.delay(2000), ctx.json(sharedLink));
        } else if (req.params.version_id === 'activity_logs') {
            return res(ctx.delay(2000), ctx.json(activityLogs));
        }
        return res(ctx.delay(2000), ctx.json(final_submittal));
    }),
    rest.get('*/bom/:bom_id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(bomDetails));
    }),
    rest.patch('*/bom/:bom_id/submittal/:version_id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'Success' }));
    })
];
