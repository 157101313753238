import { useState } from 'react';

import * as FileSaver from 'file-saver';

import { BodySmall, Box, FileSelector, FileSelectorFileType, Link, Modal, ModalHeader, Snackbar } from '@parspec/pixel';

import { useBulkUploadMutation } from '../Company/queries';
import { ImportFileResponse } from '../Company/queries/apiTypes';
import { UPLOAD_CONTACTS_CSV_TEMPLATE } from './constant';

interface ImportListDialogProps {
    open: boolean;
    handleDialog: () => void;
    confirmAction: (uploadedData: ImportFileResponse) => void;
}

const BulkImport = (props: ImportListDialogProps) => {
    const { open, handleDialog, confirmAction } = props;

    const [fileRequiredError, setFileRequiredError] = useState<string | null>(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { mutateAsync: uploadFile, isLoading } = useBulkUploadMutation();

    const downloadTemplate = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const contentType = 'text/csv';
        setSnackbarMessage('The download has started ! Check your Downloads folder');
        const csvFile = new Blob([UPLOAD_CONTACTS_CSV_TEMPLATE], { type: contentType });
        FileSaver.saveAs(csvFile, 'Parspec CRM Bulk Import Format.csv');
    };

    const onUploadFile = async (files: File[] | FileSelectorFileType[]) => {
        if (files.length) {
            setFileRequiredError(null);
            const formData = new FormData();
            formData.append('file', files[0] as File);
            const uploadData = await uploadFile(formData);
            if (uploadData.status === 201) {
                confirmAction(uploadData.data);
            } else {
                setFileRequiredError(uploadData.data.message);
            }
        }
    };

    const onDeleteFile = () => {
        setFileRequiredError(null);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Modal open={open} header={<ModalHeader title={'Upload Contacts'} onClose={handleDialog} />} footer={<></>}>
                <Box width={420} marginTop={4}>
                    <FileSelector
                        placeholder={
                            <BodySmall
                                sx={{
                                    div: {
                                        WebkitLineClamp: 'unset !important'
                                    }
                                }}
                            >
                                Download our <Link onClick={downloadTemplate}>Contact File Template</Link>,<br />
                                then browse or drag your file to upload.
                            </BodySmall>
                        }
                        onSelect={onUploadFile}
                        acceptedFormats={['.xls', '.xlsx', '.csv']}
                        helperText={'Supported file types: XLS, XLSX, CSV'}
                        onDeleteFile={onDeleteFile}
                        isLoading={isLoading}
                    ></FileSelector>
                    {fileRequiredError && (
                        <BodySmall limit={false} color="error.main" pt={3}>
                            {fileRequiredError}
                        </BodySmall>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default BulkImport;
