import { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';

import { Autocomplete, BodySmall, BodyXS, Box, Modal, ModalFooter, ModalHeader, OptionType, Skeleton, Snackbar, Status, TextField, Tooltip } from '@parspec/pixel';

import { useAddMfgToCompanyMutation, useParspecMfg } from './queries';
import { getMfgStatusColor } from '../../shared/constants';
import ManufacturerSearch from './ManufacturerSearch';
import { specialCharactersRegex, websiteValidationRegex } from 'src/features/shared/utils/utils';
import { searchManufacturer } from '../../shared/utils/utils';
import { ParspecMfgDetailsResponse } from './queries/apiTypes';
import { ManufacturerDropdownType } from '../../BOM/productFinder/setRequirements/types';

interface AddManufacturerDialogProps {
    open: boolean;
    confirmAction: () => void;
    handleDialog: () => void;
}
interface ICardFields {
    mfgId: number | null;
    mfgName: string | null;
    abbreviation: string | string[];
    parspecId: string | null;
    website: string;
    suppStatus: string;
    erpCode: string | null;
}
interface IErrorField {
    mfgNameErr: string;
    parspecIdErr: boolean;
    websiteErr: string;
    abbrErr: string;
}

export const AddManufacturer = ({ open, handleDialog, confirmAction }: AddManufacturerDialogProps) => {
    const { data: companyMfgData, isLoading: isMfgListLoading } = useParspecMfg();
    const { mutateAsync: addRepresentedMfg, isLoading: addLoading } = useAddMfgToCompanyMutation();

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [formValues, setFormValues] = useState<ICardFields>({
        mfgId: 0,
        mfgName: null,
        abbreviation: '',
        parspecId: '',
        website: '',
        suppStatus: '',
        erpCode: null
    });
    const [mfgNameList, setMfgNameList] = useState<OptionType<ParspecMfgDetailsResponse>[]>([]);
    const [formError, setFormError] = useState<IErrorField>({
        mfgNameErr: '',
        parspecIdErr: false,
        websiteErr: '',
        abbrErr: ''
    });

    useEffect(() => {
        if (companyMfgData?.data.length) {
            setMfgNameList([...(companyMfgData.data as OptionType<ParspecMfgDetailsResponse>[])]);
        }
    }, [companyMfgData]);

    const onInputChange = (_event: React.SyntheticEvent<Element, Event>, value: string) => {
        if (value?.trim().length > 0) {
            setFormValues({
                ...formValues,
                mfgName: value
            });
        } else {
            setFormValues({
                ...formValues,
                mfgName: null,
                parspecId: '',
                mfgId: null,
                website: '',
                suppStatus: ''
            });
            setFormError({
                ...formError,
                mfgNameErr: 'Required',
                parspecIdErr: false,
                websiteErr: ''
            });
        }
    };

    const handleMfgNameChange = (event: any) => {
        if (event.target.value) {
            const { manufacturer_id, parspec_id, website: selectedWebsite, status } = event.target.value;
            setFormValues({
                ...formValues,
                mfgName: parspec_id,
                parspecId: parspec_id,
                mfgId: manufacturer_id,
                website: selectedWebsite,
                suppStatus: status
            });
            setFormError({
                ...formError,
                mfgNameErr: '',
                parspecIdErr: false,
                websiteErr: ''
            });
        } else {
            setFormError({
                ...formError,
                mfgNameErr: 'Required',
                parspecIdErr: false,
                websiteErr: ''
            });
        }
    };

    const handleMfgNameBlur = () => {
        if (formValues.mfgName && !formValues.parspecId) {
            setFormValues({
                ...formValues,
                mfgId: null,
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                mfgNameErr: '',
                parspecIdErr: true,
                websiteErr: 'warning'
            });
        }
    };

    const handleParpsecIdChange = (value?: ParspecMfgDetailsResponse) => {
        if (value) {
            const { parspec_id, website: selectedWebsite, status, manufacturer_id } = value;
            setFormValues({
                ...formValues,
                parspecId: parspec_id,
                mfgId: manufacturer_id,
                website: selectedWebsite,
                suppStatus: status
            });
            setFormError({
                ...formError,
                websiteErr: ''
            });
        } else {
            setFormValues({
                ...formValues,
                parspecId: null,
                mfgId: null,
                website: '',
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                websiteErr: 'warning'
            });
        }
    };

    const onAbbrBlur = (event: any) => {
        if (specialCharactersRegex.test(event.target.value)) {
            setFormError({
                ...formError,
                abbrErr: ''
            });
        } else {
            setFormError({
                ...formError,
                abbrErr: 'regex'
            });
        }
    };
    const handleWebsiteChange = (event: any) => {
        if (event.target.value.trim()) {
            const selectedWebsite = event.target.value;
            const matchingMfg = companyMfgData?.data.find((item) => item.website.toLowerCase() === selectedWebsite);
            if (matchingMfg) {
                setFormValues({
                    ...formValues,
                    parspecId: matchingMfg.parspec_id,
                    mfgId: matchingMfg.manufacturer_id,
                    suppStatus: matchingMfg.status
                });
                setFormError({
                    ...formError,
                    websiteErr: ''
                });
            } else if (websiteValidationRegex().test(selectedWebsite.trim())) {
                setFormValues({
                    ...formValues,
                    parspecId: null,
                    mfgId: null,
                    suppStatus: 'Requested'
                });
                setFormError({
                    ...formError,
                    websiteErr: ''
                });
            } else {
                setFormValues({
                    ...formValues,
                    parspecId: null,
                    mfgId: null,
                    suppStatus: 'Not Recognized'
                });
                setFormError({
                    ...formError,
                    websiteErr: 'required'
                });
            }
        } else {
            setFormValues({
                ...formValues,
                parspecId: null,
                website: '',
                mfgId: null,
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                websiteErr: 'warning'
            });
        }
    };

    const handleSubmit = async () => {
        if (formError.websiteErr === 'required' || formError.abbrErr === 'regex') {
            return;
        }
        if (formValues.mfgName?.trim()) {
            let payloadAbbreviation: string | string[];
            if (typeof formValues.abbreviation === 'string') {
                if (formValues.abbreviation.trim()) {
                    const newAbbr = formValues.abbreviation.split(',');
                    payloadAbbreviation = newAbbr.filter((n) => n.trim());
                } else {
                    payloadAbbreviation = [];
                }
            } else {
                payloadAbbreviation = formValues.abbreviation;
            }

            const payload = {
                abbreviation_merge_issue: true,
                data: [
                    {
                        manufacturer_name: formValues.mfgName,
                        abbreviations: payloadAbbreviation,
                        parspec_id: formValues.parspecId || null,
                        website: formValues.website.trim() || null,
                        manufacturer_id: formValues.mfgId,
                        status: formValues.suppStatus,
                        erp_code: formValues.erpCode?.trim() || null
                    }
                ]
            };
            const response = await addRepresentedMfg(payload);
            const conflict = response.data[0].conflict;
            if (Object.keys(conflict).length === 0) {
                confirmAction();
            } else {
                setFormError({
                    ...formError,
                    abbrErr: 'conflict'
                });
            }
        } else {
            setFormError({
                ...formError,
                mfgNameErr: 'Required',
                parspecIdErr: false,
                websiteErr: ''
            });
        }
    };

    const websiteHelperText =
        formError.websiteErr === 'warning' ? (
            <Box>
                <BodySmall lines={2} color={'warning.main'}>
                    <strong>Can’t find a matching Parpsec ID?</strong> <br />
                    Add the manufacturer’s website to request support
                </BodySmall>
            </Box>
        ) : formError.websiteErr === 'required' ? (
            <>
                <BodySmall color={'error.main'}>Invalid url</BodySmall>
                <BodySmall padding={'6px'} lines={3} color={'warning.main'}>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        Remove all text before the https:// extension
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        Try adding the protocol or sub-domain like ‘https://’ or ‘www’
                    </ListItem>
                </BodySmall>
            </>
        ) : (
            ''
        );

    const abbrHelperText =
        formError.abbrErr === 'conflict' ? (
            <BodySmall paddingTop={'6px'} lines={2} color={'error.main'}>
                One or more abbreviations listed are used for more than one manufacturer. Remove duplicates to continue.
            </BodySmall>
        ) : formError.abbrErr === 'regex' ? (
            <BodySmall color={'error.main'}>Special characters not allowed</BodySmall>
        ) : (
            ''
        );

    const footer = <ModalFooter onReject={handleDialog} onAccept={handleSubmit} continueButtonLabel="Confirm" isLoading={addLoading} />;

    const filterHandler = (options: OptionType<ManufacturerDropdownType>[] | { [index: string]: string | number; }[], params: { inputValue: string }) => {
        return searchManufacturer(options as OptionType<ManufacturerDropdownType>[], params.inputValue.trim());
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Modal open={open} header={<ModalHeader title={'Add Manufacturers'} onClose={handleDialog} />} footer={footer}>
                <Box width={424} maxHeight={'80vh'} overflow={'auto'} paddingRight={1}>
                    <Box
                        mt={6}
                        sx={{
                            '.Mui-error': {
                                fontSize: '14px',
                                marginTop: '3px'
                            }
                        }}
                    >
                        {!isMfgListLoading ? (
                            <Autocomplete
                                value={formValues.mfgName}
                                options={mfgNameList}
                                onChange={handleMfgNameChange}
                                placeholder="Type a manufacturer..."
                                onBlur={handleMfgNameBlur}
                                onTextFieldChange={onInputChange}
                                id="mfgName"
                                label="Manufacturer Name"
                                optionlabelkeyname="parspec_id"
                                helperText={formError.mfgNameErr}
                                error={Boolean(formError.mfgNameErr)}
                                filterOptionsCallBack={filterHandler}
                            />
                        ) : (
                            <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                        )}
                    </Box>
                    <Box mt={6}>
                        <TextField
                            value={formValues.erpCode}
                            label="Manufacturer ERP Code"
                            placeholder="Enter Manufacturer ERP Code..."
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFormValues({
                                    ...formValues,
                                    erpCode: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Box>
                    <Box mt={6}>
                        <TextField
                            value={formValues.abbreviation}
                            label="Abbreviations"
                            placeholder="Enter abbreviation..."
                            onBlur={onAbbrBlur}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFormValues({
                                    ...formValues,
                                    abbreviation: e.target.value.toUpperCase()
                                });
                            }}
                            helperText={abbrHelperText}
                            error={Boolean(formError.abbrErr)}
                        />
                    </Box>
                    <Box mt={6}>
                        <ManufacturerSearch
                            parspecId={formValues.parspecId}
                            handleParpsecIdChange={handleParpsecIdChange}
                            placeholder="View Suggestions..."
                            label="Parspec ID"
                            disabled={!formValues.mfgName}
                            showWarning={formError.parspecIdErr}
                            showHelperText={true}
                        />
                    </Box>
                    <Box
                        mt={6}
                        sx={{
                            '.Mui-error .MuiOutlinedInput-notchedOutline': {
                                borderColor: formError.websiteErr === 'warning' ? '#FFA72B !important' : ''
                            },
                            '.MuiFormLabel-root': {
                                color: formError.websiteErr === 'warning' ? '#FFA72B !important' : ''
                            }
                        }}
                    >
                        <Tooltip title={!formValues.mfgName ? 'Please select a manufacturer first.' : ''} placement={'top'}>
                            <TextField
                                value={formValues.website}
                                label="Website"
                                placeholder="Enter website..."
                                onBlur={handleWebsiteChange}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFormValues({
                                        ...formValues,
                                        website: e.target.value
                                    });
                                }}
                                helperText={websiteHelperText}
                                error={Boolean(formError.websiteErr)}
                                disabled={!formValues.mfgName}
                            />
                        </Tooltip>
                    </Box>
                    <Box mt={6}>
                        {formValues.suppStatus.length > 0 && (
                            <Box display={'flex'} alignItems={'center'}>
                                <BodySmall>Support Status: &nbsp;</BodySmall>
                                <Status color={getMfgStatusColor(formValues.suppStatus)}>
                                    <BodyXS color="inherit">{formValues.suppStatus}</BodyXS>
                                </Status>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
