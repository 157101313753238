import { BodySmall, Box, Paper, Skeleton } from '@parspec/pixel';
import ImageMagnifier from '../../../../shared/DocumentSuggestions/ImageMagnifier';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { useParams } from 'react-router-dom';
import { useGetSelectedDocumentQuery } from '../../../Datasheets/AnnotateDatasheet/queries';
import { useEffect } from 'react';

interface DataSheetSourceHeaderProps {
    setSourceDataSheet: (status: boolean) => void;
}

const DataSheetSourceHeader = (props: DataSheetSourceHeaderProps) => {
    const { bomId, productId, documentType, subDocumentType } = useParams();
    const { setSourceDataSheet } = props;

    const { data: selectedDocumentData, isFetching: isSelectedDocumentQueryLoading } = useGetSelectedDocumentQuery(bomId!, productId!, documentType!, 'datasheet', {
        enabled: !!bomId && !!productId && !!documentType && !!subDocumentType,
        staleTime: 0
    });
    useEffect(() => {
        if (selectedDocumentData && selectedDocumentData?.data?.length) {
            if (selectedDocumentData?.data[0]?.id) setSourceDataSheet(true);
        }
    }, [selectedDocumentData?.data]);
    const selectedDataSource = selectedDocumentData?.data[0];
    const url = selectedDataSource?.suggestion?.selected_document_preview ? getProxyFileUrl(selectedDataSource?.suggestion?.selected_document_preview) : '';
    return (
        <>
            {!isSelectedDocumentQueryLoading ? (
                <>
                    {selectedDataSource?.id ? (
                        <>
                            <Paper
                                variant="outlined"
                                sx={{
                                    width: '522px',

                                    padding: 4,
                                    boxSizing: 'border-box',
                                    minHeight: '544px'
                                }}
                            >
                                <Box
                                    height={350}
                                    width="100%"
                                    padding={1}
                                    border="1px solid"
                                    bgcolor={'neutral.main'}
                                    mt={'46px'}
                                    display={'flex'}
                                    justifyContent={'space-around'}
                                    alignItems={'center'}
                                >
                                    {url ? <ImageMagnifier src={url} width="100%" /> : <BodySmall>No Preview available.</BodySmall>}
                                </Box>
                            </Paper>
                        </>
                    ) : (
                        <Box
                            height={'544px'}
                            width={'522px'}
                            display={'flex'}
                            border={'1px solid'}
                            borderColor={'neutral.main'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            bgcolor={'neutral.main'}
                        >
                            <BodySmall>No reference datasheet provided.</BodySmall>
                        </Box>
                    )}
                </>
            ) : (
                <Box
                    height={'544px'}
                    width={'522px'}
                    display={'flex'}
                    border={'1px solid'}
                    borderColor={'neutral.main'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={'30px'}
                >
                    <Skeleton variant="rectangular" height={'544px'} width={'522px'} />
                </Box>
            )}
        </>
    );
};

export default DataSheetSourceHeader;
