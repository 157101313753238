export const coverPageData = [
    { field_name: 'cover_page_section', is_active: false, field_value: '' },
    {
        field_name: 'architect',
        field_value: 'This is architect',
        is_active: false
    },
    {
        field_name: 'engineer',
        field_value: 'This is engineer',
        is_active: false
    },
    {
        field_name: 'distributor',
        field_value: 'This is distributor',
        is_active: false
    },
    {
        field_name: 'contractor',
        field_value: 'This is contractor',
        is_active: false
    },
    {
        field_name: 'owner',
        field_value: 'This is owner again',
        is_active: false
    },
    {
        field_name: 'prepared_by',
        field_value: {
            company: 'LITHONIA',
            email: 'abc@gmail.com',
            phone: '123456789',
            address1: '<Address line 1>',
            address2: '<Address line 2>',
            city: 'Brooklyn',
            state: 'PE',
            zip: '122017',
            first_name: 'Abhishek',
            last_name: 'Raj',
            country: 'Canada',
            logo: null
        },
        is_active: true
    },
    {
        field_name: 'addressed_to',
        field_value: {
            company: 'Google',
            email: 'test@gmail.com',
            phone: '9876543210',
            first_name: 'John',
            last_name: 'Doe',
            address1: '<Address line 1>',
            address2: '<Address line 2>',
            city: 'New York',
            state: 'AR',
            zip: '122017',
            country: 'USA',
            logo: {
                filepath:
                    'https://parspec-product-url.s3.us-east-2.amazonaws.com/media/bom/54895/om/logo/cover_page/cool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcoo.jpg',
                filename:
                    'cool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcoo.jpg'
            }
        },
        is_active: true
    },
    {
        field_name: 'opening_notes',
        field_value: {
            value: 'This is opening notes...',
            font: {
                color: '#bd10e0',
                type: 'regular'
            }
        },
        is_active: true
    }
];

export const coverPageLogoData = {
    field_name: 'prepared_by',
    logo: {
        filepath:
            'https://parspec-product-url.s3.us-east-2.amazonaws.com/media/bom/54895/om/logo/cover_page/cool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcoo.jpg',
        filename:
            'cool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcool-fox-with-sharp-eyes-mascot-logo-design-free-vectorcoo.jpg'
    }
};
