import { useMutation } from '@tanstack/react-query';
import { selectedDocumentKey } from '../../../BOM/Datasheets/AnnotateDatasheet/queries';
import { updateHiddenPages } from './apis';
import { queryClient } from 'src/app/queryClient';
import { getBoundDatasheetKey } from '../../../BOM/AggregatorCropping/queries';
import { DatasheetBoundResponse } from '../../../BOM/AggregatorCropping/queries/apiTypes';
import { AxiosResponse } from 'axios';
import { SelectedDocumentResponse } from '../../../BOM/Datasheets/AnnotateDatasheet/queries/apiTypes';
import { updateBomDataSelectionStatus } from '../../../BOM/queries';

export const useUpdateHidddePagesMutation = () => {
    return useMutation(updateHiddenPages, {
        onSuccess(data, variables) {
            if (variables.apiDetails.entity === 'annotateDatasheet') {
                updateBomDataSelectionStatus({ bomId: variables.bomId, bodId: Number(variables.bodId), all_pages_hidden: false });
                queryClient.setQueryData(
                    selectedDocumentKey(variables.bomId, variables.bodId, variables.documentType, variables.subDocumentType),
                    (oldData?: AxiosResponse<Array<SelectedDocumentResponse>>) => {
                        if (oldData) {
                            return { ...oldData, data: [{ ...oldData.data[0], hidden_pages: data.data.hidden_pages }] };
                        }
                        return oldData;
                    }
                );
            } else {
                queryClient.setQueryData(getBoundDatasheetKey(variables.bomId, variables.submittalPackageId), (oldData?: AxiosResponse<DatasheetBoundResponse>) => {
                    if (oldData) {
                        return { ...oldData, data: { ...oldData.data, hidden_pages: data.data.hidden_pages, datasheet_bound_info: data.data.datasheet_bound_info } };
                    }
                    return oldData;
                });
            }
        }
    });
};
