import { axios } from 'src/app/Axios';

import { CompileTimeResponseType, CompileGenerateProps, GenerateDocumentResponse, CreditCheckResponse } from './apiTypes';

export const endPoints = {
    getCompileTime: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}/compile_time`,
    compileDocument: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}/`,
    checkCredit: (documentType: string) => `/bom/${documentType}/check_credit/`
};

export const getCompileTime = (bomId: string, documentType: string) => axios.get<CompileTimeResponseType>(endPoints.getCompileTime(bomId, documentType));
export const createCompileDocument = ({ bomId, documentType, version_notes, enable_cednet_format, enable_epicor_format }: CompileGenerateProps) =>
    axios.post<GenerateDocumentResponse>(endPoints.compileDocument(bomId, documentType), { version_notes, enable_cednet_format, enable_epicor_format });

export const checkCreditBeforeCompile = async ({ documentType, payload }: { documentType: string; payload: { boms: Array<number> } }) =>
    axios.post<CreditCheckResponse>(endPoints.checkCredit(documentType), payload);
