import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectDetailsQuery } from '../../Project/queries';
import { useGetBomDetails } from '../../BOM/queries';
import { useAllDocumentsQuery } from '../../BOM/FinalDocuments/queries';
import { DocumentType } from '../../BOM/FinalDocuments/constants';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { pushEvent } from './EventService';

interface TrackActivityWrapperProps {
    activityType: string;
    key?: string;
    children: JSX.Element;
}
interface TrackActivityProps extends TrackActivityWrapperProps {
    userId: number;
    projectId: number;
    companyId: number;
    submittalVersion: number;
    oAndMVersion: number;
    quoteVersion: number;
}

export const TrackActivityDurationWrapper = (props: TrackActivityWrapperProps) => {
    const { children, key = 'default-track-key', activityType } = props;
    const { bomId } = useParams();
    const { data: userData, isLoading } = useGetUserProfileInfoQuery();
    const { data: bomDetails, isLoading: bomDetailsLoading } = useGetBomDetails(`${bomId || 0}`, { enabled: Boolean(bomId) });
    const projectId = bomDetails?.data?.project || 0;
    const { data: projectDetails, isLoading: projectDetailsLoading } = useGetProjectDetailsQuery(projectId! || 0, { enabled: Boolean(projectId) });
    const companyId = projectDetails?.data.company || 0;
    const { data: allSubmittalsData } = useAllDocumentsQuery(bomId!, DocumentType.SUBMITTAL);
    const submittalVersion = allSubmittalsData?.data.length || 0;
    const { data: allOAndMData } = useAllDocumentsQuery(bomId!, DocumentType.O_M);
    const oAndMVersion = allOAndMData?.data.length || 0;
    const { data: allQuoteData } = useAllDocumentsQuery(bomId!, DocumentType.QUOTE);
    const quoteVersion = allQuoteData?.data.length || 0;
    if (isLoading || bomDetailsLoading || projectDetailsLoading) {
        return <></>;
    } else if (userData && companyId && projectId && allSubmittalsData && allOAndMData && allQuoteData) {
        return (
            <TrackActivityDuration
                activityType={activityType}
                key={key}
                userId={userData.data.id}
                projectId={projectId}
                companyId={companyId}
                submittalVersion={submittalVersion}
                oAndMVersion={oAndMVersion}
                quoteVersion={quoteVersion}
            >
                {children}
            </TrackActivityDuration>
        );
    } else {
        return <>{children}</>;
    }
};
export const TrackActivityDuration = (props: TrackActivityProps) => {
    const { children, activityType, userId, companyId, projectId, submittalVersion, oAndMVersion, quoteVersion } = props;

    const { bomId, documentType, subDocumentType } = useParams();

    const specificActivityType = useMemo(() => {
        return getActivityType(activityType, documentType!, subDocumentType!);
    }, []);
    const startTime = useRef(0);
    const storedDuration = useRef(0);
    const idleTimer = useRef<NodeJS.Timeout>();
    const [isIdle, setIsIdle] = useState(false);
    const [unmountCalledOnIdle, setUnmountCalledOnIdle] = useState(false);

    useEffect(() => {
        startTime.current = Date.now();

        window.addEventListener('beforeunload', handleBeforeUnmount);
        // not adding idle time constraint because mousemove event won't work on iframe
        if (activityType !== 'AnnotateDatasheet') {
            window.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            handleBeforeUnmount();
            window.removeEventListener('beforeunload', handleBeforeUnmount);
            window.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(idleTimer.current);
        };
    }, []);

    const handleBeforeUnmount = () => {
        const insertMutation = `
        mutation {
            events_db{
            insert_user_activity_duration( objects: { activity_type: "${specificActivityType}", bom_id:  ${bomId!}, company_id:  ${companyId}, duration_ms:  ${
            storedDuration.current + (Date.now() - startTime.current)
        }, project_id:  ${projectId}, o_m_version: ${oAndMVersion + 1}, quote_version: ${quoteVersion + 1}, submittal_version: ${submittalVersion + 1}}) {
                affected_rows
            }}
        }
    `;
        const updateMutation = `
        mutation {
            events_db{
            update_user_activity_duration(_inc: {duration_ms: ${
                storedDuration.current + (Date.now() - startTime.current)
            }}, where: {user_id: {_eq: ${userId}}, activity_type: {_eq: "${specificActivityType}"}, bom_id: {_eq: ${bomId!}}, quote_version: {_eq: ${quoteVersion + 1}}, submittal_version: {_eq: ${
            submittalVersion + 1
        }}, o_m_version: {_eq: ${oAndMVersion + 1}}}) {
            affected_rows
            }}
        }
    `;
        pushEvent({ insertMutation, updateMutation, responseAttribute: 'user_activity_duration' });
    };

    useEffect(() => {
        if (unmountCalledOnIdle && !isIdle) {
            startTime.current = Date.now();
            setUnmountCalledOnIdle(false);
        }
    }, [isIdle, unmountCalledOnIdle]);

    const handleMouseMove = () => {
        clearTimeout(idleTimer.current);

        setIsIdle(false);

        idleTimer.current = setTimeout(() => {
            setIsIdle(true);
            setUnmountCalledOnIdle(true);
            const duration = Date.now() - startTime.current - 60000;
            storedDuration.current = storedDuration.current + duration;
        }, 60000);
    };

    return <> {children} </>;
};

function getActivityType(type: string, documentType: string, subDocumentType: string) {
    let activityType = type;
    if (type === 'MatchDatasheet') {
        if (documentType === 'submittal') {
            activityType = 'SelectDatasheetPage';
        } else if (documentType === 'o_m') {
            switch (subDocumentType) {
                case 'installation_guide':
                    activityType = 'SelectInstallationGuidePage';
                    break;
                case 'drawing':
                    activityType = 'SelectDrawingPage';
                    break;
                case 'warranty':
                    activityType = 'SelectWarrantyPage';
                    break;
                case 'other_document':
                    activityType = 'SelectOtherDocPage';
                    break;
            }
        }
    } else if (type === 'AnnotateDatasheet') {
        if (documentType === 'submittal') {
            activityType = 'AnnotateDatasheetPage';
        } else if (documentType === 'o_m') {
            switch (subDocumentType) {
                case 'installation_guide':
                    activityType = 'AnnotateInstallationGuidePage';
                    break;
                case 'drawing':
                    activityType = 'AnnotateDrawingPage';
                    break;
                case 'warranty':
                    activityType = 'AnnotateWarrantyPage';
                    break;
                case 'other_document':
                    activityType = 'AnnotateOtherDocPage';
                    break;
            }
        }
    } else if (type === 'CompileDocument') {
        if (documentType === 'submittal') {
            activityType = 'CustomizeSubmittalPage';
        } else if (documentType === 'o_m') {
            activityType = 'CustomizeO&M';
        }
    }
    return activityType;
}
