export const headerFooterData = [
    {
        field_name: 'header_footer_section',
        field_value: null,
        is_active: false
    },
    {
        field_name: 'logo',
        field_value: {
            filename: 'my_logo.png',
            filepath: 'full_s3_link'
        }
    },
    {
        field_name: 'job_name',
        field_value: 'This is Job name',
        is_active: true
    },
    {
        field_name: 'addressed_to',
        field_value: '',
        is_active: false
    },
    {
        field_name: 'project_location',
        field_value: null,
        is_active: true
    },
    {
        field_name: 'contact',
        field_value: null,
        is_active: false
    },
    {
        field_name: 'quote_number',
        field_value: 'QUOTE NUMBER',
        is_active: true
    },
    {
        field_name: 'bid_date',
        field_value: {
            value: null,
            font: {
                color: '#FF0000',
                type: 'bold'
            }
        },
        is_active: true
    },
    {
        field_name: 'expiry_date',
        field_value: {
            value: null,
            font: {
                color: '#FF0000',
                type: 'light'
            }
        },
        is_active: true
    },
    {
        field_name: 'left_footer',
        field_value: 'Left Footer Value',
        is_active: true
    },
    {
        field_name: 'right_footer',
        field_value: 'Right Footer Value',
        is_active: false
    },
    {
        field_name: 'date',
        field_value: null,
        is_active: false
    },
    {
        field_name: 'page_number',
        field_value: null,
        is_active: false
    },
    {
        field_name: 'hyperlink',
        field_value: null,
        is_active: false
    }
];

export const headerFooterLogoData = {
    logo: {
        filepath: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/bom/21881/submittal/logo/header_footer/Screenshot_3.png',
        filename: 'Screenshot_3.png'
    }
};
