// Enviornment needs to be first import of the app to support obfuscation plugin
import { environment } from './environments';

import ReactDOM from 'react-dom/client';

import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import * as Sentry from '@sentry/react';

import { defineCustomElements, applyPolyfills } from 'xml-viewer-component/dist/loader';
import App from './app';

import { registerLicense } from '@syncfusion/ej2-base';
import { LicenseManager } from '@ag-grid-enterprise/core';

registerLicense(environment.e);
LicenseManager.setLicenseKey(environment.c);

Sentry.init({
    dsn: 'https://4dbb09b186984279b961448657bec904@o1052126.ingest.us.sentry.io/6035619',
    integrations: [],
    enabled: environment.Z,
    release: 'parspec-frontend@9.64.0',
    maxValueLength: 2000
});

if (environment.a) {
    require('./testing/mock/browser');
}

applyPolyfills().then(() => {
    defineCustomElements(window);
});

if (environment.Z) {
    // Initialize Firebase
    const app = initializeApp({
        apiKey: environment.f,
        authDomain: environment.U,
        projectId: environment.g,
        storageBucket: environment.T,
        messagingSenderId: environment.h,
        appId: environment.S
    });
    // Intialaize Firebase Performance
    getPerformance(app);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
