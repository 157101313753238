import { useEffect, useLayoutEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosResponse } from 'axios';

import { getFastProxyFileUrl, getProxyFileUrl } from 'src/features/shared/utils/utils';
import { SearchResultProps } from '../../BOM/productFinder/selectProduct/queries/apiTypes';
import { Document } from '../DocumentSuggestions/DocumentItem';

const isValidDocument = (response: AxiosResponse) => {
    const { status, headers } = response;
    return status === 200 && !['text/html', 'application/zip'].includes(headers['content-type']);
};

interface Options {
    enabled: boolean;
}

interface SearchResult extends SearchResultProps {
    isValid?: boolean;
}

interface DocumentProps extends Document {
    isValid?: boolean;
}

type DataProps = DocumentProps | SearchResult;

const useGetLiveDocuments = (inputData: DataProps[] | undefined, options: Options): any => {
    const [resultsWithIsValidStatus, setResultsWithIsValidStatus] = useState<DataProps[]>([]);
    const [isAllDataFetching, setAllDataFetching] = useState(false);
    const [isOneDataFetching, setOneDataFetching] = useState(false);
    const updateFilteredResponse = (item: DataProps, index: number, isValid: boolean) => {
        setResultsWithIsValidStatus((old) => {
            const state = [...old];
            state[index] = { ...item, isValid, id: uuidv4() };
            return state;
        });
    };

    const getFilteredList = async () => {
        inputData?.forEach(async (item: DataProps, index: number) => {
            try {
                const response = await axios.head(getFastProxyFileUrl(item.document_internal_url!));
                updateFilteredResponse(item, index, isValidDocument(response));
            } catch (e: any) {
                //some servers does not have head call, so this is fallback for that
                if ((String(e?.response?.status) || '').startsWith('4')) {
                    try {
                        const response = await axios.get(getFastProxyFileUrl(item.document_url!));
                        updateFilteredResponse(item, index, isValidDocument(response));
                    } catch (e) {
                        updateFilteredResponse(item, index, false);
                    }
                }

                //some servers has request limit, so this is fallback for that
                else if (e?.response?.status === 500) {
                    try {
                        const response = await axios.head(getProxyFileUrl(item.document_url!));
                        updateFilteredResponse(item, index, isValidDocument(response));
                    } catch (e) {
                        try {
                            const response = await axios.get(getFastProxyFileUrl(item.document_url!));
                            updateFilteredResponse(item, index, isValidDocument(response));
                        } catch (e) {
                            updateFilteredResponse(item, index, false);
                        }
                    }
                } else {
                    updateFilteredResponse(item, index, false);
                }
            }
        });
    };
    useLayoutEffect(() => {
        if (options.enabled) {
            setOneDataFetching(true);
            setAllDataFetching(true);
            setResultsWithIsValidStatus(Array(inputData?.length));
            getFilteredList();
        }
    }, [options.enabled]);
    useEffect(() => {
        if (!resultsWithIsValidStatus?.includes(undefined as never)) {
            setAllDataFetching(false);
        }
    }, [resultsWithIsValidStatus]);

    const getResult = () => {
        const finalResult = [];
        let loader = true;
        for (let i = 0; i < resultsWithIsValidStatus.length; i++) {
            if (!resultsWithIsValidStatus[i]) {
                break;
            } else {
                if (resultsWithIsValidStatus[i].isValid) {
                    finalResult.push(resultsWithIsValidStatus[i]);
                    loader = false;
                }
            }
            if (i === resultsWithIsValidStatus.length - 1) {
                loader = false;
            }
        }
        if (isOneDataFetching && !loader) {
            setOneDataFetching(false);
        }
        if (isOneDataFetching && !loader) {
            setOneDataFetching(false);
        }

        return finalResult;
    };
    const allValidResults = getResult();
    return { data: allValidResults, isAllDataFetching, isOneDataFetching };
};

export default useGetLiveDocuments;
