export const setToken = () => {
    const hubspotEmail = localStorage.getItem('hubspotEmail');
    const hubspotToken = localStorage.getItem('hubspotToken');

    (window as any).hsConversationsSettings = {
        identificationEmail: hubspotEmail,
        identificationToken: hubspotToken
    };

    (window as any).HubSpotConversations.clear({ resetWidget: true });
    (window as any).HubSpotConversations.widget.load();
};
