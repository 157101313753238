import { rest } from 'msw';
import { alternates, selectedProduct, productSearchHistory } from './dataAlternates';

export const selectProductHandlers = [
    rest.get('*/bom/:bomId/product_finder/:bodId/search_results', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(alternates));
    }),
    rest.post('*/bom/:bom_id/product_finder/:productId/selected_datasheet/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.post('/bom/:bom_id/product_finder/:productId/selected_datasheet/bulk?source=${actionType}', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.post('*/bom/:bom_id/product_finder/:bod_id/selection_history', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(productSearchHistory));
    })
];
