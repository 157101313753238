import { Internationalization } from '@syncfusion/ej2-base';

import { BodResponse, updateBomDataRequest } from '../../../queries/apiTypes';
import { getAmountForUi, getMainProductOptionObj } from '../../../shared/utils';
import { CALC_METHODS, PRICING_TABLE_ENUM } from '../constants';
import { convertToCents, convertToDollar } from '../../../../shared/utils/utils';
import { LotsResponse } from '../../lotPriceModal/queries/apiTypes';
import { LotInfoType, MainProductDataType } from '../../mainProductTable';
import { PricingBodInfo, PricingBodsResponse } from '../../queries/apiTypes';
import { ServicesResponseType } from '../../servicesModal/queries/apiTypes';
import { OptionDataType } from '../../optionsTable';

interface PricingInfo {
    qty: number | null;
    cost: number | null;
    discount: number | null;
    discountedCost: number | null;
    margin: number | null;
    sellPrice: number | null;
    extendedPrice: number | null;
}

function handleCostChange({ cost, discount, margin, qty }: Pick<Partial<PricingInfo>, 'discount' | 'cost' | 'margin' | 'qty'>) {
    const updatedRowInfo: Pick<Partial<PricingInfo>, 'discountedCost' | 'sellPrice' | 'extendedPrice' | 'discount' | 'margin'> = {};
    if (!cost) {
        updatedRowInfo.discount = null;
        updatedRowInfo.discountedCost = null;
        updatedRowInfo.margin = null;
    } else {
        updatedRowInfo.discount = discount ?? 0;
        updatedRowInfo.margin = margin ?? 0;

        const updatedInfoBasedOnCost = handleDiscountChange({ cost, discount: updatedRowInfo.discount, margin: updatedRowInfo.margin, qty });
        updatedRowInfo.discountedCost = updatedInfoBasedOnCost.discountedCost;
        updatedRowInfo.sellPrice = updatedInfoBasedOnCost.sellPrice;
        updatedRowInfo.extendedPrice = updatedInfoBasedOnCost.extendedPrice;
    }

    return updatedRowInfo;
}

function handleDiscountChange({ cost, discount, margin, qty }: Pick<Partial<PricingInfo>, 'discount' | 'cost' | 'margin' | 'qty'>) {
    const updatedRowInfo: Pick<Partial<PricingInfo>, 'discountedCost' | 'sellPrice' | 'extendedPrice'> = {};
    if (discount === null) {
        updatedRowInfo.discountedCost = null;
        updatedRowInfo.sellPrice = null;
        updatedRowInfo.extendedPrice = null;
    }

    if (cost && (discount || discount === 0)) {
        updatedRowInfo.discountedCost = Number((cost * (1 - discount / 100)).toFixed(2));
        const updatedInfoBasedOnDiscountedCost = handleDiscountedCostChange({ discountedCost: updatedRowInfo.discountedCost, margin, qty });
        updatedRowInfo.sellPrice = updatedInfoBasedOnDiscountedCost.sellPrice;
        updatedRowInfo.extendedPrice = updatedInfoBasedOnDiscountedCost.extendedPrice;
    }

    return updatedRowInfo;
}

function handleDiscountedCostChange({ discountedCost, cost, margin, qty }: Pick<Partial<PricingInfo>, 'discountedCost' | 'cost' | 'margin' | 'qty'>) {
    const updatedRowInfo: Pick<Partial<PricingInfo>, 'discount' | 'sellPrice' | 'extendedPrice'> = {};

    if (discountedCost === null) {
        updatedRowInfo.discount = null;
        updatedRowInfo.sellPrice = null;
        updatedRowInfo.extendedPrice = null;
    }

    if ((discountedCost || discountedCost === 0) && (cost || cost === 0)) {
        const discount = Number(((1 - discountedCost / cost) * 100).toFixed(2));
        updatedRowInfo.discount = Number.isFinite(discount) ? discount : null;
    }

    if ((discountedCost || discountedCost === 0) && (margin || margin === 0)) {
        const updatedInfoBasedOnDiscountedCost = handleMarginChange({ discountedCost, margin, qty });
        updatedRowInfo.sellPrice = updatedInfoBasedOnDiscountedCost.sellPrice;
        updatedRowInfo.extendedPrice = updatedInfoBasedOnDiscountedCost.extendedPrice;
    }

    return updatedRowInfo;
}

function handleMarginChange({ discountedCost, margin, qty }: Pick<Partial<PricingInfo>, 'discountedCost' | 'margin' | 'qty'>) {
    const updatedRowInfo: { sellPrice?: number | null; extendedPrice?: number | null } = {};
    if ((discountedCost || discountedCost === 0) && (margin || margin === 0)) {
        const sellPrice = Number((discountedCost / (1 - margin / 100)).toFixed(2));
        updatedRowInfo.sellPrice = Number.isFinite(sellPrice) ? sellPrice : null;
        const updatedInfoBasedOnSellPrice = handleSellPriceChange({
            qty,
            sellPrice: updatedRowInfo.sellPrice
        });
        updatedRowInfo.extendedPrice = updatedInfoBasedOnSellPrice.extendedPrice;
    } else if (margin === null) {
        updatedRowInfo.sellPrice = null;
        updatedRowInfo.extendedPrice = null;
    }
    return updatedRowInfo;
}

function handleSellPriceChange({ discountedCost, sellPrice, qty }: Pick<Partial<PricingInfo>, 'discountedCost' | 'sellPrice' | 'qty'>) {
    const updatedRowInfo: { margin?: number | null; extendedPrice?: number | null } = {};
    if ((discountedCost || discountedCost === 0) && (sellPrice || sellPrice === 0)) {
        const margin = Number(((1 - discountedCost / sellPrice) * 100).toFixed(2));
        updatedRowInfo.margin = Number.isFinite(margin) ? margin : null;
    } else if (sellPrice === null) {
        updatedRowInfo.margin = null;
    }
    if ((qty || qty === 0) && (sellPrice || sellPrice === 0)) {
        updatedRowInfo.extendedPrice = Number((sellPrice * qty).toFixed(2));
    } else if (qty === null || sellPrice === null) {
        updatedRowInfo.extendedPrice = null;
    }

    return updatedRowInfo;
}

export function getUpdatedPricingInfoForChange(currentInfo: PricingInfo, fieldUpdated: PRICING_TABLE_ENUM) {
    let newPricingInfo = { ...currentInfo };
    switch (fieldUpdated) {
        case PRICING_TABLE_ENUM.COST: {
            const updatedPricingInfo = handleCostChange({ cost: newPricingInfo.cost, discount: newPricingInfo.discount, margin: newPricingInfo.margin, qty: newPricingInfo.qty });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
        case PRICING_TABLE_ENUM.DISCOUNT: {
            const updatedPricingInfo = handleDiscountChange({ cost: newPricingInfo.cost, discount: newPricingInfo.discount, margin: newPricingInfo.margin, qty: newPricingInfo.qty });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
        case PRICING_TABLE_ENUM.DISCOUNT_COST: {
            const updatedPricingInfo = handleDiscountedCostChange({
                discountedCost: newPricingInfo.discountedCost,
                cost: newPricingInfo.cost,
                margin: newPricingInfo.margin,
                qty: newPricingInfo.qty
            });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
        case PRICING_TABLE_ENUM.MARGIN: {
            const updatedPricingInfo = handleMarginChange({
                discountedCost: newPricingInfo.discountedCost,
                margin: newPricingInfo.margin,
                qty: newPricingInfo.qty
            });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
        case PRICING_TABLE_ENUM.QTY: {
            const updatedPricingInfo = handleSellPriceChange({
                sellPrice: newPricingInfo.sellPrice,
                qty: newPricingInfo.qty
            });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
        case PRICING_TABLE_ENUM.SELL_PRICE: {
            const updatedPricingInfo = handleSellPriceChange({
                discountedCost: newPricingInfo.discountedCost,
                sellPrice: newPricingInfo.sellPrice,
                qty: newPricingInfo.qty
            });
            newPricingInfo = { ...newPricingInfo, ...updatedPricingInfo };
            break;
        }
    }

    return newPricingInfo;
}

export function getLotsObjForMainProductTable(lotsData: LotsResponse) {
    const lotsObj = lotsData.reduce((currLotsObj, lotData) => {
        const lotsInfo = { name: lotData.name, extendedPrice: convertToDollar(lotData.sell_price_cents || 0), isUsedInOtherSection: false };
        currLotsObj[lotData.id] = { lotsInfo, lotsUsedInSectionsSet: new Set<number>() };
        return currLotsObj;
    }, {} as { [id: number]: { lotsInfo: LotInfoType; lotsUsedInSectionsSet: Set<number> } });

    return lotsObj;
}

export function getDataForPricingTableFromApis(initBodsData?: BodResponse[], pricingData?: PricingBodsResponse, lotsData?: LotsResponse, isKitData?: boolean) {
    if (!initBodsData || !pricingData || !lotsData) {
        return {};
    }

    const bodsData = !Array.isArray(initBodsData) ? [] : isKitData ? initBodsData : initBodsData?.filter((item) => !item.kit);
    const lotsObj = getLotsObjForMainProductTable(lotsData);

    const pricingDataMap = pricingData.reduce((acc: Record<number, PricingBodsResponse[0]>, pricingBodData) => {
        acc[pricingBodData.bod_id] = pricingBodData;
        return acc;
    }, {});

    const bodsDataMap = bodsData.reduce((acc: Record<number, BodResponse>, bodData) => {
        acc[bodData.id] = bodData;
        return acc;
    }, {});

    const mainProductAndOptionsObj = getMainProductOptionObj(!Array.isArray(bodsData) ? [] : bodsData);

    let bodsExtendedPrice = 0,
        bodsCost = 0,
        bodsExtDiscountedPrice = 0;

    // to handle backend sending response as {} when no bods are present
    const tableData = bodsData.flatMap((bodData) => {
        const bodId = bodData.id;
        // to ensure that tableData has both bod and pricing data fetched
        if (!pricingDataMap[bodId]) {
            return [];
        }
        // logic to add isUsedInOtherSection flag in lotsInfo obj
        const isMainProduct = Boolean(bodData.section);
        const sectionId = bodData.section || 0;
        const optionSectionId = bodData.substitute_section || 0;
        const pricingDataForBod = pricingDataMap[bodId] || {
            notes: null,
            lot_id: null,
            cost_cents: null,
            discount_percentage: null,
            discounted_cost_cents: null,
            margin_percentage: null,
            sell_price_cents: null,
            lead_time_unit: null,
            lead_time_value: null,
            source_quote_number: null
        };

        // calculating individual bod price for current row & total sum of prices.
        const extendedSellPrice =
            (pricingDataForBod.sell_price_cents || pricingDataForBod.sell_price_cents === 0) && (bodData.qty || bodData.qty === 0)
                ? convertToDollar(pricingDataForBod.sell_price_cents) * bodData.qty
                : null;
        const discountedCost = pricingDataForBod.discounted_cost_cents && convertToDollar(pricingDataForBod.discounted_cost_cents);
        const cost = pricingDataForBod.cost_cents && convertToDollar(pricingDataForBod.cost_cents);
        const sellPrice = pricingDataForBod.sell_price_cents && convertToDollar(pricingDataForBod.sell_price_cents);

        const lotId = pricingDataForBod?.lot_id || null;
        const {
            lotsInfo,
            lotsUsedInSectionsSet
        }: {
            lotsInfo?: LotInfoType;
            lotsUsedInSectionsSet?: Set<number>;
        } = lotId && lotsObj[lotId] ? lotsObj[lotId] : {};
        if (lotId && lotsObj[lotId] && lotsUsedInSectionsSet && lotsInfo && !bodData.is_hidden) {
            lotsUsedInSectionsSet.add(sectionId);
            if (lotsUsedInSectionsSet?.size > 1 && !lotsInfo.isUsedInOtherSection) {
                lotsInfo.isUsedInOtherSection = true;
            }
        }

        if (isMainProduct && !bodData.kit && !lotId && lotId !== 0 && !bodData.is_hidden && bodData.qty && extendedSellPrice !== null) {
            bodsExtendedPrice += extendedSellPrice || 0;
            bodsExtDiscountedPrice += (discountedCost || 0) * bodData.qty;
            bodsCost += (cost || 0) * bodData.qty;
        }

        let productToReplaceInfo, addDeductAmount;
        if (!isMainProduct && bodData.product_to_replace && pricingDataMap[bodData.product_to_replace]) {
            const mainProductPricingInfo = pricingDataMap[bodData.product_to_replace];
            const mainProductInfo = bodsDataMap[bodData.product_to_replace];
            const mainProductExtPrice =
                (mainProductPricingInfo.sell_price_cents || mainProductPricingInfo.sell_price_cents === 0) && (mainProductInfo.qty || mainProductInfo.qty === 0)
                    ? convertToDollar(mainProductPricingInfo.sell_price_cents) * mainProductInfo.qty
                    : null;
            productToReplaceInfo = {
                [PRICING_TABLE_ENUM.EXTENDED_PRICE]: mainProductExtPrice,
                [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_LABEL]: [mainProductInfo?.category?.toUpperCase(), mainProductInfo?.manufacturer?.toUpperCase(), mainProductInfo?.model_number?.toUpperCase()]
                    .filter((data) => data)
                    .join(' | ')
            };
        }

        if (!isMainProduct) {
            const mainProductExtPrice = productToReplaceInfo?.[PRICING_TABLE_ENUM.EXTENDED_PRICE];
            addDeductAmount =
                (mainProductExtPrice || mainProductExtPrice === 0) && (extendedSellPrice || extendedSellPrice === 0) && bodData.product_to_replace
                    ? extendedSellPrice - mainProductExtPrice
                    : extendedSellPrice;
        }

        const currentBodHasOptions = isMainProduct && mainProductAndOptionsObj[bodData.id]?.length > 0;

        return {
            [PRICING_TABLE_ENUM.ID]: bodId,
            [PRICING_TABLE_ENUM.QTY]: bodData.qty,
            [PRICING_TABLE_ENUM.TYPE]: bodData.category,
            [PRICING_TABLE_ENUM.MANUFACTURER]: bodData.manufacturer,
            [PRICING_TABLE_ENUM.MODEL_NO]: bodData.model_number,
            [PRICING_TABLE_ENUM.SPECIFICATION]: bodData.specification,
            [PRICING_TABLE_ENUM.QUOTE_NOTES]: pricingDataForBod.notes,
            [PRICING_TABLE_ENUM.LOT]: lotId,
            [PRICING_TABLE_ENUM.COST]: cost,
            [PRICING_TABLE_ENUM.DISCOUNT]: pricingDataForBod.discount_percentage,
            [PRICING_TABLE_ENUM.DISCOUNT_COST]: discountedCost,
            [PRICING_TABLE_ENUM.MARGIN]: pricingDataForBod.margin_percentage,
            [PRICING_TABLE_ENUM.SELL_PRICE]: sellPrice,
            [PRICING_TABLE_ENUM.EXTENDED_PRICE]: extendedSellPrice,
            [PRICING_TABLE_ENUM.LEAD_TIME_UNIT]: pricingDataForBod.lead_time_unit,
            [PRICING_TABLE_ENUM.LEAD_TIME_VALUE]: pricingDataForBod.lead_time_value !== -1 ? pricingDataForBod.lead_time_value : null,
            [PRICING_TABLE_ENUM.QUOTE_NO]: pricingDataForBod.source_quote_number,
            [PRICING_TABLE_ENUM.LOT_INFO]: lotsInfo || null,
            [PRICING_TABLE_ENUM.IS_HIDDEN]: bodData.is_hidden,
            [PRICING_TABLE_ENUM.SECTION]: sectionId,
            [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE]: bodData.product_to_replace,
            [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_INFO]: productToReplaceInfo,
            [PRICING_TABLE_ENUM.ADD_DEDUCT_AMT]: addDeductAmount,
            [PRICING_TABLE_ENUM.INTERNAL_COMMENTS]: pricingDataForBod.internal_notes,
            [PRICING_TABLE_ENUM.OPTION_SECTION]: optionSectionId,
            [PRICING_TABLE_ENUM.HAS_OPTION]: currentBodHasOptions,
            [PRICING_TABLE_ENUM.KIT]: bodData.kit,
            [PRICING_TABLE_ENUM.IS_KIT]: bodData.is_kit,
            [PRICING_TABLE_ENUM.ORDER]: bodData.order,
            [PRICING_TABLE_ENUM.IS_HIGHLIGHTED]: bodData.is_highlighted
        };
    });

    const { lotsTotalExtPrice = 0, lotsExtDistCost = 0, lotsTotalCost = 0 } = getLotsTotalPrices(lotsData) || {};

    const totalExtendedPrice = lotsTotalExtPrice + bodsExtendedPrice;
    const totalExtDiscountedCost = lotsExtDistCost + bodsExtDiscountedPrice;
    const totalCost = bodsCost + lotsTotalCost;
    const totalMarginCost = totalExtendedPrice - totalExtDiscountedCost;
    const totalMarginPercentage = totalExtDiscountedCost && totalExtendedPrice ? (1 - totalExtDiscountedCost / totalExtendedPrice) * 100 : 0;
    const totalDiscount = totalCost - totalExtDiscountedCost;
    const totalDiscountPercentage = totalExtDiscountedCost && totalCost ? (1 - totalExtDiscountedCost / totalCost) * 100 : 0;
    return {
        tableData,
        totalExtendedPrice,
        totalCost,
        totalExtDiscountedCost,
        totalMarginCost,
        totalMarginPercentage,
        totalDiscount,
        totalDiscountPercentage
    };
}

export function getApiDataForPricingInfo(data: Partial<Omit<MainProductDataType, 'qty' | 'type' | 'manufacturer' | 'modelNo' | 'specification' | 'lotInfo'>>) {
    const resultData: Partial<PricingBodInfo> = {};
    for (const colKey of Object.keys(data)) {
        switch (colKey) {
            case PRICING_TABLE_ENUM.ID:
                resultData.bod_id = data[colKey];
                break;
            case PRICING_TABLE_ENUM.QUOTE_NOTES:
                resultData.notes = data[colKey];
                break;
            case PRICING_TABLE_ENUM.LOT:
                resultData.lot_id = data[colKey];
                break;
            case PRICING_TABLE_ENUM.COST:
                resultData.cost_cents = data[colKey] || data[colKey] === 0 ? convertToCents(data[colKey] || 0) : data[colKey];
                break;
            case PRICING_TABLE_ENUM.DISCOUNT:
                resultData.discount_percentage = data[colKey];
                break;
            case PRICING_TABLE_ENUM.DISCOUNT_COST:
                resultData.discounted_cost_cents = data[colKey] || data[colKey] === 0 ? convertToCents(data[colKey] || 0) : data[colKey];
                break;
            case PRICING_TABLE_ENUM.MARGIN:
                resultData.margin_percentage = data[colKey];
                break;
            case PRICING_TABLE_ENUM.SELL_PRICE:
                resultData.sell_price_cents = data[colKey] || data[colKey] === 0 ? convertToCents(data[colKey] || 0) : data[colKey];
                break;
            case PRICING_TABLE_ENUM.LEAD_TIME_UNIT:
                resultData.lead_time_unit = data[colKey];
                break;
            case PRICING_TABLE_ENUM.LEAD_TIME_VALUE:
                resultData.lead_time_value = data[colKey];
                break;
            case PRICING_TABLE_ENUM.QUOTE_NO:
                resultData.source_quote_number = data[colKey];
                break;
            case PRICING_TABLE_ENUM.INTERNAL_COMMENTS:
                resultData.internal_notes = data[colKey];
                break;
        }
    }

    return resultData;
}

export function getApiDataForBomInfo(
    data: Partial<Pick<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'id'>> & Partial<Pick<OptionDataType, 'replaceProductId'>>
) {
    const resultData: updateBomDataRequest['input'] = {};
    for (const colKey of Object.keys(data)) {
        switch (colKey) {
            case PRICING_TABLE_ENUM.ID:
                resultData.id = data[colKey];
                break;
            case PRICING_TABLE_ENUM.QTY:
                resultData.qty = data[colKey];
                break;
            case PRICING_TABLE_ENUM.TYPE:
                resultData.category = data[colKey];
                break;
            case PRICING_TABLE_ENUM.MANUFACTURER:
                resultData.manufacturer = data[colKey];
                break;
            case PRICING_TABLE_ENUM.MODEL_NO:
                resultData.model_number = data[colKey];
                break;
            case PRICING_TABLE_ENUM.SPECIFICATION:
                resultData.specification = data[colKey];
                break;
            case PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE:
                resultData.product_to_replace = data[colKey];
                break;
        }
    }

    return resultData;
}

export function getCentsToDollars(orderTotal: number) {
    const orderAmountInDollars = orderTotal / 100;
    return orderAmountInDollars;
}

export function getLotsTotalPrices(lotsData?: LotsResponse) {
    if (!lotsData) {
        return;
    }

    let lotsTotalExtPrice = 0;
    let lotsExtDistCost = 0;
    let lotsTotalCost = 0;
    for (const lotData of lotsData) {
        lotsTotalExtPrice += convertToDollar(lotData.sell_price_cents || 0);
        lotsTotalCost += convertToDollar(lotData.cost_cents || 0);
        lotsExtDistCost += convertToDollar(lotData.discounted_cost_cents || 0);
    }

    return { lotsTotalExtPrice, lotsExtDistCost, lotsTotalCost };
}

export function getPercentageAmount(amount: number, percentage: number) {
    return Number((amount * (percentage / 100)).toFixed(2));
}

export function getServicesTotal(servicesData?: ServicesResponseType, orderTotal = 0) {
    if (!servicesData || servicesData.length === 0) {
        return null;
    }

    return servicesData.reduce((currSum: number | null, currService) => {
        let currServiceAmount: number | null = currSum;
        if (currService.calculation_method === CALC_METHODS.FLAT_FEE && currService.flat_fee_cents !== null) {
            currServiceAmount = Number(currServiceAmount) + convertToDollar(currService.flat_fee_cents);
        } else if (currService.calculation_method === CALC_METHODS.PERCENTAGE_OF_ORDER && currService.percentage_of_order !== null) {
            currServiceAmount = Number(currServiceAmount) + getPercentageAmount(orderTotal, currService.percentage_of_order);
        }
        return currServiceAmount;
    }, null);
}

export function getPercentageFieldAccessor(field: string, data: Record<string, any>, column: any) {
    const fieldData = data[field];
    if (fieldData === null) {
        return fieldData;
    }

    const intl = new Internationalization();
    const val = intl.formatNumber(data[field], { format: 'N2', useGrouping: false, minimumFractionDigits: 2 });

    if (column?.parent?.isEdit) {
        return val;
    }
    return `${val}%`;
}

interface FooterDataObj {
    totalExtendedPrice?: number;
    totalCost?: number;
    totalExtDiscountedCost?: number;
    totalMarginCost?: number;
    totalMarginPercentage?: number;
    totalDiscount?: number;
    totalDiscountPercentage?: number;
    grandTotal?: number;
}

export const getPriceStats = (footerData: FooterDataObj) => {
    const {
        totalExtendedPrice = 0,
        totalCost = 0,
        totalExtDiscountedCost = 0,
        totalMarginCost = 0,
        totalMarginPercentage = 0,
        totalDiscount = 0,
        totalDiscountPercentage = 0,
        grandTotal = 0
    } = footerData;
    return [
        {
            title: 'Total Extended Cost',
            count: `$${getAmountForUi(totalCost)}`
        },
        {
            title: 'Total Discount',
            count: `$${getAmountForUi(totalDiscount)}`,
            helperText: `${getAmountForUi(totalDiscountPercentage)}%`,
            helperTextColor: 'primary.contrastText'
        },
        {
            title: 'Total Ext. Discounted Cost',
            count: `$${getAmountForUi(totalExtDiscountedCost)}`
        },
        {
            title: 'Total Margin',
            count: `$${getAmountForUi(totalMarginCost)}`,
            helperText: `${getAmountForUi(totalMarginPercentage)}%`,
            helperTextColor: 'primary.contrastText'
        },
        {
            title: 'Total Ext. Sell Price',
            count: `$${getAmountForUi(totalExtendedPrice)}`
        },
        {
            title: 'Grand Total',
            count: `$${getAmountForUi(grandTotal)}`,
            titleColor: 'tertiary.light',
            countColor: 'tertiary.main'
        }
    ];
};
