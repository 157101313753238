import { useState } from 'react';
import { Box } from '@parspec/pixel';

const ImageMagnifier = ({
    src,
    width,
    height = '100%',
    magnifierHeight = 200,
    magnifieWidth = 200,
    zoomLevel = 3
}: {
    src: string;
    width?: string;
    height?: string;
    magnifierHeight?: number;
    magnifieWidth?: number;
    zoomLevel?: number;
}) => {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
        <Box
            height={height}
            width={width}
            position="relative"
            sx={{
                backgroundImage: `url(${src})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }}
            onMouseEnter={(e: React.MouseEvent) => {
                // update image size and turn-on magnifier
                const elem = e.currentTarget;
                const { width, height } = elem.getBoundingClientRect();
                setSize([width, height]);
                setShowMagnifier(true);
            }}
            onMouseMove={(e: React.MouseEvent) => {
                // update cursor position
                const elem = e.currentTarget;
                const { top, left } = elem.getBoundingClientRect();

                // calculate cursor position on the image
                const x = e.pageX - left - window.pageXOffset;
                const y = e.pageY - top - window.pageYOffset;
                setXY([x, y]);
            }}
            onMouseLeave={() => {
                // close magnifier
                setShowMagnifier(false);
            }}
        >
            <Box
                display={showMagnifier ? '' : 'none'}
                position="absolute"
                // set size of magnifier
                height={`${magnifierHeight}px`}
                width={`${magnifieWidth}px`}
                // move element center to cursor pos
                top={`${y - magnifierHeight / 2}px`}
                left={`${x - magnifieWidth / 2}px`}
                border="1px solid lightgray"
                bgcolor="#fff"
                zIndex={1000}
                sx={{
                    // prevent maginier blocks the mousemove event of img
                    pointerEvents: 'none',
                    opacity: 1,
                    backgroundImage: `url('${src}')`,
                    backgroundRepeat: 'no-repeat',
                    //calculate zoomed image size
                    backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
                    //calculete position of zoomed image.
                    backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                    backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
                }}
            ></Box>
        </Box>
    );
};

export default ImageMagnifier;
