export const user = [
  {
    user_details: {
      id: 71,
      name: 'test 4',
      email: 'test4@yopmail.com',
      last_activity: '2023-03-27T05:10:02.504073Z',
      first_name: 'test',
      last_name: '4',
    },
    roles: [
      {
        role: 'admin',
      },
    ],
    company_details: [
      {
        company_id: 1,
        company_name: 'A',
      },
      {
        company_id: 259,
        company_name: 'test new location',
      },
      {
        company_id: 260,
        company_name: 'new location 1212',
      },
      {
        company_id: 264,
        company_name: 'test location 4',
      },
      {
        company_id: 265,
        company_name: '6 test 5 location ',
      },
      {
        company_id: 266,
        company_name: 'asdasd',
      },
      {
        company_id: 267,
        company_name: 'sadasdasd',
      },
      {
        company_id: 272,
        company_name: 'Jiyo',
      },
      {
        company_id: 273,
        company_name: 'PresetsNotTouched',
      },
      {
        company_id: 274,
        company_name: 'AllTrueturnedToFalseAndViceVersa',
      },
      {
        company_id: 284,
        company_name: 'dadads',
      },
      {
        company_id: 302,
        company_name: 'mahi way',
      },
    ],
  },
];
