import { BodyBig, BodySmall, Box, Grid, Radio, BodyMedium, Switch } from '@parspec/pixel';

import { SubmittalTemplateInterface, OMTemplateInterface } from '../../queries/apiTypes';
import { SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE } from '../utils/helper';

interface DocumentOrderingProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: SubmittalTemplateInterface | OMTemplateInterface;
}

const KIT_BREAKDOWN_TEXT = 'Determine whether or not to show kit components in this document type.';
const SECTION_BREAKS_TEXT =
    'Ideal for ease of document navigation, Section Breaks are added before a change in section, or a change of type of document. The design layout and fields for the section breaks will mimic the ones selected for the Table of Contents.';
const MAINTAIN_ASPECT_RATIO = `‘Maintaining aspect ratio’ ensures documents, especially technical drawings, are scaled proportionately to maintain their original aspect ratio. 
    When deactivated, documents automatically adjust to fit the page, maximizing their size, but potentially altering their scale.`;

const SECTION_BREAK_AND_KIT_COMPONENT_SWITCH_DATA = [
    {
        headerText: 'Page Fitting',
        label: 'Maintain Aspect Ratio',
        value: 'maintain_aspect_ratio',
        text_content: MAINTAIN_ASPECT_RATIO
    },
    {
        headerText: 'Section Breaks',
        label: 'Add Section Breaks',
        value: 'section_breaks',
        text_content: SECTION_BREAKS_TEXT
    },
    {
        headerText: 'Kit Component Breakdown',
        label: 'Show Kit Components',
        value: 'show_kit_components',
        text_content: KIT_BREAKDOWN_TEXT
    }
];

export const DocumentOrdering = (props: DocumentOrderingProps) => {
    const { changeHandler, templateState } = props;

    return (
        <Box mt={'24px'}>
            <Grid container direction={'column'} gap={3} pt={6}>
                <Grid item>
                    <Grid container gap={2}>
                        <Grid item>
                            <BodyBig fontWeight={500} color={'secondary.dark'}>
                                Document Ordering
                            </BodyBig>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <BodySmall color={'text.secondary'} limit={false}>
                        Choose how to display your documents. <strong>Order by Product</strong> will group the document by its associated product. <strong>Order by Document</strong> will group the
                        document by its document type. If you select <strong>Order by Document</strong> and have the same document and annotations assigned to multiple products, the document will
                        appear once and reference its associated products in its page header.
                    </BodySmall>
                </Grid>

                <Grid item my={2}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <Grid container direction={'column'} rowGap={2}>
                                <Grid item>
                                    <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                        Document
                                    </BodySmall>
                                </Grid>

                                <Grid item>
                                    <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                        Datasheets
                                    </BodySmall>
                                </Grid>

                                <Grid item>
                                    <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                        Installation Guides
                                    </BodySmall>
                                </Grid>

                                <Grid item>
                                    <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                        Drawings
                                    </BodySmall>
                                </Grid>

                                <Grid item>
                                    <BodySmall p={2} color={'secondary.main'}>
                                        Warranties
                                    </BodySmall>
                                </Grid>

                                <Grid item>
                                    <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                        Other Documents
                                    </BodySmall>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*   Order By Product */}
                        <Grid item>
                            <Grid container direction={'column'} rowGap={2}>
                                <Grid item>
                                    <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                        Order by Product
                                    </BodySmall>
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.datasheet === 'REPEATED'}
                                        size={'small'}
                                        value={'REPEATED'}
                                        name="datasheet"
                                        inputProps={{ 'aria-label': 'datasheet' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.installation_guide === 'REPEATED'}
                                        size={'small'}
                                        value={'REPEATED'}
                                        name="installation_guide"
                                        inputProps={{ 'aria-label': 'installation_guide' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.drawing === 'REPEATED'}
                                        size={'small'}
                                        value={'REPEATED'}
                                        name="drawing"
                                        inputProps={{ 'aria-label': 'drawing' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.warranty === 'REPEATED'}
                                        size={'small'}
                                        value={'REPEATED'}
                                        name="warranty"
                                        inputProps={{ 'aria-label': 'warranty' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.other_document === 'REPEATED'}
                                        size={'small'}
                                        value={'REPEATED'}
                                        name="other_document"
                                        inputProps={{ 'aria-label': 'other_document' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*  Order By Document */}
                        <Grid item>
                            <Grid container direction={'column'} rowGap={2}>
                                <Grid item>
                                    <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                        Order by Document
                                    </BodySmall>
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.datasheet === 'UNIQUE'}
                                        size={'small'}
                                        value={'UNIQUE'}
                                        name="datasheet"
                                        inputProps={{ 'aria-label': 'datasheet' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.installation_guide === 'UNIQUE'}
                                        size={'small'}
                                        value={'UNIQUE'}
                                        name="installation_guide"
                                        inputProps={{ 'aria-label': 'installation_guide' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.drawing === 'UNIQUE'}
                                        size={'small'}
                                        value={'UNIQUE'}
                                        name="drawing"
                                        inputProps={{ 'aria-label': 'drawing' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.warranty === 'UNIQUE'}
                                        size={'small'}
                                        value={'UNIQUE'}
                                        name="warranty"
                                        inputProps={{ 'aria-label': 'warranty' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.other_document === 'UNIQUE'}
                                        size={'small'}
                                        value={'UNIQUE'}
                                        name="other_document"
                                        inputProps={{ 'aria-label': 'other_document' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Exclude */}
                        <Grid item ml={2}>
                            <Grid container direction={'column'} rowGap={2}>
                                <Grid item>
                                    <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                        Exclude
                                    </BodySmall>
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.datasheet === 'EXCLUDE'}
                                        size={'small'}
                                        value={'EXCLUDE'}
                                        name="datasheet"
                                        inputProps={{ 'aria-label': 'datasheet' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.installation_guide === 'EXCLUDE'}
                                        size={'small'}
                                        value={'EXCLUDE'}
                                        name="installation_guide"
                                        inputProps={{ 'aria-label': 'installation_guide' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.drawing === 'EXCLUDE'}
                                        size={'small'}
                                        value={'EXCLUDE'}
                                        name="drawing"
                                        inputProps={{ 'aria-label': 'drawing' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.warranty === 'EXCLUDE'}
                                        size={'small'}
                                        value={'EXCLUDE'}
                                        name="warranty"
                                        inputProps={{ 'aria-label': 'warranty' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>

                                <Grid item alignSelf={'center'}>
                                    <Radio
                                        checked={templateState.layout_presets.other_document === 'EXCLUDE'}
                                        size={'small'}
                                        value={'EXCLUDE'}
                                        name="other_document"
                                        inputProps={{ 'aria-label': 'other_document' }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'layout_presets')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {SECTION_BREAK_AND_KIT_COMPONENT_SWITCH_DATA.map((option) => {
                    return (
                        <Grid item my={3}>
                            <Grid container direction={'column'}>
                                <Grid item mb={4}>
                                    <BodyMedium fontWeight={500} color={'secondary.dark'}>
                                        {option.headerText}
                                    </BodyMedium>
                                </Grid>

                                <Grid item mb={4}>
                                    <BodySmall color={'text.secondary'} limit={false}>
                                        {option.text_content}
                                    </BodySmall>
                                </Grid>

                                <Grid item ml="4px">
                                    <Switch
                                        checked={templateState.layout_presets[option.value] === SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE.INCLUDED ? true : false}
                                        onChange={(e) => changeHandler(e, 'layout_presets')}
                                        name={option.value}
                                        label={option.label}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};
