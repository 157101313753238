import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import { GridChartsModule } from '@ag-grid-enterprise/charts';

import { BodySmall, Box, Button, Info, Snackbar, TableHeaderMenuIcon, ToolbarType } from '@parspec/pixel';

import BulkImport from '../../shared/BulkImport';
import AddContactDialog from '../AddContactDialog';
import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../../../BOM/shared/commonTemplates';

import { defaultColDef } from '../../shared/constant';
import { dateComparator, emailTemplate, getLastModifiedFilterText, menuOptions, renderCompanyName, renderContactName, renderLastModifiedDate } from '../../shared/utils';
import { deleteTitle, contactDeleteBody, getRowId, Company, FORM_FIELDS } from '../utils';
import { CONTACT_CREATED_MSG, CONTACT_DELETED_MSG, EMAIL_COPIED_MSG } from '../../../shared/constants';
import { useDeleteContactsMutation, useGetContactsQuery } from '../queries';
import { endpoints } from '../queries/apis';
import { Contact } from '../queries/apiTypes';
import { useGetUserProfileInfoQuery } from '../../../Settings/MyProfile/queries';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { ImportFileResponse } from '../../Company/queries/apiTypes';

interface IContactTableProps {
    isAssociatedContactTable?: boolean;
    associatedTableData?: Contact[];
    preSelectedCompany?: Company;
}

const ContactsTable = ({ isAssociatedContactTable = false, associatedTableData = [], preSelectedCompany }: IContactTableProps) => {
    const [tableData, setTableData] = useState<Contact[]>();
    const tableContainerRef = useRef<any>(null);
    const [tableHeight, setTableHeight] = useState<number>();
    const [selectedRows, setSelectedRows] = useState<Contact[]>([]);
    const [searchText, setSearchText] = useState('');
    const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [apiUrl, setApiUrl] = useState(endpoints.contacts);
    const gridRef = useRef<any>(null);
    const navigate = useNavigate();

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { data: contactsData, isFetching } = useGetContactsQuery(apiUrl);
    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();
    const { mutateAsync: deleteContacts, isLoading: deleteLoading } = useDeleteContactsMutation();

    const isAdmin = userProfile?.data?.role === 'admin';
    const loadingFlag = isFetching || isAdminLoading;

    useEffect(() => {
        if (!isAssociatedContactTable && contactsData?.data && !loadingFlag) {
            const { results, next } = contactsData.data;
            if (next) {
                setApiUrl(next);
            }
            if (tableData && tableData.length > 0) {
                const arr = removeDuplicateRecords([...tableData, ...results]);
                setTableData(arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())));
            } else {
                setTableData(results);
            }
        }
    }, [apiUrl, loadingFlag]);

    useEffect(() => {
        if (isAssociatedContactTable && associatedTableData) {
            setTableData(associatedTableData);
        }
    }, [associatedTableData]);

    useEffect(() => {
        if (tableContainerRef?.current?.offsetHeight) {
            setTableHeight(tableContainerRef?.current?.offsetHeight);
        }
    }, [tableContainerRef]);

    const removeDuplicateRecords = (arr: Contact[]) => {
        return arr.filter((value: Contact, index: number, self: Contact[]) => index === self.findIndex((item: Contact) => item.id === value.id));
    };

    //this useEffect handles the loader in table
    useEffect(() => {
        if (!tableData && loadingFlag) {
            gridRef?.current?.api?.showLoadingOverlay();
        } else if (tableData && tableData?.length === 0) {
            setTimeout(() => {
                gridRef?.current?.api?.showNoRowsOverlay();
            }, 0);
        } else {
            gridRef?.current?.api?.hideOverlay();
        }
    }, [tableData]);

    useEffect(() => {
        return () => {
            setTableData(undefined);
            setTableHeight(undefined);
        };
    }, []);

    const toggleAddEditDialog = useCallback(() => setOpenAddEditDialog((old) => !old), []);

    // to open the create popup if user comes by clicking Add New Contact
    useEffect(() => {
        if (window?.openContactPopup) {
            toggleAddEditDialog();
            delete window.openContactPopup;
        }
    }, []);

    const onCheckboxChange = (args: any) => {
        const selected = Array.from(args.api.selectionService.selectedNodes.values());
        const selectedContacts = selected.map((i: any) => i.data);
        setSelectedRows(() => [...selectedContacts]);
    };

    const handleClearSelection = () => {
        gridRef.current?.api?.deselectAll();
    };

    const onClickEdit = (props: ICellRendererParams) => {
        const { id } = props.data;
        navigate(`/v2/company/1/contacts/${id}`);
    };

    const onClickDelete = (props: ICellRendererParams) => {
        setDeleteIds([props?.data?.id]);
        setOpenConfirmationDialog(true);
    };

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const onDeleteBulk = async (clearSelection?: boolean) => {
        await deleteContacts(deleteIds);
        setTableData((old: Contact[] | undefined) => old?.filter((item) => !deleteIds.includes(item.id)));
        setDeleteIds([]);
        closeConfirmationDialog();
        setSnackbarOpen(CONTACT_DELETED_MSG);
        if (clearSelection) handleClearSelection();
    };

    const onClickMultipleDelete = () => {
        const ids = selectedRows.map((item: Contact) => item.id);
        setDeleteIds(ids);
        setOpenConfirmationDialog(true);
    };

    const renderEmail = (args: ICellRendererParams) => {
        const { email } = args.data;
        const handleEmailClick = (event: React.MouseEvent) => {
            event.preventDefault();
            navigator.clipboard.writeText(email);
            setSnackbarOpen(EMAIL_COPIED_MSG);
        };
        return (
            <Box display="flex" alignItems="center" height="100%">
                {emailTemplate(email, handleEmailClick)}
            </Box>
        );
    };

    const columnDefs = useMemo<any>(() => {
        return [
            {
                field: 'id',
                hide: true
            },
            { headerCheckboxSelection: true, checkboxSelection: true, minWidth: 50, width: 50, maxWidth: 50, resizable: true, hide: !isAdmin },
            {
                field: 'first_name',
                headerName: 'Name',
                minWidth: 145,
                cellRenderer: (args: ICellRendererParams) => renderContactName(args),
                suppressMenu: true,
                valueGetter: (params: any) => params?.data?.first_name + params?.data?.last_name
            },
            {
                field: FORM_FIELDS.ROLE,
                headerName: 'Job Title',
                minWidth: 150,
                filter: 'agSetColumnFilter',
                suppressMenu: false,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.EMAIL,
                headerName: 'Email',
                minWidth: 170,
                cellRenderer: renderEmail
            },
            {
                field: FORM_FIELDS.PHONE,
                headerName: 'Phone Number',
                minWidth: 165,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'company_info.name',
                headerName: 'Company',
                minWidth: 145,
                cellRenderer: (args: ICellRendererParams) => renderCompanyName(args),
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                hide: isAssociatedContactTable
            },
            {
                field: 'company_info.business_type',
                headerName: 'Business Type',
                minWidth: 165,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                hide: isAssociatedContactTable,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.ADDRESS_LINE_1,
                headerName: 'Address Line 1',
                minWidth: 165,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.ADDRESS_LINE_2,
                headerName: 'Address Line 2',
                minWidth: 165,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.COUNTRY,
                headerName: 'Country',
                minWidth: 125,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.CITY,
                headerName: 'City',
                minWidth: 140,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.STATE,
                headerName: 'State',
                minWidth: 125,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: FORM_FIELDS.ZIP,
                headerName: 'Zip',
                minWidth: 100,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },

            {
                field: 'lastModified',
                headerName: 'Last Modified',
                minWidth: 180,
                cellRenderer: renderLastModifiedDate,
                suppressMenu: true,
                getQuickFilterText: getLastModifiedFilterText,
                comparator: dateComparator
            },
            {
                headerComponent: () => (
                    <Box mt={1}>
                        <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                    </Box>
                ),
                pinned: 'right',
                minWidth: 50,
                maxWidth: 50,
                resizable: false,
                cellRenderer: (props: ICellRendererParams) => menuOptions(props, onClickEdit, onClickDelete, isAdmin)
            }
        ];
    }, [isAdmin]);

    const clearAllFilters = () => {
        gridRef.current.api.setFilterModel(null);
    };

    const handleAddContactSuccess = useCallback(() => {
        setSnackbarOpen(CONTACT_CREATED_MSG);
    }, []);

    const onCloseBanner = () => {
        setSelectedRows([]);
        handleClearSelection();
    };

    const handleImportDialog = () => {
        setOpenImportDialog(false);
    };

    const onImport = (uploadResponse: ImportFileResponse) => {
        handleImportDialog();
        setSnackbarOpen(uploadResponse.message, 1500);
        setTimeout(() => {
            if (uploadResponse.error) {
                setSnackbarOpen(uploadResponse.error, 3000);
            }
        }, 2000);
    };

    const toolbarRightSection = useMemo(() => {
        return (
            <Box display="flex" gap={2}>
                {isAdmin && !isAssociatedContactTable && (
                    <Button variant="outlined" color="secondary" onClick={() => setOpenImportDialog(true)}>
                        Bulk Import
                    </Button>
                )}
                <Button variant="contained" onClick={toggleAddEditDialog}>
                    Create New
                </Button>
            </Box>
        );
    }, []);

    const toolBarOptions = useMemo(() => {
        return isAdmin ? (['search', 'clearFilters', 'selectedItems', 'delete'] as ToolbarType) : (['search', 'clearFilters'] as ToolbarType);
    }, [isAdmin]);

    const handleSnackbarClose = (_e: Event | React.SyntheticEvent<any, Event>, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarClose();
    };

    return (
        <>
            <Snackbar open={snackbarInfo.open} onClose={handleSnackbarClose} message={snackbarInfo.message} autoHideDuration={snackbarInfo.autoHideDuration} />
            <Box zIndex={1} width={'100%'} position={'relative'} p={4} pb={2} flex={1} ref={tableContainerRef}>
                {tableHeight ? (
                    <Box display="flex" flexDirection="column" height={isAssociatedContactTable ? '350px' : 1}>
                        <CustomToolBarPanel
                            toolBarOptions={toolBarOptions}
                            selectedRowCount={selectedRows.length}
                            disabledToolBarButton={selectedRows.length === 0}
                            onTextSearch={(value) => setSearchText(value)}
                            toolbarRightSection={toolbarRightSection}
                            onDelete={onClickMultipleDelete}
                            onClearFilter={clearAllFilters}
                            onCloseBanner={onCloseBanner}
                        />
                        <Box height={isAssociatedContactTable ? 300 : tableHeight - 120} className="ag-theme-alpine" mt={2} flex={1}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={tableData}
                                getRowId={getRowId}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressMovableColumns={true}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationAutoPageSize
                                onSelectionChanged={onCheckboxChange}
                                quickFilterText={searchText}
                                loadingOverlayComponent={loadingOverlayComponent}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                                suppressMenuHide={true}
                                suppressContextMenu={true}
                                modules={[GridChartsModule]}
                            />
                        </Box>
                    </Box>
                ) : null}
            </Box>
            {openImportDialog && <BulkImport open={openImportDialog} handleDialog={handleImportDialog} confirmAction={onImport} />}
            {openAddEditDialog ? (
                <AddContactDialog open={openAddEditDialog} onClose={toggleAddEditDialog} handleAddContactSuccess={handleAddContactSuccess} preSelectedCompany={preSelectedCompany} />
            ) : null}
            {openConfirmationDialog && (
                <DeleteConfirmationDialog
                    title={'Delete Contact(s)'}
                    open={openConfirmationDialog}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={() => onDeleteBulk(true)}
                    loading={deleteLoading}
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {deleteTitle(deleteIds.length)}
                        </BodySmall>
                        <Info mt={4}>{contactDeleteBody()}</Info>
                    </Box>
                </DeleteConfirmationDialog>
            )}
        </>
    );
};

export default ContactsTable;
