import ReactGA from 'react-ga4';

import { UaEventOptions } from 'react-ga4/types/ga4';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';

export function useAnalytics() {
    const { data: userData } = useGetUserProfileInfoQuery();

    function sendEvent(optionsOrName: string | UaEventOptions, params?: Record<string, any>) {
        if (userData?.data && userData?.data?.is_test_account === false) {
            ReactGA.event(optionsOrName, params);
        }
    }

    return { sendEvent };
}
