import { useNavigate } from 'react-router';

import { Box, Modal, BodySmall, ModalFooter, ModalHeader } from '@parspec/pixel';

import { TermsAndConditionsContent } from './TermsAndConditionsContent';
import { useSignUpNewUserPersonalInfoMutation } from '../../../queries';
import { PayloadDataInterface } from '../PersonalInfo';
import { useState } from 'react';

interface TermsOfConditionsModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    payloadData: PayloadDataInterface;
}

export const TermsAndConditionsModal = (props: TermsOfConditionsModalProps) => {
    const { isOpen, setIsOpen, payloadData } = props;
    const navigate = useNavigate();

    const [isAcceptTnCButtonActive, setIsAcceptTnCButtonActive] = useState<boolean>(false);

    const { mutateAsync: addPerosnalInfoData, isLoading } = useSignUpNewUserPersonalInfoMutation();

    const createAccountHandler = async () => {
        await addPerosnalInfoData(payloadData);

        const username = payloadData.first_name + ' ' + payloadData.last_name;

        sessionStorage.setItem('username', username);
    };

    const confirmHandler = async () => {
        await createAccountHandler();
        closeModalHandler();
        navigate('/login');
    };
    const closeModalHandler = () => {
        setIsOpen(() => false);
    };

    return (
        <Box>
            <Modal
                open={isOpen}
                footer={
                    <ModalFooter
                        isLoading={isLoading}
                        onAccept={confirmHandler}
                        onReject={closeModalHandler}
                        continueButtonLabel={'I agree to the terms of service'}
                        disabled={!isAcceptTnCButtonActive}
                    />
                }
                header={<ModalHeader onClose={closeModalHandler} title={'Parspec Terms of Service'}></ModalHeader>}
                onClose={closeModalHandler}
            >
                <Box width={'643px'} height={'389px'} mt={4}>
                    <Box>
                        <BodySmall limit={false}>Accept the terms of service to finalize your account creation</BodySmall>
                    </Box>

                    <Box mt={2} display={'flex'} justifyContent={'center'}>
                        <TermsAndConditionsContent setAcceptTnCButtonActive={setIsAcceptTnCButtonActive} />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};
