import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, TextField, BodyMedium } from '@parspec/pixel';

import { UpdateTextFieldProps } from '../queries/apiTypes';
import { useDocumentTextFieldMutation } from '../queries';
import PreviewContext from '../Context';
import { ENTITY } from '../../constants';

interface NotesPropType {
    notes: string;
}

const NotesSection = ({ notes }: NotesPropType) => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);
    const [sectionNote, setSectionNote] = useState<string>();

    const handleNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const trimmedValue = value.replace(/^\s+/, '');
        setSectionNote(trimmedValue);
        handlePreviewImage(false);
    };

    const { mutate } = useDocumentTextFieldMutation();

    useEffect(() => {
        setSectionNote(notes);
    }, [notes]);

    const handleOnBlur = () => {
            const payload: UpdateTextFieldProps = {
                bomId: bomId!,
                fieldName: 'notes',
                field_value: sectionNote,
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            };

            mutate(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
    };

    return (
        <Grid container direction={'column'} mb={2} gap={2}>
            <Grid item>
                <BodyMedium color="secondary.main">Notes</BodyMedium>
            </Grid>
            <Grid item>
                <TextField
                    label=""
                    color="secondary"
                    placeholder="Enter Your Notes Here"
                    value={sectionNote}
                    onChange={handleNotes}
                    onBlur={handleOnBlur}
                    inputProps={{
                        maxLength: 180
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default NotesSection;
