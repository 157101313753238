import { useCallback, useRef } from 'react';

import { BodyMedium, Box, BodySmall, BodyXS, LockIcon, LockOpenIcon } from '@parspec/pixel';

import { isEmpty } from 'lodash-es';

import { productRowDetails } from '../constants';
import { LUMEN_UNITS } from 'src/features/shared/utils/constants';
import { useAnalytics } from '../../../../shared/hooks/AnalyticsHook';
import { ADDITIONAL_CRITERIA, SEARCH_IMAGES } from '../../setRequirements/constants';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';

interface DatasheetProdRequirementType {
    fieldName: string;
    fieldValue: string[] | string | [number, number] | boolean;
    isLocked?: boolean;
}
interface DatasheetInfo {
    dataSheetProductRequirements: DatasheetProdRequirementType[];
}
interface ShowRequirementValueProps {
    value: string[];
    seprator: string;
    prefix?: string;
    suffix?: string;
}
const ShowValues = (props: any) => {
    const { value, prefix, suffix } = props;
    return (
        <BodySmall textTransform={'capitalize'} color="inherit" borderRadius={'inherit'}>
            {prefix} {value} {suffix}
        </BodySmall>
    );
};

const ShowRequirementValue = ({ value, seprator, suffix, prefix }: ShowRequirementValueProps) => {
    if (Array.isArray(value)) return <ShowValues value={value.join(seprator)} suffix={suffix} prefix={prefix} />;
    else {
        return <ShowValues value={value} />;
    }
};
const RequirementValues = ({ fieldName, value, productRequirements }: any) => {
    switch (fieldName) {
        case 'lumens': {
            const filterSuffix = LUMEN_UNITS.find((unit) => unit.value === productRequirements.get('lumens_unit').fieldValue)?.label;
            return <ShowRequirementValue value={value} seprator={' - '} suffix={filterSuffix} />;
        }
        case 'temperature':
            return <ShowRequirementValue value={value} seprator={' - '} suffix={'K'} />;
        case 'cri':
            return <ShowRequirementValue value={value} seprator={' - '} prefix={'CRI'} />;
        case 'reference_images':
            return (
                <>
                    {value !== '-' && (
                        <Box padding={1} height={42}>
                            <img src={getProxyFileUrl(value)} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                        </Box>
                    )}

                    {value === '-' && <>-</>}
                </>
            );
        default:
            return <ShowRequirementValue value={value} seprator={', '} />;
    }
};

const SourceProductRequirement = ({ dataSheetProductRequirements }: DatasheetInfo) => {
    const productRequirementsMap = dataSheetProductRequirements.reduce((acc, prevValue) => {
        return acc.set(prevValue.fieldName, prevValue);
    }, new Map());
    const observer = useRef<any>(null);
    const { sendEvent } = useAnalytics();

    const scrollToElementRef = useCallback((node: any) => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                sendEvent('ProductFinder_SelectProductsPage_ViewedAttributes');
                observer.current.disconnect();
            }
        });
        if (node) observer.current.observe(node);
    }, []);

    const showLockIcon = (key: string) => {
        if (key === ADDITIONAL_CRITERIA || key === SEARCH_IMAGES) {
            return <></>;
        } else {
            return (
                <>
                    {productRequirementsMap.get(key)?.isLocked || productRequirementsMap.get(key)?.fieldName === 'acceptable_manufacturers' ? (
                        <LockIcon color={'tertiary'} fontSize="small" />
                    ) : (
                        <LockOpenIcon color={'neutral'} fontSize="small" />
                    )}
                </>
            );
        }
    };
    return (
        <>
            {dataSheetProductRequirements && dataSheetProductRequirements.length > 0 && (
                <Box display={'flex'} border={'1px solid #dee4ea'} alignItems={'center'} padding={'0 10px'} height={52} width={'522px'}>
                    <BodyMedium fontWeight="500" color={'secondary.light'}>
                        Product Requirements
                    </BodyMedium>
                </Box>
            )}
            {productRowDetails.map(({ label, key }) => (
                <Box key={key} display={'flex'} border={'1px solid #dee4ea'} height={52} width={'522px'}>
                    <Box
                        display={'flex'}
                        sx={{ flex: '1 0 25%', alignItems: 'center', padding: '0 10px', justifyContent: 'space-between' }}
                        {...(key === 'mounting_type' && { ref: scrollToElementRef })}
                    >
                        <BodyXS fontWeight={'400'} color={'secondary.light'}>
                            {label}
                        </BodyXS>
                        <>{showLockIcon(key)}</>
                    </Box>
                    <Box display={'flex'} bgcolor={'#fff'} gap={3} pl={'10px'} pr={4} sx={{ flex: '1 0 41%', flexWrap: 'wrap', alignItems: 'center', textWrap: 'nowrap', overflow: 'auto' }}>
                        {productRequirementsMap.get(key) && (
                            <RequirementValues
                                fieldName={key}
                                //loadash's isEmpty cannot check for number
                                value={!isEmpty(productRequirementsMap.get(key)?.fieldValue.toString()) ? productRequirementsMap.get(key)?.fieldValue : '-'}
                                productRequirements={productRequirementsMap}
                            />
                        )}
                    </Box>
                </Box>
            ))}
        </>
    );
};
export default SourceProductRequirement;
