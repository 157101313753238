export const livenessStatus = {
    '73c8bf87857e29662317e8cd26de96ef63b632bd80d5ebae796cea91bd5d3926': {
        message: 'OUTDATED',
        status: 0,
        version: 1
    },
    bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6: {
        message: 'LIVE',
        status: 1,
        version: 2
    },
    '077e75c9bf35021bc32adf7e9386f11a95731d9cd44ddbe7d325334a4d579f2d': {
        message: 'UNKNOWN',
        status: -1,
        version: 2
    }
};
