import { CircularProgress, Box } from '@parspec/pixel';

function CircularLoader() {
    return (
        <Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="primary" size={'xxl'} />
        </Box>
    );
}

export default CircularLoader;
