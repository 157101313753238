import { Accordion, Box, FormControl, Switch, BodySmall } from '@parspec/pixel';

import { QuoteEntity } from '../constants';
import CoverPage from './accordions/coverPage';
import HeaderAndFooter from './accordions/headerAndFooter';
import PricingAndLeadTime from './accordions/pricingAndLeadTime';
import GeneralTermsAndCondition from './accordions/generalTermsAndCondition';
import ManufacturerTermsAndCondition from './accordions/manufacturerTermsAndCondition';
import Attachments from './accordions/attachments';

interface ILeftContent {
    onAccordionChange: (panel: string) => void;
    onToggle: (e: React.ChangeEvent<HTMLInputElement>, field: string) => void;
    cover_page_section?: boolean;
    price_and_lead_time_section?: boolean;
    header_footer_section?: boolean;
    general_tnc_section?: boolean;
    manufacturer_tnc_section?: boolean;
    attachments?: boolean;
}

const accordionBodyDisabled = {
    opacity: '0.4',
    pointerEvents: 'none',
    overflow: 'hidden',
    height: 'calc(100vh - 580px)'
};

const accordionBodyEnabled = {
    overflow: 'auto',
    height: 'calc(100vh - 580px)'
};

const accordionSummaryStyle = { ml: 4, mt: 2 };

const disableEventBubbling = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
};

function LeftContent({
    onAccordionChange,
    onToggle,
    cover_page_section,
    price_and_lead_time_section,
    header_footer_section,
    general_tnc_section,
    manufacturer_tnc_section,
    attachments
}: ILeftContent) {
    const accordionArray = [
        {
            details: !header_footer_section ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <HeaderAndFooter />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <HeaderAndFooter />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={header_footer_section}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['HeaderAndFooter'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Header & Footer
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['HeaderAndFooter']}`
        },
        {
            details: !cover_page_section ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <CoverPage />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <CoverPage />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={cover_page_section}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['CoverPage'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Cover Page
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['CoverPage']}`
        },
        {
            details: !price_and_lead_time_section ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <PricingAndLeadTime />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <PricingAndLeadTime />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={price_and_lead_time_section}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['PricingLeadTime'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Pricing & Lead Time
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['PricingLeadTime']}`
        },
        {
            details: !general_tnc_section ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <GeneralTermsAndCondition />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <GeneralTermsAndCondition />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={general_tnc_section}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['GeneralTermsAndCondition'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                General Terms & Conditions
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['GeneralTermsAndCondition']}`
        },
        {
            details: !manufacturer_tnc_section ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <ManufacturerTermsAndCondition />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <ManufacturerTermsAndCondition />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={Boolean(manufacturer_tnc_section)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['ManufactureTermsAndCondition'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Manufacturer Terms & Conditions
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['ManufactureTermsAndCondition']}`
        },
        {
            details: !attachments ? (
                <Box width="100%" p={2} sx={accordionBodyDisabled}>
                    <Attachments />
                </Box>
            ) : (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <Attachments />
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={Boolean(attachments)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle(e, QuoteEntity['Attachments'])}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Attachments
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: `${QuoteEntity['Attachments']}`
        }
    ];

    return <Accordion getPanel={onAccordionChange} disableGutters options={accordionArray} />;
}

export default LeftContent;
