// /bom/<bom_id>/product_finder/<bod_id>/search_results?page_number=<number>&limit=<limit>	GET
import { AddSuggestionResponse, AlternatesDetailsResponse, DataSheetProductInfo } from '../apiTypes';
export const alternates: AlternatesDetailsResponse = {
    search_results: [
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/47342/577dc6b8-4de4-43c4-903a-6a5aa4ef44ea/dipl.pdf?abl_version=09%2f30%2f2022+19%3a49%3a07&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/51049f4ad14da2059e4a1098e07e8d0b0284b2bf914306de30503373c0d2676e.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,
            selection_history_count: 50,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',

            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'voltage'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category', 'mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'dimming_protocol'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'lumens', 'voltage'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers', 'product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: true,

            tags: ['acceptable_manufacturers'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: true,

            tags: ['product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/926294/8b23403a-20da-405f-9be8-bcddac8c8c64/recommended-dimmers-for-e-and-p-series.pdf?abl_version=09%2f12%2f2021+06%3a04%3a15&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/266deff6fb8d49826fb8a43057bd6ef6131d05f2940ae436bbe0fb11795e4858.jpeg',
            company_confirm: 75,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: false,

            tags: ['product_category'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: false,

            tags: ['mounting_type'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: false,

            tags: ['environment'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Strong Match',
            previously_selected: false,

            tags: ['environment'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: false,

            tags: ['dimming_protocol'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Medium Match',
            previously_selected: true,

            tags: ['voltage'],
            document_internal_url: ''
        },
        {
            selected_document_id: '',
            content_hash: 'asjdgahj213123',
            document_url:
                'https://lithonia.acuitybrands.com/api/products/getasset/lithonia-lighting/292427/0aaf74c3-64ea-4767-b8b6-d40849965b33/junoeseries-dim.pdf?abl_version=10%2f13%2f2022+17%3a23%3a30&DOC_Type=SPEC_SHEET',
            pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3832373121df35556ffeaa131f35d27a984925455006b9c7ec057726cebc9493.jpeg',
            company_confirm: 0,
            total_confirm: 0,
            elastic_url: '',
            search_result_metadata: {
                user_name: 'Parspec User',
                type: 'elastic',
                date: 'null',
                manufacturer: 'LITHONIA'
            },
            match_strength: 'Weak Match',
            previously_selected: false,

            tags: ['lumens'],
            document_internal_url: ''
        }
    ],
    total_count: 29
};
export const ProductInfo: DataSheetProductInfo[] = [
    {
        id: 1,
        field_name: 'acceptable_manufacturers',
        field_value: ['Legrand', 'Osram'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 2,
        field_name: 'product_category',
        field_value: ['X', 'Y', 'Z'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 3,
        field_name: 'mounting_type',
        field_value: ['X', 'Y', 'Z'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 4,
        field_name: 'environment',
        field_value: ['X', 'Y', 'Z'],
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 5,
        field_name: 'dimming_protocol',
        field_value: ['X', 'Y', 'Z'],
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 6,
        field_name: 'dimming_range',
        field_value: 'X',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 7,
        field_name: 'voltage',
        field_value: 'X',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 8,
        field_name: 'lumens',
        field_value: 'X',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 9,
        field_name: 'lumens_unit',
        field_value: 'X',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 10,
        field_name: 'cri',
        field_value: 'X',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 11,
        field_name: 'temperature',
        field_value: 'x',
        is_locked: false,
        bod_id: 7479
    }
];

export const selectedProduct: AddSuggestionResponse = {
    id: 355299,
    document: 176979,
    type: '',
    manufacturer: '',
    model_number: '',
    document_selection_status: 'CONFIRMED',
    description: '',
    bod_id: 361318,
    is_bod_created: true,
    specification: 'Alternate',
    hide_origin_line: true,
    remember_selection: true,
    created_at: '2023-06-22T06:28:34.040079Z',
    updated_at: '2023-06-22T06:28:34.040123Z'
};
export const sourceDataSheet = {};

export const productSearchHistory = [
    {
        project_id: '1',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 1,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Pending Alternate',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '2',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 2,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Closed Lost',
        location: 'Parspec India',
        specification: 'Lost Alternate',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '3',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 3,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Complete',
        location: 'Parspec India',
        specification: 'Approved Alternate',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '4',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 4,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Submittal Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '5',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 5,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'O&M Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '6',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 6,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '7',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 7,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '8',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 8,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '9',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 9,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '10',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 10,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '11',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 11,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '12',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 12,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '13',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 13,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements', 'datasheet']
    },
    {
        project_id: '14',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 14,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '15',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 15,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '16',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 16,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '17',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 17,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '18',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 18,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '19',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 19,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '20',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 20,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '21',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 21,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '22',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 22,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '23',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 23,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '24',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 24,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '25',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 25,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '26',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 26,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '27',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 27,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '28',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 28,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number', 'product_requirements']
    },
    {
        project_id: '29',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 29,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number']
    },
    {
        project_id: '30',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 30,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number']
    },
    {
        project_id: '31',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 31,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number']
    },
    {
        project_id: '32',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 32,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number']
    },
    {
        project_id: '33',
        project_name: 'Walnut Creek Medical Center',
        bom_id: 33,
        bom_name: 'BOM-ID - Customer Name',
        project_stage: 'Quoting Stage',
        location: 'Parspec India',
        specification: 'Basis of Design',
        match_type: ['model_number']
    }
];
