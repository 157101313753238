import { Box, Button, Select, SelectChangeEvent, Skeleton } from '@parspec/pixel';

import { VIEW_OPTIONS } from '../../constants';

interface TableRightSectionProps {
    selectedView: string;
    isMyViewLoading: boolean;
    isTableLoading: boolean;
    onMyViewChange: (props: SelectChangeEvent<unknown>) => void;
    onManageColumnsClick: () => void;
    onExportClick: () => void;
}

export default function TableRightSection({ isTableLoading, selectedView, onMyViewChange, isMyViewLoading, onManageColumnsClick, onExportClick }: TableRightSectionProps) {
    return (
        <Box display="flex" gap={2} alignItems="flex-end" flexWrap="nowrap" minWidth="max-content">
            <Box>
                {isTableLoading ? (
                    <Skeleton variant="rectangular" width={'147px'} height={'38px'} />
                ) : (
                    <Button onClick={onManageColumnsClick} color="secondary" variant="outlined">
                        Manage Columns
                    </Button>
                )}
            </Box>
            <Box>
                {isTableLoading ? (
                    <Skeleton variant="rectangular" width={'76px'} height={'38px'} />
                ) : (
                    <Button onClick={onExportClick} color="secondary" variant="outlined">
                        Export
                    </Button>
                )}
            </Box>
            <Box>
                {isMyViewLoading ? (
                    <Skeleton variant="rectangular" width={'115px'} height={'38px'} />
                ) : (
                    <Select
                        value={selectedView}
                        label="View"
                        options={VIEW_OPTIONS}
                        optionLabelKeyname="name"
                        optionValueKeyname="value"
                        id="workOptions"
                        labelId="workOptions"
                        onChange={onMyViewChange}
                    />
                )}
            </Box>
        </Box>
    );
}
