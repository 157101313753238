import { BodySmall, Box, H5 } from '@parspec/pixel';

import ManufacturerListTable from './manufacturerListTable';

export default function ManufacturerList() {
    return (
        <Box height="calc(100vh - 165.02px)" width="100%" display="flex" flexDirection="column" gap={6}>
            <Box>
                <H5>Manufacturer Lists</H5>
                <BodySmall limit={false}>
                    These are the manufacturer lists that can be used to filter products when using the product finder. Create lists representing your preferred manufacturers, your recurring Vendor
                    line cards, manufacturers that fall in a certain price range, typical lead time range, or even manufacturers with which you have targets to meet, etc.
                </BodySmall>
            </Box>
            <ManufacturerListTable />
        </Box>
    );
}
