import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ModalHeader, Modal, ModalFooter, Box, BodySmall, Select, Snackbar, BodyXS, SelectChangeEvent } from '@parspec/pixel';

import { MOVE_SECTION_MSG, SELECT_ONE_SECTION_MSG } from '../../shared/constants';
import { useGetBomSectionsQuery, useMoveToSectionMutation, useOptionSectionQuery } from '../queries';
import { SectionApiResponseType } from '../queries/apiTypes';
import { SECTION_TYPE } from './constants';
import OptionWarningModal from './optionWarningModal';
import { useUserEventCount } from '../../shared/UserActivityTracking/TrackActivityCount';

interface MoveSectionDialogProps {
    open: boolean;
    handleDialog: (afterSubmission?: boolean) => void;
    movingIds: number[];
    handleClearSelection: () => void;
    currentSection?: SectionApiResponseType;
    mainProductOptionsInfo?: Record<number, Array<number>>;
    isLotsPresentInBods?: boolean;
    updateLocalData?: (update: boolean) => void;
}

interface SectionOptionsType extends SectionApiResponseType {
    disabled?: boolean;
}

const DIALOG_TEXT = 'Select the section that you would like to move the product(s) to.';

const MoveSectionDialog = (props: MoveSectionDialogProps) => {
    const { bomId } = useParams();
    const { pushUserEventCount } = useUserEventCount();
    const { open, handleDialog, movingIds, handleClearSelection, currentSection, mainProductOptionsInfo, isLotsPresentInBods, updateLocalData } = props;
    const [selectedSection, setSelectedSection] = useState<string>('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [sectionOptions, setSectionOptions] = useState<SectionOptionsType[]>([]);
    const [error, setError] = useState('');
    const [isWarningModalOpen, setWarningModalVisilbity] = useState(false);

    const { mutateAsync: moveToSection, isLoading: moveLoading } = useMoveToSectionMutation(Number(bomId || 0));

    const { data: sectionData } = useGetBomSectionsQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });
    const { data: optionSectionData } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const optionSectionIdsSet = useMemo(() => {
        if (!optionSectionData?.data) {
            return new Set();
        }
        const optionSectionsIds = optionSectionData?.data.map((section) => String(section.id));
        return new Set(optionSectionsIds);
    }, [optionSectionData?.data]);

    useEffect(() => {
        if ((sectionData?.data?.length || optionSectionData?.data?.length) && currentSection) {
            const optionData = (optionSectionData?.data || []).map((optionData) => ({ ...optionData, disabled: isLotsPresentInBods }));
            const sections: SectionOptionsType[] = [...(sectionData?.data || []), ...optionData].filter((item) => item.id !== currentSection.id);
            const selectedSectionId = sections?.[0]?.id && sections?.[0]?.disabled !== true ? String(sections[0].id) : '';
            setSectionOptions(sections);
            setSelectedSection(selectedSectionId);
        }
    }, [sectionData?.data, optionSectionData?.data?.length, currentSection]);

    const handleSelectedSection = (e: SelectChangeEvent<unknown>) => {
        setSelectedSection(e.target.value as string);
        setError('');
    };

    function onSubmit() {
        if (optionSectionIdsSet.has(String(selectedSection)) && mainProductOptionsInfo) {
            const isBodPresentWithOption = movingIds.some((productId) => Array.isArray(mainProductOptionsInfo[productId]));
            if (isBodPresentWithOption) {
                setWarningModalVisilbity(true);
                return;
            }
        }
        handleMoveSection();
    }

    const handleMoveSection = async () => {
        if (selectedSection) {
            try {
                const sectionType = optionSectionIdsSet.has(String(selectedSection)) ? SECTION_TYPE.OPTION : SECTION_TYPE.MAIN;
                await moveToSection({
                    bomId: Number(bomId),
                    input: {
                        destination_section_id: Number(selectedSection),
                        bod_id: movingIds,
                        section_type: sectionType
                    }
                });
                pushUserEventCount({ eventType: 'change_section_clicked', needCummulation: true });
                handleClearSelection();
                setSnackbarMessage(MOVE_SECTION_MSG);
            } finally {
                handleDialog(true);
                setWarningModalVisilbity(false);
                updateLocalData?.(false);
            }
        } else {
            setError(SELECT_ONE_SECTION_MSG);
        }
    };

    function handleWarningCancel() {
        setWarningModalVisilbity(false);
        handleDialog(true);
    }

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Modal
                open={open && !isWarningModalOpen}
                header={<ModalHeader title={'Change Section'} onClose={handleDialog} />}
                footer={<ModalFooter continueButtonLabel={'Move'} onAccept={onSubmit} onReject={handleDialog} isLoading={moveLoading} />}
            >
                <Box width="25vw" my={4}>
                    <BodySmall limit={false} color="neutral.dark">
                        {DIALOG_TEXT}
                    </BodySmall>
                    <Box mt={5}>
                        <Select
                            label=""
                            onChange={handleSelectedSection}
                            options={sectionOptions?.map((item) => ({ name: item.name, id: item.id, disabled: item?.disabled }))}
                            optionLabelKeyname="name"
                            optionValueKeyname="id"
                            value={selectedSection}
                        />
                        {error && (
                            <BodyXS color="error" mt={1}>
                                {error}
                            </BodyXS>
                        )}
                    </Box>
                </Box>
            </Modal>
            {isWarningModalOpen && (
                <OptionWarningModal open={isWarningModalOpen} source="move" onCancel={handleWarningCancel} onConfirm={handleMoveSection} isLoading={moveLoading}></OptionWarningModal>
            )}
        </>
    );
};

export default MoveSectionDialog;
