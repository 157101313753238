import { Grid, BodyMedium, DeleteIcon, IconButton } from '@parspec/pixel';

interface Logo {
    filename: string;
    filepath: string;
}

interface LogoFileUploaded {
    logo: Logo | null;
    onClick: () => void;
    isDeleteing: boolean;
    isUpdating: boolean;
}

const LogoFileUploaded = ({ logo, onClick, isDeleteing, isUpdating }: LogoFileUploaded) => {
    return (
        <Grid container direction={'column'} rowSpacing={1}>
            <Grid item pr={2} pl={2} pt={2}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={10}>
                        <BodyMedium mt={1} limit={true} color="secondary.main">
                            {logo?.filename}
                        </BodyMedium>
                    </Grid>

                    <Grid item xs={2} onClick={onClick} pr={4} sx={isDeleteing || isUpdating ? { opacity: '0.4', pointerEvents: 'none' } : null}>
                        <IconButton>
                            <DeleteIcon
                                sx={{
                                    cursor: 'pointer'
                                }}
                                fontSize={'small'}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LogoFileUploaded;
