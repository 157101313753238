import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Snackbar } from '@parspec/pixel';

import EmailPopup from './EmailPopup';

import { DocumentType, O_M, QUOTE, SUBMITTAL } from './constants';
import AllDocumentCards from './AllDocumentsCards';
import { useAllDocumentsQuery, useDocumentOutOfSyncQuery } from './queries';
import { useDocumentTags } from '../../Dashboard/queries';
import { docType } from '../../shared/constants';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { COMPILE_ERROR_FOR_BOMS_MESSAGE } from '../../shared/constants';
import { DownloadQuotesModal } from './DownloadQuotesPopup';
import { deleteKeysFromSearchParams } from '../../shared/utils/utils';

const DocumentCards: React.FC = () => {
    const { bomId = '', projectId = '' } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const compiledBomIds = searchParams.get('compiledBomIds');
    const errorWhileCompileBomCount = Number(searchParams.get('errorWhileCompileBomCount'));

    const [openEmailPopup, setOpenEmailPopup] = useState(false);
    const [openDownloadQuotesPopup, setOpenDownloadQuotesPopup] = useState(false);

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { data: submittalOutOfSyncData, isLoading: isSubmittalOutOfSyncLoading } = useDocumentOutOfSyncQuery(bomId, DocumentType.SUBMITTAL);

    const { data: o_mOutOfSyncData, isLoading: isOAndMOutOfSyncLoading } = useDocumentOutOfSyncQuery(bomId, DocumentType.O_M);

    const { data: quoteOutOfSyncData, isLoading: isQuoteOutOfSyncLoading } = useDocumentOutOfSyncQuery(bomId, DocumentType.QUOTE);

    const { data: allSubmittalsData, isLoading: isAllSubmittalsLoading } = useAllDocumentsQuery(bomId, DocumentType.SUBMITTAL);

    const { data: allOAndMData, isLoading: isAllOAndMLoading } = useAllDocumentsQuery(bomId, DocumentType.O_M);

    const { data: allQuoteData, isLoading: isAllQuoteDataLoading } = useAllDocumentsQuery(bomId, DocumentType.QUOTE);

    const { data: quotesTagData } = useDocumentTags(DocumentType.QUOTE as docType);

    const { data: submittalTagData } = useDocumentTags(DocumentType.SUBMITTAL as docType);

    const { data: omsTagData } = useDocumentTags(DocumentType.O_M as docType);

    const { enable_quoting } = useFeatureFlag();

    const showEmailPopup = useCallback(() => {
        setOpenEmailPopup(true);
        setOpenDownloadQuotesPopup(false);
    }, []);

    useEffect(() => {
        if (errorWhileCompileBomCount) {
            setSnackbarOpen(COMPILE_ERROR_FOR_BOMS_MESSAGE);
            searchParams.delete('errorWhileCompileBomCount');
            setSearchParams(searchParams);
        }
    }, [errorWhileCompileBomCount]);

    const quotesDocumentData = useMemo(() => {
        if (allQuoteData?.data) {
            return allQuoteData.data.map((document) => {
                const currentDocument = { ...document };
                if (quotesTagData?.data) {
                    for (const quoteTag of quotesTagData.data) {
                        if (currentDocument.id === quoteTag.entity_id && currentDocument.bom === quoteTag.bom_id && quoteTag.entity_type === DocumentType.QUOTE) {
                            currentDocument.status = quoteTag.tag_id!;
                        }
                    }
                }

                return currentDocument;
            });
        }

        return [];
    }, [allQuoteData?.data, quotesTagData?.data]);

    const submittalsDocumentData = useMemo(() => {
        if (allSubmittalsData?.data) {
            return allSubmittalsData?.data.map((document) => {
                const currentDocument = { ...document };
                if (submittalTagData?.data) {
                    for (const submittalTag of submittalTagData.data) {
                        if (currentDocument.id === submittalTag.entity_id && currentDocument.bom === submittalTag.bom_id && submittalTag.entity_type === DocumentType.SUBMITTAL) {
                            currentDocument.status = submittalTag.tag_id!;
                        }
                    }
                }

                return currentDocument;
            });
        }

        return [];
    }, [allSubmittalsData?.data, submittalTagData?.data]);

    const omDocumentData = useMemo(() => {
        if (allOAndMData?.data) {
            return allOAndMData?.data.map((document) => {
                const currentDocument = { ...document };
                if (omsTagData?.data) {
                    for (const omTag of omsTagData.data) {
                        if (currentDocument.id === omTag.entity_id && currentDocument.bom === omTag.bom_id && omTag.entity_type === DocumentType.O_M) {
                            currentDocument.status = omTag.tag_id;
                        }
                    }
                }

                return currentDocument;
            });
        }

        return [];
    }, [allOAndMData?.data, omsTagData?.data]);

    const selectedBomIds = useMemo(() => {
        if (compiledBomIds) {
            const selectedIds = JSON.parse(decodeURIComponent(compiledBomIds));
            setOpenDownloadQuotesPopup(true);
            return [...selectedIds];
        }
        return [];
    }, [compiledBomIds]);

    const handleEmailClose = useCallback((message?: string) => {
        setOpenEmailPopup(false);
        setSearchParams(deleteKeysFromSearchParams(searchParams, ['compiledBomIds', 'errorWhileCompileBomCount']));
        if (message) setSnackbarOpen(message);
    }, []);

    const handleCloseDownloadQuotePopup = useCallback(() => {
        setOpenDownloadQuotesPopup(false);
        setSearchParams(deleteKeysFromSearchParams(searchParams, ['compiledBomIds', 'errorWhileCompileBomCount']));
    }, []);

    return (
        <>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
            <Box width={'100%'} height={'100vh'} overflow={'scroll'}>
                {enable_quoting && (
                    <AllDocumentCards
                        documentType={QUOTE}
                        allDocumentsData={quotesDocumentData}
                        isAllDocumentsLoading={isAllQuoteDataLoading}
                        isDocumentOutOfSyncDataLoading={isQuoteOutOfSyncLoading}
                        documentOutOfSyncData={quoteOutOfSyncData?.data}
                    />
                )}

                <AllDocumentCards
                    documentType={SUBMITTAL}
                    allDocumentsData={submittalsDocumentData!}
                    isAllDocumentsLoading={isAllSubmittalsLoading}
                    isDocumentOutOfSyncDataLoading={isSubmittalOutOfSyncLoading}
                    documentOutOfSyncData={submittalOutOfSyncData?.data}
                />

                <AllDocumentCards
                    documentType={O_M}
                    allDocumentsData={omDocumentData!}
                    isAllDocumentsLoading={isAllOAndMLoading}
                    isDocumentOutOfSyncDataLoading={isOAndMOutOfSyncLoading}
                    documentOutOfSyncData={o_mOutOfSyncData?.data}
                />
            </Box>
            {openEmailPopup && <EmailPopup projectId={Number(projectId)} open={openEmailPopup} onClose={handleEmailClose} selectedBomdIds={selectedBomIds} isBulkAction={true} />}
            {openDownloadQuotesPopup && (
                <DownloadQuotesModal onAccept={showEmailPopup} open={openDownloadQuotesPopup} onClose={handleCloseDownloadQuotePopup} bomIds={selectedBomIds} setSnackbarOpen={setSnackbarOpen} />
            )}
        </>
    );
};

export default DocumentCards;
