export const bomDetails = {
    id: 123,
    primary_contact: 'djdfn',
    secondary_contact: 'ldnlnf',
    name: 'Ace Builders - #176',
    project_id: 3198,
    Is_deleted: false,
    is_submittal_out_of_sync: false,
    Is_o_m_out_of_sync: true,
    Is_quote_out_of_sync: true,
    created_at: '2023-01-01',
    updated_at: '2023-01-01'
};
