import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { endPoints, getService, putService } from './api';
import { queryClient } from 'src/app/queryClient';
import { ServicesResponseType } from './apiTypes';
import { AxiosResponse } from 'axios';

export const getServiceQueryKey = (bomId: number) => [endPoints.getQuoteServiceUrl(bomId)];

export const invalidateServiceQueries = (bomId: number) => {
    queryClient.invalidateQueries(getServiceQueryKey(bomId));
};

export function useServiceQuery(bomId: number, queryOptions: Omit<UseQueryOptions<AxiosResponse<ServicesResponseType>>, 'queryKey' | 'queryFn'>) {
    return useQuery(getServiceQueryKey(bomId), () => getService(bomId), queryOptions);
}

export function useServicePutMutation() {
    return useMutation(putService, {
        onSuccess: (data, variables) => {
            const { bomId } = variables;
            queryClient.setQueryData(getServiceQueryKey(bomId), data);
        }
    });
}
