import { Box, Checkbox, Menu, MoreVertIcon, BodyXS, BodySmall, StarIcon, LinkIcon, Tooltip } from '@parspec/pixel';

import { useCustomerTabContext } from './CustomerTabContext';
import { ICustomerTabContent } from './types';
import { LINKED_START_ICON_MESSAGE, PRIMARY_START_ICON_MESSAGE } from '../constants';

interface IgetMenuOptions {
    onDelete: (bomId: string, isPrimary?: boolean, bomLength?: number) => void;
    onDuplicate: (bomId: string) => void;
    bomId: string;
    isPrimary?: boolean;
    bomLength: number;
}

const TOOL_TIP_MESSAGE = `To delete this BOM, you must first assign another BOM as Primary. Open Manage BOMs to reassign.`;

const getMenuOptions = ({ onDelete, onDuplicate, bomId, isPrimary, bomLength }: IgetMenuOptions) => [
    { label: 'Duplicate', onClick: () => onDuplicate(bomId) },
    {
        label: <BodySmall sx={isPrimary && bomLength !== 1 ? { opacity: '0.4', cursor: 'default', color: 'error' } : { color: 'error' }}>Delete</BodySmall>,
        onClick: () => onDelete(bomId, isPrimary, bomLength),
        color: 'error.main',
        toolTipMessage: isPrimary && bomLength !== 1 ? TOOL_TIP_MESSAGE : ''
    }
];

export const CustomerTabLabel: React.FC<ICustomerTabContent> = ({ labelId, bomName, primaryCustomerName, isPrimary, isLinked, bomLength }) => {
    const { showCheckbox, showMenu, checkBoxState, updateCheckBoxSelection, onDelete, onDuplicate, newBomIds } = useCustomerTabContext();
    const isChecked = checkBoxState?.[labelId] || false;

    return (
        <Box width={1} display="flex" justifyContent="space-between" px={1}>
            <Box display="flex">
                {showCheckbox && (
                    <Box
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox size="medium" sx={{ margin: 0, padding: 0, paddingTop: 2 }} checked={isChecked} label="" onChange={(e) => updateCheckBoxSelection(e, labelId)} />
                    </Box>
                )}

                <Box display="flex" flexDirection="column" gap="2px" alignItems="flex-start" minWidth={136} maxWidth={160}>
                    <Box display="flex" gap="6px">
                        {isPrimary && (
                            <Tooltip title={PRIMARY_START_ICON_MESSAGE}>
                                <StarIcon fontSize="small" color="inherit" />
                            </Tooltip>
                        )}
                        {isLinked && (
                            <Tooltip title={LINKED_START_ICON_MESSAGE}>
                                <LinkIcon fontSize="small" color="inherit" />
                            </Tooltip>
                        )}
                        <BodyXS limit color="inherit" mt="2px">
                            {bomName}
                        </BodyXS>
                    </Box>
                    <BodySmall color="inherit" limit fontWeight={600} height={18}>
                        {primaryCustomerName}
                    </BodySmall>
                </Box>
            </Box>

            <Box display="flex">
                {showMenu && (
                    <Box
                        pt={2}
                        ml={2}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Menu options={getMenuOptions({ onDelete, onDuplicate, bomId: labelId, isPrimary, bomLength })}>
                            <MoreVertIcon />
                        </Menu>
                    </Box>
                )}

                {newBomIds?.has(Number(labelId)) && (
                    <Box width="52px" ml="6px" bgcolor="primary.main" p={1} alignSelf="center" borderRadius="6px">
                        <BodySmall color="white">NEW</BodySmall>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
