import { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Box, ModalFooter, ModalHeader, Modal, TextField, Autocomplete, Select, BodySmall, Skeleton, BodyXS, SelectChangeEvent } from '@parspec/pixel';

import { createBomPushEvents, useManageBOMMutation } from './queries/index';
import { ManageBomResponse, ManageBOMRequestPayload, Project } from './queries/apiTypes';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { useGetAllCompanyDetailsQuery, useGetAllLocationsListQuery } from '../../shared/CreateAndEditProjectModal/queries';
import { useGetProjectBomsQuery, useGetProjectDetailsQuery } from '../../Project/queries';
import { getOwnerCacheListForLocation, setRouteToLocalStorage } from '../../shared/utils/utils';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import CompanyContactSelector from '../../shared/CompanyContactSelector';
import { CompanyResponse, ContactResponse } from '../queries/apiTypes';
import { CustomerTab } from '../../shared/CustomerTab';
import { useOrderProjectBomsMutation } from 'src/features/shared/CustomerTab/queries';
import { ProjectBomResponse } from '../../Project/queries/apiTypes';
import { TAB_VALUES } from '../../BOM/shared/constants';

interface IErrorField {
    projectNameError?: string | null;
    footerError?: string | null;
}

interface BomModalProps {
    open: boolean;
    onCloseClick: () => void;
    onSuccess: (manageBomResponse: ManageBomResponse) => void;
    selectedProjectId?: number;
    selectedBomId?: string;
}

interface FilterOptionProps {
    option: {
        label: string;
        value: string;
    };
}

interface IBomsTabPayload {
    previous_bom_id: number | null;
    bom_ids: (number | null)[];
}

const LINKAGE_OPTIONS = [
    { label: 'Primary', value: 'primary' },
    { label: 'Linked', value: 'linked' },
    { label: 'Unlinked', value: 'unlinked' }
];

export const LinkageFilterOption = ({ option }: FilterOptionProps) => {
    return (
        <BodyXS color={option.value === 'primary' ? 'primary.dark' : 'secondary.light'} sx={{ lineHeight: '20px' }}>
            {option?.label}
        </BodyXS>
    );
};

const BOM_DEFAULT_DATA = {
    id: 1,
    primary_contact: {},
    secondary_contact: {},
    created_by: 1,
    project: 0,
    name: '',
    is_deleted: false,
    is_submittal_out_of_sync: false,
    is_om_out_of_sync: false,
    last_edited_by: '',
    last_edited_at: '',
    updated_at: '',
    created_at: '',
    submittal: null,
    om: null,
    quote: null,
    sales_agent: null,
    is_linked: false,
    is_primary: false
};
export const BomModal = (props: BomModalProps) => {
    const { open, onCloseClick, onSuccess, selectedProjectId, selectedBomId } = props;
    const { pathname, search } = useLocation();
    const { projectId: project_Id } = useParams();

    const { enable_quoting } = useFeatureFlag();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const { data: userProfile } = useGetUserProfileInfoQuery();
    const {
        isLoading: isLocationLoading,
        data: companyLocations,
        isFetching: isLocationFetching
    } = useGetAllLocationsListQuery(userProfile?.data.id || 0, {
        enabled: Boolean(userProfile?.data.id)
    });
    const { isLoading: isOwnerListLoading, data: allCompanyDetails } = useGetAllCompanyDetailsQuery();
    const { isLoading: isManageBomLoading, mutateAsync: manageBom } = useManageBOMMutation(search.includes(TAB_VALUES.FINAL_DOCUMENTS_TAB_VALUE));
    const { mutateAsync: reOrderBomMutation } = useOrderProjectBomsMutation(false);
    const { data: projectData } = useGetProjectDetailsQuery(Number(project_Id)!, {
        enabled: Boolean(project_Id)
    });

    const [location, setLocation] = useState<number>(-1);
    const [projectName, setProjectName] = useState<string>('');
    const [linkage, setLinkage] = useState<(typeof LINKAGE_OPTIONS)[number]['value']>('primary');
    const [primaryCustomer, setPrimaryCustomer] = useState<ContactResponse | null>();
    const [primayCustomerCompany, setPrimaryCustomerCompany] = useState<CompanyResponse | null>();
    const [secondaryCustomer, setSecondaryCustomer] = useState<ContactResponse | null>();
    const [secondaryCustomerCompany, setSecondaryCustomerCompany] = useState<CompanyResponse | null>();
    const [bomName, setBomName] = useState('');
    const [projectId, setProjectId] = useState<number>(selectedProjectId || 0);
    const [salesAgent, setSalesAgent] = useState<number | ''>('');
    const [formErrors, setFormErrors] = useState<IErrorField>({
        projectNameError: null,
        footerError: null
    });
    const [projectListForCorrespondingLocation, setProjectListForCorrespondingLocation] = useState<Project[]>();
    const [createBOMStart, setCreateBOMStart] = useState(0);
    const [newLabelBomIds, setNewLabelBomIds] = useState<number[]>([1]);
    const [customerTabData, setCustomerTabData] = useState<ProjectBomResponse>(selectedBomId || selectedProjectId ? [] : [{ ...BOM_DEFAULT_DATA, is_primary: true }]);
    const [bomsTabPayload, setBomsTabPayload] = useState<Array<IBomsTabPayload>>([]);

    const { data: projectBomsData } = useGetProjectBomsQuery(projectId, { enabled: Boolean(projectId) });
    const [selectedCustomerTab, setSelectedCustomerTab] = useState<number>(1);

    useEffect(() => {
        setCreateBOMStart(Date.now());
        return () => {
            setBomsTabPayload([]);
        };
    }, []);

    useEffect(() => {
        if (allCompanyDetails?.data?.[0]?.company_details) {
            if (projectData?.data?.company) {
                // This was done since, when we create a BOM, we invalidate the allCompanyDetails query
                // So, now, if autoselect preference is true, after create BOM, autoselect api will run and our modal will still be in loading state
                // Due to this, since allCompanyDetails is invalidated, again the data is fetched and the component is reloaded, while the autoselect api is running
                // This causes the company's current Branch location to show up and not our selected location
                if (location === -1) {
                    setLocation(projectData.data.company);
                }
            } else {
                // This was done since, when we create a BOM, we invalidate the allCompanyDetails query
                // So, now, if autoselect preference is true, after create BOM, autoselect api will run and our modal will still be in loading state
                // Due to this, since allCompanyDetails is invalidated, again the data is fetched and the component is reloaded, while the autoselect api is running
                // This causes the company's current Branch location to show up and not our selected location
                // if (location === -1) {
                setLocation(allCompanyDetails?.data[0].company_details.company_id);
                // }
            }
            setProjectListForCorrespondingLocation(allCompanyDetails?.data[0].projects.filter((x) => x.name.trim().length !== 0));

            // This is the case when we open BOM Modal from project screen there we need to show the location of that particular project and also update project list with the list corresponding to that location.
            if (projectData?.data && project_Id) {
                const selectedCompany = allCompanyDetails?.data.find((company) => {
                    return company.company_details.company_id === projectData?.data.company;
                });
                setProjectListForCorrespondingLocation(selectedCompany?.projects.filter((project) => project.name.length !== 0));
            }
        }
    }, [allCompanyDetails, projectData?.data?.company]);

    useEffect(() => {
        if (projectId === 0) {
            const bomIdsSet = new Set(newLabelBomIds);
            const newBomTabsData = customerTabData.filter((obj) => bomIdsSet.has(obj.id));
            const isPresent = newBomTabsData.find((item) => item.is_primary);
            if (!isPresent) {
                newBomTabsData[0].is_primary = true;
                newBomTabsData[0].is_linked = false;
            }
            const index = newBomTabsData.findIndex((item) => item.id === selectedCustomerTab);
            if (index !== -1) {
                if (newBomTabsData[index].is_primary) {
                    setLinkage('primary');
                } else {
                    setLinkage(newBomTabsData[index].is_linked ? 'linked' : 'unlinked');
                }
            } else {
                handleCustomerTabChange('1', [...newBomTabsData]);
            }
            setCustomerTabData([...newBomTabsData]);
        }
    }, [projectId]);

    useEffect(() => {
        if (projectBomsData) {
            const bomIdsSet = new Set(newLabelBomIds);
            let newBomTabData = customerTabData.filter((obj) => bomIdsSet.has(obj.id));
            if (projectBomsData.data.length) {
                const updatedProjectBoms = (projectBomsData?.data || []).map((item) => ({ ...item }));
                if (selectedProjectId) {
                    setCustomerTabData(updatedProjectBoms);
                    const primaryBom = projectBomsData.data.find((bom) => bom.is_primary);
                    if (selectedBomId) {
                        handleCustomerTabChange(selectedBomId, updatedProjectBoms);
                    } else if (primaryBom) handleCustomerTabChange(primaryBom.id.toString(), updatedProjectBoms);
                } else {
                    newBomTabData.forEach((item) => (item.is_primary = false));
                    setCustomerTabData([...updatedProjectBoms, ...newBomTabData]);
                    handleCustomerTabChange('1', [...updatedProjectBoms, ...newBomTabData]);
                }
            } else {
                if (newBomTabData.length === 0) newBomTabData = [{ ...BOM_DEFAULT_DATA, is_primary: true }];
                setCustomerTabData([...newBomTabData]);
                handleCustomerTabChange('1', [...newBomTabData]);
            }
        }
    }, [projectBomsData?.data]);

    useEffect(() => {
        if (selectedProjectId && projectData?.data?.name) {
            setProjectName(projectData.data.name);
            setProjectId(selectedProjectId);
        }
    }, [selectedProjectId, projectData?.data?.name]);

    const handleSubmit = async () => {
        setRouteToLocalStorage(pathname);
        const hasError = customerTabData.every((obj) => !obj.is_primary);
        if (hasError) {
            setFormErrors({ ...formErrors, footerError: 'requiredError' });
            return;
        }
        if (!projectName) {
            setFormErrors({ ...formErrors, projectNameError: 'Required' });
            return;
        }
        const bomIdsSet = new Set(newLabelBomIds);
        const payload: ManageBOMRequestPayload = {
            project_id: projectId,
            project_name: projectName,
            company_id: location,
            bom_list: customerTabData.map((obj) => ({
                id: bomIdsSet.has(obj.id) ? 0 : obj.id,
                name: obj.name,
                is_linked: obj.is_linked,
                is_primary: obj.is_primary,
                primary_contact: {
                    company: obj.primary_contact?.company?.id || null,
                    contact: obj.primary_contact?.contact?.id || null
                },
                secondary_contact: {
                    company: obj.secondary_contact?.company?.id || null,
                    contact: obj.secondary_contact?.contact?.id || null
                },
                sales_agent: typeof obj.sales_agent === 'number' ? obj.sales_agent : obj.sales_agent?.id || null
            }))
        };
        try {
            if (projectId && bomsTabPayload.length) {
                await reOrderBomMutation({
                    projectId,
                    payload: bomsTabPayload
                });
            }
            const response = await manageBom(payload);
            const createdBoms = response.data.created_boms;
            onSuccess({ projectId: response.data.project_id, ...(createdBoms.length && { bomId: createdBoms[0] }) });
            trackIntercomEvent('Create_Bom');
            createBomPushEvents(location!, createBOMStart, response.data.project_id.toString(), userProfile?.data.id);
        } catch (err) {
            console.log(err);
        }
    };

    const errorText = (
        <>
            {formErrors.footerError === 'requiredError' ? (
                <BodyXS color={'error.main'}>A primary BOM is required.</BodyXS>
            ) : formErrors.footerError === 'primaryWarning' ? (
                <>
                    <BodyXS color={'warning.main'}>Changing the Primary BOM will overwrite the content of all linked BOMs.</BodyXS>
                    <BodyXS color={'warning.main'}>This action cannot be undone.</BodyXS>
                </>
            ) : formErrors.footerError === 'linkWarning' ? (
                <>
                    <BodyXS color={'warning.main'}>Linking a BOM to the Primary BOM will overwrite its contents.</BodyXS>
                    <BodyXS color={'warning.main'}>This action cannot be undone.</BodyXS>
                </>
            ) : (
                <></>
            )}
        </>
    );
    const headerSubtitle = (
        <BodySmall color={'text.secondary'}>{selectedProjectId ? 'Add new BOMs or edit existing details.' : 'Add new customers or edit existing BOM and customer details.'}</BodySmall>
    );
    const header = <ModalHeader title={selectedProjectId ? 'Create & Manage BOMs' : 'New Bill of Materials'} onClose={onCloseClick} children={headerSubtitle} />;

    const footer = <ModalFooter isLoading={isManageBomLoading} onReject={onCloseClick} onAccept={handleSubmit} continueButtonLabel="Confirm" helperText={errorText} />;

    const onAutoCompleteProjectValueChanged = (event: any) => {
        if (event.target.value && typeof event.target.value === 'object') {
            setProjectName(event.target.value.name.trim());
            setProjectId(event.target.value.id);
            setFormErrors({});
        } else if (event.target.value && typeof event.target.value === 'string') {
            setProjectName(event.target.value.trim());
            setProjectId(0);
            setFormErrors({});
        } else {
            setProjectId(0);
            setProjectName('');
        }
    };

    const onLocationChanged = (event: any) => {
        setLocation(event.target.value);
        for (let i = 0; i < (allCompanyDetails?.data || []).length; i++) {
            if (allCompanyDetails?.data[i].company_details.company_id === event.target.value) {
                setProjectListForCorrespondingLocation(allCompanyDetails?.data[i].projects);
            }
        }
        setProjectName('');
        setProjectId(0);
        setPrimaryCustomer(null);
        setPrimaryCustomerCompany(null);
        setSecondaryCustomer(null);
        setSecondaryCustomerCompany(null);
        setSalesAgent('');
    };
    const updateCustomerData = (field: string, value: any) => {
        const foundItem = customerTabData.findIndex((item) => item.id === selectedCustomerTab);
        if (foundItem !== -1) {
            const data = [...customerTabData];
            if (field === 'name') {
                data[foundItem].name = value;
            } else if (field === 'primary_contact_company') {
                data[foundItem]['primary_contact'] = { ...data[foundItem]['primary_contact'] };
                data[foundItem]['primary_contact'].company = value;
                data[foundItem]['primary_contact'].contact = undefined;
            } else if (field === 'primary_contact') {
                data[foundItem]['primary_contact'] = { ...data[foundItem]['primary_contact'] };
                data[foundItem]['primary_contact'].contact = value;
            } else if (field === 'secondary_contact_company') {
                data[foundItem]['secondary_contact'] = { ...data[foundItem]['secondary_contact'] };
                data[foundItem]['secondary_contact'].company = value;
                data[foundItem]['secondary_contact'].contact = undefined;
            } else if (field === 'secondary_contact') {
                data[foundItem]['secondary_contact'] = { ...data[foundItem]['secondary_contact'] };
                data[foundItem]['secondary_contact'].contact = value;
            } else if (field === 'sales_agent') {
                data[foundItem]['sales_agent'] = value;
            }

            setCustomerTabData(data);
        }
    };
    const onBomNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBomName(event.target.value);
        updateCustomerData('name', event.target.value);
    };

    const updateCompanyInfo = (field: string, value: CompanyResponse) => {
        if (field === 'primary') {
            setPrimaryCustomerCompany(value);
            setPrimaryCustomer(null);
        } else if (field === 'secondary') {
            setSecondaryCustomerCompany(value);
            setSecondaryCustomer(null);
        }
        updateCustomerData(field + '_contact_company', value);
    };
    const updateContactInfo = (field: string, value: ContactResponse) => {
        if (field === 'primary') {
            setPrimaryCustomer(value);
        } else if (field === 'secondary') {
            setSecondaryCustomer(value);
        }
        updateCustomerData(field + '_contact', value);
    };
    const onProjectNameFocusOut = (event: any) => {
        const error: IErrorField = {};
        if (typeof event === 'object' && !event.name.trim()) {
            error.projectNameError = 'Required';
            setFormErrors(error);
            setProjectId(0);
            setProjectName('');
        } else if (typeof event === 'object' && event.name.trim()) {
            setProjectName(event.name.trim());
            setProjectId(event.id);
        } else if (typeof event === 'string' && !event) {
            error.projectNameError = 'Required';
            setFormErrors(error);
            setProjectId(0);
            setProjectName('');
        } else {
            setProjectName(event.trim());
            setProjectId(0);
            error.projectNameError = null;
            setFormErrors(error);
        }
    };

    const onProjectNameChanged = () => {
        if (formErrors.projectNameError) {
            setFormErrors((state) => ({ ...state, projectNameError: '' }));
        }
    };

    const onBOMNameFocusOut = (event: any) => {
        setBomName(event.target.value.trim());
        updateCustomerData('name', event.target.value.trim());
    };
    const onLinkageChange = (event: any) => {
        const value = event.target.value;
        setLinkage(value);

        const existingTabData = [...customerTabData];

        if (value === 'primary') {
            existingTabData.forEach((item) => {
                if (item.id !== selectedCustomerTab) {
                    item.is_primary = false;
                } else {
                    item.is_primary = true;
                    item.is_linked = false;
                }
            });
        } else {
            existingTabData.forEach((item) => {
                if (item.id === selectedCustomerTab) {
                    if (value === 'linked') {
                        item.is_linked = true;
                    } else {
                        item.is_linked = false;
                    }
                    item.is_primary = false;
                }
            });
        }
        setCustomerTabData(existingTabData);
        const hasExistingBom = projectBomsData?.data.some((obj) => obj.is_primary);
        if (hasExistingBom) {
            const currentTabIndex = customerTabData.findIndex((obj) => obj.id === selectedCustomerTab);
            if (currentTabIndex) {
                const obj = customerTabData[currentTabIndex];
                if (obj.is_primary && value !== 'primary') {
                    setFormErrors({ ...formErrors, footerError: 'primaryWarning' });
                }
                if (value === 'primary') {
                    setFormErrors({ ...formErrors, footerError: 'primaryWarning' });
                }
            }
        }
        const isExistingSelectedCustomer = projectBomsData?.data.some((obj) => obj.id === selectedCustomerTab);
        if (isExistingSelectedCustomer && value === 'linked') {
            setFormErrors({ ...formErrors, footerError: 'linkWarning' });
        }
    };

    const onSalesAgentChanged = (event: SelectChangeEvent<unknown>) => {
        setSalesAgent(Number(event.target.value));
        updateCustomerData('sales_agent', event.target.value);
    };
    const handleCustomerTabChange = (bomId: string, newCustomerTabData?: ProjectBomResponse): void => {
        setSelectedCustomerTab(Number(bomId));
        const foundItem = (newCustomerTabData || customerTabData).find((item: any) => item.id === Number(bomId));
        setBomName(foundItem?.name || '');
        setPrimaryCustomerCompany(checkObjectNotEmpty(foundItem?.primary_contact.company));
        setPrimaryCustomer(checkObjectNotEmpty(foundItem?.primary_contact.contact));
        setSecondaryCustomerCompany(checkObjectNotEmpty(foundItem?.secondary_contact.company));
        setSecondaryCustomer(checkObjectNotEmpty(foundItem?.secondary_contact.contact));
        setSalesAgent(typeof foundItem?.sales_agent === 'number' ? foundItem?.sales_agent : foundItem?.sales_agent?.id || '');
        if (foundItem?.is_primary) {
            setLinkage('primary');
        } else {
            setLinkage(foundItem?.is_linked ? 'linked' : 'unlinked');
        }
    };
    const checkObjectNotEmpty = (obj: any) => {
        if (obj && Object.keys(obj).length !== 0) {
            return obj;
        } else {
            return null;
        }
    };
    const handleAddBom = () => {
        const count = newLabelBomIds.length + 1;
        let newData = { ...BOM_DEFAULT_DATA };
        if (newLabelBomIds.length) {
            newData = { ...newData, id: count };
        } else if (customerTabData.length === 0) {
            newData = { ...newData, id: count, is_primary: true };
        }

        const newids = newLabelBomIds.concat(count);
        setNewLabelBomIds(newids);
        const newCustomTabData = [...customerTabData, newData];
        setCustomerTabData(newCustomTabData);
        handleCustomerTabChange(count.toString(), newCustomTabData);
    };
    const ownerCacheList = useMemo(() => getOwnerCacheListForLocation(allCompanyDetails?.data || []), [allCompanyDetails]);

    const ownerLocationList = ownerCacheList[location] || [];

    const calculatedHeight = useMemo(() => {
        return 64 * customerTabData.length > 344 ? 344 : 64 * customerTabData.length;
    }, [customerTabData.length]);

    const handleOnMoveBomsTab = (sourceIndex: number, destinationIndex: number) => {
        const currentBomsTabData = [...customerTabData];

        if (customerTabData.length > 1) {
            // we must not re-order Primary BOM
            // performing re-ordering on only existing BOMs
            if (
                currentBomsTabData[sourceIndex]?.is_primary ||
                currentBomsTabData[destinationIndex]?.is_primary ||
                !currentBomsTabData[sourceIndex]?.created_at ||
                !currentBomsTabData[destinationIndex]?.created_at
            ) {
                return;
            }
            let previousBomId: number | null = currentBomsTabData[destinationIndex].id!;

            if (sourceIndex > destinationIndex) {
                previousBomId = destinationIndex - 1 >= 0 ? currentBomsTabData[destinationIndex - 1].id! : null;
            }

            const draggedItem = currentBomsTabData.splice(sourceIndex, 1)[0];
            currentBomsTabData.splice(destinationIndex, 0, draggedItem);
            setCustomerTabData(currentBomsTabData);
            setBomsTabPayload((old) => [...old, { previous_bom_id: previousBomId, bom_ids: [draggedItem.id!] }]);
        }
    };
    return (
        <Box>
            <Modal header={header} open={open} footer={footer}>
                <form onSubmit={handleSubmit}>
                    <Box width={selectedProjectId ? 690 : 932} overflow="auto" maxHeight={'70vh'}>
                        <Box display="flex " position="relative" height={'100%'}>
                            {isManageBomLoading && (
                                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                            )}
                            {!selectedProjectId && (
                                <Box width={'30%'} pr={2} pt={2} borderRight={'1px solid'} borderColor={'neutral.main'}>
                                    <BodySmall sx={{ fontWeight: 500, marginBottom: 4 }}>Location</BodySmall>
                                    <Box>
                                        {!(isLocationLoading && isOwnerListLoading) && location && companyLocations && !isLocationFetching ? (
                                            <Select
                                                options={companyLocations?.data[0].company_details || []}
                                                value={location}
                                                label="Branch Location *"
                                                id="location"
                                                labelId="location"
                                                autoComplete="off"
                                                onChange={(event: any) => onLocationChanged(event)}
                                                optionLabelKeyname="company_name"
                                                optionValueKeyname="company_id"
                                            ></Select>
                                        ) : (
                                            <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                                        )}
                                    </Box>
                                    <Box mt={4}>
                                        {Array.isArray(projectListForCorrespondingLocation) ? (
                                            <>
                                                <Autocomplete
                                                    value={{ id: projectId, name: projectName }}
                                                    options={projectListForCorrespondingLocation || []}
                                                    onChange={(event: any) => {
                                                        onAutoCompleteProjectValueChanged(event);
                                                    }}
                                                    onBlur={onProjectNameFocusOut}
                                                    onTextFieldChange={onProjectNameChanged}
                                                    id="projectName"
                                                    label="Project Name *"
                                                    optionlabelkeyname="name"
                                                    freeSolo
                                                    helperText={formErrors.projectNameError!}
                                                    error={Boolean(formErrors.projectNameError)}
                                                />
                                            </>
                                        ) : (
                                            <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                                        )}
                                    </Box>
                                </Box>
                            )}

                            <Box width={selectedProjectId ? '40%' : '30%'} pl={2} pt={2} borderColor={'neutral.main'}>
                                <BodySmall sx={{ fontWeight: 500, marginBottom: 4 }}>BOM(s)</BodySmall>
                                {customerTabData.length > 0 && (
                                    <Box width={'100%'} height={calculatedHeight}>
                                        <CustomerTab
                                            onMoveBomsTab={handleOnMoveBomsTab}
                                            variant={customerTabData.length > 4 ? 'scrollable' : 'standard'}
                                            orientation="vertical"
                                            projectId={projectId}
                                            newBomIds={new Set(newLabelBomIds)}
                                            defaultSelectedBomId={selectedCustomerTab.toString()}
                                            onTabChange={handleCustomerTabChange}
                                            bomOptionsData={customerTabData}
                                        />
                                    </Box>
                                )}
                                <Box onClick={handleAddBom} pt={4}>
                                    <BodySmall color={'primary.main'} fontWeight={500} sx={{ cursor: 'pointer', maxWidth: 'fit-content' }}>
                                        + Add BOM
                                    </BodySmall>
                                </Box>
                            </Box>

                            <Box width={selectedProjectId ? '60%' : '40%'} pt={2} borderLeft={'1px solid'} borderColor={'neutral.main'}>
                                <Box pr={4} pl={2}>
                                    <BodySmall sx={{ fontWeight: 500, marginBottom: 4 }}>Details</BodySmall>
                                </Box>

                                <Box pr={4} pl={2} pb={4} bgcolor={'neutral.light'}>
                                    <Box mt={4} pt={4} display={'flex'} gap={'10px'} alignItems={'center'}>
                                        <BodySmall color={'text.secondary'} fontWeight={500}>
                                            Linkage :{' '}
                                        </BodySmall>
                                        <Box width={'100px'}>
                                            <Select
                                                options={LINKAGE_OPTIONS}
                                                value={linkage}
                                                id="linkage"
                                                autoComplete="off"
                                                onChange={onLinkageChange}
                                                optionLabelKeyname="label"
                                                optionValueKeyname="value"
                                                size="xs"
                                                customOption={(option: { [index: string]: string | number | boolean | undefined }) =>
                                                    LinkageFilterOption({ option: option as { label: string; value: string } })
                                                }
                                                bgColor={'primary.contrastText'}
                                            ></Select>
                                        </Box>
                                    </Box>

                                    <Box mt={4}>
                                        <TextField label="BOM Name" onBlur={onBOMNameFocusOut} autoComplete="off" onChange={onBomNameChanged} value={bomName} bgColor={'primary.contrastText'} />
                                    </Box>
                                    <Box mt={2}>
                                        <Box mb={2} display="flex" alignItems="center" gap={1}>
                                            <BodySmall fontWeight="500" color="text.secondary">
                                                Primary Customer
                                            </BodySmall>
                                            <BodySmall fontWeight="500" color="text.secondary">
                                                (Recipient of documentation)
                                            </BodySmall>
                                        </Box>
                                        <Box>
                                            <CompanyContactSelector
                                                companyInfo={primayCustomerCompany}
                                                setCompanyInfo={(value) => updateCompanyInfo('primary', value)}
                                                contactInfo={primaryCustomer}
                                                setContactInfo={(value) => updateContactInfo('primary', value)}
                                                locationId={location}
                                                isLoading={false}
                                                retainCustomer={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box mt={4}>
                                        <Box mb={2}>
                                            <BodySmall fontWeight="500" color="text.secondary">
                                                Secondary Customer
                                            </BodySmall>
                                        </Box>
                                        <Box>
                                            <CompanyContactSelector
                                                companyInfo={secondaryCustomerCompany}
                                                setCompanyInfo={(value) => updateCompanyInfo('secondary', value)}
                                                contactInfo={secondaryCustomer}
                                                setContactInfo={(value) => updateContactInfo('secondary', value)}
                                                locationId={location}
                                                isLoading={false}
                                                retainCustomer={true}
                                            />
                                        </Box>
                                    </Box>
                                    {enable_quoting && (
                                        <Box mt={4}>
                                            <Box>
                                                <Select
                                                    label="Outside Sales"
                                                    id="SalesAgent"
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    value={salesAgent}
                                                    onChange={onSalesAgentChanged}
                                                    options={ownerLocationList}
                                                    bgColor={'primary.contrastText'}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Modal>
        </Box>
    );
};
