import { BodyBig, BodySmall, TextField, Link } from '@parspec/pixel';

interface IAddtionalCriteriaProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
    onBlur: (arg: React.ChangeEvent<HTMLElement>) => void;
}

const BEST_PRACTICES_URL = 'https://knowledgebase.parspec.io/knowledge/product-finder';
const AdditionalCriteria = ({ value, onChange, onBlur }: IAddtionalCriteriaProps) => {
    return (
        <>
            <BodyBig fontWeight={500} mt={6} mb={4}>
                Additional Criteria
            </BodyBig>
            <BodySmall limit={false} color="secondary.light" mb={3}>
                Refine your search by describing some attributes not listed above you’d like to have included.&nbsp;See our recommended&nbsp;
                <Link target="_blank" href={BEST_PRACTICES_URL} underline="none" sx={{ cursor: 'pointer' }}>
                    best practices here.
                </Link>
            </BodySmall>
            <TextField
                value={value}
                onChange={onChange}
                label=""
                placeholder="Enter a product description, or keywords to refine your product search..."
                multiline
                rows={5}
                onBlur={(evt) => onBlur(evt)}
            />
        </>
    );
};

export default AdditionalCriteria;
