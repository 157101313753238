/* eslint-disable */
import { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AutocompleteChangeReason, Box, Snackbar } from '@parspec/pixel';

import RightSection from './RightSection';
import { SearchProductHeader } from './Header';
import { ProductAttributesForm } from './ProductAttributesForm';
import {
    MULTI_SELECT_ACTIONS,
    MULTI_SELECT_FIELDS_CONSTANT,
    MULTI_SELECT_INIT_STATE,
    SELECT_FIELD_CONSTANT,
    SLIDERFIELD_CONSTANT,
    SLIDER_ACTIONS,
    SLIDER_INIT_STATE,
    MANUFACTURER_FIELD_CONSTANT,
    ADDITIONAL_CRITERIA,
    ADDITIONAL_CRITERIA_CONSTANT,
    ERROR_MESSAGES,
    SEARCH_IMAGES
} from './constants';
import {
    getIsMultiselectField,
    getOptionsArrayForField,
    groupSelectReducer,
    multiSelectReducer,
    sliderReducer,
    getIsManufacturerField,
    getIsSliderField,
    getSelectUnitField,
    getIsManufacturerFieldData
} from './utils';
import { useProductRequirementPatchMutation, useProductRequirementQuery, useSearchProductRequirementsMutation } from './queries';
import { ProdRequirementPatchArgType } from './queries/api';
import { useSnackbar } from 'src/features/shared/hooks/useSnackbar';
import { useGetBomDetails } from '../../queries';
import { useGetProjectDetailsQuery } from '../../../Project/queries';
import { ProductAttributesFormHeader } from './ProductAttributesFormHeader';
import { useGetSelectedDocumentQuery, useGetDatasheetQuery } from '../../Datasheets/AnnotateDatasheet/queries';
import {
    DropdownOptionType,
    FieldData,
    FieldsNameType,
    ManufacturerActionType,
    ManufacturerNameType,
    MultiSelectActionType,
    MultiSelectFieldsNameType,
    RangeSliderValType,
    SearchImageDataType,
    SelectFieldsNameType,
    SliderActionType,
    SliderFieldsNameType
} from './types';
import { useAnalytics } from '../../../shared/hooks/AnalyticsHook';
import { useManufacturerOptions } from '../shared/hooks';
import { ProdRequirementPatchPayload } from './queries/apiTypes';
import { getApplicationCategoryForApi } from '../shared/utils';
import { ALL_MANUFACTURERS_SEARCH_KEYWORD } from '../shared/constants';
import { MOUNTING_TYPE_SET, PRODUCT_CATEGORY_SET } from '../../../shared/utils/constants';
import { useTrackIntercomEvent } from '../../../shared/hooks/useIntercomHook';

export default function SetRequirements() {
    const navigate = useNavigate();
    const params = useParams();
    const bomId = params.bomId !== undefined ? Number(params.bomId) : params.bomId;
    const productId = params.productId !== undefined ? Number(params.productId) : params.productId;
    const projectId = params.projectId !== undefined ? Number(params.projectId) : params.projectId;

    const { trackIntercomEvent } = useTrackIntercomEvent();
    // Requirement queries
    const { data: productRequirementData } = useProductRequirementQuery(bomId, productId);
    const { mutateAsync: updateProductRequirement } = useProductRequirementPatchMutation();
    const { data: bomDetails } = useGetBomDetails(`${bomId}`, { enabled: Boolean(bomId) });
    const { data: projectDetails } = useGetProjectDetailsQuery(bomDetails?.data.project || 0, { enabled: Boolean(bomDetails?.data.project) });
    const { manufacturerOptions, manufacturerGroup } = useManufacturerOptions(projectDetails?.data);

    // Datasheet queries
    const { data: dataSheetData, isFetching: isDatasheetFetching } = useGetSelectedDocumentQuery(`${bomId}`, `${productId}`, 'submittal', 'datasheet', {});
    const selectedDatasheetId = dataSheetData?.data[0].id || 0;
    const datasheetId = dataSheetData?.data[0].document || 0;
    const { data: datasheetFileData, isFetching: isDatasheetFileFetching } = useGetDatasheetQuery(`${bomId}`, `${productId}`, selectedDatasheetId, datasheetId, 'submittal', 'datasheet', {
        enabled: Boolean(dataSheetData?.data[0].id)
    });
    const fileUrl = datasheetFileData?.data.flat_filepath || '';
    const isDatasheetLoading = isDatasheetFetching || isDatasheetFileFetching;

    // refresh queries
    const [isPatchAndSearchInprogress, updatePatchAndSearchInProgress] = useState(false);
    const { mutateAsync: searchProductRequirements, isLoading: isSearchProductRequirementsLoading } = useSearchProductRequirementsMutation();
    const isSearchInProgress = isSearchProductRequirementsLoading || isPatchAndSearchInprogress;

    const [selectedManufacturers, updateSelectedManufacturers] = useReducer(groupSelectReducer, MULTI_SELECT_INIT_STATE);
    const [patchManufacturerAfterChange, updatePatchManufacturerAfterChange] = useState(false);
    const [selectedProductCategory, updateSelectedProductCategory] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedMounting, updateSelectedMounting] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedEnvironment, updateSelectedEnvironment] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedDimmingProtocol, updateSelectedDimmingDimmingProtocol] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedDimmingRange, updateSelectedDimmingRange] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedVoltage, updateSelectedVoltage] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);
    const [selectedApplication, updateSelectedApplications] = useReducer(multiSelectReducer, MULTI_SELECT_INIT_STATE);

    const [productCategoryOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.CATEGORY));
    const [mountingOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE));
    const [environmentOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT));
    const [dimmingProtocolOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL));
    const [dimmingRangeOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE));
    const [voltageOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE));
    const [applicationOptions] = useState(getOptionsArrayForField(MULTI_SELECT_FIELDS_CONSTANT.APPLICATION));

    const [cct, changeCct] = useReducer(sliderReducer, SLIDER_INIT_STATE);
    const [cri, changeCri] = useReducer(sliderReducer, SLIDER_INIT_STATE);
    const [lumen, changeLumen] = useReducer(sliderReducer, SLIDER_INIT_STATE);
    const [lumenUnitOptions] = useState(getOptionsArrayForField(SELECT_FIELD_CONSTANT.LUMENS_UNIT));
    const [currentLumenUnit, changeCurrentLumenUnit] = useState({ id: 0, value: '' });

    const [additionalCriteria, setAdditionalCriteria] = useState({ id: 0, value: '' });

    const [searchImage, setSearchImage] = useState<{ s3ImgPath: string; id: number; localImgPath: string }>({ s3ImgPath: '', id: 0, localImgPath: '' });
    const [attributesErrorMessage, setAttributesErrorMessage] = useState<string>('');

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();
    const productReqUpdateRef = useRef(false);
    const searchProductRef = useRef(false);
    const errorMessageRef = useRef<HTMLDivElement | null>(null);

    const { sendEvent } = useAnalytics();

    const [isClearAllInProgress, setClearAllInProgress] = useState(false);

    useEffect(() => {
        if (productRequirementData?.data) {
            const dropdownCallback = (value: string) => ({ label: value, value });
            productRequirementData?.data.forEach((requirementInfo) => {
                switch (requirementInfo.field_name) {
                    case MULTI_SELECT_FIELDS_CONSTANT.CATEGORY: {
                        const values = Array.isArray(requirementInfo.field_value)
                            ? (requirementInfo.field_value as Array<string>).filter((category) => PRODUCT_CATEGORY_SET.has(category)).map(dropdownCallback)
                            : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedProductCategory({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.APPLICATION: {
                        const values = Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value as Array<string>).map(dropdownCallback) : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedApplications({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT: {
                        const values = Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value as Array<string>).map(dropdownCallback) : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedEnvironment({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE: {
                        const values = Array.isArray(requirementInfo.field_value)
                            ? (requirementInfo.field_value as Array<string>).filter((category) => MOUNTING_TYPE_SET.has(category)).map(dropdownCallback)
                            : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedMounting({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL: {
                        const values = Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value as Array<string>).map(dropdownCallback) : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedDimmingDimmingProtocol({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE: {
                        const values = Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value as Array<string>).map(dropdownCallback) : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedDimmingRange({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE: {
                        const values = Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value as Array<string>).map(dropdownCallback) : MULTI_SELECT_INIT_STATE.values;
                        updateSelectedVoltage({
                            type: MULTI_SELECT_ACTIONS.INIT_SET,
                            value: { values, locked: Boolean(requirementInfo?.is_locked), fieldId: requirementInfo.id }
                        });
                        break;
                    }
                    case SLIDERFIELD_CONSTANT.LUMENS:
                        changeLumen({
                            type: SLIDER_ACTIONS.INIT_SET,
                            value: {
                                values: Array.isArray(requirementInfo.field_value) && requirementInfo.field_value.length === 2 ? (requirementInfo.field_value as [number, number]) : [0, 100000],
                                minVal: 0,
                                maxVal: 100000,
                                locked: Boolean(requirementInfo?.is_locked),
                                fieldId: requirementInfo.id
                            }
                        });
                        break;
                    case SLIDERFIELD_CONSTANT.CCT:
                        changeCct({
                            type: SLIDER_ACTIONS.INIT_SET,
                            value: {
                                values: Array.isArray(requirementInfo.field_value) && requirementInfo.field_value.length === 2 ? (requirementInfo.field_value as [number, number]) : [0, 10000],
                                minVal: 0,
                                maxVal: 10000,
                                locked: Boolean(requirementInfo?.is_locked),
                                fieldId: requirementInfo.id
                            }
                        });
                        break;
                    case SLIDERFIELD_CONSTANT.CRI:
                        changeCri({
                            type: SLIDER_ACTIONS.INIT_SET,
                            value: {
                                values: Array.isArray(requirementInfo.field_value) && requirementInfo.field_value.length === 2 ? (requirementInfo.field_value as [number, number]) : [0, 100],
                                minVal: 0,
                                maxVal: 100,
                                locked: Boolean(requirementInfo?.is_locked),
                                fieldId: requirementInfo.id
                            }
                        });
                        break;
                    case SELECT_FIELD_CONSTANT.LUMENS_UNIT:
                        changeCurrentLumenUnit({ id: requirementInfo.id, value: (requirementInfo.field_value as string) || lumenUnitOptions[0].value });
                        break;
                    case ADDITIONAL_CRITERIA: {
                        setAdditionalCriteria({
                            id: requirementInfo.id,
                            value: requirementInfo.field_value as string
                        });
                        break;
                    }
                    case SEARCH_IMAGES: {
                        setSearchImage({
                            s3ImgPath: Array.isArray(requirementInfo.field_value) ? (requirementInfo.field_value[0] as string) : '',
                            id: requirementInfo.id,
                            localImgPath: ''
                        });
                        break;
                    }
                }
                if (attributesErrorMessage) {
                    setAttributesErrorMessage('');
                }
            });
        }
    }, [productRequirementData?.data]);

    useEffect(() => {
        if (productRequirementData?.data && manufacturerOptions.length > 0) {
            productRequirementData?.data.forEach((requirementInfo) => {
                if (requirementInfo.field_name === MANUFACTURER_FIELD_CONSTANT && Array.isArray(requirementInfo.field_value)) {
                    const selectedManufacturersSet = new Set(requirementInfo?.field_value as Array<string>);
                    const selectedManufacturersForUi =
                        manufacturerOptions.filter((manufacturer) => selectedManufacturersSet.has(manufacturer.value) || selectedManufacturersSet.has(ALL_MANUFACTURERS_SEARCH_KEYWORD)) || [];
                    updateSelectedManufacturers({
                        type: MULTI_SELECT_ACTIONS.INIT_SET,
                        value: { values: selectedManufacturersForUi, fieldId: requirementInfo.id }
                    });
                }
            });
        }
    }, [manufacturerOptions, productRequirementData?.data]);

    useEffect(() => {
        if (patchManufacturerAfterChange) {
            updatePatchManufacturerAfterChange(false);
            handleUpdateForManufacturer();
        }
    }, [selectedManufacturers, patchManufacturerAfterChange]);

    function handleUiPatchForClearAll(reqData: ProdRequirementPatchArgType) {
        return updateProductRequirement(reqData);
    }

    async function handleUiForPatchProductRequirement(reqData: ProdRequirementPatchArgType) {
        productReqUpdateRef.current = true;
        try {
            setSnackbarOpen('Saving...', null);
            await updateProductRequirement(reqData);
        } finally {
            setSnackbarOpen('Saved Successfully', 3000);
            productReqUpdateRef.current = false;
            if (searchProductRef.current) {
                await onSearch();
            }
        }
    }

    async function handleUpdateProductReqForUnit(field: FieldsNameType, fieldValue?: string) {
        let payload;
        switch (field) {
            case SELECT_FIELD_CONSTANT.LUMENS_UNIT:
                payload = {
                    id: currentLumenUnit.id,
                    field_name: field,
                    field_value: fieldValue || currentLumenUnit.value
                };
                break;
        }
        if (payload) {
            handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
        }
    }

    function handleUpdateProductReqForSliderChange(field: FieldsNameType, fieldData: SliderActionType, forClearAll = false) {
        let currentFieldState: RangeSliderValType | undefined;
        switch (field) {
            case SLIDERFIELD_CONSTANT.LUMENS:
                currentFieldState = { ...lumen };
                break;
            case SLIDERFIELD_CONSTANT.CCT:
                currentFieldState = { ...cct };
                break;
            case SLIDERFIELD_CONSTANT.CRI:
                currentFieldState = { ...cri };
                break;
        }
        if (currentFieldState) {
            if (fieldData.type === SLIDER_ACTIONS.LOCK) {
                currentFieldState.locked = fieldData.value as boolean;
            } else if (fieldData.type === SLIDER_ACTIONS.CLEAR) {
                currentFieldState.values = [currentFieldState.minVal, currentFieldState.maxVal];
            } else {
                currentFieldState.values = fieldData.value as [number, number];
            }
            const payload = {
                field_name: field,
                field_value: currentFieldState.values,
                id: currentFieldState.fieldId,
                is_locked: currentFieldState.locked
            };
            if (forClearAll) {
                return handleUiPatchForClearAll({ payload, bomId: bomId || 0, productId: productId || 0 });
            }
            return handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
        }
        return Promise.resolve();
    }

    function handleUpdateForManufacturer() {
        const manufacturerSearchValue =
            selectedManufacturers.values.length === manufacturerOptions.length
                ? [ALL_MANUFACTURERS_SEARCH_KEYWORD]
                : selectedManufacturers.values.map((fieldInfo: DropdownOptionType) => fieldInfo.value);
        const payload = {
            field_name: MANUFACTURER_FIELD_CONSTANT,
            field_value: manufacturerSearchValue,
            id: selectedManufacturers.fieldId
        };
        handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
    }

    function handleUpdateProductReqForMultiSelect(field: FieldsNameType, latestInfoForField: { locked?: boolean; values?: DropdownOptionType[] } = {}, forClearAll = false) {
        let fieldInfosToUpdate;
        switch (field) {
            case MULTI_SELECT_FIELDS_CONSTANT.CATEGORY: {
                fieldInfosToUpdate = { ...selectedProductCategory, ...latestInfoForField };
                if (fieldInfosToUpdate.values && fieldInfosToUpdate.values.length > 0) {
                    fieldInfosToUpdate.values = fieldInfosToUpdate.values.flatMap((categoryVal) => {
                        let categoryValForApi;
                        categoryValForApi = getApplicationCategoryForApi(categoryVal.value);
                        if (Array.isArray(categoryValForApi)) {
                            return categoryValForApi.map((value) => ({ ...categoryVal, value }));
                        }

                        return categoryVal;
                    });
                }
                break;
            }
            case MULTI_SELECT_FIELDS_CONSTANT.APPLICATION:
                fieldInfosToUpdate = { ...selectedApplication, ...latestInfoForField };
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE:
                fieldInfosToUpdate = { ...selectedMounting, ...latestInfoForField };
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT:
                fieldInfosToUpdate = { ...selectedEnvironment, ...latestInfoForField };
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL:
                fieldInfosToUpdate = { ...selectedDimmingProtocol, ...latestInfoForField };
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE:
                fieldInfosToUpdate = { ...selectedDimmingRange, ...latestInfoForField };
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE:
                fieldInfosToUpdate = { ...selectedVoltage, ...latestInfoForField };
                break;
        }
        if (fieldInfosToUpdate) {
            const fieldValues = fieldInfosToUpdate?.values.map((fieldInfo: DropdownOptionType) => fieldInfo.value);

            const payload = {
                field_name: field,
                field_value: fieldValues,
                id: fieldInfosToUpdate.fieldId,
                is_locked: field === MANUFACTURER_FIELD_CONSTANT ? undefined : fieldInfosToUpdate.locked
            };
            if (forClearAll) {
                return handleUiPatchForClearAll({ payload, bomId: bomId || 0, productId: productId || 0 });
            }
            return handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
        }

        return Promise.resolve();
    }

    function updateMultiRowFields(field: MultiSelectFieldsNameType, action: MultiSelectActionType) {
        switch (field) {
            case MULTI_SELECT_FIELDS_CONSTANT.CATEGORY:
                updateSelectedProductCategory(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.APPLICATION:
                updateSelectedApplications(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE:
                updateSelectedMounting(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT:
                updateSelectedEnvironment(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL:
                updateSelectedDimmingDimmingProtocol(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE:
                updateSelectedDimmingRange(action);
                break;
            case MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE:
                updateSelectedVoltage(action);
                break;
        }
    }

    function updateSliderRowFields(field: SliderFieldsNameType, action: SliderActionType) {
        switch (field) {
            case SLIDERFIELD_CONSTANT.LUMENS: {
                changeLumen(action);
                if (action.type === SLIDER_ACTIONS.CLEAR) {
                    changeCurrentLumenUnit({ ...currentLumenUnit, value: lumenUnitOptions[0].value });
                }
                break;
            }
            case SLIDERFIELD_CONSTANT.CCT:
                changeCct(action);
                break;
            case SLIDERFIELD_CONSTANT.CRI:
                changeCri(action);
                break;
        }
    }

    function updateSelectFields(field: SelectFieldsNameType, value: string) {
        switch (field) {
            case SELECT_FIELD_CONSTANT.LUMENS_UNIT:
                changeCurrentLumenUnit({ ...currentLumenUnit, value });
                break;
        }
    }

    function updateManufacturers(action: ManufacturerActionType) {
        updateSelectedManufacturers(action);
    }

    function updateField<T extends FieldsNameType>(field: T, fieldData: FieldData<T>, reason?: AutocompleteChangeReason) {
        if (getIsManufacturerField(field) && getIsManufacturerFieldData(field, fieldData)) {
            if (reason === 'removeOption') {
                updatePatchManufacturerAfterChange(true);
            }
            if (reason === 'removeOption' && fieldData.type === MULTI_SELECT_ACTIONS.UPDATE && Array.isArray(fieldData.value) && fieldData.value.length === 0) {
                updateManufacturers({ type: MULTI_SELECT_ACTIONS.INIT_SET, value: { ...selectedManufacturers, values: [], error: true } });
            } else {
                setAttributesErrorMessage('');
                updateManufacturers(fieldData as ManufacturerActionType);
            }
        } else if (getIsMultiselectField(field)) {
            updateMultiRowFields(field, fieldData as MultiSelectActionType);
            if ((fieldData as MultiSelectActionType).type === MULTI_SELECT_ACTIONS.LOCK) {
                handleUpdateProductReqForMultiSelect(field, { locked: (fieldData as MultiSelectActionType).value as boolean });
            } else if (reason === 'removeOption') {
                handleUpdateProductReqForMultiSelect(field, { values: (fieldData as MultiSelectActionType).value as DropdownOptionType[] });
            } else {
                setAttributesErrorMessage('');
            }
        } else if (getIsSliderField(field)) {
            updateSliderRowFields(field, fieldData as SliderActionType);
            if ((fieldData as SliderActionType).type === SLIDER_ACTIONS.LOCK || (fieldData as SliderActionType).type === SLIDER_ACTIONS.CLEAR) {
                handleUpdateProductReqForSliderChange(field, fieldData as SliderActionType);
                if (field === SLIDERFIELD_CONSTANT.LUMENS && (fieldData as SliderActionType).type === SLIDER_ACTIONS.CLEAR) {
                    handleUpdateProductReqForUnit(SELECT_FIELD_CONSTANT.LUMENS_UNIT, lumenUnitOptions[0].value);
                }
            } else {
                setAttributesErrorMessage('');
            }
        } else if (getSelectUnitField(field)) {
            updateSelectFields(field, fieldData as string);
        } else if (field === ADDITIONAL_CRITERIA_CONSTANT) {
            setAdditionalCriteria({ ...additionalCriteria, value: fieldData as string });
            setAttributesErrorMessage('');
        } else if (field === SEARCH_IMAGES) {
            setSearchImage({ ...searchImage, ...(fieldData as SearchImageDataType) });
            const s3ImgPath = (fieldData as SearchImageDataType)?.s3ImgPath;
            if (s3ImgPath !== undefined) {
                handleReferenceImageUpload(s3ImgPath);
            }
            setAttributesErrorMessage('');
        }
    }

    function getIfSearchIsAllowed() {
        if (getIfManufacturerEmpty()) {
            updateSelectedManufacturers({ type: MULTI_SELECT_ACTIONS.UPDATE_ERROR, value: true });
            setAttributesErrorMessage(ERROR_MESSAGES.NOT_ENOUGH_ATTRIBUTE);
            return false;
        } else if (getIfAttributesAreEmpty() && additionalCriteria.value.trim().length === 0 && searchImage.s3ImgPath.length === 0 && searchImage.localImgPath.length === 0) {
            setAttributesErrorMessage(ERROR_MESSAGES.NOT_ENOUGH_ATTRIBUTE);
            return false;
        }
        return true;
    }

    const clearSearchImage = () => {
        updateField(SEARCH_IMAGES, { s3ImgPath: '', localImgPath: '' });
    };

    async function onSearch() {
        searchProductRef.current = false;
        if (!getIfSearchIsAllowed()) {
            errorMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            updatePatchAndSearchInProgress(false);
            return;
        }
        try {
            await searchProductRequirements({ bomId, productId, refresh: true });
            sendEvent('ProductFinder_SetRequirementsPage_SearchProductsClicked');
            trackIntercomEvent('PF_Search_Products');
            navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/submittal/alternates/selectAlternate`, { state: { track: true } });
        } finally {
            updatePatchAndSearchInProgress(false);
        }
    }

    function handleSearch() {
        setTimeout(() => {
            if (productReqUpdateRef.current) {
                searchProductRef.current = true;
                updatePatchAndSearchInProgress(true);
                return;
            }
            onSearch();
        });
    }

    function getIfManufacturerEmpty() {
        return selectedManufacturers.values.length === 0;
    }

    function getIfAttributesAreEmpty() {
        return (
            selectedProductCategory.values.length === 0 &&
            selectedApplication.values.length === 0 &&
            selectedMounting.values.length === 0 &&
            selectedEnvironment.values.length === 0 &&
            selectedDimmingProtocol.values.length === 0 &&
            selectedDimmingRange.values.length === 0 &&
            selectedVoltage.values.length === 0 &&
            lumen.values[0] === lumen.minVal &&
            lumen.values[1] === lumen.maxVal &&
            cct.values[0] === cct.minVal &&
            cct.values[1] === cct.maxVal &&
            cri.values[0] === cri.minVal &&
            cri.values[1] === cri.maxVal
        );
    }

    function getIfFieldsAreEmpty() {
        return getIfManufacturerEmpty() && getIfAttributesAreEmpty();
    }

    function handleMultiSelectBlur(field: MultiSelectFieldsNameType | ManufacturerNameType) {
        if (field === MANUFACTURER_FIELD_CONSTANT && selectedManufacturers.values.length === 0) {
            updateSelectedManufacturers({ type: MULTI_SELECT_ACTIONS.UPDATE_ERROR, value: true });
            return;
        } else if (field === MANUFACTURER_FIELD_CONSTANT) {
            handleUpdateForManufacturer();
            return;
        }
        handleUpdateProductReqForMultiSelect(field);
    }

    const handleAdditionalCriteriaOnBlur = (value?: string, forClearAll = false) => {
        const payload: ProdRequirementPatchPayload = {
            id: additionalCriteria.id,
            field_name: ADDITIONAL_CRITERIA,
            field_value: value ?? additionalCriteria.value
        };
        if (forClearAll) {
            return handleUiPatchForClearAll({ payload, bomId: bomId || 0, productId: productId || 0 });
        }
        return handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
    };

    function handleClearAllForMfg() {
        updateManufacturers({ type: MULTI_SELECT_ACTIONS.INIT_SET, value: { ...selectedManufacturers, values: [], error: true } });
        const payload = {
            field_name: MANUFACTURER_FIELD_CONSTANT,
            field_value: [],
            id: selectedManufacturers.fieldId
        };
        return handleUiPatchForClearAll({ payload, bomId: bomId || 0, productId: productId || 0 });
    }

    function handleMultiSelectClearAll() {
        const apiPromiseArray = [];
        for (const multiselectField of Object.values(MULTI_SELECT_FIELDS_CONSTANT)) {
            updateMultiRowFields(multiselectField, { type: MULTI_SELECT_ACTIONS.UPDATE, value: [] });
            const multiSelectPromise = handleUpdateProductReqForMultiSelect(multiselectField, { values: [] }, true);
            apiPromiseArray.push(multiSelectPromise);
        }

        return apiPromiseArray;
    }

    function handleSliderClearAll() {
        const apiPromiseArray = [];
        for (const sliderField of Object.values(SLIDERFIELD_CONSTANT)) {
            updateSliderRowFields(sliderField, { type: SLIDER_ACTIONS.CLEAR });
            const sliderPromise = handleUpdateProductReqForSliderChange(sliderField, { type: SLIDER_ACTIONS.CLEAR }, true);
            apiPromiseArray.push(sliderPromise);
        }

        return apiPromiseArray;
    }

    function handleAdditionalCriteriaClearAll() {
        updateField(ADDITIONAL_CRITERIA_CONSTANT, '');
        return handleAdditionalCriteriaOnBlur('', true);
    }

    function handleSearchImageClearAll() {
        setSearchImage({ ...searchImage, s3ImgPath: '', localImgPath: '' });
        return handleReferenceImageUpload('', true);
    }

    async function handleClearAll() {
        try {
            setSnackbarOpen('Saving...', null);
            setClearAllInProgress(true);

            const apiPromiseArray = [];
            const mfgPromise = handleClearAllForMfg();
            apiPromiseArray.push(mfgPromise);

            const multiSelectPromiseArray = handleMultiSelectClearAll();
            apiPromiseArray.push(...multiSelectPromiseArray);

            const sliderClearAll = handleSliderClearAll();
            apiPromiseArray.push(...sliderClearAll);

            const additionalCriteriaPromise = handleAdditionalCriteriaClearAll();
            apiPromiseArray.push(additionalCriteriaPromise);

            const searchImgPromise = handleSearchImageClearAll();
            apiPromiseArray.push(searchImgPromise);

            await Promise.allSettled(apiPromiseArray);
        } finally {
            setSnackbarOpen('Saved Successfully', 3000);
            setClearAllInProgress(false);
        }
    }

    const handleReferenceImageUpload = (filePath: string, forClearAll = false) => {
        const payload: ProdRequirementPatchPayload = {
            id: searchImage.id,
            field_name: SEARCH_IMAGES,
            field_value: filePath.trim().length === 0 ? '' : [filePath]
        };

        if (forClearAll) {
            return handleUiPatchForClearAll({ payload, bomId: bomId || 0, productId: productId || 0 });
        }

        return handleUiForPatchProductRequirement({ payload, bomId: bomId || 0, productId: productId || 0 });
    };

    return (
        <>
            <Snackbar
                open={snackbarInfo.open}
                message={snackbarInfo.message}
                onClose={(_e, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setSnackbarClose();
                }}
                autoHideDuration={snackbarInfo.autoHideDuration}
            />
            <Box width="100%" height="100vh" display="flex" flexDirection="column">
                <SearchProductHeader onSearchProduct={handleSearch} isSearchRefreshInProgress={isSearchInProgress} />
                <Box width="100%" flex={1} display="flex" overflow="hidden">
                    <Box width="50%" bgcolor="neutral.light" borderRight="1px solid" borderColor="neutral.main" height="100%" display="flex" flexDirection="column" overflow="auto">
                        <ProductAttributesFormHeader
                            isDataSheetLoading={isDatasheetLoading}
                            dataSheetId={selectedDatasheetId}
                            getIfFieldsAreEmpty={getIfFieldsAreEmpty}
                            isActionDisabled={isSearchInProgress || isClearAllInProgress}
                            onClearAll={handleClearAll}
                            errorMessage={attributesErrorMessage}
                            errorMessageRef={errorMessageRef}
                        />
                        <ProductAttributesForm
                            additionalCriteria={additionalCriteria.value}
                            selectedManufacturers={selectedManufacturers}
                            selectedProductCategory={selectedProductCategory}
                            selectedMounting={selectedMounting}
                            selectedEnvironment={selectedEnvironment}
                            selectedDimmingProtocol={selectedDimmingProtocol}
                            selectedDimmingRange={selectedDimmingRange}
                            selectedVoltage={selectedVoltage}
                            selectedApplication={selectedApplication}
                            lumen={lumen}
                            cct={cct}
                            cri={cri}
                            currentLumenUnit={currentLumenUnit.value}
                            manufacturerOptions={manufacturerOptions}
                            manufacturerGroup={manufacturerGroup}
                            productCategoryOptions={productCategoryOptions}
                            mountingOptions={mountingOptions}
                            environmentOptions={environmentOptions}
                            dimmingProtocolOptions={dimmingProtocolOptions}
                            dimmingRangeOptions={dimmingRangeOptions}
                            lumenUnitOptions={lumenUnitOptions}
                            applicationOptions={applicationOptions}
                            voltageOptions={voltageOptions}
                            onFieldChange={updateField}
                            onMultiSelectBlur={handleMultiSelectBlur}
                            onSliderBlur={handleUpdateProductReqForSliderChange}
                            onUnitSelectBlur={handleUpdateProductReqForUnit}
                            isActionDisabled={isSearchInProgress || isClearAllInProgress}
                            onAdditionalCriteriaBlur={handleAdditionalCriteriaOnBlur}
                            searchImageS3Path={searchImage.s3ImgPath}
                            searchImageLocalPath={searchImage.localImgPath}
                            clearSearchImage={clearSearchImage}
                        />
                    </Box>
                    <RightSection isDatasheetLoading={isDatasheetLoading} fileUrl={fileUrl} />
                </Box>
            </Box>
        </>
    );
}
