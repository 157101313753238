import { useCallback, useMemo, useState } from 'react';

import { QueryObserverResult } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { DocType } from '../../../constants';
import { useCompileTimeQuery, useCompileTimeForQuote } from '../queries';
import { useGetPreviewQuote } from '../../compileQuote/PreviewQuote/queries';
import { useGetProjectBomsQuery } from 'src/features/Project/queries';
import { useQuoteContext } from '../../compileQuote/QuoteContext';
import { useHeaderFooterDataQuery } from '../../compileQuote/queries';

interface IBoms {
    bomIds: Array<number>;
}

interface ICompileTimeParams extends IBoms {
    documentType: string;
}

export const useAggregatedCompileTime = ({ bomIds, documentType }: ICompileTimeParams) => {
    const data = useCompileTimeQuery(bomIds, documentType as DocType, {
        enabled: false,
        retry: 3,
        retryDelay: 1000
    });

    const [isFetching, setIsFetching] = useState(false);

    const refetchQueries = useCallback(async () => {
        setIsFetching(true);
        if (data.length && bomIds.length) {
            const refetchPromiseArr: Array<Promise<QueryObserverResult<AxiosResponse>>> = [];
            for (const compile of data) {
                refetchPromiseArr.push(compile.refetch());
            }
            await Promise.all(refetchPromiseArr);
            setIsFetching(false);
        }
    }, [data, bomIds]);

    const aggregatedCompileTime = useMemo(() => {
        let aggregatredCompileTimeValue = 0;
        if (data.length && bomIds.length) {
            data.forEach((compile) => {
                if (compile?.data?.data?.['Total Time']) {
                    aggregatredCompileTimeValue += compile?.data?.data['Total Time'];
                }
            });
        }

        return aggregatredCompileTimeValue;
    }, [data, bomIds]);

    return { refetch: refetchQueries, aggregatedCompileTime, isFetching };
};

export const useAggregatedCompileTimeForPreviewQuote = ({ bomIds, documentType }: ICompileTimeParams) => {
    const data = useCompileTimeForQuote(bomIds, documentType, {
        enabled: false,
        retry: 3,
        retryDelay: 1000
    });

    const [isFetching, setIsFetching] = useState(false);

    const refetchQueries = useCallback(async () => {
        setIsFetching(true);
        if (data.length && bomIds.length) {
            const refetchPromiseArr: Array<Promise<QueryObserverResult<AxiosResponse>>> = [];
            for (const compile of data) {
                refetchPromiseArr.push(compile.refetch());
            }
            await Promise.all(refetchPromiseArr);
            setIsFetching(false);
        }
    }, [data, bomIds]);

    const aggregatedCompileTimeForQuote = useMemo(() => {
        let aggregatredCompileTimeValue = 0;
        if (data.length) {
            data.forEach((compile) => {
                if (compile?.data?.data?.['Total Time']) {
                    aggregatredCompileTimeValue += compile?.data?.data['Total Time'];
                }
            });
        }

        return aggregatredCompileTimeValue;
    }, [data]);

    return { refetch: refetchQueries, aggregatedCompileTimeForQuote, isFetching };
};

export const useGetPreviewQuoteUrl = ({ bomIds: bomIdsArr }: IBoms) => {
    const data = useGetPreviewQuote(bomIdsArr, {
        enabled: false,
        retry: 3,
        retryDelay: 1000
    });

    const refetchQueries = useCallback(async () => {
        if (data.length && bomIdsArr.length) {
            const refetchPromiseArr: Array<Promise<QueryObserverResult<AxiosResponse>>> = [];
            for (const compile of data) {
                refetchPromiseArr.push(compile.refetch());
            }
            await Promise.all(refetchPromiseArr);
        }
    }, [data, bomIdsArr]);

    const pdfUrls = useMemo(() => {
        const urls: Record<string, string> = {};
        const bomIds: number[] = [];
        if (data.length) {
            data.forEach((pdf) => {
                if (pdf?.data?.data?.document_link_flat) {
                    urls[pdf.data.data.bom] = pdf.data.data.document_link_flat;
                    bomIds.push(pdf.data.data.bom);
                }
            });
        }

        return { urls, boms: bomIds, bomsLength: data.length };
    }, [data]);

    return { refetch: refetchQueries, pdfUrls };
};

export const useGetAllBomIds = (projectId: number, submittalsOmBomId?: number[]) => {
    const { data: projectBomsData, isLoading } = useGetProjectBomsQuery(projectId, {
        enabled: Boolean(projectId) && !submittalsOmBomId
    });

    const bomIds = useMemo(() => {
        if (projectBomsData?.data && !submittalsOmBomId) {
            let primaryBomIndex = -1;
            const bomIds = projectBomsData.data.map((bom, index) => {
                if (bom.is_primary) {
                    primaryBomIndex = index;
                }
                return bom.id;
            });
            if (primaryBomIndex !== -1) {
                const [primaryBom] = bomIds.splice(primaryBomIndex, 1);
                bomIds.unshift(primaryBom);
            }
            return bomIds;
        } else if (submittalsOmBomId) {
            return [...submittalsOmBomId];
        }

        return [];
    }, [projectBomsData?.data, submittalsOmBomId]);

    return { bomIds, isLoading: submittalsOmBomId ? false : isLoading };
};

export const useCheckIfCreateQuoteDisabled = () => {
    const { bomId, isInitiateApiCalls, error: quoteError } = useQuoteContext();
    const { data: headerFooterData } = useHeaderFooterDataQuery(bomId, isInitiateApiCalls);

    const isDisabled = useMemo(() => {
        if (headerFooterData?.header_footer_section) {
            return Object.values(quoteError).some((errorName) => errorName.length > 0) && headerFooterData.header_footer_section.is_active;
        }
        return false;
    }, [quoteError, headerFooterData?.header_footer_section]);

    return isDisabled;
};
