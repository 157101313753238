import { useMutation, useQuery, UseQueryOptions, QueryKey } from '@tanstack/react-query';

import { queryClient } from 'src/app/queryClient';
import { convertToQueryStringFormat, downloadFile } from '../shared/utils';
import {
    addBomData,
    deleteBomData,
    duplicateBomData,
    exportBomData,
    getBodDetail,
    getBomData,
    getBomTabStats,
    getDatasheetTabData,
    getDatasheetTabStats,
    getDatsheetDetail,
    getOmData,
    getOmTabStats,
    reorderBomData,
    reuseDatasheet,
    toggleHideBomData,
    updateBomData,
    updateDatasheetMetaData,
    getBomDetails,
    endPoints,
    datasheetAutoSearch,
    omAutoSearch,
    getOmDetail,
    sortProductList,
    updateSectionDetails,
    deleteSection,
    createSection,
    moveToSection,
    getBomSections,
    createKit,
    getOptionSection,
    postApproveOptionProduct,
    createOptionSection,
    deleteOptionSection,
    updateOptionSectionDetails,
    getCustomisedColumnData,
    toggleHighLightBomData,
    updateSectionOrder,
    addMultipleBods,
    updateGrandTotal
} from './apis';
import {
    BodResponse,
    DatasheetTabDataResponse,
    MetadataStatusRequest,
    SectionApiResponseType,
    UpdateSectionOrderTypes,
    ColumnKeys,
    DatasheetTabStatsResponse,
    OmTabStatsResponse,
    BomStatsResponse,
    OmDataResponse,
    BomDetails
} from './apiTypes';

import { invalidateGetSelectedDocument } from '../Datasheets/AnnotateDatasheet/queries';
import { AxiosResponse } from 'axios';
import { invalidatePricingBodQuery } from '../pricing&LeadTime/queries';
import { invalidaLotQueries } from '../pricing&LeadTime/lotPriceModal/queries';
import { SECTION_TYPE_FOR_PAYLOAD } from '../shared/constants';
import { pushEvent } from '../../shared/UserActivityTracking/EventService';
import { DEFAULT_COLUMN_DETAILS } from '../shared/constants';
import { invalidateBomInfoInDashboard } from 'src/features/Dashboard/queries';

//Keys
export const getBomDataKey = (bomId: string) => [endPoints.bodList(bomId)];
export const getDatasheetTabDataKey = (bomId: string) => [endPoints.datasheetTabData(bomId)];
const getDataSheetTabStatsKey = (bomId: string) => [endPoints.datasheetTabStats(bomId)];
const getOmTabStatsKey = (bomId: string) => [endPoints.omTabStats(bomId)];
const getBomTabStatsKey = (bomId: string) => [endPoints.bodStats(bomId)];
export const getOmTabDataKey = (bomId: string) => [endPoints.omTabData(bomId)];
const getDatasheetDetailKey = (bomId: string, bodId: string) => [endPoints.datasheetDetail(bomId, bodId)];
const getBodDetailKey = (bomId: string, bodId: string) => [endPoints.bodDetail(bomId, bodId)];
export const getBomDetailsKey = (bomId: string) => [endPoints.bomDetail(bomId)];
const getOmDetailKey = (bomId: string, bodId: string) => [endPoints.omDetail(bomId, bodId)];
const getBomSectionsKey = (bomId: number) => [endPoints.getSectionsUrl(bomId)];
const getOptionSectionsKey = (bomId: number) => [endPoints.getOptionSectionsUrl(bomId)];
const getBomDuplicateKey = (bomId: string) => [endPoints.getDuplicateBodUrl(bomId)];
const getChangeSectionKey = (bomId: number) => [endPoints.getMoveToSectionUrl(bomId)];
const getApproveOptionKey = (bomId: number) => [endPoints.approveOptionsProductUrl(bomId)];
const getCreateKitKey = (bomId: number) => [endPoints.getKitsUrl(bomId)];
const getCustomisedColumnKey = () => ['customised'];
//Queries

export const useGetBomDataQuery = (bomId: string, options?: UseQueryOptions<AxiosResponse<BodResponse[], any>, unknown, AxiosResponse<BodResponse[], any>, QueryKey>) =>
    useQuery<AxiosResponse<BodResponse[], any>, unknown, AxiosResponse<BodResponse[], any>, QueryKey>(getBomDataKey(bomId), () => getBomData(bomId), options);

export const invalidateBomDataQuery = (bomId: number) => {
    queryClient.invalidateQueries(getBomDataKey(String(bomId)));
};

export const invalidateDatasheetDataQuery = (bomId: string) => {
    queryClient.invalidateQueries(getDatasheetTabDataKey(String(bomId)));
};

export const invalidateOmDataQuery = (bomId: string) => {
    queryClient.invalidateQueries(getOmTabDataKey(String(bomId)));
};

export const invalidateBomStatsQuery = (bomId: string) => {
    queryClient.invalidateQueries(getBomTabStatsKey(String(bomId)));
};

export const invalidateDatasheetStatsQuery = (bomId: string) => {
    queryClient.invalidateQueries(getDataSheetTabStatsKey(String(bomId)));
};

export const invalidateOmStatsQuery = (bomId: string) => {
    queryClient.invalidateQueries(getOmTabStatsKey(String(bomId)));
};

export const invalidateGetSectionsQuery = (bomId: number) => {
    queryClient.invalidateQueries(getBomSectionsKey(bomId));
};

export const invalidateGetOptionSectionQuery = (bomId: number) => {
    queryClient.invalidateQueries(getOptionSectionsKey(bomId));
};

export const invalidateCustomisedColumnQuery = () => {
    queryClient.invalidateQueries(getCustomisedColumnKey());
};

export function getIfKeyIsTableMutationKey(bomId: string, key: string) {
    return (
        key === getBomDuplicateKey(bomId)[0] ||
        key === getBomDataKey(bomId)[0] ||
        key === getChangeSectionKey(Number(bomId))[0] ||
        key === getApproveOptionKey(Number(bomId))[0] ||
        key === getCreateKitKey(Number(bomId))[0]
    );
}

export const useGetDatasheetTabDataQuery = (bomId: string, options: Omit<UseQueryOptions<AxiosResponse<DatasheetTabDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getDatasheetTabDataKey(bomId), () => getDatasheetTabData(bomId), options);

export const useGetDatasheetTabStatsQuery = (bomId: string, options: Omit<UseQueryOptions<AxiosResponse<DatasheetTabStatsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getDataSheetTabStatsKey(bomId), () => getDatasheetTabStats(bomId), options);

export const useGetOmTabStatsQuery = (bomId: string, options: Omit<UseQueryOptions<AxiosResponse<OmTabStatsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getOmTabStatsKey(bomId), () => getOmTabStats(bomId), options);

export const useGetBomTabStatsQuery = (bomId: string, options: Omit<UseQueryOptions<AxiosResponse<BomStatsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getBomTabStatsKey(bomId), () => getBomTabStats(bomId), options);

export const useGetOmDataQuery = (bomId: string, options: Omit<UseQueryOptions<AxiosResponse<OmDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getOmTabDataKey(bomId), () => getOmData(bomId), options);

export const useGetBomSectionsQuery = (bomId: number, options?: Omit<UseQueryOptions<AxiosResponse<SectionApiResponseType[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getBomSectionsKey(bomId), () => getBomSections(bomId), options);

//Using 'any' because this is graphQL response instead of AxiosResponse which doesn't have 'data' attribute
export const useGetCustomisedColumnQuery = (userId: number, options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
    useQuery(getCustomisedColumnKey(), () => getCustomisedColumnData(userId), options);

//Mutations

export const useReorderBomDataMutation = () =>
    useMutation(reorderBomData, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(Number(bomId));
        }
    });

export const useUpdateGrandTotalMutation = () =>
    useMutation(updateGrandTotal, {
        onSuccess: () => {
            invalidateBomInfoInDashboard();
        }
    });

export const useDeleteBomDataMutation = () =>
    useMutation(deleteBomData, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            invalidatePricingBodQuery(Number(bomId));
            invalidaLotQueries(bomId);
            invalidateBomDataQuery(Number(bomId));
        }
    });

export const useDuplicateBomDataMutation = (bomId: string) =>
    useMutation(duplicateBomData, {
        mutationKey: getBomDuplicateKey(bomId),
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(Number(bomId));
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            invalidateDatasheetDataQuery(bomId);
            invalidateOmDataQuery(bomId);
            invalidatePricingBodQuery(Number(bomId));
            invalidaLotQueries(bomId);
        }
    });

export const useToggleHideBomDataMutation = () =>
    useMutation(toggleHideBomData, {
        onSuccess: (_, request) => {
            const { bomId, input } = request;
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            queryClient.setQueryData(getBomDataKey(bomId!), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.map((item: any) => {
                        const toggledItem = input.find((itm: any) => itm === item.id);
                        if (toggledItem)
                            return {
                                ...item,
                                is_hidden: !item.is_hidden
                            };
                        return item;
                    })
                };
            });
        }
    });

export const useAddBomDataMutation = (bomId: string) =>
    useMutation(addBomData, {
        mutationKey: getBomDataKey(bomId),
        onSuccess: (response, request) => {
            const {
                bomId,
                input: { previous_bod_id: previousBodId }
            } = request;
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            invalidateDatasheetDataQuery(bomId);
            invalidateOmDataQuery(bomId);
            invalidatePricingBodQuery(Number(bomId));
            queryClient.setQueryData(getBomDataKey(bomId!), (oldData: any) => {
                let updatedData = oldData?.data?.length ? [...(oldData?.data || [])] : [];
                if (updatedData.length) {
                    const prevIndex = updatedData.findIndex((item: BodResponse) => item.id === previousBodId);
                    updatedData.splice(prevIndex + 1, 0, response.data);
                } else {
                    updatedData = [response.data];
                }
                return {
                    ...oldData,
                    data: updatedData
                };
            });
        }
    });

export const useUpdateBomDataMutation = () =>
    useMutation(updateBomData, {
        onMutate: (request) => {
            const { bomId, input, isInvalidateBodList = false } = request;
            const { id, ...rest } = input;
            if (!isInvalidateBodList) {
                queryClient.setQueryData(getBomDataKey(bomId!), (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData.data.map((item: BodResponse) => (item.id === id ? { ...item, ...rest } : item))
                    };
                });
            }
            queryClient.setQueryData(getBodDetailKey(bomId, String(id)), (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: { ...oldData.data, ...rest }
                    };
                }
            });
        },
        onSuccess: (_, request) => {
            const { bomId, isInvalidateBodList = false } = request;
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            if (isInvalidateBodList) {
                invalidateBomDataQuery(Number(bomId));
            }
        }
    });

export const useUpdateDatasheetMetaDataMutation = () =>
    useMutation(updateDatasheetMetaData, {
        onMutate: (request) => {
            const { bomId, bodId, notes } = request;
            queryClient.setQueryData(getDatasheetTabDataKey(bomId!), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.map((item: DatasheetTabDataResponse) => (item.bod_id === bodId ? { ...item, notes } : item))
                };
            });
            queryClient.setQueryData(getDatasheetDetailKey(bomId, String(bodId)), (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: { ...oldData.data, notes }
                    };
                }
            });
        }
    });

export const useReuseDatasheetMutation = () =>
    useMutation(reuseDatasheet, {
        onSuccess: (response, request) => {
            const { bomId, documentType, payload } = request;
            if (documentType === 'datasheet') {
                invalidateDatasheetStatsQuery(bomId);
                invalidateGetSelectedDocument(String(bomId), String(payload.bod_id), 'submittal', documentType);

                queryClient.setQueryData(getDatasheetTabDataKey(bomId!), (oldData: any) => {
                    const newDataIds = response.data.map((item: any) => Number(item.bod_id));
                    const updatedData = [...oldData.data];
                    updatedData.forEach((row: any) => {
                        const index = newDataIds.indexOf(row.bod_id);
                        if (index !== -1) {
                            const newRowData = response.data[index];
                            row.selected_datasheet_id = newRowData.id;
                            row.selected_datasheet = {
                                annotations_status: newRowData.annotations_status,
                                document: newRowData.document,
                                document_selection_status: newRowData.document_selection_status,
                                hidden_pages: newRowData.hidden_pages
                            };
                        }
                    });
                    return {
                        ...oldData,
                        data: updatedData
                    };
                });

                invalidateDatasheetDataQuery(bomId);
            } else {
                invalidateOmStatsQuery(bomId);
                invalidateOmDataQuery(bomId);
            }
        }
    });

export const useExportDataMutation = () =>
    useMutation(exportBomData, {
        onSuccess: (response) => {
            downloadFile(response.data.link);
        }
    });

export const useGetBodDetailQuery = (bomId: string, bodId: string, options: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) =>
    useQuery(getBodDetailKey(bomId, bodId), () => getBodDetail(bomId, bodId), options);

export const useUpdateBodDMutation = () =>
    useMutation(updateBomData, {
        onSuccess: (_, request) => {
            const { bomId, input } = request;
            const { id, ...rest } = input;
            queryClient.setQueryData(getBodDetailKey(bomId, String(id)), (oldData: any) => {
                return {
                    ...oldData,
                    data: { ...oldData.data, ...rest }
                };
            });
            queryClient.setQueryData(getBomDataKey(bomId!), (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.map((item: BodResponse) => (item.id === Number(id) ? { ...item, ...rest } : item))
                    };
                }
            });
        }
    });

export const useGetDatasheetDetailQuery = (bomId: string, bodId: string, options: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) =>
    useQuery(getDatasheetDetailKey(bomId, bodId), () => getDatsheetDetail(bomId, bodId), options);

export const useGetOmDetailQuery = (bomId: string, bodId: string, options: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) =>
    useQuery(getOmDetailKey(bomId, bodId), () => getOmDetail(bomId, bodId), options);

export const useUpdateDatasheetDetailMutation = () =>
    useMutation(updateDatasheetMetaData, {
        onSuccess: (_, request) => {
            const { bomId, bodId, notes } = request;
            //to update the notes in list
            queryClient.setQueryData(getDatasheetTabDataKey(bomId), (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.map((item: DatasheetTabDataResponse) => (item.bod_id === bodId ? { ...item, notes } : item))
                    };
                }
            });
            //to update the notes in header of corousal Detail page
            queryClient.setQueryData(getDatasheetDetailKey(bomId, String(bodId)), (oldData: any) => {
                return {
                    ...oldData,
                    data: { ...oldData.data, notes }
                };
            });
        }
    });

export const useGetBomDetails = (bom_id: string, options?: Omit<UseQueryOptions<AxiosResponse<BomDetails>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getBomDetailsKey(bom_id), () => getBomDetails(bom_id), options);

export const useDatasheetAutoSearchMutation = () =>
    useMutation(datasheetAutoSearch, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateDatasheetDataQuery(String(bomId));
            invalidateDatasheetStatsQuery(String(bomId));
        }
    });

export const useOmAutoSearchMutation = () =>
    useMutation(omAutoSearch, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateOmDataQuery(String(bomId));
            invalidateOmStatsQuery(String(bomId));
        }
    });

export const useSortProductListMutation = () =>
    useMutation(sortProductList, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(Number(bomId));
        }
    });

export const updateBomDataSelectionStatus = ({ bomId, bodId, selectionStatus, annotationStatus, all_pages_hidden, selectedDocumentId }: MetadataStatusRequest) => {
    queryClient.setQueryData(getDatasheetTabDataKey(String(bomId)), (oldData: any) => {
        if (oldData) {
            return {
                ...oldData,
                data: oldData.data.map((item: DatasheetTabDataResponse) =>
                    item.bod_id === Number(bodId)
                        ? {
                              ...item,
                              ...(typeof all_pages_hidden === 'boolean' && { all_pages_hidden: false }),
                              selected_datasheet: {
                                  ...item.selected_datasheet,
                                  ...(selectionStatus && { document_selection_status: selectionStatus }),
                                  ...(annotationStatus && { annotations_status: annotationStatus })
                              },
                              ...(selectedDocumentId && { selected_datasheet_id: selectedDocumentId })
                          }
                        : item
                )
            };
        }
    });
};

export const updateOmDataSelectionStatus = ({ bomId, bodId, selectionStatus, subDocumentType, annotationStatus, selectedDocumentId }: MetadataStatusRequest) => {
    queryClient.setQueryData(getOmTabDataKey(String(bomId)), (oldData: any) => {
        if (oldData) {
            return {
                ...oldData,
                data: oldData.data.map((item: any) =>
                    item.bod_id === Number(bodId)
                        ? {
                              ...item,
                              [`selected_${subDocumentType}`]: {
                                  ...item[`selected_${subDocumentType}`],
                                  ...(selectionStatus ? { document_selection_status: selectionStatus } : {}),
                                  ...(annotationStatus ? { annotations_status: annotationStatus } : {}),
                                  ...(selectedDocumentId ? { id: selectedDocumentId } : {})
                              }
                          }
                        : item
                )
            };
        }
    });
};

export const updateOmDataAddStatus = ({ bomId, response, subDocumentType }: any) => {
    invalidateOmStatsQuery(bomId);
    queryClient.setQueryData(getOmTabDataKey(String(bomId)), (oldData: any) => {
        if (oldData) {
            const newDataIds = response.map((item: any) => Number(item.bod_id));
            const updatedData = [...oldData.data];
            updatedData.forEach((row: any) => {
                const index = newDataIds.indexOf(row.bod_id);
                if (index !== -1) {
                    const newRowData = response[index];
                    row[`selected_${subDocumentType}`] = {
                        document_selection_status: newRowData.document_selection_status,
                        id: newRowData.id
                    };
                }
            });
            return {
                ...oldData,
                data: updatedData
            };
        }
    });
};

export const useCreateSectionMutation = () =>
    useMutation(createSection, {
        onSuccess: (response, request) => {
            const { bomId } = request;
            queryClient.setQueryData(getBomSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: [...oldData.data, response.data]
                };
            });
        }
    });

export const useUpdateSectionDetailsMutation = () =>
    useMutation(updateSectionDetails, {
        onSuccess: (response, request) => {
            const { bomId, sectionId } = request;
            queryClient.setQueryData(getBomSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.map((item: SectionApiResponseType) => (item.id === sectionId ? { ...response.data } : item))
                };
            });
        }
    });

export const useDeleteSectionMutation = () =>
    useMutation(deleteSection, {
        onSuccess: (_, request) => {
            const { bomId, sectionId } = request;
            queryClient.setQueryData(getBomSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.filter((item: SectionApiResponseType) => item.id !== sectionId)
                };
            });
            invalidateBomDataQuery(bomId);
            invalidateBomStatsQuery(String(bomId));
            invalidaLotQueries(String(bomId));
            invalidatePricingBodQuery(bomId);
            invalidateDatasheetStatsQuery(String(bomId));
            invalidateOmStatsQuery(String(bomId));
        }
    });

export const useMoveToSectionMutation = (bomId: number) =>
    useMutation(moveToSection, {
        mutationKey: getChangeSectionKey(bomId),
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(bomId);
        }
    });

export const useCreateKitMutation = (bomId: number) =>
    useMutation(createKit, {
        mutationKey: getCreateKitKey(bomId),
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(bomId);
            invalidateBomStatsQuery(String(bomId));
            invalidateDatasheetStatsQuery(String(bomId));
            invalidateOmStatsQuery(String(bomId));
            invalidateDatasheetDataQuery(String(bomId));
            invalidateOmDataQuery(String(bomId));
            invalidatePricingBodQuery(Number(bomId));
        }
    });
export const useOptionSectionQuery = (bomId: number, options?: Omit<UseQueryOptions<AxiosResponse<SectionApiResponseType[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getOptionSectionsKey(bomId), () => getOptionSection(bomId), options);

export const useCreateOptionSectionMutation = () =>
    useMutation(createOptionSection, {
        onSuccess: (response, request) => {
            const { bomId } = request;
            queryClient.setQueryData(getOptionSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: [...oldData.data, response.data]
                };
            });
        }
    });

export const useUpdateOptionSectionDetailsMutation = () =>
    useMutation(updateOptionSectionDetails, {
        onSuccess: (response, request) => {
            const { bomId, sectionId } = request;
            queryClient.setQueryData(getOptionSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.map((item: SectionApiResponseType) => (item.id === sectionId ? { ...response.data } : item))
                };
            });
        }
    });

export const useDeleteOptionSectionMutation = () =>
    useMutation(deleteOptionSection, {
        onSuccess: (_, request) => {
            const { bomId, sectionId } = request;
            queryClient.setQueryData(getOptionSectionsKey(bomId), (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData.data.filter((item: SectionApiResponseType) => item.id !== sectionId)
                };
            });
            invalidateBomDataQuery(bomId);
            invalidateBomStatsQuery(String(bomId));
            invalidatePricingBodQuery(bomId);
            invalidateDatasheetStatsQuery(String(bomId));
            invalidateOmStatsQuery(String(bomId));
        }
    });

export const useApproveProductOption = () => {
    return useMutation(postApproveOptionProduct, {
        onSuccess: (_, { bomId }) => {
            invalidateBomDataQuery(bomId);
            invalidatePricingBodQuery(bomId);
            invalidateDatasheetDataQuery(String(bomId));
            invalidateOmDataQuery(String(bomId));
        }
    });
};

export const saveColumnOrderEvents = async (userId?: number, updateOrders?: string[]) => {
    const { width, order } = DEFAULT_COLUMN_DETAILS;

    const insertMutation = `
    mutation {
        events_db {
          insert_table_settings(objects: {order: "{${order}}", page_name: "bom",width: ${convertToQueryStringFormat(width)}}) {
            affected_rows
          }
        }
      }
    `;

    const updateMutation = `
    mutation {
        events_db {
          update_table_settings(where: {user_id: {_eq: ${userId}}, page_name: {_eq: "bom"}}, _set: {order: "{${updateOrders}}"}) {
            affected_rows
          }
        }
      }
    `;
    await pushEvent({ insertMutation, updateMutation, responseAttribute: 'table_settings' });
    invalidateCustomisedColumnQuery();
};
export const saveColumnWidthEvents = async (userId: number, newWidth: Record<ColumnKeys, number>) => {
    const { width, order } = DEFAULT_COLUMN_DETAILS;

    const insertMutation = `
    mutation {
        events_db {
          insert_table_settings(objects: {order: "{${order}}", page_name: "bom",width: ${convertToQueryStringFormat(width)}}) {
            affected_rows
          }
        }
      }
    `;

    const updateMutation = `
    mutation {
        events_db {
          update_table_settings(where: {user_id: {_eq: ${userId}}, page_name: {_eq: "bom"}}, _set: { width:  ${convertToQueryStringFormat(newWidth)}}) {
            affected_rows
          }
        }
      }
    `;

    await pushEvent({ insertMutation, updateMutation, responseAttribute: 'table_settings' });
    invalidateCustomisedColumnQuery();
};
export const useHighLightBomDataMutation = () =>
    useMutation(toggleHighLightBomData, {
        onSuccess: (_, request) => {
            const { bomId, input } = request;
            queryClient.setQueryData(getBomDataKey(bomId), (oldData?: AxiosResponse<BodResponse[]>) => {
                if (oldData) {
                    const inputSet = new Set(input);

                    return {
                        ...oldData,
                        data: oldData.data.map((item: BodResponse) => {
                            const toggledItem = inputSet.has(item.id);
                            if (toggledItem)
                                return {
                                    ...item,
                                    is_highlighted: !item.is_highlighted
                                };
                            return item;
                        })
                    };
                }

                return oldData;
            });
        }
    });

export const useUpdateSectionOrderMutation = () =>
    useMutation(updateSectionOrder, {
        onMutate: ({ sourceIndex, destinationIndex, bomId, sectionType }: UpdateSectionOrderTypes) => {
            let queryKey: Array<string> = [];
            if (sectionType === SECTION_TYPE_FOR_PAYLOAD.MAIN) {
                queryKey = getBomSectionsKey(Number(bomId));
            } else {
                queryKey = getOptionSectionsKey(Number(bomId));
            }

            if (sourceIndex !== undefined && destinationIndex !== undefined) {
                const sectionData = queryClient.getQueryData<AxiosResponse<SectionApiResponseType[]>>(queryKey);
                if (sectionData?.data) {
                    const currentSectionData = [...sectionData.data];
                    const draggedItem = currentSectionData.splice(sourceIndex, 1)[0];
                    currentSectionData.splice(destinationIndex, 0, draggedItem);
                    queryClient.setQueryData(queryKey, (oldData?: AxiosResponse) => {
                        if (oldData) {
                            return { ...oldData, data: currentSectionData };
                        }
                        return oldData;
                    });
                }
            }
        }
    });

export const useAddMultipeBodsMutation = () =>
    useMutation(addMultipleBods, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(Number(bomId));
            invalidateDatasheetDataQuery(bomId);
            invalidateOmDataQuery(bomId);
            invalidatePricingBodQuery(Number(bomId));
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
        }
    });
