import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BodySmall, Box, Button, H5, NoProductFoundIcon } from '@parspec/pixel';

import { useSupportManufacturerMutation } from '../queries';
import { useGetBodDetailQuery } from '../../../BOM/queries';

interface noDataSheetSuggestionPropType {
    onAddDatasheetClick: () => void;
}

const NO_DATASHEET_SUGGESTION_MESSAGE = 'Please make sure the manufacturer name or model number is entered correctly, manually upload a datasheet, or submit a request to support this manufacturer.';
const NoDataSheetSuggestion = (props: noDataSheetSuggestionPropType) => {
    const { onAddDatasheetClick } = props;

    const { bomId, productId, projectId } = useParams();
    const navigate = useNavigate();

    const [isRequestManufactureSupportedClicked, setIsRequestManufactureSupportedClicked] = useState(false);

    const handleReturntoDataSheetsClick = () => {
        navigate(`/v2/project/${projectId}/bom/${bomId}?tab=dataSheets`);
    };

    const { data: bodData } = useGetBodDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });

    const { mutateAsync: supportManufacturer, isLoading: supportLoading } = useSupportManufacturerMutation();

    const onClickSupportManufacture = async () => {
        if (bodData?.data) {
            const payload = {
                manufacturer: bodData?.data?.manufacturer,
                model_number: bodData?.data?.model_number
            };
            try {
                await supportManufacturer(payload);
                setIsRequestManufactureSupportedClicked(true);
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Box fontSize={112} display="flex" alignItems="center" flexDirection="column">
                <NoProductFoundIcon fontSize="inherit" />
            </Box>

            <Box display="flex" alignItems="center" flexDirection="column" mb={6}>
                <H5 textAlign="center">No Datasheets Found</H5>

                <BodySmall textAlign="center" limit={false} marginTop={2} fontWeight={500} width={410}>
                    {NO_DATASHEET_SUGGESTION_MESSAGE}
                </BodySmall>
            </Box>

            <Box display="flex" justifyContent="center" gap={4}>
                <Button variant="outlined" color="secondary" onClick={handleReturntoDataSheetsClick}>
                    Return to Datasheets
                </Button>
                <Button variant="outlined" color="secondary" onClick={onAddDatasheetClick}>
                    Add Datasheet
                </Button>
                <Button
                    variant="contained"
                    onClick={onClickSupportManufacture}
                    isLoading={supportLoading}
                    color={isRequestManufactureSupportedClicked ? 'secondary' : 'tertiary'}
                    disabled={isRequestManufactureSupportedClicked}
                >
                    {isRequestManufactureSupportedClicked ? "We'll get on it!" : '  Request Manufacturer Support'}
                </Button>
            </Box>
        </Box>
    );
};

export default NoDataSheetSuggestion;
