import { axios } from 'src/app/Axios';
import { ProdRequirementPatchPayload, ProductAttributesResponse, ProductRequirementsResponse } from './apiTypes';

export const endpoints = {
    getProductRequirementsUrl: (bomId = 0, productId = 0) => `/bom/${bomId}/product_finder/${productId}/requirements`,
    getSearchProductRequirementsUrl: (bomId = 0, productId = 0, refresh = false) => `/bom/${bomId}/product_finder/${productId}/search?refresh=${refresh}`,
    getExtractAttributesUrl: (bomId = 0, productId = 0, selectedDatasheetID = 0) => `/bom/${bomId}/product_finder/${productId}/source_datasheet/${selectedDatasheetID}/extract_attributes/`
};

export function getProductRequirements(bomId = 0, productId = 0) {
    return axios.get<ProductRequirementsResponse>(endpoints.getProductRequirementsUrl(bomId, productId));
}

export interface ProdRequirementPostArgType {
    bomId: number;
    productId: number;
}
// no payload for post requests
export function postProductRequirements({ bomId = 0, productId = 0 }: ProdRequirementPostArgType) {
    return axios.post<ProductRequirementsResponse>(endpoints.getProductRequirementsUrl(bomId, productId) + '/');
}

export interface ProdRequirementPatchArgType extends ProdRequirementPostArgType {
    payload: ProdRequirementPatchPayload;
}

export function patchProductRequirements({ bomId, productId, payload }: ProdRequirementPatchArgType) {
    return axios.patch(endpoints.getProductRequirementsUrl(bomId, productId) + '/', payload);
}

export function postProductRequirementsSearch({ bomId = 0, productId = 0, refresh = false }) {
    return axios.post(endpoints.getSearchProductRequirementsUrl(bomId, productId, refresh));
}

export function postExtractAttributes({ bomId = 0, productId = 0, selectedDatasheetID = 0 }) {
    return axios.post<ProductAttributesResponse>(endpoints.getExtractAttributesUrl(bomId, productId, selectedDatasheetID));
}
