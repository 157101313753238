import { useEffect, useState } from 'react';

import { pushEvent } from './EventService';

type ApiType = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
interface ApiTime {
    startTime: null | number;
    endTime: null | number;
}

interface MutationRequestData {
    apiResponseTime: number;
    uiResponseTime: number;
    country: string;
    apiUrl: string;
    apiType: ApiType;
}

interface AppTrackerProps {
    apiUrl: string;
    isApiLoading: boolean;
    enabled?: boolean;
    isUiLoading?: boolean;
    apiType?: ApiType;
}

const insertMutation = ({ apiResponseTime, uiResponseTime, country, apiUrl, apiType }: MutationRequestData) => `
mutation {
    events_db{
        insert_app_performance_v2(objects: {api_latency_ms: ${apiResponseTime}, api_ui_latency_ms: ${uiResponseTime}, country: "${country}", api_req_url: "${apiUrl}", api_req_method_type: "${apiType}"}) {
            affected_rows
        }
    }
  }
`;

/*
apiUrl: api endpoint url
isApiLoading: flag for api loading
isUiLoading: flag for Ui rendering
enabled: boolean, in case of conditional tracking
apiType: type of API
*/

const useAppPerformanceTracker = ({ enabled, isApiLoading, isUiLoading, apiUrl, apiType = 'GET' }: AppTrackerProps) => {
    const [apiLoadingTime, setApiLoadingTime] = useState<ApiTime>({ startTime: null, endTime: null });
    const [uiLoadingTime, setUiLoadingTime] = useState<ApiTime>({ startTime: null, endTime: null });

    // to set the start time and end time of an api
    useEffect(() => {
        if (isApiLoading) {
            setApiLoadingTime((old) => ({ ...old, startTime: Date.now() }));
        } else {
            if (apiLoadingTime.startTime) {
                setApiLoadingTime((old) => ({ ...old, endTime: Date.now() }));
            }
        }
    }, [isApiLoading]);

    // to set the response time of ui rendering
    useEffect(() => {
        if (isUiLoading) {
            setUiLoadingTime((old) => ({ ...old, startTime: Date.now() }));
        } else {
            if (uiLoadingTime.startTime) {
                setUiLoadingTime((old) => ({ ...old, endTime: Date.now() }));
            }
        }
    }, [isUiLoading]);

    const onTrackSearchApiParams = async () => {
        const apiResponseTime = (apiLoadingTime.endTime || 0) - (apiLoadingTime.startTime || 0);
        const uiResponseTime = (uiLoadingTime.endTime || 0) - (uiLoadingTime.startTime || 0);
        const input = {
            apiResponseTime,
            uiResponseTime,
            country: 'USA',
            apiUrl,
            apiType
        };
        await pushEvent({ insertMutation: insertMutation(input), updateMutation: null, responseAttribute: 'app_performance_v2' });
        setApiLoadingTime({ startTime: null, endTime: null });
        setUiLoadingTime({ startTime: null, endTime: null });
    };

    //to get the total time taken between start and end of api call
    // and posting it on Event service
    useEffect(() => {
        if (apiLoadingTime.endTime && uiLoadingTime.endTime && enabled) {
            onTrackSearchApiParams();
        }
    }, [apiLoadingTime, uiLoadingTime]);
};

export default useAppPerformanceTracker;
