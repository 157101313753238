import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { validateEmail } from '../../UserManagement/utils/utils';
import SubmittalTemplate from './SubmittalTemplatePreferances/SubmittalTemplatePreferences';
import BranchBranding from './BranchBranding/BranchBranding';
import QuoteTemplatePreferences from './QuoteTemplatePreferences/QuoteTemplatePreferences';
import OmTemplate from './OmPackageTemplatePreferences/OmPackageTemplatePreferences';
import { BranchDetails } from './BranchDetails/BranchDetails';
import { AddEditLocationHeader } from './AddEditLocationHeader';
import { useAddNewBranchDetailsMutation } from '../queries';
import { trimEmptySpacesHandler, removeAllSpacesHandler } from '../utils/utils';
import { BranchDetailsData, BranchDetailsErrorState } from './BranchDetails/BranchDetails.types';
import { BranchBrandingInterface } from './AddEditLocation.types';
import { SubmittalTemplateInterface, OMTemplateInterface, QuoteTemplateInterface } from '../queries/apiTypes';
import {
    initialBranchDetailsState,
    initialBranchDetailsErrorState,
    initialBranchBrandingState,
    initialSubmittalDetails,
    initialOMPackageDetails,
    initialCompanyDetails,
    initialQuoteDetails
} from './utils/helper';
import { useCheckAccessForProductFinderOrQuoting } from '../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useFeatureFlag } from '../../../shared/UserActivityTracking/useFeatureFlag';
import { attachmentDocumentArrFormatter } from './utils/helper';

const COMPANY_DETAILS = initialCompanyDetails;

export const AddLocation = () => {
    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();

    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const { mutateAsync: addNewBranchLocationData, isLoading: addNewLocationLoading } = useAddNewBranchDetailsMutation();
    const [branchDetailsData, setBranchDetailsData] = useState<BranchDetailsData>(initialBranchDetailsState);
    const [branchDetailsErrorState, setBranchDetailsErrorState] = useState<BranchDetailsErrorState>(initialBranchDetailsErrorState);
    const [stateListOptions, setStateListOptions] = useState<
        {
            label: string;
            value: string;
        }[]
    >([{ label: '', value: '' }]);

    const [branchBrandingState, setBranchBrandingState] = useState<BranchBrandingInterface>(initialBranchBrandingState);
    const [submittalTemplate, setSubmittalTemplate] = useState<SubmittalTemplateInterface>(initialSubmittalDetails);
    const [omPackageTemplate, setOmPackageTemplate] = useState<OMTemplateInterface>(initialOMPackageDetails);
    const [quoteTemplate, setQuoteTemplate] = useState<QuoteTemplateInterface>(initialQuoteDetails);
    const [currentHeaderTab, setCurrentHeaderTab] = useState<string>('');

    const branchDetailsRef = useRef<HTMLDivElement | null>(null);
    const branchBrandingRef = useRef<HTMLDivElement | null>(null);
    const quoteTemplatePreferencesRef = useRef<HTMLDivElement | null>(null);
    const submittalTemplatePreferencesRef = useRef<HTMLDivElement | null>(null);
    const omTemplatePreferencesRef = useRef<HTMLDivElement | null>(null);

    const handleIntersection = (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                setCurrentHeaderTab(() => entry.target.id);
            }
        });
    };

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const trimmedBranchName = trimEmptySpacesHandler(branchDetailsData.name);
        const trimmedAddress1 = trimEmptySpacesHandler(branchDetailsData.address1);
        const trimmedAddress2 = branchDetailsData.address2 ? trimEmptySpacesHandler(branchDetailsData.address2) : '';
        const trimmedWebsite = branchDetailsData.website ? removeAllSpacesHandler(branchDetailsData.website) : '';
        const trimmedcity = trimEmptySpacesHandler(branchDetailsData.city);
        const trimmedZipcode = trimEmptySpacesHandler(branchDetailsData.zipcode);

        const branchNameFlag = trimmedBranchName.length === 0;
        const address1Flag = trimmedAddress1.length === 0;
        const cityFlag = trimmedcity.length === 0;
        const countryFlag = branchDetailsData.country.length === 0;
        const stateFlag = branchDetailsData.state.length === 0;
        const zipcodeFlag = branchDetailsData.zipcode.trim().length === 0 || branchDetailsData.zipcode.trim().length > 10;

        if (branchNameFlag || address1Flag || cityFlag || countryFlag || stateFlag || zipcodeFlag) {
            let zipCodeErrorMsg = '';
            if (branchDetailsData.zipcode.trim().length === 0) {
                zipCodeErrorMsg = 'Required';
            }
            if (branchDetailsData.zipcode.trim().length > 10) {
                zipCodeErrorMsg = 'Max length allowed is 10';
            }

            return setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                name: { ...branchDetailsErrorState.name, isError: branchNameFlag, title: `${branchNameFlag ? 'Required' : ''}` },
                address1: { ...branchDetailsErrorState.address1, isError: address1Flag, title: `${address1Flag ? 'Required' : ''}` },
                city: { ...branchDetailsErrorState.city, isError: cityFlag, title: `${cityFlag ? 'Required' : ''}` },
                country: { ...branchDetailsErrorState.country, isError: countryFlag, title: `${countryFlag ? 'Required' : ''}` },
                state: { ...branchDetailsErrorState.state, isError: stateFlag, title: `${stateFlag ? 'Required' : ''}` },
                zipcode: { ...branchDetailsErrorState.zipcode, isError: zipcodeFlag, title: zipCodeErrorMsg }
            });
        }

        if (branchDetailsData.email.length > 0 && !validateEmail(branchDetailsData.email)) {
            return setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                email: { ...branchDetailsErrorState.email, isError: true, title: 'Please provide a valid email address' }
            });
        }

        const companyDetaisObj = {
            name: trimmedBranchName,
            address1: trimmedAddress1,
            address2: trimmedAddress2,
            website: trimmedWebsite,
            phone1: branchDetailsData.phone1,
            city: trimmedcity,
            state: branchDetailsData.state,
            zipcode: trimmedZipcode,
            country: branchDetailsData.country,
            email: branchDetailsData.email
        };

        const newData = {
            company_details: { ...companyDetaisObj },
            submittal: { ...submittalTemplate },
            o_m: { ...omPackageTemplate },
            quote: { ...quoteTemplate, attachments: { ...quoteTemplate.attachments, documents: attachmentDocumentArrFormatter(quoteTemplate?.attachments?.documents) } }
        };

        const str = JSON.stringify(newData);

        const formData = new FormData();

        formData.append('payload', str);

        formData.append('logo_file', branchBrandingState.primary.data as File);
        formData.append('picture_file', branchBrandingState.secondary.data as File);

        await addNewBranchLocationData({ data: formData });

        navigate('/v2/settings/company-locations?addLocation=success');
    };

    useEffect(() => {
        const options = {
            root: document.getElementById('addEditBranchLocScrollContainerRoot'),
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (branchDetailsRef.current) {
            observer.observe(branchDetailsRef.current);
        }

        if (branchBrandingRef.current) {
            observer.observe(branchBrandingRef.current);
        }

        return () => {
            if (branchDetailsRef.current) {
                observer.unobserve(branchDetailsRef.current);
            }

            if (branchBrandingRef.current) {
                observer.unobserve(branchBrandingRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            root: document.getElementById('addEditBranchLocScrollContainerRoot'),
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (submittalTemplatePreferencesRef.current) {
            observer.observe(submittalTemplatePreferencesRef.current);
        }
        if (omTemplatePreferencesRef.current) {
            observer.observe(omTemplatePreferencesRef.current);
        }

        return () => {
            if (submittalTemplatePreferencesRef.current) {
                observer.unobserve(submittalTemplatePreferencesRef.current);
            }
            if (omTemplatePreferencesRef.current) {
                observer.unobserve(omTemplatePreferencesRef.current);
            }
        };
    }, []);

    return (
        <>
            <Box bgcolor="primary.contrastText" position="relative" width="calc(100vw - 390px)" overflow={'hidden'}>
                <Box position={'sticky'} pb={4} zIndex={10} width={'100%'} top={0} left={0} bgcolor="primary.contrastText">
                    <AddEditLocationHeader onSubmitHandler={onSubmitHandler} saveBtnLoading={addNewLocationLoading} currentHeaderTab={currentHeaderTab} />
                </Box>
                <Box mb={8} height={'60vh'} sx={{ overflow: 'hidden', overflowY: 'scroll' }} id="addEditBranchLocScrollContainerRoot">
                    <Box width={'100%'} id="branchDetails" ref={branchDetailsRef} p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                        <BranchDetails
                            companyDetails={COMPANY_DETAILS.data}
                            companyDetailsLoading={false}
                            onSubmitHandler={onSubmitHandler}
                            formRef={formRef}
                            branchDetailsTemplate={branchDetailsData}
                            setBranchDetailsTemplate={setBranchDetailsData}
                            branchDetailsErrorState={branchDetailsErrorState}
                            setBranchDetailsErrorState={setBranchDetailsErrorState}
                            stateListOptions={stateListOptions}
                            setStateListOptions={setStateListOptions}
                        />
                    </Box>

                    <Box width={'100%'} mt={6} id="branchBranding" ref={branchBrandingRef} p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                        <BranchBranding
                            companyDetails={COMPANY_DETAILS.data}
                            branchBrandingTemplate={branchBrandingState}
                            setBranchBrandingTemplate={setBranchBrandingState}
                            companyDetailsLoading={false}
                        />
                    </Box>
                    {enable_quoting && quotingAccess && (
                        <Box width={'100%'} mt={6} id="quoteCustomization" ref={quoteTemplatePreferencesRef} p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                            <QuoteTemplatePreferences
                                quoteDetails={COMPANY_DETAILS?.data.quote}
                                quoteTemplate={quoteTemplate}
                                setQuoteTemplate={setQuoteTemplate}
                                intersectionObserverRef={quoteTemplatePreferencesRef}
                                intersectionObserverHandler={handleIntersection}
                                companyLocationId={null} //since its a new location so no company ID generated as of yet
                            />
                        </Box>
                    )}
                    <Box width={'100%'} mt={6} id="submittalCustomization" ref={submittalTemplatePreferencesRef} p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                        <SubmittalTemplate
                            submittalDetails={COMPANY_DETAILS?.data.submittal}
                            submittalTemplate={submittalTemplate}
                            setSubmittalTemplate={setSubmittalTemplate}
                            branchBrandingState={branchBrandingState}
                        />
                    </Box>
                    <Box width={'100%'} mt={6} id="omCustomization" ref={omTemplatePreferencesRef} p={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                        <OmTemplate omDetails={COMPANY_DETAILS?.data.o_m} omPackageTemplate={omPackageTemplate} setOmPackageTemplate={setOmPackageTemplate} branchBrandingState={branchBrandingState} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
