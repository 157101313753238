import { useEffect, useRef, useState, ReactNode } from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { environment } from 'src/environments';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { Box, CircularProgress } from '@parspec/pixel';

import { WEBVIEWER_FOLDER_NAME } from './constants';

interface PdfViewerProps {
    url: string;
    errorUi?: ReactNode;
    height?: string;
}

const PdfViewer = (props: PdfViewerProps) => {
    const { url, errorUi, height } = props;
    const viewer = useRef<any>(null);
    const wvInstanceRef = useRef<WebViewerInstance | null>(null);
    const [showLoader, setShowLoader] = useState(true);
    const [pdfLoadError, setPdfLoadError] = useState(false);
    const documentLoaded = useRef(false);

    useEffect(() => {
        const callViewer = () => {
            WebViewer(
                {
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    initialDoc: getProxyFileUrl(url),
                    licenseKey: environment.Y,
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'freeTextToolGroupButton',
                        'errorModal',
                        'markReplaceTextToolButton',
                        'ribbons',
                        'header',
                        'linkAnnotationPopup'
                    ]
                },
                viewer?.current
            ).then((instance: any) => {
                setShowLoader(false);
                wvInstanceRef.current = instance;
                const { documentViewer, annotationManager } = instance.Core;
                let searchButtonElem = {};
                instance.UI.setHeaderItems((header: any) => {
                    const items = header.getItems().filter((item: Record<string, any>) => {
                        if (item.dataElement === 'zoomOverlayButton') {
                            return true;
                        }
                        if (item.dataElement === 'searchButton') {
                            searchButtonElem = { ...item };
                        }
                        return false;
                    });
                    items.unshift({ type: 'spacer' });
                    items.push({ type: 'spacer' });
                    items.push(searchButtonElem);
                    header.update(items);
                });
                instance.UI.enableElements(['header']);
                const UIEvents = instance.UI.Events;
                instance.UI.addEventListener(UIEvents.LOAD_ERROR, () => {
                    setPdfLoadError(true);
                });
                documentViewer.addEventListener('documentLoaded', async () => {
                    documentLoaded.current = true;
                    annotationManager.enableReadOnlyMode();
                });
                documentViewer.addEventListener('finishedRendering', async () => {
                    if (documentLoaded.current) {
                        instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                        documentLoaded.current = false;
                    }
                });
                // const iframe = instance?.iframeWindow?.document.body;
                // const el = iframe.getElementsByClassName('DocumentContainer')[0];
                // el.style.backgroundColor = '#091535';

                // you can now call WebViewer APIs here...
            });
        };
        setTimeout(() => {
            if (viewer?.current) callViewer();
        }, 200);
    }, [viewer?.current]);

    //to change the pdf link on webviewer instance
    useEffect(() => {
        wvInstanceRef?.current?.UI.loadDocument(getProxyFileUrl(url));
    }, [url]);

    return (
        <>
            {!pdfLoadError ? (
                <Box id="viewer" className="webviewer" ref={viewer} position="relative" flex={1} height={height}>
                    {showLoader ? (
                        <Box position="absolute" top="50%" left="50%">
                            <CircularProgress size="xxl" color="primary" />
                        </Box>
                    ) : null}
                </Box>
            ) : (
                <>{errorUi}</> || null
            )}
        </>
    );
};

export default PdfViewer;
