import { TABLE_COLUMN_NAMES } from '../../shared/constants';

export const MANUFACTURERS_TABLE_COLUMN_OPTIONS = {
    data: [
        { field: 'products', label: TABLE_COLUMN_NAMES.PRODUCTS },
        { field: 'revenue', label: TABLE_COLUMN_NAMES.REVENUE },
        { field: 'margin', label: TABLE_COLUMN_NAMES.MARGIN },
        { field: 'profitMargin', label: TABLE_COLUMN_NAMES.PROFIT_MARGIN }
    ]
};

export const PRIMARY_FIELD_VALUE = 'manufacturer';
