import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Box, Snackbar, Skeleton } from '@parspec/pixel';
import { useSSOAuthenticationMutation } from '../queries';

export const SSOAuthentication = () => {
    const [snackbarData, setSnackbarData] = useState({
        isActive: false,
        message: ''
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const { mutateAsync: ssoAuthenticate, isLoading: isSSOAuthenticateLoading } = useSSOAuthenticationMutation();

    const authenticationHandler = async (code: string, state: string) => {
        try {
            const response = await ssoAuthenticate({ code, state });
            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('auth', response?.data?.auth);
            localStorage.setItem('email', response?.data?.email);
            localStorage.setItem('ssoLogin', 'true');
            setSearchParams({});
            navigate('/v2/dashboard');
        } catch {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Something went wrong, please try again'
            });
            return navigate('/login');
        }
    };

    useEffect(() => {
        const error = searchParams.get('error');
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (error) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Something went wrong, please try again'
            });
            return navigate('/login');
        }

        if (code && state) {
            authenticationHandler(code, state);
        }
    }, [searchParams]);

    return (
        <>
            <Box
                width="100vw"
                height="100vh"
                bgcolor="#091535"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(assets/images/login/login-bg.png)`
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box>
                        {isSSOAuthenticateLoading && (
                            <Box>
                                <Skeleton variant="rectangular" color="light" width="337px" height="427px" />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={1000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </>
    );
};
