import { useEffect } from 'react';
import * as React from 'react';

import { Box, H6, SelectChangeEvent } from '@parspec/pixel';

import { CoverPage } from '../CommonSections/CoverPage';
import { DocumentOrdering } from '../CommonSections/DocumentOrdering';
import { TableOfContents } from '../CommonSections/TableOfContents';
import { NotesSummary } from '../CommonSections/NotesSummary';
import { HeaderFooter } from '../CommonSections/HeaderFooter';
import { BranchBrandingInterface } from '../AddEditLocation.types';
import { OMTemplateInterface } from '../../queries/apiTypes';
import { SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE } from '../utils/helper';
import {
    PRE_FILL_ADDRESSED_TO,
    PRE_FILL_COVER_PAGE_LOGO,
    PRE_FILL_DATE,
    PRE_FILL_PREPARED_BY_LOGO,
    PRE_FILL_PRIMARY_TITLE,
    PRE_FILL_SECONDARY_TITLE
} from '../QuoteTemplatePreferences/utils/constants';

interface OmDetailsProps {
    omDetails: OMTemplateInterface;
    omPackageTemplate: OMTemplateInterface;
    setOmPackageTemplate: React.Dispatch<React.SetStateAction<OMTemplateInterface>>;
    branchBrandingState: BranchBrandingInterface;
}
const OmTemplate = (props: OmDetailsProps) => {
    const { omDetails, omPackageTemplate, setOmPackageTemplate, branchBrandingState } = props;

    useEffect(() => {
        if (!omDetails) {
            return;
        }

        setOmPackageTemplate(() => ({
            ...omPackageTemplate,
            cover_page_template_id: omDetails.cover_page_template_id,
            cover_page_presets: {
                ...omPackageTemplate.cover_page_presets,
                cover_page_section: omDetails.cover_page_presets.cover_page_section,
                date: omDetails.cover_page_presets.date,
                primary_title: omDetails.cover_page_presets.primary_title,
                secondary_title: omDetails.cover_page_presets.secondary_title,
                project_number: omDetails.cover_page_presets.project_number,
                quote_number: omDetails.cover_page_presets.quote_number,
                project_address: omDetails.cover_page_presets.project_address,
                stakeholders: omDetails.cover_page_presets.stakeholders,
                prepared_by: omDetails.cover_page_presets.prepared_by,
                addressed_to: omDetails.cover_page_presets.addressed_to,
                notes: omDetails.cover_page_presets.notes ? omDetails.cover_page_presets.notes : '',
                pre_fill_primary_title: omDetails.cover_page_presets.pre_fill_primary_title,
                pre_fill_secondary_title: omDetails.cover_page_presets.pre_fill_secondary_title,
                pre_fill_date: omDetails.cover_page_presets.pre_fill_date,
                pre_fill_preparedby_logo: omDetails.cover_page_presets.pre_fill_preparedby_logo,
                pre_fill_coverpage_logo: omDetails.cover_page_presets.pre_fill_coverpage_logo,
                pre_fill_addressed_to: omDetails.cover_page_presets.pre_fill_addressed_to
            },
            header_footer_presets: {
                ...omPackageTemplate.header_footer_presets,
                header_footer_section: omDetails.header_footer_presets.header_footer_section,
                job_name: omDetails.header_footer_presets.job_name,
                notes: omDetails.header_footer_presets.notes,
                document_type: omDetails.header_footer_presets.document_type,
                qty: omDetails.header_footer_presets.qty,
                left_footer: omDetails.header_footer_presets.left_footer,
                right_footer: omDetails.header_footer_presets.right_footer,
                date: omDetails.header_footer_presets.date,
                page_number: omDetails.header_footer_presets.page_number,
                hyperlinks: omDetails.header_footer_presets.hyperlinks,
                category: omDetails.header_footer_presets.category,
                pre_fill_job_name: omDetails.header_footer_presets.pre_fill_job_name,
                pre_fill_header_logo: omDetails.header_footer_presets.pre_fill_header_logo
            },
            table_of_content_presets: {
                ...omPackageTemplate.table_of_content_presets,
                toc_section: omDetails.table_of_content_presets.toc_section,
                primary_title: omDetails.table_of_content_presets.primary_title,
                secondary_title: omDetails.table_of_content_presets.secondary_title,
                project_number: omDetails.table_of_content_presets.project_number,
                quote_number: omDetails.table_of_content_presets.quote_number,
                hyperlinks: omDetails.table_of_content_presets.hyperlinks,
                type: omDetails.table_of_content_presets.type,
                qty: omDetails.table_of_content_presets.qty,
                page_number: omDetails.table_of_content_presets.page_number,
                section: omDetails.table_of_content_presets.section
            },
            notes_presets: { ...omPackageTemplate.notes_presets, notes_section: omDetails.notes_presets.notes_section },
            layout_presets: {
                ...omPackageTemplate.layout_presets,
                datasheet: omDetails.layout_presets.datasheet,
                installation_guide: omDetails.layout_presets.installation_guide,
                drawing: omDetails.layout_presets.drawing,
                warranty: omDetails.layout_presets.warranty,
                other_document: omDetails.layout_presets.other_document,
                section_breaks: omDetails.layout_presets.section_breaks,
                show_kit_components: omDetails.layout_presets.show_kit_components,
                maintain_aspect_ratio: omDetails.layout_presets.maintain_aspect_ratio
            }
        }));
    }, [omDetails]);

    const changeHandler = (event: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => {
        const fieldName = field_name ? field_name : event.target?.name;

        const isNotesFromCoverPage =
            presetName === 'cover_page_presets' &&
            (fieldName === 'notes' ||
                fieldName === PRE_FILL_PRIMARY_TITLE ||
                fieldName === PRE_FILL_SECONDARY_TITLE ||
                fieldName === PRE_FILL_DATE ||
                fieldName === PRE_FILL_COVER_PAGE_LOGO ||
                fieldName === PRE_FILL_PREPARED_BY_LOGO ||
                fieldName === PRE_FILL_ADDRESSED_TO);

        const isRadioButtonsFromLayout = presetName === 'layout_presets' && !['show_kit_components', 'section_breaks', 'maintain_aspect_ratio'].includes(fieldName);
        const isSectionBreakOrKitComponentsFromLayout = presetName === 'layout_presets' && ['show_kit_components', 'section_breaks', 'maintain_aspect_ratio'].includes(fieldName);
        const isTextValuesFromCoverHeaderFooter = presetName === 'header_footer_presets' && (fieldName === 'pre_fill_job_name' || fieldName === 'pre_fill_header_logo');

        let fieldValue: string | boolean;
        if (isNotesFromCoverPage || isRadioButtonsFromLayout || isTextValuesFromCoverHeaderFooter) {
            fieldValue = event.target?.value as string;
        } else {
            const checkedField = event.target as HTMLInputElement
            if (isSectionBreakOrKitComponentsFromLayout) {
                fieldValue = checkedField.checked ? SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE.INCLUDED : SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE.EXCLUDED;
            } else {
                fieldValue = checkedField.checked;
            }
        }

        setOmPackageTemplate(() => ({ ...omPackageTemplate, [presetName]: { ...omPackageTemplate[presetName], [fieldName]: fieldValue } }));
    };

    const handleTemplateSelection = (_e: React.MouseEvent<HTMLElement>, template: string) => {
        switch (template) {
            case '1':
                setOmPackageTemplate(() => ({
                    ...omPackageTemplate,
                    cover_page_template_id: 1
                }));
                break;
            case '2':
                setOmPackageTemplate(() => ({
                    ...omPackageTemplate,
                    cover_page_template_id: 2
                }));
                break;
            default:
                break;
        }
    };

    return (
        <Box bgcolor="primary.contrastText">
            <Box>
                <H6>O&M Package Template Preferences</H6>
                <Box mt={6}>
                    <CoverPage
                        changeHandler={changeHandler}
                        handleTemplateSelection={handleTemplateSelection}
                        templateState={omPackageTemplate}
                        branchBrandingState={branchBrandingState}
                        templateName={'OM'}
                    />
                    <DocumentOrdering changeHandler={changeHandler} templateState={omPackageTemplate} />
                    <TableOfContents changeHandler={changeHandler} templateState={omPackageTemplate} templateName={'OM'} />
                    <NotesSummary changeHandler={changeHandler} templateState={omPackageTemplate} templateName={'OM'} />
                    <HeaderFooter changeHandler={changeHandler} templateState={omPackageTemplate} templateName={'OM'} />
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(OmTemplate);
