import { Grid, Button, H5, BodySmall } from '@parspec/pixel';

import CancelButton from '../../../../shared/CancelButton';
import { compileLabels, ICompileLabels } from '../constants';
import PreviewQuoteModal from '../compileQuote/PreviewQuote';
import { IPdfUrlsQuote } from './queries/apiTypes';
import { DocType } from '../../constants';
import { getHeaderTitleforCompilePage } from './utils';

interface ICompileHeader {
    documentType: keyof ICompileLabels;
    action: () => void;
    isLoading: boolean;
    isDisabled?: boolean;
    onPreviewQuotesClick?: () => void;
    openPreviewModal?: boolean;
    isPreviewQuoteLoading: boolean;
    onPreviewQuoteModalClose: () => void;
    pdfUrls: IPdfUrlsQuote;
}

function CompileHeader({ documentType, action, isLoading, isDisabled = false, onPreviewQuotesClick, openPreviewModal, isPreviewQuoteLoading, onPreviewQuoteModalClose, pdfUrls }: ICompileHeader) {
    const handlePreviewQuotesClick = () => {
        if (onPreviewQuotesClick) onPreviewQuotesClick();
    };

    const handleCreateDocumentClick = () => {
        action();
    };

    return (
        <>
            <Grid container justifyContent={'space-between'}>
                <Grid item xs={7}>
                    <Grid container direction={'column'} rowSpacing={2}>
                        <Grid item>
                            <H5>Customize {`${compileLabels[documentType]}${documentType === DocType.QUOTE ? '(s)' : ''}`} </H5>
                        </Grid>

                        <Grid item>
                            <BodySmall color={'secondary.main'} fontStyle={'Inter'} lines={2} fontWeight={400}>
                                {getHeaderTitleforCompilePage(documentType as DocType)}
                            </BodySmall>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid container justifyContent={'flex-end'} gap={2}>
                        <Grid item>
                            <CancelButton />
                        </Grid>
                        {documentType === DocType.QUOTE && (
                            <Grid item>
                                <Button onClick={handlePreviewQuotesClick} variant="outlined" color="secondary" isLoading={isPreviewQuoteLoading}>
                                    Preview Quote(s)
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button disabled={isDisabled} isLoading={isLoading} onClick={handleCreateDocumentClick}>
                                {documentType === DocType.QUOTE ? 'Next' : `Create ${compileLabels[documentType]}`}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <PreviewQuoteModal pdfUrls={pdfUrls} open={openPreviewModal || false} onClose={onPreviewQuoteModalClose} isCreateQuoteLoading={isLoading} />
        </>
    );
}

export default CompileHeader;
