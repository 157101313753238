import { ReactNode } from 'react';
import { BodySmall, ModalHeader, Modal, ModalFooter } from '@parspec/pixel';

type DeleteConfirmationDialogProps = {
    open: boolean;
    handleDialog: () => void;
    confirmAction: () => void;
    loading?: boolean;
    title?: string;
    children?: ReactNode;
    continueButtonLabel?: string;
    cancelButtonLabel?: string;
};

const DeleteConfirmationDialog = (props: DeleteConfirmationDialogProps) => {
    const { open, handleDialog, confirmAction, loading, title, continueButtonLabel, children, cancelButtonLabel } = props;

    return (
        <Modal
            open={open}
            onClose={handleDialog}
            header={<ModalHeader title={title || 'Delete Product(s)'} onClose={handleDialog} />}
            footer={
                <ModalFooter
                    continueButtonLabel={continueButtonLabel || 'Delete'}
                    cancelButtonLabel={cancelButtonLabel}
                    onAccept={confirmAction}
                    onReject={handleDialog}
                    isLoading={loading}
                    continueButtonColor="error"
                />
            }
        >
            <>{children}</>
        </Modal>
    );
};

export default DeleteConfirmationDialog;

DeleteConfirmationDialog.defaultProps = {
    title: 'Are you sure?',
    children: <BodySmall limit={false}>{'Are you sure, you want to delete the selected items?'}</BodySmall>,
    continueButtonLabel: 'Delete',
    cancelButtonLabel: 'Cancel'
};
