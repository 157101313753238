import { Grid, Paper, BodySmall, AddIcon } from '@parspec/pixel';

const NoCompileCardWithoutCreateButton = () => (
    <Paper
        sx={{
            width: 348,
            height: 494
        }}
        variant="elevation"
        elevation={3}
    >
        <Grid container direction={'column'} position="relative" height={'100%'}>
            <Grid item bgcolor={'tertiary.light'} sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        flexGrow: 1,
                        height: '100%'
                    }}
                >
                    <Grid item>
                        <AddIcon
                            sx={{
                                fontSize: 60
                            }}
                            color="tertiary"
                        />
                    </Grid>
                    <Grid item width={234}>
                        <BodySmall color={'secondary.main'} lines={3}>
                            No documents of this type have <br /> yet been created for this Bill of <br /> Materials.
                        </BodySmall>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
);

export default NoCompileCardWithoutCreateButton;
