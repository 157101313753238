import { Box, BodyBig, BodySmall, InfoIcon } from '@parspec/pixel';

export const VerificationEmailSent = () => {
    return (
        <Box bgcolor="secondary.contrastText" width="360px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <BodyBig limit={false} fontWeight={500} mb={2}>
                    Verification E-mail Sent
                </BodyBig>
                <BodySmall limit={false} my={4} textAlign={'center'}>
                    Once your email is verified, we'll help you finalize your account setup. Excited to have you on board!
                </BodySmall>
            </Box>

            <Box bgcolor={'primary.light'} display={'flex'} alignItems={'flex-start'} p={2}>
                <Box color={'primary.main'}>
                    <InfoIcon fontSize={'small'} />
                </Box>
                <Box ml={2}>
                    <BodySmall limit={false} color={'primary.main'}>
                        Trouble signing up? Message us in the
                    </BodySmall>
                    <BodySmall limit={false} color={'primary.main'}>
                        chat or email us at support@parspec.io
                    </BodySmall>
                </Box>
            </Box>
        </Box>
    );
};
