import { axios } from 'src/app/Axios';
import {
    BOMDuplicateResponse,
    BomInfoResponse,
    OMInfoResponse,
    OutofSyncResponse,
    ProjectInfoResponse,
    QuoteInfoResponse,
    SubmittalInfoResponse,
    ViewOption,
    TagsResponse,
    CompanyResponse,
    DashboardPageNameType
} from './apiTypes';
import { docType } from 'src/features/shared/constants';
import { getEvent, pushEvent } from '../../shared/UserActivityTracking/EventService';
import { getStringifiedValueForEventService } from '../../shared/utils/utils';

export const endPoints = {
    project: (selectedView?: ViewOption) => `/dashboard/project/${selectedView === 'my_work' ? '?my_work=true' : ''}`,
    bom: (selectedView?: ViewOption) => `dashboard/bom/${selectedView === 'my_work' ? '?my_work=true' : ''}`,
    submittal: (selectedView?: ViewOption) => `dashboard/submittal/${selectedView === 'my_work' ? '?my_work=true' : ''}`,
    o_m: (selectedView?: ViewOption) => `dashboard/o_m/${selectedView === 'my_work' ? '?my_work=true' : ''}`,
    quotes: (selectedView?: ViewOption) => `/dashboard/quote/${selectedView === 'my_work' ? '?my_work=true' : ''}`,
    payment_Detail: 'api/subscription/get',
    // payment_Detail: 'api/subscription/get',
    getOutOfSyncUrl: (docType: docType) => `/dashboard/bom/${docType}/out_of_sync`,
    stats: (due: string, ownedBy: string) => `/statistics/dashboard?due=${due}&owned_by=${ownedBy}`,
    duplicateBom: `/bom/duplicate`,
    getDeleteProject: (projectId: number) => `/project/${projectId}`,
    getDeleteBom: (bomId: number) => `/bom/${bomId}`,
    documentTags: (docType: string) => `/tag/${docType}`,
    companies: 'rolodex/company_list/'
};

export const getProjectsTabData = async (selectedView?: ViewOption) => {
    return axios.get<ProjectInfoResponse>(endPoints.project(selectedView));
};

export const getBOMTabData = async (selectedView?: ViewOption) => {
    return axios.get<BomInfoResponse>(endPoints.bom(selectedView));
};

export const getSubmittalTabData = async (selectedView?: ViewOption) => {
    return axios.get<SubmittalInfoResponse>(endPoints.submittal(selectedView));
};

export const getOmPackageTabData = async (selectedView?: ViewOption) => {
    return axios.get<OMInfoResponse>(endPoints.o_m(selectedView));
};

export const getQuotesTabData = (selectedView?: ViewOption) => {
    return axios.get<QuoteInfoResponse>(endPoints.quotes(selectedView));
};
export const getStats = async (due: string, ownedBy: string) => {
    return axios.get(endPoints.stats(due, ownedBy));
};

export const deleteProject = async (projectId: number) => {
    return axios.delete(endPoints.getDeleteProject(projectId));
};

export const deleteBom = async (bomId: number) => {
    return axios.delete(endPoints.getDeleteBom(bomId));
};

export const duplicateBomData = async ({ bomId }: { bomId: number; projectId: number }) => {
    return axios.post<BOMDuplicateResponse>(endPoints.duplicateBom, { bom_id: bomId });
};

export const getOutOfSyncData = async (docType: docType) => {
    const url = endPoints.getOutOfSyncUrl(docType);
    return axios.get<OutofSyncResponse>(url);
};

// export const getPaymentDetail = async () => {
//     return axios.get<PaymentDetailResponse>(endPoints.payment_Detail);
// };

export const getDocumentTags = async (docType: docType) => axios.get<TagsResponse[]>(endPoints.documentTags(docType));

export const getCompaniesList = async () => axios.get<CompanyResponse[]>(endPoints.companies);

export const getUserViews = (userId: number) => {
    return getEvent({
        getQuery: `query {
            events_db {
              user_view_preference(where: {user_id: {_eq: ${userId}}}) {
                dashboard_view
              }
            }
          }
          `,
        responseAttribute: 'events_db'
    });
};

export const getSelectedColumnsAndFilters = (payload: { userId: number; pageName: DashboardPageNameType }) => {
    return getEvent({
        getQuery: `query{
            events_db {
              table_settings(where: {page_name: {_eq: ${payload.pageName}},  user_id: {_eq: ${payload.userId}}}) {
                order,
                filters
              }
            }
          }`,
        responseAttribute: 'events_db'
    });
};

// #################################################################################
// ########################## For Selected Columns #################################
// #################################################################################

const insertSelectedColumnsMutation = (payload: { pageName: DashboardPageNameType; columnData: string[] }) => {
    return `mutation($order: [String!] = ${getStringifiedValueForEventService(payload.columnData)}) {
    events_db {
      insert_table_settings(objects: {order: $order, page_name: ${payload.pageName}}) {
        affected_rows
      }
    }
  }`;
};

const updateColumnsMutation = (payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number }) => {
    return `mutation($order: [String!] = ${getStringifiedValueForEventService(payload.columnData)}) {
        events_db {
          update_table_settings(_set: {order: $order}, where: {user_id: {_eq: ${payload.userId}}, page_name: {_eq: ${payload.pageName}}}) {
            affected_rows
          }
        }
      }`;
};

export const updateSelectedColumns = (payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number }) => {
    return pushEvent({
        insertMutation: insertSelectedColumnsMutation(payload),
        updateMutation: updateColumnsMutation(payload),
        responseAttribute: 'table_settings'
    });
};

// #################################################################################
// ########################## For persisting Filters ###############################
// #################################################################################

const insertSelectedFiltersMutation = (payload: { pageName: DashboardPageNameType; filterData: any }) => {
    return `mutation($filters: jsonb = ${getStringifiedValueForEventService(payload.filterData)}) {
  events_db {
    insert_table_settings(objects: {filters: $filters, page_name: ${payload.pageName}}) {
      affected_rows
    }
  }
}`;
};

const updateSelectedFiltersMutation = (payload: { pageName: DashboardPageNameType; filterData: any; userId: number }) => {
    return `mutation($filters: jsonb = ${getStringifiedValueForEventService(payload.filterData)}) {
      events_db {
        update_table_settings(_set: {filters: $filters}, where: {user_id: {_eq: ${payload.userId}}, page_name: {_eq: ${payload.pageName}}}) {
          affected_rows
        }
      }
    }`;
};

export const updateSelectedFilters = (payload: { pageName: DashboardPageNameType; filterData: any; userId: number }) => {
    return pushEvent({
        insertMutation: insertSelectedFiltersMutation(payload),
        updateMutation: updateSelectedFiltersMutation(payload),
        responseAttribute: 'table_settings'
    });
};
