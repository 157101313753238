import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ThemeProvider } from '@parspec/pixel';

import { ResponseInterceptor } from './responseInterceptor';
import AppRoutes from './AppRoutes';
import { ContextProvider } from './Context';
import { queryClient } from './queryClient';
import ErrorBoundary from './errorBoundary';
import './styles.scss';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'xml-viewer-component': { xml: string };
        }
    }
}

const App = (): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <ThemeProvider>
                <ContextProvider>
                    <ErrorBoundary>
                        <AppRoutes />
                    </ErrorBoundary>
                </ContextProvider>
                <ResponseInterceptor />
            </ThemeProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" panelPosition="right" />
    </QueryClientProvider>
);

export default App;
