import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Skeleton } from '@parspec/pixel';

import Footer from '../Footer';
import MainProductTable from './mainProductTable/MainProductTable';
import FreightModal from './freightModal';
import LotPricingModal from './lotPriceModal';
import { ModalCard } from './modalCard';
import ServiceModalWrapper from './servicesModal';
import TaxesModal from './taxesModal';
import ExportButton from '../shared/ExportButton';
import CreateSectionButton from '../shared/CreateSectionButton';
import SortButton from '../shared/SortButton';
import { useCheckAccessForProductFinderOrQuoting } from '../../shared/hooks/useCheckAccessForProductFinderOrQuoting';

import { updateComponentsPricingData, usePricingAndLeadTimeBodList, usePricingBodPatchMutation } from './queries';
import { useGetBomSectionsQuery, useUpdateBomDataMutation, useUpdateGrandTotalMutation } from '../queries';
import { getApiDataForBomInfo, getApiDataForPricingInfo, getDataForPricingTableFromApis, getPriceStats, getServicesTotal } from './shared/utils';
import { MainProductDataType } from './mainProductTable';
import { getAmountForUi, prepareKitsAndComponentsData } from '../shared/utils';
import { convertToCents, convertToDollar } from '../../shared/utils/utils';
import { useGetFrieghtDetailsQuery } from './freightModal/queries';
import { updateLotQueryData, useGetLotDetailsQuery } from './lotPriceModal/queries';
import { useServiceQuery } from './servicesModal/queries';
import { useTaxesQuery } from './taxesModal/queries';
import { TAX_CALC_VALUES } from './taxesModal/constants';
import TabContent from '../shared/TabContent';
import { CALC_METHODS } from './shared/constants';
import OptionTable from './optionsTable/OptionsTable';
import { useCustomisationColumn, useGetBomData, useProjectBomsMappedData } from '../shared/hooks';
import { OptionDataType } from './optionsTable';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import LinkedBomsPopup from '../shared/LinkedBomsPopup';

export function PricingAndLeadTimeTab() {
    const { bomId = '0', projectId } = useParams();
    const navigate = useNavigate();

    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess, isLoading } = useCheckAccessForProductFinderOrQuoting();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!enable_quoting || !quotingAccess) {
            navigate('/v2/dashboard');
        }
    }, [quotingAccess, enable_quoting, isLoading]);

    const [openLotModal, setOpenLotModal] = useState(false);
    const [openServicesModal, setOpenServicesModal] = useState(false);
    const [openTaxesModal, setOpenTaxesModal] = useState(false);
    const [openFreightModal, setOpenFreightModal] = useState(false);
    const [tableMaxHeight, setTableMaxHeight] = useState(300);
    const selectedRowsRef = useRef<Set<number>>(new Set());

    const {
        data: bomData,
        isLoading: isBomDataLoading,
        mainProductOptionsInfo
    } = useGetBomData(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: pricingBodsList, isLoading: isPricingBodListLoading } = usePricingAndLeadTimeBodList(Number(bomId), { enabled: Boolean(bomId) });
    const { mutateAsync: updateBomData } = useUpdateBomDataMutation();
    const { mutateAsync: updatePricingData } = usePricingBodPatchMutation();
    const { mutateAsync: updateGrandTotal } = useUpdateGrandTotalMutation();

    const { data: lotdata, isFetching: isLotDataFetching, isLoading: isLotDataLoading } = useGetLotDetailsQuery(bomId, { enabled: Boolean(bomId) });
    const { data: servicesData, isFetching: isServicesFetching, isLoading: isServicesLoading } = useServiceQuery(Number(bomId), { enabled: Boolean(bomId) });
    const { data: taxesData, isFetching: isTaxesFetching, isLoading: isTaxesLoading } = useTaxesQuery(Number(bomId), { enabled: Boolean(bomId) });
    const { data: freightDetails, isFetching: isFreightFetching, isLoading: isFreightLoading } = useGetFrieghtDetailsQuery(bomId);
    const { handleTablesReordering, sectionRefList, handleTablesWidthChanged } = useCustomisationColumn();
    const { isFetching: sectionLoading } = useGetBomSectionsQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });
    const { projectBomsAndPrimaryData } = useProjectBomsMappedData(Number(projectId));
    const { projectBomsMappedData, primaryBomId } = projectBomsAndPrimaryData;

    const isTableLoading = isPricingBodListLoading || isBomDataLoading || isLotDataLoading;
    const isFooterLoading = isPricingBodListLoading || isBomDataLoading || isLotDataLoading || isServicesLoading || isTaxesLoading || isFreightLoading;
    const {
        tableData,
        totalExtendedPrice = 0,
        totalCost,
        totalExtDiscountedCost,
        totalMarginCost,
        totalMarginPercentage,
        totalDiscount,
        totalDiscountPercentage
    } = useMemo(() => {
        const data = getDataForPricingTableFromApis(bomData?.data, pricingBodsList?.data, lotdata?.data, true);
        if (data && data?.tableData) {
            data.tableData = prepareKitsAndComponentsData(data.tableData);
        }

        return data;
    }, [bomData?.data, pricingBodsList?.data, lotdata?.data]);

    const lotCardData = useMemo(() => {
        const lotsExtendedCostTotal = lotdata?.data.reduce((acc: number | null, row) => {
            if (row.sell_price_cents !== null) {
                return Number(acc) + row.sell_price_cents;
            }
            return acc;
        }, null);
        const lotAmount = lotdata?.data?.length === 0 || lotsExtendedCostTotal === null ? null : convertToDollar(lotsExtendedCostTotal || 0);
        const lotsLength = lotdata?.data?.length || 0;
        return {
            title: 'Lots',
            amount: Number(lotAmount?.toFixed(2) || null),
            amountForUi: lotAmount === null ? null : getAmountForUi(lotAmount),
            helperText: `${lotsLength} ${lotsLength === 1 ? 'Lot' : 'Lots'}`,
            editIconCallBack: () => setOpenLotModal(true),
            loading: isLotDataFetching
        };
    }, [lotdata?.data, isLotDataFetching]);

    const serviceCardData = useMemo(() => {
        const servicesTotal = getServicesTotal(servicesData?.data, Number(totalExtendedPrice.toFixed(2)));
        const servicesLength = servicesData?.data?.length || 0;
        return {
            title: 'Services',
            amount: Number(servicesTotal?.toFixed(2) || null),
            amountForUi: servicesTotal || servicesTotal === 0 ? getAmountForUi(servicesTotal) : null,
            helperText: `${servicesLength} ${servicesLength === 1 ? 'Service' : 'Services'}`,
            editIconCallBack: () => setOpenServicesModal(true),
            loading: isServicesFetching
        };
    }, [servicesData?.data, totalExtendedPrice, isServicesFetching]);

    const freightCardData = useMemo(() => {
        let freightAmount = null;
        if (freightDetails?.data) {
            const { calculation_method, flat_fee_cents, percentage_of_order } = freightDetails.data || {};

            if (calculation_method === CALC_METHODS.FLAT_FEE && flat_fee_cents !== null) {
                freightAmount = convertToDollar(flat_fee_cents);
            } else if (calculation_method === CALC_METHODS.PERCENTAGE_OF_ORDER && percentage_of_order !== null) {
                freightAmount = (Number(totalExtendedPrice.toFixed(2)) * percentage_of_order) / 100;
            }
        }

        return {
            title: 'Freight',
            amount: Number(freightAmount?.toFixed(2) || null),
            amountForUi: freightAmount === null ? freightAmount : getAmountForUi(freightAmount),
            helperText: null,
            editIconCallBack: () => setOpenFreightModal(true),
            loading: isFreightFetching
        };
    }, [freightDetails?.data, totalExtendedPrice, isFreightFetching]);

    const taxCardData = useMemo(() => {
        const taxType = taxesData?.data.calculation_method;
        let totalAmount = 0;
        if (taxType === TAX_CALC_VALUES.MANUAL_ENTRY) {
            if (taxesData?.data?.apply_taxes_to_products) {
                totalAmount += Number(totalExtendedPrice.toFixed(2));
            }

            if (taxesData?.data?.apply_taxes_to_services) {
                totalAmount += Number(serviceCardData.amount);
            }

            if (taxesData?.data?.apply_taxes_to_freight) {
                totalAmount += Number(freightCardData.amount);
            }
        }
        const totalTaxAmount =
            taxType !== TAX_CALC_VALUES.TAX_EXEMPT && (taxesData?.data.tax_percentage || taxesData?.data.tax_percentage === 0) ? (totalAmount * taxesData?.data.tax_percentage) / 100 : null;
        return {
            title: 'Taxes',
            amount: Number(totalTaxAmount?.toFixed(2) || null),
            amountForUi: totalTaxAmount === null ? totalTaxAmount : getAmountForUi(totalTaxAmount),
            helperText: taxType === TAX_CALC_VALUES.TAX_EXEMPT ? 'Taxes excluded' : `${taxesData?.data.tax_percentage || 0}% tax rate`,
            editIconCallBack: () => setOpenTaxesModal(true),
            loading: isTaxesFetching
        };
    }, [taxesData?.data, serviceCardData, freightCardData, totalExtendedPrice, isTaxesFetching]);

    const grandTotal = Number(totalExtendedPrice.toFixed(2)) + Number(taxCardData.amount) + Number(freightCardData.amount) + Number(serviceCardData.amount);

    const makeApiCall = useCallback(async () => {
        const payload = {
            bomId: String(bomId),
            input: {
                grand_total_cents: convertToCents(grandTotal),
                timestamp: Date.now() / 1000
            }
        };
        await updateGrandTotal(payload);
    }, [grandTotal]);

    useEffect(() => {
        if (typeof grandTotal === 'number') {
            makeApiCall();
        }
    }, [grandTotal, makeApiCall]);

    const cardDataArr = useMemo(() => [lotCardData, serviceCardData, freightCardData, taxCardData], [lotCardData, serviceCardData, freightCardData, taxCardData]);

    const handlePricingInfoEdit = useCallback(
        async function (rowData: Partial<Omit<MainProductDataType, 'qty' | 'type' | 'manufacturer' | 'modelNo' | 'specification' | 'lotInfo'>>) {
            const { id: bodId = 0, ...restRowInfo } = rowData;
            const apiReqData = getApiDataForPricingInfo(restRowInfo);
            if (bodId && apiReqData) {
                await updatePricingData({ bomId: Number(bomId), pricingBod: apiReqData, bodId: bodId });
            }
        },
        [updatePricingData, bomId]
    );

    const handleBomInfoEdit = useCallback(
        async function (
            rowData: Partial<Pick<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'id'>> & Partial<Pick<OptionDataType, 'replaceProductId'>>
        ) {
            const apiReqData = getApiDataForBomInfo(rowData);
            await updateBomData({ bomId: String(bomId), input: apiReqData });
        },
        [updateBomData, bomId]
    );

    const handleLotUpdate = useCallback(
        function ({ bodId, prevLotId, currLotId, componentIds }: { bodId: number; prevLotId: string; currLotId: string; componentIds?: Set<number> }) {
            const lotIdToAdd = currLotId === '-' ? null : Number(currLotId);
            const lotIdToDelete = prevLotId === '-' ? null : Number(prevLotId);
            const pricingInfoToUpdate =
                lotIdToAdd === null
                    ? {
                          lotId: lotIdToAdd,
                          id: bodId
                      }
                    : {
                          lotId: lotIdToAdd,
                          id: bodId,
                          cost: null,
                          discount: null,
                          discountedCost: null,
                          margin: null,
                          sellPrice: null,
                          extendedPrice: null
                      };
            updateLotQueryData(bomId || '0', lotIdToAdd, lotIdToDelete);
            if (componentIds) {
                updateComponentsPricingData(Number(bomId), componentIds);
            }
            handlePricingInfoEdit(pricingInfoToUpdate);
        },
        [updateLotQueryData, handlePricingInfoEdit, bomId]
    );

    const handleNewLot = useCallback(() => setOpenLotModal(true), []);

    const statsData = isFooterLoading
        ? []
        : sectionLoading
        ? getPriceStats({})
        : getPriceStats({ totalExtendedPrice, totalCost, totalExtDiscountedCost, totalMarginCost, totalMarginPercentage, totalDiscount, totalDiscountPercentage, grandTotal });

    function sectionRefCallback(ref: HTMLDivElement) {
        if (ref) {
            // Section height - height of elements excluding table
            setTableMaxHeight(ref.offsetHeight - 157);
        }
    }
    const isBomLinked = projectBomsMappedData.get(Number(bomId));

    return (
        <>
            {isBomLinked ? (
                <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />
            ) : (
                <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                    {isBomLinked && <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />}
                    <Box flex={1} minHeight={0} py={4} display="flex" flexDirection="column">
                        <Box flex={1} display="flex" minHeight={0} ref={sectionRefCallback} position="relative">
                            <TabContent
                                tableData={tableData || []}
                                tabCta={
                                    <Box width="100%" display="flex" flexDirection="row-reverse" gap={2}>
                                        <ExportButton />
                                        <CreateSectionButton />
                                        <SortButton />
                                    </Box>
                                }
                                ref={sectionRefList}
                            >
                                <MainProductTable
                                    section={undefined as never}
                                    optionSection={undefined as never}
                                    tableData={undefined as never}
                                    onAddOption={undefined as never}
                                    isAddOptionLoading={undefined as never}
                                    isLoading={isTableLoading}
                                    onPricingInfoEdit={handlePricingInfoEdit}
                                    onBomInfoEdit={handleBomInfoEdit}
                                    onNewLot={handleNewLot}
                                    onLotUpdate={handleLotUpdate}
                                    tableMaxHeight={tableMaxHeight}
                                    mainProductOptionsInfo={mainProductOptionsInfo}
                                    handleTablesReordering={handleTablesReordering}
                                    handleTablesWidthChanged={handleTablesWidthChanged}
                                    selectedRowsRef={selectedRowsRef}
                                />
                                <OptionTable
                                    section={undefined as never}
                                    tableData={undefined as never}
                                    isLoading={isTableLoading}
                                    tableMaxHeight={tableMaxHeight}
                                    onPricingInfoEdit={handlePricingInfoEdit}
                                    onBomInfoEdit={handleBomInfoEdit}
                                    handleTablesReordering={handleTablesReordering}
                                    handleTablesWidthChanged={handleTablesWidthChanged}
                                    selectedRowsRef={selectedRowsRef}
                                />
                            </TabContent>
                        </Box>
                        <Box width="100%" display="flex" gap={4} pt={4} px={6} pb={0}>
                            {cardDataArr.map(({ title, amountForUi, helperText, editIconCallBack, loading }) =>
                                loading ? (
                                    <Skeleton variant="rectangular" width="25%" height="100%" />
                                ) : (
                                    <ModalCard title={title} amount={amountForUi} helpertext={helperText} editIconCallback={editIconCallBack} />
                                )
                            )}
                        </Box>
                    </Box>
                    <Footer statsData={statsData} />
                    {openLotModal && <LotPricingModal open={openLotModal} onClose={() => setOpenLotModal(false)} />}
                    {openServicesModal && <ServiceModalWrapper totalExtendedPrice={Number(totalExtendedPrice.toFixed(2))} open={openServicesModal} onClose={() => setOpenServicesModal(false)} />}
                    {openTaxesModal && (
                        <TaxesModal
                            open={openTaxesModal}
                            onClose={() => setOpenTaxesModal(false)}
                            totalExtendedPrice={Number(totalExtendedPrice.toFixed(2))}
                            servicesTotal={Number(serviceCardData.amount || 0)}
                            freightTotal={Number(freightCardData.amount || 0)}
                        />
                    )}
                    {openFreightModal && <FreightModal totalExtendedPrice={Number(totalExtendedPrice.toFixed(2))} open={openFreightModal} onClose={() => setOpenFreightModal(false)} />}
                </Box>
            )}
        </>
    );
}
