import { Base64 } from 'js-base64';
import { environment } from 'src/environments';

export const getProxyFileUrl = (fileUrl: string) => `${environment.X}${Base64.encode(fileUrl)}`;

export const getFastProxyFileUrl = (fileUrl: string) => `${environment.d}${Base64.encode(fileUrl)}`;

export const getArrayBetweenNumbers = (start: number, end: number) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const getParsedXFDFForAPIPayload = (xfdf: string, selectedPages: number[], hiddenXfdf: string) => {
    let mutatedXfdf = xfdf;
    const sortedSelectedPages = selectedPages.sort((a, b) => a - b);
    for (let i = 0; i < sortedSelectedPages.length; i++) {
        mutatedXfdf = mutatedXfdf?.replaceAll(`page="${i}"`, `page-temporary="${sortedSelectedPages[i] - 1}"`);
    }

    mutatedXfdf = mutatedXfdf?.replaceAll('page-temporary', 'page');

    const parser = new DOMParser();
    const parsedHiddenXfdf = parser.parseFromString(hiddenXfdf, 'text/xml');
    const hiddenXfdfAnnots = parsedHiddenXfdf.getElementsByTagName('annots')[0];

    const parsedXfdf = parser.parseFromString(mutatedXfdf, 'text/xml');
    const parsedXfdfAnnots = parsedXfdf.getElementsByTagName('annots')[0];

    const hiddenChildNodes = hiddenXfdfAnnots.childNodes;
    if (hiddenChildNodes.length) {
        hiddenChildNodes.forEach((node: any) => {
            const cloneNode = node.cloneNode(true);
            parsedXfdfAnnots.appendChild(cloneNode);
        });
    }
    mutatedXfdf = new XMLSerializer().serializeToString(parsedXfdf.documentElement);
    return mutatedXfdf;
};

export const getParsedXFDFForWebviewer = (xfdf: string, selectedPages: number[], unselectedPages: any, hiddenXfdf: string) => {
    let mutatedXfdf = xfdf;
    const sortedSelectedPages = selectedPages.sort((a, b) => a - b);
    const sortedUnSelectedPages = unselectedPages.sort((a: any, b: any) => a - b);
    const parser = new DOMParser();
    const xy = parser.parseFromString(mutatedXfdf, 'text/xml');
    const ffield = xy.getElementsByTagName('ffield')[0];
    ffield?.parentNode?.removeChild(ffield);
    const widget = xy.getElementsByTagName('widget')[0];
    widget?.parentNode?.removeChild(widget);
    const fields = xy.getElementsByTagName('fields')[0];
    fields?.parentNode?.removeChild(fields);
    const pdfinfo = xy.getElementsByTagName('pdf-info')[0];
    pdfinfo?.parentNode?.removeChild(pdfinfo);
    let mutatedHiddenXfdf = hiddenXfdf;
    const parsedHiddenXfdf = parser.parseFromString(mutatedHiddenXfdf, 'text/xml');
    const hiddenXfdfAnnots = parsedHiddenXfdf.getElementsByTagName('annots')[0];

    const xyzw = xy.getElementsByTagName('annots')[0];
    for (let i = 0; i < [...new Set(sortedUnSelectedPages)].length; i++) {
        const pagenum = sortedUnSelectedPages[i] - 1;
        // eslint-disable-next-line no-useless-escape
        const elements = xyzw?.querySelectorAll(`[page=\"${pagenum}\"]`);
        if (elements?.length) {
            elements.forEach((ele: any) => {
                const cloneNode = ele.cloneNode(true);
                hiddenXfdfAnnots.appendChild(cloneNode);
                ele.parentNode.removeChild(ele);
            });
        }
    }
    mutatedXfdf = new XMLSerializer().serializeToString(xy.documentElement);
    for (let i = 0; i < sortedSelectedPages.length; i++) {
        mutatedXfdf = mutatedXfdf?.replaceAll(`page="${sortedSelectedPages[i] - 1}"`, `page="${i}"`);
    }

    mutatedHiddenXfdf = new XMLSerializer().serializeToString(parsedHiddenXfdf.documentElement);
    return { mutatedXfdf, hiddenXfdf: mutatedHiddenXfdf };
};

export const websiteValidationRegex = (): RegExp => {
    return /^$|(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
};

export const specialCharactersRegex: RegExp = /^[a-zA-Z0-9 ,]*$/;

export const getParsedCoordinatesForWebviewer = (coordinates: Array<any> = [], selectedPages: number[] = []) => {
    const mutatedCoordinates: any[] = [];
    const hiddenSuggestions: any[] = [];
    coordinates.forEach((coordinate: any) => {
        const pageNum = parseInt(coordinate['page']);
        const newIndex = selectedPages.indexOf(pageNum + 1);
        if (newIndex !== -1) {
            mutatedCoordinates.push({ ...coordinate, page: newIndex });
        } else {
            hiddenSuggestions.push(coordinate);
        }
    });
    return { mutatedCoordinates, hiddenSuggestions };
};

// takes a url and if the url s3
export function getSanitizedUrl(url: string): string {
    const conditions = ['parspec', '.s3.us'];
    if (conditions.some((condition) => url.includes(condition))) {
        return getProxyFileUrl(url);
    }
    return url;
}

export function sourceTagForPdfThumbnail(searchResult: any) {
    const source = searchResult?.search_result_metadata;
    let tagDetailsAvailable = true,
        selectedDate = '',
        link: any,
        tag = '';
    switch (source?.type) {
        case 'user-rated':
            if (!source.user_name) {
                tagDetailsAvailable = false;
            } else if (source.date) {
                selectedDate = getMMDDYYYY(source.date);
                if (searchResult.elastic_url) {
                    link = searchResult.elastic_url;
                    tag = 'Selected on ' + selectedDate + ' by ' + source.user_name;
                } else {
                    tag = 'Selected on ' + selectedDate + ' by ' + source.user_name;
                }
            } else {
                if (searchResult.elastic_url) {
                    link = searchResult.elastic_url;
                    tag = 'Selected by ' + source.user_name;
                } else {
                    tag = 'Selected by ' + source.user_name;
                }
            }
            break;
        case 'elastic':
            if (!source.manufacturer) {
                tagDetailsAvailable = false;
            } else {
                tag = 'Verified Manufacturer - ' + source.manufacturer;
                link = searchResult?.datasheet_url;
            }
            break;
        case 'old_elastic':
            if (!source.manufacturer) {
                tagDetailsAvailable = false;
            } else {
                tag = source.manufacturer;
                link = searchResult?.datasheet_url;
            }
            break;
        case 'pastedURL':
            if (!source.user_name) {
                tagDetailsAvailable = false;
            } else if (source.date) {
                selectedDate = getMMDDYYYY(source.date);
                tag = 'Link Upload on ' + selectedDate + ' by ' + source.user_name;
            } else {
                tag = 'Link Upload by ' + source.user_name;
            }
            link = searchResult.elastic_url || searchResult.datasheet_url;
            //adding OR won't fail because the only case where we won't get elasticl_url is when it's the same as datasheet_url
            break;
        case 'uploaded':
            if (!source.user_name) {
                tagDetailsAvailable = false;
            } else if (source.date) {
                selectedDate = getMMDDYYYY(source.date);
                tag = 'PDF Upload on ' + selectedDate + ' by ' + source.user_name;
                link = searchResult.datasheet_url;
            } else {
                tag = 'PDF Upload by ' + source.user_name;
                link = searchResult.datasheet_url;
            }
            break;
        case 'google':
            tag = searchResult?.datasheet_url;
            link = searchResult?.datasheet_url;
            break;
        default:
            tagDetailsAvailable = false;
            break;
    }

    return { link, tag, tagDetailsAvailable };
}

function getMMDDYYYY(date: any) {
    const today = new Date(date);
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return mm + '/' + dd + '/' + yyyy || '';
}
