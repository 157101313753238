import { rest } from 'msw';

import { mockGetProductRequirements } from './productRequirements';

const searchProductMockHandlers = [
    rest.get('*/bom/:bomId/product_finder/:bodId/requirements', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(mockGetProductRequirements));
    }),
    rest.patch('*/bom/:bomId/product_finder/:bodId/requirements', async (req, res, ctx) => {
        const reqBody = await req.json();
        return res(ctx.json(reqBody));
    }),
    rest.post('*/bom/:bomId/product_finder/:bodId/selected_datasheet/:selectedDatasheetID/extract_attributes', async (_req, res, ctx) => {
        return res(ctx.delay(2400), ctx.json({ message: 'Attributes extracted successfully' }));
    })
];

export { searchProductMockHandlers };
