import React from 'react';
import { FallbackRender } from '@sentry/react';

import { Box, H4, ParspecLogoIcon, Button, H6 } from '@parspec/pixel';

export default function ErrorBoundaryFallback({ resetError }: Parameters<FallbackRender>[0]) {
    return (
        <React.Fragment>
            <Box color="primary.main" width="100%" height="100vh" textAlign="center" display="flex" flexDirection="column" justifyContent="center">
                <Box p={2}>
                    <Box width="max-content" margin="0 auto">
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    '& svg': {
                                        fontSize: '44px'
                                    }
                                }}
                            >
                                <ParspecLogoIcon color={'tertiary'} />
                            </Box>
                            <H4 marginLeft="9px" sx={{ fontWeight: '800' }}>
                                PARSPEC
                            </H4>
                        </Box>
                    </Box>
                    <H6 limit lines={2}>
                        Looks like an unforeseen issue. Our support and engineering team will be notified to resolve this issue as soon as possible.
                    </H6>
                    <Button onClick={resetError}>Refresh The Page</Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
