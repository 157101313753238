import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { Snackbar } from '@parspec/pixel';

import { QuoteEntity, ERROR_KEYS } from '../constants';
import { BOM_DATA_PUSHED_MESSAGE } from 'src/features/shared/constants';

export interface FieldValueProps {
    sectionName: string;
    subSectionName: string;
    value: any;
}

interface IQuoteContext {
    accordionFieldName: string;
    setAccordionFieldName: React.Dispatch<React.SetStateAction<QuoteEntity>>;
    isFetch: boolean;
    setIsFetch: React.Dispatch<React.SetStateAction<boolean>>;
    handlePdfPreview: (value: boolean) => void;
    isInitiateApiCalls: boolean;
    isTextFieldChanged: boolean;
    handleTextFieldChanged: (value: boolean) => void;
    handleError: (errorName: ERROR_KEYS, errorMessage: string) => void;
    error: Record<string, string>;
    bomId: string;
    changedSections: { [key: string]: any };
    handleUpdateChangedSections: ({ sectionName, subSectionName, value }: FieldValueProps) => void;
    handleDeleteChangedSections: (sectionName: string, subSectionName: string) => void;
    handleDataPushSuccess: () => void;
}

const QuoteContext = createContext<IQuoteContext>({
    accordionFieldName: '',
    setAccordionFieldName: () => {},
    isFetch: false,
    setIsFetch: () => {},
    handlePdfPreview: () => {},
    isInitiateApiCalls: false,
    isTextFieldChanged: false,
    handleTextFieldChanged: () => {},
    handleError: () => {},
    error: {},
    bomId: '',
    changedSections: {},
    handleUpdateChangedSections: () => {},
    handleDeleteChangedSections: () => {},
    handleDataPushSuccess: () => {}
});

interface IQuoteContextProvider {
    isInitiateApiCalls: boolean;
    bomId: string;
}

export const QuoteContextProvider: React.FC<PropsWithChildren<IQuoteContextProvider>> = ({ children, isInitiateApiCalls, bomId }) => {
    const [accordionFieldName, setAccordionFieldName] = useState(QuoteEntity['HeaderAndFooter']);
    const [isFetch, setIsFetch] = useState(false);
    const [isTextFieldChanged, setIsTextFieldChanged] = useState(false);
    const [error, setError] = useState<Record<ERROR_KEYS, string>>({ [ERROR_KEYS.QUOTE_CUSTOM_ENTRY]: '', [ERROR_KEYS.SELECTED_BOMS_EMPTY]: '' });
    const [changedSections, setChangedSections] = useState<{ [key: string]: any }>({});
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handlePdfPreview = (value: boolean) => {
        setIsFetch(value);
    };

    const handleTextFieldChanged = (value: boolean) => {
        setIsTextFieldChanged(value);
    };

    const handleError = (errorName: ERROR_KEYS, errorMessage: string) => {
        setError({ ...error, [errorName]: errorMessage });
    };

    const handleUpdateChangedSections = ({ sectionName, subSectionName, value }: FieldValueProps) => {
        setChangedSections((old) => {
            let fields = { ...old };
            if (fields[bomId]) {
                if (fields[bomId][sectionName]) {
                    fields[bomId][sectionName] = { ...fields[bomId][sectionName], [subSectionName]: value };
                } else {
                    fields[bomId] = {
                        ...fields[bomId],
                        [sectionName]: {
                            [subSectionName]: value
                        }
                    };
                }
            } else {
                fields = {
                    ...fields,
                    [bomId]: {
                        [sectionName]: {
                            [subSectionName]: value
                        }
                    }
                };
            }
            return fields;
        });
    };

    const handleDeleteChangedSections = (sectionName: string, subSectionName: string) => {
        setChangedSections((old) => {
            const fields = { ...old };
            Object.keys(fields).forEach((key) => {
                delete fields[key]?.[sectionName]?.[subSectionName];
            });
            return fields;
        });
    };

    const handleDataPushSuccess = () => {
        setSnackbarMessage(BOM_DATA_PUSHED_MESSAGE);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <QuoteContext.Provider
                value={{
                    accordionFieldName,
                    setAccordionFieldName,
                    isFetch,
                    setIsFetch,
                    handlePdfPreview,
                    isInitiateApiCalls,
                    isTextFieldChanged,
                    handleTextFieldChanged,
                    handleError,
                    error,
                    bomId,
                    changedSections,
                    handleUpdateChangedSections,
                    handleDeleteChangedSections,
                    handleDataPushSuccess
                }}
            >
                {children}
            </QuoteContext.Provider>
        </>
    );
};

export const useQuoteContext = () => {
    return useContext(QuoteContext);
};
