import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import Header from '../shared/Header';
import MainContent from './MainContent';
import PreviewContext from './Context';
import { useCompileDocumentPreviewQuery, useDocumentPresetMutation } from './queries';
import { PRESET_RESPONSE, ENTITY } from '../constants';
import { getCurrentTimezoneName } from '../shared/utils';

const CompileDocument: React.FC = () => {
    const [accordionFieldName, setAccordionFieldName] = useState('cover_page');
    const { bomId = '', documentType = '' } = useParams();

    const { mutate: documentPresetMutation, data: presetData } = useDocumentPresetMutation();

    const { isLoading: isPreviewImageLoading, refetch: refetchPreviewImage } = useCompileDocumentPreviewQuery(bomId, accordionFieldName, documentType, {
        enabled: presetData?.data?.message === PRESET_RESPONSE.SUCCESS && accordionFieldName !== ENTITY.LAYOUT
    });

    const [isFetch, setIsFetch] = useState(false);
    const [presetResponse, setPresetResponse] = useState('');

    const handlePreviewImage = (value: boolean) => {
        if (accordionFieldName === ENTITY.LAYOUT) {
            return;
        }
        setIsFetch(value);
    };

    const handlePreviewImageClick = () => {
        setIsFetch(false);
        if (accordionFieldName === ENTITY.LAYOUT) {
            return;
        }
        refetchPreviewImage();
    };

    useEffect(() => {
        documentPresetMutation(
            { bomId: bomId!, documentType: documentType!, timezone: getCurrentTimezoneName() },
            {
                onSuccess: (data) => {
                    setPresetResponse(data?.data?.message);
                }
            }
        );
    }, []);

    const submittalsOmBomId = useMemo(() => [Number(bomId)], [bomId]);

    return (
        <PreviewContext.Provider value={{ handlePreviewImage, accordionFieldName, presetResponse }}>
            <Box>
                <Header submittalsOmBomId={submittalsOmBomId} />
                <MainContent
                    isFetch={isFetch}
                    setAccordionFieldName={setAccordionFieldName}
                    handlePreviewImageClick={handlePreviewImageClick}
                    isPreviewImageLoading={isPreviewImageLoading}
                    accordionFieldName={accordionFieldName}
                />
            </Box>
        </PreviewContext.Provider>
    );
};

export default CompileDocument;
