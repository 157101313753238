import { Box, BodyMedium, Switch, RichTextEditor } from '@parspec/pixel';
import ApplyToAllBomsButton from './ApplyToAllBomsButton';

interface IToggleRichText {
    checked?: boolean;
    switchLabel: string;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    blurAction: (html: string) => void;
    placeholder: string;
    initialHtml: string;
    disabled?: boolean;
    onChange?: (html: string) => void;
    sectionName?: string;
    subSectionName?: string;
}

function ToggleRichTextEditor({ checked, switchLabel, onToggle, placeholder, initialHtml, blurAction, disabled = false, onChange, sectionName, subSectionName }: IToggleRichText) {
    return (
        <Box>
            <Box display={'flex'} gap={'12px'} mb={2} alignItems="center">
                <BodyMedium fontWeight={500} sx={{ opacity: disabled ? 0.4 : 1 }}>
                    {switchLabel}
                </BodyMedium>
                <Switch checked={checked} onChange={(e) => onToggle(e)} disabled={disabled} />
                {sectionName && (
                    <Box ml={-3}>
                        <ApplyToAllBomsButton sectionName={sectionName} subSectionName={subSectionName} />
                    </Box>
                )}
            </Box>

            <RichTextEditor initialHtml={initialHtml} placeHolderText={placeholder} onBlur={blurAction} isDisable={disabled} onChange={onChange} />
        </Box>
    );
}

export default ToggleRichTextEditor;
