import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid, BodyMedium, BodySmall, Button, Switch, Menu, Paper, Box, Snackbar, BodyXS, MoreVertIcon, StatusSelect, Skeleton, SelectChangeEvent } from '@parspec/pixel';

import CardKeyValue from './CardKeyValue';
import { DocumentResponse as DocumentPropTypes } from '../queries/apiTypes';
import { QUOTE } from '../constants';
import { useAllTagsQuery } from '../../../Project/queries';

import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { getDocumentOptions } from '../../../shared/utils/utils';
import { useCheckAccessForProductFinderOrQuoting } from '../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';

import EmailPopup from '../EmailPopup';
import { useGetBomDetails } from '../../queries';
import { useGetShareOrDownloadOptions } from '../shared/hooks';

interface DocumentProps {
    isLabelVisible?: boolean;
    documentCardData?: DocumentPropTypes;
    handleEditNotes: (id: number, notesContent: string, flag: string) => void;
    getSharedState: (state: boolean, id: number, flag: string) => void;
    flag: string;
    deleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    handleDelete: (submittalId: number, documentType: string) => void;
    documentType?: 'submittal' | 'o_m' | 'quote';
    onSelectStatus: (statusId: number, id: number, documentType: string) => Promise<void>;
}

const DocumentCard: React.FC<DocumentProps> = ({ isLabelVisible, documentCardData, handleEditNotes, getSharedState, flag, deleteConfirmation, handleDelete, documentType = '', onSelectStatus }) => {
    const navigate = useNavigate();
    const { bomId = '' } = useParams();
    const { data: allTagsData } = useAllTagsQuery();
    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();
    const { data: bomData } = useGetBomDetails(bomId || '', {
        enabled: Boolean(bomId)
    });

    const [isPublished, setIsPublished] = useState(documentCardData?.is_published);
    const [documentStatus, setDocumentStatus] = useState(0);
    const [openEmailPopup, setOpenEmailPopup] = useState(false);

    const showOpenEmailPopup = useCallback(() => {
        setOpenEmailPopup(true);
    }, []);

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();
    const { optionsArray: shareOptionsArr } = useGetShareOrDownloadOptions({ quoteId: documentCardData?.id, onClick: showOpenEmailPopup, setSnackbarOpen, documentType });

    useEffect(() => {
        setIsPublished(documentCardData?.is_published);
    }, [documentCardData?.is_published]);

    useEffect(() => {
        setDocumentStatus(documentCardData?.status || 0);
    }, [documentCardData?.status]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublished(event.target.checked);
        getSharedState(event.target.checked, documentCardData?.id || 0, flag);
    };

    const onDeleteConfirmationHandler = (id: number) => {
        handleDelete(id, flag);
        deleteConfirmation(true);
    };

    const onViewHandler = (id: number) => {
        if (id) {
            navigate(`finalDocuments/share/${flag}/${id}`);
        }
    };

    const viewDocumentOption = {
        label: 'View',
        onClick: () => onViewHandler(Number(documentCardData?.id))
    };

    const deleteDocumentOption = {
        label: 'Delete',
        onClick: () => onDeleteConfirmationHandler(Number(documentCardData?.id)),
        color: 'error.main'
    };

    function handleOnSelect(e: SelectChangeEvent<unknown>) {
        const value = Number(e.target.value);
        setDocumentStatus(Number(value));
        onSelectStatus(value, Number(documentCardData?.id), flag);
    }

    const DOCUMENT_STATUS_OPTIONS = getDocumentOptions(allTagsData, documentType);

    const menuOptions = () => [viewDocumentOption, deleteDocumentOption];

    const handleEmailClose = (message?: string) => {
        setOpenEmailPopup(false);
        if (message) setSnackbarOpen(message);
    };

    const selectedBomIds = useMemo(() => {
        return [Number(bomId)];
    }, [bomId]);

    return (
        <>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
            <Paper
                sx={{
                    width: 348,
                    height: 494
                }}
                variant="elevation"
                elevation={3}
            >
                <Grid container direction={'column'} height={'100%'} justifyContent={'space-around'}>
                    <Grid item>
                        <Grid container justifyContent={'space-between'} p={2}>
                            {/* label */}
                            <Grid visibility={isLabelVisible ? 'visible' : 'hidden'} item width={114}>
                                <Box width={'100%'} textAlign={'center'} bgcolor={'success.main'} p={1} borderRadius={1}>
                                    <BodyXS fontWeight={700} color={'neutral.light'}>
                                        LATEST VERSION
                                    </BodyXS>
                                </Box>
                            </Grid>

                            {/* menu */}
                            <Grid item bgcolor={'neutral.light'} borderRadius={'50%'}>
                                <Menu options={menuOptions()} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* primary title */}
                    <Grid item p={4}>
                        <BodyMedium
                            fontWeight={500}
                            sx={{
                                minHeight: 24
                            }}
                            color={'secondary.main'}
                        >
                            {documentCardData?.primary_title || '_'}
                        </BodyMedium>
                    </Grid>

                    {/* secondary title */}
                    {documentType !== QUOTE && (
                        <Grid item pl={4}>
                            <BodyXS
                                fontWeight={500}
                                sx={{
                                    minHeight: 18
                                }}
                                color={'secondary.main'}
                            >
                                {documentCardData?.secondary_title || '_'}
                            </BodyXS>
                        </Grid>
                    )}
                    {/* key value pair */}
                    <Grid item p={4}>
                        <Grid container direction={'column'} alignItems={'flex-start'} rowSpacing={2}>
                            <Grid item width={'100%'}>
                                <CardKeyValue keyName={'Version'} value={documentCardData?.version || ''} />
                            </Grid>

                            <Grid item width={'100%'}>
                                <CardKeyValue isDate keyName={'Creation Date'} value={documentCardData?.created_at || ''} />
                            </Grid>

                            <Grid item width={'100%'}>
                                <CardKeyValue keyName={'Created By'} value={documentCardData?.compiled_by || ''} />
                            </Grid>

                            <Grid item width={'100%'}>
                                <CardKeyValue keyName={'Products Included'} value={documentCardData?.product_count || ''} />
                            </Grid>

                            <Grid item width={'100%'}>
                                <CardKeyValue keyName={documentType === QUOTE ? 'Quote #' : 'Quote Number'} value={documentCardData?.quote_number || ''} />
                            </Grid>

                            {documentType === QUOTE && (
                                <Grid item width={'100%'}>
                                    <CardKeyValue keyName={'Grand Total'} value={documentCardData?.grand_total} />
                                </Grid>
                            )}

                            {(documentType !== QUOTE || quotingAccess) && (
                                <Grid item width={'100%'}>
                                    <CardKeyValue
                                        keyName={'Version Notes'}
                                        value={documentCardData?.id || ''}
                                        notesContent={documentCardData?.version_notes || ''}
                                        handleEditNotes={handleEditNotes}
                                        isNotes
                                        flag={flag}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {/* paragraph */}

                    {(documentType !== QUOTE || quotingAccess) && (
                        <Grid item pl={4} pb={6} height={'15px'}>
                            <BodyXS mr={2} lines={1}>
                                {documentCardData?.version_notes || ''}
                            </BodyXS>
                        </Grid>
                    )}

                    {/* document status */}

                    {(documentType !== QUOTE || quotingAccess) && (
                        <Grid item pl={4} pr={4}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <BodyMedium fontWeight={500} pb={1}>
                                    Status
                                </BodyMedium>
                                {DOCUMENT_STATUS_OPTIONS.length ? (
                                    <StatusSelect
                                        value={documentStatus || 0}
                                        id={`${documentType}-${documentCardData?.id}`}
                                        labelId={`${documentType}-${documentCardData?.id}`}
                                        options={DOCUMENT_STATUS_OPTIONS as any}
                                        optionLabelKeyname="label"
                                        optionValueKeyname="value"
                                        onChange={handleOnSelect}
                                    />
                                ) : (
                                    <Skeleton width="100%" height={34} variant="rectangular" sx={{ borderRadius: 1 }} />
                                )}
                            </Box>
                        </Grid>
                    )}

                    {/* actions */}
                    <Grid item>
                        <Grid container columnSpacing={3} p={4}>
                            <Grid item xs={6}>
                                <Button color="secondary" fullWidth variant="outlined" onClick={() => onViewHandler(Number(documentCardData?.id || ''))}>
                                    View
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Menu options={shareOptionsArr}>
                                    <Button variant="outlined" color="secondary" fullWidth endIcon={<MoreVertIcon />}>
                                        Share
                                    </Button>
                                </Menu>
                            </Grid>
                        </Grid>

                        <Box width={'100%'} textAlign={'center'}>
                            <Switch size={'small'} label={<BodySmall color={'secondary.main'}>Publish to Shared Link</BodySmall>} checked={isPublished || false} onChange={handleOnChange} />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {openEmailPopup && (
                <EmailPopup
                    projectId={bomData?.data.project || -1}
                    open={openEmailPopup}
                    onClose={handleEmailClose}
                    selectedBomdIds={selectedBomIds}
                    documentType={documentType}
                    isBulkAction={false}
                    docId={documentCardData?.id.toString()}
                />
            )}
        </>
    );
};

export default DocumentCard;
