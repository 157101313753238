import { useState, useEffect } from 'react';

import { BodySmall, TextField, Grid, Select, SelectChangeEvent } from '@parspec/pixel';

import { IAddressTo } from '../submittals&o_m/queries/apiTypes';
import { USA_STATES, CANADA_STATES, COUNTRY_OPTIONS, COUNTRY } from 'src/features/shared/constants';
import { modifiedStateList } from 'src/features/shared/utils/utils';

interface BasicAddressTypes {
    address?: Partial<IAddressTo>;
    label?: string;
    action: (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => void;
    onBlur: () => void;
    onFocus?: () => void;
    disabled?: boolean;
}

const BasicAddress = ({ address, label, action, onBlur, onFocus, disabled }: BasicAddressTypes) => {
    const [stateListOptions, setStateListOptions] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }]);
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        if (address?.country === COUNTRY.USA) {
            setStateListOptions(modifiedStateList(USA_STATES));
        } else if (address?.country === COUNTRY.CANADA) {
            setStateListOptions(modifiedStateList(CANADA_STATES));
        }

        setCountry(address?.country || '');
    }, [address?.country]);

    useEffect(() => {
        if (address?.state) {
            if (USA_STATES.includes(address?.state) || CANADA_STATES.includes(address?.state)) {
                const codedStateValue = address.state.substring(0, 2);
                setState(codedStateValue);
            } else {
                setState(address.state);
            }
        } else {
            setState('');
        }
    }, [address?.state]);

    return (
        <Grid container>
            {label ? (
                <Grid item xs={4}>
                    <BodySmall>{label}</BodySmall>
                </Grid>
            ) : null}

            <Grid item xs={label ? 8 : 12}>
                <Grid container direction={'column'} rowSpacing={2}>
                    <Grid item>
                        <TextField
                            disabled={disabled}
                            label={''}
                            placeholder={'Address Line 1'}
                            name="address1"
                            color="secondary"
                            value={address?.address1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => action(e)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            inputProps={{
                                maxLength: 64
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            disabled={disabled}
                            label={''}
                            name="address2"
                            placeholder={'Address Line 2'}
                            color="secondary"
                            value={address?.address2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => action(e)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            inputProps={{
                                maxLength: 32
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Select
                                    disabled={disabled}
                                    labelId="country"
                                    name="country"
                                    color="secondary"
                                    id="country"
                                    label="Country"
                                    onChange={(e: SelectChangeEvent<unknown>) => action(e)}
                                    onBlur={onBlur}
                                    options={COUNTRY_OPTIONS}
                                    optionLabelKeyname="name"
                                    optionValueKeyname="id"
                                    value={country}
                                    inputProps={{
                                        maxLength: 16
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    disabled={disabled}
                                    label={''}
                                    name="city"
                                    placeholder={'City'}
                                    color="secondary"
                                    value={address?.city}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => action(e)}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    inputProps={{
                                        maxLength: 32
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Select
                                    value={state}
                                    label="State"
                                    id="state"
                                    labelId="state"
                                    name="state"
                                    options={stateListOptions}
                                    optionLabelKeyname="name"
                                    optionValueKeyname="id"
                                    onChange={(e: SelectChangeEvent<unknown>) => action(e)}
                                    disabled={Boolean(!country) || disabled}
                                    onBlur={onBlur}
                                    inputProps={{
                                        maxLength: 32
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    disabled={disabled}
                                    label={''}
                                    placeholder={'Zip'}
                                    color="secondary"
                                    name="zip_code"
                                    value={address?.zip_code}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => action(e)}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    inputProps={{
                                        maxLength: 16
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BasicAddress;
