import { Box, Button } from '@parspec/pixel';

interface FooterProps {
    closePreview: () => void;
    updatePreviewIndex: (arg: 1 | -1) => void;
    onSelect: () => void;
    totalDocuments: number;
    previewIndex: number;
    isSelected?: boolean;
}

const Footer = (props: FooterProps) => {
    const { updatePreviewIndex, closePreview, onSelect, totalDocuments, previewIndex, isSelected } = props;
    return (
        <Box display="flex" justifyContent="flex-end" gap={3} pt={1}>
            <Button color="secondary" variant="outlined" onClick={closePreview}>
                Cancel
            </Button>
            {totalDocuments > 1 ? (
                <>
                    <Button color="secondary" variant="outlined" onClick={() => updatePreviewIndex(-1)} disabled={previewIndex < 1}>
                        Previous
                    </Button>
                    <Button color="secondary" variant="outlined" onClick={() => updatePreviewIndex(1)} disabled={previewIndex === totalDocuments - 1}>
                        Next
                    </Button>
                </>
            ) : null}

            <Button onClick={onSelect}>{isSelected ? 'Unselect' : 'Select'}</Button>
        </Box>
    );
};

export default Footer;
