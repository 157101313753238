import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodyMedium, BodySmall, BodyXS, TextField, Button, Link, OpenInNewIcon, Snackbar } from '@parspec/pixel';

import { initialSignInErrorState, initialSingInState, ISnackbarDataState } from '../utils/helper';
import { SignInType, ErrorStateType } from './SignIn.types';
import { validateEmail } from '../../Settings/UserManagement/utils/utils';
import { environment } from 'src/environments';
import { useSignInUserMutation, useHubspotTokenRequestMutation, useGetUserInfoMutation, useGetSSOIdentifierMutation } from '../queries';
import { setUser } from '../shared/ts-scripts/intercom/set_user';
import { setToken } from '../shared/ts-scripts/hubspot/set_token';

const INCORRECT_LOGIN_CREDS_MSG = 'Incorrect login credentials';

export const SignIn = () => {
    const { mutateAsync: signInUserMutation, isLoading: isSignInLoading } = useSignInUserMutation();
    const { mutateAsync: hubspotTokenRequestMutation, isLoading: isHubspotTokenRequestMutationLoading } = useHubspotTokenRequestMutation();
    const { mutateAsync: getUserProfileMutation, isLoading: isUserProfileLoading } = useGetUserInfoMutation();
    const { mutateAsync: getSSOIdentifierMutation, isLoading: isGetSSOIdentifierMutationLoading } = useGetSSOIdentifierMutation();

    const [signinData, setSigninData] = useState<SignInType>(initialSingInState);
    const [errorState, setErrorState] = useState<ErrorStateType>(initialSignInErrorState);
    const [snackbarData, setSnackbarData] = useState<ISnackbarDataState>({
        isActive: false,
        message: ''
    });

    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);

    const identifyUserForHubspotAndIntercom = async () => {
        const resp = await getUserProfileMutation();

        // getting parspec user email and name info
        const email = resp?.data.email as string;
        const user_id = resp?.data.id;
        let firstName = resp?.data.first_name;
        let lastName = resp?.data.last_name;

        if (!firstName) {
            const arr = resp?.data.name.trim().split(' ');
            firstName = arr ? arr[0].trim() : '';
        }
        if (!lastName) {
            const arr = resp?.data.name.trim().split(' ');
            if (arr && arr.length > 1) {
                lastName = arr[1];
            } else {
                lastName = '';
            }
        }

        // setting intercom
        setUser(email, user_id);

        const payload = { email, firstName, lastName };

        // get hubspot token
        const tokenRes = await hubspotTokenRequestMutation(payload);

        if (tokenRes?.data.token) {
            localStorage.setItem('hubspotToken', tokenRes.data.token);
            localStorage.setItem('hubspotEmail', email);
            setToken();
        }
    };

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name: fieldName, value: fieldValue } = event.target;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName)) {
            return;
        }

        if (errorState[fieldName].isError) {
            if (errorState.password.isError && errorState.password.title === INCORRECT_LOGIN_CREDS_MSG) {
                setErrorState({
                    ...errorState,
                    email: { ...errorState.email, isError: false, title: '' },
                    password: { ...errorState.password, isError: false, title: '' }
                });
            } else {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
                });
            }
        }
        if (fieldValue === '') {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
            });
        }

        setSigninData(() => ({ ...signinData, [fieldName]: fieldValue }));
    };

    const emailFieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const eventType = event.type;

        if (signinData.email.length > 0 && eventType === 'blur') {
            if (!validateEmail(signinData.email.trim())) {
                return setErrorState({
                    ...errorState,
                    email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
                });
            }
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const ssoOnClickHandler = async () => {
        const response = await getSSOIdentifierMutation();

        const CLIENT_ID = environment.i;
        const REDIRECT_URI = `${environment.R}/login/sso/authorize`;
        const STATE = response.data?.state;
        const NONCE = response.data?.nonce;

        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=openid+email+profile&state=${STATE}&nonce=${NONCE}`;
        window.open(url, '_self');
    };

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const emailFlag = signinData.email.length === 0;
        const password = signinData.password.length === 0;

        if (emailFlag || password) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: emailFlag, title: `${emailFlag ? 'Required' : ''}` },
                password: { ...errorState.password, isError: password, title: `${password ? 'Required' : ''}` }
            });
        }

        if (!validateEmail(signinData.email.trim())) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
            });
        }

        const payload = {
            email: signinData.email.trim(),
            password: signinData.password
        };

        try {
            await signInUserMutation(payload);
            identifyUserForHubspotAndIntercom();
            navigate('/v2/dashboard');
        } catch (err: any) {
            // need this code for cases where user can only login through SSO but for both SSO and invalid creds, we get 400 error, need to check for solution
            if (err?.response?.status === 400 && err?.response?.data?.message) {
                return setSnackbarData({
                    ...snackbarData,
                    isActive: true,
                    message: err?.response?.data?.message ? err?.response?.data?.message : 'Something went wrong, please try again'
                });
            } else {
                setErrorState({
                    ...errorState,
                    email: { ...errorState.email, isError: true, title: '' },
                    password: { ...errorState.password, isError: true, title: INCORRECT_LOGIN_CREDS_MSG }
                });
            }
        }
    };

    const loadFlag = isSignInLoading || isHubspotTokenRequestMutationLoading || isUserProfileLoading;
    return (
        <>
            <Box
                width="100vw"
                height="100vh"
                bgcolor="#091535"
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(assets/images/login/login-bg.png)`
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box display="flex" alignItems="center" mb={14}>
                        <Box height="45px" width="45px" mr={3}>
                            <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                        </Box>

                        <Box>
                            <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                        </Box>
                    </Box>
                    <Box bgcolor="secondary.contrastText" width="337px" padding={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={10}>
                            <BodyMedium limit={false}>Welcome Back</BodyMedium>
                            <BodySmall limit={false} mt={4}>
                                Sign into your account below
                            </BodySmall>
                        </Box>

                        <Box>
                            <form onSubmit={onSubmitHandler} autoComplete={'off'} ref={formRef}>
                                <Box height={'72px'}>
                                    <TextField
                                        required
                                        id={'email'}
                                        name={'email'}
                                        autoComplete={'email'}
                                        label={'Email'}
                                        value={signinData.email || null}
                                        error={errorState.email.isError}
                                        helperText={errorState.email.title}
                                        onKeyDown={keydownHandler}
                                        onBlur={emailFieldBlurHandler}
                                        onChange={fieldChangeHandler}
                                    />
                                </Box>
                                <Box height={'72px'}>
                                    <TextField
                                        required
                                        id={'password'}
                                        name={'password'}
                                        autoComplete={'password'}
                                        label={'Password'}
                                        type="password"
                                        value={signinData.password || null}
                                        error={errorState.password.isError}
                                        helperText={errorState.password.title}
                                        onKeyDown={keydownHandler}
                                        onChange={fieldChangeHandler}
                                    />
                                </Box>
                            </form>
                        </Box>

                        <BodySmall
                            limit={false}
                            width={'max-content'}
                            color="primary.main"
                            onClick={() => {
                                navigate('/forgotPassword');
                            }}
                            sx={{ cursor: 'pointer' }}
                        >
                            Forgot Password?
                        </BodySmall>

                        <Box mt={6} width="100%">
                            <Button onClick={onSubmitHandler} isLoading={loadFlag} fullWidth={true}>
                                Login
                            </Button>
                        </Box>

                        <Box mt={4} width="100%">
                            <Button onClick={ssoOnClickHandler} isLoading={isGetSSOIdentifierMutationLoading} fullWidth={true} variant="outlined" color="secondary">
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Box height={'26px'}>
                                        <img height={'100%'} width={'100%'} src={'assets/images/login/microsoft_logo.svg'} alt="microsoft login" />
                                    </Box>
                                    <BodyXS ml={3}>Login with Microsoft</BodyXS>
                                </Box>
                            </Button>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} mt={6}>
                            <BodyXS limit={false} mr={1} sx={{ fontSize: '10px' }}>
                                By using Parspec you agree to our
                            </BodyXS>
                            <Link href="https://knowledgebase.parspec.io/knowledge/terms-of-service" target="_blank" rel="noopener">
                                <Box color={'primary.main'} display={'flex'} alignItems={'flex-end'}>
                                    <BodyXS limit={false} color={'primary.main'} mr={1}>
                                        Terms of Service
                                    </BodyXS>
                                    <OpenInNewIcon fontSize={'small'} />
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={1000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </>
    );
};
