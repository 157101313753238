import { BodySmall, Box, Switch, Tooltip } from '@parspec/pixel';
import { ICellRendererParams } from 'ag-grid-community';
import { DISABLED_ACCESS_MSG } from '../utils/constants';

export const productFinderAccessDisabledTemplate = (props: ICellRendererParams) => {
    return (
        <Box display="flex">
            <Box margin="auto">
                <Tooltip title={DISABLED_ACCESS_MSG}>
                    <span>
                        <Switch color="primary" checked={props?.data?.user_details.product_finder_enabled} disabled />
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};

export const quotingAccessDisabledTemplate = (props: ICellRendererParams) => {
    return (
        <Box display="flex">
            <Box margin="auto">
                <Switch color="primary" checked={props.data.user_details.quoting_enabled} disabled />
            </Box>
        </Box>
    );
};
export const lastActiveTemplate = (props: ICellRendererParams) => {
    const newDateFormat = props?.data?.user_details?.last_activity?.split('T')[0];
    return (
        <Box>
            <BodySmall>{newDateFormat ? newDateFormat : ''}</BodySmall>
        </Box>
    );
};

export const StatusTemplate = (props: ICellRendererParams) => {
    return (
        <Box>
            <BodySmall textTransform={'capitalize'}>{props?.data.user_details.status}</BodySmall>
        </Box>
    );
};
