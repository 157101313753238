import { useParams } from 'react-router-dom';

import { Button, Box, Skeleton } from '@parspec/pixel';

import { Header } from '../../shared/Header';
import { useProductRequirementGetQuery } from '../queries';
import CancelButton from 'src/features/shared/CancelButton';
import { useParspecMfg, useCompanyMfgQuery } from 'src/features/Settings/Manufacturer/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { useGetBomDetails } from '../../../queries';
interface SearchProductHeaderPRops {
    onSearchProduct: () => void;
    isSearchRefreshInProgress: boolean;
}

export const SearchProductHeader = ({ onSearchProduct: handleSearchProducts, isSearchRefreshInProgress }: SearchProductHeaderPRops) => {
    const { bomId = 0, productId = 0 } = useParams();

    const { isLoading: parspecManufacturerLoading } = useParspecMfg();
    const { isLoading: companyManufacturerLoading } = useCompanyMfgQuery();
    const { isLoading: bomDetailsLoading, data: bomDetails } = useGetBomDetails(`${bomId || 0}`, { enabled: Boolean(bomId) });

    const { isLoading: projectDetailsLoading } = useGetProjectDetailsQuery(bomDetails?.data.project || 0, { enabled: Boolean(bomDetails?.data.project) });

    const { isLoading: isGetReqLoading, isFetching: isGetReqFetching } = useProductRequirementGetQuery(Number(bomId), Number(productId));
    const isLoading = isGetReqLoading || isGetReqFetching || parspecManufacturerLoading || companyManufacturerLoading || bomDetailsLoading || projectDetailsLoading;

    return (
        <Header title="Set Requirements">
            <Box display="flex">
                {isLoading && (
                    <>
                        <Box mr={2}>
                            <Skeleton height="33px" width="64px" variant="rectangular" />
                        </Box>
                        <Box>
                            <Skeleton height="33px" width="126.71px" variant="rectangular" />
                        </Box>
                    </>
                )}
                {!isLoading && (
                    <>
                        <Box mr={2}>
                            <CancelButton />
                        </Box>
                        <Box>
                            <Button onClick={handleSearchProducts} isLoading={isSearchRefreshInProgress}>
                                Search Products
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Header>
    );
};
