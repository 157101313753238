import { useState, useEffect } from 'react';

import { Box, BodySmall, Select, Skeleton } from '@parspec/pixel';

import { useCompanyInfoQuery, useDeleteBranchLocationMutation } from '../queries';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import { useGetUserProfileInfoQuery } from '../../MyProfile/queries';
interface DeleteLocationModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentLocationId: number;
    snackbarData: any;
    setSnackbarData: any;
}

interface BranchLocationsArrayType {
    [key: string]: any;
    label: string;
    value: number;
}

export const DeleteLocationModal = (props: DeleteLocationModalProps) => {
    const { isOpen, setIsOpen, currentLocationId, snackbarData, setSnackbarData } = props;

    const { data: companyInfo, isLoading } = useCompanyInfoQuery();
    const { mutateAsync: deleteBranchLocationMutation, isLoading: isDeleteLocationLoading } = useDeleteBranchLocationMutation();
    const { data: userProfile, isLoading: userProfileLoading } = useGetUserProfileInfoQuery();

    const [branchLocationOptions, setBranchLocationOptions] = useState<BranchLocationsArrayType[]>([]);

    const [destinationBranchLocation, setDestinationBranchLocation] = useState(0);

    useEffect(() => {
        if (!companyInfo?.data || !userProfile?.data) {
            return;
        }

        const locationsArr = companyInfo.data
            .filter((el) => el.id !== currentLocationId)
            .map((el) => {
                return {
                    label: el.name,
                    value: el.id
                };
            });

        setBranchLocationOptions(() => locationsArr);
        setDestinationBranchLocation(locationsArr[0].value);
    }, [companyInfo?.data, currentLocationId, userProfile?.data]);

    const branchLocationSelectionHandler = async (e: any) => {
        const value = e.target.value;

        setDestinationBranchLocation(value);
    };

    const confirmHandler = async () => {
        if (!userProfile?.data) {
            return;
        }

        try {
            await deleteBranchLocationMutation({ branchId: currentLocationId, dstCompanyID: destinationBranchLocation, userId: userProfile?.data.id });

            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Location Successfully Deleted'
            });
        } catch (err: any) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: err?.response?.data?.message ? err.response.data.message : 'Something went wrong, please try again'
            });
        }

        closeModalHandler();
    };
    const closeModalHandler = () => {
        setIsOpen(false);
    };

    const loadFlag = isDeleteLocationLoading || userProfileLoading;

    return (
        <DeleteConfirmationDialog title={'Delete Branch Location?'} open={isOpen} handleDialog={closeModalHandler} confirmAction={confirmHandler} continueButtonLabel={'Delete'} loading={loadFlag}>
            <Box width={'500px'} mt={4}>
                <Box>
                    <BodySmall limit={false} color={'text.secondary'}>
                        <strong>Are you sure you want to delete this branch location?</strong> To complete this action, please select a branch location to receive the Projects, BOMs, and Documents of
                        the deleted location. Users with access to the branch location being deleted will be given access to the recipient branch location.
                    </BodySmall>

                    <Box mt={6}>
                        {!isLoading && branchLocationOptions && branchLocationOptions.length > 0 ? (
                            <Select
                                label="Branch Location *"
                                onChange={branchLocationSelectionHandler}
                                optionLabelKeyname="label"
                                optionValueKeyname="value"
                                options={branchLocationOptions || []}
                                name="Permissions"
                                value={destinationBranchLocation}
                                id={'BranchLocation'}
                                labelId={'BranchLocation'}
                            />
                        ) : (
                            <Skeleton variant={'rectangular'} height={'36px'} width={'200px'} />
                        )}
                    </Box>
                </Box>
            </Box>
        </DeleteConfirmationDialog>
    );
};
