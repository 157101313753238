import { rest } from 'msw';
import { branchLocations } from './branchLocations';
import { manufacturers } from './manufactuers';
import { users } from './users';
import { customers } from './customers';

export const reportingHandlers = [
    rest.get('*/reporting/customers', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(customers));
    }),
    rest.get('*/reporting/locations', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(branchLocations));
    }),
    rest.get('*/reporting/users', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(users));
    }),

    rest.get('*/reporting/manufacturers', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(manufacturers));
    })
];
