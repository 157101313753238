import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodyMedium, Box } from '@parspec/pixel';

import { useQuoteContext } from '../../QuoteContext';
import CircularLoader from '../../../../../../shared/CircularLoader';
import { useUpdateStakeholderInfoMutation, useGetStakeholdersQuery } from '../../../../queries';
import { StakeholderInfoResponse } from '../../../../queries/apiTypes';
import StakeholderInfo from '../../../../shared/StakerHolderInfo';
import ApplyToAllBomsButton from '../../../shared/ApplyToAllBomsButton';
import { STAKE_HOLDER_SUB_SECTION } from '../../shared/constants';

interface StakeHolderInfoProps {
    sectionName?: string;
}

function StakeHolderInfo({ sectionName }: StakeHolderInfoProps) {
    const { documentType = '' } = useParams();
    const { handlePdfPreview, isTextFieldChanged, handleTextFieldChanged, isInitiateApiCalls, bomId = '0', handleUpdateChangedSections } = useQuoteContext();
    const [stakeHoldersInfo, setStakeholdersInfo] = useState<StakeholderInfoResponse[]>();

    const { data: StakeholdersApiData, isFetching } = useGetStakeholdersQuery(Number(bomId), documentType, { enabled: Boolean(Number(bomId)) && isInitiateApiCalls, staleTime: 0 });

    const { mutateAsync: updateStakeHolder } = useUpdateStakeholderInfoMutation();

    useEffect(() => {
        if (StakeholdersApiData?.data) {
            setStakeholdersInfo(StakeholdersApiData?.data);
        }
    }, [StakeholdersApiData?.data]);

    const pushChangedFields = (fieldName: string) => {
        if (sectionName) {
            handleUpdateChangedSections?.({ sectionName: sectionName, subSectionName: fieldName, value: true });
        }
    };

    const updateStakeHolderState = (stakeHolder: StakeholderInfoResponse) => {
        setStakeholdersInfo((old: any) => {
            const updated = old?.map((item: StakeholderInfoResponse) => (item.id === stakeHolder.id ? stakeHolder : item));
            return updated;
        });
    };

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, stakeHolder: StakeholderInfoResponse) {
        const { name, value } = e.target;
        updateStakeHolderState({ ...stakeHolder, [name]: value });
        handlePdfPreview(false);
        handleTextFieldChanged(true);
    }

    async function handleOnBlur(_e: any, stakeHolderInfo: StakeholderInfoResponse) {
        if (isTextFieldChanged) {
            await updateStakeHolder({
                bomId: Number(bomId),
                documentType: documentType || '',
                stakeHolderInfo
            });
            handlePdfPreview(true);
            pushChangedFields(STAKE_HOLDER_SUB_SECTION);
        }
    }

    function handleOnFocus() {
        handleTextFieldChanged(false);
    }

    async function handleSwitch(e: React.ChangeEvent<HTMLInputElement>, stakeHolder: StakeholderInfoResponse) {
        updateStakeHolderState({ ...stakeHolder, is_active: e.target.checked });

        await updateStakeHolder({
            bomId: Number(bomId),
            documentType: documentType || '',
            stakeHolderInfo: { ...stakeHolder, is_active: e.target.checked }
        });
        handlePdfPreview(true);
        pushChangedFields(STAKE_HOLDER_SUB_SECTION);
    }

    if (isFetching) {
        return <CircularLoader />;
    }

    return (
        <>
            {stakeHoldersInfo?.length ? (
                <Box display="flex" flexDirection="column" gap={3} mt={2}>
                    <Box display="flex" alignItems="center" gap={3}>
                        <BodyMedium color={'secondary.main'} fontWeight={500}>
                            Stakeholders Information
                        </BodyMedium>
                        <ApplyToAllBomsButton sectionName={sectionName} subSectionName={STAKE_HOLDER_SUB_SECTION} />
                    </Box>
                    {stakeHoldersInfo?.map((stakeHolder) => (
                        <StakeholderInfo
                            stakeHolderInfo={stakeHolder}
                            key={stakeHolder.id}
                            handleChange={handleOnChange}
                            handleOnFocus={handleOnFocus}
                            onToggleStakeHolder={handleSwitch}
                            blurAction={handleOnBlur}
                        />
                    ))}
                </Box>
            ) : null}
        </>
    );
}

export default StakeHolderInfo;
