const generateColumnLetter: any = (colIndex: any) => {
  if (typeof colIndex !== 'number') {
    return '';
  }

  const prefix = Math.floor(colIndex / 26);
  const letter = String.fromCharCode(97 + (colIndex % 26)).toUpperCase();
  if (prefix === 0) {
    return letter;
  }
  return generateColumnLetter(prefix - 1) + letter;
};

export default (index: any, rowNumber: any) =>
  `${generateColumnLetter(index)}${rowNumber}`;
