import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodyMedium, BodySmall, TextField, Button, Radio, BodyBig, Snackbar } from '@parspec/pixel';

import { useSignUpNewUserMutation } from '../../queries';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import { TeamIsUsingParspec } from './TeamIsUsingParspec';
import { EmailAlreadyInUse } from './EmailAlreadyInUse';
import { VerificationEmailSent } from './VerificationEmailSent';
import { ISnackbarDataState } from '../SignUp.types';

export interface TextFieldError {
    isError: boolean;
    title: string;
}

export interface ErrorStateType {
    [key: string]: TextFieldError;
    email: TextFieldError;
}

interface SignupStateType {
    email: string;
    isTeamAlreadyUsingParspec: 'true' | 'false';
}

export const SignUp = () => {
    const navigate = useNavigate();
    const { mutateAsync: signUpUserMutation, isLoading: isSignUpLoading } = useSignUpNewUserMutation();

    const [isTeamAlreadyUsingParspec, setIsTeamAlreayUsingParspec] = useState(false);
    const [isEmailAlreadyInUse, setIsEmailAlreadyInUse] = useState(false);
    const [isVerifcationEmailSent, setIsVerifcationEmailSent] = useState(false);
    const [snackbarData, setSnackbarData] = useState<ISnackbarDataState>({
        isActive: false,
        message: ''
    });

    const [signupData, setSignupData] = useState<SignupStateType>({ email: '', isTeamAlreadyUsingParspec: 'false' });
    const [errorState, setErrorState] = useState<ErrorStateType>({
        email: {
            isError: false,
            title: ''
        }
    });

    const formRef = useRef<HTMLFormElement>(null);

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name: fieldName, value: fieldValue } = event.target;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName)) {
            return;
        }

        if (errorState[fieldName].isError) {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
            });
        }
        if (fieldValue === '') {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
            });
        }

        setSignupData(() => ({ ...signupData, [fieldName]: fieldValue }));
    };

    const emailFieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const eventType = event.type;

        if (signupData.email.length > 0 && eventType === 'blur') {
            if (!validateEmail(signupData.email)) {
                return setErrorState({
                    ...errorState,
                    email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
                });
            }
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const onSubmitHandler = async () => {
        const emailFlag = signupData.email.length === 0;

        if (emailFlag) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: emailFlag, title: `${emailFlag ? 'Required' : ''}` }
            });
        }

        if (!validateEmail(signupData.email)) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
            });
        }

        const payload = {
            email: signupData.email,
            is_team_using_parspec: signupData.isTeamAlreadyUsingParspec
        };

        try {
            await signUpUserMutation(payload);

            if (payload.is_team_using_parspec === 'true') {
                setIsTeamAlreayUsingParspec(true);
            } else {
                setIsVerifcationEmailSent(true);
            }
        } catch (error: any) {
            if (error?.response) {
                const statusCode = error.response.status;

                if (statusCode === 400) {
                    setIsEmailAlreadyInUse(true);
                }
            }
        }
    };

    return (
        <>
            {!isTeamAlreadyUsingParspec ? (
                <>
                    {!isEmailAlreadyInUse ? (
                        <>
                            {!isVerifcationEmailSent ? (
                                <Box bgcolor="secondary.contrastText" width="350px" padding={6}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={8}>
                                        <BodyBig limit={false} fontWeight={500}>
                                            Welcome To Parspec!
                                        </BodyBig>
                                        <BodySmall limit={false} mt={4} textAlign="center">
                                            Let's get you started in a few simple steps.
                                        </BodySmall>
                                    </Box>

                                    <Box>
                                        <form onSubmit={onSubmitHandler} ref={formRef} autoComplete={'off'}>
                                            <Box height={'72px'}>
                                                <TextField
                                                    required
                                                    id={'email'}
                                                    name={'email'}
                                                    autoComplete={'email'}
                                                    label={'Email'}
                                                    value={signupData.email}
                                                    error={errorState.email.isError}
                                                    helperText={errorState.email.title}
                                                    onKeyDown={keydownHandler}
                                                    onBlur={emailFieldBlurHandler}
                                                    onChange={fieldChangeHandler}
                                                />
                                            </Box>
                                        </form>
                                    </Box>

                                    <Box>
                                        <BodyMedium>Is your team already using Parspec?</BodyMedium>
                                    </Box>

                                    <Box mt={2}>
                                        <Box display="flex" alignItems="center">
                                            <Radio
                                                checked={signupData.isTeamAlreadyUsingParspec === 'true'}
                                                size={'small'}
                                                value={'true'}
                                                name="teamAlreadyUsingParspec"
                                                inputProps={{ 'aria-label': 'teamAlreadyUsingParspec' }}
                                                onChange={() => {
                                                    setSignupData(() => ({ ...signupData, isTeamAlreadyUsingParspec: 'true' }));
                                                }}
                                            />
                                            <Box>
                                                <BodySmall limit={false}>Yes </BodySmall>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Radio
                                                checked={signupData.isTeamAlreadyUsingParspec === 'false'}
                                                size={'small'}
                                                value={'false'}
                                                name="teamNotUsingParspec"
                                                inputProps={{ 'aria-label': 'teamNotUsingParspec' }}
                                                onChange={() => {
                                                    setSignupData(() => ({ ...signupData, isTeamAlreadyUsingParspec: 'false' }));
                                                }}
                                            />
                                            <Box>
                                                <BodySmall limit={false}>No</BodySmall>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box width="100%" my={4}>
                                        <Button onClick={onSubmitHandler} isLoading={isSignUpLoading} fullWidth={true}>
                                            Sign Up
                                        </Button>
                                    </Box>

                                    <Box display="flex" width="100%" alignItems="center" justifyContent="center">
                                        <BodySmall limit={false}>Already have an account? </BodySmall>
                                        <BodySmall
                                            limit={false}
                                            color={'primary.main'}
                                            mr={1}
                                            onClick={() => navigate('/login')}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: 'primary.main',
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            Log in
                                        </BodySmall>
                                    </Box>
                                </Box>
                            ) : (
                                <VerificationEmailSent />
                            )}
                        </>
                    ) : (
                        <EmailAlreadyInUse />
                    )}
                </>
            ) : (
                <TeamIsUsingParspec snackbarData={snackbarData} setSnackbarData={setSnackbarData} />
            )}

            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={1000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </>
    );
};
