import { BodySmall, Box, Modal, ModalFooter, ModalHeader } from '@parspec/pixel';

import { MODAL_MESSAGE } from './constants';

interface OptionWarningModalProps {
    source: 'delete' | 'move';
    onConfirm: () => void;
    onCancel: () => void;
    open: boolean;
    isLoading: boolean;
}

export default function OptionWarningModal({ source, onConfirm, onCancel, open, isLoading }: OptionWarningModalProps) {
    const isOpenedForDelete = source === 'delete';
    return (
        <Modal
            open={open}
            header={<ModalHeader title={source === 'delete' ? 'Delete Product?' : 'Change Section'} onClose={onCancel} />}
            footer={
                <ModalFooter
                    continueButtonLabel={isOpenedForDelete ? 'Delete' : 'Move'}
                    cancelButtonLabel="Cancel"
                    onAccept={onConfirm}
                    onReject={onCancel}
                    continueButtonColor={isOpenedForDelete ? 'error' : 'tertiary'}
                    isLoading={isLoading}
                />
            }
        >
            <Box>
                <BodySmall mb={5} limit={false} width="402px">
                    {isOpenedForDelete ? MODAL_MESSAGE.DELETE_PARA_1 : MODAL_MESSAGE.MOVE_PARA_1}
                </BodySmall>
                <BodySmall>{isOpenedForDelete ? MODAL_MESSAGE.DELETE_PARA_2 : MODAL_MESSAGE.MOVE_PARA_2}</BodySmall>
            </Box>
        </Modal>
    );
}
