import { BodySmall, Box, Button, Tooltip } from '@parspec/pixel';
interface ModalFooterProps {
    showPricingExistsWarning?: boolean;
    onReject: () => void;
    onReuseCost: () => void;
    onReuseCostDiscount: () => void;
    onReuseAll: () => void;
    isLoading: boolean;
    isRowSelected: boolean;
    isLotPresent: boolean;
}

const LOT_DISABLE_MSG = 'This product is part of a lot, and cannot be assigned pricing information. Detach from lot before adding pricing.';

export function ModalFooter({ showPricingExistsWarning, onReject, isLoading, onReuseCost, onReuseCostDiscount, onReuseAll, isRowSelected, isLotPresent }: ModalFooterProps) {
    return (
        <Box display="flex" justifyContent={showPricingExistsWarning ? 'space-between' : 'flex-end'} alignItems={'center'}>
            {showPricingExistsWarning && (
                <Box width="60%">
                    <BodySmall color="warning.main" lines={2}>
                        <Box component="span" fontWeight="700">
                            The product selected already has some pricing information populated.{' '}
                        </Box>
                        Existing information will be overwritten if you choose to reuse historical data instead.
                    </BodySmall>
                </Box>
            )}
            <Box display={'flex'} gap={2}>
                <Button color="primary" variant="text" onClick={onReject} disabled={isLoading}>
                    Cancel
                </Button>
                <Tooltip title={LOT_DISABLE_MSG} disableHoverListener={!isLotPresent}>
                    <Box>
                        <Button color="secondary" variant="outlined" onClick={onReuseCost} isLoading={isLoading} disabled={!isRowSelected || isLotPresent}>
                            Reuse Cost
                        </Button>
                    </Box>
                </Tooltip>
                <Tooltip title={LOT_DISABLE_MSG} disableHoverListener={!isLotPresent}>
                    <Box>
                        <Button color="secondary" variant="outlined" onClick={onReuseCostDiscount} isLoading={isLoading} disabled={!isRowSelected || isLotPresent}>
                            Reuse Cost & Discount
                        </Button>
                    </Box>
                </Tooltip>
                <Tooltip title={LOT_DISABLE_MSG} disableHoverListener={!isLotPresent}>
                    <Box>
                        <Button color="tertiary" variant="contained" onClick={onReuseAll} isLoading={isLoading} disabled={!isRowSelected || isLotPresent}>
                            Reuse All Pricing
                        </Button>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    );
}
