import { axios } from 'src/app/Axios';
import { SuccessResponse } from '../../queries/apiTypes';

import { UploadedSubmittalPackageResponse, UploadedScheduleResponse, CopyDataFromExistingBomRequest } from './apiTypes';

export const endPoints = {
    uploadDifferentFile: (bom_id: number, packageType: string) => `/bom/${bom_id}/uploaded_doc/${packageType}/`,
    copyDataFromExistingBom: (bomId: number) => `/bom/${bomId}/append/`
};
export const uploadDifferentFile = ({ packageType, bom_id, data }: { packageType: string; bom_id: number; data: FormData }) => {
    return axios.post<UploadedScheduleResponse | UploadedSubmittalPackageResponse>(endPoints.uploadDifferentFile(bom_id, packageType), data);
};

export const copyDataFromExistingBom = (request: CopyDataFromExistingBomRequest) => {
    const { bomId, ...rest } = request;
    return axios.post<SuccessResponse>(endPoints.copyDataFromExistingBom(bomId), { ...rest });
};
