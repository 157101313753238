import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Menu, MoreVertIcon, Snackbar } from '@parspec/pixel';

import { SECTION_CREATE_MSG, OPTION_CREATE_MSG } from '../../shared/constants';
import { CREATE_SECTION_OPTIONS } from './constants';
import { useCreateSectionMutation, useCreateOptionSectionMutation, useGetBomSectionsQuery, useOptionSectionQuery } from '../queries';
import { useUserEventCount } from '../../shared/UserActivityTracking/TrackActivityCount';

const CreateSectionButton = () => {
    const { bomId } = useParams();
    const { pushUserEventCount } = useUserEventCount();
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const { data: sectionData } = useGetBomSectionsQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { data: optionSectionData } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { mutateAsync: createSection, isLoading: createLoading } = useCreateSectionMutation();
    const { mutateAsync: createOption, isLoading: createOptionLoading } = useCreateOptionSectionMutation();

    const previousSectionId = sectionData?.data && sectionData.data.length ? sectionData.data[sectionData.data.length - 1].id : null;
    const previousOptionsId = optionSectionData?.data && optionSectionData.data.length ? optionSectionData.data[optionSectionData.data.length - 1].id : null;

    const onCreateSection = async (type: string) => {
        switch (type) {
            case 'products':
                await createSection({
                    bomId: Number(bomId),
                    input: {
                        name: 'New Section',
                        previous_section_id: previousSectionId
                    }
                });
                pushUserEventCount({ eventType: 'section_created', needCummulation: true });
                setSnackbarMessage(SECTION_CREATE_MSG);
                break;

            case 'options':
                await createOption({
                    bomId: Number(bomId),
                    input: {
                        name: 'New Option',
                        previous_substitute_section_id: previousOptionsId
                    }
                });
                pushUserEventCount({ eventType: 'section_created', needCummulation: true });
                setSnackbarMessage(OPTION_CREATE_MSG);
                break;
        }
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Menu options={CREATE_SECTION_OPTIONS(onCreateSection)}>
                <Button variant="outlined" color="secondary" endIcon={<MoreVertIcon />} isLoading={createLoading || createOptionLoading}>
                    Create Section
                </Button>
            </Menu>
        </>
    );
};

export default CreateSectionButton;
