import { BodyXS, Tooltip } from "@parspec/pixel";

import Chip from '../Chip'

const LiveTag = () =>{
    const DOCUMENT_STATUS ="LIVE";
    return (<Tooltip placement="top" title={ <>This document is currently live <br/> on the manufacturer's website.</>}>
                                <span>
                                    <Chip bgColor="success.main">
                                        <BodyXS color="secondary.contrastText" fontWeight={500}>
                                            {DOCUMENT_STATUS}
                                        </BodyXS>
                                    </Chip>
                                </span>
                            </Tooltip>)
}


export default LiveTag;