import { useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodySmall, BodyXS, Box, H6, Modal, ModalFooter, ModalHeader, RadioGroup, TextField } from '@parspec/pixel';

import { useGetFrieghtDetailsQuery, usePutFreightMutation } from './queries';
import { getAmountForUi } from '../../shared/utils';
import { convertToCents, convertToDollar } from '../../../shared/utils/utils';

import { FREIGHT_METHODS, PutFreightDetailsRequest } from './queries/apiTypes';
import { CALC_METHODS } from '../shared/constants';
import { getLumpSumRadioOptions } from './utils';
import { useUserEventCount } from '../../../shared/UserActivityTracking/TrackActivityCount';

interface IFreightModalProps {
    open: boolean;
    onClose: () => void;
    totalExtendedPrice: number;
}

const FreightModal = ({ open, onClose, totalExtendedPrice }: IFreightModalProps) => {
    const { bomId = '0' } = useParams();
    const { pushUserEventCountAtBOM } = useUserEventCount();
    const [lumpSumCalcMethod, setLumpSumCalcMethod] = useState<FREIGHT_METHODS>(CALC_METHODS.FLAT_FEE);
    const [lumpSumCharges, setLumSumCharges] = useState({
        flatFee: '',
        percent: ''
    });

    const totalFreightCharge = useMemo(() => {
        const percentage = lumpSumCharges.percent === '.' ? 0 : lumpSumCharges.percent;
        return lumpSumCalcMethod === CALC_METHODS.FLAT_FEE ? lumpSumCharges.flatFee : getAmountForUi((Number(percentage) * totalExtendedPrice) / 100);
    }, [lumpSumCalcMethod, lumpSumCharges.flatFee, lumpSumCharges.percent]);

    const { data: freightDetails, isLoading } = useGetFrieghtDetailsQuery(bomId);
    const { mutateAsync: putFreightDetails, isLoading: isPutLoading } = usePutFreightMutation();

    useLayoutEffect(() => {
        if (!isLoading && freightDetails?.data) {
            const { flat_fee_cents, percentage_of_order, calculation_method } = freightDetails.data || {};

            setLumpSumCalcMethod(calculation_method);
            setLumSumCharges({
                flatFee: flat_fee_cents !== null ? String(convertToDollar(flat_fee_cents)) : '',
                percent: percentage_of_order !== null ? String(percentage_of_order) : ''
            });
        }
    }, [freightDetails, isLoading]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const separateDecimals = value.split('.')[1];
        const divisor = name === 'flatFee' ? 100 : 1;
        const isSafeValue = Number(value) >= Number.MIN_SAFE_INTEGER / divisor && Number(value) <= Number.MAX_SAFE_INTEGER / divisor;
        if ((separateDecimals && separateDecimals.length > 2) || !isSafeValue) {
            return;
        }
        setLumSumCharges({
            ...lumpSumCharges,
            [name]: value
        });
    };

    const handleModalSave = async () => {
        const reqObj: PutFreightDetailsRequest = {
            bomId: bomId || '',
            payload: {
                calculation_method: lumpSumCalcMethod,
                flat_fee_cents: lumpSumCalcMethod === CALC_METHODS.FLAT_FEE && lumpSumCharges.flatFee ? convertToCents(Number(lumpSumCharges.flatFee)) : null,
                percentage_of_order: lumpSumCalcMethod === CALC_METHODS.PERCENTAGE_OF_ORDER && lumpSumCharges.percent ? Number(lumpSumCharges.percent) : null
            }
        };

        await putFreightDetails(reqObj);
        pushUserEventCountAtBOM({ eventType: 'freight_flat_fee', count: Number(lumpSumCalcMethod === CALC_METHODS.FLAT_FEE) });
        pushUserEventCountAtBOM({ eventType: 'freight_%_of_order', count: Number(lumpSumCalcMethod === CALC_METHODS.PERCENTAGE_OF_ORDER) });
        pushUserEventCountAtBOM({ eventType: 'freight_excluded', count: Number((lumpSumCalcMethod as string) === 'freight_excluded') });
        onClose();
    };

    const renderPercentUI = (): React.ReactNode =>
        lumpSumCalcMethod === CALC_METHODS.PERCENTAGE_OF_ORDER ? (
            <Box pt={2}>
                <Box display="flex" justifyContent="space-between">
                    <BodySmall>*Current Order</BodySmall>
                    <BodySmall>{`$${getAmountForUi(totalExtendedPrice)}`}</BodySmall>
                </Box>
                <Box display="flex" justifyContent="space-between" pt={4}>
                    <BodySmall>Percentage</BodySmall>
                    <Box width="84px">
                        <TextField
                            sx={{ input: { textAlign: 'right' } }}
                            size="small"
                            placeholder="0.00"
                            endIcon="%"
                            label=""
                            name="percent"
                            value={lumpSumCharges.percent}
                            onChange={handleInputChange}
                            onKeyDown={(event) => {
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type="number"
                        />
                    </Box>
                </Box>
                <Box pt={4} textAlign="right">
                    <H6>Total: ${totalFreightCharge}</H6>
                </Box>
                {lumpSumCalcMethod && CALC_METHODS.PERCENTAGE_OF_ORDER && (
                    <Box pt={6}>
                        <BodyXS color="neutral.dark">*Current order based off Extended sell price</BodyXS>
                    </Box>
                )}
            </Box>
        ) : null;

    const flatFeeInput = (): React.ReactNode =>
        lumpSumCalcMethod === CALC_METHODS.FLAT_FEE ? (
            <Box width="120px">
                <TextField
                    startIcon="$"
                    placeholder="0.00"
                    type="number"
                    name="flatFee"
                    label=""
                    value={lumpSumCharges.flatFee}
                    onChange={handleInputChange}
                    onKeyDown={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault();
                        }
                    }}
                />
            </Box>
        ) : null;

    const lumpSumRadioOptions = getLumpSumRadioOptions(flatFeeInput);

    const renderLumSump = () => {
        return (
            <RadioGroup
                name="lumpSumCalcMethod"
                options={lumpSumRadioOptions}
                label="Determine how freight should be calculated for this Quote."
                size="small"
                value={lumpSumCalcMethod}
                onChange={(event) => setLumpSumCalcMethod(event.target.value as FREIGHT_METHODS)}
            />
        );
    };

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Freight" onClose={onClose} />}
            footer={<ModalFooter onAccept={handleModalSave} onReject={onClose} continueButtonLabel="Save" isLoading={isPutLoading} />}
        >
            <Box width={480}>
                <Box pt={6} width="100%">
                    {renderLumSump()}
                </Box>
                {renderPercentUI()}
            </Box>
        </Modal>
    );
};

export default FreightModal;
