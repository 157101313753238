import { ToolbarProps } from 'react-big-calendar';

import { BodyMedium, Box, KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from '@parspec/pixel';

import { EventDataType } from '../shared/types';

const CalendarToolbar = ({ onNavigate, label }: ToolbarProps<EventDataType>) => {
    return (
        <Box display="flex" gap={4} my={4} alignItems="center" justifyContent="center">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => onNavigate('PREV')}
                border="1px solid"
                borderColor="neutral.main"
                borderRadius="4px"
                sx={{ cursor: 'pointer' }}
            >
                <KeyboardArrowLeftIcon />
            </Box>
            <BodyMedium>{`Week of ${label}`}</BodyMedium>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => onNavigate('NEXT')}
                border="1px solid"
                borderColor="neutral.main"
                borderRadius="4px"
                sx={{ cursor: 'pointer' }}
            >
                <KeyboardArrowRightIcon />
            </Box>
        </Box>
    );
};

export default CalendarToolbar;
