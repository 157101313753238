import { useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BodyMedium, Grid, Box, FileSelector, Skeleton, FileSelectorFileType } from '@parspec/pixel';

import PreviewContext from '../../Context';
import FileUploadSection from '../../shared/FileUploadSection';
import GeneralInformation from './GeneralInformation';
import StakeHolder from './StakeHolders';
import NotesSection from '../../shared/NotesSection';
import LeftImageSection from './LeftImageSection';
import RightImageSection from './RightImageSection';
import CoverPageSection from './CoverPageSection';

/** Queries */
import { useCoverPageTextfieldData, useCoverPageToggleConfigurationQuery, useDocumentToggleConfigurationMutation, useDocumentTextFieldMutation, useCoverPageDocumentLogoQuery } from '../../queries';

import { ENTITY } from '../../../constants';
import { PRESET_RESPONSE } from '../../../constants';
import { createLogoImagePreview } from '../../../shared/utils';
import { FIELD_NAME } from './constants';

interface CoverPagePropType {
    onUpload: (data: File[] | FileSelectorFileType[]) => void;
    isCustomUploadLoading: boolean;
}

const AccordionBody = ({ onUpload, isCustomUploadLoading }: CoverPagePropType) => {
    const { bomId = '', documentType = '' } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);

    const { data: mainLogo, isLoading: isMainLogoLoading } = useCoverPageDocumentLogoQuery(bomId!, FIELD_NAME.coverPage, documentType!, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });
    const { data: preparedByLogo, isLoading: isPreparedByLoading } = useCoverPageDocumentLogoQuery(bomId!, FIELD_NAME.preparedBy, documentType!, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });
    const { data: addrressToLogo, isLoading: isAddressToLoading } = useCoverPageDocumentLogoQuery(bomId!, FIELD_NAME.addressedTo, documentType!, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

    const { data: toggleConfigData } = useCoverPageToggleConfigurationQuery(bomId!, documentType!, presetResponse);
    const { data: textFieldData, isLoading: isCoverPageTextFieldDataLoading } = useCoverPageTextfieldData(bomId, documentType, presetResponse);
    const { mutate: toggleFieldMutation } = useDocumentToggleConfigurationMutation();
    const { mutate: updateCoverPageTemplateMutation } = useDocumentTextFieldMutation();

    const [isLeftTemplate, setIsLeftTemplate] = useState(true);
    const [isRightTemplate, setIsRightTemplate] = useState(false);
    const [isShowLogoPreview, setIsShowLogoPreview] = useState(true);
    const [isAddressLogoPreview, setIsAddressLogoPreview] = useState(true);
    const [isPreparedByLogoPreview, setIsPreparedByLogoPreview] = useState(true);
    const [isPreparedBy, setIsPreparedBy] = useState<boolean>();
    const [isAddressedTo, setIsAddressedTo] = useState<boolean>();

    const [mainLogoUrl, setMainLogoUrl] = useState<string>();
    const [addressLogoUrl, setAddressLogoUrl] = useState<string>();
    const [preparedByLogoUrl, setPreparedByLogoUrl] = useState<string>();

    const updateAddressToLogo = useCallback((url: string) => {
        if (!url.length) {
            setIsAddressLogoPreview(false);
        } else {
            setIsAddressLogoPreview(true);
            setAddressLogoUrl(url);
        }
    }, []);

    useEffect(() => {
        if (mainLogo?.data?.logo?.filepath) {
            setIsShowLogoPreview(true);
            setMainLogoUrl(mainLogo.data.logo.filepath);
        } else {
            setIsShowLogoPreview(false);
        }
    }, [mainLogo?.data?.logo?.filepath, isMainLogoLoading]);

    useEffect(() => {
        if (preparedByLogo?.data?.logo?.filepath) {
            setIsPreparedByLogoPreview(true);
            setPreparedByLogoUrl(preparedByLogo.data.logo.filepath);
        } else {
            setIsPreparedByLogoPreview(false);
        }
    }, [preparedByLogo?.data?.logo?.filepath, isPreparedByLoading]);

    useEffect(() => {
        if (addrressToLogo?.data?.logo?.filepath) {
            setIsAddressLogoPreview(true);
            setAddressLogoUrl(addrressToLogo.data.logo.filepath);
        } else {
            setIsAddressLogoPreview(false);
        }
    }, [addrressToLogo?.data?.logo?.filepath, isAddressToLoading]);

    useEffect(() => {
        switch (textFieldData?.cover_page_template_id) {
            case '1':
                setIsLeftTemplate(true);
                setIsRightTemplate(false);
                break;
            case '2':
                setIsRightTemplate(true);
                setIsLeftTemplate(false);
                break;

            default:
                break;
        }
    }, [textFieldData?.cover_page_template_id]);

    useEffect(() => {
        setIsPreparedBy(toggleConfigData?.prepared_by);
        setIsAddressedTo(toggleConfigData?.addressed_to);
    }, [toggleConfigData]);

    const handlePreparedBy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPreparedBy(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: FIELD_NAME.preparedBy,
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleAddressedTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAddressedTo(event.target.checked);
        toggleFieldMutation(
            {
                is_active: event.target.checked,
                bomId: bomId!,
                fieldName: FIELD_NAME.addressedTo,
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleGetLogoImagePreview = (data: File[], fieldName: string) => {
        if (Array.isArray(data) && data.length) {
            switch (fieldName) {
                case FIELD_NAME.coverPage:
                    createLogoImagePreview(data, '#preview-left-image-1', '#preview-right-image-1');
                    break;
                case FIELD_NAME.preparedBy:
                    createLogoImagePreview(data, '#preview-left-image-2', '#preview-right-image-2');
                    break;
                case FIELD_NAME.addressedTo:
                    createLogoImagePreview(data, '#preview-left-image-3', '#preview-right-image-3');
                    break;

                default:
                    break;
            }
        }
    };

    const handleTemplateSelection = (_e: React.MouseEvent<HTMLElement>, template: string) => {
        switch (template) {
            case '1':
                setIsLeftTemplate(true);
                setIsRightTemplate(false);
                break;
            case '2':
                setIsLeftTemplate(false);
                setIsRightTemplate(true);
                break;
            default:
                break;
        }

        updateCoverPageTemplateMutation(
            {
                bomId: bomId!,
                documentType: documentType!,
                fieldName: 'cover_page_template_id',
                field_value: template,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    return (
        <Grid container direction={'column'}>
            {/* cover page layout */}
            <Grid item>
                <Grid container direction="column" gap={2}>
                    <Grid item>
                        <BodyMedium color={'secondary.main'}>Cover Page Layout</BodyMedium>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={'center'} gap={3}>
                            <Grid item onClick={(e: React.MouseEvent<HTMLElement>) => handleTemplateSelection(e, '1')} sx={{ cursor: 'pointer' }}>
                                {isCoverPageTextFieldDataLoading || isMainLogoLoading || isAddressToLoading || isPreparedByLoading ? (
                                    <Skeleton sx={{ bgcolor: 'rgba(0,0,0,0.05)' }} animation="wave" height={192} variant="rounded" width={178} />
                                ) : (
                                    <LeftImageSection
                                        mainLogoUrl={mainLogoUrl}
                                        preparedByLogoUrl={preparedByLogoUrl}
                                        addressLogoUrl={addressLogoUrl}
                                        isAddressLogoPreview={isAddressLogoPreview}
                                        isPreparedByLogoPreview={isPreparedByLogoPreview}
                                        isShowLogoPreview={isShowLogoPreview}
                                        is_active={isLeftTemplate}
                                    />
                                )}
                            </Grid>
                            <Grid item onClick={(e: React.MouseEvent<HTMLElement>) => handleTemplateSelection(e, '2')} sx={{ cursor: 'pointer' }}>
                                {isCoverPageTextFieldDataLoading || isMainLogoLoading || isAddressToLoading || isPreparedByLoading ? (
                                    <Skeleton sx={{ bgcolor: 'rgba(0,0,0,0.05)' }} animation="wave" height={192} variant="rounded" width={178} />
                                ) : (
                                    <RightImageSection
                                        mainLogoUrl={mainLogoUrl}
                                        preparedByLogoUrl={preparedByLogoUrl}
                                        addressLogoUrl={addressLogoUrl}
                                        isAddressLogoPreview={isAddressLogoPreview}
                                        isPreparedByLogoPreview={isPreparedByLogoPreview}
                                        isShowLogoPreview={isShowLogoPreview}
                                        is_active={isRightTemplate}
                                    />
                                )}
                            </Grid>

                            <Grid item>
                                <Box width={178} height={192}>
                                    <FileSelector placeholder="Upload Your Own Cover Page" acceptedFormats={['.pdf']} maxFiles={2} onSelect={onUpload} isLoading={isCustomUploadLoading} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* file uploader section */}
            <Grid item mt={6}>
                <FileUploadSection imagePreviewAction={setIsShowLogoPreview} fieldName={FIELD_NAME.coverPage} getLogoImagePreview={handleGetLogoImagePreview} />
            </Grid>

            {/* General Information */}
            <Grid item>
                <GeneralInformation toggleConfigData={toggleConfigData} generalInfo={textFieldData} />
            </Grid>

            {/* Stakeholders */}

            <Grid item>
                <StakeHolder />
            </Grid>

            {/* Notes */}
            <Grid item mt={2} mb={2}>
                <NotesSection notes={textFieldData?.notes} />
            </Grid>

            {/* Prepared by information */}
            <Grid item>
                <CoverPageSection
                    parentSwitchLabel="'Prepared By' Information"
                    value={isPreparedBy}
                    onToggle={handlePreparedBy}
                    fieldName={FIELD_NAME.preparedBy}
                    preparedByLogoPreview={setIsPreparedByLogoPreview}
                    getLogoImagePreview={handleGetLogoImagePreview}
                />
            </Grid>

            {/* Addressed to information */}
            <Grid item>
                <CoverPageSection
                    showAddressToPrefill
                    updateAddressToLogo={updateAddressToLogo}
                    parentSwitchLabel="'Addressed To' Information"
                    value={isAddressedTo}
                    onToggle={handleAddressedTo}
                    fieldName={FIELD_NAME.addressedTo}
                    addressLogoPreview={setIsAddressLogoPreview}
                    getLogoImagePreview={handleGetLogoImagePreview}
                />
            </Grid>
        </Grid>
    );
};

export default AccordionBody;
