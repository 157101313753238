import { UseQueryOptions, useMutation, useQuery, QueryKey } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { getLots, lotsEndpoint, putLotsDetails } from './api';
import { queryClient } from 'src/app/queryClient';
import { LotsResponse } from './apiTypes';
import { handleLotDelete } from '../../queries';

const getLotDetailsKey = (bomId: string) => [lotsEndpoint(bomId)];

export function invalidaLotQueries(bomId: string) {
    queryClient.invalidateQueries(getLotDetailsKey(bomId));
}

export const useGetLotDetailsQuery = (bomId: string, queryOptions?: UseQueryOptions<AxiosResponse<LotsResponse>>) =>
    useQuery<AxiosResponse<LotsResponse>, unknown, AxiosResponse<LotsResponse>, QueryKey>(getLotDetailsKey(bomId), () => getLots(bomId), queryOptions);

export function updateLotQueryData(bomId: string, lotIdToAssign: number | null, lotIdToRemove: number | null) {
    const lotsData = queryClient.getQueryData<AxiosResponse<LotsResponse, any>>(getLotDetailsKey(bomId));
    const updatedLotsData = lotsData?.data.map((lotData) => {
        const updatedLotData = { ...lotData };
        if (lotIdToAssign === lotData.id) {
            updatedLotData.n_products += 1;
        } else if (lotIdToRemove === lotData.id) {
            updatedLotData.n_products -= 1;
        }

        return updatedLotData;
    });
    const updatedQueryData = { ...lotsData, data: updatedLotsData };
    queryClient.setQueryData(getLotDetailsKey(bomId), updatedQueryData);
}

export const usePutLotsMutation = () => {
    return useMutation(putLotsDetails, {
        onSuccess: (data, variables) => {
            const { bomId } = variables;
            queryClient.setQueryData(getLotDetailsKey(bomId), data);
            handleLotDelete(Number(bomId), new Set(variables.payload.delete));
        }
    });
};
