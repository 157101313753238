import { useLocation, useParams } from 'react-router-dom';
import { pushEvent } from './EventService';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';

interface IUseEventCountInteger {
    eventType: string;
    needCummulation: boolean;
    incrementBy?: number;
}
interface IUseEventCountBoolean {
    eventType: string;
    count: number;
}
export const useUserEventCount = () => {
    const { bomId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tab = params?.get('tab');
    const { data: userProfile } = useGetUserProfileInfoQuery();
    const userId = userProfile?.data.id;

    function pushUserEventCount({ eventType, needCummulation, incrementBy = 1 }: IUseEventCountInteger) {
        let eventName = eventType;
        if (needCummulation) {
            eventName = eventType + '_bom_tab';
            switch (tab) {
                case 'pricingAndLeadtime':
                    eventName = eventType + '_p&lt_tab';
                    break;
                case 'dataSheets':
                    eventName = eventType + '_datasheets_tab';
                    break;
                case 'additionalDocuments':
                    eventName = eventType + '_additional_documents_tab';
                    break;
            }
        }
        try {
            const insertMutation = `
            mutation {
                events_db{
                    insert_user_event_count(objects: {count: ${incrementBy}, name: "${eventName}", bom_id: -1}) {
                        affected_rows
                    }
                }
            }
            `;
            const updateMutation = `
            mutation {
                events_db{
                    update_user_event_count( _inc: {count: ${incrementBy}},where: {user_id: {_eq: ${userId}}, bom_id: {_eq: -1}, name: {_eq: "${eventName}"}}) {
                        affected_rows
                    }
                }
            }`;
            pushEvent({ insertMutation, updateMutation, responseAttribute: 'user_event_count' });
        } catch (e) {
            /* empty */
        }
    }

    function pushUserEventCountAtBOM({ eventType, count }: IUseEventCountBoolean) {
        try {
            const insertMutation = `
            mutation {
                events_db{
                    insert_user_event_count(objects: {count: ${count}, name: "${eventType}", bom_id: ${bomId!}}) {
                        affected_rows
                    }
                }
            }
            `;
            const updateMutation = `
            mutation {
                events_db{
                    update_user_event_count(_set: {count: ${count}}, where: {user_id: {_eq: ${userId}}, bom_id: {_eq: ${bomId!}}, name: {_eq: "${eventType}"}}) {
                    affected_rows
                    }
                }
              }`;
            pushEvent({ insertMutation, updateMutation, responseAttribute: 'user_event_count' });
        } catch (e) {
            /* empty */
        }
    }

    return { pushUserEventCount, pushUserEventCountAtBOM };
};
