import { BodySmall, Box, ModalFooter, ModalHeader, Modal } from '@parspec/pixel';

type RefreshDataSheetProps = {
    open: boolean;
    handleDialog: () => void;
    onAccept: () => void;
};

const RefreshBodModal = (props: RefreshDataSheetProps) => {
    const { open, handleDialog, onAccept } = props;
    return (
        <Modal
            open={open}
            header={<ModalHeader title="Refresh Search Results?" onClose={handleDialog} />}
            footer={<ModalFooter cancelButtonLabel="Cancel" continueButtonLabel="Refresh Results" onAccept={onAccept} onReject={handleDialog} />}
        >
            <Box width="490px">
                <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                    The product information has been updated.
                </BodySmall>
                <BodySmall limit={false} my={1} color="neutral.dark">
                    Do you want to refresh results to reflect these changes, or keep the existing suggestions? Refreshing results will clear the existing selected document.
                </BodySmall>
            </Box>
        </Modal>
    );
};

export default RefreshBodModal;
