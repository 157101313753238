import { MenuItemModel } from '@syncfusion/ej2-react-navigations';
import { IMappings } from './types';

export const HEADER_TYPE = {
    TYPE: 'Type',
    MANUFACTURE: 'Manufacturer',
    MODEL_NUMBER: 'Model Number',
    MANUFACTURE_MODEL_NO: 'Manufacturer & Model Number',
    SUBMITTAL_NOTES: 'Submittal Notes (external)',
    QUANTITY: 'Quantity',
    DESCRIPTION: 'Description',
    IGNORE_COLUMN: 'Ignore Column',
    QUOTE_NOTES: 'Quote Notes',
    UNIT_COST: 'Unit Cost',
    LEAD_TIME: 'Lead Time',
    EXTENDED_COST: 'Extended Cost'
};

export const MENU_ITEMS: MenuItemModel[] = [
    {
        text: 'Type'
    },
    {
        text: 'Manufacturer'
    },
    {
        text: 'Model Number'
    },
    {
        text: 'Manufacturer & Model Number'
    },
    {
        text: 'Submittal Notes (external)'
    },
    {
        text: 'Quantity'
    },
    {
        text: 'Description'
    },
    {
        text: 'Quote Notes'
    },
    {
        text: 'Unit Cost'
    },
    {
        text: 'Lead Time'
    },
    {
        text: 'Extended Cost'
    },
    {
        text: 'Ignore Column'
    }
];

export const MAPPINGS: IMappings = {
    TYPE: 'Type',
    MANUFACTURER: 'Manufacturer',
    MODEL_NUMBER: 'Model Number',
    MANUFACTURER_MODEL_NO: 'Manufacturer & Model Number',
    SUBMITTAL_NOTES: 'Submittal Notes (external)',
    QUANTITY: 'Quantity',
    DESCRIPTION: 'Description',
    QUOTE_NOTES: 'Quote Notes',
    UNIT_COST: 'Unit Cost',
    LEAD_TIME: 'Lead Time',
    EXTENDED_COST: 'Extended Cost'
};
