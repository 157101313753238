import { Box, Button, MoreVertIcon, Menu } from '@parspec/pixel';

import { useGetShareOrDownloadOptions } from '../shared/hooks';
import { IBomAndQuoteIds } from '../shared/types';

interface IFooterProps {
    documentType: string;
    onAccept?: () => void;
    onReject?: () => void;
    setSnackbarOpen: (message: string, autoHideDuration?: number | null | undefined) => void;
    bomAndQuoteIds: Array<IBomAndQuoteIds>;
}

export const Footer = ({ onAccept, onReject, documentType, setSnackbarOpen, bomAndQuoteIds }: IFooterProps) => {
    const { optionsArray, isLoading } = useGetShareOrDownloadOptions({ documentType, setSnackbarOpen, bomAndQuoteIds, type: 'download' });
    return (
        <Box width={1} pr={3}>
            <Box display={'flex'} gap={2} alignItems={'center'} justifyContent={'flex-end'}>
                <Button disabled={isLoading} color="secondary" variant="outlined" onClick={onReject}>
                    Close
                </Button>

                <Box>
                    <Menu options={optionsArray}>
                        <Button disabled={isLoading} isLoading={isLoading} variant="outlined" color="secondary" fullWidth endIcon={<MoreVertIcon />}>
                            Download
                        </Button>
                    </Menu>
                </Box>

                <Box>
                    <Button disabled={isLoading} variant="contained" onClick={onAccept}>
                        Share Via Email
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
