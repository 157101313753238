import { Box, H5 } from '@parspec/pixel';
import ProductDescription from 'src/features/shared/ProductDescription/ProductDescription';
import ProductInfo from 'src/features/shared/ProductInfo/ProductInfo';

interface HeaderProps {
    children?: React.ReactNode;
    title: string;
}

export const Header = ({ children, title }: HeaderProps) => {
    return (
        <Box width="100%" height="160px" bgcolor="primary.contrastText" p={6}>
            <Box width="100%" display="flex">
                <Box flex={1}>
                    <H5>{title}</H5>
                </Box>
                {children}
            </Box>
            <Box width="100%" marginBottom={4} pt={2}>
                <ProductInfo />
            </Box>
            <ProductDescription />
        </Box>
    );
};
