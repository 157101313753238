import { useMemo } from 'react';

import { Skeleton, Autocomplete } from '@parspec/pixel';

import { useGetBomData } from './hooks';

interface OptionsDropDownProps {
    value: string;
    bomId: string;
    onChange: (event: React.SyntheticEvent<Element, Event>) => void;
    name?: string;
    lotMap: Record<number, number | null>;
}

const OptionsDropDown = ({ bomId, onChange, value, name, lotMap }: OptionsDropDownProps) => {
    const { data: bomData, isLoading: isBomDataLoading } = useGetBomData(bomId, {
        enabled: Boolean(bomId)
    });

    const optionsList = useMemo(() => {
        if (!bomData?.data) return [];

        const optionsArr = bomData?.data
            .filter((bodData) => bodData.section && !lotMap[bodData.id] && !bodData?.kit)
            .map((item: any) => {
                const { category, manufacturer, model_number, id } = item;

                return {
                    label: [category, manufacturer, model_number].filter((data) => data).join(' | '),
                    value: String(id)
                };
            })
            .filter((option) => option.label);

        return optionsArr;
    }, [bomData?.data]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>) => {
        const target = event.target as HTMLInputElement
        const obj = target.value as unknown as { [index: string]: string } | null;
        const value = obj?.value ? String(obj.value) : '';
        onChange({ ...event, target: { ...target, name: name || '', value: value } as HTMLInputElement });
    };

    const getSelectedLabel = (value: string) => {
        return optionsList.find((item) => item.value === value)?.label;
    };

    return (
        <>
            {isBomDataLoading ? (
                <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
            ) : (
                <Autocomplete optionlabelkeyname="label" id="productToReplace" options={optionsList} value={getSelectedLabel(value)} onChange={handleChange} label="Product to Replace" />
            )}
        </>
    );
};

export default OptionsDropDown;
