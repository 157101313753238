import { rest } from 'msw';
import { bod, bomStatsData, optionSections, sections } from './bod';
import { datasheetStatsData, datasheetTabData, datasheetReuseData } from './datasheetTabData';
import { omReuseWarrantyData, omStatsData, omTabData } from './omTabData';
import { livenessStatus } from './livenessStatus';
import { sortedProductList } from './sortedBOMDetails';

export const bomHandlers = [
    rest.get('*/bom/:bomId/bod', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(bod));
    }),
    rest.get('*/bom/:bomId/section/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(sections));
    }),
    rest.get('*/bom/:bomId/substitute_section/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(optionSections));
    }),
    //MOCKING 403 unauthorised page
    // rest.get('*/bom/:bomId/submittal_metadata/', (_req, res, ctx) => {
    //   return res(ctx.status(403), ctx.json({errorMessage: `Unauthorised`}));
    // }),
    rest.get('*/bom/:bomId/submittal_metadata/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(datasheetTabData));
    }),
    rest.get('*/bom/:bomId/o_m_metadata/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(omTabData));
    }),
    rest.get('*/bom/:bomId/bod/:bodId', (req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(bod.find((item) => item.id === Number(req.params.bodId))));
    }),
    rest.get('*/bom/:bomId/submittal_metadata/:bodId', (req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(datasheetTabData.find((item) => item.bod_id === Number(req.params.bodId))));
    }),
    rest.get('*/statistics/bom/:bomId/o_m', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(omStatsData));
    }),
    rest.get('*/bom/:bomId/statistics', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(bomStatsData));
    }),
    rest.get('*/bom/:bomId/statistics/datasheet', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(datasheetStatsData));
    }),
    rest.get('*/statistics/bom/:bomId/o_m', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(omStatsData));
    }),
    rest.patch('*/bom/:bomId/bod/order/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.delete('*/bom/:bomId/bod/bulk', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.post('*/bom/:bomId/bod/duplicate/bulk', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bomId/bod/bulk', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.post('*/bom/:bomId/bod', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bomId/bod/:bodId/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bomId/submittal_metadata/:bodId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.post('*/bom/:bomId/submittal_metadata/selected_datasheet/bulk?source=selected_datasheet', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(datasheetReuseData));
    }),
    rest.post('*/bom/:bomId/submittal_metadata/selected_installation_guide/bulk?source=selected_datasheet', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(omReuseWarrantyData));
    }),
    rest.post('*bod/sort/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(sortedProductList));
    }),
    rest.post('*document-bookkeeper-staging.parspec.io/controller/datasheet/bulk_document_liveness?version={document_hash_version}', (_req, res, ctx) => {
        return res(ctx.delay(3000), ctx.json(livenessStatus));
    })
];
