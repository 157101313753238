export const newPasswordVerifier = (password: string) => {
    if (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\s\S]{8,255}$/.test(password)) {
        return true;
    } else {
        return false;
    }
};

export const trimEmptySpaces = (nameStr: string) => {
    return nameStr.trim().replace(/\s+/g, ' ');
};

export const countryOptions = [
    { label: 'USA', value: 'USA' },
    { label: 'Canada', value: 'Canada' }
];

export const USA = 'USA';
export const CANADA = 'Canada';

export const modifiedStateList = (countryStates: string[]) => {
    const stateOptions: { label: string; value: string }[] = countryStates.map((data: string) => ({ label: data, value: data }));

    return stateOptions;
};
