import { axios } from 'src/app/Axios';

import { IUserInfoResponse, IUserAccountDataRequest, IUserAccountPreferencesResponse, IUserAccountPreferencesRequest } from './apiTypes';

export const endPoints = {
    userProfile: '/api/profile/',
    userPreferences: '/api/user/preferences/'
};

export const getUserProfileInfo = async () => {
    return axios.get<IUserInfoResponse>(endPoints.userProfile);
};

export const getUserAccountPreferences = async () => {
    return axios.get<IUserAccountPreferencesResponse>(endPoints.userPreferences);
};

export const postUserProfileInfo = async (data: IUserAccountDataRequest) => {
    return axios.patch(endPoints.userProfile, data);
};

export const patchUserAccountPreferences = async (data: IUserAccountPreferencesRequest) => {
    return axios.patch(endPoints.userPreferences, data);
};
