import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BodyBig, Button, FileDownloadOutlinedIcon, Grid, ShareIcon, Skeleton, Snackbar, Box, Info, BodySmall } from '@parspec/pixel';

import NoCompileCard from '../DocumentCards/NoCompileCard';
import NoCompileCardWithoutCreateButton from '../DocumentCards/NoCompileCardWithoutCreateButton';
import DraftDocumentCard from '../DocumentCards/DraftDocumentCard';
import DocumentCard from '../DocumentCards/DocumentCard';
import { DEFAULT_TITLE_FOR_VERSION_NOTE, O_M, QUOTE, SUBMITTAL, SnackbarMessages, getDocumentStatusMessage } from 'src/features/BOM/FinalDocuments/constants';
import { callProxyURL } from '../utils/downloadFile';
import { DocumentOutOfSyncResponse, DocumentResponse } from '../queries/apiTypes';
import { useDeleteDocumentMutation, useDownloadAllMutation, useUpdateDocumentMutation } from '../queries';
import CreateNewVersion from '../Preview/CreateNewVersionModal';
import { setRouteToLocalStorage } from '../../../shared/utils/utils';
import DeleteConfirmation from '../Preview/DeleteConfirmationModal';
import ShareLinkInfo from '../Preview/ShareLinkInfoModal';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { useUpdateDocumentTagMutation, useUnlinkBOMMutation } from '../../../Project/queries';
import { useCheckAccessForProductFinderOrQuoting } from '../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useAllTagsHook } from '../../../shared/hooks/useAllTagsHook';
import { QUOTE_DOCUMENT_AUTOMATION_STATUSES } from '../../../shared/utils/constants';

interface AllDocumentCardsProps {
    isAllDocumentsLoading: boolean;
    documentOutOfSyncData?: DocumentOutOfSyncResponse;
    isDocumentOutOfSyncDataLoading: boolean;
    allDocumentsData: DocumentResponse[];
    documentType: 'submittal' | 'o_m' | 'quote';
}

const skeletonArray = Array.from({ length: 3 }, (_a, b) => b);

const AllDocumentCards = (props: AllDocumentCardsProps) => {
    const { documentOutOfSyncData, allDocumentsData, isAllDocumentsLoading, documentType, isDocumentOutOfSyncDataLoading } = props;

    const { bomId = '', projectId = '' } = useParams();

    const navigate = useNavigate();

    const { pathname, search } = useLocation();

    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();

    const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);

    const [isDownloadAllDocumentDisabled, setIsDownloadAllDocumentDisabled] = useState(false);

    const [document, setDocument] = useState<string>();

    const [isSharedLink, setIsSharedLink] = useState(false);

    const [isEditNotes, setIsEditNotes] = useState(false);

    const [versionNote, setVersionNote] = useState<Record<string, string> | undefined>({});

    const [submittalId, setSubmittalId] = useState<number>();

    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { mutateAsync: downloadAllRefetch } = useDownloadAllMutation();

    const { mutateAsync: updateDocumentMutation, isLoading: isUpdateDocumentLoading } = useUpdateDocumentMutation();

    const { mutateAsync: documentDeleteMutation, isLoading: isDocumentDeleteLoading } = useDeleteDocumentMutation();

    const { mutateAsync: updateDocumentTag } = useUpdateDocumentTagMutation();

    const { mutateAsync: unLinkBomDetails } = useUnlinkBOMMutation();

    const allTagsObject = useAllTagsHook();

    const handleDownloadAll = async (_e: React.MouseEvent<HTMLButtonElement>, documentType: string) => {
        try {
            setSnackbarOpen(SnackbarMessages.DOWNLOADING);
            if (documentType === documentType) {
                setIsDocumentDownloading(true);
            } else {
                setIsDocumentDownloading(true);
            }
            const response = await downloadAllRefetch({
                bomId: bomId,
                documentType: documentType
            });

            if (response?.status === 200) {
                switch (documentType) {
                    case SUBMITTAL:
                        setIsDocumentDownloading(false);
                        setIsDownloadAllDocumentDisabled(true);
                        await callProxyURL(response?.data, 'zip');
                        setIsDownloadAllDocumentDisabled(false);
                        break;

                    case O_M:
                        setIsDocumentDownloading(false);
                        setIsDownloadAllDocumentDisabled(true);
                        await callProxyURL(response?.data, 'zip');
                        setIsDownloadAllDocumentDisabled(false);
                        break;

                    case QUOTE:
                        setIsDocumentDownloading(false);
                        setIsDownloadAllDocumentDisabled(true);
                        await callProxyURL(response?.data, 'zip');
                        setIsDownloadAllDocumentDisabled(false);
                        break;
                    default:
                        break;
                }
                setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
            }
        } catch (error) {
            setSnackbarClose();
        }
    };

    const sharedLinkHandler = (documentType: string) => {
        setDocument(documentType);
        setIsSharedLink(true);
    };

    const handleCreateNew = (route: string) => {
        setRouteToLocalStorage(`${pathname}${search}`);
        navigate(`finalDocuments/compile/${route}`);
    };

    const handleOnSubmit = async (bomsNote: Record<string, string> | null) => {
        if (bomsNote) {
            const versionNotes = bomsNote[bomId];
            setVersionNote({ [bomId]: versionNotes });
            if (bomId && submittalId && document) {
                await updateDocumentMutation({
                    bomId: bomId,
                    id: submittalId,
                    version_notes: versionNotes,
                    documentType: document
                });
                setIsEditNotes(false);
                setSnackbarOpen(SnackbarMessages.UPDATE_SUCCESS);
            }
        }
    };

    const handleEditNotes = useCallback((id: number, notesContent: string, documentType: string) => {
        setDocument(documentType);
        setIsEditNotes(true);
        setVersionNote({ [bomId]: notesContent });
        setSubmittalId(id);
    }, []);

    const handleSubmit = async () => {
        if (bomId && submittalId && document) {
            const response = await documentDeleteMutation({
                bomId: bomId,
                id: submittalId,
                documentType: document
            });

            if (response.status === 200) {
                setIsDeleteConfirmation(false);
            }
        }
    };

    const getSharedStateHandler = useCallback(
        async (isShared: boolean, id: number, documentType: string) => {
            await updateDocumentMutation({
                bomId,
                id: id,
                is_published: isShared,
                documentType: documentType
            });

            setSnackbarOpen(SnackbarMessages.UPDATE_SUCCESS, 3000);
        },
        [bomId]
    );

    const handleDocumentStatus = async (statusId: number, id: number, documentType: string) => {
        setSnackbarOpen(getDocumentStatusMessage(documentType, false));
        await updateDocumentTag({
            bom_id: bomId,
            entity_type: documentType,
            entity_id: id,
            tag_id: statusId || null
        });

        if (bomId && projectId && allTagsObject.size && QUOTE_DOCUMENT_AUTOMATION_STATUSES.some((status) => allTagsObject.get(statusId) === status)) {
            await unLinkBomDetails({
                bomId,
                projectId: Number(projectId)
            });
        }
        setSnackbarOpen(getDocumentStatusMessage(documentType, true));
    };

    const handleDocumentDelete = useCallback(async (submittalId: number, documentTypeFlag: string) => {
        setSubmittalId(submittalId);
        setDocument(documentTypeFlag);
    }, []);

    return (
        <>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />

            <CreateNewVersion
                open={isEditNotes}
                onCloseClick={() => setIsEditNotes(false)}
                onSuccess={() => null}
                textValue={versionNote?.[bomId]}
                title={DEFAULT_TITLE_FOR_VERSION_NOTE}
                isLoading={isUpdateDocumentLoading}
                onSubmit={handleOnSubmit}
            />

            {isDeleteConfirmation && (
                <DeleteConfirmation
                    isLoading={isDocumentDeleteLoading}
                    open={isDeleteConfirmation}
                    onCloseClick={() => setIsDeleteConfirmation(false)}
                    onSuccess={() => setIsDeleteConfirmation(false)}
                    handleSubmit={handleSubmit}
                />
            )}

            {isSharedLink && (
                <ShareLinkInfo documentType={document || ''} open={isSharedLink} onCloseClick={() => setIsSharedLink(false)} onSuccess={() => setIsSharedLink(false)} bomId={bomId || ''} />
            )}

            <Grid container direction={'column'} rowSpacing={2} p={4}>
                <Grid item>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <Grid container columnSpacing={3}>
                                <Grid container item>
                                    {documentType === QUOTE && (
                                        <Box display="flex" columnGap={4} width={1}>
                                            <BodyBig sx={{ mt: 1 }} color={'secondary.main'}>
                                                Quotes
                                            </BodyBig>
                                            <Info>
                                                <BodySmall color="inherit">
                                                    Linked BOMs will be automatically unlinked when the Quote Status is changed to <strong>Won</strong>, <strong>Lost</strong>, or{' '}
                                                    <strong>Abandoned</strong>.
                                                </BodySmall>
                                            </Info>
                                        </Box>
                                    )}
                                    {documentType === SUBMITTAL && <BodyBig color={'secondary.main'}>Submittal</BodyBig>}
                                    {documentType === O_M && <BodyBig color={'secondary.main'}>O&M Package</BodyBig>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container columnSpacing={2}>
                                <Grid item>
                                    <Button
                                        isLoading={isDocumentDownloading}
                                        disabled={isDocumentDownloading || isDownloadAllDocumentDisabled || !allDocumentsData?.length ? true : false}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDownloadAll(e, documentType)}
                                    >
                                        <FileDownloadOutlinedIcon
                                            fontSize="small"
                                            sx={{
                                                mr: 2
                                            }}
                                        />
                                        Download All
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color="secondary" variant="outlined" onClick={() => sharedLinkHandler(documentType)}>
                                        <ShareIcon
                                            fontSize="small"
                                            sx={{
                                                mr: 2
                                            }}
                                        />
                                        Manage Shareable Link
                                    </Button>
                                </Grid>
                                {documentType === QUOTE ? (
                                    <>
                                        {quotingAccess && (
                                            <Grid item>
                                                <Button onClick={() => handleCreateNew(documentType)} color="tertiary" variant="contained">
                                                    Create New
                                                </Button>
                                            </Grid>
                                        )}
                                    </>
                                ) : (
                                    <Grid item>
                                        <Button onClick={() => handleCreateNew(documentType)} color="tertiary" variant="contained">
                                            Create New
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={4}>
                        {isAllDocumentsLoading && isDocumentOutOfSyncDataLoading ? (
                            skeletonArray.map((_i, k) => (
                                <Grid item gap={2}>
                                    <Skeleton key={k} animation="wave" height={432} variant="rounded" width={314} />
                                </Grid>
                            ))
                        ) : (
                            <>
                                {!documentOutOfSyncData?.out_of_sync && !allDocumentsData?.length ? (
                                    <Grid item>
                                        {documentType === QUOTE ? (
                                            <>{quotingAccess ? <NoCompileCard flag={documentType} /> : <NoCompileCardWithoutCreateButton />}</>
                                        ) : (
                                            <NoCompileCard flag={documentType} />
                                        )}
                                    </Grid>
                                ) : (
                                    <>
                                        {documentOutOfSyncData?.out_of_sync && (quotingAccess || documentType !== QUOTE) && (
                                            <Grid item>
                                                <DraftDocumentCard flag={documentType} />
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {allDocumentsData?.length
                            ? allDocumentsData.map((item: DocumentResponse, index) => {
                                  return (
                                      <Grid item key={item.id}>
                                          <DocumentCard
                                              deleteConfirmation={setIsDeleteConfirmation}
                                              flag={documentType}
                                              isLabelVisible={!index ? true : false}
                                              documentCardData={item}
                                              handleEditNotes={handleEditNotes}
                                              getSharedState={getSharedStateHandler}
                                              handleDelete={handleDocumentDelete}
                                              documentType={documentType}
                                              onSelectStatus={handleDocumentStatus}
                                          />
                                      </Grid>
                                  );
                              })
                            : null}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AllDocumentCards;
