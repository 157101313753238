import { useRef, useMemo, useState } from 'react';

import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

import { Box, AgGridTable } from '@parspec/pixel';

import { TableRightSection, ManageColumnsPopup, CustomHeader } from '../../shared/components';
import { getRowId } from './utils';
import { BRANCH_LOCATION_TABLE_COLUMN_OPTIONS, PRIMARY_FIELD_VALUE } from './constants';
import {
    QUOTES_COLUMN_TOOLTIP_TEXT,
    REVENUE_COLUMN_TOOLTIP_TEXT,
    MARGIN_COLUMN_TOOLTIP_TEXT,
    PROFIT_MARGIN_COLUMN_TOOLTIP_TEXT,
    SUBMITTALS_AND_OM_COLUMN_TOOLTIP_TEXT,
    DEFAULT_COL_DEF,
    TABLE_COLUMN_NAMES,
    TABLE_ROW_HEIGHT,
    defaultLockColumnDef,
    WIN_RATE_COLUMN_FOR_USER_BRANCH_TAB_TOOLTIP_TEXT
} from '../../shared/constants';
import { useGetUserProfileInfoQuery } from '../../../Settings/MyProfile/queries';
import { EVENT_SERVICE_PAGENAME_VALUES } from '../../../shared/utils/constants';
import { useGetSelectedColumnsQuery, useUpdateSelectedColumnsMutation } from '../../queries';
import { ReportingPageNameTypes, BranchLocationsTabDataResponse } from '../../queries/apiTypes';
import { costInDollarsFormatterForTable, percentageFormatterForTable, numberAndQuantityFormatterForTable, dateFormatterForExportedTable } from '../../shared/utils';
import { numberComparator } from '../../../Dashboard/shared/utils';

const defaultColumnDef: Record<string, ColDef> = {
    location: {
        field: 'location',
        headerName: TABLE_COLUMN_NAMES.BRANCH_LOCATIONS,
        minWidth: 140,
        sortable: true,
        pinned: 'left',
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.BRANCH_LOCATIONS} />,
        headerComponentParams: {
            enableSorting: true
        }
    },
    projects: {
        field: 'projects',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => numberAndQuantityFormatterForTable(params?.data?.projects),
        valueGetter: (params: ValueGetterParams) => numberAndQuantityFormatterForTable(params?.data?.projects),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.projects);
            const valB = Number(nodeB?.data?.projects);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            enableSorting: true
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.PROJECTS} />
    },
    quotes: {
        field: 'quotes',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => numberAndQuantityFormatterForTable(params?.data?.quotes),
        valueGetter: (params: ValueGetterParams) => numberAndQuantityFormatterForTable(params?.data?.quotes),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.quotes);
            const valB = Number(nodeB?.data?.quotes);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: QUOTES_COLUMN_TOOLTIP_TEXT,
            enableSorting: true,
            width: '208px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.QUOTES} />
    },
    submittalsAndOMs: {
        field: 'submittalsAndOMs',
        headerName: TABLE_COLUMN_NAMES.SUBMITTALS_AND_OM,
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => numberAndQuantityFormatterForTable(params?.data?.submittalsAndOMs),
        valueGetter: (params: ValueGetterParams) => numberAndQuantityFormatterForTable(params?.data?.submittalsAndOMs),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.submittalsAndOMs);
            const valB = Number(nodeB?.data?.submittalsAndOMs);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: SUBMITTALS_AND_OM_COLUMN_TOOLTIP_TEXT,
            enableSorting: true,
            width: '228px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.SUBMITTALS_AND_OM} />
    },
    winRate: {
        field: 'winRate',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => percentageFormatterForTable(params?.data?.winRate),
        valueGetter: (params: ValueGetterParams) => percentageFormatterForTable(params?.data?.winRate),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.winRate);
            const valB = Number(nodeB?.data?.winRate);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: WIN_RATE_COLUMN_FOR_USER_BRANCH_TAB_TOOLTIP_TEXT,
            enableSorting: true,
            width: '254px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.WIN_RATE} />
    },
    revenue: {
        field: 'revenue',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => costInDollarsFormatterForTable(params?.data?.revenue),
        valueGetter: (params: ValueGetterParams) => costInDollarsFormatterForTable(params?.data?.revenue),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.revenue);
            const valB = Number(nodeB?.data?.revenue);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: REVENUE_COLUMN_TOOLTIP_TEXT,
            enableSorting: true,
            width: '265px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.REVENUE} />
    },
    margin: {
        field: 'margin',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => costInDollarsFormatterForTable(params?.data?.margin),
        valueGetter: (params: ValueGetterParams) => costInDollarsFormatterForTable(params?.data?.margin),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.margin);
            const valB = Number(nodeB?.data?.margin);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: MARGIN_COLUMN_TOOLTIP_TEXT,
            enableSorting: true,
            width: '264px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.MARGIN} />
    },
    profitMargin: {
        field: 'profitMargin',
        minWidth: 140,
        sortable: true,
        valueFormatter: (params: ValueFormatterParams) => percentageFormatterForTable(params?.data?.profitMargin),
        valueGetter: (params: ValueGetterParams) => percentageFormatterForTable(params?.data?.profitMargin),
        comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
            const valA = Number(nodeA?.data?.profitMargin);
            const valB = Number(nodeB?.data?.profitMargin);

            return numberComparator(valA, valB);
        },
        headerComponentParams: {
            tooltipText: PROFIT_MARGIN_COLUMN_TOOLTIP_TEXT,
            enableSorting: true,
            width: '254px'
        },
        headerComponent: (params: any) => <CustomHeader params={params} fieldName={TABLE_COLUMN_NAMES.PROFIT_MARGIN} />
    }
};

interface BranchLocationsTableDataResponse extends BranchLocationsTabDataResponse {
    revenue: number;
    margin: number;
    profitMargin: number;
}

interface BranchLocationTableProps {
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
    tableData: BranchLocationsTableDataResponse[] | any;
    isDataLoading: boolean;
}

export const BranchLocationsTable = (props: BranchLocationTableProps) => {
    const { setSnackbarMessage, tableData, isDataLoading } = props;

    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();

    const { data: selectedColumnsQueryData, isFetching: isSelectedColumnsQueryDataFetching } = useGetSelectedColumnsQuery(
        { userId: userData?.data?.id || 0, pageName: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_BRANCH_LOCATIONS },
        { enabled: !isUserDataLoading }
    );
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();

    const tableRef = useRef<any>();

    function handleExportExcel() {
        const date = dateFormatterForExportedTable();

        const params = {
            fileName: `Branch_Locations_Parspec_Export_${date}.xlsx`, // Specify the filename
            sheetName: 'Branch Locations' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: ReportingPageNameTypes; columnData: string[]; userId: number } = {
            pageName: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_BRANCH_LOCATIONS,
            columnData: [PRIMARY_FIELD_VALUE, ...rowData],
            userId: userData?.data?.id || 0
        };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsQueryData?.table_settings || isSelectedColumnsQueryDataFetching) {
            return;
        }

        if ((selectedColumnsQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.hasOwn(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [isSelectedColumnsQueryDataFetching, selectedColumnsQueryData?.table_settings, tableData]);

    const isTableDataLoading = isDataLoading || isUpdateSelectedColumnsMutationLoading;

    return (
        <>
            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <AgGridTable
                    ref={tableRef}
                    getRowId={getRowId}
                    isLoading={isTableDataLoading}
                    rowHeight={TABLE_ROW_HEIGHT}
                    showToolbarPanel
                    onClearFilter={clearAllFilters}
                    toolbarRightSection={
                        <Box mb={2}>
                            <TableRightSection isTableLoading={isTableDataLoading} onExportClick={handleExportExcel} onManageColumnsClick={onManageColumnsClick} />
                        </Box>
                    }
                    rowData={isTableDataLoading ? null : tableData}
                    defaultColDef={DEFAULT_COL_DEF}
                    columnDefs={columnDefs}
                    suppressContextMenu
                    suppressMenuHide
                />
            </Box>
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={BRANCH_LOCATION_TABLE_COLUMN_OPTIONS.data}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryFetching={isSelectedColumnsQueryDataFetching}
                />
            )}
        </>
    );
};
