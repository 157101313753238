import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Box, CheckboxGroup, Modal, ModalFooter, ModalHeader, MultiSelect, MultiSelectOptionType, RadioGroup, Skeleton, TextField } from '@parspec/pixel';
import { ACESS_RADIO_OPTIONS, EDIT_ACCESS_OPTIONS, PERMISSION, VISIBLITY } from '../constants';
import { useCompany } from '../../../UserManagement/queries';
import { ICompanyLocation } from '../../../UserManagement/queries/apiTypes';
import { useNinetyViewportHeight } from 'src/features/BOM/shared/hooks';
import { useCompanyMfgQuery, useGetMfgListQuery, usePatchMfgListMutation, usePostMfgListMutation } from '../../queries';
import { useGetUserProfileInfoQuery } from '../../../MyProfile/queries';

interface UpdateManufacturerListModalProps {
    open: boolean;
    onClose: (afterSave?: boolean) => void;
    id?: number | null;
}

export default function UpdateManufacturerListModal({ open, onClose: handleClose, id }: UpdateManufacturerListModalProps) {
    const { data: mfgListInfo, isFetching: isMfgListDataLoading } = useGetMfgListQuery(id || 0, { enabled: Boolean(id) });
    const { data: allBranchLocations, isFetching: isLocationsLoading } = useCompany();
    const { data: allManufacturers, isFetching: isManufacturersLoading } = useCompanyMfgQuery();
    const { data: userProfileData, isFetching: isUserProfileLoading } = useGetUserProfileInfoQuery();
    const { mutateAsync: postMfgList, isLoading: isPatchLoading } = usePostMfgListMutation();
    const { mutateAsync: patchMfgList, isLoading: isPostLoading } = usePatchMfgListMutation();

    const isEditMode = id != null;
    const isFormLoading = (Boolean(id) && isMfgListDataLoading) || isLocationsLoading || isManufacturersLoading || isUserProfileLoading;

    const [selectedManufacturers, setSelectedManufactuers] = useState<{ value: MultiSelectOptionType[]; error: boolean }>({ value: [], error: false });
    const [name, setName] = useState({ value: '', error: false });
    const [description, setDescription] = useState('');
    const [accessValue, setAccessValue] = useState(VISIBLITY.PUBLIC);
    const [locationData, setLocationData] = useState<Array<{ label: string; name: string; checked: boolean }>>([]);
    const [locationError, setLocationError] = useState({ error: false, text: '' });
    const [editAccess, setEditAccess] = useState(PERMISSION.ADMIN);

    const manufacturerOptions = useMemo(() => {
        if (!allManufacturers?.data) {
            return [];
        }
        return allManufacturers?.data.map((manufacturer) => ({ label: manufacturer.manufacturer_name || '', value: manufacturer.company_group_man_id }));
    }, [allManufacturers]);

    const modalMaxHeight = useNinetyViewportHeight();

    useEffect(() => {
        if (allBranchLocations?.data) {
            const { company_id } = mfgListInfo?.data || {};
            const selectedLocationIdSet = new Set(company_id);
            const locationData = allBranchLocations.data.map((el: ICompanyLocation) => {
                return { label: el.name, name: String(el.id), checked: selectedLocationIdSet.has(el.id) };
            });
            setLocationData(locationData);
        }
    }, [allBranchLocations?.data, mfgListInfo?.data]);

    useLayoutEffect(() => {
        if (isEditMode && mfgListInfo?.data) {
            const { name, description, company_group_man_id, visibility, permission } = mfgListInfo.data;
            if (manufacturerOptions) {
                const selectedMfgSet = new Set(company_group_man_id);
                setSelectedManufactuers({ value: manufacturerOptions.filter((mfg) => selectedMfgSet.has(mfg.value)), error: false });
            }
            setName({ value: name, error: false });
            setDescription(description || '');
            setAccessValue(visibility as VISIBLITY);
            setEditAccess(permission as PERMISSION);
        }
    }, [mfgListInfo?.data, manufacturerOptions]);

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        const currValue = event.target.value;
        setName({ value: currValue, error: false });
    }

    function handleSelect(_event: React.SyntheticEvent<Element, Event>, value: MultiSelectOptionType[]) {
        setSelectedManufactuers({ value, error: false });
    }

    function handleAccessChange(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
        setAccessValue(value as VISIBLITY);
        if (value === VISIBLITY.PRIVATE) {
            setEditAccess(PERMISSION.USER);
        }
    }

    function handleLocationChange(name: string, checked: boolean) {
        setLocationData((locationData) => locationData.map((data) => (data.name === name ? { ...data, checked } : data)));
        setLocationError({ error: false, text: '' });
    }

    function handleLocationSelectAll() {
        setLocationData((locationData) => locationData.map((data) => ({ ...data, checked: true })));
        setLocationError({ error: false, text: '' });
    }

    function handleEditAccessChange(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
        setEditAccess(value as PERMISSION);
    }

    function handleValidation() {
        let isValid = true;
        if (!name.value) {
            setName({ value: name.value, error: true });
            isValid = false;
        }
        if (selectedManufacturers.value.length === 0) {
            setSelectedManufactuers({ value: selectedManufacturers.value, error: true });
            isValid = false;
        }
        if (accessValue === VISIBLITY.PROTECTED && locationData.filter((data) => data.checked).length === 0) {
            setLocationError({ error: true, text: 'Please select at least one location' });
            isValid = false;
        }

        return isValid;
    }

    async function handleSave() {
        const isValid = handleValidation();
        if (!isValid) {
            return;
        }
        try {
            const selectedMfgIds = selectedManufacturers.value.map((mfg) => mfg.value) as number[];
            const selectedBranchLocationsId = accessValue === VISIBLITY.PROTECTED ? locationData.filter((data) => data.checked).map((data) => Number(data.name)) : null;
            const companyGrpId = userProfileData?.data.company_group_id || 0;
            const payload = {
                name: name.value,
                description,
                company_group_man_id: selectedMfgIds,
                visibility: accessValue,
                company_id: selectedBranchLocationsId,
                permission: editAccess,
                company_group_id: companyGrpId
            };
            if (isEditMode) {
                await patchMfgList({ mfgListId: id as number, payload });
            } else {
                await postMfgList(payload);
            }
            handleClose(true);
        } catch (error: any) {
            /* empty */
        }
    }

    function handleCloseWithoutSave() {
        handleClose();
    }

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Manufacturer List" onClose={handleCloseWithoutSave} />}
            footer={<ModalFooter onAccept={handleSave} onReject={handleCloseWithoutSave} isLoading={isPatchLoading || isPostLoading || isFormLoading} />}
        >
            <Box display="flex" flexDirection="column" gap={4} width="575px" paddingTop="24px" maxHeight={`${modalMaxHeight - 91}px`} overflow="auto" paddingRight="12px" marginRight="-12px">
                {isFormLoading && (
                    <>
                        <Skeleton variant="rectangular" height={40} />
                        <Skeleton variant="rectangular" height={97} />
                        <Skeleton variant="rectangular" height={40} />
                        <Skeleton variant="rectangular" height={157} />
                        <Skeleton variant="rectangular" height={114} />
                    </>
                )}
                {!isFormLoading && (
                    <>
                        <Box>
                            <TextField value={name.value} onChange={handleNameChange} label="Name" required error={name.error} helperText={name.error ? 'Required' : ''} />
                        </Box>
                        <Box>
                            <TextField
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                }}
                                label="Description"
                                multiline
                                rows={4}
                            />
                        </Box>
                        <Box>
                            <MultiSelect
                                value={selectedManufacturers.value}
                                label="Manufacturer(s) *"
                                options={manufacturerOptions}
                                onChange={handleSelect}
                                size="small"
                                limitTags={4}
                                multiple
                                error={selectedManufacturers.error}
                                helperText={selectedManufacturers.error ? 'Required' : ''}
                            />
                        </Box>
                        <Box>
                            <RadioGroup options={ACESS_RADIO_OPTIONS} value={accessValue} onChange={handleAccessChange} label="Who should have access to this list?" name="visiblity" />
                            <Box>
                                {accessValue === VISIBLITY.PROTECTED && locationData && locationData?.length !== 0 && (
                                    <CheckboxGroup
                                        label="Location Access"
                                        options={locationData}
                                        onChange={handleLocationChange}
                                        error={locationError.error}
                                        helperText={locationError.text}
                                        required
                                        showSelectAll
                                        onSelectAll={handleLocationSelectAll}
                                        maxHeight="192px"
                                    />
                                )}
                            </Box>
                        </Box>
                        {accessValue !== VISIBLITY.PRIVATE && (
                            <Box>
                                <RadioGroup options={EDIT_ACCESS_OPTIONS} value={editAccess} onChange={handleEditAccessChange} label="Who can edit this list?" name="permission" />
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Modal>
    );
}
