import { useEffect } from 'react';
import * as React from 'react';

import { Box, H6, SelectChangeEvent } from '@parspec/pixel';

import { CoverPage } from '../CommonSections/CoverPage';
import { DocumentOrdering } from '../CommonSections/DocumentOrdering';
import { TableOfContents } from '../CommonSections/TableOfContents';
import { NotesSummary } from '../CommonSections/NotesSummary';
import { HeaderFooter } from '../CommonSections/HeaderFooter';
import { BranchBrandingInterface } from '../AddEditLocation.types';
import { SubmittalTemplateInterface } from '../../queries/apiTypes';
import { SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE } from '../utils/helper';
import {
    PRE_FILL_ADDRESSED_TO,
    PRE_FILL_COVER_PAGE_LOGO,
    PRE_FILL_DATE,
    PRE_FILL_PREPARED_BY_LOGO,
    PRE_FILL_PRIMARY_TITLE,
    PRE_FILL_SECONDARY_TITLE
} from '../QuoteTemplatePreferences/utils/constants';

interface submittalDetailsProps {
    submittalDetails: SubmittalTemplateInterface;
    submittalTemplate: SubmittalTemplateInterface;
    setSubmittalTemplate: React.Dispatch<React.SetStateAction<SubmittalTemplateInterface>>;
    branchBrandingState: BranchBrandingInterface;
}
const SubmittalTemplate = (props: submittalDetailsProps) => {
    const { submittalDetails, submittalTemplate, setSubmittalTemplate, branchBrandingState } = props;

    useEffect(() => {
        if (!submittalDetails) {
            return;
        }

        setSubmittalTemplate(() => ({
            ...submittalTemplate,
            cover_page_template_id: submittalDetails.cover_page_template_id,
            cover_page_presets: {
                ...submittalTemplate.cover_page_presets,
                cover_page_section: submittalDetails.cover_page_presets.cover_page_section,
                date: submittalDetails.cover_page_presets.date,
                primary_title: submittalDetails.cover_page_presets.primary_title,
                secondary_title: submittalDetails.cover_page_presets.secondary_title,
                project_number: submittalDetails.cover_page_presets.project_number,
                quote_number: submittalDetails.cover_page_presets.quote_number,
                project_address: submittalDetails.cover_page_presets.project_address,
                stakeholders: submittalDetails.cover_page_presets.stakeholders,
                prepared_by: submittalDetails.cover_page_presets.prepared_by,
                addressed_to: submittalDetails.cover_page_presets.addressed_to,
                notes: submittalDetails.cover_page_presets.notes,
                pre_fill_primary_title: submittalDetails.cover_page_presets.pre_fill_primary_title,
                pre_fill_secondary_title: submittalDetails.cover_page_presets.pre_fill_secondary_title,
                pre_fill_date: submittalDetails.cover_page_presets.pre_fill_date,
                pre_fill_preparedby_logo: submittalDetails.cover_page_presets.pre_fill_preparedby_logo,
                pre_fill_coverpage_logo: submittalDetails.cover_page_presets.pre_fill_coverpage_logo,
                pre_fill_addressed_to: submittalDetails.cover_page_presets.pre_fill_addressed_to
            },
            header_footer_presets: {
                ...submittalTemplate.header_footer_presets,
                header_footer_section: submittalDetails.header_footer_presets.header_footer_section,
                job_name: submittalDetails.header_footer_presets.job_name,
                notes: submittalDetails.header_footer_presets.notes,
                document_type: submittalDetails.header_footer_presets.document_type,
                qty: submittalDetails.header_footer_presets.qty,
                left_footer: submittalDetails.header_footer_presets.left_footer,
                right_footer: submittalDetails.header_footer_presets.right_footer,
                date: submittalDetails.header_footer_presets.date,
                page_number: submittalDetails.header_footer_presets.page_number,
                hyperlinks: submittalDetails.header_footer_presets.hyperlinks,
                category: submittalDetails.header_footer_presets.category,
                pre_fill_job_name: submittalDetails.header_footer_presets.pre_fill_job_name,
                pre_fill_header_logo: submittalDetails.header_footer_presets.pre_fill_header_logo
            },
            table_of_content_presets: {
                ...submittalTemplate.table_of_content_presets,
                toc_section: submittalDetails.table_of_content_presets.toc_section,
                primary_title: submittalDetails.table_of_content_presets.primary_title,
                secondary_title: submittalDetails.table_of_content_presets.secondary_title,
                project_number: submittalDetails.table_of_content_presets.project_number,
                quote_number: submittalDetails.table_of_content_presets.quote_number,
                hyperlinks: submittalDetails.table_of_content_presets.hyperlinks,
                type: submittalDetails.table_of_content_presets.type,
                qty: submittalDetails.table_of_content_presets.qty,
                page_number: submittalDetails.table_of_content_presets.page_number,
                section: submittalDetails.table_of_content_presets.section
            },
            notes_presets: { ...submittalTemplate.notes_presets, notes_section: submittalDetails.notes_presets.notes_section },
            layout_presets: {
                ...submittalTemplate.layout_presets,
                datasheet: submittalDetails.layout_presets.datasheet,
                installation_guide: submittalDetails.layout_presets.installation_guide,
                drawing: submittalDetails.layout_presets.drawing,
                warranty: submittalDetails.layout_presets.warranty,
                other_document: submittalDetails.layout_presets.other_document,
                section_breaks: submittalDetails.layout_presets.section_breaks,
                show_kit_components: submittalDetails.layout_presets.show_kit_components,
                maintain_aspect_ratio: submittalDetails.layout_presets.maintain_aspect_ratio
            }
        }));
    }, [submittalDetails]);

    const changeHandler = (event: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => {
        const fieldName = field_name ? field_name : event.target?.name;

        const isNotesFromCoverPage =
            presetName === 'cover_page_presets' &&
            (fieldName === 'notes' ||
                fieldName === PRE_FILL_PRIMARY_TITLE ||
                fieldName === PRE_FILL_SECONDARY_TITLE ||
                fieldName === PRE_FILL_DATE ||
                fieldName === PRE_FILL_COVER_PAGE_LOGO ||
                fieldName === PRE_FILL_PREPARED_BY_LOGO ||
                fieldName === PRE_FILL_ADDRESSED_TO);

        const isRadioButtonsFromLayout = presetName === 'layout_presets' && !['show_kit_components', 'section_breaks', 'maintain_aspect_ratio'].includes(fieldName);
        const isSectionBreakOrKitComponentsFromLayout = presetName === 'layout_presets' && ['show_kit_components', 'section_breaks', 'maintain_aspect_ratio'].includes(fieldName);
        const isTextValuesFromHeaderFooter = presetName === 'header_footer_presets' && (fieldName === 'pre_fill_job_name' || fieldName === 'pre_fill_header_logo');

        let fieldValue: string | boolean;
        if (isNotesFromCoverPage || isRadioButtonsFromLayout || isTextValuesFromHeaderFooter) {
            fieldValue = event.target?.value as string;
        } else {
            const checkedField = event.target as HTMLInputElement
            if (isSectionBreakOrKitComponentsFromLayout) {
                fieldValue = checkedField.checked ? SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE.INCLUDED : SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE.EXCLUDED;
            } else {
                fieldValue = checkedField.checked;
            }
        }

        setSubmittalTemplate(() => ({ ...submittalTemplate, [presetName]: { ...submittalTemplate[presetName], [fieldName]: fieldValue } }));
    };

    const handleTemplateSelection = (_e: React.MouseEvent<HTMLElement>, template: string) => {
        switch (template) {
            case '1':
                setSubmittalTemplate(() => ({
                    ...submittalTemplate,
                    cover_page_template_id: 1
                }));
                break;
            case '2':
                setSubmittalTemplate(() => ({
                    ...submittalTemplate,
                    cover_page_template_id: 2
                }));
                break;
            default:
                break;
        }
    };

    return (
        <Box bgcolor="primary.contrastText">
            <Box>
                <H6>Submittal Template Preferences</H6>
                <Box mt={6}>
                    <CoverPage
                        changeHandler={changeHandler}
                        handleTemplateSelection={handleTemplateSelection}
                        templateState={submittalTemplate}
                        branchBrandingState={branchBrandingState}
                        templateName={'SUBMITTAL'}
                    />
                    <DocumentOrdering changeHandler={changeHandler} templateState={submittalTemplate} />
                    <TableOfContents changeHandler={changeHandler} templateState={submittalTemplate} templateName={'SUBMITTAL'} />
                    <NotesSummary changeHandler={changeHandler} templateState={submittalTemplate} templateName={'SUBMITTAL'} />
                    <HeaderFooter changeHandler={changeHandler} templateState={submittalTemplate} templateName={'SUBMITTAL'} />
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(SubmittalTemplate);
