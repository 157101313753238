import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BodyBig, Box, EditIcon, Grid, IconButton, Skeleton, Snackbar, TextField } from '@parspec/pixel';
import { updateBomDataSelectionStatus, updateOmDataSelectionStatus, useGetBodDetailQuery, useGetDatasheetDetailQuery, useGetOmDetailQuery, useUpdateBodDMutation } from '../../BOM/queries';
import RefreshBodModal from './RefreshBodModal';
import { someThingWentWrongMsg, updateMsg } from '../constants';
import { useParspecNavigation } from 'src/app/Context';
import { invalidateGetSelectedDocument } from '../../BOM/Datasheets/AnnotateDatasheet/queries';

interface ProductInfoProps {
    onAccept?: (val?: boolean) => void;
    isAlternates?: boolean;
    isAnnotationPage?: boolean;
}

const ProductInfo = (props: ProductInfoProps) => {
    const { onAccept, isAlternates, isAnnotationPage } = props;
    const { setHasUnsavedChanges } = useParspecNavigation();
    const [editDetails, setEditDetails] = useState(false);
    const [productData, setProductData] = useState({
        category: '',
        manufacturer: '',
        model_number: ''
    });
    const [refreshBodDetail, setOpenRefreshBodDetail] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { bomId, productId, documentType, subDocumentType } = useParams();
    const manufacturerRef = useRef<HTMLInputElement>();
    const modelRef = useRef<HTMLInputElement>();
    const typeDivRef = useRef<HTMLDivElement>(null);
    const manufacturerDivRef = useRef<HTMLDivElement>(null);
    const modelDivRef = useRef<HTMLDivElement>(null);

    const { data: bodData, isLoading } = useGetBodDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });

    const { refetch: fetchDatasheetInfo } = useGetDatasheetDetailQuery(bomId!, productId!, {
        enabled: false
    });

    const { refetch: fetchOmInfo } = useGetOmDetailQuery(bomId!, productId!, {
        enabled: false
    });

    useEffect(() => {
        if (bodData?.data) {
            const { manufacturer, model_number, category } = bodData.data;
            setProductData({ manufacturer, model_number, category });
        }
    }, [bodData]);

    const { mutateAsync: updateData } = useUpdateBodDMutation();

    //to toggle the refresh data dialog
    const toggleRefreshDataSheet = () => setOpenRefreshBodDetail((old) => !old);

    const onClickRefreshOption = () => {
        if (onAccept) onAccept(true);
        setHasUnsavedChanges(false);
        if (subDocumentType === 'datasheet') {
            invalidateGetSelectedDocument(bomId!, productId!, documentType!, subDocumentType);
            updateBomDataSelectionStatus({ bomId: bomId!, bodId: productId!, selectionStatus: 'NOT_CONFIRMED', annotationStatus: 'NOT_CONFIRMED' });
        } else {
            updateOmDataSelectionStatus({ bomId: bomId!, bodId: productId!, selectionStatus: 'NOT_CONFIRMED', subDocumentType, annotationStatus: 'NOT_CONFIRMED' });
        }
        toggleRefreshDataSheet();
    };

    // to change the Product info details
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name) {
            setProductData((old) => ({ ...old, [name]: value }));
        }
    };

    const handleEditProductInfo = async () => {
        if (!editDetails) setEditDetails(true);
        else {
            const { manufacturer, model_number, category } = bodData?.data || {};
            if (productData.category !== category || productData.manufacturer !== manufacturer || productData.model_number !== model_number) {
                try {
                    await updateData({
                        bomId: bomId!,
                        input: { id: bodData?.data?.id, ...productData }
                    });
                    if (!isAlternates) {
                        let detailResponse;
                        if (subDocumentType === 'datasheet') {
                            detailResponse = await fetchDatasheetInfo();
                        } else {
                            detailResponse = await fetchOmInfo();
                        }

                        if (!Object.keys(detailResponse?.data?.data?.[`selected_${subDocumentType}`] || {}).length) {
                            setHasUnsavedChanges(false);
                            if (onAccept) onAccept(false);
                        } else if (!isAnnotationPage) toggleRefreshDataSheet();
                    }
                    setSnackbarMessage(updateMsg);
                } catch (e: any) {
                    setSnackbarMessage(someThingWentWrongMsg);
                }
            }
            setEditDetails(false);
        }
    };

    const onPressEnter = (e: any) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'category') {
                return manufacturerRef?.current?.focus();
            }
            if (e.target.name === 'manufacturer') {
                return modelRef?.current?.focus();
            }
            modelRef?.current?.blur();
            return handleEditProductInfo();
        }
    };

    const handleOutsideClick = (e: globalThis.MouseEvent) => {
        if (editDetails) {
            if (!manufacturerDivRef.current || !modelDivRef.current || !typeDivRef.current) return;
            if (!manufacturerDivRef.current.contains(e.target as Node) && !modelDivRef.current.contains(e.target as Node) && !typeDivRef.current.contains(e.target as Node)) {
                handleEditProductInfo();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [editDetails, productData]);

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {isLoading && (
                <Grid container spacing={3}>
                    <Grid xs={2} item md={2} lg={2}>
                        <Skeleton width="100%" height="36px" />
                    </Grid>
                    <Grid xs={4} item md={4} lg={4}>
                        <Skeleton width="100%" height="36px" />
                    </Grid>
                    <Grid xs={6} item md={6} lg={6}>
                        <Skeleton width="100%" height="36px" />
                    </Grid>
                </Grid>
            )}
            {bodData && (
                <>
                    {!editDetails ? (
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <Box display="flex" gap={4}>
                                    <Box maxWidth="20%">
                                        <BodyBig color={!productData.category ? 'text.secondary' : ''}>{productData.category || 'Enter type here'}</BodyBig>
                                    </Box>

                                    <Box maxWidth="40%">
                                        <BodyBig color={!productData.manufacturer ? 'text.secondary' : ''}>{productData.manufacturer.toUpperCase() || 'Enter manufacturer here'}</BodyBig>
                                    </Box>

                                    <Box flex={1} display="flex" gap={4} alignItems="center">
                                        <BodyBig color={!productData.model_number ? 'text.secondary' : ''}>{productData.model_number.toUpperCase() || 'Enter model number here'}</BodyBig>
                                        <Box>
                                            <IconButton size="small" onClick={handleEditProductInfo}>
                                                <EditIcon color={editDetails ? 'primary' : 'secondary'} fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid xs={2} item md={2} lg={2}>
                                <TextField ref={typeDivRef} onKeyDown={onPressEnter} value={productData.category || ''} name="category" onChange={handleChange} size="small" label="Type" />
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                                <TextField
                                    ref={manufacturerDivRef}
                                    inputRef={manufacturerRef}
                                    onKeyDown={onPressEnter}
                                    value={productData.manufacturer || ''}
                                    name="manufacturer"
                                    onChange={handleChange}
                                    size="small"
                                    label="Manufacturer"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        ref={modelDivRef}
                                        inputRef={modelRef}
                                        onKeyDown={onPressEnter}
                                        value={productData.model_number || ''}
                                        name="model_number"
                                        onChange={handleChange}
                                        size="small"
                                        label="Model number"
                                    />
                                    <Box ml={5}>
                                        <IconButton size="small">
                                            <EditIcon color={editDetails ? 'primary' : 'secondary'} fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            {refreshBodDetail && <RefreshBodModal open={refreshBodDetail} handleDialog={toggleRefreshDataSheet} onAccept={onClickRefreshOption} />}
        </>
    );
};

export default ProductInfo;
