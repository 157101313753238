import { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { CellStyle, ColDef } from 'ag-grid-community';

import { BodySmall, Box, Button, ControlPointDuplicateIcon, DeleteOutlineIcon, FilterAltOffIcon, IconButton, SearchIcon, Snackbar, TableHeaderMenuIcon, TextField, Tooltip } from '@parspec/pixel';

import MenuOptions from './menuOptions';
import { PermissionsLock } from './permissionsLock';
import StartMfgList from './startMfgList';
import { LocationVisiblity } from './locationVisiblity';
import Manufacturer from './manufacturer';
import { DateCellRenderer, getDateComparator, getDateFieldFilterValueGetter, getQuickFilterTextForDateCell } from './dateCellRenderer';
import { SelectedItemsCount } from 'src/features/shared/CustomToolBarPanel';
import { PERMISSION, VISIBLITY } from '../constants';
import { ManufacturerListTableContext } from './context';
import UpdateManufacturerListModal from '../updateManufacturerListModal';
import { ListNameRenderer } from './listNameRenderer';
import { useCompanyMfgQuery, useDeleteMfgListMutation, useDuplicateMfgListMutation, useGetListOfManufactuerListQuery } from '../../queries';
import { useCompany } from '../../../UserManagement/queries';
import { CompanyMfgDetailsResponse, ManufacturerListInfo } from '../../queries/apiTypes';
import { ICompanyLocation } from '../../../UserManagement/queries/apiTypes';
import { loadingOverlayComponent, NoRowsOverlayComponent } from 'src/features/BOM/shared/commonTemplates';
import { useSnackbar } from 'src/features/shared/hooks/useSnackbar';
import { useGetUserProfileInfoQuery } from '../../../MyProfile/queries';
import DeleteConfirmationDialog from 'src/features/shared/DeleteConfirmationDialog';

const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    editable: false,
    filter: false,
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: []
};

const columnDefs: ColDef[] = [
    {
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,
        width: 52,
        minWidth: 52,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned: 'left',
        editable: false,
        resizable: false
    },
    {
        width: 52,
        minWidth: 52,
        editable: false,
        resizable: false,
        cellRenderer: StartMfgList
    },
    {
        width: 52,
        minWidth: 52,
        editable: false,
        resizable: false,
        cellRenderer: PermissionsLock
    },
    {
        headerName: 'Manufacturer List Name',
        field: 'manufacturerListName',
        cellRenderer: ListNameRenderer,
        width: 280,
        minWidth: 280,
        resizable: true
    },
    { headerName: 'Description', field: 'description', width: 214, minWidth: 214, resizable: true },
    {
        headerName: 'Manufacturers',
        field: 'manufacturers',
        width: 284,
        minWidth: 284,
        resizable: true,
        cellRenderer: Manufacturer,
        getQuickFilterText: (params) => {
            return params.data?.manufacturers?.join(' ') || '';
        }
    },
    {
        headerName: 'Visibility',
        cellRenderer: LocationVisiblity,
        width: 167,
        minWidth: 167,
        resizable: true,
        getQuickFilterText: (params) => {
            const visiblity = params.data.visibility;
            const searchString = visiblity === VISIBLITY.PRIVATE || visiblity === VISIBLITY.PUBLIC ? visiblity : params.data.locationAccess?.join(' ') || '';
            return searchString;
        }
    },
    {
        headerName: 'Created',
        field: 'createdAt',
        width: 220,
        minWidth: 220,
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        cellRenderer: DateCellRenderer,
        filterValueGetter: getDateFieldFilterValueGetter,
        getQuickFilterText: getQuickFilterTextForDateCell,
        comparator: getDateComparator,
        menuTabs: ['filterMenuTab']
    },
    {
        headerName: 'Last Modified',
        field: 'updatedAt',
        width: 180,
        minWidth: 180,
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterValueGetter: getDateFieldFilterValueGetter,
        cellRenderer: DateCellRenderer,
        getQuickFilterText: getQuickFilterTextForDateCell,
        comparator: getDateComparator,
        menuTabs: ['filterMenuTab']
    },
    {
        minWidth: 50,
        maxWidth: 50,
        cellStyle: (params: any): CellStyle => {
            if (params.node.rowPinned === 'bottom') {
                return { border: 'none' };
            }
            return { backgroundColor: 'unset' };
        },
        editable: false,
        cellRenderer: MenuOptions,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        pinned: 'right',
        resizable: false
    }
];

interface TableRowDataType {
    id: number;
    manufacturerListName: string;
    description: string | null;
    manufacturers: (string | null)[];
    visibility: string;
    locationAccess: string[] | undefined;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    permission: string;
}

export default function ManufacturerListTable() {
    const [selectedRows, setSelectedRows] = useState<Array<TableRowDataType>>([]);
    const tableRef = useRef<AgGridReact>(null);
    const [searchVal, setSearchVal] = useState('');
    const [editId, setEditId] = useState<null | number>(null);
    const [openCreateEditModal, setOpenCreateEditModal] = useState(false);
    const [gridReady, setGridReady] = useState(false);
    const [deletePopupInfo, setDeletePopupInfo] = useState<{
        showDeletePopup: boolean;
        deleteIds: Array<number>;
    }>({ showDeletePopup: false, deleteIds: [] });

    const { data: manufacturerListData, isFetching: isMfgListDataLoading } = useGetListOfManufactuerListQuery();
    const { data: allBranchLocations, isLoading: locationsLoading } = useCompany();
    const { data: allManufacturers, isLoading: manufacturersLoading } = useCompanyMfgQuery();
    const { mutateAsync: deleteMfgList, isLoading: deleteMfgListLoading } = useDeleteMfgListMutation();
    const { mutateAsync: duplicateMfgList, isLoading: duplicateMfgListLoading } = useDuplicateMfgListMutation();
    const { data: userProfileData, isLoading: isUserProfileLoading } = useGetUserProfileInfoQuery();
    const isAdmin = userProfileData?.data.role === 'admin';

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const isTableLoading = isMfgListDataLoading || locationsLoading || manufacturersLoading || duplicateMfgListLoading || isUserProfileLoading;

    const manufacturerListDataForTable = useMemo(() => {
        if (!manufacturerListData?.data || !allBranchLocations?.data || !allManufacturers?.data) {
            return null;
        }

        const manufacturerMap = allManufacturers.data.reduce((acc, manufacturer) => {
            acc[manufacturer.company_group_man_id] = manufacturer;
            return acc;
        }, {} as Record<number, CompanyMfgDetailsResponse>);

        const branchLocationMap = allBranchLocations.data.reduce((acc, location) => {
            acc[location.id] = location;
            return acc;
        }, {} as Record<number, ICompanyLocation>);

        handleClearSelection();
        return manufacturerListData.data.map((list: ManufacturerListInfo) => {
            return {
                id: list.id,
                manufacturerListName: list.name,
                description: list.description,
                manufacturers: list.company_group_man_id?.map((companyId) => manufacturerMap[companyId]?.manufacturer_name),
                visibility: list.visibility,
                locationAccess: list.company_id?.map((locationId) => branchLocationMap[locationId]?.name),
                createdAt: list.created_at,
                createdBy: list.created_by,
                updatedAt: list.updated_at,
                updatedBy: list.updated_by,
                permission: list.permission
            };
        });
    }, [manufacturerListData?.data, allBranchLocations?.data, allManufacturers?.data]);

    useEffect(() => {
        if (!gridReady) {
            return;
        }
        if (isTableLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (!isTableLoading && manufacturerListDataForTable && manufacturerListDataForTable.length === 0) {
            setTimeout(() => {
                tableRef?.current?.api?.showNoRowsOverlay();
            });
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isTableLoading, manufacturerListDataForTable, gridReady]);

    function handleCheckboxChange(args: any) {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedRows(() => [...selectedBods]);
    }

    function handleClearSelection() {
        tableRef.current?.api?.deselectAll();
    }

    function onCloseBanner() {
        setSelectedRows([]);
        handleClearSelection();
    }

    function handleManufacturerChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchVal(e.target.value);
    }

    async function handleDelete() {
        await deleteMfgList({ manufacturer_list_id: deletePopupInfo.deleteIds });
        setSelectedRows([]);
        handleClearSelection();
        handleDeletePopupClose();
    }

    function handleMultipleDelete() {
        const selecetedRowsId = selectedRows.map((data) => data.id);
        setDeletePopupInfo({ showDeletePopup: true, deleteIds: selecetedRowsId });
    }

    function handleSingleDelete(id: number) {
        setDeletePopupInfo({ showDeletePopup: true, deleteIds: [id] });
    }

    function handleDuplicate(ids: Array<number>) {
        duplicateMfgList({ manufacturer_list_id: ids });
    }

    function handleMultipleDuplicate() {
        const selecetedRowsId = selectedRows.map((data) => data.id);
        handleDuplicate(selecetedRowsId);
        setSelectedRows([]);
        handleClearSelection();
    }

    function handleSingleEdit(id: number) {
        handleEdit(id);
    }

    function handleSingleDuplicate(id: number) {
        handleDuplicate([id]);
    }

    function handleEdit(id: number) {
        setEditId(id);
        setOpenCreateEditModal(true);
    }

    function handleModalClose(afterSave?: boolean) {
        setOpenCreateEditModal(false);
        if (afterSave) {
            setSnackbarOpen('Manufacturer List Saved Successfully');
        }
    }

    function handleCreateNewList() {
        setEditId(null);
        setOpenCreateEditModal(true);
    }

    function handleDeletePopupClose() {
        setDeletePopupInfo({ showDeletePopup: false, deleteIds: [] });
    }

    function handleClearFilters() {
        tableRef.current?.api.setFilterModel(null);
    }

    const disableDeleteForUser = !isAdmin && selectedRows.some((data) => data.permission === PERMISSION.ADMIN);

    return (
        <>
            <Snackbar
                open={snackbarInfo.open}
                message={snackbarInfo.message}
                onClose={(_e: any, reason: string) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setSnackbarClose();
                }}
                autoHideDuration={snackbarInfo.autoHideDuration}
            />
            {openCreateEditModal && <UpdateManufacturerListModal open={openCreateEditModal} onClose={handleModalClose} id={editId} />}
            {deletePopupInfo.showDeletePopup && (
                <DeleteConfirmationDialog
                    open={deletePopupInfo.showDeletePopup}
                    title="Are you sure ?"
                    handleDialog={handleDeletePopupClose}
                    confirmAction={handleDelete}
                    loading={deleteMfgListLoading}
                >
                    <BodySmall limit={false}>Are you sure you want to delete the selected Manufacturer List(s)?</BodySmall>
                </DeleteConfirmationDialog>
            )}
            <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" alignItems="center" width="100%" justifyContent="space-between" pb={2}>
                    <Box display="flex" gap={2}>
                        <TextField
                            label=""
                            sx={{ width: 300 }}
                            placeholder="Search..."
                            startIcon={<SearchIcon fontSize="small" />}
                            size="small"
                            onChange={handleManufacturerChange}
                            value={searchVal}
                            name="manufacturer"
                        />
                        <Box>
                            <Tooltip title="Clear Filter(s)">
                                <IconButton onClick={handleClearFilters}>
                                    <FilterAltOffIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {selectedRows.length > 0 && <SelectedItemsCount count={selectedRows.length} closeBanner={onCloseBanner!} />}
                    </Box>
                    <Box display="flex" gap={2}>
                        <Tooltip
                            title={
                                selectedRows.length
                                    ? disableDeleteForUser
                                        ? 'Please deselect any list(s) limited to admin access prior to deletion.'
                                        : 'Remove Manufacturer List(s)'
                                    : 'Select Manufacturer List(s)'
                            }
                            placement={'bottom'}
                        >
                            <Box component="span">
                                <IconButton disabled={!selectedRows.length || disableDeleteForUser} onClick={handleMultipleDelete}>
                                    <DeleteOutlineIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={selectedRows.length ? 'Duplicate Manufacturer List(s)' : 'Select Manufacturer List(s)'} placement={'bottom'}>
                            <Box component="span">
                                <IconButton disabled={!selectedRows.length} onClick={handleMultipleDuplicate}>
                                    <ControlPointDuplicateIcon sx={{ fontSize: 24 }} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Box>
                            <Button onClick={handleCreateNewList}>Create New List</Button>
                        </Box>
                    </Box>
                </Box>
                <Box flex="1" className="ag-theme-alpine">
                    <ManufacturerListTableContext.Provider value={{ handleSingleDelete, handleSingleEdit, handleSingleDuplicate, isAdmin }}>
                        <AgGridReact
                            ref={tableRef}
                            gridOptions={{
                                suppressContextMenu: true,
                                suppressRowClickSelection: true,
                                suppressMenuHide: true,
                                suppressMovableColumns: true,
                                rowSelection: 'multiple'
                            }}
                            quickFilterText={searchVal}
                            quickFilterParser={(quickFilter) => [quickFilter.trim()]}
                            columnDefs={columnDefs}
                            onSelectionChanged={handleCheckboxChange}
                            defaultColDef={defaultColDef}
                            rowData={manufacturerListDataForTable}
                            modules={[GridChartsModule]}
                            loadingOverlayComponent={loadingOverlayComponent}
                            noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                            onGridReady={() => setGridReady(true)}
                            pagination
                            paginationAutoPageSize
                        />
                    </ManufacturerListTableContext.Provider>
                </Box>
            </Box>
        </>
    );
}
