import { forwardRef, useCallback, useMemo, useState, useEffect, useRef, useImperativeHandle, MutableRefObject } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { ColumnMovedEvent, ColumnResizedEvent, ColumnState, RowClassParams, RowGroupOpenedEvent, ICellRendererParams, CellKeyDownEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { GridChartsModule } from '@ag-grid-enterprise/charts';

import { isEqual } from 'lodash-es';

import { Box, Button, Tooltip, Snackbar, AddIcon, EditIcon, BodySmall, TableHeaderMenuIcon, ErrorIcon, Portal } from '@parspec/pixel';

import {
    useAddBomDataMutation,
    useCreateKitMutation,
    useApproveProductOption,
    useDeleteBomDataMutation,
    useDuplicateBomDataMutation,
    useReorderBomDataMutation,
    useToggleHideBomDataMutation,
    useUpdateBomDataMutation,
    useUpdateDatasheetMetaDataMutation,
    saveColumnOrderEvents,
    saveColumnWidthEvents,
    useHighLightBomDataMutation
} from '../queries';
import { BodResponse, SectionApiResponseType } from '../queries/apiTypes';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { addMsg, deleteMsg, duplicateMsg, hideMsg, reorderMsg, someThingWentWrongMsg, REMOVE_PRODUCT_FROM_KIT, HIGHLIGHT_MSG } from '../../shared/constants';
import {
    areYouSure,
    deleteContent,
    deleteDialogTitle,
    deleteMessage,
    deleteTitle,
    getCancelButtonUrl,
    getRowId,
    TextCellParams,
    kitToolbarItems,
    addNewRowsToTheTable,
    handleOnRangeSelectionChanged
} from '../shared/utils';
import { defaultColDef, toolBarOptions, checkIfResfreshParentTable, getIsTableDataUpdated } from './helper';
import { setRouteToLocalStorage } from '../../shared/utils/utils';
import MoveSectionDialog from '../shared/MoveSectionDialog';
import { KIT_ERROR, KIT_ERROR_TYPE, NO_BOD_MSG } from '../shared/constants';
import ErrorDialog from '../../shared/ErrorDialog';
import { SECTION_TYPE } from '../shared/constants';
import AddOptionModal from '../shared/addOptionModal';
import { useGetCustomisedColumnData, useLotMap, useCreateNewBods } from '../shared/hooks';
import { KitMenuOptions, MenuOptions } from '../shared/MenuOptions';
import { MenuOptionsContext, TableContext } from '../shared/context';
import CustomToolBarPanel from '../../shared/CustomToolBarPanel';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../shared/commonTemplates';
import DatasheetDetailGrid from './DatasheetDetailGrid';
import MoveProductDialog from '../shared/MoveProductDialog';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { BooleanObjType } from '../shared/types';
import { ColDef } from 'ag-grid-community';
import AddMultipleRows from '../shared/AddMultipleRows';

interface DatasheetTableProps {
    bomDataLoading?: boolean;
    datasheetDataLoading?: boolean;
    optionSection?: SectionApiResponseType[];
    mainProductOptionsInfo?: Record<number, Array<number>>;
    tableData?: BodResponse[];
    section?: SectionApiResponseType;
    aggregateSelectedItemsFromSections?: (data: any, sectionId: number) => void;
    isOptionTable?: boolean;
    onAddOption?: (args: { sectionId: number; type: string; qty: number; productToReplaceId: number }) => Promise<void>;
    isAddOptionLoading?: boolean;
    isKitTable?: boolean;
    isComponentsTable?: boolean;
    kitTableHeight?: number | string;
    kitData?: any;
    onGridReady?: (params: any) => void;
    tableHeight?: number;
    handleTablesReordering?: (columnOrder: string[]) => void;
    handleTablesWidthChanged?: (targetedColumnId: string, newWidth?: number) => void;
    selectedRowsRef: MutableRefObject<Set<number>>;
}

const DatasheetTable = forwardRef((props: DatasheetTableProps, ref: any) => {
    const {
        bomDataLoading,
        datasheetDataLoading,
        tableData,
        section,
        aggregateSelectedItemsFromSections,
        kitTableHeight,
        optionSection,
        isOptionTable = false,
        mainProductOptionsInfo,
        onAddOption,
        isAddOptionLoading = false,
        isKitTable,
        isComponentsTable,
        kitData,
        onGridReady,
        tableHeight,
        handleTablesReordering,
        handleTablesWidthChanged,
        selectedRowsRef
    } = props;
    const navigate = useNavigate();
    const { bomId = '0', projectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const productId = Number(searchParams.get('productId'));

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [movingIds, setMovingIds] = useState<number[]>([]);
    const [isAddOptionModalOpen, setAddOptionModalOpen] = useState(false);
    const [bodInfoForAddOption, setBodInfoForAddOption] = useState<{ type: string; qty: number; productToReplaceId: number } | undefined>();
    const [isLotSelectedForMovingProducts, setLotSelectedForMovingProducts] = useState(false);
    const [rowData, setRowData] = useState<any[]>();
    const [searchText, setSearchText] = useState('');
    const [kitCreationError, setKitCreationError] = useState<KIT_ERROR_TYPE>();
    const expandedRowsContainerRef = useRef<Array<number>>([]);
    const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
    const [openMoveProductDialog, setOpenMoveProductDialog] = useState(false);
    const [currentBodId, setCurrentBodId] = useState<number>();
    const [tableApi, setTableApi] = useState<any>(null);
    const [detailGridContainerHeight, setDetailGridContainerHeight] = useState(0);
    const [kitsExpansionObj, setKitsExpansionObj] = useState<BooleanObjType>({});
    const tableRef = useRef<any>();

    const { createNewRowsFromClipboard, isLoading: isLoadingNewBods } = useCreateNewBods(bomId);

    const updateDetailGridContainerHeight = useCallback((value: number) => {
        setDetailGridContainerHeight(value);
    }, []);

    const lotMap = useLotMap(Number(bomId));

    const { columnOrderDetails, isColumnDataLoading } = useGetCustomisedColumnData(bomId);

    const { data: userData } = useGetUserProfileInfoQuery();

    const { mutateAsync: reorderData } = useReorderBomDataMutation();

    const { mutateAsync: deleteData, isLoading: deleteLoading } = useDeleteBomDataMutation();

    const { mutateAsync: duplicateData, isLoading: duplicateLoading } = useDuplicateBomDataMutation(bomId);

    const { mutateAsync: toggleHideData, isLoading: hideLoading } = useToggleHideBomDataMutation();

    const { mutateAsync: toggleHighLightData, isLoading: highLightLoading } = useHighLightBomDataMutation();

    const { mutateAsync: addData, isLoading: addDataLoading } = useAddBomDataMutation(bomId);

    const { mutateAsync: updateData } = useUpdateBomDataMutation();

    const { mutateAsync: updateMetadata } = useUpdateDatasheetMetaDataMutation();

    const { mutateAsync: createKit, isLoading: createKitLoading } = useCreateKitMutation(Number(bomId || 0));

    const { mutateAsync: postApproveOption, isLoading: isApproveLoading } = useApproveProductOption();

    const noOfOptionSections = useMemo(() => optionSection?.length || 0, [optionSection]);

    useImperativeHandle(
        ref,
        () => {
            return tableRef;
        },
        []
    );

    useEffect(() => {
        if (
            !rowData ||
            (rowData && tableData && rowData.length !== tableData.length) ||
            (!isComponentsTable && tableData && checkIfResfreshParentTable(tableData, rowData)) ||
            (rowData && tableData && getIsTableDataUpdated(tableData, rowData))
        ) {
            setRowData(tableData as any);
        }
    }, [tableData]);

    useEffect(() => {
        if (productId && !showLoader && isEqual(tableData, rowData)) {
            const idx = rowData?.findIndex((item) => item.id === productId);
            tableRef?.current?.api?.ensureIndexVisible?.(idx, 'top');
            searchParams.delete('productId');
            setSearchParams(searchParams);
        }
    }, [productId, rowData, tableData]);

    useEffect(() => {
        setKitsExpansionObj(JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'));
    }, []);

    const handleClearSelection = () => {
        tableRef.current?.api?.deselectAll();
    };

    const onCheckboxChange = (args: any) => {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        aggregateSelectedItemsFromSections?.(selectedBods, section?.id || 0);
        setSelectedItems(() => [...selectedBods]);
    };

    const onReorderTableData = async (arg: any) => {
        const updatedRowData: any = [];
        arg.api.forEachNode((node: any) => {
            updatedRowData.push({ ...node.data });
        });
        const bodIds = arg.nodes.map((item: any) => item.data.id);
        const overIndex = updatedRowData.findIndex((item: any) => item?.id === bodIds[0]);
        const prevBodId = overIndex === 0 ? (isKitTable ? kitData?.id : null) : updatedRowData[overIndex - 1].id;
        const input = { previous_bod_id: prevBodId, bod_id: bodIds };
        await reorderData({ bomId: bomId!, input });
        setSnackbarMessage(reorderMsg);
    };

    const onAddBelow = async (props: any) => {
        const { id } = props?.data || {};
        const item = {
            category: '',
            qty: null,
            manufacturer: '',
            model_number: '',
            previous_bod_id: id || kitData?.id,
            section_id: section?.id,
            section_type: isOptionTable ? SECTION_TYPE.OPTION : SECTION_TYPE.MAIN,
            ...(isKitTable ? { kit_id: kitData?.id } : {})
        };
        await addData({ bomId: bomId!, input: item });
        setSnackbarMessage(addMsg);
    };

    const onAdd = () => {
        const id = tableData?.length ? tableData?.[tableData?.length - 1].id : null;
        onAddBelow({ data: { id } });
    };

    const onHide = async (props: any) => {
        const { id } = props.data;
        await toggleHideData({
            bomId: bomId!,
            input: [id]
        });
        setSnackbarMessage(hideMsg);
    };

    const onHideMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        await toggleHideData({
            bomId: bomId!,
            input
        });
        handleClearSelection();
        setSnackbarMessage(hideMsg);
    };

    const onDuplicate = async (props: any) => {
        const { id } = props.data;
        await duplicateData({
            bomId: bomId!,
            input: [id]
        });
        setSnackbarMessage(duplicateMsg);
    };

    const onDuplicateMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        await duplicateData({
            bomId: bomId!,
            input
        });
        handleClearSelection();
        setSnackbarMessage(duplicateMsg);
    };

    const onClickDelete = (props: any) => {
        setDeleteIds([props?.data?.id]);
        setOpenConfirmationDialog(true);
    };

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const onDeleteBulk = async (clearSelection?: boolean) => {
        await deleteData({ bomId: bomId!, ids: deleteIds });
        setDeleteIds([]);
        closeConfirmationDialog();
        setSnackbarMessage(deleteMsg);
        if (clearSelection) handleClearSelection();
    };

    const onClickMultipleDelete = () => {
        const ids = selectedItems.map((item: any) => item.id);
        setDeleteIds(ids);
        setOpenConfirmationDialog(true);
    };

    const editBomData = async ({ data, column, newValue }: any) => {
        const value = newValue ? (typeof newValue === 'string' ? newValue.trim().toUpperCase() : newValue) : column.colId === 'qty' ? null : '';
        try {
            await updateData({
                bomId: bomId!,
                input: { id: data.id, [column.colId]: value }
            });
        } catch (e) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const editMetadata = async (props: any) => {
        const { data, newValue } = props;
        try {
            await updateMetadata({
                bomId: bomId!,
                bodId: data.id,
                notes: newValue ? newValue?.trim().toUpperCase() : ''
            });
        } catch (e) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onCellEdit = async (props: any) => {
        const { column, newValue, data } = props;
        if (column.colId === 'notes') {
            editMetadata({ data, column, newValue });
        } else {
            editBomData({ data, column, newValue });
        }
    };

    const onClickMoveSection = (props: any) => {
        setMovingIds([props.data.id]);
        const isLotPresentInMovingBod = checkIfProductsHasLots([props.data.id]);
        setLotSelectedForMovingProducts(isLotPresentInMovingBod);
        toggleMoveSectionDialog();
    };

    const toggleMoveSectionDialog = () => setOpenMoveDialog((old) => !old);

    const onMoveMultipleItems = () => {
        const ids = selectedItems.map((item: any) => item.id);
        const isLotPresentInMovingBod = checkIfProductsHasLots(ids);
        setLotSelectedForMovingProducts(isLotPresentInMovingBod);
        setMovingIds(ids);
        toggleMoveSectionDialog();
    };

    const updateKitCreationError = (error: KIT_ERROR_TYPE.KIT | KIT_ERROR_TYPE.OPTION | undefined) => setKitCreationError(error);

    const onCreateKit = async () => {
        const isKitSelected = selectedItems.some((item) => item.is_kit);
        if (isKitSelected) {
            return updateKitCreationError(KIT_ERROR_TYPE.KIT);
        }
        if (!isOptionTable) {
            let hasOptions = false;
            selectedItems.forEach((item) => {
                if (mainProductOptionsInfo?.[item.id]?.length) {
                    hasOptions = true;
                }
            });
            if (hasOptions) {
                return updateKitCreationError(KIT_ERROR_TYPE.OPTION);
            }
        }
        const id = tableData?.length ? tableData?.[tableData?.length - 1].id : null;
        const inputData = {
            bomId: Number(bomId),
            input: {
                category: '',
                qty: null,
                manufacturer: '',
                model_number: '',
                previous_bod_id: id,
                section_id: section?.id,
                components: selectedItems.map((item) => item.id),
                section_type: isOptionTable ? SECTION_TYPE.OPTION : SECTION_TYPE.MAIN
            }
        };

        await createKit(inputData);
        handleClearSelection();
    };

    const redirectToMatchDatasheet = (id: number) => {
        setRouteToLocalStorage(getCancelButtonUrl(projectId, bomId, 'dataSheets', `${id}`));
        navigate(`/v2/project/${projectId}/bom/${bomId}/product/${id}/submittal/datasheet/match`);
    };

    const redirectToAnnotateDatasheet = (id: number) => {
        setRouteToLocalStorage(getCancelButtonUrl(projectId, bomId, 'dataSheets', `${id}`));
        navigate(`/v2/project/${projectId}/bom/${bomId}/product/${id}/submittal/datasheet/annotate`);
    };

    const getDatasheetContent = useCallback((props: any) => {
        const { selected_datasheet, id, all_pages_hidden } = props?.data || {};
        const status = selected_datasheet?.document_selection_status;
        return (
            <>
                {status === 'CONFIRMED' ? (
                    <Box display="flex" alignItems="center">
                        <Tooltip title={all_pages_hidden ? 'No pages recognized for this product. Click to edit.' : ''}>
                            <Box>
                                <Button color={all_pages_hidden ? 'warning' : 'success'} startIcon={all_pages_hidden && <ErrorIcon />} size="small" onClick={() => redirectToAnnotateDatasheet(id)}>
                                    {all_pages_hidden ? 'No Pages' : 'Selected'}
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box>
                        <Button size="small" startIcon={<AddIcon />} onClick={() => redirectToMatchDatasheet(id)} variant="outlined" color="secondary">
                            Add
                        </Button>
                    </Box>
                )}
            </>
        );
    }, []);

    const getAnnotateContent = useCallback((props: any) => {
        const { selected_datasheet, id } = props?.data || {};
        const status = selected_datasheet?.annotations_status;
        if (status === 'CONFIRMED') {
            return (
                <Button color="success" size="small" onClick={() => redirectToAnnotateDatasheet(id)}>
                    Confirmed
                </Button>
            );
        }
        if (status === 'IN_PROGRESS') {
            return (
                <Tooltip title="There are unconfirmed annotations on this document.">
                    <span>
                        <Button color="tertiary" size="small" onClick={() => redirectToAnnotateDatasheet(id)}>
                            In Progress
                        </Button>
                    </span>
                </Tooltip>
            );
        }

        return (
            <Button
                onClick={() => {
                    if (selected_datasheet?.document_selection_status === 'CONFIRMED') {
                        redirectToAnnotateDatasheet(id);
                    } else redirectToMatchDatasheet(id);
                }}
                size="small"
                startIcon={<EditIcon />}
                variant="outlined"
                color="secondary"
            >
                Annotate
            </Button>
        );
    }, []);

    function checkIfProductsHasLots(ids: Array<number>) {
        const idsToCheck = new Set(ids);
        const isLotPresentInBods = rowData?.some((row) => idsToCheck.has(row.id) && Boolean(lotMap[row.id])) || false;
        return isLotPresentInBods;
    }

    async function handleAddOptionConfirm(sectionId: number) {
        const { qty = 0, type = '', productToReplaceId = 0 } = bodInfoForAddOption || {};
        await onAddOption?.({ sectionId, qty, type, productToReplaceId });
        setAddOptionModalOpen(false);
    }

    async function handleAddOption(props: any) {
        const { data } = props;
        const currentType = data.category?.trim();
        const optionType = currentType ? currentType + ' - Alt' : '';
        const qty = data.qty;
        const productToReplaceId = data.id;
        if (noOfOptionSections === 1) {
            const sectionId = optionSection?.[0].id || 0;
            await onAddOption?.({ type: optionType, qty, productToReplaceId, sectionId });
        } else {
            setBodInfoForAddOption({ type: optionType, qty, productToReplaceId });
            setAddOptionModalOpen(true);
        }
        handleClearSelection();
    }

    async function approveOption(productId: number) {
        await postApproveOption({ bomId: Number(bomId), data: { bod_id: [productId] } });
    }

    function handleAddOptionClose() {
        setAddOptionModalOpen(false);
    }
    const defaultLockColumnDef: any = [
        {
            field: 'id',
            hide: true,
            resizable: false
        },
        { rowDrag: true, width: 40, maxWidth: 40, resizable: false, hide: isKitTable && !isComponentsTable },
        { headerCheckboxSelection: true, checkboxSelection: true, width: 50, maxWidth: 50, resizable: false, hide: isKitTable && !isComponentsTable },
        {
            resizable: false,
            cellRenderer: 'agGroupCellRenderer',
            editable: false,
            cellStyle: { backgroundColor: 'unset', border: 'unset' },
            suppressCellSelection: true,
            minWidth: 50,
            maxWidth: 50,
            hide: isKitTable
        },
        {
            headerComponent: () => (
                <Box mt={1}>
                    <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                </Box>
            ),
            minWidth: 50,
            maxWidth: 50,
            resizable: false,
            cellRenderer: isKitTable ? KitMenuOptions : MenuOptions,
            cellStyle: { backgroundColor: 'unset' },
            pinned: 'right',
            colId: 'menu',
            hide: isKitTable && !isComponentsTable
        }
    ];
    const defaultColumnDef: any = {
        category: {
            field: 'category',
            headerName: 'Type',
            editable: true,
            minWidth: 80,
            valueFormatter: 'value.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        qty: {
            field: 'qty',
            headerName: isComponentsTable ? 'Qty per Kit' : 'Qty',
            editable: true,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 0,
                max: 2147483647,
                preventStepping: true
            },
            type: 'rightAligned',
            minWidth: 100
        },
        manufacturer: {
            field: 'manufacturer',
            headerName: 'Manufacturer',
            editable: true,
            minWidth: 80,
            valueFormatter: 'value.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        model_number: {
            field: 'model_number',
            headerName: 'Model Number',
            editable: true,
            minWidth: 80,
            valueFormatter: 'value.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        notes: {
            field: 'notes',
            headerName: 'Submittal Notes (External)',
            editable: true,
            minWidth: 80,
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        datasheet: { colId: 'datasheet', headerName: 'Datasheet', editable: false, minWidth: 80, maxWidth: 140, cellRenderer: getDatasheetContent, cellStyle: { textOverflow: 'unset' } },
        annotations: {
            colId: 'annotations',
            headerName: 'Annotations',
            editable: false,
            minWidth: 80,
            maxWidth: 150,
            cellRenderer: getAnnotateContent,
            resizable: false,
            cellStyle: { textOverflow: 'unset' }
        }
    };

    const columnDefs = useMemo<ColDef[]>(() => {
        const orderedColumns = columnOrderDetails?.order.reduce((result: ColDef[], field: string) => {
            if (Object.hasOwn(defaultColumnDef, field)) {
                const column = {
                    ...defaultColumnDef[field]
                };

                // Apply width if available in defaultColumnState.width
                if (columnOrderDetails?.width) {
                    const widthField = field as keyof typeof columnOrderDetails.width;
                    column.width = columnOrderDetails?.width[widthField];
                }

                result.push(column);
            }

            return result;
        }, []);

        return [...defaultLockColumnDef, ...(orderedColumns || [])];
    }, [isColumnDataLoading, columnOrderDetails]);

    const showLoader =
        bomDataLoading || datasheetDataLoading || deleteLoading || duplicateLoading || hideLoading || addDataLoading || isApproveLoading || createKitLoading || highLightLoading || isLoadingNewBods;

    useEffect(() => {
        if (showLoader) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (tableData && tableData.length === 0) {
            setTimeout(() => {
                tableRef?.current?.api?.showNoRowsOverlay();
            }, 0);
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [showLoader, tableData]);

    const getIfRowMaster = useCallback((dataItem: any) => {
        return dataItem ? dataItem.is_kit : false;
    }, []);

    const onRowGroupOpened = useCallback(
        (e: RowGroupOpenedEvent<any, any>) => {
            const {
                rowIndex,
                node: { expanded, id }
            } = e;
            if (rowIndex !== null) {
                if (expanded) {
                    expandedRowsContainerRef.current.push(rowIndex);
                } else {
                    expandedRowsContainerRef.current.pop();
                }
            }
            setExpandedRows([...expandedRowsContainerRef.current]);
            const obj = { ...JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'), [`${id}`]: expanded };
            setKitsExpansionObj?.(obj);
            localStorage.setItem(`${bomId}-kits`, JSON.stringify(obj));
        },
        [kitsExpansionObj]
    );

    const toggleKits = useCallback(
        (params: any) => {
            if (kitsExpansionObj && Object.keys(kitsExpansionObj).length > 0) {
                Object.keys(kitsExpansionObj).map((id: string) => {
                    const rowNode = params.api.getRowNode(id);
                    if (rowNode) {
                        rowNode.setExpanded(kitsExpansionObj?.[id]);
                    }
                });
            }
        },
        [kitsExpansionObj]
    );

    const detailCellRenderer = useMemo(
        () => (props: ICellRendererParams<any, any, any>) => <DatasheetDetailGrid {...props} updateDetailGridContainerHeight={updateDetailGridContainerHeight} selectedRowsRef={selectedRowsRef} />,
        []
    );

    function handleOnGridReady(params: any) {
        setTableApi(params.api);
        if (onGridReady) {
            onGridReady(params);
        }
    }

    const handleMoveProductDialog = useCallback(() => {
        setOpenMoveProductDialog(false);
    }, []);

    function onProductMoveIn(currentBodId: number) {
        setCurrentBodId(currentBodId);
        setOpenMoveProductDialog(true);
    }

    const getTableMetaData = useCallback(() => {
        return { props: { rowData: tableData }, api: tableApi };
    }, [tableData]);

    async function onProductMoveOut(params: any) {
        const { data } = params;
        await updateData({ bomId: String(bomId), input: { kit: null, id: data.id }, isInvalidateBodList: true });
        setSnackbarMessage(REMOVE_PRODUCT_FROM_KIT);
    }

    const addSnackBarMessage = useCallback((message: string) => {
        setSnackbarMessage(message);
    }, []);

    const handleColumnMoved = async (e: ColumnMovedEvent) => {
        const newColumnOrder = e.api.getColumnState().map((column: ColumnState) => column.colId);
        const exceptDefaultColumns = newColumnOrder.slice(defaultLockColumnDef.length);
        handleTablesReordering?.(exceptDefaultColumns);
        const userId = userData?.data?.id;
        const newArraySet = new Set(exceptDefaultColumns);
        const sortedArray: string[] = [...exceptDefaultColumns];
        if (columnOrderDetails && columnOrderDetails.order) {
            sortedArray.push(...columnOrderDetails.order.filter((item: string) => !newArraySet.has(item)));
        }
        if (e.finished && e.source === 'uiColumnMoved') {
            await saveColumnOrderEvents(userId, sortedArray);
        }
    };

    const handleColumnResized = async (props: ColumnResizedEvent) => {
        const colId: any = props?.column?.getColId();
        const newChangedWidth = props?.column?.getActualWidth();
        const currentWidths = columnOrderDetails?.width;
        const columnState = props.api.getColumnState();
        const ignorePinnedColumnState = columnState.slice(defaultLockColumnDef.length - 1);
        const columnWidths = ignorePinnedColumnState.reduce((acc: any, item) => {
            acc[item.colId] = item.width;
            return acc;
        }, {});
        const newWidths = { ...currentWidths, ...columnWidths };

        handleTablesWidthChanged?.(colId, newChangedWidth);
        const userId = userData?.data?.id;
        if (props.finished && props.source === 'uiColumnResized' && userId) {
            await saveColumnWidthEvents(userId, newWidths);
        }
    };

    const onHighlightMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        onHighlight(input);
        handleClearSelection();
    };
    const onHighlight = async (ids: number[]) => {
        await toggleHighLightData({
            bomId: bomId!,
            input: ids
        });
        setSnackbarMessage(HIGHLIGHT_MSG);
    };

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event || e.event.defaultPrevented) {
            return;
        }
        // stop the default behavior of the event. While adding a row in the component table, the event must not trigger onAdd() function call for the kit table
        isComponentsTable && e.event.preventDefault();
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        if ((keyboardEvent.ctrlKey || keyboardEvent.metaKey) && keyboardEvent.key === 'i') {
            onAddBelow(e);
        }
    }, []);

    const handleProcessCellPasteForQty = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column.getColId();

        addNewRowsToTheTable(selectedRowsRef, tableRef, fieldName, createNewRowsFromClipboard);

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        }
        return value;
    };

    const currentTableHeight = isComponentsTable ? kitTableHeight : tableHeight! + 6;
    const calculatedTableHeight = !isComponentsTable && expandedRows.length ? (currentTableHeight as number) + detailGridContainerHeight : currentTableHeight;

    return (
        <>
            <Portal>
                <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            </Portal>
            {isAddOptionModalOpen && <AddOptionModal open={isAddOptionModalOpen} onConfirm={handleAddOptionConfirm} onClose={handleAddOptionClose} isAddOptionInProgress={isAddOptionLoading} />}
            <MenuOptionsContext.Provider
                value={{
                    noOfOptionSections,
                    isOptionTable,
                    handleAddOption,
                    onClickMoveSection,
                    onHide,
                    onDuplicate,
                    approveOption,
                    onClickDelete,
                    bomId,
                    onAddBelow,
                    onProductMoveIn,
                    onProductMoveOut,
                    onHighlight
                }}
            >
                <TableContext.Provider value={{ section, isOptionTable }}>
                    <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                        <CustomToolBarPanel
                            toolBarOptions={isKitTable ? (isComponentsTable ? kitToolbarItems : []) : (toolBarOptions as any)}
                            selectedRowCount={selectedItems.length}
                            disabledToolBarButton={!selectedItems.length}
                            onAdd={onAdd}
                            onDelete={onClickMultipleDelete}
                            onHideUnhide={onHideMultiple}
                            onAddDuplicates={onDuplicateMultiple}
                            onMove={onMoveMultipleItems}
                            onCreateKit={onCreateKit}
                            onCloseBanner={handleClearSelection}
                            onHighlight={onHighlightMultiple}
                            onTextSearch={(value) => setSearchText(value)}
                            isKitSelected={selectedItems.some((item) => item.is_kit)}
                        />

                        <Box mt={2} style={{ height: calculatedTableHeight }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={tableRef}
                                rowData={rowData}
                                rowHeight={40}
                                defaultColDef={defaultColDef}
                                getRowId={getRowId}
                                gridOptions={{
                                    enableRangeSelection: true,
                                    enableFillHandle: true,
                                    undoRedoCellEditing: true,
                                    enterNavigatesVerticallyAfterEdit: true,
                                    enterNavigatesVertically: true,
                                    rowDragManaged: true,
                                    suppressRowClickSelection: true,
                                    suppressContextMenu: true,
                                    stopEditingWhenCellsLoseFocus: true,
                                    suppressLastEmptyLineOnPaste: true,
                                    suppressDragLeaveHidesColumns: true,
                                    rowClassRules: {
                                        'row-hide': (params: any) => params?.data?.is_hidden,
                                        'e-highlightscroll': (params: any) => (productId && params?.data?.id === productId ? true : false),
                                        'row-highlight': (params: RowClassParams) => (params?.data?.is_kit || isComponentsTable ? false : params?.data?.is_highlighted),
                                        'e-rowhighlight-highlighscroll': (params: RowClassParams) => (productId && params?.data?.id === productId && params?.data?.is_highlighted ? true : false)
                                    }
                                }}
                                columnDefs={columnDefs}
                                modules={[ClipboardModule, GridChartsModule]}
                                onRowDragEnd={onReorderTableData}
                                rowSelection="multiple"
                                rowDragMultiRow={true}
                                onSelectionChanged={onCheckboxChange}
                                onCellValueChanged={onCellEdit}
                                quickFilterText={searchText}
                                loadingOverlayComponent={showLoader ? loadingOverlayComponent : null}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} helperText={NO_BOD_MSG} />}
                                processCellFromClipboard={handleProcessCellPasteForQty}
                                masterDetail
                                detailCellRenderer={detailCellRenderer}
                                reactiveCustomComponents
                                isRowMaster={getIfRowMaster}
                                detailRowAutoHeight
                                onRowGroupOpened={onRowGroupOpened}
                                onGridReady={handleOnGridReady}
                                onColumnMoved={handleColumnMoved}
                                onColumnResized={handleColumnResized}
                                onRowDragLeave={onReorderTableData}
                                onCellKeyDown={onCellKeyDown}
                                onFirstDataRendered={toggleKits}
                                onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsRef)}
                            />
                        </Box>
                        {!isKitTable && <AddMultipleRows sectionId={section?.id || 0} isOptionTable={isOptionTable} />}
                    </Box>
                </TableContext.Provider>
            </MenuOptionsContext.Provider>
            {openConfirmationDialog && (
                <DeleteConfirmationDialog
                    title={deleteIds.length > 1 ? deleteDialogTitle : areYouSure}
                    open={openConfirmationDialog}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={() => onDeleteBulk(true)}
                    loading={deleteLoading}
                >
                    {isKitTable ? (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                {deleteContent}
                            </BodySmall>
                        </Box>
                    ) : (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                <p style={{ whiteSpace: 'pre-line' }}>{deleteMessage(mainProductOptionsInfo!, deleteIds)}</p>
                            </BodySmall>
                        </Box>
                    )}
                </DeleteConfirmationDialog>
            )}
            {openMoveDialog && (
                <MoveSectionDialog
                    open={openMoveDialog}
                    isLotsPresentInBods={isLotSelectedForMovingProducts}
                    handleDialog={toggleMoveSectionDialog}
                    movingIds={movingIds}
                    handleClearSelection={handleClearSelection}
                    currentSection={section}
                    mainProductOptionsInfo={mainProductOptionsInfo}
                />
            )}
            {openMoveProductDialog && currentBodId && (
                <MoveProductDialog
                    open={openMoveProductDialog}
                    handleClose={handleMoveProductDialog}
                    currentBodId={currentBodId}
                    getTableMetaData={getTableMetaData}
                    sectionId={section!.id}
                    setSnackbarOpen={addSnackBarMessage}
                    isOptionTable={isOptionTable}
                />
            )}
            {kitCreationError && (
                <ErrorDialog
                    open={Boolean(kitCreationError)}
                    handleDialog={() => updateKitCreationError(undefined)}
                    title="Unable to Create Kit"
                    text={KIT_ERROR[kitCreationError].text}
                    helperText={KIT_ERROR[kitCreationError].helperText}
                />
            )}
        </>
    );
});

export default DatasheetTable;
