import { Button } from '@parspec/pixel';

import { getRouteFromLocalStorage } from './utils/utils';
import { useParspecNavigation } from '../../app/Context';

interface ICancelButton {
    label?: string;
}

const CancelButton: React.FC<ICancelButton> = ({ label = 'Cancel' }) => {
    const { pNavigate } = useParspecNavigation();

    function onClickHandler() {
        const parentRoute = getRouteFromLocalStorage() || '/v2/dashboard';
        pNavigate(parentRoute);
    }

    return (
        <Button onClick={onClickHandler} color="primary" variant="text">
            {label}
        </Button>
    );
};

export default CancelButton;
