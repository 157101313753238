import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, CustomDateRangePicker, dayjs, FilterAltOffIcon, IconButton, MultiSelect, MultiSelectOptionType, SearchIcon, Skeleton, TextField } from '@parspec/pixel';

import { isEqual } from 'lodash-es';

import { CommonOption, OwnerFilterOption, StageFilterOption } from 'src/features/BidBoard/shared/components/FilterOption';
import { getOwnersFromLocations } from 'src/features/BidBoard/shared/utils';
import { useGetUserProfileInfoQuery } from 'src/features/Settings/MyProfile/queries';
import { CONSTRUCTION_SCOPE_OPTIONS, PROJECT_STAGE_OPTIONS } from 'src/features/shared/constants';
import { useGetAllCompanyDetailsQuery } from 'src/features/shared/CreateAndEditProjectModal/queries';
import { AllCompanyDetailsResponse } from 'src/features/shared/CreateAndEditProjectModal/queries/apiTypes';
import { useGetPersistedFiltersQuery, usePersistedFiltersMutation } from 'src/features/shared/queries';
import { FILTERS_INITIAL_VALUE, FILTER_KEYS } from './constants';
import { PAGE_NAME } from '../../constants';

const Filters: FC = () => {
    const { pathname } = useLocation();
    const pageName = pathname ? PAGE_NAME[pathname] : '';
    const { data: allCompanyDetails, isLoading: allCompaniesLoading } = useGetAllCompanyDetailsQuery();
    const { data: userData } = useGetUserProfileInfoQuery();
    const { data: filtersData, isLoading: filtersLoading } = useGetPersistedFiltersQuery(
        { userId: userData?.data?.id || 0, pageName },
        {
            enabled: Boolean(userData?.data?.id) && Boolean(pathname)
        }
    );
    const { mutate: persistFilters } = usePersistedFiltersMutation();

    const filters = useMemo(() => {
        return filtersData?.table_settings_by_pk?.filters || FILTERS_INITIAL_VALUE;
    }, [filtersData?.table_settings_by_pk?.filters]);

    useEffect(() => {
        if (filtersData?.table_settings_by_pk?.filters && allCompanyDetails?.data) {
            const persistedFilters = { ...filtersData?.table_settings_by_pk?.filters };

            //this is done so that we show updated filter value if the owner name is updated/deleted
            const ownerOptions = getOwnersFromLocations(allCompanyDetails?.data).reduce((acc: any, curr: any) => {
                acc[curr.value] = curr;
                return acc;
            }, {});
            persistedFilters[FILTER_KEYS.OWNERS] = (persistedFilters[FILTER_KEYS.OWNERS] || []).reduce((acc: MultiSelectOptionType[], curr: MultiSelectOptionType) => {
                if (ownerOptions[curr.value]) {
                    acc.push(ownerOptions[curr.value]);
                }
                return acc;
            }, []);

            //this is done so that we show updated filter value if the location name is updated/deleted
            const locationOptions = (allCompanyDetails?.data || []).reduce((acc: any, curr: any) => {
                acc[curr.company_details.company_id] = { label: curr.company_details.company_name, value: curr.company_details.company_id };
                return acc;
            }, {});
            persistedFilters[FILTER_KEYS.BRANCH_LOCATIONS] = (persistedFilters[FILTER_KEYS.BRANCH_LOCATIONS] || []).reduce((acc: MultiSelectOptionType[], curr: MultiSelectOptionType) => {
                if (locationOptions[curr.value]) {
                    acc.push(locationOptions[curr.value]);
                }
                return acc;
            }, []);

            //this is done so that we can save updated values of filter in event service
            if (!isEqual(persistedFilters, filtersData?.table_settings_by_pk?.filters)) {
                persistFilters({ filters: persistedFilters, userId: userData?.data?.id, pageName });
            }
        }
    }, [filtersData, allCompanyDetails]);

    const handleFiltersUpdate = (key: string, value: string | MultiSelectOptionType[] | []) => {
        persistFilters({ filters: { ...filters, [key]: value }, userId: userData?.data?.id, pageName });
    };

    const handleSearch = (value: string) => {
        handleFiltersUpdate(FILTER_KEYS.SEARCH_TEXT, value);
    };

    const handleSelect = (key: string, val: MultiSelectOptionType[]) => {
        handleFiltersUpdate(key, val);
    };

    const handleResetFilters = () => {
        persistFilters({ filters: { ...FILTERS_INITIAL_VALUE, [FILTER_KEYS.DATE_RANGE]: [] }, userId: userData?.data?.id, pageName });
    };

    const handleDateRangeChange = (val: any) => {
        const resetDate = val.every((item: any) => item === null);
        if (resetDate) {
            handleFiltersUpdate(FILTER_KEYS.DATE_RANGE, []);
        } else {
            const newVals = val.filter((item: any) => item && item.format() !== 'Invalid Date').map((item: any) => item.format());

            if (newVals.length === 2) {
                handleFiltersUpdate(FILTER_KEYS.DATE_RANGE, newVals);
            }
        }
    };

    const ownerOptions = useMemo(() => {
        if (allCompanyDetails?.data) {
            return getOwnersFromLocations(allCompanyDetails?.data, true);
        }
        return [];
    }, [filters, allCompanyDetails]);

    const branchLocationOptions = useMemo(() => {
        return (allCompanyDetails?.data || []).map((item: AllCompanyDetailsResponse) => ({ label: item.company_details.company_name, value: item.company_details.company_id }));
    }, [allCompanyDetails?.data]);

    const dateRangeValue = useMemo(() => {
        return filters?.[FILTER_KEYS.DATE_RANGE].length ? filters?.[FILTER_KEYS.DATE_RANGE]?.map((item: string) => dayjs(item)) : [null, null];
    }, [filters?.[FILTER_KEYS.DATE_RANGE]]);

    return (
        <>
            {allCompaniesLoading || filtersLoading ? (
                <Box display="flex" gap={2} flexWrap="wrap">
                    <Skeleton width={200} height={36} variant="rectangular" />
                    <Skeleton width={280} height={36} variant="rectangular" />
                    <Skeleton width={220} height={36} variant="rectangular" />
                    <Skeleton width={220} height={36} variant="rectangular" />
                    <Skeleton width={220} height={36} variant="rectangular" />
                    <Skeleton width={220} height={36} variant="rectangular" />
                </Box>
            ) : (
                <Box display="flex" gap={3} flexWrap="wrap">
                    <Box minWidth={200} maxWidth={200}>
                        <TextField
                            label=""
                            placeholder="Search..."
                            startIcon={<SearchIcon fontSize="small" />}
                            size="small"
                            value={filters?.[FILTER_KEYS.SEARCH_TEXT]}
                            onChange={(e) => handleSearch(e.target.value)}
                            sx={{ bgcolor: 'secondary.contrastText' }}
                        />
                    </Box>

                    <Box minWidth={280} maxWidth={280}>
                        <CustomDateRangePicker
                            size="small"
                            sx={{ bgcolor: 'secondary.contrastText', width: '100%' }}
                            label="Date Range"
                            format="MMM DD, YYYY"
                            value={dateRangeValue}
                            onAccept={handleDateRangeChange}
                        />
                    </Box>

                    <Box
                        minWidth={220}
                        maxWidth={220}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '40px !important'
                            },
                            '& .MuiChip-root': {
                                maxWidth: '110px !important'
                            }
                        }}
                    >
                        <MultiSelect
                            value={filters?.[FILTER_KEYS.PROJECT_STAGES]}
                            label="Project Stage"
                            options={PROJECT_STAGE_OPTIONS.map(({ label, value }: { label: string; value: string }) => ({ label, value }))}
                            onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.PROJECT_STAGES, val)}
                            size="small"
                            limitTags={1}
                            multiple
                            sx={{ bgcolor: 'secondary.contrastText' }}
                            customRow={StageFilterOption}
                            shouldSortOptions={false}
                        />
                    </Box>

                    <Box
                        minWidth={220}
                        maxWidth={220}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '40px !important'
                            },
                            '& .MuiChip-root': {
                                maxWidth: '110px !important'
                            }
                        }}
                    >
                        <MultiSelect
                            value={filters?.[FILTER_KEYS.BRANCH_LOCATIONS]}
                            label="Branch Location"
                            options={branchLocationOptions}
                            onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.BRANCH_LOCATIONS, val)}
                            size="small"
                            limitTags={1}
                            multiple
                            sx={{ bgcolor: 'secondary.contrastText' }}
                            customRow={CommonOption}
                        />
                    </Box>

                    <Box
                        minWidth={220}
                        maxWidth={220}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '40px !important'
                            },
                            '& .MuiChip-root': {
                                maxWidth: '110px !important'
                            }
                        }}
                    >
                        <MultiSelect
                            value={filters?.[FILTER_KEYS.OWNERS]}
                            label="Quote Owner"
                            options={ownerOptions}
                            onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.OWNERS, val)}
                            size="small"
                            limitTags={1}
                            multiple
                            sx={{ bgcolor: 'secondary.contrastText' }}
                            customRow={OwnerFilterOption}
                        />
                    </Box>

                    <Box
                        minWidth={220}
                        maxWidth={220}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '40px !important'
                            },
                            '& .MuiChip-root': {
                                maxWidth: '110px !important'
                            }
                        }}
                    >
                        <MultiSelect
                            value={filters?.[FILTER_KEYS.MARKET_VERTICALS]}
                            label="Market Vertical(s)"
                            options={CONSTRUCTION_SCOPE_OPTIONS.map(({ id, name }: { id: string; name: string }) => ({ label: name, value: id }))}
                            onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.MARKET_VERTICALS, val)}
                            size="small"
                            limitTags={1}
                            multiple
                            sx={{ bgcolor: 'secondary.contrastText' }}
                            customRow={CommonOption}
                        />
                    </Box>

                    <Box>
                        <IconButton onClick={handleResetFilters}>
                            <FilterAltOffIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Filters;
