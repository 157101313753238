import { useMemo } from 'react';

import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { useUserInfoQuery } from '../../Settings/UserManagement/queries';
import { useGetAuthToken } from 'src/features/Authentication/hooks';

export const useCheckAccessForProductFinderOrQuoting = () => {
    const { token } = useGetAuthToken();

    const { data: userData, isLoading: isUserDataLoading, isFetching: isUserDataFetching } = useGetUserProfileInfoQuery({ enabled: Boolean(token) });
    const { data: allUserInfo, isLoading: isAllUserInfoLoading, isFetching: isAllUserInfoFetching } = useUserInfoQuery({ enabled: Boolean(token) });

    const isLoading = isUserDataLoading || isAllUserInfoLoading;
    const isFetching = isUserDataFetching || isAllUserInfoFetching;

    const result = useMemo(() => {
        const currentUserDetails = allUserInfo?.data.find((item: any) => item.user_details?.id === userData?.data.id);

        const productFinderAccess = currentUserDetails?.user_details ? currentUserDetails.user_details.product_finder_enabled : false;
        const quotingAccess = currentUserDetails?.user_details ? currentUserDetails.user_details.quoting_enabled : true;

        return { productFinderAccess, quotingAccess };
    }, [userData?.data, allUserInfo?.data]);

    return { ...result, isLoading, isFetching };
};
