import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
    confirmAnnotation,
    endPoints,
    getCustomRubberStamp,
    getDatasheet,
    getSelectedDocument,
    getShowSuggestionState,
    getToolPresetStyles,
    saveAnnotation,
    setCustomRubberStamp,
    setShowSuggestionState,
    setToolPresetStyles
} from './apis';
import { updateBomDataSelectionStatus, updateOmDataSelectionStatus } from '../../../queries';
import { queryClient } from 'src/app/queryClient';
import { SubDocumentType } from 'src/features/shared/constants';
import { DatasheetResponse, SaveAndConfirmAnnotationRequest, SaveOrConfirmAnnotationResponse, SelectedDocumentResponse } from './apiTypes';

export const toolPresetKey = [endPoints.toolPresets];
export const customRubberStampKey = [endPoints.customRubberStamps];
export const showSuggestionStateKey = [endPoints.showSuggestionState];
export const selectedDocumentKey = (bom_id: string, bod_id: string, documentType: string, subDocumentType: string) => [endPoints.selectedDocument(bom_id, bod_id, documentType, subDocumentType)];
export const datasheetKey = (bomId: string, bodId: string, selectedDatasheetID: number, datasheetID: number, documentType: string, subDocumentType: string) => [
    endPoints.datasheet(bomId, bodId, selectedDatasheetID, datasheetID, documentType, subDocumentType)
];

export const invalidateGetSelectedDocument = (bomId: string, bodId: string, documentType: string, subDocumentType: string) => {
    queryClient.invalidateQueries(selectedDocumentKey(bomId, bodId, documentType, subDocumentType));
};

export const useGetToolPresetStylesQuery = () => useQuery(toolPresetKey, getToolPresetStyles, { staleTime: 0 });

export const useGetCustomRubberStampQuery = () => useQuery(customRubberStampKey, getCustomRubberStamp);

export const useGetShowSuggestionStateQuery = () => useQuery(showSuggestionStateKey, getShowSuggestionState);

export const useGetSelectedDocumentQuery = (
    bom_id: string,
    bod_id: string,
    documentType: string,
    subDocumentType: string,
    options: Omit<UseQueryOptions<AxiosResponse<SelectedDocumentResponse[]>>, 'queryKey' | 'queryFn'>
) => {
    return useQuery(selectedDocumentKey(bom_id, bod_id, documentType, subDocumentType), () => getSelectedDocument(bom_id, bod_id, documentType, subDocumentType), options);
};

export const useGetDatasheetQuery = (
    bomId: string,
    bodId: string,
    selectedDatasheetID: number,
    datasheetID: number,
    documentType: string,
    subDocumentType: string,
    options: Omit<UseQueryOptions<AxiosResponse<DatasheetResponse>>, 'queryKey' | 'queryFn'>
) => {
    return useQuery(
        datasheetKey(bomId, bodId, selectedDatasheetID, datasheetID, documentType, subDocumentType),
        () => getDatasheet(bomId, bodId, selectedDatasheetID, datasheetID, documentType, subDocumentType),
        options
    );
};
export const useShowSuggestionStateMutation = () => {
    return useMutation(setShowSuggestionState, {
        onSuccess(_data, variables) {
            changeSuggestionState(variables);
        }
    });
};

export const useCustomRubberStampMutation = () => {
    return useMutation(setCustomRubberStamp, {
        onSuccess(_data) {
            changeCustomRubberStamp(_data);
        }
    });
};
export const useToolPresetStylesMutation = () => useMutation(setToolPresetStyles);

export const useSaveAnnotationMutation = () => {
    return useMutation(saveAnnotation, {
        onSuccess(data, variables) {
            const { bomId, bodId, subDocumentType, selectedDocumentId } = variables;
            const { annotations_status } = data.data;
            if (subDocumentType === SubDocumentType.DATASHEET) {
                updateBomDataSelectionStatus({ bomId, bodId, annotationStatus: annotations_status, selectedDocumentId });
            } else {
                updateOmDataSelectionStatus({ bomId, bodId, subDocumentType, annotationStatus: annotations_status, selectedDocumentId });
            }
            changeSelectedDocument(data, variables);
        }
    });
};

export const useConfirmAnnotationMutation = () => {
    return useMutation(confirmAnnotation, {
        onSuccess(data, variables) {
            const { bomId, bodId, subDocumentType, selectedDocumentId } = variables;
            const { annotations_status } = data.data;
            if (subDocumentType === SubDocumentType.DATASHEET) {
                updateBomDataSelectionStatus({ bomId, bodId, annotationStatus: annotations_status, selectedDocumentId });
            } else {
                updateOmDataSelectionStatus({ bomId, bodId, subDocumentType, annotationStatus: annotations_status, selectedDocumentId });
            }
            changeSelectedDocument(data, variables);
        }
    });
};

export const changeSuggestionState = (variables: { annotation_suggestions_status: boolean }) => {
    queryClient.setQueryData(showSuggestionStateKey, (oldData: any) => {
        return {
            ...oldData,
            data: variables
        };
    });
};

export const changeCustomRubberStamp = (updatedData: any) => {
    queryClient.setQueryData(customRubberStampKey, (oldData: any) => {
        return {
            ...oldData,
            data: [updatedData.data]
        };
    });
};

export const changeSelectedDocument = (data: AxiosResponse<SaveOrConfirmAnnotationResponse, any>, variables: SaveAndConfirmAnnotationRequest) => {
    queryClient.setQueryData(selectedDocumentKey(variables.bomId, variables.bodId, variables.documentType, variables.subDocumentType), (oldData: any) => {
        return { ...oldData, data: [{ ...data.data }] };
    });
};
