import { useContext } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Box, Menu } from '@parspec/pixel';

import { ManufacturerListTableContext } from '../context';
import { PERMISSION } from '../../constants';

export default function MenuOptions(props: ICellRendererParams) {
    const { handleSingleDelete, handleSingleDuplicate, handleSingleEdit, isAdmin } = useContext(ManufacturerListTableContext) || {};
    const { data } = props;
    const options = [
        { label: 'Edit', onClick: () => handleSingleEdit?.(data.id), disabled: !(isAdmin || data.permission === PERMISSION.USER) },
        { label: 'Duplicate', onClick: () => handleSingleDuplicate?.(data.id) },
        { label: 'Delete', onClick: () => handleSingleDelete?.(data.id), color: 'error.main', disabled: !(isAdmin || data.permission === PERMISSION.USER) }
    ];
    return (
        <Box display="flex" justifyContent="center">
            <Menu options={options} />
        </Box>
    );
}
