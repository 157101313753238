export const generalTermsAndConditionData = [
    {
        field_name: 'general_tnc_section',
        field_value: '',
        is_active: false
    },
    {
        field_name: 'closing_notes',
        field_value: {
            value: 'This is Closing Notes',
            font: {
                color: '#FF0000',
                type: 'light'
            }
        },
        is_active: true
    },
    {
        field_name: 'project_notes',
        field_value: 'This is project notes',
        is_active: true
    },

    {
        field_name: 'general_tnc',
        field_value: 'this is general tnc',
        is_active: true
    }
];
