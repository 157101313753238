import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BodySmall, Box, Button, Menu, MoreVertIcon, Snackbar } from '@parspec/pixel';

import { CustomerTab } from '../../shared/CustomerTab';
import { CREATE_DOCUMENT_OPTIONS, TAB_VALUES } from './constants';
import { useDuplicateBomDataMutation } from '../../Dashboard/queries';
import { useDeleteBomInProject, useGetProjectBomsQuery } from '../../Project/queries';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { BomModal } from '../../Dashboard/BOMModal';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import { BOM_DELETE_CONFIRMATION_TITLE, BOM_DELETE_HEADING, BOM_DELETE_SUB_HEADING, DUPLICATING_MESSAGE, deleteMsg, duplicateMsg } from '../../shared/constants';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';
import { setRouteToLocalStorage } from 'src/features/shared/utils/utils';

interface DeleteProps {
    id: string;
    isPrimary: boolean;
    bomLength: number;
}

export const CustomerHeaderTabs = () => {
    const { bomId = '', projectId = '' } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [bomModalOpen, setBomModalOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [deleteProps, setDeleteProps] = useState<DeleteProps | null>(null);

    const { data: projectBomsData } = useGetProjectBomsQuery(Number(projectId), {
        enabled: Boolean(projectId)
    });

    const { data: paymentDetail } = useSubscriptionInfo();
    const { mutateAsync: deleteBom, isLoading: deleteLoading } = useDeleteBomInProject(Number(projectId));
    const { mutateAsync: duplicateBom } = useDuplicateBomDataMutation();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const isActiveSubscription = paymentDetail?.data?.active;
    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);
    const handleBOMModalDialog = () => setBomModalOpen((old) => !old);

    const tab = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params?.get('tab');
    }, [location.search]);

    useEffect(() => {
        if (projectBomsData?.data) {
            const bomExists = projectBomsData?.data.some((option) => option.id === Number(bomId));

            if (!bomExists) {
                const primaryBom = projectBomsData?.data.find((option) => option.is_primary);

                if (primaryBom) {
                    navigateTo(primaryBom.id?.toString());
                } else {
                    navigate(`/v2/project/${projectId}`);
                }
            }
        }
    }, [bomId, projectBomsData?.data]);

    const handleTabChange = (tabValue: string) => {
        setRouteToLocalStorage(`/v2/project/${projectId}/bom/${tabValue}?tab=${tab}`);
        navigateTo(tabValue);
    };
    const navigateTo = (bomId?: string) => {
        const url = `/v2/project/${projectId}/bom/${bomId}?tab=${tab}`;
        return navigate(url);
    };

    const handleDelete = async (id: string, isPrimary = false, bomLength = 0) => {
        if (!isPrimary || (isPrimary && bomLength === 1)) {
            setDeleteProps({ id, isPrimary, bomLength });
            setOpenConfirmationDialog(true);
        }
    };

    const handleDuplicate = async (id: string) => {
        setSnackbarMessage(DUPLICATING_MESSAGE);
        await duplicateBom({ bomId: Number(id), projectId: Number(projectId) });
        setSnackbarMessage(duplicateMsg);
    };
    function handleCreateQuote() {
        navigate(`/v2/project/${projectId}/bom/${bomId}/finalDocuments/compile/quote`);
    }
    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const onDelete = async () => {
        const { id, bomLength } = deleteProps || {};

        await deleteBom(Number(id));
        setSnackbarMessage(deleteMsg);

        // navigate if only one bom in bomDetail page
        if (bomLength === 1) {
            return navigate(`/v2/project/${projectId}`);
        }
        setDeleteProps(null);
        closeConfirmationDialog();
    };

    const onCreateDocument = (document: string) => {
        trackIntercomEvent(document === 'submittal' ? 'Create_Submittal' : 'Create_Om');
        navigate(`/v2/project/${projectId}/bom/${bomId}/finalDocuments/compile/${document}`);
    };

    const isPriceAndLeadTimeTab = tab === TAB_VALUES.PRICING_AND_LEAD_TIME;
    const isDatasheetOrAdditionalTab = tab === TAB_VALUES.DATASHEET_TAB_VALUE || tab === TAB_VALUES.OM_DOCUMENTS_TAB_VALUE;
    const buttonWidth = isPriceAndLeadTimeTab ? '275' : isDatasheetOrAdditionalTab ? '305' : '145';
    const isFinalDoc = tab === TAB_VALUES.FINAL_DOCUMENTS_TAB_VALUE;

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Box display="flex" pl={4} pr={isFinalDoc ? 7 : 10} justifyContent={'space-between'} alignItems={'center'}>
                <Box width={`calc(100% - ${buttonWidth}px)`}>
                    <CustomerTab projectId={Number(projectId)} showMenu={true} onTabChange={handleTabChange} defaultSelectedBomId={bomId} onDelete={handleDelete} onDuplicate={handleDuplicate} />
                </Box>
                <Box display="flex" gap={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            if (isActiveSubscription) {
                                handleBOMModalDialog();
                            } else handleSubscriptionPopup();
                        }}
                    >
                        Manage BOMs
                    </Button>
                    {isPriceAndLeadTimeTab && (
                        <Button onClick={handleCreateQuote} color="tertiary" variant="contained">
                            Create Quote(s)
                        </Button>
                    )}
                    {isDatasheetOrAdditionalTab && (
                        <Menu options={CREATE_DOCUMENT_OPTIONS(onCreateDocument)}>
                            <Button endIcon={<MoreVertIcon />}>Create Document</Button>
                        </Menu>
                    )}
                </Box>
            </Box>
            {bomModalOpen && (
                <BomModal
                    open={bomModalOpen}
                    onCloseClick={() => {
                        setBomModalOpen(false);
                    }}
                    onSuccess={handleBOMModalDialog}
                    selectedProjectId={Number(projectId)}
                    selectedBomId={bomId}
                />
            )}
            {openConfirmationDialog && (
                <DeleteConfirmationDialog title={BOM_DELETE_CONFIRMATION_TITLE} open={openConfirmationDialog} handleDialog={closeConfirmationDialog} confirmAction={onDelete} loading={deleteLoading}>
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {BOM_DELETE_HEADING}
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            {BOM_DELETE_SUB_HEADING}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
        </>
    );
};
