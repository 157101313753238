export type AutoCompleteOption =
    | {
          id: number;
          name: string;
      }
    | undefined;

export const SCHEDULE_ALLOWED_FILE_TYPES = ['.pdf', '.xlsx', '.xls', '.xml', '.csv', '.xlsm'];
export const SCHEDULE_ALLOWED_FILE_TYPE_STRING = 'Supported file types: XLS, CSV, PDF, Agile XML';
export const SUBMITTAL_PACKAGE_ALLOWED_FILE_TYPES = ['.pdf'];
export const SUBMITALL_PACKAGE_FILE_TYPE_STRING = 'Supported file types: PDF';
export const SCHEDULE = 'schedule';
export const SUBMITTAL_PACKAGE = 'submittal_package';
export const EXISTING_BOM_OPTION = 'existingBom';

export const IMPORT_OPTIONS = [
    { value: SCHEDULE, label: 'Upload a list of products' },
    {
        value: SUBMITTAL_PACKAGE,
        label: 'Upload an existing submittal package'
    },
    {
        value: EXISTING_BOM_OPTION,
        label: 'From an existing Parspec BOM'
    }
];
