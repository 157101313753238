import {
    LUMEN_UNITS,
    PRODUCT_APPLICATION,
    PRODUCT_CATEGORY_LIST,
    PRODUCT_DIMMING_PROTOCOL,
    PRODUCT_DIMMING_RANGE,
    PRODUCT_ENVIRONMENT,
    PRODUCT_MOUNTING_TYPE,
    PRODUCT_VOLTAGE
} from '../../../shared/utils/constants';
import { MULTI_SELECT_ACTIONS, SLIDER_ACTIONS, MULTI_SELECT_FIELDS_CONSTANT, SELECT_FIELD_CONSTANT, MANUFACTURER_FIELD_CONSTANT, SLIDERFIELD_CONSTANT } from './constants';
import {
    MultiSelectValType,
    MultiSelectActionType,
    ManufacturerDropdownValType,
    ManufacturerActionType,
    RangeSliderValType,
    SliderActionType,
    FieldsNameType,
    ManufacturerNameType,
    MultiSelectFieldsNameType,
    SliderFieldsNameType,
    SelectFieldsNameType,
    SearchImageDataType
} from './types';
import { ProductRequirementsResponse } from './queries/apiTypes';

export interface CompanyInfoResponse {
    manufacturer_name: string;
    parspec_id: string;
    status: string;
    website: string;
    company_group_man_id: number;
    manufacturer_id: number;
    abbreviation: string[];
    user_preferred: boolean;
    preferred_locations: PreferredLocation[];
}

export interface PreferredLocation {
    company_id: number;
    company_name: string;
}

export function multiSelectReducer(prevState: MultiSelectValType, action: MultiSelectActionType) {
    return selectReducer(prevState, action);
}

export function groupSelectReducer(prevState: ManufacturerDropdownValType, action: ManufacturerActionType) {
    return selectReducer(prevState, action);
}

function selectReducer<T extends ManufacturerDropdownValType | MultiSelectValType>(prevState: T, action: T extends ManufacturerDropdownValType ? ManufacturerActionType : MultiSelectActionType) {
    const currentState = { ...prevState };

    if (action.type === MULTI_SELECT_ACTIONS.UPDATE && Array.isArray(action.value)) {
        currentState.values = [...action.value];
        currentState.error = false;
    } else if (action.type === MULTI_SELECT_ACTIONS.CLEAR) {
        currentState.values = [];
    } else if (action.type === MULTI_SELECT_ACTIONS.LOCK && typeof action.value === 'boolean') {
        (currentState as MultiSelectValType).locked = action.value;
    } else if (action.type === MULTI_SELECT_ACTIONS.INIT_SET) {
        return {
            ...(action.value as T)
        };
    } else if (action.type === MULTI_SELECT_ACTIONS.UPDATE_ERROR && typeof action.value === 'boolean') {
        currentState.error = action.value;
    }

    return currentState;
}

export function sliderReducer(prevState: RangeSliderValType, action: SliderActionType) {
    const currentState = { ...prevState };
    if (action.type === SLIDER_ACTIONS.UPDATE && Array.isArray(action.value)) {
        currentState.values = [...action.value];
    } else if (action.type === SLIDER_ACTIONS.CLEAR) {
        currentState.values = [currentState.minVal, currentState.maxVal];
    } else if (action.type === SLIDER_ACTIONS.LOCK && typeof action.value === 'boolean') {
        currentState.locked = action.value;
    } else if (action.type === SLIDER_ACTIONS.UPDATE_MIN_MAX && Array.isArray(action.value)) {
        currentState.minVal = action.value[0];
        currentState.maxVal = action.value[1];
    } else if (action.type === SLIDER_ACTIONS.INIT_SET) {
        return {
            ...(action.value as RangeSliderValType)
        };
    }

    return currentState;
}

export function getOptionsArrayForField(field: FieldsNameType) {
    let options: string[] | undefined;
    switch (field) {
        case MULTI_SELECT_FIELDS_CONSTANT.CATEGORY:
            options = PRODUCT_CATEGORY_LIST;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.APPLICATION:
            options = PRODUCT_APPLICATION;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE:
            options = PRODUCT_MOUNTING_TYPE;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL:
            options = PRODUCT_DIMMING_PROTOCOL;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE:
            options = PRODUCT_DIMMING_RANGE;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE:
            options = PRODUCT_VOLTAGE;
            break;
        case MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT:
            options = PRODUCT_ENVIRONMENT;
            break;
        case SELECT_FIELD_CONSTANT.LUMENS_UNIT:
            return LUMEN_UNITS;
    }
    return () => {
        return options ? options.map((option) => ({ label: option, value: option })) : [];
    };
}

// manufacturer typeguard function
export function getIsManufacturerField(field: FieldsNameType): field is ManufacturerNameType {
    if (field === MANUFACTURER_FIELD_CONSTANT) {
        return true;
    }
    return false;
}

// to be used only with getIsManufacturerField as this is just typeguard method for when field & fieldData are passed to a method as args
export function getIsManufacturerFieldData(
    field: FieldsNameType,
    _fieldData: MultiSelectActionType | ManufacturerActionType | SliderActionType | SearchImageDataType | string
): _fieldData is ManufacturerActionType {
    if (field === MANUFACTURER_FIELD_CONSTANT) {
        return true;
    }
    return false;
}

// multiselect typeguard function
export function getIsMultiselectField(field: FieldsNameType): field is MultiSelectFieldsNameType {
    const isMultiSelectField = Object.values(MULTI_SELECT_FIELDS_CONSTANT).some((element) => element === field);
    if (isMultiSelectField) {
        return true;
    }
    return false;
}

export function getIsSliderField(field: FieldsNameType): field is SliderFieldsNameType {
    const isSliderField = Object.values(SLIDERFIELD_CONSTANT).some((element) => element === field);
    if (isSliderField) {
        return true;
    }
    return false;
}

export function getSelectUnitField(field: FieldsNameType): field is SelectFieldsNameType {
    const isSelectField = Object.values(SELECT_FIELD_CONSTANT).some((element) => element === field);
    if (isSelectField) {
        return true;
    }
    return false;
}

interface Conditions {
    [key: string]: (value: any, minVal?: number, maxVal?: number) => boolean;
}

export function checkIfReqFieldsAreEmpty(data: ProductRequirementsResponse) {
    const conditions: Conditions = {
        product_category: (value: any) => value.length === 0,
        mounting_type: (value: any) => value.length === 0,
        application: (value: any) => value.length === 0,
        environment: (value: any) => value.length === 0,
        dimming_protocol: (value: any) => value.length === 0,
        dimming_range: (value: any) => value.length === 0,
        voltage: (value: any) => value.length === 0,
        lumens: (value: any, minVal?: number, maxVal?: number) => value[0] === minVal && value[1] === maxVal,
        temperature: (value: any, minVal?: number, maxVal?: number) => value[0] === minVal && value[1] === maxVal,
        cri: (value: any, minVal?: number, maxVal?: number) => value[0] === minVal && value[1] === maxVal
    };

    const lumen = { minVal: 0, maxVal: 100000 };
    const cct = { minVal: 0, maxVal: 10000 };
    const cri = { minVal: 0, maxVal: 100 };

    for (const item of data) {
        const condition = conditions[item.field_name];
        if (condition) {
            if (!condition(item.field_value, lumen.minVal, lumen.maxVal) && item.field_name === 'lumens') {
                return false;
            }
            if (!condition(item.field_value, cct.minVal, cct.maxVal) && item.field_name === 'temperature') {
                return false;
            }
            if (!condition(item.field_value, cri.minVal, cri.maxVal) && item.field_name === 'cri') {
                return false;
            }
            if (!['lumens', 'temperature', 'cri'].includes(item.field_name) && !condition(item.field_value)) {
                return false;
            }
        }
    }
    return true;
}
