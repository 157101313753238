export const userDetails = [
  {
    "user_details": {
      "id": 9,
      "name": "Forest Flager",
      "email": "admin@gmail.com",
      "last_activity": "2023-03-29T12:07:00.054097Z",
      "first_name": "Nupur 11 hsbshdsdhsd",
      "last_name": "Pandey 11"
    },
    "roles": [
      {
        "role": "admin"
      }
    ],
    "company_details": [
      {
        "company_id": 259,
        "company_name": "test new location"
      },
    ]
  }
];
