import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Switch, Grid, BodyMedium, BodySmall, SelectChangeEvent } from '@parspec/pixel';

import SwitchTextFiled from '../../shared/SwitchTextField';
import { useDocumentPresetQuery, useDocumentTextFieldMutation, useDocumentToggleConfigurationMutation } from '../../queries';
import PreviewContext from '../../Context';
import HeaderAndFooterFileUpload from './HeaderAndFooterFileUpload';

import { ENTITY } from '../../../constants';
import TokenForFields from 'src/features/BOM/FinalDocuments/Compile/shared/TokenForFields';
import { UpdateTextFieldProps } from '../../queries/apiTypes';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { OPTIONS_FOR_NAME } from '../../../shared/utils/constants';

const AccordionBody = () => {
    const { bomId, documentType } = useParams();
    const { presetResponse, handlePreviewImage } = useContext(PreviewContext);

    const { data: headerFooterPresetData } = useDocumentPresetQuery(bomId!, documentType!, ENTITY.HEADER_FOOTER_ENTITY, presetResponse);
    const { mutate: headerAndFooterToggleMutatrion } = useDocumentToggleConfigurationMutation();

    const { mutate: updateTextField } = useDocumentTextFieldMutation();

    const { data: bomData } = useGetBomDetails(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: projectData } = useGetProjectDetailsQuery(Number(bomData?.data.project)!, {
        enabled: Boolean(bomData?.data.project)
    });

    const [isLeftFooter, setIsLeftFooter] = useState(headerFooterPresetData?.left_footer?.is_active);
    const [leftFooter, setLeftFooter] = useState(headerFooterPresetData?.left_footer?.field_value);

    const [isRightFooter, setIsRightFooter] = useState(headerFooterPresetData?.right_footer?.is_active);
    const [rightFooter, setRightFooter] = useState(headerFooterPresetData?.right_footer?.field_value);

    const [isJobName, setIsJobName] = useState(headerFooterPresetData?.job_name?.is_active);
    const [jobName, setJobName] = useState('');

    const [isDocument, setIsDocument] = useState(headerFooterPresetData?.document_type?.is_active);
    const [isType, setIsType] = useState(headerFooterPresetData?.type?.is_active);
    const [isQuantity, setIsQuantity] = useState(headerFooterPresetData?.quantity?.is_active);
    const [isHyperLink, setIsHyperLink] = useState(headerFooterPresetData?.hyperlink?.is_active);
    const [isPageNumber, setIsPageNumber] = useState(headerFooterPresetData?.page_number?.is_active);
    const [isDate, setIsDate] = useState(headerFooterPresetData?.date?.is_active);

    const [jobNameGenerationRule, setJobNameGenerationRule] = useState<string>('');

    useEffect(() => {
        setIsLeftFooter(headerFooterPresetData?.left_footer?.is_active);
        setLeftFooter(headerFooterPresetData?.left_footer?.field_value);

        setIsRightFooter(headerFooterPresetData?.right_footer?.is_active);
        setRightFooter(headerFooterPresetData?.right_footer?.field_value);

        setIsJobName(headerFooterPresetData?.job_name?.is_active);
        if (headerFooterPresetData?.job_name?.field_value.pre_fill === 'Custom Entry') {
            setJobName(headerFooterPresetData?.job_name?.field_value.value);
        } else if (headerFooterPresetData?.job_name?.field_value.pre_fill === 'Project Name') {
            setJobName(projectData?.data.name || '');
        } else {
            setJobName(bomData?.data.name || '');
        }
        setJobNameGenerationRule(headerFooterPresetData?.job_name?.field_value.pre_fill);

        setIsDocument(headerFooterPresetData?.document_type?.is_active);
        setIsType(headerFooterPresetData?.type?.is_active);
        setIsQuantity(headerFooterPresetData?.quantity?.is_active);
        setIsHyperLink(headerFooterPresetData?.hyperlink?.is_active);
        setIsPageNumber(headerFooterPresetData?.page_number?.is_active);
        setIsDate(headerFooterPresetData?.date?.is_active);
    }, [headerFooterPresetData, projectData, bomData]);

    const handleToggleAction = async (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
        switch (label) {
            case 'job_name':
                setIsJobName(event.target.checked);
                break;
            case 'left_footer':
                setIsLeftFooter(event.target.checked);
                break;
            case 'right_footer':
                setIsRightFooter(event.target.checked);
                break;
            case 'document_type':
                setIsDocument(event.target.checked);
                break;
            case 'type':
                setIsType(event.target.checked);
                break;
            case 'quantity':
                setIsQuantity(event.target.checked);
                break;
            case 'hyperlink':
                setIsHyperLink(event.target.checked);
                break;
            case 'page_number':
                setIsPageNumber(event.target.checked);
                break;
            case 'date':
                setIsDate(event.target.checked);
                break;

            default:
                break;
        }

        headerAndFooterToggleMutatrion(
            {
                bomId: bomId!,
                documentType: documentType!,
                fieldName: label,
                is_active: event.target.checked,
                entity: ENTITY.HEADER_FOOTER_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    const handleJobNameGenerationRule = (event: SelectChangeEvent<unknown>) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'job_name',
            field_value: '',
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'header_footer'
        };
        if (event.target.value === 'Project Name') {
            setJobName(projectData?.data.name || '');
            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else if (event.target.value === 'BOM Name') {
            setJobName(bomData?.data.name || '');

            payload.field_value = { pre_fill: event.target.value };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        } else {
            setJobName(jobNameGenerationRule === 'BOM Name' ? bomData?.data.name || '' : projectData?.data.name || '');

            payload.field_value = { pre_fill: 'Custom Entry', value: jobNameGenerationRule === 'BOM Name' ? bomData?.data.name || '' : projectData?.data.name || '' };
            updateTextField(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        }
        setJobNameGenerationRule(event.target.value as string);
    };

    const handleOnJobNameChange = (val: string) => {
        const payload: UpdateTextFieldProps = {
            fieldName: 'job_name',
            field_value: { pre_fill: 'Custom Entry', value: val },
            bomId: bomId || '',
            documentType: documentType || '',
            entity: 'header_footer'
        };
        updateTextField(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };

    return (
        <Grid container padding={2} direction={'column'} alignItems="flex-start" rowSpacing={2}>
            <Grid item xs={4}>
                <BodyMedium color={'secondary.main'} fontWeight={500}>
                    Header Information
                </BodyMedium>
            </Grid>
            {/* file uploader section */}
            <Grid item width={'100%'} xs={8}>
                <HeaderAndFooterFileUpload />
            </Grid>

            <Grid item>
                <Grid container direction={'column'} gap={2}>
                    <Grid item>
                        <BodyMedium color={'secondary.main'} fontWeight={500}>
                            Header Format
                        </BodyMedium>
                    </Grid>

                    <Grid item>
                        <BodySmall limit={false}>Manufacturer name and model number will be present by default for repeated documents’ headers.</BodySmall>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item width={'100%'}>
                <TokenForFields
                    options={OPTIONS_FOR_NAME}
                    fieldValue={jobName}
                    handleFieldGenerationRule={handleJobNameGenerationRule}
                    selectedOption={jobNameGenerationRule}
                    handleOnBlur={handleOnJobNameChange}
                    showTextAndInput={true}
                    maxLength={128}
                    toggleLabel="Job Name"
                    isToggled={isJobName}
                    onToggle={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'job_name')}
                />
            </Grid>

            <Grid item>
                <Switch label="Document Type" checked={isDocument} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'document_type')} />
            </Grid>

            <Grid item>
                <Switch label="Type(s)" checked={isType} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'type')} />
            </Grid>

            <Grid item>
                <Switch label="Quantity" checked={isQuantity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'quantity')} />
            </Grid>

            <Grid item width={'100%'}>
                <Grid container flexDirection={'column'} alignItems="flex-start" rowSpacing={2}>
                    <Grid item>
                        <BodyMedium marginTop={3} color={'secondary.main'} fontWeight={500}>
                            Footer Format
                        </BodyMedium>
                    </Grid>

                    <Grid item width={'100%'} mt={2}>
                        <SwitchTextFiled
                            checked={isLeftFooter}
                            switchLabel={'Left Footer'}
                            action={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'left_footer')}
                            placeholder={'Left Footer'}
                            textValue={leftFooter}
                            fieldName="left_footer"
                            maxLength={51}
                        />
                    </Grid>

                    <Grid item width={'100%'}>
                        <SwitchTextFiled
                            checked={isRightFooter}
                            switchLabel={'Right Footer'}
                            action={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'right_footer')}
                            placeholder={'Right Footer'}
                            textValue={rightFooter}
                            fieldName="right_footer"
                            maxLength={51}
                        />
                    </Grid>

                    <Grid item>
                        <Switch label="Page Number" checked={isPageNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'page_number')} />
                    </Grid>

                    <Grid item>
                        <Switch label="Date" checked={isDate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'date')} />
                    </Grid>

                    <Grid item>
                        <Switch label="Hyperlink to Table of Contents (Index)" checked={isHyperLink} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleAction(e, 'hyperlink')} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AccordionBody;
