import { useNavigate } from 'react-router-dom';
import { BodyMedium, Box, Button, Grid, H5, Paper, SadFaceIcon } from '@parspec/pixel';

const helperText = 'An active subscription is required to see this page, click on Manage Subscription to go to subscription page.';

const NotPermittedPage = () => {
    const navigate = useNavigate();

    const onManageSubscription = () => navigate(`/v2/settings/plan-and-payment`);

    const onGoToDashboard = () => {
        navigate('/v2/dashboard');
    };

    return (
        <Box bgcolor="secondary.main" sx={{ backgroundImage: `linear-gradient(0deg, rgba(255,0,0,0), rgba(255,255,255,0.5))` }} height={1} display="flex" alignItems="center" justifyContent="center">
            <Paper
                variant="elevation"
                elevation={2}
                sx={{
                    bgcolor: 'secondary.contrastText',
                    width: 500,
                    padding: '32px',
                    paddingBottom: '40px'
                }}
            >
                <Box fontSize="80px" textAlign="center">
                    <SadFaceIcon fontSize="inherit" color="secondary" />
                </Box>
                <H5 limit={false} textAlign="center">
                    Your subscription has expired.
                </H5>
                <Box mt={4}>
                    <BodyMedium color="text.light" limit={false} textAlign="center">
                        {helperText}
                    </BodyMedium>
                </Box>
                <Grid container spacing={3} mt={4}>
                    <Grid item xs={6} md={6} lg={6}>
                        <Box whiteSpace="nowrap">
                            <Button variant="outlined" color="secondary" fullWidth onClick={onGoToDashboard}>
                                Go to Dashboard
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <Box whiteSpace="nowrap">
                            <Button color="tertiary" fullWidth onClick={onManageSubscription}>
                                Manage Subscription
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default NotPermittedPage;
