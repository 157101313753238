import { ProjectDetailsResponse } from '../../../shared/CreateAndEditProjectModal/queries/apiTypes';

export const projectDetails: ProjectDetailsResponse = {
    id: 3220,
    name: 'testing final 2',
    address1: 'Ram pur',
    address2: 'Ram pur',
    city: 'New delhi',
    state: 'New delhi',
    zip: '123aum',
    building_type: '',
    construction_scope: 'Multi-Family Apartment',
    size: 77883,
    submittal_due_date: '2023-03-07T18:30:00Z',
    o_m_due_date: '2023-03-07T18:30:00Z',
    quote_due_date: '2023-03-07T18:30:00Z',
    is_deleted: false,
    last_edited: '2023-03-29T11:17:31.121807Z',
    architect: 'Pawan Lal',
    engineer: 'Pawan Lal',
    contractor: 'Pawan Lal',
    distributor: 'Pawan Lal',
    owner: 'Pawan Lal',
    stage: 'Complete',
    logo: {
        filepath: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/project/3220/Vector.png',
        filename: 'Vector.png'
    },
    company: '1',
    submittal_owner: 45,
    o_m_owner: 45,
    quote_owner: 45,
    last_edited_by: null,
    country: 'Delhi'
};
