import { BodyXS, Box, Tooltip } from '@parspec/pixel';

interface CellCardProps {
    dataArray: Array<string>;
    limit?: number;
}

export default function CellCard({ dataArray, limit = 1 }: CellCardProps) {
    return (
        <Box display="flex" alignItems="center" height="100%" width="100%" gap={0.5} flexWrap="nowrap">
            {dataArray.length === 0 ? (
                '--'
            ) : (
                <>
                    {dataArray.slice(0, limit).map((data) => (
                        <Box flex="0 1 auto" whiteSpace="nowrap" minWidth="0">
                            <BodyXS
                                py={0.5}
                                px={1.5}
                                borderRadius={0.5}
                                bgcolor={'neutral.main'}
                                sx={{
                                    div: {
                                        display: 'block !important'
                                    }
                                }}
                            >
                                {data}
                            </BodyXS>
                        </Box>
                    ))}
                    {dataArray.length > limit ? (
                        <Tooltip placement="right" title={dataArray.slice(limit).join()}>
                            <Box component="span">
                                <BodyXS py={0.5} px={1.5} mx={0.5} borderRadius={0.5} bgcolor={'secondary.dark'} color={'neutral.light'} maxWidth={'max-content'}>
                                    +{dataArray.length - limit}
                                </BodyXS>
                            </Box>
                        </Tooltip>
                    ) : null}
                </>
            )}
        </Box>
    );
}
