import { CellStyle, ProcessCellForExportParams, ValueGetterParams, ValueSetterParams, ITooltipParams } from 'ag-grid-community';

import { EXTENDED_SELL_TOOLTIP_MSG, LEAD_TIME_UNITS, PRICING_TABLE_ENUM, priceRelatedCols, serviceRelatedCols } from '../constants';
import { MainProductComponentsDataType, MainProductDataType } from '../../mainProductTable';
import { ProductOptionDataType } from '../../optionsTable';
import { PricingColChangeCallback } from './types';
import { getIsTableDataUpdated } from '../../mainProductTable/utils';
import { LOT_ASSIGNED_KIT_MAIN_LINE_ITEM, KIT_LINE_TOOLTIP_MSG, LOT_ASSIGNED_TOOLTIP_MSG, DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG } from '../constants';

export function getValueGetterForPricingCols(value: ValueGetterParams) {
    return value.data[value.column.getColId() as PRICING_TABLE_ENUM];
}

export function getPricingColumnDisabled(params: any) {
    const fieldName = params.column.getColId();
    if (priceRelatedCols.has(fieldName as PRICING_TABLE_ENUM) && fieldName !== PRICING_TABLE_ENUM.COST && fieldName !== PRICING_TABLE_ENUM.SELL_PRICE) {
        return !params.data.cost;
    }
    return false;
}

export function getEditableHandlerForPricingColumns(params: any) {
    return !getPricingColumnDisabled(params);
}

export const DISABLED_COLOR_HEX = '#f1f5f9';

export function getColorHandlerForPricingColumns(params: any): CellStyle {
    if (getPricingColumnDisabled(params) && !params.node.rowPinned) {
        return { backgroundColor: DISABLED_COLOR_HEX };
    }
    if (params?.node?.rowPinned === 'bottom') {
        return { border: 'none' };
    }

    // deliberately added wrong value so that it will accept bg color set by ag grid
    return {};
}

export function getIsEditableForMainTable(params: any) {
    const fieldName = params?.column?.getColId();
    const isKit = params?.data?.is_kit && priceRelatedCols.has(fieldName);
    const isPricingCellEmpty =
        priceRelatedCols.has(fieldName) && (params?.data?.lotId !== null || (!params?.data?.cost && fieldName !== PRICING_TABLE_ENUM.COST && fieldName !== PRICING_TABLE_ENUM.SELL_PRICE));
    if (isPricingCellEmpty || (fieldName === PRICING_TABLE_ENUM.LOT && params?.data?.hasOptions) || params?.node?.rowPinned || isKit || (fieldName === PRICING_TABLE_ENUM.LOT && params?.data?.kit)) {
        return false;
    }

    return true;
}

export function getColorHandlerForMainTable(args: any): CellStyle {
    if (!getIsEditableForMainTable(args) && !args?.node?.rowPinned) {
        return { backgroundColor: DISABLED_COLOR_HEX };
    }

    if (args?.node?.rowPinned === 'bottom') {
        return { border: 'none' };
    }

    // deliberately added wrong value so that it will accept bg color set by ag grid
    return {};
}

export function getFooterDisabled(args: any) {
    if (args?.node?.rowPinned === 'bottom') {
        return { border: 'none' };
    }
    return;
}

export const handleProcessCellPasteForPricing = (args: ProcessCellForExportParams) => {
    const { column, value } = args;
    const fieldName = column.getColId();
    if (fieldName === 'qty') {
        if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
            return Number(value);
        } else {
            return null;
        }
    } else if (value === '' || value == null) {
        return fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE ? null : '';
    }

    const processCellPasteCols = new Set([...priceRelatedCols, ...serviceRelatedCols]);

    if (processCellPasteCols.has(fieldName)) {
        const newValue: string = value.replace(/\$|,|%/g, '');
        if (newValue !== '' && !isNaN(newValue as unknown as number)) {
            return parseFloat(newValue);
        }
        return null;
    }
    return value;
};

export const getTemplateCellStyle = (params: any): CellStyle => {
    if (params.node.rowPinned === 'bottom') {
        return { border: 'auto' };
    }
    return { backgroundColor: 'unset' };
};

export const isLeadTimeValueEditable = (params: any) => {
    if (params?.node?.data?.leadTimeUnit === LEAD_TIME_UNITS.IN_STOCK.toLowerCase()) {
        return false;
    }

    return true;
};

export const getleadTimeValueDisableColor = (params: any): CellStyle => {
    if (!isLeadTimeValueEditable(params)) {
        return { backgroundColor: DISABLED_COLOR_HEX };
    }

    return {};
};

export const checkIfResfreshParentTable = <T extends MainProductComponentsDataType | ProductOptionDataType>(tableData: T[], localTableData: T[] | null) => {
    if (Array.isArray(localTableData) && tableData?.length === localTableData.length) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].components?.length !== localTableData[i].components?.length) {
                return true;
            }

            if (tableData[i]?.components) {
                const result = getIsTableDataUpdated(tableData[i].components as MainProductDataType[], localTableData[i].components as MainProductDataType[]);
                if (result) {
                    return result;
                }
            }
        }
    }
    return false;
};

export function getValueSetterForPricingCols(value: ValueSetterParams, pricingColChangeCallback: PricingColChangeCallback) {
    const field = value.column.getColId() as PRICING_TABLE_ENUM;
    const hasValueChanged = value.data[field] !== value.newValue;
    if (hasValueChanged) {
        const newData = pricingColChangeCallback({ ...value.data, [field]: value.newValue }, field);
        Object.keys(newData).forEach((key: string) => {
            value.data[key] = newData[key as keyof typeof newData];
        });
    }
    return hasValueChanged;
}

export const pricingFieldToolTipValueGetter = (params: ITooltipParams<any, any, any>, isComponentsTable: boolean, kitData?: MainProductDataType, field = ``) => {
    if (params?.data?.is_kit && params.data?.lotId) return LOT_ASSIGNED_KIT_MAIN_LINE_ITEM;
    if (params?.data?.is_kit) return KIT_LINE_TOOLTIP_MSG;
    if (params.data?.lotId || (isComponentsTable && kitData?.lotId)) return LOT_ASSIGNED_TOOLTIP_MSG;
    if (!(field === PRICING_TABLE_ENUM.COST || field === PRICING_TABLE_ENUM.SELL_PRICE) && !getIsEditableForMainTable(params)) return DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG;
    if (field === PRICING_TABLE_ENUM.EXTENDED_PRICE && !params?.node?.rowPinned) {
        return EXTENDED_SELL_TOOLTIP_MSG;
    }
    return '';
};

export const optionsToolTipValueGetter = (params: ITooltipParams<any, any, any>, field = ``) => {
    if (params?.data?.is_kit) return KIT_LINE_TOOLTIP_MSG;
    if (!(field === PRICING_TABLE_ENUM.COST || field === PRICING_TABLE_ENUM.SELL_PRICE) && !getIsEditableForMainTable(params)) return DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG;
    return '';
};
