import { ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { BodyMedium, Modal, ModalFooter, ModalHeader } from '@parspec/pixel';
import NotPermittedPage from '../features/shared/NotPermittedPage';
import { useGetAuthToken } from 'src/features/Authentication/hooks';

interface IProtectedRouteProps {
    permitted?: boolean;
    redirectRoute?: string;
    children: ReactNode;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
    const { token } = useGetAuthToken();
    const { permitted, children } = props;

    return <>{token ? permitted ? children : <NotPermittedPage /> : <Navigate to="/login" />}</>;
};

interface ISubsriptionPopupProps {
    open: boolean;
    onClose: () => void;
}

export const SubsriptionPopup = (props: ISubsriptionPopupProps) => {
    const navigate = useNavigate();
    const { open, onClose } = props;

    const onManagePlan = () => {
        navigate(`/v2/settings/plan-and-payment`);
        onClose();
    };

    return (
        <Modal
            open={open}
            header={<ModalHeader title="An active subscription is required in this case." onClose={onClose} />}
            footer={<ModalFooter onAccept={onManagePlan} onReject={onClose} continueButtonLabel="Manage Subscription" />}
        >
            <BodyMedium limit={false}>Manage your subscription and select a plan to get the benefit of all features of Parspec.</BodyMedium>
        </Modal>
    );
};
