import { BodySmall, BodyXS, Box, Status, Tooltip } from '@parspec/pixel';
import Chip from '../../Chip';
import { ProductInfo } from '../DocumentItem';
import { capitalizeText, getUserCountTooltip } from '../utils';
import LiveTag from '../../LiveTag';

interface BasicInfoProps {
    bodData: ProductInfo;
    notes?: string;
    tag?: string;
    dataToPreview?: any;
    productFinderMode?: boolean;
}

const BasicInfo = (props: BasicInfoProps) => {
    const { bodData, notes, tag, dataToPreview, productFinderMode } = props;

    const getTagColor = () => {
        if (tag === 'source') return 'success';
        if (tag === 'alternate') return 'error';
        return 'primary';
    };

    const getUseCount = (user: string, count: number) => {
        if (count > 0) {
            return (
                <Tooltip title={getUserCountTooltip(user)} placement="bottom">
                    <span>
                        <Chip bgColor="secondary.main">
                            <BodyXS color="tertiary.main" fontWeight={600} limit={false}>
                                {count}
                            </BodyXS>
                            <BodyXS color="primary.contrastText" fontWeight={600} limit={false} whiteSpace="nowrap">
                                {user}
                            </BodyXS>
                        </Chip>
                    </span>
                </Tooltip>
            );
        }
        return null;
    };

    const getManufacturer = () => {
        if (dataToPreview && productFinderMode) return dataToPreview?.search_result_metadata?.manufacturer;
        return bodData?.manufacturer;
    };

    const getModelNumber = () => {
        if (dataToPreview && productFinderMode) return '';
        return bodData?.model_number;
    };

    return (
        <Box mb={4}>
            <Box display="flex" alignItems="center" gap="8px">
                {tag && (
                    <Status color={getTagColor()}>
                        <BodyXS color="inherit" borderRadius={'inherit'} limit={false}>
                            {capitalizeText(tag)}
                        </BodyXS>
                    </Status>
                )}
                {getManufacturer() && (
                    <Box display="flex" alignItems="center" gap="4px" maxWidth={productFinderMode ? '100% ' : '40%'}>
                        <BodyXS color="text.secondary" limit={false}>
                            Manufacturer:
                        </BodyXS>
                        <BodySmall lines={1}>{getManufacturer()}</BodySmall>
                    </Box>
                )}
                {getModelNumber() && (
                    <Box display="flex" alignItems="center" gap="4px" flex={1}>
                        <BodyXS color="text.secondary" limit={false}>
                            Model:
                        </BodyXS>
                        <BodySmall lines={1}>{getModelNumber()}</BodySmall>
                    </Box>
                )}

                <Box display="flex" ml="auto" gap={2}>
                    {dataToPreview?.document_liveness_status === 'LIVE' && <LiveTag />}
                    {!productFinderMode && (
                        <>
                            {getUseCount('your org', dataToPreview?.company_confirm)}
                            {getUseCount('all users', dataToPreview?.total_confirm)}
                        </>
                    )}
                </Box>
            </Box>
            {notes && (
                <Box mt={1}>
                    <BodyXS lines={1}>{notes}</BodyXS>
                </Box>
            )}
        </Box>
    );
};

export default BasicInfo;
