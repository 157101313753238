import { useCallback, useEffect, useState } from 'react';

import { Grid, FileSelector, Box, BodySmall, FileSelectorFileType, CircularProgress } from '@parspec/pixel';

import { environment } from 'src/environments';
import { useQuoteContext } from '../../QuoteContext';
import { useQuoteAttachmentsQuery, useAttachmentDocumentsMutation } from '../../queries';

import {
    ACCEPTED_FILE_TYPES,
    ACCEPTED_FILE_TYPES_STRING,
    MAX_ALLOWED_FILE_SIZE_IN_BYTES,
    MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE_FOR_MULTI_UPLOAD
} from '../../../../../../Settings/BranchLocations/AddEditLocation/QuoteTemplatePreferences/utils/constants';
import ApplyToAllBomsButton from '../../../shared/ApplyToAllBomsButton';
import { ATTACHMENTS_SECTION_NAME, ATTACHMENTS_SUB_SECTION_NAME } from '../../shared/constants';

export interface IFileType {
    file: FileSelectorAndAPIDataFileType;
    s3_file_path?: string;
}

interface FileSelectorAndAPIDataFileType extends FileSelectorFileType {
    id?: number;
}

export interface IPreselectedFiles {
    id?: number | null;
    name: string;
    size: number;
    filepath: string;
    s3_url: string;
}

function Attachments() {
    const { handlePdfPreview, isInitiateApiCalls, bomId, handleUpdateChangedSections } = useQuoteContext();

    const [preSelectedFiles, setPreSelectedFiles] = useState<IPreselectedFiles[]>([]);

    const { data: attachmentData, isFetching: isAttachmentDataFetching } = useQuoteAttachmentsQuery(bomId, isInitiateApiCalls);
    const { mutateAsync: mutateDocuments, isLoading: isMutateDocumentsLoading } = useAttachmentDocumentsMutation();

    useEffect(() => {
        if (isAttachmentDataFetching || !attachmentData?.data) {
            return;
        }

        let documentData = [];

        // adding the same s3 filepath in a new key "filepath" because in out fileUploader
        // the uploading logic is handled by the filepath key (refer line 66 in SelectedFiles.tsx in Pixel)
        // adding another key 'size' to make the key names uniform with those of the FileUploader
        if (attachmentData.data.documents.length > 0) {
            documentData = attachmentData.data.documents.map((el: any) => ({ ...el, filepath: el.s3_url, size: el.size_in_byte }));
        }

        setPreSelectedFiles(documentData);
    }, [isAttachmentDataFetching, attachmentData?.data]);

    const pushChangedFields = () => {
        handleUpdateChangedSections?.({ sectionName: ATTACHMENTS_SECTION_NAME, subSectionName: ATTACHMENTS_SUB_SECTION_NAME, value: true });
    };

    const onFileUploadedToS3 = useCallback(
        async (files: IFileType[]) => {
            if (files.length !== preSelectedFiles.length) {
                const formatedDataFromFileSelector = files.map((item: any) => ({
                    name: item.file.name,
                    s3_url: item.s3_file_path
                }));

                if (!isMutateDocumentsLoading) {
                    await mutateDocuments({ bomId, data: formatedDataFromFileSelector });
                    handlePdfPreview(true);
                    pushChangedFields();
                }
            }
        },
        [preSelectedFiles]
    );

    return (
        <Grid container direction={'column'} gap={2}>
            <Grid item position={'relative'} minWidth="350px">
                <Box m={2}>
                    <BodySmall limit={false} color="text.secondary">
                        Upload PDF documents in the sequence you want them to be attached to your quote.
                    </BodySmall>
                    <BodySmall limit={false} color="text.secondary">
                        For large files, preview might take longer to load.
                    </BodySmall>
                </Box>

                <Box mt={4} mb={2}>
                    <ApplyToAllBomsButton sectionName={ATTACHMENTS_SECTION_NAME} subSectionName={ATTACHMENTS_SUB_SECTION_NAME} />
                </Box>

                <Box m={4} ml={2} position={'relative'} width="500px">
                    {(isAttachmentDataFetching || isMutateDocumentsLoading) && (
                        <Box
                            position="absolute"
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ bgcolor: 'primary.contrastText', opacity: 0.8 }}
                            zIndex={200}
                        >
                            <Box width={1} height={1} display={'flex'} justifyContent={'center'} alignItems={'center'} zIndex={201}>
                                <CircularProgress color="primary" size={'lg'} />
                            </Box>
                        </Box>
                    )}
                    <FileSelector
                        placeholder="Drag and drop a file here, or:"
                        url={`${environment.b}/api/generate_signed_url/`}
                        onUpload={onFileUploadedToS3}
                        acceptedFormats={ACCEPTED_FILE_TYPES}
                        helperText={ACCEPTED_FILE_TYPES_STRING}
                        preSelectedFile={preSelectedFiles}
                        maxFiles={Infinity}
                        showUploaderAlways
                        maxTotalFileSizeAllowed={{
                            size_in_bytes: MAX_ALLOWED_FILE_SIZE_IN_BYTES,
                            errorText: MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE_FOR_MULTI_UPLOAD
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default Attachments;
