import { ReactNode } from 'react';

import { Box } from '@parspec/pixel';

type ChipProps = {
    children: ReactNode;
    bgColor: string;
};

const Chip = (props: ChipProps) => {
    const { children, bgColor } = props;
    return (
        <Box px={1.5} py={0.5} borderRadius={0.5} bgcolor={bgColor} display="flex" gap={1} alignItems="center">
            {children}
        </Box>
    );
};
export default Chip;
