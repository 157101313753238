import { IBodsComponent, BodResponse } from '../queries/apiTypes';

export const checkIfResfreshParentTable = <T extends IBodsComponent>(tableData: T[], localTableData: T[] | null) => {
    let result = false;
    if (Array.isArray(localTableData) && tableData?.length === localTableData.length) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].components?.length !== localTableData[i].components?.length) {
                result = true;
                break;
            }

            tableData[i].components?.forEach((component) => {
                const localComponent = localTableData[i].components?.find((item) => item.id === component.id);
                if (localComponent && (localComponent.is_hidden !== component.is_hidden || localComponent.order !== component.order || localComponent?.specification !== component.specification)) {
                    result = true;
                }
            });
        }
    }
    return result;
};

export function getIsTableDataUpdated(apiTableData: BodResponse[], localTableData: BodResponse[]) {
    return apiTableData.some((productData, index) => {
        return (
            productData?.is_hidden !== localTableData?.[index]?.is_hidden ||
            productData?.manufacturer !== localTableData?.[index]?.manufacturer ||
            productData?.specification !== localTableData?.[index]?.specification ||
            productData?.qty !== localTableData?.[index]?.qty ||
            productData?.category !== localTableData?.[index]?.category ||
            productData?.model_number !== localTableData?.[index]?.model_number ||
            productData?.specification !== localTableData?.[index]?.specification ||
            productData?.product_to_replace !== localTableData?.[index]?.product_to_replace
        );
    });
}
