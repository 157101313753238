import { useState } from 'react';
import { AddIcon, Box, H5 } from '@parspec/pixel';
import { ReuseDialogTypeLabel } from '../../BOM/shared/utils';

type AddDocumentProps = {
    handleAddDocument: () => void;
    subDocumentType: string | undefined;
};

const AddDocumentThumbnail = (props: AddDocumentProps) => {
    const { handleAddDocument, subDocumentType } = props;
    const [hovered, setHovered] = useState(false);

    const toggleHover = () => setHovered(!hovered);

    return (
        <Box height="532px" p={4} border="1px solid" width="308px" minWidth="300px" borderColor="secondary.light">
            <Box
                border="1px dashed"
                borderColor="primary.main"
                height={1}
                width={1}
                bgcolor="primary.light"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                sx={{ cursor: 'pointer' }}
                onClick={handleAddDocument}
                textAlign="center"
            >
                <Box fontSize="84px">
                    <AddIcon fontSize="inherit" color={hovered ? 'primary' : 'secondary'} />
                </Box>
                <H5 color={hovered ? 'primary.main' : 'secondary'} limit={false}>{`Add ${ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}`}</H5>
            </Box>
        </Box>
    );
};

export default AddDocumentThumbnail;
