import { BodyXS, Box, Modal, ModalHeader, Skeleton, Status, Table, ToolbarType } from '@parspec/pixel';
import { ColumnDirective } from '@syncfusion/ej2-react-treegrid';

import { useProductSearchHistoryMutation } from '../../queries';

import { BomTemplate, ProjectTemplate } from '../../../../../Dashboard/shared/components';
import { StatusTemplate } from 'src/features/shared/utils/utils';
import { specificationOptions } from 'src/features/shared/utils/constants';
import { useParams } from 'react-router-dom';
import { ColorCode } from 'src/features/shared/constants';
import { useEffect, useState } from 'react';
import { ProductSelectionHistoryResponse } from '../../queries/apiTypes';

interface ProductSelectionHistoryProps {
    open: boolean;
    document_url: string;
    content_hash: string;
    fingerprint_hash: string;
    onCloseClick: () => void;
}

const toolBarItems: ToolbarType = ['search', 'clearFilters'];

export const tableProps = {
    allowRowDragAndDrop: false,
    toolBarOptions: toolBarItems,
    editSettings: { allowEditing: false, allowAdding: false, allowDeleting: false }
};

export const ProductSelectionHistory = ({ open, onCloseClick, document_url, content_hash, fingerprint_hash }: ProductSelectionHistoryProps) => {
    const { bomId, productId: bodId } = useParams();

    const [historyData, setHistoryData] = useState<ProductSelectionHistoryResponse[]>();

    const { mutateAsync: fetchProductselectionHistory, isLoading } = useProductSearchHistoryMutation();

    const fetchHistory = async () => {
        const req = {
            bomId: bomId || '',
            bodId: bodId || '',
            input: {
                document_url,
                content_hash,
                fingerprint_hash
            }
        };
        const res = await fetchProductselectionHistory(req);
        setHistoryData(res.data);
    };

    useEffect(() => {
        fetchHistory();
    }, [bomId, bodId, document_url, content_hash]);

    const header = <ModalHeader title="Product Selection History" onClose={onCloseClick} />;

    const getProjectTemplate = (props: any) => {
        return ProjectTemplate({ projectName: props.project_name, projectId: props.project_id, target: '_blank' });
    };
    const getStageTemplate = (props: any) => StatusTemplate(props.project_stage);

    const getBomTemplate = (props: any) => {
        const { bom_id, bom_name, project_id } = props;
        return BomTemplate({ bomId: bom_id, bomName: bom_name, projectId: project_id, target: '_blank' });
    };

    const getSpecChipColor = (value: string) => {
        return specificationOptions.find((spec) => spec.value === value)?.type as ColorCode;
    };

    const SpecificationTemplate = (props: any) => (
        <Status color={getSpecChipColor(props.specification)}>
            <BodyXS color={getSpecChipColor(props.specification)}>{props.specification}</BodyXS>
        </Status>
    );

    const matchTypeTemplate = (props: any) => {
        let strToRender = '';
        const arrLength = props?.match_type.length;
        props?.match_type.forEach((el: string, index: number) => {
            if (el !== 'product_requirements') {
                el = `source_${el}`;
            }
            if (index === 0) el = `Matching_${el}`;
            const str = el.split('_').join(' ');

            strToRender += `${str}${index !== arrLength - 1 ? ', ' : ''}`;
        });

        return <Box sx={{ textWrap: 'wrap' }}>{strToRender}</Box>;
    };

    return (
        <Box>
            <Modal open={open} header={header}>
                <Box width={1200}>
                    {!isLoading ? (
                        <Table {...{ ...tableProps, toolBarOptions: [] }} data={historyData || []} loading={isLoading} height={450}>
                            <ColumnDirective field="project_id" isPrimaryKey={true} visible={false} />
                            <ColumnDirective field="project_name" headerText="Project" template={getProjectTemplate} minWidth={180} allowFiltering={false} allowSorting={false} />
                            <ColumnDirective field="bom_name" headerText="Bill Of Materials" template={getBomTemplate} minWidth={180} allowFiltering={false} allowSorting={false} />
                            <ColumnDirective field="project_stage" width={160} headerText="Project Stage" template={getStageTemplate} minWidth={150} allowSorting={false} allowFiltering={false} />
                            <ColumnDirective field="location" width={150} headerText="Branch Location" minWidth={150} allowSorting={false} allowFiltering={false} />
                            <ColumnDirective
                                field="specification"
                                template={SpecificationTemplate}
                                headerText="Specification"
                                minWidth={160}
                                width={160}
                                allowSorting={false}
                                allowEditing={false}
                                allowFiltering={false}
                            />
                            <ColumnDirective field="match_type" template={matchTypeTemplate} headerText="Type of match" width={240} minWidth={180} allowSorting={false} allowFiltering={false} />
                        </Table>
                    ) : (
                        <Skeleton height={540} width={1200} variant="rectangular" />
                    )}
                </Box>
            </Modal>
        </Box>
    );
};
