import { axios } from 'src/app/Axios';
import { LotsResponse, PutLotsRequest } from './apiTypes';

export const lotsEndpoint = (bomId: string) => `/bom/${bomId}/pricing/lot/`;

export const getLots = (bomId: string) => {
    return axios.get<LotsResponse>(lotsEndpoint(bomId));
};

export const putLotsDetails = (request: PutLotsRequest) => {
    const { bomId, payload } = request;
    return axios.put<LotsResponse>(lotsEndpoint(bomId), payload);
};
