import { Box, BodySmall, Skeleton } from '@parspec/pixel';

import { LAUNCH_PRODUCT_FINDER_FOR_ALL } from '../../../shared/constants';
import { useSubscriptionInfo } from '../../PlanAndPayment/queries';
import { useGetUserProfileInfoQuery } from '../../MyProfile/queries';

export const ProductFinderSeatsBanner = () => {
    const { data: subscriptionInfoData, isLoading: isSubscriptionInfoLoading, isFetching: isSubscriptionInfoFetching } = useSubscriptionInfo();
    const { data: userProfile, isLoading: isUserProfileInfoLoading } = useGetUserProfileInfoQuery();

    const isPlanFreeOrEnterprise = subscriptionInfoData?.data.my_plan.plan === 'Free' || subscriptionInfoData?.data.my_plan.plan === 'Enterprise';

    return (
        <Box>
            {LAUNCH_PRODUCT_FINDER_FOR_ALL && userProfile?.data?.role === 'admin' && !isPlanFreeOrEnterprise && (
                <>
                    {!isUserProfileInfoLoading && !isSubscriptionInfoFetching && !isSubscriptionInfoLoading && subscriptionInfoData?.data ? (
                        <Box>
                            {subscriptionInfoData.data.product_finder_seats_in_use === subscriptionInfoData.data.product_finder_seats_purchased ? (
                                <>
                                    {subscriptionInfoData.data.product_finder_seats_in_use === 0 && subscriptionInfoData.data.product_finder_seats_purchased === 0 ? (
                                        <Box bgcolor={'primary.light'} border={'1px solid'} borderColor={'primary.main'} p={2} mb={2} borderRadius={1}>
                                            <BodySmall color="primary.main" lines={2}>
                                                <strong>No product finder seats are allocated.</strong> To provide product finder access, toggle the Product Finder access below for the desired user.
                                            </BodySmall>
                                        </Box>
                                    ) : (
                                        <Box bgcolor={'primary.light'} border={'1px solid'} borderColor={'primary.main'} p={2} mb={2} borderRadius={1}>
                                            <BodySmall color="primary.main" lines={2}>
                                                <strong>All purchased seats are in use.</strong> To add more seats, toggle the Product Finder access below for the desired user. To re-allocate an
                                                existing seat, remove a user's access first before re-allocating it.
                                            </BodySmall>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Box bgcolor={'primary.light'} border={'1px solid'} borderColor={'primary.main'} p={2} mb={2} borderRadius={1}>
                                    <BodySmall color="primary.main" lines={2}>
                                        <strong>
                                            You have {subscriptionInfoData.data.product_finder_seats_purchased - subscriptionInfoData.data.product_finder_seats_in_use} unused Product Finder seat(s).
                                        </strong>{' '}
                                        Toggle the Product Finder access below to allocate these unused seat(s).
                                    </BodySmall>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box mb={2}>
                            <Skeleton variant="rectangular" height="54px" />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};
