import { rest } from 'msw';

import { priceAndLeadtimeGetResponse } from './priceAndLeadTime';
import { coverPageData, coverPageLogoData } from './CoverPage';
import { generalTermsAndConditionData } from './GeneralTermsAndCondition';
import { headerFooterData, headerFooterLogoData } from './HeaderFooter';
import { manufacturerTocData } from './ManufacturerTOC';
import { quoteDocumentData, compileTimeData } from './CreateQuoteDocument';

export const compileQuoteHandlers = [
    // quote preset post
    rest.post('*/bom/:bomId/quote_preset/', (_req, res, ctx) => {
        return res(ctx.delay(5000), ctx.json({ message: 'preset created' }));
    }),

    // quote pdf url
    rest.post('*/bom/:bomId/quote_preset/preview/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json({ url: 'https://parspec-product-url.s3.us-east-2.amazonaws.com/media/cover_page/661105eaebdcea1166a3a78418193f9d0149a95a2f594773b7dbfa093cdeaf87.pdf' }));
    }),

    rest.get('*/bom/:bomId/quote_preset/price_and_lead_time/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(priceAndLeadtimeGetResponse));
    }),

    rest.patch('*/bom/:bomId/quote_preset/price_and_lead_time/configuration/:fieldName/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json({ message: 'Update complete' }));
    }),

    // cover page

    rest.get('*/bom/:bomId/quote_preset/cover_page/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(coverPageData));
    }),

    rest.get('*/bom/:bomId/quote_preset/cover_page/data/:fieldName/logo/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(coverPageLogoData));
    }),

    // general terms and condition

    rest.get('*/bom/:bomId/quote_preset/general_tnc/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(generalTermsAndConditionData));
    }),

    // header footer
    rest.get('*/bom/:bomId/quote_preset/header_footer/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(headerFooterData));
    }),
    rest.get('*/bom/:bomId/quote_preset/header_footer/logo/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(headerFooterLogoData));
    }),

    // Manufacturer
    rest.get('*/bom/:bomId/quote_preset/manufacturer_tnc/data/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(manufacturerTocData));
    }),
    // create quote document mocks
    rest.post('*/bom/:bomId/quote/', (_req, res, ctx) => {
        return res(ctx.delay(5000), ctx.json(quoteDocumentData));
    }),

    rest.get('*/bom/:bomId/quote/compile_time', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(compileTimeData));
    })
];
