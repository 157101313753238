import { useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Box, BodyMedium } from '@parspec/pixel';

import MainProductTable from '../mainProductTable/MainProductTable';
import { MainProductDataType } from '../mainProductTable';
import { usePricingBodPatchMutation } from '../queries';
import { getApiDataForBomInfo, getApiDataForPricingInfo } from './utils';
import { useUpdateBomDataMutation } from '../../queries';
import { OptionDataType } from '../optionsTable';
import OptionTable from '../optionsTable/OptionsTable';
import { TableContext } from '../../shared/context';
import { IDetailGridProps } from '../../shared/types';

function PriceAndLeadtimeDetailGrid({ data, node, api, updateDetailGridContainerHeight, selectedRowsRef }: IDetailGridProps) {
    const rowId = node.id;

    const { bomId = '' } = useParams();

    const { section, isOptionTable } = useContext(TableContext);
    const ref = useRef(null);

    const { mutateAsync: updateBomData } = useUpdateBomDataMutation();
    const { mutateAsync: updatePricingData } = usePricingBodPatchMutation();

    useEffect(() => {
        return () => {
            // the detail grid is automatically destroyed as it is a React component
            if (rowId) {
                api.removeDetailGridInfo(rowId);
            }
        };
    }, []);

    const gridContainerRefCallback = useCallback((node: HTMLDivElement) => {
        if (node) {
            updateDetailGridContainerHeight(node.clientHeight);
        }
    }, []);

    const onGridReady = (params: any) => {
        if (rowId) {
            const gridInfo = {
                id: rowId,
                api: params.api,
                columnApi: params.columnApi
            };

            api.addDetailGridInfo(rowId, gridInfo);
        }
    };

    const detailTableHeight = useMemo(() => {
        return (data.components.length || 1) * 40 + 57;
    }, [data.components]);

    const onBomInfoEdit = useCallback(
        async function (
            rowData: Partial<Pick<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'id'>> & Partial<Pick<OptionDataType, 'replaceProductId'>>
        ) {
            const apiReqData = getApiDataForBomInfo(rowData);
            await updateBomData({ bomId: String(bomId), input: apiReqData });
        },
        [updateBomData]
    );

    const onPricingInfoEdit = useCallback(
        async function (rowData: Partial<Omit<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'modelNo' | 'specification' | 'lotInfo'>>) {
            const { id: bodId = 0, ...restRowInfo } = rowData;
            const apiReqData = getApiDataForPricingInfo(restRowInfo);
            if (bomId && bodId) {
                await updatePricingData({ bomId: Number(bomId), pricingBod: apiReqData, bodId: bodId });
            }
        },
        [updatePricingData]
    );

    return (
        <Box p={4} flex={1} display="flex" flexDirection="column" ref={gridContainerRefCallback}>
            <BodyMedium>Component(s)</BodyMedium>
            <Box mt={1} flex={1} minHeight={0}>
                {isOptionTable ? (
                    <OptionTable
                        ref={ref}
                        tableData={data.components}
                        onBomInfoEdit={onBomInfoEdit}
                        onPricingInfoEdit={onPricingInfoEdit}
                        section={section!}
                        tableMaxHeight={300}
                        kitTableHeight={detailTableHeight}
                        isLoading={false}
                        isKitTable={true}
                        isComponentsTable={true}
                        kitData={data}
                        selectedRowsRef={selectedRowsRef!}
                    />
                ) : (
                    <MainProductTable
                        ref={ref}
                        tableData={data.components}
                        kitData={data}
                        isKitTable={true}
                        onPricingInfoEdit={onPricingInfoEdit}
                        onBomInfoEdit={onBomInfoEdit}
                        isLoading={false}
                        section={section!}
                        tableMaxHeight={300}
                        kitTableHeight={detailTableHeight}
                        onGridReady={onGridReady}
                        isComponentsTable={true}
                        selectedRowsRef={selectedRowsRef!}
                    />
                )}
            </Box>
        </Box>
    );
}

export default PriceAndLeadtimeDetailGrid;
