import { MultiSelectOptionType } from '@parspec/pixel';
import { reportingAxiosInstance as axios } from 'src/app/Axios';
import { FilterType, FILTER_KEYS } from '../shared/components/Filters/constants';
import { BranchLocationsTabDataResponse, CustomersTabDataResponse, ManufacturersTabDataResponse, ParamProps, QuoteOwnersTabDataResponse, ReportingPageNameTypes } from './apiTypes';
import { getEvent, pushEvent } from '../../shared/UserActivityTracking/EventService';
import { getStringifiedValueForEventService } from '../../shared/utils/utils';

const getStringFromArray = (val: MultiSelectOptionType[]) => {
    return val.map((item) => item.value).join(',');
};

const getQueryParams = (filters: FilterType) => {
    const params = {} as ParamProps;
    if (filters[FILTER_KEYS.PROJECT_STAGES]?.length) {
        params.project_stage = getStringFromArray(filters[FILTER_KEYS.PROJECT_STAGES]);
    }
    if (filters[FILTER_KEYS.BRANCH_LOCATIONS]?.length) {
        params.branch_location = getStringFromArray(filters[FILTER_KEYS.BRANCH_LOCATIONS]);
    }
    if (filters[FILTER_KEYS.OWNERS]?.length) {
        params.quote_owner = getStringFromArray(filters[FILTER_KEYS.OWNERS]);
    }
    if (filters[FILTER_KEYS.MARKET_VERTICALS]?.length) {
        params.market_vertical = getStringFromArray(filters[FILTER_KEYS.MARKET_VERTICALS]);
    }
    if (filters[FILTER_KEYS.DATE_RANGE]?.length) {
        params.quote_due_date_start = filters[FILTER_KEYS.DATE_RANGE][0];
        params.quote_due_date_end = filters[FILTER_KEYS.DATE_RANGE][1];
    }
    return params;
};

export const endpoints = {
    customersTabUrl: `/reporting/customers`,
    branchLocationsTabUrl: `/reporting/locations`,
    quoteOwnersTabUrl: `/reporting/users`,
    manufacturersTabUrl: `/reporting/manufacturers`
};

export const getCustomersTabData = async (filters: FilterType) => {
    const params = getQueryParams(filters);
    return axios.get<CustomersTabDataResponse[]>(endpoints.customersTabUrl, {
        ...(Object.keys(params).length ? { params } : {})
    });
};

export const getBranchLocationsTabData = async (filters: FilterType) => {
    const params = getQueryParams(filters);
    return axios.get<BranchLocationsTabDataResponse[]>(endpoints.branchLocationsTabUrl, {
        ...(Object.keys(params).length ? { params } : {})
    });
};

export const getQuoteOwnersTabData = async (filters: FilterType) => {
    const params = getQueryParams(filters);
    return axios.get<QuoteOwnersTabDataResponse[]>(endpoints.quoteOwnersTabUrl, {
        ...(Object.keys(params).length ? { params } : {})
    });
};

export const getManufacturersTabData = async (filters: FilterType) => {
    const params = getQueryParams(filters);
    return axios.get<ManufacturersTabDataResponse[]>(endpoints.manufacturersTabUrl, {
        ...(Object.keys(params).length ? { params } : {})
    });
};

// #################################################################################
// ########################## FOR MANAGE COLUMNS ###################################
// #################################################################################

export const getSelectedColumns = (payload: { userId: number; pageName: ReportingPageNameTypes }) => {
    return getEvent({
        getQuery: `query{
            events_db {
              table_settings(where: {page_name: {_eq: ${payload.pageName}},  user_id: {_eq: ${payload.userId}}}) {
                order,
                filters
              }
            }
          }`,
        responseAttribute: 'events_db'
    });
};

const insertSelectedColumnsMutation = (payload: { pageName: ReportingPageNameTypes; columnData: string[] }) => {
    return `mutation($order: [String!] = ${getStringifiedValueForEventService(payload.columnData)}) {
    events_db {
      insert_table_settings(objects: {order: $order, page_name: ${payload.pageName}}) {
        affected_rows
      }
    }
  }`;
};

const updateColumnsMutation = (payload: { pageName: ReportingPageNameTypes; columnData: string[]; userId: number }) => {
    return `mutation($order: [String!] = ${getStringifiedValueForEventService(payload.columnData)}) {
        events_db {
          update_table_settings(_set: {order: $order}, where: {user_id: {_eq: ${payload.userId}}, page_name: {_eq: ${payload.pageName}}}) {
            affected_rows
          }
        }
      }`;
};

export const updateSelectedColumns = (payload: { pageName: ReportingPageNameTypes; columnData: string[]; userId: number }) => {
    return pushEvent({
        insertMutation: insertSelectedColumnsMutation(payload),
        updateMutation: updateColumnsMutation(payload),
        responseAttribute: 'table_settings'
    });
};
