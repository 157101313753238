import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import { invalidateGetAllCompanyDetailsKey } from '../../../shared/CreateAndEditProjectModal/queries';

import { getUserProfileInfo, postUserProfileInfo, getUserAccountPreferences, patchUserAccountPreferences, endPoints } from './apis';

import { IUserAccountPreferencesRequest } from './apiTypes';

const userProfileKey = [endPoints.userProfile];
const userAccountPreferencesKey = [endPoints.userPreferences];

export const useGetUserProfileInfoQuery = (options?: object) => useQuery(userProfileKey, getUserProfileInfo, { ...options });
export const useUserAccountPreferencesQuery = () => useQuery(userAccountPreferencesKey, getUserAccountPreferences);

export const useEditUserProfileInfoMutation = () =>
    useMutation(postUserProfileInfo, {
        onSuccess: () => {
            queryClient.invalidateQueries(userProfileKey);
            //this is added because on updating the user name, the updated name should come in project detail page
            invalidateGetAllCompanyDetailsKey();
        }
    });

export const useEditUserPreferencesMutation = () =>
    useMutation(patchUserAccountPreferences, {
        onSuccess: (_response, request) => {
            queryClient.setQueryData(userAccountPreferencesKey, (oldData?: AxiosResponse<IUserAccountPreferencesRequest>) => {
                if (oldData) {
                    return { ...oldData, data: { ...oldData.data, ...request } };
                }
                return oldData;
            });
        }
    });

// Invalidations ----------------------------------------------------------
export function invalidateUserProfileInfoQuery() {
    queryClient.invalidateQueries(userProfileKey, { exact: true });
}
