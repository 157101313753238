import { PRODUCT_CATEGORY_LIST } from 'src/features/shared/utils/constants';
import { ParspecMfgDetailsResponse, CompanyMfgDetailsResponse, ManufacturerListInfo } from '../../../Settings/Manufacturer/queries/apiTypes';
import { MfgStatusLabelsEnum } from '../../../shared/constants';
import { MANUFACTURER_GROUPS } from '../setRequirements/constants';

type ProductCategoryType = (typeof PRODUCT_CATEGORY_LIST)[number];

const productCategoryToSplit = new Set(['Troffer / Panel', 'Exit / Emergency']);

export function getApplicationCategoryForApi(productCategory: ProductCategoryType) {
    if (productCategoryToSplit.has(productCategory)) {
        const updatedProductCategory = productCategory.split('/').map((string) => string.trim());
        updatedProductCategory.push(productCategory);
        return updatedProductCategory;
    }

    return productCategory;
}

export function getAllManufacturersForDropdown(parspecManufacturerData?: ParspecMfgDetailsResponse[], companyManufacturerData?: CompanyMfgDetailsResponse[], listOfMfgLists?: ManufacturerListInfo[]) {
    const companyManufacturerMap: Record<string, CompanyMfgDetailsResponse> = {};
    for (const manufacturerData of companyManufacturerData || []) {
        if (manufacturerData.manufacturer_id !== null) {
            companyManufacturerMap[manufacturerData.manufacturer_id] = manufacturerData;
        }
    }
    const parspecSupportedMfgData = parspecManufacturerData?.filter((currentParspecManufacterData) => currentParspecManufacterData?.status === MfgStatusLabelsEnum.SUPPORTED);
    const parspecSupportedMfgSet = new Set(parspecSupportedMfgData?.map((mfg) => mfg.company_group_man_id));

    const mfgListGroupForDropdown = listOfMfgLists?.filter((mfgList) => {
        return mfgList.company_group_man_id.some((mfgId) => parspecSupportedMfgSet.has(mfgId));
    });

    const mfgToMfgListMap: Record<number, number[]> = {};
    for (const mfgListData of mfgListGroupForDropdown || []) {
        for (const mfgId of mfgListData.company_group_man_id) {
            if (mfgToMfgListMap[mfgId]) {
                mfgToMfgListMap[mfgId].push(mfgListData.id);
            } else {
                mfgToMfgListMap[mfgId] = [mfgListData.id];
            }
        }
    }

    const manufacturerData = parspecSupportedMfgData
        ?.filter((currentParspecManufacterData) => currentParspecManufacterData?.status === MfgStatusLabelsEnum.SUPPORTED)
        ?.map((currentManufacturerData) => {
            const currentCompanyManufacturerData = companyManufacturerMap[currentManufacturerData.manufacturer_id] ? companyManufacturerMap[currentManufacturerData.manufacturer_id] : undefined;
            const label = currentCompanyManufacturerData?.manufacturer_name || currentManufacturerData.parspec_id;
            const manufacturerGroup = [MANUFACTURER_GROUPS.ALL_PARSPEC_PREFERRED];
            if (currentCompanyManufacturerData) {
                manufacturerGroup.push(MANUFACTURER_GROUPS.COMPANY_PREFERRED);
            }

            if (mfgToMfgListMap[currentManufacturerData.company_group_man_id] && mfgToMfgListMap[currentManufacturerData.company_group_man_id].length > 0) {
                manufacturerGroup.push(...mfgToMfgListMap[currentManufacturerData.company_group_man_id]);
            }

            const manufacturerName = currentCompanyManufacturerData?.manufacturer_name != null ? currentCompanyManufacturerData?.manufacturer_name : undefined;

            return {
                label,
                value: currentManufacturerData.unique_mfg_id,
                manufacturer_name: manufacturerName,
                keywords: currentManufacturerData.keywords,
                abbreviations: currentCompanyManufacturerData?.abbreviations,
                group: manufacturerGroup
            };
        });

    return { manufacturerOptions: manufacturerData || [], mfgListGroupForDropdown };
}
