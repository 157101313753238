import { AddProductResponse } from '../apiTypes';

export const datasheets = [
    {
        document_url: 'https://www.emerson.com/documents/automation/brochure-appleton-led-luminaires-en-5128410.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/e05c87d25375c24a21968926f47afb350ff0a9de5d48117c51998050e41fdacf.jpeg',
        company_confirm: 4,
        total_confirm: 4,
        is_confirm: false,
        elastic_url: '',
        document_liveness_status: 'OUTDATED',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/selection-guide-led-luminaires-for-atex-iecex-hazardous-locations-appleton-en-6770886.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/a2952217478de167b4d61a176a67ebbb0aae34ffe690fa9608a2fec7ff356844.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'OUTDATED',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 4,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'OUTDATED',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/brochure-appleton-led-luminaires-en-5128410.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/e05c87d25375c24a21968926f47afb350ff0a9de5d48117c51998050e41fdacf.jpeg',
        company_confirm: 4,
        total_confirm: 4,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/selection-guide-led-luminaires-for-atex-iecex-hazardous-locations-appleton-en-6770886.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/a2952217478de167b4d61a176a67ebbb0aae34ffe690fa9608a2fec7ff356844.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/brochure-appleton-led-luminaires-en-5128410.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/e05c87d25375c24a21968926f47afb350ff0a9de5d48117c51998050e41fdacf.jpeg',
        company_confirm: 4,
        total_confirm: 4,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/selection-guide-led-luminaires-for-atex-iecex-hazardous-locations-appleton-en-6770886.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/a2952217478de167b4d61a176a67ebbb0aae34ffe690fa9608a2fec7ff356844.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/brochure-appleton-led-luminaires-en-5128410.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/e05c87d25375c24a21968926f47afb350ff0a9de5d48117c51998050e41fdacf.jpeg',
        company_confirm: 4,
        total_confirm: 4,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/selection-guide-led-luminaires-for-atex-iecex-hazardous-locations-appleton-en-6770886.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/a2952217478de167b4d61a176a67ebbb0aae34ffe690fa9608a2fec7ff356844.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'OUTDATED',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'OUTDATED',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/brochure-appleton-led-luminaires-en-5128410.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/e05c87d25375c24a21968926f47afb350ff0a9de5d48117c51998050e41fdacf.jpeg',
        company_confirm: 4,
        total_confirm: 4,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'UNKNOWN',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/selection-guide-led-luminaires-for-atex-iecex-hazardous-locations-appleton-en-6770886.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/a2952217478de167b4d61a176a67ebbb0aae34ffe690fa9608a2fec7ff356844.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'UNKNOWN',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    },
    {
        document_url: 'https://www.emerson.com/documents/automation/catalog-appleton-atx-demuled-series-luminaires-en-us-185042.pdf',
        is_reported: false,
        pdf_image: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/3e9501abbc43f16dc6bc98d0ec81883e853f2c9ae70dfcba3ed7b0ba31a4c27d.jpeg',
        company_confirm: 0,
        total_confirm: 0,
        is_confirm: false,
        elastic_url: '',
        document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
        document_liveness_status: 'LIVE',
        search_result_metadata: {
            user_name: 'a Parspec User',
            type: 'elastic',
            date: '',
            manufacturer: 'APPLETON ELECTRIC BY EMERSON'
        }
    }
];

export const AddProduct: AddProductResponse = {
    id: 355299,
    document: 176979,
    type: '',
    manufacturer: '',
    model_number: '',
    document_selection_status: 'CONFIRMED',
    description: '',
    bod_id: 361318,
    is_bod_created: true,
    specification: 'Alternate',
    hide_origin_line: true,
    remember_selection: true,
    created_at: '2023-06-22T06:28:34.040079Z',
    updated_at: '2023-06-22T06:28:34.040123Z'
};
