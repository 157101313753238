export const ACCEPTED_FILE_TYPES = ['.pdf'];
export const ACCEPTED_FILE_TYPES_STRING = 'Supported file type: PDF';

export const MAX_ALLOWED_FILE_SIZE_IN_BYTES = 524288000;
export const MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE = 'The uploaded file cannot exceed 500MB. Please upload a smaller file.';
export const MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE_FOR_MULTI_UPLOAD =
    'The total size of uploaded files cannot exceed 500MB, upload a file with a smaller size or delete existing uploads, if any.';

export const QUOTE_COVER_PAGE = 'quote_cover_page';
export const OPENING_NOTES_TEXT = 'opening_notes_text';
export const QUOTE_PRICE_LEAD_TIME = 'quote_price_lead_time';
export const SUMMARIZED_TOTAL = 'summarized_total';
export const QUOTE_HEADER_FOOTER = 'quote_header_footer';
export const PRE_FILL_JOB_NAME = 'pre_fill_job_name';
export const PRE_FILL_QUOTE_NUMBER = 'pre_fill_quote_number';
export const PRE_FILL_BID_DATE = 'pre_fill_bid_date';
export const PRE_FILL_EXPIRATION_DATE = 'pre_fill_expiration_date';
export const PRE_FILL_PREPARED_BY_LOGO = 'pre_fill_preparedby_logo';
export const PRE_FILL_HEADER_LOGO = 'pre_fill_header_logo';
export const PRE_FILL_ADDRESSED_TO = 'pre_fill_addressed_to';
export const PRE_FILL_COVER_PAGE_LOGO = 'pre_fill_coverpage_logo';
export const PRE_FILL_DATE = 'pre_fill_date';
export const PRE_FILL_SECONDARY_TITLE = 'pre_fill_secondary_title';
export const PRE_FILL_PRIMARY_TITLE = 'pre_fill_primary_title';
