export const getRowId = (params: any) => {
    return params?.data?.bomId;
};

export const bomPageTableColumnOptions = {
    data: [
        { field: 'bomName', label: 'Bill of Materials' },
        { field: 'projectName', label: 'Project' },
        { field: 'primary_contact', label: 'Primary Customer' },
        { field: 'secondary_contact', label: 'Secondary Customer' },
        { field: 'projectStage', label: 'Project Stage' },
        { field: 'estimatedProjectCost', label: 'Estimated Value' },
        { field: 'grandTotalCents', label: 'Grand Total' },
        { field: 'branchLocation', label: 'Branch Location' },
        { field: 'quoteDueDate', label: 'Quote Due Date' },
        { field: 'submittalDueDate', label: 'Submittal Due Date' },
        { field: 'omDueDate', label: 'O&M Due Date' },
        { field: 'quoteOwner', label: 'Quote Owner' },
        { field: 'submittalOwner', label: 'Submittal Owner' },
        { field: 'omOwner', label: 'O&M Owner' },
        { field: 'salesAgent', label: 'Outside Sales' },
        { field: 'lastModified', label: 'Last Modified' },
        { field: 'bomCreationDate', label: 'Creation Date' },
        { field: 'quoteStatus', label: 'Quote Status' },
        { field: 'submittalStatus', label: 'Submittal Status' },
        { field: 'omStatus', label: 'O&M Status' }
    ]
};
