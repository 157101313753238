import { useRef, useEffect, useState, useLayoutEffect } from 'react';

import WebViewer from '@pdftron/webviewer';

import { Box } from '@parspec/pixel';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { environment } from 'src/environments';
import { WEBVIEWER_FOLDER_NAME } from '../../../../shared/constants';

interface PdfViewerTypes {
    url?: string;
    isFetching?: boolean;
    isAlertBannerVisible?: boolean;
}

const PdfViewer = ({ url, isFetching, isAlertBannerVisible }: PdfViewerTypes) => {
    const viewer = useRef<HTMLDivElement>(null);
    const [wvInstance, setWvInstance] = useState<any>(null);

    useEffect(() => {
        if (!wvInstance?.Core || !url) {
            return;
        }
        wvInstance.Core.documentViewer.loadDocument(getProxyFileUrl(url), {
            useDownloader: false
        });
    }, [url, wvInstance?.Core]);

    useEffect(() => {
        if (!wvInstance?.UI) {
            return;
        }
        if (isFetching) {
            wvInstance.UI.openElements(['loadingModal']);
        } else {
            wvInstance.UI.closeElements(['loadingModal']);
        }
    }, [isFetching, wvInstance?.UI]);

    useLayoutEffect(() => {
        if (url) {
            WebViewer(
                {
                    licenseKey: environment.Y,
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    disableLogs: true,
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'searchButton',
                        'header',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'annotationPopup',
                        'freeTextToolGroupButton',
                        'contextMenuPopup',
                        'ribbons',
                        'linkAnnotationPopup'
                    ],
                    initialDoc: getProxyFileUrl(url)
                },
                viewer.current as HTMLDivElement
            ).then((instance: any) => {
                setWvInstance(instance);
                const { documentViewer, annotationManager } = instance.Core;
                documentViewer.addEventListener('documentLoaded', () => {
                    annotationManager.enableReadOnlyMode();
                });
            });
        }
    }, []);

    return <Box ref={viewer} sx={{ height: isAlertBannerVisible ? 'calc(100vh - 214px)' : 'calc(100vh - 108px)', width: '100%' }}></Box>;
};

export default PdfViewer;
