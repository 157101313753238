import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button } from '@parspec/pixel';

import { useQuoteContext } from '../compileQuote/QuoteContext';
import { useGetAllBomIds } from './hooks/index';
import { usePushChangesToBomsMutation } from '../compileQuote/queries/index';
import { ATTACHMENTS_SUB_SECTION_NAME, MANUFACTURER_SUB_SECTION_NAME } from '../compileQuote/shared/constants';

interface ApplyToAllBomsButtonProps {
    sectionName?: string;
    subSectionName?: string;
    showIcon?: boolean;
}

const ApplyToAllBomsButton: FC<ApplyToAllBomsButtonProps> = ({ sectionName, subSectionName, showIcon }) => {
    const { projectId } = useParams();
    const { bomId = '', changedSections, handleDataPushSuccess, handleDeleteChangedSections } = useQuoteContext();
    const { bomIds } = useGetAllBomIds(Number(projectId));

    const { mutateAsync: pushToAllBoms, isLoading } = usePushChangesToBomsMutation();

    const showPushIcon = useMemo(() => {
        if (sectionName && subSectionName) {
            const value = changedSections?.[bomId]?.[sectionName as string]?.[subSectionName as string];
            return bomIds.length > 1 && Boolean(value);
        }
        return Boolean(showIcon);
    }, [changedSections]);

    const handlePushClick = async () => {
        if (sectionName && subSectionName) {
            const subSection = [MANUFACTURER_SUB_SECTION_NAME, ATTACHMENTS_SUB_SECTION_NAME].includes(subSectionName) ? '' : subSectionName;
            await pushToAllBoms({ bomId, sectionName: sectionName, subSectionName: subSection });
            handleDataPushSuccess();
            handleDeleteChangedSections(sectionName, subSectionName);
        }
    };

    return (
        <>
            {showPushIcon ? (
                <Box minWidth={isLoading ? 110 : 82}>
                    <Button onClick={() => handlePushClick?.()} color="primary" variant="text" sx={{ textWrap: 'noWrap' }} isLoading={isLoading}>
                        Apply to all
                    </Button>
                </Box>
            ) : null}
        </>
    );
};

export default ApplyToAllBomsButton;
