import { To, useNavigate } from 'react-router-dom';
import { createContext, useState, useContext, PropsWithChildren, useEffect } from 'react';

import { ViewOption } from '../features/Dashboard/queries/apiTypes';
import { useGetUserDashboardViews } from '../features/Dashboard/shared/hooks';

interface IContext {
    hasUnsavedChanges: boolean;
    setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    quoteSelectedView: ViewOption;
    isDashboardViewsLoading: boolean;
}

interface NavigateHandler {
    (path: To): void;
}

const Context = createContext<IContext>({
    hasUnsavedChanges: false,
    setHasUnsavedChanges: () => {},
    quoteSelectedView: 'my_work',
    isDashboardViewsLoading: true
});

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const { dashboardSelectedViews, isDashboardViewsLoading } = useGetUserDashboardViews();

    return <Context.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges, quoteSelectedView: dashboardSelectedViews, isDashboardViewsLoading }}>{children}</Context.Provider>;
};

export function useSelectedQuoteView() {
    const { quoteSelectedView, isDashboardViewsLoading } = useContext(Context);
    return {
        quoteSelectedView,
        isDashboardViewsLoading
    };
}

export function useParspecNavigation() {
    const navigate = useNavigate();
    const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(Context);

    const pNavigate: NavigateHandler = (path) => {
        if (hasUnsavedChanges) {
            const shouldNavigate = window.confirm('Are you sure you want to leave with unsaved changes?');
            if (shouldNavigate) {
                setHasUnsavedChanges(false);
                navigate(path);
            }
        } else {
            navigate(path);
        }
    };

    return { pNavigate, setHasUnsavedChanges, hasUnsavedChanges };
}

const confirmationMessage = '';

export const useUnsavedChangesDetection = () => {
    const { hasUnsavedChanges } = useContext(Context);
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
            return;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);
};
