import { LEAD_TIME_UNITS } from '../../BOM/pricing&LeadTime/shared/constants';
import { convertToDollar } from '../../shared/utils/utils';
import { PricingHistoryPostResponse } from '../queries/apiTypes';
import { PricingHistoryTableDataType } from './types';
import { Customer } from './Customer';

export function getPricingHistoryTableData(pricingHistoryData: PricingHistoryPostResponse) {
    if (!pricingHistoryData.items || pricingHistoryData.items.length === 0) return [];
    const leadTimeUnitObj = Object.entries(LEAD_TIME_UNITS).reduce((acc, [, value]) => {
        acc[value.toLowerCase()] = value;
        return acc;
    }, {} as Record<string, string>);

    const tableData: PricingHistoryTableDataType[] = pricingHistoryData.items.map((item) => {
        const editedAtDateObj = new Date(item.edited_at);
        const editedAtString = editedAtDateObj.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
        return {
            editedOn: editedAtString,
            editedBy: item.edited_by.name,
            qty: item.qty,
            manufacturer: item.manufacturer,
            modelNumber: item.model_number,
            description: item.description,
            cost: item.cost_cents != null ? convertToDollar(item.cost_cents) : null,
            discount: item.discount_percentage,
            discountedCost: item.discounted_cost_cents != null ? convertToDollar(item.discounted_cost_cents) : null,
            margin: item.margin_percentage,
            sellPrice: item.sell_price_cents != null ? convertToDollar(item.sell_price_cents) : null,
            leadTimeUnit: item.lead_time_unit != null ? leadTimeUnitObj[item.lead_time_unit] : null,
            leadTimeValue: item.lead_time_value,
            projectName: item.bom.project.name,
            projectId: item.bom.project.id,
            projectStage: item.bom.project.stage,
            branchLocation: item.bom.project.company.name,
            quoteOwner: item.bom.project.quote_owner,
            bomName: item.bom.name,
            bomId: item.bom.id,
            quoteStatus: item.bom.quote_status,
            salesAgent: item.bom.sales_agent,
            primaryCustomer: item.bom.primary_customer,
            secondaryCustomer: item.bom.secondary_customer
        };
    });

    return tableData;
}

export const PrimaryCustomerTemplate = (props: any) => Customer(props?.data?.primaryCustomer);

export const SecondaryCustomerTemplate = (props: any) => Customer(props?.data?.secondaryCustomer);

export const getLastModifiedFilterText = (args: any, customerType: 'primaryCustomer' | 'secondaryCustomer') => {
    const { company_name, contact_name, business_type, role } = args.data[customerType];
    return `${company_name} ${contact_name} ${business_type} ${role}`;
};

export const stringComparator = (valueA: string, valueB: string) => {
    return valueA?.toLowerCase() > valueB?.toLowerCase() ? 1 : -1;
};

export const dateComparator = (valueA: string, valueB: string) => {
    const dateObj1 = new Date(valueA);
    const dateObj2 = new Date(valueB);

    if (dateObj1 < dateObj2) {
        return -1;
    } else if (dateObj1 > dateObj2) {
        return 1;
    } else {
        return 0;
    }
};

export const customerComparator = (_valueA: string, _valueB: string, nodeA: any, nodeB: any, _isDescending: boolean, customerType: 'primaryCustomer' | 'secondaryCustomer') => {
    const { company_name: companyA, business_type: businessTypeA, contact_name: contactNameA, role: roleA } = nodeA?.data?.[customerType] || {};
    const { company_name: companyB, business_type: businessTypeB, contact_name: contactNameB, role: roleB } = nodeB?.data?.[customerType] || {};

    const strA = `${companyA}${businessTypeA}${contactNameA}${roleA}`;
    const strB = `${companyB}${businessTypeB}${contactNameB}${roleB}`;

    return strA?.toLowerCase() > strB?.toLowerCase() ? 1 : -1;
};
