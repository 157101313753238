import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { environment } from 'src/environments';
interface AxiosRequestConfigWithMetadata<T = unknown> extends AxiosRequestConfig<T> {
    headers: AxiosRequestHeaders;
    metadata?: {
        startTime?: Date;
    };
}
const token = localStorage.getItem('token') || null;

const PUBLIC_ROUTES = [
    '/api/login/',
    '/api/forgot_password/',
    '/api/reset_password/*',
    '*/api/login/sso/identifier',
    '/api/validate_invitation/*',
    '/api/signup/',
    '/api/invitation/*',
    '/api/parspec_operating_regions/',
    '/api/personal_info/',
    '/api/validate_invitation/',
    '*/api/login/sso/authorize'
];

export const isURLMatchHandler = (url: string, routesArr: string[]) => {
    return routesArr.some((route) => {
        if (route.includes('*')) {
            const pattern = route.replace('*', '\\w+');
            const regex = new RegExp(pattern);
            return regex.test(url);
        } else {
            return route === url;
        }
    });
};

const performanceQueryObjects = (latency: number, method: string, url: string, startTime: string, requestId: string) => {
    return {
        api_latency_ms: latency,
        api_req_method_type: '"' + method + '"',
        api_req_url: '"' + url + '"',
        created_at: '"' + startTime + '"',
        api_ui_latency_ms: 0,
        country: '"USA"',
        request_id: '"' + requestId + '"'
    };
};

const getAxiosInstance = (apiUrl: string) => {
    const instance = axios.create({
        baseURL: apiUrl,
        headers: {
            Authorization: `Token ${token}`
        }
    });

    instance.interceptors.request.use(
        (config: AxiosRequestConfigWithMetadata) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Token ${token}`;
            }

            if (isURLMatchHandler(config.url as string, PUBLIC_ROUTES)) {
                delete config.headers.Authorization;
            }

            config.headers['X-Request-Id'] = uuidV4();
            config.metadata = { startTime: new Date() };
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use((response: AxiosResponse & { config: AxiosRequestConfigWithMetadata }) => {
        const { config } = response;
        const endTime = new Date();
        const startTime = config.metadata?.startTime || endTime;
        const duration = endTime.getTime() - startTime.getTime();
        const storedApis = JSON.parse(localStorage.getItem('fe_event_service_performance_api_list') || '[]');
        storedApis.push(performanceQueryObjects(duration, config.method?.toUpperCase() || '', config.url || '', startTime.toISOString(), config.headers['X-Request-Id']));
        localStorage.setItem('fe_event_service_performance_api_list', JSON.stringify(storedApis));

        return response;
    });

    return instance;
};

const generalInstance = getAxiosInstance(environment.b);

const reportingAxiosInstance = getAxiosInstance(environment.o);

export { generalInstance as axios, reportingAxiosInstance };
