import { Activity } from '../Preview/queries/apiTypes';

export function parsecActivity(activity: Activity) {
    const date = new Date(activity.time + 'Z');
    const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    return {
        ...activity,
        time
    };
}
