import { ICellRendererParams } from 'ag-grid-community';

import { convertToDollar } from '../../shared/utils/utils';
import { ProjectInfoForDashboard } from '../queries/apiTypes';
import { ProjectRowData } from './types';

export function getProjectRowData(apiData: ProjectInfoForDashboard): ProjectRowData {
    const submittalDueDate = apiData.submittal_due_date;
    const omDueDate = apiData.o_m_due_date;
    const quoteDueDate = apiData.quote_due_date;

    return {
        projectStage: apiData.stage,
        branchLocation: apiData.company_name,
        projectId: apiData.id,
        projectName: apiData.name,
        submittalDueDate,
        omDueDate,
        quoteDueDate,
        estimatedProjectCost: apiData.estimated_project_cost,
        quoteOwner: apiData.quote_owner_name,
        submittalOwner: apiData.submittal_owner_name,
        omOwner: apiData.o_m_owner_name,
        address1: apiData.address1,
        address2: apiData.address2,
        country: apiData.country,
        city: apiData.city,
        state: apiData.state,
        zip: apiData.zip,
        buildingType: apiData.building_type,
        constructionScope: apiData.construction_scope,
        size: apiData.size,
        stakeholders: apiData.stakeholders
    };
}

export const dollarFormatter = (value: any) => {
    const dollarCost = convertToDollar(value);
    const formattedNumber = dollarCost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    });

    if (value === 0) {
        return '$0';
    }

    return value ? `${formattedNumber}` : '-';
};

export const getEstimatedValueTemplate = (props: ICellRendererParams) => {
    const { data } = props;
    return dollarFormatter(data?.estimatedProjectCost);
};

export const grandTotalValueTemplate = (props: ICellRendererParams) => {
    const { data } = props;
    return dollarFormatter(data?.grandTotalCents);
};

export const getRowId = (params: any) => {
    return params?.data?.projectId;
};

export const projectPageTableColumnOptions = {
    data: [
        { field: 'projectName', label: 'Project Name' },
        { field: 'projectStage', label: 'Project Stage' },
        { field: 'estimatedProjectCost', label: 'Estimated Value' },
        { field: 'branchLocation', label: 'Branch Location' },
        { field: 'quoteDueDate', label: 'Quote Due Date' },
        { field: 'submittalDueDate', label: 'Submittal Due Date' },
        { field: 'omDueDate', label: 'O&M Due Date' },
        { field: 'quoteOwner', label: 'Quote Owner' },
        { field: 'submittalOwner', label: 'Submittal Owner' },
        { field: 'omOwner', label: 'O&M Owner' },
        { field: 'address1', label: 'Address Line 1' },
        { field: 'address2', label: 'Address Line 2' },
        { field: 'country', label: 'Country' },
        { field: 'city', label: 'City' },
        { field: 'state', label: 'State' },
        { field: 'zip', label: 'Zip' },
        { field: 'buildingType', label: 'Market vertical' },
        { field: 'constructionScope', label: 'Construction Scope' },
        { field: 'size', label: 'Square Footage' },
        { field: 'stakeholders[0]', label: 'Stakeholder #1' },
        { field: 'stakeholders[1]', label: 'Stakeholder #2' },
        { field: 'stakeholders[2]', label: 'Stakeholder #3' },
        { field: 'stakeholders[3]', label: 'Stakeholder #4' },
        { field: 'stakeholders[4]', label: 'Stakeholder #5' }
    ]
};
