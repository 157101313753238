import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, ModalHeader, Box, BodySmall, Checkbox, TextField, ModalFooter } from '@parspec/pixel';

import { productRowDetails } from '../../constants';
import { useProductFeedbackMutation } from '../../queries';
import { Document } from 'src/features/shared/DocumentSuggestions/DocumentItem';
import { SUCCESSFULLY_FEEDBACK_SUBMITION, someThingWentWrongMsg } from 'src/features/shared/constants';
import { useGetDatasheetQuery, useGetSelectedDocumentQuery } from '../../../../Datasheets/AnnotateDatasheet/queries';
interface ProductFeedback {
    open: boolean;
    onCloseClick: () => void;
    item: Document;
    total_results: number;
    index: number;
    setSnackbarMessage: (str: string) => void;
}
const ProductFeedback = ({ open, onCloseClick, item, total_results, index, setSnackbarMessage }: ProductFeedback) => {
    const suggestions = [...(item?.tags || [])];
    const initialStateObject: { [key: string]: boolean } = suggestions.reduce((acc, prev) => ({ ...acc, [prev]: false }), {});
    const [checkBoxState, setCheckBoxState] = useState(initialStateObject);
    const [aestheticsComment, setAestheticsComment] = useState<string>('');
    const [otherComment, setOtherComment] = useState<string>('');
    const [validationError, setValidationError] = useState(false);
    const { mutateAsync: fetchProductselectionHistory, isLoading } = useProductFeedbackMutation();
    const { bomId, productId: bodId } = useParams();
    const header = <ModalHeader title="Suggestion Quality Feedback" onClose={onCloseClick} />;

    const { data: dataSheetData } = useGetSelectedDocumentQuery(`${bomId}`, `${bodId}`, 'submittal', 'datasheet', {});
    const selectedDatasheetId = dataSheetData?.data[0].id || 0;
    const datasheetId = dataSheetData?.data[0].document || 0;
    const { data: datasheetFileData } = useGetDatasheetQuery(`${bomId}`, `${bodId}`, selectedDatasheetId, datasheetId, 'submittal', 'datasheet', {
        enabled: Boolean(dataSheetData?.data[0].id)
    });
    const onCheckboxHandle = (key: string) => {
        setCheckBoxState({ ...checkBoxState, [key]: !checkBoxState[key] });
        if (validationError) setValidationError(false);
    };

    const onHandleRemove = async () => {
        const isCheckBoxSelected = Object.values(checkBoxState)?.includes(true);
        try {
            if (otherComment.length > 0 || aestheticsComment.length > 0 || isCheckBoxSelected) {
                setValidationError(false);
                await fetchProductselectionHistory({
                    bomId: bomId || '',
                    productId: bodId || '',
                    payload: {
                        document_url: item.document_url || '',
                        content_hash: item?.content_hash,
                        total_results,
                        mismatched_attributes: Object.keys(checkBoxState).filter((key) => checkBoxState[key]),
                        aesthetics_comments: aestheticsComment,
                        other_comments: otherComment,
                        rank: index + 1,
                        source_pdf: datasheetFileData?.data.flat_filepath || ''
                    }
                });
                setSnackbarMessage(SUCCESSFULLY_FEEDBACK_SUBMITION);
                onCloseClick();
            } else {
                setValidationError(true);
            }
        } catch {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };
    const footer = (
        <ModalFooter
            onReject={onCloseClick}
            helperText={validationError ? <BodySmall color="error">Please select at least one reason for dismissing this result.</BodySmall> : null}
            onAccept={onHandleRemove}
            continueButtonLabel="Submit"
            isLoading={isLoading}
        />
    );

    return (
        <>
            <Modal open={open} header={header} footer={footer}>
                <Box>
                    <BodySmall lines={3} color="text.secondary" mb={2}>
                        Please indicate the attribute(s) that were incorrectly matched, or provide a reason as to why this was a poor suggestion.
                    </BodySmall>
                    <Box display={'flex'} flexDirection={'column'} maxHeight={'382px'} overflow={'auto'}>
                        {productRowDetails.map(({ key, label }) =>
                            (item.tags || []).includes(key) && key !== 'acceptable_manufacturers' ? (
                                <Checkbox label={(item.tags || []).includes(key) && label} checked={checkBoxState.key} onClick={() => onCheckboxHandle(key)} />
                            ) : null
                        )}
                        <BodySmall fontWeight="600" color="neutral.dark" lines={3}>
                            If there are aesthetics requirements this product does not meet, please describe them here.
                        </BodySmall>
                        <TextField
                            sx={{ marginTop: 2, marginBottom: 5, pr: 2 }}
                            maxRows={10}
                            multiline
                            rows={4}
                            inputProps={{
                                maxLength: 255
                            }}
                            label=""
                            onChange={(text) => {
                                if (text?.target?.value?.trim()) setAestheticsComment(text?.target?.value?.trim());
                            }}
                            placeholder="ex: ‘Color, finish, overall look, not matching my requirements’"
                        />
                        <BodySmall fontWeight="600" color="neutral.dark" lines={3}>
                            If there are any additional requirements this product does not meet, please describe them here.
                        </BodySmall>
                        <TextField
                            sx={{ marginTop: 2, pr: 2 }}
                            maxRows={10}
                            multiline
                            rows={4}
                            inputProps={{
                                maxLength: 255
                            }}
                            label=""
                            onChange={(text) => {
                                if (text?.target?.value?.trim()) setOtherComment(text?.target?.value?.trim());
                            }}
                            placeholder="ex: ‘Dimensions not matching my requirements’, ‘Certification not matching my requirements’"
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ProductFeedback;
