import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Autocomplete, Box, OptionType, TextField, DeleteIcon, IconButton, Skeleton, Tooltip, Status, BodyXS } from '@parspec/pixel';

import { useParspecMfg } from './queries';
import { MfgStatusLabelsEnum, getMfgStatusColor } from '../../shared/constants';
import { specialCharactersRegex, websiteValidationRegex } from 'src/features/shared/utils/utils';
import ManufacturerSearch from './ManufacturerSearch';
import { searchManufacturer } from '../../shared/utils/utils';
import { ICompanyManufacturerType, ManufacturerUploadResponse, ParspecMfgDetailsResponse } from './queries/apiTypes';
import { ManufacturerDropdownType } from '../../BOM/productFinder/setRequirements/types';

interface VerifyManufacturersDialogProp {
    card: ManufacturerUploadResponse;
    updateValues: (card: ICompanyManufacturerType) => void;
    cardDeleted: () => void;
    actionEdit: boolean;
    catalogPopup?: boolean;
}
interface IErrorField {
    parspecIdErr: boolean;
    websiteErr: string;
    abbrErr?: string;
}
interface ICardFields {
    mfgId: number | null;
    mfgName: string | null;
    abbreviation: string | string[];
    parspecId: string | null;
    website: string | null;
    suppStatus: string;
    erpCode: string | null;
}
const ABBREVATION_ERROR_TYPES = {
    DUPLICATE: 'Duplicate abbreviations',
    SPECIAL_CHARACTERS: 'Special characters not allowed'
};
const VerifyManufacturer = (props: VerifyManufacturersDialogProp) => {
    const { card, updateValues, cardDeleted, actionEdit, catalogPopup = false } = props;

    const { data: companyMfgData, isLoading: isMfgListLoading } = useParspecMfg();

    const [mfgNameList, setMfgNameList] = useState<OptionType<ParspecMfgDetailsResponse>[]>([]);
    const [formError, setFormError] = useState<IErrorField>({
        parspecIdErr: false,
        websiteErr: '',
        abbrErr: ''
    });
    const [formValues, setFormValues] = useState<ICardFields>({
        mfgId: card.manufacturer_id,
        mfgName: null,
        abbreviation: card.abbreviations,
        parspecId: '',
        website: card.website,
        suppStatus: card.status,
        erpCode: card.erp_code
    });
    const [formLoading, setFormLoading] = useState(true);
    const initialValueSet = useRef(false);

    useEffect(() => {
        if (companyMfgData?.data.length) {
            setMfgNameList([...(companyMfgData.data as OptionType<ParspecMfgDetailsResponse>[])]);
        }
        initialValueSet.current = true;
        setFormValues({
            mfgId: card.manufacturer_id,
            abbreviation: card.abbreviations,
            website: card.website,
            suppStatus: card.status,
            mfgName: card.manufacturer_name,
            parspecId: card.parspec_id,
            erpCode: card.erp_code || null
        });
        let regexError: boolean;
        if (typeof card.abbreviations === 'string') {
            regexError = !specialCharactersRegex.test(card.abbreviations);
        } else {
            regexError = card.abbreviations.some((abbr) => !specialCharactersRegex.test(abbr));
        }
        let isAbbrInvalid = '';
        if (card?.conflicts && Object.keys(card.conflicts).length !== 0) {
            isAbbrInvalid = ABBREVATION_ERROR_TYPES.DUPLICATE;
        } else if (regexError) {
            isAbbrInvalid = ABBREVATION_ERROR_TYPES.SPECIAL_CHARACTERS;
        }
        setFormError({
            ...formError,
            abbrErr: isAbbrInvalid
        });
        setFormLoading(false);
    }, [companyMfgData, card, formLoading]);

    useEffect(() => {
        if (formValues.mfgName) {
            if (!formValues.website) {
                setFormError({
                    ...formError,
                    websiteErr: 'warning'
                });
            } else if (!websiteValidationRegex().test(formValues.website.trim())) {
                setFormError({
                    ...formError,
                    websiteErr: 'required'
                });
            }
        }
        valuesUpdatedCheck();
    }, [formValues]);

    const valuesUpdatedCheck = () => {
        let payloadAbbreviation: string | string[];
        if (typeof formValues.abbreviation === 'string') {
            if (formValues.abbreviation.trim()) {
                const newAbbr = formValues.abbreviation.split(',');
                payloadAbbreviation = newAbbr.filter((n) => n.trim());
            } else {
                payloadAbbreviation = [];
            }
        } else {
            payloadAbbreviation = formValues.abbreviation;
        }

        const cardValues = {
            manufacturer_name: formValues.mfgName,
            parspec_id: formValues.parspecId || null,
            manufacturer_id: formValues.mfgId || null,
            abbreviations: payloadAbbreviation,
            website: formValues.website?.trim() || null,
            status: formValues.suppStatus,
            erp_code: formValues.erpCode?.trim() || null,
            ...(card.company_group_man_id && { company_group_man_id: card.company_group_man_id })
        };
        updateValues(cardValues);
    };
    // Need to check value is correct or not
    const onInputChange = (_event: React.SyntheticEvent<Element, Event>, value: string) => {
        if (initialValueSet.current) {
            initialValueSet.current = false;
            return;
        }

        if (value?.length > 0) {
            setFormValues({
                ...formValues,
                mfgName: value
            });
        } else {
            setFormValues({
                ...formValues,
                mfgName: null,
                parspecId: '',
                mfgId: null,
                website: '',
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                parspecIdErr: false,
                websiteErr: ''
            });
        }
    };

    const handleMfgNameChange = (event: any) => {
        if (event.target.value) {
            const { manufacturer_id, parspec_id, website: selectedWebsite, status } = event.target.value;
            setFormValues({
                ...formValues,
                mfgName: parspec_id,
                parspecId: parspec_id,
                mfgId: manufacturer_id,
                website: selectedWebsite,
                suppStatus: status
            });
            setFormError({
                ...formError,
                parspecIdErr: false,
                websiteErr: ''
            });
        } else {
            setFormError({
                ...formError,
                parspecIdErr: false,
                websiteErr: ''
            });
        }
    };

    const handleMfgNameBlur = () => {
        if (formValues.mfgName && !formValues.parspecId) {
            setFormValues({
                ...formValues,
                mfgId: null,
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                parspecIdErr: true,
                websiteErr: 'warning'
            });
        }
    };

    const handleParpsecIdChange = (value?: ParspecMfgDetailsResponse) => {
        if (value) {
            const { parspec_id, website: selectedWebsite, status, manufacturer_id } = value;
            setFormValues({
                ...formValues,
                parspecId: parspec_id,
                mfgId: manufacturer_id,
                website: selectedWebsite,
                suppStatus: status
            });
            setFormError({
                ...formError,
                websiteErr: ''
            });
        } else {
            setFormValues({
                ...formValues,
                parspecId: null,
                mfgId: null,
                website: '',
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                websiteErr: 'warning'
            });
        }
    };

    const onAbbrBlur = (event: any) => {
        if (specialCharactersRegex.test(event.target.value)) {
            setFormError({
                ...formError,
                abbrErr: ''
            });
        } else {
            setFormError({
                ...formError,
                abbrErr: ABBREVATION_ERROR_TYPES.SPECIAL_CHARACTERS
            });
        }
    };

    const handleWebsiteChange = (event: any) => {
        if (event.target.value.trim()) {
            const selectedWebsite = event.target.value;
            const matchingMfg = companyMfgData?.data.find((item) => item.website.toLowerCase() === selectedWebsite);
            if (matchingMfg) {
                setFormValues({
                    ...formValues,
                    parspecId: matchingMfg.parspec_id,
                    mfgId: matchingMfg.manufacturer_id,
                    suppStatus: matchingMfg.status
                });
                setFormError({
                    ...formError,
                    websiteErr: ''
                });
            } else if (websiteValidationRegex().test(selectedWebsite.trim())) {
                setFormValues({
                    ...formValues,
                    parspecId: null,
                    mfgId: null,
                    suppStatus: 'Requested'
                });
                setFormError({
                    ...formError,
                    websiteErr: ''
                });
            } else {
                setFormValues({
                    ...formValues,
                    parspecId: null,
                    mfgId: null,
                    suppStatus: 'Not Recognized'
                });
                setFormError({
                    ...formError,
                    websiteErr: 'required'
                });
            }
        } else {
            setFormValues({
                ...formValues,
                parspecId: null,
                website: '',
                mfgId: null,
                suppStatus: 'Not Recognized'
            });
            setFormError({
                ...formError,
                websiteErr: 'warning'
            });
        }
    };

    const filterHandler = (options: OptionType<ManufacturerDropdownType>[] | { [index: string]: string | number; }[], params: { inputValue: string }) => {
        return searchManufacturer(options as OptionType<ManufacturerDropdownType>[], params.inputValue.trim());
    };

    return (
        <>
            <Box display={'flex'} marginTop={2}>
                <Box
                    width={250}
                    marginRight={4}
                    sx={{
                        '.MuiFormHelperText-root': {
                            margin: '0px !important'
                        }
                    }}
                >
                    {!(isMfgListLoading || formLoading) ? (
                        <Autocomplete
                            value={formValues.mfgName}
                            options={mfgNameList}
                            onChange={handleMfgNameChange}
                            placeholder="Type a manufacturer..."
                            onBlur={handleMfgNameBlur}
                            onTextFieldChange={onInputChange}
                            id="mfgName"
                            label=""
                            optionlabelkeyname="parspec_id"
                            helperText={catalogPopup ? '' : formValues.mfgName ? '' : 'Required'}
                            error={!catalogPopup && !formValues.mfgName}
                            filterOptionsCallBack={filterHandler}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Box>
                <Box
                    width={240}
                    marginRight={4}
                    sx={{
                        '.MuiFormHelperText-root': {
                            margin: '0px !important'
                        }
                    }}
                >
                    {!formLoading ? (
                        <TextField
                            value={formValues.erpCode}
                            label=""
                            placeholder="Enter Manufacturer ERP Code..."
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFormValues({
                                    ...formValues,
                                    erpCode: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Box>
                <Box
                    width={200}
                    marginRight={4}
                    sx={{
                        '.MuiFormHelperText-root': {
                            margin: '0px !important'
                        }
                    }}
                >
                    {!formLoading ? (
                        <TextField
                            value={formValues.abbreviation}
                            label=""
                            placeholder="Enter abbreviation..."
                            onBlur={onAbbrBlur}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFormValues({
                                    ...formValues,
                                    abbreviation: e.target.value.toUpperCase()
                                });
                            }}
                            helperText={formError.abbrErr}
                            error={Boolean(formError.abbrErr)}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Box>
                <Box width={250} marginRight={4}>
                    {!formLoading ? (
                        <ManufacturerSearch
                            parspecId={formValues.parspecId}
                            handleParpsecIdChange={handleParpsecIdChange}
                            placeholder="View Suggestions..."
                            label=""
                            disabled={!catalogPopup && !formValues.mfgName}
                            showWarning={formError.parspecIdErr}
                            showHelperText={false}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Box>
                <Box
                    width={200}
                    marginRight={4}
                    sx={{
                        '.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: formError.websiteErr === 'warning' ? '#FFA72B !important' : ''
                        },
                        '.MuiFormHelperText-root': {
                            margin: '0px !important'
                        }
                    }}
                >
                    {!formLoading ? (
                        <Tooltip
                            title={formError.websiteErr === 'warning' ? 'Can’t find a matching Parpsec ID? Add the manufacturer’s website to request support.' : ''}
                            placement={'top'}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'warning.light',
                                        color: 'warning.main'
                                    }
                                }
                            }}
                        >
                            <TextField
                                value={formValues.website}
                                label=""
                                placeholder="Enter website..."
                                onBlur={handleWebsiteChange}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFormValues({
                                        ...formValues,
                                        website: e.target.value
                                    });
                                }}
                                helperText={formError.websiteErr === 'required' ? 'Invalid url' : ''}
                                error={Boolean(formError.websiteErr)}
                                disabled={!catalogPopup && !formValues.mfgName}
                            />
                        </Tooltip>
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Box>
                <Box width={140} marginTop={'7px'}>
                    <Tooltip
                        title={formValues.suppStatus === MfgStatusLabelsEnum.NOT_RECOGNIZED ? 'Browse suggested Parspec IDs or add a website to request this manufacturer to be supported.' : ''}
                        placement="top"
                    >
                        <Box width="fit-content">
                            <Status color={getMfgStatusColor(formValues.suppStatus)}>
                                <BodyXS color="inherit">{formValues.suppStatus}</BodyXS>
                            </Status>
                        </Box>
                    </Tooltip>
                </Box>
                {!actionEdit && (
                    <IconButton
                        onClick={() => {
                            cardDeleted();
                        }}
                        sx={{ height: '36px' }}
                    >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                )}
            </Box>
        </>
    );
};

export default VerifyManufacturer;
