import { useParams } from 'react-router-dom';

import { DocumentType } from '../constants';
import CompileDocument from './submittals&o_m';
import CompileQuote from './compileQuote';
import { TrackActivityDurationWrapper } from '../../../shared/UserActivityTracking/TrackActivityDuration';

function Compile() {
    const { documentType } = useParams();
    return <>{documentType === DocumentType.QUOTE ? <TrackActivityDurationWrapper activityType={'CustomizeQuotePage'}><CompileQuote /></TrackActivityDurationWrapper> : <TrackActivityDurationWrapper activityType={'CompileDocument'}><CompileDocument /></TrackActivityDurationWrapper>}</>;
}

export default Compile;
