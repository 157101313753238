import { ProductRequirementsResponse } from '../apiTypes';

export const mockGetProductRequirements: ProductRequirementsResponse = [
    {
        id: 1,
        field_name: 'acceptable_manufacturers',
        field_value: ['Aculux', '3G', 'CADET', '1000 BULBS', '1254 INDUSTRIES'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 2,
        field_name: 'product_category',
        field_value: ['Architectural', 'Downlight', 'Industrial'],
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 3,
        field_name: 'mounting_type',
        field_value: ['Arm', 'Wall', 'Floor'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 4,
        field_name: 'environment',
        field_value: ['Outdoor', 'Damp', 'Wet'],
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 5,
        field_name: 'dimming_protocol',
        field_value: ['0-10V', 'PWM'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 6,
        field_name: 'dimming_range',
        field_value: ['Bi-Level', 'Step Dim'],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 7,
        field_name: 'voltage',
        field_value: ['12V', '24V', '12-24V'],
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 9,
        field_name: 'lumens',
        field_value: [10, 9500],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 10,
        field_name: 'lumens_unit',
        field_value: 'Lumens',
        is_locked: false,
        bod_id: 7479
    },
    {
        id: 11,
        field_name: 'cri',
        field_value: [20, 70],
        is_locked: true,
        bod_id: 7479
    },
    {
        id: 12,
        field_name: 'temperature',
        field_value: [30, 690],
        is_locked: false,
        bod_id: 7479
    } // add more mock product requirements as necessary
];
