import { useState, useEffect } from 'react';

import { Box, Skeleton, EditIcon, TextField, H6 } from '@parspec/pixel';

import { Errors } from '../../../shared/utils/utils';
import { useGetProjectDetailsQuery } from '../../../Project/queries';

interface HeaderProps {
    saveProjectName: (name: string) => Promise<void>;
    formErrors: Errors;
    onError: (error: Errors) => void;
    projectId: number;
}

const ProjectName: React.FC<HeaderProps> = ({ saveProjectName, formErrors, onError, projectId }) => {
    const [isEditProjectName, setIsEditProjectName] = useState(false);
    const [projectName, setProjectName] = useState('');

    const { data: projectData, isLoading } = useGetProjectDetailsQuery(Number(projectId)!, {
        enabled: Boolean(projectId)
    });

    useEffect(() => {
        if (projectData?.data?.name) {
            setProjectName(projectData.data.name);
        }
    }, [projectData?.data?.name]);

    async function handleOnBlur() {
        if (!projectName.trim()) {
            onError({ ...formErrors, projectNameError: 'Required' });
            return;
        }
        await saveProjectName(projectName);
        setIsEditProjectName(false);
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        onError({ ...formErrors, projectNameError: '' });
        setProjectName(e.target.value);
    }

    function handleOnClick() {
        if (formErrors.projectNameError) {
            return;
        }
        setIsEditProjectName((prev) => !prev);
    }

    const onPressEnter = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            handleOnBlur();
        }
    };

    return (
        <Box borderBottom="1px solid" borderColor="neutral.main">
            <Box display="flex" alignItems="center" gap={2} px={6} py={4} height="50px">
                {isLoading ? (
                    <Box py={2}>
                        <Skeleton variant="rectangular" height={32} width={335} />
                    </Box>
                ) : (
                    <>
                        {isEditProjectName ? (
                            <TextField
                                error={Boolean(formErrors.projectNameError)}
                                helperText={formErrors.projectNameError}
                                label={''}
                                value={projectName}
                                onBlur={handleOnBlur}
                                onChange={handleOnChange}
                                onKeyDown={onPressEnter}
                            />
                        ) : (
                            <H6>{projectName}</H6>
                        )}
                        <EditIcon sx={{ cursor: 'pointer' }} onClick={handleOnClick} />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ProjectName;
