import { useState, useRef } from 'react';

import { Box, BodyBig, BodySmall, TextField, Button, InfoIcon } from '@parspec/pixel';

import { useForgotPasswordMutation } from '../queries';
import { validateEmail } from '../../Settings/UserManagement/utils/utils';

export interface TextFieldError {
    isError: boolean;
    title: string;
}

export interface ErrorStateType {
    [key: string]: TextFieldError;
    email: TextFieldError;
}

interface ForgotPasswordDataStateType {
    email: string;
}

export const ForgotPassword = () => {
    const [forgotPasswordData, setForgotPasswordData] = useState<ForgotPasswordDataStateType>({ email: '' });
    const [errorState, setErrorState] = useState<ErrorStateType>({
        email: {
            isError: false,
            title: ''
        }
    });

    const [showForgotPasswordSuccessScreen, setShowForgotPasswordSuccessScreen] = useState<boolean>(false);

    const { mutateAsync: forgotPasswordMutation, isLoading } = useForgotPasswordMutation();

    const formRef = useRef<HTMLFormElement>(null);

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name: fieldName, value: fieldValue } = event.target;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName)) {
            return;
        }

        if (errorState[fieldName].isError) {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
            });
        }
        if (fieldValue === '') {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
            });
        }

        setForgotPasswordData(() => ({ ...forgotPasswordData, [fieldName]: fieldValue }));
    };

    const emailFieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const eventType = event.type;

        if (forgotPasswordData.email.length > 0 && eventType === 'blur') {
            if (!validateEmail(forgotPasswordData.email)) {
                return setErrorState({
                    ...errorState,
                    email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
                });
            }
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const emailFlag = forgotPasswordData.email.length === 0;

        if (emailFlag) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: emailFlag, title: `${emailFlag ? 'Required' : ''}` }
            });
        }

        if (!validateEmail(forgotPasswordData.email)) {
            return setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
            });
        }

        const payload = {
            email: forgotPasswordData.email
        };

        const resp = await forgotPasswordMutation(payload);

        if (resp.status === 201) {
            setShowForgotPasswordSuccessScreen(true);
        } else if (resp.status === 204) {
            setErrorState({
                ...errorState,
                email: { ...errorState.email, isError: true, title: 'Email not registered' }
            });
        }
    };

    return (
        <Box
            width="100vw"
            height="100vh"
            bgcolor="#091535"
            sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(assets/images/login/login-bg.png) `
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" mb={14}>
                    <Box height="45px" width="45px" mr={3}>
                        <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                    </Box>

                    <Box>
                        <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                    </Box>
                </Box>
                {!showForgotPasswordSuccessScreen ? (
                    <Box bgcolor="secondary.contrastText" width="350px" padding={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2}>
                            <BodyBig limit={false} fontWeight={500}>
                                Forgot Password
                            </BodyBig>
                            <BodySmall limit={false} my={6}>
                                We’ll get you a new password in no time!
                            </BodySmall>
                        </Box>

                        <Box>
                            <form onSubmit={onSubmitHandler} autoComplete={'off'} ref={formRef}>
                                <Box height={'72px'}>
                                    <TextField
                                        required
                                        id={'email'}
                                        name={'email'}
                                        autoComplete={'email'}
                                        label={'Email'}
                                        value={forgotPasswordData.email}
                                        error={errorState.email.isError}
                                        helperText={errorState.email.title}
                                        onKeyDown={keydownHandler}
                                        onBlur={emailFieldBlurHandler}
                                        onChange={fieldChangeHandler}
                                    />
                                </Box>
                            </form>
                        </Box>

                        <Box width="100%">
                            <Button onClick={onSubmitHandler} isLoading={isLoading} fullWidth={true}>
                                Reset Password
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box bgcolor="secondary.contrastText" width="360px" padding={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <BodyBig limit={false} fontWeight={500} mb={2}>
                                Password Reset E-mail Sent
                            </BodyBig>
                            <BodySmall limit={false} my={4} textAlign={'center'}>
                                A link has been sent to your email, Please follow the link to reset your password!
                            </BodySmall>
                        </Box>

                        <Box bgcolor={'primary.light'} display={'flex'} alignItems={'flex-start'} p={2}>
                            <Box color={'primary.main'}>
                                <InfoIcon fontSize={'small'} />
                            </Box>
                            <Box ml={2}>
                                <BodySmall limit={false} color={'primary.main'}>
                                    Trouble signing up? Message us in the
                                </BodySmall>
                                <BodySmall limit={false} color={'primary.main'}>
                                    chat or email us at support@parspec.io
                                </BodySmall>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
