import { Box, Skeleton } from '@parspec/pixel';

const FormSkeleton = () => {
    return (
        <>
            <Box
                display="flex"
                width="100%"
                columnGap={5}
                sx={{
                    '.Mui-error': {
                        fontSize: '12px'
                    }
                }}
            >
                <Box display="flex" width="25%" flexDirection="column" rowGap={6}>
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                </Box>
                <Box display="flex" width="25%" flexDirection="column" rowGap={6}>
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Box display="flex" columnGap={3}>
                        <Skeleton height="36px" variant="rectangular" />
                        <Skeleton height="36px" variant="rectangular" />
                    </Box>
                </Box>
                <Box display="flex" width="28%" flexDirection="column">
                    <Skeleton height="18px" variant="rectangular" />
                    <Skeleton height="95px" variant="rectangular" />
                </Box>
                <Box display="flex" width="22%" flexDirection="column" height="50%">
                    <Skeleton height="18px" variant="rectangular" />

                    <Skeleton height="100px" variant="rectangular" />
                </Box>
            </Box>
            <Box width="100%" mt={6}>
                <Skeleton height="77px" variant="rectangular" />
            </Box>
        </>
    );
};

export default FormSkeleton;
