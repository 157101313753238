import { axios } from 'src/app/Axios';

import { environment } from 'src/environments';

import {
    SignUpValidateInvitationResponse,
    InvitedUserSignupRequest,
    NewUserSignupRequest,
    SignInRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    ResetPasswordMailTokenValidationResponse,
    HubspotTokenRequest,
    NewUserSignUpVerifyInvitationResponse,
    NewUserSignupAddCompanyInfoRequest,
    NewUserPersonalInfoSignupRequest,
    SSOAuthenticateRequest
} from './apiTypes';

export const endPoints = {
    signin: '/api/login/',
    forgotPassword: '/api/forgot_password/',
    resetPassword: (token: string) => `/api/reset_password/${token}/`,
    hubspotTokenRequest: `/submittal/hubspot_token_request/`,
    validateInvitedUserSignupInvitation: (token: string) => `/api/validate_invitation/${token}/`,
    signupInvitedUser: '/api/validate_invitation/',
    signupNewUser: '/api/signup/',
    validateNewUserSignupInvitation: (token: string) => `/api/invitation/${token}/`,
    parspecOperatingRegions: '/api/parspec_operating_regions/',
    personalInfo: '/api/personal_info/',
    ssoIdentifier: (url: string) => `${url}/api/login/sso/identifier`,
    ssoAuthenticate: (url: string) => `${url}/api/login/sso/authorize`
};

export const postSignInData = async (data: SignInRequest) => {
    return axios.post(endPoints.signin, data);
};
export const postForgotPassword = async (data: ForgotPasswordRequest) => {
    return axios.post(endPoints.forgotPassword, data);
};

export const postResetPassword = async (props: { token: string; data: ResetPasswordRequest }) => {
    return axios.post(endPoints.resetPassword(props.token), props.data);
};

export const getResetPassword = async (token: string) => {
    return axios.get<ResetPasswordMailTokenValidationResponse>(endPoints.resetPassword(token));
};
export const postHubspotTokenRequest = async (data: HubspotTokenRequest) => {
    return axios.post(endPoints.hubspotTokenRequest, data);
};

export const invitedUserSignupTokenValidation = async (token: string) => {
    return axios.get<SignUpValidateInvitationResponse>(endPoints.validateInvitedUserSignupInvitation(token));
};

export const postInvitedUserSignUpData = async (data: InvitedUserSignupRequest) => {
    return axios.post(endPoints.signupInvitedUser, data);
};

export const signupUser = async (data: NewUserSignupRequest) => {
    return axios.post(endPoints.signupNewUser, data);
};

export const newUserSignupTokenValidation = async (token: string) => {
    return axios.get<NewUserSignUpVerifyInvitationResponse>(endPoints.validateNewUserSignupInvitation(token));
};

export const newUserSignupAddCompanyInfo = async (data: NewUserSignupAddCompanyInfoRequest) => {
    return axios.post(endPoints.parspecOperatingRegions, data);
};

export const newUserSignupAddPersonalInfo = async (data: NewUserPersonalInfoSignupRequest) => {
    return axios.post(endPoints.personalInfo, data);
};

export const postSSOIdentifierRequest = async () => {
    return axios.post(endPoints.ssoIdentifier(environment.b));
};

export const postSSOAuthenticationRequest = async (data: SSOAuthenticateRequest) => {
    return axios.post(endPoints.ssoAuthenticate(environment.b), data);
};

export const logOut = () => axios.post<object>('/api/logout/');
