import { ReactNode, useState, useMemo, useEffect, useCallback, forwardRef, useRef, useImperativeHandle, MutableRefObject } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';

import { BodySmall, Box, Snackbar, Button, Tooltip, TableHeaderMenuIcon, IconButton, HistoryOutlinedIcon, Portal } from '@parspec/pixel';

import {
    CellValueChangedEvent,
    ColDef,
    ICellRendererParams,
    ProcessCellForExportParams,
    RowGroupOpenedEvent,
    ColSpanParams,
    ColumnResizedEvent,
    ColumnMovedEvent,
    ColumnState,
    GetQuickFilterTextParams,
    RowClassParams,
    CellKeyDownEvent,
    ProcessUnpinnedColumnsParams,
    IRowNode
} from 'ag-grid-community';

import { SpecificationDropdown } from '../../shared/SpecificationDropdown';
import { SpecificationValType } from '../../../shared/utils/constants';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import {
    deleteTitle,
    deleteMessage,
    deleteDialogTitle,
    areYouSure,
    getCellAmountFormatter,
    getCellPercentFormatter,
    pricingCellParams,
    TextCellParams,
    deleteContent,
    getRowId,
    addNewRowsToTheTable,
    handleOnRangeSelectionChanged
} from '../../shared/utils';
import {
    getColorHandlerForMainTable,
    getFooterDisabled,
    getIsEditableForMainTable,
    getTemplateCellStyle,
    getValueGetterForPricingCols,
    isLeadTimeValueEditable,
    pricingFieldToolTipValueGetter,
    checkIfResfreshParentTable
} from '../shared/utils';
import { MainProductComponentsDataType, MainProductDataType } from './types';
import {
    PRICING_TABLE_ENUM,
    bomCols,
    priceRelatedCols,
    pricingBodInfoCols,
    LEAD_TIME_UNITS,
    toolBaroptions,
    serviceRelatedCols,
    COMPONENT_LOTS_TOOLTIP_MESSAGE,
    LEAD_TIME_VALUE_TOOLTIP_MSG
} from '../shared/constants';
import MoveSectionDialog from '../../shared/MoveSectionDialog';
import { SectionApiResponseType } from '../../queries/apiTypes';
import {
    useReorderBomDataMutation,
    useDeleteBomDataMutation,
    useDuplicateBomDataMutation,
    useToggleHideBomDataMutation,
    useAddBomDataMutation,
    useCreateKitMutation,
    useUpdateBomDataMutation,
    saveColumnOrderEvents,
    saveColumnWidthEvents,
    useHighLightBomDataMutation
} from '../../queries';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { addMsg, deleteMsg, duplicateMsg, hideMsg, reorderMsg, REMOVE_PRODUCT_FROM_KIT, HIGHLIGHT_MSG } from '../../../shared/constants';
import { getExtendedPriceSum, getExtendedSellPriceFormatter, getValueSetterForMainProductPricingCols } from './utils';
import { KIT_ERROR, KIT_ERROR_TYPE, NO_BOD_MSG, SECTION_TYPE } from '../../shared/constants';
import AddOptionModal from '../../shared/addOptionModal';
import { MenuOptionsContext, TableContext } from '../../shared/context';
import { KitMenuOptions, MenuOptions } from '../../shared/MenuOptions';
import ErrorDialog from '../../../shared/ErrorDialog';
import BulkEditModal from '../bulkEdit/bulkEditPopup';
import { LotTemplate } from './lotTemplate';
import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import { SubtotalsNotApplicable } from '../shared/SubtotalsNotApplicable';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../../shared/commonTemplates';
import { useLotsObj } from './hooks';
import PricingHistoryModal from '../PricingHistoryModal';
import { PricingLeadTimeUnitTemplate } from '../shared/LeadTimeUnitTemplate';
import PriceAndLeadtimeDetailGrid from '../shared/PriceAndLeadTimeDetailGrid';
import MoveProductDialog from '../../shared/MoveProductDialog';
import { getTableHeight, calculateKitCost, kitToolbarItems } from '../../shared/utils';
import { getIsTableDataUpdated } from './utils';
import { useGetCustomisedColumnData, useCreateNewBods } from '../../shared/hooks';
import { useGetUserProfileInfoQuery } from '../../../Settings/MyProfile/queries';
import { ProductToReplaceTemplate } from '../../shared/ProductToReplaceTemplate';
import { AddOrDeductFooterTemplate } from '../optionsTable/utils';
import { notifyToSentry } from '../../../shared/SentryErrorLogger';
import { BooleanObjType } from '../../shared/types';
import AddMultipleRows from '../../shared/AddMultipleRows';
import { CustomToolTipForTable } from '../../shared/CustomTooltipForTable';
import { usePasteDataMutation } from '../queries';

interface MainProductTableProps {
    tableData: MainProductDataType[];
    onPricingInfoEdit: (info: Partial<Omit<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'lotInfo'>>) => Promise<void>;
    onBomInfoEdit: (info: Partial<Pick<MainProductDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'id'>>) => Promise<void>;
    onLotUpdate?: (args: { bodId: number; prevLotId: string; currLotId: string; componentIds?: Set<number> }) => void;
    onNewLot?: () => void;
    isLoading: boolean;
    section: SectionApiResponseType;
    optionSection?: SectionApiResponseType[];
    tableMaxHeight: number;
    mainProductOptionsInfo?: Record<number, Array<number>>;
    onAddOption?: (args: { sectionId: number; type: string; qty: number; productToReplaceId: number }) => Promise<void>;
    isAddOptionLoading?: boolean;
    toggleBulkEditBanner?: () => void;
    isKitTable?: boolean;
    isComponentsTable?: boolean;
    kitData?: MainProductDataType;
    kitTableHeight?: string | number;
    onGridReady?: (params: any) => void;
    handleTablesReordering?: (columnOrder: string[]) => void;
    handleTablesWidthChanged?: (targetedColumnId: string, newWidth?: number) => void;
    selectedRowsRef: MutableRefObject<Set<number>>;
}

const modules = [ClipboardModule, GridChartsModule];

const defaultColDef: ColDef = {
    sortable: false,
    menuTabs: [],
    resizable: true,
    cellStyle: getFooterDisabled,
    suppressFillHandle: true,
    editable: (params) => !params.node.rowPinned,
    tooltipComponent: CustomToolTipForTable
};

const MainProductTable = forwardRef((props: MainProductTableProps, ref: any) => {
    const {
        tableData,
        onNewLot: handleNewLot,
        onBomInfoEdit: handleBomInfoEdit,
        onPricingInfoEdit: handlePricingInfoEdit,
        onLotUpdate: handleLotUpdate,
        isLoading,
        section,
        tableMaxHeight,
        optionSection,
        mainProductOptionsInfo,
        onAddOption,
        isAddOptionLoading,
        isKitTable = false,
        isComponentsTable = false,
        kitData,
        kitTableHeight,
        onGridReady = () => {},
        handleTablesReordering,
        handleTablesWidthChanged,
        selectedRowsRef
    } = props;
    const { bomId = '0' } = useParams();

    const [isDeleteDialogOpen, updateDeleteDialogVisilbity] = useState(false);
    const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [movingIds, setMovingIds] = useState<number[]>([]);
    const [isLotSelectedForMovingProducts, setLotSelectedForMovingProducts] = useState(false);
    const [isAddOptionModalOpen, setAddOptionModalOpen] = useState(false);
    const [bodInfoForAddOption, setBodInfoForAddOption] = useState<{ type: string; qty: number; productToReplaceId: number } | undefined>();
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
    const [localTableData, setLocalTableData] = useState<MainProductDataType[] | null>(isComponentsTable ? tableData : null);
    const [gridReady, setGridReady] = useState(false);
    const [isBulkEditInitiated, setIsBulkEditInitiated] = useState(false);
    const [kitCreationError, setKitCreationError] = useState<KIT_ERROR_TYPE>();
    const [isPricingHistoryModalOpen, setPricingHistoryModalOpen] = useState(false);
    const [pricingHistoryInfo, setPricingHistoryInfo] = useState<{
        type?: string;
        manufacturer?: string;
        modelNo?: string;
        id?: number;
        cost?: number;
        discount?: number;
        discountedCost?: number;
        sellPrice?: number;
        margin?: number;
        qty?: number;
        extendedPrice?: number;
        lotId?: number;
        kitLotId?: number | null;
    }>();
    const [quickFilterText, updateQuickFilterText] = useState('');
    const [openMoveProductDialog, setOpenMoveProductDialog] = useState(false);
    const [currentBodId, setCurrentBodId] = useState<number>();
    const expandedRowsContainerRef = useRef<Array<number>>([]);
    const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
    const [detailGridContainerHeight, setDetailGridContainerHeight] = useState(0);
    const [kitsExpansionObj, setKitsExpansionObj] = useState<BooleanObjType>({});
    const [maxWidthForPinnedColumn, setMaxWidthForPinnedColumn] = useState<number>();
    const tableRef = useRef<any>();

    /**
     * For test purpose will be deleted on ce done
     */

    const testColumnNameRef = useRef<Set<string>>(new Set());
    const testCellDataRef = useRef<Set<string>>(new Set());

    const { mutate: updatePasteData } = usePasteDataMutation();

    // Deleter the above code once testing is complete

    const { createNewRowsFromClipboard, isLoading: isLoadingNewBods } = useCreateNewBods(bomId);

    const updateDetailGridContainerHeight = useCallback((value: number) => {
        setDetailGridContainerHeight(value);
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return tableRef;
        },
        []
    );

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { mutateAsync: reorderData } = useReorderBomDataMutation();
    const { mutateAsync: deleteData, isLoading: deleteLoading } = useDeleteBomDataMutation();
    const { mutateAsync: duplicateData, isLoading: duplicateLoading } = useDuplicateBomDataMutation(bomId);
    const { mutateAsync: toggleHideData, isLoading: hideLoading } = useToggleHideBomDataMutation();
    const { mutateAsync: addData, isLoading: addDataLoading } = useAddBomDataMutation(bomId);
    const { mutateAsync: updateBomData } = useUpdateBomDataMutation();
    const { mutateAsync: createKit, isLoading: createKitLoading } = useCreateKitMutation(Number(bomId || 0));
    const { mutateAsync: toggleHighLightData, isLoading: highLightLoading } = useHighLightBomDataMutation();
    const { columnOrderDetails, isColumnDataLoading } = useGetCustomisedColumnData(bomId);
    const { data: userData } = useGetUserProfileInfoQuery();

    const lotsObj = useLotsObj(bomId);

    const rowHeight = 40;
    const isTableLoading = isLoading || deleteLoading || duplicateLoading || hideLoading || addDataLoading || createKitLoading || isColumnDataLoading || highLightLoading || isLoadingNewBods;
    const tableHeight = isKitTable ? kitTableHeight : getTableHeight({ rowHeight, rows: tableData.length, tableMaxHeight });
    const calculatedTableHeight = !isComponentsTable && expandedRows.length ? (tableHeight as number) + detailGridContainerHeight : tableHeight;

    const noOfOptionSections = optionSection?.length || 0;
    // This is the sum of width occuiped by columns drag + checkbox + kits + menu+ qty
    const pinnedAndFixColumnWidth = 400;

    const totalExtendedPrice = useMemo(() => {
        return getExtendedPriceSum(tableData);
    }, [tableData]);

    const handleKitPricingUpdate = async () => {
        if (isComponentsTable && kitData?.id) {
            const updatedRowData: any = [];
            tableRef?.current?.api?.forEachNode((node: any) => {
                updatedRowData.push({ ...node.data });
            });

            if (updatedRowData?.length) {
                const kitCostData = calculateKitCost(updatedRowData, kitData?.qty || 0);
                await handlePricingInfoEdit({ ...kitCostData, id: kitData?.id });
            }
        }
    };
    const isPricingFieldsEditable = (params: any) => {
        if (isKitTable) {
            if (isComponentsTable) {
                return !kitData?.lotId && getIsEditableForMainTable(params);
            }
            return false;
        }
        return getIsEditableForMainTable(params);
    };

    const defaultTableLockedDefs: ColDef[] = [
        { field: PRICING_TABLE_ENUM.ID, hide: true, resizable: false, lockPosition: 'left' },
        {
            field: 'drag',
            rowDrag: true,
            width: 20,
            resizable: false,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            colSpan: (params: ColSpanParams) => (params.node?.rowPinned === 'bottom' ? 3 : 0),
            hide: isKitTable && !isComponentsTable,
            lockPosition: 'left'
        },
        {
            headerCheckboxSelection: true,
            showDisabledCheckboxes: true,
            width: 20,
            enableRowGroup: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            pinned: 'left',
            editable: false,
            lockPinned: true,
            resizable: false,
            hide: isKitTable && !isComponentsTable,
            lockPosition: 'left'
        },
        {
            width: 50,
            cellRenderer: 'agGroupCellRenderer',
            editable: false,
            pinned: 'left',
            cellStyle: () => ({ backgroundColor: 'unset' }),
            resizable: false,
            hide: isKitTable,
            lockPosition: 'left'
        },
        {
            minWidth: 50,
            maxWidth: 50,
            cellStyle: getTemplateCellStyle,
            editable: false,
            cellRenderer: isKitTable ? KitMenuOptions : MenuOptions,
            headerComponent: () => (
                <Box mt={1}>
                    <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                </Box>
            ),
            pinned: 'right',
            colId: 'menu',
            resizable: false,
            lockPosition: 'right',
            hide: isKitTable && !isComponentsTable,
            lockPinned: true
        }
    ];

    const columnDef: Record<string, ColDef> = {
        [PRICING_TABLE_ENUM.TYPE]: {
            headerName: 'Type',
            field: PRICING_TABLE_ENUM.TYPE,
            cellEditorParams: TextCellParams,
            minWidth: 80,
            pinned: 'left',
            valueFormatter: 'value?.toUpperCase()',
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.QTY]: {
            headerName: isComponentsTable ? 'Qty per Kit' : 'Qty',
            field: PRICING_TABLE_ENUM.QTY,
            cellDataType: 'number',
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForMainProductPricingCols,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 0,
                max: 2147483647,
                preventStepping: true
            },
            minWidth: 85,
            maxWidth: 100,
            pinned: 'left',
            type: 'rightAligned'
        },
        [PRICING_TABLE_ENUM.MANUFACTURER]: {
            headerName: 'Manufacturer',
            field: PRICING_TABLE_ENUM.MANUFACTURER,
            valueFormatter: 'value?.toUpperCase()',
            minWidth: 80,
            pinned: 'left',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.MODEL_NO]: {
            headerName: 'Model Number',
            field: PRICING_TABLE_ENUM.MODEL_NO,
            resizable: true,
            minWidth: 80,
            pinned: 'left',
            valueFormatter: 'value?.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.QUOTE_NOTES]: { headerName: 'Quote Notes (External)', field: PRICING_TABLE_ENUM.QUOTE_NOTES, minWidth: 80, cellDataType: 'text' },
        [PRICING_TABLE_ENUM.INTERNAL_COMMENTS]: { headerName: 'Internal Comments', field: PRICING_TABLE_ENUM.INTERNAL_COMMENTS, minWidth: 80, cellDataType: 'text' },
        specificationPlTab: {
            headerName: 'Specification',
            field: PRICING_TABLE_ENUM.SPECIFICATION,
            colId: 'specificationPlTab', // Added
            editable: false,
            cellStyle: getTemplateCellStyle,
            cellRenderer: SpecificationDropdown,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.value;
            },
            width: 204,
            minWidth: 80
        },
        [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE]: {
            headerName: 'Product To Replace',
            field: PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE,
            editable: false,
            cellRenderer: ProductToReplaceTemplate,
            getQuickFilterText: () => '',
            width: 300,
            minWidth: 80,
            cellStyle: getTemplateCellStyle,
            hide: true
        },
        [PRICING_TABLE_ENUM.LOT]: {
            headerName: 'Lot',
            colId: PRICING_TABLE_ENUM.LOT,
            editable: false,
            cellRenderer: LotTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.data?.[PRICING_TABLE_ENUM.LOT_INFO]?.name;
            },
            width: 180,
            minWidth: 80,
            cellStyle: getColorHandlerForMainTable,
            tooltipValueGetter: (params) => {
                if (params?.data?.kit) return COMPONENT_LOTS_TOOLTIP_MESSAGE;
                return '';
            }
        },
        [PRICING_TABLE_ENUM.HISTORY]: {
            minWidth: 69,
            maxWidth: 69,
            headerComponent: () => (
                <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <HistoryOutlinedIcon color="secondary" fontSize="small" />
                </Box>
            ),
            cellRenderer: (params: ICellRendererParams) => {
                if (params?.node?.rowPinned === 'bottom' || params?.data?.is_kit) {
                    return null;
                }

                function handleClick() {
                    const { category, manufacturer, model_number, id, cost, discount, discountedCost, sellPrice, margin, qty, extendedPrice, lotId } = params.data;
                    setPricingHistoryInfo({
                        type: category,
                        manufacturer,
                        modelNo: model_number,
                        id,
                        cost,
                        discount,
                        discountedCost,
                        sellPrice,
                        margin,
                        qty,
                        extendedPrice,
                        lotId,
                        kitLotId: isKitTable && isComponentsTable ? kitData?.lotId ?? undefined : undefined
                    });
                    setPricingHistoryModalOpen(true);
                }

                return (
                    <Box width="100%" alignItems="center">
                        <IconButton onClick={handleClick}>
                            <HistoryOutlinedIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Box>
                );
            },
            colId: PRICING_TABLE_ENUM.HISTORY,
            editable: false,
            cellStyle: () => ({ backgroundColor: 'unset' }),
            resizable: true,
            hide: isKitTable && !isComponentsTable
        },
        [PRICING_TABLE_ENUM.COST]: {
            headerName: 'Cost',
            colId: PRICING_TABLE_ENUM.COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForMainProductPricingCols,
            cellDataType: 'number',
            editable: (params) => isPricingFieldsEditable(params),
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            width: 120,
            minWidth: 80,
            type: 'rightAligned',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData, PRICING_TABLE_ENUM.COST)
        },
        [PRICING_TABLE_ENUM.DISCOUNT]: {
            headerName: 'Discount (%)',
            colId: PRICING_TABLE_ENUM.DISCOUNT,
            valueSetter: getValueSetterForMainProductPricingCols,
            valueGetter: getValueGetterForPricingCols,
            editable: isPricingFieldsEditable,
            valueFormatter: getCellPercentFormatter,
            getQuickFilterText: getCellPercentFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            width: 119,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData)
        },
        [PRICING_TABLE_ENUM.DISCOUNT_COST]: {
            headerName: 'Discounted Cost',
            colId: PRICING_TABLE_ENUM.DISCOUNT_COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForMainProductPricingCols,
            editable: isPricingFieldsEditable,
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            minWidth: 80,
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            width: 143,
            type: 'rightAligned',
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData)
        },
        [PRICING_TABLE_ENUM.MARGIN]: {
            headerName: 'Margin(%)',
            colId: PRICING_TABLE_ENUM.MARGIN,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForMainProductPricingCols,
            editable: isPricingFieldsEditable,
            valueFormatter: getCellPercentFormatter,
            getQuickFilterText: getCellPercentFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            width: 106,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData)
        },
        [PRICING_TABLE_ENUM.SELL_PRICE]: {
            headerName: 'Sell Price',
            colId: PRICING_TABLE_ENUM.SELL_PRICE,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForMainProductPricingCols,
            editable: isPricingFieldsEditable,
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            width: 130,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData, PRICING_TABLE_ENUM.SELL_PRICE)
        },
        [PRICING_TABLE_ENUM.EXTENDED_PRICE]: {
            headerName: 'Ext. Sell Price',
            field: PRICING_TABLE_ENUM.EXTENDED_PRICE,
            editable: false,
            width: 145,
            minWidth: 80,
            type: 'rightAligned',
            cellDataType: 'number',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            valueFormatter: getExtendedSellPriceFormatter,
            getQuickFilterText: getExtendedSellPriceFormatter,
            tooltipValueGetter: (params) => pricingFieldToolTipValueGetter(params, isComponentsTable, kitData, PRICING_TABLE_ENUM.EXTENDED_PRICE),
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params?.node?.rowPinned === 'bottom' && params?.node?.data?.extendedPrice === 'N/A') {
                    return {
                        component: () => <SubtotalsNotApplicable title="Subtotals are not available because at least one included lot is present in multiple sections" />
                    };
                }
                return undefined;
            }
        },
        [PRICING_TABLE_ENUM.ADD_DEDUCT_AMT]: {
            headerName: 'Add/Deduct Amount',
            field: PRICING_TABLE_ENUM.ADD_DEDUCT_AMT,
            editable: false,
            width: 160,
            minWidth: 80,
            type: 'rightAligned',
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params?.node?.rowPinned === 'bottom' && params?.node?.data?.addDeductAmount === 'N/A') {
                    return {
                        component: AddOrDeductFooterTemplate
                    };
                }
                return undefined;
            },
            hide: true
        },
        [PRICING_TABLE_ENUM.LEAD_TIME_VALUE]: {
            headerName: 'Lead time Value',
            field: PRICING_TABLE_ENUM.LEAD_TIME_VALUE,
            minWidth: 80,
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isLeadTimeValueEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            cellEditorParams: {
                precision: 0,
                max: 2147483647
            },
            cellDataType: 'number',
            type: 'rightAligned',
            editable: isLeadTimeValueEditable,
            tooltipValueGetter: (params) => {
                if (!isLeadTimeValueEditable(params)) return LEAD_TIME_VALUE_TOOLTIP_MSG;
                return '';
            }
        },
        [PRICING_TABLE_ENUM.LEAD_TIME_UNIT]: {
            headerName: 'Lead time Unit',
            field: PRICING_TABLE_ENUM.LEAD_TIME_UNIT,
            minWidth: 80,
            editable: false,
            cellStyle: { padding: '4px 0px' },
            cellRenderer: PricingLeadTimeUnitTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params.value;
            }
        },
        [PRICING_TABLE_ENUM.QUOTE_NO]: { headerName: 'Source Quotes Number', field: PRICING_TABLE_ENUM.QUOTE_NO, minWidth: 80, cellDataType: 'text' }
    };

    const tableColumnDefs = useMemo<ColDef[]>(() => {
        const orderedColumns = columnOrderDetails?.order.reduce((result: ColDef[], field: string) => {
            if (Object.hasOwn(columnDef, field)) {
                const column = {
                    ...columnDef[field]
                };

                if (columnOrderDetails?.width) {
                    const widthField = field as keyof typeof columnOrderDetails.width;
                    column.width = columnOrderDetails?.width[widthField];
                    if (['manufacturer', 'model_number', 'category'].includes(field) && maxWidthForPinnedColumn) {
                        column.maxWidth = maxWidthForPinnedColumn;
                    }
                }

                result.push(column);
            }

            return result;
        }, []);

        return [...defaultTableLockedDefs, ...(orderedColumns || [])];
    }, [isColumnDataLoading, columnOrderDetails, maxWidthForPinnedColumn, kitData]);

    useEffect(() => {
        if (
            (!isComponentsTable && tableData && checkIfResfreshParentTable(tableData, localTableData)) ||
            (localTableData == null && !isTableLoading && tableData) ||
            (localTableData && localTableData.length) !== tableData.length ||
            (tableData && localTableData && getIsTableDataUpdated(tableData, localTableData)) ||
            isBulkEditInitiated
        ) {
            setLocalTableData(tableData);
            setIsBulkEditInitiated(false);
        }
    }, [tableData, isTableLoading]);

    useEffect(() => {
        if (isComponentsTable && bomId && localTableData?.length) {
            handleKitPricingUpdate();
        }
    }, [localTableData?.length]);

    useEffect(() => {
        if (!gridReady) {
            return;
        }
        if (isTableLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (!isTableLoading && tableData && tableData.length === 0) {
            setTimeout(() => {
                tableRef?.current?.api?.showNoRowsOverlay();
            });
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isTableLoading, tableData, gridReady]);

    useEffect(() => {
        setKitsExpansionObj(JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'));
    }, []);

    async function handleAddBod(data: any) {
        const item = {
            category: '',
            qty: null,
            manufacturer: '',
            model_number: '',
            previous_bod_id: data.id || kitData?.id,
            section_id: section.id,
            section_type: SECTION_TYPE.MAIN,
            ...(isKitTable ? { kit_id: kitData?.id } : {})
        };

        await addData({ bomId: bomId || '0', input: item });
        setSnackbarOpen(addMsg);
    }

    function handleAddBelow(props: ICellRendererParams | CellKeyDownEvent) {
        const { data } = props;
        return handleAddBod(data);
    }

    const handleAdd = () => {
        const id = tableData.length ? tableData[tableData.length - 1].id : null;
        handleAddBod({ id });
    };

    async function onDuplicate(bodIds: Array<number>) {
        await duplicateData({
            bomId: bomId || '0',
            input: bodIds
        });

        setSnackbarOpen(duplicateMsg);
    }

    function handleAddSingleDuplicate(props: any) {
        return onDuplicate([props.data.id]);
    }

    async function handleAddDuplicates() {
        const idsToDuplicate: Array<number> = selectedItems.map((product: MainProductDataType) => product.id);
        await onDuplicate(idsToDuplicate);
        handleClearSelection();
    }

    async function handleDelete() {
        await deleteData({ bomId: bomId || '0', ids: deleteIds });
        setDeleteIds([]);
        updateDeleteDialogVisilbity(false);
        setSnackbarOpen(deleteMsg);
    }

    function handleBulkDelete() {
        const toDeleteIdsSet: Array<number> = selectedItems.map((product: MainProductDataType) => product.id);
        setDeleteIds(toDeleteIdsSet);
        updateDeleteDialogVisilbity(true);
    }

    function handleSingleDelete(props: any) {
        setDeleteIds([props.data.id]);
        updateDeleteDialogVisilbity(true);
    }

    async function handleEdit(props: CellValueChangedEvent) {
        try {
            const fieldName = props.column.getColId() as PRICING_TABLE_ENUM;
            const bodId = props.data.id;
            let sourceData = { ...props.data };

            if (priceRelatedCols.has(fieldName) || fieldName === PRICING_TABLE_ENUM.QTY) {
                fieldName === PRICING_TABLE_ENUM.QTY
                    ? handleBomInfoEdit({ [fieldName]: props.data[fieldName], id: bodId }).then(handleKitPricingUpdate)
                    : handlePricingInfoEdit({ ...sourceData, id: bodId }).then(handleKitPricingUpdate);
            } else if (pricingBodInfoCols.has(fieldName)) {
                let payload: Partial<MainProductDataType> = { [fieldName]: props.data[fieldName], id: bodId };
                if (fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE && props.data?.leadTimeUnit === null && props.data?.leadTimeValue !== null) {
                    sourceData = { ...sourceData, leadTimeUnit: LEAD_TIME_UNITS.WEEKS.toLowerCase() };
                    payload = { ...payload, leadTimeUnit: LEAD_TIME_UNITS.WEEKS.toLowerCase() };
                }

                handlePricingInfoEdit(payload);
            } else if (bomCols.has(fieldName)) {
                sourceData[fieldName] = sourceData[fieldName] ? sourceData[fieldName].toUpperCase() : '';
                handleBomInfoEdit({ [fieldName]: sourceData[fieldName], id: bodId });
            }
        } catch (error: any) {
            notifyToSentry(`(MainProductTable)${error.name}: ${error.message}\n${error.stack}`);
        }
    }

    function handleSpecificationChange(specificationVal: SpecificationValType, bodId: number) {
        handleBomInfoEdit({ specification: specificationVal, id: bodId });
    }

    async function onHide(bodIds: Array<number>) {
        await toggleHideData({
            bomId: bomId || '0',
            input: bodIds
        });
        setSnackbarOpen(hideMsg);
        if (isComponentsTable) {
            tableRef?.current?.api?.forEachNode((node: any) => {
                const { id } = node.data;
                if (bodIds.includes(id)) {
                    const bodData = localTableData?.find((item) => item.id === id);
                    node.setData({ ...bodData, is_hidden: !bodData?.is_hidden });
                }
            });
            handleKitPricingUpdate();
        }
    }

    function handleMultipleHide() {
        const toHideIds: Array<number> = selectedItems.map((product: MainProductDataType) => product.id);
        onHide(toHideIds);
        handleClearSelection();
    }

    function handleSingleHide(props: any) {
        return onHide([props.data.id]);
    }

    function handleLotChange(rowNode: IRowNode, rowData: MainProductComponentsDataType, { bodId, prevLotId, lotValue }: { lotValue: string; bodId: number; prevLotId: string }) {
        if (handleLotUpdate && handleNewLot) {
            if (lotValue === 'new') {
                handleNewLot();
                return;
            }
            const lotId = lotValue === '-' ? null : Number(lotValue);
            rowNode.setData({
                ...rowData,
                [PRICING_TABLE_ENUM.LOT]: lotId,
                [PRICING_TABLE_ENUM.COST]: null,
                [PRICING_TABLE_ENUM.DISCOUNT]: null,
                [PRICING_TABLE_ENUM.DISCOUNT_COST]: null,
                [PRICING_TABLE_ENUM.MARGIN]: null,
                [PRICING_TABLE_ENUM.SELL_PRICE]: null,
                [PRICING_TABLE_ENUM.EXTENDED_PRICE]: null,
                [PRICING_TABLE_ENUM.LOT_INFO]: lotId ? { ...lotsObj[lotId].lotsInfo } : lotId
            });
            let componentIds: Set<number> | undefined;
            if (rowData.is_kit && lotId) {
                componentIds = rowData.components?.reduce((acc, component) => {
                    acc.add(component.id);
                    return acc;
                }, new Set<number>());
            }
            handleLotUpdate({ bodId, prevLotId, currLotId: lotValue, componentIds });
        }
    }

    async function handleAddOption(props: any) {
        const { data } = props;
        const currentType = data[PRICING_TABLE_ENUM.TYPE]?.trim();
        const optionType = currentType ? currentType + ' - Alt' : '';
        const qty = data[PRICING_TABLE_ENUM.QTY];
        const productToReplaceId = data.id;
        if (noOfOptionSections === 1) {
            const sectionId = optionSection?.[0]?.id;
            await onAddOption?.({ type: optionType, qty, productToReplaceId, sectionId: sectionId! });
        } else {
            setBodInfoForAddOption({ type: optionType, qty, productToReplaceId });
            setAddOptionModalOpen(true);
        }
        handleClearSelection();
    }

    const handleCheckboxChange = (args: any) => {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedItems(selectedBods);
    };

    function closeMoveSectionDialog(afterSubmission?: boolean) {
        if (afterSubmission) {
            handleClearSelection();
        }
        setOpenMoveDialog(false);
        setMovingIds([]);
    }

    const openMoveSectionDialog = () => setOpenMoveDialog((old) => !old);

    function checkIfProductsHaveLots(ids: Array<number>) {
        const idsToCheck = new Set(ids);
        const isLotPresentInBods = tableData.some((rowData) => idsToCheck.has(rowData.id) && rowData.lotId);

        return isLotPresentInBods;
    }

    const onClickMoveSection = (props: any) => {
        const { id } = props.data;
        const isLotPresentInMovingBod = checkIfProductsHaveLots([id]);
        setLotSelectedForMovingProducts(isLotPresentInMovingBod);
        setMovingIds([id]);
        openMoveSectionDialog();
    };

    const onMoveMultipleItems = () => {
        const ids = selectedItems.map((product: MainProductDataType) => product.id);
        const isLotPresentInMovingBods = checkIfProductsHaveLots(ids);
        setLotSelectedForMovingProducts(isLotPresentInMovingBods);
        setMovingIds(ids);
        openMoveSectionDialog();
    };

    function handleAddOptionClose() {
        setAddOptionModalOpen(false);
    }

    async function handleAddOptionConfirm(sectionId: number) {
        const { qty = 0, type = '', productToReplaceId = 0 } = bodInfoForAddOption || {};
        await onAddOption?.({ sectionId, qty, type, productToReplaceId });
        setAddOptionModalOpen(false);
    }

    const updateKitCreationError = (error: KIT_ERROR_TYPE.KIT | KIT_ERROR_TYPE.OPTION | undefined) => setKitCreationError(error);

    const onCreateKit = async () => {
        const isKitSelected = selectedItems.some((item: any) => item.is_kit);
        if (isKitSelected) {
            return updateKitCreationError(KIT_ERROR_TYPE.KIT);
        }
        let hasOptions = false;
        selectedItems.forEach((item: any) => {
            if (mainProductOptionsInfo?.[item.id]?.length) {
                hasOptions = true;
            }
        });
        if (hasOptions) {
            return updateKitCreationError(KIT_ERROR_TYPE.OPTION);
        }

        const id = tableData?.length ? tableData[tableData.length - 1].id : null;
        const inputData = {
            bomId: Number(bomId),
            input: {
                category: '',
                qty: null,
                manufacturer: '',
                model_number: '',
                previous_bod_id: id,
                section_id: section?.id,
                components: selectedItems.map((item: any) => item.id),
                section_type: SECTION_TYPE.MAIN
            }
        };
        await createKit(inputData);
        handleClearSelection();
    };

    const handleBulkEditClick = () => {
        setOpenBulkEditModal((openBulkEditModal) => !openBulkEditModal);
    };

    const toolbarRightSection: ReactNode = (
        <Tooltip title={selectedItems.length === 0 ? 'Select Item(s) First' : ''} placement="top">
            <Box sx={{ width: 'max-content' }}>
                <Button variant="outlined" color="secondary" onClick={handleBulkEditClick} disabled={selectedItems.length === 0}>
                    Bulk Edit
                </Button>
            </Box>
        </Tooltip>
    );

    const onReorderTableData = async (arg: any) => {
        const updatedRowData: any = [];
        arg.api.forEachNode((node: any) => {
            updatedRowData.push({ ...node.data });
        });
        const bodIds = arg.nodes.map((item: any) => item.data.id);
        const overIndex = updatedRowData.findIndex((item: any) => item?.id === bodIds[0]);
        const prevBodId = overIndex === 0 ? (isKitTable ? kitData?.id : null) : updatedRowData[overIndex - 1].id;
        const input = { previous_bod_id: prevBodId, bod_id: bodIds };
        await reorderData({ bomId: bomId!, input });
        setSnackbarOpen(reorderMsg);
    };

    function handleSearch(searchString: string) {
        updateQuickFilterText(searchString);
    }

    function handleClearSelection() {
        tableRef?.current?.api?.deselectAll();
        setSelectedItems([]);
    }

    const resetTableData = () => {
        setIsBulkEditInitiated(true);
    };

    const handleProcessCellPasteForPricing = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column.getColId();

        if (fieldName) {
            testColumnNameRef.current.add(fieldName);
        }

        if (value) {
            testCellDataRef.current.add(value);
        }

        addNewRowsToTheTable(selectedRowsRef, tableRef, fieldName, createNewRowsFromClipboard, [PRICING_TABLE_ENUM.ADD_DEDUCT_AMT, PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE]);

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        } else if (value === '' || value == null) {
            return fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE ? null : '';
        }

        const processCellPasteCols = new Set([...priceRelatedCols, ...serviceRelatedCols]);

        if (processCellPasteCols.has(fieldName)) {
            const newValue: string = `${value}`?.replace(/\$|,|%/g, '');
            if (newValue !== '' && !isNaN(newValue as unknown as number)) {
                return parseFloat(newValue);
            }
            return null;
        }
        return value;
    };

    function handleProcessForClipboard(props: ProcessCellForExportParams) {
        const fieldName = props.column.getColId();

        if (fieldName === PRICING_TABLE_ENUM.SPECIFICATION || fieldName === PRICING_TABLE_ENUM.LOT || fieldName === PRICING_TABLE_ENUM.LEAD_TIME_UNIT) {
            return '';
        } else if (fieldName === PRICING_TABLE_ENUM.EXTENDED_PRICE && props.node?.data.lotId) {
            return `Lot (${props.node?.data.lotInfo.name})`;
        }

        return props.value;
    }

    function handleLeadTimeUnit(rowNode: IRowNode, payload: Partial<MainProductDataType>) {
        rowNode.setDataValue(PRICING_TABLE_ENUM.LEAD_TIME_UNIT, payload.leadTimeUnit);
        rowNode.setDataValue(PRICING_TABLE_ENUM.LEAD_TIME_VALUE, payload.leadTimeValue);
        handlePricingInfoEdit(payload);
    }

    const getIfRowMaster = useCallback((dataItem: any) => {
        return dataItem ? dataItem.is_kit : false;
    }, []);

    const detailCellRenderer = useMemo(
        () => (props: ICellRendererParams<any, any, any>) =>
            <PriceAndLeadtimeDetailGrid {...props} updateDetailGridContainerHeight={updateDetailGridContainerHeight} selectedRowsRef={selectedRowsRef} />,
        []
    );

    function handleOnGridReady(params: any) {
        const { leftWidth, rightWidth, viewportRight } = params?.api?.columnModel || {};
        const maxViewPortWidth = leftWidth + rightWidth + viewportRight;
        // divided by 3 width will equally distributed across type,manufacture,modelNo
        const maxPinnedColumnsWidth = Math.floor((maxViewPortWidth - pinnedAndFixColumnWidth) / 3);
        setMaxWidthForPinnedColumn(maxPinnedColumnsWidth);
        setGridReady(true);
        if (isComponentsTable) {
            onGridReady(params);
        }
    }

    function onProductMoveIn(currentBodId: number) {
        setCurrentBodId(currentBodId);
        setOpenMoveProductDialog(true);
    }

    async function onProductMoveOut(params: any) {
        const { data } = params;
        await updateBomData({ bomId: String(bomId), input: { kit: null, id: data.id }, isInvalidateBodList: true });
        if (localTableData?.length === 1 && kitData?.id) {
            const kitCostData = {
                cost: null,
                discountedCost: null,
                sellPrice: null,
                discount: null,
                margin: null,
                extendedPrice: null
            };

            await handlePricingInfoEdit({ ...kitCostData, id: kitData?.id });
        }
        setSnackbarOpen(REMOVE_PRODUCT_FROM_KIT);
    }

    const handleMoveProductDialog = useCallback(() => {
        setOpenMoveProductDialog(false);
    }, []);

    const getTableMetaData = useCallback(() => {
        if (!tableRef?.current) return null;
        return tableRef.current;
    }, []);

    const onRowGroupOpened = useCallback(
        (e: RowGroupOpenedEvent<any, any>) => {
            const {
                rowIndex,
                node: { expanded, id }
            } = e;
            if (rowIndex !== null) {
                if (expanded) {
                    expandedRowsContainerRef.current.push(rowIndex);
                } else {
                    expandedRowsContainerRef.current.pop();
                }
            }
            setExpandedRows([...expandedRowsContainerRef.current]);

            const obj = { ...JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'), [`${id}`]: expanded };
            setKitsExpansionObj?.(obj);
            localStorage.setItem(`${bomId}-kits`, JSON.stringify(obj));
        },
        [kitsExpansionObj]
    );

    const toggleKits = useCallback(
        (params: any) => {
            if (kitsExpansionObj && Object.keys(kitsExpansionObj).length > 0) {
                Object.keys(kitsExpansionObj).map((id: string) => {
                    const rowNode = params.api.getRowNode(id);
                    if (rowNode) {
                        rowNode.setExpanded(kitsExpansionObj?.[id]);
                    }
                });
            }
        },
        [kitsExpansionObj]
    );

    const handleColumnMoved = async (props: ColumnMovedEvent) => {
        const savedState = props.api.getColumnState();
        if (props.finished && props.source === 'uiColumnMoved') {
            const newColumnOrder = savedState.map((column: ColumnState) => column.colId);
            const exceptDefaultColumns = newColumnOrder.slice(defaultTableLockedDefs.length - 1);
            handleTablesReordering?.(exceptDefaultColumns);

            const userId = userData?.data?.id;
            const newArraySet = new Set(exceptDefaultColumns);
            const sortedArray: string[] = [...exceptDefaultColumns];
            if (columnOrderDetails && columnOrderDetails.order) {
                sortedArray.push(...columnOrderDetails.order.filter((item: string) => !newArraySet.has(item)));
            }
            if (!isComponentsTable) await saveColumnOrderEvents(userId, sortedArray);
        }
    };
    const handleColumnResized = async (props: ColumnResizedEvent) => {
        const colId: any = props?.column?.getColId();
        const newChangedWidth = props?.column?.getActualWidth();
        const currentWidths = columnOrderDetails?.width;
        const userId = userData?.data?.id;
        const columnState = props.api.getColumnState();
        const ignorePinnedColumnState = columnState.slice(defaultTableLockedDefs.length - 1);
        const columnWidths = ignorePinnedColumnState.reduce((acc: any, item) => {
            acc[item.colId] = item.width;
            return acc;
        }, {});
        const newWidths = { ...currentWidths, ...columnWidths };
        handleTablesWidthChanged?.(colId, newChangedWidth);

        if (props.finished && props.source === 'uiColumnResized' && userId) {
            if (!isComponentsTable) await saveColumnWidthEvents(userId, newWidths);
        }
    };
    function handlePricingModalClose(afterSave = false) {
        setPricingHistoryModalOpen(false);
        if (afterSave) {
            handleKitPricingUpdate();
        }
    }

    const onHighlightMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        onHighlight(input);

        handleClearSelection();
    };

    const onHighlight = async (ids: number[]) => {
        await toggleHighLightData({
            bomId: bomId!,
            input: ids
        });
        setSnackbarOpen(HIGHLIGHT_MSG);
    };

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event || e.event.defaultPrevented) {
            return;
        }
        // stop the default behavior of the event. While adding a row in the component table, the event must not trigger onAdd() function call for the kit table
        isComponentsTable && e.event.preventDefault();
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        if ((keyboardEvent.ctrlKey || keyboardEvent.metaKey) && keyboardEvent.key === 'i') {
            handleAddBelow(e);
        }
    }, []);

    const onHandleUnpinnedColumns = (props: ProcessUnpinnedColumnsParams) => {
        // divided by 3 width will equally distributed across type,manufacture,modelNo
        const maxColumnsWidth = Math.floor((props?.viewportWidth - pinnedAndFixColumnWidth) / 3);
        setMaxWidthForPinnedColumn(maxColumnsWidth);
        return [];
    };

    /**
     * Delete this event handler as well
     */

    const handleOnPasteEnd = () => {
        if (userData?.data) {
            try {
                updatePasteData({
                    userId: userData.data.id,
                    pastedContent: { colums: [...testColumnNameRef.current], cellData: [...testCellDataRef.current] },
                    email: userData.data.email
                });

                testCellDataRef.current.clear();
                testColumnNameRef.current.clear();
            } catch (e) {}
        }
    };

    return (
        <>
            <Portal>
                <Snackbar
                    open={snackbarInfo.open}
                    message={snackbarInfo.message}
                    onClose={(_e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setSnackbarClose();
                    }}
                    autoHideDuration={snackbarInfo.autoHideDuration}
                />
            </Portal>
            {isPricingHistoryModalOpen && <PricingHistoryModal open={isPricingHistoryModalOpen} onClose={handlePricingModalClose} pricingInfo={pricingHistoryInfo} />}
            {isAddOptionModalOpen && <AddOptionModal open={isAddOptionModalOpen} onConfirm={handleAddOptionConfirm} onClose={handleAddOptionClose} isAddOptionInProgress={isAddOptionLoading!} />}
            {isDeleteDialogOpen && (
                <DeleteConfirmationDialog
                    open={isDeleteDialogOpen}
                    title={deleteIds.length > 1 ? deleteDialogTitle : areYouSure}
                    handleDialog={() => updateDeleteDialogVisilbity(false)}
                    confirmAction={handleDelete}
                    loading={deleteLoading}
                >
                    {isKitTable ? (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                {deleteContent}
                            </BodySmall>
                        </Box>
                    ) : (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                <p style={{ whiteSpace: 'pre-line' }}>{deleteMessage(mainProductOptionsInfo!, deleteIds)}</p>
                            </BodySmall>
                        </Box>
                    )}
                </DeleteConfirmationDialog>
            )}
            {openMoveDialog && (
                <MoveSectionDialog
                    open={openMoveDialog}
                    handleDialog={closeMoveSectionDialog}
                    movingIds={movingIds}
                    handleClearSelection={handleClearSelection}
                    currentSection={section}
                    mainProductOptionsInfo={mainProductOptionsInfo}
                    isLotsPresentInBods={isLotSelectedForMovingProducts}
                />
            )}
            {openMoveProductDialog && currentBodId && (
                <MoveProductDialog
                    open={openMoveProductDialog}
                    handleClose={handleMoveProductDialog}
                    currentBodId={currentBodId}
                    getTableMetaData={getTableMetaData}
                    sectionId={section.id}
                    setSnackbarOpen={setSnackbarOpen}
                    isOptionTable={false}
                />
            )}
            {kitCreationError && (
                <ErrorDialog
                    open={Boolean(kitCreationError)}
                    handleDialog={() => updateKitCreationError(undefined)}
                    title="Unable to Create Kit"
                    text={KIT_ERROR[kitCreationError].text}
                    helperText={KIT_ERROR[kitCreationError].helperText}
                />
            )}

            {openBulkEditModal && (
                <BulkEditModal
                    open={openBulkEditModal}
                    onClose={() => setOpenBulkEditModal(false)}
                    onReset={resetTableData}
                    selectedItems={selectedItems}
                    handleClearSelection={handleClearSelection}
                    setSnackbarOpen={setSnackbarOpen}
                />
            )}
            <MenuOptionsContext.Provider
                value={{
                    bomId,
                    isOptionTable: false,
                    noOfOptionSections,
                    handleAddOption,
                    onAddBelow: handleAddBelow,
                    onClickMoveSection,
                    onHide: handleSingleHide,
                    onClickDelete: handleSingleDelete,
                    onDuplicate: handleAddSingleDuplicate,
                    onProductMoveIn,
                    onProductMoveOut,
                    onHighlight
                }}
            >
                <TableContext.Provider value={{ handleSpecificationChange, handleLotChange, handleLeadTimeUnit, section, isOptionTable: false }}>
                    <Box zIndex={1} width={'100%'} position={'relative'}>
                        <CustomToolBarPanel
                            toolBarOptions={isComponentsTable ? kitToolbarItems : toolBaroptions}
                            selectedRowCount={selectedItems.length}
                            disabledToolBarButton={selectedItems.length === 0}
                            onAdd={handleAdd}
                            onAddDuplicates={handleAddDuplicates}
                            onHideUnhide={handleMultipleHide}
                            onMove={onMoveMultipleItems}
                            onDelete={handleBulkDelete}
                            onTextSearch={handleSearch}
                            onCloseBanner={handleClearSelection}
                            loading={isTableLoading}
                            toolbarRightSection={isKitTable ? null : toolbarRightSection}
                            onCreateKit={onCreateKit}
                            onHighlight={onHighlightMultiple}
                            isKitSelected={selectedItems.some((item: MainProductDataType) => item.is_kit)}
                        ></CustomToolBarPanel>
                        <Box sx={{ height: calculatedTableHeight }} width="100%" className="ag-theme-alpine">
                            <AgGridReact
                                ref={tableRef}
                                rowData={localTableData}
                                getRowId={getRowId}
                                gridOptions={{
                                    rowHeight,
                                    tooltipShowDelay: 0,
                                    tooltipInteraction: true,
                                    suppressRowClickSelection: true,
                                    enableRangeSelection: true,
                                    enableFillHandle: true,
                                    undoRedoCellEditing: true,
                                    enterNavigatesVerticallyAfterEdit: true,
                                    enterNavigatesVertically: true,
                                    rowDragManaged: true,
                                    rowSelection: 'multiple',
                                    suppressContextMenu: true,
                                    stopEditingWhenCellsLoseFocus: true,
                                    suppressDragLeaveHidesColumns: true,
                                    suppressLastEmptyLineOnPaste: true,

                                    rowClassRules: {
                                        'row-hide': (params: any) => params?.data?.is_hidden,
                                        'row-highlight': (params: RowClassParams) => (params?.data?.is_kit || isComponentsTable ? false : params?.data?.is_highlighted)
                                    },
                                    getRowStyle: (params) => {
                                        if (params?.node?.rowPinned) {
                                            return { backgroundColor: '#f8f8f8', fontWeight: 700 };
                                        }
                                        return undefined;
                                    },
                                    rowDragMultiRow: true
                                }}
                                context={{}}
                                quickFilterText={quickFilterText}
                                pinnedBottomRowData={isKitTable ? [] : [{ drag: 'Subtotal', extendedPrice: totalExtendedPrice }]}
                                columnDefs={tableColumnDefs}
                                modules={modules}
                                defaultColDef={defaultColDef}
                                onCellValueChanged={handleEdit}
                                onRowDragEnd={onReorderTableData}
                                onSelectionChanged={handleCheckboxChange}
                                processCellFromClipboard={handleProcessCellPasteForPricing}
                                loadingOverlayComponent={loadingOverlayComponent}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} helperText={NO_BOD_MSG} />}
                                noRowsOverlayComponentParams={{
                                    isTableHaveFooter: !isComponentsTable ? true : false
                                }}
                                processCellForClipboard={handleProcessForClipboard}
                                onGridReady={handleOnGridReady}
                                masterDetail
                                detailCellRenderer={detailCellRenderer}
                                reactiveCustomComponents
                                isRowMaster={getIfRowMaster}
                                detailRowAutoHeight
                                onRowGroupOpened={onRowGroupOpened}
                                onColumnMoved={handleColumnMoved}
                                onColumnResized={handleColumnResized}
                                onRowDragLeave={onReorderTableData}
                                onCellKeyDown={onCellKeyDown}
                                onFirstDataRendered={toggleKits}
                                processUnpinnedColumns={onHandleUnpinnedColumns}
                                onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsRef)}
                                onPasteEnd={handleOnPasteEnd}
                            />
                        </Box>
                        {!isKitTable && <AddMultipleRows sectionId={section.id} isOptionTable={false} />}
                    </Box>
                </TableContext.Provider>
            </MenuOptionsContext.Provider>
        </>
    );
});

export default MainProductTable;
