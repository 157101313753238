import { BodyBig, BodyMedium, BodySmall, Box, Grid, Switch, Radio, BodyXS, Select, TextField, TransitionsColorPicker, SelectChangeEvent } from '@parspec/pixel';

import { QuoteTemplateInterface, IQuoteOptionsPresets } from '../../../queries/apiTypes';
import { TEXT_STYLE_VALUES } from '../../utils/helper';

interface PricingLeadTimeProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: QuoteTemplateInterface;
    handleColorAndFontTypeChange: (value: string, presetName: string, fieldName: string, fieldType: string) => void;
}

const COLUMN_ONE_PRODUCT_SECTION_DETAILS_OPTIONS = [
    { label: 'Quantity', value: 'quantity' },
    { label: 'Type', value: 'type' },
    { label: 'Manufacturer', value: 'manufacturer' },
    { label: 'Model Number', value: 'model_number' },
    { label: 'Description', value: 'description' },
    { label: 'Quote Notes (External)', value: 'notes' }
];

const COLUMN_TWO_PRODUCT_SECTION_DETAILS_OPTIONS = [
    { label: 'Lead Time', value: 'lead_time' },
    { label: 'Unit Price', value: 'unit_price' },
    { label: 'Extended Price', value: 'extended_price' },
    { label: 'Section Subtotal', value: 'section_subtotal' },
    { label: 'Datasheet Hyperlinks', value: 'datasheet_hyperlink' },
    { label: 'Show Kit Breakdown', value: 'show_kit_breakdown' }
];

const LOT_AND_FINANCIAL_BREAKOUT_OPTIONS = [
    { label: 'Lot Breakout', value: 'lot_breakout' },
    { label: 'Services Breakout', value: 'service_breakout' },
    { label: 'Taxes Breakout', value: 'tax_breakout' },
    { label: 'Freight Breakout', value: 'freight_breakout' }
];

const SUMMARIZED_TOTAL_OPTIONS = [
    { label: 'Products, Services, Taxes, Freight, and Grand Total', value: 'pstf_grand_total' },
    { label: 'Grand Total', value: 'grand_total' },
    { label: 'No Total', value: 'no_total' }
];

const OPTIONS_COLUMN_LEFT = [
    { label: 'Quantity', value: 'quantity' },
    { label: 'Type', value: 'type' },
    { label: 'Manufacturer', value: 'manufacturer' },
    { label: 'Model Number', value: 'model_number' },
    { label: 'Description', value: 'description' },
    { label: 'Quote Notes (External)', value: 'notes' },
    { label: 'Lead Time', value: 'lead_time' }
];

const OPTIONS_COLUMN_RIGHT = [
    { label: 'Unit Price', value: 'unit_price' },
    { label: 'Extended Price', value: 'extended_price' },
    { label: 'Product to Replace', value: 'substituted_for' },
    { label: 'Add/Deduct Amount', value: 'add_deduct_amount' },
    { label: 'Section Subtotal', value: 'section_subtotal' },
    { label: 'Datasheet Hyperlinks', value: 'datasheet_hyperlink' },
    { label: 'Show Kit Breakdown', value: 'show_kit_breakdown' }
];

export const PricingLeadTime = (props: PricingLeadTimeProps) => {
    const { changeHandler, templateState, handleColorAndFontTypeChange } = props;

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.quote_price_lead_time?.price_and_lead_time_section}
                    onChange={(e) => changeHandler(e, 'quote_price_lead_time')}
                    name="price_and_lead_time_section"
                    label={
                        <BodyBig fontWeight={500} ml={2}>
                            Pricing & Lead Time
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose how you want to display the information from the Pricing & Lead Time tab.
            </BodySmall>
            <BodySmall limit={false} color="text.secondary">
                You can edit these details when customizing your quote.
            </BodySmall>

            <Box mt={6}>
                <Box>
                    <Box height={'100%'}>
                        <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }} limit={false}>
                            Product Section Details
                        </BodyMedium>
                        <Box display="flex " position="relative">
                            <Box width="50%" pr={6}>
                                <Box>
                                    <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                        {COLUMN_ONE_PRODUCT_SECTION_DETAILS_OPTIONS.map((option) => {
                                            return (
                                                <Box ml="4px" key={option.value}>
                                                    <Switch
                                                        checked={templateState?.quote_price_lead_time[option.value]}
                                                        onChange={(e) => changeHandler(e, 'quote_price_lead_time')}
                                                        name={option.value}
                                                        label={<BodySmall>{option.label}</BodySmall>}
                                                    />{' '}
                                                </Box>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                            <Box width="50%" pr={6}>
                                <Box>
                                    <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                        {COLUMN_TWO_PRODUCT_SECTION_DETAILS_OPTIONS.map((option) => {
                                            return (
                                                <Box ml="4px" key={option.value}>
                                                    <Switch
                                                        checked={templateState?.quote_price_lead_time[option.value]}
                                                        onChange={(e) => changeHandler(e, 'quote_price_lead_time')}
                                                        name={option.value}
                                                        label={<BodySmall>{option.label}</BodySmall>}
                                                    />{' '}
                                                </Box>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>

                        <Box pt={4}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Lot & Financial Breakouts
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {LOT_AND_FINANCIAL_BREAKOUT_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState?.quote_price_lead_time[option.value]}
                                                    onChange={(e) => changeHandler(e, 'quote_price_lead_time')}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />{' '}
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box pt={4}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Summarized Total
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {SUMMARIZED_TOTAL_OPTIONS.map((el) => {
                                        return (
                                            <Box display="flex" alignItems="center">
                                                <Radio
                                                    checked={templateState?.quote_price_lead_time?.summarized_total === el.value}
                                                    size={'small'}
                                                    value={el.value}
                                                    name="summarized_total"
                                                    inputProps={{ 'aria-label': 'summarized_total' }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'quote_price_lead_time')}
                                                />
                                                <Box>
                                                    <BodySmall>{el.label}</BodySmall>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>

                        <Box pt={4}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Options (Add/Deducts) Details
                            </BodyMedium>

                            <Box mt={2}>
                                <BodySmall limit={false} color="text.secondary">
                                    The option table(s) contain options, which are suggested replacements, additions, or removals to the
                                </BodySmall>
                                <BodySmall limit={false} color="text.secondary">
                                    basis of design products. Any cost impact related to these options is not reflected in the grand total.
                                </BodySmall>
                            </Box>

                            <Box mt={2} pt={4}>
                                <Box display={'flex'}>
                                    <BodyMedium fontWeight={500} mr={4} color="secondary.light">
                                        Option Notes
                                    </BodyMedium>
                                    <Box>
                                        <Switch
                                            checked={templateState?.quote_price_lead_time_option?.option_notes}
                                            onChange={(e) => changeHandler(e, 'quote_price_lead_time_option')}
                                            name="option_notes"
                                            label={''}
                                        />
                                    </Box>
                                </Box>

                                <Box mt={2} display={'flex'} alignItems="flex-start" width="100%">
                                    <Box width={'85%'}>
                                        <TextField
                                            label="Enter Your Notes Here..."
                                            autoComplete="off"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'quote_price_lead_time_option')}
                                            value={templateState?.quote_price_lead_time_option?.option_notes_text.value}
                                            name={'option_notes_text'}
                                            multiline
                                            rows={6}
                                            inputProps={{ maxLength: 500 }}
                                        />
                                    </Box>
                                    <Box width={'15%'} ml={3}>
                                        <Box>
                                            <Select
                                                label="Text"
                                                name={'option_notes_text'}
                                                onChange={(e: SelectChangeEvent<unknown>) =>
                                                    handleColorAndFontTypeChange(e.target.value as string, 'quote_price_lead_time_option', 'option_notes_text', 'type')
                                                }
                                                optionLabelKeyname="label"
                                                optionValueKeyname="value"
                                                options={TEXT_STYLE_VALUES}
                                                value={templateState?.quote_price_lead_time_option?.option_notes_text?.font?.type}
                                            />
                                        </Box>
                                        <Box mt={4}>
                                            <BodyXS fontWeight={500} color={'text.secondary'} mr={4}>
                                                Font Color
                                            </BodyXS>
                                            <Box mt={2}>
                                                <TransitionsColorPicker
                                                    color={templateState?.quote_price_lead_time_option?.option_notes_text?.font?.color}
                                                    onChange={(color) => handleColorAndFontTypeChange(color.hex, 'quote_price_lead_time_option', 'option_notes_text', 'color')}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Grid mt={4} ml={2} container justifyContent={'space-between'}>
                                <Grid item xs={6}>
                                    <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                        {OPTIONS_COLUMN_LEFT.map((option, index) => {
                                            return (
                                                <Grid item key={index}>
                                                    <Switch
                                                        label={option.label}
                                                        checked={Boolean(templateState.quote_price_lead_time_option[option.value as keyof IQuoteOptionsPresets])}
                                                        name={option.value}
                                                        onChange={(e) => changeHandler(e, 'quote_price_lead_time_option')}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                        {OPTIONS_COLUMN_RIGHT.map((option, index) => {
                                            return (
                                                <Grid item key={index}>
                                                    <Switch
                                                        label={option.label}
                                                        checked={templateState.quote_price_lead_time_option[option.value as keyof IQuoteOptionsPresets]}
                                                        name={option.value}
                                                        onChange={(e) => changeHandler(e, 'quote_price_lead_time_option')}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
