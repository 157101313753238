import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { createProduct, endpoints, getProductDetails, patchProductDetails } from './api';
import { invalidateBomDataQuery, invalidateDatasheetDataQuery, invalidateGetOptionSectionQuery, invalidateGetSectionsQuery } from '../../../queries';
import { queryClient } from 'src/app/queryClient';
import { invalidatePricingBodQuery } from '../../../pricing&LeadTime/queries';
import { getCreateProductResponse } from './apiTypes';

//keys
export const getProductDetailsKey = (bomId: string, bodId: string) => [endpoints.getProduct(bomId, bodId)];

//queries
export const useGetProductDetailsQuery = (bomId: string, bodId: string, options?: Omit<UseQueryOptions<AxiosResponse<getCreateProductResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getProductDetailsKey(bomId, bodId), () => getProductDetails(bomId, bodId), options);

export const invalidateGetProductDetailsQuery = (bomId: string, productId: string) => {
    queryClient.invalidateQueries(getProductDetailsKey(bomId, productId));
};
//Mutations
export const usePatchProductDetailsMutations = () => {
    return useMutation(patchProductDetails, {
        onSuccess: (_, request) => {
            const { bomId } = request;
            invalidateBomDataQuery(Number(bomId));
        }
    });
};

export const useCreateProductMutation = () => {
    return useMutation(createProduct, {
        onSuccess: (_, request) => {
            const { bomId, bodId } = request;
            invalidateBomDataQuery(Number(bomId));
            invalidatePricingBodQuery(Number(bomId));
            invalidateGetProductDetailsQuery(String(bomId), String(bodId));
            invalidateDatasheetDataQuery(bomId);
            invalidateGetSectionsQuery(Number(bomId));
            invalidateGetOptionSectionQuery(Number(bomId));
        }
    });
};
