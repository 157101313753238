import { useState, useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ICellRendererParams } from 'ag-grid-community';

import { Autocomplete } from '@parspec/pixel';

import { useGetBomDataQuery } from '../queries';
import { TableContext } from './context';
import { PRICING_TABLE_ENUM } from '../pricing&LeadTime/shared/constants';

// To be used only for prodcut to replace cell renderer
export function ProductToReplaceTemplate(props: ICellRendererParams) {
    const { bomId = '0' } = useParams();

    const { column, data } = props;
    const { onProductToReplaceChange, lotMap } = useContext(TableContext) || {};
    const currentFieldId = column?.getColId();
    const currentFieldData = currentFieldId && data?.[currentFieldId];

    const [productToReplace, updateProductToReplace] = useState<{ [index: string]: string } | null>(null);

    const { data: bomData } = useGetBomDataQuery(bomId, {
        enabled: Boolean(bomId)
    });
    const productToReplaceOptions = useMemo(() => {
        if (!bomData?.data || !Array.isArray(bomData.data) || !lotMap) return [];

        const optionsArr = bomData?.data
            .filter((bodData) => bodData.section && !lotMap[bodData.id] && !bodData?.kit)
            .sort((productA, productB) => {
                const productASectionId = productA.section ?? 0;
                const productBSectionId = productB.section ?? 0;

                if (productASectionId > productBSectionId) {
                    return 1;
                } else if (productASectionId < productBSectionId) {
                    return -1;
                }

                return 0;
            })
            .map((bodData) => ({
                value: String(bodData.id),
                label: [bodData?.category?.toUpperCase(), bodData?.manufacturer?.toUpperCase(), bodData?.model_number?.toUpperCase()].filter((data) => data).join(' | ')
            }))
            .filter((product) => product.label);

        return optionsArr;
    }, [bomData?.data, lotMap]);

    function handleChange(event: React.SyntheticEvent<Element, Event>) {
        const target = event.target as HTMLInputElement;
        const obj = target.value as unknown as { [index: string]: string } | null;
        const value = obj?.value ? Number(obj.value) : null;
        props.node.setData({ ...props.data, [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_LABEL]: obj?.label });
        onProductToReplaceChange?.(value, data.id);
    }

    /**
     * Handled in useEffect as autocomplete maintains its own state and
     * it changes onChange due to this maintaining derived state for dropdown value leads to noticable flickering effect
     * Minimized this by using local state and updating it only when the value changes
     * */
    useEffect(() => {
        if (currentFieldId) {
            const selectedItem = productToReplaceOptions?.find((option) => option.value == currentFieldData);
            const updatedProductToReplace = selectedItem ? { ...selectedItem } : null;
            if (
                (productToReplace === null && updatedProductToReplace !== null) ||
                productToReplace?.value !== updatedProductToReplace?.value ||
                productToReplace?.label !== updatedProductToReplace?.label
            ) {
                updateProductToReplace(updatedProductToReplace);
                props.node.setData({ ...props.data, [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_LABEL]: selectedItem?.label });
            }
        }
    }, [currentFieldId, currentFieldData, productToReplaceOptions]);

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    return (
        <Autocomplete
            optionlabelkeyname="label"
            options={productToReplaceOptions}
            value={productToReplace}
            onChange={handleChange}
            label=""
            fieldSize="small"
            sx={{ height: '100%', display: 'flex', alignItems: 'center', '& input': { height: '18px', paddingTop: '0px !important', paddingBottom: '0px !important' } }}
        />
    );
}
