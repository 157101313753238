import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Button } from '@parspec/pixel';

import { ICellRendererParams, ColDef } from 'ag-grid-community';

import { TableMenu } from '../shared/utils';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { MenuContext } from './context';

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
};

export function MenuOption(props: ICellRendererParams) {
    const { data } = props;
    const { bomId, projectId } = data;
    const navigate = useNavigate();
    const { onDelete, onDuplicate } = useContext(MenuContext);

    const options: {
        label: React.ReactNode | string;
        onClick: () => void;
        color?: string | undefined;
        disabled?: boolean | undefined;
        toolTipMessage?: string | undefined;
    }[] = [
        {
            label: 'View',
            onClick: () => navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`)
        },
        { label: 'Duplicate', onClick: () => onDuplicate(data) },
        {
            label: 'Delete',
            onClick: () => onDelete(data),
            color: 'error.main'
        }
    ];

    return <TableMenu options={options} />;
}

export const ViewDocumentsTemplate = (props: ICellRendererParams) => {
    const { data } = props;
    const { bomId, projectId } = data;
    return (
        <Box>
            <Link to={`/v2/project/${projectId}/bom/${bomId}?tab=finalDocuments`} underline="hover" component={RouterLink}>
                <Button variant="outlined" color="secondary" size="small">
                    View Documents
                </Button>
            </Link>
        </Box>
    );
};
