import { useMemo } from 'react';

import { useGetLotDetailsQuery } from '../lotPriceModal/queries';
import { getLotsObjForMainProductTable } from '../shared/utils';

export function useLotsObj(bomId = '0') {
    const { data: lotsData } = useGetLotDetailsQuery(bomId, { enabled: Boolean(bomId) });
    const lotsObj = useMemo(() => {
        if (!lotsData?.data) {
            return {};
        }
        return getLotsObjForMainProductTable(lotsData.data);
    }, [lotsData?.data]);

    return lotsObj;
}
