import { Box, BodyBig, BodySmall, InfoIcon } from '@parspec/pixel';

export const LinkExpired = () => {
    return (
        <Box bgcolor="secondary.contrastText" width="360px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <BodyBig limit={false} fontWeight={500} mb={2}>
                    Link Expired
                </BodyBig>
                <BodySmall limit={false} my={4} textAlign={'center'}>
                    This link is no longer valid. Contact your account administrator for more information.
                </BodySmall>
            </Box>

            <Box bgcolor={'warning.light'} display={'flex'} alignItems={'flex-start'} p={2}>
                <Box color={'warning.main'}>
                    <InfoIcon fontSize={'small'} />
                </Box>
                <Box ml={2}>
                    <BodySmall limit={false} color={'warning.main'}>
                        If you think this is an error, please
                    </BodySmall>
                    <BodySmall limit={false} color={'warning.main'}>
                        email us at support@parspec.io.
                    </BodySmall>
                </Box>
            </Box>
        </Box>
    );
};
