import { FC } from 'react';

import { Box, Switch, TextField } from '@parspec/pixel';

import { StakeholderInfoResponse } from '../queries/apiTypes';

interface StakeHolderInfoProps {
    stakeHolderInfo: StakeholderInfoResponse;
    onToggleStakeHolder: (e: React.ChangeEvent<HTMLInputElement>, stakeHolderInfo: StakeholderInfoResponse) => void;
    blurAction: (e: React.FocusEvent<HTMLInputElement>, stakeHolderInfo: StakeholderInfoResponse) => void;
    handleOnFocus: () => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, stakeHolderInfo: StakeholderInfoResponse) => void;
}

const StakeholderInfo: FC<StakeHolderInfoProps> = ({ stakeHolderInfo, onToggleStakeHolder, blurAction, handleOnFocus, handleChange }) => {
    return (
        <Box display="flex" alignItems="center" gap={3}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Switch checked={stakeHolderInfo.is_active} onChange={(e) => onToggleStakeHolder(e, stakeHolderInfo)} />
            </Box>

            <Box flex={1}>
                <TextField
                    inputProps={{
                        maxLength: 64
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => blurAction(e, stakeHolderInfo)}
                    onFocus={handleOnFocus}
                    label={'Business Type'}
                    value={stakeHolderInfo?.business_type}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, stakeHolderInfo)}
                    name={'business_type'}
                />
            </Box>
            <Box flex={1}>
                <TextField
                    inputProps={{
                        maxLength: 64
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => blurAction(e, stakeHolderInfo)}
                    onFocus={handleOnFocus}
                    label={'Company Name'}
                    value={stakeHolderInfo?.stakeholder}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, stakeHolderInfo)}
                    name={'stakeholder'}
                />
            </Box>
        </Box>
    );
};

export default StakeholderInfo;
