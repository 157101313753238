import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Skeleton, Snackbar } from '@parspec/pixel';
import { Header } from '../shared/Header';
import ProductForm from './components/ProductForm';
import AnnotatedPdfViewer from 'src/features/shared/AnnotatedPdfViewer';
import ProductFormHeader from './components/ProductFormHeader';
import CancelButton from '../../../shared/CancelButton';

import { useCreateProductMutation, useGetProductDetailsQuery, usePatchProductDetailsMutations } from './queries';
import { savedSuccessfully, saving } from '../../../shared/constants';
import { setRouteToLocalStorage } from '../../../shared/utils/utils';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { useGetBodDetailQuery, useOptionSectionQuery } from '../../queries';
import { useTrackIntercomEvent } from '../../../shared/hooks/useIntercomHook';
import { useGetDatasheetQuery, useGetSelectedDocumentQuery } from '../../Datasheets/AnnotateDatasheet/queries';
import { QTY_MAX_ERROR_MSG } from './constants';

export type formDataType = {
    id: string;
    type: string;
    qty: string;
    manufacturer: string;
    modelNumber: string;
    description: string;
    specification: string;
    toHideOrigin: boolean;
    documentUrl: string;
    substituteSectionId: string | null;
};

const CreateProduct: React.FC = () => {
    const navigate = useNavigate();
    const { bomId, productId, projectId } = useParams();
    const isPatchInProgress = useRef(false);
    const isPostInProgress = useRef(false);

    const { data: bodData, isLoading, isFetching } = useGetProductDetailsQuery(bomId!, productId!, { enabled: Boolean(bomId), staleTime: 0 });

    const { data: sourceBodData } = useGetBodDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });

    //Datsheet Queries
    const { data: dataSheetData, isFetching: isDatasheetFetching } = useGetSelectedDocumentQuery(`${bomId}`, `${productId}`, 'submittal', 'datasheet', {});
    const selectedDatasheetId = dataSheetData?.data[0].id || 0;
    const datasheetId = dataSheetData?.data[0].document || 0;
    const { data: datasheetFileData, isFetching: isDatasheetFileFetching } = useGetDatasheetQuery(`${bomId}`, `${productId}`, selectedDatasheetId, datasheetId, 'submittal', 'datasheet', {
        enabled: Boolean(dataSheetData?.data[0].id)
    });
    const sourceDocUrl = datasheetFileData?.data.flat_filepath || '';
    const isSourceDatasheetLoading = isDatasheetFetching || isDatasheetFileFetching;

    const showOptions =
        sourceBodData?.data?.section && !sourceBodData.data?.kit
            ? sourceBodData
                ? sourceBodData.data?.category.length > 0 || sourceBodData.data?.manufacturer.length > 0 || sourceBodData.data?.model_number.length > 0
                : false
            : false;

    const { mutateAsync: patchData } = usePatchProductDetailsMutations();

    const { mutateAsync: createProduct } = useCreateProductMutation();

    const [formData, setFormData] = useState<formDataType>({
        id: '',
        type: '',
        qty: '',
        manufacturer: '',
        modelNumber: '',
        description: '',
        specification: 'Alternate',
        toHideOrigin: false,
        documentUrl: '',
        substituteSectionId: ''
    });
    const [isPostLoading, setIsPostLoading] = useState(false);
    const [optionsError, setOptionsError] = useState(false);
    const [qtyError, setQtyError] = useState('');

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { data: optionSectionData } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const optionsList = useMemo(() => {
        return optionSectionData?.data.map((item) => {
            return { label: item.name, value: String(item.id) };
        });
    }, [optionSectionData?.data]);

    useEffect(() => {
        if (!isFetching) {
            if (bodData && bodData?.data.length) {
                const { id, type, manufacturer, model_number, description, specification, hide_origin_line, document_url, substitute_section, qty } = bodData?.data[0] || {};
                setFormData({
                    id: String(id),
                    qty: qty ? String(qty) : '',
                    type,
                    manufacturer,
                    modelNumber: model_number,
                    description: description,
                    specification,
                    toHideOrigin: hide_origin_line,
                    documentUrl: document_url,
                    substituteSectionId: String(substitute_section || '')
                });
            } else {
                navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/setRequirements`, { replace: true });
            }
        }
    }, [bodData, isLoading, isFetching]);

    const handleFieldChange = (event: any) => {
        const { name, value, checked, type } = event.target;
        let updatedData;
        if (type === 'radio') updatedData = { ...formData, [name]: checked };
        else updatedData = { ...formData, [name]: value };

        if (name === 'substituteSectionId') {
            setOptionsError(false);
            updatedData = { ...updatedData, toHideOrigin: false };
        } else if (name === 'qty' && value > 2147483647) {
            setQtyError(QTY_MAX_ERROR_MSG);
        } else if (name === 'qty') {
            setQtyError('');
        }

        setFormData(updatedData);
        if (!['text', 'textarea', 'number'].includes(type)) patchBodDetails(updatedData);
    };

    const onChangeSelection = (): void => {
        navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/submittal/alternates/selectAlternate`);
    };

    const patchBodDetails = async (formData: formDataType) => {
        isPatchInProgress.current = true;
        const { id, type, manufacturer, modelNumber, description, specification, toHideOrigin, substituteSectionId, qty } = formData;
        const payload = {
            type,
            manufacturer,
            model_number: modelNumber,
            description,
            specification,
            hide_origin_line: toHideOrigin,
            substitute_section: substituteSectionId,
            qty: qtyError ? bodData?.data[0]?.qty || null : qty !== '' ? Number(qty) : null
        };
        setSnackbarOpen(saving, null);
        await patchData({ bomId: bomId!, bodId: productId!, dataSheetId: id, input: payload });
        if (isPostInProgress.current) callCreatProductApi();
        isPatchInProgress.current = false;
        setSnackbarOpen(savedSuccessfully, 3000);
    };

    const callCreatProductApi = async () => {
        try {
            const response = await createProduct({ bomId: bomId!, bodId: productId!, dataSheetId: formData.id });

            const { id } = response.data;
            const urlToNavigate = `/v2/project/${projectId}/bom/${bomId}?tab=bom&productId=${id}`;

            setRouteToLocalStorage(urlToNavigate);
            navigate(urlToNavigate);
        } finally {
            isPostInProgress.current = false;
            setIsPostLoading(false);
        }
    };

    const onCreateProduct = async () => {
        if (!formData.toHideOrigin && optionsList && optionsList?.length > 1 && !formData.substituteSectionId) {
            setOptionsError(true);
            return;
        }
        setIsPostLoading(true);
        setTimeout(() => {
            if (isPatchInProgress.current) isPostInProgress.current = true;
            else callCreatProductApi();
        });
        trackIntercomEvent('PF_Create_Product');
    };

    const showSourceDoc = !(datasheetFileData?.data.total_pages === dataSheetData?.data?.[0]?.hidden_pages?.length);

    return (
        <>
            <Snackbar
                open={snackbarInfo.open}
                onClose={(_e, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setSnackbarClose();
                }}
                message={snackbarInfo.message}
                autoHideDuration={snackbarInfo.autoHideDuration}
            />
            <Box>
                <Header title="Create Product">
                    <Box display="flex" gap={2} alignItems="flex-start" ml="auto">
                        <CancelButton />
                        <Button variant="outlined" color="secondary" onClick={onChangeSelection}>
                            Change Selection
                        </Button>
                        <Button onClick={onCreateProduct} isLoading={isPostLoading} disabled={Boolean(qtyError)}>
                            Create Product
                        </Button>
                    </Box>
                </Header>
                <Box display="flex" height="calc(100vh - 160px)">
                    <Box width={showSourceDoc && sourceDocUrl ? '25%' : '50%'} p={6} bgcolor="neutral.light" overflow="scroll">
                        <ProductFormHeader />
                        <ProductForm
                            formData={formData}
                            optionsList={optionsList || []}
                            handleChange={handleFieldChange}
                            patchBodDetails={patchBodDetails}
                            isLoading={isLoading || isFetching}
                            optionsError={optionsError}
                            showOptions={showOptions}
                            qtyError={qtyError}
                        />
                    </Box>
                    <Box width={showSourceDoc && sourceDocUrl ? '75%' : '50%'}>
                        {isLoading || isFetching || isSourceDatasheetLoading ? (
                            <Skeleton height="100%" width="100%" variant="rectangular" />
                        ) : bodData?.data?.[0]?.document_url ? (
                            <Box display="flex" height="100%">
                                {showSourceDoc && sourceDocUrl && <AnnotatedPdfViewer fileUrl={sourceDocUrl} height="100%" width="100%" documentType="submittal" subDocumentType="datasheet" />}
                                <AnnotatedPdfViewer
                                    fileUrl={bodData?.data?.[0]?.document_internal_url ?? bodData?.data?.[0]?.document_url}
                                    height="100%"
                                    width="100%"
                                    documentType="submittal"
                                    subDocumentType="datasheet"
                                    isTargetDoc={true}
                                />
                            </Box>
                        ) : (
                            <Box height="100vh" bgcolor="neutral.main" display="flex" justifyContent="center" alignItems="center">
                                No reference datasheet provided.
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CreateProduct;
