import { useState, useEffect, useContext } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { SelectChangeEvent, Box, StatusSelect } from '@parspec/pixel';

import { SpecificationValType, specificationOptions } from 'src/features/shared/utils/constants';
import { TableContext } from '../shared/context';

export function SpecificationTemplate(props: ICellRendererParams) {
    const { column, data } = props;
    const colId = column?.getColId();
    const [currentSpecification, updateSpecification] = useState<SpecificationValType | null>(null);
    const { onCellEdit } = useContext(TableContext);

    function handleChange(event: SelectChangeEvent<unknown>) {
        updateSpecification(event.target.value as SpecificationValType);
        onCellEdit?.({ column, newValue: event.target.value, data });
    }

    useEffect(() => {
        if (currentSpecification === null && colId && data?.[colId]) {
            updateSpecification(data?.[colId]);
        }
    }, [data?.specification]);

    return (
        <Box display="flex" alignItems="center" height="100%">
            <StatusSelect options={specificationOptions} value={currentSpecification} onChange={handleChange} size="small" sx={{ height: 30 }} />
        </Box>
    );
}
