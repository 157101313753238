import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { GetMailsRequest, GetMessageByIdRequest } from './apiTypes';

import { fetchGoogleUserInfo, fetchS3File, getGoogleMails, getMsEmails, sendGoogleMail, sendMsEMail } from './apis';
import { AxiosResponse } from 'axios';

//query-keys
const msEmailQueryKey = (token: string) => ['get-msEmail', token];
const googleMailKey = (token: string) => ['get-GoogleMail', token];
const getGoogleMailDescKey = (id: string) => ['get-GoogleMailDesc', id];
const fetchS3FileKey = (url: string) => ['get-s3Doc', url];
const googleUserInfoKey = (token: string) => ['get-google-user-info', token];

//queries
export const useGetMsEMailQuery = (reqObj: GetMailsRequest) => useQuery(msEmailQueryKey(reqObj.token), () => getMsEmails(reqObj));
export const useGetGoogleMailQuery = (reqObj: GetMailsRequest) => useQuery(googleMailKey(reqObj.token), async () => getGoogleMails(reqObj));

export const useGetGoogleMailDescQuery = (req: GetMessageByIdRequest) => useQuery(getGoogleMailDescKey(req.id), () => getGoogleMails(req));

export const useGetS3DocQuery = (url: string) => useQuery(fetchS3FileKey(url), () => fetchS3File(url));

export const useGetGoogleUserInfo = (token: string, options?: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) => useQuery(googleUserInfoKey(token), () => fetchGoogleUserInfo(token), options);

//Mutation
export const useSendMsMailMutation = () => useMutation(sendMsEMail);

export const useSendGoogleMailMutation = () => useMutation(sendGoogleMail);
