import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Switch, TextField, Grid, Select, SelectChangeEvent } from '@parspec/pixel';

import PreviewContext from '../Context';
import { ProjectAddress } from '../queries/apiTypes';
import { UpdateTextFieldProps } from '../queries/apiTypes';
import { useDocumentTextFieldMutation } from '../queries';
import { USA_STATES, CANADA_STATES, COUNTRY_OPTIONS, COUNTRY } from 'src/features/shared/constants';
import { modifiedStateList } from 'src/features/shared/utils/utils';
import { ENTITY } from '../../constants';

interface SwitchAddressPropTypes {
    checked?: boolean;
    switchLabel: string;
    action: (event: React.ChangeEvent<HTMLInputElement>) => void;
    address?: ProjectAddress;
    fieldName?: string;
}

const Address = ({ address, checked, switchLabel, action, fieldName }: SwitchAddressPropTypes) => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);
    const [addresLine1, setAddressLine1] = useState<string>();
    const [addresLine2, setAddressLine2] = useState<string>();
    const [city, setCity] = useState<string>();
    const [state, setState] = useState('');
    const [zip, setZip] = useState<string>();
    const [country, setCountry] = useState('');
    const [stateListOptions, setStateListOptions] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }]);

    const { mutate } = useDocumentTextFieldMutation();

    useEffect(() => {
        if (address?.state) {
            if (USA_STATES.includes(address?.state) || CANADA_STATES.includes(address?.state)) {
                const codedStateValue = address.state.substring(0, 2);
                setState(codedStateValue);
            } else {
                setState(address.state);
            }
        }

        setAddressLine1(address?.address1);
        setAddressLine2(address?.address2);
        setCity(address?.city);
        setZip(address?.zip_code);
        setCountry(address?.country || '');

        if (address?.country === COUNTRY.USA) {
            setStateListOptions(modifiedStateList(USA_STATES));
        } else if (address?.country === COUNTRY.CANADA) {
            setStateListOptions(modifiedStateList(CANADA_STATES));
        }
    }, [address]);

    const handleOnChange = (event: SelectChangeEvent<unknown>, label: string) => {
        const value = event.target.value as string;
        switch (label) {
            case 'address1':
                setAddressLine1(value);
                break;

            case 'address2':
                setAddressLine2(value);
                break;

            case 'city':
                setCity(value);
                break;
            case 'zip_code':
                setZip(value);
                break;

            case 'state':
                setState(value);
                break;

            case 'country':
                setCountry(value);
                setState('');
                if (value === COUNTRY.USA) {
                    setStateListOptions(modifiedStateList(USA_STATES));
                } else if (value === COUNTRY.CANADA) {
                    setStateListOptions(modifiedStateList(CANADA_STATES));
                }
                break;

            default:
                break;
        }

        handlePreviewImage(false);
    };

    const handleOnBlur = () => {
        const payload: UpdateTextFieldProps = {
            bomId: bomId!,
            fieldName,
            field_value: {
                address1: addresLine1,
                address2: addresLine2,
                city,
                state,
                zip_code: zip,
                country
            },
            documentType: documentType!,
            entity: ENTITY.COVER_PAGE_ENTITY
        };

        mutate(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <Switch checked={checked} label={switchLabel} onChange={action} />
            </Grid>

            <Grid item xs={8}>
                <Grid container direction={'column'} rowSpacing={2}>
                    <Grid item>
                        <TextField
                            label={''}
                            placeholder={'Address Line 1'}
                            color="secondary"
                            value={addresLine1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'address1')}
                            onBlur={handleOnBlur}
                            inputProps={{
                                maxLength: 35
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label={''}
                            placeholder={'Address Line 2'}
                            color="secondary"
                            value={addresLine2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'address2')}
                            onBlur={handleOnBlur}
                            inputProps={{
                                maxLength: 20
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Select
                                    labelId="country"
                                    color="secondary"
                                    id="country"
                                    label="Country"
                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e, 'country')}
                                    onBlur={handleOnBlur}
                                    options={COUNTRY_OPTIONS}
                                    optionLabelKeyname="name"
                                    optionValueKeyname="id"
                                    value={country}
                                    inputProps={{
                                        maxLength: 15
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label={''}
                                    placeholder={'City'}
                                    color="secondary"
                                    value={city}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'city')}
                                    onBlur={handleOnBlur}
                                    inputProps={{
                                        maxLength: 20
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            {/* todo... */}
                            <Grid item xs={6}>
                                <Select
                                    value={state}
                                    label="State"
                                    id="state"
                                    labelId="state"
                                    options={stateListOptions}
                                    optionLabelKeyname="name"
                                    optionValueKeyname="id"
                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e, 'state')}
                                    disabled={Boolean(!country)}
                                    onBlur={handleOnBlur}
                                    inputProps={{
                                        maxLength: 15
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label={''}
                                    placeholder={'Zip'}
                                    color="secondary"
                                    value={zip}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'zip_code')}
                                    onBlur={handleOnBlur}
                                    inputProps={{
                                        maxLength: 15
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Address;
