// eslint-disable-next-line

/* prettier-ignore */

interface TermsAndConditionsContentInterface{
    setAcceptTnCButtonActive: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TermsAndConditionsContent = (props: TermsAndConditionsContentInterface) => {
    const { setAcceptTnCButtonActive } = props;

    const handleScroll = (event: any) => {
        const threshold = 50;
        const target = event.target;

        // Get the current scroll position of the element
        const scrollTop = target.scrollTop;
        // Get the height of the element
        const clientHeight = target.clientHeight;
        // Get the full scrollable height of the element
        const scrollHeight = target.scrollHeight;

        // Calculate the scrolled position
        const scrolledTo = scrollTop + clientHeight;
        // Check if the user has scrolled to near the bottom, within the threshold
        const isReachBottom = scrollHeight - scrolledTo <= threshold;

        if (isReachBottom) {
            setAcceptTnCButtonActive(() => true);
        }
    };

    return (
        <div className="signupTnCBodyContent" onScroll={handleScroll}>
            <pre className="signup-tos-pre">
                {' '}
                {
                    <>
                        PARSPEC, INC.
                        <br />
                        CUSTOMER AGREEMENT
                        <br />
                        <br />
                        <b>LAST UPDATED DATE: October 17, 2023</b>
                        <br />
                        <br />
                        <b>This Customer Agreement</b> (this <b>“Agreement”</b>) provides the terms and conditions pursuant to which companies and other entities (each, a <b>“Customer”</b>) may use
                        the software services provided by <b>Parspec, Inc.</b>, a Delaware corporation (<b>“Parspec”</b>) to support construction product selection as well as the authoring and
                        management of sales quotes, submittals, and operation and maintenance documentation for Customer’s internal business purposes. This Agreement is effective as of the date
                        Customer indicates acceptance (the <b>“Effective Date”</b>). Parspec and Customer are, individually, a <b>“Party”</b> and, collectively, are the <b>“Parties”</b>.
                        <br />
                        <b>
                            BY CLICKING ON THE “I ACCEPT” BUTTON, COMPLETING THE REGISTRATION PROCESS, OR ACCESSING OR USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY,
                            AND CAPACITY TO ENTER INTO THIS AGREEMENT (ON BEHALF OF YOURSELF AND, AS APPLICABLE, THE ENTITY THAT YOU REPRESENT). IF THE INDIVIDUAL ENTERING INTO THIS AGREEMENT OR
                            OTHERWISE ACCESSING OR USING THE PLATFORM SERVICES IS DOING SO ON BEHALF OF, OR WITHIN HIS OR HER CAPACITY AS A REPRESENTATIVE, AGENT, OR EMPLOYEE OF AN ENTITY, SUCH
                            INDIVIDUAL AND SUCH ENTITY AGREE THAT: (i) “CUSTOMER” AS USED HEREIN APPLIES TO SUCH ENTITY AND, AS APPLICABLE, SUCH INDIVIDUAL; (ii) AND YOU REPRESENT AND WARRANT THAT THE
                            INDIVIDUAL ENTERING INTO THIS AGREEMENT HAS THE POWER, RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF SUCH ENTITY. IF YOU DO NOT AGREE TO BE BOUND
                            BY THIS AGREEMENT, IF YOU ARE AN INDIVIDUAL UNDER THE AGE OF 18, OR IF YOU HAVE PREVIOUSLY BEEN REMOVED OR BANNED FROM THE SERVICES BY PARSPEC, YOU MAY NOT ACCESS OR USE
                            THE PLATFORM SERVICES.
                        </b>
                        <br />
                        <strong>1. Overview of the Services</strong>
                        <br />
                        <strong>1.1 Definitions. </strong>The following terms, when used in this Agreement will have the following meanings:
                        <br />
                        <br />
                        <strong>(a)</strong>
                        <strong>
                            {' '}
                            <em>“Confidential Information”</em>{' '}
                        </strong>
                        means any information of a confidential or non-public nature disclosed by one Party as a disclosing Party to the other Party as a receiving Party that is either designated as
                        confidential or proprietary at the time of disclosure or should be reasonably understood to be confidential considering the nature of the information and the circumstances
                        surrounding disclosure.
                        <br />
                        <br />
                        <strong>(b)</strong>{' '}
                        <em>
                            <strong>“Customer Data”</strong>
                        </em>{' '}
                        means data collected by Parspec through the Services
                        <br />
                        <br />
                        <strong>(c)</strong>{' '}
                        <strong>
                            <em>"Documentation”</em>
                        </strong>{' '}
                        means the printed and digital instructions, on-line help files, technical documentation and user manuals made available by Parspec to Customer describing the intended operation
                        of the Services.
                        <br />
                        <br />
                        <strong>(d)</strong> “
                        <strong>
                            <em>Parspec Materials</em>
                        </strong>
                        <span>
                            ” means the Services, Documentation and any other materials, information (including Parspec’s Confidential Information) delivered or made available by Parspec under this
                            Agreement.
                        </span>
                        <br />
                        <br />
                        <strong>(e) </strong>“
                        <strong>
                            <em>Parspec O&amp;M</em>
                        </strong>
                        <span>
                            ” means a document created using Parspec’s Services that contains at least one construction product installation manual or warranty and may include product datasheets,
                            drawing or other documents.{' '}
                        </span>
                        <br />
                        <br />
                        <strong>(f)</strong> “
                        <strong>
                            <em>Parspec Quote</em>
                        </strong>
                        <span>
                            ” means a document created using Parspec’s Services that contains a construction product bill of materials with pricing information and may include product datasheets.
                        </span>
                        <br />
                        <br />
                        <strong>(g) </strong>“
                        <strong>
                            <em>Parspec Submittal</em>
                        </strong>
                        ” means a document created using Parspec’s Services that contains at least one construction product datasheet and may include product installation manuals, warranties, drawings
                        or other documents.
                        <br />
                        <br />
                        <strong>(h) </strong>
                        <span>“</span>
                        <strong>
                            <em>Permitted User</em>
                        </strong>
                        <span>” means the employees, agents and consultants of Customer that Customer permits to use the Services.</span>
                        <br />
                        <br />
                        <strong>(i)</strong> “
                        <strong>
                            <em>Platform Credit</em>
                        </strong>
                        ” means the credits that Customer can exchange to utilize Parspec’s Services as described in Section 1.2.
                        <br />
                        <br />
                        <strong>
                            (j)<em> "Services”</em>
                        </strong>{' '}
                        means Parspec’s product selection, quoting, submittal and operations and maintenance documentation capabilities provided on a remote, software-as-a-service basis, including all
                        fixes, updates and upgrades to the same made available to Customer.
                        <br />
                        <br />
                        <strong>(k)</strong> “
                        <strong>
                            <em>Specifications</em>
                        </strong>
                        ” means the then-current version of the product specifications for the features and functionality of the Services made available by Parspec to Customer.
                        <br />
                        <br />
                        <strong>(l)</strong>{' '}
                        <em>
                            <strong>“Subscription”</strong>
                        </em>{' '}
                        means the monthly or annual plan selected by Customer, which is based upon the number of Platform Credits (as defined below) that Customer intends to use in the selected time
                        period.
                        <br />
                        <br />
                        <strong>1.2 Description</strong>.&nbsp; Parspec permits Customers and its Permitted Users to use the following Services:
                        <br />
                        <br />
                        <strong>(a)</strong> Create a Parspec Quote and then share with third parties for review and/or approval, in exchange for one [1] Platform Credit if the Quote contains product
                        pricing, [1] Platform Credit if the Quote contains product datasheets, and [1] Platform Credit if the Quote contains warranties or installation manuals.&nbsp;
                        <br />
                        <br />
                        <strong>(b)</strong> Create a Parspec Submittal and then share with third parties for review and/or approval, in exchange for one [1] Platform Credit if the Submittal contains
                        product datasheets and one [1] Platform Credit if the Submittal contains product installation manuals or warranties.
                        <br />
                        <br />
                        <strong>(c)</strong> Create a Parspec O&amp;M and then share with third parties for review and/or approval, in exchange for one [1] Platform Credit if the O&amp;M contains
                        construction product installation manuals or warranties and one [1] Platform Credit if the O&amp;M contains product datasheets.
                        <br />
                        <br />
                        <strong>(d)</strong> Utilize Product Finder for the Subscription term, in exchange for a specified number of Platform Credits per Permitted User.
                        <br />
                        Platform credit consumption is additive (e.g., creating a Parspec Quote that includes pricing and product datasheets will consume two [2] Platform Credits). &nbsp;Only one [1]
                        Platform Credit can be consumed per document type (e.g., datasheet, warranty) per Parspec Bill of Materials. &nbsp;The number of Platform Credits available to Customer is
                        dependent on the Subscription tier purchased by Customer.
                        <br />
                        <br />
                        <strong>1.3</strong> <strong>Account Creation</strong>.&nbsp; In order to access and use certain features of the Services offered by Parspec, Customer may be required to
                        register for an account (“
                        <strong>
                            <em>Account</em>
                        </strong>
                        ”) and provide certain information as prompted by the account registration form.&nbsp; Customer represents and warrants that: (a) all required information submitted in
                        connection therewith is truthful and accurate; and (b) Customer will maintain the accuracy of such information.
                        <br />
                        <br />
                        <strong>1.4</strong> <strong>Free Trials</strong>.&nbsp; If the Services are made available to Customer without charge on a trial basis, the term of such trial period (“
                        <strong>
                            <em>Trial Period</em>
                        </strong>
                        ”) will be from the Effective Date of this Agreement until the end of the trial period as determined by Parspec in its sole discretion.&nbsp; At the end of the Trial Period,
                        Customer may elect a Subscription, which will begin the day after the expiration of the Trial Period and will be subject to the Subscription fees, which are calculated based on
                        the number of Platform Credits elected or used by Customer.&nbsp; Notwithstanding Section 8, if Customer provides notice of its desire to terminate this Agreement during the
                        Trial Period, this Agreement will expire on the last day of the Trial Period.
                        <br />
                        <br />
                        <strong>2. License</strong>
                        <br />
                        <br />
                        <strong>2.1&nbsp;</strong>
                        <strong>Access to the Services</strong>.&nbsp; Subject to the terms and conditions of this Agreement, Parspec hereby grants to Customer, during the Subscription term, a
                        non-exclusive, non-sublicensable, non-transferable, revocable right and license to access and use the Services, solely for Customer’s internal business purposes and in
                        accordance with the Documentation, subject to any usage limitations.
                        <br />
                        <br />
                        <strong>2.2 Documentation</strong>.&nbsp; Subject to the terms and conditions of this Agreement, Parspec hereby grants to Customer a non-exclusive, non-sublicensable,
                        non-transferable, revocable license during the applicable Subscription term to reproduce, without modification, and internally use a reasonable number of copies of the
                        Documentation solely in connection with Customer’s authorized use of the applicable Services.&nbsp; Customer will include on any copies it makes of the Services and
                        Documentation the copyright notices or proprietary legends contained within the same.
                        <br />
                        <br />
                        <strong>2.3 Permitted User Access</strong>.&nbsp; If Customer is given passwords to access the Services on Parspec’s systems, Customer will require that all Permitted Users
                        keep their individual user ID and password information strictly confidential; Permitted Users shall not share such information with any other person, even if such person is
                        also an employee or agent of Customer.&nbsp; Customer will ensure its Permitted Users comply with this Agreement, and Customer is responsible for any and all actions taken
                        using Customer’s Accounts and passwords or in Customer’s own trusted environment.&nbsp; Customer shall notify Parspec as soon as reasonably practicable of any unauthorized use
                        of any user ID, password or Account or any other known or suspected breach of security.
                        <br />
                        <br />
                        <strong>2.4 Customer Limitations</strong>.&nbsp; The rights granted herein are subject to the following restrictions.&nbsp; Customer will not, and will not allow any Permitted
                        User or third party to, directly or indirectly:
                        <br />
                        <br />
                        <strong>(a)</strong>&nbsp;modify, adapt, alter, translate or create derivate works of the Services;
                        <br />
                        <br />
                        <strong>(b) </strong>rent, lease, loan, copy, provide access to or sublicense any Services to a third party;
                        <br />
                        <br />
                        <strong>(c)</strong> use any Services to provide or incorporate any Services into any product or service provided to a third party, such as in a service bureau or time-sharing
                        arrangement;
                        <br />
                        <br />
                        <strong>(d)</strong> reverse engineer, decompile, disassemble, or otherwise seek to obtain the source code or non-public APIs to the Services (notwithstanding the foregoing,
                        decompiling software is permitted solely to the extent the laws of Customer’s jurisdiction give Customer the right to do so for obtaining information necessary to render the
                        software interoperable with other software; provided, however, that Customer must first request such information from Parspec and Parspec may, in its discretion, either provide
                        such information to Customer or impose reasonable conditions, including a reasonable fee, on such use of the software);
                        <br />
                        <br />
                        <strong>(e)</strong> remove or obscure any proprietary or other notices contained in any Services (including any reports or data printed from the Services);
                        <br />
                        <br />
                        <strong>(f) </strong>publicly disseminate information regarding the performance of the Services;
                        <br />
                        <br />
                        <strong>(g) </strong>interfere with or disrupt the Services or the hardware or network used to operate the Services or access the Services through an unapproved interface; or
                        <br />
                        <br />
                        <strong>(h)</strong> otherwise use the Services in any manner that exceeds the grant of rights permitted under this Section 2 or in a manner inconsistent with applicable law.
                        For clarity, all of the foregoing restrictions will also apply to the Documentation provided by Parspec to Customer.
                        <br />
                        <br />
                        <strong>2.5 Reservation of Rights.</strong> &nbsp;Except as expressly granted in this Agreement, Parspec does not grant to Customer any license or rights (by implication,
                        estoppel, or otherwise) in the Parspec Materials. &nbsp;All other rights are expressly reserved by Parspec. &nbsp;Notwithstanding anything herein to the contrary, all rights in
                        and to the Services and Documentation are licensed and not sold or transferred. &nbsp;Customer will include on any copies it makes of the Services and Documentation the
                        copyright notices or proprietary legends contained within the same.
                        <br />
                        <br />
                        <strong>2.6 Customer Responsibilities.</strong> &nbsp;Customer will (i) be responsible for all use of the Services under its Account, (ii) use commercially reasonable efforts
                        to prevent unauthorized access to or use of the Services and notify Parspec promptly of any such unauthorized access or use and (iii) be responsible for obtaining and
                        maintaining any equipment, software and ancillary services needed to connect to, access or otherwise use the Services, including as set forth in the Documentation. Customer
                        will be solely responsible for the use of Services under its Account, its failure to maintain equipment, software and services, and Parspec will have no liability in such
                        cases.
                        <br />
                        <br />
                        <strong>2.7 Support; Service Levels. </strong>&nbsp;During the Subscription term, and for so long that Customer is current in its payment obligations, Parspec will provide the
                        Services in accordance with the service level, support and service specific terms set forth in Exhibit A <strong>(“SLA”).</strong> &nbsp;Customer acknowledges that Parspec may
                        adjust the SLA at any time; provided that, if such changes will have a material adverse effect on Customer, Parspec will provide at least thirty [30] days’ prior written notice
                        thereof and such changes will not go into effect for Customer until the beginning of the next subscription term.
                        <br />
                        <br />
                        <strong>3. Fees</strong>
                        <br />
                        <br />
                        <strong>3.1 Subscription Fees</strong>
                        <span>.&nbsp; Customer will pay Parspec the non-refundable fees (“</span>
                        <strong>
                            <em>Fees</em>
                        </strong>
                        <span>
                            ”) set forth in the Subscription selected by Customer.&nbsp; Except as otherwise specified herein, (a) Fees are quoted and payable in United States dollars and (b) payment
                            obligations are non-cancelable and non-pro-ratable for partial Subscription periods.&nbsp; The Fees associated with a Subscription are based on estimated usage, and
                            Customer is allocated a certain number of Platform Credits based on the selected Subscription tier.&nbsp; The Subscription will automatically renew for additional periods
                            of the same duration as the Subscription term at Parspec’s then-current fees for such Services unless Customer cancels the Subscription pursuant to Section 8.1 or if
                            Parspec provides written notice to Customer of its intention not to renew at least thirty (30) days prior to the expiration of the then-current term.&nbsp; If Customer uses
                            all Platform Credits prior to the end of the Subscription term, the Customer will automatically be upgraded to the Subscription tier that is closest in Fees to the
                            Customer’s most recent Subscription tier and offers a greater number of Platform Credits per annum, unless Customer cancels the Subscription pursuant to Section 8.1 or
                            provides written notice to Parspec before Customer uses all Platform Credits. The upgraded Subscription will be of the same duration as the original Subscription
                            term.&nbsp; In the event Customer exceeds its Submittal Credits and does not renew or upgrade its Subscription, Customer will not be able to access the Services effective
                            immediately.&nbsp; Parspec may increase the fees payable under a Subscription, effective at the start of the then-upcoming renewal term, by providing written notice via
                            email or its Services of such increase to Customer at least thirty (30) days prior to the end of the then-current term.{' '}
                        </span>
                        <br />
                        <br />
                        <strong>3.2 Payment Terms</strong>
                        <span>
                            .&nbsp; Customer shall provide accurate and up-to-date payment information at the time Customer selects a Subscription.&nbsp; Customer must provide Parspec or Parspec’s
                            third-party payment processor with a valid credit card (Visa, MasterCard, or any other issuer accepted by us or a third-party payment processor) as a condition for signing
                            up for the Services.&nbsp; By providing Parspec or Parspec’s third-party payment processor with your credit card number or other payment information, you agree that Parspec
                            or Parspec’s third-party payment processor is authorized to immediately invoice Customer for all fees and charges due and payable to Parspec hereunder and that no
                            additional notice or consent is required.&nbsp; Customer shall immediately notify Parspec of any change in Customer’s billing address or payment information.&nbsp; Parspec
                            reserves the right to change its prices and billing methods upon notice to Customer.&nbsp; By subscribing, Customer authorizes Parspec to charge Customer’s payment provider
                            now and at the beginning of any subsequent term.&nbsp; Without limiting any of Parspec’s other rights and remedies, upon renewal of Customer’s Subscription, if Parspec does
                            not receive payment, (a) Customer shall pay all amounts due on the Account upon demand, and/or (b) Parspec may terminate or suspend Customer’s subscription.{' '}
                        </span>
                        <br />
                        <br />
                        <strong>3.3 Audits</strong>
                        <span>
                            .&nbsp; Customer will maintain, and Parspec will be entitled to audit, any records relevant to Customer’s use of the Parspec Materials hereunder.&nbsp; Parspec may audit
                            such records on reasonable notice at Parspec’s cost (or if the audits reveal material non-compliance with this Agreement, at Customer’s cost).
                        </span>
                        <br />
                        <br />
                        <strong>3.4 Late Payment</strong>.&nbsp; If Customer fails to make timely payments, Parspec reserves the right to charge a finance charge of the lesser of one and one half
                        percent [1.5%] interest per month or the maximum amount permitted under law, with such interest charges starting on the due date for such Fees, plus any related collection and
                        legal costs actually incurred by Parspec in connection with its collection of such Fees
                        <br />
                        <br />
                        <strong>3.5 Taxes</strong>
                        <span>.&nbsp; All amounts payable hereunder are exclusive of any sales, use and other taxes or duties, however designated (collectively “</span>
                        <strong>
                            <em>Taxes</em>
                        </strong>
                        <span>
                            ”).&nbsp; Customer will be solely responsible for payment of all Taxes, except for those taxes based on the income of Parspec.&nbsp; Customer will not withhold any taxes
                            from any amounts due to Parspec.
                        </span>
                        <br />
                        <br />
                        <strong>4. Proprietary Rights and Confidentiality</strong>
                        <br />
                        <br />
                        <strong>4.1 Proprietary Rights</strong>.&nbsp; Customer acknowledges that Parspec owns and retains all rights, title, and interest, including all intellectual property rights,
                        in and to the Parspec Materials, including all technology, software, algorithms, user interfaces, trade secrets, techniques, designs, inventions, works of authorship, and other
                        tangible and intangible material and information pertaining thereto or included therein, and nothing in the Agreement shall preclude or restrict Parspec from using or
                        exploiting any concepts, ideas, techniques or know-how of or related to the Parspec Materials or otherwise arising in connection with Parspec’s performance under the
                        Agreement.&nbsp; Other than as expressly set forth in the Agreement, no licenses or other rights in or to the Parspec Materials are granted to Customer and all such rights are
                        hereby expressly reserved.&nbsp; Customer acknowledges that the Services and its data analytics capability relies on insights derived from Customer Data.&nbsp; Accordingly,
                        Customer to grants Parspec a worldwide, royalty-free license to use, host, perform, display and create derivative works from the Customer Data, including by combining Customer
                        Data with data from third party sources and utilizing machine learning and artificial intelligence applications, for the purposes of (a) providing the Services and performing
                        its obligations and exercising its rights under this Agreement; (b) complying with applicable laws and regulations; (c) operating, analyzing and improving Parspec’s services.
                        <br />
                        <br />
                        <strong>4.2 Feedback</strong>
                        <span>.&nbsp; Customer may from time to time provide Parspec suggestions or comments for enhancements or improvements, new features or functionality or other feedback (“</span>
                        <strong>
                            <em>Feedback</em>
                        </strong>
                        <span>
                            ”) with respect to the Services.&nbsp; Parspec will have full discretion to determine whether or not to proceed with the development of any requested enhancements,
                            improvements, new features or functionality.&nbsp; Parspec will have the full, unencumbered right, without any obligation to compensate or reimburse Customer, to use,
                            incorporate and otherwise fully exercise and exploit any such Feedback in connection with its products and Services.
                        </span>
                        <br />
                        <br />
                        <strong>4.3 Confidentiality</strong>.&nbsp; Each Party agrees that it will use the Confidential Information of the other Party solely in accordance with the provisions of this
                        Agreement and it will not disclose, or permit to be disclosed, the same directly or indirectly, to any third party without the other Party’s prior written consent, except as
                        otherwise permitted hereunder.&nbsp; However, either Party may disclose Confidential Information (a) to its employees, officers, directors, attorneys, auditors, financial
                        advisors and other representatives (collectively, “
                        <strong>
                            <em>Representatives</em>
                        </strong>
                        ”) who have a need to know and are legally bound to keep such information confidential by confidentiality obligations consistent with those of this Agreement (provided that the
                        Party disclosing the Confidential Information to its Representatives shall be responsible for any actions of its Representatives that would breach this Section 4.3 if such
                        Representatives were party to this Agreement and bound by same); and (b) as required by law (in which case the receiving Party will provide the disclosing Party with prior
                        written notification thereof, will provide the disclosing Party with the opportunity to contest such disclosure, and will use its reasonable efforts to minimize such disclosure
                        to the extent permitted by applicable law).&nbsp; Neither Party will disclose the terms of this Agreement to any third party, except that either Party may confidentially
                        disclose such terms to actual or potential lenders, investors or acquirers.&nbsp; The obligations of confidentiality in this Section 4.3 shall not apply to information which
                        (a) is in or enters the public domain through no fault of receiving Party; (b) was properly known to receiving Party, without restriction, prior to disclosure by the disclosing
                        Party; (c) was properly disclosed to the receiving Party, without restriction, by another person with the legal authority to do so; or (d) is independently developed by the
                        receiving Party without use of or reference to the disclosing Party’s Confidential Information.&nbsp; Each Party agrees to exercise due care in protecting the Confidential
                        Information from unauthorized use and disclosure.&nbsp; In the event of actual or threatened breach of the provisions of this Section or the licenses contained herein, the
                        non-breaching Party will be entitled to seek immediate injunctive and other equitable relief, without waiving any other rights or remedies available to it.&nbsp; Each Party
                        will promptly notify the other in writing if it becomes aware of any violations of the confidentiality obligations set forth in this Agreement.
                        <br />
                        <br />
                        <strong>4.4 Customer Data</strong>.&nbsp; Except for purposes of (d) or (e) of this Section 4.4, Parspec will not store any piece of Customer Data beyond five [5] years after
                        the termination of this Agreement.&nbsp; Customer agrees that Parspec may use Customer Data as necessary to make available the Services, perform its obligations hereunder, and
                        improve the Services, including, without limitation, performing any required, usual, appropriate, or acceptable activities relating to the Services, such as: (a) providing or
                        supporting the use of the Services, performing professional services, and carrying out the business of which the Services is a part; (b) training Parspec’s machine learning
                        algorithms and improving Parspec’s data analytics capabilities; (c) carrying out any benefits, rights, and obligations relating to the Services; (d) complying with the
                        requirements of this Agreement, applicable law or applicable industry self-regulatory standards; and (e) maintaining records relating to the Services, including, without
                        limitation, to demonstrate compliance with the requirements described in the preceding clause and to defend against claims that such requirements have been violated.&nbsp;
                        Customer agrees that Parspec may also use and exploit in any manner on a worldwide, irrevocable, perpetual, royalty-free basis, any aggregated non-personally identifiable
                        information related to any usage of the Services to operate and improve Parspec’s products and services.&nbsp; Customer acknowledges and agrees that, notwithstanding anything
                        to the contrary herein, Parspec may, in its sole discretion, erase or delete from the Services any Customer Data that it reasonably believes is illegal, harmful, objectionable,
                        lewd, not related to the function of or necessary for the use of the Services, or that Parspec determines may, as a result of Parspec possessing such data, harm Parspec’s
                        business or reputation.&nbsp; Each party will comply with its respective obligations under applicable privacy laws with respect to its collection, use, sharing, retention and
                        other processing of Customer Data, including with respect to maintaining reasonable information security measures designed to protect the Customer Data, honoring rights
                        individuals under such laws and giving notifications of security breaches to government authorities and affected individuals.&nbsp; Customer shall ensure (and is solely
                        responsible for ensuring) that all notices are given, and all rights, authorizations, and consents are obtained, as are required under applicable law or otherwise for Customer
                        Data to be shared with Parspec and for Parspec to collect, use, share, retain and otherwise process the Customer Data as contemplated by this Agreement.
                        <br />
                        <br />
                        <strong>5. Representations, Warranties and Disclaimers</strong>
                        <br />
                        <br />
                        <strong>5.1 DISCLAIMERS</strong>
                        <span>
                            .&nbsp; EXCEPT AS EXPRESSLY SET FORTH HEREIN, EACH PARTY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE,
                            NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.&nbsp; CUSTOMER EXPRESSLY UNDERSTAND AND AGREES THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, CUSTOMER’S USE OF
                            THE SERVICES IS AT CUSTOMER’S SOLE RISK, AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE BASIS,” WITH ALL FAULTS.&nbsp; PARSPEC MAKES NO WARRANTY,
                            REPRESENTATION OR CONDITION THAT: (1) THE SERVICES WILL MEET CUSTOMER’S REQUIREMENTS; (2) CUSTOMER’S USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                            ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE.&nbsp; NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
                            FROM PARSPEC OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.&nbsp; ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH THE PARSPEC’S
                            SERVICES IS ACCESSED AT CUSTOMER’S OWN RISK, AND CUSTOMER SHALL BE SOLELY RESPONSIBLE FOR ANY LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT.&nbsp; THE SERVICES MAY BE
                            SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS.&nbsp; PARSPEC MAKES NO WARRANTY, REPRESENTATION OR CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO,
                            THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.
                        </span>
                        <br />
                        <br />
                        <strong>5.2 BETA PRODUCTS</strong>.&nbsp; FROM TIME TO TIME, CUSTOMER MAY HAVE THE OPTION TO PARTICIPATE IN A PROGRAM WITH PARSPEC WHERE CUSTOMER IS PERMITTED ACCESS TO ALPHA
                        OR BETA PRODUCTS, FEATURES OR DOCUMENTATION (COLLECTIVELY, “
                        <strong>
                            <em>BETA PRODUCTS</em>
                        </strong>
                        ”) OFFERED BY PARSPEC.&nbsp; THE BETA PRODUCTS ARE PROVIDED “
                        <strong>
                            <em>AS IS</em>
                        </strong>
                        ”.&nbsp; EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, PARSPEC DOES NOT PROVIDE ANY INDEMNITIES, SERVICE LEVEL COMMITMENTS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
                        WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, IN RELATION TO THE BETA PRODUCTS.&nbsp; CUSTOMER OR PARSPEC MAY TERMINATE
                        CUSTOMER’S ACCESS TO THE BETA PRODUCTS AT ANY TIME,
                        <br />
                        <br />
                        <strong>6. Indemnification</strong>
                        <br />
                        <br />
                        <strong>6.1 Indemnity by Parspec</strong>
                        <span>.&nbsp; Parspec will defend Customer against any claim, demand, suit, or proceeding (“</span>
                        <strong>
                            <em>Customer Claim</em>
                        </strong>
                        <span>
                            ”) made or brought against Customer by a third party alleging that the use of the Services as permitted hereunder infringes or misappropriates a United States patent,
                            copyright or trade secret and will indemnify Customer for any damages finally awarded against Customer in connection with any such Customer Claim (or any settlement
                            approved by Parspec); provided that (a) Customer will promptly notify Parspec of such Customer Claim, (b) Parspec will have the sole and exclusive authority to defend
                            and/or settle any such Customer Claim (provided that Parspec may not settle any Customer Claim without Customer’s prior written consent, which will not be unreasonably
                            withheld, unless it unconditionally releases Customer of all related liability), and (c) Customer reasonably cooperates with Parspec in connection therewith.&nbsp; If the
                            use of the Services by Customer has become, or in Parspec’s opinion is likely to become, the subject of any claim of infringement, Parspec may at its option and expense (i)
                            procure for Customer the right to continue using and receiving the Services as set forth hereunder; (ii) replace or modify the Services to make it non-infringing (with
                            comparable functionality); or (iii) if the options in clauses (i) or (ii) are not reasonably practicable, terminate this Agreement and provide a pro rata refund of any
                            prepaid fees corresponding to the terminated portion of the applicable subscription term.&nbsp; Parspec will have no liability or obligation with respect to any Customer
                            Claim if such Customer Claim is caused in whole or in part by (A) compliance with designs, guidelines, plans or specifications provided by Customer; (B) use of the Services
                            by Customer not in accordance with this Agreement or failure to install updates provided or recommended by Parspec in order to avoid such Customer Claim; (C) modification
                            of the Services by any party other than Parspec without Parspec’s express consent; (D) Customer’s Confidential Information or (E) the combination, operation or use of the
                            Services with other applications, portions of applications, product(s) or services(collectively, the “
                        </span>
                        <strong>
                            <em>Excluded Claims</em>
                        </strong>
                        <span>
                            ”).&nbsp; This Section states Parspec’s sole and exclusive liability and obligation, and Customer’s exclusive remedy, for any claim of any nature related to infringement or
                            misappropriation of intellectual property.&nbsp; SECTION 6.1 STATES THE ENTIRE LIABILITY OF PARSPEC, AND THE EXCLUSIVE REMEDY OF CUSTOMER, WITH RESPECT TO ANY ACTUAL OR
                            ALLEGED INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS BY THE SERVICES OR ANY PART THEREOF.
                        </span>
                        <br />
                        <br />
                        <strong>6.2 Indemnification by Customer</strong>
                        <span>
                            .&nbsp; Customer will defend Parspec against any Excluded Claim made or brought against Parspec by a third party arising out of the Excluded Claims, and Customer will
                            indemnify Parspec for any damages finally awarded against Parspec in connection with any such Excluded Claim (or any settlement approved by Customer); provided that (a)
                            Parspec will promptly notify Customer of such Excluded Claim, (b) Customer will have the sole and exclusive authority to defend and/or settle any such Excluded Claim
                            (provided that Customer may not settle any Excluded Claim without Parspec’s prior written consent, which will not be unreasonably withheld, unless it unconditionally
                            releases Parspec of all liability), and (c) Parspec reasonably cooperates with Customer in connection therewith.
                        </span>
                        <br />
                        <br />
                        <strong>7. Limitation of Liability</strong>
                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PARSPEC SHALL HAVE NO RESPONSIBILITY OR LIABILITY OF ANY KIND, WHETHER FOR BREACH OF WARRANTY OR OTHERWISE, ARISING OR
                        RESULTING FROM ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS,
                        LOSS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, INTERNET FAILURE, LOST CONTENT OR DATA, EVEN IF A REPRESENTATIVE OF SUCH
                        PARTY HAS BEEN ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; IN NO EVENT SHALL PARSPEC’S LIABILITY EXCEED THE AMOUNTS PAID BY CUSTOMER DURING THE
                        TWELVE (12) MONTHS PRECEDING THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY.&nbsp;{' '}
                        <strong>
                            CUSTOMER ACKNOWLEDGES THAT THE FEES REFLECT THE ALLOCATION OF RISK SET FORTH IN THIS AGREEMENT AND THAT PARSPEC WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE
                            LIMITATIONS ON ITS LIABILITY.
                        </strong>
                        <br />
                        <br />
                        <strong>8. Termination</strong>
                        <br />
                        <br />
                        <strong>8.1 Term</strong>.&nbsp; The term of this Agreement will commence on the date Customer’s Subscription begins and will continue for the subscription term selected by
                        Customer.&nbsp; The Subscription term will automatically renew for successive renewal terms equal to the length of the initial Subscription at Parspec’s current rates.&nbsp; If
                        the Customer uses all Platform Credits prior to the end of the Subscription term, the Subscription will be automatically upgraded as described in Section 3.1.&nbsp; Customer
                        accepts the automatic renewal or upgrade of the Subscription unless Customer cancels the Subscription at least one (1) day prior to the date the Subscription will renew or
                        before the Customer uses all Platform Credits allotted in the then-current Subscription.&nbsp; Customer can cancel their Subscription by logging into Customer’s Account and
                        going through the cancellation procedure on the “Change/Cancel Subscription” page of Customer’s “Account Settings” page.&nbsp; Upon the cancellation, Customer may use or access
                        the Services until the end of Customer’s then-current Subscription term or until the Customer uses all their Platform credits, whichever comes first.&nbsp; The Subscription
                        will not be renewed after the then-current term expires.
                        <br />
                        <br />
                        <strong>8.2 Termination</strong>
                        <span>
                            .&nbsp; Customer may cancel its Subscription in accordance with Section 8.1. Parspec may terminate this Agreement upon sixty (60) days’ written notice to Customer.&nbsp;
                            Each Party may also terminate this Agreement upon written notice in the event (a) the other Party commits any material breach of this Agreement and fails to remedy such
                            breach within thirty (30) days after written notice of such breach or (b) subject to applicable law, upon the other Party’s liquidation, commencement of dissolution
                            proceedings or assignment of substantially all its assets for the benefit of creditors, or if the other Party become the subject of bankruptcy or similar proceeding that is
                            not dismissed within sixty (60) days.
                        </span>
                        <br />
                        <br />
                        <strong>8.3 Survival</strong>
                        <span>
                            .&nbsp; Upon termination of this Agreement all licenses and other rights and obligations will immediately terminate except that Sections 2.4, 2.5, 4, 5, 6, 7, 8.3 and 9
                            will survive indefinitely.
                        </span>
                        <br />
                        <br />
                        <strong>9. General</strong>
                        <br />
                        <br />
                        <strong>9.1 Publicity</strong>.&nbsp; Parspec will not use Customer’s name or trademarks without Customer’s prior written consent (email to suffice).
                        <br />
                        <br />
                        <strong>9.2 Assignment; Delegation</strong>
                        <span>
                            .&nbsp; Neither Party hereto may assign or otherwise transfer this Agreement, in whole or in part, without the other Party’s prior written consent, except that either Party
                            may assign this Agreement without consent to a successor to all or substantially all of its assets or business related to this Agreement.&nbsp; If Customer subscribes to
                            the Services on an enterprise or other unlimited basis, upon a change of control or other acquisition of Customer, Customer’s right to use the Services will be limited to
                            that number of Permitted Users authorized immediately prior to such change of control or acquisition.&nbsp; Any attempted assignment, delegation, or transfer by either
                            Party in violation hereof will be null and void.&nbsp; Subject to the foregoing, this Agreement will be binding on the parties and their successors and assigns.
                        </span>
                        <br />
                        <br />
                        <strong>9.3 Amendment; Waiver</strong>
                        <span>
                            .&nbsp; No amendment or modification to this Agreement, nor any waiver of any rights hereunder, will be effective unless assented to in writing by both parties.&nbsp; Any
                            such waiver will be only to the specific provision and under the specific circumstances for which it was given, and will not apply with respect to any repeated or continued
                            violation of the same provision or any other provision.&nbsp; Failure or delay by either Party to enforce any provision of this Agreement will not be deemed a waiver of
                            future enforcement of that or any other provision.
                        </span>
                        <br />
                        <br />
                        <strong>9.4 Relationship</strong>
                        <span>
                            .&nbsp; Nothing contained herein will in any way constitute any association, partnership, agency, employment or joint venture between the parties hereto, or be construed to
                            evidence the intention of the parties to establish any such relationship.&nbsp; Neither Party will have the authority to obligate or bind the other in any manner, and
                            nothing herein contained will give rise or is intended to give rise to any rights of any kind to any third parties.
                        </span>
                        <br />
                        <br />
                        <strong>9.5 Unenforceability</strong>
                        <span>
                            .&nbsp; If a court of competent jurisdiction determines that any provision of this Agreement is invalid, illegal, or otherwise unenforceable, such provision will be
                            enforced as nearly as possible in accordance with the stated intention of the parties, while the remainder of this Agreement will remain in full force and effect and bind
                            the parties according to its terms.
                        </span>
                        <br />
                        <br />
                        <strong>9.6 Governing Law</strong>
                        <span>
                            .&nbsp; This Agreement will be governed by the laws of the State of California, exclusive of its rules governing choice of law and conflict of laws.&nbsp; This Agreement
                            will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.&nbsp; All disputes arising out of this Agreement will be subject to
                            the exclusive jurisdiction of the state and federal courts located in Santa Clara County, California, and each party hereby consents to the personal jurisdiction thereof.
                        </span>
                        <br />
                        <br />
                        <strong>9.7 Notices</strong>
                        <span>
                            .&nbsp; Any notice required or permitted to be given hereunder will be given by a Party to the other Party in writing, and such notice will be effective upon delivery as
                            follows: (a) if to Customer, when sent via email to the email address on record for Customer; and (b) if to Parspec, when sent via email to support@parspec.io.
                        </span>
                        <br />
                        <br />
                        <strong>9.8 Entire Agreement</strong>
                        <span>
                            .&nbsp; This Agreement comprises the entire agreement between Customer and Parspec with respect to its subject matter, and supersedes all prior and contemporaneous
                            proposals, statements, sales materials or presentations and agreements (oral and written).&nbsp; No oral or written information or advice given by Parspec, its agents or
                            employees will create a warranty or in any way increase the scope of the warranties in this Agreement.
                        </span>
                        <br />
                        <br />
                        <strong>9.9 Force Majeure</strong>
                        <span>
                            .&nbsp; Neither Party will be deemed in breach hereunder for any cessation, interruption or delay in the performance of its obligations due to causes beyond its reasonable
                            control, including earthquake, flood, or other natural disaster, act of God, labor controversy, civil disturbance, terrorism, war (whether or not officially declared),
                            cyber-attacks (e.g., denial of service attacks), or the inability to obtain sufficient supplies, transportation, or other essential commodity or service required in the
                            conduct of its business, or any change in or the adoption of any law, regulation, judgment or decree.
                        </span>
                        <br />
                        <br />
                        <strong>9.10 Government Terms</strong>
                        <span>
                            .&nbsp; Parspec provides the Services, including related software and technology, for ultimate federal government end use solely in accordance with the terms of this
                            Agreement.&nbsp; If Customer (or any of its customers) is an agency, department, or other entity of any government, the use, duplication, reproduction, release,
                            modification, disclosure, or transfer of the Services, or any related documentation of any kind, including technical data, software, and manuals, is restricted by the terms
                            of this Agreement.&nbsp; All other use is prohibited and no rights than those provided in this Agreement are conferred.&nbsp; The Services was developed fully at private
                            expense.
                        </span>
                        <br />
                        <br />
                        <strong>9.11 Interpretation</strong>
                        <span>.&nbsp; For purposes hereof, “including” means “including without limitation”.</span>
                        <br />
                        <br />
                        <p style={{ textAlign: 'center' }}>
                            <strong>Exhibit A</strong>
                            <strong>
                                <br />
                            </strong>
                            <strong>Service Level and Support Terms</strong>
                        </p>
                        <br />
                        This Parspec Service Level Agreement (“
                        <strong>
                            <em>SLA</em>
                        </strong>
                        ”) is policy governing use of the Services under the terms of the Parspec Customer Agreement (“
                        <strong>
                            <em>Agreement</em>
                        </strong>
                        ”) between Parspec and Customer.&nbsp; Unless otherwise provided herein, this SLA is subject to the terms of the Agreement and capitalized terms not otherwise defined will have
                        the meaning set forth in the Agreement.&nbsp; This SLA states Customer’s sole and exclusive remedy for any failure by Parspec to meet the Target Availability commitment (as
                        defined below).
                        <strong>1. Availability</strong>
                        <br />
                        <br />
                        During the Subscription term, the Services are subject to the Target Availability set forth below:
                        <strong>1.1 Target Availability</strong>
                        <span>.&nbsp; Parspec will make the Services available for at least the percentage of time in each calendar month set forth in the table below (“</span>
                        <strong>
                            <em>Target Availability</em>
                        </strong>
                        <span>”), subject to the Exclusions set forth below.&nbsp; “</span>
                        <strong>
                            <em>Available</em>
                        </strong>
                        <span>” means the Services are substantially operable for the purposes for which they are provided.&nbsp; “</span>
                        <strong>
                            <em>Downtime</em>
                        </strong>
                        <span>” means the Services are not available.</span>
                        <table style={{ borderCollapse: 'collapse', tableLayout: 'fixed', marginLeft: 'auto', marginRight: 'auto', border: '1px solid #99acc2', width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '73.3652%', borderWidth: '1px', borderStyle: 'solid', padding: '5px' }}>
                                        <strong>Services</strong>
                                    </td>
                                    <td style={{ width: '26.6348%', borderWidth: '1px', borderStyle: 'solid', padding: '5px' }}>
                                        <strong>Target Availability</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '73.3652%', borderWidth: '1px', borderStyle: 'solid', padding: '5px' }}>
                                        Parspec’s remote, software-as-a-service to support construction product selection as well as the authoring and management of sales quotes, submittals, and
                                        operation and maintenance documentation.
                                    </td>
                                    <td style={{ width: '26.6348%', borderWidth: '1px', borderStyle: 'solid', padding: '5px' }}>99.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <strong>1.2 Exclusions.</strong>
                        <span>
                            &nbsp; Achievement of Target Availability will not be affected by Downtime attributable to any of the following exclusions:&nbsp; Scheduled Downtime and, to the extent
                            Parspec is complying with a commercially reasonable written business continuity plan, issues caused by matters beyond Parspec’s reasonable control such as, acts of God,
                            acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (other than those involving Parspec’s employees),
                            denial-of-service attacks, third-party cloud infrastructure provider (e.g., Amazon Web Services, Google Cloud), third-party Internet service provider failures or delays,
                            failures of wireless carrier connectivity for any reason, or issues caused by Customer’s devices, equipment or systems (“
                        </span>
                        <strong>
                            <em>Exclusions</em>
                        </strong>
                        <span>”).&nbsp; “</span>
                        <strong>
                            <em>Scheduled Downtime</em>
                        </strong>
                        <span>
                            ” means Downtime during certain time windows for maintenance purposes on a schedule maintained by Parspec (which will typically be during the periods of lowest anticipated
                            usage), provided that Scheduled Downtime in excess of 24 hours in any twelve [12] month period (other than at the request of Customer) will not be deemed an Exclusion.
                        </span>
                        <br />
                        <strong>1.3 Term</strong>
                        <span>.&nbsp; The term of the SLA shall commence on the Effective Date and shall continue for the duration of any active Subscription terms.</span>
                        <br />
                        <strong>2. Support Levels and Response Time</strong>
                        <br />
                        During the Subscription term, the response times the Services by issue type are described below:
                        <table style={{ borderCollapse: 'collapse', tableLayout: 'fixed', marginLeft: 'auto', marginRight: 'auto', border: '1px solid #99acc2', width: '72.8198%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '65.6%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>Severity 1 Initial Response Times:</td>
                                    <td style={{ width: '34.4%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>2 Business Days</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '65.6%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>Severity 2 Initial Response Times:</td>
                                    <td style={{ width: '34.4%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>5 Business Days</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '65.6%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>Severity 3 Initial Response Times:</td>
                                    <td style={{ width: '34.4%', borderWidth: '1px', borderStyle: 'solid', padding: '10px' }}>10 Business Days</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <strong>2.1 Severity Level Definitions</strong>
                        <br />
                        <br />
                        <strong>Severity 1</strong>
                        <span>: A critical problem with the Services which results in the Services’ functionality being completely inoperable or severely degraded.</span>
                        <br />
                        <br />
                        <strong>Severity 2</strong>: A problem with the Services in which any of the following occur: the Services are degraded, major functions are not performing properly, the
                        situation is causing a significant impact to certain portions of customer’s operations or productivity.
                        <br />
                        <br />
                        <strong>Severity 3</strong>: A problem with the Services that is not a Severity 1 or a Severity 2 level problem but is otherwise a failure of the Services to conform to
                        specifications.&nbsp; A minor problem with the Services that (i) affects non-essential functions or (ii) affects material functions in a manner that has minimal (non-material)
                        impact to business operations.
                        <br />
                        <br />
                        <strong>3. Customer Support</strong>
                        <br />
                        <br />
                        <strong>3.1 Availability.&nbsp; </strong>Parspec live technical support hours are 6:00 am Pacific Time until 6:00 pm Pacific Time seven days a week (subject to the below
                        exceptions).&nbsp; Live technical support will not be available on Christmas Day (December 25) and New Year’s Day (January 1). Limited technical support will be available
                        during the hours listed above during Parspec holidays.&nbsp; The current Parspec holidays are set forth below:
                        <br />
                        <br />
                        <ul className="signup-tos-ul">
                            <li>Presidents Day (third Monday of February)</li>
                            <li>Memorial Day (last Monday of May)</li>
                            <li>Independence Day (July 4)</li>
                            <li>Labor Day (first Monday of September)</li>
                            <li>Thanksgiving Day (fourth Thursday in November)</li>
                            <li>Christmas Eve (December 24)</li>
                        </ul>
                        <br />
                        <br />
                        <strong>3.2 Contact Methods.&nbsp;</strong> Technical support can be contacted as follows:
                        <br />
                        <ul className="signup-tos-ul">
                            <li>In app ChatBot</li>
                            <li>Email: support@parspec.io</li>
                        </ul>
                        <br />
                        <br />
                        <br />
                    </>
                }
            </pre>
        </div>
    );
};
