import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Snackbar } from '@parspec/pixel';

import { QuoteOwnersTable } from './QuoteOwnersTable';
import QuoteOwnersGraph from './QuoteOwnersGraph';

import { TAB_NAMES } from '../shared/constants';
import { useGetQuoteOwnersTabDataQuery } from '../queries';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { useGetPersistedFiltersQuery } from '../../shared/queries';
import { EVENT_SERVICE_PAGENAME_VALUES } from '../../shared/utils/constants';
import { FILTERS_INITIAL_VALUE } from '../../BidBoard/shared/constants';
import { QuoteOwnersTabDataResponse } from '../queries/apiTypes';
import { getFilteredTabData, getFiltersForApiParams, getTableHeight } from '../shared/utils';
import { tableDataFormatter } from '../shared/utils';
import Loader from '../../BOM/FinalDocuments/Compile/submittals&o_m/Loader';
import { FILTER_KEYS } from '../shared/components/Filters/constants';

export const QuoteOwners = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerHeight, setContainerHeight] = useState<number>();

    const { data: userData } = useGetUserProfileInfoQuery();
    const { data: filtersData, isLoading: filtersDataLoading } = useGetPersistedFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_QUOTE_OWNERS },
        {
            enabled: Boolean(userData?.data?.id)
        }
    );
    const filters = useMemo(() => {
        return filtersData?.table_settings_by_pk?.filters || FILTERS_INITIAL_VALUE;
    }, [filtersData?.table_settings_by_pk?.filters]);

    const { data: quoteOwnersData, isLoading: isQuoteOwnersDataLoading } = useGetQuoteOwnersTabDataQuery(getFiltersForApiParams(filters), {
        enabled: !filtersDataLoading,
        staleTime: 0
    });

    const tableData = useMemo(() => {
        if (!quoteOwnersData?.data) return [];
        const data = getFilteredTabData(quoteOwnersData?.data || [], filters?.[FILTER_KEYS.SEARCH_TEXT], 'user');
        return tableDataFormatter(data as QuoteOwnersTabDataResponse[], TAB_NAMES.QUOTE_OWNERS);
    }, [quoteOwnersData?.data, filters?.[FILTER_KEYS.SEARCH_TEXT]]);

    const isDataLoading = isQuoteOwnersDataLoading;

    useEffect(() => {
        if (containerRef?.current?.offsetHeight && !isDataLoading) {
            setContainerHeight(containerRef?.current?.offsetHeight);
        }
    }, [containerRef?.current, isDataLoading]);

    const tableHeight = getTableHeight(quoteOwnersData?.data || [], containerHeight);

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {isDataLoading ? (
                <Loader />
            ) : (
                <Box px={6} overflow="auto" height="100%" ref={containerRef}>
                    <QuoteOwnersGraph usersData={tableData as QuoteOwnersTabDataResponse[]} />

                    <Box width={'100%'} height={tableHeight} mt={6}>
                        <QuoteOwnersTable setSnackbarMessage={setSnackbarMessage} tableData={tableData} isDataLoading={isDataLoading} />
                    </Box>
                </Box>
            )}
        </>
    );
};
