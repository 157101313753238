import { Box } from '@parspec/pixel';
import StatsCard from '../shared/StatsCard';
interface FooterProps {
    statsData: {
        title: string;
        count: string;
        helperText?: string | number;
    }[];
}

const Footer: React.FC<FooterProps> = ({ statsData }) => {
    return (
        <Box display="flex" mt="auto" minHeight={96} maxHeight={96} p={4} width={1} gap={4} bgcolor="tertiary.contrastText">
            {statsData?.length ? statsData.map((stat) => <StatsCard key={stat.title} {...stat} />) : null}
        </Box>
    );
};

export default Footer;
