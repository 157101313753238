export const OPTIONS_FOR_NUMBER = [
    {
        id: 'Unique ID',
        name: 'Unique ID'
    },
    {
        id: 'Initials - Date - Unique ID',
        name: 'Initials - Date - Unique ID'
    },
    {
        id: 'Custom Entry',
        name: 'Custom Entry'
    }
];

export const OPTIONS_FOR_NAME = [
    {
        id: 'Project Name',
        name: 'Project Name'
    },
    {
        id: 'BOM Name',
        name: 'BOM Name'
    },
    {
        id: 'Custom Entry',
        name: 'Custom Entry'
    }
];

export const OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY = [
    {
        id: 'Project Name',
        name: 'Project Name'
    },
    {
        id: 'BOM Name',
        name: 'BOM Name'
    }
];

export const OPTIONS_FOR_QUOTE_FOR_BID_DATE = [
    {
        id: 'Quote Creation Date',
        name: 'Quote Creation Date'
    },
    {
        id: 'Quote Due Date',
        name: 'Quote Due Date'
    }
];

export const OPTIONS_FOR_EXPIRATION_DATE = [
    {
        id: 'Bid Date +10 days',
        name: 'Bid Date +10 days'
    },
    {
        id: 'Bid Date +30 days',
        name: 'Bid Date +30 days'
    },
    {
        id: 'Bid Date +60 days',
        name: 'Bid Date +60 days'
    },
    {
        id: 'Bid Date +90 days',
        name: 'Bid Date +90 days'
    }
];

export const OPTIONS_FOR_SUBMITTAL_BID_DATE = [
    {
        id: 'Submittal Creation Date',
        name: 'Submittal Creation Date'
    },
    {
        id: 'Submittal Due Date',
        name: 'Submittal Due Date'
    }
];

export const OPTIONS_FOR_OM_BID_DATE = [
    {
        id: 'O&M Creation Date',
        name: 'O&M Creation Date'
    },
    {
        id: 'O&M Due Date',
        name: 'O&M Due Date'
    }
];

export const OPTIONS_FOR_ADDRESSED_TO = [
    { id: 'Primary Customer', name: 'Primary Customer' },
    { id: 'Custom Entry', name: 'Custom Entry' }
];

export enum ADDRESSED_TO_SELECT_OPTIONS {
    PRIMARY_CUSTOMER = 'Primary Customer',
    CUSTOM_ENTRY = 'Custom Entry'
}
