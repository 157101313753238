import { Outlet } from 'react-router-dom';

import { Box, H5 } from '@parspec/pixel';

import CMSTabs from './CMSTabs';

const ContactManagement = () => {
    return (
        <Box display="flex" flexDirection="column" height="100vh" py={4}>
            <Box bgcolor="primary.contrastText">
                <Box px={4}>
                    <H5 width={'max-content'}>Contact Management</H5>
                </Box>
                <Box px={4}>
                    <CMSTabs />
                </Box>
            </Box>
            <Outlet />
        </Box>
    );
};

export default ContactManagement;
