import { Box, ModalHeader, Modal, ModalFooter, RadioGroup, FileSelector, FileSelectorFileType } from '@parspec/pixel';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAddSuggestionMutation } from '../../shared/DocumentSuggestions/queries';
import { ReuseDialogType, ReuseDialogTypeLabel } from './utils';

const typeOptions = [
    { value: ReuseDialogType.WARRANTY, label: ReuseDialogTypeLabel.warranty },
    { value: ReuseDialogType.GUIDE, label: ReuseDialogTypeLabel.installation_guide },
    { value: ReuseDialogType.DRAWING, label: ReuseDialogTypeLabel.drawing },
    { value: ReuseDialogType.OTHER, label: ReuseDialogTypeLabel.other_document }
];

const howOptions = [
    { value: 'reuse', label: 'Reuse from another product in this BoM' },
    { value: 'upload', label: 'Upload a new document' }
];
type ReuseOMDialogProps = {
    open: boolean;
    handleDialog: () => void;
    confirmAction: (value: { reuse: boolean; document?: ReuseDialogType }) => void;
    selectedRowIds: Array<number>;
};
const subTitle = <Box>Select the document type to add, and how you’d like to add it.</Box>;
const ReuseOMDialog = (props: ReuseOMDialogProps) => {
    const { open, handleDialog, confirmAction, selectedRowIds } = props;
    const [typeselectedValue, setTypeSelectedValue] = useState(ReuseDialogType.WARRANTY);
    const { bomId } = useParams();
    const [uploadedFile, setUploadedFile] = useState<File[]>([]);
    const [fileRequiredError, setFileRequiredError] = useState<string | null>(null);
    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeSelectedValue(() => event.target.value as ReuseDialogType);
    };
    const [modeselectedValue, setModeSelectedValue] = useState('reuse');
    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModeSelectedValue(() => event.target.value);
        setUploadedFile([]);
        setFileRequiredError(null);
    };
    const { isLoading, mutateAsync: addDocument } = useAddSuggestionMutation();
    const onConfirm = async () => {
        if (modeselectedValue === 'reuse') {
            confirmAction({ reuse: true, document: typeselectedValue });
        } else {
            if (!uploadedFile?.length) {
                setFileRequiredError('File is required for above option');
                return;
            }
            const formData: FormData = new FormData();
            formData.append('input', uploadedFile[0]);
            formData.append('bod_id', `[${selectedRowIds}]`);
            try {
                await addDocument({
                    input: formData,
                    bomId: bomId!,
                    documentType: 'o_m',
                    subDocumentType: typeselectedValue,
                    actionType: 'file_upload',
                    productId: selectedRowIds
                });
                confirmAction({ reuse: false });
            } catch (error: any) {
                setFileRequiredError(error?.response?.data?.message || 'Something went wrong!');
            }
        }
    };

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Add or Reuse Document" onClose={handleDialog} children={subTitle} />}
            footer={<ModalFooter continueButtonLabel="Confirm" onAccept={onConfirm} onReject={handleDialog} isLoading={isLoading} />}
        >
            <Box width={'467px'}>
                <RadioGroup row={false} label={'Which document type are you adding to these products?'} name={'type'} options={typeOptions} onChange={handleTypeChange} value={typeselectedValue} />
                <RadioGroup row={false} label={'How are you adding this document?'} name={'mode'} options={howOptions} onChange={handleModeChange} value={modeselectedValue} />
                {modeselectedValue === 'upload' && (
                    <FileSelector
                        placeholder="Drag and drop a file here, or:"
                        onSelect={(files: FileSelectorFileType[] | File[]) => {
                            setFileRequiredError(null);
                            setUploadedFile(files as File[]);
                        }}
                        acceptedFormats={['.pdf']}
                        helperText={'Supported file types: PDF'}
                        error={fileRequiredError!}
                    ></FileSelector>
                )}
            </Box>
        </Modal>
    );
};

export default ReuseOMDialog;
