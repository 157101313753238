import { Link as MUILink } from '@parspec/pixel';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface RouteLinkProps extends Pick<RouterLinkProps, 'to' | 'replace' | 'children'> {
    target?: '_blank';
}

export default function DashboardLink({ children, target, ...restProps }: RouteLinkProps) {
    return (
        <MUILink {...restProps} color="primary" underline="hover" rel="noopener" component={RouterLink} target={target}>
            {children}
        </MUILink>
    );
}
