import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { ListItem } from './ListItem';
import { useLogoutMutation } from 'src/features/Authentication/queries';

const sideNavOptionsObj = {
    myProfile: false,
    companyLocation: false,
    userManagement: false,
    manufacturers: { manufacturers: false, companyManufacturers: false, parspecCatalog: false, manufacturerList: false },
    planAndPayment: false
};

const defaultSelectedObj = {
    ...sideNavOptionsObj,
    myProfile: true
};

export const SettingsSideNav = () => {
    const { mutateAsync: logoutMutation } = useLogoutMutation();

    const [selectedItem, setSelectedItem] = useState(defaultSelectedObj);

    async function logout() {
        const response = await logoutMutation();
        if (response.status === 204) {
            navigate('/login');
        }
    }

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const editLocationPattern = /^\/v2\/settings\/edit-location\/\w+$/;
        const isRouteEditLocation = editLocationPattern.test(location.pathname);
        const currSelectedItem = { ...sideNavOptionsObj, manufacturers: { ...sideNavOptionsObj.manufacturers } };

        if (location.pathname === '/v2/settings/my-profile' || location.pathname === '/v2/settings') {
            currSelectedItem.myProfile = true;
        } else if (['/v2/settings/company-locations', '/v2/settings/add-location'].includes(location.pathname) || isRouteEditLocation) {
            currSelectedItem.companyLocation = true;
        } else if (location.pathname === '/v2/settings/user-management') {
            currSelectedItem.userManagement = true;
        } else if (location.pathname === '/v2/settings/manufacturers') {
            const params = new URLSearchParams(location.search);
            const tabValue = params?.get('tab');
            if (tabValue === 'manufacturers') {
                currSelectedItem.manufacturers.manufacturers = true;
                currSelectedItem.manufacturers.companyManufacturers = true;
            } else if (tabValue === 'catalog') {
                currSelectedItem.manufacturers.manufacturers = true;
                currSelectedItem.manufacturers.parspecCatalog = true;
            } else if (tabValue === 'list') {
                currSelectedItem.manufacturers.manufacturers = true;
                currSelectedItem.manufacturers.manufacturerList = true;
            }
        } else if (location.pathname === '/v2/settings/plan-and-payment') {
            currSelectedItem.planAndPayment = true;
        }

        setSelectedItem(currSelectedItem);
    }, [location]);

    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            <ListItem
                listTitle={'My Profile'}
                isSelected={selectedItem.myProfile}
                onClick={() => {
                    navigate('/v2/settings/my-profile');
                    setSelectedItem({
                        ...sideNavOptionsObj,
                        myProfile: true
                    });
                }}
            />
            <ListItem
                listTitle={'Branch Locations'}
                isSelected={selectedItem.companyLocation}
                onClick={() => {
                    navigate('/v2/settings/company-locations');
                    setSelectedItem({
                        ...sideNavOptionsObj,
                        companyLocation: true
                    });
                }}
            />

            <ListItem
                listTitle={'User Management'}
                isSelected={selectedItem.userManagement}
                onClick={() => {
                    navigate('/v2/settings/user-management?tab=active');
                    setSelectedItem({
                        ...sideNavOptionsObj,
                        userManagement: true
                    });
                }}
            />
            <ListItem
                listTitle={'Manufacturers'}
                isSelected={selectedItem.manufacturers.manufacturers}
                onClick={() => {
                    navigate('/v2/settings/manufacturers?tab=manufacturers');
                    setSelectedItem({
                        ...sideNavOptionsObj,
                        manufacturers: { ...sideNavOptionsObj.manufacturers, manufacturers: true, companyManufacturers: true }
                    });
                }}
            />
            <>
                {selectedItem.manufacturers.manufacturers && (
                    <Box ml={5}>
                        <ListItem
                            listTitle={'Company Manufacturers'}
                            isSelected={selectedItem.manufacturers.companyManufacturers}
                            onClick={() => {
                                navigate('/v2/settings/manufacturers?tab=manufacturers');
                                setSelectedItem({
                                    ...sideNavOptionsObj,
                                    manufacturers: { ...sideNavOptionsObj.manufacturers, manufacturers: true, companyManufacturers: true }
                                });
                            }}
                        />
                        <ListItem
                            listTitle={'Manufacturer Lists'}
                            isSelected={selectedItem.manufacturers.manufacturerList}
                            onClick={() => {
                                navigate('/v2/settings/manufacturers?tab=list');
                                setSelectedItem({
                                    ...sideNavOptionsObj,
                                    manufacturers: { ...sideNavOptionsObj.manufacturers, manufacturers: true, manufacturerList: true }
                                });
                            }}
                        />
                        <ListItem
                            listTitle={'Parspec Catalog'}
                            isSelected={selectedItem.manufacturers.parspecCatalog}
                            onClick={() => {
                                navigate('/v2/settings/manufacturers?tab=catalog');
                                setSelectedItem({
                                    ...sideNavOptionsObj,
                                    manufacturers: { ...sideNavOptionsObj.manufacturers, manufacturers: true, parspecCatalog: true }
                                });
                            }}
                        />
                    </Box>
                )}
            </>
            <ListItem
                listTitle={'Plan and Payment'}
                isSelected={selectedItem.planAndPayment}
                onClick={() => {
                    navigate('/v2/settings/plan-and-payment');
                    setSelectedItem({
                        ...sideNavOptionsObj,
                        planAndPayment: true
                    });
                }}
            />
            <ListItem listTitle={'Log Out'} isLogout={true} onClick={async () => await logout()} />
        </Box>
    );
};
