import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { endPoints, getAlternatesDataSheet, addselectAlternates, getSearchResult, fetchProductSearchHistory, fetchProductFeedback } from './apis';
import { invalidateGetProductDetailsQuery } from '../../createProduct/queries';
import { queryClient } from 'src/app/queryClient';
import { AlternatesDetailsResponse, SearchRefreshResultResponse } from './apiTypes';

const getalternatesKey = (bomId: string, productId: string) => [endPoints.alternatesDetails(bomId, productId)];
const getSearchKey = (bomId: string, productId: string) => [endPoints.searchDetails(bomId, productId)];

export const cancelGetAlternateDatasheetsRequest = (bomId: string, productId: string) => {
    queryClient.cancelQueries({ queryKey: getalternatesKey(bomId, productId) });
};

export const useGetAlternatesDataSheet = (bomId: string, productId: string, options: Omit<UseQueryOptions<AxiosResponse<AlternatesDetailsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getalternatesKey(bomId, productId), () => getAlternatesDataSheet(bomId, productId), options);

export const useGetSearchResults = (bomId: string, productId: string, options: Omit<UseQueryOptions<AxiosResponse<SearchRefreshResultResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getSearchKey(bomId, productId), () => getSearchResult(bomId, productId), options);

export const useSelectAlaternateMutation = () =>
    useMutation(addselectAlternates, {
        onSuccess: (_res, req) => {
            const { bomId, productId } = req;
            invalidateGetProductDetailsQuery(bomId.toString(), productId.toString());
        }
    });

export const useProductSearchHistoryMutation = () => useMutation(fetchProductSearchHistory);
export const useProductFeedbackMutation = () => useMutation(fetchProductFeedback);
