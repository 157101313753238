export const activityLogs = [
    {
        date: 'Today',
        activities: [
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            },
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            }
        ]
    },
    {
        date: 'Yesterday',
        activities: [
            {
                activity: 'downloaded',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            }
        ]
    },
    {
        date: 'FEBRUARY 1,2023',
        activities: [
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            },
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            }
        ]
    },
    {
        date: 'JANUARY 31, 2023',
        activities: [
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            },
            {
                activity: 'viewed',
                user: 'zarqab@parspec.io',
                time: '8:01 AM, 12/26/2023',
                version: '1'
            }
        ]
    }
];
