import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box, H5 } from '@parspec/pixel';

import ReportingDashboardTabs from './ReportingDashboardTabs';
import { useCheckAccessForReporting } from '../shared/hooks/useCheckAccessForReporting';
import Loader from '../BOM/FinalDocuments/Compile/submittals&o_m/Loader';

export const ReportingDashboard = () => {
    const { reportingAccess, isLoading: isReportingAccessLoading } = useCheckAccessForReporting();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isReportingAccessLoading && !reportingAccess) {
            navigate('/v2/dashboard', { replace: true });
        }
    }, [isReportingAccessLoading]);

    return (
        <>
            {isReportingAccessLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <Box display="flex" flexDirection="column" height="100vh" py={4} overflow="hidden">
                    <Box bgcolor="primary.contrastText">
                        <Box px={4}>
                            <H5 width={'max-content'}>Reporting</H5>
                        </Box>
                        <ReportingDashboardTabs />
                    </Box>
                    <Box bgcolor="neutral.light" height="100%" overflow="auto">
                        <Outlet />
                    </Box>
                </Box>
            )}
        </>
    );
};
