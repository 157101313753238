import { useState, useMemo, useRef, useEffect, useCallback } from 'react';

import { AxiosResponse } from 'axios';

import { Modal, Box, ModalFooter, ModalHeader, BodySmall, StatusSelect, Table, Select, TableRefType, Info, SelectChangeEvent } from '@parspec/pixel';
import { ColumnDirective } from '@syncfusion/ej2-react-treegrid';

import { INITIAL_PROJECT_STAGE, getProjectStages, QUOTE, DocumentStatusEnum, PROJECT_STAGE_TRIGGER_VALUES } from '../constants';
import { useSelectedQuoteView } from 'src/app/Context';
import { useGetQuotesQuery, useDocumentTags } from '../../Dashboard/queries';
import { useUpdateProjectTagMutation, useGetProjectBomsQuery } from '../../Project/queries';
import { ProjectQuotesInfo } from '../../Dashboard/queries/apiTypes';
import { convertDateFormat } from '../utils/utils';
import { AllTagsOptions, IUpdateDocumentTag } from '../../Project/queries/apiTypes';
import { useFeatureFlag } from '../UserActivityTracking/useFeatureFlag';
import { useAllTagsHook } from '../hooks/useAllTagsHook';
import { useUnlinkBOMMutation } from '../../Project/queries';
import { QUOTE_DOCUMENT_AUTOMATION_STATUSES } from '../utils/constants';

interface IUpdateProjectQuoteStatusModal {
    open: boolean;
    onClose: () => void;
    quotesStatusOptions: AllTagsOptions[];
    onBlurAction?: () => void;
    onUpdateProjectStage: (value: string) => void;
    isUpdatingProjectDetails: boolean;
    selectedProjectStage?: string;
    projectId?: string | number;
}

const ROW_HEIGHT = 40;
const TABLE_HEIGHT = 260;

function UpdateProjectQuoteStatusModal({
    open,
    onClose,
    quotesStatusOptions,
    onUpdateProjectStage,
    isUpdatingProjectDetails,
    selectedProjectStage = INITIAL_PROJECT_STAGE,
    projectId = ''
}: IUpdateProjectQuoteStatusModal) {
    const { enable_quoting } = useFeatureFlag();
    const { quoteSelectedView, isDashboardViewsLoading } = useSelectedQuoteView();

    const tableRef = useRef<TableRefType | null>(null);

    const [projectStage, setProjectStage] = useState(selectedProjectStage);
    const [quotesData, setQuotesData] = useState<ProjectQuotesInfo[]>([]);
    const [error, setError] = useState({ error: '' });

    const { data: projectQuotesData, isLoading: isProjectQuotesDataLoading } = useGetQuotesQuery(quoteSelectedView, { enabled: !isDashboardViewsLoading });
    const { data: quotesTagData, isLoading: isQuotesTagLoading } = useDocumentTags(QUOTE);
    const { data: projectBomsData, isLoading: isBomDataLoading } = useGetProjectBomsQuery(Number(projectId), {
        enabled: Boolean(Number(projectId))
    });

    const allTagsObject = useAllTagsHook();

    const { mutateAsync: updateProjectQuotesTag, isLoading: isUpdatingDocumentTags } = useUpdateProjectTagMutation();
    const { mutateAsync: unLinkBomDetails } = useUnlinkBOMMutation();

    const isTableLoading = isProjectQuotesDataLoading || isQuotesTagLoading || isBomDataLoading;

    useEffect(() => {
        tableRef?.current?.updateData(quotesData);
        tableRef?.current?.refreshTable();
    }, [quotesData]);

    const quotesDataArr = useMemo(() => {
        if (!isProjectQuotesDataLoading && projectQuotesData?.data && projectBomsData?.data && enable_quoting) {
            const projectBomsArr = projectQuotesData.data.filter((project) => project.project.id === Number(projectId));
            // Inserting all the boms with no quotes
            projectBomsData.data.forEach((bomData) => {
                if (!bomData.quote) {
                    projectBomsArr.push({
                        bom: {
                            created_at: bomData.created_at,
                            created_by: String(bomData.created_by),
                            id: bomData.id,
                            last_edited_at: bomData.last_edited_at,
                            last_edited_by: bomData.last_edited_by,
                            name: bomData.name,
                            primary_contact: ''
                        }
                    } as any);
                }
            });
            // Preparing quotes data for table
            return projectBomsArr.map((item) => {
                const currentData = { ...item };
                const bomId = currentData.bom.id;
                let selectedQuoteId: number | null = null;
                let selectedQuoteStatus: number | null = null;

                if (currentData?.quote && quotesTagData?.data) {
                    for (const quote of currentData.quote) {
                        quote.quote_number += ` - ${convertDateFormat(quote.created_at!)}`;
                        for (const quoteTag of quotesTagData.data) {
                            if (bomId === quoteTag.bom_id && quote.id === quoteTag.entity_id && quoteTag.entity_type === QUOTE) {
                                quote.tag_name = quoteTag.tag_name;
                                quote.tag_id = quoteTag.tag_id;
                                selectedQuoteId = quote.id;
                                selectedQuoteStatus = quoteTag.tag_id;
                            }
                        }
                    }
                    if (!selectedQuoteId) {
                        selectedQuoteId = currentData.quote[0].id;
                    }
                }
                return {
                    bom_id: currentData.bom.id,
                    bom_name: currentData.bom.name,
                    quotes: currentData.quote,
                    selectedQuoteStatus,
                    selectedQuoteId,
                    isLinked: currentData.bom.is_linked
                };
            });
        }

        return [];
    }, [projectQuotesData?.data, quotesTagData?.data, projectBomsData?.data, enable_quoting]);

    useEffect(() => {
        if (quotesDataArr.length) {
            setQuotesData(quotesDataArr);
        }
    }, [quotesDataArr, quotesData]);

    function handleProjectStage(e: SelectChangeEvent<unknown>) {
        const { value } = e.target;
        setProjectStage(value as string);
        setError({ error: '' });
    }

    async function handleOnSave() {
        if (quotesData.length) {
            if (allTagsObject.size) {
                switch (projectStage) {
                    case PROJECT_STAGE_TRIGGER_VALUES[0]:
                        if (!quotesData.some((tag) => allTagsObject.get(tag.selectedQuoteStatus!) === DocumentStatusEnum.WON)) {
                            setError({
                                error: `A closed won project needs to contain at least one won quote.`
                            });
                            return;
                        }

                        break;

                    case PROJECT_STAGE_TRIGGER_VALUES[1]:
                        if (quotesData.some((tag) => allTagsObject.get(tag.selectedQuoteStatus!) === DocumentStatusEnum.WON)) {
                            setError({
                                error: `A closed lost project cannot contain any won quotes. `
                            });
                            return;
                        }
                        break;

                    case PROJECT_STAGE_TRIGGER_VALUES[2]:
                        if (quotesData.some((tag) => allTagsObject.get(tag.selectedQuoteStatus!) === DocumentStatusEnum.WON)) {
                            setError({
                                error: `An abandoned project cannot contain any won quotes.`
                            });
                            return;
                        }
                        break;
                }
            }

            const quotesPayload: Array<IUpdateDocumentTag> = quotesData.map((item) => {
                return {
                    bom_id: String(item.bom_id),
                    entity_type: QUOTE,
                    entity_id: item.selectedQuoteId!,
                    tag_id: item?.selectedQuoteStatus || null
                };
            });

            try {
                await updateProjectQuotesTag({
                    projectId: String(projectId),
                    payload: quotesPayload
                });

                const unlinkBomsArrMutationsFunc = quotesData.reduce((acc: Array<Promise<AxiosResponse>>, curr: ProjectQuotesInfo) => {
                    if (
                        curr.isLinked &&
                        curr?.selectedQuoteStatus &&
                        allTagsObject.size &&
                        QUOTE_DOCUMENT_AUTOMATION_STATUSES.some((status) => allTagsObject.get(curr.selectedQuoteStatus!) === status)
                    ) {
                        acc.push(
                            unLinkBomDetails({
                                bomId: String(curr.bom_id),
                                projectId: Number(projectId)
                            })
                        );
                    }
                    return acc;
                }, []);

                await Promise.all(unlinkBomsArrMutationsFunc);
            } catch (e) {
                /* empty */
            }
        }

        onUpdateProjectStage(projectStage);
        onClose();
    }

    const isLoading = isUpdatingProjectDetails || isUpdatingDocumentTags;

    const getQuotesTemplate = useCallback((props: any) => {
        const quotes: { [index: string]: string | number | undefined }[] = props.quotes;
        const fieldValue = props.selectedQuoteId;

        function handleOnSelect(e: SelectChangeEvent<unknown>) {
            const quotesToUpdateData = tableRef?.current?.getData();
            if (quotesToUpdateData) {
                (quotesToUpdateData as ProjectQuotesInfo[]).forEach((quotesRowData) => {
                    if (quotesRowData.bom_id === props.bom_id) {
                        quotesRowData.selectedQuoteId = Number(e.target.value);
                    }
                });

                setQuotesData([...(quotesToUpdateData as ProjectQuotesInfo[])]);
            }
        }

        return (
            <Box p={1}>
                {fieldValue ? (
                    <Select value={fieldValue} options={quotes} optionLabelKeyname="quote_number" optionValueKeyname="id" onChange={handleOnSelect} />
                ) : (
                    <BodySmall>There are no quotes in this BOM</BodySmall>
                )}
            </Box>
        );
    }, []);

    const getQuoteStatusTemplate = useCallback((props: any) => {
        const fieldValue = props.selectedQuoteStatus;

        function handleOnSelect(e: SelectChangeEvent<unknown>) {
            const quotesToUpdateData = tableRef?.current?.getData();
            if (quotesToUpdateData) {
                if (quotesToUpdateData) {
                    (quotesToUpdateData as ProjectQuotesInfo[]).forEach((quotesRowData) => {
                        if (quotesRowData.bom_id === props.bom_id) {
                            quotesRowData.selectedQuoteStatus = Number(e.target.value);
                        }
                    });

                    setError({ error: '' });
                    setQuotesData([...(quotesToUpdateData as ProjectQuotesInfo[])]);
                }
            }
        }

        return (
            <Box p={1}>
                {props.selectedQuoteId && <StatusSelect value={fieldValue || 0} options={quotesStatusOptions as any} optionLabelKeyname="label" optionValueKeyname="value" onChange={handleOnSelect} />}
            </Box>
        );
    }, []);

    const tableContent = useMemo(() => {
        return (
            <Table
                data={quotesData}
                ref={tableRef}
                allowPaging={false}
                allowFiltering={false}
                showToolbar
                allowRowDragAndDrop={false}
                rowHeight={ROW_HEIGHT}
                height={TABLE_HEIGHT}
                loading={isTableLoading}
                editSettings={{ allowAdding: false, allowDeleting: false }}
            >
                <ColumnDirective field="bom_id" isPrimaryKey={true} visible={false} />
                <ColumnDirective width="115" minWidth="115" field="bom_name" headerText="BOM Name" allowEditing={false} allowFiltering={false} allowSorting={false} />
                <ColumnDirective
                    width="120"
                    headerText="Quote Document (Version)"
                    minWidth="115"
                    field="selectedQuoteId"
                    template={getQuotesTemplate}
                    allowEditing={false}
                    allowFiltering={false}
                    allowSorting={false}
                />
                <ColumnDirective
                    width="90"
                    field="selectedQuoteStatus"
                    minWidth="90"
                    template={getQuoteStatusTemplate}
                    headerText="Quote Status"
                    allowEditing={false}
                    allowFiltering={false}
                    allowSorting={false}
                />
            </Table>
        );
    }, [quotesData, isTableLoading]);

    function handleOnClose(_: object, reason: 'backdropClick' | 'escapeKeyDown') {
        if (reason && reason === 'backdropClick') {
            return;
        }
        onClose();
    }

    return (
        <>
            <Modal
                onClose={handleOnClose}
                open={open}
                header={<ModalHeader title="Update Project Stage & Quotes Statuses" onClose={onClose} />}
                footer={
                    <ModalFooter
                        helperText={
                            <Box padding={'8px 24px 8px 8px'}>
                                <BodySmall color={'error'} fontWeight={500} limit={true} lines={3}>
                                    {error.error ? (
                                        <>
                                            Every BOM needs to have a won, lost, or abandoned quote in order for this project to be closed.
                                            <br />
                                            {error.error}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </BodySmall>
                            </Box>
                        }
                        onAccept={handleOnSave}
                        onReject={onClose}
                        cancelButtonLabel="Don't Update"
                        continueButtonLabel="Update"
                        isLoading={isLoading}
                    />
                }
            >
                <Box pt={6} pb={3} maxHeight="440px" width="50vw">
                    <Box>
                        <BodySmall>Take a moment to adjust the project stage and quotes statuses following your recent activity.</BodySmall>
                    </Box>

                    <Box pt={4} pb={1} width={'180px'}>
                        <BodySmall fontWeight={500}>Project Stage</BodySmall>
                        <Box width={1} pt={3}>
                            <StatusSelect
                                value={projectStage}
                                id={'Project Stage'}
                                labelId={'Project Stage'}
                                name={'stage'}
                                options={[...(getProjectStages(true, enable_quoting) as any)]}
                                optionLabelKeyname="label"
                                optionValueKeyname="value"
                                onChange={handleProjectStage}
                            />
                        </Box>
                    </Box>
                    <Box pt={3} display="flex" columnGap={4}>
                        <Box>
                            <BodySmall sx={{ mt: 2 }} fontWeight={500}>
                                Quotes Statuses
                            </BodySmall>
                        </Box>
                        <Info>
                            <BodySmall color="inherit">
                                Linked BOMs will be automatically unlinked when the Quote Status is changed to <strong>Won</strong>, <strong>Lost</strong>, or <strong>Abandoned</strong>.
                            </BodySmall>
                        </Info>
                    </Box>
                    <Box width={'100%'} pt={1} sx={{ '& .e-gridcontent': { height: 'calc(100% - 42px) !important' } }}>
                        {tableContent}
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default UpdateProjectQuoteStatusModal;
