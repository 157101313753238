import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getAllDocuments, getDocumentByVersionId, deleteDocument, updateDocument, downloadAll, downloadByVersionId, documentOutOfSync, getStakeholdersInfo, updateStakeholderDetail } from './apis';
import { endPoints } from './apis';
import { DownloadByVersionIdTypes, DocumentResponse, DocumentOutOfSyncResponse, StakeholderInfoResponse } from './apiTypes';
import { queryClient } from 'src/app/queryClient';

// keys
export const getAllDocumentKey = (bomId: string, documentType: string) => [endPoints.documents(bomId, documentType)];
export const getDocumentByVersionKey = (bomId: string, submittalId: number, documentType: string) => [endPoints.documentByVersionId(bomId, submittalId, documentType)];
export const downloadAllKeys = (bomId: string, documentType: string) => [endPoints.downloadAll(bomId, documentType)];
export const dowloadKeys = (params: DownloadByVersionIdTypes) => [endPoints.download(params)];
export const documentOutOfSyncKeys = (bomId: string, documentType: string) => [endPoints.documentOutOfSync(bomId, documentType)];
export const getStakeHoldersKey = (bomId: number, documentType: string) => [endPoints.stakeholdersInfo(bomId, documentType)];

export const invalidateDocuments = (bomId: string, documentType: string) => {
    queryClient.invalidateQueries(getAllDocumentKey(bomId, documentType));
};

export const refetchDocuments = (bomId: string, documentType: string) => {
    queryClient.refetchQueries(getAllDocumentKey(bomId, documentType));
};

export const invalidateStakeHolders = (bomId: number, documentType: string) => {
    queryClient.invalidateQueries(getStakeHoldersKey(bomId, documentType));
};

// queries
export const useAllDocumentsQuery = (bomId: string, documentType: string, options?: object) => useQuery(getAllDocumentKey(bomId, documentType), () => getAllDocuments(bomId, documentType), options);

export const useViewDocumentByVersionQuery = (bomId: string, id: number, documentType: string) =>
    useQuery(getDocumentByVersionKey(bomId, id, documentType), () => getDocumentByVersionId(bomId, id, documentType), {
        enabled: false
    });

export const useDocumentOutOfSyncQuery = (bomId: string, documentType: string, options?: Omit<UseQueryOptions<AxiosResponse<DocumentOutOfSyncResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(documentOutOfSyncKeys(bomId, documentType), () => documentOutOfSync(bomId, documentType), { staleTime: 0, ...(options || {}) } as Omit<
        UseQueryOptions<AxiosResponse<DocumentOutOfSyncResponse>>,
        'queryKey' | 'queryFn'
    >);

export function refetchOutOfSyncQuery(bomId: string, documentType: string) {
    queryClient.refetchQueries(documentOutOfSyncKeys(bomId, documentType), { exact: true });
}

export const useGetStakeholdersQuery = (bomId: number, documentType: string, options?: Omit<UseQueryOptions<AxiosResponse<StakeholderInfoResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getStakeHoldersKey(bomId, documentType), () => getStakeholdersInfo(bomId, documentType), { staleTime: 0, ...(options || {}) } as Omit<
        UseQueryOptions<AxiosResponse<StakeholderInfoResponse[]>>,
        'queryKey' | 'queryFn'
    >);

// Mutations
export const useDownloadAllMutation = () => useMutation(downloadAll);

export const useDownloadMutation = () =>
    useMutation({
        mutationFn: downloadByVersionId,
        retry: 3,
        retryDelay: 1000
    });

export const useDeleteDocumentMutation = () => {
    return useMutation(deleteDocument, {
        onSuccess: (_data, variables) => {
            queryClient.setQueryData(getAllDocumentKey(variables.bomId, variables.documentType), (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.filter((item: DocumentResponse) => item.id !== variables.id)
                    };
                }
                return oldData;
            });

            queryClient.invalidateQueries(getAllDocumentKey(variables.bomId, variables.documentType));
        }
    });
};
export const useUpdateDocumentMutation = () => {
    return useMutation(updateDocument, {
        onSuccess: (newData, variables) => {
            queryClient.setQueryData(getAllDocumentKey(variables.bomId, variables.documentType), (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.map((data: DocumentResponse) => (data.id === newData.data.id ? newData.data : data))
                    };
                }
                return oldData;
            });
        }
    });
};

export const useUpdateStakeholderInfoMutation = () => useMutation(updateStakeholderDetail);
