import { useQuery, useMutation } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import {
    compileDocumentPagePreview,
    getCoverPageToggleConfiguration,
    getAllCoverPageTextField,
    updateDocumentTextField,
    updateDocumentToggleField,
    updateCoverPageDocumentLogo,
    uploadCustomPage,
    deleteCustomPage,
    getCustomPage,
    getTocToggleConfiguration,
    updateTocToggle,
    documentPreset,
    updateContentLayout,
    contentLayout,
    getCoverPageDocumentLogo,
    deleteCoverPageDocumentLogo,
    getDocumentPreset,
    getTOCDocumentLogo,
    updateTOCDocumentLogo,
    deleteTOCDocumentLogo,
    getHeaderAndFooterDocumentLogo,
    updateHeaderAndFooterDocumentLogo,
    deleteHeaderAndFooterDocumentLogo,
    endPoints,
    updateSectionBreaks
} from './apis';

import { UpdateToggleProps, UpdateTextFieldProps, UpdateDateFieldProps } from './apiTypes';
import { queryClient } from 'src/app/queryClient';

import { PRESET_RESPONSE } from '../../constants';
import { transformToggleConfigurationData, transformCompileDocumentResponse, transformDocumentPreset } from '../shared/utils';
import { ENTITY } from '../../../constants';

/** Query Keys */
const documentPresetKey = (bomId: string, documentType: string, entity: string) => [endPoints.documentPreset(bomId, documentType, entity)];
const getContentLayoutKey = (bomId: string, documentType: string) => [endPoints.getContentLayout(bomId, documentType)];
const compileDocumentPagePreviewKey = (bomId: string, fieldName: string, documentType: string) => [endPoints.compileDocumentPagePreview(bomId, fieldName, documentType)];
const getCustomPageKey = (bomId: string, documentType: string, entity: string) => [endPoints.customPage(bomId, documentType, entity)];

const getCoverPageToggleConfigurationKey = (bomId: string, documentType: string) => [endPoints.coverPageToggleConfiguration(bomId, documentType)];
const getTocToggleConfigurationKey = (bomId: string, documentType: string) => [endPoints.getTocToggleConfiguration(bomId, documentType)];
const getAllCoverPageTextfieldKey = (bomId: string, documentType: string) => [endPoints.getCoverPageTextField(bomId, documentType)];
const getCoverPageDocumentLogoKey = (bomId: string, fieldName: string, documentType: string) => [endPoints.coverPagedocumentLogo(bomId, fieldName, documentType)];
const getTOCDocumentLogoKey = (bomId: string, documentType: string) => [endPoints.tocDocumentLogo(bomId, documentType)];
const getHeaderAndFooterLogoKey = (bomId: string, documentType: string) => [endPoints.headerAndFooterDocumentLogo(bomId, documentType)];
/** Header And footer queries and mutations */

export const invalidateCoverPagePresetData = (bomId: string, documentType: string) => queryClient.invalidateQueries(getAllCoverPageTextfieldKey(bomId, documentType));

export const useHeaderAndFooterDocumentLogoQuery = (bomId: string, documentType: string, options?: object) =>
    useQuery(getHeaderAndFooterLogoKey(bomId, documentType), () => getHeaderAndFooterDocumentLogo(bomId, documentType), options);

export const useHeaderAndFooterDeleteLogoMutation = () => {
    return useMutation(deleteHeaderAndFooterDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getHeaderAndFooterLogoKey(variables.bomId, variables.documentType));
        }
    });
};

export const useHeaderAndFooterUpdateLogoMutation = () => {
    return useMutation(updateHeaderAndFooterDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getHeaderAndFooterLogoKey(variables.bomId, variables.documentType));
        }
    });
};

/** Cover Page queries and mutations */

export const useCoverPageTextfieldData = (bomId: string, documentType: string, presetResponse: string) =>
    useQuery({
        queryKey: getAllCoverPageTextfieldKey(bomId, documentType),
        queryFn: () => getAllCoverPageTextField(bomId, documentType),
        select: transformCompileDocumentResponse,
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

export const useCoverPageToggleConfigurationQuery = (bomId: string, documentType: string, presetResponse: string) =>
    useQuery({
        queryKey: getCoverPageToggleConfigurationKey(bomId, documentType),
        queryFn: () => getCoverPageToggleConfiguration(bomId, documentType),
        select: transformToggleConfigurationData,
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

export const useCoverPageDocumentLogoDeleteMutation = () => {
    return useMutation(deleteCoverPageDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getCoverPageDocumentLogoKey(variables.bomId, variables.fieldName!, variables.documentType));
        }
    });
};

export const useCoverPageUpdateLogoMutation = () => {
    return useMutation(updateCoverPageDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getCoverPageDocumentLogoKey(variables.bomId, variables.fieldName!, variables.documentType));
        }
    });
};

export const useCoverPageDocumentLogoQuery = (bomId: string, fieldName: string, documentType: string, options: object) =>
    useQuery(getCoverPageDocumentLogoKey(bomId, fieldName, documentType), () => getCoverPageDocumentLogo(bomId, fieldName, documentType), options);
/** queries */

export const useDocumentPresetQuery = (bomId: string, documentType: string, entity: string, presetResponse: string) =>
    useQuery({
        queryKey: documentPresetKey(bomId, documentType, entity),
        queryFn: () => getDocumentPreset(bomId, documentType, entity),
        select: transformDocumentPreset,
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

export const useTOCDocumentLogoQuery = (bomId: string, documentType: string, options?: object) =>
    useQuery(getTOCDocumentLogoKey(bomId, documentType), () => getTOCDocumentLogo(bomId, documentType), options);

export const useCustomPageQuery = (bomId: string, documentType: string, entity: string, presetResponse: string) =>
    useQuery({
        queryKey: getCustomPageKey(bomId, documentType, entity),
        queryFn: () => getCustomPage(bomId, documentType, entity),
        select: transformCompileDocumentResponse,
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

export const useCompileDocumentPreviewQuery = (bomId: string, fieldName: string, documentType: string, options?: object) =>
    useQuery(compileDocumentPagePreviewKey(bomId, fieldName, documentType), () => compileDocumentPagePreview(bomId, fieldName, documentType), {
        ...options,
        staleTime: 0,
        onError: (err) => {
            throw err;
        }
    });

export const useTocToggleConfigurationQuery = (bomId: string, documentType: string, presetResponse: string) =>
    useQuery({
        queryKey: getTocToggleConfigurationKey(bomId, documentType),
        queryFn: () => getTocToggleConfiguration(bomId, documentType),
        select: transformToggleConfigurationData,
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

export const useContentLayoutQuery = (bomId: string, documentType: string) =>
    useQuery(getContentLayoutKey(bomId, documentType), () => contentLayout(bomId, documentType), {
        select: transformCompileDocumentResponse
    });

/** Mutations */

export const useTOCDeleteLogoMutation = () => {
    return useMutation(deleteTOCDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getTOCDocumentLogoKey(variables.bomId, variables.documentType));
        }
    });
};

export const useTOCUpdateLogoMutation = () => {
    return useMutation(updateTOCDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(getTOCDocumentLogoKey(variables.bomId, variables.documentType));
        }
    });
};

function updateTextFieldCache(oldData: AxiosResponse, variables: UpdateDateFieldProps | UpdateTextFieldProps) {
    return {
        data: oldData.data.map((el: any) => {
            if (el.field_name === variables.fieldName) {
                el.field_value = variables.field_value;
            }
            return el;
        })
    };
}

export const useDocumentTextFieldMutation = () => {
    return useMutation(updateDocumentTextField, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(compileDocumentPagePreviewKey(variables.bomId, variables.fieldName!, variables.documentType));
            if (variables.entity === ENTITY.HEADER_FOOTER_ENTITY) {
                queryClient.setQueryData(documentPresetKey(variables.bomId, variables.documentType, variables.entity), (oldData: any) => updateTextFieldCache(oldData, variables));
            } else if (variables.entity === ENTITY.COVER_PAGE_ENTITY) {
                queryClient.setQueryData(getAllCoverPageTextfieldKey(variables.bomId, variables.documentType), (oldData: any) => updateTextFieldCache(oldData, variables));
            }
        }
    });
};

function updateToggleCache(oldData: any, variables: UpdateToggleProps) {
    return {
        data: oldData.data.map((el: any) => {
            if (el.field_name === variables.fieldName) {
                el.is_active = variables.is_active;
            }
            return el;
        })
    };
}

export const useDocumentToggleConfigurationMutation = () => {
    return useMutation(updateDocumentToggleField, {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(compileDocumentPagePreviewKey(variables.bomId, variables.fieldName!, variables.documentType));
            if (variables.entity === ENTITY.HEADER_FOOTER_ENTITY || variables.entity === ENTITY.NOTES_PAGE_ENTITY) {
                queryClient.setQueryData(documentPresetKey(variables.bomId, variables.documentType, variables.entity), (oldData: any) => updateToggleCache(oldData, variables));
            } else if (variables.entity === ENTITY.TOC_ENTITY) {
                queryClient.setQueryData(getTocToggleConfigurationKey(variables.bomId, variables.documentType), (oldData: any) => updateToggleCache(oldData, variables));
            } else if (variables.entity === ENTITY.COVER_PAGE_ENTITY) {
                queryClient.setQueryData(getCoverPageToggleConfigurationKey(variables.bomId, variables.documentType), (oldData: any) => updateToggleCache(oldData, variables));
            }
        }
    });
};

// This will eventualy be the legacy hook
export const useTocUpdateToggleConfigurationMutation = () => {
    return useMutation(updateTocToggle, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(compileDocumentPagePreviewKey(variables.bomId, variables.fieldName!, variables.documentType));
        }
    });
};

export const useUploadCustomPageMutation = () => {
    return useMutation(uploadCustomPage, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(getCustomPageKey(variables.bomId, variables.documentType, variables.entity));
        }
    });
};

export const useDeleteCustomPageMutation = () => {
    return useMutation(deleteCustomPage, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(getCustomPageKey(variables.bomId, variables.documentType, variables.entity));
        }
    });
};

export const useDocumentPresetMutation = () => useMutation(documentPreset);

export const useContentLayoutUpdateMutation = () => {
    return useMutation(updateContentLayout, {
        onSuccess: (_, variables) =>
            queryClient.setQueryData(getContentLayoutKey(variables.bomId, variables.documentType), (oldData?: AxiosResponse) => {
                if (oldData) {
                    const res = updateTextFieldCache(oldData, variables);
                    return { ...oldData, data: res.data };
                }
                return oldData;
            })
    });
};

export const useSectionBreakMutation = () =>
    useMutation(updateSectionBreaks, {
        onSuccess: (_, variables) =>
            queryClient.setQueryData(getContentLayoutKey(variables.bomId, variables.documentType), (oldData?: AxiosResponse) => {
                if (oldData) {
                    const res = updateTextFieldCache(oldData, variables);
                    return { ...oldData, data: res.data };
                }
                return oldData;
            })
    });
