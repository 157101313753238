import { useMutation, useQuery } from '@tanstack/react-query';
import { endPoints, getFreightDetails, putFreightDetails } from './apis';
import { queryClient } from 'src/app/queryClient';

const getFreightDetailsKey = (bomId: string) => [endPoints.freightDetailsUrl(bomId)];

export function invalidaFreightQuery(bomId: string) {
    queryClient.invalidateQueries(getFreightDetailsKey(bomId));
}

export const useGetFrieghtDetailsQuery = (bomId: string) => useQuery(getFreightDetailsKey(bomId), () => getFreightDetails(bomId));

export const usePutFreightMutation = () => {
    return useMutation(putFreightDetails, {
        onSuccess: (data, variables) => {
            const { bomId } = variables;
            queryClient.setQueryData(getFreightDetailsKey(bomId), data);
        }
    });
};
