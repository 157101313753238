import { axios } from 'src/app/Axios';
import {
    CompanyInfoResponse,
    ProjectBomResponse,
    ProjectDetailsReponse,
    UserInfoResponse,
    ProjectSubmitalOutOfSyncResponse,
    BOMLastEditedResponse,
    ProjectStakeholdersResponse,
    UpdateStakeHolderRequest,
    UploadedProjectFilesResponse,
    BulkUploadFilesRequest,
    EditDocumentResponse,
    EditDocumentRequest
} from './apiTypes';
import { EditProjectDetailsResponse } from '../../shared/CreateAndEditProjectModal/queries/apiTypes';
import { docType } from '../../shared/constants';
import { AllTagsResponse, IUpdateDocumentTag } from './apiTypes';

export const endPoints = {
    projectDetails: (id: number) => `/project/${id}`,
    companyDetails: (id: number) => `/company/${id}`,
    projectBoms: (id: number) => `/dashboard/project/${id}/bom`,
    userDetails: (id: number) => `/user_info/${id}`,
    projectSubmittalOutOfSync: (project_id: number, docType: string) => `/dashboard/project/${project_id}/bom/${docType}/out_of_sync`,
    editProjectDetails: (project_id: string) => `/project/${project_id}/`,
    bomLastEditedDetails: (project_id: string) => `/dashboard/project/${project_id}/bom/last_edited`,
    allTags: () => `/tag`,
    documentTag: (bomId: string, entityType: string, entityId: number) => `/tag/bom/${bomId}/entity_type/${entityType}/entity_id/${entityId}/`,
    projectDocumentTag: (projectId: string) => `tag/project/${projectId}/`,
    getStakeholders: (projectId: number) => `project/${projectId}/stakeholder/`,
    updateStakeholders: (projectId: number, stakeHolderId: number) => `project/${projectId}/stakeholder/${stakeHolderId}/`,
    getAndAddProjectFilesURL: (projectId: number) => `/project/${projectId}/documents`,
    EditAndDeleteProjectFilesUrl: (projectId: number, documentId: number) => `/project/${projectId}/documents/${documentId}`,
    downloadUploadedFile: (projectId: number, documentId: number) => `/project/${projectId}/documents/${documentId}/download`,
    fileSize: () => `/api/available_storage/`
};

export const getProjectDetails = async (id: number) => {
    return axios.get<ProjectDetailsReponse>(endPoints.projectDetails(id));
};
export const getCompanyDetails = async (id: number) => {
    return axios.get<CompanyInfoResponse>(endPoints.companyDetails(id));
};
export const getProjectBoms = async (id: number) => {
    return axios.get<ProjectBomResponse>(endPoints.projectBoms(id));
};
export const getUserById = async (id: number) => {
    return axios.get<UserInfoResponse>(endPoints.userDetails(id));
};

export const getProjectOutOfSync = async (id: number, docType: docType) => {
    return axios.get<ProjectSubmitalOutOfSyncResponse>(endPoints.projectSubmittalOutOfSync(id, docType));
};

export const getBomLastEditedDocumentDetails = (project_id: string) => {
    return axios.get<BOMLastEditedResponse[]>(endPoints.bomLastEditedDetails(project_id));
};

export const getStakeholders = (projectId: number) => {
    return axios.get<ProjectStakeholdersResponse[]>(endPoints.getStakeholders(projectId));
};

export const editProjectDetails = ({ project_id, data }: { project_id: string; data: FormData }) => {
    return axios.patch<EditProjectDetailsResponse>(endPoints.editProjectDetails(project_id), data);
};

export const getAllTags = () => axios.get<AllTagsResponse[]>(endPoints.allTags());

export const updateDocumentTag = ({ bom_id, entity_type, entity_id, tag_id }: IUpdateDocumentTag) =>
    axios.post(endPoints.documentTag(bom_id, entity_type, entity_id), {
        tag_id
    });

export const updateProjectDocumentTags = ({ projectId, payload }: { projectId: string; payload: IUpdateDocumentTag[] }) => axios.post(endPoints.projectDocumentTag(projectId), payload);

export const updateStakeholderInfo = (request: UpdateStakeHolderRequest) => {
    const { id, projectId, ...rest } = request;
    return axios.patch(endPoints.updateStakeholders(projectId, id), { ...rest });
};

export const getAllUploadedFilesForProject = (projectId: number) => axios.get<UploadedProjectFilesResponse[]>(endPoints.getAndAddProjectFilesURL(projectId));

export const addFilesForProject = ({ projectId, payload }: { projectId: number; payload: BulkUploadFilesRequest[] }) => axios.post(endPoints.getAndAddProjectFilesURL(projectId), payload);

export const editUploadedFilesForProject = ({ projectId, documentId, payload }: { projectId: number; documentId: number; payload: EditDocumentRequest }) =>
    axios.patch<EditDocumentResponse>(endPoints.EditAndDeleteProjectFilesUrl(projectId, documentId), payload);

export const deleteUploadedFilesForProject = ({ projectId, documentId }: { projectId: number; documentId: number }) => axios.delete(endPoints.EditAndDeleteProjectFilesUrl(projectId, documentId));

export const downloadUploadedFiles = ({ projectId, documentId }: { projectId: number; documentId: number }) => axios.get(endPoints.downloadUploadedFile(projectId, documentId));

export const getRemainingSize = () => {
    axios.get(endPoints.fileSize());
};
