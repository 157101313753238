import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { queryClient } from 'src/app/queryClient';

import {
    getAllUsersData,
    getAllCompanyBranchLocations,
    getCheckForProductFinderAccess,
    postSendLoginInvitation,
    deleteUser,
    edtUserDetails,
    endPoints,
    editProductFinderAccess,
    editQuotingAccess,
    getActiveOrDeactivated,
    getInvited,
    activeDeactiveUser,
    editInvetedUserDetails,
    deleteInvitedUser
} from './apis';
import { invalidateUserProfileInfoQuery } from '../../MyProfile/queries';
import { invalidateSubscriptionInfoQuery } from '../../PlanAndPayment/queries';
import { getSubscriptionInfo } from '../../PlanAndPayment/queries/apis';
import { invalidateListOfManufactuerListQuery } from '../../Manufacturer/queries';
import { invalidateAllProjectDetails, invalidateUserDetailsQuery } from '../../../Project/queries';
import { invalidateGetAllCompanyDetailsKey, invalidateGetAllLocationsListQuery } from '../../../shared/CreateAndEditProjectModal/queries';

// KEYS
const allUserInfoKey = [endPoints.allUserInfo];
const companyKey = [endPoints.company];
const productFinderAccessKey = (userId: number) => [endPoints.productFinderAccess(userId)];
const getActiveOrDeactivatedKey = (tabValue?: string) => [endPoints.getActiveOrDeactivated(tabValue)];
const getInvitedKey = [endPoints.invitatngUser];
const allInactiveInfoKey = [endPoints.allInactiveInfo];

// GET
export const useUserInfoQuery = (options?: object) => useQuery(allUserInfoKey, getAllUsersData, { ...options });

export const useCompany = () => useQuery(companyKey, getAllCompanyBranchLocations);

export const useCheckForProductFinderAccessQuery = (userId: number) => useQuery(productFinderAccessKey(userId), () => getCheckForProductFinderAccess(userId));

// Mutations
export const useSendLoginInvitationMutation = () =>
    useMutation(postSendLoginInvitation, {
        onSuccess: (_data) => {
            invalidateActiveOrDeactivatedQuery();
            invalidateInvitedQuery();
        }
    });

export const useDeleteUserMutation = () =>
    useMutation(deleteUser, {
        onSuccess: (_data, deleteId) => {
            invalidateSubscriptionInfoQuery();
            invalidateAllProjectDetails();
            invalidateGetAllCompanyDetailsKey();
            invalidateUserDetailsQuery(deleteId);
            queryClient.setQueryData(allInactiveInfoKey, (oldData: any) => {
                const newData = oldData?.data.filter((item: any) => item.user_details?.id !== deleteId);
                return { ...oldData, data: newData };
            });
        }
    });

export const useEditUserDetailsMutation = () =>
    useMutation(edtUserDetails, {
        onSuccess: (_, request) => {
            invalidateUserProfileInfoQuery();
            queryClient.invalidateQueries({ queryKey: allUserInfoKey });
            queryClient.invalidateQueries({ queryKey: allInactiveInfoKey });
            invalidateListOfManufactuerListQuery();
            invalidateAllProjectDetails();
            invalidateGetAllCompanyDetailsKey();
            invalidateGetAllLocationsListQuery(request.userId);
            invalidateActiveOrDeactivatedQuery();
        }
    });

// To switch ON product finder from MODAL
export const useProductFinderAccessMutation = () =>
    useMutation(editProductFinderAccess, {
        onSuccess: (_data, props) => {
            invalidateSubscriptionInfoQuery();
            queryClient.invalidateQueries({ queryKey: productFinderAccessKey(props.userId) });
            queryClient.setQueryData(allUserInfoKey, (oldData: any) => {
                const newData = oldData?.data.map((item: any) => {
                    if (item.user_details.id === props.userId) {
                        // Update the product_finder_enabled field with the value from props
                        return {
                            ...item,
                            user_details: {
                                ...item.user_details,
                                product_finder_enabled: props.payload.product_finder_enabled
                            }
                        };
                    }
                    return item;
                });
                return { ...oldData, data: newData };
            });
        }
    });

// To switch OFF product finder without opening modal
export const disableProductFinderAccessToggle = (props: { userId: number; isDisablePFSuccessful: boolean; isInvitedTab: boolean }) => {
    queryClient.setQueryData(props.isInvitedTab ? getInvitedKey : allUserInfoKey, (oldData: any) => {
        const newData = oldData?.data.map((item: any) => {
            if (item.user_details.id === props.userId) {
                // Update the product_finder_enabled field with the value from props
                return {
                    ...item,
                    user_details: {
                        ...item.user_details,
                        product_finder_enabled: !props.isDisablePFSuccessful //if success, disable PF, if not, keep it enabled
                    }
                };
            }
            return item;
        });
        return { ...oldData, data: newData };
    });
};

export const useCheckForProductFinderAccessMutation = () => useMutation(getCheckForProductFinderAccess);

// doing this because the GET query was returning undefined
// for openProductFinderAccessModalHandler template function
export const useGetSubscriptionInfoMutation = () => useMutation(getSubscriptionInfo);

export const toggleQuotingAccess = (props: { isInvitedTab: boolean; userId: number }) => {
    const key = props.isInvitedTab ? getInvitedKey : allUserInfoKey;
    queryClient.setQueryData(key, (oldData: any) => {
        const newData = oldData?.data.map((item: any) => {
            if (item.user_details.id === props.userId) {
                // Update the product_finder_enabled field with the value from props
                return {
                    ...item,
                    user_details: {
                        ...item.user_details,
                        quoting_enabled: !item.user_details.quoting_enabled
                    }
                };
            }
            return item;
        });
        return { ...oldData, data: newData };
    });
};

export const useQuotingAccessMutation = () =>
    useMutation(editQuotingAccess, {
        onError: (_data, props) => {
            toggleQuotingAccess({ isInvitedTab: false, userId: props.userId });
        }
    });

export const invalidateAllUserInfoQuery = () => {
    queryClient.invalidateQueries(allUserInfoKey);
};

export const invalidateCompanyQuery = () => {
    queryClient.invalidateQueries(companyKey);
};

export const useGetTotalActiveInvitedAndDeactivatedQueary = () => {
    const tabValues = ['active', 'invited', 'inactive'];

    const queries = useQueries({
        queries: tabValues.map((tabValue) => ({
            queryKey: tabValue === 'invited' ? getInvitedKey : getActiveOrDeactivatedKey(tabValue),
            queryFn: () => (tabValue === 'invited' ? getInvited() : getActiveOrDeactivated(tabValue))
        }))
    });

    return queries;
};

export const useGetActiveOrDeactivatedQuery = (tabValue?: string, options?: object) => useQuery(getActiveOrDeactivatedKey(tabValue), () => getActiveOrDeactivated(tabValue), { ...options });
export const useGetInvitedQuery = (options?: object) => useQuery(getInvitedKey, () => getInvited(), { ...options });

export const invalidateActiveOrDeactivatedQuery = () => {
    queryClient.invalidateQueries(getActiveOrDeactivatedKey('inactive'));
    queryClient.invalidateQueries(getActiveOrDeactivatedKey('active'));
};

export const invalidateInvitedQuery = () => {
    queryClient.invalidateQueries(getInvitedKey);
};
export const useActiveDeactiveUserMutation = () =>
    useMutation(activeDeactiveUser, {
        onSuccess: (_data, { userid }) => {
            invalidateSubscriptionInfoQuery();
            invalidateAllProjectDetails();
            invalidateGetAllCompanyDetailsKey();
            invalidateActiveOrDeactivatedQuery();

            // invalidateUserDetailsQuery(deleteId);
            queryClient.setQueryData(allUserInfoKey, (oldData: any) => {
                const newData = oldData?.data.filter((item: any) => item.user_details?.id !== userid);
                return { ...oldData, data: newData };
            });
        }
    });

export const useEditInvetedUserDetailsMutation = () =>
    useMutation(editInvetedUserDetails, {
        onSuccess: (_, request) => {
            invalidateUserProfileInfoQuery();
            queryClient.invalidateQueries({ queryKey: getInvitedKey });
            invalidateListOfManufactuerListQuery();
            invalidateAllProjectDetails();
            invalidateGetAllCompanyDetailsKey();
            invalidateGetAllLocationsListQuery(request.userId);
        }
    });

export const useDeleteInvitedUserMutation = () =>
    useMutation(deleteInvitedUser, {
        onSuccess: (_data, deleteId) => {
            invalidateSubscriptionInfoQuery();
            invalidateAllProjectDetails();
            invalidateGetAllCompanyDetailsKey();
            invalidateUserDetailsQuery(deleteId);
            queryClient.setQueryData(getInvitedKey, (oldData: any) => {
                const newData = oldData?.data.filter((item: any) => item.user_details?.id !== deleteId);
                return { ...oldData, data: newData };
            });
        }
    });
