import { useRef, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import WebViewer, { Core } from '@pdftron/webviewer';
import { AxiosError, AxiosResponse } from 'axios';

import { Box, Button, BodySmall, AlertBanner, ImageIcon, FormatListBulletedIcon, CircularProgress, Skeleton, NotificationsOffOutlinedIcon, Snackbar, H5, Menu, MoreVertIcon } from '@parspec/pixel';

import { useGetActivityLog, useGetFinalSubmittal } from './queries';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { environment } from 'src/environments';
import { CED_NET_DOWNLOAD_FAILED, DOWNLOAD_FAILED_MSG, WEBVIEWER_FOLDER_NAME } from '../../../shared/constants';
import { useDocumentOutOfSyncQuery, useDownloadMutation } from '../queries';
import { callProxyURL } from '../utils/downloadFile';
import { SnackbarMessages } from '../constants';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { DownloadByVersionIdTypes } from '../queries/apiTypes';
import { useFeatureFlag } from '../../../shared/UserActivityTracking/useFeatureFlag';
import { useGetBomDetails } from '../../queries';
import EmailPopup from '../EmailPopup';
import ShareLinkInfo from './ShareLinkInfoModal';
import CancelButton from '../../../shared/CancelButton';
import { ActivitySection } from './ActivitySection';
import { parsecActivity } from '../utils/parspecActivity';
import { addCanvasThumbnail } from '../utils/addCanvasThumbnail';
import { useCheckAccessForProductFinderOrQuoting } from '../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';

const noDataToday = 'No Data For Today';
const noDataThisDay = (dateString: string) => `No Data For ${dateString}`;
const noDateYesterday = 'No Data For Yesterday';

const TODAY = 'Today';
const YESTERDAY = 'Yesterday';

interface bookMark {
    title: string;
    page: number;
}

type IDocType = { [key: string]: string };

const docType: IDocType = {
    submittal: 'Submittal',
    quote: 'Quote',
    o_m: 'O&M'
};

const QUOTE = 'quote';
const PdfViewer = () => {
    const { bomId = '', version, documentType = '', projectId } = useParams();

    const navigate = useNavigate();

    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();

    const { enable_cednet_format, enable_epicor_format } = useFeatureFlag();

    // queries start

    const { data: finalSubmittalData } = useGetFinalSubmittal(Number(bomId), Number(version), documentType);

    const { data: activityLog } = useGetActivityLog(Number(bomId), documentType);

    const { data: outOfSyncData } = useDocumentOutOfSyncQuery(bomId, documentType);

    const { data: bomData } = useGetBomDetails(bomId, {
        enabled: Boolean(bomId)
    });

    const { mutate: downloadMutation } = useDownloadMutation();

    const viewer = useRef<HTMLDivElement>(null);

    const [wvInstance, setwvInstances] = useState<any>(null);

    const [book, setBook] = useState<bookMark[]>([]);

    const [viewThumbNail, setViewThumbNail] = useState(true);

    const [viewBookMarks, setViewBookMarks] = useState(false);

    const [openSubmittalLinkModal, setOpenSubmittalLinkModal] = useState(false);

    const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);

    const [hideDocumentLoader, setHideDocumentLoader] = useState<boolean>(true);

    const [openEmailPopup, setOpenEmailPopup] = useState<boolean>(false);

    const [open, setOpen] = useState(true);

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const activityLogForUi = useMemo(() => {
        if (!activityLog?.data) {
            return null;
        }

        return activityLog.data.map((activity) => {
            const currentActivity = { ...activity };
            currentActivity.activities = currentActivity.activities.map(parsecActivity);
            return currentActivity;
        });
    }, [activityLog?.data]);

    const [showOutOfSyncBanner, setShowOutOfSyncBanner] = useState(false);
    useEffect(() => {
        if (!outOfSyncData?.data || (!quotingAccess && documentType === QUOTE)) {
            return;
        }
        setShowOutOfSyncBanner(outOfSyncData.data.out_of_sync);
    }, [outOfSyncData?.data.out_of_sync, quotingAccess]);

    useEffect(() => {
        setIsDocumentLoaded(true);
        if (finalSubmittalData) {
            WebViewer(
                {
                    licenseKey: environment.Y,
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    disableLogs: true,
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'annotationPopup',
                        'freeTextToolGroupButton',
                        'ribbons',
                        'contextMenuPopup',
                        'linkAnnotationPopup'
                    ],

                    initialDoc: getProxyFileUrl(finalSubmittalData.data.document_link_flat)
                },
                viewer.current as HTMLDivElement
            ).then((instance) => {
                setwvInstances(instance);
                const { documentViewer, annotationManager } = instance.Core;
                let searchButtonElem = {};
                instance.UI.setHeaderItems((header: any) => {
                    const items = header.getItems().filter((item: Record<string, any>) => {
                        if (item.dataElement === 'zoomOverlayButton') {
                            return true;
                        }
                        if (item.dataElement === 'searchButton') {
                            searchButtonElem = { ...item };
                        }
                        return false;
                    });
                    items.unshift({ type: 'spacer' });
                    items.push({ type: 'spacer' });
                    items.push({
                        type: 'actionButton',
                        img: '../../../../../../../assets/images/clock/clock.svg',
                        onClick: () => {
                            setOpen((prev) => !prev);
                            instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                        }
                    });
                    const printButton = {
                        type: 'actionButton',
                        img: '../../../../../../../assets/images/print-button.svg',
                        onClick: () => {
                            instance.UI.useEmbeddedPrint(true);
                            instance.UI.print();
                        },
                        dataElement: 'printButton'
                    };
                    items.push(printButton);
                    items.push(searchButtonElem);
                    header.update(items);
                });
                setHideDocumentLoader(false);
                documentViewer.addEventListener('documentLoaded', async () => {
                    annotationManager.enableReadOnlyMode();
                    instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                    instance.UI.closeElements(['loadingModal']);
                    setIsDocumentLoaded(false);
                    const doc = documentViewer.getDocument();

                    doc.getBookmarks().then((bookmarks) => {
                        const printOutlineTree = (item: Core.Bookmark, level: number) => {
                            const indent = ' '.repeat(level);
                            const name = item.getName();
                            setBook((old) => [...old, { title: indent + name, page: item.getPageNumber() }]);

                            item.getChildren().map((b: any) => printOutlineTree(b, level + 1));
                        };

                        bookmarks.map((root) => {
                            printOutlineTree(root, 0);
                        });
                    });
                    const pageCount = doc.getPageCount();
                    const thumbnailContainer: any = document.getElementById('thumbnails-container');

                    thumbnailContainer.innerHTML = '';

                    for (let i = 0; i < pageCount; i++) {
                        const pageNumber = i + 1;

                        await new Promise<void>((resolve) => {
                            doc.loadThumbnail(pageNumber, (canvas: any) => {
                                addCanvasThumbnail(pageNumber, canvas, documentViewer, thumbnailContainer);

                                resolve();
                            });
                        });
                    }
                });
            });
        }
    }, [finalSubmittalData]);

    useEffect(() => {
        const regenerateThumbNail = async () => {
            const { documentViewer } = wvInstance.Core;
            const doc = documentViewer.getDocument();
            const pageCount = doc.getPageCount();
            const thumbnailContainer: any = document.getElementById('thumbnails-container');

            thumbnailContainer.innerHTML = '';

            for (let i = 0; i < pageCount; i++) {
                const pageNumber = i + 1;

                await new Promise<void>((resolve) => {
                    doc.loadThumbnail(pageNumber, (canvas: any) => {
                        addCanvasThumbnail(pageNumber, canvas, documentViewer, thumbnailContainer);
                        resolve();
                    });
                });
            }
        };
        if (viewThumbNail && wvInstance) regenerateThumbNail();
    }, [finalSubmittalData, viewThumbNail]);

    const { documentViewer } = wvInstance?.Core || {};

    const showThumbNail = () => {
        setViewThumbNail(true);
        setViewBookMarks(false);
    };

    const showBookMarks = () => {
        setViewThumbNail(false);
        setViewBookMarks(true);
    };

    const navigateToFinalDocuments = () => {
        navigate(`/v2/project/${projectId}/bom/${bomId}?tab=finalDocuments`);
    };

    const handleSubmittalLinkModal = () => {
        setOpenSubmittalLinkModal((prev) => !prev);
    };

    const handleDownload = (fileType?: DownloadByVersionIdTypes['type']) => {
        setSnackbarOpen(SnackbarMessages.DOWNLOADING);
        downloadMutation(
            { bomId: bomId || '', id: Number(version || ''), type: fileType ? fileType : 'flattened', documentType },
            {
                onSuccess: async (data: AxiosResponse) => {
                    await callProxyURL(data?.data.url, fileType ? fileType : 'pdf', data?.data.filename);
                    setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
                },
                onError: (error: unknown) => {
                    const axiosError = error as AxiosError;
                    if ((fileType === 'ced_xlsx' || fileType === 'jm_xlsx') && axiosError?.response?.status === 404) {
                        setSnackbarOpen(CED_NET_DOWNLOAD_FAILED);
                    } else {
                        setSnackbarOpen(DOWNLOAD_FAILED_MSG);
                    }
                }
            }
        );
    };

    const onCreateNewVersionClicked = () => {
        navigate(`../compile/${documentType}`);
    };

    const getDownloadOption = (label: string, type?: DownloadByVersionIdTypes['type']) => {
        return {
            label,
            onClick: () => handleDownload(type)
        };
    };

    const shareDocumentOption = {
        label: 'Share via Email',
        onClick: () => setOpenEmailPopup(true)
    };

    const shareOptions = () => {
        const optionsArray = [];
        optionsArray.push(shareDocumentOption);
        optionsArray.push(getDownloadOption('Download as PDF'));

        if (documentType === QUOTE) {
            optionsArray.push(getDownloadOption('Download as Excel', 'xlsx'));
        }

        if (enable_cednet_format && documentType === QUOTE) {
            optionsArray.push(getDownloadOption('Download as CEDNet', 'ced_xlsx'));
        }

        if (enable_epicor_format && documentType === QUOTE) {
            optionsArray.push(getDownloadOption('Download as Job Management (Internal)', 'jm_xlsx'));
        }

        return optionsArray;
    };

    const handleEmailClose = (message?: string) => {
        setOpenEmailPopup(false);
        if (message) setSnackbarOpen(message);
    };

    const selectedBomId = useMemo(() => [Number(bomId || '')], [bomId]);

    return (
        <>
            <Box p={6}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <H5>{`View ${docType[documentType || '']}`}</H5>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        justifyContent={'space-between'}
                        gap={2}
                    >
                        <Box>
                            <CancelButton label="Close" />
                        </Box>
                        <Box>
                            <Button onClick={() => navigateToFinalDocuments()} variant="outlined" color={'secondary'}>
                                View Final Documents
                            </Button>
                        </Box>

                        <Box>
                            <Button variant="outlined" color="secondary" onClick={handleSubmittalLinkModal} fullWidth>
                                Manage Shareable Link
                            </Button>
                        </Box>

                        <Box marginRight={2}>
                            <Menu options={shareOptions()}>
                                <Button color="tertiary" variant="contained" endIcon={<MoreVertIcon />}>
                                    Share
                                </Button>
                            </Menu>
                        </Box>
                        {openSubmittalLinkModal && (
                            <ShareLinkInfo
                                open={openSubmittalLinkModal}
                                onCloseClick={handleSubmittalLinkModal}
                                onSuccess={handleSubmittalLinkModal}
                                bomId={bomId || ''}
                                documentType={documentType || ''}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            {showOutOfSyncBanner && (
                <AlertBanner
                    text="This document is out of sync. Generate a new version of this document to reflect all changes made to the product information."
                    variant="filled"
                    severity="warning"
                    onClose={() => {
                        setShowOutOfSyncBanner(false);
                    }}
                >
                    <Button variant="text" onClick={onCreateNewVersionClicked}>
                        <BodySmall
                            color="secondary.contrastText"
                            sx={{
                                '&:hover': {
                                    textDecorationLine: 'underline',
                                    textUnderlineOffset: 2
                                }
                            }}
                        >
                            Create New Version
                        </BodySmall>
                    </Button>
                </AlertBanner>
            )}

            <Box sx={{ display: 'flex' }} height={showOutOfSyncBanner ? 'calc(100vh - 118px)' : 'calc(100vh - 83px)'}>
                <Box bgcolor="secondary.light">
                    {
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginTop: 2,
                                marginLeft: 2
                            }}
                        >
                            <Box p={2} bgcolor={viewThumbNail ? 'rgba(255,255,255,0.05)' : ''} borderRadius={1}>
                                <ImageIcon onClick={showThumbNail} sx={{ cursor: 'pointer', color: 'secondary.contrastText' }} />
                            </Box>

                            <Box p={2} ml={3} bgcolor={viewBookMarks ? 'rgba(255,255,255,0.05)' : ''} borderRadius={1}>
                                <FormatListBulletedIcon
                                    onClick={showBookMarks}
                                    sx={{
                                        cursor: 'pointer',
                                        color: 'secondary.contrastText'
                                    }}
                                />
                            </Box>
                        </Box>
                    }

                    {viewThumbNail && (
                        <Box sx={{ width: 208, padding: 2 }}>
                            {
                                <Box
                                    display={!isDocumentLoaded ? 'block' : 'none'}
                                    id="thumbnails-container"
                                    sx={{
                                        height: 'calc(100vh - 170px)',
                                        width: '100%',
                                        overflowY: 'auto',
                                        '.thumbnail-box': {
                                            padding: 2,
                                            display: 'flex',
                                            width: '100%',
                                            flexDirection: 'column'
                                        },
                                        '.thumbnail-box canvas.markup-canvas': {
                                            width: '168px !important',
                                            height: '220px !important',
                                            cursor: 'pointer'
                                        },
                                        '.thumbnail-footer': {
                                            color: 'secondary.contrastText',
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            }
                            {isDocumentLoaded && (
                                <Box marginLeft={2} marginTop={4} height={'calc(100vh - 170px)'} width={'auto'} overflow={'auto'}>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}

                    {!isDocumentLoaded && book && viewBookMarks && (
                        <Box
                            width={208}
                            padding={2}
                            sx={{
                                overflowY: 'auto',
                                height: 'calc(100vh - 170px)'
                            }}
                        >
                            <Box id="bookmark">
                                {book?.map((val, index) => (
                                    <Box>
                                        <BodySmall
                                            key={index}
                                            mt={4}
                                            p={2}
                                            sx={{
                                                cursor: 'pointer',
                                                color: 'secondary.contrastText',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255,255,255,0.05)',
                                                    borderRadius: '4px'
                                                }
                                            }}
                                            onClick={() => documentViewer.setCurrentPage(val.page, true)}
                                        >
                                            {val.title}
                                        </BodySmall>
                                    </Box>
                                ))}
                            </Box>

                            {isDocumentLoaded && viewBookMarks && (
                                <Box marginTop={5}>
                                    <Skeleton color="light" sx={{ borderRadius: 1, marginBottom: 3 }} variant="rectangular" width={192} height={32} />
                                    <Skeleton color="light" sx={{ borderRadius: 1, marginBottom: 3 }} variant="rectangular" width={192} height={32} />
                                    <Skeleton color="light" sx={{ borderRadius: 1, marginBottom: 3 }} variant="rectangular" width={192} height={32} />
                                    <Skeleton color="light" sx={{ borderRadius: 1, marginBottom: 3 }} variant="rectangular" width={192} height={32} />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                <Box width={'100%'} height={'100%'} position="relative">
                    {hideDocumentLoader && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CircularProgress size="xxl" thickness={4.6} color="primary" />
                        </Box>
                    )}
                    <Box ref={viewer} height="100%" />
                </Box>
                {open && (
                    <Box
                        sx={{
                            backgroundColor: 'secondary.main',
                            color: 'white',
                            padding: 4,
                            fontWeight: '500',
                            overflowY: 'scroll',
                            width: 425,
                            overflowX: 'hidden'
                        }}
                    >
                        <BodySmall color="secondary.contrastText" paddingBottom={4}>
                            Viewing Activity
                        </BodySmall>
                        {activityLogForUi && activityLogForUi.length ? (
                            <Box>
                                {activityLogForUi.map((activity, idx) => {
                                    if (activity.date === TODAY) {
                                        return <ActivitySection key={idx} title={TODAY} data={activity.activities} noDataMessage={noDataToday} />;
                                    } else if (activity.date === YESTERDAY) {
                                        return <ActivitySection title={YESTERDAY} data={activity.activities} noDataMessage={noDateYesterday} />;
                                    } else {
                                        return <ActivitySection title={activity.date} data={activity.activities} noDataMessage={noDataThisDay(activity.date)} />;
                                    }
                                })}
                            </Box>
                        ) : (
                            <Box sx={{ paddingTop: 32, textAlign: 'center' }}>
                                <Box sx={{ paddingBottom: 8 }}>No activity</Box>
                                <NotificationsOffOutlinedIcon fontSize="large" />
                                <Box sx={{ paddingTop: 8 }}> View & download history for the shareable link will be shown here.</Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
            {openEmailPopup && (
                <EmailPopup selectedBomdIds={selectedBomId} projectId={bomData?.data.project || -1} open={openEmailPopup} onClose={handleEmailClose} documentType={documentType} docId={version} />
            )}
        </>
    );
};

export default PdfViewer;
