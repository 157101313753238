import { BodyXS, Box, CancelIcon, IconButton } from '@parspec/pixel';

interface IAttachmentProps {
    file: File;
    isLoggedIn: boolean;
    handleAttachmentDelete: (file: File) => void;
}

const Attachment = ({ file, isLoggedIn, handleAttachmentDelete }: IAttachmentProps) => {
    return (
        <Box my={1} key={file?.name} display="flex" justifyContent="space-between" width="100%" alignItems="center" sx={{ opacity: isLoggedIn ? 1 : 0.5 }}>
            <Box display="flex" alignItems="center" columnGap={2} width="95%">
                <Box width="40px" height="24px" bgcolor="error.main" display="flex" justifyContent="center" alignItems="center">
                    <BodyXS color="neutral.light" fontWeight="700">
                        PDF
                    </BodyXS>
                </Box>
                <BodyXS color="neutral.dark" limit={false} width="92%" sx={{ wordBreak: 'break-word' }}>
                    {file?.name}
                </BodyXS>
            </Box>
            <IconButton disabled={!isLoggedIn} onClick={() => handleAttachmentDelete(file)}>
                <CancelIcon />
            </IconButton>
        </Box>
    );
};

export default Attachment;
