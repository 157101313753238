import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Switch, CustomDatePicker, Grid, Box, dayjs, Dayjs } from '@parspec/pixel';

import PreviewContext from '../Context';
import { useDocumentTextFieldMutation } from '../queries';
import { ENTITY } from '../../constants';

interface SwitchTextfieldPropTypes {
    checked?: boolean;
    switchLabel: string;
    action: (event: React.ChangeEvent<HTMLInputElement>) => void;
    textValue?: string;
    fieldName?: string;
}

const SwitchDatePicker = ({ fieldName, textValue, checked, switchLabel, action }: SwitchTextfieldPropTypes) => {
    const { bomId = '', documentType = '' } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);
    const [dateField, setDateField] = useState<Dayjs | null>(null);

    const { mutateAsync: updateDate } = useDocumentTextFieldMutation();

    useEffect(() => {
        if (textValue) {
            setDateField(dayjs(textValue));
        }
    }, [textValue]);

    const handleOnBlur = async (value: string | null) => {
        await updateDate({
            bomId: bomId,
            fieldName,
            field_value: value,
            documentType: documentType!,
            entity: ENTITY.COVER_PAGE_ENTITY
        });
        handlePreviewImage(true);
    };

    const handleOnChange = (value: Dayjs | null) => {
        if (dayjs(value).isValid()) {
            setDateField(value);
            handleOnBlur(dayjs(value).format());
        } else {
            setDateField(null);
            handleOnBlur(null);
        }
    };

    return (
        <Grid container mt={2} mb={2}>
            <Grid item xs={4}>
                <Switch checked={checked} label={switchLabel} onChange={action} />
            </Grid>

            <Grid item xs={8}>
                <Box>
                    <CustomDatePicker label={''} value={dateField} onChange={handleOnChange} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default SwitchDatePicker;
