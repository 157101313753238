export enum SPECIFICATION_VALUES {
    PENDING_ALTERNATE = 'Pending Alternate',
    APPROVED_ALTERNATE = 'Approved Alternate',
    LOST_ALTERNATE = 'Lost Alternate',
    BASIS_OF_DESIGN = 'Basis of Design'
}

export const specificationOptions = [
    { label: 'Basis of Design', value: SPECIFICATION_VALUES.BASIS_OF_DESIGN, type: 'primary' },
    { label: 'Pending Alternate', value: SPECIFICATION_VALUES.PENDING_ALTERNATE, type: 'tertiary' },
    { label: 'Approved Alternate', value: SPECIFICATION_VALUES.APPROVED_ALTERNATE, type: 'success' },
    { label: 'Lost Alternate', value: SPECIFICATION_VALUES.LOST_ALTERNATE, type: 'error' }
];

export type SpecificationValType = (typeof SPECIFICATION_VALUES)[keyof typeof SPECIFICATION_VALUES];

export const PRODUCT_CATEGORY_LIST = [
    'Linear',
    'Strip Light',
    'Downlight',
    'Troffer',
    'Panel',
    'Floodlight / Grazer',
    'Flex / Tape / Channel',
    'Multiples',
    'Area / Site',
    'High Bay / Low Bay',
    'Steplight',
    'Cove / Perimeter',
    'In-Grade',
    'Vanity Light',
    'Exit',
    'Emergency',
    'Under Cabinet / Puck',
    'Wallpack',
    'Parking Garage / Canopy',
    'Cylinder',
    'Sconce',
    'Wraparound',
    'Bollard / Path Light',
    'Landscape',
    'Fans',
    'Flush Mount',
    'Semi-Flush',
    'Accent / Track Head',
    'Pendant / Chandelier',
    'Floor Lamp'
];

export const PRODUCT_CATEGORY_SET = new Set(PRODUCT_CATEGORY_LIST);

export const PRODUCT_APPLICATION = ['Architectural', 'Decorative', 'Industrial'];

export const PRODUCT_MOUNTING_TYPE = [
    'Adjustable',
    'Arm',
    'Knuckle / Yoke / Trunnion',
    'Monopoint',
    'Recessed',
    'Semi-Recessed',
    'Surface',
    'Wall',
    'Ground / Floor',
    'Suspended / Cable / Chain / Stem / Pendant',
    'T-Bar Grid',
    'Track',
    'Flange / Trimmed',
    'Trimless',
    'Stake',
    'Retrofit',
    'Magnetic',
    'Pole / Stanchion / Tenon',
    'Clamp / Hook / Strap'
];

export const MOUNTING_TYPE_SET = new Set(PRODUCT_MOUNTING_TYPE);

export const PRODUCT_DIMMING_PROTOCOL = [
    '0-10V',
    '1-10V',
    'Triac',
    'ELV (Electronic Low Voltage)',
    'MLV (Magnetic Low Voltage)',
    'Lutron',
    'DALI',
    'DMX',
    'PWM',
    'Forward Phase',
    'Reverse Phase',
    'Touch',
    'Pull Chain',
    'In-Line On/Off',
    'Hand Motion',
    'Bulb Dependent',
    'UniDim',
    'Warm Dim / Adjustable White',
    'Full Range Dimmer Switch',
    'Wireless (Bluetooth, Zigbe, Casambi)'
];

export const PRODUCT_DIMMING_RANGE = ['Bi-Level', 'Dim to <1%', 'Dim to 1%', 'Dim to 5-10%', 'Dim to ≥ 11%', 'Multi Switching', 'Step Dim', 'Dim-to-Dark', 'Non-Dimmable'];

export const PRODUCT_VOLTAGE = [
    'Other Low Voltage',
    '12v',
    '24v',
    '12-24v',
    '36v',
    '100-110v',
    '100-240v',
    '100-277v MVOLT',
    '100-347v',
    '120-240v',
    '120-277v UNV / MVOLT',
    '120-347v',
    '120v',
    '120-250v',
    '125v',
    '200-480v',
    '220-240v',
    '277-480v HVOLT',
    '277v',
    '347v',
    '347-480v HVOLT',
    '400v',
    '480v',
    'Other High Voltage'
];

export const PRODUCT_ENVIRONMENT = [
    'Healthcare / Patient Rooms',
    'Outdoor',
    'Hazardous',
    'Cleanroom',
    'Damp',
    'Classroom / Educational Facilities',
    'Wet',
    'Food Processing',
    'Marine',
    'MRI-Safe',
    'Pool / Spa',
    'Dry',
    '2HR Fire Rated',
    'ADA',
    'IC',
    'CA Title 24',
    'CCEA Chicago Plenum',
    'Energy Star',
    'IP67',
    'IP65',
    'IP66',
    'IP68',
    'IP69K',
    'JA8',
    'Non-IC'
];

export const LUMEN_UNITS = [
    { label: 'Lumens', value: 'lm' },
    { value: 'lm/ft', label: 'Lumens/Feet' },
    { label: 'Lumens/Meter', value: 'lm/meter' }
];

export const KNOWLEDGE_BASE_URL = 'https://knowledgebase.parspec.io/knowledge/terms-of-service';

export const HIDDEN_XFDF = '<?xml version="1.0" ?><annots></annots>';

export const CONTACT_PARSPEC_LINK = 'https://meetings.hubspot.com/parspec/contact';

export const MULTI_VALUE_CATEGORIES: { [key: string]: string[] } = {
    'Linear / Strip': ['Linear', 'Strip Light', 'Linear / Strip'],
    'Under Cabinet / Puck': ['Under Cabinet / Puck', 'Under Cabinet'],
    'Floodlight / Grazer': ['Floodlight', 'Floodlight / Grazer']
};

export const MULTI_VALUE_MOUNTING_TYPE: { [key: string]: string[] } = {
    'Knuckle / Yoke / Trunnion': ['Knuckle / Yoke', 'Knuckle / Yoke / Trunnion'],
    'Ground / Floor': ['Floor', 'Ground / Floor'],
    'Clamp / Hook / Strap': ['Clamp', 'Clamp / Hook / Strap']
};

export enum EVENT_SERVICE_PAGENAME_VALUES {
    BIDBOARD = 'bidboard',
    BOM = 'bom',
    REPORTING_CUSTOMERS = 'reporting_customers',
    REPORTING_QUOTE_OWNERS = 'reporting_users',
    REPORTING_BRANCH_LOCATIONS = 'reporting_branch_locations',
    REPORTING_MANUFACTURERS = 'reporting_manufacturers',
    DASHBOARD_PROJECTS = 'dashboard_projects',
    DASHBOARD_QUOTE = 'dashboard_quote',
    DASHBOARD_SUBMITTAL = 'dashboard_submittal',
    DASHBOARD_OM = 'dashboard_om',
    DASHBOARD_BOM = 'dashboard_bom'
}

export const QUOTE_DOCUMENT_AUTOMATION_STATUSES = ['Won', 'Lost', 'Abandoned'];
