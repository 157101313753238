import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Accordion, Switch, BodySmall, FormControl, FileSelectorFileType } from '@parspec/pixel';

import PreviewContext from './Context';
import FileUploaded from './shared/FileUploaded';
import { CoverPage } from './accordions/coverPage';
import { TableOfContents } from './accordions/tableOfContents';
import { NotesSummary } from './accordions/notesSummary';
import { HeaderAndFooter } from './accordions/headerAndFooter';
import { ContentFormatting } from './accordions/contentFormatting';
import { useDocumentToggleConfigurationMutation, useUploadCustomPageMutation, useDeleteCustomPageMutation, useDocumentPresetQuery } from './queries';
import { CustomUploadProps, AccordionProps } from './queries/apiTypes';

import { ENTITY } from '../constants';

const accordionBodyDisabled = {
    opacity: '0.4',
    pointerEvents: 'none',
    overflow: 'scroll',
    height: 'calc(100vh - 444px)'
};

const accordionBodyEnabled = {
    overflow: 'auto',
    height: 'calc(100vh - 444px)'
};

const fileUploadedText = 'You uploaded a cover page. Delete the uploaded file to revert to our template';

const accordionSummaryStyle = { ml: 4, mt: 2 };

const disableEventBubbling = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
};

const AccordionEditor = ({
    name,
    size,
    is_custom_cover_page,
    onCoverPageChange,
    onTocChange,
    onHeaderFooterChange,
    onNotesChange,
    handleContentVisibility,
    coverPageToggleConfigurationData,
    tocToggleConfigurationData
}: AccordionProps) => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);

    const { mutateAsync: toggleMutation } = useDocumentToggleConfigurationMutation();
    const { mutate: customCoverPageUpdateMutation, isLoading: isCustomUploadLoading } = useUploadCustomPageMutation();
    const { mutate: customCoverPageDeleteMutation } = useDeleteCustomPageMutation();
    const { data: notesPagePresetData } = useDocumentPresetQuery(bomId!, documentType!, ENTITY.NOTES_PAGE_ENTITY, presetResponse);
    const { data: headerFooterPresetData } = useDocumentPresetQuery(bomId!, documentType!, ENTITY.HEADER_FOOTER_ENTITY, presetResponse);

    const [coverState, setCoverState] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    const [isTableOfContents, setIsTableOfContents] = useState(true);
    const [isTableOfContentsDisabled, setIsTableOfContentsDisabled] = useState(false);

    const [isNotesSummary, setIsNotesSummary] = useState(true);
    const [isNotesSummaryDisabled, setIsNotesSummaryDisabled] = useState(false);

    const [isHeaderAndFooter, setIsHeaderAndFooter] = useState(true);
    const [isHeaderAndFooterDisabled, setIsHeaderAndFooterDisabled] = useState(false);

    const [customUpload, setCustomUpload] = useState<CustomUploadProps | null>(null);

    useEffect(() => {
        coverPageToggleConfigurationData?.cover_page_section ? setCoverState(true) : setCoverState(false);
    }, [coverPageToggleConfigurationData?.cover_page_section]);

    useEffect(() => {
        tocToggleConfigurationData?.toc_section ? setIsTableOfContents(true) : setIsTableOfContents(false);
    }, [tocToggleConfigurationData?.toc_section]);

    useEffect(() => {
        notesPagePresetData?.notes_section?.is_active ? setIsNotesSummary(true) : setIsNotesSummary(false);
    }, [notesPagePresetData?.notes_section?.is_active]);

    useEffect(() => {
        headerFooterPresetData?.header_footer_section?.is_active ? setIsHeaderAndFooter(true) : setIsHeaderAndFooter(false);
    }, [headerFooterPresetData?.header_footer_section?.is_active]);

    const handleCoverPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setCoverState(event.target.checked);
        onCoverPageChange(event.target.checked);
        await toggleMutation({
            is_active: event.target.checked,
            bomId: bomId!,
            fieldName: 'cover_page_section',
            documentType: documentType!,
            entity: ENTITY.COVER_PAGE_ENTITY
        });
    };

    const handleTableOfContents = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTableOfContents(event.target.checked);
        onTocChange(event.target.checked);
        await toggleMutation({
            is_active: event.target.checked,
            bomId: bomId!,
            fieldName: 'toc_section',
            documentType: documentType!,
            entity: ENTITY.TOC_ENTITY
        });
    };

    const handleNotesSummary = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNotesSummary(event.target.checked);
        onNotesChange(event.target.checked);
        await toggleMutation({
            is_active: event.target.checked,
            bomId: bomId!,
            fieldName: 'notes_section',
            documentType: documentType!,
            entity: ENTITY.NOTES_PAGE_ENTITY
        });
    };

    const handleHeaderAndFooter = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsHeaderAndFooter(event.target.checked);
        onHeaderFooterChange(event.target.checked);
        await toggleMutation({
            is_active: event.target.checked,
            bomId: bomId!,
            fieldName: 'header_footer_section',
            documentType: documentType!,
            entity: ENTITY.HEADER_FOOTER_ENTITY
        });
    };

    const customFileUploadHandler = (data: File[] | FileSelectorFileType[]) => {
        if (data.length) {
            const formData = new FormData();
            formData.append('cover_page', data[0] as File);

            customCoverPageUpdateMutation(
                {
                    bomId: bomId!,
                    formData: formData,
                    documentType: documentType!,
                    entity: ENTITY.COVER_PAGE_ENTITY
                },
                {
                    onSuccess: () => {
                        setCustomUpload({
                            name: data[0]['name'],
                            size: String(data[0]['size']),
                            is_custom_cover_page: true
                        });
                        handlePreviewImage(true);
                    }
                }
            );
        }
    };

    const customFileDeleteHanlder = () => {
        setCustomUpload(null);
        customCoverPageDeleteMutation(
            {
                bomId: bomId!,
                documentType: documentType!,
                entity: ENTITY.COVER_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    useEffect(() => {
        if (!coverState) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [coverState]);

    useEffect(() => {
        if (!isTableOfContents) {
            setIsTableOfContentsDisabled(true);
        } else {
            setIsTableOfContentsDisabled(false);
        }
    }, [isTableOfContents]);

    useEffect(() => {
        if (!isNotesSummary) {
            setIsNotesSummaryDisabled(true);
        } else {
            setIsNotesSummaryDisabled(false);
        }
    }, [isNotesSummary]);

    useEffect(() => {
        if (!isHeaderAndFooter) {
            setIsHeaderAndFooterDisabled(true);
        } else {
            setIsHeaderAndFooterDisabled(false);
        }
    }, [isHeaderAndFooter]);

    useEffect(() => {
        if (is_custom_cover_page) {
            setCustomUpload({ name, size, is_custom_cover_page });
        }
    }, [is_custom_cover_page]);

    const FileUploadedCondition = () =>
        customUpload ? (
            <FileUploaded text={fileUploadedText} customUpload={customUpload} onClick={customFileDeleteHanlder} />
        ) : (
            <CoverPage isCustomUploadLoading={isCustomUploadLoading} onUpload={customFileUploadHandler} />
        );

    const accordionArray = [
        {
            details: isDisabled ? (
                <Box
                    width="100%"
                    p={2}
                    sx={
                        customUpload
                            ? {
                                  height: '18vh',
                                  opacity: '0.4',
                                  pointerEvents: 'none'
                              }
                            : accordionBodyDisabled
                    }
                >
                    {FileUploadedCondition()}
                </Box>
            ) : (
                <Box width="100%" p={2} sx={customUpload ? { height: '18vh', overflow: 'scroll' } : accordionBodyEnabled}>
                    {FileUploadedCondition()}
                </Box>
            ),

            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={coverState}
                        onChange={handleCoverPage}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Cover Page
                            </BodySmall>
                        }
                    />
                </FormControl>
            ),
            labelId: ENTITY.COVER_PAGE_ENTITY
        },

        {
            details: (
                <Box width="100%" p={2} sx={isTableOfContentsDisabled ? accordionBodyDisabled : accordionBodyEnabled}>
                    <TableOfContents />
                </Box>
            ),
            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={isTableOfContents}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Table Of Contents
                            </BodySmall>
                        }
                        onChange={handleTableOfContents}
                    />
                </FormControl>
            ),
            labelId: ENTITY.TOC_ENTITY
        },

        {
            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={isNotesSummary}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Notes Summary
                            </BodySmall>
                        }
                        onChange={handleNotesSummary}
                    />
                </FormControl>
            ),
            details: (
                <Box width="100%" p={2} sx={isNotesSummaryDisabled ? accordionBodyDisabled : accordionBodyEnabled}>
                    <NotesSummary />
                </Box>
            ),
            labelId: ENTITY.NOTES_PAGE_ENTITY
        },

        {
            summary: (
                <FormControl sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                    <Switch
                        checked={isHeaderAndFooter}
                        label={
                            <BodySmall fontWeight={500} sx={{ ml: 2 }}>
                                Header & Footer
                            </BodySmall>
                        }
                        onChange={handleHeaderAndFooter}
                    />
                </FormControl>
            ),
            details: (
                <Box width="100%" p={2} sx={isHeaderAndFooterDisabled ? accordionBodyDisabled : accordionBodyEnabled}>
                    <HeaderAndFooter />
                </Box>
            ),
            labelId: ENTITY.HEADER_FOOTER_ENTITY
        },
        {
            summary: (
                <Box p={1}>
                    <BodySmall mb={1} fontWeight={500} sx={accordionSummaryStyle} onClick={disableEventBubbling}>
                        Contents Formatting
                    </BodySmall>
                </Box>
            ),
            details: (
                <Box width="100%" p={2} sx={accordionBodyEnabled}>
                    <ContentFormatting />
                </Box>
            ),
            labelId: ENTITY.LAYOUT
        }
    ];

    return <Accordion getPanel={handleContentVisibility} disableGutters options={accordionArray} />;
};

export default AccordionEditor;
