import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Grid, H5 } from '@parspec/pixel';
import ProductInfo from '../../../shared/ProductInfo';
import ProductNotes from '../../../shared/ProductNotes';
import CancelButton from 'src/features/shared/CancelButton';
import { ReuseDialogTypeLabel, ReuseDialogType } from '../../shared/utils';

interface HeaderProps {
    handleRefresh: (val?: boolean) => void;
    handleAddNewData: () => void;
    headerHeight?: string;
    buttonLoading?: boolean;
    onClickSave: (arg: () => void) => void;
    annotateButtonDisabled: boolean;
}

export const Header = (props: HeaderProps) => {
    const { handleRefresh, onClickSave, headerHeight, buttonLoading, annotateButtonDisabled, handleAddNewData } = props;
    const navigate = useNavigate();
    const { bomId, productId, documentType, subDocumentType, projectId } = useParams();
    const isDatasheetCorousal = subDocumentType === ReuseDialogType.DATASHEET;
    const redirectionTab = isDatasheetCorousal ? 'dataSheets' : 'additionalDocuments';
    const [loading, setLoading] = useState('');
    const [searchParams] = useSearchParams();
    const redirectToProductFinder = searchParams.get('from') === 'productFinder';

    useEffect(() => {
        if (!buttonLoading) {
            setLoading('');
        }
    }, [buttonLoading]);

    const onRedirectAfterSave = () => {
        navigate(getRedirectUrl());
    };

    function getRedirectUrl() {
        const redirectionUrl = `/v2/project/${projectId}/bom/${bomId}?tab=${redirectionTab}&productId=${productId}`;
        const productFinderUrl = `/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/setRequirements`;
        const url = redirectToProductFinder ? productFinderUrl : redirectionUrl;
        return url;
    }

    const onAnnotate = () => {
        navigate(`/v2/project/${projectId}/bom/${bomId}/product/${productId}/${documentType}/${subDocumentType}/annotate`);
    };

    const onSave = () => {
        setLoading('save');
        onClickSave(onRedirectAfterSave);
    };

    const onSaveAndAnnotate = () => {
        setLoading('annotate');
        onClickSave(onAnnotate);
    };

    return (
        <Box p={6} px={6} height={headerHeight || '166px'} borderBottom="1px solid" borderColor="neutral.main">
            <Box display="flex">
                <Box>
                    <H5>Select {ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}</H5>
                </Box>
                <Box display="flex" gap={3} alignItems="flex-start" ml="auto">
                    <Box sx={{ whiteSpace: 'nowrap' }} mr={-2}>
                        <CancelButton />
                    </Box>

                    <Box sx={{ whiteSpace: 'nowrap' }}>
                        <Button variant="outlined" color="secondary" onClick={handleAddNewData}>
                            Add/Reuse {ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}
                        </Button>
                    </Box>

                    <Box sx={{ whiteSpace: 'nowrap' }}>
                        <Button
                            variant={redirectToProductFinder ? 'contained' : 'outlined'}
                            color={redirectToProductFinder ? 'tertiary' : 'secondary'}
                            onClick={onSave}
                            isLoading={buttonLoading && loading === 'save'}
                        >
                            Save
                        </Button>
                    </Box>
                    {!redirectToProductFinder && (
                        <Box sx={{ whiteSpace: 'nowrap' }}>
                            <Button color="tertiary" variant="contained" onClick={onSaveAndAnnotate} isLoading={buttonLoading && loading === 'annotate'} disabled={annotateButtonDisabled}>
                                Save & Annotate
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Grid container mt={2}>
                <Grid item md={12} lg={12} xs={12} sm={12} xl={12}>
                    <Box>
                        <ProductInfo onAccept={handleRefresh} />
                    </Box>
                </Grid>
                {subDocumentType === 'datasheet' ? (
                    <>
                        <Grid item xs={12}>
                            <Box mt={3}>
                                <ProductNotes />
                            </Box>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </Box>
    );
};

export default Header;
