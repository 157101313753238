import { axios } from 'src/app/Axios';

import { PricingBodInfo, PricingBodsResponse } from './apiTypes';
import { pushEvent } from 'src/features/shared/UserActivityTracking/EventService';
import { getStringifiedValueForEventService } from 'src/features/shared/utils/utils';

export const endpoints = {
    getPricingBodListUrl: (bomId: number) => `/bom/${bomId}/quote_metadata/`,
    getPricingBodEditUrl: (bomId: number, bodId: number) => `/bom/${bomId}/quote_metadata/${bodId}/`,
    getPricingStatsUrl: (bomId: number) => `/bom/${bomId}/statistics/pricing`
};

export function getPricingBods(bomId: number) {
    return axios.get<PricingBodsResponse>(endpoints.getPricingBodListUrl(bomId));
}

export function patchPricingBod({ bomId, bodId, pricingBod }: { bomId: number; bodId: number; pricingBod: Partial<PricingBodInfo> }) {
    return axios.patch<PricingBodsResponse>(endpoints.getPricingBodEditUrl(bomId, bodId), pricingBod);
}

/**********************************  THE BELOW CODE WILL BE DELETED ONCE THE TESTING FOR VSAP TOOLS TESTING IS DONE ******************************* */
const insertPastedContentMutation = (pastedContent: { [index: string]: string[] }, userId: number, email: string) => {
    return `mutation {
  logging_db {
    insert_copy_paste_data_log(objects: {user_email_id: "${email}", pasted_data:   ${getStringifiedValueForEventService(pastedContent)} }) {
      affected_rows
    }
  }
}`;
};

const updatePastedContentMutation = (pastedContent: { [index: string]: string[] }, userId: number) => {
    return `mutation {
  logging_db {
    update_copy_paste_data_log(where: {user_id: {_eq: ${userId}}}, _set: {pasted_data:  ${getStringifiedValueForEventService(pastedContent)} }) {
      affected_rows
    }
  }
}`;
};

export const updateUserPastedContent = ({ pastedContent, userId, email }: { pastedContent: { [index: string]: string[] }; userId: number; email: string }) => {
    return pushEvent({
        insertMutation: insertPastedContentMutation(pastedContent, userId, email),
        updateMutation: updatePastedContentMutation(pastedContent, userId),
        responseAttribute: 'copy_paste_data_log'
    });
};
