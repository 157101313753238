import { axios } from 'src/app/Axios';

import { ServicesRequestType, ServicesResponseType } from './apiTypes';

export const endPoints = {
    getQuoteServiceUrl: (bomId: number) => `/bom/${bomId}/pricing/service/`
};

export function getService(bomId: number) {
    return axios.get<ServicesResponseType>(endPoints.getQuoteServiceUrl(bomId));
}

export function putService({ bomId, reqBody }: { bomId: number; reqBody: ServicesRequestType }) {
    return axios.put<ServicesResponseType>(endPoints.getQuoteServiceUrl(bomId), reqBody);
}
