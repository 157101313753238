import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AxiosResponse } from 'axios';

import { Box } from '@parspec/pixel';

import CompileHeader from './CompileHeader';
import AutosearchLoaderDialog from 'src/features/shared/AutosearchLoaderDialog';
import { useGenerateDocumentMutation, useCheckCreditBeforeCompilingMutation } from './queries';
import CreateNewVersion from '../../Preview/CreateNewVersionModal';
import { useAllDocumentsQuery } from '../../queries';
import PreviewContext from '../submittals&o_m/Context';
import { PRESET_RESPONSE, ICompileLabels, compileLabels } from '../constants';
import { useQuoteContext } from '../compileQuote/QuoteContext';
import { CreditCheckModalInterface } from './utils/helper';
import { getOrderedNotes } from './utils';
import { NotEnoughCreditsModal } from './NotEnoughCreditsModal';
import { DocType, QUOTE_SUBTITLE_FOR_VERSION_NOTE, DEFAULT_SUB_TITLE_FOR_VERSION_NOTE, DEFAULT_TITLE_FOR_VERSION_NOTE, QUOTE_TITLE_FOR_VERSION_NOTE } from '../../constants';
import { useFeatureFlag } from 'src/features/shared/UserActivityTracking/useFeatureFlag';
import { useTrackIntercomEvent } from 'src/features/shared/hooks/useIntercomHook';
import { useAggregatedCompileTime, useAggregatedCompileTimeForPreviewQuote, useGetPreviewQuoteUrl, useGetAllBomIds } from './hooks';

interface HeaderProps {
    submittalsOmBomId?: number[];
}

const Header = (props: HeaderProps) => {
    const { submittalsOmBomId } = props;

    const navigate = useNavigate();
    const { bomId = '', documentType = '', projectId = '0' } = useParams();
    const { enable_cednet_format, enable_epicor_format } = useFeatureFlag();
    const { trackIntercomEvent } = useTrackIntercomEvent();
    const { presetResponse } = useContext(PreviewContext);
    const { isInitiateApiCalls } = useQuoteContext();

    const { bomIds, isLoading: isBomIdsLoading } = useGetAllBomIds(Number(projectId), submittalsOmBomId);

    const [creditCheckModalData, setCreditCheckModalData] = useState<CreditCheckModalInterface>({ isOpen: false, credits_to_deduct: 0, auto_upgrade: false });
    const [showCompileLoader, setShowCompileLoader] = useState(false);
    const [showPreviewLoader, setShowPreviewLoader] = useState(false);
    const [editNotes, setEditNotes] = useState(false);
    const [showNotes, setShowNotes] = useState(true);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [selectedBoms, setSelectedBoms] = useState<Array<number>>([]);
    const [bomsNotes, setBomsNote] = useState<Record<string, string> | null>(null);

    const isQuoteDocument = documentType === DocType.QUOTE ? true : false;
    const compileLoaderTitle = useMemo(
        () => `Compiling ${isQuoteDocument && bomsNotes ? Object.keys(bomsNotes).length : ''} ${compileLabels[documentType as keyof ICompileLabels]}${isQuoteDocument ? '(s)' : ''}`,
        [bomsNotes, isQuoteDocument]
    );

    const updateSelectedBoms = useCallback((params: number[]) => {
        setSelectedBoms(params);
    }, []);

    const { pdfUrls, refetch: refetchPreviewQuote } = useGetPreviewQuoteUrl({ bomIds });
    const { data: allDocumentData } = useAllDocumentsQuery(bomId, documentType, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS || isInitiateApiCalls
    });
    const { aggregatedCompileTime, refetch: getCompileTime, isFetching: isCompileTimeFetching } = useAggregatedCompileTime({ bomIds: selectedBoms, documentType });
    const {
        aggregatedCompileTimeForQuote,
        refetch: refetchCompileQuoteTimeForQuote,
        isFetching: isCompileTimeForQuoteFetching
    } = useAggregatedCompileTimeForPreviewQuote({ bomIds, documentType: 'quote' });

    const { onMutation, invalidateQueries } = useGenerateDocumentMutation(Number(projectId), documentType as DocType);
    const { mutateAsync: checkCreditBeforeCompilation, isLoading: isCreditCheckLoading } = useCheckCreditBeforeCompilingMutation();

    useEffect(() => {
        if (allDocumentData?.data && !allDocumentData?.data.length) {
            setShowNotes(false);
        }
    }, [allDocumentData?.data]);

    const checkCompileQuoteCredit = async (toCompileBomIds: number[]) => {
        const resp = await checkCreditBeforeCompilation({
            documentType,
            payload: {
                boms: toCompileBomIds
            }
        });
        if (!resp.data.has_credits) {
            setCreditCheckModalData({ ...creditCheckModalData, isOpen: true, credits_to_deduct: resp?.data.credits_to_deduct, auto_upgrade: resp?.data.auto_upgrade });
            throw Error();
        }
    };

    const handleCreateQuote = useCallback(
        async (notes: Record<string, string> | null) => {
            try {
                if (!selectedBoms.length) {
                    return;
                }
                setBomsNote(notes);
                await checkCompileQuoteCredit(selectedBoms);
                await getCompileTime();
                setEditNotes(false);
                setShowCompileLoader(true);
                const mutationsPromiseArr: Array<Promise<AxiosResponse>> = [];
                const orderedNotes = getOrderedNotes(notes, bomIds);
                for (const item of orderedNotes) {
                    mutationsPromiseArr.push(
                        onMutation.mutateAsync({
                            bomId: item.bomId,
                            documentType: documentType,
                            version_notes: item.notes,
                            enable_cednet_format: enable_cednet_format,
                            enable_epicor_format: enable_epicor_format
                        })
                    );
                }
                const response = await Promise.allSettled(mutationsPromiseArr);
                const successfulyCompiledBoms: number[] = [];
                let errorBomsCount = 0;
                response.forEach((result) => {
                    if (result.status === 'fulfilled') {
                        if (isQuoteDocument) {
                            successfulyCompiledBoms.push(result.value.data.bom);
                        } else {
                            successfulyCompiledBoms.push(result.value.data.id);
                        }
                    } else {
                        errorBomsCount++;
                    }
                });

                if (!successfulyCompiledBoms.length) {
                    return;
                }

                if (isQuoteDocument) {
                    navigate(`../../?tab=finalDocuments&compiledBomIds=${encodeURIComponent(JSON.stringify(successfulyCompiledBoms))}&errorWhileCompileBomCount=${errorBomsCount}`);
                } else {
                    navigate(`../share/${documentType}/${successfulyCompiledBoms[0]}`);
                }
                invalidateQueries();
            } catch (error) {
                /* empty */
            } finally {
                setShowCompileLoader(false);
            }
        },
        [selectedBoms]
    );

    const onClickHandler = () => {
        if (isQuoteDocument) {
            trackIntercomEvent('Create_Quote');
        }
        if (showNotes || isQuoteDocument) {
            setEditNotes(true);
        } else {
            handleCreateQuote({ [bomId]: '' });
        }
    };

    const handlePreviewQuote = async () => {
        await refetchCompileQuoteTimeForQuote();
        setShowPreviewLoader(true);
        await refetchPreviewQuote();
        setOpenPreviewModal(true);
        setShowPreviewLoader(false);
    };

    const closePreviewQuoteModal = () => {
        setOpenPreviewModal(false);
    };

    return (
        <>
            <Box width={'100%'} px={6} pt={6} pb={isQuoteDocument ? '14px' : 6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                <CreateNewVersion
                    open={editNotes}
                    onCloseClick={() => setEditNotes(false)}
                    title={isQuoteDocument ? QUOTE_TITLE_FOR_VERSION_NOTE : DEFAULT_TITLE_FOR_VERSION_NOTE}
                    isLoading={isCompileTimeFetching || isCreditCheckLoading}
                    onSubmit={handleCreateQuote}
                    bomIds={bomIds}
                    updateSelectedBoms={updateSelectedBoms}
                    selectedBoms={selectedBoms}
                    footerHelperText="One or more BOMs must be selected."
                    modalSubtitle={isQuoteDocument ? QUOTE_SUBTITLE_FOR_VERSION_NOTE : DEFAULT_SUB_TITLE_FOR_VERSION_NOTE}
                />

                <AutosearchLoaderDialog
                    title="Preview Generation"
                    subTitle="We are preparing this document for your review."
                    open={showPreviewLoader}
                    loadTime={aggregatedCompileTimeForQuote}
                    helperText="The most epic quote ever made! So far..."
                    footerText={`of ${compileLabels[documentType as keyof ICompileLabels]} Compiled`}
                />

                <AutosearchLoaderDialog
                    title={compileLoaderTitle}
                    subTitle={`  We are currently compiling all of the details in your ${compileLabels[documentType as keyof ICompileLabels]}${isQuoteDocument ? '(s)' : ''}`}
                    open={showCompileLoader}
                    loadTime={aggregatedCompileTime}
                    helperText="Sit tight while we make the magic happen..."
                    footerText={`of ${compileLabels[documentType as keyof ICompileLabels]} Compiled`}
                />

                <CompileHeader
                    isDisabled={onMutation.isLoading || isBomIdsLoading}
                    isLoading={onMutation.isLoading || isCreditCheckLoading || isCompileTimeFetching || isBomIdsLoading}
                    action={onClickHandler}
                    documentType={documentType as keyof ICompileLabels}
                    onPreviewQuotesClick={handlePreviewQuote}
                    openPreviewModal={openPreviewModal}
                    isPreviewQuoteLoading={isCompileTimeForQuoteFetching || isBomIdsLoading}
                    onPreviewQuoteModalClose={closePreviewQuoteModal}
                    pdfUrls={pdfUrls}
                />
            </Box>
            <NotEnoughCreditsModal creditCheckModalData={creditCheckModalData} setCreditCheckModalData={setCreditCheckModalData} onCreateQuote={handleCreateQuote} bomNotes={bomsNotes} />
        </>
    );
};

export default Header;
