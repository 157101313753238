import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodyBig, BodyMedium, BodySmall, BodyXS, TransitionsColorPicker, Box, TextField, Switch, Select, IconButton, LockIcon, LockOpenIcon, Tooltip, RichTextEditor, SelectChangeEvent } from '@parspec/pixel';

import { TEXT_STYLE_VALUES } from '../../utils/helper';
import { QuoteTemplateInterface } from '../../../queries/apiTypes';
import { useBranchDetailsQuery } from '../../../queries';

interface GeneralTermsAndConditionsProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: QuoteTemplateInterface;
    handleColorAndFontTypeChange: (value: string, presetName: string, fieldName: string, fieldType: string) => void;
    onLockToggle: (value: boolean) => void;
    onChangeHtml?: (html: string, presetName: string, fieldName: string) => void;
}

const GENERAL_TNC_SECTION_OPTIONS = [
    { sectionLabel: 'closing_notes', data: { label: 'Closing Notes', switchValue: 'closing_notes', textfieldValue: 'closing_notes_text' } },
    { sectionLabel: 'project_notes', data: { label: 'Project Notes', switchValue: 'project_notes', textfieldValue: 'project_notes_text' } },
    { sectionLabel: 'general_tnc', data: { label: 'General Terms & Conditions', switchValue: 'general_tnc', textfieldValue: 'general_tnc_text' } }
];

export const GeneralTermsAndConditions = (props: GeneralTermsAndConditionsProps) => {
    const locationId = useParams();
    const { changeHandler, templateState, handleColorAndFontTypeChange, onLockToggle, onChangeHtml } = props;

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLocked, setIsLocked] = useState(false);

    const { isLoading: isBranchDataLoading } = useBranchDetailsQuery(Number(locationId));

    useEffect(() => {
        if (!isBranchDataLoading) {
            setIsDisabled(false);
        }
    }, [isBranchDataLoading]);

    useEffect(() => {
        if (templateState) {
            setIsLocked(templateState.quote_general_tnc.is_general_tnc_locked);
        }
    }, [templateState]);
    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.quote_general_tnc?.general_tnc_section}
                    onChange={(e) => changeHandler(e, 'quote_general_tnc')}
                    name="general_tnc_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            General Terms & Conditions
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Enter the relevant notes and terms & conditions you would like to pre-fill your quotes with.
            </BodySmall>
            <BodySmall limit={false} color="text.secondary">
                You can edit these details when customizing your quote.
            </BodySmall>

            <Box mt={6}>
                {GENERAL_TNC_SECTION_OPTIONS.map((option) => {
                    return (
                        <Box mt={4} key={option.sectionLabel}>
                            <Box display={'flex'}>
                                <Switch
                                    checked={templateState?.quote_general_tnc[option.data.switchValue]}
                                    onChange={(e) => changeHandler(e, 'quote_general_tnc')}
                                    name={option.data.switchValue}
                                    label={
                                        <BodyMedium fontWeight={500} mr={4}>
                                            {option.data.label}
                                        </BodyMedium>
                                    }
                                />
                                {option.sectionLabel === 'general_tnc' && (
                                    <Tooltip
                                        title={
                                            isLocked
                                                ? 'Terms & Conditions are currently locked and will not be editable during quote generation. However, you can still adjust the state in these settings.'
                                                : 'Terms & Conditions are currently unlocked making them editable during quote generation.'
                                        }
                                        placement={'top'}
                                    >
                                        <Box
                                            display={'flex'}
                                            justifyContent={'center'}
                                            border={'2px solid'}
                                            borderColor={isLocked ? 'tertiary.main' : 'neutral.main'}
                                            bgcolor={isLocked ? 'tertiary.light' : 'neutral.light'}
                                            borderRadius={'6px'}
                                            width={'32px'}
                                            height={'32px'}
                                            mb={2}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    onLockToggle(!isLocked);
                                                    setIsLocked(!isLocked);
                                                }}
                                            >
                                                {isLocked ? <LockIcon color="tertiary"></LockIcon> : <LockOpenIcon color="neutral"></LockOpenIcon>}
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                )}
                            </Box>

                            <Box mt={2} display={'flex'} alignItems="flex-start" width="100%">
                                <Box width={option.sectionLabel === 'closing_notes' ? '85%' : '100%'}>
                                    {option.sectionLabel === 'closing_notes' ? (
                                        <TextField
                                            label="Enter Your Notes Here..."
                                            autoComplete="off"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'quote_general_tnc')}
                                            value={templateState?.quote_general_tnc[option.data.textfieldValue].value}
                                            name={option.data.textfieldValue}
                                            id={option.data.textfieldValue}
                                            multiline
                                            rows={6}
                                            inputProps={{ maxLength: 10000 }}
                                        />
                                    ) : (
                                        <Box width={1} mt="-10px">
                                            {!isBranchDataLoading && (
                                                <RichTextEditor
                                                    isDisable={isDisabled}
                                                    onChange={(html) => onChangeHtml?.(html, 'quote_general_tnc', option.data.textfieldValue)}
                                                    initialHtml={templateState.quote_general_tnc[option.data.textfieldValue]}
                                                    placeHolderText="Enter Your Notes Here..."
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Box>

                                {option.sectionLabel === 'closing_notes' && (
                                    <Box width={'15%'} ml={3}>
                                        <Box>
                                            <Select
                                                label="Text"
                                                name={'closing_notes_text'}
                                                onChange={(e: SelectChangeEvent<unknown>) => handleColorAndFontTypeChange(e.target.value as string, 'quote_general_tnc', 'closing_notes_text', 'type')}
                                                optionLabelKeyname="label"
                                                optionValueKeyname="value"
                                                options={TEXT_STYLE_VALUES}
                                                value={templateState?.quote_general_tnc?.closing_notes_text?.font?.type}
                                            />
                                        </Box>
                                        <Box mt={4}>
                                            <BodyXS fontWeight={500} color={'text.secondary'} mr={4}>
                                                Font Color
                                            </BodyXS>
                                            <Box mt={2}>
                                                <TransitionsColorPicker
                                                    color={templateState?.quote_general_tnc?.closing_notes_text?.font?.color}
                                                    onChange={(color) => handleColorAndFontTypeChange(color.hex, 'quote_general_tnc', 'closing_notes_text', 'color')}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};
