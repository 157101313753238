import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosInstance } from 'axios';

import { BodySmall, Box, ModalHeader, Modal, BodyMedium, Snackbar, Portal } from '@parspec/pixel';

import { axios, reportingAxiosInstance } from './Axios';

import { notifyToSentry } from '../features/shared/SentryErrorLogger';
import { DATASHEET_SELECTED_MESSAGE } from '../features/shared/constants';

const DEAFULT_SNACKBAR_MSG = 'Something went wrong, please try again!';

const DONT_SHOW_SNACKBAR_ON_400_END_POINTS = [
    `/bom/*/submittal_metadata/selected_datasheet/bulk?source=file_upload`,
    `/bom/*/o_m_metadata/selected_drawing/bulk?source=file_upload`,
    `/bom/*/o_m_metadata/selected_warranty/bulk?source=file_upload`,
    `/bom/*/o_m_metadata/selected_installation_guide/bulk?source=file_upload`,
    `/bom/*/o_m_metadata/selected_other_document/bulk?source=file_upload`,
    `/bom/*/submittal_metadata/selected_datasheet/bulk?source=link_upload`,
    `/bom/*/o_m_metadata/selected_drawing/bulk?source=link_upload`,
    `/bom/*/o_m_metadata/selected_warranty/bulk?source=link_upload`,
    `/bom/*/o_m_metadata/selected_installation_guide/bulk?source=link_upload`,
    `/bom/*/o_m_metadata/selected_other_document/bulk?source=link_upload`,
    `/bom/*/product_finder/*/selected_datasheet/bulk?source=link_upload`,
    `/bom/*/product_finder/*/selected_datasheet/bulk?source=file_upload`,
    `/project/*/documents`,
    `/bom/*/uploaded_doc/submittal_package/`,
    '/login/',
    '/signup/',
    '/api/parspec_operating_regions/',
    '/api/login_invitation/'
];

const DONT_SHOW_SNACKBAR_ON_402_END_POINTS = [`/user_info/*/product_finder/`];

const SHOW_ABBREVATION_SBACKBAR_END_POINTS = [
    `bom/`,
    `bom/*/uploaded_doc/schedule/*`,
    `bom/*/uploaded_doc/schedule/*/select_bod`,
    `bom/*/uploaded_doc/submittal_package/*/select_bod`,
    'bom/*/uploaded_doc/submittal_package/*/confirm/existing_bods/'
];

// const SHOW_SNACKBAR_ON_404_ENDPOINTS = [
//     `bom/*/submittal_preset/toc/logo/`,
//     `bom/*/submittal_preset/header_footer/data/logo/`,
//     `bom/*/submittal_preset/cover_page/data/cover_page/logo/`,
//     `bom/*/submittal_preset/cover_page/data/prepared_by/logo/`,
//     `bom/*/submittal_preset/cover_page/data/addressed_to/logo/`,
//     `bom/*/submittal_preset/cover_page/custom/`,
//     `bom/*/submittal_preset/notes_page/custom/`
// ];

const ESCAPE_401_ENDPOINT = ['/api/validate_invitation/'];

const IGNORE_HOUSTON_ARRAY_ON_500_ERRROR_URLS = [`bom/*/quote/document_preview/`, `bom/*/quote/compile_time/`, `bom/*/quote/`, `bom/*/quote_preset/`];

const isCurrentReqUrlExistInEndpointList = (endPointList: string[], reqUrl: string) => {
    return endPointList.some((endPoint) => {
        // convert /api/user/*/ to /api/user//
        const removedStarEndPoint = endPoint.replace(/\*/g, '');

        // convert http//example/api/user/123/ to http//example/api/user//
        const removedDynamicValuesReqUrl = reqUrl.replace(/\d/g, '');

        return removedDynamicValuesReqUrl.includes(removedStarEndPoint) && removedDynamicValuesReqUrl.endsWith(removedStarEndPoint);
    });
};

export const ResponseInterceptor = () => {
    const navigate = useNavigate();
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(DEAFULT_SNACKBAR_MSG);
    const handleDialog = () => {
        setOpenErrorModal((old) => !old);
    };

    const interceptCalls = (instance: AxiosInstance) => {
        instance.interceptors.response.use(
            (response) => {
                const { status, data, request } = response;
                if ((status === 200 || status === 201) && isCurrentReqUrlExistInEndpointList(SHOW_ABBREVATION_SBACKBAR_END_POINTS, request.responseURL)) {
                    if (data?.[0]?.abbreviation_replace_flag || data?.abbreviation_replace_flag || data?.uploaded_doc?.abbreviation_replace_flag) {
                        setShowSnackbar(true);
                        setSnackbarMsg('Abbreviations were replaced with the manufacturer names on file for newly created line items.');
                    }
                    if (request.responseURL.endsWith('/confirm/existing_bods/')) {
                        setShowSnackbar(true);
                        setSnackbarMsg(DATASHEET_SELECTED_MESSAGE);
                    }
                }

                return response;
            },
            (error) => {
                const err = error as AxiosError<{ message: string }>;

                switch (err.response?.status) {
                    case 400: {
                        if (isCurrentReqUrlExistInEndpointList(DONT_SHOW_SNACKBAR_ON_400_END_POINTS, err.request.responseURL)) {
                            break;
                        }

                        const msg = err.response.data.message || DEAFULT_SNACKBAR_MSG;
                        setShowSnackbar(true);
                        setSnackbarMsg(msg);
                        break;
                    }
                    case 404: {
                        if (err.response?.config?.method === 'patch' || err.response?.config?.method === 'put') {
                            const msg_404 = err.response.data.message || DEAFULT_SNACKBAR_MSG;
                            setShowSnackbar(true);
                            setSnackbarMsg(msg_404);
                        }
                        break;
                    }

                    case 401: {
                        if (isCurrentReqUrlExistInEndpointList(ESCAPE_401_ENDPOINT, err.request.responseURL)) {
                            break;
                        }
                        localStorage.clear();
                        navigate('/login');
                        break;
                    }

                    case 402: {
                        if (isCurrentReqUrlExistInEndpointList(DONT_SHOW_SNACKBAR_ON_402_END_POINTS, err.request.responseURL)) {
                            break;
                        }
                        const msg_402 = err.response.data.message || DEAFULT_SNACKBAR_MSG;
                        setShowSnackbar(true);
                        setSnackbarMsg(msg_402);
                        break;
                    }

                    case 403: {
                        navigate('/unauthorised');
                        break;
                    }

                    case 500: {
                        if (isCurrentReqUrlExistInEndpointList(IGNORE_HOUSTON_ARRAY_ON_500_ERRROR_URLS, err.request.responseURL)) {
                            const slackMessage = `URL: ${err.request.responseURL}\nMethod: ${err.config?.method}\nBody: ${err.config?.data}\nStatus Code: ${err.response?.status}\nRequest Id: ${err.config?.headers['X-Request-Id']}\nMessage: ${err.message}\nResponse: ${err.response?.data}\n\n`;
                            notifyToSentry(slackMessage, true);
                            break;
                        }

                        setOpenErrorModal(true);
                        const slackMessage = `URL: ${err.request.responseURL}\nMethod: ${err.config?.method}\nBody: ${err.config?.data}\nStatus Code: ${err.response?.status}\nRequest Id: ${err.config?.headers['X-Request-Id']}\nMessage: ${err.message}\nResponse: ${err.response?.data}\n\n`;
                        notifyToSentry(slackMessage, true);

                        break;
                    }

                    default:
                        // setShowSnackbar(true);
                        break;
                }
                return Promise.reject(error);
            }
        );
    };

    useEffect(() => {
        interceptCalls(axios);
        interceptCalls(reportingAxiosInstance);
    }, []);

    return (
        <>
            {openErrorModal && (
                <Modal open={openErrorModal} header={<ModalHeader title="Houston... we’ve got a problem." onClose={handleDialog} />}>
                    <Box width="418px">
                        <Box margin={'8px 0px 24px'} textAlign={'center'}>
                            <img src="assets/images/error/astronaut.svg" alt="" />
                        </Box>
                        <BodyMedium
                            mb={4}
                            height="120px"
                            sx={{
                                div: {
                                    WebkitLineClamp: 'unset !important'
                                }
                            }}
                        >
                            Looks like an unforeseen issue. Our support and engineering team will be notified to resolve this issue as soon as possible.
                            <br /> <br />
                            In the meantime here are some troubleshooting tips:
                        </BodyMedium>
                        <BodySmall
                            p={2}
                            color="primary.main"
                            height={'80px'}
                            sx={{
                                div: {
                                    WebkitLineClamp: 'unset !important'
                                }
                            }}
                        >
                            1. Trigger a hard refresh (Ctrl + Shift + R)
                            <br />
                            2. Open Parspec in a Google Incognito tab
                            <br />
                            3. If the issue persists, message us in the chatbot
                            <br />
                        </BodySmall>
                    </Box>
                </Modal>
            )}
            <Portal>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    message={snackbarMsg}
                    onClose={() => {
                        setShowSnackbar(false);
                    }}
                />
            </Portal>
        </>
    );
};
