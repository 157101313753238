import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Snackbar, Tabs } from '@parspec/pixel';

import { ISnackbarDataState, IUsersModalState } from './UserDataTable/userDataTable.types';
import { UsersModal } from './Modal';
import { useGetUserProfileInfoQuery } from '../MyProfile/queries';
import { TAB_OPTIONS } from './utils/UserManagementTabs';

const Header = ({ tabDataLengths, isApiDataLoaded }: { tabDataLengths: number[]; isApiDataLoaded: boolean }) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const inviteUsers = params?.get('inviteUsers');
    const tabValue = params?.get('tab');
    const [usersModalState, setUsersModalState] = useState<IUsersModalState>({
        isOpen: Boolean(inviteUsers),
        isEdit: false,
        data: {}
    });
    const [snackbarData, setSnackbarData] = useState<ISnackbarDataState>({
        isActive: false,
        message: ''
    });

    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();

    useEffect(() => {
        if (isAdminLoading) return;

        if (!tabValue) {
            handleTabChange('active');
        }
    }, [isAdminLoading, tabValue]);

    const handleTabChange = (tabValue: string) => {
        navigate({
            search: new URLSearchParams({ tab: tabValue }).toString()
        });
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Tabs onChange={handleTabChange} options={TAB_OPTIONS(isApiDataLoaded, tabDataLengths)} selectedTab={tabValue || ''} />
                {userProfile?.data?.role === 'admin' && (
                    <Button
                        onClick={() => {
                            setUsersModalState((prev: any) => ({
                                ...prev,
                                isOpen: true,
                                isEdit: false,
                                data: {}
                            }));
                        }}
                    >
                        Invite Users
                    </Button>
                )}
            </Box>
            {usersModalState?.isOpen && (
                <>
                    <UsersModal
                        isOpen={usersModalState?.isOpen}
                        setIsOpen={setUsersModalState}
                        rowData={usersModalState?.data}
                        isEdit={usersModalState?.isEdit}
                        snackbarData={snackbarData}
                        setSnackbarData={setSnackbarData}
                    />
                </>
            )}
            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={1000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </>
    );
};

export default Header;
