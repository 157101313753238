import { FC } from 'react';

import { Avatar, Tooltip } from '@parspec/pixel';

import { AVATAR_COLORS, UNASSIGNED_AVATAR_COLOR } from '../constants';
import { getOwnerInitials } from '../utils';

interface UserAvatarProps {
    userName: string;
    showTooltip?: boolean;
    userId: number;
}

const UserAvatar: FC<UserAvatarProps> = ({ userName, showTooltip, userId }) => {
    return (
        <Tooltip title={showTooltip ? userName : ''}>
            <Avatar sx={{ width: 24, height: 24, bgcolor: userId === -1 ? UNASSIGNED_AVATAR_COLOR : AVATAR_COLORS[(userId || 0) % 7], fontSize: 10 }}>{getOwnerInitials(userName)}</Avatar>
        </Tooltip>
    );
};

export default UserAvatar;
