import { useState, useEffect } from 'react';

import { useQuotePresetMutation, invalidateQuoteHeaderFooterInGeneralQuery } from '../queries';
import { getCurrentTimezoneName } from '../../shared/utils';
import { useGetAllBomIds } from '../../shared/hooks';

export const useBatchCreatePresetMutation = (projectId: number) => {
    const [isInitiateApiCalls, setIsInitiateApiCalls] = useState(false);

    const { bomIds } = useGetAllBomIds(projectId);
    const { mutateAsync } = useQuotePresetMutation();

    useEffect(() => {
        async function createPreset() {
            try {
                if (bomIds.length && !isInitiateApiCalls) {
                    const timezone = getCurrentTimezoneName();
                    const mutationsPromiseArr = bomIds.map((id) =>
                        mutateAsync({
                            bomId: String(id),
                            timezone
                        })
                    );

                    await Promise.all(mutationsPromiseArr);
                    setIsInitiateApiCalls(true);
                    invalidateQuoteHeaderFooterInGeneralQuery();
                }
            } catch (error) {
                /* empty */
            }
        }

        createPreset();
    }, [bomIds, isInitiateApiCalls]);

    return isInitiateApiCalls;
};
