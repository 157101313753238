export const bod = [
    {
        id: 386082,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        section: 2
    },
    {
        id: 3,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        section: 2
    },
    {
        id: 2,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        section: 2
    },
    {
        id: 387363,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        section: 2
    },
    {
        id: 390011,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        substitute_section: 4
    },
    {
        id: 387363,
        created_at: '2023-08-29T11:10:48.822721Z',
        updated_at: '2023-08-29T11:10:48.822753Z',
        is_deleted: false,
        is_hidden: false,
        order: 1.0,
        category: '',
        qty: null,
        manufacturer: 'AXIS',
        model_number: 'LED',
        description: '',
        specification: 'Basis of Design',
        bom: 21300,
        submittal_metadata: 386094,
        o_m_metadata: 386094,
        quote_metadata: 386082,
        substitute_section: 5
    }
];

export const sections = [
    {
        name: 'Section 1',
        id: 1,
        is_subtotal_overriden: true
    },
    {
        name: 'Section 2',
        id: 2,
        is_subtotal_overriden: true
    },
    {
        name: 'Section 3',
        id: 3,
        is_subtotal_overriden: true
    }
];

export const optionSections = [
    {
        name: 'Option Section 1',
        id: 4,
        is_subtotal_overriden: true
    },
    {
        name: 'Option Section 2',
        id: 5,
        is_subtotal_overriden: true
    }
];

export const bomStatsData = {
    product: 115,
    hidden_product: 15,
    manufacturer: 25
};
