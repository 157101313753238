import { useRef, useState, useMemo, useEffect } from 'react';

import { Box, Modal, ModalFooter, ModalHeader, AgGridTable, BodySmall, ColDef } from '@parspec/pixel';

import { getRowId } from './utils';
import { DEFAULT_COL_DEF } from '../../constants';

interface ManageColumnsPropsT {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackbarData: React.Dispatch<React.SetStateAction<string>>;
    allColumnOptions: { field: string; label: string }[];
    onColumnSelection: (data: string[]) => void;
    selectedColumnsData: string[] | null;
    isSelectedColumnsMutationLoading: boolean;
    isSelectedColumnsQueryFetching: boolean;
}

export const ManageColumnsPopup = ({
    isOpen,
    setIsOpen,
    allColumnOptions,
    setSnackbarData,
    onColumnSelection,
    selectedColumnsData,
    isSelectedColumnsMutationLoading,
    isSelectedColumnsQueryFetching
}: ManageColumnsPropsT) => {
    const [searchText, setSearchText] = useState('');
    const [rowDataOptions, setRowDataOptions] = useState<{ field: string; label: string }[]>([]);

    const tableRef = useRef<any>();

    useEffect(() => {
        if (isSelectedColumnsQueryFetching) {
            return;
        }

        if (selectedColumnsData && selectedColumnsData.length > 0) {
            const allColumnOptionsObj = allColumnOptions.reduce((acc, curr) => {
                acc[curr.field] = curr;
                return acc;
            }, {} as { [key: string]: { field: string; label: string } });
            const selectedColumnDataSet = new Set(selectedColumnsData);
            const resultData = selectedColumnsData
                .map((field) => allColumnOptionsObj[field])
                .filter((item) => item !== undefined)
                .concat(allColumnOptions.filter((item) => !selectedColumnDataSet.has(item.field)));

            setRowDataOptions(resultData);
        } else {
            setRowDataOptions(allColumnOptions);
        }
    }, [allColumnOptions, selectedColumnsData]);

    function onGridReady() {
        if ((selectedColumnsData || [])?.length === 0) {
            tableRef.current.api.forEachNode((node: any) => {
                return node.setSelected(true);
            });
        } else {
            tableRef.current.api.forEachNode((node: any) => {
                if (selectedColumnsData?.includes(node.data.field)) {
                    node.setSelected(true);
                } else {
                    node.setSelected(false);
                }
            });
        }
    }

    const closeModalHandler = () => {
        setIsOpen(() => false);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const columnDefs = useMemo<ColDef[]>(() => {
        const columns = [
            { rowDrag: true, width: 10, maxWidth: 50, resizable: false, editable: false },
            { headerCheckboxSelection: true, checkboxSelection: true, width: 50, maxWidth: 50, resizable: false },
            {
                field: 'label',
                minWidth: 100,
                headerName: 'Column Name'
            }
        ];

        return columns;
    }, [allColumnOptions]);

    const onAccept = () => {
        const rowData: any[] = [];
        tableRef.current.api.forEachNode((node: any) => {
            if (node.selected) {
                rowData.push(node.data);
            }
        });

        if (rowData.length === 0) {
            return setSnackbarData('Please select at least one column');
        }

        const finalRowData = rowData.map((el: { field: string; label: string }) => el.field);
        onColumnSelection(finalRowData);
    };

    return (
        <Box>
            <Modal
                open={isOpen}
                footer={<ModalFooter isLoading={isSelectedColumnsMutationLoading} onAccept={onAccept} onReject={closeModalHandler} />}
                header={<ModalHeader onClose={closeModalHandler} title={'Manage Columns'}></ModalHeader>}
                onClose={closeModalHandler}
            >
                <Box width={'400px'}>
                    <Box mb={6}>
                        <BodySmall color={'text.secondary'} limit={false}>
                            Choose the columns to display, and the order in which they are to appear.{' '}
                        </BodySmall>
                    </Box>

                    <Box height={'400px'} width={'100%'}>
                        <AgGridTable
                            ref={tableRef}
                            getRowId={getRowId}
                            isLoading={false}
                            rowHeight={40}
                            showToolbarPanel
                            toolBarPanelOptions={['search']}
                            rowData={rowDataOptions}
                            defaultColDef={DEFAULT_COL_DEF}
                            columnDefs={columnDefs}
                            suppressContextMenu={true}
                            suppressMenuHide={true}
                            onTextSearch={handleSearch}
                            quickFilterText={searchText}
                            gridOptions={{ rowDragManaged: true, rowSelection: 'multiple', suppressRowClickSelection: true, suppressDragLeaveHidesColumns: true }}
                            onGridReady={onGridReady}
                            searchBarFullWidth={true}
                        />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};
