import { rest } from 'msw';

import { getMfgListForId, mockGetListOfMfgListData } from './manufacturerList';

export const settingsMockHandlers = [
    rest.get('*/company_group/manufacturer_list/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(mockGetListOfMfgListData));
    }),
    rest.get('*/company_group/manufacturer_list/:id/', (req, res, ctx) => {
        const { id } = req.params;
        return res(ctx.delay(1000), ctx.json(getMfgListForId(Number(id))));
    })
];
