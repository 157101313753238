import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ListItem, List } from '@mui/material';

import { BodySmall, Box, Modal, ModalFooter, ModalHeader, StatusSelect, TextField, Tooltip, SelectChangeEvent } from '@parspec/pixel';

import LotSelect from '../../lotSelect';

import { PRICING_TABLE_ENUM, priceRelatedCols, LEAD_TIME_UNITS } from '../../shared/constants';
import { updateMsg } from 'src/features/shared/constants';

import { getUpdatedPricingInfoForChange } from '../../shared/utils';
import { convertToCents } from '../../../../shared/utils/utils';
import { useNinetyViewportHeight } from '../../../shared/hooks';

import { BulkEditPayload } from './queries/apiTypes';
import { useBulkEditPatchMutation } from './queries';
import OptionsDropDown from '../../../shared/OptionsDropDown';
import {
    BULKEDIT_POPUP_TEXT,
    BULK_EDIT_ASSIGN_LOT_WARNING,
    BULK_EDIT_COST_WARNING,
    BULK_EDIT_KIT_WARNING,
    BULK_EDIT_LOT_WARNING,
    BULK_EDIT_OPTION_WARNING,
    BULK_EDIT_RESET_LOT_WARNING,
    LOT_SELECTED_TOOLTIP_TEXT
} from '../constants';
import { Template as LeadTimeUnitTemplate, options as leadTimeUnitOptions } from '../../shared/LeadTimeUnitTemplate';
import { SPECIFICATION_VALUES, specificationOptions } from 'src/features/shared/utils/constants';

interface IBulkEditProps {
    open: boolean;
    onClose: () => void;
    isOptionsBulkEdit?: boolean;
    selectedItems: Array<any>;
    handleClearSelection: () => void;
    setSnackbarOpen: (message: string) => void;
    onReset?: () => void;
    lotMap?: Record<number, number | null>;
}

type IBulkEditTableDataType = {
    qty: string | null;
    manufacturer: string | null;
    specification: SPECIFICATION_VALUES | '';
    lotId: string;
    product_to_replace: string | null;
    cost: number | string | null;
    discount: string | null;
    discountedCost: string | null;
    margin: string | null;
    sellPrice: string | null;
    leadTimeUnit: string | null;
    leadTimeValue: number | null;
    quoteNo: string | null;
    externalNotes: string | null;
    internalComments: string | null;
};

const BulkEditModal = ({
    open,
    onClose,
    selectedItems,
    handleClearSelection,
    isOptionsBulkEdit: optionsEdit = false,
    setSnackbarOpen,
    onReset,
    lotMap = {} as Record<number, number | null>
}: IBulkEditProps) => {
    const { bomId } = useParams();

    const [formValues, updateFormValue] = useState<IBulkEditTableDataType>({
        qty: null,
        manufacturer: null,
        lotId: '',
        specification: '',
        product_to_replace: null,
        cost: null,
        discount: null,
        discountedCost: null,
        margin: null,
        sellPrice: null,
        leadTimeUnit: null,
        leadTimeValue: null,
        quoteNo: null,
        externalNotes: null,
        internalComments: null
    });
    const [selectedBods, setSelectedBodsInfo] = useState(selectedItems);
    const [nullCostFound, setNullCostFound] = useState(false);
    const [lotExists, setLotExists] = useState(false);
    const [lotSelected, setLotSelected] = useState(false);
    const [resetLotWarning, setResetLotWarning] = useState(false);
    const [hasOption, setHasOption] = useState(false);
    const [isPartOfKit, setIsPartOfKit] = useState(false);
    const [isLeadTimeValueDisabled, setIsLeadTimeValueDisabled] = useState(false);

    const { mutateAsync: patchBulkEdit, isLoading } = useBulkEditPatchMutation();

    const modalMaxHeight = useNinetyViewportHeight();
    /** here we are subtracting sum of heights of header and footer(120) from total modal height to fetch content height */
    const boxMaxHeight = modalMaxHeight - 120;
    /** here we are subtracting height of text used above form in content for get only form's height */
    const formHeight = boxMaxHeight - 64;

    const handleModalSave = async () => {
        const payload: Array<BulkEditPayload> = [];
        selectedBods.forEach((item: any) => {
            const {
                id,
                section,
                qty,
                manufacturer,
                lotId,
                cost,
                discount,
                discountedCost,
                margin,
                sellPrice,
                leadTimeUnit,
                leadTimeValue,
                quoteNo,
                product_to_replace,
                externalNotes,
                specification,
                internalComments,
                substitute_section
            } = item;

            let obj: BulkEditPayload = {
                bod_data: {
                    bod_id: id,
                    qty: parseToNumber(String(qty)),
                    manufacturer: trimAndConvertToUpperCase(manufacturer),
                    specification: specification
                },
                metadata: {
                    cost_cents: cost !== null && String(cost).length > 0 ? convertToCents(cost) : null,
                    discount_percentage: discount,
                    discounted_cost_cents: discountedCost !== null && String(discountedCost).length > 0 ? convertToCents(discountedCost) : null,
                    margin_percentage: margin,
                    sell_price_cents: sellPrice !== null && String(sellPrice).length > 0 ? convertToCents(sellPrice) : null,
                    lead_time_unit: leadTimeUnit,
                    lead_time_value: parseToNumber(leadTimeValue),
                    source_quote_number: trimAndConvertToUpperCase(quoteNo),
                    notes: trimAndConvertToUpperCase(externalNotes || ''),
                    internal_notes: trimAndConvertToUpperCase(internalComments || '')
                }
            };

            if ((obj.metadata.lead_time_unit === null || obj.metadata.lead_time_unit === '-') && obj.metadata.lead_time_value) {
                obj.metadata.lead_time_unit = LEAD_TIME_UNITS.WEEKS.toLowerCase();
            }

            if (optionsEdit) {
                obj = { ...obj, bod_data: { ...obj.bod_data, substitute_section_id: substitute_section, product_to_replace: product_to_replace } };
            } else {
                obj = { ...obj, bod_data: { ...obj.bod_data, section_id: section }, metadata: { ...obj.metadata, lot_id: lotId !== '' ? lotId : null } };
            }

            payload.push(obj);
        });

        await patchBulkEdit({ bomId: Number(bomId), payload });

        onReset && onReset();
        handleClearSelection();
        setSnackbarOpen(updateMsg);
        onClose();
    };

    const trimAndConvertToUpperCase = (str: string) => {
        if (!str) return null;

        return str.length > 0 ? str.trim().toUpperCase() : null;
    };

    const parseToNumber = (str: string) => {
        if (!str) return null;

        return String(str).length > 0 ? Number(str) : null;
    };

    const areAllPricingFieldEmpty = (name: string, value: string) => {
        const { cost, discount, discountedCost, margin, sellPrice } = { ...formValues, [name]: value } as IBulkEditTableDataType;
        return !cost && !discount && !discountedCost && !margin && !sellPrice;
    };

    const handleformChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown> | React.SyntheticEvent<Element, Event>) => {
            const target = event.target as HTMLInputElement;
            let value = target?.value;
            const name = target?.name;

            if (priceRelatedCols.has(name as PRICING_TABLE_ENUM)) {
                const newValue = value.replace(/[^0-9.]/g, '');
                value = newValue.replace(/(\..*)\./g, '$1');
            } else if (name === PRICING_TABLE_ENUM.QTY || name === PRICING_TABLE_ENUM.LEAD_TIME_VALUE) {
                value = value.replace(/[^0-9]/g, '');
            }
            if (name === PRICING_TABLE_ENUM.LOT && value !== '') {
                setNullCostFound(false);
                setLotExists(false);
                updateFormValue({
                    ...formValues,
                    [name]: value,
                    [PRICING_TABLE_ENUM.COST]: null,
                    [PRICING_TABLE_ENUM.DISCOUNT]: null,
                    [PRICING_TABLE_ENUM.DISCOUNT_COST]: null,
                    [PRICING_TABLE_ENUM.MARGIN]: null,
                    [PRICING_TABLE_ENUM.SELL_PRICE]: null
                });
            } else {
                updateFormValue({ ...formValues, [name]: value });
            }

            if (name === PRICING_TABLE_ENUM.COST) {
                if (value?.length > 0) setNullCostFound(false);
            }

            const { qty: formQty, manufacturer, lotId, product_to_replace, leadTimeUnit, leadTimeValue, quoteNo, externalNotes } = formValues;

            if (priceRelatedCols.has(name as PRICING_TABLE_ENUM)) {
                const updatedValues = selectedBods.map((item: any, index: number) => {
                    if (selectedItems?.[index].lotId && !areAllPricingFieldEmpty(name, value) && Number(lotId) !== 0) {
                        setLotExists(true);
                    } else if (!value && value !== '0') setLotExists(false);

                    if (selectedItems?.[index].is_kit && !areAllPricingFieldEmpty(name, value)) {
                        setIsPartOfKit(true);
                    } else if (!value && value !== '0') setIsPartOfKit(false);

                    if (selectedItems?.[index].lotId || selectedItems?.[index].is_kit) {
                        return {
                            ...selectedItems[index],
                            qty: formQty ? formQty : selectedItems[index].qty,
                            manufacturer: manufacturer ? manufacturer : selectedItems[index].manufacturer,
                            lotId: lotId !== '' ? lotId : selectedItems[index].lotId,
                            product_to_replace: product_to_replace ? product_to_replace : selectedItems[index].product_to_replace,
                            leadTimeUnit: leadTimeUnit ? leadTimeUnit : selectedItems[index].leadTimeUnit,
                            leadTimeValue: leadTimeValue ? leadTimeValue : selectedItems[index].leadTimeValue,
                            quoteNo: quoteNo ? quoteNo : selectedItems[index].quoteNo,
                            externalNotes: externalNotes ? externalNotes : selectedItems[index].externalNotes,
                            internalComments: internalComments ? internalComments : selectedItems[index].internalComments,
                            specification: specification ? specification : selectedItems[index].specification
                        };
                    }

                    if (name !== PRICING_TABLE_ENUM.COST && (item.cost === null || item.cost == 0) && !areAllPricingFieldEmpty(name, value)) {
                        setNullCostFound(true);
                        return { ...item };
                    } else if (name !== PRICING_TABLE_ENUM.COST && !value.length && value !== '0') setNullCostFound(false);

                    item = { ...item, [name]: value.length === 0 ? selectedItems?.[index][name] : value };

                    if (
                        name === PRICING_TABLE_ENUM.COST &&
                        (item.cost === null || item.cost == 0) &&
                        (formValues.discount?.length || formValues.discountedCost?.length || formValues.margin?.length || formValues.sellPrice?.length)
                    ) {
                        setNullCostFound(true);
                    }

                    const { cost, discount, discountedCost, margin, sellPrice, qty } = item;

                    const priceInfo = {
                        cost: parseToNumber(cost),
                        discount: parseToNumber(discount),
                        discountedCost: parseToNumber(discountedCost),
                        margin: parseToNumber(margin),
                        sellPrice: parseToNumber(sellPrice),
                        qty: parseToNumber(qty),
                        extendedPrice: Number(qty) * Number(sellPrice)
                    };

                    const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange(priceInfo, name as PRICING_TABLE_ENUM);
                    return { ...item, ...pricingRowInfoToUpdate };
                });

                setSelectedBodsInfo(updatedValues);
            } else {
                const updatedValues = selectedBods.map((item: any, index: number) => {
                    if (name === PRICING_TABLE_ENUM.LOT) {
                        if (Number(value) !== 0 && value !== '' && selectedItems?.[index].cost !== null) setLotSelected(true);
                        else if (Number(value) === 0 || value === '') {
                            setLotSelected(false);
                            setLotExists(false);
                        }

                        if (Number(value) === 0 && selectedItems?.[index].lotId) {
                            setResetLotWarning(true);
                        }

                        if (Number(value) !== 0 && value !== '' && item.hasOptions) {
                            setLotSelected(false);
                            setHasOption(true);
                            if (selectedBods.length === 1) setLotSelected(false);
                            return {
                                ...selectedItems?.[index],
                                qty: formQty ? formQty : selectedItems[index].qty,
                                manufacturer: manufacturer ? manufacturer : selectedItems[index].manufacturer,
                                lotId: lotId !== '' ? lotId : selectedItems[index].lotId,
                                product_to_replace: product_to_replace ? product_to_replace : selectedItems[index].product_to_replace,
                                leadTimeUnit: leadTimeUnit ? leadTimeUnit : selectedItems[index].leadTimeUnit,
                                leadTimeValue: leadTimeValue ? leadTimeValue : selectedItems[index].leadTimeValue,
                                quoteNo: quoteNo ? quoteNo : selectedItems[index].quoteNo,
                                externalNotes: externalNotes ? externalNotes : selectedItems[index].externalNotes,
                                internalComments: internalComments ? internalComments : selectedItems[index].internalComments,
                                specification: specification ? specification : selectedItems[index].specification
                            };
                        } else {
                            setHasOption(false);
                        }
                    }

                    if (name === PRICING_TABLE_ENUM.LEAD_TIME_UNIT) {
                        if (value === '-') {
                            item = { ...item, leadTimeUnit: null, leadTimeValue: null };
                            updateFormValue({ ...formValues, leadTimeUnit: null, leadTimeValue: null });
                        }

                        if (value === LEAD_TIME_UNITS.IN_STOCK.toLowerCase()) {
                            item = { ...item, leadTimeValue: -1 };
                            updateFormValue({ ...formValues, leadTimeValue: null, leadTimeUnit: LEAD_TIME_UNITS.IN_STOCK.toLowerCase() });
                            setIsLeadTimeValueDisabled(true);
                        } else {
                            setIsLeadTimeValueDisabled(false);
                        }
                    }

                    return name === PRICING_TABLE_ENUM.LOT
                        ? value !== ''
                            ? {
                                  ...item,
                                  [name]: typeof value === 'string' ? value.trim() : value,
                                  [PRICING_TABLE_ENUM.COST]: null,
                                  [PRICING_TABLE_ENUM.DISCOUNT]: null,
                                  [PRICING_TABLE_ENUM.DISCOUNT_COST]: null,
                                  [PRICING_TABLE_ENUM.MARGIN]: null,
                                  [PRICING_TABLE_ENUM.SELL_PRICE]: null
                              }
                            : {
                                  ...item,
                                  [name]: null,
                                  [PRICING_TABLE_ENUM.COST]: selectedItems[index].cost,
                                  [PRICING_TABLE_ENUM.DISCOUNT]: selectedItems[index].discount,
                                  [PRICING_TABLE_ENUM.DISCOUNT_COST]: selectedItems[index].discountedCost,
                                  [PRICING_TABLE_ENUM.MARGIN]: selectedItems[index].margin,
                                  [PRICING_TABLE_ENUM.SELL_PRICE]: selectedItems[index].sellPrice
                              }
                        : { ...item, [name]: typeof value === 'string' ? value.trim() : value };
                });
                setSelectedBodsInfo(updatedValues);
            }
        },
        [formValues]
    );

    const { qty, manufacturer, lotId, product_to_replace, cost, discount, discountedCost, margin, sellPrice, leadTimeUnit, leadTimeValue, quoteNo, externalNotes, specification, internalComments } =
        formValues;

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Bulk Edit" onClose={onClose} />}
            footer={<ModalFooter onAccept={handleModalSave} onReject={onClose} continueButtonLabel="Save" isLoading={isLoading} />}
        >
            <Box width={550} maxHeight={boxMaxHeight}>
                <BodySmall limit={false} pt={3} pb={4}>
                    {BULKEDIT_POPUP_TEXT}
                </BodySmall>
                <Box maxHeight={formHeight} overflow="scroll">
                    {(nullCostFound || lotExists || lotSelected || hasOption || isPartOfKit || resetLotWarning) && (
                        <List sx={{ listStyleType: 'disc', pl: 5 }}>
                            <BodySmall color={'warning.main'} limit={false} pb={2}>
                                {nullCostFound && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_COST_WARNING}
                                    </ListItem>
                                )}
                                {lotExists && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_LOT_WARNING}
                                    </ListItem>
                                )}
                                {lotSelected && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_ASSIGN_LOT_WARNING}
                                    </ListItem>
                                )}
                                {hasOption && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_OPTION_WARNING}
                                    </ListItem>
                                )}
                                {isPartOfKit && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_KIT_WARNING}
                                    </ListItem>
                                )}
                                {resetLotWarning && (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        {BULK_EDIT_RESET_LOT_WARNING}
                                    </ListItem>
                                )}
                            </BodySmall>
                        </List>
                    )}
                    <Box display="flex" pt={2}>
                        <Box width="100px">
                            <TextField value={qty || ''} name={PRICING_TABLE_ENUM.QTY} onChange={handleformChange} label="Qty" inputProps={{ maxLength: 10 }} />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="300px">
                            <TextField value={manufacturer || ''} name={PRICING_TABLE_ENUM.MANUFACTURER} onChange={handleformChange} label="Manufacturer" />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="300px">
                            <StatusSelect
                                options={specificationOptions}
                                value={specification}
                                name="specification"
                                onChange={handleformChange}
                                size="small"
                                label="Specification"
                                selectedOptionFontSize="14px"
                            />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        {optionsEdit ? (
                            <Box width="300px">
                                <OptionsDropDown lotMap={lotMap} bomId={bomId || ''} name="product_to_replace" value={product_to_replace || ''} onChange={handleformChange} />
                            </Box>
                        ) : (
                            <Box width="200px">
                                <LotSelect value={lotId} fieldName={PRICING_TABLE_ENUM.LOT} onChange={handleformChange} isBulkEdit={true} />
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" pt={4}>
                        <Tooltip title={lotId !== '0' && lotId !== '' ? <p style={{ whiteSpace: 'pre-line' }}>{LOT_SELECTED_TOOLTIP_TEXT}</p> : ''} placement="top">
                            <Box width="120px">
                                <TextField
                                    value={cost || ''}
                                    name={PRICING_TABLE_ENUM.COST}
                                    placeholder="Cost"
                                    onChange={handleformChange}
                                    disabled={Number(lotId) !== 0 && lotId !== ''}
                                    label="Cost"
                                    startIcon="$"
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" pt={4} width="330px">
                        <Tooltip title={lotId !== '0' && lotId !== '' ? <p style={{ whiteSpace: 'pre-line' }}>{LOT_SELECTED_TOOLTIP_TEXT}</p> : ''} placement="top">
                            <Box width="152px">
                                <TextField
                                    value={discount || ''}
                                    name={PRICING_TABLE_ENUM.DISCOUNT}
                                    onChange={handleformChange}
                                    label="Discount (%)"
                                    disabled={(discountedCost && discountedCost.length > 0) || (Number(lotId) !== 0 && lotId !== '')}
                                />
                            </Box>
                        </Tooltip>
                        <BodySmall>or</BodySmall>
                        <Tooltip title={lotId !== '0' && lotId !== '' ? <p style={{ whiteSpace: 'pre-line' }}>{LOT_SELECTED_TOOLTIP_TEXT}</p> : ''} placement="top">
                            <Box width="152px">
                                <TextField
                                    value={discountedCost || ''}
                                    name={PRICING_TABLE_ENUM.DISCOUNT_COST}
                                    onChange={handleformChange}
                                    label="Discounted Cost"
                                    placeholder="Discounted Cost"
                                    disabled={(discount && discount.length > 0) || (Number(lotId) !== 0 && lotId !== '')}
                                    startIcon="$"
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" pt={4} width="330px">
                        <Tooltip title={lotId !== '0' && lotId !== '' ? <p style={{ whiteSpace: 'pre-line' }}>{LOT_SELECTED_TOOLTIP_TEXT}</p> : ''} placement="top">
                            <Box width="152px">
                                <TextField
                                    value={margin || ''}
                                    name={PRICING_TABLE_ENUM.MARGIN}
                                    onChange={handleformChange}
                                    label="Margin (%)"
                                    disabled={(sellPrice && sellPrice?.length > 0) || (Number(lotId) !== 0 && lotId !== '')}
                                />
                            </Box>
                        </Tooltip>
                        <BodySmall>or</BodySmall>
                        <Tooltip title={lotId !== '0' && lotId !== '' ? <p style={{ whiteSpace: 'pre-line' }}>{LOT_SELECTED_TOOLTIP_TEXT}</p> : ''} placement="top">
                            <Box width="152px">
                                <TextField
                                    value={sellPrice || ''}
                                    name={PRICING_TABLE_ENUM.SELL_PRICE}
                                    onChange={handleformChange}
                                    label="Sell Price"
                                    placeholder="Sell Price"
                                    disabled={(margin && margin.length > 0) || (Number(lotId) !== 0 && lotId !== '')}
                                    startIcon="$"
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="200px">
                            <LeadTimeUnitTemplate
                                showLabel={true}
                                fieldName={PRICING_TABLE_ENUM.LEAD_TIME_UNIT}
                                handleOnChange={handleformChange}
                                leadTimeUnit={leadTimeUnit || '-'}
                                options={leadTimeUnitOptions}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="200px">
                            <TextField disabled={isLeadTimeValueDisabled} value={leadTimeValue || ''} name={PRICING_TABLE_ENUM.LEAD_TIME_VALUE} onChange={handleformChange} label="Lead Time Value" />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="200px">
                            <TextField value={quoteNo || ''} name={PRICING_TABLE_ENUM.QUOTE_NO} onChange={handleformChange} label="Source Quote Number" />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="100%">
                            <TextField multiline rows={5} name="externalNotes" value={externalNotes || ''} onChange={handleformChange} label="Quote Notes(External)" />
                        </Box>
                    </Box>
                    <Box display="flex" pt={4}>
                        <Box width="100%">
                            <TextField multiline rows={5} name="internalComments" value={internalComments || ''} onChange={handleformChange} label="Internal Comments" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default BulkEditModal;
