import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodySmall, Box, Modal, ModalFooter, ModalHeader, Select, SelectChangeEvent } from '@parspec/pixel';

import { useOptionSectionQuery } from '../../queries';

interface AddOptionModalProps {
    open: boolean;
    onConfirm: (sectionId: number) => void;
    onClose: () => void;
    isAddOptionInProgress: boolean;
}

export default function AddOptionModal({ open, onConfirm, onClose, isAddOptionInProgress }: AddOptionModalProps) {
    const { bomId } = useParams();

    const [selectedSection, updateSelectedSection] = useState(0);

    const { data: optionSectionData } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    useEffect(() => {
        if (optionSectionData?.data && selectedSection === 0) {
            updateSelectedSection(optionSectionData.data[0].id);
        }
    }, [optionSectionData?.data]);

    const dropdownOptions = useMemo(() => {
        if (!optionSectionData?.data) {
            return [];
        }

        return optionSectionData.data.map((sectionData) => ({ label: sectionData.name, value: sectionData.id }));
    }, [optionSectionData?.data]);

    function handleSelectChange(event: SelectChangeEvent<unknown>) {
        updateSelectedSection(event.target.value as unknown as number);
    }

    function handleAccept() {
        onConfirm(Number(selectedSection));
    }

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Select Options Section" onClose={onClose} />}
            footer={<ModalFooter continueButtonLabel="Create" onAccept={handleAccept} onReject={onClose} isLoading={isAddOptionInProgress} />}
        >
            <Box width="352px">
                <BodySmall mb={6} limit={false}>
                    Select the options section that you would like to create this option in.
                </BodySmall>
                <Select label="" onChange={handleSelectChange} options={dropdownOptions} optionLabelKeyname="label" optionValueKeyname="value" value={selectedSection} />
            </Box>
        </Modal>
    );
}
