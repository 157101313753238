import { useMutation, useQueries, UseQueryOptions } from '@tanstack/react-query';
import { queryClient } from 'src/app/queryClient';

import { AxiosResponse } from 'axios';

import { endPoints, getCompileTime, createCompileDocument, checkCreditBeforeCompile } from './api';
import { invalidateProjectBomsQuery } from 'src/features/Project/queries';
import { invalidateDocuments } from '../../../queries';
import { invalidateSubscriptionInfoQuery } from 'src/features/Settings/PlanAndPayment/queries';
import { invalidateQuoteHeaderFooterQuery } from '../../compileQuote/queries';
import { DocumentType, DocType } from '../../../constants';
import { invalidateCoverPagePresetData } from '../../submittals&o_m/queries';

const getCompileTimeKey = (bomId: string, documentType: DocType) => [endPoints.getCompileTime(bomId, documentType)];

const getCompileTimeKeyForQuotePreview = (bomId: string, documentType: string) => [endPoints.getCompileTime(bomId, documentType), 'preview'];

export const useCompileTimeQuery = (bomIds: Array<number>, documentType: DocType, options: UseQueryOptions<AxiosResponse>) =>
    useQueries({
        queries: bomIds.map((bomId) => {
            return {
                queryKey: getCompileTimeKey(`${bomId}`, documentType),
                queryFn: () => getCompileTime(`${bomId}`, documentType),
                ...options
            };
        })
    });

export const useCompileTimeForQuote = (bomIds: Array<number>, documentType = 'quote', options: UseQueryOptions<AxiosResponse>) =>
    useQueries({
        queries: bomIds.map((bomId) => {
            return {
                queryKey: getCompileTimeKeyForQuotePreview(`${bomId}`, documentType),
                queryFn: () => getCompileTime(`${bomId}`, documentType),
                ...options
            };
        })
    });

export const useGenerateDocumentMutation = (projectId: number, documentType: DocType) => {
    const onMutation = useMutation({
        mutationFn: createCompileDocument,
        onSuccess: (_, variables) => {
            if (documentType === DocumentType.QUOTE) {
                invalidateQuoteHeaderFooterQuery(variables.bomId);
                invalidateCoverPagePresetData(variables.bomId, DocumentType.SUBMITTAL);
                invalidateCoverPagePresetData(variables.bomId, DocumentType.O_M);
            }
            invalidateDocuments(variables.bomId, variables.documentType);
            queryClient.invalidateQueries({ queryKey: getCompileTimeKey(variables.bomId, variables.documentType as DocType) });
        },
        retry: 3,
        retryDelay: 1000
    });

    const invalidateQueries = () => {
        invalidateProjectBomsQuery(projectId);
        invalidateSubscriptionInfoQuery();
    };

    return { onMutation, invalidateQueries };
};

export const useCheckCreditBeforeCompilingMutation = () => useMutation(checkCreditBeforeCompile);
