import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BodySmall, Box, FileSelector, ModalFooter, ModalHeader, Modal, RadioGroup, TextField, FileSelectorFileType } from '@parspec/pixel';
import { websiteValidationRegex } from 'src/features/shared/utils/utils';
import { useGetBomDataQuery, useGetDatasheetTabDataQuery, useGetOmDataQuery } from '../../../BOM/queries';
import ReuseDatasheetDialog from '../../../BOM/shared/ReuseDatsheetDialog';
import { getTableData, ReuseDialogType, ReuseDialogTypeLabel } from '../../../BOM/shared/utils';
import { selectPdfMsg } from '../../constants';
import { useAddAlternatesMutation, useAddSuggestionMutation } from '../queries';
import { useTrackIntercomEvent } from '../../hooks/useIntercomHook';

type optionType = {
    label: string;
    value: string;
};
type AddSuggestionProps = {
    open: boolean;
    handleAddNewData: () => void;
    options: optionType[];
    attributeOverwritePopupHandler?: () => void;
};

type InputProps = FormData;

const AddSuggestion = (props: AddSuggestionProps) => {
    const { open, handleAddNewData, options, attributeOverwritePopupHandler } = props;
    const [selected, setSelected] = useState('addLink');
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState('');
    const [openReuseDatasheetDialog, setOpenReuseDatasheetDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { bomId, productId, documentType, subDocumentType, projectId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectToProductFinder = searchParams.get('from') === 'productFinder';

    const { data: bomData } = useGetBomDataQuery(bomId!, {
        enabled: !!bomId
    });
    const { data: datasheetTabData } = useGetDatasheetTabDataQuery(bomId!, {
        enabled: !!bomId
    });

    const { data: omData } = useGetOmDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });
    const { trackIntercomEvent } = useTrackIntercomEvent();
    const isAlternates: boolean = subDocumentType === 'alternates';
    const tableData = !isAlternates && (subDocumentType === 'datasheet' ? getTableData(bomData?.data, datasheetTabData?.data) : getTableData(bomData?.data, omData?.data));

    useEffect(() => {
        return () => {
            setPdfUrl('');
            setError('');
        };
    }, []);

    const { isLoading, mutateAsync: addDocument } = useAddSuggestionMutation();

    const { isLoading: loadingAddAlternates, mutateAsync: addAlternates } = useAddAlternatesMutation();
    const onChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPdfUrl(e.target.value);
        setError('');
    };

    let helper: null | ReactNode = null;

    //helper attached to the Add Link option
    if (selected === 'addLink') {
        const suggestions = [
            'Ensure the url is valid and points to a PDF.',
            'Remove all text before the https:// extension.',
            'If the url still can’t be uploaded, download the PDF and select ‘Upload a file’.'
        ];
        helper = (
            <>
                {' '}
                <TextField
                    label=""
                    fullWidth
                    size="small"
                    placeholder="Enter link here"
                    value={pdfUrl}
                    onChange={onChangeUrl}
                    helperText={error ? (!pdfUrl.trim() ? 'This field is required.' : 'The specified url could not be uploaded.') : ''}
                    error={Boolean(error)}
                />
                {Boolean(error) && pdfUrl.trim() && (
                    <Box mt={2}>
                        <Box mt={2}>
                            <BodySmall color="warning.main">Here are some suggested resolutions:</BodySmall>
                            <Box pl={4} color="warning.main">
                                <ul style={{ color: 'inherit' }}>
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index}>
                                            <BodySmall color="warning.main" limit={false}>
                                                {suggestion}
                                            </BodySmall>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
        );
    }

    //Helper attached to the Add File option
    if (selected === 'addFile') {
        helper = (
            <FileSelector
                onSelect={(files: File[] | FileSelectorFileType[]) => {
                    setError('');
                    setSelectedFiles(files as File[]);
                }}
                acceptedFormats={['.pdf']}
                helperText="Supported file types: PDF"
                error={error || ''}
                placeholder="Drag and drop a file here, or:"
                isLoading={isLoading || loadingAddAlternates}
            />
        );
    }

    //Called on selecting the option from radio group
    const onSelectOption = (_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelected(value);
        setError('');
        setPdfUrl('');
        setSelectedFiles([]);
    };

    //On submit the form
    const onSubmit = async () => {
        setError('');
        const input: any = { bod_id: [Number(productId)] };
        const formData: InputProps = new FormData();
        let actionType = '';
        if (selected === 'reuse') {
            handleReuseDatasheetDialog();
            return;
        }
        if (selected === 'addLink') {
            actionType = 'link_upload';
            if (pdfUrl.trim() && websiteValidationRegex().test(pdfUrl)) {
                input.input = pdfUrl;
            } else {
                return setError('inValid');
            }
        }
        if (selected === 'addBlankPage') {
            actionType = 'blank_file_upload';
        }
        if (selected === 'addFile') {
            actionType = 'file_upload';
            if (selectedFiles.length) {
                formData.append('input', selectedFiles[0]);
                formData.append('bod_id', `[${productId}]`);
            } else {
                return setError(selectPdfMsg);
            }
        }
        try {
            const payload = {
                bomId: bomId!,
                actionType //*
            };
            if (isAlternates) {
                await addAlternates({
                    ...payload,
                    productId: Number(productId),
                    input: selected === 'addFile' ? formData : input.input //*
                });
                navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/create`);
            } else {
                await addDocument({
                    ...payload,
                    documentType: documentType!,
                    subDocumentType: subDocumentType!,
                    productId: [Number(productId)],
                    input: selected === 'addFile' ? formData : input //*
                });
                const annotatePageUrl = `/v2/project/${projectId}/bom/${bomId}/product/${productId}/${documentType}/${subDocumentType}/annotate`;
                const productFinderUrl = `/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/setRequirements`;
                const url = redirectToProductFinder ? productFinderUrl : annotatePageUrl;
                navigate(url);
            }

            if (['file_upload', 'link_upload'].includes(actionType) && (subDocumentType === 'datasheet' || redirectToProductFinder)) {
                const uploadType = selected === 'addFile' ? 'File' : 'Link';
                const eventPrefix = redirectToProductFinder ? 'PF_' : '';
                const eventName = `${eventPrefix}Add_Datasheet_${uploadType}_Uploaded`;
                trackIntercomEvent(eventName);
                if (attributeOverwritePopupHandler) {
                    attributeOverwritePopupHandler();
                }
            }

            handleAddNewData();
        } catch (error: any) {
            setError(error?.response?.data?.message || 'Something went wrong!');
        }
    };
    const handleReuseDatasheetDialog = () => {
        setOpenReuseDatasheetDialog((old) => !old);
    };
    const onReuse = () => {
        if (redirectToProductFinder) {
            if (attributeOverwritePopupHandler) {
                attributeOverwritePopupHandler();
            }
            navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/setRequirements`);
        } else {
            navigate(`/v2/project/${projectId}/bom/${bomId}/product/${productId}/${documentType}/${subDocumentType}/annotate`);
        }
        handleAddNewData();
    };

    return (
        <>
            {!openReuseDatasheetDialog && (
                <Modal
                    open={open}
                    header={<ModalHeader title={`Add ${ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}`} onClose={handleAddNewData} />}
                    footer={<ModalFooter onAccept={onSubmit} onReject={handleAddNewData} isLoading={isLoading || loadingAddAlternates} continueButtonLabel="Confirm" />}
                >
                    <Box width={375}>
                        <RadioGroup
                            name="add-data-sheet"
                            label={`How would you like to add a ${ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}?`}
                            options={options}
                            value={selected}
                            onChange={onSelectOption}
                        />
                        {helper && helper}
                    </Box>
                </Modal>
            )}
            {openReuseDatasheetDialog && (
                <ReuseDatasheetDialog
                    open={openReuseDatasheetDialog}
                    handleDialog={handleAddNewData}
                    confirmAction={onReuse}
                    tableData={tableData}
                    selectedRowIds={[Number(productId!)]}
                    reuseType={subDocumentType! as ReuseDialogType}
                />
            )}
        </>
    );
};

export default AddSuggestion;
