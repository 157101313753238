export const users = [
    {
        user: 'John Doe',
        projects: 13100,
        quotes: 1310,
        submittals: 5000,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 20000,
        freight: 1000,
        totalExtDiscountedCost: 100
    },
    {
        user: 'Foo Fae',
        projects: 125,
        quotes: 125,
        submittals: 125,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 50000000,
        services: 56666,
        freight: 455,
        totalExtDiscountedCost: 12333
    },
    {
        user: 'Jo Johns',
        projects: 54677,
        quotes: 125,
        submittals: 125,
        oMs: 5654,
        winRate: 93,
        totalExtSellPrice: 2234234,
        services: 1000,
        freight: 100,
        totalExtDiscountedCost: 77778
    },
    {
        user: 'David Smith',
        projects: 34555,
        quotes: 777,
        submittals: 66,
        oMs: 555,
        winRate: 76,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 10000
    },
    {
        user: 'Rahul Joy',
        projects: 2344,
        quotes: 435,
        submittals: 125,
        oMs: 131,
        winRate: 56,
        totalExtSellPrice: 35006666,
        services: 45,
        freight: 555,
        totalExtDiscountedCost: 55
    }
];
