import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BodySmall, Box, Modal, ModalFooter, ModalHeader, Info, Checkbox, Snackbar } from '@parspec/pixel';
import { CustomerTab } from 'src/features/shared/CustomerTab';
import { DocType, DEFAULT_SUB_TITLE_FOR_VERSION_NOTE } from '../../constants';
import { VersionNotes } from '../VersionNotes';
import { BOM_DATA_PUSHED_MESSAGE } from 'src/features/shared/constants';

interface CreateNewVersionPropTypes {
    open: boolean;
    onCloseClick: () => void;
    onSuccess?: () => void;
    textValue?: string;
    title?: string;
    onSubmit?: (bomsNote: Record<string, string> | null) => void;
    isLoading: boolean;
    bomIds?: number[];
    updateSelectedBoms?: (params: number[]) => void;
    selectedBoms?: number[];
    footerHelperText?: string;
    modalSubtitle?: string;
}

interface IBomsNoteData {
    bomsToCompile: number[];
    compiledBomsNote: Record<string, string>;
}

const CreateNewVersion: React.FC<CreateNewVersionPropTypes> = ({
    open,
    onCloseClick,
    textValue,
    title,
    onSubmit,
    isLoading,
    bomIds,
    updateSelectedBoms,
    selectedBoms,
    footerHelperText = '',
    modalSubtitle = ''
}) => {
    const { projectId = '0', documentType = '', bomId = '' } = useParams();

    const [isNotesSectionDisabled, setIsNotesSectionDisabled] = useState(false);
    const [bomsNote, setBomNotes] = useState<Record<string, string> | null>(null);
    const [activeBom, setActiveBom] = useState('');
    const [checkedBoms, setCheckedBoms] = useState<Record<string, boolean> | null>(null);
    const [applyToAllBomId, setApplyToAllBomId] = useState<number[]>([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const isQuoteDocument = documentType === DocType.QUOTE ? true : false;

    const handleCloseModal = () => {
        setApplyToAllBomId([]);
        onCloseClick();
    };

    const header = <ModalHeader title={title ? title : ''} onClose={handleCloseModal} />;

    if (!bomIds && (!activeBom || activeBom !== bomId)) {
        setActiveBom(bomId);
    } else if (bomIds?.length && !activeBom) {
        setActiveBom(`${bomIds[0]}`);
    }

    const { bomsToCompile, compiledBomsNote } = useMemo(() => {
        const bomsData: IBomsNoteData = {
            bomsToCompile: [],
            compiledBomsNote: {}
        };

        if (bomsNote && checkedBoms && isQuoteDocument) {
            for (const key in checkedBoms) {
                if (checkedBoms[key]) {
                    bomsData.bomsToCompile.push(Number(key));
                    bomsData.compiledBomsNote[key] = bomsNote![key];
                }
            }
        } else if (!isQuoteDocument) {
            bomsData.bomsToCompile.push(Number(bomId));
            bomsData.compiledBomsNote = { ...bomsNote };
        }

        return bomsData;
    }, [checkedBoms, bomsNote]);

    useEffect(() => {
        if (updateSelectedBoms && selectedBoms && selectedBoms.length !== bomsToCompile.length) {
            updateSelectedBoms(bomsToCompile);
        }
    }, [bomsToCompile, updateSelectedBoms, selectedBoms]);

    useEffect(() => {
        if (textValue && open) {
            setBomNotes({ [bomId]: textValue });
        } else if (open && bomIds?.length && !bomsNote) {
            const bomNotesObj = Object.fromEntries(bomIds.map((item) => [`${item}`, '']));
            setBomNotes(bomNotesObj);
        }
        setIsNotesSectionDisabled(false);
    }, [textValue, open]);

    useEffect(() => {
        return () => {
            setBomNotes(null);
            setCheckedBoms(null);
        };
    }, [open]);

    useEffect(() => {
        if (isLoading) {
            setIsNotesSectionDisabled(true);
        }
    }, [isLoading]);

    const handleNotes = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, bomId: string) => {
        if (!bomsNote) {
            setBomNotes({ [bomId]: event.target.value });
        } else {
            setBomNotes({ ...bomsNote, [bomId]: event.target.value });
        }
        setApplyToAllBomId((old) => [...old, Number(bomId)]);
    };

    const handleApplyToAllClick = (bomId: string) => {
        const note = bomsNote?.[bomId] || '';
        const updatedNotes = { ...bomsNote };
        Object.keys(updatedNotes).forEach((item) => {
            updatedNotes[item] = note;
        });
        setBomNotes(updatedNotes);
        setApplyToAllBomId([]);
        setSnackbarMessage(BOM_DATA_PUSHED_MESSAGE);
    };

    const handleTabChange = (bomId: string) => {
        setActiveBom(bomId);
    };

    const handleCheckBoxState = (params: Record<string, boolean>) => {
        setCheckedBoms(params);
    };

    const handleSubmit = async () => {
        if (onSubmit && bomsToCompile.length) {
            onSubmit(compiledBomsNote);
        }
    };

    const handleSelectAll = (event: any) => {
        const selectAllBoms: Record<string, boolean> = {};
        bomIds?.forEach((bomId) => {
            selectAllBoms[bomId] = true;
        });

        const selectState = event.target.checked;

        if (selectState) {
            setCheckedBoms(selectAllBoms);
        } else {
            setCheckedBoms(null);
        }
    };

    const footer = (
        <ModalFooter
            onAccept={handleSubmit}
            helperText={
                <Box padding={'8px 24px 8px 0px'}>
                    <BodySmall color={'error'} fontWeight={500} limit={true} lines={2}>
                        {!bomsToCompile.length ? footerHelperText : ''}
                    </BodySmall>
                </Box>
            }
            onReject={handleCloseModal}
            continueButtonLabel={isQuoteDocument ? 'Create' : 'Confirm'}
            isLoading={isLoading}
        />
    );

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Modal header={header} footer={footer} open={open}>
                <Box width={isQuoteDocument ? 718 : 515}>
                    <Box width={1}>
                        <BodySmall color="#64748B" lines={3} fontWeight={500}>
                            {modalSubtitle ? modalSubtitle : DEFAULT_SUB_TITLE_FOR_VERSION_NOTE}
                        </BodySmall>
                    </Box>
                    <Box display="flex" gap={3}>
                        {isQuoteDocument && bomIds?.length ? (
                            <Box display={'flex'} flexDirection={'column'} mt={3}>
                                <Box sx={{ margin: '0px', marginLeft: '11px' }}>
                                    <Checkbox
                                        label={'Select all'}
                                        size={'medium'}
                                        onChange={handleSelectAll}
                                        checked={checkedBoms && bomIds ? (Object.values(checkedBoms).filter((el) => el)?.length === bomIds.length ? true : false) : false}
                                        indeterminate={
                                            checkedBoms && bomIds
                                                ? Object.values(checkedBoms).filter((el) => el)?.length > 0 && (Object.values(checkedBoms).filter((el) => el)?.length !== bomIds.length ? true : false)
                                                : false
                                        }
                                    />
                                </Box>
                                <Box sx={{ height: 270 }} border={'1px solid'} borderColor={'neutral.main'}>
                                    <CustomerTab
                                        onCheckboxSelect={handleCheckBoxState}
                                        checkBoxState={checkedBoms}
                                        showCheckbox
                                        orientation="vertical"
                                        projectId={Number(projectId)}
                                        defaultSelectedBomId={activeBom}
                                        onTabChange={handleTabChange}
                                    />
                                </Box>
                            </Box>
                        ) : null}
                        <Box display="flex" flexDirection="column" width={1}>
                            <VersionNotes
                                documentType={documentType}
                                isNotesSectionDisabled={isNotesSectionDisabled}
                                onSubmit={handleSubmit}
                                notes={bomsNote?.[activeBom] || ''}
                                onChange={handleNotes}
                                bomId={activeBom}
                                applyToAllBomId={applyToAllBomId}
                                handleApplyToAllClick={handleApplyToAllClick}
                                showApplyButton={Boolean(isQuoteDocument && bomIds?.length)}
                            />
                            {!isQuoteDocument ? (
                                <Info>
                                    <BodySmall lines={3} color={'primary.main'}>
                                        <strong>Previously shared versions of this document will remain active on document shared link.</strong>
                                        You can un-publish older versions from the <br /> ‘final documents’ tab.
                                    </BodySmall>
                                </Info>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default CreateNewVersion;
