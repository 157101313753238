import { SelectedDocumentResponse } from '../apiTypes';

export const selectedProduct: Array<SelectedDocumentResponse> = [
    {
        id: 19660,
        document: 351795,
        hidden_pages: [1, 2, 3],
        document_selection_status: 'SELECTED',
        annotations_status: 'NOT_ANNOTATED',
        xfdf: '',
        draft_xfdf: '',
        is_blank_document: false,
        created_at: '2023-04-18T03:26:11.238628Z',
        updated_at: '2023-04-18T03:26:11.238682Z',
        suggestion: {
            coordinates: {
                'https://parspec-staging.s3.us-east-2.amazonaws.com/media/datasheets/LITHONIA/FOT_FLEX_SPECIFICATION_1681788369_flat.pdf': []
            },
            auto_markup: ['CLXDFDSF', 'CLXDFDSF-'],
            selected_document_url: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/datasheets/LITHONIA/FOT_FLEX_SPECIFICATION_1681788369_flat.pdf',
            selected_document_preview: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/cas/51049f4ad14da2059e4a1098e07e8d0b0284b2bf914306de30503373c0d2676e.jpeg'
        }
    }
];
export const datasheet = {
    id: 178882,
    weblink: 'https://www.luminii.com/wp-content/uploads/z_special_catalog_products/products/fot-flex-ho/specsheets/FOT_FLEX_specification.pdf',
    filepath: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/datasheets/LITHONIA/FOT_FLEX_SPECIFICATION_1681788369.pdf',
    flat_filepath: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/datasheets/LITHONIA/FOT_FLEX_SPECIFICATION_1681788369_flat.pdf',
    filename: null,
    content_hash: '0aeb1e550ce370c19ddce924da2b4a83',
    total_pages: 10
};
