import { useMemo } from 'react';

import { ProjectBomInfo } from '../../Project/queries/apiTypes';
import { useGetProjectBomsQuery } from '../../Project/queries';

interface IProjectBomsDataParams {
    projectId: number;
    selectedBomIds?: Set<number>;
    bomOptionsData?: Partial<ProjectBomInfo>[];
}

export const useProjectBomsData = ({ projectId, selectedBomIds, bomOptionsData }: IProjectBomsDataParams) => {
    const { data: projectBomsData, isLoading } = useGetProjectBomsQuery(projectId, {
        enabled: Boolean(projectId) && !bomOptionsData?.length
    });

    const bomOptions = useMemo(() => {
        let bomsArr: Array<Partial<ProjectBomInfo>> = [];

        if (projectBomsData?.data) {
            if (selectedBomIds?.size) {
                bomsArr = projectBomsData.data.filter((bom) => selectedBomIds.has(bom.id));
            } else {
                bomsArr = [...projectBomsData.data];
            }
        }

        if (bomOptionsData?.length) {
            bomsArr = [...bomOptionsData];
            const primaryBomIndex = bomsArr.findIndex((bom) => bom.is_primary);
            if (primaryBomIndex !== -1) {
                const [primaryBom] = bomsArr.splice(primaryBomIndex, 1);
                bomsArr.unshift(primaryBom);
            }
        }

        return bomsArr;
    }, [projectBomsData?.data, selectedBomIds, bomOptionsData]);

    return { bomOptions, isLoading };
};
