import { BodyMedium, Box, Button, CircularProgress } from '@parspec/pixel';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUnlinkBOMMutation } from '../../Project/queries';

const LinkedBomsPopup = ({ primaryBomId, isbomLoading }: { primaryBomId: number; isbomLoading: boolean }) => {
    const { projectId = '', bomId = '' } = useParams();
    const { mutateAsync: unLinkBomDetails, isLoading } = useUnlinkBOMMutation();

    const navigate = useNavigate();
    const location = useLocation();

    const tab = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params?.get('tab');
    }, [location.search]);

    const onUnlinkClick = async () => {
        await unLinkBomDetails({ bomId: bomId, projectId: Number(projectId) });
    };
    const handleGoToPrimary = () => {
        navigate(`/v2/project/${projectId}/bom/${primaryBomId}?tab=${tab}`);
    };
    return (
        <Box position={'absolute'} top={'42%'} width={'fit-content'} bgcolor={'#fff'} zIndex={1} left={0} right={0} ml={'auto'} mr={'auto'} borderRadius={3} p={4}>
            {isbomLoading ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
                    <CircularProgress color="primary" size="xxl" />
                </Box>
            ) : (
                <>
                    <BodyMedium textAlign={'center'} height={'60px'} lines={0}>
                        This Bill of Materials is linked to the Primary Bill Of Materials' content. <br />
                        To make custom changes, click unlink.
                    </BodyMedium>
                    <Box display={'flex'} justifyContent={'center'} gap={4}>
                        <Button color="secondary" variant="outlined" onClick={handleGoToPrimary}>
                            Go to Primary Bom
                        </Button>
                        <Button isLoading={isLoading} onClick={onUnlinkClick}>
                            Unlink
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};
export default LinkedBomsPopup;
