import { CircularProgress, Box } from '@parspec/pixel';

const Loader = () => {
    return (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
            <CircularProgress color="primary" size={'xxl'} />
        </Box>
    );
};

export default Loader;
