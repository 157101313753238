import { useEffect } from 'react';

import { Box, Grid } from '@parspec/pixel';

import LeftContent from './LeftContent';
import QuotePdfPreview from './QuotePdfPreview';
import { useQuoteContext } from './QuoteContext';
import { ERROR_KEYS, QuoteEntity } from '../constants';

import {
    useCoverPageToggleMutation,
    useGeneralTermsAndConditionToggleMutation,
    usePriceAndLeadTimeToggleMutation,
    useCoverPageDataQuery,
    usePriceAndLeadTimeQuery,
    useGeneralTermsAndConditionQuery,
    useHeaderFooterDataQuery,
    useManufacturerTermsAndConditionsQuery,
    useUpdateHeaderFooterToggleMutation,
    useManufacturerToggleMutation,
    useQuotePdfUrlQuery,
    useQuoteAttachmentsQuery,
    useAttachmentToggleMutation
} from './queries';

import CircularLoader from '../../../../shared/CircularLoader';

interface IMainContent {
    isPending: boolean;
}

function MainContent({ isPending }: IMainContent) {
    const { setAccordionFieldName, isInitiateApiCalls, handlePdfPreview, bomId, handleError } = useQuoteContext();

    const { mutateAsync: coverPageToggleMutation } = useCoverPageToggleMutation();
    const { mutateAsync: priceToggleMutation } = usePriceAndLeadTimeToggleMutation();
    const { mutateAsync: generalTncMutation } = useGeneralTermsAndConditionToggleMutation();
    const { mutate: headerFooterMutation } = useUpdateHeaderFooterToggleMutation();
    const { mutate: manufacturerTncMutation } = useManufacturerToggleMutation();
    const { mutateAsync: attachmentsMutation } = useAttachmentToggleMutation();

    const { data: coverPageData, isLoading: isCoverPageLoading } = useCoverPageDataQuery(bomId, isInitiateApiCalls);
    const { isLoading: isPriceAndLeadTimeLoading, data: priceAndLeadTime } = usePriceAndLeadTimeQuery(bomId, isInitiateApiCalls);
    const { data: generalTncData, isLoading: isGeneralTncLoading } = useGeneralTermsAndConditionQuery(bomId, isInitiateApiCalls);
    const { data: headerFooterData, isLoading: isHeaderFooterLoading } = useHeaderFooterDataQuery(bomId, isInitiateApiCalls);
    const { data: mtncData, isLoading: isManufacturerLoading } = useManufacturerTermsAndConditionsQuery(bomId, isInitiateApiCalls);
    const { data: attachmentData, isLoading: isAttachmentDataLoading } = useQuoteAttachmentsQuery(bomId, isInitiateApiCalls);

    useEffect(() => {
        if (!headerFooterData?.header_footer_section.is_active) {
            handleError(ERROR_KEYS.QUOTE_CUSTOM_ENTRY, '');
        }
    }, [headerFooterData?.header_footer_section.is_active]);

    const { isLoading: isPreviewImageLoading } = useQuotePdfUrlQuery(bomId, {
        enabled: isInitiateApiCalls
    });

    async function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        switch (type) {
            case QuoteEntity.CoverPage:
                await coverPageToggleMutation({
                    bomId,
                    fieldName: type,
                    is_active: e.target.checked
                });
                break;
            case QuoteEntity.GeneralTermsAndCondition:
                await generalTncMutation({
                    bomId,
                    fieldName: type,
                    is_active: e.target.checked
                });
                break;
            case QuoteEntity.PricingLeadTime:
                await priceToggleMutation({
                    bomId,
                    fieldName: type,
                    is_active: e.target.checked
                });
                break;
            case QuoteEntity.HeaderAndFooter:
                headerFooterMutation({
                    bomId,
                    fieldName: type,
                    is_active: e.target.checked
                });
                break;
            case QuoteEntity.ManufactureTermsAndCondition:
                manufacturerTncMutation({
                    bomId,
                    fieldName: 'manufacturer_tnc_section',
                    payload: { is_active: e.target.checked }
                });
                break;
            case QuoteEntity.Attachments:
                await attachmentsMutation({
                    bomId,
                    payload: { is_enable: e.target.checked }
                });

                break;
        }

        handlePdfPreview(true);
    }

    function handleAccordionChange(panel: string) {
        setAccordionFieldName(panel as QuoteEntity);
    }

    if (isPriceAndLeadTimeLoading || isCoverPageLoading || isGeneralTncLoading || isHeaderFooterLoading || isManufacturerLoading || isAttachmentDataLoading || isPending) {
        return <CircularLoader />;
    }

    const manufacturer_tnc_section = mtncData?.data.find((item) => item.field_name === 'manufacturer_tnc_section');

    return (
        <>
            <Box width={1}>
                <Grid container>
                    <Grid item xs={6}>
                        <LeftContent
                            onToggle={onChangeHandler}
                            onAccordionChange={handleAccordionChange}
                            cover_page_section={coverPageData?.cover_page_section.is_active}
                            price_and_lead_time_section={priceAndLeadTime?.price_and_lead_time_section.is_active}
                            header_footer_section={headerFooterData?.header_footer_section.is_active}
                            general_tnc_section={generalTncData?.general_tnc_section.is_active}
                            manufacturer_tnc_section={manufacturer_tnc_section?.is_active}
                            attachments={attachmentData?.data?.is_enable}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {isPreviewImageLoading ? (
                            <CircularLoader />
                        ) : (
                            <QuotePdfPreview
                                cover_page_section={coverPageData?.cover_page_section.is_active}
                                price_and_lead_time_section={priceAndLeadTime?.price_and_lead_time_section.is_active}
                                header_footer_section={headerFooterData?.header_footer_section.is_active}
                                general_tnc_section={generalTncData?.general_tnc_section.is_active}
                                manufacturer_tnc_section={manufacturer_tnc_section?.is_active}
                                attachments={attachmentData?.data?.is_enable}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default MainContent;
