import { useState } from 'react';

import * as FileSaver from 'file-saver';

import { BodySmall, Box, FileSelector, FileSelectorFileType, Link, Modal, ModalHeader, Snackbar } from '@parspec/pixel';

import { useUploadMfgFile } from './queries';
import { ManufacturerUploadResponse } from './queries/apiTypes';

interface ImportListDialogProps {
    open: boolean;
    handleDialog: () => void;
    confirmAction: (uploadedData: ManufacturerUploadResponse[]) => void;
}

const ImportList = (props: ImportListDialogProps) => {
    const { open, handleDialog, confirmAction } = props;

    const { mutateAsync: uploadFile, isLoading } = useUploadMfgFile();

    const [fileRequiredError, setFileRequiredError] = useState<string | null>(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const downloadTemplate = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const csv = 'Manufacturer Name (Required),Manufacturer Website,Manufacturer ERP Code,Abbreviation 1,Abbreviation 2,Abbreviation 3\n,,,\n,,,\n,,,\n,,,\n,,,\n';
        const contentType = 'text/csv';
        setSnackbarMessage('The download has started ! Check your Downloads folder');
        const csvFile = new Blob([csv], { type: contentType });
        FileSaver.saveAs(csvFile, 'Manufacturer_File_Template.csv');
    };

    const onUploadFile = async (files: File[] | FileSelectorFileType[]) => {
        if (files.length) {
            setFileRequiredError(null);
            const formData = new FormData();
            formData.append('file', files[0] as File);
            const uploadData = await uploadFile(formData);
            if (!uploadData.data.data.length) {
                setFileRequiredError('No data found in the uploaded file');
            } else {
                confirmAction(uploadData.data.data);
            }
        }
    };

    const onDeleteFile = () => {
        setFileRequiredError(null);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Modal open={open} header={<ModalHeader title={'Upload Manufacturers'} onClose={handleDialog} />} footer={<></>}>
                <Box width={376} marginTop={4}>
                    <FileSelector
                        placeholder={
                            <BodySmall
                                sx={{
                                    div: {
                                        WebkitLineClamp: 'unset !important'
                                    }
                                }}
                            >
                                Download our <Link onClick={downloadTemplate}>Manufacturer File Template</Link>,<br />
                                then browse or drag your file to upload.
                            </BodySmall>
                        }
                        onSelect={onUploadFile}
                        acceptedFormats={['.xls', '.xlsx', '.csv']}
                        helperText={'Supported file types: XLS, XLSX, CSV'}
                        error={fileRequiredError!}
                        onDeleteFile={onDeleteFile}
                        isLoading={isLoading}
                    ></FileSelector>
                </Box>
            </Modal>
        </>
    );
};

export default ImportList;
