import { IHeaderParams, SortDirection } from '@ag-grid-community/core';

import { useState, useEffect } from 'react';

import { Box, Tooltip, InfoOutlinedIcon, BodyXS, UnfoldMoreIcon, ArrowUpwardIcon, ArrowDownwardIcon } from '@parspec/pixel';

interface ICustomHeaderParams extends IHeaderParams {
    tooltipText: string;
    enableSorting: boolean;
    setSort: (value: SortDirection) => void;
    column: any;
    width?: string;
}
interface ICustomHeader {
    params: ICustomHeaderParams;
    fieldName: string;
}

export const CustomHeader = (props: ICustomHeader) => {
    const { tooltipText, enableSorting, setSort, column, width } = props.params;
    const fieldName = props.fieldName;

    const [currSortState, setCurrSortState] = useState<string | null | undefined>(null);

    const onSortChanged = () => {
        if (column.isSortAscending()) {
            setCurrSortState('asc');
        } else if (column.isSortDescending()) {
            setCurrSortState('desc');
        } else {
            setCurrSortState(null);
        }
    };

    const onSortRequested = () => {
        const currSort = column?.sort;

        let newSort;

        if (currSort === 'asc') {
            newSort = 'desc';
        } else if (currSort === 'desc') {
            newSort = null;
        } else {
            newSort = 'asc';
        }
        setSort(newSort as SortDirection);
        setCurrSortState(newSort);
    };

    useEffect(() => {
        column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, []);

    return (
        <Box mt={1} height="100%" display="flex" justifyContent="space-between" alignItems="center" width={1}>
            <Box>
                <BodyXS fontWeight={500}>{fieldName}</BodyXS>
            </Box>
            <Box display="flex" gap={2} alignItems="center">
                {tooltipText && (
                    <Box>
                        <Tooltip placement="top" title={<span style={{ display: 'block', textAlign: 'center', width: width ? width : '150px' }}>{tooltipText}</span>}>
                            <Box color={'text.secondary'}>
                                <InfoOutlinedIcon color={'inherit'} fontSize="small" />
                            </Box>
                        </Tooltip>
                    </Box>
                )}
                <Box onClick={() => (enableSorting ? onSortRequested() : undefined)}>
                    {(currSortState === undefined || currSortState === null) && (
                        <Box color={'text.secondary'}>
                            <UnfoldMoreIcon color={'inherit'} fontSize="small" />
                        </Box>
                    )}
                    {currSortState === 'asc' && (
                        <Box color={'text.secondary'}>
                            <ArrowUpwardIcon color={'inherit'} fontSize="small" />
                        </Box>
                    )}
                    {currSortState === 'desc' && (
                        <Box color={'text.secondary'}>
                            <ArrowDownwardIcon color={'inherit'} fontSize="small" />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
