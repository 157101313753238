import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, MutableRefObject } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnState,
    RowGroupOpenedEvent,
    ICellRendererParams,
    RowClassParams,
    ProcessCellForExportParams,
    ColDef,
    CellKeyDownEvent,
    GetQuickFilterTextParams
} from 'ag-grid-community';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { isEqual } from 'lodash-es';

import { Box, TableHeaderMenuIcon, BodySmall, Snackbar, ToolbarType, Portal } from '@parspec/pixel';

import { ProductFinderTemplate, ProductFinderDisabledTemplate, loadingOverlayComponent, NoRowsOverlayComponent } from '../shared/commonTemplates';
import {
    useAddBomDataMutation,
    useCreateKitMutation,
    useDeleteBomDataMutation,
    useDuplicateBomDataMutation,
    useApproveProductOption,
    useReorderBomDataMutation,
    useToggleHideBomDataMutation,
    useUpdateBomDataMutation,
    saveColumnWidthEvents,
    saveColumnOrderEvents,
    useHighLightBomDataMutation
} from '../queries';
import { addMsg, deleteMsg, duplicateMsg, hideMsg, reorderMsg, someThingWentWrongMsg, REMOVE_PRODUCT_FROM_KIT, HIGHLIGHT_MSG } from '../../shared/constants';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import {
    areYouSure,
    deleteContent,
    deleteDialogTitle,
    deleteMessage,
    deleteTitle,
    getRowId,
    TextCellParams,
    kitToolbarItems,
    addNewRowsToTheTable,
    handleOnRangeSelectionChanged
} from '../shared/utils';
import MoveSectionDialog from '../shared/MoveSectionDialog';
import { BodResponse, IBodsComponent, SectionApiResponseType } from '../queries/apiTypes';
import ErrorDialog from '../../shared/ErrorDialog';
import { KIT_ERROR, KIT_ERROR_TYPE, NO_BOD_MSG } from '../shared/constants';
import { SECTION_TYPE } from '../shared/constants';
import AddOptionModal from '../shared/addOptionModal';
import { useGetCustomisedColumnData, useLotMap, useCreateNewBods } from '../shared/hooks';
import { KitMenuOptions, MenuOptions } from '../shared/MenuOptions';
import { MenuOptionsContext, TableContext } from '../shared/context';
import { AgGridReact } from 'ag-grid-react';
import CustomToolBarPanel from '../../shared/CustomToolBarPanel';
import { getTemplateCellStyle } from '../pricing&LeadTime/shared/utils';
import { defaultColDef } from './helper';
import { ProductToReplaceTemplate } from '../shared/ProductToReplaceTemplate';
import { useCheckAccessForProductFinderOrQuoting } from '../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import MoveProductDialog from '../shared/MoveProductDialog';
import BOMDetailGrid from './BOMDetailGrid';
import { checkIfResfreshParentTable, getIsTableDataUpdated } from './utils';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { toolBarOptions } from '../DatasheetTab/helper';
import { BooleanObjType } from '../shared/types';
import AddMultipleRows from '../shared/AddMultipleRows';
import { PRICING_TABLE_ENUM } from '../pricing&LeadTime/shared/constants';
import { SpecificationTemplate } from './SpecificationTemplate';

interface BomTableProps {
    dataLoading?: boolean;
    dataFetching?: boolean;
    tableData: BodResponse[];
    section?: SectionApiResponseType;
    tableHeight?: number;
    isOptionTable?: boolean;
    optionSection?: SectionApiResponseType[];
    mainProductOptionsInfo?: Record<number, Array<number>>;
    onAddOption?: (args: { sectionId: number; type: string; qty: number; productToReplaceId: number }) => Promise<void>;
    isAddOptionLoading?: boolean;
    isKitTable?: boolean;
    isComponentsTable?: boolean;
    kitTableHeight?: number | string;
    kitData?: any;
    onGridReady?: (params: any) => void;
    handleTablesReordering?: (columnOrder: string[]) => void;
    handleTablesWidthChanged?: (targetedColumnId: string, newWidth?: number) => void;
    selectedRowsRef: MutableRefObject<Set<number>>;
}

const BomTable = forwardRef((props: BomTableProps, ref: any) => {
    const {
        dataLoading,
        tableData,
        section,
        isOptionTable = false,
        optionSection,
        mainProductOptionsInfo,
        onAddOption,
        isAddOptionLoading = false,
        tableHeight,
        isKitTable = false,
        isComponentsTable = false,
        kitTableHeight,
        kitData,
        onGridReady = () => {},
        handleTablesReordering,
        handleTablesWidthChanged,
        selectedRowsRef
    } = props;

    const tableRef = useRef<any>();
    const { bomId = '0' } = useParams<{ bomId?: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const productId = Number(searchParams.get('productId'));
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [movingIds, setMovingIds] = useState<number[]>([]);
    const [isLotSelectedForMovingProducts, setLotSelectedForMovingProducts] = useState(false);
    const [isAddOptionModalOpen, setAddOptionModalOpen] = useState(false);
    const [rowData, setRowData] = useState<BodResponse[]>();
    const [rowDataInitialised, setRowDataInitialised] = useState(false);
    const [bodInfoForAddOption, setBodInfoForAddOption] = useState<{ type: string; qty: number; productToReplaceId: number } | undefined>();
    const [kitCreationError, setKitCreationError] = useState<KIT_ERROR_TYPE>();
    const [quickFilterText, updateQuickFilerText] = useState('');
    const expandedRowsContainerRef = useRef<Array<number>>([]);
    const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
    const [openMoveProductDialog, setOpenMoveProductDialog] = useState(false);
    const [currentBodId, setCurrentBodId] = useState<number>();
    const prevTableData = useRef(tableData);
    const [detailGridContainerHeight, setDetailGridContainerHeight] = useState(0);
    const [kitsExpansionObj, setKitsExpansionObj] = useState<BooleanObjType>({});

    const updateDetailGridContainerHeight = useCallback((value: number) => {
        setDetailGridContainerHeight(value);
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return tableRef;
        },

        []
    );

    const { createNewRowsFromClipboard, isLoading: isLoadingNewBods } = useCreateNewBods(bomId);

    const noOfOptionSections = optionSection?.length || 0;

    const { mutateAsync: reorderData } = useReorderBomDataMutation();

    const { mutateAsync: deleteData, isLoading: deleteLoading } = useDeleteBomDataMutation();

    const { mutateAsync: duplicateData, isLoading: duplicateLoading } = useDuplicateBomDataMutation(bomId);

    const { mutateAsync: toggleHideData, isLoading: hideLoading } = useToggleHideBomDataMutation();

    const { mutateAsync: toggleHighLightData, isLoading: highLightLoading } = useHighLightBomDataMutation();

    const { mutateAsync: addData, isLoading: addDataLoading } = useAddBomDataMutation(bomId);

    const { mutateAsync: updateData } = useUpdateBomDataMutation();

    const { columnOrderDetails, isColumnDataLoading } = useGetCustomisedColumnData(bomId);

    const { data: userData } = useGetUserProfileInfoQuery();

    const { mutateAsync: createKit, isLoading: createKitLoading } = useCreateKitMutation(Number(bomId || 0));

    const { mutateAsync: postApproveOption, isLoading: isApproveOptionLoading } = useApproveProductOption();

    const { productFinderAccess, isLoading: isCheckUserHasPFAccessLoading } = useCheckAccessForProductFinderOrQuoting();
    const lotMap = useLotMap(Number(bomId));
    const isTableLoading =
        dataLoading ||
        deleteLoading ||
        duplicateLoading ||
        hideLoading ||
        addDataLoading ||
        isApproveOptionLoading ||
        createKitLoading ||
        isCheckUserHasPFAccessLoading ||
        isColumnDataLoading ||
        highLightLoading ||
        isLoadingNewBods;

    useEffect(() => {
        if (prevTableData.current || isComponentsTable) {
            if (
                (tableData?.length && !rowDataInitialised) ||
                (!isComponentsTable && tableData && checkIfResfreshParentTable(tableData as IBodsComponent[], rowData as IBodsComponent[])) ||
                (rowData && rowData.length !== tableData.length) ||
                (tableData && rowData && getIsTableDataUpdated(tableData, rowData))
            ) {
                setRowData(tableData);
                setRowDataInitialised(true);
            }
            prevTableData.current = tableData;
        }
    }, [rowDataInitialised, tableData]);

    useEffect(() => {
        if (isTableLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (tableData && tableData.length === 0) {
            setTimeout(() => {
                tableRef?.current?.api?.showNoRowsOverlay();
            }, 0);
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isTableLoading, tableData]);

    useEffect(() => {
        if (productId && !isTableLoading && isEqual(tableData, rowData)) {
            const idx = rowData?.findIndex((item) => item.id === productId);
            tableRef?.current?.api?.ensureIndexVisible?.(idx, 'top');
            searchParams.delete('productId');
            setSearchParams(searchParams);
        }
    }, [productId, rowData, tableData]);

    useEffect(() => {
        setKitsExpansionObj(JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'));
    }, []);

    const handleClearSelection = () => {
        tableRef.current?.api?.deselectAll();
    };

    const onClickMultipleDelete = () => {
        const ids = selectedItems.map((item: any) => item.id);
        setDeleteIds(ids);
        setOpenConfirmationDialog(true);
    };

    const onHideMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        try {
            await toggleHideData({
                bomId: bomId!,
                input
            });
            handleClearSelection();
            resetRowDataInitialised();
            setSnackbarMessage(hideMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onHighlightMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        onHighlight(input);
        handleClearSelection();
    };

    const onHighlight = async (ids: number[]) => {
        await toggleHighLightData({
            bomId: bomId!,
            input: ids
        });
        setSnackbarMessage(HIGHLIGHT_MSG);
        resetRowDataInitialised();
    };

    const onHide = async (props: any) => {
        const { data } = props;
        try {
            await toggleHideData({
                bomId: bomId!,
                input: [data.id]
            });
            resetRowDataInitialised();
            setSnackbarMessage(hideMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onDuplicate = async (props: any) => {
        const { data } = props;
        try {
            await duplicateData({
                bomId: bomId!,
                input: [data.id]
            });
            setSnackbarMessage(duplicateMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onDuplicateMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        try {
            await duplicateData({
                bomId: bomId!,
                input
            });
            handleClearSelection();
            setSnackbarMessage(duplicateMsg);
        } catch (e) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onAddBelow = async (props: any, updateTableKey?: boolean) => {
        const { id } = props.data;
        const item = {
            category: '',
            qty: null,
            manufacturer: '',
            model_number: '',
            previous_bod_id: id || kitData?.id,
            section_id: section?.id,
            section_type: isOptionTable ? SECTION_TYPE.OPTION : SECTION_TYPE.MAIN,
            ...(isKitTable ? { kit_id: kitData?.id } : {})
        };
        try {
            await addData({ bomId: bomId!, input: item });
            setSnackbarMessage(addMsg);
            if (updateTableKey) {
                handleClearSelection();
            }
        } catch (e) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onAdd = () => {
        const id = tableData?.length ? tableData[tableData!.length - 1].id : null;
        onAddBelow({ data: { id } }, true);
    };

    const onCellEdit = async (props: any) => {
        const { column, newValue, data } = props;
        const value = newValue ? (typeof newValue === 'string' && column.colId !== 'specification' ? newValue.trim().toUpperCase() : newValue) : column.colId === 'qty' ? null : '';

        try {
            await updateData({
                bomId: bomId!,
                input: { id: data.id, [column.colId]: value }
            });
        } catch (e) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onReorderTableData = async (arg: any) => {
        const updatedRowData: any = [];
        arg.api.forEachNode((node: any) => {
            updatedRowData.push({ ...node.data });
        });
        const bodIds = arg.nodes.map((item: any) => item.data.id);
        const overIndex = updatedRowData.findIndex((item: any) => item?.id === bodIds[0]);
        const prevBodId = overIndex === 0 ? (isKitTable ? kitData?.id : null) : updatedRowData[overIndex - 1].id;
        const input = { previous_bod_id: prevBodId, bod_id: bodIds };
        await reorderData({ bomId: bomId!, input });
        setSnackbarMessage(reorderMsg);
    };

    const onDeleteBulk = async () => {
        try {
            await deleteData({ bomId: bomId!, ids: deleteIds });
            setDeleteIds([]);
            closeConfirmationDialog();
            handleClearSelection();
            resetRowDataInitialised();
            setSnackbarMessage(deleteMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
    };

    const onClickDelete = (props: any) => {
        setDeleteIds([props.data.id]);
        setOpenConfirmationDialog(true);
    };

    const toggleMoveSectionDialog = () => setOpenMoveDialog((old) => !old);

    function checkIfProductsHasLots(ids: Array<number>) {
        const idsToCheck = new Set(ids);
        const isLotPresentInBods = tableData?.some((rowData) => idsToCheck.has(rowData.id) && Boolean(lotMap[rowData.id])) || false;

        return isLotPresentInBods;
    }

    const onClickMoveSection = (props: any) => {
        const isLotPresentInMovingBod = checkIfProductsHasLots([props.data.id]);
        setLotSelectedForMovingProducts(isLotPresentInMovingBod);
        setMovingIds([props.data.id]);
        openMoveSectionDialog();
    };

    const onMoveMultipleItems = () => {
        const ids = selectedItems.map((item: any) => item.id);
        const isLotPresentInMovingBods = checkIfProductsHasLots(ids);
        setLotSelectedForMovingProducts(isLotPresentInMovingBods);
        setMovingIds(ids);
        openMoveSectionDialog();
    };

    const updateKitCreationError = (error: KIT_ERROR_TYPE.KIT | KIT_ERROR_TYPE.OPTION | undefined) => setKitCreationError(error);

    const onCreateKit = async () => {
        const isKitSelected = selectedItems.some((item) => item.is_kit);
        if (isKitSelected) {
            return updateKitCreationError(KIT_ERROR_TYPE.KIT);
        }
        if (!isOptionTable) {
            let hasOptions = false;
            selectedItems.forEach((item) => {
                if (mainProductOptionsInfo?.[item.id]?.length) {
                    hasOptions = true;
                }
            });
            if (hasOptions) {
                return updateKitCreationError(KIT_ERROR_TYPE.OPTION);
            }
        }
        const id = tableData?.length ? tableData[tableData!.length - 1].id : null;
        const inputData = {
            bomId: Number(bomId),
            input: {
                category: '',
                qty: null,
                manufacturer: '',
                model_number: '',
                previous_bod_id: id,
                section_id: section?.id,
                components: selectedItems.map((item) => item.id),
                section_type: isOptionTable ? SECTION_TYPE.OPTION : SECTION_TYPE.MAIN
            }
        };

        await createKit(inputData);
        handleClearSelection();
    };

    function handleAddOptionClose() {
        setAddOptionModalOpen(false);
    }

    async function handleAddOptionConfirm(sectionId: number) {
        const { qty = 0, type = '', productToReplaceId = 0 } = bodInfoForAddOption || {};
        await onAddOption?.({ sectionId, qty, type, productToReplaceId });
        setAddOptionModalOpen(false);
    }

    async function handleAddOption(props: any) {
        const { id, category, qty } = props.data;
        const currentType = category?.trim();
        const optionType = currentType ? currentType + ' - Alt' : '';
        const productToReplaceId = id;
        if (noOfOptionSections === 1) {
            const sectionId = optionSection?.[0].id || 0;
            await onAddOption?.({ type: optionType, qty, productToReplaceId, sectionId });
        } else {
            setBodInfoForAddOption({ type: optionType, qty, productToReplaceId });
            setAddOptionModalOpen(true);
        }
        handleClearSelection();
    }

    async function approveOption(productId: number) {
        await postApproveOption({ bomId: Number(bomId), data: { bod_id: [productId] } });
    }

    const onCheckboxChange = (args: any) => {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedItems(selectedBods);
    };

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const resetRowDataInitialised = () => setRowDataInitialised(false);

    async function handleProductToReplaceChange(productToReplaceId: number | null, bodId: number) {
        await updateData({
            bomId: bomId!,
            input: { id: bodId, product_to_replace: productToReplaceId }
        });
    }
    const defaultLockColumnDef: ColDef[] = [
        {
            field: 'id',
            hide: true,
            lockPosition: 'left',
            resizable: false
        },
        { rowDrag: true, width: 10, maxWidth: 50, resizable: false, hide: isKitTable && !isComponentsTable, lockPosition: 'left', editable: false },
        { headerCheckboxSelection: true, checkboxSelection: true, width: 50, maxWidth: 50, resizable: false, hide: isKitTable && !isComponentsTable, lockPosition: 'left' },
        {
            cellRenderer: 'agGroupCellRenderer',
            resizable: false,
            editable: false,
            minWidth: 50,
            maxWidth: 50,
            hide: isKitTable,
            lockPosition: 'left'
        },
        {
            headerComponent: () => (
                <Box mt={1}>
                    <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                </Box>
            ),
            cellRenderer: isKitTable ? KitMenuOptions : MenuOptions,
            pinned: 'right',
            minWidth: 50,
            maxWidth: 50,
            colId: 'menu',
            resizable: false,
            hide: isKitTable && !isComponentsTable,
            cellStyle: getTemplateCellStyle,
            lockPosition: 'right',
            lockPinned: true,
            editable: false
        }
    ];

    const defaultColumnDef: Record<string, ColDef> = {
        category: { headerName: 'Type', field: 'category', editable: true, minWidth: 80, valueFormatter: 'value.toUpperCase()', cellEditorParams: TextCellParams, cellDataType: 'text' },
        qty: {
            headerName: isComponentsTable ? 'Qty per Kit' : 'Qty',
            editable: true,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: { precision: 0, max: 2147483647, preventStepping: true },
            minWidth: 80,
            field: 'qty',
            type: 'rightAligned'
        },
        manufacturer: {
            field: 'manufacturer',
            headerName: 'Manufacturer',
            editable: true,
            minWidth: 80,
            valueFormatter: 'value.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        model_number: {
            field: 'model_number',
            headerName: 'Model Number',
            editable: true,
            minWidth: 80,
            valueFormatter: 'value.toUpperCase()',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        description: { field: 'description', headerName: 'Description', editable: true, minWidth: 80, valueFormatter: 'value.toUpperCase()', cellEditorParams: TextCellParams, cellDataType: 'text' },
        product_to_replace: {
            field: 'product_to_replace',
            headerName: 'Product To Replace',
            editable: false,
            minWidth: 80,
            maxWidth: 700,
            cellRenderer: ProductToReplaceTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.data?.[PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_LABEL];
            },
            hide: !isOptionTable || isComponentsTable,
            cellStyle: getTemplateCellStyle
        },
        specification: {
            headerName: 'Specification',
            field: 'specification',
            minWidth: 80,
            maxWidth: 208,
            editable: false,
            getQuickFilterText: (params: any) => params.value,
            cellRenderer: SpecificationTemplate,
            cellStyle: getTemplateCellStyle
        },
        productFinder: {
            field: 'productFinder',
            headerComponent: () => <Box sx={{ maxWidth: '100%', whiteSpace: 'break-spaces', textAlign: 'center' }}>Product Finder</Box>,
            minWidth: 100,
            maxWidth: 100,
            editable: false,
            cellRenderer: productFinderAccess ? ProductFinderTemplate : ProductFinderDisabledTemplate,
            cellStyle: { textAlign: 'center', ...getTemplateCellStyle }
        }

        // Add other fields as needed
    };

    const columnDefs = useMemo<ColDef[]>(() => {
        const orderedColumns = columnOrderDetails?.order.reduce((result: ColDef[], field: string) => {
            // Check if field exists in defaultColumnDef
            if (Object.hasOwn(defaultColumnDef, field)) {
                const column = {
                    ...defaultColumnDef[field]
                };

                // Apply width if available in defaultColumnState.width
                if (columnOrderDetails?.width) {
                    const widthField = field as keyof typeof columnOrderDetails.width;
                    column.width = columnOrderDetails?.width[widthField];
                }

                result.push(column);
            }

            return result;
        }, []);

        return [...defaultLockColumnDef, ...(orderedColumns || [])];
    }, [productFinderAccess, isColumnDataLoading, columnOrderDetails]);

    const handleSearch = (searchString: string) => {
        updateQuickFilerText(searchString);
    };

    const openMoveSectionDialog = () => setOpenMoveDialog((old) => !old);

    const onCloseBanner = () => {
        setSelectedItems([]);
        handleClearSelection();
    };

    const handleProcessCellPasteForQty = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column.getColId();

        addNewRowsToTheTable(selectedRowsRef, tableRef, fieldName, createNewRowsFromClipboard);

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        }
        return value;
    };

    function handleProcessForClipboard(props: ProcessCellForExportParams) {
        const fieldName = props.column.getColId();

        if (fieldName === 'specification' || fieldName === 'product_to_replace') {
            return '';
        }
        return props.value;
    }

    const getIfRowMaster = useCallback((dataItem: any) => {
        return dataItem ? dataItem.is_kit : false;
    }, []);

    const onRowGroupOpened = useCallback(
        (e: RowGroupOpenedEvent<any, any>) => {
            const {
                rowIndex,
                node: { expanded, id }
            } = e;
            if (rowIndex !== null) {
                if (expanded) {
                    expandedRowsContainerRef.current.push(rowIndex);
                } else {
                    expandedRowsContainerRef.current.pop();
                }
            }
            setExpandedRows([...expandedRowsContainerRef.current]);

            const obj = { ...JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'), [`${id}`]: expanded };
            setKitsExpansionObj?.(obj);
            localStorage.setItem(`${bomId}-kits`, JSON.stringify(obj));
        },
        [kitsExpansionObj]
    );

    const toggleKits = useCallback(
        (params: any) => {
            if (kitsExpansionObj && Object.keys(kitsExpansionObj).length > 0) {
                Object.keys(kitsExpansionObj).map((id: string) => {
                    const rowNode = params.api.getRowNode(id);
                    if (rowNode) {
                        rowNode.setExpanded(kitsExpansionObj?.[id]);
                    }
                });
            }
        },
        [kitsExpansionObj]
    );

    const detailCellRenderer = useMemo(
        () => (props: ICellRendererParams<any, any, any>) => <BOMDetailGrid {...props} updateDetailGridContainerHeight={updateDetailGridContainerHeight} selectedRowsRef={selectedRowsRef} />,
        []
    );

    function handleOnGridReady(params: any) {
        if (onGridReady) {
            onGridReady(params);
        }
    }

    const handleMoveProductDialog = useCallback(() => {
        setOpenMoveProductDialog(false);
    }, []);

    function onProductMoveIn(currentBodId: number) {
        setCurrentBodId(currentBodId);
        setOpenMoveProductDialog(true);
    }

    //  TODO -> Keeping this is the reference because in the parent, refs are being maintained as state;
    const getTableMetaData = useCallback(() => {
        if (!tableRef?.current) return null;
        return tableRef.current;
    }, []);

    async function onProductMoveOut(params: any) {
        const { data } = params;
        await updateData({ bomId: String(bomId), input: { kit: null, id: data.id }, isInvalidateBodList: true });
        setSnackbarMessage(REMOVE_PRODUCT_FROM_KIT);
    }

    const addSnackBarMessage = useCallback((message: string) => {
        setSnackbarMessage(message);
    }, []);

    const handleColumnMoved = async (e: ColumnMovedEvent) => {
        if (!isComponentsTable) {
            const savedState = e.api.getColumnState();
            const newColumnOrder = savedState.map((column: ColumnState) => column.colId);
            const exceptDefaultColumns = newColumnOrder.slice(defaultLockColumnDef.length - 1);
            const userId = userData?.data?.id;
            const newArraySet = new Set(exceptDefaultColumns);
            const sortedArray: string[] = [...exceptDefaultColumns];
            if (columnOrderDetails && columnOrderDetails.order) {
                sortedArray.push(...columnOrderDetails.order.filter((item: string) => !newArraySet.has(item)));
            }
            handleTablesReordering?.(exceptDefaultColumns);
            if (e.finished && e.source === 'uiColumnMoved') {
                await saveColumnOrderEvents(userId, sortedArray);
            }
        }
    };

    const handleColumnResized = async (props: ColumnResizedEvent) => {
        if (!isComponentsTable) {
            const colId = props?.column?.getColId() as string;
            const newChangedWidth = props?.column?.getActualWidth();
            const currentWidths = columnOrderDetails?.width;
            const userId = userData?.data?.id;
            const columnState = props.api.getColumnState();
            const ignorePinnedColumnState = columnState.slice(defaultLockColumnDef.length - 1);
            const columnWidths = ignorePinnedColumnState.reduce((acc: any, item) => {
                acc[item.colId] = item.width;
                return acc;
            }, {});
            const newWidths = { ...currentWidths, ...columnWidths };

            handleTablesWidthChanged?.(colId, newChangedWidth);
            if (props.finished && props.source === 'uiColumnResized' && userId) {
                await saveColumnWidthEvents(userId, newWidths);
            }
        }
    };

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event || e.event.defaultPrevented) {
            return;
        }
        // stop the default behavior of the event. While adding a row in the component table, the event must not trigger onAdd() function call for the kit table
        isComponentsTable && e.event.preventDefault();
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        if ((keyboardEvent.ctrlKey || keyboardEvent.metaKey) && keyboardEvent.key === 'i') {
            onAddBelow(e);
        }
    }, []);

    const currentTableHeight = isKitTable ? kitTableHeight : tableHeight! + 6;
    const calculatedTableHeight = !isComponentsTable && expandedRows.length ? (currentTableHeight as number) + detailGridContainerHeight : currentTableHeight;

    return (
        <>
            <Portal>
                <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            </Portal>
            <MenuOptionsContext.Provider
                value={{
                    isOptionTable,
                    bomId,
                    onAddBelow,
                    onClickMoveSection,
                    onDuplicate,
                    onHide,
                    onClickDelete,
                    handleAddOption,
                    approveOption,
                    noOfOptionSections,
                    onProductMoveIn,
                    onProductMoveOut,
                    onHighlight
                }}
            >
                <TableContext.Provider
                    value={{
                        onProductToReplaceChange: handleProductToReplaceChange,
                        lotMap,
                        section,
                        isOptionTable,
                        onCellEdit
                    }}
                >
                    <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                        <CustomToolBarPanel
                            toolBarOptions={isKitTable ? (isComponentsTable ? kitToolbarItems : []) : (toolBarOptions as ToolbarType)}
                            selectedRowCount={selectedItems.length}
                            disabledToolBarButton={selectedItems.length === 0}
                            onAdd={onAdd}
                            onHideUnhide={onHideMultiple}
                            onDelete={onClickMultipleDelete}
                            onAddDuplicates={onDuplicateMultiple}
                            onMove={onMoveMultipleItems}
                            onTextSearch={handleSearch}
                            onCreateKit={onCreateKit}
                            onCloseBanner={onCloseBanner}
                            onHighlight={onHighlightMultiple}
                            loading={isTableLoading}
                            isKitSelected={selectedItems.some((item) => item.is_kit)}
                        />
                        <Box style={{ height: calculatedTableHeight }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={tableRef}
                                rowData={rowData}
                                getRowId={getRowId}
                                quickFilterText={quickFilterText}
                                rowHeight={40}
                                gridOptions={{
                                    enableRangeSelection: true,
                                    enableFillHandle: true,
                                    undoRedoCellEditing: true,
                                    enterNavigatesVerticallyAfterEdit: true,
                                    enterNavigatesVertically: true,
                                    rowDragManaged: true,
                                    rowSelection: 'multiple',
                                    suppressRowClickSelection: true,
                                    suppressDragLeaveHidesColumns: true,
                                    suppressContextMenu: true,
                                    stopEditingWhenCellsLoseFocus: true,
                                    suppressLastEmptyLineOnPaste: true,
                                    rowClassRules: {
                                        'row-hide': (params: any) => params?.data?.is_hidden,
                                        'e-highlightscroll': (params: any) => (productId && params?.data?.id === productId ? true : false),
                                        'row-highlight': (params: RowClassParams) => (params?.data?.is_kit || isComponentsTable ? false : params?.data?.is_highlighted),
                                        'e-rowhighlight-highlighscroll': (params: RowClassParams) => (productId && params?.data?.id === productId && params?.data?.is_highlighted ? true : false)
                                    },

                                    rowDragMultiRow: true
                                }}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                modules={[ClipboardModule, GridChartsModule]}
                                onCellValueChanged={onCellEdit}
                                onRowDragEnd={onReorderTableData}
                                onSelectionChanged={onCheckboxChange}
                                processCellFromClipboard={handleProcessCellPasteForQty}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} helperText={NO_BOD_MSG} />}
                                loadingOverlayComponent={isTableLoading ? loadingOverlayComponent : null}
                                processCellForClipboard={handleProcessForClipboard}
                                masterDetail
                                onColumnMoved={handleColumnMoved}
                                detailCellRenderer={detailCellRenderer}
                                reactiveCustomComponents
                                isRowMaster={getIfRowMaster}
                                detailRowAutoHeight
                                onColumnResized={handleColumnResized}
                                onRowGroupOpened={onRowGroupOpened}
                                onGridReady={handleOnGridReady}
                                onRowDragLeave={onReorderTableData}
                                onCellKeyDown={onCellKeyDown}
                                onFirstDataRendered={toggleKits}
                                onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsRef)}
                            />
                        </Box>
                        {!isKitTable && <AddMultipleRows sectionId={section?.id || 0} isOptionTable={isOptionTable} />}
                    </Box>
                </TableContext.Provider>
            </MenuOptionsContext.Provider>
            {openConfirmationDialog && (
                <DeleteConfirmationDialog
                    title={deleteIds.length > 1 ? deleteDialogTitle : areYouSure}
                    open={openConfirmationDialog}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={onDeleteBulk}
                    loading={deleteLoading}
                >
                    {isKitTable ? (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                {deleteContent}
                            </BodySmall>
                        </Box>
                    ) : (
                        <Box width="40vw" my={4}>
                            <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                                {deleteTitle(deleteIds.length)}
                            </BodySmall>
                            <BodySmall limit={false} my={1} color="neutral.dark">
                                <p style={{ whiteSpace: 'pre-line' }}>{deleteMessage(mainProductOptionsInfo!, deleteIds)}</p>
                            </BodySmall>
                        </Box>
                    )}
                </DeleteConfirmationDialog>
            )}
            {openMoveDialog && (
                <MoveSectionDialog
                    open={openMoveDialog}
                    handleDialog={toggleMoveSectionDialog}
                    movingIds={movingIds}
                    handleClearSelection={handleClearSelection}
                    currentSection={section}
                    isLotsPresentInBods={isLotSelectedForMovingProducts}
                    updateLocalData={(val: boolean) => setRowDataInitialised(val)}
                    mainProductOptionsInfo={mainProductOptionsInfo}
                />
            )}
            {openMoveProductDialog && currentBodId && (
                <MoveProductDialog
                    open={openMoveProductDialog}
                    handleClose={handleMoveProductDialog}
                    currentBodId={currentBodId}
                    getTableMetaData={getTableMetaData}
                    sectionId={section!.id}
                    setSnackbarOpen={addSnackBarMessage}
                    isOptionTable={isOptionTable}
                />
            )}
            {kitCreationError && (
                <ErrorDialog
                    open={Boolean(kitCreationError)}
                    handleDialog={() => updateKitCreationError(undefined)}
                    title="Unable to Create Kit"
                    text={KIT_ERROR[kitCreationError].text}
                    helperText={KIT_ERROR[kitCreationError].helperText}
                />
            )}

            {isAddOptionModalOpen && <AddOptionModal open={isAddOptionModalOpen} onConfirm={handleAddOptionConfirm} onClose={handleAddOptionClose} isAddOptionInProgress={isAddOptionLoading} />}
        </>
    );
});

export default BomTable;
