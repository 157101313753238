import { FC, useState, useMemo, useCallback, useEffect } from 'react';

import { Box, dayjs, MultiSelectOptionType } from '@parspec/pixel';
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar';
import { isEqual } from 'lodash-es';

import { useGetProjectsQuery } from '../../Dashboard/queries';
import Loader from '../../BOM/FinalDocuments/Compile/submittals&o_m/Loader';
import { EventDataType } from '../shared/types';
import EventCard from './EventCard';
import CalendarToolbar from './CalendarToolbar';
import Filters from './Filters';
import { DUE_DATE_TYPES, FILTERS_INITIAL_VALUE, FILTER_KEYS } from '../shared/constants';
import WeeklySummary from './WeeklySummary';
import { ProjectInfoForDashboard } from '../../Dashboard/queries/apiTypes';
import { getOwnersFromLocations, getValuesFromArr } from '../shared/utils';
import ProjectDetail from './ProjectDetail';
import { convertToDollar } from '../../shared/utils/utils';
import { VIEW_OPTION_VALUES } from '../../Dashboard/shared/constants';
import { useGetAllCompanyDetailsQuery } from '../../shared/CreateAndEditProjectModal/queries';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { getIfWeekendDates } from '../../shared/CreateAndEditProjectModal/utils';
import { useGetPersistedFiltersQuery, usePersistedFiltersMutation } from '../../shared/queries';
import { EVENT_SERVICE_PAGENAME_VALUES } from '../../shared/utils/constants';

const localizer = dayjsLocalizer(dayjs);

const CalendarView: FC = () => {
    const [selectedEventId, setSelectedEventId] = useState('');
    const [selectedWeekDate, setSelectedWeekDate] = useState<Date>();

    const { data: allCompanyDetails, isLoading: allCompaniesLoading } = useGetAllCompanyDetailsQuery();
    const { data: projectsData, isLoading } = useGetProjectsQuery(VIEW_OPTION_VALUES.TEAM_WORK);
    const { data: userData, isLoading: userDataLoading } = useGetUserProfileInfoQuery();
    const { data: filtersData, isFetching: filtersDataFetching } = useGetPersistedFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: EVENT_SERVICE_PAGENAME_VALUES.BIDBOARD },
        {
            enabled: Boolean(userData?.data?.id)
        }
    );
    const { mutate: persistFilters } = usePersistedFiltersMutation();

    const filters = useMemo(() => {
        return filtersData?.table_settings_by_pk?.filters || FILTERS_INITIAL_VALUE;
    }, [filtersData?.table_settings_by_pk?.filters]);

    useEffect(() => {
        if (filtersData?.table_settings_by_pk?.filters && allCompanyDetails?.data) {
            const persistedFilters = { ...filtersData?.table_settings_by_pk?.filters };

            //this is done so that we show updated filter value if the owner name is updated/deleted
            const ownerOptions = getOwnersFromLocations(allCompanyDetails?.data).reduce((acc: any, curr: any) => {
                acc[curr.value] = curr;
                return acc;
            }, {});
            persistedFilters.owners = (persistedFilters.owners || []).reduce((acc: MultiSelectOptionType[], curr: MultiSelectOptionType) => {
                if (ownerOptions[curr.value]) {
                    acc.push(ownerOptions[curr.value]);
                }
                return acc;
            }, []);

            //this is done so that we show updated filter value if the location name is updated/deleted
            const locationOptions = (allCompanyDetails?.data || []).reduce((acc: any, curr: any) => {
                acc[curr.company_details.company_id] = { label: curr.company_details.company_name, value: curr.company_details.company_id };
                return acc;
            }, {});
            persistedFilters.branchLocations = (persistedFilters.branchLocations || []).reduce((acc: MultiSelectOptionType[], curr: MultiSelectOptionType) => {
                if (locationOptions[curr.value]) {
                    acc.push(locationOptions[curr.value]);
                }
                return acc;
            }, []);

            //this is done so that we can save updated values of filter in event service
            if (!isEqual(persistedFilters, filtersData?.table_settings_by_pk?.filters)) {
                persistFilters({ filters: persistedFilters, userId: userData?.data?.id, pageName: EVENT_SERVICE_PAGENAME_VALUES.BIDBOARD });
            }
        }
    }, [filtersData, allCompanyDetails]);

    const onSelectEvent = (eventId: string) => {
        if (eventId === selectedEventId) setSelectedEventId('');
        else setSelectedEventId(eventId);
    };

    const handleFiltersUpdate = (key: string, value: string | MultiSelectOptionType[]) => {
        persistFilters({ filters: { ...filters, [key]: value }, userId: userData?.data?.id, pageName: EVENT_SERVICE_PAGENAME_VALUES.BIDBOARD });
    };

    const handleFilterEvent = useCallback(
        (event: EventDataType) => {
            if (filters[FILTER_KEYS.SEARCH_TEXT] && !event.title.toLowerCase().includes(filters[FILTER_KEYS.SEARCH_TEXT].toLowerCase())) {
                return false;
            }
            if (filters[FILTER_KEYS.DUE_DATE_TYPES].length) {
                const filteredTypes = getValuesFromArr(filters[FILTER_KEYS.DUE_DATE_TYPES]);
                if (!filteredTypes.includes(event.type)) return false;
            }
            if (filters[FILTER_KEYS.PRIORITIES].length) {
                const filteredPriorities = getValuesFromArr(filters[FILTER_KEYS.PRIORITIES]);
                if (!filteredPriorities.includes(event.priority)) return false;
            }
            if (filters[FILTER_KEYS.BRANCH_LOCATIONS].length) {
                const filteredLocations = getValuesFromArr(filters[FILTER_KEYS.BRANCH_LOCATIONS]);
                if (!filteredLocations.includes(event.locationId)) return false;
            }
            if (filters[FILTER_KEYS.PROJECT_STAGES].length) {
                const filteredStages = getValuesFromArr(filters[FILTER_KEYS.PROJECT_STAGES]);
                if (!filteredStages.includes(event.stage)) return false;
            }
            if (filters[FILTER_KEYS.OWNERS].length) {
                const filteredLocations = getValuesFromArr(filters[FILTER_KEYS.OWNERS]);
                if (!filteredLocations.includes(event.ownerId)) return false;
            }
            return true;
        },
        [filters]
    );

    //this is used because even if the user is deleted, then also it is coming in project detail
    // if  he was the owner of any of the milestone
    const getOwnerInfo = (companyName: string, { ownerId, owner }: { ownerId: number; owner: string }) => {
        const selectedLocation = allCompanyDetails?.data?.find((item) => item.company_details.company_name === companyName);
        if (selectedLocation) {
            const user = (selectedLocation?.users || []).find((user) => user.id === ownerId);
            if (user) return { ownerId, owner: user?.name || owner };
        }
        return { owner: 'Unassigned', ownerId: -1 };
    };

    const bidBoardData = useMemo(() => {
        let data: EventDataType[] = [];
        if (projectsData?.data && allCompanyDetails?.data) {
            if (projectsData?.data) {
                projectsData.data.forEach((project: ProjectInfoForDashboard) => {
                    const {
                        id,
                        name,
                        o_m_due_date,
                        quote_due_date,
                        submittal_due_date,
                        o_m_owner_name,
                        quote_owner_name,
                        submittal_owner_name,
                        stage,
                        estimated_project_cost,
                        company_name,
                        company_id,
                        submittal_owner_id,
                        o_m_owner_id,
                        quote_owner_id,
                        priority
                    } = project;
                    const types = [
                        {
                            date: o_m_due_date,
                            type: DUE_DATE_TYPES.OM,
                            ...getOwnerInfo(company_name, { owner: o_m_owner_name || '', ownerId: o_m_owner_id })
                        },
                        {
                            date: quote_due_date,
                            type: DUE_DATE_TYPES.QUOTE,
                            ...getOwnerInfo(company_name, { owner: quote_owner_name || '', ownerId: quote_owner_id })
                        },
                        {
                            date: submittal_due_date,
                            type: DUE_DATE_TYPES.SUBMITTAL,
                            ...getOwnerInfo(company_name, { owner: submittal_owner_name || '', ownerId: submittal_owner_id })
                        }
                    ];
                    types.forEach((type) => {
                        if (type.date && !getIfWeekendDates(dayjs(type.date))) {
                            const event = {
                                ...type,
                                date: type.date,
                                start: type.date,
                                end: type.date,
                                projectId: id,
                                title: name,
                                allDay: true,
                                id: `${id}-${type.type}`,
                                cost: estimated_project_cost !== null ? convertToDollar(estimated_project_cost) : null,
                                stage,
                                location: company_name,
                                locationId: company_id,
                                priority
                            };
                            const pushEventToData = handleFilterEvent(event);
                            if (pushEventToData) data.push(event);
                        }
                    });
                });
            }
            //sorting
            data = [...data].sort((a, b) => {
                if (dayjs(a.date) > dayjs(b.date)) {
                    return 1;
                }
                if (dayjs(a.date).format() === dayjs(b.date).format()) {
                    const priorityOrder = { High: 3, Medium: 2, Low: 1 };
                    return priorityOrder[a.priority] > priorityOrder[b.priority] ? -1 : 1;
                }
                return -1;
            });
        }
        return data;
    }, [projectsData, filters, allCompanyDetails?.data]);

    return (
        <>
            {isLoading || allCompaniesLoading || filtersDataFetching || userDataLoading ? (
                <Loader />
            ) : (
                <Box height={1} width={1} p={4} bgcolor="neutral.light" display="flex" flexDirection="column" overflow="hidden">
                    <Filters filters={filters} handleFiltersUpdate={handleFiltersUpdate} />
                    <Box overflow="hidden" display="flex" height={1} gap={6}>
                        <Box width="70%">
                            <Calendar
                                localizer={localizer}
                                defaultView={Views.WORK_WEEK}
                                events={bidBoardData}
                                showMultiDayTimes
                                step={60}
                                views={[Views.WORK_WEEK]}
                                components={{
                                    event: (props: any) => <EventCard {...props} onSelectEvent={onSelectEvent} selectedEventId={selectedEventId} />,
                                    work_week: {
                                        header: ({ date, localizer }) => localizer.format(date, 'dddd, DD') as any
                                    },
                                    toolbar: CalendarToolbar
                                }}
                                tooltipAccessor={null}
                                onNavigate={(date: Date) => setSelectedWeekDate(date)}
                            />
                        </Box>
                        <Box width="30%" display="flex" flexDirection="column" gap={4}>
                            <WeeklySummary bidBoardData={bidBoardData} selectedEventId={selectedEventId} selectedWeekDate={selectedWeekDate} />
                            {selectedEventId ? <ProjectDetail projectId={Number(selectedEventId?.split('-')?.[0] || 0)} /> : null}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default CalendarView;
