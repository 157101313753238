import { useMemo, useRef, useState } from 'react';

import { ValueGetterParams, ICellRendererParams, ColDef } from 'ag-grid-community';
import { isEqual } from 'lodash-es';

import { Snackbar, Box, BodySmall, AgGridTable, TableHeaderMenuIcon, SelectChangeEvent } from '@parspec/pixel';

import {
    useDeleteProjectMutation,
    useGetProjectsQuery,
    userDashboardViewMutation,
    useGetSelectedColumnsAndPersistingFiltersQuery,
    useUpdateSelectedColumnsMutation,
    useUpdatePersistingFiltersMutation
} from '../queries';
import { DashboardPageNameType } from '../queries/apiTypes';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { deleteMsg, someThingWentWrongMsg, PROJECT_DELETE_HEADING, PROJECT_DELETE_SUB_HEADING, PROJECT_DELETE_TITLE } from '../../shared/constants';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import { useGetUserDashboardViews } from '../shared/hooks';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { getEstimatedValueTemplate, getProjectRowData, getRowId, dollarFormatter } from './utils';
import { ProjectRowData } from './types';
import { MenuContext } from './context';
import { stringComparator, getStageTemplate, getStakeholderValue, getDateComparator, numberComparator, getDateValueForUI, dateFilterParams } from '../shared/utils';
import { STAKEHOLDER_POSITION } from '../shared/constants';
import { MenuOption, defaultColDef } from './components';
import TableRightSection from '../shared/components/TableRightSection';
import { ManageColumnsPopup } from '../shared/components/ManageColumnsPopup/ManageColumnsPopup';
import { projectPageTableColumnOptions } from './utils';
import { ProjectTemplate, Customer, DateTemplate } from '../shared/components';

const defaultLockColumnDef: ColDef[] = [
    {
        field: 'projectId',
        hide: true,
        lockPosition: 'left',
        resizable: false
    },

    {
        minWidth: 60,
        maxWidth: 60,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        cellStyle: { backgroundColor: 'unset' },
        cellRenderer: MenuOption,
        editable: false,
        pinned: 'right',
        colId: 'menu',
        resizable: false,
        lockPosition: 'right',
        lockPinned: true
    }
];

const STAKEHOLDER_KEYS = ['stakeholders[0]', 'stakeholders[1]', 'stakeholders[2]', 'stakeholders[3]', 'stakeholders[4]'];

const ProjectsTable = () => {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { enable_quoting } = useFeatureFlag();
    const { isDashboardViewsLoading, dashboardSelectedViews } = useGetUserDashboardViews();

    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();
    const { data: paymentDetail, isLoading: isPaymentDetailLoading } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const { data: projectsData, isLoading } = useGetProjectsQuery(dashboardSelectedViews, {
        enabled: !isDashboardViewsLoading
    });

    const { data: selectedColumnsAndFiltersQueryData, isFetching: isSelectedColumnsAndFiltersQueryDataFetching } = useGetSelectedColumnsAndPersistingFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: 'dashboard_projects' },
        { enabled: !isDashboardViewsLoading && !isUserDataLoading }
    );

    const { mutateAsync: deleteProject, isLoading: deleteLoading } = useDeleteProjectMutation();
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();
    const { mutateAsync: updateSelectedFiltersMutation } = useUpdatePersistingFiltersMutation();

    const tableRef = useRef<any>();

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const onDeleteLineItem = async () => {
        try {
            await deleteProject(deleteId!);
            setSnackbarMessage(deleteMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
        setDeleteId(null);
        closeConfirmationDialog();
    };

    const onClickDelete = (props: any) => {
        if (isActiveSubscription) {
            setDeleteId(props.projectId);
            setOpenConfirmationDialog(true);
        } else handleSubscriptionPopup();
    };

    const handleViewChange = (props: SelectChangeEvent<unknown>) => {
        if (userData?.data.id) userDashboardViewMutation(userData?.data?.id, props?.target?.value as string);
    };

    const defaultColumnDef: any = {
        projectName: {
            field: 'projectName',
            minWidth: 200,
            cellRenderer: (params: ICellRendererParams) => <ProjectTemplate {...(params?.data || {})} />,
            headerName: 'Project Name',
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectStage: {
            field: 'projectStage',
            headerName: 'Project Stage',
            cellRenderer: getStageTemplate,
            minWidth: 150,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        estimatedProjectCost: {
            field: 'estimatedProjectCost',
            headerName: 'Estimated Value',
            cellRenderer: getEstimatedValueTemplate,
            minWidth: 150,
            valueGetter: (params: ValueGetterParams) => dollarFormatter(params.data.estimatedProjectCost),
            comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
                const valA = Number(nodeA?.data?.estimatedProjectCost);
                const valB = Number(nodeB?.data?.estimatedProjectCost);

                return numberComparator(valA, valB);
            },
            sortable: true
        },
        branchLocation: {
            field: 'branchLocation',
            headerName: 'Branch Location',
            minWidth: 150,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        quoteDueDate: {
            field: 'quoteDueDate',
            headerName: 'Quote Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.quoteDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'quoteDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'quoteDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false,
            hide: !enable_quoting
        },
        submittalDueDate: {
            field: 'submittalDueDate',
            headerName: 'Submittal Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.submittalDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'submittalDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'submittalDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        omDueDate: {
            field: 'omDueDate',
            headerName: 'O&M Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.omDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'omDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'omDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        quoteOwner: {
            field: 'quoteOwner',
            headerName: 'Quote Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            hide: !enable_quoting
        },
        submittalOwner: {
            field: 'submittalOwner',
            headerName: 'Submittal Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        omOwner: {
            field: 'omOwner',
            headerName: 'O&M Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        address1: {
            field: 'address1',
            headerName: 'Address Line 1',
            minWidth: 200,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        address2: {
            field: 'address2',
            headerName: 'Address Line 2',
            minWidth: 200,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        country: {
            field: 'country',
            headerName: 'Country',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        city: {
            field: 'city',
            headerName: 'City',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        state: {
            field: 'state',
            headerName: 'State',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        zip: {
            field: 'zip',
            headerName: 'Zip',
            minWidth: 200,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        buildingType: {
            field: 'buildingType',
            headerName: 'Market vertical',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        constructionScope: {
            field: 'constructionScope',
            headerName: 'Construction Scope',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        size: {
            field: 'size',
            headerName: 'Square Footage',
            minWidth: 200,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        'stakeholders[0]': {
            field: 'stakeholders[0]',
            headerName: STAKEHOLDER_POSITION.FIRST.label,
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => Customer(props?.data?.stakeholders[0]),
            valueGetter: (params: ValueGetterParams) => getStakeholderValue(params, '0'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        'stakeholders[1]': {
            field: 'stakeholders[1]',
            headerName: STAKEHOLDER_POSITION.SECOND.label,
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => Customer(props?.data?.stakeholders[1]),
            valueGetter: (params: ValueGetterParams) => getStakeholderValue(params, '1'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        'stakeholders[2]': {
            field: 'stakeholders[2]',
            headerName: STAKEHOLDER_POSITION.THIRD.label,
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => Customer(props?.data?.stakeholders[2]),
            valueGetter: (params: ValueGetterParams) => getStakeholderValue(params, '2'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        'stakeholders[3]': {
            field: 'stakeholders[3]',
            headerName: STAKEHOLDER_POSITION.FOURTH.label,
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => Customer(props?.data?.stakeholders[3]),
            valueGetter: (params: ValueGetterParams) => getStakeholderValue(params, '3'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        'stakeholders[4]': {
            field: 'stakeholders[4]',
            headerName: STAKEHOLDER_POSITION.FIFTH.label,
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => Customer(props?.data?.stakeholders[4]),
            valueGetter: (params: ValueGetterParams) => getStakeholderValue(params, '4'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        }
    };

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsAndFiltersQueryData?.table_settings || isSelectedColumnsAndFiltersQueryDataFetching) {
            return;
        }

        if ((selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.prototype.hasOwnProperty.call(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [enable_quoting, isSelectedColumnsAndFiltersQueryDataFetching, selectedColumnsAndFiltersQueryData?.table_settings]);

    function handleExportExcel() {
        const params = {
            fileName: 'Projects_Parspec_Export.xlsx', // Specify the filename
            sheetName: 'Projects' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number } = { pageName: 'dashboard_projects', columnData: rowData, userId: userData?.data?.id || 0 };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const projectDataForTable = useMemo(() => {
        let projectDataForTable: ProjectRowData[] = [];
        if (!isLoading && projectsData?.data) {
            projectDataForTable = projectsData.data.map(getProjectRowData);
        }

        return projectDataForTable;
    }, [projectsData?.data, isLoading]);

    const allColumnOptions = useMemo(() => {
        const fieldsToExcludeIfNoQuotingAccess = ['quoteDueDate', 'quoteOwner'];
        if (!enable_quoting) {
            const res = projectPageTableColumnOptions.data.filter((item) => !fieldsToExcludeIfNoQuotingAccess.includes(item.field));
            return res;
        } else {
            return projectPageTableColumnOptions.data;
        }
    }, [enable_quoting]);

    const onFirstDataRendered = (params: any) => {
        if (selectedColumnsAndFiltersQueryData?.table_settings.length === 0 || Object.keys(selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters || {}).length === 0) {
            return;
        }

        const response = selectedColumnsAndFiltersQueryData?.table_settings?.[0].filters;

        const filterKeys = Object.keys(response);
        filterKeys.forEach((key: string) => {
            if (key === 'stakeholders' && Object.keys(response[key]).length > 0) {
                const stakeholderKeys = Object.keys(response[key]);

                stakeholderKeys.forEach((stakeholderNumber: string) => {
                    if (stakeholderNumber === 'stakeholders_0') {
                        params.api.getFilterInstance('stakeholders[0]').setModel(response[key][stakeholderNumber]);
                    } else if (stakeholderNumber === 'stakeholders_1') {
                        params.api.getFilterInstance('stakeholders[1]').setModel(response[key][stakeholderNumber]);
                    } else if (stakeholderNumber === 'stakeholders_2') {
                        params.api.getFilterInstance('stakeholders[2]').setModel(response[key][stakeholderNumber]);
                    } else if (stakeholderNumber === 'stakeholders_3') {
                        params.api.getFilterInstance('stakeholders[3]').setModel(response[key][stakeholderNumber]);
                    } else if (stakeholderNumber === 'stakeholders_4') {
                        params.api.getFilterInstance('stakeholders[4]').setModel(response[key][stakeholderNumber]);
                    }
                });
            } else {
                params.api.getFilterInstance(key).setModel(response[key]);
            }
        });

        params.api.onFilterChanged();
    };

    const handleFilterChanged = async (params: any) => {
        const { api } = params;
        const filterModel = api.getFilterModel();

        const apiResponse = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters;

        const filterModelObj: { [key: string]: any } = {};
        const stakeholdersObj: { [key: string]: any } = {};

        const filterModelKeys = Object.keys(filterModel);

        // Extract data of all keys of filterModel except the stakeholders data
        filterModelKeys.forEach((key: string) => {
            if (!STAKEHOLDER_KEYS.includes(key)) {
                filterModelObj[key] = filterModel[key];
            }
        });

        STAKEHOLDER_KEYS.forEach((key: string) => {
            if (Object.prototype.hasOwnProperty.call(filterModel, key)) {
                if (key === 'stakeholders[0]') {
                    stakeholdersObj.stakeholders_0 = filterModel[key];
                } else if (key === 'stakeholders[1]') {
                    stakeholdersObj.stakeholders_1 = filterModel[key];
                } else if (key === 'stakeholders[2]') {
                    stakeholdersObj.stakeholders_2 = filterModel[key];
                } else if (key === 'stakeholders[3]') {
                    stakeholdersObj.stakeholders_3 = filterModel[key];
                } else if (key === 'stakeholders[4]') {
                    stakeholdersObj.stakeholders_4 = filterModel[key];
                }
            }
        });

        let filterModelPayload = {};
        if (Object.keys(stakeholdersObj).length > 0) {
            filterModelPayload = { ...filterModelObj, stakeholders: stakeholdersObj };
        } else {
            filterModelPayload = filterModelObj;
        }

        if (!isEqual(filterModelPayload, apiResponse)) {
            // call mutation
            const payload: { pageName: DashboardPageNameType; filterData: any; userId: number } = { pageName: 'dashboard_projects', filterData: filterModelPayload, userId: userData?.data?.id || 0 };
            await updateSelectedFiltersMutation(payload);
        }
    };

    const isTableDataLoading =
        isLoading || isPaymentDetailLoading || isUserDataLoading || isDashboardViewsLoading || isSelectedColumnsAndFiltersQueryDataFetching || isUpdateSelectedColumnsMutationLoading;

    return (
        <>
            <Snackbar open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <MenuContext.Provider value={{ onDelete: onClickDelete, tabName: 'PROJECTS' }}>
                    <AgGridTable
                        ref={tableRef}
                        getRowId={getRowId}
                        isLoading={isTableDataLoading}
                        rowHeight={52}
                        showToolbarPanel
                        toolBarPanelOptions={['search', 'clearFilters']}
                        onClearFilter={clearAllFilters}
                        toolbarRightSection={
                            <TableRightSection
                                selectedView={dashboardSelectedViews}
                                isMyViewLoading={isLoading}
                                onMyViewChange={handleViewChange}
                                isTableLoading={isTableDataLoading}
                                onExportClick={handleExportExcel}
                                onManageColumnsClick={onManageColumnsClick}
                            />
                        }
                        rowData={isTableDataLoading ? null : projectDataForTable}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        pagination
                        paginationAutoPageSize
                        suppressContextMenu
                        suppressMenuHide
                        onTextSearch={handleSearch}
                        quickFilterText={searchText}
                        onFirstDataRendered={onFirstDataRendered}
                        onFilterChanged={handleFilterChanged}
                    />
                </MenuContext.Provider>
            </Box>
            {openConfirmationDialog && (
                <DeleteConfirmationDialog title={PROJECT_DELETE_TITLE} open={openConfirmationDialog} handleDialog={closeConfirmationDialog} confirmAction={onDeleteLineItem} loading={deleteLoading}>
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {PROJECT_DELETE_HEADING}
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            {PROJECT_DELETE_SUB_HEADING}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={allColumnOptions}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryDataFetching={isSelectedColumnsAndFiltersQueryDataFetching}
                />
            )}
        </>
    );
};

export default ProjectsTable;
