import { createContext } from 'react';

interface ManufacturerListTableContextType {
    handleSingleDelete: (id: number) => void;
    handleSingleEdit: (id: number) => void;
    handleSingleDuplicate: (id: number) => void;
    isAdmin: boolean;
}

export const ManufacturerListTableContext = createContext<ManufacturerListTableContextType | null>(null);
