import { rest } from 'msw';
import { datasheet, selectedProduct } from './annotateDatasheet';

export const selectedDatasheetHandlers = [
    rest.get('*/bom/:bom_id/o_m_metadata/:bod_id/selected_warranty/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.get('*/bom/:bom_id/o_m_metadata/:bod_id/selected_installation_guide/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.get('*/bom/:bom_id/o_m_metadata/:bod_id/selected_drawing/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.get('*/bom/:bom_id/o_m_metadata/:bod_id/selected_other_document/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.get('*/bom/:bom_id/submittal_metadata/:bod_id/selected_datasheet/?latest=true', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(selectedProduct));
    }),
    rest.get('*/bom/:bom_id/submittal_metadata/:bod_id/selected_datasheet/:selected_datasheet_id/datasheet/:datasheet_id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(datasheet));
    }),
    rest.patch('*/bom/:bom_id/submittal_metadata/:bod_id/selected_datasheet/:documentId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bom_id/o_m_metadata/:bod_id/selected_warranty/:documentId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bom_id/o_m_metadata/:bod_id/selected_installation_guide/:documentId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bom_id/o_m_metadata/:bod_id/selected_drawing/:documentId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.patch('*/bom/:bom_id/o_m_metadata/:bod_id/selected_other_document/:documentId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    })
];
