import { Link as RouterLink } from 'react-router-dom';

import { Core } from '@pdftron/webviewer';

import { Box, Status, OptionType, BodyXS, Dayjs, dayjs } from '@parspec/pixel';

import { STATUS_TO_COLOR_MAP, ColorCode, DOCUMENT_STATUS_COLOR_MAP, PROJECT_STAGE_TRIGGER_VALUES, PROJECT_STAGE_TRIGGER_LABELS, QUOTES_LEVEL_STAGES, PROJECT_STAGES_LABELS_ENUM } from '../constants';

import { ManufacturerDropdownType } from '../../BOM/productFinder/setRequirements/types';
import { AllCompanyDetailsResponse, Users } from '../CreateAndEditProjectModal/queries/apiTypes';
import { AllTagsOptions } from '../../Project/queries/apiTypes';
import { Logo } from '../CreateAndEditProjectModal/queries/apiTypes';
import { StakeholderType } from '../CreateAndEditProjectModal/utils';
import { PRIORITY_VALUES } from '../../Project/shared/utils';

export * from './pdfUtils';

export const StatusTemplate = (stage: string) => {
    let currentStage = stage;
    if (currentStage === PROJECT_STAGE_TRIGGER_VALUES[0]) {
        currentStage = PROJECT_STAGE_TRIGGER_LABELS[0];
    } else if (currentStage === PROJECT_STAGE_TRIGGER_VALUES[1]) {
        currentStage = PROJECT_STAGE_TRIGGER_LABELS[1];
    } else if (currentStage === QUOTES_LEVEL_STAGES[1]) {
        currentStage = PROJECT_STAGES_LABELS_ENUM.QUOTE_SENT;
    }
    const color = STATUS_TO_COLOR_MAP[currentStage as keyof typeof STATUS_TO_COLOR_MAP] as ColorCode;
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            <Status color={color}>
                <BodyXS color={color}>{currentStage}</BodyXS>
            </Status>
        </Box>
    );
};

export const getDateDifference = (date: string | undefined | null) => {
    const d1 = dayjs();
    const d2 = dayjs(date);
    const diffDays = d2.diff(d1, 'day', true);

    return diffDays;
};

export const getIsDueDateRed = (date: string | undefined | null) => {
    const diffDays = getDateDifference(date);
    if (diffDays <= 7 && diffDays > -1) {
        return true;
    }
    return false;
};

interface TableLinkTemplateProps {
    to: string;
    label: string;
    target?: '_blank';
}

export const TableLinkTemplate = ({ to, label, target }: TableLinkTemplateProps) => (
    <Box component="span" sx={{ textOverflow: 'inherit', overflow: 'inherit', wordWrap: 'inherit', display: 'inline-block', maxWidth: '100%' }} color="primary.main">
        <RouterLink to={to} target={target}>
            {label}
        </RouterLink>
    </Box>
);

// export function searchManufacturer(mfgNameList: ManufacturerDropdownType[], searchQuery: string) {
//     const isMatchFound = (list: Array<string>) => list.some((ele: string) => ele.toLowerCase().includes(searchQuery.toLowerCase()));
//     return mfgNameList
//         .filter((option: any) => {
//             return option?.manufacturer_name?.toLowerCase().includes(searchQuery.toLowerCase()) || isMatchFound(option?.keywords || []) || isMatchFound(option?.abbreviations || []);
//         })
//         .slice(0, 50);
// }

const sessionStorageKey = 'current_parent_path';

export function setRouteToLocalStorage(path: string) {
    sessionStorage.setItem(sessionStorageKey, path);
}

export function getRouteFromLocalStorage() {
    return sessionStorage.getItem(sessionStorageKey);
}
// interface mfgInfo extends ParspecMfgDetailsResponse {
//     manufacturer_name?: string;
//     abbreviations?: string;
// }
// export type ManufacturerDropdownType = {
//     manufacturer_name?: string;
//     keywords: string[];
//     abbreviations?: string[];
//     group: MANUFACTURER_GROUPS[];
// } & DropdownOptionType;

export function searchManufacturer(mfgNameList: OptionType<ManufacturerDropdownType>[], searchQuery: string) {
    const isMatchFound = (list: Array<any>) => list.some((ele: string) => ele.toLowerCase().includes(searchQuery.toLowerCase()));
    return mfgNameList
        .filter((option: any) => {
            return option?.manufacturer_name?.toLowerCase().includes(searchQuery.toLowerCase()) || isMatchFound(option?.keywords || []) || isMatchFound(option?.abbreviations || []);
        })
        .slice(0, 50);
}

export interface IGetThumbnails {
    thumbnails: string[];
    checkboxes: string[];
    isExecutionComplete: boolean;
}

interface ILoadCanvasWrapper {
    createThumbnails: () => void;
    getThumbnailsAsync: () => IGetThumbnails;
}

export const loadCanvasWrapper = (doc: Core.Document, totalPages: number): ILoadCanvasWrapper => {
    const thumbnails: string[] = [];
    const checkboxes: string[] = [];
    let isExecutionComplete = false;
    let timerId: any;

    const loadThumbnails = (i: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                doc?.loadThumbnail(i + 1, (canvas: HTMLCanvasElement) => {
                    if (thumbnails[i]) {
                        clearTimeout(timerId);
                        resolve();
                    }

                    const imageUrl = canvas.toDataURL();
                    thumbnails[i] = imageUrl;
                    checkboxes[i] = 'false';

                    if (thumbnails.length === totalPages) {
                        isExecutionComplete = true;
                    }

                    clearTimeout(timerId);
                    resolve();
                });

                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    reject();
                }, 1000);
            } catch (error) {
                clearTimeout(timerId);
            }
        });
    };

    return {
        createThumbnails: async () => {
            isExecutionComplete = false;
            for (let i = 0; i < totalPages; i++) {
                try {
                    await loadThumbnails(i);
                } catch (error) {
                    i--;
                    continue;
                }
            }
        },
        getThumbnailsAsync: (): IGetThumbnails => {
            const fetchThumbnails = (): IGetThumbnails => {
                return {
                    thumbnails,
                    checkboxes,
                    isExecutionComplete
                };
            };

            return fetchThumbnails();
        }
    };
};

export const getOwnerCacheListForLocation = (allCompanyDetails: AllCompanyDetailsResponse[]) => {
    const ownerCacheList: Record<number, Users[]> = {};
    if (allCompanyDetails && allCompanyDetails.length) {
        for (let i = 0; i < allCompanyDetails.length!; i++) {
            ownerCacheList[allCompanyDetails[i].company_details.company_id] = allCompanyDetails[i].users;
        }
    }
    return ownerCacheList;
};

export const modifiedStateList = (country: string[]) => {
    return country.map((item) => {
        return {
            id: item.substring(0, 2),
            name: item.substring(0, 2)
        };
    });
};

export function convertDateFormat(dateStr: string) {
    const inputDate = new Date(dateStr);
    return `${inputDate.getFullYear()}/${(inputDate.getMonth() + 1).toString().padStart(2, '0')}/${inputDate.getDate().toString().padStart(2, '0')}`;
}

export function getDocumentOptions(data: AllTagsOptions[] | undefined, docType: string) {
    let result: Array<AllTagsOptions> = [];

    if (data) {
        result = [{ label: '-', value: 0, entity_type: docType, order: 0 }, ...data.filter((status) => status.entity_type === docType)].sort((a, b) => a.order - b.order);
        if (Array.isArray(result)) {
            for (const option of result) {
                option.type = DOCUMENT_STATUS_COLOR_MAP[option.label as keyof typeof DOCUMENT_STATUS_COLOR_MAP] as ColorCode;
            }
        }
    }

    return result;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getDateStringForTable = (date: string) => {
    const date_data = new Date(date);
    const d = date_data.getDate();
    const m = date_data.getMonth();
    const y = date_data.getFullYear();
    let th = date_data.getHours();
    const tm = date_data.getMinutes();
    const morning = th < 12;
    if (th > 12) {
        th = th - 12;
    }
    return `${months[m]} ${d}, ${y} at ${th < 10 ? `0${th}` : th}:${tm < 10 ? `0${tm}` : tm} ${morning ? 'am' : 'pm'}`;
};

export interface IProjectDetailState {
    name: string;
    company: number;
    stage: string;
    quote_owner: number;
    quote_due_date: Dayjs | null;
    submittal_owner: number;
    submittal_due_date: Dayjs | null;
    o_m_owner: number;
    o_m_due_date: Dayjs | null;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    building_type: string;
    construction_scope: string;
    size: string;
    logo: File | Logo | null;
    estimated_project_cost: number | null;
    internal_notes?: string | null;
    priority: PRIORITY_VALUES;
}

export interface IEditProjectDetail {
    editMode?: boolean;
    setProjectStateRef: (data: IProjectDetailState) => void;
    onError: (error: Errors) => void;
    formErrors: Errors;
    onBlurAction?: () => void;
    isUpdatingProjectDetails?: boolean;
    stakeholdersInfo?: StakeholderType[];
    updateStakeholderInfo?: (info: StakeholderType, index: number) => void;
    isStakeHoldersInfoFetching?: boolean;
    showOnlyProjectInfo?: boolean;
    selectedProjectId?: number;
}

export interface Errors {
    projectNameError: string;
}

export const convertToDollar = (cents: number) => {
    return parseFloat((cents / 100).toFixed(2));
};

export const convertToCents = (dollars: number) => {
    const centsValue = dollars * 100;
    const correctCentsValue = Number(centsValue.toFixed(0));
    return correctCentsValue;
};

export const convertCostToFormattedValue = (cost: number) => {
    if (cost === 0) {
        return '$0';
    }

    if (!cost) return '-';

    const formattedCost = cost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    });
    return `${formattedCost}`;
};

export const getStringifiedValueForEventService = (value: Record<string, any>) => {
    if (!value) return null;
    const stringifiedVal = JSON.stringify(value).replace(/"([^(")"]+)":/g, '$1:');
    console.log(`test-obj`, value);
    console.log(`test-stringified-version`, stringifiedVal);
    return stringifiedVal;
};

export const saveDataToSessionStorage = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
};

export const removeDataFromSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
};

export const getDataFromSessionStorage = (key: string) => {
    return sessionStorage.getItem(key);
};

export const toPascalCase = (str: string) => str[0].toUpperCase() + str.slice(1);

export const getUnifiedStrFromApi = (strArr: (string | number | undefined | null)[], seperator = '-') => strArr.filter(Boolean).join(seperator);

export const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return '';
    return filename.substring(lastDotIndex + 1);
};

export const deleteKeysFromSearchParams = (searchParams: URLSearchParams, keys: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    if (keys.length) {
        keys.forEach((item) => {
            newSearchParams.delete(item);
        });
    }
    return newSearchParams;
};
