import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    BodyXS,
    Box,
    Button,
    DatasheetSelectInfoIcon,
    HistoryOutlinedIcon,
    IconButton,
    LanguageIcon,
    Link,
    Paper,
    Snackbar,
    Status,
    ThumbDownOffAltIcon,
    Tooltip,
    Portal,
    FileUploadIcon
} from '@parspec/pixel';

import { getSanitizedUrl } from 'src/features/shared/utils/utils';
import Chip from '../Chip';
import ImageMagnifier from './ImageMagnifier';
import PreviewDocument from './PreviewDocument';
import { getUserCountTooltip } from './utils';
import { ProductSelectionHistory } from '../../BOM/productFinder/selectProduct/components/productSearchHistory';
import { useParspecNavigation } from 'src/app/Context';
import ProductFeedback from '../../BOM/productFinder/selectProduct/components/productSuggestions/ProductFeedback';
import LiveTag from '../LiveTag';

export interface ProductInfo {
    id?: number;
    category: string;
    manufacturer: string;
    model_number: string;
}

export interface Document {
    pdf_image: string;
    document_url?: string;
    content_hash?: string;
    fingerprint_hash?: string;
    company_confirm: number;
    total_confirm: number;
    elastic_url?: string;
    document_liveness_status: 'LIVE' | 'OUTDATED' | 'UNKNOWN';
    document_hash: string;
    document_hash_version: number;
    search_result_metadata: {
        type: string;
        user_name?: string;
        date?: string;
        manufacturer?: string;
        source_metadata?: {
            source_origin: number | null;
            date: Date;
            type: string;
            user_name: string;
            unique_manufacturer_id: string;
        };
    };
    selected_document_id: string;
    match_strength?: string;
    tags?: Array<string>;
    previously_selected?: boolean;
    selection_history_count?: number;
    id?: string;
    document_internal_url: string;
    rank?: number;
}
interface DocumentItemProps {
    item: Document;
    total: number;
    index: number;
    dataList: Document[];
    setSelectedDocument: (arg: Document | undefined) => void;
    selectedDocument: Document | undefined;
    setPreSelectedDocument: (arg: Document) => void;
    productFinderMode?: boolean;
    isSourceDataSheet?: boolean;
    subDocumentType?: string;
    preSelectedDocument?: Document | undefined;
}

const getMMDDYYYY = (date: string | undefined) => {
    if (!date) return '';
    const today = new Date(date);
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return mm + '/' + dd + '/' + yyyy || '';
};

const DocumentItem = (props: DocumentItemProps) => {
    const [showPreview, setShowPreview] = useState(false);
    const { setHasUnsavedChanges } = useParspecNavigation();
    const { index, item, dataList, selectedDocument, setSelectedDocument, setPreSelectedDocument, productFinderMode, isSourceDataSheet, subDocumentType } = props;
    const [searchHistory, setSeacheHistory] = useState(false);
    const [openFeedbackModal, setFeedbackModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (item[`selected_document_id` as keyof typeof item]) {
            setSelectedDocument(item);
            setPreSelectedDocument(item);
        }
    }, []);

    const url = getSanitizedUrl(item.pdf_image);
    const isMatched = item.match_strength;
    const color = isMatched?.toLocaleLowerCase() === 'strong match' ? 'success' : isMatched?.toLocaleLowerCase() === 'medium match' ? 'tertiary' : 'error';
    const { user_name, date, manufacturer, type: search_type } = item.search_result_metadata;
    const { type: origin_type, user_name: origin_user_name, date: origin_date } = item.search_result_metadata?.source_metadata || {};
    const isPreviouslySelected = Boolean(item.previously_selected);

    const documentUrl = item.document_internal_url ?? item.document_url;
    const types = {
        'user-rated': {
            helperText: 'Selected By: ',
            linkText: user_name || '',
            link: item.elastic_url || documentUrl
        },
        elastic: {
            helperText: 'MFG: ',
            linkText: manufacturer || '',
            link: documentUrl
        },
        old_elastic: {
            helperText: 'MFG: ',
            linkText: manufacturer || '',
            link: documentUrl
        },
        pastedURL: {
            helperText: `Uploaded on ${getMMDDYYYY(String(origin_date))} by`,
            linkText: origin_user_name,
            link: ''
        },
        // company group check is applicable only in this case, so "a parspec user" will not come for this
        uploaded: {
            helperText: `Uploaded on ${getMMDDYYYY(String(origin_date))} by`,
            linkText: origin_user_name,
            link: ''
        },
        google: {
            helperText: 'Web Source: ',
            linkText: item.document_url,
            link: documentUrl
        }
    };

    const info = types[item.search_result_metadata?.source_metadata ? (origin_type as keyof typeof types) : (search_type as keyof typeof types)];

    //to close the preview modal
    const handlePreviewDialog = () => {
        setShowPreview(!showPreview);
    };
    const handleSearchHistory = () => setSeacheHistory((old) => !old);
    const getUseCount = (user: string, count: number) => {
        if (count > 0) {
            return (
                <Tooltip title={getUserCountTooltip(user)} placement="top">
                    <span>
                        <Chip bgColor="secondary.main">
                            <BodyXS color="tertiary.main" fontWeight={600}>
                                {count}
                            </BodyXS>
                            <BodyXS color="primary.contrastText" fontWeight={600}>
                                {user}
                            </BodyXS>
                        </Chip>
                    </span>
                </Tooltip>
            );
        }
        return null;
    };

    const showSelectedText = date && user_name;
    const isSelected = item.id === selectedDocument?.id;
    const onClickSelection = (item: Document) => {
        setSelectedDocument(item.id === selectedDocument?.id ? undefined : item);
        setHasUnsavedChanges(true);
    };
    const handleCloseFeedback = () => {
        setFeedbackModal(!openFeedbackModal);
    };
    return (
        <>
            <Portal>
                <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            </Portal>

            <Paper
                variant="elevation"
                elevation={3}
                sx={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    backgroundColor: isSelected ? 'tertiary.light' : 'primary.contrastText',
                    width: '308px',
                    minWidth: '300px'
                }}
            >
                {productFinderMode ? (
                    <Box display={'flex'} gap={2} minHeight={'23px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            {isMatched && (
                                <Status {...(isMatched && { color: color || 'success' })}>
                                    <BodyXS color="inherit" borderRadius={'inherit'} height={'fit-content'}>
                                        {isMatched}
                                    </BodyXS>
                                </Status>
                            )}
                            {item.document_liveness_status === 'LIVE' && <LiveTag />}
                        </Box>
                        <Tooltip title="Provide Feedback" sx={{ border: '2px solid', borderColor: 'neutral.light', cursor: 'pointer' }} onClick={() => setFeedbackModal(true)}>
                            <IconButton size="small">
                                <ThumbDownOffAltIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center">
                        {item.document_liveness_status === 'LIVE' && <LiveTag />}
                        {/*  when no chips are there we need to add margin that is equal to the height of chips */}
                        {item.document_liveness_status !== 'LIVE' && !item.company_confirm && !item.total_confirm && <Box marginBottom={5}></Box>}

                        <Box display="flex" ml="auto" gap={2}>
                            {getUseCount('your org', item.company_confirm)}
                            {getUseCount('all users', item.total_confirm)}
                        </Box>
                    </Box>
                )}
                <Box height={350} width="100%" border="1px solid gray">
                    <ImageMagnifier src={url} width="100%" />
                </Box>
                <Box display="flex" justifyContent="space-between" gap={2}>
                    <Button color="secondary" variant="outlined" fullWidth onClick={handlePreviewDialog}>
                        {isSourceDataSheet ? 'Compare' : 'Preview'}
                    </Button>
                    <Button fullWidth onClick={() => onClickSelection(item)}>
                        {isSelected ? 'Unselect' : 'Select'}
                    </Button>
                </Box>
                <Box>
                    {info && (
                        <Box display="flex" alignItems="center" overflow="hidden" gap={2}>
                            {origin_type === 'uploaded' || origin_type === 'pasted' ? <FileUploadIcon fontSize="small" color="secondary" /> : <LanguageIcon fontSize="small" color="secondary" />}
                            <BodyXS sx={{ whiteSpace: 'nowrap' }} fontWeight={500}>
                                {info?.helperText}
                            </BodyXS>
                            {info?.link ? (
                                <Link to={getSanitizedUrl(info.link)} underline="hover" target="_blank" component={RouterLink}>
                                    <BodyXS color="primary" fontWeight={600}>
                                        {info.linkText}
                                    </BodyXS>
                                </Link>
                            ) : (
                                <BodyXS fontWeight={600} ml={-1}>
                                    {info.linkText}
                                </BodyXS>
                            )}
                        </Box>
                    )}
                    {productFinderMode ? (
                        <Box display="flex" mt={2} minHeight={'25px'} gap={2} alignItems={'center'}>
                            {isPreviouslySelected && (
                                <>
                                    <Box>
                                        <HistoryOutlinedIcon fontSize="small" color="secondary" />
                                    </Box>
                                    <BodyXS fontWeight={600} color={'text.primary'} sx={{ lineHeight: '14px', mt: 1 }}>
                                        <Link component={'button'} fontWeight={600} underline="hover" color="primary" target="_blank" onClick={() => setSeacheHistory(true)}>
                                            View Similar Searches
                                        </Link>
                                    </BodyXS>
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center" mt={2}>
                            <Box ml={0.5} mt={1}>
                                <DatasheetSelectInfoIcon color="secondary" />
                            </Box>
                            <BodyXS sx={{ lineHeight: '14px', mt: -2 }} fontWeight={600} color={showSelectedText ? 'text.primary' : 'text.secondary'}>
                                {showSelectedText ? `Last selected on ${getMMDDYYYY(date)} by ${user_name}` : `Not yet selected by your organization.`}
                            </BodyXS>
                        </Box>
                    )}
                </Box>
            </Paper>
            {showPreview && (
                <PreviewDocument
                    open={showPreview}
                    closePreview={handlePreviewDialog}
                    onSelectItem={onClickSelection}
                    index={index}
                    dataList={dataList}
                    productFinderMode={productFinderMode}
                    subDocumentType={subDocumentType!}
                    selectedDocument={selectedDocument}
                />
            )}
            {searchHistory && (
                <ProductSelectionHistory
                    open={searchHistory}
                    onCloseClick={handleSearchHistory}
                    document_url={item.document_url || ''}
                    content_hash={item.content_hash || ''}
                    fingerprint_hash={item.fingerprint_hash || ''}
                />
            )}
            {openFeedbackModal && (
                <ProductFeedback open={openFeedbackModal} onCloseClick={handleCloseFeedback} item={item} total_results={dataList.length} index={index} setSnackbarMessage={setSnackbarMessage} />
            )}
        </>
    );
};

export default DocumentItem;
