import { Dispatch, useEffect, useRef, useState, SetStateAction } from 'react';

import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { useParams } from 'react-router-dom';

import { Box, Skeleton } from '@parspec/pixel';

import PdfViewerHeader from './PdfViewerHeader';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { useGetContentDetailQuery } from '../queries';
import { environment } from 'src/environments';
import { WEBVIEWER_FOLDER_NAME } from '../../../shared/constants';

interface PdfViewerProps {
    setConfirmSelection: Dispatch<SetStateAction<boolean>>;
    setBoundInfo: Dispatch<SetStateAction<string>>;
    type?: string;
    handleChangeFile: Dispatch<SetStateAction<boolean>>;
}
interface AnnotationStateScrollHelper {
    drawingRect: boolean; // whether user is currently drawing annotation
    scrollInterval: number | null; // stores return value of `window.setInterval` or null otherwise
    toScrollBy: null | { top: number; left: number }; // the value to scroll by or null otherwise
}

const addCanvasThumbnail = (pageNumber: any, canvas: any, docViewer: any, thumbnailContainer: any) => {
    const ele = document.createElement('div');
    ele.classList.add('thumbnail-box');
    canvas.classList.add('markup-canvas');
    const thumbnailBox = document.createElement('div');
    thumbnailBox.classList.add('position-relative');
    thumbnailBox.appendChild(canvas);
    const footer = document.createElement('div');
    footer.classList.add('thumbnail-footer');
    const pageNum = document.createElement('span');
    pageNum.innerHTML = 'Page ' + pageNumber;
    footer.appendChild(pageNum);
    ele.appendChild(thumbnailBox);
    ele.appendChild(footer);
    canvas.onclick = () => {
        docViewer.setCurrentPage(pageNumber);
    };
    thumbnailContainer.appendChild(ele);
};
const annotationState: AnnotationStateScrollHelper = {
    drawingRect: false,
    scrollInterval: null,
    toScrollBy: null
};
const PdfViewer = (props: PdfViewerProps) => {
    const { bomId } = useParams();
    const { data, isFetching } = useGetContentDetailQuery(Number(bomId), { staleTime: 0 });
    const viewer: any = useRef(null);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
    const wvInstanceRef = useRef<WebViewerInstance | null>(null);
    useEffect(() => {
        setIsDocumentLoaded(true);
        if (!isFetching && data) {
            WebViewer(
                {
                    licenseKey: environment.Y,
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    disableLogs: true,
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'freeTextToolGroupButton',
                        'ribbons',
                        'contextMenuPopup',
                        'linkAnnotationPopup'
                    ],
                    initialDoc: getProxyFileUrl(data?.data?.file_info)
                },
                viewer.current
            ).then((instance: any) => {
                wvInstanceRef.current = instance;
                let searchButtonElem = {};
                const { documentViewer, annotationManager, Annotations, PageRotation } = instance.Core;
                instance.UI.setHeaderItems((header: any) => {
                    const items = header.getItems().filter((item: Record<string, any>) => {
                        if (item.dataElement === 'zoomOverlayButton') {
                            return true;
                        }
                        if (item.dataElement === 'searchButton') {
                            searchButtonElem = { ...item };
                        }
                        return false;
                    });
                    items.unshift({ type: 'spacer' });
                    items.push({ type: 'spacer' });
                    items.push({
                        type: 'actionButton',
                        img: '../../../../../../../assets/images/rotate.svg',
                        onClick: async () => {
                            const doc = documentViewer.getDocument();
                            const allPages: number[] = Array.from({ length: documentViewer.getPageCount() || 0 }, (_, index) => index + 1);
                            await doc.rotatePages(allPages, PageRotation.E_90);
                        }
                    });
                    items.push(searchButtonElem);
                    header.update(items);
                });
                const iframeDoc = instance.UI.iframeWindow.document.body;
                instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                annotationManager.addEventListener('annotationChanged', (_: any, action: any, { imported }: any) => {
                    if (imported) return;

                    const annotations = annotationManager.getAnnotationsList();
                    const isRectangleAnnotationPresent = annotations.some((annotation: any) => {
                        if (annotation instanceof Annotations.RectangleAnnotation) {
                            return true;
                        }
                        return false;
                    });

                    if (action === 'add' || action === 'modify') {
                        props.setConfirmSelection(true);
                    } else if ((action = 'delete' && !isRectangleAnnotationPresent)) {
                        props.setConfirmSelection(false);
                    }

                    if (!isRectangleAnnotationPresent) {
                        props.setConfirmSelection(false);
                    }
                    annotationManager.exportAnnotations().then((xfdfString: any) => {
                        const xmlstr = [];

                        xmlstr.push(xfdfString);
                        props.setBoundInfo(xmlstr.join());
                    });
                });
                /** Scroll if mouse leaves while doing an annotation */
                documentViewer.addEventListener('mouseLeftDown', () => {
                    if (documentViewer.getToolMode().name == 'AnnotationCreateRectangle') {
                        // starting annotation rect
                        annotationState.drawingRect = true;
                    }
                });

                documentViewer.addEventListener('mouseLeave', () => {
                    if (annotationState.drawingRect) {
                        annotationState.scrollInterval = window.setInterval(() => scrollPDFViewerByDistance(iframeDoc.querySelector('[data-element="documentContainer"]')), 100);
                    }
                });

                documentViewer.addEventListener('mouseMove', (e: MouseEvent) => {
                    if (annotationState.scrollInterval != null) {
                        // compute how much to scroll by if is scrollling
                        const viewerRect = viewer.current.getBoundingClientRect();
                        let top = 0;
                        let left = 0;
                        const x = e.screenX,
                            y = e.screenY;
                        if (x - viewerRect.right > 0) {
                            left = x - viewerRect.right;
                        } else if (viewerRect.left - x > 0) {
                            left = x - viewerRect.left; // negative scroll horizontal
                        }
                        if (y - viewerRect.bottom > 0) {
                            top = y - viewerRect.bottom;
                        } else if (viewerRect.top - y > 0) {
                            top = y - viewerRect.top;
                        }

                        // scaling it a bit
                        const zoomlevel = documentViewer.getZoom();
                        top = (top * zoomlevel) / 10;
                        left = (left * zoomlevel) / 10;

                        annotationState.toScrollBy = {
                            top,
                            left
                        };
                    }
                });

                const endScroll = () => {
                    if (annotationState.scrollInterval != null) {
                        // stop scroll
                        window.clearInterval(annotationState.scrollInterval);
                        annotationState.scrollInterval = null;
                    }
                };

                documentViewer.addEventListener('mouseLeftUp', () => {
                    endScroll();
                    annotationState.drawingRect = false;
                });
                documentViewer.addEventListener('mouseEnter', endScroll);
                documentViewer.addEventListener('documentLoaded', async () => {
                    setIsDocumentLoaded(false);
                    instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                    instance.UI.closeElements(['loadingModal']);
                    const pageCount = documentViewer.getPageCount();
                    const doc = documentViewer.getDocument();
                    const thumbnailContainer: any = document.getElementById('thumbnails-container');
                    thumbnailContainer.innerHTML = '';
                    for (let i = 0; i < pageCount; i++) {
                        const pageNumber = i + 1;
                        await new Promise<void>((resolve) => {
                            doc.loadThumbnail(pageNumber, (canvas: any) => {
                                addCanvasThumbnail(pageNumber, canvas, documentViewer, thumbnailContainer);
                                resolve();
                            });
                        });
                    }
                });
            });
        }
    }, [viewer?.current, isFetching]);
    useEffect(() => {
        wvInstanceRef?.current?.UI.loadDocument(getProxyFileUrl(data?.data?.file_info || ''));
    }, [data?.data]);

    const setToolModeToAnnotation = () => {
        if (wvInstanceRef.current) {
            const { documentViewer } = wvInstanceRef.current.Core;
            documentViewer.setToolMode(documentViewer.getTool('AnnotationCreateRectangle'));
            const Annotations = wvInstanceRef.current.Core.Annotations;
            documentViewer.getTool('AnnotationCreateRectangle').setStyles({
                FillColor: new Annotations.Color(255, 173, 77),
                Opacity: 0.3,
                StrokeThickness: 3,
                StrokeColor: new Annotations.Color(255, 0, 0)
            });
        }
    };
    const scrollPDFViewerByDistance = (doc_elem: any) => {
        if (annotationState.toScrollBy) scrollPDFViewer(doc_elem, annotationState?.toScrollBy?.top, annotationState?.toScrollBy?.left);
    };
    const scrollPDFViewer = (doc_elem: any, top: number, left: number) => {
        doc_elem.scrollBy({ top, left, behaviour: 'smooth' });
    };
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >
                <PdfViewerHeader setToolModeToAnnotation={setToolModeToAnnotation} handleChangeFile={props.handleChangeFile} isPdf={true} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: 'calc(100vh - 110px)'
                }}
            >
                <Box bgcolor="secondary.light">
                    {
                        <Box sx={{ width: 208, padding: 2, height: '100%' }}>
                            {
                                <Box
                                    display={!isDocumentLoaded ? 'block' : 'none'}
                                    id="thumbnails-container"
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        overflowY: 'auto',
                                        '.thumbnail-box': {
                                            padding: 2,
                                            display: 'flex',
                                            width: '100%',
                                            flexDirection: 'column'
                                        },
                                        '.thumbnail-box canvas.markup-canvas': {
                                            width: '168px !important',
                                            height: '220px !important',
                                            cursor: 'pointer'
                                        },
                                        '.thumbnail-footer': {
                                            color: 'secondary.contrastText',
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            }
                            {isDocumentLoaded && (
                                <Box marginLeft={2} marginTop={4}>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                    <Box paddingBottom={4}>
                                        <Skeleton color="light" variant="rectangular" width={168} height={220} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    }
                    <Box
                        width={208}
                        padding={2}
                        sx={{
                            overflowY: 'auto'
                        }}
                    ></Box>
                </Box>
                <Box flex="11" height=" calc(100% - 7px)">
                    <Box className="webviewer" ref={viewer} height={'100%'}></Box>
                </Box>
            </Box>
        </Box>
    );
};
export default PdfViewer;
