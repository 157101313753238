import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Modal, BodySmall, ModalFooter, ModalHeader, Skeleton } from '@parspec/pixel';

import { ProductFinderAccessModalProps } from './modal.types';
import { useCheckForProductFinderAccessQuery, useEditInvetedUserDetailsMutation, useProductFinderAccessMutation } from '../queries';

export const ProductFinderAccessModal = (props: ProductFinderAccessModalProps) => {
    const { isOpen, setIsOpen, rowData, snackbarData, setSnackbarData, isInvitedTab } = props;

    const [isError, setIsError] = useState(false);

    // To switch on or off the product finder access
    const { mutateAsync: modifyProductFinderAccessMutation, isLoading: isEditProductFinderAccessMutationLoading } = useProductFinderAccessMutation();
    const { mutateAsync: editInvitedUserDetailsMutation, isLoading: isEditInvitedUserDetailsLoading } = useEditInvetedUserDetailsMutation();

    // To get whether the user has the enough credits and the credits that need to be deducted to get PF access
    const { data: checkCreditsForProductFinderAccess, isLoading: isProductFinderAccessDataLoading } = useCheckForProductFinderAccessQuery(rowData?.data?.user_details.id);

    const navigate = useNavigate();

    const confirmHandler = async () => {
        // Check if the minimim credit criteria is met to activate product finder
        const userId = rowData?.data?.user_details.id;

        try {
            if (isInvitedTab) {
                const payload = {
                    has_product_finder_access: true
                };

                await editInvitedUserDetailsMutation({ userId, data: payload });
            } else {
                await modifyProductFinderAccessMutation({ userId: userId, payload: { product_finder_enabled: true } });
            }
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Updated successfully'
            });
            closeModalHandler();
        } catch (err) {
            setIsError(true);
        }
    };
    const closeModalHandler = () => {
        setIsOpen((prev) => ({ ...prev, isOpen: false, data: {} }));
    };
    return (
        <Box>
            <Modal
                open={isOpen}
                footer={<ModalFooter isLoading={isEditProductFinderAccessMutationLoading || isEditInvitedUserDetailsLoading} onAccept={confirmHandler} onReject={closeModalHandler} />}
                header={<ModalHeader onClose={closeModalHandler} title={'Turn on Product Finder access?'}></ModalHeader>}
                onClose={closeModalHandler}
            >
                {!isProductFinderAccessDataLoading ? (
                    <Box width={'500px'} mt={4}>
                        <Box>
                            {isInvitedTab ? (
                                <BodySmall limit={false} color={'text.secondary'}>
                                    <Box component={'span'} fontWeight={700}>
                                        Provide this user with Product Finder access for 100 credits per year, prorated based on your plan's next billing date?
                                    </Box>{' '}
                                    Credits will not be deducted until the user creates their account, and if an unused seat is available at that time, it will be automatically allocated to that user
                                    with no additional cost.
                                </BodySmall>
                            ) : (
                                <BodySmall limit={false} color={'text.secondary'}>
                                    <Box component={'span'} fontWeight={700}>
                                        Provide this user with Product Finder access for {checkCreditsForProductFinderAccess?.data.credits_to_be_deducted} credit(s)?
                                    </Box>{' '}
                                    This action is prorated based on your plan's next billing date?
                                </BodySmall>
                            )}

                            <Box mt={3}>
                                {isInvitedTab ? (
                                    <BodySmall limit={false} color={'text.secondary'}>
                                        Once their account is created, access will remain enabled for this user, and credits will be deducted at the beginning of the pay period, unless access is
                                        removed prior to the next billing date.
                                    </BodySmall>
                                ) : (
                                    <BodySmall limit={false} color={'text.secondary'}>
                                        Access will remain enabled for this user, and credits will be deducted at the beginning of the pay period, unless access is removed prior to the next billing
                                        date.
                                    </BodySmall>
                                )}
                            </Box>
                        </Box>

                        {isError && (
                            <Box mt={3}>
                                <BodySmall limit={false} color={'error.main'} display={'inline'}>
                                    You do not have enough credits to perform this action. Upgrade your plan under
                                </BodySmall>

                                <BodySmall color={'primary.main'} limit={false} display={'inline'} ml={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/v2/settings/plan-and-payment')}>
                                    Plan & Payments
                                </BodySmall>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box mt={4}>
                        <Box mb={1}>
                            <Skeleton height="17px" variant="rectangular" width={'500px'} />
                        </Box>

                        <Box mb={3}>
                            <Skeleton height="17px" variant="rectangular" width={'300px'} />
                        </Box>

                        <Box mb={1}>
                            <Skeleton height="17px" variant="rectangular" width={'500px'} />
                        </Box>
                        <Box mb={1}>
                            <Skeleton height="17px" variant="rectangular" width={'500px'} />
                        </Box>
                        <Box mb={1}>
                            <Skeleton height="17px" variant="rectangular" width={'300px'} />
                        </Box>
                    </Box>
                )}
            </Modal>
        </Box>
    );
};
