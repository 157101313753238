import { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { Box, Button, MoreVertIcon, Snackbar, Tooltip, Link, Menu } from '@parspec/pixel';

import AutosearchLoaderDialog from '../../shared/AutosearchLoaderDialog';
import { autoSearchMsg, DATASHEET_SELECTED_MESSAGE } from '../../shared/constants';
import { useDatasheetAutoSearchMutation } from '../queries';
import { AUTO_SEARCH_OPTIONS } from '../shared/constants';
import CreateSectionButton from '../shared/CreateSectionButton';
import ExportButton from '../shared/ExportButton';
import ReuseDatasheetDialog from '../shared/ReuseDatsheetDialog';
import SortButton from '../shared/SortButton';
import { ReuseDialogType } from '../shared/utils';
import { getDatasheetLoadTime } from './helper';
import { BodResponse, DatasheetTabDataResponse } from '../queries/apiTypes';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';
import AddReuseOptionDialog from './AddReuseOptionDialog';

interface DatasheetTabHeaderProps {
    selectedItems: BodResponse[];
    unCheckSelectedLineItems: () => void;
    tableData: BodResponse[] | DatasheetTabDataResponse[];
}

const DatasheetTabHeader = ({ selectedItems, unCheckSelectedLineItems, tableData }: DatasheetTabHeaderProps) => {
    const { bomId } = useParams();

    const [openReuseDatasheetDialog, setOpenReuseDatasheetDialog] = useState(false);
    const [showAutosearchDialog, setShowAutoSearchDialog] = useState(false);
    const [showAddReuseOptionDialog, setShowAddReuseOptionDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const { mutateAsync: autoSearch, isLoading: autoSearchLoading } = useDatasheetAutoSearchMutation();

    const onReuse = () => {
        handleReuseDatasheetDialog();
        unCheckSelectedLineItems();
        setSnackbarMessage(DATASHEET_SELECTED_MESSAGE);
    };

    const handleReuseDatasheetDialog = () => {
        setOpenReuseDatasheetDialog((old) => !old);
    };

    const handleSuccessAddReuseOption = () => {
        unCheckSelectedLineItems();
        handleShowAddReuseOptionDialog();
        setSnackbarMessage(DATASHEET_SELECTED_MESSAGE);
    };

    const handleAutoSearchDialog = () => setShowAutoSearchDialog((old) => !old);

    const handleShowAddReuseOptionDialog = () => setShowAddReuseOptionDialog((old) => !old);

    const onAutoSearch = async (type: 'me' | 'company_users' | 'company_group_users') => {
        handleAutoSearchDialog();
        try {
            const data = {
                bomId: Number(bomId),
                input: {
                    bod_id: selectedItems.map((item) => item.id),
                    user_preference: type
                }
            };
            await autoSearch(data);
            handleAutoSearchDialog();
            unCheckSelectedLineItems();
            setSnackbarMessage(autoSearchMsg);
            trackIntercomEvent('Auto_Select');
        } catch (e) {
            /* empty */
        }
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box display="flex" justifyContent="flex-end" minWidth="1100px">
                <Box display="flex" gap={2} alignItems="center">
                    <SortButton />
                    <CreateSectionButton />
                    <Button variant="outlined" color="secondary" disabled={!selectedItems.length} onClick={handleShowAddReuseOptionDialog}>
                        Add/Reuse Datasheet
                    </Button>
                    <ExportButton />
                    <Tooltip title={selectedItems.length ? '' : 'Select Item(s) First'} placement={'bottom'}>
                        <span>
                            <Menu options={AUTO_SEARCH_OPTIONS(onAutoSearch)}>
                                <Button variant="outlined" color="secondary" endIcon={<MoreVertIcon />} disabled={!selectedItems.length}>
                                    Auto-Select
                                </Button>
                            </Menu>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            {showAddReuseOptionDialog && (
                <AddReuseOptionDialog
                    open={showAddReuseOptionDialog}
                    onCloseDialog={handleShowAddReuseOptionDialog}
                    selectedItems={selectedItems}
                    onReuseDatasheetDialog={handleReuseDatasheetDialog}
                    onSuccess={handleSuccessAddReuseOption}
                />
            )}
            {openReuseDatasheetDialog && (
                <ReuseDatasheetDialog
                    open={openReuseDatasheetDialog}
                    handleDialog={handleReuseDatasheetDialog}
                    confirmAction={onReuse}
                    tableData={tableData || []}
                    selectedRowIds={(selectedItems || []).map((item) => item.id)}
                    reuseType={ReuseDialogType.DATASHEET}
                />
            )}
            {showAutosearchDialog && autoSearchLoading && (
                <AutosearchLoaderDialog
                    open={showAutosearchDialog && autoSearchLoading}
                    onClose={handleAutoSearchDialog}
                    loadTime={getDatasheetLoadTime(selectedItems)}
                    helperLink={
                        <Link to="/v2/settings/my-profile" component={RouterLink} underline="hover">
                            Customize behavior here.
                        </Link>
                    }
                />
            )}
        </>
    );
};

export default DatasheetTabHeader;
