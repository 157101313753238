import { UseQueryOptions, useMutation, useQuery, QueryKey } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { endpoints, getPricingBods, patchPricingBod, updateUserPastedContent } from './api';
import { queryClient } from 'src/app/queryClient';
import { PricingBodsResponse } from './apiTypes';

const getPricingBodListKey = (bomId: number) => [endpoints.getPricingBodListUrl(bomId)];

export function invalidatePricingBodQuery(bomId: number) {
    queryClient.invalidateQueries(getPricingBodListKey(bomId));
}

export function handleLotDelete(bomId: number, deletedLotIds: Set<number>) {
    const queryKey = getPricingBodListKey(bomId);
    queryClient.setQueryData(queryKey, (pricingData?: AxiosResponse<PricingBodsResponse>) => {
        if (!pricingData) {
            return pricingData;
        }
        pricingData.data = pricingData.data.map((bodData) => {
            if (bodData.lot_id && deletedLotIds.has(bodData.lot_id)) {
                return { ...bodData, lot_id: null };
            }
            return bodData;
        });

        return pricingData;
    });
}

// Updates components pricing data in pricing query data when kit's lot is set to null
export function updateComponentsPricingData(bomId: number, componentBodIds: Set<number>) {
    const queryKey = getPricingBodListKey(bomId);
    queryClient.setQueryData(queryKey, (pricingData?: AxiosResponse<PricingBodsResponse>) => {
        if (pricingData) {
            const newPricingData = { ...pricingData };
            const newBodPricingData = { cost_cents: null, discount_percentage: null, discounted_cost_cents: null, margin_percentage: null, sell_price_cents: null };
            const pricingBodList = newPricingData?.data;
            newPricingData.data = pricingBodList.map((pricingBodInfo) => {
                if (componentBodIds.has(pricingBodInfo.bod_id)) {
                    return { ...pricingBodInfo, ...newBodPricingData };
                }
                return { ...pricingBodInfo };
            });
            return newPricingData;
        }

        return pricingData;
    });
}

export function usePricingAndLeadTimeBodList(bomId: number, options: UseQueryOptions<AxiosResponse<PricingBodsResponse>>) {
    return useQuery<AxiosResponse<PricingBodsResponse, any>, unknown, AxiosResponse<PricingBodsResponse, any>, QueryKey>(getPricingBodListKey(bomId), () => getPricingBods(bomId), options);
}

export function usePricingBodPatchMutation() {
    return useMutation(patchPricingBod, {
        onMutate: async ({ bomId, pricingBod: updatedPricingBodInfo, bodId }) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            const queryKey = getPricingBodListKey(bomId);
            // await queryClient.cancelQueries({ queryKey });

            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, (pricingData?: AxiosResponse<PricingBodsResponse>) => {
                if (pricingData) {
                    const newPricingData = { ...pricingData };
                    const pricingBodList = newPricingData?.data;
                    newPricingData.data = pricingBodList.map((pricingBodInfo) => {
                        if (pricingBodInfo.bod_id === bodId) {
                            return { ...pricingBodInfo, ...updatedPricingBodInfo };
                        }
                        return { ...pricingBodInfo };
                    });

                    return newPricingData;
                }
                return pricingData;
            });
        }
    });
}

/**********************************  THE BELOW CODE WILL BE DELETED ONCE THE TESTING FOR VSAP TOOLS TESTING IS DONE ******************************* */
export const usePasteDataMutation = () => useMutation(updateUserPastedContent);
