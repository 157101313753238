import { ToolbarType } from '@parspec/pixel';

export const toolBaroptions: ToolbarType = ['delete', 'search', 'hide', 'unhide', 'selectedItems', 'duplicate', 'add', 'move'];

export const BULKEDIT_POPUP_TEXT = 'Edit the various fields from your selected products here. Any entered values will be applied to the corresponding fields from your selected products.';

export const LOT_SELECTED_TOOLTIP_TEXT = 'This field can’t be edited \nwhen there is a Lot selected';

export const BULK_EDIT_COST_WARNING = 'Some selected products do not have a Cost value assigned. Discount or Margin cannot be applied to these products.';

export const BULK_EDIT_LOT_WARNING = 'Some selected products are assigned to Lot. Cost, Discount, or Margin cannot be applied to these products.';

export const BULK_EDIT_RESET_LOT_WARNING = 'Some selected products are assigned to Lot. This action will remove selected Lot from those items.';

export const BULK_EDIT_ASSIGN_LOT_WARNING = 'Some selected products have Cost, Discount, and/or Margin entries. Assigning Lot to these products will overwrite these values.';

export const BULK_EDIT_OPTION_WARNING = 'Some selected products has associated Options. Lot will not be applied for these products.';

export const BULK_EDIT_KIT_WARNING = 'Some selected products are part of a Kit. Cost, Discount, or Margin cannot be applied to these products.';
