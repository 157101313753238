import { FocusEvent, useCallback, useEffect, useState } from 'react';

import {
    BodySmall,
    BodyXS,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    CloseIcon,
    FileSelector,
    FileSelectorFileType,
    Grid,
    IconButton,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField
} from '@parspec/pixel';

import { BUSINESS_TYPE_OPTIONS, VISIBILITY_RADIO_OPTIONS, acceptedFileTypes, acceptedFileTypesString, CONTACT_NUMBER_REQEX } from '../../shared/constant';
import { environment } from 'src/environments';
import { ICompanyLocation } from '../../../Settings/UserManagement/queries/apiTypes';
import { CANADA_STATES, COUNTRY, COUNTRY_OPTIONS, EMAIL_FORMAT_INVALID, INVALIED_URL_MSG, USA_STATES } from '../../../shared/constants';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import { CompanyDetailsFormType, ICompanyErrorField } from './CompanyDetails';
import { websiteValidationRegex } from 'src/features/shared/utils/utils';
import { useCompanyInfoQuery } from '../../../Settings/BranchLocations/queries';
import { modifiedStateList } from '../../../shared/utils/utils';

interface ICompanyDetailFormProps {
    formValues: CompanyDetailsFormType;
    formError: ICompanyErrorField;
    selectedLocations: string[];
    stateListOptions: { id: string; name: string }[];
    setStateListOptions: (obj: { id: string; name: string }[]) => void;
    setFormValues: (obj: CompanyDetailsFormType) => void;
    setFormError: (obj: ICompanyErrorField) => void;
    setSelectedLocations: (arr: string[]) => void;
}

const CompanyDetailsForm = ({ formValues, setFormValues, formError, setFormError, selectedLocations, setSelectedLocations, stateListOptions, setStateListOptions }: ICompanyDetailFormProps) => {
    const [locationData, setLocationData] = useState<ICompanyLocation[]>();
    const [allSelected, setAllSelected] = useState(false);

    const { data: allBranchLocations, isLoading: locationsLoading } = useCompanyInfoQuery();

    useEffect(() => {
        let isAllSelected = true;
        const locationData = (allBranchLocations?.data as unknown[] as ICompanyLocation[])?.map((el: ICompanyLocation) => {
            if (!selectedLocations.includes(el.id.toString())) {
                isAllSelected = false;
            }
            return { ...el, isChecked: selectedLocations.includes(el.id.toString()) };
        });
        setAllSelected(isAllSelected);
        setLocationData(locationData);
    }, [allBranchLocations?.data, selectedLocations]);

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
        const { name } = event.target;
        const value = event.target?.value as string;

        if (name === 'name') {
            if (formError.nameErr.length > 0) {
                setFormError({ ...formError, nameErr: '' });
            }
        }

        if (name === 'email') {
            if (formError.emailErr.length > 0) {
                setFormError({ ...formError, emailErr: '' });
            }
        }

        if (name === 'website') {
            if (formError.websiteErr.length > 0) {
                setFormError({ ...formError, websiteErr: '' });
            }
        }

        if (name === 'phone') {
            if (!CONTACT_NUMBER_REQEX.test(value)) {
                return;
            }
        }

        if (name === 'country') {
            let stateOptions: { id: string; name: string }[] = [];
            if (value === COUNTRY.USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (value === COUNTRY.CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            setStateListOptions(stateOptions);
        }

        const updatedData = { ...formValues, [name]: value };
        setFormValues(updatedData);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name } = event.target;
        if (name === 'email' && email.length > 0) {
            setFormError({ ...formError, emailErr: !validateEmail(email) ? EMAIL_FORMAT_INVALID : '' });
        }
        if (name === 'website' && website.length > 0) {
            setFormError({ ...formError, websiteErr: !websiteValidationRegex().test(website.trim()) ? INVALIED_URL_MSG : '' });
        }
    };

    const checkboxHandler = (_event: React.ChangeEvent<HTMLInputElement>, eachLocationData: ICompanyLocation) => {
        const checkedData: string[] = [];
        const locDataArr = locationData?.map((el: ICompanyLocation) => {
            if (el.id === eachLocationData?.id) {
                if (!el.isChecked) {
                    checkedData.push(el.id.toString());
                }
                return { ...el, isChecked: !el.isChecked };
            } else {
                if (el.isChecked) {
                    checkedData.push(el.id.toString());
                }
                return el;
            }
        });
        if (checkedData.length === locationData?.length) setAllSelected(true);
        else setAllSelected(false);
        setSelectedLocations(checkedData);
        setLocationData(locDataArr);
        setFormError({ ...formError, visibilityErr: '' });
    };

    const onSelectLogo = (files: File[] | FileSelectorFileType[]) => {
        const values = { ...formValues, selectedLogo: { file: files[0] as File, s3_file_path: '' } };
        setFormValues(values);
    };

    const onUploadLogo = (files: { file: File | FileSelectorFileType; s3_file_path?: string }[]) => {
        if (files?.[0]) {
            const { file, s3_file_path } = files[0];
            const objectUrl = URL.createObjectURL(file as File);
            const values = { ...formValues, logo: s3_file_path as string, selectedLogo: { file: file as File, s3_file_path: objectUrl } };
            setFormValues(values);
        }
        const errors = { ...formError, logoError: '' };
        setFormError(errors);
    };

    const handleDeleteImagePreview = useCallback(() => {
        setFormValues({ ...formValues, logo: null, selectedLogo: { s3_file_path: '' } });
    }, [formValues]);

    const handleSelectAll = () => {
        if (allSelected) {
            const allSelectedLocations = locationData?.map((locObj) => {
                locObj.isChecked = false;
                return locObj;
            });
            setSelectedLocations([]);
            setLocationData(allSelectedLocations);
            setAllSelected(false);
        } else {
            const allSelectedLocations = locationData?.map((locObj) => {
                locObj.isChecked = true;
                return locObj;
            });
            const selectedLocId: string[] = (allSelectedLocations?.length && allSelectedLocations.map((el) => String(el.id))) || [];
            setSelectedLocations(selectedLocId);
            setLocationData(allSelectedLocations);
            setAllSelected(true);
        }
        setFormError({ ...formError, visibilityErr: '' });
    };

    const { name, business_type, email, phone, website, line1, line2, city, state, country, zip, notes, visiblity, selectedLogo, erp_customer_id } = formValues;

    return (
        <>
            <Box display="flex" width="100%" columnGap={5}>
                <Box display="flex" width="25%" flexDirection="column" rowGap={6}>
                    <TextField
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                        label="Name*"
                        name="name"
                        error={Boolean(formError.nameErr)}
                        helperText={formError.nameErr}
                    />
                    <TextField value={erp_customer_id} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="CRM/ERP ID" name="erp_customer_id" />
                    <Box display="flex" flexDirection="column" rowGap={1}>
                        <Select value={business_type} options={BUSINESS_TYPE_OPTIONS} onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)} label="Business Type" name="business_type" />
                    </Box>
                    <TextField
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        error={Boolean(formError.emailErr)}
                        helperText={formError.emailErr}
                    />
                    <TextField value={phone} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Phone Number" name="phone" />
                    <TextField
                        value={website}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                        label="Website"
                        name="website"
                        onBlur={handleBlur}
                        error={Boolean(formError.websiteErr)}
                        helperText={formError.websiteErr}
                    />
                </Box>
                <Box display="flex" width="25%" flexDirection="column" rowGap={6}>
                    <TextField value={line1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Address Line 1" name="line1" />
                    <TextField value={line2} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Address Line 2" name="line2" />
                    <TextField value={city} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="City" name="city" />
                    <Select
                        value={country}
                        onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)}
                        options={COUNTRY_OPTIONS}
                        optionLabelKeyname="name"
                        optionValueKeyname="id"
                        label="Country"
                        name="country"
                    />
                    <Box display="flex" columnGap={3}>
                        <Select
                            value={state}
                            options={stateListOptions}
                            optionLabelKeyname="name"
                            optionValueKeyname="id"
                            onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)}
                            label="State / Province"
                            name="state"
                        />
                        <TextField value={zip} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Zip Code" name="zip" />
                    </Box>
                </Box>
                <Box display="flex" width="28%" flexDirection="column">
                    <BodySmall color="text.secondary">Manage Visibility</BodySmall>
                    <RadioGroup options={VISIBILITY_RADIO_OPTIONS} value={visiblity} onChange={handleFormChange} name="visiblity" label="" />
                    {visiblity === VISIBILITY_RADIO_OPTIONS[1].value && (
                        <Box width="96%">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" alignItems="baseline">
                                    <BodySmall>Location Access</BodySmall>
                                    <BodyXS mt={1} color={'error.main'}>
                                        *
                                    </BodyXS>
                                </Box>
                                <Box>
                                    <Button variant="outlined" color="secondary" size="small" onClick={handleSelectAll} disabled={locationsLoading}>
                                        {allSelected ? 'Deselect All' : 'Select All'}
                                    </Button>
                                </Box>
                            </Box>
                            <Box position={'relative'} height="142px" maxHeight={'190px'} mt={2} bgcolor={'neutral.light'} py={1} px={3} sx={{ overflow: 'hidden', overflowY: 'scroll' }}>
                                {locationsLoading && (
                                    <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                                        <CircularProgress size="lg" color={'primary'} thickness={3.6} />
                                    </Box>
                                )}
                                <Box>
                                    {locationData &&
                                        locationData?.length !== 0 &&
                                        locationData.map((eachLocationData: ICompanyLocation) => {
                                            return (
                                                <Box
                                                    key={eachLocationData.id}
                                                    sx={{
                                                        '.MuiFormControlLabel-label': {
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            width: '100%'
                                                        }
                                                    }}
                                                >
                                                    <Checkbox checked={eachLocationData.isChecked} label={eachLocationData.name} onChange={(event) => checkboxHandler(event, eachLocationData)} />
                                                </Box>
                                            );
                                        })}
                                </Box>
                            </Box>
                            {Boolean(formError.visibilityErr) && (
                                <Box my={2}>
                                    <BodyXS color={'error.main'}>{formError.visibilityErr}</BodyXS>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                <Box display="flex" width="22%" flexDirection="column" height="50%">
                    <Box display="flex" justifyContent="space-between" alignItems="baseline">
                        <Box>
                            <BodySmall mb={3}>Company Logo</BodySmall>
                        </Box>
                        {selectedLogo.s3_file_path && (
                            <Box>
                                <IconButton onClick={() => handleDeleteImagePreview()}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Box>

                    {selectedLogo.s3_file_path && (
                        <Grid item width={1} height={2}>
                            <img src={selectedLogo.s3_file_path || ''} style={{ objectFit: 'contain', width: '100%', maxHeight: 236 }} />
                        </Grid>
                    )}

                    {!selectedLogo.s3_file_path && (
                        <FileSelector
                            placeholder="Drag and drop a file here, or:"
                            url={`${environment.b}/api/generate_signed_url/`}
                            onSelect={onSelectLogo}
                            onUpload={onUploadLogo}
                            acceptedFormats={acceptedFileTypes}
                            helperText={acceptedFileTypesString}
                            error={formError.logoError}
                        />
                    )}
                </Box>
            </Box>
            <Box width="100%" mt={6}>
                <TextField value={notes} multiline={true} rows={3} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Company Notes" name="notes" />
            </Box>
        </>
    );
};

export default CompanyDetailsForm;
