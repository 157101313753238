import { memo, useEffect } from 'react';

import { Box } from '@parspec/pixel';

import { ImageSectionType, initialLogoPreview } from './LeftImageSection';

const RightImageSection: React.FC<ImageSectionType> = memo(({ is_active, isShowLogoPreview, mainLogoUrl, isAddressLogoPreview, addressLogoUrl, isPreparedByLogoPreview, preparedByLogoUrl }) => {
    useEffect(() => {
        const rightMainLogoElement: HTMLImageElement | null = document.querySelector('#preview-right-image-1');
        if (rightMainLogoElement && isShowLogoPreview && mainLogoUrl) {
            initialLogoPreview(mainLogoUrl, rightMainLogoElement);
        }
    }, [isShowLogoPreview, mainLogoUrl]);

    useEffect(() => {
        const rightPreparedByElement: HTMLImageElement | null = document.querySelector('#preview-right-image-2');
        if (rightPreparedByElement && isPreparedByLogoPreview && preparedByLogoUrl) {
            initialLogoPreview(preparedByLogoUrl, rightPreparedByElement);
        }
    }, [isPreparedByLogoPreview, preparedByLogoUrl]);

    useEffect(() => {
        const rightAddressElement: HTMLImageElement | null = document.querySelector('#preview-right-image-3');
        if (rightAddressElement && isAddressLogoPreview && addressLogoUrl) {
            initialLogoPreview(addressLogoUrl, rightAddressElement);
        }
    }, [isAddressLogoPreview, addressLogoUrl]);

    return (
        <Box width={178} height={192} border={`1px solid`} borderColor={is_active ? 'primary.main' : 'neutral.main'} padding={2} bgcolor={is_active ? 'primary.light' : 'neutral.light'}>
            <Box width={'100%'} height={'100%'} padding={1} bgcolor={'primary.contrastText'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box width={122} height={10} bgcolor="neutral.main" marginTop={3}></Box>
                <Box width={78} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                <Box width={78} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                <Box width={72} height={38} marginTop={2} marginLeft={2} display={'block'}>
                    <img id="preview-right-image-1" style={{ objectFit: 'contain', width: '100%', maxHeight: 38, display: isShowLogoPreview ? 'block' : 'none' }} />
                </Box>

                <Box width={122} height={4} bgcolor="neutral.main" marginTop={3}></Box>
                <Box width={122} height={4} bgcolor="neutral.main" marginTop={1}></Box>

                <Box width={'100%'} padding={1} marginTop={4} display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <Box width={24} height={20} display={'block'}>
                            <img id="preview-right-image-2" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: isPreparedByLogoPreview ? 'block' : 'none' }} />
                        </Box>

                        <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <Box width={24} height={20} display={'block'}>
                            <img id="preview-right-image-3" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: isAddressLogoPreview ? 'block' : 'none' }} />
                        </Box>
                        <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default RightImageSection;
