import { useLayoutEffect, useState } from 'react';

import { BodySmall, Box, Modal, ModalFooter, ModalHeader, RadioGroup, TextField, H6, CheckboxGroup, Skeleton } from '@parspec/pixel';
import { useTaxesPutMutation, useTaxesQuery } from './queries';
import { useParams } from 'react-router-dom';
import { INIT_CHECKBOX_OPTIONS, QUOTES_SECTION, RADIO_OPTIONS, TAX_CALC_VALUES } from './constants';
import { getAmountForUi } from '../../shared/utils';
import { useUserEventCount } from '../../../shared/UserActivityTracking/TrackActivityCount';

interface TaxesModalInterface {
    open: boolean;
    totalExtendedPrice: number;
    servicesTotal: number;
    freightTotal: number;
    onClose: () => void;
}

export default function TaxesModal({ open, onClose, totalExtendedPrice, servicesTotal, freightTotal }: TaxesModalInterface) {
    const { bomId } = useParams();
    const { pushUserEventCountAtBOM } = useUserEventCount();
    const { data: taxesData, isFetching: isTaxesReqLoading } = useTaxesQuery(Number(bomId), { enabled: Boolean(bomId) });
    const { mutateAsync: putTaxes, isLoading: isTaxesPutInProgress } = useTaxesPutMutation();

    const [selectedTaxCalculationMethod, setSelectedTaxCalculationMethod] = useState(TAX_CALC_VALUES.MANUAL_ENTRY);
    const [applyTaxesOptions, updateApplyTaxes] = useState(INIT_CHECKBOX_OPTIONS);
    const [taxPercentage, setTaxPercentage] = useState({ value: '', error: false });
    const [showTaxesOptionsError, setTaxesOptionError] = useState({ error: false, errorText: '' });

    useLayoutEffect(() => {
        if (taxesData?.data) {
            setSelectedTaxCalculationMethod(taxesData.data.calculation_method);
            if (taxesData.data.calculation_method === TAX_CALC_VALUES.MANUAL_ENTRY) {
                taxesData?.data.tax_percentage !== null && setTaxPercentage({ ...taxPercentage, value: String(taxesData?.data.tax_percentage) });
                const selectedFieldsForTaxesObj = {
                    [QUOTES_SECTION.PRODUCTS]: taxesData?.data.apply_taxes_to_products,
                    [QUOTES_SECTION.SERVICES]: taxesData?.data.apply_taxes_to_services,
                    [QUOTES_SECTION.FREIGHT]: taxesData?.data.apply_taxes_to_freight
                };
                const newCheckboxOptions = INIT_CHECKBOX_OPTIONS.map((taxOption) => {
                    return {
                        ...taxOption,
                        checked: selectedFieldsForTaxesObj[taxOption.name]
                    };
                });
                updateApplyTaxes(newCheckboxOptions);
            }
        }
    }, [taxesData?.data, isTaxesReqLoading]);

    function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value as TAX_CALC_VALUES;
        setSelectedTaxCalculationMethod(value);
    }

    function handleTaxPercentageChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        const separateDecimals = value.split('.')[1];
        if (separateDecimals && separateDecimals.length > 2) {
            return;
        }

        setTaxPercentage({ error: false, value: value });
    }

    function handleOnCheckBoxChange(name: string, checked: boolean) {
        setTaxesOptionError({ error: false, errorText: '' });
        const newState = applyTaxesOptions.map((option) => {
            if (option.name === name) {
                return { ...option, checked };
            }
            return { ...option };
        });
        updateApplyTaxes(newState);
    }

    function getFieldsValidation() {
        if (selectedTaxCalculationMethod === TAX_CALC_VALUES.TAX_EXEMPT) {
            return true;
        }

        let areFieldsValid = true;
        if (taxPercentage.value === '') {
            setTaxPercentage({ ...taxPercentage, error: true });
            areFieldsValid = false;
        }

        const isTaxOptionChecked = applyTaxesOptions.some((taxOptions) => taxOptions.checked);
        if (!isTaxOptionChecked) {
            areFieldsValid = false;
            setTaxesOptionError({ error: true, errorText: 'Select at least one' });
        }

        return areFieldsValid;
    }

    async function handleSave() {
        const areFieldsValid = getFieldsValidation();
        if (!areFieldsValid) {
            return;
        }
        const applyTaxesTo = applyTaxesOptions.reduce((currApplyTaxesTo, taxesOption) => {
            if (taxesOption.name === QUOTES_SECTION.FREIGHT) {
                currApplyTaxesTo.apply_taxes_to_freight = taxesOption.checked;
            } else if (taxesOption.name === QUOTES_SECTION.PRODUCTS) {
                currApplyTaxesTo.apply_taxes_to_products = taxesOption.checked;
            } else if (taxesOption.name === QUOTES_SECTION.SERVICES) {
                currApplyTaxesTo.apply_taxes_to_services = taxesOption.checked;
            }
            return currApplyTaxesTo;
        }, {} as { apply_taxes_to_freight: boolean; apply_taxes_to_products: boolean; apply_taxes_to_services: boolean });
        const taxPercent = selectedTaxCalculationMethod === TAX_CALC_VALUES.MANUAL_ENTRY ? Number(taxPercentage.value) : null;
        await putTaxes({ bomId: Number(bomId), reqBody: { calculation_method: selectedTaxCalculationMethod, tax_percentage: taxPercent, ...applyTaxesTo } });
        pushUserEventCountAtBOM({ eventType: 'taxes_manual_entry', count: Number(selectedTaxCalculationMethod === TAX_CALC_VALUES.MANUAL_ENTRY) });
        pushUserEventCountAtBOM({ eventType: 'taxes_excluded', count: Number(selectedTaxCalculationMethod === TAX_CALC_VALUES.TAX_EXEMPT) });
        pushUserEventCountAtBOM({ eventType: 'taxes_apply_to_products', count: Number(applyTaxesTo.apply_taxes_to_products) });
        pushUserEventCountAtBOM({ eventType: 'taxes_apply_to_services', count: Number(applyTaxesTo.apply_taxes_to_services) });
        pushUserEventCountAtBOM({ eventType: 'taxes_apply_to_freight', count: Number(applyTaxesTo.apply_taxes_to_freight) });
        onClose();
    }

    const totalAmountToCompute = applyTaxesOptions.reduce((acc, taxOption) => {
        if (taxOption.checked) {
            switch (taxOption.name) {
                case QUOTES_SECTION.PRODUCTS:
                    return acc + totalExtendedPrice;
                case QUOTES_SECTION.FREIGHT:
                    return acc + freightTotal;
                case QUOTES_SECTION.SERVICES:
                    return acc + servicesTotal;
            }
        }
        return acc;
    }, 0);

    const taxPercentageText = taxPercentage.value.trim();
    const actualTaxPercentage = taxPercentageText === '.' ? 0 : Number(taxPercentageText);
    const orderAmountInDollars = totalAmountToCompute;
    const totalAmount = orderAmountInDollars * (actualTaxPercentage / 100);
    const totalAmountForUi = getAmountForUi(totalAmount);
    return (
        <Modal
            open={open}
            header={<ModalHeader onClose={onClose} title="Taxes" />}
            footer={<ModalFooter onAccept={handleSave} onReject={onClose} continueButtonLabel="Save" isLoading={isTaxesPutInProgress} />}
        >
            <Box py={6} width="500px">
                <BodySmall color="secondary.light" paddingBottom={6}>
                    Determine how taxes will be calculated for your bill of materials.
                </BodySmall>
                {isTaxesReqLoading && (
                    <>
                        <Box pb={6}>
                            <Skeleton width="153px" height="114px" variant="rectangular" />
                        </Box>
                        <Box pb={6}>
                            <Skeleton width="150px" height="36px" variant="rectangular" />
                        </Box>
                        <Box pb={6}>
                            <Skeleton width="210px" height="157px" variant="rectangular" />
                        </Box>
                        <Box pb={6} display="flex" flexDirection="row-reverse" width="100%">
                            <Skeleton width="115px" height="32px" variant="rectangular" />
                        </Box>
                    </>
                )}
                {!isTaxesReqLoading && (
                    <>
                        <Box pb={6}>
                            <RadioGroup
                                label="Tax Calculation Method"
                                size="small"
                                name="taxCalculationMethod"
                                options={RADIO_OPTIONS}
                                onChange={handleRadioChange}
                                value={selectedTaxCalculationMethod}
                                disabled={isTaxesPutInProgress}
                            />
                        </Box>
                        {selectedTaxCalculationMethod === TAX_CALC_VALUES.MANUAL_ENTRY && (
                            <>
                                <Box pb={6} width="150px">
                                    <TextField
                                        value={taxPercentage.value}
                                        error={taxPercentage.error}
                                        helperText={taxPercentage.error ? 'Required' : undefined}
                                        onChange={handleTaxPercentageChange}
                                        onKeyDown={(event) => {
                                            if (event.key === 'e' || event.key === 'E') {
                                                event.preventDefault();
                                            }
                                        }}
                                        label="Tax Percentage"
                                        fullWidth
                                        placeholder="0.00"
                                        size="small"
                                        sx={{ input: { textAlign: 'right' } }}
                                        endIcon={<Box>%</Box>}
                                        disabled={isTaxesPutInProgress}
                                        type="number"
                                    />
                                </Box>
                                <Box pb={6}>
                                    <CheckboxGroup
                                        label="Apply Taxes To"
                                        options={applyTaxesOptions}
                                        onChange={handleOnCheckBoxChange}
                                        disabled={isTaxesPutInProgress}
                                        error={showTaxesOptionsError.error}
                                        helperText={showTaxesOptionsError.error ? showTaxesOptionsError.errorText : undefined}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="row-reverse">
                                    <H6>${totalAmountForUi}</H6>
                                    <H6 mr={10}>Total</H6>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Box>
        </Modal>
    );
}
