const BASE_DASHBOARD_URL = '/v2/dashboard';

export const TAB_VALUES = {
    PROJECT_TAB_VALUE: BASE_DASHBOARD_URL,
    BOM_TAB_VALUE: `${BASE_DASHBOARD_URL}/bom`,
    SUBMITTAL_TAB_VALUE: `${BASE_DASHBOARD_URL}/submittals`,
    OMPACKAGE_TAB_VALUE: `${BASE_DASHBOARD_URL}/omPackages`,
    QUOTES_TAB_VALUE: `${BASE_DASHBOARD_URL}/quotes`
};

export const TAB_LABEL = {
    PROJECT_TAB_LABEL: 'Projects',
    BOM_TAB_LABEL: 'Bill of Materials',
    SUBMITTAL_TAB_LABEL: 'Submittals',
    OM_TAB_LABEL: 'O&M Packages',
    QUOTE_LABEL: 'Quotes'
};

export const TAB_OPTIONS = [
    { label: 'Projects', value: TAB_VALUES.PROJECT_TAB_VALUE },
    { label: 'Bill of Materials', value: TAB_VALUES.BOM_TAB_VALUE },
    { label: 'Quotes', value: TAB_VALUES.QUOTES_TAB_VALUE },
    { label: 'Submittals', value: TAB_VALUES.SUBMITTAL_TAB_VALUE },
    { label: 'O&M Packages', value: TAB_VALUES.OMPACKAGE_TAB_VALUE }
];

export const TAB_OPTIONS_WITHOUT_QUOTE = [
    { label: 'Projects', value: TAB_VALUES.PROJECT_TAB_VALUE },
    { label: 'Bill of Materials', value: TAB_VALUES.BOM_TAB_VALUE },
    { label: 'Submittals', value: TAB_VALUES.SUBMITTAL_TAB_VALUE },
    { label: 'O&M Packages', value: TAB_VALUES.OMPACKAGE_TAB_VALUE }
];

export const DUE_OPTIONS = [
    { label: 'This Week', value: 'week' },
    { label: 'This Month', value: 'month' },
    { label: 'This Year', value: 'year' }
];

export const OWNED_OPTIONS = [
    { label: 'Me', value: 'me' },
    { label: 'My Team', value: 'team' }
];

export interface DashboardTableOutlet {
    height: number;
}

export const VIEW_OPTIONS = [
    { name: 'My Work', value: 'my_work' },
    { name: 'Team Work', value: 'team_work' }
];

export enum VIEW_OPTION_VALUES {
    TEAM_WORK = 'team_work',
    MY_WORK = 'my_work'
}

export const STAKEHOLDER_POSITION = {
    FIRST: { label: 'Stakeholder #1', value: 'first' },
    SECOND: { label: 'Stakeholder #2', value: 'second' },
    THIRD: { label: 'Stakeholder #3', value: 'third' },
    FOURTH: { label: 'Stakeholder #4', value: 'fourth' },
    FIFTH: { label: 'Stakeholder #5', value: 'fifth' }
};
