import { PRIORITY_VALUES } from '../../Project/shared/utils';

export enum DUE_DATE_TYPES {
    QUOTE = 'Quote',
    OM = 'O&M',
    SUBMITTAL = 'Submittal'
}

export const DUE_DATE_TYPE_OPTIONS = [
    { label: 'Quote Due', value: DUE_DATE_TYPES.QUOTE },
    { label: 'Submittal Due', value: DUE_DATE_TYPES.SUBMITTAL },
    { label: 'O&M Due', value: DUE_DATE_TYPES.OM }
];

export const PRIORITY_OPTIONS = [
    { label: 'High', value: PRIORITY_VALUES.HIGH },
    { label: 'Medium', value: PRIORITY_VALUES.MEDIUM },
    { label: 'Low', value: PRIORITY_VALUES.LOW }
];

export enum FILTER_KEYS {
    SEARCH_TEXT = 'searchText',
    DUE_DATE_TYPES = 'dueDateTypes',
    PRIORITIES = 'priorities',
    BRANCH_LOCATIONS = 'branchLocations',
    OWNERS = 'owners',
    PROJECT_STAGES = 'projectStages'
}

export const FILTERS_INITIAL_VALUE = {
    [FILTER_KEYS.SEARCH_TEXT]: '',
    [FILTER_KEYS.DUE_DATE_TYPES]: [],
    [FILTER_KEYS.PRIORITIES]: [],
    [FILTER_KEYS.BRANCH_LOCATIONS]: [],
    [FILTER_KEYS.OWNERS]: [],
    [FILTER_KEYS.PROJECT_STAGES]: []
};

export const AVATAR_COLORS = ['#5048E5', '#E89827', '#BE123C', '#E65100', '#047857', '#6F3FDE', '#0F172A'];

export const UNASSIGNED_AVATAR_COLOR = '#64748B';
