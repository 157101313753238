import { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';

import { AutocompleteChangeReason, Box, Skeleton } from '@parspec/pixel';

import { MultiSelectRow } from './MultiSelectRow';
import { SliderRow } from './SliderRow';
import {
    MULTI_SELECT_ACTIONS,
    MULTI_SELECT_FIELDS_CONSTANT,
    SLIDERFIELD_CONSTANT,
    SLIDER_ACTIONS,
    SELECT_FIELD_CONSTANT,
    MANUFACTURER_FIELD_CONSTANT,
    ADDITIONAL_CRITERIA_CONSTANT,
    SEARCH_IMAGES
} from '../constants';
import { useProductRequirementGetQuery } from '../queries';
import ProductAttributeFormSkeleton from './Skeleton';
import { ManufactererRow } from './ManufactererRow';
import { useParspecMfg, useCompanyMfgQuery } from 'src/features/Settings/Manufacturer/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { useGetBomDetails } from '../../../queries';
import {
    ManufacturerDropdownValType,
    MultiSelectValType,
    ManufacturerDropdownType,
    DropdownOptionsType,
    RangeSliderValType,
    FieldsNameType,
    FieldData,
    SliderActionType,
    MultiSelectFieldsNameType,
    ManufacturerNameType,
    SliderFieldsNameType,
    SelectFieldsNameType,
    ManufacturerGroupType
} from '../types';
import { RowContainer } from './RowContainer';
import AdditionalCriteria from './AdditionalCriteria';
import ReferenceImageSelectorForProductFinder from '../ReferenceImageSelector';

interface ProductAttributesFormProps {
    selectedManufacturers: ManufacturerDropdownValType;
    selectedProductCategory: MultiSelectValType;
    selectedApplication: MultiSelectValType;
    selectedMounting: MultiSelectValType;
    selectedEnvironment: MultiSelectValType;
    selectedDimmingProtocol: MultiSelectValType;
    selectedDimmingRange: MultiSelectValType;
    selectedVoltage: MultiSelectValType;
    manufacturerOptions: ManufacturerDropdownType[];
    manufacturerGroup: ManufacturerGroupType[];
    productCategoryOptions: DropdownOptionsType;
    mountingOptions: DropdownOptionsType;
    environmentOptions: DropdownOptionsType;
    dimmingProtocolOptions: DropdownOptionsType;
    dimmingRangeOptions: DropdownOptionsType;
    voltageOptions: DropdownOptionsType;
    applicationOptions: DropdownOptionsType;
    lumen: RangeSliderValType;
    cct: RangeSliderValType;
    cri: RangeSliderValType;
    lumenUnitOptions: DropdownOptionsType;
    currentLumenUnit: string;
    isActionDisabled?: boolean;
    onFieldChange: <T extends FieldsNameType>(field: T, fieldData: FieldData<T>, reason?: AutocompleteChangeReason) => void;
    onMultiSelectBlur: (field: ManufacturerNameType | MultiSelectFieldsNameType) => void;
    onSliderBlur: (field: FieldsNameType, fieldData: SliderActionType) => void;
    onUnitSelectBlur: (field: FieldsNameType) => void;
    onAdditionalCriteriaBlur: (value?: string) => void;
    additionalCriteria: string;
    searchImageS3Path: string;
    searchImageLocalPath: string;
    clearSearchImage: () => void;
}

export function ProductAttributesForm({
    selectedManufacturers,
    selectedProductCategory,
    selectedApplication,
    selectedMounting,
    selectedEnvironment,
    selectedDimmingProtocol,
    selectedDimmingRange,
    selectedVoltage,
    manufacturerOptions,
    productCategoryOptions,
    mountingOptions,
    environmentOptions,
    dimmingProtocolOptions,
    dimmingRangeOptions,
    applicationOptions,
    voltageOptions,
    lumen,
    cct,
    cri,
    lumenUnitOptions,
    currentLumenUnit,
    isActionDisabled,
    onFieldChange: handleFieldsChange,
    onMultiSelectBlur: handleMultiSelectBlur,
    onSliderBlur: handleSliderBlur,
    onUnitSelectBlur: handleUnitBlur,
    onAdditionalCriteriaBlur: handleAdditionalCriteriaOnBlur,
    clearSearchImage,
    manufacturerGroup,
    additionalCriteria,
    searchImageS3Path,
    searchImageLocalPath
}: ProductAttributesFormProps) {
    const { bomId = 0, productId = 0 } = useParams();

    const { isLoading: isGetReqLoading, isFetching: isGetReqFetching } = useProductRequirementGetQuery(Number(bomId), Number(productId));
    const { isFetching: parspecManufacturerLoading } = useParspecMfg();
    const { isFetching: companyManufacturerLoading } = useCompanyMfgQuery();
    const { isFetching: bomDetailsLoading, data: bomDetails } = useGetBomDetails(`${bomId || 0}`, { enabled: Boolean(bomId) });

    const { isFetching: projectDetailsLoading } = useGetProjectDetailsQuery(bomDetails?.data.project || 0, { enabled: Boolean(bomDetails?.data.project) });

    const isLoading = isGetReqLoading || isGetReqFetching;
    const isMfgLoading = parspecManufacturerLoading || companyManufacturerLoading || bomDetailsLoading || projectDetailsLoading;

    function getOnSelectCallback(field: MultiSelectFieldsNameType | ManufacturerNameType) {
        return (value: DropdownOptionsType, reason: AutocompleteChangeReason) => {
            handleFieldsChange(field, { type: MULTI_SELECT_ACTIONS.UPDATE, value }, reason);
        };
    }

    const handleFileUpload = (filePath: string) => {
        handleFieldsChange(SEARCH_IMAGES, { s3ImgPath: filePath });
    };

    const handleFileSelect = (filePath: string) => {
        handleFieldsChange(SEARCH_IMAGES, { localImgPath: filePath });
    };

    function getOnLockChangeCallbackForMultiSelect(field: MultiSelectFieldsNameType) {
        return (isLocked: boolean) => {
            handleFieldsChange(field, { type: MULTI_SELECT_ACTIONS.LOCK, value: isLocked });
        };
    }

    function getOnLockChangeCallbackForSlider(field: SliderFieldsNameType) {
        return (isLocked: boolean) => {
            handleFieldsChange(field, { type: SLIDER_ACTIONS.LOCK, value: isLocked });
        };
    }

    function getOnSliderChangeCallback(field: SliderFieldsNameType) {
        return (value: [number, number]) => {
            handleFieldsChange(field, { type: SLIDER_ACTIONS.UPDATE, value });
        };
    }

    function getOnSliderBlurCallback(field: SliderFieldsNameType) {
        return (_event: React.FocusEvent<HTMLInputElement, Element> | React.KeyboardEvent<HTMLElement>, value: [number, number]) => {
            handleSliderBlur(field, { type: SLIDER_ACTIONS.UPDATE, value });
        };
    }

    function getOnUnitChangeCallback(field: SelectFieldsNameType) {
        return (value: string) => {
            handleFieldsChange(field, value);
        };
    }

    function getAdditionalCriteriaChangeCallback() {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleFieldsChange(ADDITIONAL_CRITERIA_CONSTANT, event.target.value);
        };
    }

    function getSliderOnClearCallback(field: SliderFieldsNameType) {
        return () => {
            handleFieldsChange(field, { type: SLIDER_ACTIONS.CLEAR });
        };
    }

    return (
        <Box p={6} pt={0} flex={1}>
            {isLoading && <ProductAttributeFormSkeleton />}
            {!isLoading && (
                <>
                    {isMfgLoading && (
                        <RowContainer forSkeleton>
                            <Skeleton height="46.71px" width="100%" key={'mfg-skeleton'} variant="rectangular" />
                        </RowContainer>
                    )}
                    {!isMfgLoading && (
                        <ManufactererRow
                            id="manufacturer-input"
                            label="Acceptable Manufacturer(s)"
                            options={manufacturerOptions}
                            manufacturerGroup={manufacturerGroup}
                            selectedOptions={selectedManufacturers}
                            error={selectedManufacturers.error}
                            onSelect={getOnSelectCallback(MANUFACTURER_FIELD_CONSTANT)}
                            onBlur={() => handleMultiSelectBlur(MANUFACTURER_FIELD_CONSTANT)}
                            disableRow={isActionDisabled}
                        />
                    )}
                    <MultiSelectRow
                        id="prod-category-input"
                        label="Product Category"
                        options={productCategoryOptions}
                        selectedOptions={selectedProductCategory}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.CATEGORY)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.CATEGORY)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.CATEGORY)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="application-input"
                        label="Application"
                        options={applicationOptions}
                        selectedOptions={selectedApplication}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.APPLICATION)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.APPLICATION)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.APPLICATION)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="mounting-input"
                        label="Mounting Type"
                        options={mountingOptions}
                        selectedOptions={selectedMounting}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.MOUNTING_TYPE)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="environment-input"
                        label="Environment"
                        options={environmentOptions}
                        selectedOptions={selectedEnvironment}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.ENVIRONMENT)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="dimming-protocol-input"
                        label="Dimming Protocol"
                        options={dimmingProtocolOptions}
                        selectedOptions={selectedDimmingProtocol}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_PROTOCOL)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="dimming-range-input"
                        label="Dimming Range"
                        options={dimmingRangeOptions}
                        selectedOptions={selectedDimmingRange}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.DIMMING_RANGE)}
                        disableRow={isActionDisabled}
                    />
                    <MultiSelectRow
                        id="voltage-input"
                        label="Voltage"
                        options={voltageOptions}
                        selectedOptions={selectedVoltage}
                        onSelect={getOnSelectCallback(MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE)}
                        onLockChange={getOnLockChangeCallbackForMultiSelect(MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE)}
                        onBlur={() => handleMultiSelectBlur(MULTI_SELECT_FIELDS_CONSTANT.VOLTAGE)}
                        disableRow={isActionDisabled}
                    />
                    <SliderRow
                        showUnit
                        steps={1000}
                        unit={currentLumenUnit}
                        unitOptions={lumenUnitOptions}
                        sliderInfo={lumen}
                        onLockChange={getOnLockChangeCallbackForSlider(SLIDERFIELD_CONSTANT.LUMENS)}
                        onSliderChange={getOnSliderChangeCallback(SLIDERFIELD_CONSTANT.LUMENS)}
                        onTextfieldBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.LUMENS)}
                        onRangeBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.LUMENS)}
                        onUnitBlur={() => handleUnitBlur(SELECT_FIELD_CONSTANT.LUMENS_UNIT)}
                        onUnitChange={getOnUnitChangeCallback(SELECT_FIELD_CONSTANT.LUMENS_UNIT)}
                        onClear={getSliderOnClearCallback(SLIDERFIELD_CONSTANT.LUMENS)}
                        leftTextFieldWidth={64}
                        rightFieldWidth={79}
                        showPlus
                        header="Lumens"
                        disableRow={isActionDisabled}
                    />
                    <SliderRow
                        showUnit={false}
                        unitInfo="Kelvin"
                        sliderInfo={cct}
                        onLockChange={getOnLockChangeCallbackForSlider(SLIDERFIELD_CONSTANT.CCT)}
                        onSliderChange={getOnSliderChangeCallback(SLIDERFIELD_CONSTANT.CCT)}
                        onTextfieldBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.CCT)}
                        onRangeBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.CCT)}
                        onClear={getSliderOnClearCallback(SLIDERFIELD_CONSTANT.CCT)}
                        leftTextFieldWidth={64}
                        rightFieldWidth={70}
                        showPlus
                        header="CCT (Correlated Color Temperature)"
                        disableRow={isActionDisabled}
                    />
                    <SliderRow
                        showUnit={false}
                        sliderInfo={cri}
                        onLockChange={getOnLockChangeCallbackForSlider(SLIDERFIELD_CONSTANT.CRI)}
                        onSliderChange={getOnSliderChangeCallback(SLIDERFIELD_CONSTANT.CRI)}
                        onTextfieldBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.CRI)}
                        onRangeBlur={getOnSliderBlurCallback(SLIDERFIELD_CONSTANT.CRI)}
                        onClear={getSliderOnClearCallback(SLIDERFIELD_CONSTANT.CRI)}
                        leftTextFieldWidth={64}
                        rightFieldWidth={64}
                        header="CRI (Color Rendering Index)"
                        disableRow={isActionDisabled}
                        lastRow
                    />

                    <AdditionalCriteria value={additionalCriteria} onChange={getAdditionalCriteriaChangeCallback()} onBlur={() => handleAdditionalCriteriaOnBlur()} />
                    <ReferenceImageSelectorForProductFinder
                        s3ImgPath={searchImageS3Path}
                        localImgPath={searchImageLocalPath}
                        onFileUpload={handleFileUpload}
                        onFileSelect={handleFileSelect}
                        clearUploadedFile={clearSearchImage}
                    />
                </>
            )}
        </Box>
    );
}
