import { useEffect, useMemo } from 'react';
import { useCheckAccessForProductFinderOrQuoting } from './useCheckAccessForProductFinderOrQuoting';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { useCompanyMfgQuery } from '../../Settings/Manufacturer/queries';
import { useUserInfoQuery } from '../../Settings/UserManagement/queries';
import { environment } from 'src/environments';
import { useFeatureFlag } from '../UserActivityTracking/useFeatureFlag';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { useGetAuthToken } from 'src/features/Authentication/hooks';

declare global {
    interface Window {
        [key: string]: any;
    }
}

export const useInitialiseIntercom = () => {
    const { token } = useGetAuthToken();

    const { data: userData, isLoading: isUserDataLading } = useGetUserProfileInfoQuery({ enabled: Boolean(token) });

    const { productFinderAccess, quotingAccess, isLoading: isAccessLoading } = useCheckAccessForProductFinderOrQuoting();
    const { enable_quoting } = useFeatureFlag();
    const { data: companyMfgData, isLoading: isCompanyMfgLoading } = useCompanyMfgQuery({ enabled: Boolean(token) });
    const { data: allUserInfo, isLoading: isAllUserInfoLoading } = useUserInfoQuery({ enabled: Boolean(token) });
    const { data: paymentDetail, isLoading: isSubscriptionInfoLoading } = useSubscriptionInfo({ enabled: Boolean(token) });

    const isLoading = isUserDataLading && isCompanyMfgLoading && isAllUserInfoLoading && isAccessLoading && isSubscriptionInfoLoading;
    const allowIntercom = environment.Z && userData?.data && userData?.data?.is_test_account === false;
    const initialisedIntercomAttributes = useMemo(() => {
        let intialisedData;
        if (!isLoading && userData && companyMfgData) {
            const { first_name, last_name, role, billing_company_name } = userData.data;
            const teammates = allUserInfo && allUserInfo?.data?.length - 1;
            const company_manufacture = companyMfgData?.data?.length;
            const tier = paymentDetail?.data?.my_plan?.plan;
            intialisedData = {
                user_first_name: first_name,
                user_last_name: last_name,
                user_role: role,
                name: `${first_name} ${last_name}`,
                teammates,
                tier,
                company_manufacture,
                company_name: billing_company_name,
                is_pf_enabled: productFinderAccess,
                is_quoting_enabled: enable_quoting && quotingAccess
            };
        }
        return intialisedData;
    }, [isLoading, userData, companyMfgData, enable_quoting, productFinderAccess, quotingAccess]);

    const updateIntercomAttributes = async (attributes: object) => {
        if (allowIntercom) {
            window.Intercom('update', attributes);
        }
    };
    useEffect(() => {
        if (initialisedIntercomAttributes) {
            updateIntercomAttributes(initialisedIntercomAttributes);
        }
    }, [initialisedIntercomAttributes]);
};

export const useTrackIntercomEvent = () => {
    const { data: userData } = useGetUserProfileInfoQuery();
    const allowIntercom = environment.Z && userData?.data && userData?.data?.is_test_account === false;
    const trackIntercomEvent = (eventName: string) => {
        if (allowIntercom) {
            window.Intercom('trackEvent', eventName);
        }
    };
    return { trackIntercomEvent };
};
