import { axios } from 'src/app/Axios';
import { CompanyDataResponse, CompanyDetailResponse, CreateCompanyRequest, EditCompanyRequest, CompanyObjType, ImportFileResponse } from './apiTypes';

export const endpoints = {
    getCompanyDetails: (id: number) => `/rolodex/company/${id}/`,
    postCompanyDetails: () => '/rolodex/company/',
    patchCompanyDetails: (id: number) => `/rolodex/company/${id}/`,
    bulkImport: () => '/rolodex/import/'
};

export const getCompanyTableData = async (url: string) => {
    return axios.get<CompanyDataResponse>(url);
};

export const getCompanyDetailsData = async (id: number) => {
    return axios.get<CompanyDetailResponse>(endpoints.getCompanyDetails(id));
};

export const createCompany = async (payload: CreateCompanyRequest) => {
    return axios.post<CompanyObjType>(endpoints.postCompanyDetails(), { ...payload });
};

export const editCompany = async (request: EditCompanyRequest) => {
    const { id, payload } = request;
    return axios.patch<CompanyObjType>(endpoints.patchCompanyDetails(id), { ...payload });
};

export const deleteCompany = async (companyIds: number[]) => {
    return axios.delete(endpoints.postCompanyDetails(), { data: { id: companyIds } });
};

export const bulkUpload = async (data: FormData) => {
    return axios.post<ImportFileResponse>(endpoints.bulkImport(), data);
};
