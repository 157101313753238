import React, { Dispatch, SetStateAction, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

import { AgGridTable, Box, H6, Snackbar, BodySmall, TableHeaderMenuIcon, Button } from '@parspec/pixel';

import { BOM_DELETE_CONFIRMATION_TITLE, deleteMsg, duplicateMsg, someThingWentWrongMsg, BOM_DELETE_HEADING, BOM_DELETE_SUB_HEADING, OM_DOC, SUBMITTAL_DOC, QUOTE } from '../../shared/constants';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { useDuplicateBomInProject, useGetProjectBomsQuery, useDeleteBomInProject } from '../queries';
import { useDocumentTags } from '../../Dashboard/queries';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { SubsriptionPopup } from '../../../app/ProtectedRoute';
import { useCheckAccessForProductFinderOrQuoting } from '../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import { Customer, LastModified } from '../../Dashboard/shared/components';
import { getRowId } from './utils';
import { defaultColDef, BomTemplate, ViewDocumentsTemplate, DocumentStatusTemplate, CustomNoRowsOverlayComponent } from './components';
import { getDateComparator, numberComparator, stringComparator } from '../../Dashboard/shared/utils/utils';
import { getLastModifiedValue } from '../shared/utils';
import { MenuTemplate } from './components';
import { MenuContext } from './context';
import { dollarFormatter } from 'src/features/Dashboard/ProjectsTable/utils';

interface BomTableProps {
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EMPTY_ARRAY: [] = [];

const TOOL_TIP_MESSAGE = 'To delete this BOM, you must first assign another BOM as Primary. Open Manage BOMs to reassign.';

export const BomTable: React.FC<BomTableProps> = React.memo(({ setModalOpen }) => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackBar, toggleSnackBarView] = useState(false);
    const { projectId } = useParams();
    const { data: projectBoms, isLoading } = useGetProjectBomsQuery(Number(projectId)!, {
        enabled: Boolean(projectId)
    });

    const { mutateAsync: deleteBom, isLoading: deleteLoading } = useDeleteBomInProject(Number(projectId));

    const { quotingAccess, isLoading: isUserLevelQuotingAccessCheckLoading } = useCheckAccessForProductFinderOrQuoting();
    const { enable_quoting } = useFeatureFlag();

    const [deleteId, setDeleteId] = useState<string | null>();

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

    const gridRef = useRef<any>(null);

    const { data: quoteTagsData } = useDocumentTags(QUOTE);
    const { data: submittalTagsData } = useDocumentTags(SUBMITTAL_DOC);
    const { data: omTagsData } = useDocumentTags(OM_DOC);

    const { mutateAsync: duplicateBom, isLoading: isDuplicateInProgress } = useDuplicateBomInProject();

    const { data: paymentDetail } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const onClickDelete = (props: any) => {
        setDeleteId(props.id);
        setOpenConfirmationDialog(true);
    };

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    function setSnackbarMessageToView(msg: string) {
        setSnackbarMessage(msg);
        toggleSnackBarView(true);
    }

    function hideSnackbar() {
        toggleSnackBarView(false);
    }

    const onDeleteLineItem = async () => {
        try {
            await deleteBom(Number(deleteId));
            setSnackbarMessageToView(deleteMsg);
        } catch (error) {
            setSnackbarMessageToView(someThingWentWrongMsg);
            toggleSnackBarView(true);
        }
        setDeleteId(null);
        closeConfirmationDialog();
    };

    const onDuplicate = async (props: any) => {
        const { id } = props;
        try {
            await duplicateBom({ bomId: Number(id), projectId: Number(projectId) });
            setSnackbarMessageToView(duplicateMsg);
        } catch (error) {
            setSnackbarMessageToView(someThingWentWrongMsg);
        }
    };

    const isTableLoading = isLoading || isDuplicateInProgress || isUserLevelQuotingAccessCheckLoading;

    const bomTableDataForUi = useMemo(() => {
        if (!projectBoms?.data) return EMPTY_ARRAY;

        const data = projectBoms.data.map((bom) => {
            const currentBomData = { ...bom, quoteStatus: '', submittalStatus: '', omStatus: '' };

            if (quoteTagsData?.data) {
                for (const quoteTag of quoteTagsData?.data || []) {
                    if (currentBomData?.quote && currentBomData.id === quoteTag.bom_id) {
                        for (const quote of currentBomData.quote) {
                            if (currentBomData.id === quoteTag.bom_id && quote.id === quoteTag.entity_id && quoteTag.entity_type === QUOTE) {
                                currentBomData.quoteStatus = quoteTag?.tag_name as string;
                            }
                        }
                    }
                }
            }

            if (submittalTagsData?.data) {
                for (const submittalTag of submittalTagsData?.data || []) {
                    if (currentBomData?.submittal && currentBomData.id === submittalTag.bom_id) {
                        for (const submittal of currentBomData.submittal) {
                            if (currentBomData.id === submittalTag.bom_id && submittal.id === submittalTag.entity_id && submittalTag.entity_type === SUBMITTAL_DOC) {
                                currentBomData.submittalStatus = submittalTag?.tag_name as string;
                            }
                        }
                    }
                }
            }

            if (omTagsData?.data) {
                for (const omTag of omTagsData?.data || []) {
                    if (currentBomData?.om && currentBomData.id === omTag.bom_id) {
                        for (const om of currentBomData.om) {
                            if (currentBomData.id === omTag.bom_id && om.id === omTag.entity_id && omTag.entity_type === OM_DOC) {
                                currentBomData.omStatus = omTag?.tag_name as string;
                            }
                        }
                    }
                }
            }

            return currentBomData;
        });

        return data;
    }, [projectBoms, quoteTagsData?.data, submittalTagsData?.data, omTagsData?.data]);

    const columnDefs = useMemo<ColDef[]>(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => <BomTemplate {...(params?.data || {})} />,
                sortable: true,
                comparator: (valueA: { name: string; isLinked: boolean; isPrimary: boolean }, valueB: { name: string; isLinked: boolean; isPrimary: boolean }) =>
                    stringComparator(valueA?.name, valueB?.name),
                valueGetter: (params: ValueGetterParams) => ({
                    name: params?.data?.name,
                    isLinked: params?.data?.is_linked,
                    isPrimary: params?.data?.is_primary
                })
            },
            {
                field: 'primary_contact.company.name',
                headerName: 'Primary Customer',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => <Customer {...(params?.data?.primary_contact || {})} />,
                sortable: true
            },
            {
                field: 'grand_total_cents',
                headerName: 'Grand Total',
                cellRenderer: (params: ICellRendererParams) => dollarFormatter(params?.data?.grand_total_cents),
                minWidth: 150,
                comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
                    const valA = Number(nodeA?.data?.grand_total_cents);
                    const valB = Number(nodeB?.data?.grand_total_cents);

                    return numberComparator(valA, valB);
                },
                sortable: true
            },
            {
                field: 'secondary_contact.company.name',
                headerName: 'Secondary Customer',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => <Customer {...(params?.data?.secondary_contact || {})} />,
                sortable: true
            },
            {
                field: 'sales_agent.name',
                headerName: 'Outside Sales',
                editable: false,
                minWidth: 80,
                width: 150,
                hide: !(enable_quoting && quotingAccess),
                sortable: true
            },
            {
                field: 'updated_at',
                headerName: 'Last Modified',
                editable: false,
                minWidth: 80,
                width: 160,
                cellRenderer: (params: ICellRendererParams) => <LastModified updatedBy={params?.data?.last_edited_by} updatedAt={params?.data?.last_edited_at} />,
                sortable: true,
                comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'last_edited_at'),
                valueGetter: (params: ValueGetterParams) => getLastModifiedValue(params)
            },
            {
                headerName: 'Quote Status',
                editable: false,
                minWidth: 80,
                width: 160,
                cellRenderer: (params: ICellRendererParams) => (
                    <DocumentStatusTemplate
                        {...{
                            data: params?.data?.quote,
                            bomId: params?.data?.id,
                            documentType: QUOTE,
                            projectId: params.data?.project,
                            status: params?.data?.quoteStatus
                        }}
                    />
                ),
                hide: !(enable_quoting && quotingAccess),
                sortable: false
            },
            {
                headerName: 'Submittal Status',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => (
                    <DocumentStatusTemplate
                        {...{
                            data: params?.data?.submittal,
                            bomId: params?.data.id,
                            documentType: SUBMITTAL_DOC,
                            projectId: params?.data?.project,
                            status: params?.data?.submittalStatus
                        }}
                    />
                ),
                sortable: false
            },
            {
                headerName: 'O&M Status',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: (params: ICellRendererParams) => (
                    <DocumentStatusTemplate
                        {...{
                            data: params?.data?.om,
                            bomId: params?.data?.id,
                            documentType: OM_DOC,
                            projectId: params?.data?.project,
                            status: params?.data?.omStatus
                        }}
                    />
                ),
                sortable: false
            },
            {
                headerName: '',
                editable: false,
                minWidth: 80,
                width: 150,
                cellRenderer: ViewDocumentsTemplate,
                sortable: false
            },
            {
                headerComponent: () => (
                    <Box mt={1}>
                        <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                    </Box>
                ),
                cellRenderer: MenuTemplate,
                minWidth: 50,
                maxWidth: 50,
                resizable: false,
                sortable: false
            }
        ];
    }, [quotingAccess, enable_quoting]);

    const { primaryBomTableData, linkedUnlinkedBomTableData } = useMemo(() => {
        const primaryBomTableData: typeof bomTableDataForUi = [];
        const linkedUnlinkedBomTableData: typeof bomTableDataForUi = [];

        bomTableDataForUi.forEach((item) => {
            if (item.is_primary) {
                primaryBomTableData.push(item);
            } else {
                linkedUnlinkedBomTableData.push(item);
            }
        });

        return {
            primaryBomTableData,
            linkedUnlinkedBomTableData
        };
    }, [bomTableDataForUi]);

    const gridOptions = {
        rowData: isTableLoading ? null : linkedUnlinkedBomTableData,
        getRowId,
        rowHeight: 52,
        columnDefs,
        defaultColDef,
        isLoading: isTableLoading,
        tableHeight: '527px',
        showToolbarPanel: false,
        selectedRowCount: 0,
        disabledToolBarButton: true,
        pagination: true, // Enable pagination
        paginationAutoPageSize: true,
        pinnedTopRowData: primaryBomTableData,
        suppressContextMenu: true,
        suppressMenuHide: true,
        noRowsOverlayComponent: CustomNoRowsOverlayComponent
    };

    return (
        <>
            <Snackbar open={showSnackBar} onClose={hideSnackbar} message={snackbarMessage}></Snackbar>
            <Box mt={6}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <H6>Bills Of Materials</H6>
                    {!isTableLoading && (
                        <Box>
                            <Button
                                onClick={() => {
                                    if (isActiveSubscription) {
                                        setModalOpen(true);
                                    } else handleSubscriptionPopup();
                                }}
                            >
                                {linkedUnlinkedBomTableData.length || primaryBomTableData.length ? 'Manage BOMs' : 'Create BOM'}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box mt={2}>
                    <Box width={'100%'} height={527}>
                        <Box className="ag-theme-alpine" width={'100%'} height={'100%'} my={4}>
                            <MenuContext.Provider value={{ onClickDelete, onDuplicate, isActiveSubscription, handleSubscriptionPopup, TOOL_TIP_MESSAGE, bomDataLength: bomTableDataForUi?.length }}>
                                <AgGridTable ref={gridRef} {...gridOptions} />
                            </MenuContext.Provider>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {openConfirmationDialog && (
                <DeleteConfirmationDialog
                    title={BOM_DELETE_CONFIRMATION_TITLE}
                    open={openConfirmationDialog}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={onDeleteLineItem}
                    loading={deleteLoading}
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {BOM_DELETE_HEADING}
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            {BOM_DELETE_SUB_HEADING}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
        </>
    );
});
