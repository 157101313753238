import { FC, useMemo } from 'react';

import { Box, useTheme } from '@parspec/pixel';

import {
    MARGIN_REVENUE_BAR_INFO,
    PROFIT_CHART_HEADER,
    PROFIT_CHART_SUB_HEADER,
    PROFIT_MARGIN_BAR_INFO,
    PROFIT_MARGIN_LEGENDS,
    REVENUE_PROFIT_CHART_HEADER,
    REVENUE_PROFIT_LEGENDS,
    REVENUE_PROFIT_SUB_HEADER,
    TAB_NAMES
} from '../shared/constants';
import { ManufacturersTabDataResponse } from '../queries/apiTypes';
import { getAverage, getProfitMarginChartData, getRevenueProfitChartData, millionFormmater, nbspFormatter, percentageFormatter } from '../shared/utils';
import BarChart from '../shared/components/BarChart';
interface ManufacturersGraphsProps {
    manufacturersData?: ManufacturersTabDataResponse[];
}

const ManufacturersGraphs: FC<ManufacturersGraphsProps> = ({ manufacturersData }) => {
    const muiTheme = useTheme();

    const graphDataObject = useMemo(() => {
        if (!manufacturersData) return;
        const profitMarginChartData = getProfitMarginChartData(manufacturersData, TAB_NAMES.MANUFACTURER);
        const revenueMarginChartData = getRevenueProfitChartData(manufacturersData, TAB_NAMES.MANUFACTURER);
        return { profitMarginChartData, revenueMarginChartData };
    }, [manufacturersData]);

    const profitMarginAverage = useMemo(() => {
        const profitMargins = (manufacturersData || [])?.map((obj) => {
            const { totalExtSellPrice, totalExtDiscountedCost } = obj;
            return !totalExtSellPrice ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / totalExtSellPrice) * 100;
        });
        return getAverage(profitMargins || []);
    }, [manufacturersData]);

    const graphDataLoading = !graphDataObject;

    return (
        <Box display="flex" gap={4} width={1}>
            <Box width={1}>
                <BarChart
                    isLoading={graphDataLoading}
                    header={PROFIT_CHART_HEADER(TAB_NAMES.MANUFACTURER)}
                    subHeader={PROFIT_CHART_SUB_HEADER}
                    legends={PROFIT_MARGIN_LEGENDS}
                    data={graphDataObject?.['profitMarginChartData'] || []}
                    barsInfo={PROFIT_MARGIN_BAR_INFO}
                    referenceLineInfo={[{ value: profitMarginAverage, color: muiTheme.palette.tertiary.main }]}
                    xAxisTickFormatter={percentageFormatter}
                    yAxisTickFormatter={nbspFormatter}
                    referenceLabelFormatter={percentageFormatter}
                    tooltipValueFormatter={percentageFormatter}
                />
            </Box>
            <Box width={1}>
                <BarChart
                    isLoading={graphDataLoading}
                    header={REVENUE_PROFIT_CHART_HEADER(TAB_NAMES.MANUFACTURER)}
                    subHeader={REVENUE_PROFIT_SUB_HEADER}
                    legends={REVENUE_PROFIT_LEGENDS}
                    data={graphDataObject?.['revenueMarginChartData'] || []}
                    barsInfo={MARGIN_REVENUE_BAR_INFO}
                    xAxisTickFormatter={millionFormmater}
                    yAxisTickFormatter={nbspFormatter}
                    referenceLabelFormatter={millionFormmater}
                    tooltipValueFormatter={millionFormmater}
                />
            </Box>
        </Box>
    );
};

export default ManufacturersGraphs;
