import { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Box, Breadcrumb, H5, Skeleton, EditIcon, TextField } from '@parspec/pixel';

import PagesHeader from '../shared/PagesHeader';
import { useGetProjectDetailsQuery } from './queries';
import { Errors } from '../shared/utils/utils';

interface HeaderProps {
    getProjectName: (name: string) => Promise<void>;
    formErrors: Errors;
    onError: (error: Errors) => void;
}

export const Header: React.FC<HeaderProps> = ({ getProjectName, formErrors, onError }) => {
    
    const [isEditProjectName, setIsEditProjectName] = useState(false);
    const [projectName, setProjectName] = useState('');

    const { projectId } = useParams();

    const { data: projectData, isLoading } = useGetProjectDetailsQuery(Number(projectId)!, {
        enabled: Boolean(projectId)
    });

    useEffect(() => {
        if (projectData?.data?.name) {
            setProjectName(projectData.data.name);
        }
    }, [projectData?.data?.name]);

    const options = [
        { displaytext: 'Dashboard', href: '/v2/dashboard' },
        { displaytext: projectData?.data?.name || '', href: '#' }
    ];

    async function handleOnBlur() {
        if (!projectName.trim()) {
            onError({ ...formErrors, projectNameError: 'Required' });
            return;
        }
        await getProjectName(projectName);
        setIsEditProjectName(false);
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        onError({ ...formErrors, projectNameError: '' });
        setProjectName(e.target.value);
    }

    function handleOnClick() {
        if (formErrors.projectNameError) {
            return;
        }
        setIsEditProjectName((prev) => !prev);
    }

    return (
        <>
            <PagesHeader>
                <>
                    <Box display={'flex'} alignItems="flex-start" justifyContent={'space-between'}>
                        <Box flex={1}>
                            <Breadcrumb options={options} component={Link} isLoading={isLoading} />
                            {isLoading ? (
                                <Box mt={2}>
                                    <Skeleton variant="rectangular" height={32} width={335} />
                                </Box>
                            ) : (
                                <Box display={'flex'} columnGap={4} pr={6} mt={3}>
                                    {isEditProjectName ? (
                                        <TextField
                                            error={Boolean(formErrors.projectNameError)}
                                            helperText={formErrors.projectNameError}
                                            label={''}
                                            value={projectName}
                                            onBlur={handleOnBlur}
                                            onChange={handleOnChange}
                                        />
                                    ) : (
                                        <H5>{projectName}</H5>
                                    )}
                                    <EditIcon sx={{ mt: '6px', cursor: 'pointer' }} onClick={handleOnClick} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </>
            </PagesHeader>
            <Box width="100%" borderBottom="1px solid" borderColor="neutral.main"></Box>
        </>
    );
};
