import { BodyXS, Box, H5, CloseIcon } from '@parspec/pixel';

interface CardProps {
    title: string;
    count: string | number;
    helperText?: number | string;
    helperTextColor?: string;
    showIcon?: boolean;
    titleColor?: string;
    countColor?: string;
}

const defaultColor = 'success.main';

const StatsCard = (props: CardProps) => {
    const { title, count, helperText, helperTextColor, showIcon, titleColor, countColor } = props;
    return (
        <Box px={4} bgcolor="#283548" sx={{ borderLeft: '8px solid', borderLeftColor: 'secondary.light' }} py={2} width={256}>
            <Box display="flex" alignItems="center">
                <BodyXS color={titleColor || 'white'} sx={{ textTransform: 'uppercase' }}>
                    {title}
                </BodyXS>
                {showIcon && <CloseIcon sx={{ fontSize: '12px' }} />}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <H5 color={countColor || 'secondary.contrastText'}>{count}</H5>
                {helperText && <BodyXS color={helperTextColor || defaultColor}>{helperText}</BodyXS>}
            </Box>
        </Box>
    );
};

StatsCard.defaultProps = {
    showIcon: false
};

export default StatsCard;
