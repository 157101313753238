import { KeyboardArrowDownIcon, KeyboardArrowUpIcon, RemoveIcon, MultiSelectOptionType } from '@parspec/pixel';
import { PRIORITY_VALUES } from '../../Project/shared/utils';
import { AllCompanyDetailsResponse } from '../../shared/CreateAndEditProjectModal/queries/apiTypes';

export const getOwnerInitials = (name: string) => {
    return (name || '')
        .split(' ')
        .slice(0, 2)
        .map((namePart) => namePart?.[0]?.toUpperCase())
        .join('');
};

export const getValuesFromArr = (arr: MultiSelectOptionType[]) => {
    return arr.map((item) => item.value);
};

export const getPriorityColorAndIcon = (value: string) => {
    if (value === PRIORITY_VALUES.HIGH) {
        return { color: 'error.main', icon: <KeyboardArrowUpIcon sx={{ color: 'error.main' }} /> };
    }
    if (value === PRIORITY_VALUES.MEDIUM) {
        return { color: 'secondary.main', icon: <RemoveIcon sx={{ color: 'secondary.main' }} /> };
    }
    if (value === PRIORITY_VALUES.LOW) {
        return { color: 'primary.dark', icon: <KeyboardArrowDownIcon sx={{ color: 'primary.dark' }} /> };
    }
    return { color: 'error.main', icon: <KeyboardArrowUpIcon sx={{ color: 'error.main' }} /> };
};

export const getOwnersFromLocations = (locations: AllCompanyDetailsResponse[], noUnassignedOption?: boolean) => {
    if (locations?.length) {
        const users: { [key: string]: { value: number; label: string } } = {};
        locations?.forEach((company) => {
            (company?.users || []).forEach((user) => {
                if (user.name && !users?.[user.id]) {
                    users[user.id] = {
                        value: user.id,
                        label: user.name
                    };
                }
            });
        });
        const unAssignedOption = noUnassignedOption ? [] : [{ label: 'Unassigned', value: -1 }];
        return [...Object.keys(users).map((id) => users[id]), ...unAssignedOption];
    }
    return [];
};
