import { useParams } from 'react-router-dom';

import { BodySmall, Snackbar, Box } from '@parspec/pixel';

import DeleteConfirmationDialog from 'src/features/shared/DeleteConfirmationDialog';
import { useExtractRequirementsMutation } from '../queries';
import { useGetSelectedDocumentQuery } from '../../../Datasheets/AnnotateDatasheet/queries';
import { useSnackbar } from 'src/features/shared/hooks/useSnackbar';

const OVERWRITE_TITLE = 'Overwrite Requirements?';
const OVERWRITE_BODY = 'A new datasheet was selected for this product. Do you want to overwrite the existing requirements with those extracted from this new document?';
const OVERWRITE_CONTINUE_BUTTON = 'Overwrite';
const OVERWRITEOVERWRITE_CANCEL_BUTTON = 'Keep Existing';

interface ExtractAttributesPopupProps {
    showPopup: boolean;
    updatePopup: React.Dispatch<React.SetStateAction<boolean>>;
    setAttributeOverwriteKeyToFalse: () => void;
    isUpdateProductRequirementLoading: boolean;
}

export default function ExtractAttributesPopup(props: ExtractAttributesPopupProps) {
    const { showPopup, updatePopup, setAttributeOverwriteKeyToFalse, isUpdateProductRequirementLoading } = props;
    const { bomId = '0', productId = '0' } = useParams();
    const { mutateAsync: extractRequirements, isLoading } = useExtractRequirementsMutation();
    const { data: dataSheetData } = useGetSelectedDocumentQuery(bomId, productId, 'submittal', 'datasheet', {});

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    async function handleConfirmation() {
        try {
            const id = dataSheetData?.data[0].id;
            await extractRequirements({ productId: Number(productId), bomId: Number(bomId), selectedDatasheetID: Number(id) });
            setAttributeOverwriteKeyToFalse();

            updatePopup(false);
        } catch (error: any) {
            setSnackbarOpen(error.message);
        }
    }

    function handleDialog() {
        if (isLoading) {
            return;
        }
        setAttributeOverwriteKeyToFalse();

        updatePopup((old: boolean) => !old);
    }

    const loadingStatusFlag = isLoading || isUpdateProductRequirementLoading;

    return (
        <>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={setSnackbarClose} />
            <DeleteConfirmationDialog
                open={showPopup}
                title={OVERWRITE_TITLE}
                handleDialog={handleDialog}
                confirmAction={handleConfirmation}
                loading={isLoading || isUpdateProductRequirementLoading}
                continueButtonLabel={OVERWRITE_CONTINUE_BUTTON}
                cancelButtonLabel={OVERWRITEOVERWRITE_CANCEL_BUTTON}
            >
                <Box width="467px">
                    <Box display="flex " position="relative" height={'100%'}>
                        {loadingStatusFlag && (
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                        )}
                        <BodySmall limit={false} color="neutral.dark">
                            {OVERWRITE_BODY}
                        </BodySmall>
                    </Box>
                </Box>
            </DeleteConfirmationDialog>
        </>
    );
}
