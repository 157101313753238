export const projectBoms = [
  {
    "id": 17611,
    "primary_contact": "Courtney Henry",
    "secondary_contact": "Courtney Henry",
    "created_by": 574,
    "project": 2787,
    "name": "Ace Builders",
    "is_deleted": false,
    "is_submittal_out_of_sync": false,
    "is_om_out_of_sync": false,
    "last_edited_by": "Courtney Henry",
    "last_edited_at": "2023-01-23T08:39:35.996896Z",
    "updated_at": "2023-01-23T08:39:35.996896Z",
    "created_at": "2023-01-23T08:34:47.321534Z",
    "submittal": null,
    "om": null
  },
  {
    "id": 17706,
    "primary_contact": "Courtney Henry",
    "secondary_contact": "Courtney Henry",
    "created_by": 574,
    "project": 2787,
    "name": "Illumination",
    "is_deleted": false,
    "is_submittal_out_of_sync": true,
    "is_om_out_of_sync": false,
    "last_edited_by": "Courtney Henry",
    "last_edited_at": "2023-01-24T12:49:10.479653Z",
    "updated_at": "2023-01-24T12:49:10.479653Z",
    "created_at": "2023-01-24T12:16:12.809335Z",
    "submittal": [
      {
        "id": 22130,
        "document_link": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_unflattened_V1.pdf",
        "document_link_flat": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_flattened_V1.pdf",
        "version": 1
      }
    ],
    "om": null
  },
  {
    "id": 177061,
    "primary_contact": "Courtney Henry",
    "secondary_contact": "Courtney Henry",
    "created_by": 574,
    "project": 2787,
    "name": "Castle Construction",
    "is_deleted": false,
    "is_submittal_out_of_sync": true,
    "is_om_out_of_sync": true,
    "last_edited_by": "Courtney Henry",
    "last_edited_at": "2023-01-24T12:49:10.479653Z",
    "updated_at": "2023-01-24T12:49:10.479653Z",
    "created_at": "2023-01-24T12:16:12.809335Z",
    "submittal": [
      {
        "id": 22130,
        "document_link": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_unflattened_V1.pdf",
        "document_link_flat": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_flattened_V1.pdf",
        "version": 1
      }
    ],
    "om": [
      {
        "id": 22130,
        "document_link": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_unflattened_V1.pdf",
        "document_link_flat": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_flattened_V1.pdf",
        "version": 1
      }
    ]
  },
  {
    "id": 1761,
    "primary_contact": "Courtney Henry",
    "secondary_contact": "Courtney Henry",
    "created_by": 574,
    "project": 2787,
    "name": "Castle",
    "is_deleted": false,
    "is_submittal_out_of_sync": false,
    "is_om_out_of_sync": false,
    "last_edited_by": "Courtney Henry",
    "last_edited_at": "2023-01-24T12:49:10.479653Z",
    "updated_at": "2023-01-24T12:49:10.479653Z",
    "created_at": "2023-01-24T12:16:12.809335Z",
    "submittal": [
      {
        "id": 22130,
        "document_link": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_unflattened_V1.pdf",
        "document_link_flat": "https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/17706/Submittal_vhgj_2023_01_24_flattened_V1.pdf",
        "version": 1
      }
    ],
    "om": null
  }
]