import { BodyMedium, BodyXS, Box, EditIcon, H5, IconButton } from '@parspec/pixel';

interface ModalCardProps {
    title: string;
    amount: string | null;
    helpertext: string | null;
    editIconCallback: () => void;
}

export const ModalCard = ({ title, amount, helpertext, editIconCallback }: ModalCardProps) => {
    return (
        <Box width="25%" border="1px solid" borderColor="neutral.main" borderRadius={1} p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <BodyMedium>{title}</BodyMedium>
                <IconButton onClick={editIconCallback} size="small">
                    <EditIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box display="flex" alignItems="end" columnGap={4} rowGap={1} flexWrap="wrap">
                <Box mb="-5px">
                    <H5>{amount === null ? '-' : `$${amount}`}</H5>
                </Box>

                {helpertext && (
                    <Box>
                        <BodyXS color="secondary.main">{helpertext}</BodyXS>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
