import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodyBig, BodySmall, TextField, Button, InfoIcon, VisibilityIcon, VisibilityOffIcon, Skeleton, Snackbar } from '@parspec/pixel';

import { trimEmptySpaces, newPasswordVerifier } from '../../utils/utils';
import { initialInvitedUserSignupErrorState, initialInvitedUserSingUpState } from '../../utils/helper';
import { InvitedUserSignUpStateErrorState, InvitedUserSignUpState } from '../SignUp.types';
import { useSignInUserMutation, useSignUpInvitedUserMutation, useValidateInvitedUserSignUpInvitationMutation } from '../../queries';

export const InvitedUsersSignup = () => {
    const tokenFromLocalStorage = localStorage.getItem('user_token');

    const { data: getValidateSignupInvitation, isLoading: isTokenValidLoading } = useValidateInvitedUserSignUpInvitationMutation(tokenFromLocalStorage || '');

    const { mutateAsync: postSignupInvitedUserData, isLoading } = useSignUpInvitedUserMutation();
    const { mutateAsync: signInUserMutation, isLoading: isSignInLoading } = useSignInUserMutation();

    const [profileState, setProfileState] = useState<InvitedUserSignUpState>(initialInvitedUserSingUpState);
    const [errorState, setErrorState] = useState<InvitedUserSignUpStateErrorState>(initialInvitedUserSignupErrorState);

    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
            });
        }

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && profileState.newPassword.data !== profileState.confirmPassword.data) {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
            });
        }

        if (errorState.firstName.isError || errorState.lastName.isError || errorState.newPassword.isError || errorState.confirmPassword.isError) {
            return;
        }

        const trimmedFirstName = trimEmptySpaces(profileState.firstName);
        const trimmedLastName = trimEmptySpaces(profileState.lastName);

        const firstNameFlag = trimmedFirstName.length === 0;
        const lastNameFlag = trimmedLastName.length === 0;

        const newPasswordFlag = profileState.newPassword.data.trim().length === 0;
        const confirmPasswordFlag = profileState.confirmPassword.data.trim().length === 0;

        if (firstNameFlag || lastNameFlag || newPasswordFlag || confirmPasswordFlag) {
            return setErrorState({
                ...errorState,
                firstName: { ...errorState.firstName, isError: firstNameFlag, title: `${firstNameFlag ? 'Required' : ''}` },
                lastName: { ...errorState.lastName, isError: lastNameFlag, title: `${lastNameFlag ? 'Required' : ''}` },
                newPassword: { ...errorState.newPassword, isError: newPasswordFlag, title: `${newPasswordFlag ? 'Required' : ''}` },
                confirmPassword: { ...errorState.confirmPassword, isError: confirmPasswordFlag, title: `${confirmPasswordFlag ? 'Required' : ''}` }
            });
        }

        const signupInvitedUserpayload = {
            email: getValidateSignupInvitation?.data.email || '',
            first_name: trimmedFirstName,
            last_name: trimmedLastName,
            name: trimmedFirstName + ' ' + trimmedLastName,
            password: profileState.confirmPassword.data,
            token: tokenFromLocalStorage || ''
        };

        try {
            const resp = await postSignupInvitedUserData(signupInvitedUserpayload);

            if (resp.status === 201 || resp.status === 200) {
                const loginPayload = {
                    email: signupInvitedUserpayload.email,
                    password: signupInvitedUserpayload.password
                };

                const response = await signInUserMutation(loginPayload);
                localStorage.setItem('token', response?.data?.token);
                localStorage.setItem('auth', response?.data?.auth);
                localStorage.setItem('email', loginPayload.email);
                navigate('/v2/dashboard');
            }
        } catch (e) {
            setShowSnackBar(() => true);
        }
    };

    const togglePasswordVisibility = (passwordFieldName: string) => {
        if (passwordFieldName === 'newPassword') {
            setProfileState(() => ({ ...profileState, newPassword: { ...profileState.newPassword, showPassword: !profileState.newPassword.showPassword } }));
        } else if (passwordFieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, confirmPassword: { ...profileState.confirmPassword, showPassword: !profileState.confirmPassword.showPassword } }));
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(profileState, fieldName)) {
            return;
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (errorState.newPassword.isError || errorState.confirmPassword.isError) {
                setErrorState({ ...errorState, newPassword: { ...errorState.newPassword, isError: false, title: '' }, confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' } });
            }
        } else {
            if (errorState[fieldName].isError) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (fieldValue.length === 0) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            setProfileState(() => ({ ...profileState, [fieldName]: fieldValue }));
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, [fieldName]: { ...profileState[fieldName], data: fieldValue } }));
        }
    };

    const fieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(profileState, fieldName)) {
            return;
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            if (profileState[fieldName].length > 0 && eventType === 'blur') {
                if (profileState[fieldName].trim().length === 0) {
                    return setErrorState({ ...errorState, [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' } });
                }
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            const trimmedValue = trimEmptySpaces(profileState[fieldName]);
            setProfileState(() => ({ ...profileState, [fieldName]: trimmedValue }));
        }
    };

    const handleNewPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.confirmPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
                });
            }

            if (!newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }
        } else if (profileState.newPassword.data.trim().length === 0 && profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
            });
        }
    };

    const handleConfirmPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.newPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }

            if (profileState.newPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }
        } else if (profileState.confirmPassword.data.trim().length === 0 && profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
            });
        }
    };

    useEffect(() => {
        if (!tokenFromLocalStorage) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (profileState.newPassword.data.length === 0 && profileState.confirmPassword.data.length === 0) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }

        if (
            profileState.newPassword.data.length > 0 &&
            profileState.confirmPassword.data.length > 0 &&
            profileState.newPassword.data === profileState.confirmPassword.data &&
            newPasswordVerifier(profileState.confirmPassword.data)
        ) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }
    }, [profileState.newPassword.data, profileState.confirmPassword.data]);

    const loadFlag = isLoading || isSignInLoading;
    return (
        <Box bgcolor="secondary.contrastText" width="337px" padding={6}>
            {!isTokenValidLoading ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={12}>
                    <BodyBig limit={false} fontWeight={500}>
                        Welcome to Parspec
                    </BodyBig>
                    {getValidateSignupInvitation?.data.invitation_message ? (
                        <BodySmall limit={false} mt={4} textAlign="center">
                            {getValidateSignupInvitation?.data.invitation_message} Sign up below!
                        </BodySmall>
                    ) : (
                        <BodySmall limit={false} mt={4} textAlign="center">
                            You have been invited to join your team. Sign up below!
                        </BodySmall>
                    )}
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={12}>
                    <Skeleton variant="rectangular" height={36} sx={{ my: 1 }} />
                    <Skeleton variant="rectangular" height={36} sx={{ my: 1 }} />
                </Box>
            )}

            <Box>
                <form onSubmit={onSubmitHandler} ref={formRef} autoComplete={'off'}>
                    <Box height={'72px'}>
                        {!isTokenValidLoading ? (
                            <TextField
                                id={'email'}
                                name={'email'}
                                autoComplete={'email'}
                                label={'Email'}
                                value={getValidateSignupInvitation?.data.email}
                                inputProps={{ tabIndex: -1 }}
                                InputLabelProps={{
                                    style: {
                                        color: '#6B7280'
                                    }
                                }}
                                sx={{
                                    pointerEvents: 'none',
                                    opacity: 0.5,
                                    '&:focus': {
                                        borderColor: 'transparent'
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        // borderColor: 'black'
                                        border: '1px solid #6B7280',
                                        opacity: '0.5'
                                    },
                                    '& .MuiOutlinedInput-input:focus': {
                                        outline: 'none'
                                    }
                                }}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box height={'72px'}>
                        {!isTokenValidLoading ? (
                            <TextField
                                id={'firstName'}
                                name={'firstName'}
                                autoComplete={'given-name'}
                                label={'First Name*'}
                                value={profileState.firstName}
                                error={errorState.firstName.isError}
                                helperText={errorState.firstName.title}
                                onKeyDown={keydownHandler}
                                onBlur={fieldBlurHandler}
                                onChange={fieldChangeHandler}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box height={'72px'}>
                        {!isTokenValidLoading ? (
                            <TextField
                                id={'lastName'}
                                name={'lastName'}
                                autoComplete={'family-name'}
                                label={'Last Name*'}
                                value={profileState.lastName}
                                error={errorState.lastName.isError}
                                helperText={errorState.lastName.title}
                                onKeyDown={keydownHandler}
                                onBlur={fieldBlurHandler}
                                onChange={fieldChangeHandler}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box height={'72px'}>
                        {!isTokenValidLoading ? (
                            <TextField
                                id={'newPassword'}
                                name={'newPassword'}
                                autoComplete={'new-password-2'}
                                label={'New Password'}
                                type={profileState.newPassword.showPassword ? 'text' : 'password'}
                                value={profileState.newPassword.data}
                                error={errorState.newPassword.isError}
                                helperText={errorState.newPassword.title}
                                onKeyDown={keydownHandler}
                                onChange={fieldChangeHandler}
                                onBlur={handleNewPasswordBlur}
                                endIcon={
                                    profileState.newPassword.showPassword ? (
                                        <VisibilityOffIcon
                                            cursor={'pointer'}
                                            onClick={() => {
                                                togglePasswordVisibility('newPassword');
                                            }}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            cursor={'pointer'}
                                            onClick={() => {
                                                togglePasswordVisibility('newPassword');
                                            }}
                                        />
                                    )
                                }
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box height={'72px'}>
                        {!isTokenValidLoading ? (
                            <TextField
                                id={'confirmPassword'}
                                name={'confirmPassword'}
                                autoComplete={'new-password-3'}
                                label={'Confirm Password'}
                                type={profileState.confirmPassword.showPassword ? 'text' : 'password'}
                                value={profileState.confirmPassword.data}
                                error={errorState.confirmPassword.isError}
                                helperText={errorState.confirmPassword.title}
                                onKeyDown={keydownHandler}
                                onBlur={handleConfirmPasswordBlur}
                                onChange={fieldChangeHandler}
                                endIcon={
                                    profileState.confirmPassword.showPassword ? (
                                        <VisibilityOffIcon
                                            cursor={'pointer'}
                                            onClick={() => {
                                                togglePasswordVisibility('confirmPassword');
                                            }}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            cursor={'pointer'}
                                            onClick={() => {
                                                togglePasswordVisibility('confirmPassword');
                                            }}
                                        />
                                    )
                                }
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                </form>
            </Box>

            <Box display="flex" bgcolor={'warning.light'} p={2} mt={2}>
                <Box color={'warning.main'}>
                    <InfoIcon />
                </Box>
                <Box ml={4}>
                    <BodySmall color={'warning.main'} limit={false}>
                        Password must contain:
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        1. At least 8 characters
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        2. At least one uppercase letter
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        3. At least one lowercase letter
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        4. At least one number
                    </BodySmall>
                </Box>
            </Box>

            <Box width="100%" mt={6}>
                <Button onClick={onSubmitHandler} isLoading={loadFlag} fullWidth={true}>
                    Sign Up
                </Button>
            </Box>

            {showSnackBar && (
                <>
                    <Snackbar
                        open={showSnackBar}
                        autoHideDuration={6000}
                        message={'Something went wrong'}
                        onClose={() => {
                            setShowSnackBar(false);
                        }}
                    />
                </>
            )}
        </Box>
    );
};
