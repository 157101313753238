import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodySmall, TextField, Button, BodyBig, VisibilityIcon, VisibilityOffIcon, InfoIcon } from '@parspec/pixel';

import { TermsAndConditionsModal } from './TermsAndConditions/TermsAndConditionsModal';
import { trimEmptySpaces, newPasswordVerifier } from '../../utils/utils';
import { initialPersonalInfoSingUpState, initialPersonalInfoSignupErrorState } from '../../utils/helper';
import { PersonalInfoSignUpErrorStateInterface, PersonalInfoSignUpStateInterface } from '../SignUp.types';
export interface PayloadDataInterface {
    first_name: string;
    last_name: string;
    name: string;
    password: string;
    email: string;
}

const initialPayloadData = {
    first_name: '',
    last_name: '',
    name: '',
    password: '',
    email: ''
};

export const PersonalInfo = () => {
    const navigate = useNavigate();

    const [openTnCModal, setOpenTnCModal] = useState(false);

    const companyEmailFromLocalStorage = localStorage.getItem('company_email');

    const [personalInfoState, setPersonalInfoState] = useState<PersonalInfoSignUpStateInterface>(initialPersonalInfoSingUpState);
    const [errorState, setErrorState] = useState<PersonalInfoSignUpErrorStateInterface>(initialPersonalInfoSignupErrorState);
    const [payloadData, setPayloadData] = useState<PayloadDataInterface>(initialPayloadData);

    const formRef = useRef<HTMLFormElement>(null);

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (personalInfoState.newPassword.data.trim().length > 0 && personalInfoState.confirmPassword.data.trim().length > 0 && !newPasswordVerifier(personalInfoState.newPassword.data)) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
            });
        }

        if (
            personalInfoState.newPassword.data.trim().length > 0 &&
            personalInfoState.confirmPassword.data.trim().length > 0 &&
            personalInfoState.newPassword.data !== personalInfoState.confirmPassword.data
        ) {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
            });
        }

        if (errorState.firstName.isError || errorState.lastName.isError || errorState.newPassword.isError || errorState.confirmPassword.isError) {
            return;
        }

        const trimmedFirstName = trimEmptySpaces(personalInfoState.firstName);
        const trimmedLastName = trimEmptySpaces(personalInfoState.lastName);

        const firstNameFlag = trimmedFirstName.length === 0;
        const lastNameFlag = trimmedLastName.length === 0;

        const newPasswordFlag = personalInfoState.newPassword.data.trim().length === 0;
        const confirmPasswordFlag = personalInfoState.confirmPassword.data.trim().length === 0;

        if (firstNameFlag || lastNameFlag || newPasswordFlag || confirmPasswordFlag) {
            return setErrorState({
                ...errorState,
                firstName: { ...errorState.firstName, isError: firstNameFlag, title: `${firstNameFlag ? 'Required' : ''}` },
                lastName: { ...errorState.lastName, isError: lastNameFlag, title: `${lastNameFlag ? 'Required' : ''}` },
                newPassword: { ...errorState.newPassword, isError: newPasswordFlag, title: `${newPasswordFlag ? 'Required' : ''}` },
                confirmPassword: { ...errorState.confirmPassword, isError: confirmPasswordFlag, title: `${confirmPasswordFlag ? 'Required' : ''}` }
            });
        }

        const payLoad = {
            first_name: trimmedFirstName,
            last_name: trimmedLastName,
            name: trimmedFirstName + ' ' + trimmedLastName,
            password: personalInfoState.confirmPassword.data,
            email: companyEmailFromLocalStorage || ''
        };

        setPayloadData(payLoad);
        openTnCModalHandler();
    };

    const openTnCModalHandler = () => {
        setOpenTnCModal(true);
    };

    const togglePasswordVisibility = (passwordFieldName: string) => {
        if (passwordFieldName === 'newPassword') {
            setPersonalInfoState(() => ({ ...personalInfoState, newPassword: { ...personalInfoState.newPassword, showPassword: !personalInfoState.newPassword.showPassword } }));
        } else if (passwordFieldName === 'confirmPassword') {
            setPersonalInfoState(() => ({ ...personalInfoState, confirmPassword: { ...personalInfoState.confirmPassword, showPassword: !personalInfoState.confirmPassword.showPassword } }));
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(personalInfoState, fieldName)) {
            return;
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (errorState.newPassword.isError || errorState.confirmPassword.isError) {
                setErrorState({ ...errorState, newPassword: { ...errorState.newPassword, isError: false, title: '' }, confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' } });
            }
        } else {
            if (errorState[fieldName].isError) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (fieldValue.length === 0) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            setPersonalInfoState(() => ({ ...personalInfoState, [fieldName]: fieldValue }));
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            setPersonalInfoState(() => ({ ...personalInfoState, [fieldName]: { ...personalInfoState[fieldName], data: fieldValue } }));
        }
    };

    const fieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(personalInfoState, fieldName)) {
            return;
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            if (personalInfoState[fieldName].length > 0 && eventType === 'blur') {
                if (personalInfoState[fieldName].trim().length === 0) {
                    return setErrorState({ ...errorState, [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' } });
                }
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            const trimmedValue = trimEmptySpaces(personalInfoState[fieldName]);
            setPersonalInfoState(() => ({ ...personalInfoState, [fieldName]: trimmedValue }));
        }
    };

    const handleNewPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (personalInfoState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            if (personalInfoState.confirmPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
                });
            }

            if (!newPasswordVerifier(personalInfoState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }

            if (personalInfoState.newPassword.data !== personalInfoState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }
        } else if (personalInfoState.newPassword.data.trim().length === 0 && personalInfoState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
            });
        }
    };

    const handleConfirmPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (personalInfoState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            if (personalInfoState.newPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
                });
            }

            if (personalInfoState.newPassword.data !== personalInfoState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }

            if (personalInfoState.newPassword.data.trim().length > 0 && !newPasswordVerifier(personalInfoState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }
        } else if (personalInfoState.confirmPassword.data.trim().length === 0 && personalInfoState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
            });
        }
    };

    useEffect(() => {
        if (!companyEmailFromLocalStorage) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (personalInfoState.newPassword.data.length === 0 && personalInfoState.confirmPassword.data.length === 0) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }

        if (
            personalInfoState.newPassword.data.length > 0 &&
            personalInfoState.confirmPassword.data.length > 0 &&
            personalInfoState.newPassword.data === personalInfoState.confirmPassword.data &&
            newPasswordVerifier(personalInfoState.confirmPassword.data)
        ) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }
    }, [personalInfoState.newPassword.data, personalInfoState.confirmPassword.data]);

    return (
        <Box bgcolor="secondary.contrastText" width="350px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
                <BodyBig limit={false} fontWeight={500}>
                    Personal Information
                </BodyBig>
                <BodySmall limit={false} mt={4} textAlign="center" color={'text.secondary'}>
                    Almost there! A few more details...
                </BodySmall>
            </Box>

            <Box>
                <form onSubmit={onSubmitHandler} ref={formRef} autoComplete={'off'}>
                    <Box height={'72px'}>
                        <TextField
                            id={'firstName'}
                            name={'firstName'}
                            autoComplete={'given-name'}
                            label={'First Name*'}
                            value={personalInfoState.firstName}
                            error={errorState.firstName.isError}
                            helperText={errorState.firstName.title}
                            onKeyDown={keydownHandler}
                            onBlur={fieldBlurHandler}
                            onChange={fieldChangeHandler}
                        />
                    </Box>
                    <Box height={'72px'}>
                        <TextField
                            id={'lastName'}
                            name={'lastName'}
                            autoComplete={'family-name'}
                            label={'Last Name*'}
                            value={personalInfoState.lastName}
                            error={errorState.lastName.isError}
                            helperText={errorState.lastName.title}
                            onKeyDown={keydownHandler}
                            onBlur={fieldBlurHandler}
                            onChange={fieldChangeHandler}
                        />
                    </Box>
                    <Box height={'72px'}>
                        <TextField
                            id={'newPassword'}
                            name={'newPassword'}
                            autoComplete={'new-password'}
                            label={'New Password*'}
                            type={personalInfoState.newPassword.showPassword ? 'text' : 'password'}
                            value={personalInfoState.newPassword.data}
                            error={errorState.newPassword.isError}
                            helperText={errorState.newPassword.title}
                            onKeyDown={keydownHandler}
                            onChange={fieldChangeHandler}
                            onBlur={handleNewPasswordBlur}
                            endIcon={
                                personalInfoState.newPassword.showPassword ? (
                                    <VisibilityOffIcon
                                        cursor={'pointer'}
                                        onClick={() => {
                                            togglePasswordVisibility('newPassword');
                                        }}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        cursor={'pointer'}
                                        onClick={() => {
                                            togglePasswordVisibility('newPassword');
                                        }}
                                    />
                                )
                            }
                        />
                    </Box>
                    <Box height={'72px'}>
                        <TextField
                            id={'confirmPassword'}
                            name={'confirmPassword'}
                            autoComplete={'new-password-2'}
                            label={'Confirm Password*'}
                            type={personalInfoState.confirmPassword.showPassword ? 'text' : 'password'}
                            value={personalInfoState.confirmPassword.data}
                            error={errorState.confirmPassword.isError}
                            helperText={errorState.confirmPassword.title}
                            onKeyDown={keydownHandler}
                            onBlur={handleConfirmPasswordBlur}
                            onChange={fieldChangeHandler}
                            endIcon={
                                personalInfoState.confirmPassword.showPassword ? (
                                    <VisibilityOffIcon
                                        cursor={'pointer'}
                                        onClick={() => {
                                            togglePasswordVisibility('confirmPassword');
                                        }}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        cursor={'pointer'}
                                        onClick={() => {
                                            togglePasswordVisibility('confirmPassword');
                                        }}
                                    />
                                )
                            }
                        />
                    </Box>
                </form>
            </Box>

            <Box display="flex" mt={2} bgcolor={'warning.light'} p={2}>
                <Box color={'warning.main'}>
                    <InfoIcon />
                </Box>
                <Box ml={4}>
                    <BodySmall color={'warning.main'} limit={false}>
                        Password must contain:
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        1. At least 8 characters
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        2. At least one uppercase letter
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        3. At least one lowercase letter
                    </BodySmall>
                    <BodySmall color={'warning.main'} limit={false}>
                        4. At least one number
                    </BodySmall>
                </Box>
            </Box>

            <Box width="100%" mt={6}>
                <Button onClick={onSubmitHandler} isLoading={false} fullWidth={true}>
                    Create Account
                </Button>
            </Box>

            {openTnCModal && <TermsAndConditionsModal isOpen={openTnCModal} setIsOpen={setOpenTnCModal} payloadData={payloadData} />}
        </Box>
    );
};
