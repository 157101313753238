import { ColDef } from 'ag-grid-community';
import { EVENT_SERVICE_PAGENAME_VALUES } from '../../shared/utils/constants';

import { toPascalCase } from '../../shared/utils/utils';

const BASE_REPORTING_DASHBOARD_URL = '/v2/reportingDashboard';

export const TAB_VALUES = {
    CUSTOMERS_TAB_VALUE: BASE_REPORTING_DASHBOARD_URL,
    BRANCH_LOCATION_TAB_VALUE: `${BASE_REPORTING_DASHBOARD_URL}/branchLocations`,
    QUOTE_OWNERS_TAB_VALUE: `${BASE_REPORTING_DASHBOARD_URL}/quoteOwners`,
    MANUFACTURERS_TAB_VALUE: `${BASE_REPORTING_DASHBOARD_URL}/manufacturers`
};

export const TAB_OPTIONS = [
    { label: 'Customers', value: TAB_VALUES.CUSTOMERS_TAB_VALUE },
    { label: 'Branch Locations', value: TAB_VALUES.BRANCH_LOCATION_TAB_VALUE },
    { label: 'Quote Owners', value: TAB_VALUES.QUOTE_OWNERS_TAB_VALUE },
    { label: 'Manufacturers', value: TAB_VALUES.MANUFACTURERS_TAB_VALUE }
];

export const PAGE_NAME = {
    [TAB_VALUES.CUSTOMERS_TAB_VALUE]: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_CUSTOMERS,
    [TAB_VALUES.BRANCH_LOCATION_TAB_VALUE]: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_BRANCH_LOCATIONS,
    [TAB_VALUES.QUOTE_OWNERS_TAB_VALUE]: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_QUOTE_OWNERS,
    [TAB_VALUES.MANUFACTURERS_TAB_VALUE]: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_MANUFACTURERS
};

export const QUOTES_COLUMN_TOOLTIP_TEXT = 'Number of BOMs quoted (Quote credits).';

export const SUBMITTALS_AND_OM_COLUMN_TOOLTIP_TEXT = 'Number of BOMs submitted (Submittal & O&M credits).';

export const WIN_RATE_COLUMN_TOOLTIP_TEXT = 'Won BOMs / (Won + Lost BOMs) (excl. Pending and abandoned BOMs).';
export const WIN_RATE_COLUMN_FOR_USER_BRANCH_TAB_TOOLTIP_TEXT = 'Projects with one or more won BOMs / Projects (Leverage the Project Stage filter to find the desired win rate)';
export const REVENUE_COLUMN_TOOLTIP_TEXT = 'Summed Revenue from won quotes (excl. tax).';
export const MARGIN_COLUMN_TOOLTIP_TEXT = 'Summed Margin from won quotes.';
export const PROFIT_MARGIN_COLUMN_TOOLTIP_TEXT = 'Σ Margin / Σ Revenue.';

// Manufacturer tab tooltip texts
export const MFG_PRODUCTS_COLUMN_TOOLTIP_TEXT = 'Number of products from this manufacturer in won BOMs.';
export const MFG_REVENUE_COLUMN_TOOLTIP_TEXT = 'Total extended sell of products from this manufacturer in won BOMs.';
export const MFG_MARGIN_COLUMN_TOOLTIP_TEXT = 'Total margin of products quoted from this manufacturer in won BOMs.';
export const MFG_PROFIT_MARGIN_COLUMN_TOOLTIP_TEXT = 'Margin / Extended Sell of products quoted from this manufacturer in won BOMs.';

export const DEFAULT_COL_DEF: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
} as const;

export const WIN_RATE_CHART_HEADER = (tabName: string) => `Win Rate by ${toPascalCase(tabName)}`;
export const PROFIT_CHART_HEADER = (tabName: string) => `Profit Margin by ${toPascalCase(tabName)}`;
export const REVENUE_PROFIT_CHART_HEADER = (tabName: string) => `Revenue & Margin by ${toPascalCase(tabName)}`;
export const WIN_RATE_CHART_SUB_HEADER = 'Won BOMs over won and lost BOMs';
export const PROFIT_CHART_SUB_HEADER = 'Profit over revenue for won BOMs';
export const REVENUE_PROFIT_SUB_HEADER = 'Revenue & Margin of won BOMs';

export const WIN_RATE_BAR_INFO = [
    {
        name: 'winRate',
        color: '#6467F2',
        stackId: 'a',
        barSize: 15
    }
];

export const PROFIT_MARGIN_BAR_INFO = [
    {
        name: 'profitMargin',
        color: '#6467F2',
        stackId: 'a',
        barSize: 15
    }
];

export const MARGIN_REVENUE_BAR_INFO = [
    {
        name: 'margin',
        color: '#FFA72B',
        stackId: 'a',
        barSize: 15
    },
    {
        name: 'revenue',
        color: '#6467F2',
        stackId: 'a',
        barSize: 15
    }
];

export const WIN_RATE_LEGENDS = [
    { name: 'Win Rate', color: 'primary.main' },
    { name: 'Average Win Rate', color: 'tertiary.main' }
];

export const PROFIT_MARGIN_LEGENDS = [
    { name: 'Profit Margin', color: 'primary.main' },
    { name: 'Average Profit Margin', color: 'tertiary.main' }
];

export const REVENUE_PROFIT_LEGENDS = [
    { name: 'Revenue', color: 'primary.main' },
    { name: 'Margin', color: 'tertiary.main' }
];

export const graphTooltipLabels = (value: string) => {
    switch (value) {
        case 'winRate':
            return 'Win Rate';
        case 'profitMargin':
            return 'Profit Margin';
        case 'revenue':
            return 'Revenue';
        default:
            return 'Margin';
    }
};

export const TABLE_COLUMN_NAMES = {
    CUSTOMER: 'Customer',
    PROJECTS: 'Projects',
    QUOTES: 'Quotes',
    SUBMITTALS_AND_OM: 'Submittals / O&Ms',
    WIN_RATE: 'Win Rate',
    REVENUE: 'Σ Revenue',
    MARGIN: 'Σ Margin',
    PROFIT_MARGIN: 'Profit Margin',
    BRANCH_LOCATIONS: 'Branch Locations',
    QUOTE_OWNER: 'Quote Owner',
    MANUFACTURER: 'Manufacturer',
    PRODUCTS: 'Products'
};

export enum TAB_NAMES {
    CUSTOMER = 'customer',
    BRANCH_LOCATIONS = 'Location',
    QUOTE_OWNERS = 'quote Owner',
    MANUFACTURER = 'manufacturer'
}

export const TABLE_ROW_HEIGHT = 40;

export const defaultLockColumnDef: ColDef[] = [
    {
        field: 'id',
        hide: true,
        lockPosition: 'left',
        resizable: false
    }
];
