import { useState, useEffect } from 'react';

import { Box, H5, Switch, BodySmall, Select, Skeleton } from '@parspec/pixel';

import { useUserAccountPreferencesQuery, useEditUserPreferencesMutation } from '../queries';
import { someThingWentWrongMsg } from '../../../shared/constants';

const USER_GROUP_OPTIONS = [
    {
        label: 'Individual User (Me)',
        value: 'me'
    },
    {
        label: 'All users in the location',
        value: 'company_users'
    },
    {
        label: 'All users in the company',
        value: 'company_group_users'
    }
];

export interface SnackbarDataType {
    isActive: boolean;
    message: string;
}
interface PreferencesProps {
    snackbarData: SnackbarDataType;
    setSnackbarData: React.Dispatch<React.SetStateAction<SnackbarDataType>>;
}

const PREFERENCES_INFO_TEXT =
    'Previously confirmed datasheets will be remembered and automatically selected if the same combination of Manufacturer and Model number is used again. Associated annotations will also be remembered. The most recently confirmed annotated version of the datasheet will be selected out of the preferred user group.';

export const Preferences = (props: PreferencesProps) => {
    const { snackbarData, setSnackbarData } = props;
    const { data: userPreferences, isLoading: userPreferencesLoading } = useUserAccountPreferencesQuery();
    const { mutateAsync: editUserPreferencesMutation } = useEditUserPreferencesMutation();

    const [preferencesState, setPreferencesState] = useState<{ autoselection: boolean; autoselection_user_group: 'me' | 'company_users' | 'company_group_users' }>({
        autoselection: false,
        autoselection_user_group: 'me'
    });

    const autoSearchToggleHandler = async (e: any) => {
        const checkedStatus = e.target.checked;
        setPreferencesState(() => ({ ...preferencesState, autoselection: checkedStatus }));

        try {
            const res = await editUserPreferencesMutation({
                autoselection: checkedStatus,
                autoselection_user_group: preferencesState.autoselection_user_group,
                email_body: userPreferences?.data.email_body || '',
                is_pdf_attached: userPreferences?.data.is_pdf_attached
            });
            if (res.status !== 200)
                setSnackbarData({
                    ...snackbarData,
                    isActive: true,
                    message: someThingWentWrongMsg
                });
        } catch {
            // revert the changes since API failed
            setPreferencesState(() => ({ ...preferencesState, autoselection: !checkedStatus }));
        }
    };

    const autoselectionUserGroupHandler = async (e: any) => {
        const selectedUserGroup = e.target.value;

        setPreferencesState(() => ({ ...preferencesState, autoselection_user_group: selectedUserGroup }));
        const res = await editUserPreferencesMutation({
            autoselection: preferencesState.autoselection,
            autoselection_user_group: selectedUserGroup,
            email_body: userPreferences?.data.email_body || '',
            is_pdf_attached: userPreferences?.data.is_pdf_attached
        });
        if (res.status !== 200)
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: someThingWentWrongMsg
            });
    };

    useEffect(() => {
        if (!userPreferences?.data) {
            return;
        }

        setPreferencesState(() => ({ ...preferencesState, autoselection: userPreferences.data.autoselection, autoselection_user_group: userPreferences.data.autoselection_user_group }));
    }, [userPreferences?.data]);

    return (
        <Box mt={2} mb={4}>
            <H5>Datasheet Preferences</H5>

            <Box mt={6}>
                {!userPreferencesLoading ? (
                    <Switch
                        color="primary"
                        label={'Auto-search for previously confirmed datasheets and annotations for listed products.'}
                        onChange={(e) => autoSearchToggleHandler(e)}
                        checked={preferencesState.autoselection}
                    />
                ) : (
                    <Skeleton variant={'rectangular'} height={'20px'} width={'500px'} />
                )}
            </Box>

            <Box display={'flex'} mt={4} alignItems={'center'}>
                <BodySmall limit={false} mr={2} noWrap>
                    Preferred user group:
                </BodySmall>
                <Box ml={'4px'}>
                    {!userPreferencesLoading ? (
                        <Select
                            label="User selection"
                            error={false}
                            onChange={autoselectionUserGroupHandler}
                            optionLabelKeyname="label"
                            optionValueKeyname="value"
                            options={USER_GROUP_OPTIONS}
                            value={preferencesState.autoselection_user_group}
                        />
                    ) : (
                        <Skeleton variant={'rectangular'} height={'36px'} width={'200px'} />
                    )}
                </Box>
            </Box>

            <BodySmall limit={false} mt={6} color={'text.secondary'}>
                {PREFERENCES_INFO_TEXT}
            </BodySmall>
        </Box>
    );
};
