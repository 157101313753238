import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Button, BodySmall, FileSelector, Skeleton, BodyXS, FileSelectorFileType } from '@parspec/pixel';

import { useHeaderAndFooterDocumentLogoQuery, useHeaderAndFooterDeleteLogoMutation, useHeaderAndFooterUpdateLogoMutation } from '../../queries';
import PreviewContext from '../../Context';
import LogoFileUploaded from '../../../shared/LogoFileUploaded';

interface FileUploadPayloadType {
    documentType: string;
    bomId: string;
    logo?: FormData | null;
    source?: string | null;
}

interface FileDelete {
    bomId: string;
    documentType: string;
}

const HeaderAndFooterFileUpload: React.FC<Record<string, never>> = () => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);
    const { data: documentLogo, isLoading: isDocumentLogoLoading, isFetching: isDocumentLogoFetching } = useHeaderAndFooterDocumentLogoQuery(bomId!, documentType!);
    const { mutate: deleteMutation, isLoading: isDeleteingLogo } = useHeaderAndFooterDeleteLogoMutation();
    const { mutate: updateLogoMutation, isLoading: isLogoUploading } = useHeaderAndFooterUpdateLogoMutation();

    const fileUploadHandler = (data: File[] | null, label?: string | null) => {
        const payload: FileUploadPayloadType = {
            documentType: documentType!,
            bomId: bomId!
        };
        if (data && data.length) {
            const formData = new FormData();
            formData.append('logo', data[0]);

            payload.logo = formData;
            payload.source = null;
        } else if (!data && label) {
            payload.logo = null;
            payload.source = label;
        }

        if (Object.hasOwn(payload, 'logo') || Object.hasOwn(payload, 'source')) {
            updateLogoMutation(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        }
    };

    const handleFileDelete = () => {
        const payload: FileDelete = {
            bomId: bomId!,
            documentType: documentType!
        };

        deleteMutation(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
            }
        });
    };
    return (
        <>
            <Grid container maxHeight={200}>
                <Grid item xs={4}>
                    <BodySmall>Header Logo</BodySmall>
                </Grid>
                <Grid item xs={8}>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction={'column'} gap={2}>
                                <Grid item>
                                    {!isDocumentLogoLoading || !isDocumentLogoFetching ? (
                                        <>
                                            {documentLogo?.data?.logo ? (
                                                <LogoFileUploaded isDeleteing={isDeleteingLogo} isUpdating={isLogoUploading} logo={documentLogo?.data?.logo} onClick={handleFileDelete} />
                                            ) : (
                                                <FileSelector
                                                    acceptedFormats={['.png', '.jpg']}
                                                    placeholder="  Drag and drop files here, or:"
                                                    maxFiles={2}
                                                    onSelect={(data: File[] | FileSelectorFileType[]) => fileUploadHandler(data as File[], null)}
                                                    isLoading={isLogoUploading}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Skeleton sx={{ bgcolor: 'rgba(0,0,0,0.05)' }} animation="wave" height={109} variant="rounded" width={160} />
                                    )}
                                </Grid>
                                <Grid item>
                                    <BodyXS limit={false}>Supported file types: PNG, JPG</BodyXS>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item alignSelf={'center'} xs={6}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Button variant="text" size="medium" color="primary" onClick={() => fileUploadHandler(null, 'primary')}>
                                        Use Primary Logo
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="text" size="medium" color="primary" onClick={() => fileUploadHandler(null, 'secondary')}>
                                        Use Secondary Logo
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="text" size="medium" color="primary" onClick={() => fileUploadHandler(null, 'project')}>
                                        Use Project Logo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default HeaderAndFooterFileUpload;
