import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Modal, ModalFooter, ModalHeader } from '@parspec/pixel';

import { CustomerTab } from 'src/features/shared/CustomerTab';
import { IPdfUrlsQuote } from '../../shared/queries/apiTypes';
import PdfViewer from 'src/features/shared/PdfViewer';
interface IPreviewModal {
    open: boolean;
    onClose: () => void;
    isCreateQuoteLoading: boolean;
    pdfUrls: IPdfUrlsQuote;
}
const PreviewQuoteModal = (props: IPreviewModal) => {
    const { projectId = '0' } = useParams();
    const {
        open,
        onClose,
        pdfUrls: { urls = {}, boms = [], bomsLength }
    } = props;

    const [url, setUrl] = useState('');
    const [isReOrderInitiated, setIsReOrderInitiated] = useState(false);

    const updateIfReOrderInitiated = useCallback((value: boolean) => {
        setIsReOrderInitiated(value);
    }, []);

    useEffect(() => {
        if (boms.length === bomsLength && !isReOrderInitiated) {
            setUrl(urls[boms[0]]);
        }
    }, [boms, bomsLength, isReOrderInitiated]);

    const handleTabChange = (bomId: string) => {
        setUrl(urls[bomId]);
    };

    const handleModalClose = () => {
        setUrl(``);
        setIsReOrderInitiated(false);
        onClose();
    };

    const header = <ModalHeader title={'Preview Quote(s)'} onClose={handleModalClose} />;
    const footer = <ModalFooter onReject={handleModalClose} cancelButtonLabel="Close" />;

    return (
        <Modal header={header} footer={footer} open={open}>
            <Box display="flex" flexDirection="column" alignItems={'center'} width={1}>
                <Box width="75vw">
                    <CustomerTab
                        updateIfReOrderInitiated={updateIfReOrderInitiated}
                        isReOrderInitiated={isReOrderInitiated}
                        projectId={Number(projectId)}
                        defaultSelectedBomId={String(boms[0])}
                        onTabChange={handleTabChange}
                    />
                </Box>
                <Box width="75vw" height="70vh">
                    {url && <PdfViewer url={url} height="100%" />}
                </Box>
            </Box>
        </Modal>
    );
};

export default PreviewQuoteModal;
