import { useState } from 'react';
import { useNavigate, Outlet, useMatch } from 'react-router-dom';

import {
    Box,
    H5,
    IconButton,
    KeyboardArrowLeftIcon,
    KeyboardArrowRightIcon,
    Grid,
    SettingsIcon,
    LogoutIcon,
    HistoryOutlinedIcon,
    CMSIcon,
    Skeleton,
    CalendarIcon,
    AssessmentOutlinedIcon
} from '@parspec/pixel';

import { useParspecNavigation } from '../../app/Context';
import ListItem from './ListItem';
import { useLogoutMutation } from '../Authentication/queries';
import { useCheckAccessForProductFinderOrQuoting } from '../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useFeatureFlag } from '../shared/UserActivityTracking/useFeatureFlag';
import { useCheckAccessForReporting } from '../shared/hooks/useCheckAccessForReporting';

const listItem = {
    DASHBOARD: 'dashboard',
    CMS: 'cms',
    KNOWLEDGE_BASE: 'knowledge_base',
    SETTINGS: 'settings',
    LOGOUT: 'logout',
    PRICING_HISTORY: 'pricing_history',
    BID_BOARD: 'bid_board',
    REPORTING_DASHBOARD: 'reporting_dashboard'
};

const expandedWidth = '264px';
const collapsedWidth = '72px';

export default function SideNavBar() {
    const navigate = useNavigate();
    const { mutateAsync: logoutMutation } = useLogoutMutation();
    const { pNavigate, hasUnsavedChanges, setHasUnsavedChanges } = useParspecNavigation();
    const [open, setOpen] = useState(false);
    const { quotingAccess, isFetching: isQuotingAccessFetching } = useCheckAccessForProductFinderOrQuoting();
    const { enable_quoting, isLoading: isFeatureFlagLoading } = useFeatureFlag();
    const isQuotingAccessLoading = isQuotingAccessFetching || isFeatureFlagLoading;
    const doesUserHaveQuotingAccess = quotingAccess && enable_quoting;

    const { reportingAccess, isLoading: isReportingAccessLoading } = useCheckAccessForReporting();

    const matchPricingHistory = useMatch('/v2/pricing-history');
    const matchSetting = useMatch('/v2/settings/*');
    const matchContactManagement = useMatch('/v2/contactManagement/*');
    const matchCompany = useMatch('/v2/company/*');
    const matchBidBoard = useMatch('/v2/bid-board/*');
    const matchReportingDashboard = useMatch('/v2/reportingDashboard/*');

    const isPricingHistory = Boolean(matchPricingHistory);
    const isSettings = Boolean(matchSetting);
    const isCMS = Boolean(matchContactManagement) || Boolean(matchCompany);
    const isBidBoard = Boolean(matchBidBoard);
    const isReportingDashboard = Boolean(matchReportingDashboard);
    const isDashboard = !isPricingHistory && !isSettings && !isCMS && !isBidBoard && !isReportingDashboard;

    function toggleDrawer() {
        setOpen((prev) => !prev);
    }

    async function logout() {
        const response = await logoutMutation();
        if (response.status === 204) {
            navigate('/login');
        }
    }

    async function onClickHandler(itemType: string) {
        switch (itemType) {
            case listItem.DASHBOARD:
                pNavigate('/v2/dashboard');
                break;

            case listItem.PRICING_HISTORY:
                pNavigate('/v2/pricing-history');
                break;

            case listItem.REPORTING_DASHBOARD:
                pNavigate('/v2/reportingDashboard');
                break;

            case listItem.CMS:
                pNavigate('/v2/contactManagement');
                break;

            case listItem.BID_BOARD:
                pNavigate('/v2/bid-board');
                break;

            case listItem.KNOWLEDGE_BASE:
                window.open('https://knowledgebase.parspec.io/knowledge');
                break;

            case listItem.SETTINGS:
                pNavigate('/v2/settings/my-profile');
                break;

            case listItem.LOGOUT:
                if (!hasUnsavedChanges) {
                    await logout();
                } else {
                    const shouldNavigate = window.confirm('Are you sure you want to leave with unsaved changes?');
                    if (shouldNavigate) {
                        setHasUnsavedChanges(false);
                        await logout();
                    }
                }
                break;

            default:
                break;
        }

        setOpen(false);
    }

    return (
        <>
            <Box
                width={open ? expandedWidth : collapsedWidth}
                px={4}
                pt={4}
                pb={7}
                height={'100vh'}
                bgcolor={'secondary.main'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{ transition: 'width 0.5s ease-in-out', position: 'fixed', zIndex: 100, overflowX: open ? 'hidden' : 'visible', whiteSpace: 'nowrap', boxSizing: 'border-box' }}
            >
                {/* NavBar header */}
                <Grid container width={'100%'} justifyContent={open ? 'flex-start' : 'center'} sx={{ position: 'relative' }}>
                    <Grid item xs={10}>
                        <Box display={'flex'} gap={2}>
                            <Box>
                                <img onClick={() => onClickHandler(listItem.DASHBOARD)} loading="lazy" src="/assets/images/sidenav/logo.svg" style={{ width: '36px', objectFit: 'contain' }} />
                            </Box>

                            {open && (
                                <Box>
                                    <H5 className="textSideNavBarExpand" limit={false} color="white" sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        PARSPEC
                                    </H5>
                                </Box>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={2} sx={!open ? { position: 'absolute', top: 32, right: 7 } : null}>
                        <IconButton onClick={toggleDrawer} sx={{ mt: 3, ml: 5, p: 0, bgcolor: 'rgba(59, 69, 95, 1)', '&:hover': { bgcolor: 'rgba(59, 69, 95, 1)' } }}>
                            {!open ? <KeyboardArrowRightIcon fontSize="medium" color="neutral" /> : <KeyboardArrowLeftIcon fontSize="medium" color="neutral" />}
                        </IconButton>
                    </Grid>
                </Grid>

                <Box width={'100%'} mt={5} height={'100%'} display={'flex'} flexDirection="column" justifyContent="space-between">
                    {/* Upper content */}
                    <Box display="flex" flexDirection="column" gap={4}>
                        <ListItem
                            isSelected={isDashboard}
                            onClick={() => onClickHandler(listItem.DASHBOARD)}
                            listTitle="Dashboard"
                            imageDimension={{ width: '16px' }}
                            imageUrl="/assets/images/sidenav/dashboard-icon.svg"
                            open={open}
                        />
                        <ListItem
                            isSelected={isCMS}
                            onClick={() => onClickHandler(listItem.CMS)}
                            listTitle="Contact Management"
                            imageDimension={{ width: '16px' }}
                            Icon={<CMSIcon fontSize="medium" color="neutral" />}
                            open={open}
                        />
                        {isQuotingAccessLoading && (
                            <Box width="100%">
                                <Skeleton variant="rectangular" width="100%" height="40px" color="light" />
                            </Box>
                        )}
                        {reportingAccess && !isReportingAccessLoading && (
                            <ListItem
                                isSelected={isReportingDashboard}
                                onClick={() => onClickHandler(listItem.REPORTING_DASHBOARD)}
                                listTitle="Reporting Dashboard"
                                Icon={<AssessmentOutlinedIcon fontSize="medium" color="neutral" />}
                                open={open}
                            />
                        )}

                        {isQuotingAccessLoading && (
                            <Box width="100%">
                                <Skeleton variant="rectangular" width="100%" height="40px" color="light" />
                            </Box>
                        )}
                        {doesUserHaveQuotingAccess && !isQuotingAccessLoading && (
                            <ListItem
                                isSelected={isPricingHistory}
                                onClick={() => onClickHandler(listItem.PRICING_HISTORY)}
                                listTitle="Pricing History"
                                Icon={<HistoryOutlinedIcon fontSize="medium" color="neutral" />}
                                open={open}
                            />
                        )}
                        <ListItem
                            isSelected={isBidBoard}
                            onClick={() => onClickHandler(listItem.BID_BOARD)}
                            listTitle="Bid Board"
                            imageDimension={{ width: '16px' }}
                            Icon={<CalendarIcon fontSize="medium" color="neutral" />}
                            open={open}
                        />
                    </Box>

                    {/* Lower content */}
                    <Grid container direction={'column'} gap={2}>
                        <Grid item>
                            <ListItem
                                onClick={() => onClickHandler(listItem.KNOWLEDGE_BASE)}
                                listTitle="Knowledge Base"
                                imageDimension={{ width: '16px' }}
                                imageUrl="/assets/images/sidenav/knowledge-base-icon.svg"
                                open={open}
                            />
                        </Grid>

                        <Grid item>
                            <ListItem
                                isSelected={isSettings}
                                onClick={() => onClickHandler(listItem.SETTINGS)}
                                listTitle="Settings"
                                Icon={<SettingsIcon fontSize="medium" color="neutral" sx={{ opacity: 0.4 }} />}
                                open={open}
                            />
                        </Grid>

                        <Grid item>
                            <ListItem
                                onClick={() => onClickHandler(listItem.LOGOUT)}
                                listTitle="Logout"
                                Icon={<LogoutIcon color="neutral" fontSize="medium" sx={{ opacity: 0.4, transform: 'rotate(180deg)' }} />}
                                open={open}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box marginLeft="71px" height="100vh" onClick={() => setOpen(false)}>
                <Outlet />
            </Box>
        </>
    );
}
