import dayjs, { Dayjs } from 'dayjs';

import { CompanyResponse, ContactResponse } from '../../Dashboard/queries/apiTypes';

export const FIELD_NAMES = {
    name: {
        label: 'Project Name',
        value: 'name'
    },
    company: {
        label: 'Branch Location',
        value: 'company'
    },
    stage: {
        label: 'Project Stage',
        value: 'stage'
    },
    estimated_project_cost: {
        label: 'Estimated Value',
        value: 'estimated_project_cost'
    },
    quote_owner: {
        label: 'Quote Owner',
        value: 'quote_owner'
    },
    quote_due_date: {
        label: 'Quote Due Date',
        value: 'quote_due_date'
    },
    submittal_owner: {
        label: 'Submittal Owner',
        value: 'submittal_owner'
    },
    submittal_due_date: {
        label: 'Submittal Due Date',
        value: 'submittal_due_date'
    },
    o_m_owner: {
        label: 'O&M Package Owner',
        value: 'o_m_owner'
    },
    o_m_due_date: {
        label: 'O&M Due Date',
        value: 'o_m_due_date'
    },
    address1: {
        label: 'Address Line 1',
        value: 'address1'
    },
    address2: {
        label: 'Address Line 2',
        value: 'address2'
    },
    country: {
        label: 'Country',
        value: 'country'
    },
    city: {
        label: 'City',
        value: 'city'
    },
    state: {
        label: 'State',
        value: 'state'
    },
    zip: {
        label: 'Zip',
        value: 'zip'
    },
    building_type: {
        label: 'Market Vertical',
        value: 'building_type'
    },
    construction_scope: {
        label: 'Construction Scope',
        value: 'construction_scope'
    },
    size: {
        label: 'Square Footage',
        value: 'size'
    },
    architect: {
        label: 'Architect',
        value: 'architect'
    },
    engineer: {
        label: 'Engineer',
        value: 'engineer'
    },
    distributor: {
        label: 'Distributor',
        value: 'distributor'
    },
    contractor: {
        label: 'Contractor',
        value: 'contractor'
    },
    owner: {
        label: 'Owner/Developer',
        value: 'owner'
    },
    priority: {
        label: 'Priority',
        value: 'priority'
    }
};

export const projectStakeHoldersInitialState = [
    { company: null, contact: null },
    { company: null, contact: null },
    { company: null, contact: null },
    { company: null, contact: null },
    { company: null, contact: null }
];

export interface StakeholderType {
    id?: number;
    company?: CompanyResponse | null;
    contact?: ContactResponse | null;
}

export const getIfWeekendDates = (date: Dayjs) => {
    return dayjs(date).get('day') === 0 || dayjs(date).get('day') === 6;
};
