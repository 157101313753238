import { PricingBodsResponse } from '../apiTypes';

export const quoteMetadata: PricingBodsResponse = [
    {
        bod_id: 386082,
        notes: 'Random notes 0',
        lot_id: 420,
        cost_cents: null,
        discount_percentage: null,
        discounted_cost_cents: null,
        margin_percentage: null,
        sell_price_cents: null,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-743',
        internal_notes: 'asasas'
    },
    {
        bod_id: 1,
        notes: 'Random notes 1',
        lot_id: 421,
        cost_cents: 402,
        discount_percentage: 72.631,
        discounted_cost_cents: 322,
        margin_percentage: 28.846,
        sell_price_cents: 40200,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-402',
        internal_notes: 'asasas'
    },
    {
        bod_id: 2,
        notes: 'Random notes 2',
        lot_id: 420,
        cost_cents: 871,
        discount_percentage: 28.917,
        discounted_cost_cents: 696,
        margin_percentage: 57.462,
        sell_price_cents: 87100,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-871',
        internal_notes: 'asasas'
    },
    {
        bod_id: 3,
        notes: 'Random notes 3',
        lot_id: null,
        cost_cents: 239,
        discount_percentage: 81.329,
        discounted_cost_cents: 192,
        margin_percentage: 67.364,
        sell_price_cents: 23900,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-239',
        internal_notes: 'asasas'
    },
    {
        bod_id: 4,
        notes: 'Random notes 4',
        lot_id: null,
        cost_cents: 523,
        discount_percentage: 45.254,
        discounted_cost_cents: 418,
        margin_percentage: 33.458,
        sell_price_cents: 52300,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-523',
        internal_notes: 'asasas'
    },
    {
        bod_id: 5,
        notes: 'Random notes 5',
        lot_id: null,
        cost_cents: 624,
        discount_percentage: 63.957,
        discounted_cost_cents: 499,
        margin_percentage: 39.24,
        sell_price_cents: 62400,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-624',
        internal_notes: 'asasas'
    },
    {
        bod_id: 387363,
        notes: 'Random notes 6',
        lot_id: null,
        cost_cents: 318,
        discount_percentage: 92.154,
        discounted_cost_cents: 254,
        margin_percentage: 36.478,
        sell_price_cents: 31800,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-318',
        internal_notes: 'asasas'
    },
    {
        bod_id: 390011,
        notes: 'Random notes 7',
        lot_id: null,
        cost_cents: 964,
        discount_percentage: 39.662,
        discounted_cost_cents: 771,
        margin_percentage: 46.455,
        sell_price_cents: 96400,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-964',
        internal_notes: 'asasas'
    },
    {
        bod_id: 390012,
        notes: 'Random notes 8',
        lot_id: null,
        cost_cents: 185,
        discount_percentage: 17.849,
        discounted_cost_cents: 148,
        margin_percentage: 19.189,
        sell_price_cents: 18500,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-185',
        internal_notes: 'asasas'
    },
    {
        bod_id: 390013,
        notes: 'Random notes 9',
        lot_id: null,
        cost_cents: 834,
        discount_percentage: 54.213,
        discounted_cost_cents: 667,
        margin_percentage: 24.235,
        sell_price_cents: 83400,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-834',
        internal_notes: 'asasas'
    },
    {
        bod_id: 390014,
        notes: 'Random notes 10',
        lot_id: null,
        cost_cents: 147,
        discount_percentage: 73.529,
        discounted_cost_cents: 117,
        margin_percentage: 20.136,
        sell_price_cents: 14700,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-147',
        internal_notes: 'asasas'
    },
    {
        bod_id: 390015,
        notes: 'Random notes 11',
        lot_id: null,
        cost_cents: 983,
        discount_percentage: 11.846,
        discounted_cost_cents: 787,
        margin_percentage: 15.834,
        sell_price_cents: 98300,
        lead_time_unit: null,
        lead_time_value: null,
        source_quote_number: 'SN-983',
        internal_notes: 'asasas'
    }
];

export const pricingHistoryResponseData = {
    Items: [
        {
            bom: {
                id: 10,
                name: 'Cyberdyne Systems Reactor',
                quote_status: 'Lost',
                primary_customer: 'Kyle Reese',
                secondary_customer: 'Ed Traxler',
                sales_agent: 'Peter Silberman'
            },
            project: {
                id: 1,
                name: 'Skynet',
                stage: 'Sentient',
                quote_owner: 'T-800'
            },
            company: {
                id: 1,
                name: 'Cyberdyne Systems',
                company_group: 1
            },
            id: 10,
            edited_by: {
                id: 1,
                name: 'Miles Dyson'
            },
            edited_at: '1705402549',
            qty: 1,
            manufacturer: 'Cyberdyne Systems',
            model_number: 'T-1000',
            description: 'Cyberdyne Systems Series 1000 Terminator',
            cost_cents: 2000000000,
            discount_percent: 5,
            discounted_cost_cents: 1900000000,
            margin_percent: 10,
            sell_price_cents: 2090000000,
            lead_time: '5 working days'
        },
        {
            bom: {
                id: 11,
                name: 'Cyberdyne Systems Lab',
                quote_status: 'Won',
                primary_customer: 'Sarah Connor',
                secondary_customer: 'John Connor',
                sales_agent: 'T-1000'
            },
            project: {
                id: 2,
                name: 'Terminator',
                stage: 'Development',
                quote_owner: 'T-1000'
            },
            company: {
                id: 2,
                name: 'SkyNet',
                company_group: 2
            },
            id: 11,
            edited_by: {
                id: 2,
                name: 'Sarah Connor'
            },
            edited_at: '1705402550',
            qty: 2,
            manufacturer: 'SkyNet',
            model_number: 'T-800',
            description: 'SkyNet Series 800 Terminator',
            cost_cents: 1500000000,
            discount_percent: 10,
            discounted_cost_cents: 1350000000,
            margin_percent: 15,
            sell_price_cents: 1552500000,
            lead_time: '10 working days'
        },
        {
            bom: {
                id: 12,
                name: 'Cyberdyne Systems T-600',
                quote_status: 'Pending',
                primary_customer: 'John Connor',
                secondary_customer: 'Kyle Reese',
                sales_agent: 'T-600'
            },
            project: {
                id: 3,
                name: 'Resistance',
                stage: 'Planning',
                quote_owner: 'T-600'
            },
            company: {
                id: 3,
                name: 'Resistance',
                company_group: 3
            },
            id: 12,
            edited_by: {
                id: 3,
                name: 'John Connor'
            },
            edited_at: '1705402551',
            qty: 3,
            manufacturer: 'Resistance',
            model_number: 'T-600',
            description: 'Resistance Series 600 Terminator',
            cost_cents: 1000000000,
            discount_percent: 20,
            discounted_cost_cents: 800000000,
            margin_percent: 25,
            sell_price_cents: 1000000000,
            lead_time: '15 working days'
        },
        {
            bom: {
                id: 13,
                name: 'Cyberdyne Systems T-700',
                quote_status: 'Won',
                primary_customer: 'Sarah Connor',
                secondary_customer: 'Kyle Reese',
                sales_agent: 'T-700'
            },
            project: {
                id: 4,
                name: 'Skynet',
                stage: 'Development',
                quote_owner: 'T-700'
            },
            company: {
                id: 4,
                name: 'Cyberdyne Systems',
                company_group: 4
            },
            id: 13,
            edited_by: {
                id: 4,
                name: 'Sarah Connor'
            },
            edited_at: '1705402552',
            qty: 4,
            manufacturer: 'Cyberdyne Systems',
            model_number: 'T-700',
            description: 'Cyberdyne Systems Series 700 Terminator',
            cost_cents: 2500000000,
            discount_percent: 15,
            discounted_cost_cents: 2125000000,
            margin_percent: 20,
            sell_price_cents: 2550000000,
            lead_time: '20 working days'
        },
        {
            bom: {
                id: 14,
                name: 'Cyberdyne Systems T-800',
                quote_status: 'Lost',
                primary_customer: 'John Connor',
                secondary_customer: 'Sarah Connor',
                sales_agent: 'T-800'
            },
            project: {
                id: 5,
                name: 'Resistance',
                stage: 'Execution',
                quote_owner: 'T-800'
            },
            company: {
                id: 5,
                name: 'Resistance',
                company_group: 5
            },
            id: 14,
            edited_by: {
                id: 5,
                name: 'John Connor'
            },
            edited_at: '1705402553',
            qty: 5,
            manufacturer: 'Resistance',
            model_number: 'T-800',
            description: 'Resistance Series 800 Terminator',
            cost_cents: 3000000000,
            discount_percent: 25,
            discounted_cost_cents: 2250000000,
            margin_percent: 30,
            sell_price_cents: 2925000000,
            lead_time: '25 working days'
        },
        {
            bom: {
                id: 15,
                name: 'Cyberdyne Systems T-900',
                quote_status: 'Pending',
                primary_customer: 'Sarah Connor',
                secondary_customer: 'Kyle Reese',
                sales_agent: 'T-900'
            },
            project: {
                id: 6,
                name: 'Skynet',
                stage: 'Sentient',
                quote_owner: 'T-900'
            },
            company: {
                id: 6,
                name: 'Cyberdyne Systems',
                company_group: 6
            },
            id: 15,
            edited_by: {
                id: 6,
                name: 'Sarah Connor'
            },
            edited_at: '1705402554',
            qty: 6,
            manufacturer: 'Cyberdyne Systems',
            model_number: 'T-900',
            description: 'Cyberdyne Systems Series 900 Terminator',
            cost_cents: 3500000000,
            discount_percent: 30,
            discounted_cost_cents: 2450000000,
            margin_percent: 35,
            sell_price_cents: 3307500000,
            lead_time: '30 working days'
        }
    ]
};
