import { useState, useEffect, FocusEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Modal, BodyXS, ModalFooter, ModalHeader, TextField, Select, CircularProgress, Checkbox, SelectChangeEvent, BodySmall, StarIcon, Tooltip } from '@parspec/pixel';

import { validateEmail } from '../utils/utils';
import { useCompany, useSendLoginInvitationMutation, useEditUserDetailsMutation, useEditInvetedUserDetailsMutation } from '../queries';
import { modalInitialErrorState } from '../utils/utils';
import { IPermissionLocation, UsersModalPropsT, LocationType } from './modal.types';
import { EditInvitedUserDetailsRequest, ICompanyLocation, IEditUserDetailsRequest } from '../queries/apiTypes';
import { useFeatureFlag } from 'src/features/shared/UserActivityTracking/useFeatureFlag';
import { DEAFULT_SNACKBAR_MSG } from '../utils/constants';
import { useSubscriptionInfo } from '../../PlanAndPayment/queries';

export const UsersModal = ({ isOpen, setIsOpen, snackbarData, setSnackbarData, isEdit, rowData }: UsersModalPropsT) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const currentTab = params?.get('tab');

    const [userPermissionLocation, setUserPermissionLocation] = useState<IPermissionLocation>({ permission: 'user', locations: [], access: { quoting: false, product_finder: false } });
    const [locationData, setLocationData] = useState<ICompanyLocation[]>();
    const [inviteEmails, setInviteEmails] = useState<string[]>([]);
    const [currentEmail, setCurrentEmail] = useState<string>('');
    const [errorState, setErrorState] = useState(modalInitialErrorState);
    const { data: allBranchLocations, isLoading: isCompanyLoading } = useCompany();
    const { mutateAsync: sendLoginInvitation, isLoading: loginInvitationLoading } = useSendLoginInvitationMutation();
    const { mutateAsync: editUserDetailsMutation, isLoading: editUserDetailsLoading } = useEditUserDetailsMutation();
    const { mutateAsync: editInvitedUserDetailsMutation, isLoading: editInvitedUserDetailsLoading } = useEditInvetedUserDetailsMutation();
    const { enable_quoting, isLoading: isQuotingFeatureFlagLoading } = useFeatureFlag();
    const { data: paymentDetail, isLoading: paymentDetailLoading } = useSubscriptionInfo();

    const closeModalHandler = () => {
        setIsOpen((prev: UsersModalPropsT) => ({
            ...prev,
            isOpen: false,
            isEdit: false,
            data: {}
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setCurrentEmail(event.target?.value.trim());
        if (errorState.email.errorActive) {
            setErrorState({
                ...errorState,
                email: { ...errorState.email, errorActive: false, title: '' }
            });
        }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (currentEmail.length > 0 && event.type === 'blur') {
            const emailAddresses = currentEmail
                .split(',')
                .filter((i) => i)
                .map((i) => i.trim());

            const allEmailsVerificationPass = emailAddresses.every((mail) => {
                const validationFlag = validationHandler(mail);

                if (validationFlag) {
                    return true;
                } else {
                    return false;
                }
            });

            if (!allEmailsVerificationPass) {
                setErrorState({
                    ...errorState,
                    email: {
                        ...errorState.email,
                        errorActive: true,
                        title: 'Please enter correct email address'
                    }
                });
            }
        }
    };

    const validationHandler = (mailAddress: string) => {
        if (validateEmail(mailAddress)) {
            const mailAddArr = mailAddress.split('@');
            if (mailAddArr[0].length > 64) {
                setErrorState({
                    ...errorState,
                    email: {
                        ...errorState.email,
                        errorActive: true,
                        title: 'One or more email is invalid'
                    }
                });
                return false;
            }

            return true;
        } else {
            setErrorState({
                ...errorState,
                email: {
                    ...errorState.email,
                    errorActive: true,
                    title: 'Please enter correct email address'
                }
            });
            return false;
        }
    };

    const validationFunction = () => {
        const emailAddresses = currentEmail
            .split(',')
            .filter((i) => i)
            .map((i) => i.trim());

        const allEmailsVerificationPass = emailAddresses.every((mail) => {
            const validationFlag = validationHandler(mail);

            if (validationFlag) {
                return true;
            } else {
                return false;
            }
        });

        if (allEmailsVerificationPass) {
            if (inviteEmails.length === 0) {
                const newInviteEmails = [...new Set(emailAddresses)];
                return { emailArr: newInviteEmails, allPassFlag: true };
            } else {
                const uniqueCurrentEmails = [...new Set(emailAddresses)];
                const newInviteEmails = uniqueCurrentEmails.filter((el) => !inviteEmails.includes(el));
                return { emailArr: newInviteEmails, allPassFlag: true };
            }
        }

        return { emailArr: [], allPassFlag: false };
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const newInviteEmailsObj = validationFunction();
            if (newInviteEmailsObj.allPassFlag) {
                setInviteEmails([...inviteEmails, ...newInviteEmailsObj.emailArr]);
                setCurrentEmail('');
            }

            event.preventDefault();
        }
    };

    const checkboxHandler = (eachLocationData: ICompanyLocation, markedDefaultLocation?: boolean) => {
        if (errorState.location.errorActive) {
            setErrorState({
                ...errorState,
                location: {
                    ...errorState.location,
                    errorActive: false
                }
            });
        }
        const isLocationLength = userPermissionLocation.locations?.length === 0;

        const locDataArr = locationData?.map((el: ICompanyLocation) => {
            if (el.id === eachLocationData?.id) {
                return {
                    ...el,
                    isChecked: isLocationLength || markedDefaultLocation ? true : !el.isChecked,
                    is_default: isLocationLength ? true : markedDefaultLocation ? !el.is_default : false
                };
            } else {
                return { ...el, is_default: !markedDefaultLocation ? el.is_default : false };
            }
        });

        const newLocationArr: LocationType[] =
            locDataArr
                ?.filter((el) => el.isChecked)
                .map((el) => ({
                    company_id: el.id,
                    company_name: el.name,
                    is_default: el?.is_default
                })) || [];

        setUserPermissionLocation({
            ...userPermissionLocation,
            locations: newLocationArr
        });

        setLocationData(locDataArr);
    };

    const finalCheckAndDispatchHandler = async () => {
        if (errorState.email.title === 'Email already exists!') {
            return;
        }
        let inviteEmailsArr = [...inviteEmails];

        if (!isEdit) {
            if (inviteEmailsArr.length === 0) {
                if (currentEmail.length === 0) {
                    return setErrorState({
                        ...errorState,
                        email: {
                            ...errorState.email,
                            errorActive: true,
                            title: 'Please provide at least one email address'
                        }
                    });
                }

                const newInviteEmailsObj = validationFunction();

                if (newInviteEmailsObj.allPassFlag) {
                    inviteEmailsArr = [...inviteEmailsArr, ...newInviteEmailsObj.emailArr];
                    setInviteEmails(() => inviteEmailsArr);
                    setCurrentEmail('');
                } else {
                    return;
                }
            } else {
                const newInviteEmailsObj = validationFunction();
                if (newInviteEmailsObj.allPassFlag) {
                    inviteEmailsArr = [...inviteEmailsArr, ...newInviteEmailsObj.emailArr];
                    setInviteEmails(() => inviteEmailsArr);
                    setCurrentEmail('');
                } else {
                    return;
                }
            }
        }

        if (userPermissionLocation.locations.length === 0) {
            setErrorState({
                ...errorState,
                location: {
                    ...errorState.location,
                    errorActive: true
                }
            });
            return;
        }

        if (isEdit) {
            dispatchEditDetailsHandler();
        } else if (!isEdit && inviteEmailsArr.length > 0) {
            dispatchInviteHandler(inviteEmailsArr);
        }
    };

    const generateErrorMessages = (response: any) => {
        // Create containers for each type of error
        const activeUsers: any = [];
        const deactivatedUsers: any = [];
        const rateLimitedUsers: any = [];

        // Iterate through the response and categorize emails
        response.forEach((item: any) => {
            switch (item.error) {
                case 'active_user':
                    activeUsers.push(...item.emails);
                    break;
                case 'deactived_user':
                    deactivatedUsers.push(...item.emails);
                    break;
                case 'rate_limited':
                    rateLimitedUsers.push(...item.emails);
                    break;
                default:
                    break;
            }
        });

        // Generate messages for each type
        const activeUsersMessage = activeUsers.length > 0 ? `The following email(s) already have an active account: ${activeUsers.join(', ')}.` : '';

        const deactivatedUsersMessage = deactivatedUsers.length > 0 ? `The following email(s) have been deactivated: ${deactivatedUsers.join(', ')}.` : '';

        const rateLimitedUsersMessage =
            rateLimitedUsers.length > 0 ? `The following email(s) have reached their daily invitation limit: ${rateLimitedUsers.join(', ')}. Please wait 24 hours and try again.` : '';

        // Combine all messages
        const messages = [activeUsersMessage, deactivatedUsersMessage, rateLimitedUsersMessage]
            .filter((message) => message.length > 0) // Remove empty messages
            .join('\n');

        return messages;
    };
    const dispatchInviteHandler = async (inviteEmailsArr: string[]) => {
        const allUsersnew = {
            emails: inviteEmailsArr,
            companies: userPermissionLocation.locations,
            role: userPermissionLocation.permission as 'user' | 'admin',
            access: { quote: userPermissionLocation.access.quoting, product_finder: userPermissionLocation.access.product_finder }
        };
        const payload = {
            users: allUsersnew
        };
        try {
            const response = await sendLoginInvitation(payload);
            closeModalHandler();
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response.data.message
            });
        } catch (error: any) {
            if (error.response.status === 400) {
                const errorMessages = generateErrorMessages(error.response.data);
                setErrorState({
                    ...errorState,
                    email: {
                        ...errorState.email,
                        errorActive: true,
                        title: errorMessages
                    }
                });
            } else {
                closeModalHandler();
                setSnackbarData({
                    ...snackbarData,
                    isActive: true,
                    message: DEAFULT_SNACKBAR_MSG
                });
            }
        }
    };

    const dispatchEditDetailsHandler = async () => {
        const userId = rowData?.data?.user_details.id;
        const allUsersnew =
            currentTab === 'invited'
                ? {
                      has_quote_access: userPermissionLocation.access.quoting,
                      has_product_finder_access: userPermissionLocation.access.product_finder,
                      role: userPermissionLocation.permission as 'user' | 'admin',
                      company: userPermissionLocation.locations
                  }
                : {
                      user_details: { ...rowData?.data?.user_details },
                      roles: [
                          {
                              role: userPermissionLocation.permission
                          }
                      ],
                      company_details: userPermissionLocation.locations
                  };
        try {
            const response =
                currentTab === 'invited'
                    ? await editInvitedUserDetailsMutation({ userId, data: allUsersnew as EditInvitedUserDetailsRequest })
                    : await editUserDetailsMutation({ userId, data: allUsersnew as IEditUserDetailsRequest });

            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response.data.message
            });
        } catch (e) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
        closeModalHandler();
    };

    useEffect(() => {
        if (isEdit) {
            setUserPermissionLocation({
                ...userPermissionLocation,
                permission: rowData?.data?.roles[0]?.role,
                locations: rowData?.data?.company_details,
                access: { quoting: rowData?.data?.user_details?.quoting_enabled, product_finder: rowData?.data?.user_details?.product_finder_enabled }
            });

            const defaultItems: ICompanyLocation[] = [];
            const nonDefaultItems: ICompanyLocation[] = [];
            for (const el of allBranchLocations?.data || []) {
                const locPresent = rowData?.data?.company_details.find((loc: LocationType) => loc.company_id === el.id);

                const enrichedItem: ICompanyLocation = {
                    ...el,
                    isChecked: Boolean(locPresent),
                    is_default: locPresent?.is_default || false
                };

                // Classify items based on is_default
                if (enrichedItem.is_default) {
                    defaultItems.push(enrichedItem);
                } else {
                    nonDefaultItems.push(enrichedItem);
                }
            }

            // Combine the arrays, placing default items at the top
            const sortedLocDataArr: ICompanyLocation[] = [...defaultItems, ...nonDefaultItems];

            setLocationData(() => sortedLocDataArr);
        } else {
            const branchLocations = allBranchLocations?.data.map((list: ICompanyLocation) => {
                return { ...list, is_default: false };
            });
            setLocationData(branchLocations);
        }
    }, [rowData, isEdit, allBranchLocations]);

    const headerSubtitle = (
        <BodySmall lines={2} color={'text.secondary'}>
            {'To add multiple users, type emails separated by commas and press enter.'}
        </BodySmall>
    );

    const helperText = (
        <>
            {errorState.location.errorActive && (
                <Box mt={2}>
                    <BodyXS color={'error.main'}>{errorState.location.title}</BodyXS>
                </Box>
            )}
        </>
    );

    const isAccessDisabled = !paymentDetail?.data?.product_finder_seats_purchased && !enable_quoting;

    return (
        <Box>
            <Modal
                open={isOpen}
                footer={
                    <ModalFooter
                        isLoading={isEdit ? editUserDetailsLoading || editInvitedUserDetailsLoading : loginInvitationLoading}
                        onAccept={finalCheckAndDispatchHandler}
                        onReject={closeModalHandler}
                        helperText={helperText}
                    />
                }
                header={<ModalHeader onClose={closeModalHandler} title={isEdit ? 'Edit Details' : 'Invite Users'} children={isEdit ? '' : headerSubtitle}></ModalHeader>}
            >
                <Box display={'flex'} flexDirection={'column'} gap={1} width={'560px'}>
                    <Box>
                        {isEdit ? (
                            <>
                                <Box>
                                    <BodyXS>Email</BodyXS>
                                </Box>
                                <Box my={2}>
                                    <BodyXS color={'secondary.dark'}>{rowData?.data?.user_details?.email}</BodyXS>
                                </Box>
                            </>
                        ) : (
                            <TextField
                                label="Email(s)*"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                onBlur={handleBlur}
                                value={currentEmail}
                                error={errorState.email.errorActive}
                                helperText={errorState.email.title}
                                chips={inviteEmails}
                                sx={{
                                    '& .MuiFormHelperText-root.Mui-error': {
                                        fontSize: '12px'
                                    }
                                }}
                                onChipDelete={(index) => {
                                    const filteredAddresses = inviteEmails.filter((_el, i) => i !== index);
                                    setInviteEmails(filteredAddresses);
                                }}
                            />
                        )}
                    </Box>

                    <Box>
                        <Box pt={2}>
                            <Select
                                label="Permission"
                                onChange={(e: SelectChangeEvent<unknown>) => {
                                    setUserPermissionLocation({
                                        ...userPermissionLocation,
                                        permission: e.target.value as string
                                    });
                                }}
                                optionLabelKeyname="label"
                                optionValueKeyname="value"
                                options={[
                                    {
                                        label: 'User',
                                        value: 'user'
                                    },
                                    {
                                        label: 'Admin',
                                        value: 'admin'
                                    }
                                ]}
                                name="Permissions"
                                value={userPermissionLocation.permission}
                                id={'Permission'}
                                labelId={'Permission'}
                            />
                        </Box>
                    </Box>

                    <Box py={2} display={'flex'} flexDirection={'column'} gap={1}>
                        <BodySmall fontWeight={600}>Branch Location(s) *</BodySmall>
                        <BodySmall color={'secondary.light'} fontStyle={'Inter'} lines={3} fontWeight={400}>
                            Select the location(s) you want to grant access to. Star a location to set it as their default branch for future projects.
                        </BodySmall>
                        <Box position={'relative'} height={'150px'} bgcolor={'neutral.light'} p={'8px 16px'} sx={{ overflow: 'hiden', overflowY: 'scroll' }}>
                            {isCompanyLoading && (
                                <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                                    <CircularProgress size="lg" color={'primary'} thickness={3.6} />
                                </Box>
                            )}
                            <Box>
                                {locationData &&
                                    locationData?.length !== 0 &&
                                    locationData.map((eachLocationData: any) => {
                                        return (
                                            <Box key={eachLocationData?.id}>
                                                <Checkbox
                                                    checked={eachLocationData.isChecked}
                                                    label={
                                                        <Box display={'flex'} gap={2} alignItems={'center'}>
                                                            <Tooltip
                                                                title={
                                                                    !eachLocationData.is_default
                                                                        ? eachLocationData.isChecked
                                                                            ? 'Set this location as the default for future projects.'
                                                                            : 'Access to this location must be granted before it can be set as the default.'
                                                                        : null
                                                                }
                                                                onClick={(e) => {
                                                                    checkboxHandler(eachLocationData, true);
                                                                    e.preventDefault();
                                                                }}
                                                            >
                                                                <StarIcon fontSize={'medium'} color={eachLocationData.is_default ? 'tertiary' : 'neutral'} />
                                                            </Tooltip>
                                                            {eachLocationData.name}
                                                        </Box>
                                                    }
                                                    onChange={() => {
                                                        checkboxHandler(eachLocationData, false);
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            </Box>
                        </Box>
                    </Box>
                    {!isEdit &&
                        (paymentDetailLoading || isQuotingFeatureFlagLoading ? (
                            <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                                <CircularProgress size="lg" color="primary" thickness={3.6} />
                            </Box>
                        ) : (
                            !isAccessDisabled && (
                                <Box display="flex" flexDirection="column" gap={1}>
                                    <BodySmall fontWeight={600}>Access</BodySmall>
                                    <Box bgcolor="neutral.light" p="8px 16px">
                                        {enable_quoting && (
                                            <Box>
                                                <Checkbox
                                                    checked={userPermissionLocation.access.quoting}
                                                    label="Quoting"
                                                    onChange={() => {
                                                        const updatedAccess = {
                                                            ...userPermissionLocation.access,
                                                            quoting: !userPermissionLocation.access.quoting
                                                        };

                                                        setUserPermissionLocation({
                                                            ...userPermissionLocation,
                                                            access: updatedAccess
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {Boolean(paymentDetail?.data?.product_finder_seats_purchased) && (
                                            <Box>
                                                <Checkbox
                                                    checked={userPermissionLocation.access.product_finder}
                                                    label="Product Finder"
                                                    onChange={() => {
                                                        const updatedAccess = {
                                                            ...userPermissionLocation.access,
                                                            product_finder: !userPermissionLocation.access.product_finder
                                                        };

                                                        setUserPermissionLocation({
                                                            ...userPermissionLocation,
                                                            access: updatedAccess
                                                        });
                                                    }}
                                                />
                                                <BodySmall color="secondary.light" fontStyle="Inter" lines={3} fontWeight={400} ml={6}>
                                                    100 credits per user/year, prorated based on your plan's next billing date. Credits will not be deducted until the user(s) create their account.
                                                </BodySmall>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )
                        ))}
                </Box>
            </Modal>
        </Box>
    );
};
