export const final_submittal = {
  id: 23096,
  created_at: '2023-03-09T12:02:13.760912Z',
  updated_at: '2023-03-22T12:38:52.193236Z',
  name: 'xml test 4',
  document_link:
    'https://parspec-staging.s3.amazonaws.com/media/submittals/17501/Submittal_This_is_abhishek_s_submital_2023_03_30_unflattened_V1.pdf',
  document_link_flat:
    'https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/18421/Submittal_xml_test_4_2023_02_23_flattened_V4.pdf',
  version_notes: 'test notes 123',
  product_count: null,
  version: 4,
  is_published: false,
  quote_number: '9814',
  is_deleted: true,
  bom: 18421,
  compiled_by: 'admin parsp',
};
