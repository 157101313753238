import { useState } from 'react';

import { Accordion, BodyMedium, Box, DeleteOutlineIcon, IconButton, RichTextEditor } from '@parspec/pixel';

import TextEditor from 'src/features/BOM/shared/TextEditor';
import { useDeleteManufacturerMutation, useUpdateManufacturerNameMutation, useUpdateManufacturerNotesMutation } from '../../queries';
import { StoredManufacturerPairResponse } from '../../queries/apiTypes';
import { useQuoteContext } from '../../QuoteContext';
import { NOTES_PLACEHOLDER } from '../../../constants';

function Manufacturer(props: { data: StoredManufacturerPairResponse; allManufacturers: StoredManufacturerPairResponse[]; pushChangedFields?: () => void }) {
    const { id, field_name, field_value } = props.data;
    const { allManufacturers, pushChangedFields } = props;

    const { handlePdfPreview, isFetch, bomId } = useQuoteContext();
    const [isError, setIsError] = useState(false);
    const [text, setText] = useState({
        note: field_value,
        name: field_name
    });

    const { mutateAsync: updateName } = useUpdateManufacturerNameMutation();
    const { mutateAsync: updateNotes } = useUpdateManufacturerNotesMutation();
    const { mutateAsync: deleteManufacturer } = useDeleteManufacturerMutation();

    const onEditManufacturerName = async (updatedName: string, callBack?: (isError?: boolean) => void) => {
        try {
            const findDuplicateName = allManufacturers.find((mfg: StoredManufacturerPairResponse) => mfg.id !== id && mfg.field_name.toLowerCase() === updatedName.trim().toLowerCase());
            if (findDuplicateName) {
                setIsError(true);
                return;
            } else {
                setIsError(false);
            }
            await updateName({
                bomId: bomId,
                mfgId: id,
                payload: {
                    field_name: updatedName.trim(),
                    field_value: text.note
                }
            });
            setText({
                ...text,
                name: updatedName.trim()
            });
            handlePdfPreview(true);
            pushChangedFields?.();
            if (callBack) callBack();
        } catch (e) {
            if (callBack) callBack(true);
        }
    };

    const onErrorUpdate = () => setIsError(false);

    const onEditManufacturerNotes = async (html: string) => {
        if (field_value !== html) {
            await updateNotes({
                bomId: bomId,
                mfgId: id,
                payload: {
                    field_name: text.name,
                    field_value: html
                }
            });
            handlePdfPreview(true);
            pushChangedFields?.();
        }
    };

    const onDeleteManufacturer = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        e?.preventDefault();

        await deleteManufacturer({ bomId: bomId, mfgId: id });
        handlePdfPreview(true);
        pushChangedFields?.();
    };

    function handleChange() {
        if (isFetch) {
            handlePdfPreview(false);
        }
    }

    const accordianHeader = (
        <Box display="flex" alignItems="center" gap={3} ml={3} boxSizing="border-box">
            <TextEditor inputText={text.name} onSave={onEditManufacturerName} isError={isError} onErrorUpdate={onErrorUpdate} maxLength={128}>
                <BodyMedium fontWeight={700}>{text.name}</BodyMedium>
            </TextEditor>
            <IconButton size="small" onClick={onDeleteManufacturer}>
                <DeleteOutlineIcon color={'secondary'} fontSize="small" />
            </IconButton>
        </Box>
    );

    const accordianBody = <RichTextEditor initialHtml={field_value || ''} placeHolderText={NOTES_PLACEHOLDER} onBlur={onEditManufacturerNotes} onChange={handleChange} />;

    return (
        <>
            <Box
                mb={2}
                sx={{
                    '.MuiAccordionSummary-content': {
                        margin: '0px',
                        '&.Mui-expanded': {
                            margin: '0px'
                        }
                    },
                    '.MuiAccordionSummary-root': {
                        minHeight: '24px',
                        borderBottom: 'none',
                        '&.Mui-expanded': {
                            minHeight: '24px'
                        }
                    }
                }}
            >
                <Accordion
                    options={[
                        {
                            labelId: String(id),
                            summary: accordianHeader,
                            details: accordianBody
                        }
                    ]}
                    sx={{ boxShadow: 'none' }}
                />
            </Box>
        </>
    );
}

export default Manufacturer;
