import React from 'react';

interface TableContextType {
    onClickDelete: (props: any) => void;
    onDuplicate: (props: any) => void;
    isActiveSubscription?: boolean;
    handleSubscriptionPopup: () => void;
    TOOL_TIP_MESSAGE: string;
    bomDataLength: number;
}

const defaultTableContext: TableContextType = {
    onClickDelete: () => {},
    onDuplicate: () => {},
    isActiveSubscription: false,
    handleSubscriptionPopup: () => {},
    TOOL_TIP_MESSAGE: '',
    bomDataLength: 0
};

export const MenuContext = React.createContext<TableContextType>(defaultTableContext);
