import { useNavigate } from 'react-router-dom';

import { BodySmall } from '@parspec/pixel';

import CommonCard from '../shared/CommonCard';

const NoCompileCard: React.FC<{ flag: string }> = ({ flag }) => {
    const navigate = useNavigate();

    const handleCreateNew = () => {
        navigate(`finalDocuments/compile/${flag}`);
    };
    return (
        <CommonCard
            handleCreateNew={handleCreateNew}
            isAutoSync={false}
            content={
                <BodySmall color={'secondary.main'} lines={3}>
                    No documents of this type have <br /> yet been created for this Bill of <br /> Materials.
                </BodySmall>
            }
        />
    );
};

export default NoCompileCard;
