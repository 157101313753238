import { useState, useEffect, useRef } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Box, Snackbar } from '@parspec/pixel';

import { Header } from './Header';
import { FormBody } from './FormBody';
import { Preferences } from './Preferences/Preferences';
import EmailPreferences from './EmailPreferences';

import { initialErrorState, initialProfileState, newPasswordVerifier, trimEmptySpaces } from './utils/utils';
import { ISnackbarDataState } from './MyProfile.types';
import { useGetUserProfileInfoQuery, useEditUserProfileInfoMutation } from './queries';
import { GMAIL_CONFIG } from '../../BOM/FinalDocuments/EmailPopup/constants';

export const MyProfile = () => {
    const { data: userProfile, isLoading: userProfileLoading } = useGetUserProfileInfoQuery();
    const { mutateAsync: editUserDetailsMutation, isLoading: editUserDetailsLoading } = useEditUserProfileInfoMutation();

    const [profileState, setProfileState] = useState(initialProfileState);
    const [resetData, setResetData] = useState(initialProfileState);
    const [errorState, setErrorState] = useState(initialErrorState);

    const [snackbarData, setSnackbarData] = useState<ISnackbarDataState>({
        isActive: false,
        message: ''
    });

    const formRef = useRef<HTMLFormElement>(null);

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
            });
        }

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && profileState.newPassword.data !== profileState.confirmPassword.data) {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
            });
        }

        const newPasswordFlag = profileState.newPassword.data.trim().length === 0 && profileState.confirmPassword.data.length > 0;
        const confirmPasswordFlag = profileState.confirmPassword.data.trim().length === 0 && profileState.newPassword.data.length > 0;

        if (newPasswordFlag || confirmPasswordFlag) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: newPasswordFlag, title: `${newPasswordFlag ? 'Required' : ''}` },
                confirmPassword: { ...errorState.confirmPassword, isError: confirmPasswordFlag, title: `${confirmPasswordFlag ? 'Required' : ''}` }
            });
        }

        if (errorState.firstName.isError || errorState.lastName.isError || errorState.currentPassword.isError || errorState.newPassword.isError || errorState.confirmPassword.isError) {
            return;
        }

        const trimmedFirstName = trimEmptySpaces(profileState.firstName);
        const trimmedLastName = trimEmptySpaces(profileState.lastName);

        const firstNameFlag = trimmedFirstName.length === 0;
        const lastNameFlag = trimmedLastName.length === 0;
        const passwordFlag = profileState.currentPassword.data.length === 0;

        if (firstNameFlag || lastNameFlag || passwordFlag) {
            return setErrorState({
                ...errorState,
                firstName: { ...errorState.firstName, isError: firstNameFlag, title: `${firstNameFlag ? 'Required' : ''}` },
                lastName: { ...errorState.lastName, isError: lastNameFlag, title: `${lastNameFlag ? 'Required' : ''}` },
                currentPassword: { ...errorState.currentPassword, isError: passwordFlag, title: `${passwordFlag ? 'Required' : ''}` }
            });
        }

        const payload = {
            first_name: trimmedFirstName,
            last_name: trimmedLastName,
            email: profileState.email,
            password: profileState.currentPassword.data,
            new_password: profileState.confirmPassword.data,
            confirm_password: profileState.confirmPassword.data
        };

        const resp = await editUserDetailsMutation(payload);

        setSnackbarData({
            ...snackbarData,
            isActive: true,
            message: `${(resp as any)?.response?.data ? (resp as any)?.response?.data : 'User details updated successfully'}`
        });
    };

    useEffect(() => {
        if (!userProfile?.data) {
            return;
        }

        setProfileState(() => ({
            ...profileState,
            firstName: userProfile.data.first_name,
            lastName: userProfile.data.last_name,
            email: userProfile.data.email,
            currentPassword: { ...profileState.currentPassword, data: '', showPassword: false },
            newPassword: { ...profileState.newPassword, data: '', showPassword: false },
            confirmPassword: { ...profileState.confirmPassword, data: '', showPassword: false }
        }));

        setResetData(() => ({ ...resetData, firstName: userProfile.data.first_name, lastName: userProfile.data.last_name, email: userProfile.data.email }));
    }, [userProfile]);

    return (
        <Box mb={8} width="calc(100vw - 390px)" height={'82vh'} sx={{ overflowY: 'scroll', overflowX: 'scroll' }} bgcolor={'#f3f5fa'} minWidth={'942px'}>
            <Box p={6} bgcolor="secondary.contrastText">
                <Header resetData={resetData} setProfileState={setProfileState} onSubmitHandler={onSubmitHandler} editUserDetailsLoading={editUserDetailsLoading} setErrorState={setErrorState} />
                <FormBody
                    userProfileLoading={userProfileLoading}
                    profileState={profileState}
                    setProfileState={setProfileState}
                    onSubmitHandler={onSubmitHandler}
                    formRef={formRef}
                    errorState={errorState}
                    setErrorState={setErrorState}
                />
            </Box>

            <Box p={6} mt={6} bgcolor="secondary.contrastText">
                <Preferences snackbarData={snackbarData} setSnackbarData={setSnackbarData} />
            </Box>

            <Box p={6} mt={6} bgcolor="secondary.contrastText">
                <GoogleOAuthProvider clientId={GMAIL_CONFIG.clientId}>
                    <EmailPreferences snackbarData={snackbarData} setSnackbarData={setSnackbarData} />
                </GoogleOAuthProvider>
            </Box>

            {<Snackbar open={snackbarData.isActive} message={snackbarData.message} onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })} />}
        </Box>
    );
};
