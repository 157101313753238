import { rest } from 'msw';
import { datasheets, AddProduct } from './datasheets';

export const documentSuggestionHandlers = [
    rest.post('*/bom/:bomId/submittal_metadata/:bodId/search?refresh=false', (req, res, ctx) => {
        const { bomId, bodId } = req.params || {};
        const callback = `bom/${bomId}/submittal_metadata/${bodId}/search_results/340431?page_number=1&limit=3`;
        return res(ctx.delay(2000), ctx.json({ callback }));
    }),
    rest.get('*/bom/:bomId/submittal_metadata/:bodId/search_results/:searchId?page_number=:page&limit=3', (req, res, ctx) => {
        const { bomId, bodId, searchId } = req.params || {};
        const index = req.url.search.indexOf('=');
        const pageNumber = Number(req.url.search[index + 1]);
        const callback = pageNumber === 6 ? '' : `/bom/${bomId}/submittal_metadata/${bodId}/search_results/${searchId}?page_number=${pageNumber + 1}&limit=3`;
        return res(
            ctx.delay(2000),
            ctx.json({
                search_results: datasheets.slice((pageNumber - 1) * 3, 3 * pageNumber),
                callback
            })
        );
    }),
    rest.post('*/bom/:bomId/submittal_metadata/:bodId/selected_datasheet/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.delete('*/bom/:bomId/submittal_metadata/:bodId/selected_datasheet/:selectedId', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({ message: 'success' }));
    }),
    rest.post('*/bom/:bom_id/product_finder/:bod_id/selected_datasheet/bulk?source=:actionType', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(AddProduct));
    })
];
