import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import {
    endPoints,
    postSignInData,
    postForgotPassword,
    postResetPassword,
    getResetPassword,
    postHubspotTokenRequest,
    invitedUserSignupTokenValidation,
    newUserSignupTokenValidation,
    postInvitedUserSignUpData,
    signupUser,
    newUserSignupAddCompanyInfo,
    newUserSignupAddPersonalInfo,
    postSSOIdentifierRequest,
    postSSOAuthenticationRequest,
    logOut
} from './apis';
import { getUserProfileInfo } from '../../Settings/MyProfile/queries/apis';
import { queryClient } from 'src/app/queryClient';
import { AxiosResponse } from 'axios';
import { NewUserSignUpVerifyInvitationResponse, ResetPasswordMailTokenValidationResponse, SignUpValidateInvitationResponse } from './apiTypes';

const getResetPasswordKey = (token: string) => [endPoints.resetPassword(token)];
const getInvitedUserSignupInvitationValidationKey = (token: string) => [endPoints.validateInvitedUserSignupInvitation(token)];
const getNewUserSignupInvitationValidationKey = (token: string) => [endPoints.validateInvitedUserSignupInvitation(token)];
const authTokenKey = ['auth-token-key'];

export function updateAuthTokenData(data: { auth: boolean; token: string | null }) {
    queryClient.setQueriesData(authTokenKey, data);
}

export function deleteAuthTokenData() {
    updateAuthTokenData({ auth: false, token: null });
}

export function useAuthTokenQuery() {
    return useQuery<{ auth: boolean; token: string | null }>(authTokenKey, () => Promise.resolve({ auth: localStorage.getItem('auth') === 'true', token: localStorage.getItem('token') }));
}

export const useSignInUserMutation = () =>
    useMutation(postSignInData, {
        onSuccess: (response, payload) => {
            queryClient.removeQueries();
            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('auth', response?.data?.auth);
            localStorage.setItem('email', payload.email);
            updateAuthTokenData(response?.data);
        }
    });

export const useForgotPasswordMutation = () => useMutation(postForgotPassword);

export const useResetPasswordMutation = () => useMutation(postResetPassword);

export const useGetSSOIdentifierMutation = () => useMutation(postSSOIdentifierRequest);

export const useSSOAuthenticationMutation = () =>
    useMutation(postSSOAuthenticationRequest, {
        onSuccess: (response) => {
            queryClient.removeQueries();

            updateAuthTokenData(response?.data);
        }
    });

export const useResetPasswordMailTokenValidationQuery = (token: string, options?: Omit<UseQueryOptions<AxiosResponse<ResetPasswordMailTokenValidationResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getResetPasswordKey(token), () => getResetPassword(token), options);

export const useHubspotTokenRequestMutation = () => useMutation(postHubspotTokenRequest);

export const useGetUserInfoMutation = () => useMutation(getUserProfileInfo);

export const useValidateInvitedUserSignUpInvitationMutation = (token: string, options?: Omit<UseQueryOptions<AxiosResponse<SignUpValidateInvitationResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getInvitedUserSignupInvitationValidationKey(token), () => invitedUserSignupTokenValidation(token), options);

export const useSignUpInvitedUserMutation = () => useMutation(postInvitedUserSignUpData);

export const useSignUpNewUserMutation = () => useMutation(signupUser);

export const useValidateNewUserSignUpInvitationMutation = (token: string, options?: Omit<UseQueryOptions<AxiosResponse<NewUserSignUpVerifyInvitationResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getNewUserSignupInvitationValidationKey(token), () => newUserSignupTokenValidation(token), options);

export const useSignUpNewUserCompanyInfoMutation = () => useMutation(newUserSignupAddCompanyInfo);

export const useSignUpNewUserPersonalInfoMutation = () => useMutation(newUserSignupAddPersonalInfo);

export const useLogoutMutation = () => {
    return useMutation(logOut, {
        onSuccess: () => {
            localStorage.clear();
            deleteAuthTokenData();
            queryClient.removeQueries();
        }
    });
};
