import { ICellRendererParams } from 'ag-grid-community';

import CellCard from '../cellCard';
import { VISIBLITY } from '../../constants';

export function LocationVisiblity(params: ICellRendererParams) {
    const { data } = params;
    const { visibility, locationAccess } = data;
    const dataArray = visibility === VISIBLITY.PUBLIC ? ['Public'] : visibility === VISIBLITY.PRIVATE ? ['Private'] : locationAccess || [];
    return <CellCard dataArray={dataArray} />;
}
