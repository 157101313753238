import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BodySmall, Box, Button, H5, Skeleton, Grid } from '@parspec/pixel';

import { ManageBomResponse } from './BOMModal/queries/apiTypes';
import { BomModal } from './BOMModal';
import { useGetUserProfileInfoQuery } from '../Settings/MyProfile/queries';
import { CreateAndEditProjectModal } from '../shared/CreateAndEditProjectModal';
import { CreateProjectResponse } from '../shared/CreateAndEditProjectModal/queries/apiTypes';
import { useSubscriptionInfo } from '../Settings/PlanAndPayment/queries';
import { SubsriptionPopup } from '../../app/ProtectedRoute';

const Header = () => {
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const { data: userData, isLoading: isUserInfoLoading } = useGetUserProfileInfoQuery();

    const { data: paymentDetail, isLoading: paymentDetailLoading } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const [openModal, setOpenModal] = useState(false);

    const [openProjectModal, setOpenProjectModal] = useState(false);

    const navigate = useNavigate();

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const onClickCreateProject = () => {
        if (isActiveSubscription) {
            handleProjectDialog();
        } else {
            handleSubscriptionPopup();
        }
    };

    const onClickCreateBom = () => {
        if (isActiveSubscription) {
            handleDialog();
        } else {
            handleSubscriptionPopup();
        }
    };

    const handleDialog = () => {
        setOpenModal(!openModal);
    };

    const handleProjectDialog = () => {
        setOpenProjectModal(!openProjectModal);
    };

    const onAcceptSuccessCreateProject = (response: CreateProjectResponse) => {
        handleProjectDialog();
        navigate(`../project/${response.id}`);
    };
    const handleAcceptSuccess = async (response: ManageBomResponse) => {
        const { projectId, bomId } = response;
        handleDialog();
        if (bomId) navigate(`../project/${projectId}/bom/${bomId}?tab=bom`);
    };

    const firstName = userData?.data?.first_name.trim();
    const currentUserName = isUserInfoLoading ? <Skeleton variant="text" width="48px" height="18px" sx={{ display: 'inline-block' }} /> : firstName;
    return (
        <Box px={4} py={6} bgcolor="primary.contrastText" borderBottom="1px solid" borderColor="neutral.main">
            <Box display="flex">
                <Grid container xs={6}>
                    <Grid item>
                        <Box>
                            <H5 width={'max-content'}>Dashboard</H5>

                            <BodySmall>Welcome back, {currentUserName}</BodySmall>
                        </Box>
                    </Grid>
                </Grid>

                <Box ml="auto" display="flex">
                    <Box display="flex">
                        <Box mr={2}>
                            <Button variant="outlined" color="secondary" onClick={onClickCreateProject} disabled={paymentDetailLoading}>
                                Create Project
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="contained" onClick={onClickCreateBom} disabled={paymentDetailLoading}>
                                Create BOM
                            </Button>
                        </Box>
                        {openModal && <BomModal open={openModal} onCloseClick={handleDialog} onSuccess={handleAcceptSuccess} />}
                        {openProjectModal && <CreateAndEditProjectModal open={openProjectModal} onCloseClick={handleProjectDialog} onSuccess={onAcceptSuccessCreateProject} />}
                        {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default Header;
