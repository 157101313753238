import { axios } from 'src/app/Axios';
import {
    UpdateTextFieldProps,
    UpdateToggleProps,
    UpdateLogoProps,
    UpdateDateFieldProps,
    DocumentTypes,
    DocumentPresetResponse,
    DocumentLayoutType,
    PreviewImageProps as PreviewImageResponseType,
    DocumentLogoType,
    ToggleConfigurationTypes,
    CustomPageResponseType,
    UpdateTOCLogoProps,
    UpdateHeaderAndFooterLogoProps
} from './apiTypes';

import { ENTITY } from '../../constants';

export const endPoints = {
    presetPost: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/`,
    compileDocumentPagePreview: (bomId: string, fieldName: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/${fieldName}/preview/`,
    updateDocumentToggleField: (bomId: string, fieldName: string, documentType: string, entity: string) => `/bom/${bomId}/${documentType}_preset/${entity}/configuration/${fieldName}/`,
    updateDocumentTextField: (bomId: string, fieldName: string, documentType: string, entity: string) => `/bom/${bomId}/${documentType}_preset/${entity}/data/${fieldName}/`,
    getContentLayout: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/${documentType}_layout/data/`,
    updateContentLayout: (bomId: string, documentType: string, fieldName: string) => `/bom/${bomId}/${documentType}_preset/${documentType}_layout/data/${fieldName}/`,
    coverPagedocumentLogo: (bomId: string, fieldName: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/cover_page/data/${fieldName}/logo/`,
    tocDocumentLogo: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/toc/logo/`,
    headerAndFooterDocumentLogo: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/header_footer/data/logo/`,
    customPage: (bomId: string, documentType: string, entity: string) => `/bom/${bomId}/${documentType}_preset/${entity}/custom/`,
    coverPageToggleConfiguration: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/cover_page/configuration/`,
    getCoverPageTextField: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/cover_page/data/`,
    getTocToggleConfiguration: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}_preset/toc/configuration/`,
    documentPreset: (bomId: string, documentType: string, entity: string) => `/bom/${bomId}/${documentType}_preset/${entity}/`,
    sectionBreak: (bomId: string, documentType: string, field_name: string) => `/bom/${bomId}/${documentType}_preset/${documentType}_layout/data/${field_name}/`
};

/** generalized API calls */
export const getDocumentPreset = (bomId: string, documentType: string, entity: string) => axios.get<DocumentPresetResponse>(endPoints.documentPreset(bomId, documentType, entity));

export const compileDocumentPagePreview = async (bomId: string, fieldName: string, documentTypePreset: string) => {
    if (fieldName === ENTITY.LAYOUT) {
        fieldName = `${documentTypePreset}_${fieldName}`;
    }
    const response = await axios.post<PreviewImageResponseType>(endPoints.compileDocumentPagePreview(bomId, fieldName, documentTypePreset));
    if (response) return response;
    throw new Error('API request failed');
};

export const updateDocumentTextField = ({ bomId, fieldName, documentType, field_value, entity }: UpdateTextFieldProps | UpdateDateFieldProps) => {
    const { pre_fill, value } = field_value as { pre_fill: string; value?: string | number };
    const payload = {
        field_value: fieldName === 'quote_number' || fieldName === 'addressed_to' ? { pre_fill, value } : field_value
    };
    return axios.patch(endPoints.updateDocumentTextField(bomId, fieldName!, documentType, entity!), payload);
};
export const updateDocumentToggleField = ({ bomId, fieldName, documentType, entity, is_active }: UpdateToggleProps) => {
    return axios.patch(endPoints.updateDocumentToggleField(bomId, fieldName, documentType, entity), { is_active });
};

export const uploadCustomPage = async ({ bomId, documentType, entity, formData }: { bomId: string; documentType: string; entity: string; formData: FormData }) => {
    return axios.post(endPoints.customPage(bomId, documentType, entity), formData);
};

export const deleteCustomPage = async ({ bomId, documentType, entity }: { bomId: string; documentType: string; entity: string }) => {
    return axios.delete(endPoints.customPage(bomId, documentType, entity));
};

export const getCustomPage = (bomId: string, documentType: string, entity: string) => {
    return axios.get<CustomPageResponseType>(endPoints.customPage(bomId, documentType, entity));
};

/** Cover Page APIs */
export const getCoverPageDocumentLogo = (bomId: string, fieldName: string, documentType: string) => axios.get<DocumentLogoType>(endPoints.coverPagedocumentLogo(bomId, fieldName, documentType));

export const updateCoverPageDocumentLogo = ({ documentType, bomId, fieldName, logo, source }: UpdateLogoProps) => {
    if (logo) {
        return axios.patch(endPoints.coverPagedocumentLogo(bomId, fieldName, documentType), logo);
    }
    return axios.patch(endPoints.coverPagedocumentLogo(bomId, fieldName, documentType), { source });
};

export const deleteCoverPageDocumentLogo = ({ bomId, fieldName, documentType }: { bomId: string; fieldName: string; documentType: string; coverPageEntity?: string }) =>
    axios.delete(endPoints.coverPagedocumentLogo(bomId, fieldName, documentType));

export const getCoverPageToggleConfiguration = (bomId: string, documentType: string) => {
    return axios.get<ToggleConfigurationTypes[]>(endPoints.coverPageToggleConfiguration(bomId, documentType));
};

export const getAllCoverPageTextField = (bomId: string, documentType: string) => axios.get(endPoints.getCoverPageTextField(bomId, documentType));

/** toc apis */

export const getTocToggleConfiguration = (bomId: string, documentType: string) => {
    return axios.get<ToggleConfigurationTypes[]>(endPoints.getTocToggleConfiguration(bomId, documentType));
};

export const updateTocToggle = async ({ bomId, fieldName, ...payload }: UpdateToggleProps) => {
    return axios.patch(`/bom/${bomId}/submittal_preset/toc/configuration/${fieldName}/`, payload);
};

export const documentPreset = ({ bomId, documentType, timezone }: DocumentTypes) =>
    axios.post(endPoints.presetPost(bomId, documentType), {
        timezone
    });

export const getTOCDocumentLogo = (bomId: string, documentType: string) => axios.get<DocumentLogoType>(endPoints.tocDocumentLogo(bomId, documentType));

export const updateTOCDocumentLogo = ({ documentType, bomId, logo, source }: UpdateTOCLogoProps) => {
    if (logo) {
        return axios.patch(endPoints.tocDocumentLogo(bomId, documentType), logo);
    }
    return axios.patch(endPoints.tocDocumentLogo(bomId, documentType), { source });
};

export const deleteTOCDocumentLogo = ({ bomId, documentType }: { bomId: string; documentType: string }) => axios.delete(endPoints.tocDocumentLogo(bomId, documentType));

/** Content Formatting */
export const contentLayout = (bomId: string, documentType: string) => axios.get<DocumentLayoutType>(endPoints.getContentLayout(bomId, documentType));

export const updateContentLayout = ({ bomId, documentType, fieldName, field_value }: { bomId: string; documentType: string; fieldName: string; field_value: string }) =>
    axios.patch(endPoints.updateContentLayout(bomId, documentType, fieldName), { field_value });

/** Header And Footer */

export const getHeaderAndFooterDocumentLogo = (bomId: string, documentType: string) => axios.get<DocumentLogoType>(endPoints.headerAndFooterDocumentLogo(bomId, documentType));

export const updateHeaderAndFooterDocumentLogo = ({ documentType, bomId, logo, source }: UpdateHeaderAndFooterLogoProps) => {
    if (logo) {
        return axios.patch(endPoints.headerAndFooterDocumentLogo(bomId, documentType), logo);
    }
    return axios.patch(endPoints.headerAndFooterDocumentLogo(bomId, documentType), { source });
};

export const deleteHeaderAndFooterDocumentLogo = ({ bomId, documentType }: { bomId: string; documentType: string }) => axios.delete(endPoints.headerAndFooterDocumentLogo(bomId, documentType));

export const updateSectionBreaks = ({ bomId, documentType, field_value, fieldName }: { bomId: string; documentType: string; field_value: string | boolean; fieldName: string }) =>
    axios.patch(endPoints.sectionBreak(bomId, documentType, fieldName), { field_value });
