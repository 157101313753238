import { ICellRendererParams } from 'ag-grid-community';

import { Box, Tooltip, SyncIcon, Status, BodyXS, Button, StarIcon, LinkIcon } from '@parspec/pixel';

import Link from '../../../shared/RouteLink';
import { TableLinkTemplate, getIsDueDateRed } from 'src/features/shared/utils/utils';
import { ContactInfo } from '../../queries/apiTypes';
import { DOCUMENT_STATUS_COLOR_MAP, ColorCode, DocumentStatusEnum, PRIMARY_START_ICON_MESSAGE, LINKED_START_ICON_MESSAGE } from '../../../shared/constants';
import { getDate } from '../../../Project/shared/utils';
import { getDateForTable } from '../utils';

export const ProjectTemplate = (data: any) => {
    const { projectName, projectId, target } = data;

    return (
        <Box>
            <TableLinkTemplate to={`/v2/project/${projectId}`} label={projectName} target={target} />
        </Box>
    );
};

export const BomTemplate = function (data: any) {
    const { bomId, bomName, isPrimary, isLinked, projectId, target } = data;

    return (
        <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
            <Box color={'text.secondary'} fontSize={'16px'}>
                {isPrimary && (
                    <Box mr={2}>
                        <Tooltip title={PRIMARY_START_ICON_MESSAGE}>
                            <StarIcon fontSize={'inherit'} color={'inherit'} />
                        </Tooltip>
                    </Box>
                )}
                {isLinked && (
                    <Box mr={2}>
                        <Tooltip title={LINKED_START_ICON_MESSAGE}>
                            <LinkIcon fontSize={'inherit'} color={'inherit'} />
                        </Tooltip>
                    </Box>
                )}
            </Box>

            <Box>
                <TableLinkTemplate to={`/v2/project/${projectId}/bom/${bomId}?tab=bom`} label={bomName} target={target} />
            </Box>
        </Box>
    );
};

export const ViewTemplate = (props: any) => {
    const { data, isOutOfSync, bomId, documentType, projectId } = props;
    const status = props?.status || DocumentStatusEnum.IN_PROGRESS;
    const color = DOCUMENT_STATUS_COLOR_MAP[status as keyof typeof DOCUMENT_STATUS_COLOR_MAP] as ColorCode;
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            {data ? (
                <Box display="flex" alignItems="center">
                    <Box>
                        <Status color={color}>
                            <BodyXS color={color}>{status}</BodyXS>
                        </Status>
                    </Box>
                    {isOutOfSync && (
                        <Box ml={3} display="flex">
                            <Tooltip title="Changes have been made to the Bill of Materials since the latest version.">
                                <SyncIcon color={'tertiary' as 'primary'} />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            ) : (
                <TableLinkTemplate to={`/v2/project/${projectId}/bom/${bomId}/finalDocuments/compile/${documentType}`} label={DocumentStatusEnum.CREATENEW}/>
            )}
        </Box>
    );
};

export const DocumentIdTemplate = (params: ICellRendererParams) => {
    const { bomId, submittalId, omId, projectId } = params.data;
    let id = 0,
        docType = '';

    if (submittalId) {
        id = submittalId;
        docType = 'submittal';
    }

    if (omId) {
        id = omId;
        docType = 'o_m';
    }

    return (
        <Link to={`/v2/project/${projectId}/bom/${bomId}/finalDocuments/share/${docType}/${id}`}>
            <Button variant="text" color="primary" size="small">
                {id}
            </Button>
        </Link>
    );
};

export const QuoteNumberTemplate = (params: ICellRendererParams) => {
    const { quoteNumber, bomId, quoteId, projectId } = params.data;

    return (
        <Link to={`/v2/project/${projectId}/bom/${bomId}/finalDocuments/share/quote/${quoteId}`}>
            <Button variant="text" color="primary" size="small">
                {quoteNumber}
            </Button>
        </Link>
    );
};

export const DateTemplate = ({ dueDate }: { dueDate: string | null }) => {
    const color = dueDate && getIsDueDateRed(dueDate) ? 'error.main' : '';

    const dueDateForUi = getDateForTable(dueDate);

    return (
        <Box component="span" color={color}>
            {dueDateForUi || '-'}
        </Box>
    );
};

export const Customer = (customerInfo: ContactInfo) => {
    const { company, contact } = customerInfo || {};
    const { id: companyId, name: companyName, business_type, is_deleted: isCompanyDeleted } = company || {};
    const { id: contactId, name: contactName, role, is_deleted: isContactDeleted } = contact || {};

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            {!companyId && !contactId ? '-' : ''}
            {companyId ? (
                <Box component={'span'} display="flex" gap={1} alignItems="center" mt={-1}>
                    {!isCompanyDeleted ? (
                        <Box component={'span'}>
                            <TableLinkTemplate to={`/v2/company/${companyId}`} label={companyName || ''} />
                        </Box>
                    ) : (
                        <Box component="span">{companyName}</Box>
                    )}

                    {business_type && (
                        <Box color="secondary.light" component="span" fontSize={12}>
                            ({business_type})
                        </Box>
                    )}
                </Box>
            ) : null}
            {contactId ? (
                <Box component={'span'} display="flex" gap={1} alignItems="center" mt={-6}>
                    {!isContactDeleted ? (
                        <Box component={'span'}>
                            <TableLinkTemplate to={`/v2/company/${companyId}/contacts/${contactId}`} label={contactName || ''} />
                        </Box>
                    ) : (
                        <Box component="span">{contactName}</Box>
                    )}
                    {role && (
                        <Box color="secondary.light" component="span" fontSize={12}>
                            ({role})
                        </Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

export const LastModified = (props: { updatedBy: string; updatedAt: string }) => {
    const { updatedBy, updatedAt } = props;
    return (
        <Box display="flex" flexDirection="column" mt={-2}>
            <Box component="span">{updatedBy || ''}</Box>
            <Box color="secondary.light" component="span" fontSize={12} mt={-6}>
                {updatedAt ? getDate(updatedAt) : '-'}
            </Box>
        </Box>
    );
};
