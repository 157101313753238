export const priceAndLeadtimeGetResponse = [
    { field_name: 'price_and_lead_time_section', is_active: false, field_value: '' },
    { field_name: 'quantity', field_value: '', is_active: true },
    { field_name: 'type', field_value: '', is_active: true },
    { field_name: 'manufacturer', field_value: '', is_active: true },
    { field_name: 'lead_time', field_value: '', is_active: true },
    { field_name: 'unit_price', field_value: '', is_active: true },
    { field_name: 'extended_sell_price', field_value: '', is_active: true },
    { field_name: 'section_subtotal', field_value: '', is_active: true },
    { field_name: 'datasheet_hyperlink', field_value: '', is_active: true },
    { field_name: 'service_breakdown', field_value: '', is_active: true },
    { field_name: 'tax_breakdown', field_value: '', is_active: true },
    { field_name: 'freight_breakdown', field_value: '', is_active: true },
    { field_name: 'lot_breakdown', field_value: '', is_active: true },
    { field_name: 'summarized_total', field_value: 'pstf_grand_total', is_active: true }
];
