export const branchLocations = [
    {
        location: 'CED Europe',
        projects: 131,
        quotes: 131,
        submittals: 131,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 1000000
    },
    {
        location: 'CED Antarctica',
        projects: 131,
        quotes: 131,
        submittals: 131,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 1000000
    },
    {
        location: 'CED Asia',
        projects: 131,
        quotes: 131,
        submittals: 131,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 1000000
    },
    {
        location: 'CED Africa',
        projects: 131,
        quotes: 131,
        submittals: 131,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 1000000
    },
    {
        location: 'CED Australia',
        projects: 131,
        quotes: 131,
        submittals: 131,
        oMs: 131,
        winRate: 43,
        totalExtSellPrice: 10000000,
        services: 10000000,
        freight: 1000000,
        totalExtDiscountedCost: 1000000
    }
];
