import { numberComparator, DocumentStatusTemplate } from '../shared/utils/utils';

import { convertToCents } from '../../shared/utils/utils';

export const getRowId = (params: any) => {
    return params?.data?.quoteId;
};

export const quotePageTableColumnOptions = {
    data: [
        { field: 'quoteNumber', label: 'Quote ID' },
        { field: 'status', label: 'Status' },
        { field: 'quoteVersion', label: 'Version' },
        { field: 'quoteCreationDate', label: 'Creation Date' },
        { field: 'quoteCreatedBy', label: 'Created By' },
        { field: 'quoteProductCount', label: 'Products Included' },
        { field: 'quoteGrandTotal', label: 'Grand Total' },
        { field: 'dueDate', label: 'Quote Due Date' },
        { field: 'quoteOwner', label: 'Quote Owner' },
        { field: 'bomName', label: 'Bill Of Materials' },
        { field: 'primary_contact', label: 'Primary Customer' },
        { field: 'projectName', label: 'Project' },
        { field: 'projectStage', label: 'Project Stage' },
        { field: 'branchLocation', label: 'Branch Location' }
    ]
};

export const getGrandTotalComparator = (valueA: string, valueB: string) => {
    const valA = convertToCents(Number(valueA.split('$')[1].replace(',', '')));
    const valB = convertToCents(Number(valueB.split('$')[1].replace(',', '')));

    return numberComparator(valA, valB);
};

export const getQuoteStatusTemplate = (props: any) => {
    const { data } = props;
    return <DocumentStatusTemplate {...data} isOutOfSync={data?.quoteOutOfSync} />;
};
