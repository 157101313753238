import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { SpreadsheetComponent, getRangeIndexes, getRangeAddress, BeforeSelectEventArgs } from '@syncfusion/ej2-react-spreadsheet';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';

import { BodySmall, Box, ModalFooter, ModalHeader, Modal, Button } from '@parspec/pixel';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import zipcelx from 'src/features/shared/utils/excel-creator/zipcelx';
import { useConfirmContinueAnnotationMutation, useConfirmContinuePackageMutation, useGetContentDetailQuery } from '../../addProducts/queries';
import { SubmittalBomType } from '../constants';
import { environment } from 'src/environments';
import { useUserAccountPreferencesQuery } from '../../../Settings/MyProfile/queries';
import { useDatasheetAutoSearchMutation, useOmAutoSearchMutation } from '../../queries';
import { pushEvent } from '../../../shared/UserActivityTracking/EventService';
import { HEADER_TYPE, MAPPINGS, MENU_ITEMS } from './constants';
import { IMappings } from './types';
import { insertMutation } from './queries/mutations';

type RefreshDataSheetProps = {
    open: boolean;
    handleDialog: () => void;
    onAccept: () => void;
    onReject: () => void;
    onClose: () => void;
    xmlData: Record<string, any>;
};
enableRipple(true);

const ConfirmColumn = (props: RefreshDataSheetProps) => {
    const { bomId } = useParams();
    const { data: userPreferences } = useUserAccountPreferencesQuery();
    const { mutateAsync: autoSearchDatasheet, isLoading: isAutoSearchDatasheetLoading } = useDatasheetAutoSearchMutation();
    const { mutateAsync: autoSearchOM, isLoading: isAutoSearchOMLoading } = useOmAutoSearchMutation();

    const spreadsheetRef = useRef<SpreadsheetComponent>(null);
    const [isSpreadSheetInitialized, setisSpreadSheetInitialized] = useState<boolean>();
    const [currentExcelIndex, setCurrentExcelIndex] = useState<number>(0);
    const { data } = useGetContentDetailQuery(Number(bomId));
    const [isOcrImgExist, setisOcrImgExist] = useState<boolean>(false);
    const [hyperlink, sethyperlink] = useState<string>();
    const [isExcelFileExist, setisExcelFileExist] = useState<boolean>();
    const { isLoading: isconfirmContinue, mutateAsync: confirmContinue } = useConfirmContinueAnnotationMutation();
    const { isLoading: isconfirmContinuePackageLoading, mutateAsync: confirmContinuePackage } = useConfirmContinuePackageMutation();
    const formDataConfirmColumnRef = useRef<FormData>(new FormData());
    const cMenuRef = useRef<ContextMenuComponent>(null);
    const ClickedHeaderRef = useRef<Element | null>(null);

    const [errorState, setErrorState] = useState<{ isError: boolean; errorText: string }>({ isError: false, errorText: '' });

    const mappingTrackObj = useRef<Record<string, any>>({
        [MAPPINGS.TYPE]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.MANUFACTURER]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.MODEL_NUMBER]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.QUANTITY]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.SUBMITTAL_NOTES]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.DESCRIPTION]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.QUOTE_NOTES]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.UNIT_COST]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.LEAD_TIME]: {
            mappedElement: null,
            mappedCol: null
        },
        [MAPPINGS.EXTENDED_COST]: {
            mappedElement: null,
            mappedCol: null
        },
        'Ignore Column': {
            mappedElement: null,
            mappedCol: null
        }
    });

    const setErrDialogFlags = () => {
        let localIsOcrImgExist, localIsExcelFileExist;
        if (xmlData[currentExcelIndex].image_link) {
            setisOcrImgExist(() => true);
            localIsOcrImgExist = true;
        } else {
            setisOcrImgExist(() => false);
            localIsOcrImgExist = false;
        }

        if (xmlData[currentExcelIndex].excel_data) {
            setisExcelFileExist(() => true);
            localIsExcelFileExist = true;
        } else {
            setisExcelFileExist(() => false);
            localIsExcelFileExist = false;
        }

        if (!localIsExcelFileExist) {
            if (localIsOcrImgExist) {
                let hyperLink = xmlData[currentExcelIndex]?.image_link;
                hyperLink += '?t=' + new Date().getTime();
                hyperLink = getProxyFileUrl(hyperLink);
                sethyperlink(hyperLink);
            } else {
                sethyperlink('');
            }
        }
    };
    useEffect(() => {
        if (currentExcelIndex !== 0) {
            goToNextOcr();
        }
    }, [currentExcelIndex]);
    useEffect(() => {
        setErrDialogFlags();
    }, []);
    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
    }, []);
    const { open, xmlData, onAccept, onReject, onClose } = props;
    const created = () => {
        loadExcel();
        disableSpecificShortcuts();
    };
    const handleResize = () => {
        cMenuRef.current?.close();
    };
    const loadExcel = () => {
        let hyperlink = xmlData[currentExcelIndex]?.image_link;
        hyperlink += '?t=' + new Date().getTime();
        hyperlink = getProxyFileUrl(hyperlink);
        sethyperlink(hyperlink);
        setisSpreadSheetInitialized(false);
        const url = xmlData[currentExcelIndex].excel_data;
        if (url) {
            const proxy_file = getProxyFileUrl(url);
            fetch(proxy_file) // fetch the remote url
                .then((response) => {
                    response.blob().then((fileBlob) => {
                        const file = new File([fileBlob], 'Sample.xlsx'); //convert the blob into file
                        spreadsheetRef.current?.open({ file: file }); // open the file into spreadsheet
                    });
                });
        }
    };
    const goToNextOcr = () => {
        setErrDialogFlags();

        if (isExcelFileExist) {
            reset();
            loadExcel();
        }
    };
    const reset = () => {
        ClickedHeaderRef.current = null;
    };
    const beforeSelect = (args: BeforeSelectEventArgs) => {
        const indexes = getRangeIndexes(args.range);
        if (indexes[0] === 0 && indexes[2] >= 99 && indexes[1] === indexes[3]) {
            args.cancel = true;
            const querySelector = document?.querySelectorAll('#headerContextMenu .e-header-cell');
            ClickedHeaderRef.current = querySelector[indexes[1]];
            const left = ClickedHeaderRef.current?.getBoundingClientRect().left + 10;
            const b: any = document?.querySelector('#headerContextMenu .e-column-header');
            const rect = b.getBoundingClientRect();
            const top: any = rect.height + rect.top;
            cMenuRef.current?.open(top, left);
        }
    };
    const setColWidth = (widths: any, colNo: any, width: any) => {
        if (widths) {
            setTimeout(() => {
                try {
                    spreadsheetRef.current?.setColWidth(width, colNo, 0);
                } catch {
                    /* empty */
                }
            });
        }
    };
    const highlightColumn = (colNo: number, getColIndex?: boolean, colName?: string, resetColumnState?: boolean) => {
        if (getColIndex) {
            if (colName) {
                getColumnIndex(colName).then((res) => {
                    colNo = res;
                    let rowCount = spreadsheetRef.current?.getActiveSheet().rows?.length ?? 1;
                    rowCount -= 1;
                    const range = getRangeAddress([0, colNo, rowCount, colNo]);
                    if (colName === HEADER_TYPE.IGNORE_COLUMN) {
                        spreadsheetRef.current?.cellFormat({ backgroundColor: '#FFFFFF' }, range);
                    }
                    if (resetColumnState) {
                        spreadsheetRef.current?.cellFormat({ backgroundColor: '#FFFFFF' }, range);
                    } else {
                        spreadsheetRef.current?.cellFormat({ backgroundColor: '#FFF7ED' }, range);
                    }
                });
            }
        } else {
            let rowCount = spreadsheetRef.current?.getActiveSheet().rows?.length ?? 1;
            rowCount -= 1;
            const range = getRangeAddress([0, colNo, rowCount, colNo]);
            spreadsheetRef.current?.cellFormat({ backgroundColor: '#FFF7ED' }, range);
        }
    };
    const getColumnIndex = async (columnName: string): Promise<number> => {
        const rowCount = await spreadsheetRef.current?.getActiveSheet().rowCount;
        columnName += '1:' + columnName + rowCount;
        const colIndex = await getRangeIndexes(columnName)[1];
        return colIndex;
    };
    const onExcelLoaded = async () => {
        if (isExcelFileExist) {
            await initializeServerMapping();
        }
        setisSpreadSheetInitialized(true);
    };
    const onContextMenuItemSelect = async (args: any) => {
        if (!isSpreadSheetInitialized) return;
        const val = args.item.text;
        let clickHeaderInnerhtml = ClickedHeaderRef.current?.innerHTML;
        clickHeaderInnerhtml = clickHeaderInnerhtml?.replace('&amp;', '&');
        clickHeaderInnerhtml = clickHeaderInnerhtml?.replace(' ▼', '');

        // Reset errorText displayed in modal footer if any
        if (errorState.isError) {
            setErrorState({ ...errorState, isError: false, errorText: '' });
        }

        if (val === HEADER_TYPE.IGNORE_COLUMN) {
            const header = ClickedHeaderRef.current?.innerHTML.replace(' ▼', '');
            if (header === HEADER_TYPE.TYPE) {
                changeColMappingToNull(MAPPINGS.TYPE);
            } else if (header === HEADER_TYPE.MANUFACTURE) {
                changeColMappingToNull(MAPPINGS.MANUFACTURER);
            } else if (header === HEADER_TYPE.MODEL_NUMBER) {
                changeColMappingToNull(MAPPINGS.MODEL_NUMBER);
            } else if (clickHeaderInnerhtml === HEADER_TYPE.MANUFACTURE_MODEL_NO) {
                changeColMappingToNull(MAPPINGS.MANUFACTURER); // CHANGE MFG MAPPING TO NULL
                changeColMappingToNull(MAPPINGS.MODEL_NUMBER); // CHANGE MODEL NO MAPPING TO NULL
            } else if (header === HEADER_TYPE.SUBMITTAL_NOTES) {
                changeColMappingToNull(MAPPINGS.SUBMITTAL_NOTES);
            } else if (header === HEADER_TYPE.QUANTITY) {
                changeColMappingToNull(MAPPINGS.QUANTITY);
            } else if (header === HEADER_TYPE.DESCRIPTION) {
                changeColMappingToNull(MAPPINGS.DESCRIPTION);
            } else if (header === HEADER_TYPE.QUOTE_NOTES) {
                changeColMappingToNull(MAPPINGS.QUOTE_NOTES);
            } else if (header === HEADER_TYPE.UNIT_COST) {
                changeColMappingToNull(MAPPINGS.UNIT_COST);
            } else if (header === HEADER_TYPE.LEAD_TIME) {
                changeColMappingToNull(MAPPINGS.LEAD_TIME);
            } else if (header === HEADER_TYPE.EXTENDED_COST) {
                changeColMappingToNull(MAPPINGS.EXTENDED_COST);
            }
            clickHeaderInnerhtml = '';
        }

        // Extracting the key values of the MAPPINGS obj
        const MAPPINGS_VALUES_ARRAY = Object.values(MAPPINGS);

        // replace arrow down icon (if any) to empty string
        // first checking if column already mapped to someone
        if (clickHeaderInnerhtml && MAPPINGS_VALUES_ARRAY.includes(clickHeaderInnerhtml)) {
            changeMappingOfMappedCol(val, MAPPINGS, clickHeaderInnerhtml); // change mapping if clicked column already mapped to someone
        } else {
            changeMappingOfNotMappedCol(val, MAPPINGS); // change mapping if clicked column is not mapped to anyone
        }
        // AUTOFIT THE COL
        autoFitCol(val, MAPPINGS, clickHeaderInnerhtml ? clickHeaderInnerhtml : '');
    };

    const changeColMappingToNull = (val: string) => {
        setColToItsPrevState(val);
        mappingTrackObj.current[val].mappedElement = null;
        mappingTrackObj.current[val].mappedCol = null;
    };
    // set a col to its original value
    const setColToItsPrevState = (val: any) => {
        if (mappingTrackObj.current[val].mappedElement) {
            mappingTrackObj.current[val].mappedElement.innerHTML = mappingTrackObj.current[val].mappedCol;
            mappingTrackObj.current[val].mappedElement.classList.remove('highlight-header');
            highlightColumn(0, true, mappingTrackObj.current[val].mappedCol, true);
        }
    };
    // CHANGE MAPPING CLICKED COL WHICH IS ALREADY MAPPED TO SOMEONE
    const changeMappingOfMappedCol = (val: string, MAPPINGS: IMappings, selectedHeaderText: string) => {
        if (selectedHeaderText === val)
            // if clicked col is already mapped to context item value then do nothing
            return;

        if (selectedHeaderText === MAPPINGS.TYPE) {
            // IF CLICKED COL IS MAPPED TO TYPE ALREADY
            changeMappingOfColMappedForTypeAlready(val, MAPPINGS);
        } else if (
            [MAPPINGS.SUBMITTAL_NOTES, MAPPINGS.QUANTITY, MAPPINGS.DESCRIPTION, MAPPINGS.QUOTE_NOTES, MAPPINGS.UNIT_COST, MAPPINGS.LEAD_TIME, MAPPINGS.EXTENDED_COST].includes(selectedHeaderText)
        ) {
            // IF CLICKED COLUMN IS = Submittal Notes/ Quantity/ Description/ Quote Notes/ Unit Cost/ Lead Time/ Extended Cost
            changeMappingOfColsMappedAlreadyHandler(val, selectedHeaderText);
        } else if (selectedHeaderText === MAPPINGS.MANUFACTURER_MODEL_NO) {
            // IF CLICKED COL IS MAPPED TO MFG AND MODEL NO ALREADY
            changeMappingOfColMappedForMfgAndModelNoJointlyAlready(val, MAPPINGS);
        } else {
            changeMappingOfColMappedForMfgORModelNoAlready(val, MAPPINGS, selectedHeaderText);
        }
    };

    // APPLICABLE FOR: TYPE
    const changeMappingOfColMappedForTypeAlready = (val: string, MAPPINGS: IMappings) => {
        changeColMappingToNull(MAPPINGS.TYPE); // CHANGE TYPE MAPPING TO NULL

        if (val === MAPPINGS.MANUFACTURER_MODEL_NO) {
            // IF CONTEXT ITEM VALUE IS MFG AND MODEL NO JOINTLY
            changeJointMapping(val); // CHANGE JOINT MAPPING FOR BOTH
        } else {
            // ELSE IF CONTEXT ITEM VALUE IS MFG/MODEL
            deconstructJointMapping(val, MAPPINGS);
        }
    };

    // APPLICABLE FOR: 'Submittal Notes', 'Quantity', 'Description', 'Quote Notes', 'Unit Cost', 'Lead Time', 'Extended Cost'
    const changeMappingOfColsMappedAlreadyHandler = (val: string, selectedHeaderText: string) => {
        changeColMappingToNull(selectedHeaderText);
        changeMapping(val); // UPDATE CLICKED MAPPING FOR CONTEXT ITEM VALUE
    };

    // change mapping of clicked context item
    const changeMapping = (val: string) => {
        setColToItsPrevState(val);
        setMappingTrackObj(val);
        if (ClickedHeaderRef.current) {
            ClickedHeaderRef.current.innerHTML = val + ' &#x25BC;';
        }
    };
    // CHANGE MAPPING OF CLICKED COL WHICH IS NOT MAPPED TO ANYONE YET
    const changeMappingOfNotMappedCol = (val: string, MAPPINGS: IMappings) => {
        const mappingsValuesArray = Object.values(MAPPINGS);
        if (mappingsValuesArray.includes(val)) {
            // if context item value is type

            if (
                [MAPPINGS.TYPE, MAPPINGS.SUBMITTAL_NOTES, MAPPINGS.QUANTITY, MAPPINGS.DESCRIPTION, MAPPINGS.QUOTE_NOTES, MAPPINGS.UNIT_COST, MAPPINGS.LEAD_TIME, MAPPINGS.EXTENDED_COST].includes(val)
            ) {
                changeMapping(val);
            } else if ([MAPPINGS.MANUFACTURER, MAPPINGS.MODEL_NUMBER].includes(val)) {
                // if context item is mfg/modelno
                deconstructJointMapping(val, MAPPINGS);
            } else {
                // if context item value is mfg and model no
                changeJointMapping(val);
            }
        }
    };

    const changeMappingOfColMappedForMfgAndModelNoJointlyAlready = (val: string, MAPPINGS: IMappings) => {
        if (val === MAPPINGS.TYPE) {
            // IF CONTEXT ITEM VALUE IS TYPE
            changeColMappingToNull(MAPPINGS.MANUFACTURER); // CHANGE MFG MAPPING TO NULL
            changeColMappingToNull(MAPPINGS.MODEL_NUMBER); // CHANGE MODEL NO MAPPING TO NULL
        } else {
            // IF CONTEXT ITEM VALUE IS MFG OR MODEL NO
            if (val === MAPPINGS.MANUFACTURER) {
                // IF CONTEXT ITEM VALUE IS MFG
                changeColMappingToNull(MAPPINGS.MODEL_NUMBER); // CHANGE MODEL NO MAPPING TO NULL
            } else {
                changeColMappingToNull(MAPPINGS.MANUFACTURER); // ELSE CHANGE MFG MAPPING TO NULL
            }
        }

        changeMapping(val); // UPDATE CLICKED COL FOR CONTEXT ITEM VALUE
    };

    const changeMappingOfColMappedForMfgORModelNoAlready = (val: string, MAPPINGS: IMappings, selectedHeaderText: string) => {
        if (val === MAPPINGS.MANUFACTURER_MODEL_NO) {
            // IF CONTEXT ITEM VALUE IS MFG AND MODEL NO JOINTLY
            changeJointMapping(val);
        } else {
            if (selectedHeaderText === MAPPINGS.MANUFACTURER) {
                // IF CLICKED HEADER IS MAPPED TO MFG
                changeColMappingToNull(MAPPINGS.MANUFACTURER); // SET MFG MAPPING TO NULL
            } else {
                changeColMappingToNull(MAPPINGS.MODEL_NUMBER); // ELSE SET MODEL NO MAPPING TO NULL
            }
            changeMapping(val); // UPDATE CLICKED MAPPING FOR CONTEXT ITEM VALUE
        }
    };

    // CHANGE MAPPING OF MFG AND MODEL NO
    const deconstructJointMapping = (val: string, MAPPINGS: IMappings) => {
        // check if mfg and model no are jointly mapped. if yes then break them
        if (!!mappingTrackObj.current[val].mappedElement && mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol == mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol) {
            if (val === MAPPINGS.MANUFACTURER) {
                mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedElement.innerHTML = MAPPINGS.MODEL_NUMBER + ' &#x25BC;';
            } else {
                mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedElement.innerHTML = MAPPINGS.MANUFACTURER + ' &#x25BC;';
            }
        } else {
            // if mfg and model are not jointly mapped then set clicked context item's mapped col to its original value
            setColToItsPrevState(val);
        }

        // set mapping of cliced context item
        setMappingTrackObj(val);

        if (ClickedHeaderRef.current) {
            ClickedHeaderRef.current.innerHTML = val + ' &#x25BC;';
        }
    };

    const autoFitCol = (val: string, MAPPINGS: IMappings, selectedHeaherText: string) => {
        const widths = spreadsheetRef.current?.getActiveSheet().columns;
        let col = selectedHeaherText;
        const mappingsValuesArray = Object.values(MAPPINGS);
        if (mappingsValuesArray.includes(col)) {
            if (col === MAPPINGS.MANUFACTURER_MODEL_NO) {
                col = mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol;
            } else {
                col = mappingTrackObj.current[col].mappedCol;
            }
        }

        col += '1:' + col + spreadsheetRef.current?.getActiveSheet().rowCount;

        const colNo = getRangeIndexes(col)[1];

        const width = val === 'Manufacturer & Model Number' ? 340 : 150;

        if (widths) {
            spreadsheetRef.current?.setColWidth(width, colNo, 0);
        }
    };
    // set mapping of manufacturer and model no to one col
    const changeJointMapping = (val: string) => {
        const joints = [MAPPINGS.MANUFACTURER, MAPPINGS.MODEL_NUMBER];
        setColToItsPrevState(joints[0]);
        setColToItsPrevState(joints[1]);

        setMappingTrackObj(joints[0]);
        setMappingTrackObj(joints[1]);

        if (ClickedHeaderRef.current) {
            ClickedHeaderRef.current.innerHTML = val + ' &#x25BC;';
        }
    };
    // change value obj of an element of mappingTrackObj.current
    const setMappingTrackObj = (val: string) => {
        mappingTrackObj.current[val].mappedElement = ClickedHeaderRef.current;
        mappingTrackObj.current[val].mappedCol = ClickedHeaderRef.current?.innerHTML;
        ClickedHeaderRef.current?.classList.add('highlight-header');
        highlightColumn(0, true, mappingTrackObj.current[val].mappedCol);
    };

    const disableSpecificShortcuts = () => {
        spreadsheetRef.current?.element.addEventListener(
            'keydown',
            (e: any) => {
                const ctrlKeysArr = ['\\', '1', '5', '8', '9', 'a', 'b', 'd', 'f3', 'i', 'k', 'o', 'r', 'u'];
                const altKeyArr = ['f1', 't'];
                const shiftKeyArr = ['f2', 'f11'];
                const ctrlShiftKeysArr = ['&', '_', '~', '$', '%', '^', '#', '@', '!', '8', '9', 'd', 'i', 'f2', 'o', 'i'];

                const key = e.key.toLowerCase();

                if (
                    (e.ctrlKey && e.shiftKey && ctrlShiftKeysArr.includes(key)) ||
                    (e.ctrlKey && ctrlKeysArr.includes(key)) ||
                    (e.shiftKey && shiftKeyArr.includes(key)) ||
                    (e.altKey && altKeyArr.includes(key))
                ) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                } else {
                    return;
                }
            },
            true
        );
    };

    const initializeServerMapping = async () => {
        const colNames = [
            'Ignore Column',
            MAPPINGS.TYPE,
            MAPPINGS.MANUFACTURER,
            MAPPINGS.MODEL_NUMBER,
            MAPPINGS.SUBMITTAL_NOTES,
            MAPPINGS.QUANTITY,
            MAPPINGS.DESCRIPTION,
            MAPPINGS.QUOTE_NOTES,
            MAPPINGS.UNIT_COST,
            MAPPINGS.LEAD_TIME,
            MAPPINGS.EXTENDED_COST
        ];
        colNames.forEach((item: string) => {
            mappingTrackObj.current[item].mappedCol = null;
        });

        const widths = spreadsheetRef.current?.getActiveSheet().columns;

        const mappings = xmlData[currentExcelIndex].column_tags;
        mappings.myCol = 'Ignore Column';
        let type = mappings['type'].split(':')[0].replace(/[0-9]/g, '');
        let mfg = mappings['mfg'].split(':')[0].replace(/[0-9]/g, '');
        let part = mappings['part'].split(':')[0].replace(/[0-9]/g, '');
        let igCol = mappings['myCol'];
        let qty = mappings['quantity'].split(':')[0].replace(/[0-9]/g, '');
        let description = mappings['description'].split(':')[0].replace(/[0-9]/g, '');
        let extendedCost = mappings['extended_cost'].split(':')[0].replace(/[0-9]/g, '');
        let unitCost = mappings['unit_cost'].split(':')[0].replace(/[0-9]/g, '');
        mappingTrackObj.current[MAPPINGS.TYPE].mappedCol = type;
        mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol = mfg;
        mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol = part;
        mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedCol = description;
        mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol = qty;
        mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol = extendedCost;
        mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol = unitCost;
        mappingTrackObj.current['Ignore Column'].mappedCol = igCol;
        const rowCount = await spreadsheetRef.current?.getActiveSheet().rowCount;
        type += '1:' + type + rowCount;
        mfg += '1:' + mfg + rowCount;
        part += '1:' + part + rowCount;
        igCol += '1:' + igCol + 1;
        qty += '1:' + qty + rowCount;
        description += '1:' + description + rowCount;
        extendedCost += '1:' + extendedCost + rowCount;
        unitCost += '1:' + unitCost + rowCount;
        type = await getRangeIndexes(type)[1];
        mfg = await getRangeIndexes(mfg)[1];
        part = await getRangeIndexes(part)[1];
        qty = await getRangeIndexes(qty)[1];
        description = await getRangeIndexes(description)[1];
        extendedCost = await getRangeIndexes(extendedCost)[1];
        unitCost = await getRangeIndexes(unitCost)[1];
        mappingTrackObj.current[MAPPINGS.TYPE].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[type];
        mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[mfg];
        mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[part];
        mappingTrackObj.current[MAPPINGS.QUANTITY].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[qty];
        mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[description];
        mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[extendedCost];
        mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[unitCost];

        mappingTrackObj.current['Ignore Column'].mappedElement = spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[igCol];

        // Highlight Type header
        if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[type]) {
            spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[type].innerHTML = 'Type &#x25BC;';
            spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[type].classList.add('highlight-header');
        }
        // Highlight quantity header
        if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[qty]) {
            spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[qty].innerHTML = 'Quantity &#x25BC;';
            spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[qty].classList.add('highlight-header');
        }
        // Highlight the description header
        if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[description]) {
            spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[description].innerHTML = 'Description &#x25BC;';
            spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[description].classList.add('highlight-header');
        }

        // Highlight the Extended cost header
        if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[extendedCost]) {
            spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[extendedCost].innerHTML = 'Extended Cost &#x25BC;';
            spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[extendedCost].classList.add('highlight-header');
        }

        // Highlight the Unit Cost header
        if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[unitCost]) {
            spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[unitCost].innerHTML = 'Unit Cost &#x25BC;';
            spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[unitCost].classList.add('highlight-header');
        }
        // highlight type column and set the width
        setColWidth(widths, type, 150);
        highlightColumn(type);

        // highlight quantity column and set the width
        setColWidth(widths, qty, 150);
        highlightColumn(qty);

        // highlight description column and set the width

        setColWidth(widths, description, 150);
        highlightColumn(description);

        // highlight Extended Cost column and set the width

        setColWidth(widths, extendedCost, 150);
        highlightColumn(extendedCost);

        // highlight Unit Cost column and set the width

        setColWidth(widths, unitCost, 150);
        highlightColumn(unitCost);

        if (mfg === part) {
            if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[mfg]) {
                spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[mfg].innerHTML = 'Manufacturer & Model Number &#x25BC;';
                setColWidth(widths, mfg, 340);
                highlightColumn(mfg);
                spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[mfg].classList.add('highlight-header');
            }
        } else {
            if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[mfg]) {
                spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[mfg].innerHTML = 'Manufacturer &#x25BC;';
                setColWidth(widths, mfg, 150);
                highlightColumn(mfg);
                spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[mfg].classList.add('highlight-header');
            }
            if (spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[part]) {
                spreadsheetRef.current!.getColHeaderTable().children[1].children[0].children[part].innerHTML = 'Model Number &#x25BC;';
                setColWidth(widths, part, 150);
                highlightColumn(part);
                spreadsheetRef.current?.getColHeaderTable().children[1].children[0].children[part].classList.add('highlight-header');
            }
        }

        if (mappings['type'] === mappings['mfg'] || mappings['type'] === mappings['part']) {
            mappingTrackObj.current['Type'] = {
                mappedElement: null,
                mappedCol: null
            };
        }
        return Promise.resolve('success');
    };

    const getRangeValue = (key: string, rowCount: number | undefined) => {
        // tabRange is actually notesRange or quantityRange etc etc....
        let tabRange = mappingTrackObj.current[key].mappedCol;
        if (mappingTrackObj.current[key].mappedCol !== null) {
            tabRange += '1:' + tabRange + rowCount;
        } else if (mappingTrackObj.current[key].mappedCol === null) {
            tabRange = '';
        }
        return tabRange;
    };

    const getColumnFinalValue = (val: string) => {
        if ((val || '').startsWith('NA')) return '';
        return val;
    };

    const setFormData = (newExcelFile: any, flag?: boolean) => {
        formDataConfirmColumnRef.current.append(`file_${currentExcelIndex + 1}`, newExcelFile);
        formDataConfirmColumnRef.current.append(`excel_id_${currentExcelIndex + 1}`, xmlData[currentExcelIndex]?.id);

        if (!flag) {
            const rowCount = spreadsheetRef.current?.getActiveSheet().rows?.length;
            let typeRange = mappingTrackObj.current[MAPPINGS.TYPE].mappedCol;
            if (typeRange) {
                typeRange += '1:' + typeRange + rowCount;
            } else {
                typeRange = '';
            }
            let mfgRange = mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol;
            if (mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol !== null) {
                mfgRange += '1:' + mfgRange + rowCount;
            } else if (mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol === null) {
                mfgRange = '';
            }
            let modelNoRange = mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol;
            if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol !== null) {
                modelNoRange += '1:' + modelNoRange + rowCount;
            } else if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol === null) {
                modelNoRange = '';
            }

            // Submittal notes
            const notesRange = getRangeValue(MAPPINGS.SUBMITTAL_NOTES, rowCount);

            // Description
            const descriptionRange = getRangeValue(MAPPINGS.DESCRIPTION, rowCount);

            // Adding quantity
            const quantityRange = getRangeValue(MAPPINGS.QUANTITY, rowCount);

            // Quote notes
            const quoteNotesRange = getRangeValue(MAPPINGS.QUOTE_NOTES, rowCount);

            // Unit Cost
            const unitCostRange = getRangeValue(MAPPINGS.UNIT_COST, rowCount);

            // Lead Time
            const leadTimeRange = getRangeValue(MAPPINGS.LEAD_TIME, rowCount);

            // Extended Cost
            const extendedCostRange = getRangeValue(MAPPINGS.EXTENDED_COST, rowCount);

            formDataConfirmColumnRef.current.append(`type_${currentExcelIndex + 1}`, getColumnFinalValue(typeRange));
            formDataConfirmColumnRef.current.append(`mfg_${currentExcelIndex + 1}`, getColumnFinalValue(mfgRange));
            formDataConfirmColumnRef.current.append(`part_${currentExcelIndex + 1}`, getColumnFinalValue(modelNoRange));
            formDataConfirmColumnRef.current.append(`submittal_notes_${currentExcelIndex + 1}`, getColumnFinalValue(notesRange));
            formDataConfirmColumnRef.current.append(`description_${currentExcelIndex + 1}`, getColumnFinalValue(descriptionRange));
            formDataConfirmColumnRef.current.append(`qty_${currentExcelIndex + 1}`, getColumnFinalValue(quantityRange));
            formDataConfirmColumnRef.current.append(`quote_notes_${currentExcelIndex + 1}`, getColumnFinalValue(quoteNotesRange));
            formDataConfirmColumnRef.current.append(`unit_cost_${currentExcelIndex + 1}`, getColumnFinalValue(unitCostRange));
            formDataConfirmColumnRef.current.append(`lead_time_${currentExcelIndex + 1}`, getColumnFinalValue(leadTimeRange));
            formDataConfirmColumnRef.current.append(`extended_cost_${currentExcelIndex + 1}`, getColumnFinalValue(extendedCostRange));
        } else {
            if (mappingTrackObj.current[MAPPINGS.TYPE].mappedCol === null) {
                formDataConfirmColumnHandler(MAPPINGS.TYPE);

                if (mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.MANUFACTURER);
                } else if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.MODEL_NUMBER);
                }
            } else if (mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol === null) {
                formDataConfirmColumnHandler(MAPPINGS.MANUFACTURER);

                if (mappingTrackObj.current[MAPPINGS.TYPE].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.TYPE);
                } else if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.MODEL_NUMBER);
                }
            } else if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol === null) {
                formDataConfirmColumnHandler(MAPPINGS.MODEL_NUMBER);

                if (mappingTrackObj.current[MAPPINGS.TYPE].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.TYPE);
                } else if (mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.MANUFACTURER);
                } else if (mappingTrackObj.current[MAPPINGS.SUBMITTAL_NOTES].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.SUBMITTAL_NOTES);
                } else if (mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.QUANTITY);
                } else if (mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.MODEL_NUMBER);
                } else if (mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.DESCRIPTION);
                } else if (mappingTrackObj.current[MAPPINGS.QUOTE_NOTES].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.QUOTE_NOTES);
                } else if (mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.UNIT_COST);
                } else if (mappingTrackObj.current[MAPPINGS.LEAD_TIME].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.LEAD_TIME);
                } else if (mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol === null) {
                    formDataConfirmColumnHandler(MAPPINGS.EXTENDED_COST);
                }
            }
        }
    };

    const formDataConfirmColumnHandler = (colName: string) => {
        formDataConfirmColumnRef.current.append(`type_${currentExcelIndex + 1}`, colName === MAPPINGS.TYPE ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.TYPE].mappedCol));
        formDataConfirmColumnRef.current.append(`mfg_${currentExcelIndex + 1}`, colName === MAPPINGS.MANUFACTURER ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol));
        formDataConfirmColumnRef.current.append(
            `part_${currentExcelIndex + 1}`,
            colName === MAPPINGS.MODEL_NUMBER ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol)
        );
        formDataConfirmColumnRef.current.append(
            `submittal_notes_${currentExcelIndex + 1}`,
            colName === MAPPINGS.SUBMITTAL_NOTES ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.SUBMITTAL_NOTES].mappedCol)
        );
        formDataConfirmColumnRef.current.append(`qty_${currentExcelIndex + 1}`, colName === MAPPINGS.QUANTITY ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol));
        formDataConfirmColumnRef.current.append(
            `description_${currentExcelIndex + 1}`,
            colName === MAPPINGS.DESCRIPTION ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedCol)
        );
        formDataConfirmColumnRef.current.append(
            `quote_notes_${currentExcelIndex + 1}`,
            colName === MAPPINGS.QUOTE_NOTES ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.QUOTE_NOTES].mappedCol)
        );
        formDataConfirmColumnRef.current.append(`unit_cost_${currentExcelIndex + 1}`, colName === MAPPINGS.UNIT_COST ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol));
        formDataConfirmColumnRef.current.append(`lead_time_${currentExcelIndex + 1}`, colName === MAPPINGS.LEAD_TIME ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.LEAD_TIME].mappedCol));
        formDataConfirmColumnRef.current.append(
            `extended_cost_${currentExcelIndex + 1}`,
            colName === MAPPINGS.EXTENDED_COST ? '' : getColumnFinalValue(mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol)
        );
    };

    // CREATE EXCEL FILE
    const getExcelFile = async () => {
        const [filename] = xmlData[currentExcelIndex].excel_data.split('/').slice(-1);
        const excel = [];
        let totalRows = spreadsheetRef.current?.getActiveSheet().rows?.length;

        const typeColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.TYPE].mappedCol);
        const mfgColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol);
        const modelNoColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol);
        const notesColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.SUBMITTAL_NOTES].mappedCol);
        const descriptionColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedCol);
        const quantityColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol);
        const quoteNotesColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.QUOTE_NOTES].mappedCol);
        const unitCostColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol);
        const leadTimeColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.LEAD_TIME].mappedCol);
        const extendedCostColIndex = await getColumnIndex(mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol);

        const typeCol = mappingTrackObj.current[MAPPINGS.TYPE].mappedCol;
        const manufacturerCol = mappingTrackObj.current[MAPPINGS.MANUFACTURER].mappedCol;
        const modelCol = mappingTrackObj.current[MAPPINGS.MODEL_NUMBER].mappedCol;
        const notesCol = mappingTrackObj.current[MAPPINGS.SUBMITTAL_NOTES].mappedCol;
        const descriptionCol = mappingTrackObj.current[MAPPINGS.DESCRIPTION].mappedCol;
        const quantityCol = mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol;
        const quoteNotesCol = mappingTrackObj.current[MAPPINGS.QUOTE_NOTES].mappedCol;
        const unitCostCol = mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol;
        const leadTimeCol = mappingTrackObj.current[MAPPINGS.LEAD_TIME].mappedCol;
        const extendedCostCol = mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol;

        let totalCols = 0;
        const data = [
            { value: typeCol, index: typeColIndex },
            { value: manufacturerCol, index: mfgColIndex },
            { value: modelCol, index: modelNoColIndex },
            { value: notesCol, index: notesColIndex },
            { value: quantityCol, index: quantityColIndex },
            { value: descriptionCol, index: descriptionColIndex },
            { value: quoteNotesCol, index: quoteNotesColIndex },
            { value: unitCostCol, index: unitCostColIndex },
            { value: leadTimeCol, index: leadTimeColIndex },
            { value: extendedCostCol, index: extendedCostColIndex }
        ];
        totalCols = Math.max(...data.filter((item) => Boolean(item.value)).map((item) => item.index));
        totalCols += 1;

        totalRows = totalRows ? totalRows : 1;
        totalCols = totalCols ? totalCols : 1;

        for (let i = 0; i < totalRows; i++) {
            const row = [];
            for (let j = 0; j < totalCols; j++) {
                row.push({ value: '', type: 'string' });
            }
            excel.push(row);
        }

        const rows = spreadsheetRef.current?.getActiveSheet().rows;

        for (let i = 0; i < totalRows; i++) {
            for (let j = 0; j < totalCols; j++) {
                const cell = rows?.[i]?.cells?.[j];
                if (!cell?.value) {
                    continue;
                } else {
                    if (!cell?.colSpan && !cell?.rowSpan) {
                        if (excel?.[i]?.[j]?.value !== undefined) {
                            excel[i][j].value = cell.value;
                        }
                    } else {
                        const colSpan = cell?.colSpan ? cell.colSpan : 1;
                        const rowSpan = cell?.rowSpan ? cell.rowSpan : 1;

                        for (let k = 0; k < rowSpan; k++) {
                            for (let l = 0; l < colSpan; l++) {
                                if (j + l < totalCols) {
                                    if (excel?.[i + k]?.[j + l]?.value !== undefined) {
                                        excel[i + k][j + l].value = cell.value;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const zipExcelBlob = await zipcelx({
            filename: filename,
            sheet: { data: excel }
        });

        const file = new File([zipExcelBlob], filename, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        return file;
    };

    const extendedCostColumnValidationHandler = () => {
        let validationPassFlag = true;
        const quantityCol = mappingTrackObj.current[MAPPINGS.QUANTITY].mappedCol;
        const unitCostCol = mappingTrackObj.current[MAPPINGS.UNIT_COST].mappedCol;
        const extendedCostCol = mappingTrackObj.current[MAPPINGS.EXTENDED_COST].mappedCol;

        // If both Extended Cost and Unit Cost is selected
        if (extendedCostCol && unitCostCol && unitCostCol !== 'NA' && extendedCostCol !== 'NA') {
            setErrorState({ ...errorState, isError: true, errorText: 'Only one cost entry type can be imported. Please select either Unit Cost, or Extended Cost and Quantity.' });
            validationPassFlag = false;
            return validationPassFlag;
        }

        // If Extended Cost column is selected
        if (extendedCostCol && extendedCostCol !== 'NA') {
            // If Quantity is not selected
            if (!quantityCol || quantityCol === 'NA') {
                setErrorState({ ...errorState, isError: true, errorText: 'Please designate a column as Quantity, or remove the Extended Cost column.' });
                validationPassFlag = false;
                return validationPassFlag;
            }
        }

        return validationPassFlag;
    };

    //function to be called on confirm or close popup
    const logColumnMapping = (finalData?: FormData) => {
        pushEvent({
            insertMutation: insertMutation({ initialSelection: xmlData, finalSelection: finalData }),
            updateMutation: null,
            responseAttribute: 'bom_column_mapping_tracking'
        });
    };

    const ocrConfirm = async () => {
        if (data?.data?.type === SubmittalBomType) {
            formDataConfirmColumnRef.current.append('total_selections', xmlData.length);
            await confirmContinuePackage({
                data: formDataConfirmColumnRef.current,
                submittalId: data?.data.bom,
                projectScheduleId: data?.data?.id,
                bomId: Number(bomId)
            });
            // to log the column mapping to event service
            logColumnMapping(formDataConfirmColumnRef?.current);
            onAccept();
        } else {
            formDataConfirmColumnRef.current.append('total_selections', `${xmlData.length}`);
            const resp = await confirmContinue({ bomId: Number(bomId), data: formDataConfirmColumnRef.current, submittalId: data?.data.bom || 0, projectScheduleId: data?.data?.id || 0 });
            if (userPreferences?.data.autoselection) {
                const uniqueBodsSet = new Set<number>();

                resp.data.forEach((item: any) => {
                    item.bods.forEach((bodid: any) => {
                        uniqueBodsSet.add(bodid);
                    });
                });

                const uniqueBODsArray = Array.from(uniqueBodsSet);

                const data = {
                    bomId: Number(bomId),
                    input: {
                        bod_id: uniqueBODsArray,
                        user_preference: userPreferences?.data.autoselection_user_group
                    }
                };

                await autoSearchDatasheet(data);
                await autoSearchOM(data);
            }
            // to log the column mapping to event service
            logColumnMapping(formDataConfirmColumnRef?.current);
            onAccept();
        }
    };
    const onConfirmContinue = async () => {
        const validationPass = extendedCostColumnValidationHandler();

        if (validationPass) {
            const newExcelFile: File = await getExcelFile();
            setFormData(newExcelFile);
            if (xmlData.length !== currentExcelIndex + 1) {
                setCurrentExcelIndex((prevValue) => {
                    return prevValue + 1;
                });
            } else {
                ocrConfirm();
            }
        }
    };
    const onConfirmOcrWithError = () => {
        const flag: any = true;
        // hit api here
        formDataConfirmColumnRef.current.append(`error_${currentExcelIndex + 1}`, flag);
        formDataConfirmColumnRef.current.append(`excel_id_${currentExcelIndex + 1}`, xmlData[currentExcelIndex]?.id);
        if (xmlData.length !== currentExcelIndex + 1) {
            setCurrentExcelIndex((prevValue) => {
                return prevValue + 1;
            });
        } else {
            ocrConfirm();
        }
    };

    const handleClosePopup = () => {
        // to log the column mapping to event service
        logColumnMapping();
        onClose();
    };

    return (
        <Modal
            open={open}
            header={
                <ModalHeader
                    title="Confirm Columns"
                    onClose={handleClosePopup}
                    children={
                        <Box color="neutral.dark" mb={4} fontSize={12}>
                            Confirm that <b>Type</b>, <b>Manufacturer</b>, <b>Model Number</b>, <b>Quantity</b> and <b>Description</b> are mapped to the correct column content, and adjust or verify
                            data as necessary.
                        </Box>
                    }
                />
            }
            footer={
                <ModalFooter
                    cancelButtonLabel={xmlData.length === 1 ? 'Edit Selection' : 'Edit Selection(s)'}
                    continueButtonLabel={
                        xmlData.length !== currentExcelIndex + 1 ? `Next Selection ( ${currentExcelIndex + 1} of ${xmlData.length} )` : `Confirm All (${currentExcelIndex + 1} of ${xmlData.length})`
                    }
                    onAccept={!isExcelFileExist && isOcrImgExist ? onConfirmOcrWithError : onConfirmContinue}
                    onReject={onReject}
                    isLoading={
                        data?.data?.type === SubmittalBomType
                            ? isconfirmContinuePackageLoading
                            : userPreferences?.data.autoselection
                            ? isconfirmContinue || isAutoSearchDatasheetLoading || isAutoSearchOMLoading
                            : isconfirmContinue
                    }
                    helperText={
                        <>
                            {errorState.isError ? (
                                <Box display="flex" maxWidth={'700px'}>
                                    <Box sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}>
                                        <Button color="secondary" variant="outlined" onClick={() => window.open(hyperlink, '_blank')}>
                                            View Original Selection
                                        </Button>
                                    </Box>

                                    <BodySmall limit={false} mx={2} lines={2} color={'error.main'}>
                                        {errorState.errorText}
                                    </BodySmall>
                                </Box>
                            ) : (
                                <Button color="secondary" variant="outlined" onClick={() => window.open(hyperlink, '_blank')}>
                                    View Original Selection
                                </Button>
                            )}
                        </>
                    }
                />
            }
        >
            {xmlData?.length ? (
                xmlData[currentExcelIndex].excel_data ? (
                    <Box width="978px" position="relative" id="headerContextMenu">
                        {(data?.data?.type === SubmittalBomType ? isconfirmContinuePackageLoading : isconfirmContinue) && (
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                        )}
                        <Box
                            sx={{
                                '.e-input-group.e-control-wrapper': {
                                    display: 'none'
                                },
                                '.e-spreadsheet .e-column-header .e-header-cell': {
                                    position: 'relative'
                                },
                                '.e-spreadsheet .e-column-header .e-header-cell.highlight-header ': {
                                    background: '#ffedd5',
                                    color: '#f78500'
                                },
                                '.e-spreadsheet .e-column-header .e-header-cell:not(.highlight-header)::before': {
                                    content: '"Select ▼"',
                                    position: 'absolute',
                                    left: '0',
                                    top: '0',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: '999',
                                    bgcolor: '#f5f5f5',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            <SpreadsheetComponent
                                ref={spreadsheetRef}
                                allowOpen={true}
                                openUrl={environment.W}
                                created={created}
                                openComplete={onExcelLoaded}
                                allowUndoRedo={false}
                                showRibbon={false}
                                showSheetTabs={false}
                                showFormulaBar={false}
                                selectionSettings={{ mode: 'Single' }}
                                allowChart={false}
                                allowSorting={false}
                                allowDataValidation={false}
                                allowFiltering={false}
                                allowFindAndReplace={false}
                                scrollSettings={{ isFinite: true, enableVirtualization: false }}
                                allowHyperlink={false}
                                allowImage={false}
                                enableContextMenu={false}
                                beforeSelect={beforeSelect.bind(this)}
                            ></SpreadsheetComponent>
                            <ContextMenuComponent id="contextmenu" ref={cMenuRef} items={MENU_ITEMS} select={onContextMenuItemSelect.bind(this)} />
                        </Box>
                    </Box>
                ) : (
                    <Box position="relative">
                        {(data?.data?.type === SubmittalBomType ? isconfirmContinuePackageLoading : isconfirmContinue) && (
                            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                        )}
                        {isOcrImgExist ? (
                            <Box p={4} display="flex" justifyContent="space-between" flexDirection="column" width="100%" gap={2} alignItems="center">
                                <Box fontWeight={600}>We couldn’t read this selection...</Box>
                                <br />
                                <Button variant="contained" onClick={() => window.open(hyperlink, '_blank')}>
                                    View Your Selection
                                </Button>
                                <br />
                                <BodySmall> If this selection was made by mistake, ignore this message and click ‘Confirm’.</BodySmall>
                                <br />
                                <BodySmall
                                    sx={{
                                        div: {
                                            WebkitLineClamp: 'unset !important'
                                        }
                                    }}
                                >
                                    If this selection contains products, click ‘import more products’ on the next
                                    <br />
                                    screen, upload your file, and draw a selection around the missing products.
                                </BodySmall>
                                <br />
                                <BodySmall>If the issue persists, message us in the chat, or email us at support@parspec.io</BodySmall>
                            </Box>
                        ) : (
                            <Box p={8} display="flex" justifyContent="space-between" flexDirection="column" width="100%" gap={2} alignItems="center">
                                <Box>One or more selections are empty.</Box>
                                <Box>
                                    <img src="assets/images/view-files/empty-selection-err.svg" alt="" />
                                </Box>
                                <Box>
                                    If you meant to select additional products, select ‘Import
                                    <br />
                                    More Products’ after closing this message.
                                </Box>
                            </Box>
                        )}
                    </Box>
                )
            ) : (
                <></>
            )}
        </Modal>
    );
};

export default ConfirmColumn;
