import { useState, useEffect } from 'react';

import { AlertBanner, BodySmall, Box, Link } from '@parspec/pixel';

import { useSubscriptionInfo } from '../Settings/PlanAndPayment/queries';

interface FewCreditsLeftBannerProps {
    onClose: () => void;
}

const FewCreditsLeftBanner = (props: FewCreditsLeftBannerProps) => {
    const [daysLeftTillNextBilling, setDaysLeftTillNextBilling] = useState(0);
    const { data: paymentDetail } = useSubscriptionInfo();
    const { onClose } = props;

    useEffect(() => {
        if (paymentDetail?.data) {
            // Current date
            const currentDate = new Date();

            // Next billing date (replace this with your actual date)
            const nextBillingDate = new Date(paymentDetail.data.next_billing_data);

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = nextBillingDate.getTime() - currentDate.getTime();

            // Convert milliseconds to days
            const daysLeft = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            setDaysLeftTillNextBilling(() => daysLeft);
        }
    }, [paymentDetail?.data]);
    return (
        <Box>
            <AlertBanner
                text={`90% of credits used, with ${daysLeftTillNextBilling} days remaining in you billing period (${paymentDetail?.data.submittal_credit_left} credit(s) remaining). Your account will be upgraded to the next tier if your credit allowance is exceeded.`}
                variant="filled"
                severity="warning"
                onClose={onClose}
            >
                <Link
                    href="https://knowledgebase.parspec.io/knowledge/parspec-pricing-guide"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        underline: 'none',
                        textDecoration: 'none',
                        '&:hover': {
                            color: 'warning.contrastText'
                        }
                    }}
                >
                    <Box display={'flex'} alignItems={'flex-end'}>
                        <BodySmall limit={false} color={'warning.contrastText'} mr={1}>
                            Learn More
                        </BodySmall>
                    </Box>
                </Link>
            </AlertBanner>
        </Box>
    );
};

export default FewCreditsLeftBanner;
