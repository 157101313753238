import { ReactNode, useEffect, useMemo, useState, useCallback, forwardRef, useRef, useImperativeHandle, MutableRefObject } from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';

import { BodySmall, Box, Button, HistoryOutlinedIcon, IconButton, Snackbar, TableHeaderMenuIcon, Tooltip, Portal } from '@parspec/pixel';

import {
    CellValueChangedEvent,
    ColDef,
    ICellRendererParams,
    ProcessCellForExportParams,
    IRowNode,
    RowGroupOpenedEvent,
    ColSpanParams,
    ColumnResizedEvent,
    ColumnMovedEvent,
    ColumnState,
    GetQuickFilterTextParams,
    RowClassParams,
    CellKeyDownEvent,
    ProcessUnpinnedColumnsParams
} from 'ag-grid-community';

import { SectionApiResponseType } from '../../queries/apiTypes';
import {
    PRICING_TABLE_ENUM,
    bomCols,
    priceRelatedCols,
    pricingBodInfoCols,
    toolBaroptions,
    LEAD_TIME_UNITS,
    serviceRelatedCols,
    EXTENDED_SELL_TOOLTIP_MSG,
    KIT_LINE_TOOLTIP_MSG,
    LEAD_TIME_VALUE_TOOLTIP_MSG,
    ADD_DEDUCT_AMOUNT_TOOLTIP_MESSAGE
} from '../shared/constants';
import { getColorHandlerForMainTable, getIsEditableForMainTable, getFooterDisabled, getTemplateCellStyle, getValueGetterForPricingCols, optionsToolTipValueGetter } from '../shared/utils';
import { KIT_ERROR, KIT_ERROR_TYPE, NO_BOD_MSG, SECTION_TYPE } from '../../shared/constants';
import { addMsg, deleteMsg, duplicateMsg, hideMsg, reorderMsg, REMOVE_PRODUCT_FROM_KIT, HIGHLIGHT_MSG } from '../../../shared/constants';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import {
    saveColumnOrderEvents,
    saveColumnWidthEvents,
    useAddBomDataMutation,
    useApproveProductOption,
    useCreateKitMutation,
    useDeleteBomDataMutation,
    useDuplicateBomDataMutation,
    useHighLightBomDataMutation,
    useReorderBomDataMutation,
    useToggleHideBomDataMutation,
    useUpdateBomDataMutation
} from '../../queries';
import { OptionDataType } from './types';
import { AddOrDeductFooterTemplate, getAddOrDeductFooterExtendedPriceSum, getIsOptionsDataUpdated, getValueSetterForOptionProductPricingCols } from './utils';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import MoveSectionDialog from '../../shared/MoveSectionDialog';
import {
    deleteTitle,
    deleteContent,
    getCellAmountFormatter,
    getCellPercentFormatter,
    pricingCellParams,
    TextCellParams,
    getRowId,
    getTableHeight,
    addNewRowsToTheTable,
    handleOnRangeSelectionChanged
} from '../../shared/utils';
import { useGetCustomisedColumnData, useLotMap, useCreateNewBods } from '../../shared/hooks';
import { KitMenuOptions, MenuOptions } from '../../shared/MenuOptions';
import { MenuOptionsContext, TableContext } from '../../shared/context';
import { SpecificationValType } from '../../../shared/utils/constants';
import ErrorDialog from '../../../shared/ErrorDialog';
import { SpecificationDropdown } from '../../shared/SpecificationDropdown';
import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import { ProductToReplaceTemplate } from '../../shared/ProductToReplaceTemplate';
import BulkEditModal from '../bulkEdit/bulkEditPopup';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../../shared/commonTemplates';
import PricingHistoryModal from '../PricingHistoryModal';
import { PricingLeadTimeUnitTemplate } from '../shared/LeadTimeUnitTemplate';
import { isLeadTimeValueEditable, checkIfResfreshParentTable } from '../shared/utils';
import PriceAndLeadtimeDetailGrid from '../shared/PriceAndLeadTimeDetailGrid';
import { default as MoveOptionDialog } from '../../shared/MoveProductDialog';
import { calculateKitCost, kitToolbarItems } from '../../shared/utils';
import { useGetUserProfileInfoQuery } from '../../../Settings/MyProfile/queries';
import { LotTemplate } from '../mainProductTable/lotTemplate';
import { notifyToSentry } from '../../../shared/SentryErrorLogger';
import { BooleanObjType } from '../../shared/types';
import AddMultipleRows from '../../shared/AddMultipleRows';
import { CustomToolTipForTable } from '../../shared/CustomTooltipForTable';

interface OptionTableProps {
    tableData: OptionDataType[] | any[];
    isLoading: boolean;
    section: SectionApiResponseType;
    onPricingInfoEdit: (info: Partial<Omit<OptionDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification'>>) => Promise<void>;
    onBomInfoEdit: (info: Partial<Pick<OptionDataType, 'qty' | 'category' | 'manufacturer' | 'model_number' | 'specification' | 'id' | 'replaceProductId'>>) => Promise<void>;
    tableMaxHeight: number;
    toggleBulkEditBanner?: () => void;
    isKitTable?: boolean;
    isComponentsTable?: boolean;
    kitData?: any;
    onGridReady?: (params: any) => void;
    kitTableHeight?: string | number;
    handleTablesReordering?: (columnOrder: string[]) => void;
    handleTablesWidthChanged?: (targetedColumnId: string, newWidth?: number) => void;
    selectedRowsRef: MutableRefObject<Set<number>>;
}

const modules = [ClipboardModule, GridChartsModule];

const defaultColDef: ColDef = {
    sortable: false,
    menuTabs: [],
    resizable: true,
    editable: (params) => !params.node.rowPinned,
    suppressFillHandle: true,
    cellStyle: getFooterDisabled,
    tooltipComponent: CustomToolTipForTable
};

const OptionTable = forwardRef((props: OptionTableProps, ref: any) => {
    const {
        tableData: pricingTableData,
        isLoading,
        section,
        tableMaxHeight,
        onBomInfoEdit: handleBomInfoEdit,
        onPricingInfoEdit: handlePricingInfoEdit,
        isKitTable,
        isComponentsTable,
        kitData,
        kitTableHeight,
        onGridReady = () => {},
        handleTablesReordering,
        handleTablesWidthChanged,
        selectedRowsRef
    } = props;
    const { bomId = '0' } = useParams();

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [isDeleteDialogOpen, updateDeleteDialogVisilbity] = useState(false);
    const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [movingIds, setMovingIds] = useState<number[]>([]);
    const [localTableData, setLocalTableData] = useState<OptionDataType[] | null>(isComponentsTable ? pricingTableData : null);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
    const [gridReady, setGridReady] = useState(false);
    const lotMap = useLotMap(Number(bomId));
    const [kitCreationError, setKitCreationError] = useState<KIT_ERROR_TYPE>();
    const [isBulkEditInitiated, setIsBulkEditInitiated] = useState(false);
    const [isPricingHistoryModalOpen, setPricingHistoryModalOpen] = useState(false);
    const [pricingHistoryInfo, setPricingHistoryInfo] = useState<{
        type?: string;
        manufacturer?: string;
        modelNo?: string;
        id?: number;
        cost?: number;
        discount?: number;
        discountedCost?: number;
        sellPrice?: number;
        margin?: number;
        qty?: number;
        extendedPrice?: number;
        lotId?: number;
        kitLotId?: number | null;
    }>();
    const [quickFilterText, updateQuickFilterText] = useState('');
    const [openMoveOptionDialog, setOpenMoveOptionDialog] = useState(false);
    const [currentBodId, setCurrentBodId] = useState<number>();
    const expandedRowsContainerRef = useRef<Array<number>>([]);
    const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
    const [detailGridContainerHeight, setDetailGridContainerHeight] = useState(0);
    const [kitsExpansionObj, setKitsExpansionObj] = useState<BooleanObjType>({});
    const [maxWidthForPinnedColumn, setMaxWidthForPinnedColumn] = useState<number>();

    const tableRef = useRef<any>();

    const { createNewRowsFromClipboard, isLoading: isLoadingNewBods } = useCreateNewBods(bomId);

    const updateDetailGridContainerHeight = useCallback((value: number) => {
        setDetailGridContainerHeight(value);
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return tableRef;
        },
        []
    );

    const { columnOrderDetails, isColumnDataLoading } = useGetCustomisedColumnData(bomId);
    const { data: userData } = useGetUserProfileInfoQuery();

    const { mutateAsync: reorderData } = useReorderBomDataMutation();

    const { mutateAsync: deleteData, isLoading: deleteLoading } = useDeleteBomDataMutation();

    const { mutateAsync: duplicateData, isLoading: duplicateLoading } = useDuplicateBomDataMutation(bomId);

    const { mutateAsync: toggleHideData, isLoading: hideLoading } = useToggleHideBomDataMutation();

    const { mutateAsync: addData, isLoading: addDataLoading } = useAddBomDataMutation(bomId);

    const { mutateAsync: postApproveOption, isLoading: isApproveLoading } = useApproveProductOption();

    const { mutateAsync: createKit, isLoading: createKitLoading } = useCreateKitMutation(Number(bomId || 0));

    const { mutateAsync: updateBomData } = useUpdateBomDataMutation();

    const { mutateAsync: toggleHighLightData, isLoading: highLightLoading } = useHighLightBomDataMutation();

    const totalExtendedPrice = useMemo(() => getAddOrDeductFooterExtendedPriceSum(pricingTableData), [pricingTableData]);

    // This is the sum of width occuiped by columns drag + checkbox + kits + menu+ qty
    const pinnedAndFixColumnWidth = 400;

    const handleKitPricingUpdate = async () => {
        if (isComponentsTable && kitData?.id) {
            const updatedRowData: any = [];
            tableRef?.current?.api?.forEachNode((node: any) => {
                updatedRowData.push({ ...node.data });
            });
            if (updatedRowData?.length) {
                const kitCostData = calculateKitCost(updatedRowData, kitData?.qty || 0);
                await handlePricingInfoEdit({ ...kitCostData, id: kitData?.id });
            }
        }
    };

    const isPricingFieldsEditable = (params: any) => {
        if (isKitTable) {
            if (isComponentsTable) {
                return !kitData?.lotId && getIsEditableForMainTable(params);
            }
            return false;
        }
        return getIsEditableForMainTable(params);
    };
    const defaultTableLockedDefs: ColDef[] = [
        { field: PRICING_TABLE_ENUM.ID, hide: true, resizable: false, editable: false, lockPosition: 'left' },
        {
            field: 'drag',
            rowDrag: true,
            width: 20,
            resizable: false,
            pinned: 'left',
            lockPinned: true,
            colSpan: (params: ColSpanParams) => (params?.node?.rowPinned === 'bottom' ? 3 : 0),
            editable: false,
            hide: isKitTable && !isComponentsTable,
            lockPosition: 'left'
        },
        {
            headerCheckboxSelection: true,
            showDisabledCheckboxes: true,
            width: 20,
            enableRowGroup: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            resizable: false,
            hide: isKitTable && !isComponentsTable,
            lockPosition: 'left'
        },
        {
            width: 50,
            cellRenderer: 'agGroupCellRenderer',
            editable: false,
            pinned: 'left',
            cellStyle: () => ({ backgroundColor: 'unset' }),
            resizable: false,
            hide: isKitTable,
            lockPosition: 'left'
        },
        {
            minWidth: 50,
            colId: 'menu',
            maxWidth: 50,
            cellStyle: getTemplateCellStyle,
            editable: false,
            cellRenderer: isKitTable ? KitMenuOptions : MenuOptions,
            headerComponent: () => (
                <Box mt={1}>
                    <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                </Box>
            ),
            pinned: 'right',
            resizable: false,
            lockPosition: 'right',
            hide: isKitTable && !isComponentsTable
        }
    ];

    const columnDef: Record<string, ColDef> = {
        [PRICING_TABLE_ENUM.TYPE]: {
            headerName: 'Type',
            field: PRICING_TABLE_ENUM.TYPE,
            valueFormatter: 'value?.toUpperCase()',
            minWidth: 80,
            pinned: 'left',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.QTY]: {
            headerName: isComponentsTable ? 'Qty per Kit' : 'Qty',
            colId: PRICING_TABLE_ENUM.QTY,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 0,
                max: 2147483647,
                preventStepping: true
            },
            minWidth: 85,
            pinned: 'left',
            maxWidth: 100,
            type: 'rightAligned'
        },
        [PRICING_TABLE_ENUM.MANUFACTURER]: {
            headerName: 'Manufacturer',
            field: PRICING_TABLE_ENUM.MANUFACTURER,
            valueFormatter: 'value?.toUpperCase()',
            minWidth: 80,
            pinned: 'left',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.MODEL_NO]: {
            headerName: 'Model Number',
            field: PRICING_TABLE_ENUM.MODEL_NO,
            valueFormatter: 'value?.toUpperCase()',
            resizable: true,
            minWidth: 80,
            pinned: 'left',
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        [PRICING_TABLE_ENUM.QUOTE_NOTES]: { headerName: 'Quote Notes (External)', field: PRICING_TABLE_ENUM.QUOTE_NOTES, minWidth: 80, cellDataType: 'text' },
        [PRICING_TABLE_ENUM.INTERNAL_COMMENTS]: { headerName: 'Internal Comments', field: PRICING_TABLE_ENUM.INTERNAL_COMMENTS, minWidth: 80, cellDataType: 'text' },
        specificationPlTab: {
            headerName: 'Specification',
            field: PRICING_TABLE_ENUM.SPECIFICATION,
            colId: 'specificationPlTab',
            editable: false,
            cellRenderer: SpecificationDropdown,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.value;
            },
            width: 204,
            minWidth: 80,
            cellStyle: getTemplateCellStyle
        },
        [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE]: {
            headerName: 'Product To Replace',
            field: PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE,
            editable: false,
            cellRenderer: ProductToReplaceTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.data?.[PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE_LABEL];
            },
            width: 300,
            minWidth: 80,
            cellStyle: getTemplateCellStyle,
            hide: isComponentsTable
        },
        [PRICING_TABLE_ENUM.LOT]: {
            headerName: 'Lot',
            colId: PRICING_TABLE_ENUM.LOT,
            editable: false,
            cellRenderer: LotTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.data?.[PRICING_TABLE_ENUM.LOT_INFO]?.name;
            },
            width: 180,
            minWidth: 80,
            cellStyle: getColorHandlerForMainTable,
            hide: true
        },
        [PRICING_TABLE_ENUM.HISTORY]: {
            minWidth: 69,
            maxWidth: 69,
            headerComponent: () => (
                <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <HistoryOutlinedIcon color="secondary" fontSize="small" />
                </Box>
            ),
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.rowPinned === 'bottom' || params.data.is_kit) {
                    return null;
                }

                function handleClick() {
                    const { category, manufacturer, model_number, id, cost, discount, discountedCost, sellPrice, margin, qty, extendedPrice, lotId } = params.data;
                    setPricingHistoryInfo({
                        type: category,
                        manufacturer,
                        modelNo: model_number,
                        id,
                        cost,
                        discount,
                        discountedCost,
                        sellPrice,
                        margin,
                        qty,
                        extendedPrice,
                        lotId,
                        kitLotId: isKitTable && isComponentsTable ? kitData?.lotId : null
                    });
                    setPricingHistoryModalOpen(true);
                }

                return (
                    <Box width="100%" alignItems="center">
                        <IconButton onClick={handleClick}>
                            <HistoryOutlinedIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Box>
                );
            },
            editable: false,
            cellStyle: () => ({ backgroundColor: 'unset' }),
            resizable: true,
            hide: isKitTable && !isComponentsTable,
            colId: PRICING_TABLE_ENUM.HISTORY
        },
        [PRICING_TABLE_ENUM.COST]: {
            headerName: 'Cost',
            colId: PRICING_TABLE_ENUM.COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            cellDataType: 'number',
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            width: 120,
            minWidth: 80,
            editable: isPricingFieldsEditable,
            type: 'rightAligned',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isPricingFieldsEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            tooltipValueGetter: (params) => optionsToolTipValueGetter(params, PRICING_TABLE_ENUM.COST)
        },
        [PRICING_TABLE_ENUM.DISCOUNT]: {
            headerName: 'Discount (%)',
            colId: PRICING_TABLE_ENUM.DISCOUNT,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            editable: getIsEditableForMainTable,
            valueFormatter: getCellPercentFormatter,
            getQuickFilterText: getCellPercentFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellStyle: getColorHandlerForMainTable,
            width: 119,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: optionsToolTipValueGetter
        },
        [PRICING_TABLE_ENUM.DISCOUNT_COST]: {
            headerName: 'Discounted Cost',
            colId: PRICING_TABLE_ENUM.DISCOUNT_COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            editable: getIsEditableForMainTable,
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellStyle: getColorHandlerForMainTable,
            width: 140,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: optionsToolTipValueGetter
        },
        [PRICING_TABLE_ENUM.MARGIN]: {
            headerName: 'Margin(%)',
            colId: PRICING_TABLE_ENUM.MARGIN,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            editable: getIsEditableForMainTable,
            valueFormatter: getCellPercentFormatter,
            getQuickFilterText: getCellPercentFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellStyle: getColorHandlerForMainTable,
            width: 106,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: optionsToolTipValueGetter
        },
        [PRICING_TABLE_ENUM.SELL_PRICE]: {
            headerName: 'Sell Price',
            colId: PRICING_TABLE_ENUM.SELL_PRICE,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForOptionProductPricingCols,
            editable: getIsEditableForMainTable,
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellStyle: getColorHandlerForMainTable,
            width: 130,
            minWidth: 80,
            type: 'rightAligned',
            tooltipValueGetter: (params) => optionsToolTipValueGetter(params, PRICING_TABLE_ENUM.SELL_PRICE)
        },
        [PRICING_TABLE_ENUM.EXTENDED_PRICE]: {
            headerName: 'Ext. Sell Price',
            field: PRICING_TABLE_ENUM.EXTENDED_PRICE,
            editable: false,
            width: 145,
            minWidth: 80,
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            type: 'rightAligned',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            tooltipValueGetter: (params) => {
                if (params?.data?.is_kit) return KIT_LINE_TOOLTIP_MSG;
                return EXTENDED_SELL_TOOLTIP_MSG;
            }
        },
        [PRICING_TABLE_ENUM.ADD_DEDUCT_AMT]: {
            headerName: 'Add/Deduct Amount',
            field: PRICING_TABLE_ENUM.ADD_DEDUCT_AMT,
            editable: false,
            width: 160,
            minWidth: 80,
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            valueFormatter: getCellAmountFormatter,
            getQuickFilterText: getCellAmountFormatter,
            type: 'rightAligned',
            tooltipValueGetter: () => ADD_DEDUCT_AMOUNT_TOOLTIP_MESSAGE,
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params?.node?.rowPinned === 'bottom' && params?.node?.data?.addDeductAmount === 'N/A') {
                    return {
                        component: AddOrDeductFooterTemplate
                    };
                }
                return undefined;
            },
            hide: isComponentsTable
        },
        [PRICING_TABLE_ENUM.LEAD_TIME_VALUE]: {
            headerName: 'Lead time Value',
            field: PRICING_TABLE_ENUM.LEAD_TIME_VALUE,
            minWidth: 80,
            cellEditor: 'agNumberCellEditor',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isLeadTimeValueEditable(args),
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            cellEditorParams: {
                precision: 0,
                max: 2147483647
            },
            tooltipValueGetter: (params) => {
                if (!isLeadTimeValueEditable(params)) return LEAD_TIME_VALUE_TOOLTIP_MSG;
                return '';
            },
            editable: isLeadTimeValueEditable,
            cellDataType: 'number',
            type: 'rightAligned'
        },
        [PRICING_TABLE_ENUM.LEAD_TIME_UNIT]: {
            headerName: 'Lead time Unit',
            field: PRICING_TABLE_ENUM.LEAD_TIME_UNIT,
            minWidth: 80,
            editable: false,
            cellStyle: { padding: '4px 0px' },
            cellRenderer: PricingLeadTimeUnitTemplate,
            getQuickFilterText: (params: GetQuickFilterTextParams) => {
                return params?.value;
            }
        },

        [PRICING_TABLE_ENUM.QUOTE_NO]: { headerName: 'Source Quotes Number', field: PRICING_TABLE_ENUM.QUOTE_NO, width: 173, minWidth: 80, cellDataType: 'text' }
    };

    const tableColumnDefs: ColDef[] = useMemo(() => {
        const orderedColumns = columnOrderDetails?.order.reduce((result: ColDef[], field: string) => {
            // Check if field exists in defaultColumnDef
            if (Object.hasOwn(columnDef, field)) {
                const column = {
                    ...columnDef[field]
                };

                // Apply width if available in defaultColumnState.width
                if (columnOrderDetails?.width) {
                    const widthField = field as keyof typeof columnOrderDetails.width;
                    column.width = columnOrderDetails?.width[widthField];
                    if (['manufacturer', 'model_number', 'category'].includes(field) && maxWidthForPinnedColumn) {
                        column.maxWidth = maxWidthForPinnedColumn;
                    }
                }

                result.push(column);
            }

            return result;
        }, []);

        return [...defaultTableLockedDefs, ...(orderedColumns || [])];
    }, [columnOrderDetails, isColumnDataLoading, maxWidthForPinnedColumn]);

    useEffect(() => {
        if (
            (!isComponentsTable && pricingTableData && checkIfResfreshParentTable(pricingTableData, localTableData)) ||
            (localTableData == null && !isLoading && pricingTableData) ||
            (localTableData && localTableData.length) !== pricingTableData.length ||
            (localTableData && getIsOptionsDataUpdated(pricingTableData, localTableData)) ||
            isBulkEditInitiated
        ) {
            setLocalTableData(pricingTableData);
            setIsBulkEditInitiated(false);
        }
    }, [pricingTableData, isLoading]);

    useEffect(() => {
        if (isComponentsTable && bomId && localTableData?.length) {
            handleKitPricingUpdate();
        }
    }, [localTableData?.length]);

    useEffect(() => {
        setKitsExpansionObj(JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'));
    }, []);

    const resetTableData = () => {
        setIsBulkEditInitiated(true);
    };

    const rowHeight = 40;
    const isTableLoading = isLoading || addDataLoading || deleteLoading || duplicateLoading || hideLoading || isApproveLoading || createKitLoading || highLightLoading || isLoadingNewBods;
    const tableHeight = isKitTable ? kitTableHeight : getTableHeight({ rowHeight, rows: pricingTableData?.length || 0, tableMaxHeight });
    const calculatedTableHeight = !isComponentsTable && expandedRows.length ? (tableHeight as number) + detailGridContainerHeight : tableHeight;

    useEffect(() => {
        if (!gridReady) {
            return;
        }
        if (isTableLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (!isTableLoading && pricingTableData && pricingTableData.length === 0) {
            setTimeout(() => {
                tableRef?.current?.api?.showNoRowsOverlay();
            });
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isTableLoading, pricingTableData, gridReady]);

    async function handleAddBod(prevBodId: number | null) {
        const item = {
            category: '',
            qty: null,
            manufacturer: '',
            model_number: '',
            previous_bod_id: prevBodId,
            section_id: section.id,
            section_type: SECTION_TYPE.OPTION,
            ...(isKitTable ? { kit_id: kitData?.id } : {})
        };

        await addData({ bomId: bomId || '0', input: item });
        setSnackbarOpen(addMsg);
    }

    function handleAddBelow(props: ICellRendererParams | CellKeyDownEvent) {
        const { data } = props;
        return handleAddBod(data.id);
    }

    async function onDuplicate(bodIds: Array<number>) {
        await duplicateData({
            bomId: bomId || '0',
            input: bodIds
        });
        setSnackbarOpen(duplicateMsg);
    }

    function handleAddSingleDuplicate(props: any) {
        return onDuplicate([props.data.id]);
    }

    async function handleAddDuplicates() {
        const idsToDuplicate: Array<number> = selectedItems.map((product: OptionDataType) => product.id);
        await onDuplicate(idsToDuplicate);
        handleClearSelection();
    }

    const handleAdd = () => {
        const id = pricingTableData.length ? pricingTableData[pricingTableData.length - 1].id : null;
        handleAddBod(id);
    };

    const handleCheckboxChange = (args: any) => {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedItems(selectedBods);
    };

    async function handleDelete() {
        await deleteData({ bomId: bomId || '0', ids: deleteIds });
        setDeleteIds([]);
        updateDeleteDialogVisilbity(false);
        handleClearSelection();
        setSnackbarOpen(deleteMsg);
    }

    function handleBulkDelete() {
        const toDeleteIdsSet: Array<number> = selectedItems.map((product: OptionDataType) => product.id);
        setDeleteIds(toDeleteIdsSet);
        updateDeleteDialogVisilbity(true);
    }

    function handleSingleDelete(props: any) {
        setDeleteIds([props.data.id]);
        updateDeleteDialogVisilbity(true);
    }

    function closeMoveSectionDialog(afterSubmission?: boolean) {
        if (afterSubmission) {
            handleClearSelection();
        }
        setOpenMoveDialog(false);
        setMovingIds([]);
    }

    const openMoveSectionDialog = () => setOpenMoveDialog((old) => !old);

    const onClickMoveSection = (props: any) => {
        const { id } = props?.data || {};
        setMovingIds([id]);
        openMoveSectionDialog();
    };

    const onMoveMultipleItems = () => {
        const ids = selectedItems.map((product: OptionDataType) => product.id);
        setMovingIds(ids);
        openMoveSectionDialog();
    };

    async function onHide(bodIds: Array<number>) {
        await toggleHideData({
            bomId: bomId || '0',
            input: bodIds
        });
        setSnackbarOpen(hideMsg);
        if (isComponentsTable) {
            tableRef?.current?.api?.forEachNode((node: any) => {
                const { id } = node.data;
                if (bodIds.includes(id)) {
                    const bodData = localTableData?.find((item) => item.id === id);
                    node.setData({ ...bodData, is_hidden: !bodData?.is_hidden });
                }
            });
            handleKitPricingUpdate();
        }
    }

    function handleMultipleHide() {
        const toHideIds: Array<number> = selectedItems.map((product: OptionDataType) => product.id);
        onHide(toHideIds);
        handleClearSelection();
    }

    function handleSingleHide(props: any) {
        return onHide([props.data.id]);
    }

    async function handleEdit(props: CellValueChangedEvent) {
        try {
            const fieldName = props.column.getColId() as PRICING_TABLE_ENUM;
            const bodId = props.data.id;
            let sourceData = { ...props.data };
            if (priceRelatedCols.has(fieldName) || fieldName === PRICING_TABLE_ENUM.QTY) {
                fieldName === PRICING_TABLE_ENUM.QTY
                    ? handleBomInfoEdit({ [fieldName]: props.data[fieldName], id: bodId }).then(handleKitPricingUpdate)
                    : handlePricingInfoEdit({ ...sourceData, id: bodId }).then(handleKitPricingUpdate);
            } else if (pricingBodInfoCols.has(fieldName)) {
                let payload: Partial<OptionDataType> = { [fieldName]: props.data[fieldName], id: bodId };
                if (fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE && props.data?.leadTimeUnit === null && props.data?.leadTimeValue !== null) {
                    sourceData = { ...sourceData, leadTimeUnit: LEAD_TIME_UNITS.WEEKS.toLowerCase() };
                    payload = { ...payload, leadTimeUnit: LEAD_TIME_UNITS.WEEKS.toLowerCase() };
                }

                handlePricingInfoEdit(payload);
            } else if (bomCols.has(fieldName)) {
                sourceData[fieldName] = sourceData[fieldName] ? sourceData[fieldName].toUpperCase() : '';
                handleBomInfoEdit({ [fieldName]: sourceData[fieldName], id: bodId });
            }
            props.node.setData(sourceData);
        } catch (error: any) {
            notifyToSentry(`(OptionsTable)${error.name}: ${error.message}\n${error.stack}`);
        }
    }

    async function approveOption(productId: number) {
        await postApproveOption({ bomId: Number(bomId), data: { bod_id: [productId] } });
    }

    const updateKitCreationError = (error: KIT_ERROR_TYPE.KIT | KIT_ERROR_TYPE.OPTION | undefined) => setKitCreationError(error);

    const onCreateKit = async () => {
        const isKitSelected = selectedItems.some((item: any) => item.is_kit);
        if (isKitSelected) {
            return updateKitCreationError(KIT_ERROR_TYPE.KIT);
        }
        const id = pricingTableData.length ? pricingTableData[pricingTableData.length - 1].id : null;
        const inputData = {
            bomId: Number(bomId),
            input: {
                category: '',
                qty: null,
                manufacturer: '',
                model_number: '',
                previous_bod_id: id,
                section_id: section?.id,
                components: selectedItems.map((item: any) => item.id),
                section_type: SECTION_TYPE.OPTION
            }
        };
        await createKit(inputData);
        handleClearSelection();
    };

    function handleSpecificationChange(specificationVal: SpecificationValType, bodId: number) {
        handleBomInfoEdit({ specification: specificationVal, id: bodId });
    }

    function handleProductToReplaceChange(productToReplaceVal: number | null, bodId: number) {
        handleBomInfoEdit({ [PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE]: productToReplaceVal, id: bodId });
    }

    async function onReorderTableData(arg: any) {
        const updatedRowData: any = [];
        arg.api.forEachNode((node: any) => {
            updatedRowData.push({ ...node.data });
        });
        const bodIds = arg.nodes.map((item: any) => item.data.id);
        const overIndex = updatedRowData.findIndex((item: any) => item?.id === bodIds[0]);
        const prevBodId = overIndex === 0 ? (isKitTable ? kitData?.id : null) : updatedRowData[overIndex - 1].id;
        const input = { previous_bod_id: prevBodId, bod_id: bodIds };
        await reorderData({ bomId: bomId!, input });
        setSnackbarOpen(reorderMsg);
    }

    function handleSearch(searchString: string) {
        updateQuickFilterText(searchString);
    }

    function handleClearSelection() {
        tableRef?.current?.api?.deselectAll();
        setSelectedItems([]);
    }

    const handleBulkEditClick = () => {
        setOpenBulkEditModal((openBulkEditModal) => !openBulkEditModal);
    };

    const handleProcessCellPasteForPricing = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column.getColId();

        addNewRowsToTheTable(selectedRowsRef, tableRef, fieldName, createNewRowsFromClipboard, [PRICING_TABLE_ENUM.LOT]);

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        } else if (value === '' || value == null) {
            return fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE ? null : '';
        }

        const processCellPasteCols = new Set([...priceRelatedCols, ...serviceRelatedCols]);

        if (processCellPasteCols.has(fieldName)) {
            const newValue: string = value.replace(/\$|,|%/g, '');
            if (newValue !== '' && !isNaN(newValue as unknown as number)) {
                return parseFloat(newValue);
            }
            return null;
        }
        return value;
    };

    const toolbarRightSection: ReactNode = (
        <Tooltip title={selectedItems.length === 0 ? 'Select Item(s) First' : ''} placement="top">
            <Box sx={{ width: 'max-content' }}>
                <Button variant="outlined" color="secondary" onClick={handleBulkEditClick} disabled={selectedItems.length === 0}>
                    Bulk Edit
                </Button>
            </Box>
        </Tooltip>
    );

    function handleProcessForClipboard(props: ProcessCellForExportParams) {
        const fieldName = props?.column?.getColId();

        if (fieldName === PRICING_TABLE_ENUM.SPECIFICATION || fieldName === PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE) {
            return '';
        }

        return props.value;
    }

    function handleLeadTimeUnit(rowNode: IRowNode, payload: Partial<OptionDataType>) {
        rowNode.setDataValue(PRICING_TABLE_ENUM.LEAD_TIME_UNIT, payload.leadTimeUnit);
        rowNode.setDataValue(PRICING_TABLE_ENUM.LEAD_TIME_VALUE, payload.leadTimeValue);
        handlePricingInfoEdit(payload);
    }

    function handleOnGridReady(params: any) {
        const { leftWidth, rightWidth, viewportRight } = params?.api?.columnModel || {};
        const maxViewPortWidth = leftWidth + rightWidth + viewportRight;
        // divided by 3 width will equally distributed across type,manufacture,modelNo
        const maxPinnedColumnsWidth = Math.floor((maxViewPortWidth - pinnedAndFixColumnWidth) / 3);
        setMaxWidthForPinnedColumn(maxPinnedColumnsWidth);
        setGridReady(true);
        if (isComponentsTable) {
            onGridReady(params);
        }
    }

    const detailCellRenderer = useMemo(
        () => (props: ICellRendererParams<any, any, any>) =>
            <PriceAndLeadtimeDetailGrid {...props} updateDetailGridContainerHeight={updateDetailGridContainerHeight} selectedRowsRef={selectedRowsRef} />,
        []
    );

    const getIfRowMaster = useCallback((dataItem: any) => {
        return dataItem ? dataItem.is_kit : false;
    }, []);

    async function onProductMoveOut(params: any) {
        const { data } = params;
        await updateBomData({ bomId: String(bomId), input: { kit: null, id: data.id }, isInvalidateBodList: true });
        if (localTableData?.length === 1 && kitData?.id) {
            const kitCostData = {
                cost: null,
                discountedCost: null,
                sellPrice: null,
                discount: null,
                margin: null,
                extendedPrice: null
            };

            await handlePricingInfoEdit({ ...kitCostData, id: kitData?.id });
        }
        setSnackbarOpen(REMOVE_PRODUCT_FROM_KIT);
    }

    const handleMoveOptionDialog = useCallback(() => {
        setOpenMoveOptionDialog(false);
    }, []);

    const getTableMetaData = useCallback(() => {
        if (!tableRef?.current) return null;
        return tableRef.current;
    }, []);

    function onProductMoveIn(currentBodId: number) {
        setCurrentBodId(currentBodId);
        setOpenMoveOptionDialog(true);
    }

    const onRowGroupOpened = useCallback(
        (e: RowGroupOpenedEvent<any, any>) => {
            const {
                rowIndex,
                node: { expanded, id }
            } = e;

            if (rowIndex !== null) {
                if (expanded) {
                    expandedRowsContainerRef.current.push(rowIndex);
                } else {
                    expandedRowsContainerRef.current.pop();
                }
            }
            setExpandedRows([...expandedRowsContainerRef.current]);

            const obj = { ...JSON.parse(localStorage.getItem(`${bomId}-kits`) || '{}'), [`${id}`]: expanded };
            setKitsExpansionObj?.(obj);
            localStorage.setItem(`${bomId}-kits`, JSON.stringify(obj));
        },
        [kitsExpansionObj]
    );

    const toggleKits = useCallback(
        (params: any) => {
            if (kitsExpansionObj && Object.keys(kitsExpansionObj).length > 0) {
                Object.keys(kitsExpansionObj).map((id: string) => {
                    const rowNode = params.api.getRowNode(id);
                    if (rowNode) {
                        rowNode.setExpanded(kitsExpansionObj?.[id]);
                    }
                });
            }
        },
        [kitsExpansionObj]
    );

    const handleColumnMoved = async (e: ColumnMovedEvent) => {
        const savedState = e.api.getColumnState();
        if (e.finished && e.source === 'uiColumnMoved') {
            const newColumnOrder = savedState.map((column: ColumnState) => column.colId);
            const exceptDefaultColumns = newColumnOrder.slice(defaultTableLockedDefs.length - 1);
            handleTablesReordering?.(exceptDefaultColumns);

            const userId = userData?.data?.id;
            const newArraySet = new Set(exceptDefaultColumns);
            const sortedArray: string[] = [...exceptDefaultColumns];
            if (columnOrderDetails && columnOrderDetails.order) {
                sortedArray.push(...columnOrderDetails.order.filter((item: string) => !newArraySet.has(item)));
            }
            if (!isComponentsTable) await saveColumnOrderEvents(userId, sortedArray);
        }
    };
    const handleColumnResized = async (props: ColumnResizedEvent) => {
        const colId = props?.column?.getColId() as string;
        const userId = userData?.data?.id;
        const newChangedWidth = props?.column?.getActualWidth();
        const currentWidths = columnOrderDetails?.width;
        const columnState = props.api.getColumnState();
        const ignorePinnedColumnState = columnState.slice(defaultTableLockedDefs.length - 1);
        const columnWidths = ignorePinnedColumnState.reduce((acc: any, item) => {
            acc[item.colId] = item.width;
            return acc;
        }, {});
        const newWidths = { ...currentWidths, ...columnWidths };
        handleTablesWidthChanged?.(colId, newChangedWidth);

        if (props.finished && props.source === 'uiColumnResized' && userId) {
            if (!isComponentsTable) await saveColumnWidthEvents(userId, newWidths);
        }
    };
    function handlePricingModalClose(afterSave = false) {
        setPricingHistoryModalOpen(false);
        if (afterSave) {
            handleKitPricingUpdate();
        }
    }

    const onHighlightMultiple = async () => {
        const input = selectedItems.map((item: any) => item.id);
        onHighlight(input);

        handleClearSelection();
    };

    const onHighlight = async (ids: number[]) => {
        await toggleHighLightData({
            bomId: bomId,
            input: ids
        });
        setSnackbarOpen(HIGHLIGHT_MSG);
    };

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event || e.event.defaultPrevented) {
            return;
        }
        // stop the default behavior of the event. While adding a row in the component table, the event must not trigger onAdd() function call for the kit table
        isComponentsTable && e.event.preventDefault();
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        if ((keyboardEvent.ctrlKey || keyboardEvent.metaKey) && keyboardEvent.key === 'i') {
            handleAddBelow(e);
        }
    }, []);

    const onHandleUnpinnedColumns = (props: ProcessUnpinnedColumnsParams) => {
        // divided by 3 width will equally distributed across type,manufacture,modelNo
        const maxColumnsWidth = Math.floor((props?.viewportWidth - pinnedAndFixColumnWidth) / 3);
        setMaxWidthForPinnedColumn(maxColumnsWidth);
        return [];
    };

    return (
        <>
            {isDeleteDialogOpen && (
                <DeleteConfirmationDialog open={isDeleteDialogOpen} handleDialog={() => updateDeleteDialogVisilbity(false)} confirmAction={handleDelete} loading={deleteLoading}>
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {deleteTitle(deleteIds.length)}
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            {deleteContent}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {openMoveDialog && (
                <MoveSectionDialog open={openMoveDialog} handleDialog={closeMoveSectionDialog} movingIds={movingIds} handleClearSelection={handleClearSelection} currentSection={section} />
            )}
            {<PricingHistoryModal open={isPricingHistoryModalOpen} onClose={handlePricingModalClose} pricingInfo={pricingHistoryInfo} />}
            {openMoveOptionDialog && currentBodId && (
                <MoveOptionDialog
                    open={openMoveOptionDialog}
                    handleClose={handleMoveOptionDialog}
                    currentBodId={currentBodId}
                    getTableMetaData={getTableMetaData}
                    sectionId={section.id}
                    setSnackbarOpen={setSnackbarOpen}
                    isOptionTable={true}
                />
            )}
            {kitCreationError && (
                <ErrorDialog
                    open={Boolean(kitCreationError)}
                    handleDialog={() => updateKitCreationError(undefined)}
                    title="Unable to Create Kit"
                    text={KIT_ERROR[kitCreationError].text}
                    helperText={KIT_ERROR[kitCreationError].helperText}
                />
            )}

            {openBulkEditModal && (
                <BulkEditModal
                    open={openBulkEditModal}
                    onClose={() => setOpenBulkEditModal(false)}
                    selectedItems={selectedItems}
                    handleClearSelection={handleClearSelection}
                    setSnackbarOpen={setSnackbarOpen}
                    isOptionsBulkEdit
                    lotMap={lotMap}
                    onReset={resetTableData}
                />
            )}
            <Portal>
                <Snackbar
                    open={snackbarInfo.open}
                    message={snackbarInfo.message}
                    onClose={(_e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setSnackbarClose();
                    }}
                    autoHideDuration={snackbarInfo.autoHideDuration}
                />
            </Portal>

            <MenuOptionsContext.Provider
                value={{
                    bomId,
                    isOptionTable: true,
                    onAddBelow: handleAddBelow,
                    onClickMoveSection,
                    onHide: handleSingleHide,
                    onClickDelete: handleSingleDelete,
                    onDuplicate: handleAddSingleDuplicate,
                    approveOption,
                    onProductMoveOut,
                    onProductMoveIn,
                    onHighlight
                }}
            >
                <TableContext.Provider
                    value={{
                        lotMap,
                        onProductToReplaceChange: handleProductToReplaceChange,
                        handleSpecificationChange,
                        handleLeadTimeUnit,
                        section,
                        isOptionTable: true
                    }}
                >
                    <Box zIndex={1} width={'100%'} position={'relative'}>
                        <Box>
                            <CustomToolBarPanel
                                toolBarOptions={isKitTable ? (isComponentsTable ? kitToolbarItems : []) : toolBaroptions}
                                selectedRowCount={selectedItems.length}
                                disabledToolBarButton={selectedItems.length === 0}
                                onAdd={handleAdd}
                                onAddDuplicates={handleAddDuplicates}
                                onHideUnhide={handleMultipleHide}
                                onMove={onMoveMultipleItems}
                                onDelete={handleBulkDelete}
                                onTextSearch={handleSearch}
                                onCloseBanner={handleClearSelection}
                                loading={isTableLoading}
                                toolbarRightSection={isKitTable ? null : toolbarRightSection}
                                onCreateKit={onCreateKit}
                                onHighlight={onHighlightMultiple}
                                isKitSelected={selectedItems.some((item: OptionDataType) => item.is_kit)}
                            ></CustomToolBarPanel>
                        </Box>
                        <Box style={{ height: calculatedTableHeight }} className="ag-theme-alpine" width="100%">
                            <AgGridReact
                                ref={tableRef}
                                rowData={localTableData}
                                getRowId={getRowId}
                                quickFilterText={quickFilterText}
                                gridOptions={{
                                    rowHeight,
                                    tooltipShowDelay: 0,
                                    tooltipInteraction: true,
                                    suppressRowClickSelection: true,
                                    enableRangeSelection: true,
                                    enableFillHandle: true,
                                    undoRedoCellEditing: true,
                                    enterNavigatesVerticallyAfterEdit: true,
                                    enterNavigatesVertically: true,
                                    suppressDragLeaveHidesColumns: true,
                                    rowDragManaged: true,
                                    valueCache: true,
                                    rowSelection: 'multiple',
                                    stopEditingWhenCellsLoseFocus: true,
                                    suppressLastEmptyLineOnPaste: true,
                                    rowClassRules: {
                                        'row-hide': (params: any) => params?.data?.is_hidden,
                                        'row-highlight': (params: RowClassParams) => (params?.data?.is_kit || isComponentsTable ? false : params?.data?.is_highlighted)
                                    },
                                    getRowStyle: (params) => {
                                        if (params?.node?.rowPinned) {
                                            return { backgroundColor: '#f8f8f8', fontWeight: 700 };
                                        }
                                        return undefined;
                                    },
                                    rowDragMultiRow: true
                                }}
                                context={{}}
                                pinnedBottomRowData={isKitTable ? [] : [{ drag: 'Subtotal', addDeductAmount: totalExtendedPrice }]}
                                columnDefs={tableColumnDefs}
                                modules={modules}
                                processUnpinnedColumns={onHandleUnpinnedColumns}
                                defaultColDef={defaultColDef}
                                onCellValueChanged={handleEdit}
                                onRowDragEnd={onReorderTableData}
                                onSelectionChanged={handleCheckboxChange}
                                loadingOverlayComponent={loadingOverlayComponent}
                                processCellFromClipboard={handleProcessCellPasteForPricing}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} helperText={NO_BOD_MSG} />}
                                noRowsOverlayComponentParams={{
                                    isTableHaveFooter: !isComponentsTable ? true : false
                                }}
                                processCellForClipboard={handleProcessForClipboard}
                                onGridReady={handleOnGridReady}
                                masterDetail
                                detailCellRenderer={detailCellRenderer}
                                isRowMaster={getIfRowMaster}
                                detailRowAutoHeight
                                reactiveCustomComponents
                                onRowGroupOpened={onRowGroupOpened}
                                onColumnMoved={handleColumnMoved}
                                onColumnResized={handleColumnResized}
                                onRowDragLeave={onReorderTableData}
                                onCellKeyDown={onCellKeyDown}
                                onFirstDataRendered={toggleKits}
                                onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsRef)}
                            />
                        </Box>
                        {!isKitTable && <AddMultipleRows sectionId={section.id} isOptionTable={true} />}
                    </Box>
                </TableContext.Provider>
            </MenuOptionsContext.Provider>
        </>
    );
});
export default OptionTable;
