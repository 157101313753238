import { Box, H6, BodyBig, Button } from '@parspec/pixel';

export const MaintenancePage = (props: { date: string; refetchMaintenanceData?: () => void }) => {
    const localDate = new Date(props.date);
    return (
        <Box bgcolor="secondary.main" width="100vw " height="100vh" padding="100px" color={'secondary.contrastText'} display="flex" alignItems="center" justifyContent="space-around">
            <Box>
                <H6 color={'secondary.contrastText'} sx={{ fontWeight: 400 }}>
                    Scheduled Maintenance in Progress
                </H6>
                <br />
                <BodyBig limit={false} color={'secondary.contrastText'} fontWeight={200}>
                    We’re currently tuning up the gears of Parspec to bring you an even better experience.
                </BodyBig>
                <br />
                <H6 color={'secondary.contrastText'} sx={{ fontWeight: 400 }}>
                    Estimated Time of Completion: {localDate.toLocaleString()}
                </H6>
                <br />
                <BodyBig limit={false} color={'secondary.contrastText'} fontWeight={200}>
                    We appreciate your patience and understanding. If you have any concerns or need assistance, please don’t hesitate to reach out to our support team at support@parspec.io.{' '}
                </BodyBig>
                <br />
                <BodyBig limit={false} color={'secondary.contrastText'} fontWeight={200}>
                    Thank you for being a valued part of the Parspec community!
                </BodyBig>
                <Box mt={4}>
                    <Button onClick={() => props.refetchMaintenanceData?.()}>Refresh Page</Button>
                </Box>
            </Box>
            <Box>
                <img src={'assets/images/Unauthorised-logo.svg'} alt="parspec-text" vertical-align="middle" width={'460px'} height={'446px'} />
            </Box>
        </Box>
    );
};
