import { useLocation } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { CompanyManufacturer } from './Manufacturer';
import { Catalog } from './Catalog';
import ManufacturerList from './manufacturerList';

export function Manufacturer() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tabValue = params?.get('tab');
    let manufacturerContainer = null;
    switch (tabValue) {
        case 'manufacturers':
            manufacturerContainer = <CompanyManufacturer />;
            break;
        case 'catalog':
            manufacturerContainer = <Catalog />;
            break;
        case 'list':
            manufacturerContainer = <ManufacturerList/>;
            break;
        default:
            break;
    }

    return (
        <Box p={4} mb={3} boxSizing="border-box" display="flex" flexDirection="column" width="calc(100vw - 390px)" overflow="auto" bgcolor={'primary.contrastText'}>
            {manufacturerContainer}
        </Box>
    );
}
