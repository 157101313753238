// import { dashboardHandlers } from './Dashboard/queries/mockHandlers';
import { bomHandlers } from './BOM/queries/mockHandlers';
import { finalDocumentHandlers } from './BOM/FinalDocuments/queries/mockHandlers';
import { createBOMHandler } from './Dashboard/BOMModal/queries/mockHandlers';
import { compileSubmittalHandlers } from './BOM/FinalDocuments/Compile/submittals&o_m/queries/mockHandlers';
import { compileQuoteHandlers } from './BOM/FinalDocuments/Compile/compileQuote/queries/mockHandlers';
import { importMoreProductHandler } from './BOM/ImportMoreProducts/queries/mockHandlers';
import { documentSuggestionHandlers } from './shared/DocumentSuggestions/queries/mockHandlers';
// import { sharedApisHandlers } from './shared/queries/mockHandler';
import { selectedDatasheetHandlers } from './BOM/Datasheets/AnnotateDatasheet/queries/mockHandlers';
import { projectHandlers } from './Project/queries/mockHandlers';
import { viewDocumentHandlers } from './BOM/FinalDocuments/Preview/queries/mockHandlers';
import { selectProductHandlers } from './BOM/productFinder/selectProduct/queries/mockHandlers';
import { searchProductMockHandlers } from './BOM/productFinder/setRequirements/queries/mockHandler';
import { creatProductHandlers } from './BOM/productFinder/createProduct/queries/mockHandlers';
import { pricingHandlers } from './BOM/pricing&LeadTime/queries/mockHandler';
import { serviceMockHandlers } from './BOM/pricing&LeadTime/servicesModal/queries/mockHandler';
import { taxesMockHandler } from './BOM/pricing&LeadTime/taxesModal/queries/mockHandler';
import { frieghtMockHandlers } from './BOM/pricing&LeadTime/freightModal/queries/mockHandlers';
import { getLotsMockHandler } from './BOM/pricing&LeadTime/lotPriceModal/queries/mockHandlers';
import { settingsMockHandlers } from './Settings/Manufacturer/queries/mockHandler';
import { reportingHandlers } from './ReportingDashboard/queries/mockHandlers';

export const v2ComponentHandlers = [
    // ...dashboardHandlers,
    ...bomHandlers,
    ...finalDocumentHandlers,
    ...compileSubmittalHandlers,
    ...viewDocumentHandlers,
    ...createBOMHandler,
    ...documentSuggestionHandlers,
    ...selectedDatasheetHandlers,
    ...projectHandlers,
    // ...sharedApisHandlers,
    ...importMoreProductHandler,
    ...searchProductMockHandlers,
    ...creatProductHandlers,
    ...selectProductHandlers,
    ...compileQuoteHandlers,
    ...pricingHandlers,
    ...serviceMockHandlers,
    ...taxesMockHandler,
    ...frieghtMockHandlers,
    ...getLotsMockHandler,
    ...settingsMockHandlers,
    ...reportingHandlers
];
