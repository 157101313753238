import { useEffect, useState } from 'react';

import { Autocomplete, BodyXS, Box } from '@parspec/pixel';

import { validateEmail } from 'src/features/Settings/UserManagement/utils/utils';

interface IEmailInputProps {
    fieldId: string;
    fieldLabel: string;
    handleChange: (name: string, value: any) => void;
    options: { id: string; name: string }[];
    disabled: boolean;
    defaultValue?: { id: string; name: string }[];
    recipientError: string;
    setRecipientError: (value: string) => void;
    setSendButtonError: (val: string) => void;
}

const EmailInput = ({ fieldId, fieldLabel, handleChange, options, disabled, defaultValue, recipientError: errorState, setRecipientError: setErrorState, setSendButtonError }: IEmailInputProps) => {
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

    useEffect(() => {
        const emailArr: string[] = [];
        defaultValue && defaultValue.map((obj) => emailArr.push(obj.id));
        setSelectedEmails(emailArr);
    }, [defaultValue]);

    const isDuplicate = (arr: string[]) => {
        return arr.some(function (item, idx) {
            return arr.indexOf(item) != idx;
        });
    };

    const onChange = (event: any) => {
        setErrorState('');
        setSendButtonError('');

        const { value } = event.target;

        const emailArr: string[] = value.map((obj: any) => {
            if (typeof obj === 'string') return obj;

            return obj.id;
        });

        for (let i = 0; i < value.length; i++) {
            if (typeof value[i] === 'string') {
                if (!validateEmail(value[i].trim())) {
                    setErrorState('One or more email is invalid');
                    return;
                } else if (isDuplicate(emailArr)) {
                    setErrorState('Please remove duplicate emails');
                    return;
                }
            } else if (typeof value[i] === 'object' && !validateEmail(value[i].id.trim())) {
                setErrorState('One or more email is invalid');
                return;
            } else if (isDuplicate(emailArr)) {
                setErrorState('Please remove duplicate emails');
                return;
            }
        }
        const uniqueArr = emailArr.filter((value: string, index: number, self: string[]) => index === self.findIndex((item: string) => item === value));
        setSelectedEmails(uniqueArr);
        handleChange(fieldId, uniqueArr.join(','));
    };

    const handleTextChange = (_e: any, value: string) => {
        setErrorState('');
        setSendButtonError('');
        if (validateEmail(value)) {
            const valueArr = [...selectedEmails, value];
            if (value.includes('.com')) {
                const index = valueArr.indexOf(`${value.split('.com')[0]}.co`);
                valueArr[index] = value;
            }
            const uniqueArr = valueArr.filter((value: string, index: number, self: string[]) => index === self.findIndex((item: string) => item === value));
            setSelectedEmails(uniqueArr);
            handleChange(fieldId, uniqueArr.join(','));
        } else if (value.length > 0) {
            setErrorState('One or more email is invalid');
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" columnGap={2}>
                <Box width="100%">
                    <Autocomplete
                        options={options || []}
                        onChange={onChange}
                        id={fieldId}
                        label={fieldLabel}
                        optionlabelkeyname="name"
                        freeSolo
                        multiple
                        placeholder="Search name or emails"
                        disabled={disabled}
                        defaultValue={defaultValue}
                        error={Boolean(errorState)}
                        onTextFieldChange={handleTextChange}
                        bgColor="white"
                    />
                </Box>
            </Box>
            <BodyXS color="error.main" mt={1}>
                {errorState}
            </BodyXS>
        </Box>
    );
};

export default EmailInput;
