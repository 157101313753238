import { BodySmall } from '@parspec/pixel';
import { GetRowIdParams } from 'ag-grid-community';
import { validateEmail } from '../../Settings/UserManagement/utils/utils';
import { EMAIL_FORMAT_INVALID, REQUIRED_FIELD } from '../../shared/constants';
import { Contact } from './queries/apiTypes';

export const addressOptions = [
    {
        label: 'Same as parent company',
        value: 'true'
    },
    { label: 'Contact specific address', value: 'false' }
];

export const getInitialState = (selectedContact?: Contact) => {
    if (selectedContact) {
        const { first_name, last_name, company_info, role, email, phone, line1, line2, city, state, country, notes, zip, address_inherited_from_company } = selectedContact;
        return { first_name, last_name, company_info, role, email, phone, line1, line2, city, state, country, notes, zip, address_inherited_from_company };
    }
    return { address_inherited_from_company: true, first_name: '', last_name: '' };
};

export const deleteTitle = (selectedItems: number) => {
    if (selectedItems > 1) {
        return `Are you sure you want to delete the ${selectedItems} contact(s) selected?`;
    }
    return 'Are you sure you want to delete this contact?';
};

export const contactDeleteBody = () => {
    return (
        <BodySmall lines={3} color={'primary.main'} limit={false}>
            <strong>These contacts may be used by other teams in your org. </strong>If these contacts are not relevant to you or your team, consider adjusting the visibility for their parent companies
            to exclude your team.
        </BodySmall>
    );
};

export const getRowId = (params: GetRowIdParams) => {
    return params?.data?.id;
};

export enum FORM_FIELDS {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    COMPANY = 'company_info',
    ROLE = 'role',
    EMAIL = 'email',
    PHONE = 'phone',
    ADDRESS_LINE_1 = 'line1',
    ADDRESS_LINE_2 = 'line2',
    COUNTRY = 'country',
    STATE = 'state',
    CITY = 'city',
    ZIP = 'zip',
    NOTES = 'notes',
    ADDRESS_INHERITED = 'address_inherited_from_company'
}

export const checkFormValidation = (formValues: ContactFormFields) => {
    const errors: ContactFormErrorType = {};
    if (!formValues[FORM_FIELDS.FIRST_NAME]?.trim()) {
        errors[FORM_FIELDS.FIRST_NAME] = REQUIRED_FIELD;
    }
    if (!formValues[FORM_FIELDS.LAST_NAME]?.trim()) {
        errors[FORM_FIELDS.LAST_NAME] = REQUIRED_FIELD;
    }
    if (!formValues[FORM_FIELDS.COMPANY]?.id) {
        errors[FORM_FIELDS.COMPANY] = REQUIRED_FIELD;
    }
    if (formValues[FORM_FIELDS.EMAIL]?.trim() && !validateEmail(formValues[FORM_FIELDS.EMAIL]!)) {
        errors[FORM_FIELDS.EMAIL] = EMAIL_FORMAT_INVALID;
    }
    return errors;
};

export const contactDetailBreadcrumbOptions = (contactDetail: Contact) => {
    const { name: companyName, id } = contactDetail?.company_info || {};
    const { first_name, last_name } = contactDetail || {};
    return [
        { displaytext: 'Contact Management', href: '/v2/contactManagement/contacts' },
        { displaytext: companyName, href: `/v2/company/${id}` },
        { displaytext: `${first_name} ${last_name}`, href: '#' }
    ];
};

export interface Company {
    id: number;
    name: string;
    business_type: string;
}

export interface ContactFormFields {
    [FORM_FIELDS.FIRST_NAME]: string;
    [FORM_FIELDS.LAST_NAME]: string;
    [FORM_FIELDS.COMPANY]?: Company;
    [FORM_FIELDS.ROLE]?: string;
    [FORM_FIELDS.EMAIL]?: string;
    [FORM_FIELDS.PHONE]?: string;
    [FORM_FIELDS.ADDRESS_LINE_1]?: string;
    [FORM_FIELDS.ADDRESS_LINE_2]?: string;
    [FORM_FIELDS.CITY]?: string;
    [FORM_FIELDS.STATE]?: string;
    [FORM_FIELDS.COUNTRY]?: string;
    [FORM_FIELDS.NOTES]?: string;
    [FORM_FIELDS.ZIP]?: string | number;
    [FORM_FIELDS.ADDRESS_INHERITED]: boolean;
}

export interface ContactFormErrorType {
    [FORM_FIELDS.FIRST_NAME]?: string;
    [FORM_FIELDS.LAST_NAME]?: string;
    [FORM_FIELDS.COMPANY]?: string;
    [FORM_FIELDS.EMAIL]?: string;
}
