import { useEffect, useState, useTransition } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { QuoteContextProvider } from './QuoteContext';
import Header from '../shared/Header';
import MainContent from './MainContent';
import CircularLoader from '../../../../shared/CircularLoader';
import { useCheckAccessForProductFinderOrQuoting } from '../../../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useFeatureFlag } from 'src/features/shared/UserActivityTracking/useFeatureFlag';
import { useBatchCreatePresetMutation } from './hooks';
import { CustomerTab } from 'src/features/shared/CustomerTab';

function CompileQuote() {
    const { bomId = '', projectId = '0' } = useParams();
    const navigate = useNavigate();

    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess, isLoading } = useCheckAccessForProductFinderOrQuoting();

    const [currentBomId, setCurrentBomId] = useState(bomId);
    const [isPending, startTransition] = useTransition();

    const isInitiateApiCalls = useBatchCreatePresetMutation(Number(projectId));

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!enable_quoting || !quotingAccess) {
            navigate('/v2/dashboard');
        }
    }, [quotingAccess, enable_quoting, isLoading]);

    const handleCustomerTabChange = (bomId: string) => {
        startTransition(() => {
            setCurrentBomId(bomId);
        });
    };

    if (isLoading) {
        return <CircularLoader />;
    }

    return (
        <QuoteContextProvider isInitiateApiCalls={isInitiateApiCalls} bomId={currentBomId}>
            <Header />
            <Box borderBottom={'1px solid'} borderColor={'neutral.main'} width={1}>
                <CustomerTab projectId={Number(projectId)} defaultSelectedBomId={bomId} onTabChange={handleCustomerTabChange} />
            </Box>
            <MainContent isPending={isPending} />
        </QuoteContextProvider>
    );
}

export default CompileQuote;
