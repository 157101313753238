import {
    AddIcon,
    AddKitIcon,
    BodySmall,
    Box,
    CloseIcon,
    ControlPointDuplicateIcon,
    DeleteOutlineIcon,
    FilterAltOffIcon,
    HighlightOutlinedIcon,
    IconButton,
    MoveDownIcon,
    SearchIcon,
    TextField,
    Tooltip,
    VisibilityOffIcon
} from '@parspec/pixel';
import { useState } from 'react';

export type ToolBarT = 'delete' | 'search' | 'clearFilters' | 'hide' | 'unhide' | 'selectedItems' | 'duplicate' | 'add' | 'createKit' | 'move' | 'highlight';

interface ICustomToolBarPanel {
    toolBarOptions: ToolBarT[];
    onTextSearch?: (searchString: string) => void;
    onAdd?: () => void;
    onAddDuplicates?: () => void;
    onDelete?: () => void;
    onHideUnhide?: () => void;
    onCreateKit?: () => void;
    onMove?: () => void;
    onCloseBanner?: () => void;
    onClearFilter?: () => void;
    onHighlight?: () => void;
    selectedRowCount: number;
    disabledToolBarButton: boolean;
    title?: string;
    loading?: boolean;
    toolbarRightSection?: React.ReactNode;
    isKitSelected?: boolean;
}
export const CustomToolBarPanel = (props: ICustomToolBarPanel) => {
    const {
        toolBarOptions,
        onTextSearch,
        onAdd,
        onAddDuplicates,
        onDelete,
        onHideUnhide,
        onCreateKit,
        onMove,
        onClearFilter,
        selectedRowCount,
        disabledToolBarButton,
        onCloseBanner,
        title,
        loading,
        toolbarRightSection,
        onHighlight,
        isKitSelected
    } = props;

    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (onTextSearch) onTextSearch(event.target.value.toLowerCase());
    };

    return (
        <Box display={'flex'} justifyContent="space-between" alignItems={'flex-end'} mb={2} sx={loading ? { PointerEvent: 'none' } : {}}>
            <Box width={'100%'} height={'100%'} display="flex" alignItems="center" gap={1}>
                {title && <BodySmall color="neutral.dark">{title}</BodySmall>}

                {/* For filtering of table */}
                {toolBarOptions?.includes('search') && (
                    <Box width={300}>
                        <TextField label="" placeholder="Search..." startIcon={<SearchIcon fontSize="small" />} size="small" value={searchText} onChange={handleSearchChange} />
                    </Box>
                )}

                {/* for clearing applied filters */}
                {toolBarOptions?.includes('clearFilters') && (
                    <Tooltip title="Clear Filter(s)">
                        <IconButton onClick={() => onClearFilter && onClearFilter()} disabled={loading}>
                            <FilterAltOffIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                )}

                {/* For addition of row */}
                {toolBarOptions?.includes('add') && (
                    <Tooltip title={'Add'}>
                        <Box>
                            <IconButton
                                disabled={loading}
                                onClick={() => {
                                    if (onAdd) onAdd();
                                }}
                            >
                                <AddIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For creating kit */}
                {toolBarOptions?.includes('createKit') && (
                    <Tooltip title={'Create Kit'}>
                        <Box data-testid="create-kit-btn">
                            <IconButton onClick={() => onCreateKit!()} disabled={loading}>
                                <AddKitIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For duplicating selected row */}
                {toolBarOptions?.includes('duplicate') && (
                    <Tooltip title={disabledToolBarButton ? 'Select Item(s) First' : 'Duplicate'}>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    if (onAddDuplicates) onAddDuplicates();
                                }}
                                disabled={disabledToolBarButton || loading}
                            >
                                <ControlPointDuplicateIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For changing the section */}
                {toolBarOptions?.includes('move') && (
                    <Tooltip title={disabledToolBarButton ? 'Select Item(s) First' : 'Change Section'}>
                        <Box data-testid="move-btn">
                            <IconButton onClick={() => onMove!()} disabled={disabledToolBarButton || loading}>
                                <MoveDownIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For hiding and unhiding rows */}
                {toolBarOptions?.includes('hide') && (
                    <Tooltip title={disabledToolBarButton ? 'Select Item(s) First' : 'Hide / Unhide'}>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    if (onHideUnhide) onHideUnhide();
                                }}
                                disabled={disabledToolBarButton || loading}
                            >
                                <VisibilityOffIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For deleting row */}
                {toolBarOptions?.includes('delete') && (
                    <Tooltip title={disabledToolBarButton ? 'Select Item(s) First' : 'Delete'}>
                        <Box>
                            <IconButton
                                disabled={disabledToolBarButton || loading}
                                onClick={() => {
                                    if (onDelete) onDelete();
                                }}
                            >
                                <DeleteOutlineIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For Heighlight Row*/}
                {toolBarOptions?.includes('highlight') && (
                    <Tooltip
                        title={disabledToolBarButton ? 'Select Item(s) First' : isKitSelected ? 'Select items first and ensure no kits or components are of your selection' : 'Highlight / Unhighlight'}
                    >
                        <Box>
                            <IconButton
                                disabled={disabledToolBarButton || loading || isKitSelected}
                                onClick={() => {
                                    if (onHighlight) onHighlight();
                                }}
                            >
                                <HighlightOutlinedIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {/* For showing banner of, number of rows selected */}
                {toolBarOptions?.includes('selectedItems') && selectedRowCount > 0 && <SelectedItemsCount count={selectedRowCount} closeBanner={onCloseBanner!} />}
            </Box>
            <Box width="100%" display="flex" justifyContent="flex-end">
                {toolbarRightSection}
            </Box>
        </Box>
    );
};

export default CustomToolBarPanel;

interface SelectedItemsCountProps {
    count: number;
    closeBanner: () => void;
}
export const SelectedItemsCount = (props: SelectedItemsCountProps) => {
    const { count, closeBanner } = props;
    return (
        <Box p={1} pl={3} pr={2} bgcolor={'primary.main'} color={'secondary.contrastText'} display="flex" alignItems="center" gap={2}>
            <BodySmall color="secondary.contrastText" limit={false}>
                {count} item(s) selected
            </BodySmall>
            <IconButton onClick={closeBanner} sx={{ color: 'secondary.contrastText', margin: 0, padding: 0 }}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};
