import { Grid, Switch, CustomDatePicker, TransitionsColorPicker, Box } from '@parspec/pixel';

import { IColorPickerCircle, IDate } from '../../constants';
import ApplyToAllBomsButton from '../../shared/ApplyToAllBomsButton';
import TextFontType, { ITextFontType } from './TextFontType';

interface IToggleDateWithFontAndColor extends IColorPickerCircle, IDate, ITextFontType {
    onClick?: (open: boolean) => void;
    toggleName?: string;
    sectionName?: string;
}

function ToggleDateWithFontAndColor({
    checked,
    switchLabel,
    onToggle,
    selectFieldValue,
    onSelect,
    dateField,
    onDateChange,
    color,
    onColorChange,
    onColorBlur,
    onClick = () => {},
    toggleName = '',
    sectionName
}: IToggleDateWithFontAndColor) {
    return (
        <Grid container width={1}>
            <Grid item xs={4}>
                <Switch name={toggleName} checked={checked} label={switchLabel} onChange={(e) => onToggle(e)} />
            </Grid>

            <Grid item xs={8} width={1}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={4.5}>
                        <CustomDatePicker label={''} value={dateField} onChange={onDateChange} />
                    </Grid>

                    <Grid item xs={1} p={1} ml={2}>
                        <TransitionsColorPicker onClick={(open) => onClick(open)} onClickAway={onColorBlur} color={color} onChange={onColorChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <TextFontType selectFieldValue={selectFieldValue} onSelect={onSelect} />
                            <ApplyToAllBomsButton sectionName={sectionName} subSectionName={toggleName} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ToggleDateWithFontAndColor;
