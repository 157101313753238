import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodySmall, Box, Button, H5, ModalHeader, ModalFooter, Modal, Skeleton } from '@parspec/pixel';
import { useCompanyInfoQuery } from './queries';
import { useSubscriptionInfo } from '../PlanAndPayment/queries';
import { MAX_LOCATION_PERMITTED_BY_PLAN } from '../../shared/constants';
interface HeaderProps {
    isAdminLoading: boolean;
    userProfile: any;
}

export const Header = (props: HeaderProps) => {
    const { isAdminLoading, userProfile } = props;
    const { data: companyInfo } = useCompanyInfoQuery();
    const { data: subscriptionInfoData, isLoading: subscriptionInfoLoading } = useSubscriptionInfo();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const closeModalHandler = () => setIsOpen(false);

    const CURRENT_PLAN = subscriptionInfoData?.data.my_plan.plan || 'Silver';

    const maxLocationPermitted = MAX_LOCATION_PERMITTED_BY_PLAN[CURRENT_PLAN as keyof typeof MAX_LOCATION_PERMITTED_BY_PLAN];

    const addLocationHandler = () => {
        if (!companyInfo?.data || !subscriptionInfoData?.data) {
            return;
        }

        const currentNumberOfLocations = companyInfo?.data.length;

        if (currentNumberOfLocations >= maxLocationPermitted) {
            setIsOpen(true);
        } else {
            navigate(`/v2/settings/add-location`);
        }
    };
    return (
        <Box>
            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'} gap={2}>
                <Box>
                    <H5 mb={0.5}>Branch Locations</H5>
                    <BodySmall limit={false} color="text.secondary">
                        If your organization has multiple branch locations, you can add them here.
                        <br /> Projects are linked to a location, a user needs to be associated to a location to access its projects.
                    </BodySmall>
                </Box>
                {isAdminLoading && (
                    <Box>
                        <Skeleton variant="rectangular" height={36} width={231} />
                    </Box>
                )}
                {!isAdminLoading && !subscriptionInfoLoading && userProfile?.data.role === 'admin' && (
                    <Box display={'flex'} gap={1}>
                        <Button variant="outlined" color="secondary" onClick={() => navigate('/v2/settings/user-management?inviteUsers=true&tab=active')}>
                            Invite Users
                        </Button>
                        <Button onClick={addLocationHandler}>Add Locations</Button>
                    </Box>
                )}
            </Box>
            <Modal
                open={isOpen}
                footer={
                    <ModalFooter
                        onAccept={() => {
                            window.open('https://www.parspec.io/parspec-pricing', 'blank');
                            closeModalHandler();
                        }}
                        onReject={closeModalHandler}
                        continueButtonLabel={'Learn More'}
                    />
                }
                header={<ModalHeader onClose={closeModalHandler} title={'Location Limit Reached'}></ModalHeader>}
                onClose={closeModalHandler}
            >
                <BodySmall limit={false}>Your active subscription only permits up to {maxLocationPermitted} location(s). Upgrade your plan to unlock more locations.</BodySmall>
            </Modal>
        </Box>
    );
};
