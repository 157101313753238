import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodyMedium, BodyBig, BodySmall, TextField, Button, InfoIcon, VisibilityIcon, VisibilityOffIcon, Snackbar } from '@parspec/pixel';

import { newPasswordVerifier } from '../utils/utils';
import { initialResetPasswordErrorState, initialResetPasswordState } from '../utils/helper';
import { IResetPasswordErrorState, IResetPasswordState } from './ResetPassword.types';
import { useResetPasswordMutation } from '../queries';

interface ResetPasswordProps {
    token: string;
}

export const ResetPasswordForm = (props: ResetPasswordProps) => {
    const { token } = props;
    const [profileState, setProfileState] = useState<IResetPasswordState>(initialResetPasswordState);
    const [errorState, setErrorState] = useState<IResetPasswordErrorState>(initialResetPasswordErrorState);

    const [snackbarData, setSnackbarData] = useState<{ isActive: boolean; message: string }>({
        isActive: false,
        message: ''
    });

    const [showResetPasswordSuccessScreen, setShowResetPasswordSuccessScreen] = useState<boolean>(false);

    const { mutateAsync: postResetPasswordData, isLoading } = useResetPasswordMutation();

    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const isNewPasswordFieldEmpty = profileState.newPassword.data.trim().length === 0;
        const isConfirmPasswordFieldEmpty = profileState.confirmPassword.data.trim().length === 0;

        if (isNewPasswordFieldEmpty || isConfirmPasswordFieldEmpty) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: isNewPasswordFieldEmpty, title: `${isNewPasswordFieldEmpty ? 'Required' : ''}` },
                confirmPassword: { ...errorState.confirmPassword, isError: isConfirmPasswordFieldEmpty, title: `${isConfirmPasswordFieldEmpty ? 'Required' : ''}` }
            });
        }

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
            });
        }

        if (profileState.newPassword.data.trim().length > 0 && profileState.confirmPassword.data.trim().length > 0 && profileState.newPassword.data !== profileState.confirmPassword.data) {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
            });
        }

        if (errorState.newPassword.isError || errorState.confirmPassword.isError) {
            return;
        }

        const payload = {
            password: profileState.confirmPassword.data
        };

        try {
            await postResetPasswordData({ token: token as string, data: payload });
        } catch (err) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Something went wrong, please try again!'
            });
        }

        localStorage.clear();

        setShowResetPasswordSuccessScreen(true);
    };

    const togglePasswordVisibility = (passwordFieldName: string) => {
        if (passwordFieldName === 'newPassword') {
            setProfileState(() => ({ ...profileState, newPassword: { ...profileState.newPassword, showPassword: !profileState.newPassword.showPassword } }));
        } else if (passwordFieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, confirmPassword: { ...profileState.confirmPassword, showPassword: !profileState.confirmPassword.showPassword } }));
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(profileState, fieldName)) {
            return;
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (errorState.newPassword.isError || errorState.confirmPassword.isError) {
                setErrorState({ ...errorState, newPassword: { ...errorState.newPassword, isError: false, title: '' }, confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' } });
            }
        } else {
            if (errorState[fieldName].isError) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
                });
            }
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (fieldValue.length === 0) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
                });
            }
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, [fieldName]: { ...profileState[fieldName], data: fieldValue } }));
        }
    };

    const handleNewPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.confirmPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
                });
            }

            if (!newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }
        } else if (profileState.newPassword.data.trim().length === 0 && profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
            });
        }
    };

    const handleConfirmPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.newPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }

            if (profileState.newPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }
        } else if (profileState.confirmPassword.data.trim().length === 0 && profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
            });
        }
    };

    useEffect(() => {
        if (profileState.newPassword.data.length === 0 && profileState.confirmPassword.data.length === 0) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }

        if (
            profileState.newPassword.data.length > 0 &&
            profileState.confirmPassword.data.length > 0 &&
            profileState.newPassword.data === profileState.confirmPassword.data &&
            newPasswordVerifier(profileState.confirmPassword.data)
        ) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }
    }, [profileState.newPassword.data, profileState.confirmPassword.data]);

    return (
        <>
            <Box>
                {!showResetPasswordSuccessScreen ? (
                    <Box bgcolor="secondary.contrastText" width="337px" padding={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={12}>
                            <BodyMedium limit={false}>Reset Password</BodyMedium>
                            <BodySmall limit={false} mt={4} textAlign="center">
                                You'll get your new password in no time!
                            </BodySmall>
                        </Box>

                        <Box>
                            <form onSubmit={onSubmitHandler} ref={formRef} autoComplete={'off'}>
                                <Box height={'72px'}>
                                    <TextField
                                        required
                                        id={'newPassword'}
                                        name={'newPassword'}
                                        autoComplete={'new-password-2'}
                                        label={'New Password'}
                                        type={profileState.newPassword.showPassword ? 'text' : 'password'}
                                        value={profileState.newPassword.data}
                                        error={errorState.newPassword.isError}
                                        helperText={errorState.newPassword.title}
                                        onKeyDown={keydownHandler}
                                        onChange={fieldChangeHandler}
                                        onBlur={handleNewPasswordBlur}
                                        endIcon={
                                            profileState.newPassword.showPassword ? (
                                                <VisibilityOffIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('newPassword');
                                                    }}
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('newPassword');
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                </Box>
                                <Box height={'72px'}>
                                    <TextField
                                        required
                                        id={'confirmPassword'}
                                        name={'confirmPassword'}
                                        autoComplete={'new-password-3'}
                                        label={'Confirm Password'}
                                        type={profileState.confirmPassword.showPassword ? 'text' : 'password'}
                                        value={profileState.confirmPassword.data}
                                        error={errorState.confirmPassword.isError}
                                        helperText={errorState.confirmPassword.title}
                                        onKeyDown={keydownHandler}
                                        onBlur={handleConfirmPasswordBlur}
                                        onChange={fieldChangeHandler}
                                        endIcon={
                                            profileState.confirmPassword.showPassword ? (
                                                <VisibilityOffIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('confirmPassword');
                                                    }}
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('confirmPassword');
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                </Box>
                            </form>
                        </Box>

                        <Box display="flex" mt={2} bgcolor={'warning.light'} p={2}>
                            <Box color={'warning.main'}>
                                <InfoIcon />
                            </Box>
                            <Box ml={4}>
                                <BodySmall color={'warning.main'} limit={false}>
                                    Password must contain:
                                </BodySmall>
                                <BodySmall color={'warning.main'} limit={false}>
                                    1. At least 8 characters
                                </BodySmall>
                                <BodySmall color={'warning.main'} limit={false}>
                                    2. At least one uppercase letter
                                </BodySmall>
                                <BodySmall color={'warning.main'} limit={false}>
                                    3. At least one lowercase letter
                                </BodySmall>
                                <BodySmall color={'warning.main'} limit={false}>
                                    4. At least one number
                                </BodySmall>
                            </Box>
                        </Box>

                        <Box width="100%" mt={2}>
                            <Button onClick={onSubmitHandler} isLoading={isLoading} fullWidth={true}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box bgcolor="secondary.contrastText" width="337px" padding={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
                            <BodyBig limit={false} fontWeight={500}>
                                Password Change Successful!
                            </BodyBig>
                            <BodySmall limit={false} mt={4} textAlign={'center'}>
                                Your account's password has been changed successfully! Please login to continue
                            </BodySmall>
                        </Box>

                        <Box width="100%" mt={2}>
                            <Button
                                onClick={() => {
                                    navigate('/login');
                                }}
                                isLoading={false}
                                fullWidth={true}
                            >
                                Login
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
            {<Snackbar open={snackbarData.isActive} message={snackbarData.message} onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })} />}
        </>
    );
};
