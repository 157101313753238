import { useQueries, useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import {
    getProjectBoms,
    getProjectDetails,
    getCompanyDetails,
    getUserById,
    getProjectOutOfSync,
    endPoints,
    editProjectDetails,
    getBomLastEditedDocumentDetails,
    getAllTags,
    updateDocumentTag,
    updateProjectDocumentTags,
    getStakeholders,
    updateStakeholderInfo,
    getAllUploadedFilesForProject,
    addFilesForProject,
    editUploadedFilesForProject,
    deleteUploadedFilesForProject,
    downloadUploadedFiles,
    getRemainingSize
} from './apis';
import { deleteBomInDashboard, invalidateBomInfoInDashboard, invalidateOmPackageInfoInDashboard, invalidateProjectInfoInDashboard, invalidateSubmittalInfoInDashboard } from '../../Dashboard/queries';
import { queryClient } from 'src/app/queryClient';
import { deleteBom, duplicateBomData } from '../../Dashboard/queries/apis';
import { docType, SUBMITTAL_DOC, OM_DOC, QUOTE } from '../../shared/constants';
import { ProjectBomResponse, AllTagsResponse, AllTagsTransformResponse, ProjectDetailsReponse, CompanyInfoResponse, ProjectSubmitalOutOfSyncResponse, ProjectStakeholdersResponse } from './apiTypes';
import { invalidateDocumentTags } from '../../Dashboard/queries';
import { unlinkBod } from '../../BOM/queries/apis';
import {
    invalidateBomDataQuery,
    invalidateBomStatsQuery,
    invalidateDatasheetDataQuery,
    invalidateDatasheetStatsQuery,
    invalidateGetOptionSectionQuery,
    invalidateGetSectionsQuery,
    invalidateOmDataQuery,
    invalidateOmStatsQuery
} from '../../BOM/queries';
import { invalidaLotQueries } from '../../BOM/pricing&LeadTime/lotPriceModal/queries';
import { invalidatePricingBodQuery } from '../../BOM/pricing&LeadTime/queries';
import { invalidateServiceQueries } from '../../BOM/pricing&LeadTime/servicesModal/queries';
import { invalidaTaxesPricingQuery } from '../../BOM/pricing&LeadTime/taxesModal/queries';
import { invalidaFreightQuery } from '../../BOM/pricing&LeadTime/freightModal/queries';

const projectDetailsKey = (id: number) => ['project-detail', endPoints.projectDetails(id)];
const projectBomsKey = (id: number) => [endPoints.projectBoms(id)];
const companyKey = (id: number) => [endPoints.companyDetails(id)];
const projectSubmittalOutOfSyncKey = (id: number, docType: docType) => [endPoints.projectSubmittalOutOfSync(id, docType)];
const getUserDetailsKey = (id: number) => [endPoints.userDetails(id)];
const getLastEditedBomDetailsQueryKey = (projectId: string) => [endPoints.bomLastEditedDetails(projectId)];
const allTagsKey = () => [endPoints.allTags()];
const getStakeholdersKey = (projectId: number) => [endPoints.getStakeholders(projectId)];
const getAllProjectFilesForProjectKey = (projectId: number) => [endPoints.getAndAddProjectFilesURL(projectId)];
const getRemainingFileSizeKey = () => [endPoints.fileSize()];

//this needs to be called when location is deleted
export function invalidateAllProjectDetails() {
    queryClient.invalidateQueries(['project-detail']);
}

function invalidateProjectDetails(id: number) {
    queryClient.invalidateQueries(projectDetailsKey(id), { exact: true });
}

export function refetchProjectDetails(id: number) {
    queryClient.refetchQueries(projectDetailsKey(id), { exact: true });
}

export function refetchProjectBomsQuery(id: number) {
    queryClient.refetchQueries(projectBomsKey(id), { exact: true });
}

export function invalidateProjectBomsQuery(id: number) {
    queryClient.invalidateQueries(projectBomsKey(id), { exact: true });
}

export const invalidateGetAllUploadedProjectFilesForProject = (projectId: number) => {
    queryClient.invalidateQueries(getAllProjectFilesForProjectKey(projectId));
};

export const getProjectBomsQueryKeyAndCache = (projectId: number) => {
    return {
        projectBomsCache: queryClient.getQueryData<AxiosResponse<ProjectBomResponse>>(projectBomsKey(projectId)),
        queryKey: projectBomsKey
    };
};

function deleteBomInProject(projectId: number, bomId: number) {
    queryClient.setQueryData<AxiosResponse<ProjectBomResponse>>(projectBomsKey(projectId), (oldData) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.filter((item) => item.id !== bomId)
        };
    });
}

export const useGetProjectDetailsQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectDetailsReponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectDetailsKey(id), () => getProjectDetails(id), options);

export const useGetProjectBomsQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectBomResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectBomsKey(id), () => getProjectBoms(id), options);

export const useGetCompanyDetailsQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<CompanyInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(companyKey(id), () => getCompanyDetails(id), options);

export const useProjectSubmitalOutOfSyncQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectSubmitalOutOfSyncResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectSubmittalOutOfSyncKey(id, SUBMITTAL_DOC), () => getProjectOutOfSync(id, SUBMITTAL_DOC), options);

export const useProjectOMOutOfSyncQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectSubmitalOutOfSyncResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectSubmittalOutOfSyncKey(id, OM_DOC), () => getProjectOutOfSync(id, OM_DOC), options);

export const useProjectQuoteOutOfSyncQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectSubmitalOutOfSyncResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectSubmittalOutOfSyncKey(id, QUOTE), () => getProjectOutOfSync(id, QUOTE), options);

export const useGetLastEditedBomDetailsQuery = (projectId: string) => useQuery(getLastEditedBomDetailsQueryKey(projectId), () => getBomLastEditedDocumentDetails(projectId), { staleTime: 10000 });

export const useGetAllUploadedFilesForProject = (projectId: number) => useQuery(getAllProjectFilesForProjectKey(projectId), () => getAllUploadedFilesForProject(projectId));

export const useDownloadDocument = () => useMutation(downloadUploadedFiles);

function invalidateLastEditedBomDetailsQuery(projectId: string) {
    queryClient.invalidateQueries(getLastEditedBomDetailsQueryKey(projectId));
}

export const invalidateUserDetailsQuery = (userId: number) => {
    queryClient.invalidateQueries(getUserDetailsKey(userId));
};

export const useGetUserDetails = (ids: number[], options: { enabled: boolean }) => {
    return useQueries({
        queries: ids.map((userId) => {
            return {
                queryKey: getUserDetailsKey(userId),
                queryFn: () => getUserById(userId),
                enabled: options.enabled
            };
        })
    });
};

export const useGetProjectStakeholdersQuery = (projectId: number, options?: Omit<UseQueryOptions<AxiosResponse<ProjectStakeholdersResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getStakeholdersKey(projectId), () => getStakeholders(projectId), { staleTime: 0, ...options } as Omit<
        UseQueryOptions<AxiosResponse<ProjectStakeholdersResponse[]>>,
        'queryKey' | 'queryFn'
    >);

export const useGetRemainingFileSize = () => useQuery(getRemainingFileSizeKey(), () => getRemainingSize());

// mutation starts
export const useEditProjectDetails = (editMode = false) => {
    return useMutation(editProjectDetails, {
        onSuccess: (response, variables) => {
            if (!editMode) {
                invalidateProjectDetails(Number(variables.project_id));
            } else {
                queryClient.setQueryData(projectDetailsKey(Number(variables.project_id)), (oldData?: AxiosResponse) => {
                    if (!oldData) return oldData;
                    return {
                        ...oldData,
                        data: { ...response.data }
                    };
                });
            }
            invalidateBomInfoInDashboard();
            invalidateProjectInfoInDashboard();
            invalidateSubmittalInfoInDashboard();
            invalidateOmPackageInfoInDashboard();
        }
    });
};

export const useUpdateStakeholderInfoMutation = () => useMutation(updateStakeholderInfo);
//mutation end

export const useDuplicateBomInProject = () => {
    return useMutation(duplicateBomData, {
        onSuccess: (_response, variables) => {
            refetchProjectBomsQuery(variables.projectId);
            invalidateLastEditedBomDetailsQuery(String(variables.projectId));
        }
    });
};

export const useDeleteBomInProject = (projectId: number) => {
    return useMutation(deleteBom, {
        onSuccess: (_response, variables) => {
            deleteBomInProject(projectId, variables);
            deleteBomInDashboard(variables);
        }
    });
};

export const useAllTagsQuery = () =>
    useQuery({
        queryKey: allTagsKey(),
        queryFn: () => getAllTags(),
        select: (data: AxiosResponse) =>
            data.data.map((option: AllTagsResponse): AllTagsTransformResponse => {
                return {
                    label: option.tag_name,
                    value: option.tag_id,
                    entity_type: option.entity_type,
                    order: option.order
                };
            })
    });

export const useUpdateDocumentTagMutation = () => {
    return useMutation(updateDocumentTag, {
        onSuccess: (_, variables) => {
            invalidateDocumentTags(variables.entity_type as docType);
        }
    });
};

export const useUpdateProjectTagMutation = () => {
    return useMutation({
        mutationFn: updateProjectDocumentTags,
        onSuccess() {
            invalidateDocumentTags('quote');
        },
        retry: 3,
        retryDelay: 1000
    });
};

export const useUpdateAddFilesForProject = () => {
    return useMutation(addFilesForProject, {
        onSuccess: (_, req) => {
            invalidateGetAllUploadedProjectFilesForProject(req.projectId);
        }
    });
};

export const useEditUploadedFilesForProject = () => {
    return useMutation(editUploadedFilesForProject, {
        onSuccess: (_, req) => {
            invalidateGetAllUploadedProjectFilesForProject(req.projectId);
        }
    });
};

export const useDeleteUploadedFilesForProject = () => {
    return useMutation(deleteUploadedFilesForProject, {
        onSuccess: (_, req) => {
            invalidateGetAllUploadedProjectFilesForProject(req.projectId);
        }
    });
};

function unlinkBomInProject(projectId: number, bomId: number) {
    queryClient.setQueryData(projectBomsKey(projectId), (oldData?: AxiosResponse<ProjectBomResponse>) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.map((item) => (item.id === bomId ? { ...item, is_linked: false } : item))
        };
    });
}

export const useUnlinkBOMMutation = () => {
    return useMutation({
        mutationFn: unlinkBod,
        onSuccess(_, req) {
            const { bomId, projectId } = req;
            unlinkBomInProject(projectId, Number(bomId));
            invalidateGetOptionSectionQuery(Number(bomId));
            invalidateGetSectionsQuery(Number(bomId));
            invalidateBomDataQuery(Number(bomId));
            invalidateBomStatsQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
            invalidateOmStatsQuery(bomId);
            invalidatePricingBodQuery(Number(bomId));
            invalidaLotQueries(bomId);
            invalidateDatasheetDataQuery(bomId);
            invalidateOmDataQuery(bomId);
            invalidateServiceQueries(Number(bomId));
            invalidaTaxesPricingQuery(Number(bomId));
            invalidaFreightQuery(bomId);
        },
        retry: 3,
        retryDelay: 1000
    });
};
