import { useCallback, useEffect, useState } from 'react';

import { Tabs } from '@parspec/pixel';

import { CustomerTabsContext } from './CustomerTabContext';
import { CustomerTabLabel } from './CustomerTabLabel';
import { useProjectBomsData } from './hooks';
import { ProjectBomInfo } from '../../Project/queries/apiTypes';
import { ORIENTATION, VARIANT } from './types';
import CustomerTabSkeleton from './CustomerTabSkeleton';
import { useOrderProjectBomsMutation } from './queries';

const renderOptions = (options: Partial<ProjectBomInfo>[]) =>
    options.map(({ id, name, primary_contact, is_primary = false, is_linked = false }) => ({
        label: (
            <CustomerTabLabel
                bomName={name || '-'}
                bomLength={options.length}
                primaryCustomerName={primary_contact?.company?.name || '-'}
                labelId={String(id)}
                isPrimary={is_primary}
                isLinked={is_linked}
            />
        ),
        value: String(id),
        isPrimary: is_primary
    }));

interface ICustomerTab {
    projectId: number;
    orientation?: ORIENTATION;
    variant?: VARIANT;
    showMenu?: boolean;
    showCheckbox?: boolean;
    defaultSelectedBomId: string;
    onTabChange: (bomId: string) => void;
    onCheckboxSelect?: (params: Record<string, boolean>) => void;
    checkBoxState?: Record<string, boolean> | null;
    onDuplicate?: (bomId: string) => void;
    onDelete?: (bomId: string, isPrimary?: boolean, bomLength?: number) => void;
    selectedBomIds?: Set<number>;
    newBomIds?: Set<number>;
    bomOptionsData?: Partial<ProjectBomInfo>[];
    isLoading?: boolean;
    onMoveBomsTab?: (sourceIndex: number, destinationIndex: number) => void;
    updateIfReOrderInitiated?: (value: boolean) => void;
    isReOrderInitiated?: boolean;
}

export const CustomerTab: React.FC<ICustomerTab> = ({
    projectId = 0,
    showMenu = false,
    showCheckbox = false,
    defaultSelectedBomId = '',
    onTabChange = () => {},
    orientation = 'horizontal',
    variant = 'scrollable',
    onCheckboxSelect = () => {},
    onDuplicate = () => {},
    onDelete = () => {},
    selectedBomIds,
    newBomIds,
    bomOptionsData = [],
    isLoading = false,
    checkBoxState = {},
    onMoveBomsTab,
    updateIfReOrderInitiated = () => {},
    isReOrderInitiated = false
}) => {
    const [selectedTab, setSelectedTab] = useState(defaultSelectedBomId);

    const { bomOptions, isLoading: isBomOptionsLoading } = useProjectBomsData({ projectId, selectedBomIds, bomOptionsData });

    const { mutateAsync: reOrderBomMutation } = useOrderProjectBomsMutation();

    useEffect(() => {
        setSelectedTab(defaultSelectedBomId);
    }, [defaultSelectedBomId]);

    const updateCheckBoxSelection = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, selectedTab: string) => {
            const updatedCheckBox = { ...checkBoxState, [selectedTab]: e.target.checked };
            onCheckboxSelect(updatedCheckBox);
        },
        [checkBoxState]
    );

    const onChange = useCallback(
        (newValue: string) => {
            setSelectedTab(newValue);
            onTabChange(newValue);
        },
        [bomOptions]
    );

    const handleDuplicate = useCallback(
        (bomId: string) => {
            onDuplicate(bomId);
        },
        [bomOptions]
    );

    const handleDelete = useCallback(
        (bomId: string, isPrimary?: boolean, bomLength?: number) => {
            onDelete(bomId, isPrimary, bomLength);
        },
        [bomOptions]
    );

    const handleOnMoveBomsTab = async (sourceIndex: number, destinationIndex: number) => {
        // This condition informs the component consuming Boms Tab that the re-ordering of BOMs has initiated.
        if (!isReOrderInitiated) {
            updateIfReOrderInitiated(true);
        }
        if (sourceIndex === destinationIndex || sourceIndex == null || destinationIndex == null || selectedBomIds?.size) {
            return;
        }

        if (bomOptionsData?.length && onMoveBomsTab) {
            // bosmTabData is comming from outside through bomOptionsData.
            // Therefore, we handle the reOrder logic where ever this BomsTab component is being used.
            onMoveBomsTab(sourceIndex, destinationIndex);
        } else {
            // This else branch handles the reOrder logic when we are not providing the tab data from outside.
            // We do not keep the record of any previous order operations and hence we send the payload (an array of length one).
            if (bomOptions.length > 1) {
                // we must not re-order Primary BOM
                if (bomOptions[sourceIndex]?.is_primary || bomOptions[destinationIndex]?.is_primary) {
                    return;
                }
                let previousBomId: number | null = bomOptions[destinationIndex].id!;

                if (sourceIndex > destinationIndex) {
                    previousBomId = destinationIndex - 1 >= 0 ? bomOptions[destinationIndex - 1].id! : null;
                }

                await reOrderBomMutation({
                    projectId,
                    sourceIndex,
                    destinationIndex,
                    payload: [
                        {
                            previous_bom_id: previousBomId,
                            bom_ids: [bomOptions[sourceIndex].id!]
                        }
                    ]
                });
            }
        }
    };
    return (
        <CustomerTabsContext.Provider value={{ selectedTab, checkBoxState, showMenu, showCheckbox, newBomIds, updateCheckBoxSelection, onDelete: handleDelete, onDuplicate: handleDuplicate }}>
            {(bomOptionsData?.length && !isLoading) || (bomOptions.length && !isBomOptionsLoading) ? (
                <Tabs
                    isDragable
                    onMoveBomsTab={handleOnMoveBomsTab}
                    isEnableTabsBackground
                    variant={variant}
                    orientation={orientation}
                    options={renderOptions(bomOptions)}
                    selectedTab={selectedTab}
                    onChange={onChange}
                />
            ) : (
                <CustomerTabSkeleton orientation={orientation} />
            )}
        </CustomerTabsContext.Provider>
    );
};
