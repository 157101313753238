import { useNavigate } from 'react-router';

import { Box, BodyMedium, Link, H3, BodyBig } from '@parspec/pixel';

export const UnauthorisedContent = () => {
    const navigate = useNavigate();
    return (
        <Box bgcolor={'#091535'} width="100vw " height="100vh" padding="100px" color={'secondary.contrastText'} display="flex" alignItems="center" justifyContent="space-around">
            <Box>
                <Box>
                    <H3 color={'secondary.contrastText'} sx={{ fontWeight: 100 }}>
                        Unauthorized Access
                    </H3>
                </Box>
                <BodyBig limit={false} color={'secondary.contrastText'} fontWeight={400}>
                    Looks like you don’t have access to this page. Learn about
                </BodyBig>

                <Box display="flex" alignItems="center">
                    <BodyBig limit={false} color={'secondary.contrastText'} fontWeight={400} mr={1}>
                        permission related restrictions{' '}
                    </BodyBig>
                    <Link
                        href="http://knowledgebase.parspec.io/knowledge/parspec-permissions"
                        target="_blank"
                        rel="noopener"
                        sx={{
                            underline: 'none',
                            textDecoration: 'none',
                            '&:hover': {
                                color: 'info.main'
                            }
                        }}
                    >
                        <BodyBig limit={false} color={'info.main'} mr={1}>
                            here.
                        </BodyBig>
                    </Link>
                </Box>

                <Box mt={5}>
                    <BodyMedium limit={false} onClick={() => navigate('/v2/dashboard')} color={'warning.main'} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                        Go back to my Dashboard
                    </BodyMedium>
                </Box>
            </Box>
            <Box>
                <img src={'assets/images/Unauthorised-logo.svg'} alt="parspec-text" vertical-align="middle" width={'522px'} height={'508px'} />
            </Box>
        </Box>
    );
};
