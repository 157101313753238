import { useMemo } from 'react';

import { useParspecMfg, useCompanyMfgQuery, useGetListOfManufactuerListQuery } from '../../../Settings/Manufacturer/queries';
import { ProjectDetailsReponse } from '../../../Project/queries/apiTypes';
import { getAllManufacturersForDropdown } from './utils';
import { VISIBLITY } from '../../../Settings/Manufacturer/manufacturerList/constants';
import { MANUFACTURER_GROUP_FOR_MULTI_SELECT } from '../setRequirements/constants';

export function useManufacturerOptions(projectDetails?: ProjectDetailsReponse) {
    const { data: parspecManufacturerData } = useParspecMfg();
    const { data: companyManufacturerData } = useCompanyMfgQuery();
    const { data: listOfManufactuerListData } = useGetListOfManufactuerListQuery();
    const eligbleMfgLists = useMemo(() => {
        if (!listOfManufactuerListData?.data) {
            return;
        }
        return listOfManufactuerListData.data.filter(
            (mfgList) => mfgList.visibility !== VISIBLITY.PROTECTED || (mfgList.company_id?.includes(projectDetails?.company || 0) && mfgList.company_group_man_id.length > 0)
        );
    }, [listOfManufactuerListData?.data, projectDetails]);

    const { manufacturerOptions, mfgListGroupForDropdown } = useMemo(
        () => getAllManufacturersForDropdown(parspecManufacturerData?.data, companyManufacturerData?.data, eligbleMfgLists),
        [parspecManufacturerData?.data, companyManufacturerData?.data, eligbleMfgLists]
    );

    const manufacturerGroup = useMemo(() => {
        return [...MANUFACTURER_GROUP_FOR_MULTI_SELECT, ...(mfgListGroupForDropdown?.map((mfgList) => ({ value: mfgList.id, label: mfgList.name })) || [])];
    }, [mfgListGroupForDropdown]);
    const manufacturerDropdownInfo = useMemo(() => ({ manufacturerGroup, manufacturerOptions }), [manufacturerGroup, manufacturerOptions]);

    return manufacturerDropdownInfo;
}
