import { AxiosResponse } from 'axios';

import {
    IUpdateText as IUpdateCacheParams,
    StoredManufacturerPairResponse,
    IQueryResponse,
    IGetPriceAndLeadtimeTransformedResponse,
    ICoverpageTransformedResponse,
    IGeneralTermsAndConditionTransformedResponse,
    IHeaderFooterTransformedResponse,
    IPriceAndLeadTimeOptionsTransformedResponse
} from '../../queries/apiTypes';

import { queryClient } from 'src/app/queryClient';
import { QueryKey } from '@tanstack/query-core';

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getSnakeCase(str: string): string {
    return str.split(' ').join('_').toLowerCase();
}

export function transformPriceAndLeadTime<T extends IQueryResponse>(data: AxiosResponse<T[], any> | undefined): IGetPriceAndLeadtimeTransformedResponse {
    const response: any = {};

    if (data) {
        data?.data.forEach((item: T) => {
            response[item.field_name] = { field_value: item.field_value, is_active: item.is_active };
        });
    }

    return response;
}

export function transformPriceAndLeadTimeOptions<T extends IQueryResponse>(data: AxiosResponse<T[], any> | undefined): IPriceAndLeadTimeOptionsTransformedResponse {
    const response: any = {};

    if (data) {
        data?.data.forEach((item: T) => {
            response[item.field_name] = { field_value: item.field_value, is_active: item.is_active };
        });
    }

    return response;
}

export function transformCoverPage<T extends IQueryResponse>(data: AxiosResponse<T[], any>): ICoverpageTransformedResponse {
    const response: any = {};

    data.data.forEach((item: T) => {
        response[item.field_name] = { field_value: item.field_value, is_active: item.is_active };
    });

    return response;
}

export function transformGeneralTermAndCondition<T extends IQueryResponse>(data: AxiosResponse<T[], any>): IGeneralTermsAndConditionTransformedResponse {
    const response: any = {};

    data.data.forEach((item: T) => {
        response[item.field_name] = { field_value: item.field_value, is_active: item.is_active };
    });

    return response;
}

export function transformHeaderFooter<T extends IQueryResponse>(data: AxiosResponse<T[], any>): IHeaderFooterTransformedResponse {
    const response: any = {};

    data.data.forEach((item: T) => {
        response[item.field_name] = { field_value: item.field_value, is_active: item.is_active };
    });

    return response;
}

type Data = AxiosResponse;
type Item = IQueryResponse;
type AttributeType = 'is_active' | 'field_value' | 'field_name';

export enum Attribute {
    isActive = 'is_active',
    fieldValue = 'field_value'
}

export enum ManufacturerAttribute {
    fieldName = 'field_name',
    fieldValue = 'field_value'
}

export function updateCacheOnMutate(queryKey: QueryKey, payload: Partial<IUpdateCacheParams>, attribute: AttributeType) {
    queryClient.setQueryData(queryKey, (oldData?: Data) => {
        if (oldData) {
            return updateCache(oldData, payload, attribute);
        }
        return oldData;
    });
}

export function updateCache(oldData: Data, variables: Partial<IUpdateCacheParams>, attribute: AttributeType) {
    return {
        ...oldData,
        data: oldData?.data.map((item: Item) => {
            if (item.field_name === variables.fieldName) {
                item = { ...item, [attribute]: variables[attribute as keyof Partial<IUpdateCacheParams>] };
            }

            return item;
        })
    };
}

export function updateManufacturerCacheOnMutate(queryKey: QueryKey, payload: StoredManufacturerPairResponse, attribute: AttributeType) {
    queryClient.setQueryData(queryKey, (oldData?: AxiosResponse<StoredManufacturerPairResponse[]>) => {
        if (oldData) {
            return updateManufacturerCache(oldData, payload, attribute);
        }
        return oldData;
    });
}

export function updateManufacturerCache(oldData: AxiosResponse<StoredManufacturerPairResponse[]>, variables: StoredManufacturerPairResponse, attribute: Omit<AttributeType, 'is_active'>) {
    return {
        ...oldData,
        data: oldData?.data.map((item: StoredManufacturerPairResponse) => {
            if (item.id === variables.id) {
                item = { ...item, [attribute as keyof StoredManufacturerPairResponse]: variables[attribute as keyof StoredManufacturerPairResponse] };
            }

            return item;
        })
    };
}

export const optionsForNumber = [
    {
        id: 'Unique ID',
        name: 'Unique ID'
    },
    {
        id: 'Initials - Date - Unique ID',
        name: 'Initials - Date - Unique ID'
    },
    {
        id: 'Custom Entry',
        name: 'Custom Entry'
    }
];

export const CUSTOM_ENTRY_QUOTE_NUMBER_ERROR = 'Required';
