import { axios } from 'src/app/Axios';
import { LocationResponse, AllCompanyResponse, IContactsResponse } from './apiTypes';

export const endPoints = {
    allCompanyDetails: `/user/projects_info/`,
    allLocations: (userId: number) => `/api/user_info/${userId}/`,
    bomDetails: (bomId: number) => `/bom/${bomId}`,
    contacts: (companyId?: number) => `/api/${companyId}/contacts`,
    manageBom: `/project/manage_boms`
};

export const manageBom = (data: any) => {
    return axios.patch(endPoints.manageBom, data);
};

export const getAllCompanyDetails = () => {
    return axios.get<AllCompanyResponse[]>(endPoints.allCompanyDetails);
};

export const getAllLocations = (userId: number) => {
    return axios.get<LocationResponse[]>(endPoints.allLocations(userId));
};

export const getContacts = (companyId?: number) => axios.get<IContactsResponse>(endPoints.contacts(companyId));
