import { Accordion, BodyMedium, Box, CircularProgress, Pagination } from '@parspec/pixel';
import { AccordionCarousalProps } from '../../queries/apiTypes';

export const AccordionCarousal = (props: AccordionCarousalProps) => {
    const { children, totalPages, page, title, setPage, isAllDataFetching, dataLength } = props;

    const sectionHeader = (
        <Box display="flex" gap={1} ml={1}>
            <BodyMedium fontWeight={500}> {`${title} (${dataLength})`} </BodyMedium>
            {isAllDataFetching && (
                <Box mt={1}>
                    <CircularProgress color="primary" />
                </Box>
            )}
        </Box>
    );
    return (
        <Box>
            <Accordion
                options={[
                    {
                        labelId: String(title),
                        summary: sectionHeader,
                        details: <Box mt={2}>{children}</Box>,
                        rightSummary: (
                            <Pagination
                                size="small"
                                page={page}
                                onChange={(_e, clickePage: number | null) => {
                                    setPage(clickePage as number);
                                }}
                                color={'primary'}
                                count={totalPages}
                                boundaryCount={0}
                                siblingCount={1}
                            />
                        )
                    }
                ]}
                summaryPointerEvent={'none'}
                sx={{ boxShadow: 'none', bgcolor: 'neutral.light' }}
            />
        </Box>
    );
};
