import { Button, Grid, CropFreeIcon, Box } from '@parspec/pixel';
import { useTrackIntercomEvent } from '../../../shared/hooks/useIntercomHook';
interface PdfViewerHeaderProps {
    isPdf: boolean;
    setToolModeToAnnotation?: () => void;
    handleChangeFile: (param: boolean) => void;
}
const PdfViewerHeader = (props: PdfViewerHeaderProps) => {
    const { trackIntercomEvent } = useTrackIntercomEvent();
    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" py={2} px={6} spacing={2}>
                <Grid item xs={9}>
                    <Box>Click and drag to create one or more selection(s) containing Type, Manufacturer, Model Number, Quantity and Description information for the desired products.</Box>
                </Grid>

                <Grid item xs={3}>
                    <Grid container justifyContent={'flex-end'} gap={2}>
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={() => props.handleChangeFile(true)}>
                                Change File
                            </Button>
                        </Grid>
                        <Grid item>
                            {props.isPdf ? (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        props?.setToolModeToAnnotation!();
                                        trackIntercomEvent('Select_Schedule');
                                    }}
                                    endIcon={<CropFreeIcon />}
                                >
                                    Select Schedule
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PdfViewerHeader;
