import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@parspec/pixel';

import PreviewContext from './Context';
import AccordionEditor from './AccordionEditor';
import PdfPreview from './PdfPreview';
import Loader from './Loader';
import { useCustomPageQuery, useCoverPageToggleConfigurationQuery, useTocToggleConfigurationQuery, useDocumentPresetQuery } from './queries';
import { ENTITY } from '../constants';

interface MainContentPropTypes {
    isFetch: boolean;
    setAccordionFieldName: React.Dispatch<React.SetStateAction<string>>;
    handlePreviewImageClick: () => void;
    isPreviewImageLoading: boolean;
    accordionFieldName: string;
}

const MainContent: React.FC<MainContentPropTypes> = ({ isFetch, setAccordionFieldName, handlePreviewImageClick, isPreviewImageLoading, accordionFieldName }) => {
    const { presetResponse } = useContext(PreviewContext);
    const { bomId, documentType } = useParams();

    const { data: coverPageToggleConfigurationData, isLoading: isCoverPageToggleConfigLoading } = useCoverPageToggleConfigurationQuery(bomId!, documentType!, presetResponse);
    const { data: tocToggleConfigurationData, isLoading: isTocToggleConfigLoading } = useTocToggleConfigurationQuery(bomId!, documentType!, presetResponse);
    const { data: notesPagePresetData } = useDocumentPresetQuery(bomId!, documentType!, ENTITY.NOTES_PAGE_ENTITY, presetResponse);
    const { data: headerFooterPresetData } = useDocumentPresetQuery(bomId!, documentType!, ENTITY.HEADER_FOOTER_ENTITY, presetResponse);

    const { data: customCoverPageData, isLoading: isCustomCoverPageLoading } = useCustomPageQuery(bomId!, documentType!, ENTITY.COVER_PAGE_ENTITY, presetResponse);

    const [isCoverPageVisible, setIsCoverPageVisible] = useState(false);
    const [isTocVisible, setIsTocVisible] = useState(false);
    const [isNotesVisible, setIsNotesVisible] = useState(false);
    const [isHeaderAndFooterVisible, setIsHeaderAndFooterVisible] = useState(false);

    useEffect(() => {
        if (coverPageToggleConfigurationData?.cover_page_section) {
            setIsCoverPageVisible(true);
        } else {
            setIsCoverPageVisible(false);
        }
    }, [coverPageToggleConfigurationData?.cover_page_section]);

    useEffect(() => {
        if (tocToggleConfigurationData?.toc_section) {
            setIsTocVisible(true);
        } else {
            setIsTocVisible(false);
        }
    }, [tocToggleConfigurationData?.toc_section]);

    useEffect(() => {
        if (notesPagePresetData?.notes_section?.is_active) {
            setIsNotesVisible(true);
        } else {
            setIsNotesVisible(false);
        }
    }, [notesPagePresetData?.notes_section?.is_active]);

    useEffect(() => {
        if (headerFooterPresetData?.header_footer_section?.is_active) {
            setIsHeaderAndFooterVisible(true);
        } else {
            setIsHeaderAndFooterVisible(false);
        }
    }, [headerFooterPresetData?.header_footer_section?.is_active]);

    const handleContentVisibility = (panel: string) => {
        switch (panel) {
            case ENTITY.COVER_PAGE_ENTITY:
                setAccordionFieldName(ENTITY.COVER_PAGE_ENTITY);
                break;

            case ENTITY.TOC_ENTITY:
                setAccordionFieldName(ENTITY.TOC_ENTITY);
                break;

            case ENTITY.NOTES_PAGE_ENTITY:
                setAccordionFieldName(ENTITY.NOTES_PAGE_ENTITY);
                break;

            case ENTITY.HEADER_FOOTER_ENTITY:
                setAccordionFieldName(ENTITY.HEADER_FOOTER_ENTITY);
                break;

            case ENTITY.LAYOUT:
                setAccordionFieldName(ENTITY.LAYOUT);
                break;

            default:
                break;
        }
    };

    const handleCoverPage = (value: boolean) => {
        setIsCoverPageVisible(value);
    };

    const handleToc = (value: boolean) => {
        setIsTocVisible(value);
    };

    const handleHeaderAndFooter = (value: boolean) => {
        setIsHeaderAndFooterVisible(value);
    };

    const handleNotesSection = (value: boolean) => {
        setIsNotesVisible(value);
    };

    if (isCustomCoverPageLoading || isCoverPageToggleConfigLoading || isTocToggleConfigLoading) {
        return <Loader />;
    }

    return (
        <Box width={'100%'}>
            <Grid container>
                <Grid item xs={6}>
                    <AccordionEditor
                        is_custom_cover_page={customCoverPageData?.is_custom_cover_page}
                        name={customCoverPageData?.custom_cover_page?.filename}
                        size={customCoverPageData?.size}
                        onCoverPageChange={handleCoverPage}
                        onTocChange={handleToc}
                        onHeaderFooterChange={handleHeaderAndFooter}
                        onNotesChange={handleNotesSection}
                        handleContentVisibility={handleContentVisibility}
                        coverPageToggleConfigurationData={coverPageToggleConfigurationData!}
                        tocToggleConfigurationData={tocToggleConfigurationData!}
                    />
                </Grid>
                <Grid item xs={6}>
                    {isPreviewImageLoading && accordionFieldName !== ENTITY.LAYOUT ? (
                        <Loader />
                    ) : (
                        <PdfPreview
                            isFetch={isFetch}
                            onClick={handlePreviewImageClick}
                            accordionFieldName={accordionFieldName}
                            isCoverPageVisible={isCoverPageVisible}
                            isTocVisible={isTocVisible}
                            isNotesVisible={isNotesVisible}
                            isHeaderAndFooterVisible={isHeaderAndFooterVisible}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default MainContent;
