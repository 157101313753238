export const SnackbarMessages = {
    DOWNLOAD_SUCCESS: 'Downloaded Successfully',
    DELETING: 'Deleting...',
    DELETE_SUCCESS: 'Deleted Successfully',
    UPDATE_SUCCESS: 'Updated successfully',
    SUBMITTAL_PRESET: 'Submittal preset created successfuly',
    O_M_PRESET: 'O&M preset created successfuly',
    DOWNLOADING: 'Downloading...',
    UPDATING: 'Updating...',
    RENAME_SUCCESS: 'Renamed Successfully',
    UPLOAD_SUCCESS: 'Uploaded Successfully'
};

export const DocumentType = {
    SUBMITTAL: 'submittal',
    O_M: 'o_m',
    QUOTE: 'quote'
};

export enum DocType {
    SUBMITTAL = 'submittal',
    O_M = 'o_m',
    QUOTE = 'quote'
}

export const getDocumentStatusMessage = (docType: string, isUpdated: boolean) => {
    let message = '';
    let document = `${docType.substring(0, 1).toUpperCase()}${docType.substring(1)}`;

    if (document === 'O_m') {
        document = `O&M`;
    }

    if (isUpdated) {
        message = `${document} statuses updated`;
    } else {
        message = `Updating ${document} statuses...`;
    }

    return message;
};

export const ENTITY = {
    COVER_PAGE_ENTITY: 'cover_page',
    TOC_ENTITY: 'toc',
    NOTES_PAGE_ENTITY: 'notes_page',
    HEADER_FOOTER_ENTITY: 'header_footer',
    LAYOUT: 'layout',
    QUOTE: 'quote'
};

export const SUBMITTAL = 'submittal';

export const O_M = 'o_m';

export const QUOTE = 'quote';

export const accessOptions = [
    { value: 'anyone', label: 'All Users' },
    { value: 'users', label: 'Only Specific Emails' }
];

export const actionOptions = [
    { value: 'true', label: 'View and download' },
    { value: 'false', label: 'Only View' }
];

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const passwordSnackbarMessage = {
    NONE: 'NONE',
    APPLIED: 'Password has been applied',
    REMOVED: 'Password has been removed.',
    COPIED: 'Link copied to clipboard',
    SAVE_SUCCESS: 'Saved Successfully'
};

export const DEFAULT_TITLE_FOR_VERSION_NOTE = 'Version Notes';
export const DEFAULT_SUB_TITLE_FOR_VERSION_NOTE = 'Let your reviewers know what changed from the previous version. This note will be visible on the shared link, and final documents tab.';
export const QUOTE_TITLE_FOR_VERSION_NOTE = 'Create Quote(s)';
export const QUOTE_SUBTITLE_FOR_VERSION_NOTE = `Select the BOMs you would like to create quotes for and leave notes for your reviewers. Version notes will be visible on the shared link, and final documents tab. `;
