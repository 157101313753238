export const productRowDetails: { label: string; key: string }[] = [
    { label: 'Acceptable Manufacturer(s)', key: 'acceptable_manufacturers' },
    { label: 'Product Category', key: 'product_category' },
    { label: 'Application', key: 'application' },
    { label: 'Mounting Type', key: 'mounting_type' },
    { label: 'Environment', key: 'environment' },
    { label: 'Dimming Protocol', key: 'dimming_protocol' },
    { label: 'Dimming Range', key: 'dimming_range' },
    { label: 'Voltage', key: 'voltage' },
    { label: 'Lumens', key: 'lumens' },
    { label: 'CCT', key: 'temperature' },
    { label: 'CRI', key: 'cri' },
    { label: 'Additional Criteria', key: 'additional_criteria' },
    { label: 'Reference Image', key: 'reference_images' }
];

export const options = [
    {
        label: 'Add a link to a file',
        value: 'addLink'
    },
    { label: 'Upload a file', value: 'addFile' },
    { label: `Reuse from another product in this BOM`, value: 'reuse' }
];

export const sortByDropdownOptions = [
    { label: 'Sort by manufacturer', value: 'sort_by_manufacturer' },
    { label: 'Sort by score', value: 'sort_by_score' }
];

export enum SORT {
    SORT_BY_MANUFACTURER = 'sort_by_manufacturer',
    BY_SCORE = 'sort_by_score'
}
