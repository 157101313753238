
export const capitalizeText = (text: string) => {
    if (text) {
        return text[0].toUpperCase() + text.slice(1);
    }
    return '';
};

export const getUserCountTooltip = (countType: string) => {
    if (countType === 'your org') return 'Amount of times users from your team selected this datasheet for this manufacturer and product family.';
    if (countType === 'all users') return 'Amount of times Parspec users selected this datasheet for this manufacturer and product family.';
    return '';
};

