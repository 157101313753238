import { Box, Grid, Skeleton } from '@parspec/pixel';
import { FC } from 'react';

const ContactDetailSkeleton: FC = () => {
    return (
        <Grid container spacing={8}>
            <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={4}>
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="36px" variant="rectangular" />
                    <Skeleton height="100px" variant="rectangular" />
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: 'none' }}>
                <Box display="flex" flexDirection="column" gap={4}>
                    <Skeleton height="60px" variant="rectangular" />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ContactDetailSkeleton;
