import { BodySmall, Box, BodyXS } from '@parspec/pixel';
import { Activity } from '../queries/apiTypes';

interface ActivitySectionProps {
    title: string;
    data?: Activity[];
    noDataMessage: string;
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ActivitySection = ({ title, data, noDataMessage }: ActivitySectionProps) => (
    <>
        <Box color="tertiary.main" paddingBottom={3}>
            {title}
        </Box>
        <Box
            sx={{
                borderTop: '1px solid',
                borderColor: 'secondary.contrastText',
                paddingBottom: 4
            }}
        ></Box>
        {data && data.length ? (
            data.map((post) => {
                return (
                    <Box paddingBottom={4}>
                        <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center" gap={1} marginBottom={1}>
                            <Box width={150}>
                                <BodySmall color="secondary.ContrastText">{post.user} </BodySmall>
                            </Box>
                            <Box>
                                <BodyXS color="secondary.ContrastText">{post.time.toString()}</BodyXS>
                            </Box>
                        </Box>
                        <Box sx={{ fontWeight: '400' }}>
                            <BodyXS color="secondary.ContrastText">
                                {capitalizeFirstLetter(post.activity) + ' '}
                                {'Version' + ' ' + post.version}
                            </BodyXS>
                        </Box>
                    </Box>
                );
            })
        ) : (
            <Box paddingBottom={4}>{noDataMessage}</Box>
        )}
    </>
);
