import { NumericFormat } from 'react-number-format';

export const CustomDollarFormatterComponent = (props: any) => {
    // The values should be number in your Textfield component
    // Comsume this component in the InputProps of your Textfield component
    // Example:
    // InputProps={{
    //  startAdornment: <InputAdornment position="start">$</InputAdornment>,
    //  inputComponent: CustomDollarNumberFormat
    // }}

    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            // For only integer values - currently only used in Estimated Value field in Create/Edit Project
            decimalScale={0}
            isAllowed={(values) => {
                const { floatValue } = values;

                if (!floatValue && floatValue !== 0) {
                    return true;
                } else {
                    return floatValue < 999999999999.99;
                }
            }}
        />
    );
};
