import { EmailFormType } from '.';
import { CompanyObjType } from '../../../ContactManagement/Company/queries/apiTypes';
import { Contact } from '../../../ContactManagement/Contacts/queries/apiTypes';
import { msalInstance } from '../../../Settings/MyProfile/EmailPreferences/SyncAccount';
import { MSAL_ACCESSTOKEN_LS_KEY, MSAL_CONFIG, MSAL_TOKEN_GENERATED_AT_LS_KEY } from './constants';

export const makeMsEmailBody = async (emailObj: EmailFormType) => {
    const { to, subject, attachments, body, cc } = emailObj;

    const messageBody = body.replaceAll('style="white-space: pre-wrap;"', '');

    return JSON.stringify({
        message: {
            subject: subject,
            body: {
                contentType: 'html',
                content: messageBody
            },
            toRecipients: to.split(',').map((el) => {
                return {
                    emailAddress: {
                        address: el.trim()
                    }
                };
            }),
            ccRecipients:
                cc.length > 0
                    ? cc.split(',').map((el) => {
                          return {
                              emailAddress: {
                                  address: el.trim()
                              }
                          };
                      })
                    : [],
            attachments: await getMsAttachmentList(attachments)
        }
    });
};

export const makeGoogleMailBody = async (emailObj: EmailFormType) => {
    const { to, subject, attachments, body, cc } = emailObj;

    const boundary = 'boundary_boundary';

    const messageBody = body.replaceAll('style=white-space: pre-wrap;', '');

    const attachmentParts = await getGoogleAttachments(attachments);

    const messageParts = [
        `To: ${to}`,
        `Cc: ${cc}`,
        `Subject: ${subject}`,
        'Content-Type: multipart/mixed; boundary="' + boundary + '"',
        '',
        `--${boundary}`,
        'Content-Type: text/html; charset="UTF-8"',
        '',
        messageBody,
        '',
        ...attachmentParts
    ];

    messageParts.push(`--${boundary}--`);

    return messageParts.join('\n');
};

export const getGoogleAttachments = async (attachments: File[]) => {
    const attachmentParts = attachments.map(async (attachment) => {
        const base64Str: any = await getFileInBase64(attachment);
        const attachmentData = [
            `Content-Type: ${attachment.type}`,
            `MIME-Version: 1.0`,
            'Content-Transfer-Encoding: base64',
            `Content-Disposition: attachment; filename="${attachment.name}"`,
            '',
            base64Str,
            ''
        ].join('\n');

        return ['--boundary_boundary', attachmentData].join('\n');
    });
    const attachmentsArr = await Promise.all(attachmentParts);
    return attachmentsArr;
};

export const getMsAttachmentList = async (attachments: File[]) => {
    const arr = attachments.map(async (attachment: File) => {
        const base64Str = await getFileInBase64(attachment);
        return {
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: attachment.name,
            contentBytes: base64Str
        };
    });
    const attachmentsArr = await Promise.all(arr);
    return attachmentsArr;
};

const getFileInBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result)?.split(',')[1]);
        reader.onerror = reject;
    });

export const groupMsEMailsToThreads = (emails: any[]) => {
    const threadsMap: any = {};
    emails.forEach((email) => {
        const conversationId = email.conversationId;
        if (!threadsMap[conversationId]) {
            threadsMap[conversationId] = [email];
        } else {
            threadsMap[conversationId].push(email);
        }
    });
    return Object.values(threadsMap);
};

export const groupGoogleMailToThread = (emails: any[]) => {
    const threadsMap: any = {};
    emails.forEach((email) => {
        const threadId = email.threadId;
        if (!threadsMap[threadId]) {
            threadsMap[threadId] = [email];
        } else {
            threadsMap[threadId].push(email);
        }
    });
    return Object.values(threadsMap);
};

export const makeRecipientsList = (companyArr: CompanyObjType[], contactArr: Contact[], locationId: number) => {
    const options: { id: string; name: string }[] = [];
    const selectedCompanyIds: number[] = [];

    companyArr.forEach((companyObj) => {
        const { email, name, visible_to, visible_to_all, id } = companyObj;

        const visibleLocations = visible_to.map((obj) => obj.id);

        if ((visible_to_all || visibleLocations.includes(locationId)) && email.length) {
            selectedCompanyIds.push(id);
            options.push({ id: email, name: `${name} (${email})` });
        }
    });

    contactArr.forEach((contactObj) => {
        const { email, first_name, last_name, company_id } = contactObj;

        if (company_id && selectedCompanyIds.includes(company_id) && email?.length) options.push({ id: email, name: `${first_name} ${last_name} (${email})` });
    });

    return removeDuplicates(options);
};

function removeDuplicates(arr: { id: string; name: string }[]) {
    const seenIds = new Set<string>();
    const uniqueArr = [];

    for (const obj of arr) {
        if (!seenIds.has(obj.id)) {
            seenIds.add(obj.id);
            uniqueArr.push(obj);
        }
    }

    return uniqueArr;
}

export const getUpdatedMsalAccessToken = async () => {
    const tokenGeneratedAt = localStorage.getItem(MSAL_TOKEN_GENERATED_AT_LS_KEY);
    const savedDate = new Date(parseInt(tokenGeneratedAt || ''));
    const difference = Math.round((Date.now() - savedDate.getTime()) / 60000);

    if (difference > 30) {
        await msalInstance.initialize();
        const activeAccount = msalInstance.getAllAccounts();
        if (activeAccount.length > 0) {
            const { accessToken } = await msalInstance.acquireTokenSilent({ scopes: MSAL_CONFIG.auth.OIDCOptions.defaultScopes, account: activeAccount[0] });
            localStorage.setItem(MSAL_ACCESSTOKEN_LS_KEY, accessToken);
            localStorage.setItem(MSAL_TOKEN_GENERATED_AT_LS_KEY, String(Date.now()));
        } else {
            return '';
        }
    }

    return localStorage.getItem(MSAL_ACCESSTOKEN_LS_KEY) || '';
};
