import { BodySmall, Box } from '@parspec/pixel';

import { SubtotalsNotApplicable } from '../shared/SubtotalsNotApplicable';
import { MainProductDataType } from './types';
import { ValueFormatterParams, ValueSetterParams } from 'ag-grid-community';
import { getCellAmountFormatter } from '../../shared/utils';
import { getUpdatedPricingInfoForChange, getValueSetterForPricingCols } from '../shared/utils';
import { PRICING_TABLE_ENUM } from '../shared/constants';

export function getExtendedPriceSum(rowsData: MainProductDataType[]) {
    let toShowNA = false;
    let aggregrateSum = 0;
    const lotComputed = new Set();

    for (const rowData of rowsData) {
        if (!rowData?.lotId && !rowData?.lotInfo && !rowData?.is_hidden) {
            aggregrateSum += rowData?.extendedPrice || 0;
            continue;
        } else if (rowData?.lotInfo?.isUsedInOtherSection) {
            toShowNA = true;
            break;
        }
        if (rowData?.lotId && !lotComputed.has(rowData?.lotId) && !rowData?.is_hidden) {
            lotComputed.add(rowData.lotId);
            aggregrateSum += rowData?.lotInfo?.extendedPrice || 0;
        }
    }

    return toShowNA ? 'N/A' : aggregrateSum;
}

export function SubtotalTemplate() {
    return (
        <Box position="relative" width="64px" height="30px" display="flex" alignItems="center">
            <BodySmall fontWeight="700" lines={1}>
                Subtotal
            </BodySmall>
        </Box>
    );
}

export const emptyFunction = () => 0;

export function getExtendedPriceFooterTemplate(props: any) {
    const uiText = props.Custom.toShowNA ? (
        <SubtotalsNotApplicable title="Subtotals are not available because at least one included lot is present in multiple sections" />
    ) : (
        <BodySmall fontWeight="700">{props.Custom.aggregrateSumForUi}</BodySmall>
    );
    return <Box px="13px">{uiText}</Box>;
}

export function getUppercaseTemplate(props: any) {
    const field = props.column.field;
    return <BodySmall limit={false}>{props?.[field]?.toUpperCase() || ''}</BodySmall>;
}

export function getExtendedSellPriceFormatter(params: ValueFormatterParams) {
    if (params?.data?.lotId) {
        return `${params?.data?.lotInfo?.name || ''}`;
    }

    return getCellAmountFormatter({ ...params, value: params.data[PRICING_TABLE_ENUM.EXTENDED_PRICE] });
}

export function getIsTableDataUpdated(apiTableData: MainProductDataType[], localTableData: MainProductDataType[]) {
    return apiTableData.some((productData, index) => {
        return (
            productData?.hasOptions !== localTableData?.[index]?.hasOptions ||
            productData?.lotId !== localTableData?.[index]?.lotId ||
            productData?.is_hidden !== localTableData?.[index]?.is_hidden ||
            productData?.qty !== localTableData?.[index]?.qty ||
            productData?.category !== localTableData?.[index]?.category ||
            productData?.manufacturer !== localTableData?.[index]?.manufacturer ||
            productData?.model_number !== localTableData?.[index]?.model_number ||
            productData?.specification !== localTableData?.[index]?.specification ||
            productData?.cost !== localTableData?.[index]?.cost ||
            productData?.discount !== localTableData?.[index]?.discount ||
            productData?.discountedCost !== localTableData?.[index]?.discountedCost ||
            productData?.margin !== localTableData?.[index]?.margin ||
            productData?.sellPrice !== localTableData?.[index]?.sellPrice ||
            productData?.extendedPrice !== localTableData?.[index]?.extendedPrice ||
            productData?.leadTimeUnit !== localTableData?.[index]?.leadTimeUnit ||
            productData?.leadTimeValue !== localTableData?.[index]?.leadTimeValue ||
            productData?.quoteNo !== localTableData?.[index]?.quoteNo ||
            productData?.internalComments !== localTableData?.[index]?.internalComments ||
            productData?.quoteNotes !== localTableData?.[index]?.quoteNotes ||
            productData?.is_highlighted !== localTableData?.[index]?.is_highlighted
        );
    });
}

function handlePricingColumnChange(data: any, fieldName: PRICING_TABLE_ENUM) {
    const { cost, discount, discountedCost, margin, sellPrice, qty, extendedPrice } = data || {};
    const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange({ cost, discount, discountedCost, margin, sellPrice, qty, extendedPrice }, fieldName);

    return pricingRowInfoToUpdate;
}

export function getValueSetterForMainProductPricingCols(value: ValueSetterParams) {
    return getValueSetterForPricingCols(value, handlePricingColumnChange);
}
