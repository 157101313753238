import { useMutation } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import { orderProjectBoms } from './apis';
import { getProjectBomsQueryKeyAndCache } from '../../../Project/queries';

export const useOrderProjectBomsMutation = (shouldMutate = true) => {
    return useMutation({
        mutationFn: orderProjectBoms,
        onMutate: ({ sourceIndex, destinationIndex, projectId }) => {
            if (shouldMutate) {
                if (sourceIndex !== undefined && destinationIndex !== undefined && projectId) {
                    const { projectBomsCache, queryKey } = getProjectBomsQueryKeyAndCache(projectId);

                    if (projectBomsCache?.data) {
                        const currentData = [...projectBomsCache.data];
                        const draggedItem = currentData.splice(sourceIndex, 1)[0];
                        currentData.splice(destinationIndex, 0, draggedItem);
                        queryClient.setQueryData(queryKey(projectId), (oldData?: AxiosResponse) => oldData && { ...oldData, data: currentData });
                    }
                }
            }
        }
    });
};
