import { useState, useEffect } from 'react';
import { Box, Button, Modal, ModalFooter, ModalHeader, TextField, Grid, BodySmall, Snackbar, IconButton, CloseIcon } from '@parspec/pixel';
import Body from './Body';
import { getArrayBetweenNumbers } from 'src/features/shared/utils/utils';
import { PageRangePopupProps } from './queries/apiTypes';
import { useUpdateHidddePagesMutation } from './queries';
import { PageRangeThumbnail } from '../../BOM/AggregatorCropping/queries/apiTypes';

let intervalId: any;

const PageRangePopup = (props: PageRangePopupProps) => {
    const [hiddenPages, setHiddenPages] = useState<number[]>([]);
    const [startPage, setStartPage] = useState<number>();
    const [endPage, setEndPage] = useState<number>();
    const [pageNumberError, setPageNumberError] = useState(false);
    const [modalError, setModalError] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState('');
    const [adjustPageRangeThumbnailData, setAdjustPageRangeThumbnailData] = useState<PageRangeThumbnail>({ thumbnails: [], checkboxes: [], isExecutionComplete: false });

    useEffect(() => {
        setHiddenPages(props.hiddenPages);
    }, [props.hiddenPages]);

    useEffect(() => {
        if (props.getThumbnailsAsyncFunc) {
            getThumbnailsAndCheckboxes();
        }
    }, [props.getThumbnailsAsyncFunc]);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleStartPageChange = (e: any) => {
        setModalError(false);
        setPageNumberError(false);
        setStartPage(parseInt(e.target.value));
    };

    const getThumbnailsAndCheckboxes = () => {
        intervalId = setInterval(() => {
            const data = props.getThumbnailsAsyncFunc();
            if (data.isExecutionComplete) {
                clearInterval(intervalId);
            }
            setAdjustPageRangeThumbnailData((old) => ({
                ...old,
                thumbnails: [...data.thumbnails],
                checkboxes: [...data.checkboxes],
                isExecutionComplete: data.isExecutionComplete
            }));
        }, 100);
    };

    const handleEndPageChange = (e: any) => {
        setModalError(false);
        setPageNumberError(false);
        setEndPage(parseInt(e.target.value));
    };
    const { open, onClose, title, headerTitle } = props;
    const { mutateAsync: mutateHiddenPages, isLoading } = useUpdateHidddePagesMutation();

    const updateSelectedAndHiddenPages = () => {
        if (props.setHiddenPages) props.setHiddenPages(hiddenPages.sort());
        props.setSelectedPages(
            getArrayBetweenNumbers(1, adjustPageRangeThumbnailData?.thumbnails?.length)
                .filter((page: any) => !hiddenPages.includes(page))
                .sort()
        );
    };
    const handleSelectRangeClick = () => {
        if (endPage! <= startPage! || endPage! > adjustPageRangeThumbnailData?.thumbnails?.length || !startPage || !endPage) {
            setPageNumberError(true);
            return;
        }
        setPageNumberError(false);
        const range = getArrayBetweenNumbers(startPage!, endPage!);
        const newHiddenPages = hiddenPages.filter((page: any) => !range.includes(page));
        setHiddenPages(newHiddenPages);
    };
    const handleUnselectRangeClick = () => {
        if (endPage! <= startPage! || endPage! > adjustPageRangeThumbnailData?.thumbnails?.length || !startPage || !endPage) {
            setPageNumberError(true);
            return;
        }
        setPageNumberError(false);
        const range = getArrayBetweenNumbers(startPage!, endPage!);
        const newHiddenPages = [...new Set(hiddenPages.concat(range))];
        setHiddenPages(newHiddenPages);
    };
    const onAccept = async () => {
        if (hiddenPages.length === adjustPageRangeThumbnailData?.thumbnails?.length) {
            setModalError(true);
            return;
        }
        setModalError(false);
        if (props.apiDetails) {
            try {
                await mutateHiddenPages({
                    submittalPackageId: props.submittalPackageId,
                    apiDetails: props.apiDetails,
                    hiddenPages: { hidden_pages: hiddenPages.sort() },
                    bomId: props?.bomId,
                    bodId: props?.bodId,
                    documentType: props?.documentType,
                    subDocumentType: props?.subDocumentType
                });
                updateSelectedAndHiddenPages();
                onClose(false);
            } catch (error) {
                setMessage('Something went wrong !!');
                setOpenSnackbar(true);
            }
        } else {
            updateSelectedAndHiddenPages();
            onClose(false);
        }
    };
    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const action = (
        <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    const header = (
        <ModalHeader title={headerTitle} onClose={() => onClose(false)}>
            <Grid container gap={3} paddingTop={2} direction="column">
                <BodySmall color="secondary.light">{title}</BodySmall>
                <Box display="flex" gap={2} alignItems="flex-start">
                    <Box width="120px">
                        <TextField
                            placeholder="Start Page"
                            variant="outlined"
                            type="number"
                            onChange={handleStartPageChange}
                            value={startPage}
                            size="small"
                            label={''}
                            sx={{ appearance: 'textfield' }}
                            error={pageNumberError}
                            helperText={pageNumberError ? 'Invalid Range' : ''}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: adjustPageRangeThumbnailData?.thumbnails?.length
                                }
                            }}
                        />
                    </Box>
                    <Box width="120px">
                        <TextField
                            placeholder="End Page"
                            variant="outlined"
                            type="number"
                            onChange={handleEndPageChange}
                            value={endPage}
                            size="small"
                            label={''}
                            sx={{ appearance: 'textfield' }}
                            error={pageNumberError}
                            helperText={pageNumberError ? 'Invalid Range' : ''}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: adjustPageRangeThumbnailData?.thumbnails?.length
                                }
                            }}
                        />
                    </Box>
                    <Button children="Select Range" onClick={handleSelectRangeClick}></Button>
                    <Button children="Unselect Range" variant="outlined" color="secondary" onClick={handleUnselectRangeClick}></Button>
                </Box>
            </Grid>
        </ModalHeader>
    );
    const footer = (
        <ModalFooter
            continueButtonLabel="Confirm"
            onReject={() => onClose(false)}
            onAccept={() => {
                onAccept();
            }}
            helperText={
                modalError && (
                    <BodySmall color="error">
                        <Box component="span" fontWeight="bold">
                            Unhide at least one page to continue.
                        </Box>{' '}
                        Don’t want this product in your submittal? Hide it from the product list.
                    </BodySmall>
                )
            }
            isLoading={isLoading}
        />
    );
    return (
        <>
            <Modal header={header} open={open} onClose={() => onClose(false)} footer={footer}>
                <>
                    <Body thumbnails={adjustPageRangeThumbnailData?.thumbnails} hiddenPages={hiddenPages} setHiddenPages={setHiddenPages} setModalError={setModalError} />
                    <Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose} action={action} message={message} />
                </>
            </Modal>
        </>
    );
};
export default PageRangePopup;
