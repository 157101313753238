import { DocumentStatusTemplate } from '../shared/utils/utils';

export const getRowId = (params: any) => {
    return params?.data?.submittalId;
};

export const submittalPageTableColumnOptions = {
    data: [
        { field: 'submittalId', label: 'Submittal ID' },
        { field: 'status', label: 'Status' },
        { field: 'quoteNumber', label: 'Quote ID' },
        { field: 'submittalVersion', label: 'Version' },
        { field: 'submittalCreationDate', label: 'Creation Date' },
        { field: 'submittalCreatedBy', label: 'Created By' },
        { field: 'submittalProductCount', label: 'Products Included' },
        { field: 'dueDate', label: 'Submittal Due Date' },
        { field: 'submittalOwner', label: 'Submittal Owner' },
        { field: 'bomName', label: 'Bill Of Materials' },
        { field: 'projectName', label: 'Project' },
        { field: 'projectStage', label: 'Project Stage' },
        { field: 'branchLocation', label: 'Branch Location' }
    ]
};

export const getSubmittalStatusTemplate = (props: any) => {
    const { data } = props;
    return <DocumentStatusTemplate {...data} isOutOfSync={data?.submittalOutOfSync} />;
};
