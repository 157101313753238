export const manufacturerTocData = [
    {
        id: 2345,
        field_name: 'manufacturer_tnc_section',
        field_value: null,
        is_active: false
    },
    {
        id: 2346,
        field_name: 'GE Lighting',
        field_value: 'GE TOC',
        is_active: true
    },
    {
        id: 2347,
        field_name: 'Eaton',
        field_value: 'This is TOC',
        is_active: true
    },
    {
        id: 2348,
        field_name: 'Glamox',
        field_value: 'GLAM MFG',
        is_active: true
    }
];
