import { useMemo, FC } from 'react';

import { Box, MultiSelect, MultiSelectOptionType, SearchIcon, Skeleton, TextField } from '@parspec/pixel';

import { PROJECT_STAGE_OPTIONS } from '../../shared/constants';
import { useGetAllCompanyDetailsQuery } from '../../shared/CreateAndEditProjectModal/queries';
import { AllCompanyDetailsResponse } from '../../shared/CreateAndEditProjectModal/queries/apiTypes';
import { FiltersType } from '../shared/types';
import { DUE_DATE_TYPE_OPTIONS, FILTER_KEYS, PRIORITY_OPTIONS } from '../shared/constants';
import { CommonOption, OwnerFilterOption, PriorityFilterOption, StageFilterOption } from '../shared/components/FilterOption';
import { getOwnersFromLocations } from '../shared/utils';

interface FiltersProps {
    filters?: FiltersType;
    handleFiltersUpdate: (key: string, value: string | MultiSelectOptionType[]) => void;
}

const Filters: FC<FiltersProps> = ({ filters, handleFiltersUpdate }) => {
    const { data: allCompanyDetails, isLoading: allCompaniesLoading } = useGetAllCompanyDetailsQuery();

    const handleSearch = (value: string) => {
        handleFiltersUpdate(FILTER_KEYS.SEARCH_TEXT, value);
    };

    const handleSelect = (key: string, val: MultiSelectOptionType[]) => {
        handleFiltersUpdate(key, val);
    };

    const ownerOptions = useMemo(() => {
        if (allCompanyDetails?.data) {
            return getOwnersFromLocations(allCompanyDetails?.data);
        }
        return [];
    }, [filters, allCompanyDetails]);

    const branchLocationOptions = useMemo(() => {
        return (allCompanyDetails?.data || []).map((item: AllCompanyDetailsResponse) => ({ label: item.company_details.company_name, value: item.company_details.company_id }));
    }, [allCompanyDetails?.data]);

    return (
        <Box display="flex" gap={2} flexWrap="wrap">
            <Box minWidth={200} maxWidth={200}>
                <TextField
                    label=""
                    placeholder="Search..."
                    startIcon={<SearchIcon fontSize="small" />}
                    size="small"
                    value={filters?.[FILTER_KEYS.SEARCH_TEXT]}
                    onChange={(e) => handleSearch(e.target.value)}
                    sx={{ bgcolor: 'secondary.contrastText' }}
                />
            </Box>
            <Box
                minWidth={190}
                maxWidth={190}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '10px !important'
                    }
                }}
            >
                <MultiSelect
                    value={filters?.[FILTER_KEYS.DUE_DATE_TYPES]}
                    label="Due Date Type"
                    options={DUE_DATE_TYPE_OPTIONS}
                    onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.DUE_DATE_TYPES, val)}
                    size="small"
                    limitTags={1}
                    multiple
                    sx={{ bgcolor: 'secondary.contrastText' }}
                    shouldSortOptions={false}
                    customRow={CommonOption}
                />
            </Box>
            <Box
                minWidth={185}
                maxWidth={185}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '5px !important'
                    }
                }}
            >
                <MultiSelect
                    value={filters?.[FILTER_KEYS.PRIORITIES]}
                    label="Priority"
                    options={PRIORITY_OPTIONS}
                    onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.PRIORITIES, val)}
                    size="small"
                    limitTags={1}
                    multiple
                    sx={{ bgcolor: 'secondary.contrastText' }}
                    customRow={PriorityFilterOption}
                    shouldSortOptions={false}
                />
            </Box>
            {allCompaniesLoading ? (
                <Skeleton width={210} height={36} variant="rectangular" />
            ) : (
                <Box
                    minWidth={210}
                    maxWidth={210}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            paddingRight: '10px !important'
                        },
                        '& .MuiChip-root': {
                            maxWidth: '120px !important'
                        }
                    }}
                >
                    <MultiSelect
                        value={filters?.[FILTER_KEYS.BRANCH_LOCATIONS]}
                        label="Branch Location"
                        options={branchLocationOptions}
                        onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.BRANCH_LOCATIONS, val)}
                        size="small"
                        limitTags={1}
                        multiple
                        sx={{ bgcolor: 'secondary.contrastText' }}
                        customRow={CommonOption}
                    />
                </Box>
            )}
            {allCompaniesLoading ? (
                <Skeleton width={220} height={36} variant="rectangular" />
            ) : (
                <Box
                    minWidth={220}
                    maxWidth={220}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            paddingRight: '10px !important'
                        },
                        '& .MuiChip-root': {
                            maxWidth: '130px !important'
                        }
                    }}
                >
                    <MultiSelect
                        value={filters?.[FILTER_KEYS.OWNERS]}
                        label="Owner"
                        options={ownerOptions}
                        onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.OWNERS, val)}
                        size="small"
                        limitTags={1}
                        multiple
                        sx={{ bgcolor: 'secondary.contrastText' }}
                        customRow={OwnerFilterOption}
                    />
                </Box>
            )}
            <Box
                minWidth={210}
                maxWidth={210}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important'
                    }
                }}
            >
                <MultiSelect
                    value={filters?.[FILTER_KEYS.PROJECT_STAGES]}
                    label="Project Stage"
                    options={PROJECT_STAGE_OPTIONS.map(({ label, value }: { label: string; value: string }) => ({ label, value }))}
                    onChange={(_, val: MultiSelectOptionType[]) => handleSelect(FILTER_KEYS.PROJECT_STAGES, val)}
                    size="small"
                    limitTags={1}
                    multiple
                    sx={{ bgcolor: 'secondary.contrastText' }}
                    customRow={StageFilterOption}
                    shouldSortOptions={false}
                />
            </Box>
        </Box>
    );
};

export default Filters;
