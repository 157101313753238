import { axios } from 'src/app/Axios';
import { createProductPostRequest, createProductPostResponse, getCreateProductResponse, patchBodDetailsRequest, patchBodDetailsResponse } from './apiTypes';

export const endpoints = {
    getProduct: (bomId: string, bodId: string) => `/bom/${bomId}/product_finder/${bodId}/selected_datasheet?latest=true`,
    patchProduct: (bomId: string, bodId: string, dataSheetId: string) => `/bom/${bomId}/product_finder/${bodId}/selected_datasheet/${dataSheetId}`,
    createProduct: (bomId: string, bodId: string, dataSheetId: string) => `/bom/${bomId}/product_finder/${bodId}/selected_datasheet/${dataSheetId}/create_product/`
};

//APIs

export const getProductDetails = async (bomId: string, bodId: string) => {
    return axios.get<getCreateProductResponse[]>(endpoints.getProduct(bomId, bodId));
};

export const patchProductDetails = async (data: patchBodDetailsRequest) => {
    const { bomId, bodId, dataSheetId, input } = data;
    return axios.patch<patchBodDetailsResponse>(endpoints.patchProduct(bomId, bodId, dataSheetId), input);
};

export const createProduct = async (data: createProductPostRequest) => {
    const { bomId, bodId, dataSheetId } = data;
    return axios.post<createProductPostResponse>(endpoints.createProduct(bomId, bodId, dataSheetId));
};
