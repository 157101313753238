import { DocumentStatusTemplate } from '../shared/utils/utils';

export const getRowId = (params: any) => {
    return params?.data?.omId;
};

export const omPageTableColumnOptions = {
    data: [
        { field: 'omId', label: 'O&M Package ID' },
        { field: 'status', label: 'Status' },
        { field: 'quoteNumber', label: 'Quote ID' },
        { field: 'omVersion', label: 'Version' },
        { field: 'omCreationDate', label: 'Creation Date' },
        { field: 'omCreatedBy', label: 'Created By' },
        { field: 'omProductCount', label: 'Products Included' },
        { field: 'dueDate', label: 'O&M Due Date' },
        { field: 'omOwner', label: 'O&M Owner' },
        { field: 'bomName', label: 'Bill Of Materials' },
        { field: 'projectName', label: 'Project' },
        { field: 'projectStage', label: 'Project Stage' },
        { field: 'branchLocation', label: 'Branch Location' }
    ]
};

export const getOMStatusTemplate = (props: any) => {
    const { data } = props;
    return <DocumentStatusTemplate {...data} isOutOfSync={data?.omOutOfSync} />;
};
