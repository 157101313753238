import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BodyMedium, Box, Button, Tooltip, Snackbar, CircularProgress, Select, SelectChangeEvent } from '@parspec/pixel';

import DocumentItem, { Document } from '../../../shared/DocumentSuggestions/DocumentItem';
import DataSheetSourceHeader from './DataSheets/DataSheetSourceHeader';
import SourceProductRequirement from './DataSheets/SourceProductRequirement';
import AddSuggestion from '../../../shared/DocumentSuggestions/AddSuggestion/AddSuggestion';
import ProductCarousel from './components/productAccordion/ProductCarousel';
import { Header } from '../shared/Header';
import { cancelGetAlternateDatasheetsRequest, useGetAlternatesDataSheet, useSelectAlaternateMutation } from './queries';
import { addProductManuallyOptions } from '../../shared/utils';
import CancelButton from '../../../shared/CancelButton';
import { SearchResultProps, SelectDataRequest } from './queries/apiTypes';
import { someThingWentWrongMsg } from '../../../shared/constants';
import { SelectProductSkelton } from './DataSheets/SelectProductSkelton';
import { useParspecNavigation } from 'src/app/Context';
import { useProductRequirementGetQuery } from '../setRequirements/queries';
import NoProductFound from './DataSheets/NoProductFound';
import useGetLiveDocuments from '../../../shared/hooks/useGetLiveDocuments';
import { useAnalytics } from '../../../shared/hooks/AnalyticsHook';
import { useManufacturerOptions } from '../shared/hooks';
import { getProductRequirementsForUi } from './utils';
import useAppPerformanceTracker from '../../../shared/UserActivityTracking/useAppPerformanceTracker';
import { SORT, sortByDropdownOptions } from './constants';
import { graphQLClient, pushEvent } from '../../../shared/UserActivityTracking/EventService';
import { useGetSelectedDocumentQuery } from '../../Datasheets/AnnotateDatasheet/queries';
import { getStringifiedValueForEventService } from '../../../shared/utils/utils';
import { notifyToSentry } from '../../../shared/SentryErrorLogger/index';

const executeQuery = ({
    alternatePdf,
    productId,
    description,
    documentId,
    hideOriginLine,
    selectionId,
    isBodCreated,
    manufacturer,
    modelNumber,
    rememberSelection,
    sortSuggestedResultsBy,
    manufacturerAndCountCache,
    previouslySelectedDataSheetArrayLength,
    relativeIndex,
    selectedDocumentUrl,
    type,
    specification,
    requirementsCache,
    searchResultId,
    dataOrigin,
    mfgOrder
}: {
    alternatePdf: string | undefined;
    productId: number | null;
    description: string | null;
    documentId: number | null;
    hideOriginLine: boolean | undefined;
    selectionId: number | undefined;
    isBodCreated: boolean | undefined;
    manufacturer: string | undefined;
    modelNumber: string | null;
    rememberSelection: boolean | undefined;
    sortSuggestedResultsBy: string;
    manufacturerAndCountCache: { manufacturers: string; count: number }[];
    previouslySelectedDataSheetArrayLength: number;
    relativeIndex: number | undefined;
    selectedDocumentUrl: string | null | undefined;
    type: string | null;
    specification: string | undefined;
    requirementsCache: any[];
    searchResultId: number | null;
    dataOrigin: string;
    mfgOrder: number;
}) => {
    const commentsValue = getStringifiedValueForEventService(requirementsCache);
    const arrayOfManufacturersAndCount = getStringifiedValueForEventService(manufacturerAndCountCache);

    const queryString = `mutation($extracted_attributes: jsonb = ${commentsValue}, $results_per_mfg: jsonb = ${arrayOfManufacturersAndCount}  ){
        events_db {
          insert_pf_search_select_analytics(objects: {alternate_pdf: "${alternatePdf}", bod_id: ${productId}, data_origin: "${dataOrigin}", description: "${description}",document_id: ${documentId}, extracted_attributes: $extracted_attributes, hide_origin_line: ${hideOriginLine}, id: ${selectionId}, is_bod_created: ${isBodCreated}, manufacturer: "${manufacturer}", model_number: "${modelNumber}", rank: ${relativeIndex}, remember_selection: ${rememberSelection}, result_preferredlist_count: ${previouslySelectedDataSheetArrayLength}, results_per_mfg: $results_per_mfg, search_result_id: ${searchResultId}, selection_type: "${sortSuggestedResultsBy}", selection_type_relative_rank: ${relativeIndex}, source_pdf: "${selectedDocumentUrl}", specification: "${specification}", type: "${type}", mfg_order:${mfgOrder}}) {
            affected_rows
          }
        }
      }
      `;
    return queryString;
};

const SelectProducts = () => {
    const { setHasUnsavedChanges, pNavigate } = useParspecNavigation();
    const { bomId, productId, documentType, subDocumentType, projectId } = useParams();
    const [addNewData, setAddNewData] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { track } = state || {};
    const [selectedDocument, setSelectedDocument] = useState<Document>();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isSourceDataSheet, setSourceDataSheet] = useState<boolean>(false);
    // isLiveStatusCheckStarted needed for tracking the api response time
    const [isLiveStatusCheckStarted, setIsLiveStatusCheckStarted] = useState(false);
    const [sortSuggestedResultsBy, setSortSuggestedResultsBy] = useState<string>(SORT.SORT_BY_MANUFACTURER);
    const { data: dataSheetProductRequirements, isFetching: isSelectedFetching } = useProductRequirementGetQuery(Number(bomId), Number(productId));
    const { mutateAsync: selectDocumentAction, isLoading: confirmLoading } = useSelectAlaternateMutation();

    const { data: alternatesResults, isFetching: isAlternateFetching } = useGetAlternatesDataSheet(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId),
        staleTime: 0
    });

    const { data: selectedDocumentData } = useGetSelectedDocumentQuery(bomId || '', productId || '', documentType || '', 'datasheet', {
        enabled: Boolean(bomId) && Boolean(productId) && Boolean(documentType) && Boolean(subDocumentType)
    });
    const { manufacturerOptions } = useManufacturerOptions();
    const productRequirementsForUi = useMemo(() => getProductRequirementsForUi(manufacturerOptions, dataSheetProductRequirements?.data), [manufacturerOptions, dataSheetProductRequirements?.data]);
    const { sendEvent } = useAnalytics();

    const limit = Math.floor((window?.innerWidth - 700) / 328);

    const manufacturerAndCountCache = useMemo(() => {
        const ans = alternatesResults?.data.search_results.reduce((acc, val) => {
            if (acc.has(val.search_result_metadata.manufacturer)) {
                acc.set(val.search_result_metadata.manufacturer, (acc.get(val?.search_result_metadata?.manufacturer) || 0) + 1);
            } else {
                acc.set(val.search_result_metadata.manufacturer, 1);
            }
            return acc;
        }, new Map<string, number>());

        return Array.from(ans || new Map(), ([manufacturers, count]) => ({ manufacturers, count }));
    }, [alternatesResults?.data]);

    const previouslySelectedDataSheetArray = useMemo(() => {
        const result: SearchResultProps[] = [];

        alternatesResults?.data.search_results.forEach((val) => {
            if (val.previously_selected) {
                result.push(val);
            }
        });

        return result;
    }, [alternatesResults?.data]);

    const requirementsCache = useMemo(() => {
        const result = productRequirementsForUi.reduce<{ fieldName: string; fieldValue: any }[]>((acc, val) => {
            const obj: { fieldName: string; fieldValue: any } = { fieldName: val.fieldName, fieldValue: val.fieldValue };
            acc.push(obj);
            return acc;
        }, []);
        return result;
    }, [productRequirementsForUi]);

    useEffect(() => {
        return () => {
            setHasUnsavedChanges(false);
        };
    }, []);
    const onEditRequirement = () => {
        pNavigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/setRequirements`);
    };

    const {
        data: filteredResponse,
        isAllDataFetching,
        isOneDataFetching
    } = useGetLiveDocuments(alternatesResults?.data?.search_results, {
        enabled: !isAlternateFetching && Boolean(alternatesResults?.data?.search_results?.length)
    });

    const groupedData = useMemo(() => {
        return filteredResponse.reduce((acc: Record<string, SearchResultProps[]>, item: SearchResultProps) => {
            const manufacturer = item.search_result_metadata.manufacturer;
            if (!acc[manufacturer]) {
                acc[manufacturer] = [];
            }
            acc[manufacturer].push(item);
            return acc;
        }, {});
    }, [filteredResponse]);

    const relativeIndex = useMemo(() => {
        let selectedIndex = -1;
        if (!selectedDocument) {
            return;
        }
        if (sortSuggestedResultsBy === SORT.SORT_BY_MANUFACTURER && !selectedDocument?.previously_selected) {
            groupedData[selectedDocument?.search_result_metadata.manufacturer || '']?.forEach((val: any, index: number) => {
                if (val.document_internal_url === selectedDocument?.document_internal_url) {
                    selectedIndex = index;
                }
            });
        } else if (sortSuggestedResultsBy === SORT.BY_SCORE && !selectedDocument?.previously_selected) {
            filteredResponse.forEach((val: any, index: number) => {
                if (val.document_internal_url === selectedDocument?.document_internal_url) {
                    selectedIndex = index;
                }
            });
        } else {
            previouslySelectedDataSheetArray.forEach((val, i) => {
                if (val?.document_internal_url === selectedDocument?.document_internal_url || val.document_url === selectedDocument?.document_url) {
                    selectedIndex = i;
                }
            });
        }

        return selectedIndex;
    }, [groupedData, sortSuggestedResultsBy, previouslySelectedDataSheetArray, selectedDocument]);
    useEffect(() => {
        return () => {
            if (bomId && productId) {
                cancelGetAlternateDatasheetsRequest(bomId, productId);
            }
        };
    }, []);
    const handleAddNewData = () => setAddNewData(!addNewData);
    const headerHeight = '205px';
    const handleConfirm = async () => {
        if (selectedDocument) {
            const mfgRank =
                sortSuggestedResultsBy === SORT.SORT_BY_MANUFACTURER
                    ? (groupedData[selectedDocument.search_result_metadata.manufacturer as keyof typeof groupedData] || []).findIndex((item: Document) => item.id === selectedDocument.id) + 1 || -1
                    : null;
            const data: SelectDataRequest = {
                bomId: bomId!,
                productId: productId!,
                input: {
                    data_origin: selectedDocument.search_result_metadata.type,
                    document_url: selectedDocument?.document_url || '',
                    manufacturer: selectedDocument?.search_result_metadata?.manufacturer || '',
                    document_hash: selectedDocument?.document_hash,
                    document_hash_version: selectedDocument.document_hash_version,
                    document_preview: selectedDocument?.pdf_image,
                    document_internal_url: selectedDocument.document_internal_url,
                    rank: selectedDocument?.rank as number,
                    mfg_rank: mfgRank
                }
            };
            let selectAlternateDocumentresponse;
            try {
                selectAlternateDocumentresponse = await selectDocumentAction(data);
                sendEvent('ProductFinder_SelectProductsPage_ConfirmClicked');
                try {
                    const queryObj = {
                        alternatePdf: selectedDocument?.document_url || 'null',
                        productId: Number(productId) || 0,
                        description: selectAlternateDocumentresponse?.data?.description || 'null',
                        documentId: selectedDocumentData?.data[0]?.document || -1,
                        hideOriginLine: Boolean(selectAlternateDocumentresponse?.data?.hide_origin_line),
                        selectionId: Math.floor(Math.random() * 100000000),
                        isBodCreated: Boolean(selectAlternateDocumentresponse?.data?.is_bod_created),
                        manufacturer: selectAlternateDocumentresponse?.data?.manufacturer || 'null',
                        modelNumber: selectAlternateDocumentresponse?.data.model_number || 'null',
                        rememberSelection: Boolean(selectAlternateDocumentresponse?.data?.remember_selection),
                        sortSuggestedResultsBy: selectedDocument.previously_selected ? 'userPreferredList' : sortSuggestedResultsBy || 'null',
                        manufacturerAndCountCache: manufacturerAndCountCache || [{ test: 'null' }],
                        previouslySelectedDataSheetArrayLength: previouslySelectedDataSheetArray?.length || 0,
                        relativeIndex: relativeIndex || 0,
                        selectedDocumentUrl: selectedDocumentData?.data[0]?.suggestion?.selected_document_url || 'null',
                        type: selectAlternateDocumentresponse?.data.type || 'null',
                        specification: selectAlternateDocumentresponse?.data?.specification || 'null',
                        requirementsCache: requirementsCache || [{ test: 'null' }],
                        searchResultId: alternatesResults?.data?.id || 0,
                        dataOrigin: selectedDocument?.search_result_metadata.type || 'null',
                        mfgOrder: Object.keys(groupedData || {}).indexOf(selectAlternateDocumentresponse?.data?.manufacturer) || -1
                    };

                    pushEvent({
                        updateMutation: null,
                        insertMutation: executeQuery(queryObj),
                        responseAttribute: 'insert_pf_search_select_analytics'
                    });
                } catch (e: any) {
                    console.log(e);
                    const slackMessage = `URL: ${e.request.query}\nToken: ${JSON.stringify(graphQLClient.requestConfig.headers)}\nUpdate Query: Production Finder logging\nError: ${JSON.stringify(
                        e.response.errors
                    )}`;
                    notifyToSentry(slackMessage);
                }
                // After we have logged data in hasura we will navigate, otherwise data won't be logged.
                navigate(`/v2/project/${projectId}/bom/${bomId}/productFinder/${productId}/create`);
            } catch (e) {
                setSnackbarMessage(someThingWentWrongMsg);
            }
        }
    };
    const confirmButtonDisable = !selectedDocument;
    const isLoading = isAlternateFetching || isOneDataFetching;

    //needed to keep the loading true till first response is shown on UI
    // this is for tracking the time
    useEffect(() => {
        if (isOneDataFetching) {
            setIsLiveStatusCheckStarted(true);
        }
    }, [isOneDataFetching]);

    //For tracking the time taken to show the Search results
    const loader = isLoading || !isLiveStatusCheckStarted;
    useAppPerformanceTracker({ apiUrl: `/bom/*/product_finder/*/search_results`, enabled: track, isUiLoading: loader, isApiLoading: isAlternateFetching });

    const pastSelection = filteredResponse.filter((list: SearchResultProps) => list.previously_selected);
    return (
        <Box minWidth={'fit-content'}>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Header title="Select Product">
                <Box display="flex" gap={3} alignItems="flex-start" ml="auto">
                    <CancelButton />
                    <Button variant="outlined" color="secondary" onClick={onEditRequirement}>
                        Edit Requirements
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleAddNewData}>
                        Add Product Manually
                    </Button>
                    <Tooltip title={confirmButtonDisable ? 'Please select a product.' : null}>
                        <span>
                            <Button color="tertiary" variant="contained" onClick={handleConfirm} disabled={confirmButtonDisable} isLoading={confirmLoading}>
                                Confirm
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Header>
            {!isSelectedFetching ? (
                <Box display={'flex'} bgcolor="neutral.light" p={6} pr={0} pb={5} minWidth={'fit-content'}>
                    <Box mr={5} height={`calc(100vh - ${headerHeight})`} overflow={'auto'}>
                        <Box display={'flex'} height={'40px'}>
                            <BodyMedium fontWeight={500}>Source Datasheet</BodyMedium>
                        </Box>
                        <Box>
                            <DataSheetSourceHeader setSourceDataSheet={setSourceDataSheet} />
                            <SourceProductRequirement dataSheetProductRequirements={productRequirementsForUi} />
                        </Box>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} width={'calc(100% - 550px)'} height={`calc(100vh - ${headerHeight})`} overflow={'auto'} pr={6}>
                        {pastSelection?.length > 0 && (
                            <Box pb={5}>
                                <ProductCarousel
                                    title={'Past Selection(s)'}
                                    data={pastSelection}
                                    limit={limit}
                                    isAllDataFetching={isAllDataFetching}
                                    setSelectedDocument={setSelectedDocument}
                                    selectedDocument={selectedDocument}
                                    setPreSelectedDocument={setSelectedDocument}
                                    isSourceDataSheet={isSourceDataSheet}
                                />
                            </Box>
                        )}

                        <Box display={'flex'} justifyContent={'space-between'} pb={5} alignItems={'center'}>
                            <Box minWidth={'240px'} display="flex" gap={1}>
                                <BodyMedium fontWeight={500}>Suggested Results</BodyMedium>
                            </Box>
                            <Box width={'190px'}>
                                <Select
                                    label=""
                                    onChange={(e: SelectChangeEvent<unknown>) => setSortSuggestedResultsBy(e.target.value as string)}
                                    options={sortByDropdownOptions}
                                    optionLabelKeyname="label"
                                    optionValueKeyname="value"
                                    value={sortSuggestedResultsBy}
                                    bgColor={'primary.contrastText'}
                                />
                            </Box>
                        </Box>

                        {isLoading ? (
                            <>
                                <Box display="flex" alignItems="center" justifyContent="center" mt={50} width={1}>
                                    <CircularProgress color="primary" size="xxl" />
                                </Box>
                            </>
                        ) : filteredResponse.length === 0 ? (
                            <NoProductFound onEditRequirement={onEditRequirement} handleAddNewData={handleAddNewData} />
                        ) : (
                            <>
                                <Box>
                                    {sortSuggestedResultsBy === SORT.SORT_BY_MANUFACTURER ? (
                                        <>
                                            {Object.keys(groupedData).map((manufacturer) => (
                                                <ProductCarousel
                                                    title={manufacturer}
                                                    data={groupedData[manufacturer]}
                                                    limit={limit}
                                                    isAllDataFetching={isAllDataFetching}
                                                    setSelectedDocument={setSelectedDocument}
                                                    selectedDocument={selectedDocument}
                                                    setPreSelectedDocument={setSelectedDocument}
                                                    isSourceDataSheet={isSourceDataSheet}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <Box display={'flex'} gap={4} flexWrap={'wrap'} p={6}>
                                            {filteredResponse.map((item: Document | SearchResultProps, index: number) => (
                                                <Box key={index}>
                                                    <DocumentItem
                                                        item={item as Document}
                                                        key={index}
                                                        index={index}
                                                        total={filteredResponse.length}
                                                        dataList={filteredResponse as Document[]}
                                                        setSelectedDocument={setSelectedDocument}
                                                        selectedDocument={selectedDocument}
                                                        setPreSelectedDocument={setSelectedDocument}
                                                        productFinderMode={true}
                                                        isSourceDataSheet={isSourceDataSheet}
                                                        subDocumentType={'datasheet'}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                    {isAllDataFetching && (
                                        <>
                                            <Box display="flex" alignItems="center" justifyContent="center" pb={5} width={1}>
                                                <CircularProgress color="primary" size="xxl" />
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            ) : (
                <SelectProductSkelton />
            )}
            {addNewData ? <AddSuggestion open={addNewData} handleAddNewData={handleAddNewData} options={addProductManuallyOptions} /> : null}
        </Box>
    );
};

export default SelectProducts;
