import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { AxiosResponse } from 'axios';

import { Box, H6, Paper, CircularProgress, Skeleton, Button, TableHeaderMenuIcon, Menu, Link, BodyXS, Snackbar, RichTextEditor } from '@parspec/pixel';

import { BomTable } from './BomTable/BomTable';
import { Header } from './Header';
import { BomModal } from '../Dashboard/BOMModal';
import {
    useDeleteUploadedFilesForProject,
    useDownloadDocument,
    useEditProjectDetails,
    useGetAllUploadedFilesForProject,
    useGetProjectDetailsQuery,
    useGetProjectStakeholdersQuery,
    useUpdateStakeholderInfoMutation
} from './queries';
import EditProjectDetail from '../shared/CreateAndEditProjectModal/EditProjectDetail';
import { IProjectDetailState, Errors, getDateStringForTable } from '../shared/utils/utils';
import { useSubscriptionInfo } from '../Settings/PlanAndPayment/queries';
import { convertToCents } from '../shared/utils/utils';
import { projectStakeHoldersInitialState, StakeholderType } from '../shared/CreateAndEditProjectModal/utils';
import RenameModal from './RenameProjectModal';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { useSnackbar } from '../shared/hooks/useSnackbar';
import { SnackbarMessages } from '../BOM/FinalDocuments/constants';
import { DOWNLOAD_FAILED_MSG } from '../shared/constants';
import { callProxyURL } from '../BOM/FinalDocuments/utils/downloadFile';
import ProjectFilesUploadModal from './UploadProjectFilesModal';

interface IFileInfo {
    id: number;
    file_name: string;
    file_type: string;
}
const ProjectDetails = () => {
    const { projectId } = useParams();
    const [stakeholdersInfo, setStakeholdersInfo] = useState<StakeholderType[]>(projectStakeHoldersInitialState);
    const [openProjectFilesModal, setOpenProjectFilesModal] = useState(false);

    const projectStateRef = useRef<IProjectDetailState>();
    const setProjectStateRef = useCallback((data: IProjectDetailState) => {
        projectStateRef.current = data;
    }, []);

    const [formErrors, setFormErrors] = useState<Errors>({ projectNameError: '' });
    const [bomModalOpen, setBomModalOpen] = useState<boolean>(false);
    const [openRenameModal, setOpenRenameModal] = useState(false);

    const [uploadedFileDetails, setUploadedFileDetails] = useState<{ name: string; id: number }>({ name: '', id: -1 });
    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { data: projectStakeholders, isFetching } = useGetProjectStakeholdersQuery(Number(projectId || 0));
    const { isFetching: isSubscriptionFetching } = useSubscriptionInfo();
    const { data: projectDetails, isLoading: isEditProjectLoading } = useGetProjectDetailsQuery(Number(projectId));
    const { data: uploadedFiles } = useGetAllUploadedFilesForProject(Number(projectId));

    const { mutateAsync: editProjectDetails, isLoading: isUpdatingProjectDetails } = useEditProjectDetails(true);
    const { mutateAsync: updateStakeholder } = useUpdateStakeholderInfoMutation();
    const { mutateAsync: deleteUploadedFile } = useDeleteUploadedFilesForProject();
    const { mutateAsync } = useDownloadDocument();

    const handleDelete = async ({ projectId, documentId }: { projectId: number; documentId: number }) => {
        await deleteUploadedFile({ projectId: Number(projectId), documentId: documentId || -1 });
        setSnackbarOpen(SnackbarMessages.DELETE_SUCCESS);
    };

    const handleUploadedFileName = (name: string) => {
        setUploadedFileDetails((old) => ({ ...old, name: name }));
    };
    const getOptions = ({ id, file_name, file_type }: IFileInfo) => {
        return [
            {
                label: 'Rename',
                onClick: () => {
                    setOpenRenameModal(true);
                    const payload = {
                        id: id,
                        name: file_name
                    };
                    setUploadedFileDetails(payload);
                }
            },
            {
                label: 'Download',
                onClick: () => {
                    mutateAsync(
                        { projectId: Number(projectId), documentId: id },
                        {
                            onSuccess: async (data: AxiosResponse) => {
                                await callProxyURL(data?.data.s3_url, file_type, file_name);
                                setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
                            },
                            onError: () => {
                                setSnackbarOpen(DOWNLOAD_FAILED_MSG);
                            }
                        }
                    );
                }
            },
            {
                label: 'Delete',
                onClick: () => handleDelete({ projectId: Number(projectId), documentId: id || -1 }),
                color: 'error.main'
            }
        ];
    };

    const MenuOptions = (props: ICellRendererParams) => {
        return (
            <Box display="flex" justifyContent="center">
                <Menu options={getOptions(props.data)} />
            </Box>
        );
    };

    const handleCloseRenameModal = () => {
        setOpenRenameModal((prev) => !prev);
    };
    const headerIconComponent = () => {
        return (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        );
    };
    const fieldNameRenderer = (props: ICellRendererParams) => {
        const { file_name, file_type, id: fileId, project: projectID } = props?.data;

        const handleDownloadFile = () => {
            mutateAsync(
                { projectId: Number(projectID), documentId: fileId },
                {
                    onSuccess: async (data: AxiosResponse) => {
                        await callProxyURL(data?.data.s3_url, file_type, file_name);
                        setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
                    },
                    onError: () => {
                        setSnackbarOpen(DOWNLOAD_FAILED_MSG);
                    }
                }
            );
        };

        if (file_type === 'pdf') {
            return (
                <Link to={getProxyFileUrl(props.data.s3_url)} target="_blank" rel="noopener noreferrer" underline="hover" component={RouterLink}>
                    {props.data.file_name + '.' + props.data.file_type}
                </Link>
            );
        }

        return (
            <Link onClick={handleDownloadFile} to="" rel="noopener noreferrer" underline="hover" component={RouterLink}>
                {props.data.file_name + '.' + props.data.file_type}
            </Link>
        );
    };

    const fileSizeRenderer = (props: ICellRendererParams) => {
        return (props.data.file_size / 1024).toFixed(2) + ' kB';
    };

    const createdAtRenderer = (props: ICellRendererParams) => {
        return (
            <Box>
                <Box marginTop={-2}>{props.data.created_by_name}</Box>

                <Box marginTop={-3} color="secondary.light" sx={{ wordWrap: 'break-word' }}>
                    <BodyXS>{props.data.created_at ? getDateStringForTable(props.data.created_at) : '-'} </BodyXS>
                </Box>
            </Box>
        );
    };
    const defaultColumnDef: any = [
        {
            field: 'file_name',
            headerName: 'Name',
            minWidth: 350,
            cellRenderer: fieldNameRenderer,
            sortable: true,
            suppressMenu: true,
            unSortIcon: true
        },
        {
            field: 'Uploaded',
            headerName: 'Uploaded',
            minWidth: 180,
            cellRenderer: createdAtRenderer,
            sortable: true,
            suppressMenu: true,
            unSortIcon: true,
            comparator: (_valueA: string, _valueB: string, nodeA: RowNode, nodeB: RowNode) => {
                if (nodeA.data.created_by_name === nodeB.data.created_by_name) return 0;
                else if (nodeA.data.created_by_name > nodeB.data.created_by_name) return 1;
                return -1;
            }
        },
        {
            field: 'file_size',
            headerName: 'Size',
            minWidth: 100,
            cellRenderer: fileSizeRenderer,
            suppressMenu: true,
            sortable: true,
            unSortIcon: true
        },
        {
            headerName: '',
            headerComponent: headerIconComponent,
            cellRenderer: MenuOptions,
            minWidth: 50,
            width: 60,
            colId: 'menu',
            suppressMenu: true,
            lockPosition: 'right',
            lockPinned: true,
            pinned: 'right'
        }
    ];

    useEffect(() => {
        if (projectStakeholders?.data && !isFetching) {
            setStakeholdersInfo(projectStakeholders?.data);
        }
    }, [projectStakeholders?.data, isFetching]);

    const handleFormErrorsAction = useCallback((error: Errors) => {
        setFormErrors(error);
    }, []);

    const handleBOMModalDialog = () => {
        setBomModalOpen(!bomModalOpen);
    };

    async function handleProjectName(value: string) {
        if (projectStateRef?.current) {
            projectStateRef.current.name = value;
            await handleOnBlur();
        }
    }

    async function handleOnBlur() {
        if (projectStateRef?.current) {
            if (
                (projectStateRef.current.submittal_due_date !== null &&
                    projectStateRef.current.submittal_due_date &&
                    !projectStateRef.current.submittal_due_date.isValid() &&
                    projectStateRef.current.submittal_due_date.year() > 2099) ||
                (projectStateRef.current.o_m_due_date !== null &&
                    projectStateRef.current.o_m_due_date &&
                    !projectStateRef.current.o_m_due_date.isValid() &&
                    projectStateRef.current.o_m_due_date.year() > 2099)
            ) {
                return;
            }

            const formData = new FormData();
            formData.append('name', projectStateRef.current.name);
            formData.append('company', JSON.stringify(projectStateRef.current.company));
            formData.append('stage', projectStateRef.current.stage);
            formData.append(
                'estimated_project_cost',
                projectStateRef.current.estimated_project_cost === null ? 'null' : JSON.stringify(convertToCents(projectStateRef.current.estimated_project_cost))
            );
            formData.append('quote_owner', JSON.stringify(projectStateRef.current.quote_owner));
            formData.append('quote_due_date', projectStateRef.current.quote_due_date ? projectStateRef.current.quote_due_date.format() : 'null');
            formData.append('submittal_owner', JSON.stringify(projectStateRef.current.submittal_owner));
            formData.append('submittal_due_date', projectStateRef.current.submittal_due_date ? projectStateRef.current.submittal_due_date.format() : 'null');
            formData.append('o_m_owner', JSON.stringify(projectStateRef.current.o_m_owner));
            formData.append('o_m_due_date', projectStateRef.current.o_m_due_date ? projectStateRef.current.o_m_due_date.format() : 'null');
            formData.append('address1', projectStateRef.current.address1);
            formData.append('address2', projectStateRef.current.address2);
            formData.append('country', projectStateRef.current.country);
            formData.append('city', projectStateRef.current.city);
            formData.append('state', projectStateRef.current.state);
            formData.append('zip', projectStateRef.current.zip);
            formData.append('building_type', projectStateRef.current.building_type);
            formData.append('construction_scope', projectStateRef.current.construction_scope);
            formData.append('priority', projectStateRef.current.priority);
            if (projectStateRef.current.size) {
                formData.append('size', projectStateRef.current.size);
            } else {
                formData.append('size', '');
            }
            formData.append('internal_notes', projectStateRef.current?.internal_notes ? projectStateRef.current.internal_notes : '');
            // @ts-ignore:next-line
            if (!projectStateRef.current.logo?.filepath) {
                // @ts-ignore:next-line
                formData.append('logo', projectStateRef.current.logo);
            }

            if (projectId) {
                await editProjectDetails({ project_id: projectId!, data: formData });
            }
        }
    }

    const updateStakeholderInfo = async (info: StakeholderType, index: number) => {
        setStakeholdersInfo((old) => {
            const updatedInfo = [...old];
            updatedInfo[index] = info;
            return updatedInfo;
        });
        if (info?.id) {
            const input = {
                id: info?.id,
                projectId: Number(projectId || 0),
                company: info?.company?.id || null,
                contact: info?.contact?.id || null
            };
            await updateStakeholder(input);
        }
    };

    function onEditorBlur(html: string) {
        if (projectStateRef?.current) {
            projectStateRef.current.internal_notes = html;
            handleOnBlur();
        }
    }
    const handleUploadFile = () => {
        setOpenProjectFilesModal(true);
    };

    const handleCloseProjectFilesModal = () => {
        setOpenProjectFilesModal(false);
    };

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <>
            {bomModalOpen && (
                <BomModal
                    open={bomModalOpen}
                    onCloseClick={() => {
                        setBomModalOpen(false);
                    }}
                    onSuccess={handleBOMModalDialog}
                    selectedProjectId={Number(projectId)}
                />
            )}
            <Box>
                <Header getProjectName={handleProjectName} formErrors={formErrors} onError={handleFormErrorsAction} />
                {isSubscriptionFetching ? (
                    <Box display="flex" alignItems="center" justifyContent="center" height={'100vh'}>
                        <CircularProgress color="primary" size="xxl" />
                    </Box>
                ) : (
                    <Box p={6} overflow="auto" height="calc(100vh - 140px)">
                        <H6 mb={3}>Project Details</H6>
                        <Paper variant="outlined">
                            <Box py={6} height="500px" overflow="auto">
                                <EditProjectDetail
                                    editMode={true}
                                    onError={handleFormErrorsAction}
                                    formErrors={formErrors}
                                    setProjectStateRef={setProjectStateRef}
                                    onBlurAction={handleOnBlur}
                                    isUpdatingProjectDetails={isUpdatingProjectDetails}
                                    stakeholdersInfo={stakeholdersInfo}
                                    updateStakeholderInfo={updateStakeholderInfo}
                                    isStakeHoldersInfoFetching={isFetching}
                                />
                            </Box>
                        </Paper>

                        <Box mt={4}>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <H6 mb={3}>Project Notes (Internal)</H6>
                                </Box>
                                <Box marginLeft={4}>
                                    <H6 mb={3}>Project Files</H6>
                                </Box>
                                <Box>
                                    <Button onClick={handleUploadFile}>Upload File(s)</Button>
                                </Box>
                            </Box>
                            {isEditProjectLoading ? (
                                <Skeleton variant="rectangular" height={100} width="100%" />
                            ) : (
                                <Box display="flex" justifyContent="space-between">
                                    <Box width="50%">
                                        <RichTextEditor
                                            initialHtml={projectDetails?.data.internal_notes || ''}
                                            placeHolderText="Add important notes, or links to files relevant to this project."
                                            onBlur={onEditorBlur}
                                        />
                                    </Box>
                                    <Box className="ag-theme-alpine" style={{ height: '196px', width: '50%', marginLeft: 16 }}>
                                        <AgGridReact rowData={uploadedFiles?.data} columnDefs={defaultColumnDef} onGridReady={onGridReady} modules={[GridChartsModule]} suppressContextMenu />
                                    </Box>
                                    <Box>
                                        <ProjectFilesUploadModal openModal={openProjectFilesModal} closeProjectFilesModal={handleCloseProjectFilesModal} uploadedFileData={uploadedFiles?.data || []} />
                                    </Box>
                                    <Box>
                                        {openRenameModal && (
                                            <RenameModal
                                                open={openRenameModal}
                                                handleCloseRenameModal={handleCloseRenameModal}
                                                uploadedFileDetails={uploadedFileDetails}
                                                handleUploadedFileName={handleUploadedFileName}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        <BomTable setModalOpen={setBomModalOpen} />
                        <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ProjectDetails;
