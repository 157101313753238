import { Grid } from '@parspec/pixel';

import StakeHolderInfo from './StakeHolderInformation';
import CoverPageSection from './CoverPageSection';
import CoverPageSectionLogo from './CoverPageSectionLogo';
import OpeningNotes from './OpeningNotes';
import { ADDRESS_TO_LOGO_SUB_SECTION, COVER_PAGE_SECTION_NAME, PREPARED_BY_LOGO_SUB_SECTION } from '../../shared/constants';

function CoverPage() {
    return (
        <Grid container direction={'column'} gap={2}>
            <Grid item>
                <StakeHolderInfo sectionName={COVER_PAGE_SECTION_NAME} />
            </Grid>

            <Grid item mt={2}>
                <CoverPageSection
                    sectionType="prepared_by"
                    renderLogo={(props) => <CoverPageSectionLogo {...props} subSectionName={PREPARED_BY_LOGO_SUB_SECTION} />}
                    sectionLabel={'‘Prepared By’ Information'}
                    sectionName={COVER_PAGE_SECTION_NAME}
                />
            </Grid>

            <Grid item mt={2}>
                <CoverPageSection
                    showAddressToPrefill
                    sectionType="addressed_to"
                    renderLogo={(props) => <CoverPageSectionLogo {...props} subSectionName={ADDRESS_TO_LOGO_SUB_SECTION} />}
                    sectionLabel={'‘Addressed To’ Information'}
                    sectionName={COVER_PAGE_SECTION_NAME}
                />
            </Grid>

            <Grid item width={1}>
                <OpeningNotes sectionName={COVER_PAGE_SECTION_NAME} />
            </Grid>
        </Grid>
    );
}

export default CoverPage;
