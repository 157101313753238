import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { useParspecNavigation } from 'src/app/Context';
import AddSuggestion from '../../../shared/DocumentSuggestions/AddSuggestion';
import Header from './Header';
import DocumentSuggestions from '../../../shared/DocumentSuggestions';
import { useSelectDocumentMutation, useUnSelectDocumentMutation } from '../../../shared/DocumentSuggestions/queries';
import { options } from '../../shared/utils';
import { Document } from '../../../shared/DocumentSuggestions/DocumentItem';
import { useUnsavedChangesDetection } from 'src/app/Context';
import { useProductRequirementGetQuery, useProductRequirementPatchMutation } from '../../productFinder/setRequirements/queries';
import { checkIfReqFieldsAreEmpty } from '../../productFinder/setRequirements/utils';

const MatchDatasheet = () => {
    useUnsavedChangesDetection();
    const [addNewData, setAddNewData] = useState(false);
    const [refresh, setRefresh] = useState<string>('false-0');
    const { productId, bomId, documentType, subDocumentType } = useParams();
    const [selectedDocument, setSelectedDocument] = useState<Document>();
    const [preSelectedDocument, setPreSelectedDocument] = useState<Document>();
    const { setHasUnsavedChanges } = useParspecNavigation();

    const { data: productRequirementData } = useProductRequirementGetQuery(Number(bomId), Number(productId));
    const { mutateAsync: updateProductRequirement, isLoading: isUpdateProductRequirementLoading } = useProductRequirementPatchMutation();

    useEffect(() => {
        return () => {
            setHasUnsavedChanges(false);
        };
    }, []);

    //To toggle the add New Data dialog
    const handleAddNewData = () => setAddNewData(!addNewData);

    const handleRefresh = (refreshFlag?: boolean) => {
        const val = `${String(refreshFlag)}-${Math.random() * 100000}`;
        setRefresh(val);
    };

    const { mutateAsync: selectDocumentAction, isLoading: selectLoading } = useSelectDocumentMutation();

    const { mutateAsync: unSelectDocument, isLoading: unSelectLoading } = useUnSelectDocumentMutation();

    //to select the datalist item
    const onSelectDocument = async (callback?: () => void) => {
        if (productId && selectedDocument) {
            const data = {
                bomId: bomId!,
                bodId: productId,
                documentType: documentType!,
                subDocumentType: subDocumentType!,
                input: {
                    data_origin: selectedDocument.search_result_metadata.type,
                    document_url: selectedDocument.document_url || '',
                    document_hash: selectedDocument.document_hash,
                    source_origin: selectedDocument.search_result_metadata.source_metadata?.source_origin || null,
                    unique_manufacturer_id: selectedDocument.search_result_metadata.source_metadata?.unique_manufacturer_id || null,
                    // This is converted to number as sometimes BE is sending string, However it should be number
                    document_hash_version: selectedDocument?.document_hash_version ? Number(selectedDocument?.document_hash_version) : '',
                    document_preview: selectedDocument.pdf_image,
                    document_internal_url: selectedDocument.document_internal_url
                }
            };

            try {
                await selectDocumentAction(data);
                // this callback will come from preview popup to close the preview popup after selection success.
                if (callback) callback();
            } catch (e) {
                /* empty */
            }
        }
    };

    const attributeOverwritePopupHandler = async () => {
        // if preSelectedDocument === null BUT selectedDocument has value -> fresh case, user has selected a document
        // if selectedDocument === null -> user has unselected all document

        if (!productRequirementData?.data) {
            // if user selects datasheet but never went to PF screen --> attributes will not be generated
            // hence, dataSheetProductRequirements will return null, so we break the condition here itself
            return;
        }

        if (checkIfReqFieldsAreEmpty(productRequirementData.data)) {
            // check to see if setRequirement fields are empty
            return;
        }

        const arrributePopupFieldDetails = productRequirementData.data.find((el) => el.field_name === 'attribute_overwrite_popup');

        const data = {
            bomId: Number(bomId) || 0,
            productId: Number(productId) || 0,
            payload: {
                field_name: arrributePopupFieldDetails?.field_name || '',
                field_value: true,
                id: arrributePopupFieldDetails?.id || 0,
                is_locked: arrributePopupFieldDetails?.is_locked || false
            }
        };

        await updateProductRequirement(data);
    };

    const onUnSelectDocument = async (callBack: () => void) => {
        if (productId) {
            const data = {
                bomId: bomId!,
                bodId: productId,
                documentType: documentType!,
                subDocumentType: subDocumentType!,
                selectedId: preSelectedDocument?.[`selected_document_id`] || ''
            };
            try {
                await unSelectDocument(data);
                if (callBack) callBack();
            } catch (e) {
                // setSnackbarMessage(someThingWentWrongMsg);
            }
        }
    };

    const onSaveDocument = async (callBack: () => void) => {
        if (selectedDocument) {
            onSelectDocument(callBack);
            if (preSelectedDocument && preSelectedDocument?.id !== selectedDocument?.id) {
                // if preSelectedDocument === undefined, it means there was no datasheet selected
                // hence no need to make attributeOverwritePopupHandler true
                attributeOverwritePopupHandler();
            }
        } else {
            if (preSelectedDocument) {
                onUnSelectDocument(callBack);
            } else callBack();
        }
    };

    const headerHeight = subDocumentType !== 'datasheet' ? '120px' : '160px';
    const annotateButtonDisabled = !selectedDocument;

    return (
        <>
            <Header
                handleRefresh={handleRefresh}
                handleAddNewData={handleAddNewData}
                headerHeight={headerHeight}
                buttonLoading={selectLoading || unSelectLoading || isUpdateProductRequirementLoading}
                onClickSave={onSaveDocument}
                annotateButtonDisabled={annotateButtonDisabled}
            />
            {/* 166 px is the height of Header */}
            <Box height={`calc(100vh - ${headerHeight})`} sx={{ overflowY: 'auto', overflowX: 'hidden' }} pt={6} pb={4}>
                <DocumentSuggestions
                    refresh={refresh}
                    setSelectedDocument={setSelectedDocument}
                    selectedDocument={selectedDocument}
                    setPreSelectedDocument={setPreSelectedDocument}
                    attributeOverwritePopupHandler={attributeOverwritePopupHandler}
                />
            </Box>
            {addNewData && <AddSuggestion open={addNewData} handleAddNewData={handleAddNewData} options={options} attributeOverwritePopupHandler={attributeOverwritePopupHandler} />}
        </>
    );
};

export default MatchDatasheet;
