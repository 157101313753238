import { ReactNode, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useDrag, useDrop } from 'react-dnd';
import { Accordion, BodySmall, Box, DeleteOutlineIcon, H6, IconButton, Tooltip, DragIndicatorIcon } from '@parspec/pixel';
import { getEmptyImage } from 'react-dnd-html5-backend';

import CustomDragLayer from './CustomDragLayer';

import { SECTION_DELETE_CONTENT, SECTION_UPDATE_MSG, SECTION_DELETE_MSG, SECTION_REORDER_MESSAGE } from '../../shared/constants';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { useDeleteOptionSectionMutation, useDeleteSectionMutation, useUpdateOptionSectionDetailsMutation, useUpdateSectionDetailsMutation } from '../queries';
import { SectionApiResponseType } from '../queries/apiTypes';
import TextEditor from './TextEditor';
import { useIsTableApisInProgress } from './hooks';
import { useUserEventCount } from '../../shared/UserActivityTracking/TrackActivityCount';
import { DRAGABLE_TYPES } from './utils';
import { BooleanObjType } from './types';

interface SectionProps {
    children: ReactNode;
    section: SectionApiResponseType;
    setSnackbarMessage?: (message: string) => void;
    isOptionSection?: boolean;
    isSectionEmpty: boolean;
    collapseAll: boolean;
    setCollapseAll: (val: boolean) => void;
    onMoveSection: (dragIndex: number, hoverIndex: number, sectionType: string) => void;
    index: number;
    sectionType: string;
    sectionExpansionObj?: any;
    updateSectionExpansionObj: (obj: BooleanObjType) => void;
}

const Section = (props: SectionProps) => {
    const { bomId } = useParams();
    const { pushUserEventCount } = useUserEventCount();
    const {
        section,
        children,
        setSnackbarMessage,
        isOptionSection = false,
        isSectionEmpty,
        collapseAll,
        setCollapseAll,
        onMoveSection,
        index,
        sectionType,
        sectionExpansionObj,
        updateSectionExpansionObj
    } = props;
    const { name, id } = section;
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const sectionRef = useRef(null);

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: `${DRAGABLE_TYPES.SECTION} ${sectionType}`,
            item: { index, name },
            collect: (monitor: any) => ({
                isDragging: Boolean(monitor.isDragging())
            })
        }),
        [index, name, id, onMoveSection]
    );

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: `${DRAGABLE_TYPES.SECTION} ${sectionType}`,
            collect: (monitor: any) => ({
                isOver: Boolean(monitor.isOver())
            }),
            drop: (item: { index: number }) => {
                onMoveSection(item.index, index, sectionType);
                setSnackbarMessage?.(SECTION_REORDER_MESSAGE);
            }
        }),
        [index, name, id, onMoveSection]
    );

    const isTableApiInProgress = useIsTableApisInProgress(bomId || '0');

    const { mutateAsync: updateSectionData } = useUpdateSectionDetailsMutation();
    const { mutateAsync: updateOptionSectionData } = useUpdateOptionSectionDetailsMutation();

    const { mutateAsync: deleteSection, isLoading: deleteLoading } = useDeleteSectionMutation();

    const { mutateAsync: deleteOptionSection, isLoading: deleteOptionLoading } = useDeleteOptionSectionMutation();

    // This code basically feeds an empty image to the browser while doing drag operations for the draggable item.
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    useEffect(() => {
        if (isDragging) {
            setCollapseAll(true);
        } else {
            setCollapseAll(false);
        }
    }, [isDragging]);

    const onEditSectionName = async (updatedName: string, callBack?: (isError?: boolean) => void) => {
        try {
            if (isOptionSection) {
                await updateOptionSectionData({ bomId: Number(bomId), sectionId: id, input: { name: updatedName } });
            } else {
                await updateSectionData({
                    bomId: Number(bomId),
                    sectionId: id,
                    input: { name: updatedName }
                });
            }
            pushUserEventCount({ eventType: 'section_renamed', needCummulation: true });
            if (setSnackbarMessage) setSnackbarMessage(SECTION_UPDATE_MSG);
            if (callBack) callBack();
        } catch (e) {
            if (callBack) callBack(true);
        }
    };

    const toggleDeleteDialog = () => {
        setOpenDeleteDialog(!openDeleteDialog);
    };

    const onClickDelete = (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        toggleDeleteDialog();
    };

    const onDeleteSection = async () => {
        try {
            if (isOptionSection) {
                await deleteOptionSection({ bomId: Number(bomId), sectionId: id });
            } else {
                await deleteSection({ bomId: Number(bomId), sectionId: id });
            }
            toggleDeleteDialog();
            pushUserEventCount({ eventType: 'section_deleted', needCummulation: true });
            if (setSnackbarMessage) setSnackbarMessage(SECTION_DELETE_MSG);
        } catch (e) {
            /* empty */
        }
    };

    drag(drop(sectionRef));

    const sectionHeader = (
        <Box display="flex" alignItems="center" ml={6} boxSizing="border-box">
            <TextEditor inputText={name} onSave={onEditSectionName} maxLength={90}>
                <H6>{name}</H6>
            </TextEditor>
            <Tooltip title={isTableApiInProgress || !isSectionEmpty ? 'Please remove all the products in this section in order to delete it.' : 'Delete Section'}>
                <Box
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <IconButton onClick={onClickDelete} size="small" disabled={isTableApiInProgress || !isSectionEmpty}>
                        <DeleteOutlineIcon fontSize="medium" />
                    </IconButton>
                </Box>
            </Tooltip>
        </Box>
    );

    const handleAccChange = useCallback(() => {
        const obj = { ...sectionExpansionObj };
        if (id in obj) {
            obj[`${id}`] = !obj[`${id}`];
        } else {
            obj[`${id}`] = false;
        }
        updateSectionExpansionObj(obj);
    }, [sectionExpansionObj]);

    const defaultExpandFlag = useMemo(() => (!collapseAll ? (id in sectionExpansionObj ? sectionExpansionObj[`${id}`] : true) : false), [collapseAll, sectionExpansionObj]);

    return (
        <>
            <Box
                my={2}
                sx={{
                    '.MuiAccordionSummary-root': {
                        minHeight: '53px !important',
                        maxHeight: '53px !important',
                        '&.Mui-expanded': {
                            minHeight: '53px !important',
                            maxHeight: '53px !important'
                        },

                        opacity: isDragging ? '0.5' : '1'
                    }
                }}
            >
                <CustomDragLayer />
                <Box ref={sectionRef} position="relative" marginLeft={isOver ? 4 : 0}>
                    <IconButton sx={{ position: 'absolute', top: '8px', left: '38px', zIndex: 1 }}>
                        <DragIndicatorIcon sx={{ cursor: 'move' }} />
                    </IconButton>
                    <Accordion
                        ref={sectionRef}
                        expanded={defaultExpandFlag}
                        options={[{ labelId: String(id), summary: sectionHeader, details: <Box mt={2}>{children}</Box> }]}
                        getPanel={handleAccChange}
                        sx={{ boxShadow: 'none' }}
                    />
                </Box>
            </Box>
            {openDeleteDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteDialog}
                    handleDialog={toggleDeleteDialog}
                    confirmAction={onDeleteSection}
                    loading={deleteLoading || deleteOptionLoading}
                    title="Delete Section?"
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            <p style={{ whiteSpace: 'pre-line' }}>{SECTION_DELETE_CONTENT}</p>
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
        </>
    );
};

export default Section;
