import { Box, Skeleton } from '@parspec/pixel';

import AnnotatedPdfViewer from 'src/features/shared/AnnotatedPdfViewer';
import SearchSection from './SearchSection';

interface RightSectionProps {
    fileUrl?: string;
    isDatasheetLoading: boolean;
}

export default function RightSection({ fileUrl, isDatasheetLoading }: RightSectionProps) {
    return (
        <>
            <Box height="100%" width="50%" position="relative">
                {fileUrl || isDatasheetLoading ? (
                    <>
                        {isDatasheetLoading ? (
                            <Skeleton height="100%" width="100%" variant="rectangular" />
                        ) : (
                            <AnnotatedPdfViewer fileUrl={fileUrl || ''} height="100%" width="100%" documentType="submittal" subDocumentType="datasheet" />
                        )}
                    </>
                ) : (
                    <SearchSection />
                )}
            </Box>
        </>
    );
}
