import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'src/app/queryClient';

import { getSubscriptionInfo, getBillingInfo, getParspecOperatingRegionsInfo, getManageSubscription, postAutoUpgradeSubscription, patchBillingInfo, endPoints } from './apis';

// Keys
const subscriptionInfoKey = [endPoints.subscriptionInfo];
const billingInfoKey = [endPoints.billingInfo];
const parspecOperatingRegionsKey = [endPoints.parspecOperatingRegionsInfo];

// Queries ----------------------------------------------------------------
export const useSubscriptionInfo = (options?: object) => useQuery(subscriptionInfoKey, getSubscriptionInfo, { ...options });

export const useBillingInfoQuery = () => useQuery(billingInfoKey, getBillingInfo);

export const useParspecOperatingRegions = () => useQuery(parspecOperatingRegionsKey, getParspecOperatingRegionsInfo);

// Mutations --------------------------------------------------------------

// GET call
export const useManageSubscriptionMutation = () => useMutation(getManageSubscription);

export const useEditBillingDetailsMutation = () =>
    useMutation(patchBillingInfo, {
        onSuccess: (_, request) => {
            queryClient.setQueryData(billingInfoKey, (oldData: any) => {
                const newData = request;
                return { ...oldData, data: newData };
            });
        }
    });

export const useAutoUpgradeSubscriptionMutation = () =>
    useMutation(postAutoUpgradeSubscription, {
        onSuccess: (_, request) => {
            invalidateSubscriptionInfoQuery();
            queryClient.setQueryData(subscriptionInfoKey, (oldData: any) => {
                return { ...oldData, data: { ...oldData.data, auto_upgrade_to_next_tier: request.auto_upgrade_to_next_tier } };
            });
        }
    });

// Invalidations ----------------------------------------------------------
export function invalidateSubscriptionInfoQuery() {
    queryClient.invalidateQueries(subscriptionInfoKey, { exact: true });
}
