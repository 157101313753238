import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { Box, BodySmall, TextField, Button, BodyBig, Grid, BodyXS, Select, InfoIcon, WarningIcon, SelectChangeEvent } from '@parspec/pixel';

import { initialBranchDetailsState, initialBranchDetailsErrorState } from '../../utils/helper';
import { BranchDetailsDataInterface, BranchDetailsErrorState } from '../SignUp.types';
import { USA_STATES, CANADA_STATES } from '../../../shared/constants';
import { trimEmptySpaces, countryOptions, USA, CANADA, modifiedStateList } from '../../utils/utils';
import { useSignUpNewUserCompanyInfoMutation } from '../../queries';

export const CompanyInfo = () => {
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);

    const { mutateAsync: addCompanyInfoData, isLoading: isAddCompanyInfoDataLoading } = useSignUpNewUserCompanyInfoMutation();

    const companyEmailFromLocalStorage = localStorage.getItem('company_email');
    const [branchDetailsData, setBranchDetailsData] = useState<BranchDetailsDataInterface>(initialBranchDetailsState);
    const [branchDetailsErrorState, setBranchDetailsErrorState] = useState<BranchDetailsErrorState>(initialBranchDetailsErrorState);
    const [isAccountAlreadyExist, setIsAccountAlreadyExist] = useState<boolean>(false);

    const [stateListOptions, setStateListOptions] = useState<
        {
            label: string;
            value: string;
        }[]
    >([{ label: '', value: '' }]);

    useEffect(() => {
        if (!companyEmailFromLocalStorage) {
            navigate('/login');
        }
    }, []);

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const requiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
        const fieldName = event.target?.name as string;
        const fieldValue = event.target?.value as string;

        if (!Object.prototype.hasOwnProperty.call(branchDetailsErrorState, fieldName)) {
            return;
        }

        if (fieldName === 'country') {
            setBranchDetailsData(() => ({
                ...branchDetailsData,
                country: fieldValue,
                state: ''
            }));
            setBranchDetailsErrorState(() => ({
                ...branchDetailsErrorState,
                country: { ...branchDetailsErrorState.country, isError: false, title: '' },
                state: { ...branchDetailsErrorState.state, isError: true, title: 'Required' }
            }));

            let stateOptions: { label: string; value: string }[] = [];

            if (fieldValue === USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (fieldValue === CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            return setStateListOptions(stateOptions);
        }

        if (branchDetailsErrorState[fieldName].isError) {
            setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                [fieldName]: { ...branchDetailsErrorState[fieldName], isError: false, title: '' }
            });
        }
        if (fieldValue === '') {
            setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                [fieldName]: { ...branchDetailsErrorState[fieldName], isError: true, title: 'Required' }
            });
        }

        if (fieldName === 'zipcode') {
            if ((event.target?.value as string).trim().length > 10) {
                setBranchDetailsErrorState({
                    ...branchDetailsErrorState,
                    zipcode: { ...branchDetailsErrorState.zipcode, isError: true, title: 'Max length allowed is 10' }
                });
            }
            return setBranchDetailsData(() => ({ ...branchDetailsData, zipcode: event.target?.value as string }));
        }

        setBranchDetailsData(() => ({ ...branchDetailsData, [fieldName]: fieldValue }));
    };

    const unRequiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (fieldValue.trim().length === 0) {
            return setBranchDetailsData(() => ({ ...branchDetailsData, [fieldName]: '' }));
        }

        setBranchDetailsData(() => ({ ...branchDetailsData, [fieldName]: fieldValue }));
    };

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(branchDetailsData, fieldName)) {
            return;
        }

        if (branchDetailsData[fieldName].length > 0 && eventType === 'blur') {
            if (fieldName === 'address2') {
                const trimmedValue = trimEmptySpaces(branchDetailsData[fieldName]);
                return setBranchDetailsData(() => ({ ...branchDetailsData, [fieldName]: trimmedValue }));
            }

            if (branchDetailsData[fieldName].trim().length === 0) {
                return setBranchDetailsErrorState({ ...branchDetailsErrorState, [fieldName]: { ...branchDetailsErrorState[fieldName], isError: true, title: 'Required' } });
            }

            const trimmedValue = trimEmptySpaces(branchDetailsData[fieldName]);
            setBranchDetailsData(() => ({ ...branchDetailsData, [fieldName]: trimmedValue }));

            if (fieldName === 'zipcode') {
                if (trimmedValue.length <= 10) {
                    return setBranchDetailsErrorState({ ...branchDetailsErrorState, zipcode: { ...branchDetailsErrorState.zipcode, isError: false, title: '' } });
                }
            }
        }
    };

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        if (!companyEmailFromLocalStorage) {
            return;
        }
        event.preventDefault();

        const trimmedBranchName = trimEmptySpaces(branchDetailsData.name);
        const trimmedAddress1 = trimEmptySpaces(branchDetailsData.address1);
        const trimmedAddress2 = branchDetailsData.address2 ? trimEmptySpaces(branchDetailsData.address2) : '';
        const trimmedcity = trimEmptySpaces(branchDetailsData.city);
        const trimmedZipcode = trimEmptySpaces(branchDetailsData.zipcode);

        const branchNameFlag = trimmedBranchName.length === 0;
        const address1Flag = trimmedAddress1.length === 0;
        const cityFlag = trimmedcity.length === 0;
        const countryFlag = branchDetailsData.country.length === 0;
        const stateFlag = branchDetailsData.state.length === 0;
        const zipcodeFlag = branchDetailsData.zipcode.trim().length === 0 || branchDetailsData.zipcode.trim().length > 10;

        if (branchNameFlag || address1Flag || cityFlag || countryFlag || stateFlag || zipcodeFlag) {
            let zipCodeErrorMsg = '';
            if (branchDetailsData.zipcode.trim().length === 0) {
                zipCodeErrorMsg = 'Required';
            }
            if (branchDetailsData.zipcode.trim().length > 10) {
                zipCodeErrorMsg = 'Max length allowed is 10';
            }

            return setBranchDetailsErrorState({
                ...branchDetailsErrorState,
                name: { ...branchDetailsErrorState.name, isError: branchNameFlag, title: `${branchNameFlag ? 'Required' : ''}` },
                address1: { ...branchDetailsErrorState.address1, isError: address1Flag, title: `${address1Flag ? 'Required' : ''}` },
                city: { ...branchDetailsErrorState.city, isError: cityFlag, title: `${cityFlag ? 'Required' : ''}` },
                country: { ...branchDetailsErrorState.country, isError: countryFlag, title: `${countryFlag ? 'Required' : ''}` },
                state: { ...branchDetailsErrorState.state, isError: stateFlag, title: `${stateFlag ? 'Required' : ''}` },
                zipcode: { ...branchDetailsErrorState.zipcode, isError: zipcodeFlag, title: zipCodeErrorMsg }
            });
        }

        const payload = {
            company_name: trimmedBranchName,
            country: branchDetailsData.country,
            address_line_1: trimmedAddress1,
            address_line_2: trimmedAddress2,
            city: trimmedcity,
            state: branchDetailsData.state,
            email: companyEmailFromLocalStorage,
            zipcode: trimmedZipcode
        };

        try {
            await addCompanyInfoData(payload);

            navigate('/signup/personal_info');
        } catch (error: any) {
            if (error?.response) {
                const statusCode = error.response.status;

                if (statusCode === 400) {
                    setIsAccountAlreadyExist(true);
                }
            }
        }
    };

    return (
        <Box bgcolor="secondary.contrastText" width="380px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
                <BodyBig limit={false} fontWeight={500}>
                    Company Information
                </BodyBig>
                <BodySmall limit={false} mt={4} textAlign="center" color={'text.secondary'}>
                    First things first, enter your company details.
                </BodySmall>
            </Box>

            <Box>
                <form onSubmit={onSubmitHandler} ref={formRef}>
                    <Box pt={6}>
                        <Box height={'72px'}>
                            <TextField
                                required
                                label="Company Name"
                                name="name"
                                variant="outlined"
                                value={branchDetailsData.name}
                                autoComplete="off"
                                onBlur={onBlurHandler}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => requiredFieldChangeHandler(e)}
                                onKeyDown={keydownHandler}
                                helperText={branchDetailsErrorState.name.title}
                                error={branchDetailsErrorState.name.isError}
                            />
                        </Box>

                        <Box height={'72px'}>
                            <Grid item md={6}>
                                <Select
                                    required
                                    label="Country *"
                                    name="country"
                                    id="country"
                                    labelId="country"
                                    optionLabelKeyname="label"
                                    optionValueKeyname="value"
                                    options={countryOptions}
                                    value={branchDetailsData.country === 'usa' || branchDetailsData.country === 'Usa' ? branchDetailsData.country.toUpperCase() : branchDetailsData.country}
                                    onChange={requiredFieldChangeHandler}
                                    error={branchDetailsErrorState.country.isError}
                                />
                                {branchDetailsErrorState.country.isError && (
                                    <Box my={2}>
                                        <BodyXS sx={{ fontSize: '10.2857px' }} color={'error.main'}>
                                            {branchDetailsErrorState.country.title}
                                        </BodyXS>
                                    </Box>
                                )}
                            </Grid>
                        </Box>

                        <Box height={'72px'}>
                            <TextField
                                required
                                label="Address Line 1"
                                name="address1"
                                variant="outlined"
                                value={branchDetailsData.address1}
                                autoComplete="off"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => requiredFieldChangeHandler(e)}
                                onBlur={onBlurHandler}
                                onKeyDown={keydownHandler}
                                helperText={branchDetailsErrorState.address1.title}
                                error={branchDetailsErrorState.address1.isError}
                            />
                        </Box>
                        <Box height={'72px'}>
                            <TextField
                                label="Address Line 2"
                                name="address2"
                                variant="outlined"
                                value={branchDetailsData.address2}
                                autoComplete="off"
                                onChange={unRequiredFieldChangeHandler}
                                onBlur={onBlurHandler}
                                onKeyDown={keydownHandler}
                            />
                        </Box>
                        <Box height={'72px'}>
                            <TextField
                                required
                                label="City"
                                name="city"
                                variant="outlined"
                                value={branchDetailsData.city}
                                autoComplete="off"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => requiredFieldChangeHandler(e)}
                                onBlur={onBlurHandler}
                                onKeyDown={keydownHandler}
                                helperText={branchDetailsErrorState.city.title}
                                error={branchDetailsErrorState.city.isError}
                            />
                        </Box>

                        <Box height={'72px'} display="flex " position="relative" justifyContent={'space-between'}>
                            <Box width={'166px'} mr={2}>
                                <Grid item md={6}>
                                    {stateListOptions.length > 0 && (
                                        <>
                                            <Select
                                                required
                                                id="state"
                                                labelId="state"
                                                label="State *"
                                                name="state"
                                                value={branchDetailsData.state}
                                                onChange={(e: SelectChangeEvent<unknown>) => requiredFieldChangeHandler(e)}
                                                options={stateListOptions}
                                                error={branchDetailsErrorState.state.isError}
                                                optionLabelKeyname="label"
                                                optionValueKeyname="value"
                                            />

                                            {branchDetailsErrorState.state.isError && (
                                                <Box my={2}>
                                                    <BodyXS sx={{ fontSize: '10.2857px' }} color={'error.main'}>
                                                        {branchDetailsErrorState.state.title}
                                                    </BodyXS>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Box>
                            <Box width={'158px'}>
                                <TextField
                                    required
                                    label="Zip Code"
                                    name="zipcode"
                                    variant="outlined"
                                    value={branchDetailsData.zipcode}
                                    autoComplete="off"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => requiredFieldChangeHandler(e)}
                                    onBlur={onBlurHandler}
                                    onKeyDown={keydownHandler}
                                    helperText={branchDetailsErrorState.zipcode.title}
                                    error={branchDetailsErrorState.zipcode.isError}
                                />
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Box>

            {isAccountAlreadyExist && (
                <Box>
                    <Box bgcolor={'error.light'} display={'flex'} alignItems={'flex-start'} p={2} mb={6}>
                        <Box color={'error.main'}>
                            <WarningIcon fontSize={'small'} />
                        </Box>
                        <Box ml={2}>
                            <BodySmall limit={false} color={'error.main'}>
                                There is an existing account associated
                            </BodySmall>
                            <BodySmall limit={false} color={'error.main'}>
                                with this company name and location.
                            </BodySmall>
                        </Box>
                    </Box>
                    <Box bgcolor={'warning.light'} display={'flex'} alignItems={'flex-start'} p={2} mb={6}>
                        <Box color={'warning.main'}>
                            <InfoIcon fontSize={'small'} />
                        </Box>
                        <Box ml={2}>
                            <BodySmall limit={false} color={'warning.main'}>
                                If you think this is an error, please
                            </BodySmall>
                            <BodySmall limit={false} color={'warning.main'}>
                                email us at <span style={{ textDecoration: 'underline' }}>support@parspec.io.</span>
                            </BodySmall>
                        </Box>
                    </Box>
                    <Box bgcolor={'primary.light'} display={'flex'} alignItems={'flex-start'} p={2} mb={6}>
                        <Box color={'primary.main'}>
                            <InfoIcon fontSize={'small'} />
                        </Box>
                        <Box ml={2}>
                            <BodySmall limit={false} color={'primary.main'}>
                                City and state should correspond to
                            </BodySmall>
                            <BodySmall limit={false} color={'primary.main'}>
                                the address for your home office.
                            </BodySmall>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box width="100%" mt={2}>
                <Button onClick={onSubmitHandler} isLoading={isAddCompanyInfoDataLoading} fullWidth={true}>
                    Create Company
                </Button>
            </Box>
        </Box>
    );
};
