import { useEffect, useState } from 'react';

import { Box, Modal, LinearProgress, BodyBig, BodyMedium, Img } from '@parspec/pixel';
interface MatchingColumnProps {
    open: boolean;
}
let intervalId: any;
const MatchingColumnHeader = (props: MatchingColumnProps) => {
    const { open } = props;
    const [progress, setProgress] = useState<number>(1);

    useEffect(() => {
        if (open) {
            intervalId = setInterval(() => {
                setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : prevProgress));
            }, 700);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [open]);
    return (
        <Modal open={open}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width="400px" p={4} pb={0} pt={2}>
                <Box width={'100%'} textAlign="center" pb={2}>
                    <BodyBig color={'secondary.main'}> Matching Column Headers</BodyBig>
                </Box>
                <Img src="assets/images/view-files/matching-headers-oval.gif" width="220px" height="219px" />
                <BodyMedium mt={10} mb={1} alignSelf={'flex-start'} color={'secondary.main'}>
                    Sit tight while we make the magic happen...
                </BodyMedium>

                <LinearProgress value={progress} variant="determinate" color="primary" />
            </Box>
        </Modal>
    );
};

export default MatchingColumnHeader;
