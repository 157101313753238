import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Menu, Snackbar, BodySmall, TableHeaderMenuIcon, Tooltip, BodyXS, Switch, AgGridTable, Button } from '@parspec/pixel';

import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import { UsersModal, ProductFinderAccessModal } from '../Modal';
import { ISnackbarDataState, IUsersModalState, IProductFinderAccessModalState } from './userDataTable.types';
import {
    useDeleteUserMutation,
    useProductFinderAccessMutation,
    disableProductFinderAccessToggle,
    useQuotingAccessMutation,
    toggleQuotingAccess,
    useGetSubscriptionInfoMutation,
    useActiveDeactiveUserMutation,
    useGetInvitedQuery,
    useGetActiveOrDeactivatedQuery,
    useSendLoginInvitationMutation,
    useEditInvetedUserDetailsMutation,
    useDeleteInvitedUserMutation
} from '../queries';
import { useGetUserProfileInfoQuery } from '../../MyProfile/queries';
import { useSubscriptionInfo } from '../../PlanAndPayment/queries';
import { LAUNCH_PRODUCT_FINDER_FOR_ALL } from '../../../shared/constants';
import { useFeatureFlag } from '../../../shared/UserActivityTracking/useFeatureFlag';
import { defaultColDef, getCellStyle, getRowId } from '../utils/helper';
import { TABLE_ROW_HEIGHT } from 'src/features/ReportingDashboard/shared/constants';
import DeactivateConfirmationDialog from 'src/features/shared/DeactivateConfirmationDialog';
import { DEAFULT_SNACKBAR_MSG, RESEND_MESSAGE, TOOLBAR_OPTIONS } from '../utils/constants';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { StatusTemplate, productFinderAccessDisabledTemplate, quotingAccessDisabledTemplate } from './UserDataTableTemplate';
import { MenuOption } from '../queries/apiTypes';
import { getDateForTable } from 'src/features/Dashboard/shared/utils';

export const UserDataTable = ({ isTabDataLoaded }: { isTabDataLoaded: boolean }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const currentTab = params?.get('tab');

    const [usersModalState, setUsersModalState] = useState<IUsersModalState>({
        isOpen: false,
        isEdit: false,
        data: {}
    });
    const [searchText, setSearchText] = useState('');

    const [openDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>();
    const [openDeactivateUserModal, setOpenDeactivateUserModal] = useState<boolean>(false);
    const [deactivateId, setDeactivateId] = useState<number | null>();
    const [snackbarData, setSnackbarData] = useState<ISnackbarDataState>({
        isActive: false,
        message: ''
    });
    const [productFinderAccessModalState, setProductFinderAccessModalState] = useState<IProductFinderAccessModalState>({
        isOpen: false,
        data: {}
    });

    const { data: activeAndDeactiveData, isLoading: isActiveOrDeactiveLoading } = useGetActiveOrDeactivatedQuery(currentTab || '', {
        enabled: Boolean(currentTab) && (currentTab === 'active' || currentTab === 'inactive')
    });
    const {
        data: invitedData,
        isLoading: isInvitedLoading,
        isFetching: isInvitedFetching
    } = useGetInvitedQuery({
        enabled: Boolean(currentTab) && currentTab === 'invited'
    });

    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();

    const { mutateAsync: getSubscriptionInfo, isLoading: isSubscriptionInfoMutationLoading } = useGetSubscriptionInfoMutation();

    const { data: subscriptionInfoData, isLoading: isSubscriptionInfoLoading, isFetching: isSubscriptionInfoFetching } = useSubscriptionInfo();
    const { mutateAsync: modifyProductFinderAccessMutation, isLoading: isProductFinderAccessMutationLoading } = useProductFinderAccessMutation();

    const { mutateAsync: modifyQuotingAccessMutation, isLoading: isQuotingAccessMutationLoading } = useQuotingAccessMutation();

    const { enable_quoting, isLoading: isQuotingFeatureFlagLoading } = useFeatureFlag();

    const { mutateAsync: deleteUserMutation, isLoading: deleteUserLoading } = useDeleteUserMutation();
    const { mutateAsync: deleteInvitedUserMutation, isLoading: deleteInvitedUserLoading } = useDeleteInvitedUserMutation();
    const { mutateAsync: deactivateUserMutation, isLoading: deactivateUserLoading } = useActiveDeactiveUserMutation();
    const { mutateAsync: sendLoginInvitation, isLoading: loginInvitationLoading } = useSendLoginInvitationMutation();
    const { mutateAsync: editInvitedUserDetailsMutation, isLoading: editInvitedUserDetailsLoading } = useEditInvetedUserDetailsMutation();

    const allUserInfo = currentTab === 'invited' ? invitedData : activeAndDeactiveData;
    const isAllUserInfoLoading = currentTab === 'invited' ? isInvitedLoading || loginInvitationLoading || isInvitedFetching || editInvitedUserDetailsLoading : isActiveOrDeactiveLoading;

    useEffect(() => {
        if (!allUserInfo?.data || !subscriptionInfoData?.data) {
            return;
        }
    }, [allUserInfo?.data, subscriptionInfoData?.data]);

    useEffect(() => {
        // reset search Text when tab switch
        handleSearch('');
    }, [currentTab]);

    const isSubscriptionActive = subscriptionInfoData?.data?.active;

    const handleResend = async (props: ICellRendererParams) => {
        const allUsersnew = {
            emails: [props?.data?.user_details.email],
            companies: props?.data?.company_details,
            role: props?.data?.roles?.[0]?.role as 'user' | 'admin',
            access: { quote: props?.data?.user_details.quoting_enabled, product_finder: props?.data?.user_details.product_finder_enabled }
        };
        const payload = {
            users: allUsersnew
        };
        try {
            const response = await sendLoginInvitation(payload);
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response.data.message
            });
        } catch (e) {
            setSnackbarData({
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
    };

    const inviteTemplete = useCallback((props: ICellRendererParams, isUser: boolean) => {
        return (
            <Tooltip placement="right" title={!props?.data?.user_details.can_be_invited && RESEND_MESSAGE}>
                <span>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleResend(props)}
                        size="small"
                        disabled={!props?.data?.user_details.can_be_invited || isUser}
                    >
                        Resend
                    </Button>
                </span>
            </Tooltip>
        );
    }, []);

    const permissionTemplate = useCallback((props: ICellRendererParams) => {
        const rawRole = props.data.roles[0]?.role;
        let role;
        if (rawRole === 'admin') {
            role = 'Admin';
        } else if (rawRole === 'user') {
            role = 'User';
        }
        return (
            <Box>
                <BodySmall>{role}</BodySmall>
            </Box>
        );
    }, []);

    const emailTemplate = useCallback((props: ICellRendererParams) => {
        return (
            <BodySmall
                color={'primary.main'}
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(props?.data.user_details?.email);
                    setSnackbarData({
                        ...snackbarData,
                        isActive: true,
                        message: 'Email copied to clipboard'
                    });
                }}
            >
                {props?.data.user_details?.email}
            </BodySmall>
        );
    }, []);

    const locationTemplate = useCallback((props: ICellRendererParams) => {
        const all_company_details = props.data.company_details;
        const totalLocationCount = all_company_details.reduce((acc: number, curr: any) => {
            return curr.company_name ? acc + 1 : acc;
        }, 0);
        let minLength;
        if (totalLocationCount === 1) {
            minLength = 12;
        } else {
            minLength = 7;
        }
        // To add space after each location ---> "abc, def" and not "abc,def"
        const commaSpacedLocations = props?.data?.company_locations_str.replace(/,/g, ', ');
        return (
            <Box display="flex" justifyContent="flex-start">
                <Tooltip placement="right" title={commaSpacedLocations}>
                    <Box>
                        <BodyXS py={0.5} px={1.5} mx={0.5} borderRadius={0.5} bgcolor={'neutral.main'}>
                            {all_company_details[0].company_name.length > minLength ? all_company_details[0].company_name.slice(0, minLength) + '...' : all_company_details[0].company_name}
                        </BodyXS>
                    </Box>
                </Tooltip>

                {totalLocationCount > 1 && (
                    <Tooltip placement="right" title="This user is part of multiple locations. Edit Details to view and edit the locations this user is assigned to.">
                        <Box>
                            <BodyXS py={0.5} px={1.5} mx={0.5} borderRadius={0.5} bgcolor={'neutral.main'} color={'secondary.dark'} maxWidth={'max-content'}>
                                +{totalLocationCount - 1}
                            </BodyXS>
                        </Box>
                    </Tooltip>
                )}
            </Box>
        );
    }, []);

    const productFinderAccessTemplate = useCallback(
        (props: ICellRendererParams) => {
            return (
                <Box display="flex">
                    <Box margin="auto">
                        <Switch
                            color="primary"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => openProductFinderAccessModalHandler(e, props)}
                            checked={props.data.user_details.product_finder_enabled}
                        />
                    </Box>
                </Box>
            );
        },
        [currentTab]
    );

    const quotingAccessTemplate = useCallback(
        (props: ICellRendererParams) => {
            return (
                <Box display="flex">
                    <Box margin="auto">
                        <Switch color="primary" onChange={(e: React.ChangeEvent<HTMLInputElement>) => quotingAccessToggleHandler(e, props)} checked={props.data.user_details.quoting_enabled} />
                    </Box>
                </Box>
            );
        },
        [currentTab]
    );

    const menuTemplate = useCallback(
        (props: ICellRendererParams) => {
            const options: MenuOption[] = [
                {
                    label: 'Edit Details',
                    onClick: () => {
                        setUsersModalState((prev: IUsersModalState) => ({
                            ...prev,
                            isOpen: true,
                            isEdit: true,
                            data: props
                        }));
                    }
                }
            ];

            if (currentTab === 'active') {
                options.push({
                    label: 'Deactivate User',
                    onClick: () => handleDeactivate(props),
                    color: 'error.main'
                });
            }

            if (currentTab === 'inactive') {
                options.push({
                    label: 'Reactivate User',
                    onClick: () => handleReactivate(props)
                });
            }

            if (currentTab === 'invited') {
                options.push({
                    label: ' Resend Invite',
                    onClick: () => handleResend(props),
                    toolTipMessage: !props?.data?.user_details.can_be_invited ? RESEND_MESSAGE : '',
                    disabled: !props?.data?.user_details.can_be_invited
                });
            }

            if (currentTab !== 'active') {
                options.push({ label: 'Delete User', onClick: () => onClickDelete(props), color: 'error.main' });
            }

            return (
                <Box display="flex" justifyContent="center">
                    <Menu options={options} />
                </Box>
            );
        },
        [currentTab]
    );

    const disableProductFinderAccessHandler = async (userId: number, isInvitedTab: boolean) => {
        try {
            if (isInvitedTab) {
                const payload = {
                    has_product_finder_access: false
                };

                await editInvitedUserDetailsMutation({ userId, data: payload });
            } else {
                await modifyProductFinderAccessMutation({ userId: userId, payload: { product_finder_enabled: false } });
            }
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Updated successfully'
            });
            return;
        } catch (err) {
            disableProductFinderAccessToggle({ userId, isDisablePFSuccessful: false, isInvitedTab });
        }
    };

    const openProductFinderAccessModalHandler = useCallback(
        async (_e: React.ChangeEvent<HTMLInputElement>, props: ICellRendererParams) => {
            const userId = props.data.user_details.id;
            const isInvitedTab = currentTab === 'invited';

            if (!props.data.user_details.product_finder_enabled) {
                // If product finder toggle is unchecked
                // if we have unused seats, dont show modal but fire api request

                // doing this because the GET query was returning undefined
                const subscriptionResponse = await getSubscriptionInfo();
                const hasUnusedSeats = subscriptionResponse?.data.product_finder_seats_in_use < subscriptionResponse?.data.product_finder_seats_purchased;
                if (hasUnusedSeats && !isInvitedTab) {
                    await modifyProductFinderAccessMutation({ userId: userId, payload: { product_finder_enabled: true } });

                    setSnackbarData({
                        ...snackbarData,
                        isActive: true,
                        message: 'Updated successfully'
                    });
                    return;
                }
                // If all seats are filled, then show modal

                setProductFinderAccessModalState(() => ({ ...productFinderAccessModalState, isOpen: true, data: props }));
            } else {
                // User wants to disable PF

                disableProductFinderAccessToggle({ userId, isDisablePFSuccessful: true, isInvitedTab });
                try {
                    disableProductFinderAccessHandler(userId, isInvitedTab);
                } catch (err) {
                    // Revert the local changes made before this try/catch block
                }
            }
        },
        [currentTab]
    );

    const quotingAccessHandler = async (userId: number, checkedStatus: boolean, isInvitedTab?: boolean) => {
        try {
            if (isInvitedTab) {
                const payload = {
                    has_quote_access: checkedStatus
                };

                await editInvitedUserDetailsMutation({ userId, data: payload });
            } else {
                await modifyQuotingAccessMutation({ userId: userId, payload: { quoting_enabled: checkedStatus } });
            }
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Updated successfully'
            });
        } catch (err) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
    };

    const quotingAccessToggleHandler = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>, props: ICellRendererParams) => {
            const userId = props.data.user_details.id;
            const checkedStatus = e.target.checked;
            const isInvitedTab = currentTab === 'invited';
            toggleQuotingAccess({ isInvitedTab, userId: userId });
            quotingAccessHandler(userId, checkedStatus, isInvitedTab);
        },
        [currentTab]
    );

    const closeConfirmationDialog = () => {
        setOpenDeleteUserModal(false);
    };

    const onClickDelete = (props: ICellRendererParams) => {
        setDeleteId(props.data.user_details.id);
        setOpenDeleteUserModal(true);
    };

    const handleDeactivate = (props: ICellRendererParams) => {
        setDeactivateId(props.data.user_details.id);
        setOpenDeactivateUserModal(true);
    };

    const handleReactivate = async (props: ICellRendererParams) => {
        try {
            const response = await deactivateUserMutation({ activeUser: 'activate', userid: props.data.user_details.id! });
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response?.data?.message
            });
        } catch (error) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
    };
    const closeDeactivateConfirmationDialog = () => {
        setOpenDeactivateUserModal(false);
    };
    const onDeactivateConfirm = async () => {
        if (userProfile?.data?.id === deactivateId) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Cannot deactivate self'
            });
            return;
        }
        try {
            const response = await deactivateUserMutation({ activeUser: 'deactivate', userid: deactivateId! });
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response?.data?.message
            });

            setDeactivateId(null);
            closeDeactivateConfirmationDialog();
        } catch (error) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
    };

    const onDeleteLineItem = async () => {
        if (userProfile?.data?.id === deleteId) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'Cannot delete.'
            });
            return;
        }
        try {
            if (currentTab === 'invited') {
                await deleteInvitedUserMutation(deleteId!);
            } else {
                await deleteUserMutation(deleteId!);
            }
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: 'User deleted!'
            });

            setDeleteId(null);
            closeConfirmationDialog();
        } catch (error) {
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: DEAFULT_SNACKBAR_MSG
            });
        }
    };

    const loadFlag =
        isAllUserInfoLoading ||
        isAdminLoading ||
        isSubscriptionInfoLoading ||
        isSubscriptionInfoFetching ||
        isProductFinderAccessMutationLoading ||
        isSubscriptionInfoMutationLoading ||
        isQuotingAccessMutationLoading ||
        isQuotingFeatureFlagLoading ||
        isTabDataLoaded ||
        isInvitedFetching ||
        isInvitedLoading;

    const tableRef = useRef<any>();

    const columnDefs = useMemo<ColDef[]>(() => {
        return [
            {
                field: 'user_details.id',
                hide: true
            },

            {
                field: 'user_details.name',
                headerName: 'Name',
                editable: false,
                minWidth: 150,
                width: 150,
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered',
                hide: currentTab === 'invited'
            },
            {
                field: 'user_details.email',
                headerName: 'Email',
                editable: false,
                minWidth: 150,
                width: 150,
                cellRenderer: emailTemplate,
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },
            {
                field: 'user_details.status',
                headerName: 'Status',
                editable: false,
                minWidth: 150,
                width: 150,
                hide: currentTab !== 'invited',
                cellRenderer: StatusTemplate,
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },
            {
                field: 'user_details.updated_at',
                headerName: 'Last Active',
                editable: false,
                minWidth: 150,
                width: 150,
                hide: currentTab === 'invited',
                cellRenderer: (params: ICellRendererParams) => getDateForTable(params?.data?.user_details[currentTab === 'inactive' ? 'updated_at' : 'last_activity']),
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },
            {
                field: 'user_details.is_active',
                headerName: 'Invite',
                editable: false,
                minWidth: 100,
                width: 150,
                hide: currentTab !== 'invited',
                cellRenderer: (props: ICellRendererParams) => inviteTemplete(props, userProfile?.data?.role === 'user'),
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },
            {
                field: 'roles.0.role',
                headerName: 'Permission',
                editable: false,
                minWidth: 150,
                width: 150,
                cellRenderer: permissionTemplate,
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },

            {
                field: 'user_details.product_finder_enabled',
                headerName: 'Product Finder Access',
                editable: false,
                minWidth: 150,
                width: 160,
                cellStyle: getCellStyle,
                cellRenderer:
                    LAUNCH_PRODUCT_FINDER_FOR_ALL && Boolean(isSubscriptionActive) && userProfile?.data?.role === 'admin' && currentTab !== 'inactive'
                        ? productFinderAccessTemplate
                        : productFinderAccessDisabledTemplate
            },
            {
                field: 'user_details.quoting_enabled',
                headerName: 'Quoting Access',
                editable: false,
                minWidth: 150,
                width: 160,
                cellRenderer: currentTab !== 'inactive' ? quotingAccessTemplate : quotingAccessDisabledTemplate,
                hide: !enable_quoting || userProfile?.data?.role === 'user',
                cellStyle: getCellStyle
            },
            {
                field: 'company_locations_arr',
                headerName: 'Branch Location',
                editable: false,
                minWidth: 150,
                width: 150,
                cellRenderer: locationTemplate,
                cellStyle: getCellStyle,
                cellClass: 'grid-cell-centered'
            },
            {
                headerComponent: () => (
                    <Box mt={1}>
                        <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                    </Box>
                ),
                cellRenderer: menuTemplate,
                pinned: 'right',
                minWidth: 50,
                maxWidth: 50,
                resizable: false,
                hide: userProfile?.data?.role !== 'admin',
                cellStyle: getCellStyle
            }
        ];
    }, [allUserInfo?.data, currentTab, enable_quoting, userProfile?.data, subscriptionInfoData?.data]);

    const handleSearch = (value: string) => {
        setSearchText(value);
    };

    return (
        <>
            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <AgGridTable
                    ref={tableRef}
                    getRowId={getRowId}
                    isLoading={loadFlag}
                    rowHeight={TABLE_ROW_HEIGHT}
                    showToolbarPanel
                    rowData={loadFlag ? null : allUserInfo?.data || []}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    suppressContextMenu
                    suppressMenuHide
                    toolBarPanelOptions={TOOLBAR_OPTIONS}
                    onTextSearch={handleSearch}
                    quickFilterText={searchText}
                    pagination={true}
                    paginationAutoPageSize
                />
            </Box>

            {openDeleteUserModal && (
                <DeleteConfirmationDialog
                    title={'Permanently Delete User?'}
                    open={openDeleteUserModal}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={onDeleteLineItem}
                    continueButtonLabel={'Delete'}
                    loading={deleteUserLoading || deleteInvitedUserLoading}
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            Are you sure you want to permanently delete this user?
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            This user’s details will be removed from this account for all locations. This action cannot be undone.{' '}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}

            {openDeactivateUserModal && (
                <DeactivateConfirmationDialog
                    open={openDeactivateUserModal}
                    handleDialog={closeDeactivateConfirmationDialog}
                    confirmAction={onDeactivateConfirm}
                    continueButtonLabel={'Deactivate'}
                    loading={deactivateUserLoading}
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            Are you sure you want to deactivate this user?
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            This user will no longer have access to Parspec. This action can be undone by reactivating the user, or made permanent by permanently deleting this user from the user
                            management page.{' '}
                        </BodySmall>
                    </Box>
                </DeactivateConfirmationDialog>
            )}

            {usersModalState?.isOpen && (
                <>
                    <UsersModal
                        isOpen={usersModalState?.isOpen}
                        setIsOpen={setUsersModalState}
                        rowData={usersModalState?.data}
                        isEdit={usersModalState?.isEdit}
                        snackbarData={snackbarData}
                        setSnackbarData={setSnackbarData}
                    />
                </>
            )}

            {productFinderAccessModalState?.isOpen && (
                <>
                    <ProductFinderAccessModal
                        isOpen={productFinderAccessModalState?.isOpen}
                        setIsOpen={setProductFinderAccessModalState}
                        rowData={productFinderAccessModalState?.data}
                        snackbarData={snackbarData}
                        setSnackbarData={setSnackbarData}
                        isInvitedTab={currentTab === 'invited'}
                    />
                </>
            )}

            {
                <Snackbar
                    open={snackbarData.isActive}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    autoHideDuration={1000}
                    message={snackbarData.message}
                    onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })}
                />
            }
        </>
    );
};
