import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';

import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { debounce } from 'lodash-es';

import { BodyXS, Box, FilterAltOffIcon, IconButton, SearchIcon, Status, TextField, Tooltip } from '@parspec/pixel';

import { StatusTemplate, TableLinkTemplate } from 'src/features/shared/utils/utils';
import { DOCUMENT_STATUS_COLOR_MAP, ColorCode, DocumentStatusEnum, PROJECT_STAGE_OPTIONS_MAP } from 'src/features/shared/constants';
import { usePricingHistoryMutation } from '../queries';
import { getPricingHistoryTableData, PrimaryCustomerTemplate, SecondaryCustomerTemplate, getLastModifiedFilterText, dateComparator, customerComparator } from './utils';
import { PricingHistoryTableDataType } from './types';
import { getCellAmountFormatter, getCellPercentFormatter } from '../../BOM/shared/utils';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../../BOM/shared/commonTemplates';

const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    wrapHeaderText: true
};

const columnDefs: ColDef[] = [
    { headerName: 'Edited On', field: 'editedOn', sortable: true, width: 116, minWidth: 116, comparator: dateComparator },
    { headerName: 'Edited By', field: 'editedBy', suppressMenu: false, filter: 'agSetColumnFilter', width: 100, minWidth: 100 },
    { headerName: 'Qty', field: 'qty', sortable: true, width: 80, minWidth: 80 },
    { headerName: 'Manufacturer', field: 'manufacturer', sortable: true, width: 176, minWidth: 176 },
    {
        headerName: 'Model Number',
        field: 'modelNumber',
        sortable: true,
        width: 200,
        minWidth: 200
    },
    { headerName: 'Description', field: 'description', width: 188, minWidth: 188 },
    {
        headerName: 'Cost',
        field: 'cost',
        sortable: true,
        width: 100,
        minWidth: 100,
        valueFormatter: getCellAmountFormatter,
        getQuickFilterText: getCellAmountFormatter
    },
    {
        headerName: 'Discount (%)',
        field: 'discount',
        width: 92,
        minWidth: 92,

        valueFormatter: getCellPercentFormatter,
        getQuickFilterText: getCellPercentFormatter
    },
    {
        headerName: 'Discounted Cost',
        field: 'discountedCost',
        width: 104,
        minWidth: 104,

        valueFormatter: getCellAmountFormatter,
        getQuickFilterText: getCellAmountFormatter
    },
    {
        headerName: 'Margin (%)',
        field: 'margin',
        width: 84,
        minWidth: 84,

        valueFormatter: getCellPercentFormatter,
        getQuickFilterText: getCellPercentFormatter
    },
    {
        headerName: 'Sell Price',
        field: 'sellPrice',
        sortable: true,
        width: 100,
        minWidth: 100,

        valueFormatter: getCellAmountFormatter,
        getQuickFilterText: getCellAmountFormatter
    },
    { headerName: 'Lead time Value', field: 'leadTimeValue', sortable: true, width: 152, minWidth: 152 },
    { headerName: 'Lead time Unit', field: 'leadTimeUnit', sortable: true, width: 152, minWidth: 152 },
    {
        headerName: 'Project',
        width: 180,
        minWidth: 180,
        cellRenderer: (params: ICellRendererParams) => <TableLinkTemplate to={`/v2/project/${params.data.projectId}`} label={params.data.projectName} target="_blank" />,
        getQuickFilterText: (params) => params.data.projectName
    },
    {
        headerName: 'Project Stage',
        field: 'projectStage',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        width: 156,
        minWidth: 156,
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <Box display="flex" alignItems="center" height="100%">
                    {StatusTemplate(params.data.projectStage)}
                </Box>
            );
        },
        getQuickFilterText: (params) => PROJECT_STAGE_OPTIONS_MAP[params.data.projectStage]
    },
    { headerName: 'Branch Location', field: 'branchLocation', suppressMenu: false, filter: 'agSetColumnFilter', width: 120, minWidth: 120 },
    { headerName: 'Quote(s) Owner', field: 'quoteOwner', suppressMenu: false, filter: 'agSetColumnFilter', width: 136, minWidth: 136 },
    {
        headerName: 'BOM',
        width: 140,
        minWidth: 140,
        cellRenderer: (params: ICellRendererParams) => <TableLinkTemplate to={`/v2/project/${params?.data?.projectId}/bom/${params.data.bomId}?tab=bom`} label={params.data.bomName} target="_blank" />,
        getQuickFilterText: (params) => params.data.bomName
    },
    {
        headerName: 'Primary Customer',
        width: 140,
        minWidth: 140,
        sortable: true,
        filter: 'agSetColumnFilter',
        suppressMenu: false,
        cellRenderer: (params: ICellRendererParams) => PrimaryCustomerTemplate(params),
        getQuickFilterText: (params: any) => getLastModifiedFilterText(params, 'primaryCustomer'),
        comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => customerComparator(valueA, valueB, nodeA, nodeB, isDescending, 'primaryCustomer'),
        valueGetter: (params: any) => params?.data?.primaryCustomer?.company_name
    },
    {
        headerName: 'Secondary Customer',
        width: 140,
        minWidth: 140,
        sortable: true,
        filter: 'agSetColumnFilter',
        suppressMenu: false,
        cellRenderer: (params: ICellRendererParams) => SecondaryCustomerTemplate(params),
        getQuickFilterText: (params: any) => getLastModifiedFilterText(params, 'secondaryCustomer'),
        comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => customerComparator(valueA, valueB, nodeA, nodeB, isDescending, 'secondaryCustomer'),
        valueGetter: (params: any) => params?.data?.secondaryCustomer?.company_name
    },
    {
        headerName: 'Quote Status',
        width: 120,
        minWidth: 120,
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        valueGetter: (params: ValueGetterParams) => params.data.quoteStatus || DocumentStatusEnum.PENDING,
        cellRenderer: (params: ICellRendererParams) => {
            const status = params.value;

            const color = DOCUMENT_STATUS_COLOR_MAP[status as keyof typeof DOCUMENT_STATUS_COLOR_MAP] as ColorCode;
            return (
                <Box display={'flex'} alignItems="center" height="100%">
                    <Status color={color}>
                        <BodyXS color={color}>{status}</BodyXS>
                    </Status>
                </Box>
            );
        }
    },
    { headerName: 'Outside Sales', field: 'salesAgent', width: 136, minWidth: 136, suppressMenu: false, filter: 'agSetColumnFilter' }
];

interface PricingBodyProps {
    manufacturer?: string;
    modelNo?: string;
    onSelectionChange?: (selectedRow: PricingHistoryTableDataType[]) => void;
    isForPricingHistoryTab?: boolean;
}

export default function PricingHistoryBody({ manufacturer, modelNo, onSelectionChange, isForPricingHistoryTab = false }: PricingBodyProps) {
    const [manufacturerSearchVal, setManufacturerSearchVal] = useState(manufacturer || '');
    const [modelNoSearchVal, setModelNoSearchVal] = useState(modelNo || '');
    const [adhocSearchVal, setAdhocSearchVal] = useState('');
    const [gridReady, setGridReady] = useState(false);

    const { mutateAsync: postPricingHistory, isLoading } = usePricingHistoryMutation();
    const [pricingHistoryData, setPricingHistoryData] = useState<PricingHistoryTableDataType[] | null>(null);

    const tableRef = useRef<any>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    const searchDebounceRef = useRef({
        handleSearch: debounce(handleSearch, 250)
    });
    const isInitSearchDone = useRef(false);

    async function handleSearch(manufacturer: string, modelNo: string) {
        let responseData: PricingHistoryTableDataType[] = [];
        if (abortControllerRef.current?.signal && !abortControllerRef.current.signal.aborted) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        try {
            const pricingHistoryData = await postPricingHistory({ payload: { manufacturer: manufacturer, model_number: modelNo }, controller: abortControllerRef.current });
            responseData = getPricingHistoryTableData(pricingHistoryData.data || []);
            setPricingHistoryData(responseData);
        } catch (error: any) {
            // catch is for catching abort errors
        }
    }

    useLayoutEffect(() => {
        if (!isInitSearchDone.current) {
            handleSearch(manufacturerSearchVal.trim(), modelNoSearchVal.trim()).then(() => {
                isInitSearchDone.current = true;
            });
        }
        if (isInitSearchDone.current) {
            searchDebounceRef.current.handleSearch(manufacturerSearchVal.trim(), modelNoSearchVal.trim());
        }
    }, [manufacturerSearchVal.trim(), modelNoSearchVal.trim()]);

    useEffect(() => {
        if (!gridReady) {
            return;
        }
        if (isLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else if (!isLoading && Array.isArray(pricingHistoryData) && pricingHistoryData.length === 0) {
            tableRef?.current?.api?.showNoRowsOverlay();
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isLoading, pricingHistoryData, gridReady]);

    function handleManufacturerChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const name = event.target.name;
        if (name === 'manufacturer') {
            setManufacturerSearchVal(event.target.value);
        } else if (name === 'modelNo') {
            setModelNoSearchVal(event.target.value);
        } else if (name === 'adhoc') {
            setAdhocSearchVal(event.target.value);
        }
    }

    function handleClearFilters() {
        tableRef.current?.api?.setFilterModel(null);
    }

    function handleSelectionChange() {
        onSelectionChange?.(tableRef.current?.api?.getSelectedRows());
    }

    return (
        <Box width="100%" height="100%">
            <Box display="flex" gap={2} paddingBottom={4}>
                <Box width={300}>
                    <TextField
                        label={isForPricingHistoryTab ? 'Manufacturer' : 'Manufacturer must contain'}
                        size="small"
                        onChange={handleManufacturerChange}
                        value={manufacturerSearchVal}
                        name="manufacturer"
                    />
                </Box>
                <Box width={300}>
                    <TextField label={isForPricingHistoryTab ? 'Model Number' : 'Model Number must contain'} size="small" onChange={handleManufacturerChange} value={modelNoSearchVal} name="modelNo" />
                </Box>
                <Box width={300}>
                    <TextField value={adhocSearchVal} label="" placeholder="Search..." startIcon={<SearchIcon fontSize="small" />} size="small" onChange={handleManufacturerChange} name="adhoc" />
                </Box>
                <Box>
                    <Tooltip title="Clear Filter(s)">
                        <IconButton onClick={handleClearFilters}>
                            <FilterAltOffIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box height="calc(100% - 52.7px)" className="ag-theme-alpine">
                <AgGridReact
                    ref={tableRef}
                    rowSelection={isForPricingHistoryTab ? undefined : 'single'}
                    quickFilterText={adhocSearchVal}
                    gridOptions={{
                        suppressContextMenu: true,
                        suppressMenuHide: true,
                        suppressMovableColumns: true,
                        enableRangeSelection: true
                    }}
                    rowHeight={52}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={pricingHistoryData}
                    modules={[GridChartsModule]}
                    loadingOverlayComponent={loadingOverlayComponent}
                    noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                    onGridReady={() => setGridReady(true)}
                    onSelectionChanged={handleSelectionChange}
                />
            </Box>
        </Box>
    );
}
