import { BranchLocationsTabDataResponse, CustomersTabDataResponse, ManufacturersTabDataResponse, QuoteOwnersTabDataResponse } from '../queries/apiTypes';
import { convertToDollar } from '../../shared/utils/utils';
import { TABLE_ROW_HEIGHT, TAB_NAMES } from './constants';
import { FilterType } from './components/Filters/constants';
import { v4 as uuidV4 } from 'uuid';

export const getWinRateChartData = (data: CustomersTabDataResponse[] | BranchLocationsTabDataResponse[] | QuoteOwnersTabDataResponse[], tabName: string) => {
    let chartData = [];
    switch (tabName) {
        case TAB_NAMES.QUOTE_OWNERS:
            chartData = data.map((userObj) => {
                const { user, winRate } = userObj as QuoteOwnersTabDataResponse;
                return { name: user, winRate: Number(winRate.toFixed(2)) };
            });
            break;
        case TAB_NAMES.CUSTOMER:
            chartData = data.map((customerObj) => {
                const { customer, winRate } = customerObj as CustomersTabDataResponse;
                return { name: customer, winRate: Number(winRate.toFixed(2)) };
            });
            break;
        default:
            chartData = data.map((locationObj) => {
                const { location, winRate } = locationObj as BranchLocationsTabDataResponse;
                return { name: location, winRate: Number(winRate.toFixed(2)) };
            });
    }

    return chartData.sort((a, b) => b.winRate - a.winRate).slice(0, 10);
};

export const getProfitMarginChartData = (data: CustomersTabDataResponse[] | BranchLocationsTabDataResponse[] | QuoteOwnersTabDataResponse[] | ManufacturersTabDataResponse[], tabName: string) => {
    let chartData = [];
    switch (tabName) {
        case TAB_NAMES.QUOTE_OWNERS:
            chartData = data.map((userObj) => {
                const { user, totalExtSellPrice, services, freight, totalExtDiscountedCost } = userObj as QuoteOwnersTabDataResponse;
                const revenue = totalExtSellPrice + services + freight;
                const profitMargin = !revenue ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / revenue) * 100;
                return { name: user, profitMargin: Number(profitMargin.toFixed(2)) };
            });
            break;
        case TAB_NAMES.CUSTOMER:
            chartData = data.map((customerObj) => {
                const { customer, totalExtSellPrice, services, freight, totalExtDiscountedCost } = customerObj as CustomersTabDataResponse;
                const revenue = totalExtSellPrice + services + freight;
                const profitMargin = !revenue ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / revenue) * 100;
                return { name: customer, profitMargin: Number(profitMargin.toFixed(2)) };
            });
            break;
        case TAB_NAMES.BRANCH_LOCATIONS:
            chartData = data.map((locationObj) => {
                const { location, totalExtSellPrice, services, freight, totalExtDiscountedCost } = locationObj as BranchLocationsTabDataResponse;
                const revenue = totalExtSellPrice + services + freight;
                const profitMargin = !revenue ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / revenue) * 100;
                return { name: location, profitMargin: Number(profitMargin.toFixed(2)) };
            });
            break;
        default:
            chartData = data.map((locationObj) => {
                const { manufacturer, totalExtSellPrice, totalExtDiscountedCost } = locationObj as ManufacturersTabDataResponse;
                const profitMargin = !totalExtSellPrice ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / totalExtSellPrice) * 100;
                return { name: manufacturer, profitMargin: Number(profitMargin.toFixed(2)) };
            });
    }
    return chartData.sort((a, b) => b.profitMargin - a.profitMargin).slice(0, 10);
};

export const getRevenueProfitChartData = (data: CustomersTabDataResponse[] | BranchLocationsTabDataResponse[] | QuoteOwnersTabDataResponse[] | ManufacturersTabDataResponse[], tabName: string) => {
    let chartData = [];
    switch (tabName) {
        case TAB_NAMES.QUOTE_OWNERS:
            chartData = data.map((userObj) => {
                const { user, totalExtSellPrice, services, freight, totalExtDiscountedCost } = userObj as QuoteOwnersTabDataResponse;
                return { name: user, revenue: convertIntoMillion(totalExtSellPrice + services + freight), margin: convertIntoMillion(totalExtSellPrice - totalExtDiscountedCost) };
            });
            break;
        case TAB_NAMES.CUSTOMER:
            chartData = data.map((customerObj) => {
                const { customer, totalExtSellPrice, services, freight, totalExtDiscountedCost } = customerObj as CustomersTabDataResponse;
                return { name: customer, revenue: convertIntoMillion(totalExtSellPrice + services + freight), margin: convertIntoMillion(totalExtSellPrice - totalExtDiscountedCost) };
            });
            break;
        case TAB_NAMES.BRANCH_LOCATIONS:
            chartData = data.map((locationObj) => {
                const { location, totalExtSellPrice, services, freight, totalExtDiscountedCost } = locationObj as BranchLocationsTabDataResponse;
                return { name: location, revenue: convertIntoMillion(totalExtSellPrice + services + freight), margin: convertIntoMillion(totalExtSellPrice - totalExtDiscountedCost) };
            });
            break;
        default:
            chartData = data.map((locationObj) => {
                const { manufacturer, totalExtSellPrice, totalExtDiscountedCost } = locationObj as ManufacturersTabDataResponse;
                return { name: manufacturer, revenue: convertIntoMillion(totalExtSellPrice), margin: convertIntoMillion(totalExtSellPrice - totalExtDiscountedCost) };
            });
    }
    // Sort by revenue and then by margin in ascending order
    return chartData
        .sort((a, b) => {
            if (b.revenue === a.revenue) {
                return b.margin - a.margin;
            }
            return b.revenue - a.revenue;
        })
        .slice(0, 10);
};

export const getAverage = (data: number[]): number => {
    const average = data.reduce((total: number, next: number) => total + next, 0) / data.length;
    return Number(average.toFixed(2));
};

export const percentageFormatter = (value: string) => `${value}%`;

export const millionFormmater = (value: string) => `$${value}M`;

export const convertIntoMillion = (value: number) => Number((convertToDollar(value) / 1000000).toFixed(4));

export const nbspFormatter = (value: string) => {
    return value.length > 10 ? `${value.replace(/ /g, '\u00A0').substring(0, 10)}...` : value.replace(/ /g, '\u00A0');
};

export const tableDataFormatter = (data: CustomersTabDataResponse[] | BranchLocationsTabDataResponse[] | QuoteOwnersTabDataResponse[] | ManufacturersTabDataResponse[], tabName: TAB_NAMES) => {
    switch (tabName) {
        case TAB_NAMES.MANUFACTURER:
            return data.map((mfgObj) => {
                const { totalExtSellPrice, totalExtDiscountedCost } = mfgObj as ManufacturersTabDataResponse;
                const revenue = totalExtSellPrice;
                const margin = totalExtSellPrice - totalExtDiscountedCost;
                const profitMargin = totalExtSellPrice === 0 ? 0 : (margin / revenue) * 100;
                return { ...mfgObj, revenue, margin, profitMargin: profitMargin.toFixed(2), id: uuidV4() };
            });
        case TAB_NAMES.QUOTE_OWNERS:
            return data.map((item) => {
                const { totalExtSellPrice, services, freight, totalExtDiscountedCost } = item as QuoteOwnersTabDataResponse;
                const revenue = totalExtSellPrice + services + freight;
                const margin = totalExtSellPrice - totalExtDiscountedCost;
                const profitMargin = revenue === 0 ? 0 : (margin / revenue) * 100;
                return { ...item, revenue, margin, profitMargin: profitMargin.toFixed(2), id: uuidV4() };
            });
        default:
            return data.map((item) => {
                const { totalExtSellPrice, services, freight, totalExtDiscountedCost, submittals, oMs } = item as BranchLocationsTabDataResponse | CustomersTabDataResponse;
                const submittalsAndOMs = submittals + oMs;
                const revenue = totalExtSellPrice + services + freight;
                const margin = totalExtSellPrice - totalExtDiscountedCost;
                const profitMargin = revenue === 0 ? 0 : (margin / revenue) * 100;
                return { ...item, submittalsAndOMs, revenue, margin, profitMargin: profitMargin.toFixed(2), id: uuidV4() };
            });
    }
};

export const costInDollarsFormatterForTable = (value: number) => {
    const dollarCost = convertToDollar(value);
    const formattedNumber = dollarCost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    });

    if (value === 0) {
        return '$0';
    }

    return value ? `${formattedNumber}` : '-';
};

export const numberAndQuantityFormatterForTable = (value: number) => {
    const formattedNumber = value.toLocaleString('en-US', {
        maximumFractionDigits: 2
    });

    if (value === 0) {
        return '0';
    }

    return value ? `${formattedNumber}` : '-';
};

export const percentageFormatterForTable = (value: number | null | undefined | string) => {
    if (value === null || value === undefined) {
        return '-';
    }
    return Number(value).toFixed(2) + '%';
};

export const getTableHeight = (data: any, containerHeight?: number) => {
    const height = 500;
    if (!data.length) return 160;
    if (data.length < 10) return data.length * TABLE_ROW_HEIGHT + 102;
    return containerHeight && containerHeight - 470 > height ? containerHeight - 470 : height;
};

export const getFiltersForApiParams = (filters: FilterType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { searchText, ...rest } = filters;
    return { ...rest };
};

type TabData = CustomersTabDataResponse | BranchLocationsTabDataResponse | QuoteOwnersTabDataResponse | ManufacturersTabDataResponse;

export const getFilteredTabData = (data: TabData[], searchText: string, key: string) => {
    if (!data.length) return [];
    return data.filter((item: TabData) =>
        String(item?.[key as keyof TabData])
            ?.toLowerCase()
            .includes((searchText || '')?.trim()?.toLowerCase())
    );
};

export const dateFormatterForExportedTable = () => {
    const currentDate = new Date();

    const month: number = currentDate.getMonth() + 1; // Months are zero-based
    const day: number = currentDate.getDate();
    const year: number = currentDate.getFullYear();

    const formattedMonth: string = month < 10 ? '0' + month : String(month);
    const formattedDay: string = day < 10 ? '0' + day : String(day);

    const formattedDate: string = `${formattedMonth}.${formattedDay}.${year}`;

    return formattedDate;
};
