import { rest } from 'msw';
import { manufacturersFreightDetails } from './freightMockData';

export const frieghtMockHandlers = [
    rest.get('*/bom/:bomId/pricing/freight', (_req, res, ctx) => {
        return res(ctx.delay(200), ctx.json(manufacturersFreightDetails));
    }),
    rest.put('*/bom/:bomId/pricing/freight/', (_req, res, ctx) => {
        return res(ctx.status(400), ctx.delay(200), ctx.json(manufacturersFreightDetails));
    })
];
