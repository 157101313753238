import { Box, H5, Button } from '@parspec/pixel';

import { IProfileState, IErrorStateType } from './MyProfile.types';
import { initialErrorState } from './utils/utils';

interface IHeaderProps {
    resetData: IProfileState;
    setProfileState: React.Dispatch<React.SetStateAction<IProfileState>>;
    onSubmitHandler: (event: React.MouseEvent<HTMLElement>) => void;
    editUserDetailsLoading: boolean;
    setErrorState: React.Dispatch<React.SetStateAction<IErrorStateType>>;
}

export const Header = (props: IHeaderProps) => {
    const { resetData, setProfileState, onSubmitHandler, editUserDetailsLoading, setErrorState } = props;

    const resetDataHandler = () => {
        setProfileState(() => resetData);
        setErrorState(() => initialErrorState);
    };

    const submitHandler = async (e: React.MouseEvent<HTMLElement>) => {
        onSubmitHandler(e);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <H5>Account Details</H5>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box ml={2}>
                    <Button color="secondary" variant="outlined" sx={{ margin: '50px' }} onClick={resetDataHandler}>
                        Cancel
                    </Button>
                </Box>
                <Box ml={2}>
                    <Button onClick={submitHandler} isLoading={editUserDetailsLoading}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
