import { BodyXS, Box } from '@parspec/pixel';

export const CustomToolTipForTable = (props: any) => {
    return (
        <Box width="258px" bgcolor="black" padding="8px 10px 8px 10px" borderRadius="2px">
            <BodyXS color="white" textAlign="center" limit={false}>
                {props.value}
            </BodyXS>
        </Box>
    );
};
