import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ColumnDirective } from '@syncfusion/ej2-react-treegrid';

import { BodySmall, Box, ModalHeader, Modal, Table, Button, Tooltip, ErrorIcon } from '@parspec/pixel';

import { useReuseDatasheetMutation } from '../queries';
import { ReuseDialogType, ReuseDialogTypeLabel } from './utils';
import { getManufacturer, getModelNumber } from './commonTemplates';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';

type ReuseDatasheetDialogProps = {
    open: boolean;
    handleDialog: () => void;
    confirmAction: () => void;
    tableData: any;
    reuseType: ReuseDialogType;
    selectedRowIds: Array<number | string>;
};
const getTableProps = (args: any) => {
    return {
        allowFiltering: false,
        allowExports: false,
        allowEditing: true,
        allowRowDragAndDrop: false,
        allowPaging: false,
        allowResizing: false,
        selectionSettings: { type: 'Single' },
        ...args
    };
};
const ReuseDatasheetDialog = (props: ReuseDatasheetDialogProps) => {
    const { open, handleDialog, confirmAction, tableData, reuseType, selectedRowIds } = props;

    const [selectedRowForPayload, setSelectedRowForPayload] = useState<any[]>([]);
    let needConfirmationDialog = false;
    const { bomId } = useParams();
    const [reuseTypeClicked, setReuseTypeClicked] = useState<string>('');
    const { isLoading, mutateAsync: confirmReuse } = useReuseDatasheetMutation();
    const { trackIntercomEvent } = useTrackIntercomEvent();
    const documentType = reuseType;
    const documentAnnotatedStatus = ['CONFIRMED', 'IN_PROGRESS'];
    const updatedTableData = tableData.filter((item: any) => {
        if (selectedRowIds.includes(item.id)) {
            if (item[`selected_${documentType}`]?.document_selection_status === 'CONFIRMED') {
                needConfirmationDialog = true;
            }
            return false;
        }
        item.is_hidden = false;
        return item[`selected_${documentType}`]?.document_selection_status === 'CONFIRMED';
    });

    const confirmReuseDatasheets = async (reuseTypeForPayload: string) => {
        let selected_document;
        if (documentType === 'datasheet') {
            selected_document = selectedRowForPayload[0].selected_datasheet_id;
        } else {
            selected_document = selectedRowForPayload[0][`selected_${documentType}`].id;
        }
        const payload = {
            bod_id: selectedRowIds,
            selected_document_id: selected_document,
            data: reuseTypeForPayload
        };
        try {
            await confirmReuse({ bomId: bomId!, payload, documentType });
            confirmAction();
        } catch (e) {
            console.log('e', e);
        }
    };

    const getDatasheetContent = useCallback((props: any) => {
        const { all_pages_hidden } = props;
        return (
            <Box display="flex" alignItems="center" justifyContent="center" ml={1}>
                <Box>
                    <Button color={all_pages_hidden ? 'warning' : 'success'} startIcon={all_pages_hidden && <ErrorIcon />} size="small">
                        {all_pages_hidden ? 'No Pages' : 'Selected'}
                    </Button>
                </Box>
            </Box>
        );
    }, []);

    const getAnnotateContent = useCallback((props: any) => {
        if (props[`selected_${documentType}`]?.annotations_status === 'IN_PROGRESS') {
            return (
                <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
                    <Button color="tertiary" size="small">
                        In Progress
                    </Button>
                </Box>
            );
        } else if (props[`selected_${documentType}`]?.annotations_status === 'CONFIRMED') {
            return (
                <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
                    <Button color="success" size="small">
                        Confirmed
                    </Button>
                </Box>
            );
        }
        return <></>;
    }, []);
    const handleReusedEvent = () => trackIntercomEvent('reuse_datasheet');
    const onReuseDocument = () => {
        setReuseTypeClicked('document');
        confirmReuseDatasheets('document');
        handleReusedEvent();
    };
    const onReuseAnnotation = () => {
        setReuseTypeClicked('xfdf');
        confirmReuseDatasheets('xfdf');
        handleReusedEvent();
    };
    const subTitle = <Box>Choose an existing product to reuse the documentation from. </Box>;
    const isButtonDisabled = () => {
        const row = selectedRowForPayload[0];
        return !documentAnnotatedStatus.includes(row[`selected_${documentType}`]?.annotations_status);
    };
    const modalFooter = (
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <Box>
                {needConfirmationDialog && (
                    <BodySmall
                        fontWeight={700}
                        width={'529px'}
                        padding={'8px'}
                        sx={{
                            color: 'warning.main',
                            div: {
                                WebkitLineClamp: 'unset !important'
                            }
                        }}
                    >
                        One or more products from your initial selection have an associated <br />
                        document.
                        <span style={{ fontWeight: '500' }}>These existing documents and annotations will be overwritten.</span>
                    </BodySmall>
                )}
            </Box>
            <Box display={'flex'} gap={2}>
                <Button color="secondary" variant="outlined" onClick={handleDialog}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={!selectedRowForPayload.length || (isLoading && reuseTypeClicked === 'xfdf')}
                    onClick={onReuseDocument}
                    isLoading={isLoading && reuseTypeClicked === 'document'}
                >
                    Reuse Document
                </Button>
                <Tooltip title={selectedRowForPayload.length && isButtonDisabled() ? 'No annotations confirmed for this product' : ''} placement={'top'}>
                    <span>
                        <Button
                            variant="contained"
                            disabled={!selectedRowForPayload.length || isButtonDisabled() || (isLoading && reuseTypeClicked === 'document')}
                            onClick={onReuseAnnotation}
                            isLoading={isLoading && reuseTypeClicked === 'xfdf'}
                        >
                            Reuse Document & Annotations
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
    const onRowSelection = (args: any) => {
        setSelectedRowForPayload(() => [...args]);
    };
    return (
        <>
            <Modal open={open} header={<ModalHeader title={`Reuse ${ReuseDialogTypeLabel[reuseType]}`} onClose={handleDialog} children={subTitle} />} footer={modalFooter}>
                <Box height={'460px'}>
                    <Table {...getTableProps({ onRowSelection })} data={updatedTableData}>
                        <ColumnDirective allowEditing={false} field="category" headerText="Type" width="200" />
                        <ColumnDirective allowEditing={false} field="manufacturer" template={getManufacturer} headerText="Manufacturer" minWidth="200" width="200" />
                        <ColumnDirective allowEditing={false} field="model_number" template={getModelNumber} headerText="Model Number" minWidth="360" width="360" />
                        <ColumnDirective headerText="Datasheet" minWidth="140" width="140" template={getDatasheetContent} textAlign="Center" headerTextAlign="Center" />
                        <ColumnDirective headerText="Annotation" minWidth="123" width="123" template={getAnnotateContent} textAlign="Center" headerTextAlign="Center" />
                    </Table>
                </Box>
            </Modal>
        </>
    );
};

export default ReuseDatasheetDialog;
