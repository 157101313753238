import { BodyXS, Box, Tooltip } from '@parspec/pixel';

import { useUserEventCount } from '../../shared/UserActivityTracking/TrackActivityCount';

const PRICING_LEADTIME_TAB_DISABLED_TOOLTIP = 'The quoting module is not activated for your company. Reach out to us in the chat to learn more.';

export const PricingLeadTimeTabDisabledLabel = () => {
    const { pushUserEventCount } = useUserEventCount();

    const handleTooltipShown = () => {
        pushUserEventCount({ eventType: 'hover_diabled_p&lt_tab', needCummulation: false });
    };
    return (
        <Box>
            <Tooltip placement="bottom" title={PRICING_LEADTIME_TAB_DISABLED_TOOLTIP} onOpen={handleTooltipShown}>
                <Box display="flex" alignItems="center" gap="4px">
                    <BodyXS color="neutral.main" fontWeight="500">
                        Pricing & Lead Time
                    </BodyXS>
                </Box>
            </Tooltip>
        </Box>
    );
};
