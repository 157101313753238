import { Select, SelectChangeEvent } from '@parspec/pixel';

const options = [
    {
        label: 'Light',
        value: 'light'
    },
    {
        label: 'Regular',
        value: 'regular'
    },
    {
        label: 'Bold',
        value: 'bold'
    }
];

export interface ITextFontType {
    selectFieldValue: number | string;
    onSelect: (e: SelectChangeEvent<unknown>) => void;
}

function TextFontType({ selectFieldValue, onSelect }: ITextFontType) {
    return <Select label="" onChange={onSelect} optionLabelKeyname={'label'} optionValueKeyname={'value'} options={options} value={selectFieldValue} />;
}

export default TextFontType;
