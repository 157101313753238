import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { bulkUpload, createCompany, deleteCompany, editCompany, endpoints, getCompanyDetailsData, getCompanyTableData } from './api';
import { queryClient } from 'src/app/queryClient';
import { CompanyDetailResponse } from './apiTypes';
import { invalidateGetContactsQuery } from '../../Contacts/queries';
import { invalidateContactDropdownQuery, invalidateCompanyDropdownQuery } from 'src/features/shared/queries';

//Query keys
const getCompanyQueryKey = (url: string) => ['get-companydata', url];
const getCompanyDetailQuery = (id: number) => [endpoints.getCompanyDetails(id)];

export const invalidateGetCompaniesQuery = () => {
    queryClient.invalidateQueries(['get-companydata']);
};

export const invalidateCompanyDetailQuery = (companyId: number) => {
    queryClient.invalidateQueries(getCompanyDetailQuery(companyId));
};

//Queries
export const useGetCompanyQuery = (url: string, options?: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) => useQuery(getCompanyQueryKey(url), () => getCompanyTableData(url), options);

export const useGetCompanyDetails = (id: number, options?: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) =>
    useQuery(getCompanyDetailQuery(id), () => getCompanyDetailsData(id), { staleTime: 0, ...options } as Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>);

//Mutations
export const useCreateCompanyMutation = () =>
    useMutation(createCompany, {
        onSuccess: () => {
            invalidateGetCompaniesQuery();
            invalidateCompanyDropdownQuery();
        }
    });

export const useEditCompanyMutation = () =>
    useMutation(editCompany, {
        onSuccess: (_, request) => {
            const { id } = request;
            invalidateCompanyDetailQuery(id);
            invalidateGetCompaniesQuery();
            invalidateGetContactsQuery();
            invalidateCompanyDropdownQuery();
        }
    });

export const useBulkUploadMutation = () =>
    useMutation(bulkUpload, {
        onSuccess: (response) => {
            if (response.status === 201) {
                invalidateGetCompaniesQuery();
                invalidateGetContactsQuery();
                invalidateCompanyDropdownQuery();
                invalidateContactDropdownQuery();
            }
        }
    });

export const useDeleteCompanyMutation = () =>
    useMutation(deleteCompany, {
        onSuccess: (_, request) => {
            queryClient.setQueriesData(['get-companydata'], (oldData: any) => {
                return {
                    ...oldData,
                    data: { ...oldData.data, results: oldData.data.results.filter((item: CompanyDetailResponse) => !request.includes(item.id)) }
                };
            });
            invalidateGetContactsQuery();
            invalidateCompanyDropdownQuery();
            invalidateContactDropdownQuery();
        }
    });
