import { BodyBig, BodyMedium, BodySmall, Box, Grid, Switch, Select, SelectChangeEvent } from '@parspec/pixel';

import { optionsForLogo } from '../../../../BOM/FinalDocuments/Compile/shared/utils';

import { SubmittalTemplateInterface, OMTemplateInterface } from '../../queries/apiTypes';
import { OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY } from 'src/features/BOM/FinalDocuments/Compile/shared/utils/constants';

interface HeaderFooterProps {
    changeHandler: (e: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => void;
    templateState: SubmittalTemplateInterface | OMTemplateInterface;
    templateName: 'SUBMITTAL' | 'OM';
}

const HEADER_INFO_COLUMN_OPTIONS = [
    { label: 'Job Name', value: 'job_name' },
    { label: 'Notes', value: 'notes' },
    { label: 'Document Type', value: 'document_type' },
    { label: 'Type(s)', value: 'category' },
    { label: 'Quantity', value: 'qty' }
];

const FOOTER_INFO_COLUMN_OPTIONS = [
    { label: 'Left Footer', value: 'left_footer' },
    { label: 'Right Footer', value: 'right_footer' },
    { label: 'Date', value: 'date' },
    { label: 'Page Number', value: 'page_number' },
    { label: 'Hyperlink to table of contents(index)', value: 'hyperlinks' }
];

export const HeaderFooter = (props: HeaderFooterProps) => {
    const { changeHandler, templateState, templateName } = props;

    const handleJobNameGenerationRule = (event: SelectChangeEvent<unknown>) => {
        changeHandler(event, 'header_footer_presets', 'pre_fill_job_name');
    };
    const handleHeaderLogo = (event: SelectChangeEvent<unknown>) => {
        changeHandler(event, 'header_footer_presets', 'pre_fill_header_logo');
    };

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState.header_footer_presets.header_footer_section}
                    onChange={(e) => changeHandler(e, 'header_footer_presets')}
                    name="header_footer_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Header & Footer
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose the fields you want to appear by default in your headers and footers.
            </BodySmall>
            {templateName === 'SUBMITTAL' && (
                <BodySmall limit={false} color="text.secondary">
                    You can edit the fields during the submittal creation process.
                </BodySmall>
            )}
            {templateName === 'OM' && (
                <BodySmall limit={false} color="text.secondary">
                    You can edit the fields during the O&M creation process.
                </BodySmall>
            )}

            <Box mt={6}>
                <Box>
                    <Box display="flex " position="relative" height={'100%'}>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Header Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {HEADER_INFO_COLUMN_OPTIONS.map((option) => {
                                        const optionValue = option.value;
                                        let labelValue = '';

                                        if (optionValue === 'notes' && templateName === 'OM') {
                                            labelValue = `${option.label} (only for datasheets if included)`;
                                        } else if (optionValue === 'qty' && templateName === 'OM') {
                                            labelValue = `${option.label} (only for repeated documents)`;
                                        } else {
                                            labelValue = option.label;
                                        }

                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.header_footer_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, 'header_footer_presets')}
                                                    name={option.value}
                                                    label={<BodySmall>{labelValue}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Footer Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {FOOTER_INFO_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.header_footer_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, 'header_footer_presets')}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <BodySmall color="secondary.light" fontWeight={500} mt={4} mb={2}>
                            Job Name Pre-fill
                        </BodySmall>

                        <BodySmall color="text.secondary" fontWeight={400} mb={4}>
                            Determine the values that we’ll use to pre-fill your Job Name{' '}
                        </BodySmall>
                        <Box mb={4} width={250}>
                            <Select
                                label="Job Name"
                                options={OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY}
                                optionLabelKeyname="name"
                                optionValueKeyname="id"
                                onChange={handleJobNameGenerationRule}
                                value={templateState.header_footer_presets.pre_fill_job_name}
                            />
                        </Box>
                        <Box width={250}>
                            <Select
                                label="Header Logo"
                                options={optionsForLogo}
                                optionLabelKeyname="name"
                                optionValueKeyname="id"
                                onChange={handleHeaderLogo}
                                value={templateState.header_footer_presets.pre_fill_header_logo}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
