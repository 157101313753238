import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useGetBodDetailQuery, useUpdateBomDataMutation } from '../../BOM/queries';
import { BodySmall, Box, EditIcon, IconButton, Skeleton, Snackbar, TextField } from '@parspec/pixel';

import { updateMsg } from '../constants';

const ProductDescription = () => {
    const [editDesc, setEditDesc] = useState(false);
    const [description, setDescription] = useState('');

    const [snackbarMessage, setSnackbarMessage] = useState('');

    const textfieldRef = useRef<HTMLDivElement>(null);

    const { bomId, productId } = useParams();

    const { data: bodData, isLoading } = useGetBodDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });

    const { mutateAsync: updateData, isLoading: updateMetadataLoading } = useUpdateBomDataMutation();

    useEffect(() => {
        if (bodData?.data?.description) {
            setDescription(bodData?.data.description);
        }
    }, [bodData?.data]);

    const handleEditDescription = async () => {
        if (description.trim() !== bodData?.data.description) {
            await updateData({
                bomId: bomId!,
                input: { id: Number(productId), description: description }
            });
            setEditDesc(false);
            setSnackbarMessage(updateMsg);
        } else setEditDesc(false);
    };

    const onPressEnter = (e: any) => {
        if (e.key === 'Enter') {
            handleEditDescription();
        }
    };

    const handleOutsideClick = (e: globalThis.MouseEvent) => {
        if (editDesc) {
            if (!textfieldRef.current) return;
            if (!textfieldRef.current.contains(e.target as Node)) {
                handleEditDescription();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [editDesc, description]);

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {isLoading && <Skeleton width="100%" height="30px" />}
            {!isLoading && (
                <Box display="flex" alignItems="center">
                    {!editDesc ? (
                        <Box maxWidth="100%">
                            <BodySmall limit lines={1} color={!description ? 'text.secondary' : ''}>
                                {description || 'Enter Description here'}
                            </BodySmall>
                        </Box>
                    ) : (
                        <TextField
                            ref={textfieldRef}
                            label="Description"
                            style={{ width: '100%' }}
                            size="small"
                            value={description}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            disabled={updateMetadataLoading}
                            onKeyDown={onPressEnter}
                        />
                    )}
                    <Box ml={5}>
                        <IconButton
                            onClick={() => {
                                setEditDesc(true);
                            }}
                            size="small"
                        >
                            <EditIcon color={editDesc ? 'primary' : 'secondary'} fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ProductDescription;
