import { rest } from 'msw';
import { user } from './user';
import { users } from './users';
import { bomScheduleResponse, bomAggregatorResponse, bomManualResponse } from './bom';
import { bomDetails } from './bomDetails';
import { editBomDetailsResponse } from './editBomResponse';

export const createBOMHandler = [
    rest.get('*/api/user_info/:userId/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(user));
    }),
    rest.get('*/submittal/submittal_detail/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(users));
    }),
    rest.get('*/bom/:bomId/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(bomDetails));
    }),
    rest.post('*/bom/:bomId/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(editBomDetailsResponse));
    }),
    rest.patch('*/bom', (req: any, res, ctx) => {
        console.log('Request=====>', req);
        if (req.body.type === 'schedule') {
            return res(ctx.delay(2000), ctx.json(bomScheduleResponse));
        } else if (req.body.type === 'submittal_package') {
            return res(ctx.delay(2000), ctx.json(bomAggregatorResponse));
        }
        return res(ctx.delay(2000), ctx.json(bomManualResponse));
    })
];
