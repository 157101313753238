import { ColDef } from 'ag-grid-community';

import { isEqual } from 'lodash-es';

import { getPercent, getPercentColor } from '../shared/utils';
import { IBodsComponent, OmDataResponse, BodResponse } from '../queries/apiTypes';

export const getStats = (data: any) => {
    if (data?.data) {
        const { selected_instalation_guide, selected_installation_drawing, selected_warranty, selected_other_documents, hidden_product, total } = data.data;
        const totalValid = total - hidden_product;
        return [
            {
                title: 'Selected Install Guides',
                count: `${selected_instalation_guide} of ${totalValid}`,
                helperText: `${getPercent(selected_instalation_guide, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(selected_instalation_guide, totalValid))
            },
            {
                title: 'Selected drawings',
                count: `${selected_installation_drawing} of ${totalValid}`,
                helperText: `${getPercent(selected_installation_drawing, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(selected_installation_drawing, totalValid))
            },
            {
                title: 'Selected Warranties',
                count: `${selected_warranty} of ${totalValid}`,
                helperText: `${getPercent(selected_warranty, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(selected_warranty, totalValid))
            },
            {
                title: 'Selected Additional Docs.',
                count: `${selected_other_documents} of ${totalValid}`,
                helperText: `${getPercent(selected_other_documents, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(selected_other_documents, totalValid))
            },
            {
                title: 'Products Hidden',
                count: hidden_product
            }
        ];
    }
    return [];
};

export const toolBarOptions = ['delete', 'search', 'hide', 'selectedItems', 'duplicate', 'move', 'createKit', 'highlight'];

export const getOmLoadTime = (selectedItems: any[]) => {
    const loadTime = 1000; //time for one bod in milliseconds;
    const validBods = selectedItems.filter(
        (item: any) => item?.selected_warranty?.document_selection_status !== 'CONFIRMED' || item?.selected_installation_guide?.document_selection_status !== 'CONFIRMED'
    );
    return validBods.length * loadTime + 270; //270 milliseconds is the static time for api calling
};

export const defaultColDef: ColDef = {
    flex: 1,
    sortable: false,
    menuTabs: [],
    resizable: true,
    suppressFillHandle: true
};

export const checkIfResfreshParentTable = <T extends IBodsComponent & OmDataResponse>(tableData: T[], localTableData: T[] | null) => {
    let result = false;
    if (Array.isArray(localTableData) && tableData?.length === localTableData.length) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].components?.length !== localTableData[i].components?.length) {
                result = true;
                break;
            }

            tableData[i].components?.forEach((component) => {
                const localComponent = localTableData[i].components?.find((item) => item.id === component.id);
                if (localComponent && (localComponent.is_hidden !== component.is_hidden || localComponent.order !== component.order)) {
                    result = true;
                }
            });
        }
    }
    return result;
};

export const getIsTableDataUpdated = <T extends BodResponse & OmDataResponse>(apiTableData: T[], localTableData: T[]) => {
    return apiTableData.some((productData, index) => {
        return (
            productData?.is_hidden !== localTableData?.[index]?.is_hidden ||
            productData?.is_highlighted !== localTableData?.[index]?.is_highlighted ||
            productData?.manufacturer !== localTableData?.[index]?.manufacturer ||
            productData?.qty !== localTableData?.[index]?.qty ||
            productData?.category !== localTableData?.[index]?.category ||
            productData?.model_number !== localTableData?.[index]?.model_number ||
            !isEqual(productData.selected_drawing, localTableData[index].selected_drawing) ||
            !isEqual(productData.selected_installation_guide, localTableData[index].selected_installation_guide) ||
            !isEqual(productData.selected_other_document, localTableData[index].selected_other_document) ||
            !isEqual(productData.selected_warranty, localTableData[index].selected_warranty)
        );
    });
};
