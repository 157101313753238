import { useEffect } from 'react';

import { H5, Box, TextField, BodyMedium, Select, Skeleton, BodyXS, SelectChangeEvent } from '@parspec/pixel';

import { useBillingInfoQuery } from '../queries';

import { BillingData, ErrorState, SelectFieldOptions } from '../PlanAndPayment.types';

import { trimEmptySpaces, countryOptions, USA, CANADA, modifiedStateList } from '../utils/utils';
import { validateEmail } from '../../UserManagement/utils/utils';

import { USA_STATES, CANADA_STATES } from '../../../shared/constants';

interface BillingInfoProps {
    billingData: BillingData;
    setBillingData: React.Dispatch<React.SetStateAction<BillingData>>;
    errorState: ErrorState;
    setErrorState: React.Dispatch<React.SetStateAction<ErrorState>>;
    unmodifiedBillingData: BillingData;
    setUnmodifiedBillingData: React.Dispatch<React.SetStateAction<BillingData>>;
    onSubmitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
    formRef: any;
    stateListOptions: SelectFieldOptions;
    setStateListOptions: React.Dispatch<React.SetStateAction<SelectFieldOptions>>;
}

export const BillingInfo = (props: BillingInfoProps) => {
    const { billingData, setBillingData, errorState, setErrorState, unmodifiedBillingData, setUnmodifiedBillingData, onSubmitHandler, formRef, stateListOptions, setStateListOptions } = props;

    const { data: billingInfoData, isLoading: billingInfoLoading } = useBillingInfoQuery();

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const requiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value as string;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName)) {
            return;
        }

        if (fieldName === 'country') {
            setBillingData(() => ({
                ...billingData,
                country: fieldValue,
                state: ''
            }));
            setErrorState(() => ({
                ...errorState,
                country: { ...errorState.country, isError: false, title: '' },
                state: { ...errorState.state, isError: true, title: 'Required' }
            }));

            let stateOptions: { label: string; value: string }[] = [];

            if (fieldValue === USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (fieldValue === CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            return setStateListOptions(stateOptions);
        }

        if (errorState[fieldName].isError) {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
            });
        }
        if (fieldValue === '') {
            setErrorState({
                ...errorState,
                [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
            });
        }

        if (fieldName === 'zipcode') {
            if (fieldValue.trim().length > 10) {
                setErrorState({
                    ...errorState,
                    zipcode: { ...errorState.zipcode, isError: true, title: 'Max length allowed is 10' }
                });
            }
            return setBillingData(() => ({ ...billingData, zipcode: fieldValue }));
        }

        setBillingData(() => ({ ...billingData, [fieldName]: fieldValue }));
    };

    const unRequiredFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        setBillingData(() => ({ ...billingData, [fieldName]: fieldValue }));
    };

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(billingData, fieldName)) {
            return;
        }

        if (billingData[fieldName].length > 0 && eventType === 'blur') {
            if (fieldName === 'email') {
                if (!validateEmail(billingData.email)) {
                    return setErrorState({
                        ...errorState,
                        email: { ...errorState.email, isError: true, title: 'Please provide a valid email address' }
                    });
                }
            }

            if (fieldName === 'address2') {
                const trimmedValue = trimEmptySpaces(billingData[fieldName]);
                return setBillingData(() => ({ ...billingData, [fieldName]: trimmedValue }));
            }

            if (billingData[fieldName].trim().length === 0) {
                return setErrorState({ ...errorState, [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' } });
            }

            const trimmedValue = trimEmptySpaces(billingData[fieldName]);
            setBillingData(() => ({ ...billingData, [fieldName]: trimmedValue }));

            if (fieldName === 'zipcode') {
                if (trimmedValue.length <= 10) {
                    return setErrorState({ ...errorState, zipcode: { ...errorState.zipcode, isError: false, title: '' } });
                }
            }
        }
    };

    useEffect(() => {
        if (!billingInfoData?.data) {
            return;
        }

        setBillingData(() => ({
            ...billingData,
            companyName: billingInfoData.data.company_name,
            address1: billingInfoData.data.address1,
            address2: billingInfoData.data.address2,
            city: billingInfoData.data.city,
            state: billingInfoData.data.state,
            zipcode: billingInfoData.data.zipcode,
            country: billingInfoData.data.country,
            email: billingInfoData.data.email
        }));

        setUnmodifiedBillingData(() => ({
            ...unmodifiedBillingData,
            companyName: billingInfoData.data.company_name,
            address1: billingInfoData.data.address1,
            address2: billingInfoData.data.address2,
            city: billingInfoData.data.city,
            state: billingInfoData.data.state,
            zipcode: billingInfoData.data.zipcode,
            country: billingInfoData.data.country,
            email: billingInfoData.data.email
        }));

        if (billingInfoData.data.country.toUpperCase() === USA) {
            setStateListOptions(modifiedStateList(USA_STATES));
        } else if (billingInfoData.data.country === CANADA) {
            setStateListOptions(modifiedStateList(CANADA_STATES));
        }
    }, [billingInfoData?.data]);

    return (
        <Box mt={12} mb={10}>
            <H5 mb={8}>Billing Information</H5>
            <form onSubmit={onSubmitHandler} ref={formRef}>
                <Box>
                    <Box mb={6} width={'50%'} mt={8}>
                        {!billingInfoLoading ? (
                            <TextField
                                required
                                label="Billing Email"
                                name="email"
                                value={billingData.email}
                                onChange={requiredFieldChangeHandler}
                                onKeyDown={keydownHandler}
                                onBlur={onBlurHandler}
                                error={errorState.email.isError}
                                helperText={errorState.email.title}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                </Box>
                <Box>
                    <BodyMedium limit={false} mb={6}>
                        Billing Address
                    </BodyMedium>
                    <Box mb={6} width={'50%'}>
                        {!billingInfoLoading ? (
                            <TextField
                                required
                                label="Address Line 1"
                                name="address1"
                                value={billingData.address1}
                                onChange={requiredFieldChangeHandler}
                                onKeyDown={keydownHandler}
                                onBlur={onBlurHandler}
                                error={errorState.address1.isError}
                                helperText={errorState.address1.title}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box mb={6} width={'50%'}>
                        {!billingInfoLoading ? (
                            <TextField label="Address Line 2" name="address2" value={billingData.address2} onChange={unRequiredFieldChangeHandler} onKeyDown={keydownHandler} onBlur={onBlurHandler} />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box mb={6} width={'50%'}>
                        {!billingInfoLoading ? (
                            <TextField
                                required
                                label="City"
                                name="city"
                                value={billingData.city}
                                onChange={requiredFieldChangeHandler}
                                onKeyDown={keydownHandler}
                                onBlur={onBlurHandler}
                                error={errorState.city.isError}
                                helperText={errorState.city.title}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box mb={6} width={'50%'}>
                        {!billingInfoLoading ? (
                            <>
                                <Select
                                    required
                                    label="Country *"
                                    name="country"
                                    error={errorState.country.isError}
                                    optionLabelKeyname="label"
                                    optionValueKeyname="value"
                                    options={countryOptions}
                                    value={billingData.country === 'usa' || billingData.country === 'Usa' ? billingData.country.toUpperCase() : billingData.country}
                                    onChange={requiredFieldChangeHandler}
                                />
                                {errorState.country.isError && (
                                    <Box my={2}>
                                        <BodyXS color={'error.main'}>{errorState.country.title}</BodyXS>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Skeleton variant="rectangular" height={36} />
                        )}
                    </Box>
                    <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'} width={'50%'}>
                        <Box width={'45%'}>
                            {!billingInfoLoading && stateListOptions.length > 0 ? (
                                <>
                                    <Select
                                        required
                                        label="State / Province *"
                                        name="state"
                                        error={errorState.state.isError}
                                        optionLabelKeyname="label"
                                        optionValueKeyname="value"
                                        options={stateListOptions}
                                        value={billingData.state}
                                        onChange={requiredFieldChangeHandler}
                                    />
                                    {errorState.state.isError && (
                                        <Box my={2}>
                                            <BodyXS color={'error.main'}>{errorState.state.title}</BodyXS>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Box>
                        <Box ml={4} width={'45%'}>
                            {!billingInfoLoading ? (
                                <TextField
                                    required
                                    label="Zip Code"
                                    name="zipcode"
                                    value={billingData.zipcode}
                                    onChange={requiredFieldChangeHandler}
                                    onKeyDown={keydownHandler}
                                    onBlur={onBlurHandler}
                                    error={errorState.zipcode.isError}
                                    helperText={errorState.zipcode.title}
                                />
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Box>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};
