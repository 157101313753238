import { useEffect, useRef, useState } from 'react';

import { BodySmall, Box, Modal, ModalFooter, ModalHeader, Tooltip, HelpIcon, Pagination } from '@parspec/pixel';

import VerifyManufacturer from './VerifyManufacturer';
import { useAddMfgToCompanyMutation, useEditManufacturersMutation, useParspecMfg } from './queries';
import { specialCharactersRegex, websiteValidationRegex } from 'src/features/shared/utils/utils';
import { ICompanyManufacturerType, ManufacturerAddResponse, ManufacturerUploadResponse, ParspecMfgDetailsResponse } from './queries/apiTypes';
interface EditManufacturersDialogProps {
    open: boolean;
    handleDialog: () => void;
    confirmAction: () => void;
    verifyData: ManufacturerUploadResponse[] | ParspecMfgDetailsResponse[];
    actionEdit: boolean;
    catalogPopup?: boolean;
}
interface IErrorField {
    mfgNameErr: boolean;
    websiteErr: boolean;
    abbrRegexErr: boolean;
}

const pageLimit = 10;

const EditManufacturers = (props: EditManufacturersDialogProps) => {
    const { open, handleDialog, confirmAction, verifyData, actionEdit, catalogPopup = false } = props;

    const { isLoading: isMfgListLoading } = useParspecMfg();
    const { mutateAsync: addManufacturers, isLoading: addLoading } = useAddMfgToCompanyMutation();
    const { mutateAsync: editManufacturers, isLoading: editLoading } = useEditManufacturersMutation();

    const finalPayload = useRef<{ [index: number]: ManufacturerUploadResponse }>({});
    const [dataToDisplay, setDataToDisplay] = useState<{ [index: number]: ManufacturerUploadResponse }>({});
    const [formError, setFormError] = useState<IErrorField>({
        mfgNameErr: false,
        websiteErr: false,
        abbrRegexErr: false
    });
    const abbrErr = useRef(false);
    const [currentPage, setPage] = useState(0);

    useEffect(() => {
        const verifyFormattedData = { ...verifyData } as ManufacturerUploadResponse[];
        setDataToDisplay(verifyFormattedData);
        finalPayload.current = verifyFormattedData;
    }, [verifyData]);

    const cardValuesUpdated = (data: ICompanyManufacturerType, index: number) => {
        const oldData = finalPayload.current[index];
        if (
            (formError.mfgNameErr || formError.websiteErr || formError.abbrRegexErr || abbrErr.current) &&
            (oldData.manufacturer_name !== data.manufacturer_name ||
                oldData.parspec_id !== data.parspec_id ||
                JSON.stringify(oldData.abbreviations) !== JSON.stringify(data.abbreviations) ||
                oldData.website !== data.website)
        ) {
            setFormError({
                mfgNameErr: false,
                websiteErr: false,
                abbrRegexErr: false
            });
            abbrErr.current = false;
        }
        finalPayload.current[index] = data;
    };

    const cardRowDeleted = (index: number) => {
        delete finalPayload.current[index];
        setDataToDisplay({ ...finalPayload.current });
        if (formError.mfgNameErr || formError.websiteErr || formError.abbrRegexErr || abbrErr.current) {
            setFormError({
                mfgNameErr: false,
                websiteErr: false,
                abbrRegexErr: false
            });
            abbrErr.current = false;
        }
    };

    const confirmEdit = async () => {
        if (!catalogPopup) {
            let localMfgErr = false,
                localWebErr = false,
                localAbbRegexErr = false;
            Object.values(finalPayload.current).forEach((card: ManufacturerUploadResponse) => {
                if (!card.manufacturer_name?.trim()) {
                    localMfgErr = true;
                }
                if (card.website && !websiteValidationRegex().test(card.website?.trim())) {
                    localWebErr = true;
                }
                if (card.abbreviations.some((abbr) => !specialCharactersRegex.test(abbr))) {
                    localAbbRegexErr = true;
                }
            });
            if (localMfgErr || localWebErr || localAbbRegexErr) {
                setFormError({
                    mfgNameErr: localMfgErr,
                    websiteErr: localWebErr,
                    abbrRegexErr: localAbbRegexErr
                });
                return;
            }

            let response;
            const payload = {
                abbreviation_merge_issue: true,
                data: Object.values(finalPayload.current)
            };
            if (actionEdit) {
                response = await editManufacturers(payload);
            } else {
                response = await addManufacturers(payload);
            }
            let conflict = false;
            const newVerifyData = response.data.map((card: ManufacturerAddResponse) => {
                if (Object.keys(card.conflict).length !== 0) {
                    conflict = true;
                }
                return card.data[0];
            });
            if (!conflict) {
                confirmAction();
            } else {
                finalPayload.current = { ...newVerifyData };
                abbrErr.current = true;
                setDataToDisplay(() => {
                    return newVerifyData.reduce((acc: any, element, index) => {
                        acc[index] = { ...element };
                        return acc;
                    }, {});
                });
            }
        } else {
            const manufacturers = Object.values(finalPayload.current);
            const payload = {
                abbreviation_merge_issue: false,
                data: manufacturers.map((mfg: ManufacturerUploadResponse) => {
                    return {
                        manufacturer_name: mfg.manufacturer_name?.trim() || mfg.parspec_id,
                        abbreviations: mfg.abbreviations || [],
                        parspec_id: mfg.parspec_id,
                        website: mfg.website,
                        manufacturer_id: mfg.manufacturer_id,
                        preferred_locations: [],
                        status: mfg.status,
                        erp_code: mfg.erp_code
                    };
                })
            };
            await addManufacturers(payload);
            confirmAction();
        }
    };

    const helperText = abbrErr.current ? (
        <BodySmall color={'error.main'}>One or more abbreviations listed are used for more than one manufacturer. Remove duplicates to continue.</BodySmall>
    ) : formError.mfgNameErr ? (
        <BodySmall color={'error.main'}>A manufacturer name is required for all entries listed.</BodySmall>
    ) : formError.websiteErr ? (
        <BodySmall color={'error.main'}>One or more websites entered are invalid</BodySmall>
    ) : formError.abbrRegexErr ? (
        <BodySmall color={'error.main'}>One or more abbreviations entered are invalid</BodySmall>
    ) : (
        ''
    );

    const totalPages = Math.ceil(Object.entries(dataToDisplay).length / pageLimit);

    const getPaginatedEntries = (entries: any) => {
        const offset = currentPage * pageLimit;
        return entries.slice(offset, offset + pageLimit);
    };

    return (
        <>
            <Modal
                open={open}
                header={<ModalHeader title={actionEdit ? 'Edit Manufacturer Information' : 'Verify Upload'} onClose={handleDialog} />}
                footer={<ModalFooter onAccept={confirmEdit} onReject={handleDialog} continueButtonLabel="Confirm" isLoading={isMfgListLoading || addLoading || editLoading} helperText={helperText} />}
            >
                <>
                    <Box display={'flex'} color={'text.secondary'}>
                        <BodySmall width={250} marginRight={4}>
                            Manufacturer Name
                            <Tooltip title="Manufacturer name used across your organization." placement="top">
                                <HelpIcon fontSize="small" sx={{ marginBottom: '3px', marginLeft: '6px', color: '#6B7280' }} />
                            </Tooltip>
                        </BodySmall>
                        <BodySmall width={240} marginRight={4}>
                            Manufacturer ERP Code
                            <Tooltip
                                title="Enter the manufacturer code used in your ERP for seamless tool compatibility. Manufacturer codes will be present on non-PDF quote exports. "
                                placement="top"
                            >
                                <HelpIcon fontSize="small" sx={{ marginBottom: '3px', marginLeft: '6px', color: '#6B7280' }} />
                            </Tooltip>
                        </BodySmall>
                        <BodySmall width={200} marginRight={4}>
                            Abbreviations
                            <Tooltip title="Add one or more abbreviations separated by commas." placement="top">
                                <HelpIcon fontSize="small" sx={{ marginBottom: '3px', marginLeft: '6px', color: '#6B7280' }} />
                            </Tooltip>
                        </BodySmall>
                        <BodySmall width={250} marginRight={4}>
                            Parspec ID
                            <Tooltip title="Select the Parspec ID matching your manufacturer. No match? Add a website to request support for this manufacturer." placement="top">
                                <HelpIcon fontSize="small" sx={{ marginBottom: '3px', marginLeft: '6px', color: '#6B7280' }} />
                            </Tooltip>
                        </BodySmall>
                        <BodySmall width={200} marginRight={4}>
                            Website
                            <Tooltip
                                title="Ensure the website corresponds to the intended manufacturer. If not, help us support the manufacturer by updating the website if incorrect or missing."
                                placement="top"
                            >
                                <HelpIcon fontSize="small" sx={{ marginBottom: '3px', marginLeft: '6px', color: '#6B7280' }} />
                            </Tooltip>
                        </BodySmall>
                        <BodySmall minWidth={105}>Support Status</BodySmall>
                    </Box>
                    <Box height={405} overflow={'auto'}>
                        {getPaginatedEntries(Object.entries(dataToDisplay)).map((el: any) => {
                            const [key, value] = el;
                            return (
                                <VerifyManufacturer
                                    catalogPopup={catalogPopup}
                                    key={key}
                                    card={value}
                                    updateValues={(data) => cardValuesUpdated(data, Number(key))}
                                    cardDeleted={() => cardRowDeleted(Number(key))}
                                    actionEdit={actionEdit}
                                />
                            );
                        })}
                    </Box>
                    {Object.entries(dataToDisplay).length > pageLimit && (
                        <Pagination
                            size="small"
                            page={currentPage > totalPages ? totalPages : currentPage + 1}
                            onChange={(_e, clickePage: number | null) => {
                                if(clickePage) setPage(clickePage - 1);
                            }}
                            color={'primary'}
                            count={totalPages}
                            boundaryCount={0}
                            siblingCount={1}
                            sx={{ paddingTop: 2 }}
                        />
                    )}
                </>
            </Modal>
        </>
    );
};

export default EditManufacturers;
