import { useState, useEffect } from 'react';

import { Grid, ColorResult, Tooltip, SelectChangeEvent } from '@parspec/pixel';

import MultilineTextWithFontAndColor from '../../shared/MultilineWithColorAndFont';
import { useQuoteContext } from '../../QuoteContext';
import { useGeneralTermsAndConditionQuery, useGeneralTermsAndConditionToggleMutation, useGeneralTermsAndConditionTextMutation } from '../../queries';
import CircularLoader from '../../../../../../shared/CircularLoader';
import { FieldNotes } from '../../queries/apiTypes';
import { NOTES_PLACEHOLDER } from '../../../constants';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { useBranchDetailsQuery } from 'src/features/Settings/BranchLocations/queries';
import ToggleRichTextEditor from '../../../shared/ToggleRichTextEditor';
import { CLOSING_NOTES_SUB_SECTION, GENERAL_TNC_SECTION_NAME, GENERAL_TNC_SUB_SECTION, PROJECT_NOES_SUB_SECTION } from '../../shared/constants';

enum Type {
    closingNotes = 'closing_notes',
    projectNotes = 'project_notes',
    generalTermsAndCondition = 'general_tnc'
}

function GeneralTermsAndCondition() {
    const { handlePdfPreview, isInitiateApiCalls, isTextFieldChanged, handleTextFieldChanged, isFetch, bomId, handleUpdateChangedSections } = useQuoteContext();

    const { data: generalTermsAndConditionData, isLoading: isGeneralTermsAndConditionLoading } = useGeneralTermsAndConditionQuery(bomId, isInitiateApiCalls);
    const { data: bomData } = useGetBomDetails(bomId!, { enabled: Boolean(bomId) });
    const { data: projectData } = useGetProjectDetailsQuery(Number(bomData?.data.project)!, { enabled: Boolean(bomData?.data.project) });
    const { data: companyDetails } = useBranchDetailsQuery(Number(projectData?.data.company)!, { enabled: Boolean(projectData?.data.company) });
    const isTandCDisabled: boolean = companyDetails?.data.quote.quote_general_tnc.is_general_tnc_locked ?? true;

    const { mutateAsync: mutateToggle } = useGeneralTermsAndConditionToggleMutation();
    const { mutateAsync: mutateText } = useGeneralTermsAndConditionTextMutation();

    const [closingNotesState, setClosingNotesState] = useState<FieldNotes>({
        value: '',
        font: {
            color: '#37d67a',
            type: 'regular'
        }
    });

    useEffect(() => {
        if (generalTermsAndConditionData) {
            setClosingNotesState(generalTermsAndConditionData.closing_notes.field_value);
        }
    }, [isGeneralTermsAndConditionLoading]);

    const pushChangedFields = (fieldName: string) => {
        handleUpdateChangedSections?.({ sectionName: GENERAL_TNC_SECTION_NAME, subSectionName: fieldName, value: true });
    };

    async function handleOnBlur(field: string, htmlString: string) {
        switch (field) {
            case Type.projectNotes:
                if (generalTermsAndConditionData?.project_notes?.field_value !== htmlString) {
                    await mutateText({
                        bomId,
                        fieldName: field,
                        field_value: htmlString
                    });
                    handlePdfPreview(true);
                    pushChangedFields(field);
                }

                break;

            case Type.generalTermsAndCondition:
                if (generalTermsAndConditionData?.general_tnc?.field_value !== htmlString) {
                    await mutateText({
                        bomId,
                        fieldName: field,
                        field_value: htmlString
                    });
                    handlePdfPreview(true);
                    pushChangedFields(field);
                }

                break;

            default:
                break;
        }
    }

    async function handleClosingNotesOnBlur(isColor?: boolean) {
        if (isTextFieldChanged || isColor) {
            await mutateText({
                bomId,
                fieldName: Type.closingNotes,
                field_value: closingNotesState
            });

            handlePdfPreview(true);
            pushChangedFields(CLOSING_NOTES_SUB_SECTION);
        }
    }

    function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        setClosingNotesState({ ...closingNotesState, value: e.target.value });
        handlePdfPreview(false);
        handleTextFieldChanged(true);
    }

    function handleOnFocus() {
        handleTextFieldChanged(false);
    }

    function handleOnClick(open: boolean) {
        if (open) {
            handleClosingNotesOnBlur(true);
        }
    }

    async function selectFontWeightHandler(e: SelectChangeEvent<unknown>) {
        const fieldValue = {
            ...closingNotesState,
            font: {
                ...closingNotesState?.font,
                type: e.target.value as string
            }
        };

        setClosingNotesState(fieldValue);
        await mutateText({
            bomId,
            fieldName: Type.closingNotes,
            field_value: fieldValue
        });

        handlePdfPreview(true);
        pushChangedFields(CLOSING_NOTES_SUB_SECTION);
    }

    async function handleColorChange(color: ColorResult) {
        setClosingNotesState({
            ...closingNotesState,
            font: {
                ...closingNotesState.font,
                color: color.hex
            }
        });
    }

    async function handleOnToggle(e: React.ChangeEvent<HTMLInputElement>, field: string, subSectionName?: string) {
        await mutateToggle({
            bomId,
            fieldName: field,
            is_active: e.target.checked
        });

        handlePdfPreview(true);
        if (subSectionName) pushChangedFields(subSectionName);
    }

    function handleRichTextOnChange() {
        if (isFetch) {
            handlePdfPreview(false);
        }
    }

    if (isGeneralTermsAndConditionLoading) {
        return <CircularLoader />;
    }

    return (
        <>
            {generalTermsAndConditionData && (
                <Grid container direction="column" rowSpacing={3}>
                    <Grid item width={1}>
                        <MultilineTextWithFontAndColor
                            textFieldValue={closingNotesState.value}
                            onBlur={handleClosingNotesOnBlur}
                            selectedFontValue={closingNotesState.font.type}
                            onTextFieldChange={handleTextFieldChange}
                            onSelect={(e) => selectFontWeightHandler(e)}
                            numberOfRows={6}
                            color={closingNotesState.font.color}
                            onColorChange={(color) => handleColorChange(color)}
                            switchValue={generalTermsAndConditionData.closing_notes.is_active}
                            onToggle={(e) => handleOnToggle(e, Type.closingNotes, CLOSING_NOTES_SUB_SECTION)}
                            title="Closing Notes"
                            onFocus={handleOnFocus}
                            onClick={handleOnClick}
                            maxLength={10000}
                            sectionName={GENERAL_TNC_SECTION_NAME}
                            subSectionName={CLOSING_NOTES_SUB_SECTION}
                        />
                    </Grid>

                    <Grid item>
                        <ToggleRichTextEditor
                            checked={generalTermsAndConditionData.project_notes.is_active}
                            placeholder={NOTES_PLACEHOLDER}
                            switchLabel="Project Notes"
                            onToggle={(e: React.ChangeEvent<HTMLInputElement>) => handleOnToggle(e, Type.projectNotes, PROJECT_NOES_SUB_SECTION)}
                            blurAction={(html) => handleOnBlur(Type.projectNotes, html)}
                            initialHtml={generalTermsAndConditionData.project_notes.field_value || ''}
                            onChange={handleRichTextOnChange}
                            sectionName={GENERAL_TNC_SECTION_NAME}
                            subSectionName={PROJECT_NOES_SUB_SECTION}
                        />
                    </Grid>

                    <Grid item>
                        <Tooltip
                            title={isTandCDisabled ? 'Terms & Conditions are currently locked. Admins can unlock and/or edit the T&Cs in Settings > Branch Locations > Quote Customization tab.' : ''}
                            placement="top"
                        >
                            <span>
                                <ToggleRichTextEditor
                                    checked={generalTermsAndConditionData.general_tnc.is_active}
                                    placeholder={NOTES_PLACEHOLDER}
                                    switchLabel="General Terms & Conditions"
                                    disabled={isTandCDisabled}
                                    onToggle={(e: React.ChangeEvent<HTMLInputElement>) => handleOnToggle(e, Type.generalTermsAndCondition, GENERAL_TNC_SUB_SECTION)}
                                    blurAction={(html) => handleOnBlur(Type.generalTermsAndCondition, html)}
                                    initialHtml={generalTermsAndConditionData.general_tnc.field_value || ''}
                                    onChange={handleRichTextOnChange}
                                    sectionName={GENERAL_TNC_SECTION_NAME}
                                    subSectionName={GENERAL_TNC_SUB_SECTION}
                                />
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default GeneralTermsAndCondition;
