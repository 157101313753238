import { useParams } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

import { AxiosResponse } from 'axios';

import { useFeatureFlag } from '../../../shared/UserActivityTracking/useFeatureFlag';
import { QUOTE } from '../constants';
import { DownloadByVersionIdTypes } from '../queries/apiTypes';
import { useDownloadMutation } from '../queries';
import { SnackbarMessages } from '../constants';
import { CED_NET_DOWNLOAD_FAILED, DOWNLOAD_FAILED_MSG } from '../../../shared/constants';
import { callProxyURL } from '../utils/downloadFile';
import { IBomAndQuoteIds } from './types';
import { getFileExtension } from '../../../shared/utils/utils';

type OptionsType = 'share' | 'download';

interface IShareOrDownloadOptionsType {
    documentType: string;
    setSnackbarOpen: (message: string, autoHideDuration?: number | null | undefined) => void;
    onClick?: () => void;
    bomAndQuoteIds?: Array<IBomAndQuoteIds>;
    quoteId?: number;
    type?: OptionsType;
}

export const useGetShareOrDownloadOptions = ({ onClick = () => {}, quoteId, setSnackbarOpen, documentType, type = 'share', bomAndQuoteIds }: IShareOrDownloadOptionsType) => {
    const { bomId = '' } = useParams();
    const { enable_cednet_format, enable_epicor_format } = useFeatureFlag();

    const { mutateAsync, isLoading } = useDownloadMutation();

    function handleError(fileType: DownloadByVersionIdTypes['type'], error: any) {
        if ((fileType === 'ced_xlsx' || fileType === 'jm_xlsx') && error?.response?.status === 404) {
            setSnackbarOpen(CED_NET_DOWNLOAD_FAILED);
        } else {
            setSnackbarOpen(DOWNLOAD_FAILED_MSG);
        }
    }

    const handleSingleDownload = useCallback(
        async (fileType?: DownloadByVersionIdTypes['type']) => {
            const currentFileType = fileType ? fileType : 'flattened';
            try {
                setSnackbarOpen(SnackbarMessages.DOWNLOADING);
                if (quoteId && documentType) {
                    const response = await mutateAsync({ bomId, id: quoteId, type: currentFileType, documentType });
                    await callProxyURL(response?.data.url, fileType ? fileType : 'pdf', response?.data.filename);
                    setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
                }
            } catch (error) {
                handleError(currentFileType, error);
            }
        },
        [bomId, quoteId, setSnackbarOpen, documentType]
    );

    const handleMutlipleDownloads = useCallback(
        async (fileType?: DownloadByVersionIdTypes['type']) => {
            const currentFileType = fileType ? fileType : 'flattened';
            try {
                if (bomAndQuoteIds?.length) {
                    setSnackbarOpen(SnackbarMessages.DOWNLOADING);
                    const mutationsPromiseArr: Array<Promise<AxiosResponse>> = bomAndQuoteIds.map((item) =>
                        mutateAsync({
                            bomId: item.bomId,
                            id: item.quoteId,
                            type: currentFileType,
                            documentType
                        })
                    );
                    const response = await Promise.all(mutationsPromiseArr);
                    if (response?.length) {
                        await Promise.all([
                            ...response.map((item, index) => callProxyURL(item?.data.url, fileType ? fileType : 'pdf', `${bomAndQuoteIds[index].filename}.${getFileExtension(item?.data.filename)}`))
                        ]);
                    }
                    setSnackbarOpen(SnackbarMessages.DOWNLOAD_SUCCESS);
                }
            } catch (error) {
                handleError(currentFileType, error);
            }
        },
        [bomAndQuoteIds, documentType, setSnackbarOpen]
    );

    const getDownloadOption = useCallback(
        (label: string, type?: DownloadByVersionIdTypes['type']) => {
            if (bomAndQuoteIds?.length) {
                return {
                    label,
                    onClick: () => handleMutlipleDownloads(type)
                };
            }
            return {
                label,
                onClick: () => handleSingleDownload(type)
            };
        },
        [bomAndQuoteIds, handleSingleDownload, handleMutlipleDownloads]
    );

    const shareDocumentOption = useMemo(
        () => ({
            label: 'Share via Email',
            onClick
        }),
        [onClick]
    );

    const optionsArray = useMemo(() => {
        const optionsArray = [];
        const labelPrefix = type === 'share' ? 'Download as ' : '';
        if (type === 'share') {
            optionsArray.push(shareDocumentOption);
        }
        optionsArray.push(getDownloadOption(`${labelPrefix}PDF`));

        if (documentType === QUOTE) {
            optionsArray.push(getDownloadOption(`${labelPrefix}Excel`, 'xlsx'));
        }

        if (enable_cednet_format && documentType === QUOTE) {
            optionsArray.push(getDownloadOption(`${labelPrefix}CEDNet`, 'ced_xlsx'));
        }

        if (enable_epicor_format && documentType === QUOTE) {
            optionsArray.push(getDownloadOption(`${labelPrefix}Job Management (Internal)`, 'jm_xlsx'));
        }

        return optionsArray;
    }, [enable_cednet_format, enable_epicor_format, documentType, type, bomAndQuoteIds, shareDocumentOption, getDownloadOption]);

    return { optionsArray, isLoading };
};
