import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BodyBig, BodyMedium, Switch, Grid } from '@parspec/pixel';

import TOCFileUpload from './TOCFileUpload';
import { useTocToggleConfigurationQuery, useDocumentToggleConfigurationMutation } from '../../queries';
import PreviewContext from '../../Context';
import { ENTITY } from '../../../constants';

interface IState {
    primary_title: boolean;
    secondary_title: boolean;
    project_number: boolean;
    quote_number: boolean;
    section: boolean;
    hyperlinks: boolean;
    type: boolean;
    qty: boolean;
    page: boolean;
}

const TOC_GENERAL_INFORAMTION_FIELDS = [
    {
        label: 'Primary Title',
        value: 'primary_title'
    },
    {
        label: 'Secondary Title',
        value: 'secondary_title'
    },
    {
        label: 'Project Number',
        value: 'project_number'
    },
    {
        label: 'Quote Number',
        value: 'quote_number'
    }
];

const TOC_INFO_FIELDS = [
    {
        label: 'Sections',
        value: 'section'
    },
    {
        label: 'Hyperlinks',
        value: 'hyperlinks'
    },
    {
        label: 'Types',
        value: 'type'
    },
    {
        label: 'Quantities',
        value: 'qty'
    },
    {
        label: 'Page Numbers',
        value: 'page'
    }
];

const AccordionBody = () => {
    const { bomId = '', documentType = '' } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);

    const { data: tocToggleConfigurationData, isLoading } = useTocToggleConfigurationQuery(bomId, documentType, presetResponse);

    const { mutateAsync: tocMutate } = useDocumentToggleConfigurationMutation();

    const [tocState, setTocState] = useState<IState>({
        primary_title: true,
        secondary_title: true,
        project_number: true,
        quote_number: true,
        section: true,
        hyperlinks: true,
        type: true,
        qty: true,
        page: true
    });

    useEffect(() => {
        if (tocToggleConfigurationData) {
            setTocState({ ...tocToggleConfigurationData });
        }
    }, [isLoading]);

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;

        setTocState({ ...tocState, [name]: checked });

        await tocMutate({
            bomId,
            fieldName: name,
            is_active: checked,
            documentType: documentType,
            entity: ENTITY.TOC_ENTITY
        });

        handlePreviewImage(true);
    };

    return (
        <Grid container direction={'column'}>
            {/* file uploader section */}
            <Grid item p={2}>
                <TOCFileUpload />
            </Grid>

            <Grid item p={2} mt={-2}>
                <BodyBig color={'secondary.main'} fontWeight={500}>
                    General Information
                </BodyBig>
            </Grid>

            <Grid item p={2}>
                <BodyMedium color={'secondary.main'} fontWeight={400} lines={2}>
                    The entries for these fields will be as entered in the cover page section.
                </BodyMedium>
            </Grid>

            {TOC_GENERAL_INFORAMTION_FIELDS.map((option) => {
                return (
                    <Grid item p={2} key={option.label}>
                        <Switch name={option.value} label={option.label} checked={tocState[option.value as keyof IState]} onChange={handleOnChange} />
                    </Grid>
                );
            })}

            <Grid item p={2}>
                <BodyBig color={'#283453'} fontWeight={500}>
                    Table of Contents Information
                </BodyBig>
            </Grid>

            {TOC_INFO_FIELDS.map((option) => {
                return (
                    <Grid item p={2} key={option.label}>
                        <Switch name={option.value} label={option.label} checked={tocState[option.value as keyof IState]} onChange={handleOnChange} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default AccordionBody;
