import { useRef, useMemo, useState } from 'react';

import { ICellRendererParams, ValueGetterParams, ColDef } from 'ag-grid-community';
import { isEqual } from 'lodash-es';

import { Box, Snackbar, TableHeaderMenuIcon, AgGridTable, SelectChangeEvent } from '@parspec/pixel';

import {
    getDocumentOwnerTemplate,
    getBomRowData,
    getStageTemplate,
    getSyncCache,
    getCustomerContactTemplate,
    getCustomerContactValue,
    getDateForTable,
    stringComparator,
    getDateComparator,
    getDateValueForUI,
    dateFilterParams
} from '../shared/utils/utils';
import { TAB_LABEL } from '../shared/constants';
import { QuoteInfoDashBoard, ViewOption, QuoteDocInfo, DashboardPageNameType } from '../queries/apiTypes';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import {
    useGetQuotesQuery,
    useOutOfSyncQuery,
    useDocumentTags,
    useGetSelectedColumnsAndPersistingFiltersQuery,
    useUpdateSelectedColumnsMutation,
    useUpdatePersistingFiltersMutation,
    userDashboardViewMutation
} from '../queries';
import { BomTablesDataType, SyncTypeForTable } from '../shared/utils';
import { QUOTE } from '../../shared/constants';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { MenuOption, defaultColDef } from './components';
import { MenuContext } from './context';
import { getRowId, getQuoteStatusTemplate } from './utils';
import TableRightSection from '../shared/components/TableRightSection';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { ManageColumnsPopup } from '../shared/components/ManageColumnsPopup/ManageColumnsPopup';
import { quotePageTableColumnOptions, getGrandTotalComparator } from './utils';
import { QuoteNumberTemplate, DateTemplate, BomTemplate, ProjectTemplate } from '../shared/components';
import { useCheckAccessForProductFinderOrQuoting } from '../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useGetUserDashboardViews } from '../shared/hooks';

const defaultLockColumnDef: ColDef[] = [
    {
        field: 'quoteId',
        hide: true,
        lockPosition: 'left',
        resizable: false
    },
    {
        minWidth: 60,
        maxWidth: 60,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        cellStyle: { backgroundColor: 'unset' },
        cellRenderer: MenuOption,
        editable: false,
        pinned: 'right',
        colId: 'menu',
        resizable: false,
        lockPosition: 'right',
        lockPinned: true
    }
];

const QuotesTables = () => {
    const { dashboardSelectedViews: quoteSelectedView, isDashboardViewsLoading } = useGetUserDashboardViews();
    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();

    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [searchText, setSearchText] = useState('');

    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { data: paymentDetail, isLoading: isPaymentDetailLoading } = useSubscriptionInfo();
    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();

    const { data: quotesData, isLoading } = useGetQuotesQuery(quoteSelectedView, { enabled: !isDashboardViewsLoading });
    const { data: quoteSyncData } = useOutOfSyncQuery(QUOTE);
    const { data: documentTagsData } = useDocumentTags(QUOTE);

    const { data: selectedColumnsAndFiltersQueryData, isFetching: isSelectedColumnsAndFiltersQueryDataFetching } = useGetSelectedColumnsAndPersistingFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: 'dashboard_quote' },
        { enabled: !isDashboardViewsLoading && !isUserDataLoading }
    );
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();
    const { mutateAsync: updateSelectedFiltersMutation } = useUpdatePersistingFiltersMutation();

    const tableRef = useRef<any>();

    // derived state
    const isActiveSubscription = paymentDetail?.data?.active;

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const quoteOutOfSyncCache = useMemo(() => {
        if (quoteSyncData?.data) return getSyncCache(quoteSyncData.data);
        return null;
    }, [quoteSyncData]);

    const quoteTableData = useMemo(() => {
        if (!quotesData?.data) {
            return null;
        }
        const quoteTableDataWithSyncAndTags = quotesData.data.map((data) => {
            const bomId = data?.bom?.id;
            const currentQuoteData: QuoteInfoDashBoard & SyncTypeForTable = { ...data };
            if (bomId && quoteOutOfSyncCache && quotingAccess) {
                currentQuoteData.quoteOutOfSync = quoteOutOfSyncCache?.[bomId] || false;
            }

            if (bomId && documentTagsData?.data && currentQuoteData?.quote) {
                currentQuoteData.quote.forEach((item: QuoteDocInfo) => {
                    for (const quoteDocument of documentTagsData.data) {
                        if (item.bom_id === quoteDocument.bom_id && item.id === quoteDocument.entity_id && quoteDocument.entity_type === QUOTE) {
                            item.tag_name = quoteDocument.tag_name;
                            item.tag_id = quoteDocument.tag_id;
                        }
                    }
                });
            }

            return currentQuoteData;
        });

        return quoteTableDataWithSyncAndTags;
    }, [quotesData?.data, quoteOutOfSyncCache, documentTagsData?.data]);

    const handleViewChange = (event: SelectChangeEvent<unknown>) => {
        if (event?.target?.value) {
            if (userData?.data.id) userDashboardViewMutation(userData?.data?.id, event.target.value as ViewOption);
        }
    };

    const defaultColumnDef: any = {
        quoteNumber: {
            field: 'quoteNumber',
            headerName: 'Quote ID',
            minWidth: 140,
            cellRenderer: QuoteNumberTemplate,
            sortable: true
        },
        status: {
            field: 'status',
            headerName: 'Status',
            cellRenderer: getQuoteStatusTemplate,
            minWidth: 140,
            filter: 'agSetColumnFilter',
            suppressMenu: false
        },
        quoteVersion: {
            field: 'quoteVersion',
            headerName: 'Version',
            minWidth: 100,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        quoteCreationDate: {
            field: 'quoteCreationDate',
            headerName: 'Creation Date',
            minWidth: 200,
            cellRenderer: (params: any) => getDateForTable(params?.data?.quoteCreationDate),
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'quoteCreationDate'),
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'quoteCreationDate'),
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            },
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        quoteCreatedBy: {
            field: 'quoteCreatedBy',
            headerName: 'Created By',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        quoteProductCount: {
            field: 'quoteProductCount',
            headerName: 'Products Included',
            minWidth: 165,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        quoteGrandTotal: {
            field: 'quoteGrandTotal',
            headerName: 'Grand Total',
            minWidth: 180,
            sortable: true,
            comparator: (valueA: string, valueB: string) => getGrandTotalComparator(valueA, valueB),
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        dueDate: {
            field: 'dueDate',
            headerName: 'Quote Due Date',
            minWidth: 210,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.dueDate} />,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'dueDate'),
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'dueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        quoteOwner: {
            field: 'quoteOwner',
            headerName: 'Quote Owner',
            minWidth: 210,
            cellRenderer: (props: ICellRendererParams) => getDocumentOwnerTemplate(props, 'QUOTE'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        bomName: {
            field: 'bomName',
            headerName: 'Bill Of Materials',
            minWidth: 220,
            cellRenderer: (params: ICellRendererParams) => <BomTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        primary_contact: {
            field: 'primary_contact',
            headerName: 'Primary Customer',
            minWidth: 220,
            cellRenderer: (props: ICellRendererParams) => getCustomerContactTemplate(props, 'primary_contact'),
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueGetter: (params: ValueGetterParams) => getCustomerContactValue(params, 'primary_contact')
        },
        projectName: {
            field: 'projectName',
            headerName: 'Project',
            minWidth: 200,
            cellRenderer: (params: ICellRendererParams) => <ProjectTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectStage: {
            field: 'projectStage',
            headerName: 'Project Stage',
            minWidth: 180,
            cellRenderer: getStageTemplate,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        branchLocation: {
            field: 'branchLocation',
            headerName: 'Branch Location',
            minWidth: 180,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        }
    };

    function handleExportExcel() {
        const params = {
            fileName: 'Quotes_Parspec_Export.xlsx', // Specify the filename
            sheetName: 'Quotes' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number } = { pageName: 'dashboard_quote', columnData: rowData, userId: userData?.data?.id || 0 };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const quoteDataForTable = useMemo(() => {
        let quoteTabRowData: Array<BomTablesDataType> = [];
        const quotesDataForTableUi: Array<BomTablesDataType | null> = [];

        if (!quoteTableData) {
            return null;
        }

        if (!isLoading && quoteTableData) {
            quoteTabRowData = quoteTableData.map(getBomRowData(TAB_LABEL.QUOTE_LABEL));
            for (const item of quoteTabRowData) {
                if (item?.quoteData) {
                    for (const quote of item.quoteData) {
                        quotesDataForTableUi.push({
                            ...item,
                            quoteNumber: quote?.quote_number || '',
                            quoteId: quote?.id || 0,
                            shareLink: quote?.share_link || null,
                            status: quote?.tag_name || null,
                            quoteVersion: quote?.version || null,
                            quoteCreationDate: quote?.created_at || null,
                            quoteCreatedBy: quote?.compiled_by || null,
                            quoteProductCount: quote?.product_count || null,
                            quoteGrandTotal: quote?.grand_total || null
                        });
                    }
                }
            }
        }

        return quotesDataForTableUi;
    }, [quoteTableData, isLoading]);

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsAndFiltersQueryData?.table_settings || isSelectedColumnsAndFiltersQueryDataFetching) {
            return;
        }

        if ((selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.prototype.hasOwnProperty.call(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [isSelectedColumnsAndFiltersQueryDataFetching, selectedColumnsAndFiltersQueryData?.table_settings, quoteDataForTable]);

    const onFirstDataRendered = (params: any) => {
        if (selectedColumnsAndFiltersQueryData?.table_settings.length === 0 || Object.keys(selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters || {}).length === 0) {
            return;
        }

        const response = selectedColumnsAndFiltersQueryData?.table_settings?.[0].filters;

        const filterKeys = Object.keys(response);
        filterKeys.forEach((key: string) => {
            params.api.getFilterInstance(key).setModel(response[key]);
        });

        params.api.onFilterChanged();
    };

    const handleFilterChanged = async (params: any) => {
        const { api } = params;
        const filterModel = api.getFilterModel();

        const apiResponse = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters;

        if (!isEqual(filterModel, apiResponse)) {
            // call mutation
            const payload: { pageName: DashboardPageNameType; filterData: any; userId: number } = { pageName: 'dashboard_quote', filterData: filterModel, userId: userData?.data?.id || 0 };
            await updateSelectedFiltersMutation(payload);
        }
    };

    const isTableDataLoading = isLoading || isPaymentDetailLoading || isUserDataLoading || isSelectedColumnsAndFiltersQueryDataFetching || isUpdateSelectedColumnsMutationLoading;

    return (
        <>
            <Snackbar open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>

            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <MenuContext.Provider value={{ isActiveSubscription, setSnackbarMessage, handleSubscriptionPopup, tabName: 'QUOTE' }}>
                    <AgGridTable
                        ref={tableRef}
                        getRowId={getRowId}
                        isLoading={isTableDataLoading}
                        rowHeight={52}
                        showToolbarPanel
                        toolBarPanelOptions={['search', 'clearFilters']}
                        onClearFilter={clearAllFilters}
                        toolbarRightSection={
                            <TableRightSection
                                selectedView={quoteSelectedView}
                                isMyViewLoading={isDashboardViewsLoading}
                                onMyViewChange={handleViewChange}
                                isTableLoading={isLoading}
                                onExportClick={handleExportExcel}
                                onManageColumnsClick={onManageColumnsClick}
                            />
                        }
                        rowData={quoteDataForTable}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        pagination
                        paginationAutoPageSize
                        suppressContextMenu
                        suppressMenuHide
                        onTextSearch={handleSearch}
                        quickFilterText={searchText}
                        onFirstDataRendered={onFirstDataRendered}
                        onFilterChanged={handleFilterChanged}
                    />
                </MenuContext.Provider>
            </Box>
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={quotePageTableColumnOptions.data}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryDataFetching={isSelectedColumnsAndFiltersQueryDataFetching}
                />
            )}
        </>
    );
};
export default QuotesTables;
