import { UsersModalInitialErrorStateT } from '../Modal/modal.types';
import { IUserInfo } from '../queries/apiTypes';

export const validateEmail = (mailAddress: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(mailAddress)) {
        return true;
    } else {
        return false;
    }
};

export const modalInitialErrorState: UsersModalInitialErrorStateT = {
    email: {
        errorActive: false,
        title: ''
    },
    location: {
        errorActive: false,
        title: 'Please select at least one location'
    }
};

export const mapCompanyLocationData = (data: IUserInfo[]) => {
    return data.map((el) => {
        const companyNameArr = el.company_details.map((loc) => loc.company_name);
        const flatenedString = companyNameArr.join(',');
        return {
            ...el,
            company_locations_arr: companyNameArr,
            company_locations_str: flatenedString
        };
    });
};
