import { axios } from 'src/app/Axios';
import { GetFreightDetailsResponse, PutFreightDetailsRequest, PutFreightDetailsResponse } from './apiTypes';

export const endPoints = {
    freightDetailsUrl: (bomId: string) => `/bom/${bomId}/pricing/freight/`
};

export const getFreightDetails = async (bomId: string) => {
    return axios.get<GetFreightDetailsResponse>(endPoints.freightDetailsUrl(bomId));
};

export const putFreightDetails = async (req: PutFreightDetailsRequest) => {
    const { bomId, payload } = req;
    return axios.put<PutFreightDetailsResponse>(endPoints.freightDetailsUrl(bomId), payload);
};
