import { environment } from 'src/environments';

export const MSAL_CONFIG = {
    auth: {
        clientId: environment.l,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: `${environment.R}/v2/settings/my-profile`,
        protocolMode: 'OIDC',
        OIDCOptions: {
            defaultScopes: ['Mail.Send', 'User.Read', 'email', 'profile']
        }
    }
};

export const GMAIL_CONFIG = {
    clientId: environment.O,
    scopes: [
        'https://www.googleapis.com/auth/gmail.modify',
        'https://www.googleapis.com/auth/gmail.compose',
        'https://www.googleapis.com/auth/gmail.readonly',
        'https://www.googleapis.com/auth/userinfo.email'
    ]
};

export const EMAIL_SERVICE_TYPES = {
    MSAL: 'MSAL',
    GMAIL: 'GMAIL'
};

export const LOGIN_OPTIONS = [
    {
        type: EMAIL_SERVICE_TYPES.MSAL,
        logo: 'assets/images/Email/outlook.png',
        text: 'Add an Outlook, Office365, or\n Exchange account.'
    }
    // {
    //     type: EMAIL_SERVICE_TYPES.GMAIL,
    //     logo: 'assets/images/Email/gmail.png',
    //     text: 'Add a google account'
    // }
];

export const EMAIL_BATCH_SIZE = 3;

export const SHAREABLE_LINK_TEXT = 'Shareable Link';

export const ATTACHMENT_ALLOWED_SIZE = 15728640;

export const MSAL_ACCESSTOKEN_LS_KEY = 'msToken';

export const MAILING_EMAIL_LS_KEY = 'mailing-email';

export const GOOGLE_ACCESS_TOKE_LS_KEY = 'googleToken';

export const MSAL_TOKEN_GENERATED_AT_LS_KEY = 'msTokenGenerationAt';

export const ATTACHMENT_ERROR_TEXT = 'Total attachment size should be less than 15MB';

export const BOM_SELECTION_ERROR = 'One or more BOMs must be selected.';

export const BULK_BOM_EMAIL_ERROR = 'One or more selected BOMs have invalid email addresses.';

export const BOM_EMAIL_ERROR = 'Please fix the errors and try again.';

export const BULK_NO_RECEPIENT_ERROR = 'One or more selected BOMs are missing email recipient(s).';

export const NO_RECEPIENT_ERROR = 'An email recipient is required.';

export const BULK_ATTACHMENT_ERROR = 'One or more selected BOMs exceeds the maximum allowed attachment size limit of 15MB';

export const SEND_MAIL_LIMIT_EXCEED_ERROR_CODE = 'ErrorExceededMessageLimit';

export const SEND_MAIL_LIMIT_EXCEED_ERROR_MESSAGE = 'Cannot send mail. Daily Message/Recipient limit exceeded.';

export const BULK_EMAIL_HEADER_SUBTITLE = 'Send documents via email to the selected BOMs. Remember to personalize messages, and adjust the selection if appropriate prior to sending.';
