import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import { invalidateBomDataQuery, invalidateGetSectionsQuery } from '../../queries';
import { sendXmlAnnotation, getcontentDetail, getXmlurl, getXmlDetail, getSubmittalPackagetoc, getSubmittalPackageXmlDetail, endPoints } from './api';
import { invalidatePricingBodQuery } from '../../pricing&LeadTime/queries';
import { SelectPackageRequest, SelectScheduleResponse } from './apiTypes';

const getcontentDetailQuerykey = (bomId: number) => [endPoints.contentDetail(bomId)];
const getXmlDetailQuery = (bomId: number, url: string) => [endPoints.xmlUrl(bomId, url)];
export const useSendXmlAnnotationMutation = () => useMutation(sendXmlAnnotation);
export const useSendPackageXmlAnnotationMutation = () => useMutation(getSubmittalPackagetoc);
export const useConfirmContinueAnnotationMutation = () =>
    useMutation(getXmlDetail, {
        onSuccess: (_response, variables) => {
            invalidateGetSectionsQuery(variables.bomId);
            invalidateBomDataQuery(variables.bomId);
            invalidatePricingBodQuery(Number(variables.bomId));
        }
    });
export const useConfirmContinuePackageMutation = () =>
    useMutation(getSubmittalPackageXmlDetail, {
        onSuccess: (_response, variables) => {
            invalidateGetSectionsQuery(variables.bomId);
            invalidateBomDataQuery(variables.bomId);
            invalidatePricingBodQuery(Number(variables.bomId));
        }
    });

export const useGetContentDetailQuery = (submittalId: number, options?: Omit<UseQueryOptions<AxiosResponse<SelectScheduleResponse & SelectPackageRequest>>, 'queryKey' | 'queryFn'>) => {
    return useQuery(getcontentDetailQuerykey(submittalId), () => getcontentDetail(submittalId), options);
};
export const useGetXmlDetailQuery = (bomId: number, url: string) => {
    return useQuery(getXmlDetailQuery(bomId, url), () => getXmlurl(bomId, url));
};

export const invalidateContentDetailQuery = (submittalId: number) => {
    queryClient.invalidateQueries(getcontentDetailQuerykey(submittalId));
};
