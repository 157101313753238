import { Outlet } from 'react-router-dom';

import { Box, H5 } from '@parspec/pixel';

import { SettingsSideNav } from './SettingsSideNav/SettingsSideNav';

export const Settings = () => {
    return (
        <Box height="100vh" bgcolor={'neutral.light'}>
            <Box px={4} py={6} bgcolor="primary.contrastText" borderBottom="1px solid" borderColor="neutral.main">
                <H5>Settings</H5>
            </Box>

            <Box display="flex" p={6} pb={0}>
                <Box height={'fit-content'} bgcolor="primary.contrastText" borderColor={'neutral.main'} p={4} minWidth={'247px'} mr={6} borderRadius={'6px'}>
                    <SettingsSideNav />
                </Box>

                <Box>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};
