import { BodySmall, Box, BodyBig, Button, Modal, Link } from '@parspec/pixel';

interface ForceRefreshModalInterface {
    isOpen: boolean;
}

export const ForceRefreshModal = (props: ForceRefreshModalInterface) => {
    const { isOpen } = props;

    const getNewestVersionClickHandler = async () => {
        // @ts-ignore
        window.location.reload(true);
    };
    return (
        <Box>
            <Modal
                open={isOpen}
                footer={
                    <Box textAlign={'center'} mt={4}>
                        <Button color="warning" onClick={getNewestVersionClickHandler}>
                            Get the newest version!
                        </Button>
                    </Box>
                }
                header={''}
                onClose={() => {}}
            >
                <Box width={'424px'}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box>
                            <img src={'assets/icons/refresh-star.png'} alt="newest-version-modal" />
                        </Box>
                        <BodyBig limit={false} display={'inline'} mt={4}>
                            Our latest update is here!
                        </BodyBig>
                    </Box>

                    <Box mt={4} textAlign="center">
                        <BodySmall limit={false} color={'text.secondary'} display={'inline'}>
                            We’ve recently made changes to our application that require a refresh. Click
                        </BodySmall>

                        <Link
                            href="https://knowledgebase.parspec.io/knowledge/product-updates"
                            target="_blank"
                            rel="noopener"
                            sx={{
                                underline: 'none',
                                textDecoration: 'none',
                                '&:hover': {
                                    color: 'primary.main'
                                }
                            }}
                        >
                            <Box display={'inline'} alignItems={'flex-end'} mx={1}>
                                <BodySmall limit={false} display={'inline'} color={'primary.main'}>
                                    here
                                </BodySmall>
                            </Box>
                        </Link>

                        <BodySmall limit={false} color={'text.secondary'} display={'inline'}>
                            to see what’s new!
                        </BodySmall>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};
