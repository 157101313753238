import { AutocompleteChangeReason, MultiSelect, MultiSelectOptionType } from '@parspec/pixel';

import { FormFieldAction } from '../FormFieldAction';
import { RowContainer } from '../RowContainer';
import { DropdownOptionsType } from '../../types';

interface MultiSelectRowProps {
    options: DropdownOptionsType;
    selectedOptions: { values: DropdownOptionsType; locked: boolean };
    onSelect: (value: DropdownOptionsType, reason: AutocompleteChangeReason) => void;
    onLockChange: (isLocked: boolean) => void;
    label: string;
    id: string;
    onBlur: () => void;
    lastRow?: boolean;
    error?: boolean;
    disableRow?: boolean;
}

export function MultiSelectRow({ onLockChange, label, options, selectedOptions, id, onSelect, onBlur, lastRow = false, error, disableRow }: MultiSelectRowProps) {
    function handleSelect(_event: React.SyntheticEvent<Element, Event>, value: MultiSelectOptionType[], reason: AutocompleteChangeReason) {
        onSelect(value as DropdownOptionsType, reason);
    }
    return (
        <RowContainer lastRow={lastRow}>
            <MultiSelect
                id={id}
                value={selectedOptions.values}
                label={label}
                options={options}
                onChange={handleSelect}
                size="small"
                limitTags={4}
                multiple
                onBlur={onBlur}
                error={error}
                disabled={disableRow}
            />
            <FormFieldAction isLocked={selectedOptions.locked} onLockChange={onLockChange} showClear={false} isDisabled={disableRow} tooltip="Strict matching" />
        </RowContainer>
    );
}
