import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import { FilterType } from '../shared/components/Filters/constants';
import { getBranchLocationsTabData, getCustomersTabData, getManufacturersTabData, getQuoteOwnersTabData, getSelectedColumns, updateSelectedColumns } from './api';
import { ReportingPageNameTypes, CustomersTabDataResponse, BranchLocationsTabDataResponse, QuoteOwnersTabDataResponse, ManufacturersTabDataResponse } from './apiTypes';

const customersTabDataKey = (filters: FilterType) => ['customers-tab-data', filters];
const branchLocationsTabDataKey = (filters: FilterType) => ['branch-locations-tab-data', filters];
const usersTabDataKey = (filters: FilterType) => ['users-tab-data', filters];
const manufacturersTabDataKey = (filters: FilterType) => ['manufacturers-tab-data', filters];

export const useGetCustomersTabDataQuery = (filters: FilterType, options?: Omit<UseQueryOptions<AxiosResponse<CustomersTabDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(customersTabDataKey(filters), () => getCustomersTabData(filters), options);

export const useGetBranchLocationsTabDataQuery = (filters: FilterType, options?: Omit<UseQueryOptions<AxiosResponse<BranchLocationsTabDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(branchLocationsTabDataKey(filters), () => getBranchLocationsTabData(filters), options);

export const useGetQuoteOwnersTabDataQuery = (filters: FilterType, options?: Omit<UseQueryOptions<AxiosResponse<QuoteOwnersTabDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(usersTabDataKey(filters), () => getQuoteOwnersTabData(filters), options);

export const useGetManufacturersTabDataQuery = (filters: FilterType, options?: Omit<UseQueryOptions<AxiosResponse<ManufacturersTabDataResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(manufacturersTabDataKey(filters), () => getManufacturersTabData(filters), options);

// #################################################################################
// ########################## FOR MANAGE COLUMNS ###################################
// #################################################################################

const selectedColumnsApiKey = (pageName: ReportingPageNameTypes) => [`selected-columns-for-${pageName}`];

export const useGetSelectedColumnsQuery = (payload: { userId: number; pageName: ReportingPageNameTypes }, options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
    useQuery(selectedColumnsApiKey(payload.pageName), () => getSelectedColumns(payload), options);

export const useUpdateSelectedColumnsMutation = () =>
    useMutation(updateSelectedColumns, {
        onSuccess: (_, variables) => {
            invalidateUseGetSelectedColumnsQuery(variables.pageName);
        }
    });
export const invalidateUseGetSelectedColumnsQuery = (pageName: ReportingPageNameTypes) => {
    queryClient.invalidateQueries(selectedColumnsApiKey(pageName));
};
