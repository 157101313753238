import { rest } from 'msw';

import { taxesGetManualJson } from './mockGetTaxes';
import { TaxesPricingRequestOrResponseType } from '../apiTypes';

const taxesMockHandler = [
    rest.get('*/bom/:bomId/pricing/tax/', (_req, res, ctx) => {
        // const random = Math.round(Math.random());
        return res(ctx.delay(1000), ctx.json(taxesGetManualJson));
    }),
    rest.put('*/bom/:bomId/pricing/tax/', async (req, res, ctx) => {
        const reqBody = await (req.json() as Promise<TaxesPricingRequestOrResponseType>);
        return res(ctx.delay(5000), ctx.json(reqBody));
    })
];

export { taxesMockHandler };
