import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import { Box, CircularProgress } from '@parspec/pixel';

import { useGetProductDetailsQuery } from './createProduct/queries';

export default function ProductFinder() {
    const { bomId = '0', productId = '0' } = useParams();
    const navigate = useNavigate();

    const { data: bodDetails, error, isLoading, isFetching } = useGetProductDetailsQuery(bomId, productId);
    const apiErrorCode = (error as AxiosError)?.code;

    useEffect(() => {
        if (!isFetching) {
            if (bodDetails?.data && bodDetails?.data?.length > 0) {
                onSuccess();
            } else if (apiErrorCode || (bodDetails?.data && bodDetails?.data.length === 0)) {
                onError();
            }
        }
    }, [bodDetails?.data, apiErrorCode, isLoading, isFetching]);

    function onSuccess() {
        navigate('create', { replace: true });
    }

    function onError() {
        navigate('setRequirements', { replace: true });
    }

    return (
        <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size="xxl" color="primary" />
        </Box>
    );
}
