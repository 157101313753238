import { useParams } from 'react-router-dom';

import { Breadcrumb } from '@parspec/pixel';

import { useGetBomDetails } from '../../queries/index';
import { useGetProjectDetailsQuery } from '../../../Project/queries';
import { RouterLink } from '../../../shared/RouterLink';

interface BomBreadcrumbProps {
    options?: Array<{ displaytext: string; href: string }>;
    component?: React.ElementType;
}

const getProjectRoute = (projectId?: number) => `/v2/project/${projectId || ''}`;
const getBomRoute = (bomId: string, projectId?: number) => `/v2/project/${projectId}/bom/${bomId}?tab=bom`;

const projectsLinkOption = {
    displaytext: 'Dashboard',
    href: '/v2/dashboard'
};

const BomBreadcrumb: React.FC<BomBreadcrumbProps> = ({ options = [], component }) => {
    const { bomId = '' } = useParams();

    const { data: bomData, isError: isBomError } = useGetBomDetails(bomId!, {
        enabled: Boolean(bomId)
    });

    const projectId = bomData?.data?.project;

    const { data: projectDetails, isError: isProjectError } = useGetProjectDetailsQuery(projectId!, {
        enabled: Boolean(projectId),
        refetchOnMount: false
    });

    const projectName = projectDetails?.data.name;
    const bomName = bomData?.data.name;
    const projectNameHref = getProjectRoute(projectId);
    const bomHref = getBomRoute(bomId, projectId);

    const projectDetailLinkOption = {
        displaytext: projectName || 'Project Name',
        href: projectNameHref
    };

    const bomDetailLinkOption = {
        displaytext: bomName || 'BOM Name',
        href: bomHref
    };

    const isLoading = !projectName || !bomName || isBomError || isProjectError;
    const breadCrumbOptions = [projectsLinkOption, projectDetailLinkOption, bomDetailLinkOption, ...options];

    return <Breadcrumb options={breadCrumbOptions} component={component ?? RouterLink} isLoading={isLoading} />;
};

export default BomBreadcrumb;
