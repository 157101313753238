import { FC, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Autocomplete, BodyMedium, Box, MultiSelectOptionType, Switch } from '@parspec/pixel';

import { useGetProjectBomsQuery, useGetProjectDetailsQuery } from '../../Project/queries';
import { useGetAllCompanyDetailsQuery } from '../../shared/CreateAndEditProjectModal/queries';
import { AutoCompleteOption } from './shared/constants';

interface ExistingBomOptionFlowProps {
    selectedProject: AutoCompleteOption;
    onSelectProject: (project: AutoCompleteOption) => void;
    selectedBom: AutoCompleteOption;
    onSelectBom: (bom: AutoCompleteOption) => void;
    bomError: string;
    overWriteTaxes: boolean;
    onToggleOverWriteTaxes: (value: boolean) => void;
}

const ExistingBomOptionFlow: FC<ExistingBomOptionFlowProps> = ({ selectedBom, selectedProject, onSelectBom, onSelectProject, bomError, overWriteTaxes, onToggleOverWriteTaxes }) => {
    const { projectId, bomId } = useParams();
    const [openProjectAutoComplete, setOpenProjectAutoComplete] = useState(false);
    const [openBomAutoComplete, setOpenBomAutoComplete] = useState(false);

    const { data: projectData, isLoading: projectDataLoading } = useGetProjectDetailsQuery(Number(projectId || 0), {
        enabled: Boolean(projectId)
    });

    const { data: allCompanyDetails, isLoading: allCompaniesLoading } = useGetAllCompanyDetailsQuery();

    const { data: projectBoms, isLoading: bomsLoading } = useGetProjectBomsQuery(selectedProject?.id || 0, {
        enabled: Boolean(selectedProject)
    });

    useEffect(() => {
        if (projectData?.data) {
            const { id, name } = projectData?.data || {};
            onSelectProject({ id, name });
        }
    }, [projectData]);

    const projectOptions = useMemo(() => {
        if (projectData && allCompanyDetails) {
            const currentLocation = (allCompanyDetails?.data || [])?.find((location) => location.company_details.company_id === projectData?.data?.company);
            return currentLocation?.projects || [];
        }
        return [];
    }, [projectData, allCompanyDetails]);

    const bomOptions = useMemo(() => {
        const boms: { id: number; name: string }[] = [];
        if (projectBoms?.data) {
            (projectBoms?.data || [])?.forEach((item) => {
                if (item.id !== Number(bomId)) {
                    boms.push({ id: item.id, name: item.name });
                }
            });
        }
        return boms;
    }, [projectBoms]);

    const onOpenProjectAutoComplete = () => setOpenProjectAutoComplete(true);

    const onCloseProjectAutoComplete = () => setOpenProjectAutoComplete(false);

    const onOpenBomAutoComplete = () => setOpenBomAutoComplete(true);

    const onCloseBomAutoComplete = () => setOpenBomAutoComplete(false);

    const onFilterOptions = (options: MultiSelectOptionType[], params: { inputValue: string }) => {
        if (params.inputValue) {
            return options.filter((item) => String(item.name).toLowerCase().includes(params.inputValue.toLowerCase()));
        }
        return options;
    };

    return (
        <Box mt={2}>
            <BodyMedium fontWeight="medium" marginBottom={4}>
                Select BOM
            </BodyMedium>
            <Box>
                <Autocomplete
                    open={openProjectAutoComplete}
                    onOpen={onOpenProjectAutoComplete}
                    optionlabelkeyname="name"
                    options={projectOptions as { id: number; name: string }[]}
                    value={selectedProject || null}
                    onChange={(e: any) => {
                        onSelectProject?.(e.target.value);
                        onCloseProjectAutoComplete();
                    }}
                    label="Project Name"
                    fieldSize="small"
                    loading={allCompaniesLoading || projectDataLoading}
                    filterOptionsCallBack={onFilterOptions}
                    clearOnBlur
                    onBlur={onCloseProjectAutoComplete}
                    blurOnEmptyInput={onCloseProjectAutoComplete}
                />
            </Box>
            <Box mt={6}>
                <Autocomplete
                    open={openBomAutoComplete}
                    onOpen={onOpenBomAutoComplete}
                    optionlabelkeyname="name"
                    options={bomOptions as { id: number; name: string }[]}
                    value={selectedBom || null}
                    onChange={(e: any) => {
                        onSelectBom?.(e.target.value);
                        onCloseBomAutoComplete();
                    }}
                    label="BOM Name *"
                    fieldSize="small"
                    loading={bomsLoading}
                    filterOptionsCallBack={onFilterOptions}
                    clearOnBlur
                    onBlur={onCloseBomAutoComplete}
                    blurOnEmptyInput={onCloseBomAutoComplete}
                    disabled={!selectedProject}
                    error={Boolean(bomError)}
                    helperText={bomError}
                />
            </Box>
            <Box my={6}>
                <Switch checked={overWriteTaxes} label="Overwrite Taxes & Freight" onChange={(e) => onToggleOverWriteTaxes(e.target.checked)} />
            </Box>
        </Box>
    );
};

export default ExistingBomOptionFlow;
