import { BodySmall, Box, Button } from '@parspec/pixel';

interface IFooterProps {
    onAccept?: () => void;
    onReject?: () => void;
    isLoading?: boolean;
    fileError: string;
    isLoggedIn: boolean;
    sendButtonError: string;
}

const Footer = ({ onAccept, onReject, isLoading, isLoggedIn, sendButtonError }: IFooterProps) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <Box>{sendButtonError && <BodySmall color="error.main">{sendButtonError}</BodySmall>}</Box>
            <Box display={'flex'} gap={2}>
                <Button color="secondary" variant="outlined" onClick={onReject}>
                    Cancel
                </Button>

                {isLoggedIn && (
                    <Box>
                        <Button variant="contained" onClick={onAccept} isLoading={isLoading}>
                            Send Email
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Footer;
