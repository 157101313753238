import { useState, useEffect } from 'react';
import { Box, Modal, LinearProgress, BodyBig, BodySmall } from '@parspec/pixel';
import { ReactNode } from 'react';

interface AutosearchLoaderDialogProps {
    open: boolean;
    onClose?: () => void;
    loadTime: number;
    title?: string;
    subTitle?: string;
    helperLink?: ReactNode;
    helperText?: string;
    footerText?: string;
}

const AutosearchLoaderDialog = (props: AutosearchLoaderDialogProps) => {
    const { open, onClose, loadTime, title, subTitle, helperLink, helperText, footerText } = props;
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        let timer: any;
        if (open) {
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
            }, Math.floor(loadTime / 10));
        }
        return () => {
            if (timer) {
                clearInterval(timer);
                setProgress(0);
            }
        };
    }, [open]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width="410px" p={4} pb={0} pt={2}>
                <Box width={'100%'} textAlign="center">
                    <BodyBig color={'secondary.main'}>{title}</BodyBig>
                    <Box mt={4}>
                        <BodySmall color={'neutral.dark'} lines={2} sx={{ lineHeight: '20px' }}>
                            {subTitle} {helperLink ? helperLink : ''}
                        </BodySmall>
                    </Box>
                </Box>
                <img src="assets/images/view-files/compile.gif" alt="loader" height="240px" />
                <BodySmall mb={2} alignSelf={'flex-start'} color={'neutral.dark'}>
                    {helperText || ''}
                </BodySmall>
                <LinearProgress value={progress} variant="determinate" color="primary" />
                <Box
                    alignSelf={'flex-start'}
                    sx={{
                        minWidth: 35
                    }}
                    mt={1}
                >
                    <BodySmall color="neutral.dark">{`${Math.round(progress)}% ${footerText}`}</BodySmall>
                </Box>
            </Box>
        </Modal>
    );
};

export default AutosearchLoaderDialog;

AutosearchLoaderDialog.defaultProps = {
    title: 'Retrieving Datasheets',
    subTitle: 'We are currently searching for previously selected and annotated datasheets.',
    helperText: 'Avoid rework! Let us remember this for you. ',
    footerText: 'of products searched...'
};
