import { Box, H5, Button } from '@parspec/pixel';

import { BillingData, ErrorState, SelectFieldOptions } from '../PlanAndPayment.types';
import { useBillingInfoQuery, useSubscriptionInfo, useManageSubscriptionMutation } from '../queries';
import { USA, CANADA, modifiedStateList } from '../utils/utils';
import { USA_STATES, CANADA_STATES } from '../../../shared/constants';

interface HeaderProps {
    billingData: BillingData;
    setBillingData: React.Dispatch<React.SetStateAction<BillingData>>;
    errorState: ErrorState;
    setErrorState: React.Dispatch<React.SetStateAction<ErrorState>>;
    initialErrorState: ErrorState;
    modifyBillingDetailsLoading: boolean;
    onSubmitHandler: (event: React.MouseEvent<HTMLElement>) => void;
    setStateListOptions: React.Dispatch<React.SetStateAction<SelectFieldOptions>>;
}

export const Header = (props: HeaderProps) => {
    const { billingData, setBillingData, setErrorState, initialErrorState, modifyBillingDetailsLoading, onSubmitHandler, setStateListOptions } = props;

    const { data: subscriptionInfoData, isLoading: subscriptionInfoLoading } = useSubscriptionInfo();
    const { mutateAsync: manageSubscriptionData, isLoading: manageSubscriptionLoading } = useManageSubscriptionMutation();
    const { data: billingInfoData, isLoading: billingInfoLoading } = useBillingInfoQuery();

    const resetBillingDataHandler = () => {
        if (!billingInfoData?.data) {
            return;
        }

        setBillingData(() => ({
            ...billingData,
            companyName: billingInfoData.data.company_name,
            address1: billingInfoData.data.address1,
            address2: billingInfoData.data.address2,
            city: billingInfoData.data.city,
            state: billingInfoData.data.state,
            zipcode: billingInfoData.data.zipcode,
            country: billingInfoData.data.country,
            email: billingInfoData.data.email
        }));

        if (billingInfoData.data.country.toUpperCase() === USA) {
            setStateListOptions(modifiedStateList(USA_STATES));
        } else if (billingInfoData.data.country === CANADA) {
            setStateListOptions(modifiedStateList(CANADA_STATES));
        }
        setErrorState(() => initialErrorState);
    };

    const submitHandler = async (e: React.MouseEvent<HTMLElement>) => {
        onSubmitHandler(e);
    };

    const manageSubscriptionHandler = async () => {
        const resp = await manageSubscriptionData();
        window.location.replace(resp.data.url);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <H5>Plan and Payment</H5>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box ml={2}>
                    <Button color="secondary" variant="outlined" sx={{ margin: '50px' }} onClick={resetBillingDataHandler}>
                        Cancel
                    </Button>
                </Box>
                <Box ml={2}>
                    <Button onClick={submitHandler} isLoading={modifyBillingDetailsLoading}>
                        Save
                    </Button>
                </Box>
                {!subscriptionInfoLoading && !billingInfoLoading &&
                    (subscriptionInfoData?.data.my_plan.plan === 'Free' ||
                    subscriptionInfoData?.data.my_plan.plan === 'Diamond' ||
                    subscriptionInfoData?.data.my_plan.plan === 'Platinum' ||
                    subscriptionInfoData?.data.my_plan.plan === 'Gold' ||
                    subscriptionInfoData?.data.my_plan.plan === 'Silver') &&
                    billingInfoData?.data.country !== 'Canada' && (
                        <Box ml={2}>
                            <Button onClick={manageSubscriptionHandler} isLoading={manageSubscriptionLoading}>
                                Manage Subscription
                            </Button>
                        </Box>
                    )}
            </Box>
        </Box>
    );
};
