export const mockGetListOfMfgListData = [
    {
        id: 11,
        name: 'List 1',
        description: 'Sample description 1',
        company_group_man_id: [8906, 8846, 8845, 8842, 8843],
        visibility: 'public',
        company_id: null,
        permission: 'admin',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    },
    {
        id: 12,
        name: 'List 2',
        description: 'Sample description 2',
        company_group_man_id: [8906, 8846, 8845, 8842],
        visibility: 'private',
        company_id: null,
        permission: 'user',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    },
    {
        id: 13,
        name: 'List 3',
        description: null,
        company_group_man_id: null,
        visibility: 'protected',
        company_id: [13, 240, 287],
        permission: 'admin',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    },
    {
        id: 14,
        name: 'List 4',
        description: 'Sample description 4',
        company_group_man_id: null,
        visibility: 'public',
        company_id: null,
        permission: 'user',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    },
    {
        id: 15,
        name: 'List 5',
        description: null,
        company_group_man_id: null,
        visibility: 'private',
        company_id: null,
        permission: 'admin',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    },
    {
        id: 16,
        name: 'List 6',
        description: 'Sample description 6',
        company_group_man_id: null,
        visibility: 'protected',
        company_id: [460, 240, 287, 13],
        permission: 'user',
        create_at: '2023-08-10T12:57:12.676207Z',
        created_by: 'John Doe',
        updated_at: '2023-08-10T12:57:12.676207Z',
        updated_by: 'John Doe'
    }
];

export const getMfgListForId = (id: number) => {
    return mockGetListOfMfgListData.find((item) => item.id === id);
};
