import { BodyBig, BodyXS, Box } from '@parspec/pixel';

const ProductFormHeader: React.FC = () => {
    return (
        <Box>
            <BodyBig fontWeight="500">New Product Information</BodyBig>
            <BodyXS mt={1} limit={false}>
                Adjust type, model number, manufacturer, and description for this new product line item.
            </BodyXS>
        </Box>
    );
};

export default ProductFormHeader;
