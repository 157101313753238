import { axios } from 'src/app/Axios';
import {
    DocumentResponse,
    UpdateDocumentProps,
    DeleteDocumentProps,
    DownloadByVersionIdTypes,
    DocumentOutOfSyncResponse,
    StakeholderInfoResponse,
    UpdateStakeHolderRequest,
    DownloadedFileResponse
} from './apiTypes';

export const endPoints = {
    documents: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}/`,
    documentByVersionId: (bomId: string, submittalId: number, documentType: string) => `/bom/${bomId}/${documentType}/${submittalId}`,
    downloadAll: (bomId: string | undefined, documentType: string) => `/bom/${bomId}/${documentType}/download`,
    download: ({ bomId, id, type, documentType }: DownloadByVersionIdTypes) => `/bom/${bomId}/${documentType}/${id}/download?type=${type}`,
    documentOutOfSync: (bomId: string, documentType: string) => `/bom/${bomId}/${documentType}/out_of_sync`,
    mutateDocument: (bomId: string, versionId: number, documentType: string) => `/bom/${bomId}/${documentType}/${versionId}/`,
    stakeholdersInfo: (bomId: number, documentType: string) => `bom/${bomId}/${documentType}/stakeholder`,
    updateStakeholderInfo: (bomId: number, documentType: string, holderId: number) => `bom/${bomId}/${documentType}/stakeholder/${holderId}/`
};

export const getAllDocuments = async (bomId: string, documentType: string) => axios.get<DocumentResponse[]>(endPoints.documents(bomId, documentType));

export const getDocumentByVersionId = async (bomId: string, submittalId: number, documentType: string) => axios.get<DocumentResponse>(endPoints.documentByVersionId(bomId, submittalId, documentType));

export const deleteDocument = async ({ bomId, id, documentType }: DeleteDocumentProps) => axios.delete(endPoints.mutateDocument(bomId, id, documentType));

export const updateDocument = async ({ bomId, id, documentType, ...payload }: UpdateDocumentProps) => axios.patch<DocumentResponse>(endPoints.mutateDocument(bomId, id, documentType), payload);

export const downloadAll = async ({ bomId, documentType }: { bomId: string | undefined; documentType: string }) => axios.get<string>(endPoints.downloadAll(bomId, documentType));

export const downloadByVersionId = async (params: DownloadByVersionIdTypes) => axios.get<DownloadedFileResponse>(endPoints.download(params));

export const documentOutOfSync = async (bomId: string, documentType: string) => axios.get<DocumentOutOfSyncResponse>(endPoints.documentOutOfSync(bomId, documentType));

export const getStakeholdersInfo = async (bomId: number, documentType: string) => axios.get<StakeholderInfoResponse[]>(endPoints.stakeholdersInfo(bomId, documentType));

export const updateStakeholderDetail = async (input: UpdateStakeHolderRequest) => {
    const {
        bomId,
        documentType,
        stakeHolderInfo: { id, ...rest }
    } = input;
    return axios.patch(endPoints.updateStakeholderInfo(bomId, documentType, id), { ...rest });
};
