import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { downloadFlattenedPDF, getActivityLog, getFinalDocument, getSharedLinkDetails, publishToShareLinkToggle, updateShareLinkDetails, endPoints } from './api';
import { queryClient } from 'src/app/queryClient';
import { DownloadResponseType, GetLinkDetailsResponse } from './apiTypes';

// query key starts

const getSharedLinkDetailKey = (bomId: number, documentType: string) => [endPoints.sharedLinkDetails(bomId, documentType)];

const getActivityLogsKey = (bomId: number, documentType: string) => [endPoints.activityLogs(bomId, documentType)];

const getFinalDocumentKey = (bomId: number, versionId: number, documentType: string) => [endPoints.finalDocument(bomId, versionId, documentType)];

const getDownloadKey = (bomId: number, versionId: number, documentType: string) => [endPoints.download(bomId, versionId, documentType)];

// queries key ends

// get query starts
export const useGetFinalSubmittal = (bom_Id: number, version_Id: number, documentType: string) =>
    useQuery(getFinalDocumentKey(bom_Id, version_Id, documentType), () => getFinalDocument(bom_Id, version_Id, documentType));

export const useGetSharedLinkDetails = (id: number, documentType: string, options?: Omit<UseQueryOptions<AxiosResponse<GetLinkDetailsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getSharedLinkDetailKey(id, documentType), () => getSharedLinkDetails(id, documentType), options);

export const useDownloadFlattenedPDF = (bomId: number, versionId: number, documentType: string, options: Omit<UseQueryOptions<AxiosResponse<DownloadResponseType>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getDownloadKey(bomId, versionId, documentType), () => downloadFlattenedPDF(bomId, versionId, documentType), options);

export const useGetActivityLog = (bom_Id: number, documentType: string) => useQuery(getActivityLogsKey(bom_Id, documentType), () => getActivityLog(bom_Id, documentType));

// get queries ends

// mutations starts

export const useUpdateShareLinkDetails = () => {
    return useMutation(updateShareLinkDetails, {
        onSuccess: (_response, variables) => {
            queryClient.invalidateQueries(getSharedLinkDetailKey(Number(variables.bomId), variables.documentType));
        }
    });
};

export const usePublishToShareLinkToggle = () => useMutation(publishToShareLinkToggle);

// mutation ends
