import { BodySmall, ModalHeader, Modal, Box } from '@parspec/pixel';
import { FC } from 'react';

interface ErrorDialogProps {
    open: boolean;
    handleDialog: () => void;
    title: string;
    text: string;
    helperText?: string;
}

const ErrorDialog: FC<ErrorDialogProps> = (props) => {
    const { open, handleDialog, title, text, helperText } = props;

    return (
        <Modal open={open} header={<ModalHeader title={title} onClose={handleDialog} />}>
            <Box width="450px" mt={4}>
                <BodySmall limit={false} color="text.light">
                    {text}
                </BodySmall>
                {helperText && (
                    <BodySmall limit={false} mt={3} color="text.light">
                        {helperText}
                    </BodySmall>
                )}
            </Box>
        </Modal>
    );
};

export default ErrorDialog;
