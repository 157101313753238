import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { createProject, getAllLocations, getAllCompanyDetails, projectModalEndPoints } from './apis';
import { invalidateProjectInfoInDashboard } from '../../../Dashboard/queries';
import { queryClient } from 'src/app/queryClient';
import { AllCompanyDetailsResponse, LocationResponse } from './apiTypes';

const USER_LOCATIONS_DEFAULT_KEY = 'user-locations';

const getAllLocationDataKey = (userId: number) => [USER_LOCATIONS_DEFAULT_KEY, projectModalEndPoints.allLocations(userId), userId];

const getAllCompanyDetailsKey = () => [projectModalEndPoints.allCompanyDetails];

export const useGetAllLocationsListQuery = (userId: number, options?: Omit<UseQueryOptions<AxiosResponse<LocationResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getAllLocationDataKey(userId), () => getAllLocations(userId), options);

export const useGetAllCompanyDetailsQuery = (options?: Omit<UseQueryOptions<AxiosResponse<AllCompanyDetailsResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getAllCompanyDetailsKey(), () => getAllCompanyDetails(), options);

export const invalidateGetAllCompanyDetailsKey = () => {
    queryClient.invalidateQueries(getAllCompanyDetailsKey());
};
export const invalidateGetAllLocationsListQuery = (userID: number) => {
    queryClient.invalidateQueries(getAllLocationDataKey(userID));
};

export const invalidateGetAllUserLocations = () => {
    queryClient.invalidateQueries([USER_LOCATIONS_DEFAULT_KEY]);
};

// mutations start

export const useCreateProjectDataMutation = () =>
    useMutation(createProject, {
        onSuccess: () => {
            invalidateProjectInfoInDashboard();
            invalidateGetAllCompanyDetailsKey();
        }
    });

// mutation ends
