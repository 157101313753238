import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    BodySmall,
    H4,
    H5,
    H6,
    Grid,
    Skeleton,
    InfoIcon,
    BodyXS,
    Link,
    OpenInNewIcon,
    Switch,
    SignalCellular0BarIcon,
    SignalCellular1BarIcon,
    SignalCellular2BarIcon,
    SignalCellular3BarIcon,
    SignalCellular4BarIcon
} from '@parspec/pixel';

import { useGetUserProfileInfoQuery } from '../../MyProfile/queries';
import { useSubscriptionInfo, useAutoUpgradeSubscriptionMutation } from '../queries';
import { getMMDDYYYY, capitalizeFirstLetter } from '../utils/utils';
import { LAUNCH_PRODUCT_FINDER_FOR_ALL } from '../../../shared/constants';
import { SnackbarData } from '../PlanAndPayment.types';

interface SubscriptionInfoProps {
    snackbarData: SnackbarData;
    setSnackbarData: React.Dispatch<React.SetStateAction<SnackbarData>>;
}

const DIAMOND_PLAN_COLOR = '#7CD4FD';

export const SubscriptionInfo = (props: SubscriptionInfoProps) => {
    const { snackbarData, setSnackbarData } = props;

    const { data: userProfile, isLoading: isUserProfileLoading } = useGetUserProfileInfoQuery();

    const { data: subscriptionInfoData, isLoading: subscriptionInfoLoading } = useSubscriptionInfo();
    const { mutateAsync: autoUpgradeSubscription } = useAutoUpgradeSubscriptionMutation();

    const [isAutoUpgradeChecked, setIsAutoUpgradeChecked] = useState<boolean>(false);

    const navigate = useNavigate();

    const autoUpgradePlanHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const checkedStatus = e.target.checked;
        setIsAutoUpgradeChecked(() => checkedStatus);

        try {
            const response = await autoUpgradeSubscription({
                auto_upgrade_to_next_tier: checkedStatus
            });

            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: response?.data.message ? response?.data.message : 'Success'
            });
        } catch {
            // Revert changes if api fails
            setIsAutoUpgradeChecked(() => !checkedStatus);
        }
    };

    useEffect(() => {
        if (!subscriptionInfoData?.data.auto_upgrade_to_next_tier) {
            return;
        }

        setIsAutoUpgradeChecked(() => subscriptionInfoData?.data.auto_upgrade_to_next_tier);
    }, [subscriptionInfoData?.data.auto_upgrade_to_next_tier]);

    return (
        <Box>
            <H5 mb={4}>Subscription</H5>
            {
                <Grid container spacing={6}>
                    <Grid item xs={5}>
                        {!subscriptionInfoLoading ? (
                            <Box height={'100%'} border={'1px solid'} borderColor={'text.secondary'} display={'flex'} flexDirection={'column'}>
                                {subscriptionInfoData?.data.active && (
                                    <>
                                        {subscriptionInfoData?.data.my_plan.plan === 'Diamond' && (
                                            <Box px={4} py={4} bgcolor={DIAMOND_PLAN_COLOR} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <Box mr={2} color={'tertiary.contrastText'}>
                                                    <SignalCellular4BarIcon fontSize={'medium'} />
                                                </Box>
                                                <H6 textAlign={'center'} color={'tertiary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Platinum' && (
                                            <Box px={4} py={4} bgcolor={'primary.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <Box mr={2} color={'primary.contrastText'}>
                                                    <SignalCellular3BarIcon fontSize={'medium'} />
                                                </Box>
                                                <H6 textAlign={'center'} color={'primary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Gold' && (
                                            <Box px={4} py={4} bgcolor={'tertiary.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <Box mr={2} color={'tertiary.contrastText'}>
                                                    <SignalCellular2BarIcon fontSize={'medium'} />
                                                </Box>
                                                <H6 textAlign={'center'} color={'tertiary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Silver' && (
                                            <Box px={4} py={4} bgcolor={'neutral.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <Box mr={2} color={'neutral.contrastText'}>
                                                    <SignalCellular1BarIcon fontSize={'medium'} />
                                                </Box>
                                                <H6 textAlign={'center'} color={'neutral.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Free' && (
                                            <Box px={4} py={4} bgcolor={'success.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <Box mr={2} color={'success.contrastText'}>
                                                    <SignalCellular0BarIcon fontSize={'medium'} />
                                                </Box>
                                                <H6 textAlign={'center'} color={'success.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Enterprise_limited' && (
                                            <Box px={4} py={4} bgcolor={'secondary.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <H6 textAlign={'center'} color={'secondary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    Enterprise
                                                </H6>
                                            </Box>
                                        )}
                                        {subscriptionInfoData?.data.my_plan.plan === 'Enterprise' && (
                                            <Box px={4} py={4} bgcolor={'secondary.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                                <H6 textAlign={'center'} color={'secondary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                    {subscriptionInfoData?.data.my_plan.plan}
                                                </H6>
                                            </Box>
                                        )}
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} flexGrow={1}>
                                            {subscriptionInfoData?.data.my_plan.plan === 'Enterprise' && <H5 limit={false}>Unlimited</H5>}
                                            {subscriptionInfoData?.data.my_plan.plan === 'Enterprise_limited' && <H4 limit={false}>{subscriptionInfoData?.data.my_plan.submittals}</H4>}
                                            {subscriptionInfoData?.data.my_plan.plan === 'Free' && <BodySmall limit={false}>Unlimited Credits</BodySmall>}
                                            {subscriptionInfoData?.data.my_plan.plan !== 'Enterprise' &&
                                                subscriptionInfoData?.data.my_plan.plan !== 'Enterprise_limited' &&
                                                subscriptionInfoData?.data.my_plan.plan !== 'Free' && <H4 limit={false}>{subscriptionInfoData?.data.my_plan.submittals}</H4>}
                                            <BodySmall limit={false}>------------------</BodySmall>
                                            {subscriptionInfoData?.data.my_plan.plan === 'Free' ? (
                                                <BodySmall limit={false}>14 days</BodySmall>
                                            ) : (
                                                <BodySmall limit={false} textAlign="center">
                                                    {subscriptionInfoData?.data.my_plan.submittals_frequency}
                                                </BodySmall>
                                            )}
                                        </Box>
                                    </>
                                )}
                                {!subscriptionInfoData?.data.active && (
                                    <>
                                        <Box px={4} py={4} bgcolor={'error.main'} minHeight={'56px'} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                                            <H6 textAlign={'center'} color={'primary.contrastText'} sx={{ lineHeight: 1 }} limit={false}>
                                                No Active Plan
                                            </H6>
                                        </Box>

                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} flexGrow={1}>
                                            <H4 limit={false}>0</H4>
                                            <BodySmall limit={false}>------------------</BodySmall>

                                            <BodySmall limit={false}>Credits</BodySmall>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        ) : (
                            <Skeleton variant={'rectangular'} height={'334px'} />
                        )}
                    </Grid>
                    <Grid item xs={7}>
                        <Box mb={6}>
                            {!subscriptionInfoLoading ? (
                                <>
                                    {!subscriptionInfoData?.data.contract_terms ? (
                                        <BodySmall limit={false} color={'text.secondary'} mb={2}>
                                            Billing Frequency
                                        </BodySmall>
                                    ) : (
                                        <BodySmall limit={false} color={'text.secondary'} mb={2}>
                                            Contract Terms
                                        </BodySmall>
                                    )}
                                </>
                            ) : (
                                <Box mb={6}>
                                    <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                                </Box>
                            )}
                            {!subscriptionInfoLoading ? (
                                <>
                                    {subscriptionInfoData?.data.active ? (
                                        <>
                                            {!subscriptionInfoData?.data.canceled ? (
                                                <>
                                                    {subscriptionInfoData?.data.my_plan.plan === 'Free' ? (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'success.main'}>
                                                                Free trial
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - No active plan selected
                                                            </BodySmall>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {subscriptionInfoData?.data.contract_terms ? (
                                                                <Box>
                                                                    <BodySmall limit={false}>{capitalizeFirstLetter(subscriptionInfoData?.data.contract_terms)}</BodySmall>
                                                                    {subscriptionInfoData?.data.contract_renewal_date ? (
                                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                                            <BodySmall limit={false}>Renews on</BodySmall>
                                                                            <BodySmall limit={false} ml={1}>
                                                                                {getMMDDYYYY(subscriptionInfoData?.data.contract_renewal_date)}
                                                                            </BodySmall>
                                                                        </Box>
                                                                    ) : (
                                                                        <BodySmall limit={false} ml={1}>
                                                                            -
                                                                        </BodySmall>
                                                                    )}
                                                                </Box>
                                                            ) : (
                                                                <BodySmall limit={false}>{subscriptionInfoData?.data.billing_frequency}</BodySmall>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {subscriptionInfoData?.data.my_plan.plan === 'Free' ? (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'success.main'}>
                                                                Free trial
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - No active plan selected
                                                            </BodySmall>
                                                        </Box>
                                                    ) : (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'error.main'}>
                                                                Cancelled
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - No active plan selected
                                                            </BodySmall>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Box display={'flex'} alignItems={'flex-end'}>
                                                <BodySmall limit={false} color={'error.main'}>
                                                    Cancelled
                                                </BodySmall>
                                                <BodySmall limit={false} ml={1}>
                                                    - No active plan selected
                                                </BodySmall>
                                            </Box>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                            )}
                        </Box>
                        <Box mb={6}>
                            {!subscriptionInfoLoading ? (
                                <>
                                    {!subscriptionInfoData?.data.payment_terms ? (
                                        <BodySmall limit={false} color={'text.secondary'} mb={2}>
                                            Next Payment Date
                                        </BodySmall>
                                    ) : (
                                        <BodySmall limit={false} color={'text.secondary'} mb={2}>
                                            Payment Terms
                                        </BodySmall>
                                    )}
                                </>
                            ) : (
                                <Box mb={6}>
                                    <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                                </Box>
                            )}

                            {!subscriptionInfoLoading ? (
                                <>
                                    {subscriptionInfoData?.data.active ? (
                                        <>
                                            {!subscriptionInfoData?.data.canceled ? (
                                                <>
                                                    {subscriptionInfoData?.data.my_plan.plan === 'Free' ? (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'success.main'}>
                                                                Free trial expires
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - {getMMDDYYYY(subscriptionInfoData?.data.next_billing_data)}
                                                            </BodySmall>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {subscriptionInfoData?.data.payment_terms ? (
                                                                <Box>
                                                                    <BodySmall limit={false}>{capitalizeFirstLetter(subscriptionInfoData?.data.payment_terms)}</BodySmall>
                                                                    {subscriptionInfoData?.data.next_billing_data ? (
                                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                                            <BodySmall limit={false}>Next payment due on</BodySmall>
                                                                            <BodySmall limit={false} ml={1}>
                                                                                {getMMDDYYYY(subscriptionInfoData?.data.next_billing_data)}
                                                                            </BodySmall>
                                                                        </Box>
                                                                    ) : (
                                                                        <BodySmall limit={false} ml={1}>
                                                                            -
                                                                        </BodySmall>
                                                                    )}
                                                                </Box>
                                                            ) : (
                                                                <BodySmall limit={false}>{getMMDDYYYY(subscriptionInfoData?.data.next_billing_data)}</BodySmall>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {subscriptionInfoData?.data.my_plan.plan === 'Free' ? (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'success.main'}>
                                                                Free trial expires
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - {getMMDDYYYY(subscriptionInfoData?.data.next_billing_data)}
                                                            </BodySmall>
                                                        </Box>
                                                    ) : (
                                                        <Box display={'flex'} alignItems={'flex-end'}>
                                                            <BodySmall limit={false} color={'error.main'}>
                                                                Plan expires
                                                            </BodySmall>
                                                            <BodySmall limit={false} ml={1}>
                                                                - {getMMDDYYYY(subscriptionInfoData?.data.next_billing_data)}
                                                            </BodySmall>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Box display={'flex'} alignItems={'flex-end'}>
                                                <BodySmall limit={false} color={'error.main'}>
                                                    Cancelled
                                                </BodySmall>
                                                <BodySmall limit={false} ml={1}>
                                                    - No active plan selected
                                                </BodySmall>
                                            </Box>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                            )}
                        </Box>
                        <Box mb={6}>
                            <BodySmall limit={false} color={'text.secondary'} mb={2}>
                                Credits Remaining in this Billing Period
                            </BodySmall>
                            {!subscriptionInfoLoading ? (
                                <>
                                    {(subscriptionInfoData?.data.my_plan.plan === 'Enterprise' || subscriptionInfoData?.data.my_plan.plan === 'Free') && (
                                        <BodySmall limit={false}>Unlimited Credits</BodySmall>
                                    )}

                                    {(subscriptionInfoData?.data.my_plan.plan === 'Enterprise_limited' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Diamond' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Platinum' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Gold' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Silver') && (
                                        <>
                                            {subscriptionInfoData?.data.submittal_credit_left === 0 ||
                                            subscriptionInfoData?.data.submittal_credit_left === 1 ||
                                            subscriptionInfoData?.data.submittal_credit_left === -1 ? (
                                                <BodySmall limit={false}>{subscriptionInfoData?.data.submittal_credit_left} Credit</BodySmall>
                                            ) : (
                                                <BodySmall limit={false}>{subscriptionInfoData?.data.submittal_credit_left} Credits</BodySmall>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                            )}

                            {subscriptionInfoData?.data.active && !subscriptionInfoData?.data.canceled && (
                                <>
                                    {(subscriptionInfoData?.data.my_plan.plan === 'Diamond' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Platinum' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Gold' ||
                                        subscriptionInfoData?.data.my_plan.plan === 'Silver') &&
                                        subscriptionInfoData?.data.submittal_credit_left <= 0 && (
                                            <Box my={2} display={'flex'} alignItems={'flex-end'}>
                                                <Box color={'error.main'} display={'flex'} alignItems={'flex-end'}>
                                                    <BodyXS color={'error.main'} limit={false}>
                                                        No credits left, please Upgrade your Subscription <br />
                                                        if credits are needed prior to your subscription renewal.
                                                    </BodyXS>
                                                </Box>

                                                <Box ml={2} display={'flex'} color={'primary.main'} alignItems={'flex-end'}>
                                                    <Link href="https://knowledgebase.parspec.io/knowledge/parspec-pricing-guide" target="_blank" rel="noopener">
                                                        <BodySmall mr={1} color={'primary.main'}>
                                                            Learn More
                                                        </BodySmall>
                                                    </Link>
                                                    <InfoIcon fontSize={'small'} />
                                                </Box>
                                            </Box>
                                        )}
                                </>
                            )}

                            {subscriptionInfoData?.data.active && !subscriptionInfoData?.data.canceled && (
                                <>
                                    {subscriptionInfoData?.data.my_plan.plan === 'Enterprise_limited' && subscriptionInfoData?.data.submittal_credit_left === 0 && (
                                        <Box my={2} display={'flex'} alignItems={'flex-end'} color={'error.main'}>
                                            <BodyXS color={'error.main'}>No credits left,</BodyXS>
                                            <Link href="mailto:support@parspec.io" target="_blank" rel="noopener">
                                                <BodyXS ml={1} color={'error.main'} fontWeight={'600'}>
                                                    Contact Sales
                                                </BodyXS>
                                            </Link>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                        {LAUNCH_PRODUCT_FINDER_FOR_ALL && (
                            <Box mb={6}>
                                <Box display={'flex'} alignItems={'flex-end'} mb={2}>
                                    <BodySmall limit={false} color={'text.secondary'}>
                                        Product Finder Seats
                                    </BodySmall>
                                </Box>

                                {!subscriptionInfoLoading && subscriptionInfoData?.data ? (
                                    <Box color={'primary.main'}>
                                        {subscriptionInfoData.data.product_finder_seats_in_use === 0 && subscriptionInfoData.data.product_finder_seats_purchased === 0 ? (
                                            <BodySmall limit={false}>No seats purchased</BodySmall>
                                        ) : (
                                            <BodySmall limit={false}>
                                                {subscriptionInfoData.data.product_finder_seats_in_use} of {subscriptionInfoData.data.product_finder_seats_purchased} seats allocated
                                            </BodySmall>
                                        )}
                                        <BodySmall color={'primary.main'} onClick={() => navigate('/v2/settings/user-management?tab=active')} sx={{ cursor: 'pointer' }}>
                                            Manage Product Finder Access
                                        </BodySmall>
                                    </Box>
                                ) : (
                                    <Skeleton variant={'rectangular'} height={'16px'} width={'50%'} />
                                )}
                            </Box>
                        )}

                        <Box mb={6}>
                            <Box display={'flex'} alignItems={'flex-end'} mb={2}>
                                <BodySmall limit={false} color={'text.secondary'}>
                                    Auto Upgrade Subscription
                                </BodySmall>
                            </Box>

                            <BodySmall limit={false}>
                                Upon using all your credits, your subscription will automatically <br />
                                be upgraded to the next tier and a new billing cycle will start.
                            </BodySmall>

                            {!isUserProfileLoading && !subscriptionInfoLoading ? (
                                <Box ml={'4px'}>
                                    {subscriptionInfoData?.data.active ? (
                                        <>
                                            {!subscriptionInfoData?.data.canceled ? (
                                                <>
                                                    {subscriptionInfoData?.data.my_plan.plan !== 'Free' && (
                                                        <Switch
                                                            color="primary"
                                                            label={
                                                                <BodySmall limit={false} color={'text.secondary'}>
                                                                    Enable auto upgrade
                                                                </BodySmall>
                                                            }
                                                            onChange={(e: any) => autoUpgradePlanHandler(e)}
                                                            checked={isAutoUpgradeChecked}
                                                            disabled={
                                                                subscriptionInfoData?.data.my_plan.plan === 'Enterprise' ||
                                                                subscriptionInfoData?.data.my_plan.plan === 'Enterprise_limited' ||
                                                                userProfile?.data.role !== 'admin'
                                                            }
                                                        />
                                                    )}

                                                    {subscriptionInfoData?.data.my_plan.plan === 'Free' && (
                                                        <Switch color="primary" label={'Enable auto upgrade'} onChange={(e: any) => autoUpgradePlanHandler(e)} checked={false} disabled={true} />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Switch color="primary" label={'Enable auto upgrade'} onChange={(e: any) => autoUpgradePlanHandler(e)} checked={false} disabled={true} />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Switch color="primary" label={'Enable auto upgrade'} onChange={(e: any) => autoUpgradePlanHandler(e)} checked={false} disabled={true} />
                                        </>
                                    )}
                                </Box>
                            ) : (
                                <Skeleton variant={'rectangular'} height={'16px'} width={'25%'} />
                            )}
                        </Box>

                        <Box mb={1}>
                            <Box display={'flex'} alignItems={'center'}>
                                <BodySmall limit={false} mr={1}>
                                    Read our
                                </BodySmall>
                                <Link
                                    href="https://knowledgebase.parspec.io/knowledge/terms-of-service"
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        underline: 'none',
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Box color={'primary.main'} display={'flex'} alignItems={'flex-end'}>
                                        <BodySmall limit={false} color={'primary.main'} mr={1}>
                                            Terms of Service
                                        </BodySmall>
                                        <OpenInNewIcon fontSize={'small'} />
                                    </Box>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }

            {subscriptionInfoData?.data.change_upcoming && (
                <Box bgcolor={'primary.light'} display={'flex'} alignItems={'center'} mt={'24px'} p={'10px'}>
                    <Box color={'primary.main'}>
                        <InfoIcon fontSize={'small'} />
                    </Box>
                    <Box ml={2}>
                        <BodySmall limit={false} color={'primary.main'}>
                            You enrolled in a new plan! This change will take effect as of the next billing date.
                        </BodySmall>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
