import { useMemo } from 'react';

import { useProjectOMOutOfSyncQuery, useProjectSubmitalOutOfSyncQuery, useProjectQuoteOutOfSyncQuery } from '../../queries';
import { QUOTE, SUBMITTAL_DOC, docType, OM_DOC } from '../../../shared/constants';
import { getSyncCacheForProject } from '../utils';

function useSubmittalSync(projectId: string) {
    const { isLoading: isSubmittalSyncLoading, data: submittalSyncData } = useProjectSubmitalOutOfSyncQuery(Number(projectId)!, {
        enabled: Boolean(projectId),
        staleTime: 0
    });

    const submittalOutOfSyncCache = useMemo(() => {
        if (submittalSyncData?.data) return getSyncCacheForProject(submittalSyncData.data);
        return null;
    }, [submittalSyncData]);

    return {
        outOfSyncCache: submittalOutOfSyncCache,
        isSyncLoading: isSubmittalSyncLoading
    };
}

function useOMSync(projectId: string) {
    const { isLoading: isOMSyncLoading, data: omSyncData } = useProjectOMOutOfSyncQuery(Number(projectId)!, {
        enabled: Boolean(projectId),
        staleTime: 0
    });

    const omOutOfSyncCache = useMemo(() => {
        if (omSyncData?.data) return getSyncCacheForProject(omSyncData.data);
        return null;
    }, [omSyncData]);

    return {
        outOfSyncCache: omOutOfSyncCache,
        isSyncLoading: isOMSyncLoading
    };
}

function useQuoteSync(projectId: string) {
    const { isLoading: isQuoteSyncLoading, data: quoteSyncData } = useProjectQuoteOutOfSyncQuery(Number(projectId)!, {
        enabled: Boolean(projectId),
        staleTime: 0
    });

    const quoteOutOfSyncCache = useMemo(() => {
        if (quoteSyncData?.data) return getSyncCacheForProject(quoteSyncData.data);
        return null;
    }, [quoteSyncData]);

    return {
        outOfSyncCache: quoteOutOfSyncCache,
        isSyncLoading: isQuoteSyncLoading
    };
}

export function useBOMSyncForProject(projectId: string, docType: docType) {
    const submittalSyncData = useSubmittalSync(docType === SUBMITTAL_DOC ? projectId : '');
    const quoteSyncData = useQuoteSync(docType === QUOTE ? projectId : '');
    const omSyncData = useOMSync(docType === OM_DOC ? projectId : '');

    if (docType === SUBMITTAL_DOC) {
        return submittalSyncData;
    } else if (docType === QUOTE) {
        return quoteSyncData;
    }
    return omSyncData;
}
