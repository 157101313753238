import { FC, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Paper } from '@parspec/pixel';

import { useEditProjectDetails } from '../../../Project/queries';
import EditProjectDetail from '../../../shared/CreateAndEditProjectModal/EditProjectDetail';
import { convertToCents, Errors, IProjectDetailState } from '../../../shared/utils/utils';
import ProjectName from './ProjectName';

interface ProjectDetailProps {
    projectId: number;
}

const ProjectDetail: FC<ProjectDetailProps> = ({ projectId }) => {
    const navigate = useNavigate();
    const projectStateRef = useRef<IProjectDetailState>();
    const setProjectStateRef = useCallback((data: IProjectDetailState) => {
        projectStateRef.current = data;
    }, []);
    const [formErrors, setFormErrors] = useState<Errors>({ projectNameError: '' });

    const { mutateAsync: editProjectDetails, isLoading: isUpdatingProjectDetails } = useEditProjectDetails(true);

    const handleFormErrorsAction = useCallback((error: Errors) => {
        setFormErrors(error);
    }, []);

    async function handleProjectName(value: string) {
        if (projectStateRef?.current) {
            projectStateRef.current.name = value;
            await handleOnBlur();
        }
    }

    async function handleOnBlur() {
        if (projectStateRef?.current) {
            if (
                (projectStateRef.current.submittal_due_date !== null &&
                    projectStateRef.current.submittal_due_date &&
                    !projectStateRef.current.submittal_due_date.isValid() &&
                    projectStateRef.current.submittal_due_date.year() > 2099) ||
                (projectStateRef.current.o_m_due_date !== null &&
                    projectStateRef.current.o_m_due_date &&
                    !projectStateRef.current.o_m_due_date.isValid() &&
                    projectStateRef.current.o_m_due_date.year() > 2099)
            ) {
                return;
            }

            const formData = new FormData();
            formData.append('name', projectStateRef.current.name);
            formData.append('company', JSON.stringify(projectStateRef.current.company));
            formData.append('stage', projectStateRef.current.stage);
            formData.append(
                'estimated_project_cost',
                projectStateRef.current.estimated_project_cost === null ? 'null' : JSON.stringify(convertToCents(projectStateRef.current.estimated_project_cost))
            );
            formData.append('quote_owner', JSON.stringify(projectStateRef.current.quote_owner));
            formData.append('quote_due_date', projectStateRef.current.quote_due_date ? projectStateRef.current.quote_due_date.toISOString() : 'null');
            formData.append('submittal_owner', JSON.stringify(projectStateRef.current.submittal_owner));
            formData.append('submittal_due_date', projectStateRef.current.submittal_due_date ? projectStateRef.current.submittal_due_date.toISOString() : 'null');
            formData.append('o_m_owner', JSON.stringify(projectStateRef.current.o_m_owner));
            formData.append('o_m_due_date', projectStateRef.current.o_m_due_date ? projectStateRef.current.o_m_due_date.toISOString() : 'null');
            formData.append('address1', projectStateRef.current.address1);
            formData.append('address2', projectStateRef.current.address2);
            formData.append('country', projectStateRef.current.country);
            formData.append('city', projectStateRef.current.city);
            formData.append('state', projectStateRef.current.state);
            formData.append('zip', projectStateRef.current.zip);
            formData.append('building_type', projectStateRef.current.building_type);
            formData.append('construction_scope', projectStateRef.current.construction_scope);
            formData.append('priority', projectStateRef.current.priority);
            if (projectStateRef.current.size) {
                formData.append('size', projectStateRef.current.size);
            } else {
                formData.append('size', '');
            }
            // formData.append('internal_notes', internalNotes ? internalNotes : '');
            // @ts-ignore:next-line
            if (!projectStateRef.current.logo?.filepath) {
                // @ts-ignore:next-line
                formData.append('logo', projectStateRef.current.logo);
            }

            if (projectId) {
                await editProjectDetails({ project_id: String(projectId), data: formData });
            }
        }
    }

    const onNavigateToProjectDetail = () => {
        navigate(`/v2/project/${projectId}`);
    };

    return (
        <Paper variant="outlined" sx={{ overflow: 'hidden', borderRadius: 2 }}>
            <ProjectName projectId={projectId} saveProjectName={handleProjectName} formErrors={formErrors} onError={handleFormErrorsAction} />
            <Box pt={4} overflow="scroll" height={'calc(100% - 100px)'} mb={2}>
                <EditProjectDetail
                    editMode={true}
                    onError={handleFormErrorsAction}
                    formErrors={formErrors}
                    setProjectStateRef={setProjectStateRef}
                    onBlurAction={handleOnBlur}
                    isUpdatingProjectDetails={isUpdatingProjectDetails}
                    showOnlyProjectInfo
                    selectedProjectId={projectId}
                />
            </Box>
            <Box px={6} height="55px">
                <Button fullWidth color="tertiary" variant="contained" onClick={onNavigateToProjectDetail}>
                    View Project
                </Button>
            </Box>
        </Paper>
    );
};

export default ProjectDetail;
