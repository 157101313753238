import { useContext, useEffect, useState } from 'react';

import { ICellRendererParams, IRowNode } from 'ag-grid-community';

import { Box, Select, SelectChangeEvent } from '@parspec/pixel';

import { LEAD_TIME_UNITS, IOptions } from './constants';
import { MainProductDataType } from '../mainProductTable';
import { OptionDataType } from '../optionsTable';
import { TableContext } from '../../shared/context';
import { LotPriceRow } from '../lotPriceModal/LotPricingModal';

export const options = [
    { label: '-', value: '-' },
    { label: LEAD_TIME_UNITS.DAYS, value: LEAD_TIME_UNITS.DAYS.toLowerCase() },
    { label: LEAD_TIME_UNITS.WORKING_DAYS, value: LEAD_TIME_UNITS.WORKING_DAYS.toLowerCase() },
    { label: LEAD_TIME_UNITS.WEEKS, value: LEAD_TIME_UNITS.WEEKS.toLowerCase() },
    { label: LEAD_TIME_UNITS.MONTHS, value: LEAD_TIME_UNITS.MONTHS.toLowerCase() },
    { label: LEAD_TIME_UNITS.YEARS, value: LEAD_TIME_UNITS.YEARS.toLowerCase() },
    { label: LEAD_TIME_UNITS.IN_STOCK, value: LEAD_TIME_UNITS.IN_STOCK.toLowerCase() }
];

export function Template({
    handleOnChange,
    leadTimeUnit,
    options,
    fieldName = '',
    showLabel = false
}: {
    handleOnChange: (e: SelectChangeEvent<unknown>) => void;
    leadTimeUnit: string;
    options: IOptions[];
    fieldName?: string;
    showLabel?: boolean;
}) {
    return (
        <Box>
            <Select
                name={fieldName}
                size="xs"
                label={showLabel ? 'Lead Time Unit' : ''}
                onChange={handleOnChange}
                optionLabelKeyname="label"
                optionValueKeyname="value"
                options={options}
                value={leadTimeUnit}
            />
        </Box>
    );
}

export function PricingLeadTimeUnitTemplate(props: ICellRendererParams<any, any, any>) {
    const { data, value } = props;
    const { handleLeadTimeUnit } = useContext(TableContext);
    const [dropdownVal, setDropdownVal] = useState(options[0].value);

    useEffect(() => {
        if (dropdownVal !== value) {
            setDropdownVal(value);
        }
    }, [value]);

    async function handleOnChange(e: SelectChangeEvent<unknown>) {
        const value = e.target.value as string;
        setDropdownVal(value);
        let payload: Partial<MainProductDataType | OptionDataType> = { id: data.id, leadTimeUnit: value, leadTimeValue: props.data.leadTimeValue };

        if (value === '-') {
            payload = { ...payload, leadTimeUnit: null, leadTimeValue: null };
        }

        if (value === LEAD_TIME_UNITS.IN_STOCK.toLowerCase()) {
            payload = { ...payload, leadTimeValue: null };
        }

        handleLeadTimeUnit?.(props?.node, payload);
    }

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    return <Template handleOnChange={handleOnChange} options={options} leadTimeUnit={dropdownVal || '-'} />;
}

export function LotLeadTimeUnitTemplate(props: ICellRendererParams<any, any, any>, cb: (rowNode: IRowNode, payload: Partial<LotPriceRow>) => void) {
    const { data, value } = props;
    const [dropdownVal, setDropdownVal] = useState(options[0].value);

    useEffect(() => {
        if (dropdownVal !== value) {
            setDropdownVal(value);
        }
    }, [value]);

    async function handleOnChange(e: SelectChangeEvent<unknown>) {
        const value = e.target.value as string;
        setDropdownVal(value);
        let payload: Partial<MainProductDataType | OptionDataType> = { id: data.id, leadTimeUnit: value, leadTimeValue: props.data.leadTimeValue };

        if (value === '-') {
            payload = { ...payload, leadTimeUnit: null, leadTimeValue: null };
        }

        if (value === LEAD_TIME_UNITS.IN_STOCK.toLowerCase()) {
            payload = { ...payload, leadTimeValue: null };
        }

        const updatedRow = { ...data, ...payload };
        cb(props?.node, updatedRow);
    }

    if (props.node.rowPinned === 'bottom') {
        return null;
    }

    return <Template handleOnChange={handleOnChange} options={options} leadTimeUnit={dropdownVal || '-'} />;
}
