export enum PERMISSION {
    ADMIN = 'admin',
    USER = 'user'
}

export enum VISIBLITY {
    PUBLIC = 'public',
    PRIVATE = 'private',
    PROTECTED = 'protected'
}

export const ACESS_RADIO_OPTIONS = [
    {
        label: 'Public ',
        value: VISIBLITY.PUBLIC
    },
    {
        label: 'Private ',
        value: VISIBLITY.PRIVATE
    },
    {
        label: 'Specific Branch Locations ',
        value: VISIBLITY.PROTECTED
    }
];

export const EDIT_ACCESS_OPTIONS = [
    {
        label: 'Admins only',
        value: PERMISSION.ADMIN
    },
    {
        label: 'All users',
        value: PERMISSION.USER
    }
];
