import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, H5 } from '@parspec/pixel';

import ConfirmColumn from '../../shared/ConfirmColumn';
import MatchingColumnHeader from '../../shared/MatchingColumnHeader';
import { useGetContentDetailQuery } from '../queries';
import { useSendXmlAnnotationMutation, useSendPackageXmlAnnotationMutation } from '../queries';
import { ImportMoreProducts } from '../../ImportMoreProducts';
import { UploadedScheduleResponse, UploadedSubmittalPackageResponse } from '../../ImportMoreProducts/queries/apiTypes';
import { SubmittalBomType } from '../../shared/constants';
import CancelButton from '../../../shared/CancelButton';
import { isRecognisedFileFormat } from '../../shared/utils';
import { useUserAccountPreferencesQuery } from '../../../Settings/MyProfile/queries';
import { useDatasheetAutoSearchMutation, useOmAutoSearchMutation } from '../../queries';
import { RECOGNISED_EXCEL_FOMRATS, SCHEDULE_FILE_TYPES } from '../../shared/constants';

const Header = (props: any) => {
    const { bomId, projectId } = useParams();
    const navigate = useNavigate();
    const [refreshDataSheet, setOpenRefreshDataSheet] = useState<boolean>(false);
    const [matchingColumn, setMatchingColumn] = useState<boolean>(false);
    const { data, refetch } = useGetContentDetailQuery(Number(bomId));
    const [xmlData, setXmlData] = useState<Record<string, any>>([]);
    const { mutateAsync: sendXmlAnnotation } = useSendXmlAnnotationMutation();
    const { mutateAsync: sendXmlAnnotationSubmittalPackage } = useSendPackageXmlAnnotationMutation();
    const { data: userPreferences } = useUserAccountPreferencesQuery();
    const { mutateAsync: autoSearchDatasheet } = useDatasheetAutoSearchMutation();
    const { mutateAsync: autoSearchOM } = useOmAutoSearchMutation();

    const handleRefreshDataSheet = () => {
        setOpenRefreshDataSheet((old: any) => !old);
    };
    const handleMatchingColumns = () => {
        setMatchingColumn((old: any) => !old);
    };

    const handleChangeFile = (res?: UploadedScheduleResponse | UploadedSubmittalPackageResponse) => {
        props.handleChangeFile(!props.changeFile);
        if (res) {
            props.setConfirmSelection(false);
        }
    };

    const handleAcceptRequest = () => {
        if (props?.type === SubmittalBomType) {
            navigate(`/v2/project/${projectId}/bom/${bomId}/adjustPages?showPopup=${true}&submittalPackageId=${data?.data?.id}`);
            handleRefreshDataSheet();
        } else {
            navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`);
            handleRefreshDataSheet();
        }
    };

    const changeFileAccept = async (res: UploadedScheduleResponse | UploadedSubmittalPackageResponse, packageType: 'schedule' | 'submittal_package') => {
        refetch();

        if (packageType === 'schedule') {
            if ('file_format' in res && userPreferences?.data.autoselection && res.bods.length > 0) {
                const data = {
                    bomId: Number(bomId),
                    input: {
                        bod_id: res.bods,
                        user_preference: userPreferences?.data.autoselection_user_group
                    }
                };

                handleChangeFile(res);
                await autoSearchDatasheet(data);
                await autoSearchOM(data);
            }
        }

        if (isRecognisedFileFormat(res)) {
            navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`);
            return;
        }

        handleChangeFile(res);
    };
    const confirmSelection = async () => {
        const formData = new FormData();
        formData.append('file_info', data?.data?.file_info || '');
        if (data?.data?.filetype === SCHEDULE_FILE_TYPES.xmlFileType) {
            navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`);
        } else {
            handleMatchingColumns();

            if (props?.type === SubmittalBomType) {
                formData.append('bound_info', props.boundInfo);
                sendXmlAnnotationSubmittalPackage({
                    data: formData,
                    submittalId: data?.data?.bom || 0,
                    projectScheduleId: data?.data?.id || 0
                }).then(
                    (res) => {
                        handleMatchingColumns();
                        setXmlData(res.data);
                        handleRefreshDataSheet();
                    },
                    () => {
                        handleMatchingColumns();
                    }
                );
            } else if (
                data?.data?.file_format === RECOGNISED_EXCEL_FOMRATS.OASIS_EXCEL ||
                data?.data?.file_format === RECOGNISED_EXCEL_FOMRATS.EPISOR_EXCEL ||
                data?.data?.file_format === RECOGNISED_EXCEL_FOMRATS.AGILE_EXCEL ||
                data?.data?.file_format === RECOGNISED_EXCEL_FOMRATS.NORMAL_EXCEL
            ) {
                navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`);
            } else {
                formData.append('bound_info', props.boundInfo);
                sendXmlAnnotation({ data: formData, submittalId: data?.data?.bom || 0, projectScheduleId: data?.data?.id || 0 }).then(
                    (res) => {
                        handleMatchingColumns();
                        if (
                            data?.data?.filetype === SCHEDULE_FILE_TYPES.xlsxFileType ||
                            data?.data?.filetype === SCHEDULE_FILE_TYPES.xlsFileType ||
                            data?.data?.filetype === SCHEDULE_FILE_TYPES.csvFileType ||
                            data?.data?.filetype === SCHEDULE_FILE_TYPES.xlsmFileType
                        ) {
                            setXmlData([res.data]);
                        } else {
                            setXmlData(res.data);
                        }
                        handleRefreshDataSheet();
                    },
                    (err) => {
                        handleMatchingColumns();
                        console.log(err, 'err');
                    }
                );
            }
        }
    };
    return (
        <>
            <Grid container justifyContent={'space-between'} alignItems={'center'} pt={6} px={6} width={'100%'}>
                <Grid item xs={9}>
                    <Grid container direction="column" gap={2}>
                        <Grid item>
                            <H5>Select Product List</H5>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <Grid container gap={2} justifyContent={'flex-end'} alignItems={'flex-start'}>
                        <Grid item>
                            <CancelButton />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" disabled={!props.confirmSelection} onClick={() => confirmSelection()}>
                                Confirm Selection(s){' '}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {refreshDataSheet && (
                <ConfirmColumn
                    open={refreshDataSheet}
                    handleDialog={handleRefreshDataSheet}
                    xmlData={xmlData}
                    onAccept={handleAcceptRequest}
                    onReject={handleRefreshDataSheet}
                    onClose={handleRefreshDataSheet}
                />
            )}
            {matchingColumn && <MatchingColumnHeader open={matchingColumn} />}
            {props.changeFile && <ImportMoreProducts open={props.changeFile} onSuccess={changeFileAccept} onCloseClick={handleChangeFile} />}
        </>
    );
};

export default Header;
