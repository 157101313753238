import { BodySmall, Box } from '@parspec/pixel';

import { StatusTemplate } from '../../../shared/utils/utils';
import UserAvatar from './UserAvatar';
import { getPriorityColorAndIcon } from '../utils';

interface FilterOptionProps {
    option: {
        label: string;
        value: string | number;
    };
}

export const PriorityFilterOption = ({ option }: FilterOptionProps) => {
    const info = getPriorityColorAndIcon(String(option?.value)) || {};
    return (
        <Box display="flex" alignItems="center">
            {info.icon}
            <BodySmall color={info.color} display="flex" textOverflow="ellipsis" whiteSpace="normal" overflow="hidden">
                {option?.label}
            </BodySmall>
        </Box>
    );
};

export const StageFilterOption = ({ option }: FilterOptionProps) => {
    return StatusTemplate(String(option.value));
};

export const OwnerFilterOption = ({ option }: FilterOptionProps) => {
    return (
        <Box display="flex" alignItems="center" gap={2}>
            <UserAvatar userName={option.label} userId={Number(option.value)} />
            <BodySmall display="flex" textOverflow="ellipsis" whiteSpace="normal" overflow="hidden">
                {option.label}
            </BodySmall>
        </Box>
    );
};

export const CommonOption = ({ option }: FilterOptionProps) => {
    return (
        <BodySmall display="flex" textOverflow="ellipsis" whiteSpace="normal" overflow="hidden">
            {option.label}
        </BodySmall>
    );
};
