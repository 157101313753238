import SectionLogo from '../../../shared/SectionLogo';
import { FileUploadPayloadType } from '../../../constants';
import { useCoverPageLogoQuery, useCoverUpdateLogoMutation, useCoverPageDeleteLogoMutation } from '../../queries';
import { DocumentType } from '../../../../constants';
import { useQuoteContext } from '../../QuoteContext';
import { IRenderLogoProps } from '../../../shared/utils/helper';
import { COVER_PAGE_SECTION_NAME } from '../../shared/constants';

function CoverPageSectionLogo({ sectionType, showAddressToPrefill, companyLogo, disabled, subSectionName }: IRenderLogoProps) {
    const { isInitiateApiCalls, handlePdfPreview, bomId, handleUpdateChangedSections } = useQuoteContext();

    const { data: sectionLogoData, isLoading } = useCoverPageLogoQuery(bomId, sectionType, isInitiateApiCalls);

    const pushChangedFields = () => {
        if (subSectionName) handleUpdateChangedSections?.({ sectionName: COVER_PAGE_SECTION_NAME, subSectionName, value: true });
    };

    const { mutateAsync: deleteMutation, isLoading: isDeleting } = useCoverPageDeleteLogoMutation();
    const { mutateAsync: updateLogoMutation, isLoading: isUpdating } = useCoverUpdateLogoMutation();

    const fileUploadHandler = async (data: File[] | null, label?: string | null) => {
        const payload: FileUploadPayloadType = {
            documentType: DocumentType.QUOTE,
            bomId: bomId,
            fieldName: sectionType
        };
        if (data && data.length) {
            const formData = new FormData();
            formData.append('logo', data[0]);

            payload.logo = formData;
            payload.source = null;
        } else if (!data && label) {
            payload.logo = null;
            payload.source = label;
        }

        if (Object.hasOwn(payload, 'logo') || Object.hasOwn(payload, 'source')) {
            await updateLogoMutation(payload);
            handlePdfPreview(true);
            pushChangedFields();
        }
    };

    const handleFileDelete = async () => {
        await deleteMutation({
            bomId: bomId,
            fieldName: sectionType
        });

        handlePdfPreview(true);
        pushChangedFields();
    };

    const logo = showAddressToPrefill && companyLogo ? companyLogo : sectionLogoData?.data?.logo;
    return (
        <SectionLogo
            disabled={disabled}
            isDocumentLogoLoading={isLoading}
            logo={logo}
            onDelete={handleFileDelete}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onFileUpload={fileUploadHandler}
            sectionName={COVER_PAGE_SECTION_NAME}
            subSectionName={subSectionName}
        />
    );
}

export default CoverPageSectionLogo;
