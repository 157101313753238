import { Outlet } from 'react-router-dom';
import { Box } from '@parspec/pixel';

export const SignupOutlet = () => {
    return (
        <Box
            width="100vw"
            minHeight="100vh"
            py={8}
            bgcolor="#091535"
            sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(assets/images/login/login-bg.png)`
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" mb={14}>
                    <Box height="45px" width="45px" mr={3}>
                        <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                    </Box>

                    <Box>
                        <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                    </Box>
                </Box>

                <Box>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};
