export const generateSubmittalResponse = {
  id: 1,
  document_link: 'string',
  document_link_flat: 'string',
  version: 1,
  compiled_by: 'string',
  product_count: 'numberstring',
  quote_number: 'numberstring',
  primary_title: 'string',
  secondary_title: 'string',
  version_notes: 'string',
  is_published: 'string',
  is_deleted: 'booleanstring',
  created_at: 'string',
  updated_at: 'string',
};

export const CompileTimeResponse = {
  compile_time: 3000,
};
