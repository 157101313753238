import { BodyBig, BodyMedium, BodySmall, Box, Grid, Select, SelectChangeEvent, Switch, TextField } from '@parspec/pixel';

import { BranchBrandingInterface } from '../AddEditLocation.types';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { optionsForLogo } from '../../../../BOM/FinalDocuments/Compile/shared/utils';
import { SubmittalTemplateInterface, OMTemplateInterface } from '../../queries/apiTypes';
import {
    OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY,
    OPTIONS_FOR_OM_BID_DATE,
    OPTIONS_FOR_SUBMITTAL_BID_DATE,
    OPTIONS_FOR_ADDRESSED_TO
} from 'src/features/BOM/FinalDocuments/Compile/shared/utils/constants';
import { PRE_FILL_ADDRESSED_TO, PRE_FILL_PREPARED_BY_LOGO, PRE_FILL_COVER_PAGE_LOGO } from '../QuoteTemplatePreferences/utils/constants';

const BASIC_INFO_COLUMN_OPTIONS = [
    { label: 'Date', value: 'date' },
    { label: 'Primary Title', value: 'primary_title' },
    { label: 'Secondary Title', value: 'secondary_title' },
    { label: 'Project Number', value: 'project_number' },
    { label: 'Quote Number', value: 'quote_number' },
    { label: 'Project Address', value: 'project_address' }
];

const STAKEHOLDERS_COLUMN_OPTIONS = [{ label: 'Stakeholders', value: 'stakeholders' }];
const PREPARER_ADDRESSEE_COLUMN_OPTIONS = [
    { label: 'Prepared By Information', value: 'prepared_by' },
    { label: 'Addressed To Information', value: 'addressed_to' }
];

const COVER_PAGE_PRESETS = 'cover_page_presets';

const PRE_FILL_PRIMARY_TITLE = 'pre_fill_primary_title';

const PRE_FILL_SECONDARY_TITLE = 'pre_fill_secondary_title';

const PRE_FILL_DATE = 'pre_fill_date';
interface CoverPageSectionProps {
    changeHandler: (e: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => void;
    handleTemplateSelection: (e: React.MouseEvent<HTMLElement>, pageNumber: '1' | '2') => void;
    templateState: SubmittalTemplateInterface | OMTemplateInterface;
    branchBrandingState: BranchBrandingInterface;
    templateName: 'SUBMITTAL' | 'OM';
}

export const CoverPage = (props: CoverPageSectionProps) => {
    const { changeHandler, handleTemplateSelection, templateState, branchBrandingState, templateName } = props;

    const primaryImageUrl = branchBrandingState.primary?.isChanged ? branchBrandingState.primary?.url : branchBrandingState.primary?.url ? getProxyFileUrl(branchBrandingState.primary?.url) : '';
    const secondaryImageUrl = branchBrandingState.secondary?.isChanged
        ? branchBrandingState.secondary?.url
        : branchBrandingState.secondary?.url
        ? getProxyFileUrl(branchBrandingState.secondary?.url)
        : '';

    const onChangeHandler = (event: SelectChangeEvent<unknown>) => {
        changeHandler(event, COVER_PAGE_PRESETS);
    };

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState.cover_page_presets.cover_page_section}
                    onChange={(e) => changeHandler(e, COVER_PAGE_PRESETS)}
                    name="cover_page_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Cover Page
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose a design layout and the fields you want to appear by default on your cover page.
            </BodySmall>
            {templateName === 'SUBMITTAL' && (
                <BodySmall limit={false} color="text.secondary">
                    You can edit the layout and fields during the submittal creation process.
                </BodySmall>
            )}
            {templateName === 'OM' && (
                <BodySmall limit={false} color="text.secondary">
                    You can edit the layout and fields during the O&M creation process.
                </BodySmall>
            )}

            <Box mt={6}>
                <BodyMedium color="neutral.dark" fontWeight={500}>
                    Select a default cover page layout:
                </BodyMedium>
                <Box mt={6} mb={6}>
                    <Grid item>
                        <Grid container alignItems={'center'} gap={3}>
                            <Grid item onClick={(e: React.MouseEvent<HTMLElement>) => handleTemplateSelection(e, '1')} sx={{ cursor: 'pointer' }}>
                                <Box
                                    width={177}
                                    height={225}
                                    border={`1px solid`}
                                    borderColor={templateState.cover_page_template_id === 1 ? 'primary.main' : 'neutral.main'}
                                    padding={2}
                                    bgcolor={templateState.cover_page_template_id === 1 ? 'primary.light' : 'neutral.light'}
                                >
                                    <Box width={'100%'} height={'100%'} padding={'10px'} bgcolor={'primary.contrastText'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                                        <Box width={72} height={35} marginTop={4} mb={2}>
                                            {branchBrandingState.primary.url && (
                                                <img
                                                    loading="lazy"
                                                    src={primaryImageUrl || ''}
                                                    id="preview-left-image-1"
                                                    style={{ objectFit: 'contain', width: '100%', maxHeight: 38, verticalAlign: 'middle' }}
                                                />
                                            )}
                                        </Box>
                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={3}></Box>
                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={3}></Box>
                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={100} height={4} bgcolor="neutral.main" marginTop={'10px'}></Box>
                                        <Box width={100} height={4} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={'100%'} marginTop={4} display={'flex'} justifyContent={'space-between'}>
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                                                <Box width={24} height={20} mb={3}>
                                                    {branchBrandingState.secondary.url && (
                                                        <img
                                                            src={secondaryImageUrl || ''}
                                                            loading="lazy"
                                                            id="preview-left-image-2"
                                                            style={{ objectFit: 'contain', width: '100%', maxHeight: 20, verticalAlign: 'middle' }}
                                                        />
                                                    )}
                                                </Box>
                                                <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                <Box width={20} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                <Box width={40} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                            </Box>

                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                                                <Box width={24} height={20} mb={3}>
                                                    <img loading="lazy" id="preview-left-image-3" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: 'block' }} />
                                                </Box>
                                                <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                <Box width={20} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                <Box width={40} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item onClick={(e: React.MouseEvent<HTMLElement>) => handleTemplateSelection(e, '2')} sx={{ cursor: 'pointer' }}>
                                <Box
                                    width={177}
                                    height={225}
                                    border={`1px solid`}
                                    borderColor={templateState.cover_page_template_id === 2 ? 'primary.main' : 'neutral.main'}
                                    padding={2}
                                    bgcolor={templateState.cover_page_template_id === 2 ? 'primary.light' : 'neutral.light'}
                                >
                                    <Box width={'100%'} height={'100%'} padding={'10px'} bgcolor={'primary.contrastText'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                        <Box width={120} height={10} bgcolor="neutral.main" marginTop={3}></Box>
                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={72} height={6} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={72} height={35} mb={1} mt={2}>
                                            {branchBrandingState.primary.url && (
                                                <img
                                                    loading="lazy"
                                                    src={primaryImageUrl || ''}
                                                    id="preview-left-image-1"
                                                    style={{ objectFit: 'contain', width: '100%', maxHeight: 38, verticalAlign: 'middle' }}
                                                />
                                            )}
                                        </Box>

                                        <Box width={112} height={4} bgcolor="neutral.main" marginTop={3}></Box>
                                        <Box width={112} height={4} bgcolor="neutral.main" marginTop={1}></Box>

                                        <Box width={'100%'} marginTop={4} display={'flex'} justifyContent={'space-between'}>
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'center'}>
                                                <Box width={19} height={18} mb={3}>
                                                    {branchBrandingState.secondary.url && (
                                                        <img
                                                            src={secondaryImageUrl || ''}
                                                            loading="lazy"
                                                            id="preview-left-image-2"
                                                            style={{ objectFit: 'contain', width: '100%', maxHeight: 20, verticalAlign: 'middle' }}
                                                        />
                                                    )}
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                    <Box width={20} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                    <Box width={40} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                </Box>
                                            </Box>

                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'center'}>
                                                <Box width={24} height={20} display={'block'}>
                                                    <img id="preview-right-image-3" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: 'block' }} />
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                    <Box width={20} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                    <Box width={40} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Box display="flex " position="relative" height={'100%'}>
                        <Box width="33%" pr={6}>
                            <BodyMedium color="secondary.light" fontWeight={500}>
                                Basic Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {BASIC_INFO_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.cover_page_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, COVER_PAGE_PRESETS)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box width="33%" pr={6}>
                            <BodyMedium color="secondary.light" fontWeight={500}>
                                Stakeholders
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {STAKEHOLDERS_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.cover_page_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, COVER_PAGE_PRESETS)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box width="33%" pr={6}>
                            <BodyMedium color="secondary.light" fontWeight={500}>
                                Preparer & Addressee
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {PREPARER_ADDRESSEE_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.cover_page_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, COVER_PAGE_PRESETS)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <BodySmall color="secondary.light" fontWeight={500} mt={4} mb={2}>
                            Attribute Pre-fill
                        </BodySmall>

                        {templateName === 'SUBMITTAL' && (
                            <BodySmall color="text.secondary" fontWeight={400} mb={4}>
                                Determine the values that we’ll use to pre-fill some of your Submittal attributes.
                            </BodySmall>
                        )}
                        {templateName === 'OM' && (
                            <BodySmall color="text.secondary" fontWeight={400} mb={4}>
                                Determine the values that we’ll use to pre-fill some of your O&M attributes.
                            </BodySmall>
                        )}

                        <Grid container flexDirection={'column'} rowSpacing={4} width="50vw">
                            <Grid container item columnSpacing={4}>
                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_PRIMARY_TITLE}
                                        label={'Pre-fill Format'}
                                        options={OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState.cover_page_presets.pre_fill_primary_title}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_DATE}
                                        label={'Bid Date'}
                                        options={templateName === 'SUBMITTAL' ? OPTIONS_FOR_SUBMITTAL_BID_DATE : OPTIONS_FOR_OM_BID_DATE}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState.cover_page_presets.pre_fill_date}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_COVER_PAGE_LOGO}
                                        label={'Cover Page Logo'}
                                        options={optionsForLogo}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState?.cover_page_presets.pre_fill_coverpage_logo}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item columnSpacing={4}>
                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_SECONDARY_TITLE}
                                        label={'Secondary Title'}
                                        options={OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState.cover_page_presets.pre_fill_secondary_title}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_PREPARED_BY_LOGO}
                                        label={'Prepared By Logo'}
                                        options={optionsForLogo}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState?.cover_page_presets.pre_fill_preparedby_logo}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        name={PRE_FILL_ADDRESSED_TO}
                                        label={'Addressed To'}
                                        options={OPTIONS_FOR_ADDRESSED_TO}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHandler}
                                        value={templateState?.cover_page_presets.pre_fill_addressed_to}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box pr={6} pt={4}>
                        <BodySmall color="secondary.light" fontWeight={500}>
                            Notes
                        </BodySmall>
                        <BodySmall limit={false} color="text.secondary" mt={2}>
                            Default entry for the cover page notes field.
                        </BodySmall>
                        <Box mt={3}>
                            <TextField
                                label="Enter Your Notes Here..."
                                autoComplete="off"
                                value={templateState.cover_page_presets.notes}
                                name={'notes'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, COVER_PAGE_PRESETS)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
