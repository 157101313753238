import { ColDef } from 'ag-grid-community';

const BASE_CMS_URL = '/v2/contactManagement';

export const TAB_VALUES = {
    COMPANY_TAB_VALUE: BASE_CMS_URL,
    CONTACT_TAB_VALUE: `${BASE_CMS_URL}/contacts`
};

export const TAB_OPTIONS = [
    { label: 'Companies', value: TAB_VALUES.COMPANY_TAB_VALUE },
    { label: 'Contacts', value: TAB_VALUES.CONTACT_TAB_VALUE }
];

export const BUSINESS_TYPE_OPTIONS = [
    { label: 'Architect', value: 'Architect' },
    { label: 'Contractor', value: 'Contractor' },
    { label: 'Distributor', value: 'Distributor' },
    { label: 'Electrical Contractor', value: 'Electrical Contractor' },
    { label: 'Engineer', value: 'Engineer' },
    { label: 'General Contractor', value: 'General Contractor' },
    { label: 'Landscape Architect', value: 'Landscape Architect' },
    { label: 'Lighting Designer', value: 'Lighting Designer' },
    { label: 'Municipality', value: 'Municipality' },
    { label: 'National Account', value: 'National Account' },
    { label: 'Owner', value: 'Owner' },
    { label: 'Sales Agency', value: 'Sales Agency' }
];

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    comparator: (valueA: string, valueB: string) => {
        return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
};

export const DELETE_COMPANY_TITLE = 'Delete Company(ies) & Associated Contact(s)?';

export const VISIBILITY_RADIO_OPTIONS = [
    { label: 'All Locations', value: 'all_branch_locations' },
    { label: 'Specific Branch Locations', value: 'specific_branch_locations' }
];

export const acceptedFileTypes = ['.png', '.jpg'];

export const acceptedFileTypesString = 'Supported file types: PNG, JPG';

export const COMPANY_DETAIL_PAGE_DESCRIPTION =
    "The company details, including the logo, are used to pre-populate the 'Addressed To' section of your documents based on the selected primary customer for the bill of materials.\nYou can always edit the information during the document creation process.";

export const UPLOAD_CONTACTS_CSV_TEMPLATE =
    'First Name,Last Name,Job Title,Email,Phone Number,Notes,Company - Company Name,CRM/ERP ID,Company - Business Type,Company - Email,Company - Phone Number,Company - Website,Company - Address Line 1,Company - Address Line 2,Company - Country,Company - City,Company - State,Company - Zip,Company - Location Access,Company - Notes\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n';

export const CONTACT_NUMBER_REQEX = new RegExp('^[0-9-().]*$');
