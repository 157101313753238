import { useContext } from 'react';

import { Grid, VisibilityOffOutlinedIcon, Box, BodySmall, Link, H5 } from '@parspec/pixel';

import Context from './Context';
import { ENTITY } from '../constants';

const accordionTitleMaping: { [index: string]: string } = {
    layout: 'Contents Formatting',
    header_footer: 'Header & Footer',
    notes_page: 'Notes Summary',
    toc: 'Table Of Contents',
    cover_page: 'Cover Page'
};

const NotVisible: React.FC = () => {
    const ctx = useContext(Context);
    return (
        <Grid mt={0.5} container direction={'column'} alignItems={'center'} justifyContent={'center'} height={'calc(100vh - 110px)'} rowSpacing={2} sx={{ padding: '20px 80px 20px 85px' }}>
            <Grid item>
                <VisibilityOffOutlinedIcon
                    sx={{
                        fontSize: 112
                    }}
                />
            </Grid>

            <Grid item>
                <Grid container width={400} direction={'column'} alignItems={'center'} gap={1}>
                    <Grid item>
                        <H5 sx={{ fontWeight: 700 }} color="secondary.main">
                            No Preview Available
                        </H5>
                    </Grid>
                    <Grid item>
                        <Box width={'100%'} textAlign={'center'}>
                            {ctx.accordionFieldName === ENTITY.LAYOUT ? (
                                <BodySmall limit={false} color={'secondary.main'}>
                                    No preview available for {accordionTitleMaping[ctx.accordionFieldName]}. Refer to <br />
                                    <Link target="_blank" href="https://knowledgebase.parspec.io/knowledge/contents-formatting" underline="none" fontSize={'small'}>
                                        this article
                                    </Link>{' '}
                                    to learn more
                                </BodySmall>
                            ) : (
                                <>
                                    {ctx?.accordionFieldName === ENTITY.HEADER_FOOTER_ENTITY ? (
                                        <BodySmall lines={3} color={'secondary.main'}>
                                            The headers and footers are currently hidden. Click the switch next to the {accordionTitleMaping[ctx.accordionFieldName]} section to reveal it.
                                        </BodySmall>
                                    ) : (
                                        <BodySmall lines={3} color={'secondary.main'}>
                                            The {accordionTitleMaping[ctx.accordionFieldName].toLowerCase()} is currently hidden. Click the switch next to the{' '}
                                            {accordionTitleMaping[ctx.accordionFieldName]} section to reveal it.
                                        </BodySmall>
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NotVisible;
