import { Box, TextField, Button } from '@parspec/pixel';
import { DocType } from '../constants';

interface IVersionNotes {
    isNotesSectionDisabled: boolean;
    onSubmit: () => void;
    notes: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, bomId: string) => void;
    bomId: string;
    documentType: string;
    applyToAllBomId?: number[];
    handleApplyToAllClick?: (bomId: string) => void;
    showApplyButton?: boolean;
}

export const VersionNotes: React.FC<IVersionNotes> = ({ isNotesSectionDisabled, onSubmit, notes, onChange, bomId, documentType, applyToAllBomId, handleApplyToAllClick, showApplyButton }) => {
    const isQuote = documentType === DocType.QUOTE ? true : false;
    return (
        <Box
            mt={3}
            mb={2}
            sx={
                isNotesSectionDisabled
                    ? {
                          opacity: 0.4,
                          pointerEvents: 'none'
                      }
                    : null
            }
        >
            <form onSubmit={onSubmit}>
                <TextField label={`Enter your ${isQuote ? 'version' : ''}  notes here...`} rows={isQuote ? 15 : 10} multiline={true} value={notes} onChange={(e) => onChange(e, bomId)} />
            </form>
            {showApplyButton && (applyToAllBomId || []).includes(Number(bomId)) && (
                <Box mt={1}>
                    <Button onClick={() => handleApplyToAllClick?.(bomId)} color="primary" variant="text" sx={{ textWrap: 'noWrap' }}>
                        Apply to all
                    </Button>
                </Box>
            )}
        </Box>
    );
};
