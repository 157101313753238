import { AlertBanner, BodySmall, Box, Button } from '@parspec/pixel';
import { useNavigate } from 'react-router-dom';
import { CONTACT_PARSPEC_LINK } from 'src/features/shared/utils/constants';

interface InactiveAccountBannerProps {
    onClose: () => void;
}
const InactiveAccountBanner = (props: InactiveAccountBannerProps) => {
    const navigate = useNavigate();
    const { onClose } = props;

    const onManagePlanClick = () => {
        navigate(`/v2/settings/plan-and-payment`);
    };
    return (
        <Box>
            <AlertBanner
                text="No active subscription. You can access previously compiled submittals, but are no longer able to
                    edit or create new submittals."
                variant="filled"
                severity="error"
                onClose={onClose}
            >
                <Button variant="text" onClick={onManagePlanClick} color="error">
                    <BodySmall
                        color="secondary.contrastText"
                        sx={{
                            '&:hover': {
                                textDecorationLine: 'underline',
                                textUnderlineOffset: 2
                            }
                        }}
                    >
                        Manage Plan
                    </BodySmall>
                </Button>
                <Box>or</Box>
                <Button variant="text" onClick={() => window.open(CONTACT_PARSPEC_LINK, '_blank')} color="error">
                    <BodySmall
                        color="secondary.contrastText"
                        sx={{
                            '&:hover': {
                                textDecorationLine: 'underline',
                                textUnderlineOffset: 2
                            }
                        }}
                    >
                        Contact Sales
                    </BodySmall>
                </Button>
            </AlertBanner>
        </Box>
    );
};

export default InactiveAccountBanner;
