import { BodySmall, Box, Checkbox, Grid, Skeleton } from '@parspec/pixel';
import { getArrayBetweenNumbers } from 'src/features/shared/utils/utils';

function getMapFromArray(arr: number[]) {
    const map = new Map();

    for (let i = 0; i < arr.length; i++) {
        map.set(arr[i], i);
    }

    return map;
}

const Body = (props: any) => {
    const { hiddenPages, setHiddenPages, thumbnails } = props;

    const handleChange = (item: any) => {
        props.setModalError(false);
        if (hiddenPages?.includes(item + 1)) {
            setHiddenPages(hiddenPages?.filter((i: any) => i !== item + 1));
        } else {
            setHiddenPages((prevSelectedItems: any) => [...prevSelectedItems, item + 1]);
        }
    };

    const hiddenPagesMap = getMapFromArray(hiddenPages);

    return (
        <>
            <Box display="flex" flexWrap="wrap" gap={2} justifyContent="flex-start" width="70vw" height="60vh" overflow="auto" mt={6}>
                <Box display="flex" flexWrap="wrap" gap={4}>
                    {thumbnails.length
                        ? thumbnails?.map((thumbnail: any, index: number) => {
                              return (
                                  <Box
                                      height={'328px'}
                                      pt={1}
                                      pl={2}
                                      pr={2}
                                      bgcolor={!hiddenPagesMap.has(index + 1) ? 'primary.light' : 'white'}
                                      position="relative"
                                      sx={{ objectFit: 'cover' }}
                                      key={index}
                                  >
                                      <Box border={1} borderColor={!hiddenPagesMap.has(index + 1) ? 'primary.main' : 'neutral.dark'} mt={1}>
                                          <img width="220px" height="284px" src={thumbnail} onClick={() => handleChange(index)} />
                                      </Box>
                                      <Box position="absolute" width={5} height={5} left={12} top={4}>
                                          <Checkbox
                                              sx={{
                                                  padding: 0,
                                                  backgroundColor: 'white',
                                                  mt: 3,
                                                  ml: 3,
                                                  borderRadius: 0,
                                                  '&:hover': { bgcolor: 'white' }
                                              }}
                                              size="medium"
                                              label=""
                                              checked={!hiddenPagesMap.has(index + 1)}
                                              onChange={() => handleChange(index)}
                                          />
                                      </Box>
                                      <BodySmall
                                          fontWeight={400}
                                          sx={
                                              !hiddenPagesMap.has(index + 1)
                                                  ? {
                                                        color: 'primary.main'
                                                    }
                                                  : null
                                          }
                                          pt={2}
                                          pb={'6px'}
                                      >
                                          Page {index + 1}
                                      </BodySmall>
                                  </Box>
                              );
                          })
                        : getArrayBetweenNumbers(1, 5).map((_page, index) => {
                              return (
                                  <Grid item key={index} p={2} pt={1}>
                                      <Skeleton variant="rectangular" width={220} height={248} key={index} />
                                  </Grid>
                              );
                          })}
                </Box>
            </Box>
        </>
    );
};
export default Body;
