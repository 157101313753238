import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { endPoints, getQuotePreview } from './apis';

const getPreviewQuoteKey = (bomId: string) => [endPoints.getPreviewQuote(bomId)];

export const useGetPreviewQuote = (bomIds: Array<number>, options?: UseQueryOptions<AxiosResponse>) =>
    useQueries({
        queries: bomIds.map((bomId) => {
            return {
                queryKey: getPreviewQuoteKey(`${bomId}`),
                queryFn: () => getQuotePreview(`${bomId}`),
                ...options
            };
        })
    });
