import { USER_ROLES } from '../constants';
import { useGetUserRole } from '../RBAC';
import { useFeatureFlag } from '../UserActivityTracking/useFeatureFlag';
import { useCheckAccessForProductFinderOrQuoting } from './useCheckAccessForProductFinderOrQuoting';

export const useCheckAccessForReporting = () => {
    const { quotingAccess, isFetching: isQuotingAccessFetching } = useCheckAccessForProductFinderOrQuoting();
    const { enable_quoting, enable_reporting, isLoading: isFeatureFlagLoading } = useFeatureFlag();
    const { currentUserRole } = useGetUserRole();

    const isAdmin = currentUserRole === USER_ROLES.ADMIN;

    const reportingAccess = enable_quoting && enable_reporting && quotingAccess && isAdmin;

    const isLoading = isQuotingAccessFetching || isFeatureFlagLoading;

    return { reportingAccess, isLoading };
};
