import { BodySmall, BodyXS, Box } from '@parspec/pixel';

import { GetQuickFilterTextParams, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { getDateStringForTable } from 'src/features/shared/utils/utils';

interface DateCellProps {
    editedBy: string;
    editedAt: string;
}

export function getDateComparator(valueA: string, valueB: string) {
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
    if (dateA > dateB) {
        return 1;
    } else if (dateA < dateB) {
        return -1;
    } else {
        return 0;
    }
}

export function getDateFieldFilterValueGetter(params: ValueGetterParams) {
    const { data, column } = params;
    const fieldName = column?.getColId() || 'createdAt';
    return fieldName === 'createdAt' ? data.createdBy : data.updatedBy;
}

export function getQuickFilterTextForDateCell(params: GetQuickFilterTextParams) {
    const { data, column } = params;
    const fieldName = column?.getColId() || 'createdAt';
    const editedAt = data[fieldName] ? getDateStringForTable(data[fieldName]) : '-';
    const editedByKey = fieldName === 'createdAt' ? 'createdBy' : 'updatedBy';
    const editedBy = data[editedByKey] || '-';
    return editedBy + ' ' + editedAt;
}

export function DateCellRenderer(params: ICellRendererParams) {
    const { data, column } = params;
    const fieldName = column?.getColId() || 'createdAt';
    const editedAt = data[fieldName] ? getDateStringForTable(data[fieldName]) : '-';
    const editedByKey = fieldName === 'createdAt' ? 'createdBy' : 'updatedBy';
    const editedBy = data[editedByKey] || '-';

    return <DateCell editedBy={editedBy} editedAt={editedAt} />;
}

function DateCell({ editedBy, editedAt }: DateCellProps) {
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center">
            <BodySmall>{editedBy}</BodySmall>
            <BodyXS color="secondary.light" sx={{ wordWrap: 'break-word' }}>
                {editedAt}
            </BodyXS>
        </Box>
    );
}
