import { useRef, useCallback, useState } from 'react';

import { Box, Modal, ModalFooter, ModalHeader } from '@parspec/pixel';

import { useCreateProjectDataMutation } from './queries';
import { CreateProjectResponse } from './queries/apiTypes';
import EditProjectDetail from './EditProjectDetail';
import { IProjectDetailState, Errors } from '../utils/utils';
import { useTrackIntercomEvent } from '../hooks/useIntercomHook';
import { convertToCents } from '../utils/utils';
import { projectStakeHoldersInitialState, StakeholderType } from './utils';

interface IPropstype {
    open: boolean;
    onCloseClick: () => void;
    onSuccess: (response: CreateProjectResponse) => void;
    editMode?: boolean;
}

export const CreateAndEditProjectModal: React.FC<IPropstype> = (props: IPropstype) => {
    const [stakeholdersInfo, setStakeholdersInfo] = useState<StakeholderType[]>(projectStakeHoldersInitialState);
    const { onCloseClick, onSuccess, editMode, open } = props;

    const projectStateRef = useRef<IProjectDetailState>();
    const [formErrors, setFormErrors] = useState<Errors>({ projectNameError: '' });

    const setProjectStateRef = useCallback((data: IProjectDetailState) => {
        projectStateRef.current = data;
    }, []);

    const handleFormErrorsAction = useCallback((error: Errors) => {
        setFormErrors(error);
    }, []);

    const { isLoading: isCreateProjectLoading, mutateAsync: createProject } = useCreateProjectDataMutation();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const updateStakeholderInfo = async (info: StakeholderType, index: number) => {
        setStakeholdersInfo((old) => {
            const updatedInfo = [...old];
            updatedInfo[index] = info;
            return updatedInfo;
        });
    };

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();

        if (projectStateRef?.current) {
            if (!projectStateRef.current.name.trim()) {
                setFormErrors({ ...formErrors, projectNameError: 'Required' });
                return;
            }

            if (
                (projectStateRef.current.submittal_due_date !== null &&
                    projectStateRef.current.submittal_due_date &&
                    !projectStateRef.current.submittal_due_date.isValid() &&
                    projectStateRef.current.submittal_due_date.year() > 2099) ||
                (projectStateRef.current.o_m_due_date !== null &&
                    projectStateRef.current.o_m_due_date &&
                    !projectStateRef.current.o_m_due_date.isValid() &&
                    projectStateRef.current.o_m_due_date.year() > 2099)
            ) {
                return;
            }

            const stakeHolders = stakeholdersInfo.map((item) => ({ company: item?.company?.id || null, contact: item?.contact?.id || null }));
            const formData = new FormData();
            formData.append('name', projectStateRef.current.name);
            formData.append('company', JSON.stringify(projectStateRef.current.company));
            formData.append('stage', projectStateRef.current.stage);
            formData.append(
                'estimated_project_cost',
                projectStateRef.current.estimated_project_cost === null ? 'null' : JSON.stringify(convertToCents(projectStateRef.current.estimated_project_cost))
            );
            formData.append('quote_owner', JSON.stringify(projectStateRef.current.quote_owner));
            projectStateRef.current.quote_due_date && formData.append('quote_due_date', projectStateRef.current.quote_due_date?.format());
            formData.append('submittal_owner', JSON.stringify(projectStateRef.current.submittal_owner));
            projectStateRef.current.submittal_due_date && formData.append('submittal_due_date', projectStateRef.current.submittal_due_date?.format());
            formData.append('o_m_owner', JSON.stringify(projectStateRef.current.o_m_owner));
            projectStateRef.current.o_m_due_date && formData.append('o_m_due_date', projectStateRef.current.o_m_due_date?.format());
            formData.append('address1', projectStateRef.current.address1);
            formData.append('address2', projectStateRef.current.address2);
            formData.append('country', projectStateRef.current.country);
            formData.append('city', projectStateRef.current.city);
            formData.append('state', projectStateRef.current.state);
            formData.append('zip', projectStateRef.current.zip);
            formData.append('building_type', projectStateRef.current.building_type);
            formData.append('construction_scope', projectStateRef.current.construction_scope);
            formData.append('stakeholder', JSON.stringify(stakeHolders));
            formData.append('priority', projectStateRef.current.priority);
            if (projectStateRef.current.size) {
                formData.append('size', projectStateRef.current.size);
            } else {
                formData.append('size', '');
            }
            // @ts-ignore:next-line
            if (!projectStateRef.current.logo?.filepath) {
                // @ts-ignore:next-line
                formData.append('logo', projectStateRef.current.logo);
            }

            try {
                const response = await createProject(formData);
                onSuccess(response.data);
                trackIntercomEvent('Create_Project');
            } catch {
                // Handle error
            }
        }
    };

    const header = <ModalHeader title={editMode ? 'Project Details' : 'New Project'} onClose={onCloseClick} />;
    const footer = <ModalFooter onAccept={handleSubmit} onReject={onCloseClick} isLoading={isCreateProjectLoading} continueButtonLabel="Confirm" />;

    return (
        <Modal header={header} open={open} footer={footer} onClose={onCloseClick}>
            <Box maxHeight={'75vh'} overflow="scroll">
                <form onSubmit={handleSubmit}>
                    <EditProjectDetail
                        onError={handleFormErrorsAction}
                        formErrors={formErrors}
                        editMode={editMode}
                        setProjectStateRef={setProjectStateRef}
                        stakeholdersInfo={stakeholdersInfo}
                        updateStakeholderInfo={updateStakeholderInfo}
                    />
                </form>
            </Box>
        </Modal>
    );
};
