import { Box } from '@parspec/pixel';

interface RowContainerProps {
    children: React.ReactNode;
    forSkeleton?: boolean;
    lastRow?: boolean;
}
export function RowContainer({ children, forSkeleton = false, lastRow = false }: RowContainerProps) {
    return (
        <Box display="flex" alignItems="center" mb={lastRow ? 0 : 4} pt={forSkeleton ? '0px' : '10px'}>
            {children}
        </Box>
    );
}
