import { SearchSettingsModel } from '@syncfusion/ej2-react-treegrid';
import { ToolbarType } from '@parspec/pixel';
import { ColDef } from 'ag-grid-community';

export const getStats = (data: any) => {
    if (data?.data) {
        const { product, hidden_product, manufacturer } = data.data;
        return [
            {
                title: 'Manufacturers Listed',
                count: manufacturer
            },
            {
                title: 'Products Listed',
                count: product
            },
            {
                title: 'Products Hidden',
                count: hidden_product
            }
        ];
    }
    return [];
};

export const getTableProps = (args: any) => {
    const toolBarItems: ToolbarType = ['add', 'delete', 'search', 'hide', 'duplicate', 'selectedItems', 'move', 'createKit'];
    const searchSettings: SearchSettingsModel = {
        fields: ['category', 'qty', 'description', 'model_number', 'manufacturer'],
        hierarchyMode: 'Both'
    };

    return {
        toolBarOptions: toolBarItems,
        allowPaging: false,
        allowFiltering: false,
        selectionSettings: {
            checkboxOnly: true,
            persistSelection: true
        },
        ...args,
        searchSettings
    };
};

export const defaultColDef: ColDef = {
    sortable: false,
    menuTabs: [],
    resizable: true,
    suppressFillHandle: true
};
