export interface ICustomerTabContent {
    bomName: string;
    primaryCustomerName: string;
    labelId: string;
    isPrimary?: boolean;
    isLinked?: boolean;
    bomLength: number;
}

export enum ORIENTATION_TYPE {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical'
}

export type ORIENTATION = 'horizontal' | 'vertical';

export type VARIANT = 'fullWidth' | 'standard' | 'scrollable';
