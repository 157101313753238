import { Box, Button, SyncIcon } from '@parspec/pixel';

interface IPreviewButton {
    onClick: () => void;
}

function PreviewButton({ onClick }: IPreviewButton) {
    return (
        <Box
            position={'absolute'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent="center"
            width={'100%'}
            height={'100%'}
            sx={{
                top: '50%',
                left: '50%',
                transform: ' translate(-50%, -50%)',
                bgcolor: 'rgba(100, 103, 242, 0.5)'
            }}
        >
            <Box mt={4} bgcolor={'white'} borderRadius={1}>
                <Button size="large" variant="outlined" color="secondary" onClick={onClick}>
                    <SyncIcon
                        fontSize="small"
                        sx={{
                            mr: 2
                        }}
                    />
                    Refresh Preview
                </Button>
            </Box>
        </Box>
    );
}

export default PreviewButton;
