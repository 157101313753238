import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useCoverPageUpdateLogoMutation, useCoverPageDocumentLogoDeleteMutation, useCoverPageDocumentLogoQuery } from '../queries';
import PreviewContext from '../Context';
import SectionLogo from '../../shared/SectionLogo';
import { PRESET_RESPONSE } from '../../constants';
import { FIELD_NAME } from '../accordions/coverPage/constants';

import { FileUploadProps, FileUploadPayloadType, FileDeleteProps } from '../../constants';

const FileUploadSection = ({ getLogoImagePreview, fieldName, addressLogoPreview, showAddressToPrefill, companyLogo, preparedByLogoPreview, imagePreviewAction, disabled }: FileUploadProps) => {
    const { bomId = '', documentType = '' } = useParams();
    const { presetResponse, handlePreviewImage } = useContext(PreviewContext);

    const { data: documentLogo, isLoading: isDocumentLogoLoading } = useCoverPageDocumentLogoQuery(bomId, fieldName, documentType, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

    const { mutate: deleteMutation, isLoading: isDeleting } = useCoverPageDocumentLogoDeleteMutation();
    const { mutate: updateLogoMutation, isLoading: isUpdating } = useCoverPageUpdateLogoMutation();

    const fileUploadHandler = (data: File[] | null, label?: string | null) => {
        if (data && getLogoImagePreview) {
            switch (fieldName) {
                case FIELD_NAME.coverPage:
                    getLogoImagePreview(data, FIELD_NAME.coverPage);
                    break;
                case FIELD_NAME.preparedBy:
                    getLogoImagePreview(data, FIELD_NAME.preparedBy);
                    break;
                case FIELD_NAME.addressedTo:
                    getLogoImagePreview(data, FIELD_NAME.addressedTo);
                    break;
                default:
                    break;
            }
        }

        const payload: FileUploadPayloadType = {
            documentType: documentType!,
            bomId: bomId!,
            fieldName: fieldName
        };
        if (data && data.length) {
            const formData = new FormData();
            formData.append('logo', data[0]);

            payload.logo = formData;
            payload.source = null;
        } else if (!data && label) {
            payload.logo = null;
            payload.source = label;
        }

        if (Object.hasOwn(payload, 'logo') || Object.hasOwn(payload, 'source')) {
            updateLogoMutation(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                    if (fieldName === FIELD_NAME.coverPage && imagePreviewAction) {
                        imagePreviewAction(true);
                    } else if (fieldName === FIELD_NAME.preparedBy && preparedByLogoPreview) {
                        preparedByLogoPreview(true);
                    } else if (fieldName === FIELD_NAME.addressedTo && addressLogoPreview) {
                        addressLogoPreview(true);
                    }
                }
            });
        }
    };

    const handleFileDelete = () => {
        const payload: FileDeleteProps = {
            bomId: bomId,
            documentType: documentType,
            fieldName: fieldName
        };

        deleteMutation(payload, {
            onSuccess: () => {
                handlePreviewImage(true);
                if (fieldName === FIELD_NAME.coverPage && imagePreviewAction) {
                    imagePreviewAction(false);
                } else if (fieldName === FIELD_NAME.preparedBy && preparedByLogoPreview) {
                    preparedByLogoPreview(false);
                } else if (fieldName === FIELD_NAME.addressedTo && addressLogoPreview) {
                    addressLogoPreview(false);
                }
            }
        });
    };

    const logo = showAddressToPrefill && companyLogo ? companyLogo : documentLogo?.data?.logo;

    return (
        <SectionLogo
            disabled={disabled}
            isDocumentLogoLoading={isDocumentLogoLoading}
            logo={logo}
            onDelete={handleFileDelete}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onFileUpload={fileUploadHandler}
        />
    );
};

export default FileUploadSection;
