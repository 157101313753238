export const taxesGetManualJson = {
    calculation_method: 'manual_entry',
    tax_percentage: 10,
    apply_taxes_to_services: true,
    apply_taxes_to_products: false,
    apply_taxes_to_freight: false
};

export const taxesGetExemptJson = {
    calculation_method: 'tax_exempt',
    tax_percentage: null,
    apply_taxes_to_services: false,
    apply_taxes_to_products: false,
    apply_taxes_to_freight: false
};
