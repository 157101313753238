import { FC, useMemo } from 'react';

import { Box, BodyXS, Tooltip, Paper, dayjs } from '@parspec/pixel';

import { EventDataType } from '../shared/types';
import UserAvatar from '../shared/components/UserAvatar';
import { convertCostToFormattedValue } from '../../shared/utils/utils';
import { getPriorityColorAndIcon } from '../shared/utils';

interface EventCardProps {
    event: EventDataType;
    onSelectEvent: (eventId: string) => void;
    selectedEventId?: string;
}

const EventCard: FC<EventCardProps> = ({ event, onSelectEvent, selectedEventId }) => {
    const priorityInfo = useMemo(() => {
        return getPriorityColorAndIcon(event.priority);
    }, [event]);
    const isEventSelected = event.id === selectedEventId;
    return (
        <Paper sx={{ cursor: 'pointer', p: 2, bgcolor: isEventSelected ? 'tertiary.light' : '', position: 'relative' }} onClick={() => onSelectEvent(event.id)}>
            <Box
                position="absolute"
                left={5}
                top={-8}
                px={1}
                sx={{
                    background: (theme) => {
                        const outsideColor = theme.palette.secondary.contrastText;
                        const insideColor = isEventSelected ? theme.palette.tertiary.light : outsideColor;
                        return `linear-gradient(to bottom, ${outsideColor} 0%, ${outsideColor} 50%, ${insideColor} 50%, ${insideColor} 100%)`;
                    }
                }}
            >
                <BodyXS>{dayjs(event.date).format('HH:mm')}</BodyXS>
            </Box>
            <Box mt={1}>
                <BodyXS display="flex" textOverflow="ellipsis" whiteSpace="normal" overflow="hidden" fontWeight={500}>
                    {event?.title}
                </BodyXS>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
                <BodyXS color="text.secondary">{event?.type}</BodyXS>
                {event?.cost !== null ? (
                    <Box display="flex" alignItems="center" gap={1} maxWidth="100%">
                        <Box height="3px" width="3px" borderRadius="50%" bgcolor="text.secondary" />
                        <BodyXS color="text.secondary" display="flex" textOverflow="ellipsis" whiteSpace="normal" overflow="hidden">
                            {convertCostToFormattedValue(event.cost)}
                        </BodyXS>
                    </Box>
                ) : null}
            </Box>
            <Box mt={1} display="flex" alignItems="center" gap={1}>
                <UserAvatar userName={event.owner} showTooltip userId={event.ownerId} />
                <Tooltip title={event.priority}>{priorityInfo.icon}</Tooltip>
            </Box>
        </Paper>
    );
};

export default EventCard;
