import { BodyMedium } from '@parspec/pixel';

interface ITitle {
    title: string;
}

function Title({ title }: ITitle) {
    return (
        <BodyMedium fontWeight={500} color={'secondary.main'}>
            {title}
        </BodyMedium>
    );
}

export default Title;
