import { useEffect, useCallback } from 'react';
import * as React from 'react';

import { Box, H6, SelectChangeEvent } from '@parspec/pixel';

import { QuoteCoverPagePreference } from './components/QuoteCoverPagePreference';
import { QuoteHeaderFooterPreference } from './components/QuoteHeaderFooterPreference';
import { PricingLeadTime } from './components/PricingLeadTime';
import { GeneralTermsAndConditions } from './components/GeneralTermsAndConditions';
import { ManufacturerTermsAndConditions } from './components/ManufacturerTermsAndConditions';
import { QuoteTemplateInterface, IQuoteAttachmentDocumentsPreset } from '../../queries/apiTypes';
import { Attachments } from './components/Attachment/Attachments';
import { IFileType } from './utils/helper';
import {
    OPENING_NOTES_TEXT,
    PRE_FILL_ADDRESSED_TO,
    PRE_FILL_BID_DATE,
    PRE_FILL_EXPIRATION_DATE,
    PRE_FILL_HEADER_LOGO,
    PRE_FILL_JOB_NAME,
    PRE_FILL_QUOTE_NUMBER,
    QUOTE_COVER_PAGE,
    QUOTE_HEADER_FOOTER,
    QUOTE_PRICE_LEAD_TIME,
    SUMMARIZED_TOTAL
} from './utils/constants';

interface quoteCustomizationProps {
    quoteDetails: QuoteTemplateInterface;
    quoteTemplate: QuoteTemplateInterface;
    setQuoteTemplate: React.Dispatch<React.SetStateAction<QuoteTemplateInterface>>;
    intersectionObserverRef: any;
    intersectionObserverHandler: any;
    companyLocationId: number | null;
}

const QuoteTemplate = (props: quoteCustomizationProps) => {
    const { quoteDetails, quoteTemplate, setQuoteTemplate, intersectionObserverRef, intersectionObserverHandler, companyLocationId } = props;

    useEffect(() => {
        if (!quoteDetails) {
            return;
        }

        // adding the same s3 filepath in a new key "filepath" because in out fileUploader
        // the uploading logic is handled by the filepath key (refer line 66 in SelectedFiles.tsx in Pixel)
        // adding another key 'size' to make the key names uniform with those of the FileUploader
        const formattedDocumentData = quoteDetails?.attachments?.documents.map((el: IQuoteAttachmentDocumentsPreset) => ({ ...el, filepath: el.s3_url, size: el.size_in_byte }));

        setQuoteTemplate(() => ({
            ...quoteTemplate,
            quote_header_footer: {
                header_footer_section: quoteDetails.quote_header_footer.header_footer_section,
                job_name: quoteDetails.quote_header_footer.job_name,
                addressed_to: quoteDetails.quote_header_footer.addressed_to,
                project_location: quoteDetails.quote_header_footer.project_location,
                contact: quoteDetails.quote_header_footer.contact,
                quote_number: quoteDetails.quote_header_footer.quote_number,
                bid_date: quoteDetails.quote_header_footer.bid_date,
                expiration_date: quoteDetails.quote_header_footer.expiration_date,
                left_footer: quoteDetails.quote_header_footer.left_footer,
                right_footer: quoteDetails.quote_header_footer.right_footer,
                date: quoteDetails.quote_header_footer.date,
                page_number: quoteDetails.quote_header_footer.page_number,
                hyperlink: quoteDetails.quote_header_footer.hyperlink,
                pre_fill_job_name: quoteDetails.quote_header_footer.pre_fill_job_name,
                pre_fill_quote_number: quoteDetails.quote_header_footer.pre_fill_quote_number,
                pre_fill_bid_date: quoteDetails.quote_header_footer.pre_fill_bid_date,
                pre_fill_expiration_date: quoteDetails.quote_header_footer.pre_fill_expiration_date,
                pre_fill_header_logo: quoteDetails.quote_header_footer.pre_fill_header_logo,
                pre_fill_addressed_to: quoteDetails.quote_header_footer.pre_fill_addressed_to
            },
            quote_cover_page: {
                cover_page_section: quoteDetails.quote_cover_page.cover_page_section,
                stakeholders: quoteDetails.quote_cover_page.stakeholders,
                prepared_by: quoteDetails.quote_cover_page.prepared_by,
                addressed_to: quoteDetails.quote_cover_page.addressed_to,
                opening_notes: quoteDetails.quote_cover_page.opening_notes,
                opening_notes_text: {
                    value: quoteDetails.quote_cover_page.opening_notes_text?.value,
                    font: { color: quoteDetails.quote_cover_page.opening_notes_text?.font?.color, type: quoteDetails.quote_cover_page.opening_notes_text?.font?.type }
                },
                pre_fill_preparedby_logo: quoteDetails.quote_cover_page.pre_fill_preparedby_logo,
                pre_fill_addressed_to: quoteDetails.quote_cover_page.pre_fill_addressed_to
            },
            quote_price_lead_time: {
                price_and_lead_time_section: quoteDetails.quote_price_lead_time.price_and_lead_time_section,
                quantity: quoteDetails.quote_price_lead_time.quantity,
                type: quoteDetails.quote_price_lead_time.type,
                manufacturer: quoteDetails.quote_price_lead_time.manufacturer,
                model_number: quoteDetails.quote_price_lead_time.model_number,
                notes: quoteDetails.quote_price_lead_time.notes,
                lead_time: quoteDetails.quote_price_lead_time.lead_time,
                unit_price: quoteDetails.quote_price_lead_time.unit_price,
                extended_price: quoteDetails.quote_price_lead_time.extended_price,
                section_subtotal: quoteDetails.quote_price_lead_time.section_subtotal,
                datasheet_hyperlink: quoteDetails.quote_price_lead_time.datasheet_hyperlink,
                lot_breakout: quoteDetails.quote_price_lead_time.lot_breakout,
                service_breakout: quoteDetails.quote_price_lead_time.service_breakout,
                tax_breakout: quoteDetails.quote_price_lead_time.tax_breakout,
                freight_breakout: quoteDetails.quote_price_lead_time.freight_breakout,
                summarized_total: quoteDetails.quote_price_lead_time.summarized_total,
                show_kit_breakdown: quoteDetails.quote_price_lead_time.show_kit_breakdown,
                description: quoteDetails.quote_price_lead_time.description
            },
            quote_general_tnc: {
                general_tnc_section: quoteDetails.quote_general_tnc.general_tnc_section,
                closing_notes: quoteDetails.quote_general_tnc.closing_notes,
                closing_notes_text: {
                    value: quoteDetails.quote_general_tnc.closing_notes_text?.value,
                    font: { color: quoteDetails.quote_general_tnc.closing_notes_text?.font?.color, type: quoteDetails.quote_general_tnc.closing_notes_text?.font?.type }
                },
                project_notes: quoteDetails.quote_general_tnc.project_notes,
                project_notes_text: quoteDetails.quote_general_tnc.project_notes_text,
                general_tnc: quoteDetails.quote_general_tnc.general_tnc,
                general_tnc_text: quoteDetails.quote_general_tnc.general_tnc_text,
                is_general_tnc_locked: quoteDetails.quote_general_tnc.is_general_tnc_locked
            },
            quote_manufacturer_tnc: {
                manufacturer_tnc_section: quoteDetails.quote_manufacturer_tnc.manufacturer_tnc_section
            },
            quote_price_lead_time_option: {
                quantity: quoteDetails.quote_price_lead_time_option.quantity,
                type: quoteDetails.quote_price_lead_time_option.type,
                manufacturer: quoteDetails.quote_price_lead_time_option.manufacturer,
                model_number: quoteDetails.quote_price_lead_time_option.model_number,
                notes: quoteDetails.quote_price_lead_time_option.notes,
                substituted_for: quoteDetails.quote_price_lead_time_option.substituted_for,
                lead_time: quoteDetails.quote_price_lead_time_option.lead_time,
                unit_price: quoteDetails.quote_price_lead_time_option.unit_price,
                extended_price: quoteDetails.quote_price_lead_time_option.extended_price,
                add_deduct_amount: quoteDetails.quote_price_lead_time_option.add_deduct_amount,
                section_subtotal: quoteDetails.quote_price_lead_time_option.section_subtotal,
                datasheet_hyperlink: quoteDetails.quote_price_lead_time_option.datasheet_hyperlink,
                show_kit_breakdown: quoteDetails.quote_price_lead_time_option.show_kit_breakdown,
                option_notes: quoteDetails.quote_price_lead_time_option.option_notes,
                option_notes_text: {
                    value: quoteDetails.quote_price_lead_time_option.option_notes_text?.value === null ? '' : quoteDetails.quote_price_lead_time_option.option_notes_text?.value,
                    font: { color: quoteDetails.quote_price_lead_time_option.option_notes_text?.font?.color, type: quoteDetails.quote_price_lead_time_option.option_notes_text?.font?.type }
                },
                description: quoteDetails.quote_price_lead_time_option.description
            },
            attachments: { is_enable: quoteDetails?.attachments?.is_enable, company: companyLocationId, documents: formattedDocumentData }
        }));
    }, [quoteDetails]);

    useEffect(() => {
        const options = {
            root: document.getElementById('addEditBranchLocScrollContainerRoot'),
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver(intersectionObserverHandler, options);

        if (intersectionObserverRef.current) {
            observer.observe(intersectionObserverRef.current);
        }

        return () => {
            if (intersectionObserverRef.current) {
                observer.unobserve(intersectionObserverRef.current);
            }
        };
    }, []);

    // Ask hrishikesh about Header and footer.
    const changeHandler = (event: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => {
        const fieldName = field_name ? field_name : event.target?.name;
        const checkedField = event.target as HTMLInputElement;
        if (presetName === 'attachments') {
            // for toggling attachment switch
            setQuoteTemplate(() => ({ ...quoteTemplate, [presetName]: { ...quoteTemplate[presetName], [fieldName]: checkedField.checked } }));
            return;
        }

        const isOpeningNotesFromCoverPage = presetName === QUOTE_COVER_PAGE && fieldName === OPENING_NOTES_TEXT;
        const isOptionNotesFromPriceLeadTime = presetName === 'quote_price_lead_time_option' && fieldName === 'option_notes_text';
        const isClosingNotesFromGeneralTnC = presetName === 'quote_general_tnc' && fieldName === 'closing_notes_text';

        const isSumarrisedTotal = presetName === QUOTE_PRICE_LEAD_TIME && fieldName === SUMMARIZED_TOTAL;

        const isTextFromQuoteHeaderFooter =
            presetName === QUOTE_HEADER_FOOTER &&
            (fieldName === PRE_FILL_JOB_NAME ||
                fieldName === PRE_FILL_QUOTE_NUMBER ||
                fieldName === PRE_FILL_BID_DATE ||
                fieldName === PRE_FILL_EXPIRATION_DATE ||
                fieldName === PRE_FILL_HEADER_LOGO ||
                fieldName === PRE_FILL_ADDRESSED_TO);

        const isPreparedByLogo = presetName === 'quote_cover_page' && fieldName === 'pre_fill_preparedby_logo';
        if (isOpeningNotesFromCoverPage || isClosingNotesFromGeneralTnC || isOptionNotesFromPriceLeadTime) {
            const value = event.target?.value;

            return setQuoteTemplate(() => ({
                ...quoteTemplate,
                [presetName]: { ...quoteTemplate[presetName], [fieldName]: { ...quoteTemplate[presetName][fieldName], value: value } }
            }));
        }

        let fieldValue: string | boolean;
        if (isSumarrisedTotal || isTextFromQuoteHeaderFooter || isPreparedByLogo || (presetName === QUOTE_COVER_PAGE && fieldName === PRE_FILL_ADDRESSED_TO)) {
            fieldValue = event.target?.value as string;
        } else {
            fieldValue = checkedField.checked;
        }

        setQuoteTemplate(() => ({ ...quoteTemplate, [presetName]: { ...quoteTemplate[presetName], [fieldName]: fieldValue } }));
    };

    const textEditorOnChangeHandler = useCallback(
        (html: string, presetName: string, fieldName: string) => {
            setQuoteTemplate(() => ({ ...quoteTemplate, [presetName]: { ...quoteTemplate[presetName], [fieldName]: html } }));
        },
        [quoteTemplate]
    );

    const handleColorAndFontTypeChange = (value: string, presetName: string, fieldName: string, fieldType: string) => {
        if (fieldType === 'type') {
            setQuoteTemplate(() => ({
                ...quoteTemplate,
                [presetName]: { ...quoteTemplate[presetName], [fieldName]: { ...quoteTemplate[presetName][fieldName], font: { ...quoteTemplate[presetName][fieldName]?.font, type: value } } }
            }));
        } else if (fieldType === 'color') {
            setQuoteTemplate(() => ({
                ...quoteTemplate,
                [presetName]: { ...quoteTemplate[presetName], [fieldName]: { ...quoteTemplate[presetName][fieldName], font: { ...quoteTemplate[presetName][fieldName]?.font, color: value } } }
            }));
        }
    };

    const handleAttachments = (files: IFileType[]) => {
        const newFiles = files.map((item: IFileType) => ({ name: item.file.name, size: item.file.size, filepath: item.s3_file_path, s3_url: item.s3_file_path! }));
        setQuoteTemplate(() => ({ ...quoteTemplate, attachments: { ...quoteTemplate.attachments, documents: newFiles } }));
    };

    const handleLockChange = (value: boolean) => {
        setQuoteTemplate(() => ({ ...quoteTemplate, ['quote_general_tnc']: { ...quoteTemplate['quote_general_tnc'], ['is_general_tnc_locked']: value } }));
    };

    return (
        <Box bgcolor="primary.contrastText">
            <Box>
                <H6>Quote Template Preferences</H6>
                <Box mt={6}>
                    <QuoteHeaderFooterPreference changeHandler={changeHandler} templateState={quoteTemplate} />
                    <QuoteCoverPagePreference changeHandler={changeHandler} templateState={quoteTemplate} handleColorAndFontTypeChange={handleColorAndFontTypeChange} />
                    <PricingLeadTime changeHandler={changeHandler} templateState={quoteTemplate} handleColorAndFontTypeChange={handleColorAndFontTypeChange} />
                    <GeneralTermsAndConditions
                        changeHandler={changeHandler}
                        templateState={quoteTemplate}
                        handleColorAndFontTypeChange={handleColorAndFontTypeChange}
                        onLockToggle={handleLockChange}
                        onChangeHtml={textEditorOnChangeHandler}
                    />
                    <ManufacturerTermsAndConditions changeHandler={changeHandler} templateState={quoteTemplate} />
                    <Attachments changeHandler={changeHandler} handleAttachments={handleAttachments} templateState={quoteTemplate} />
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(QuoteTemplate);
