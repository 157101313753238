import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import { addContact, deleteContacts, getContactDetail, editContact, endpoints, getContactsData } from './apis';
import { ContactResponse } from '../../../Dashboard/queries/apiTypes';
import { invalidateCompanyDetailQuery } from '../../Company/queries';
import { Contact, ContactsResponse } from './apiTypes';
import { invalidateCompanyDropdownQuery } from 'src/features/shared/queries';

//Keys
const getContactsDataKey = (url: string) => ['contacts', url];
const getContactDetailKey = (contactId: number) => [endpoints.contactDetail(contactId)];

//Invalidations
export const invalidateGetContactsQuery = () => queryClient.invalidateQueries(['contacts']);

export const invalidateContactDetailQuery = (contactId: number) => {
    queryClient.invalidateQueries(getContactDetailKey(contactId));
};

//Queries
export const useGetContactsQuery = (url: string, options?: Omit<UseQueryOptions<AxiosResponse<ContactsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getContactsDataKey(url), () => getContactsData(url), options);

export const useGetContactDetailQuery = (contactId: number, options: Omit<UseQueryOptions<AxiosResponse<Contact>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getContactDetailKey(contactId), () => getContactDetail(contactId), {
        ...options,
        staleTime: 0
    } as Omit<UseQueryOptions<AxiosResponse<Contact>>, 'queryKey' | 'queryFn'>);

//Mutations
export const useAddContactMutation = () =>
    useMutation(addContact, {
        onSuccess: (_, _request) => {
            invalidateGetContactsQuery();
            invalidateCompanyDetailQuery(_request.company);
            invalidateCompanyDropdownQuery();
        }
    });

export const useEditContactMutation = () =>
    useMutation(editContact, {
        onSuccess: (_, request) => {
            const { id } = request;
            invalidateGetContactsQuery();
            invalidateContactDetailQuery(id);
            invalidateCompanyDropdownQuery();
        }
    });

export const useDeleteContactsMutation = () =>
    useMutation(deleteContacts, {
        onSuccess: (_, request) => {
            queryClient.setQueriesData(['contacts'], (oldData: any) => {
                return {
                    ...oldData,
                    data: { ...oldData.data, results: oldData.data.results.filter((item: ContactResponse) => !request.includes(item.id)) }
                };
            });
            invalidateCompanyDropdownQuery();
        }
    });
