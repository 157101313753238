import { Grid, BodyXS, Button, Paper, SyncProblemIcon, AddIcon } from '@parspec/pixel';

export type CommonTypes = {
    handleCreateNew: () => void;
    isAutoSync: boolean;
    content: React.ReactNode;
};

const CommonCard: React.FC<CommonTypes> = ({ handleCreateNew, isAutoSync, content }) => (
    <Paper
        sx={{
            width: 348,
            height: 494
        }}
        variant="elevation"
        elevation={3}
    >
        <Grid container direction={'column'} position="relative" height={'100%'}>
            <Grid item bgcolor={'tertiary.light'} sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        flexGrow: 1,
                        height: '100%'
                    }}
                >
                    <Grid item>
                        {isAutoSync ? (
                            <SyncProblemIcon
                                sx={{
                                    fontSize: 60
                                }}
                                color="tertiary"
                            />
                        ) : (
                            <AddIcon
                                sx={{
                                    fontSize: 60
                                }}
                                color="tertiary"
                            />
                        )}
                    </Grid>
                    <Grid item width={234}>
                        {content}
                    </Grid>
                </Grid>
            </Grid>
            {/* label */}
            <Grid
                item
                p={1}
                sx={{
                    width: 114,
                    position: 'absolute',
                    top: 8,
                    left: 14,
                    borderRadius: 1,
                    textAlign: 'center'
                }}
                bgcolor={'tertiary.main'}
            >
                <BodyXS fontWeight={700} color={'neutral.light'}>
                    DRAFT VERSION
                </BodyXS>
            </Grid>
            {/* actions */}
            <Grid item p={3} borderRadius={2}>
                <Button size={'large'} fullWidth onClick={handleCreateNew}>
                    Create New Version
                </Button>
            </Grid>
        </Grid>
    </Paper>
);

export default CommonCard;
