import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { docType } from 'src/features/shared/constants';
import {
    getProjectsTabData,
    getBOMTabData,
    getStats,
    getSubmittalTabData,
    deleteProject,
    deleteBom,
    duplicateBomData,
    getOmPackageTabData,
    endPoints,
    getQuotesTabData,
    getOutOfSyncData,
    getDocumentTags,
    getCompaniesList,
    getUserViews,
    getSelectedColumnsAndFilters,
    updateSelectedColumns,
    updateSelectedFilters
} from './apis';
import { queryClient } from 'src/app/queryClient';
import { invalidateProjectBomsQuery } from '../../Project/queries';
import { AxiosResponse } from 'axios';
import { BomInfoResponse, ProjectInfoResponse, ViewOption, DashboardPageNameType, SubmittalInfoResponse, OMInfoResponse, QuoteInfoResponse, OutofSyncResponse, CompanyResponse } from './apiTypes';
import { pushEvent } from '../../shared/UserActivityTracking/EventService';
import { invalidateGetAllCompanyDetailsKey } from '../../shared/CreateAndEditProjectModal/queries';

//keys
const getSyncKey = (docType: docType) => [endPoints.getOutOfSyncUrl(docType)];
const projectsKey = (selectedView?: ViewOption) => [endPoints.project(selectedView)];
const bomsKey = (selectedView?: ViewOption) => [endPoints.bom(selectedView)];
const submittalKey = (selectedView?: ViewOption) => [endPoints.submittal(selectedView)];
const omPackageKey = (selectedView?: ViewOption) => [endPoints.o_m(selectedView)];
const quotesKey = (selectedView?: ViewOption) => [endPoints.quotes(selectedView)];
const getQuickStatsKey = (due: string, owned_by: string) => [endPoints.stats(due, owned_by)];
const documentTagsKey = (docType: docType) => [endPoints.documentTags(docType)];
const getCompaniesKey = [endPoints.companies];
const getDashboardViewKey = ['dashboardView'];

export const useGetProjectsQuery = (selectedView?: ViewOption, options?: Omit<UseQueryOptions<AxiosResponse<ProjectInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(projectsKey(selectedView), () => getProjectsTabData(selectedView), { ...options, staleTime: 5000 } as Omit<UseQueryOptions<AxiosResponse<ProjectInfoResponse>>, 'queryKey' | 'queryFn'>);

export const useGetBOMQuery = (selectedView?: ViewOption, options?: Omit<UseQueryOptions<AxiosResponse<BomInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(bomsKey(selectedView), () => getBOMTabData(selectedView), { ...options, staleTime: 5000 } as Omit<UseQueryOptions<AxiosResponse<BomInfoResponse>>, 'queryKey' | 'queryFn'>);

export const useGetSubmittalQuery = (selectedView?: ViewOption, options?: Omit<UseQueryOptions<AxiosResponse<SubmittalInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(submittalKey(selectedView), () => getSubmittalTabData(selectedView), { ...options, staleTime: 5000 } as Omit<
        UseQueryOptions<AxiosResponse<SubmittalInfoResponse>>,
        'queryKey' | 'queryFn'
    >);

export const useGetOmPackageQuery = (selectedView?: ViewOption, options?: Omit<UseQueryOptions<AxiosResponse<OMInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(omPackageKey(selectedView), () => getOmPackageTabData(selectedView), { ...options, staleTime: 5000 } as Omit<UseQueryOptions<AxiosResponse<OMInfoResponse>>, 'queryKey' | 'queryFn'>);

export const useGetStatsQuery = (due: string, ownedBy: string) => useQuery(getQuickStatsKey(due, ownedBy), () => getStats(due, ownedBy));

export const useGetQuotesQuery = (selectedView?: ViewOption, options?: Omit<UseQueryOptions<AxiosResponse<QuoteInfoResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(quotesKey(selectedView), () => getQuotesTabData(selectedView), { ...options, staleTime: 5000 } as Omit<UseQueryOptions<AxiosResponse<QuoteInfoResponse>>, 'queryKey' | 'queryFn'>);

export const useDeleteProjectMutation = () => {
    return useMutation(deleteProject, {
        onSuccess(_data, variables) {
            deleteProjectInDashboard(variables);
            invalidateGetAllCompanyDetailsKey();
        }
    });
};

export const useDeleteBomMutation = () => {
    return useMutation(deleteBom, {
        onSuccess(_data, variables) {
            deleteBomInDashboard(variables);
        }
    });
};

export const useDuplicateBomDataMutation = () => {
    return useMutation(duplicateBomData, {
        onSuccess: (_data, variables) => {
            invalidateBomInfoInDashboard();
            invalidateProjectBomsQuery(variables.projectId);
        }
    });
};

export const useOutOfSyncQuery = (docType: docType, options?: Omit<UseQueryOptions<AxiosResponse<OutofSyncResponse>>, 'queryKey' | 'queryFn'>) => {
    const queryKey = getSyncKey(docType);
    return useQuery(queryKey, () => getOutOfSyncData(docType), { staleTime: 0, ...options } as Omit<UseQueryOptions<AxiosResponse<OutofSyncResponse>>, 'queryKey' | 'queryFn'>);
};

export function invalidateBomInfoInDashboard() {
    queryClient.invalidateQueries(bomsKey(), { exact: true });
    queryClient.invalidateQueries(bomsKey('my_work'), { exact: true });
}

export function invalidateProjectInfoInDashboard() {
    queryClient.invalidateQueries(projectsKey(), { exact: true });
    queryClient.invalidateQueries(projectsKey('my_work'), { exact: true });
}

export function invalidateSubmittalInfoInDashboard() {
    queryClient.invalidateQueries(submittalKey(), { exact: true });
    queryClient.invalidateQueries(submittalKey('my_work'), { exact: true });
}

export function invalidateOmPackageInfoInDashboard() {
    queryClient.invalidateQueries(omPackageKey(), { exact: true });
    queryClient.invalidateQueries(omPackageKey('my_work'), { exact: true });
}
export function invalidateQuoteInfoInDashboard() {
    queryClient.invalidateQueries(quotesKey(), { exact: true });
    queryClient.invalidateQueries(quotesKey('my_work'), { exact: true });
}

export function deleteProjectInDashboard(projectId: number) {
    queryClient.setQueryData(projectsKey(), (oldData?: AxiosResponse<ProjectInfoResponse>) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.filter((item) => item.id !== projectId)
        };
    });
    queryClient.setQueryData(projectsKey('my_work'), (oldData?: AxiosResponse<ProjectInfoResponse>) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.filter((item) => item.id !== projectId)
        };
    });
}

export function deleteBomInDashboard(bomId: number) {
    queryClient.setQueryData(bomsKey(), (oldData?: AxiosResponse<BomInfoResponse>) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.filter((item) => item?.bom?.id !== bomId)
        };
    });
    queryClient.setQueryData(bomsKey('my_work'), (oldData?: AxiosResponse<BomInfoResponse>) => {
        if (!oldData) return oldData;
        return {
            ...oldData,
            data: oldData.data.filter((item) => item?.bom?.id !== bomId)
        };
    });
}

export function useDocumentTags(docType: docType) {
    return useQuery({
        queryKey: documentTagsKey(docType),
        queryFn: () => getDocumentTags(docType),
        staleTime: 0
    });
}

export function invalidateDocumentTags(docType: docType) {
    queryClient.invalidateQueries(documentTagsKey(docType));
}

export const useGetCompaniesQuery = (options?: Omit<UseQueryOptions<AxiosResponse<CompanyResponse[]>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getCompaniesKey, () => getCompaniesList(), { staleTime: 0, ...options } as Omit<UseQueryOptions<AxiosResponse<CompanyResponse[]>>, 'queryKey' | 'queryFn'>);

//Using 'any' because this is graphQL response instead of AxiosResponse which doesn't have 'data' attribute
export const useGetUserViewsPreferenceQuery = (userId: number, options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) => useQuery(getDashboardViewKey, () => getUserViews(userId), options);

const updateUserDashboardView = (newView: string) => {
    queryClient.setQueryData(getDashboardViewKey, (oldData?: any) => {
        return {
            ...oldData,
            user_view_preference: [
                {
                    dashboard_view: newView
                }
            ]
        };
    });
};
export const userDashboardViewMutation = async (userId: number, newView: string) => {
    const insertMutation = `mutation {
        events_db {
          insert_user_view_preference(objects: {dashboard_view: "${newView}"}) {
            affected_rows
          }
        }
      }`;

    const updateMutation = `mutation {
        events_db {
          update_user_view_preference(where: {user_id: {_eq: ${userId}}}, _set: {dashboard_view: "${newView}"}) {
            affected_rows
          }
        }
      }`;

    updateUserDashboardView(newView);
    await pushEvent({ insertMutation, updateMutation, responseAttribute: 'user_view_preference' });
};

const selectedColumnsAndFiltersApiKey = (pageName: DashboardPageNameType) => [`selected-columns-and-filters-for-${pageName}`];
//Using 'any' because this is graphQL response instead of AxiosResponse which doesn't have 'data' attribute
export const useGetSelectedColumnsAndPersistingFiltersQuery = (payload: { userId: number; pageName: DashboardPageNameType }, options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
    useQuery(selectedColumnsAndFiltersApiKey(payload.pageName), () => getSelectedColumnsAndFilters(payload), options);

export const useUpdateSelectedColumnsMutation = () =>
    useMutation(updateSelectedColumns, {
        onSuccess: (_, variables) => {
            invalidateUseGetSelectedColumnsAndFiltersQuery(variables.pageName);
        }
    });
export const invalidateUseGetSelectedColumnsAndFiltersQuery = (pageName: DashboardPageNameType) => {
    queryClient.invalidateQueries(selectedColumnsAndFiltersApiKey(pageName));
};

export const useUpdatePersistingFiltersMutation = () =>
    useMutation(updateSelectedFilters, {
        onMutate: (variables) => {
            queryClient.setQueryData(selectedColumnsAndFiltersApiKey(variables.pageName), (oldData?: any) => {
                if (!oldData) return oldData;
                return {
                    ...(oldData || {}),
                    table_settings: [{ ...oldData.table_settings?.[0], filters: variables.filterData }]
                };
            });
        }
    });
