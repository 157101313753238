import { useRef, useMemo, useState } from 'react';

import { ICellRendererParams, ValueGetterParams, ColDef } from 'ag-grid-community';
import { isEqual } from 'lodash-es';

import { Snackbar, Box, TableHeaderMenuIcon, AgGridTable, SelectChangeEvent } from '@parspec/pixel';

import {
    getStageTemplate,
    getBomRowData,
    getSyncCache,
    getDocumentOwnerTemplate,
    getDateForTable,
    getDateComparator,
    stringComparator,
    getDateValueForUI,
    dateFilterParams
} from '../shared/utils/utils';
import {
    useGetOmPackageQuery,
    useOutOfSyncQuery,
    useDocumentTags,
    userDashboardViewMutation,
    useGetSelectedColumnsAndPersistingFiltersQuery,
    useUpdateSelectedColumnsMutation,
    useUpdatePersistingFiltersMutation
} from '../queries';
import { DashboardPageNameType } from '../queries/apiTypes';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { OM_DOC } from '../../shared/constants';
import { TAB_LABEL } from '../shared/constants';
import { BomTablesDataType, SyncTypeForTable } from '../shared/utils';
import { OMInfoForDashboard, DocInfo } from '../queries/apiTypes';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import { useGetUserDashboardViews } from '../shared/hooks';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { MenuOption, defaultColDef } from './components';
import { MenuContext } from './context';
import { getRowId, getOMStatusTemplate } from './utils';
import TableRightSection from '../shared/components/TableRightSection';
import { ManageColumnsPopup } from '../shared/components/ManageColumnsPopup/ManageColumnsPopup';
import { omPageTableColumnOptions } from './utils';
import { DateTemplate, DocumentIdTemplate, BomTemplate, ProjectTemplate } from '../shared/components';

const defaultLockColumnDef: ColDef[] = [
    {
        minWidth: 60,
        maxWidth: 60,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        cellStyle: { backgroundColor: 'unset' },
        cellRenderer: MenuOption,
        editable: false,
        pinned: 'right',
        colId: 'menu',
        resizable: false,
        lockPosition: 'right',
        lockPinned: true
    }
];

const OmPackageTable = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { isDashboardViewsLoading, dashboardSelectedViews } = useGetUserDashboardViews();

    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();
    const { data: paymentDetail, isLoading: isPaymentDetailLoading } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const { data: omPackageData, isLoading } = useGetOmPackageQuery(dashboardSelectedViews, { enabled: !isDashboardViewsLoading });
    const { data: omSyncData } = useOutOfSyncQuery(OM_DOC);
    const { data: documentTagsData } = useDocumentTags(OM_DOC);

    const { data: selectedColumnsAndFiltersQueryData, isFetching: isSelectedColumnsAndFiltersQueryDataFetching } = useGetSelectedColumnsAndPersistingFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: 'dashboard_om' },
        { enabled: !isDashboardViewsLoading && !isUserDataLoading }
    );
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();
    const { mutateAsync: updateSelectedFiltersMutation } = useUpdatePersistingFiltersMutation();

    const tableRef = useRef<any>();

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const oMOutOfSyncCache = useMemo(() => {
        if (omSyncData?.data) return getSyncCache(omSyncData.data);
        return null;
    }, [omSyncData]);

    const omTableData = useMemo(() => {
        if (!omPackageData?.data) {
            return null;
        }

        const omTableDataWithSyncAndTags = omPackageData.data.map((data) => {
            const bomId = data?.bom?.id;
            const currentBomData: OMInfoForDashboard & SyncTypeForTable = { ...data };
            if (bomId) {
                currentBomData.omOutOfSync = oMOutOfSyncCache?.[bomId] || false;
            }

            if (bomId && documentTagsData?.data && currentBomData?.o_m) {
                currentBomData.o_m.forEach((item: DocInfo) => {
                    for (const omDoc of documentTagsData.data) {
                        if (item.bom_id === omDoc.bom_id && item.id === omDoc.entity_id && omDoc.entity_type === OM_DOC) {
                            item.tag_name = omDoc.tag_name;
                        }
                    }
                });
            }

            return currentBomData;
        });

        return omTableDataWithSyncAndTags;
    }, [omPackageData?.data, oMOutOfSyncCache, documentTagsData?.data]);

    const handleViewChange = (props: SelectChangeEvent<unknown>) => {
        if (userData?.data.id) userDashboardViewMutation(userData?.data?.id, props?.target?.value as string);
    };

    const defaultColumnDef: any = {
        omId: {
            field: 'omId',
            headerName: 'O&M Package ID',
            minWidth: 180,
            cellRenderer: DocumentIdTemplate,
            sortable: true
        },
        status: {
            field: 'status',
            headerName: 'Status',
            cellRenderer: getOMStatusTemplate,
            minWidth: 140,
            filter: 'agSetColumnFilter',
            suppressMenu: false,
        },
        quoteNumber: {
            field: 'quoteNumber',
            headerName: 'Quote ID',
            minWidth: 140,
            sortable: true
        },
        omVersion: {
            field: 'omVersion',
            headerName: 'Version',
            minWidth: 100,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        omCreationDate: {
            field: 'omCreationDate',
            headerName: 'Creation Date',
            minWidth: 200,
            cellRenderer: (params: any) => getDateForTable(params?.data?.omCreationDate),
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'omCreationDate'),
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'omCreationDate'),
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            },
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        omCreatedBy: {
            field: 'omCreatedBy',
            headerName: 'Created By',
            minWidth: 200,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        omProductCount: {
            field: 'omProductCount',
            headerName: 'Products Included',
            minWidth: 165,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        dueDate: {
            field: 'dueDate',
            headerName: 'O&M Due Date',
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.dueDate} />,
            minWidth: 210,
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'dueDate'),
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'dueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        omOwner: {
            field: 'omOwner',
            headerName: 'O&M Owner',
            minWidth: 210,
            cellRenderer: (props: ICellRendererParams) => getDocumentOwnerTemplate(props, 'SUBMITTAL'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        bomName: {
            field: 'bomName',
            headerName: 'Bill Of Materials',
            minWidth: 220,
            cellRenderer: (params: ICellRendererParams) => <BomTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectName: {
            field: 'projectName',
            headerName: 'Project',
            minWidth: 200,
            cellRenderer: (params: ICellRendererParams) => <ProjectTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectStage: {
            field: 'projectStage',
            headerName: 'Project Stage',
            minWidth: 180,
            cellRenderer: getStageTemplate,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        branchLocation: {
            field: 'branchLocation',
            headerName: 'Branch Location',
            minWidth: 180,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        }
    };

    function handleExportExcel() {
        const params = {
            fileName: 'O&Ms_Parspec_Export.xlsx', // Specify the filename
            sheetName: 'O&Ms' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number } = { pageName: 'dashboard_om', columnData: rowData, userId: userData?.data?.id || 0 };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const omDataForTable = useMemo(() => {
        let omTabRowData: Array<BomTablesDataType> = [];
        const omTableDataForUi: Array<BomTablesDataType> = [];

        if (!omTableData) {
            return null;
        }

        if (!isLoading && omTableData) {
            omTabRowData = omTableData.map(getBomRowData(TAB_LABEL.OM_TAB_LABEL));

            for (const item of omTabRowData) {
                if (item?.omData) {
                    for (const om of item.omData) {
                        omTableDataForUi.push({
                            ...item,
                            omId: om?.id || 0,
                            shareLink: om?.share_link || null,
                            status: om?.tag_name || null,
                            omVersion: om?.version || null,
                            omCreationDate: om?.created_at || null,
                            omCreatedBy: om?.compiled_by || null,
                            omProductCount: om?.product_count || null,
                            quoteNumber: om?.quote_number || null
                        });
                    }
                }
            }
        }

        return omTableDataForUi;
    }, [omTableData, isLoading]);

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsAndFiltersQueryData?.table_settings || isSelectedColumnsAndFiltersQueryDataFetching) {
            return;
        }

        if ((selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.prototype.hasOwnProperty.call(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [isSelectedColumnsAndFiltersQueryDataFetching, selectedColumnsAndFiltersQueryData?.table_settings, omDataForTable]);

    const onFirstDataRendered = (params: any) => {
        if (selectedColumnsAndFiltersQueryData?.table_settings.length === 0 || Object.keys(selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters || {}).length === 0) {
            return;
        }

        const response = selectedColumnsAndFiltersQueryData?.table_settings?.[0].filters;

        const filterKeys = Object.keys(response);
        filterKeys.forEach((key: string) => {
            params.api.getFilterInstance(key).setModel(response[key]);
        });

        params.api.onFilterChanged();
    };

    const handleFilterChanged = async (params: any) => {
        const { api } = params;
        const filterModel = api.getFilterModel();

        const apiResponse = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters;

        if (!isEqual(filterModel, apiResponse)) {
            // call mutation
            const payload: { pageName: DashboardPageNameType; filterData: any; userId: number } = { pageName: 'dashboard_om', filterData: filterModel, userId: userData?.data?.id || 0 };
            await updateSelectedFiltersMutation(payload);
        }
    };

    const isTableDataLoading = isLoading || isUserDataLoading || isPaymentDetailLoading || isSelectedColumnsAndFiltersQueryDataFetching || isUpdateSelectedColumnsMutationLoading;

    return (
        <>
            <Snackbar open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <MenuContext.Provider value={{ isActiveSubscription, setSnackbarMessage, handleSubscriptionPopup, tabName: 'OM' }}>
                <AgGridTable
                    ref={tableRef}
                    getRowId={getRowId}
                    isLoading={isTableDataLoading}
                    rowHeight={52}
                    showToolbarPanel
                    toolBarPanelOptions={['search', 'clearFilters']}
                    onClearFilter={clearAllFilters}
                    toolbarRightSection={
                        <TableRightSection
                            selectedView={dashboardSelectedViews}
                            isMyViewLoading={isDashboardViewsLoading}
                            onMyViewChange={handleViewChange}
                            isTableLoading={isLoading}
                            onExportClick={handleExportExcel}
                            onManageColumnsClick={onManageColumnsClick}
                        />
                    }
                    rowData={omDataForTable}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    pagination
                    paginationAutoPageSize
                    suppressContextMenu
                    suppressMenuHide
                    onTextSearch={handleSearch}
                    quickFilterText={searchText}
                    onFirstDataRendered={onFirstDataRendered}
                    onFilterChanged={handleFilterChanged}
                />
            </MenuContext.Provider>
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={omPageTableColumnOptions.data}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryDataFetching={isSelectedColumnsAndFiltersQueryDataFetching}
                />
            )}
        </>
    );
};

export default OmPackageTable;
