export const tocConfigurations = [
  {
    field_name: 'toc_section',
    is_active: true,
  },
  {
    field_name: 'primary_title',
    is_active: true,
  },
  {
    field_name: 'secondary_title',
    is_active: true,
  },
  {
    field_name: 'project_number',
    is_active: true,
  },
  {
    field_name: 'quote_number',
    is_active: true,
  },
  {
    field_name: 'hyperlink',
    is_active: true,
  },
  {
    field_name: 'type',
    is_active: true,
  },
  {
    field_name: 'quantity',
    is_active: true,
  },
  {
    field_name: 'page_number',
    is_active: true,
  },
];
