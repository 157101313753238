import { useQuery, useMutation } from '@tanstack/react-query';
import { invalidateDatasheetDataQuery } from '../../queries';

import { getProductDatasheetBound, confirmSubmittalPackage, adjustPageUpdate, addDocumentToBods } from './apis';
import { endPoints } from './apis';

// keys
export const getBoundDatasheetKey = (bomId: string, submitalPackageId: number) => [endPoints.dataSheetBound(bomId, submitalPackageId)];

// queries
export const useDatasheetBoundQuery = (bomId: string, submitalPackageId: number, options: object) =>
    useQuery(getBoundDatasheetKey(bomId, submitalPackageId), () => getProductDatasheetBound(bomId, submitalPackageId), options);

// mutation
export const useUpdateAdjustPageMutation = () => useMutation(adjustPageUpdate);
export const useConfirmSubmittalPackageMutation = () =>
    useMutation(confirmSubmittalPackage, {
        onSuccess: (_, request) => {
            invalidateDatasheetDataQuery(request.bomId);
        }
    });

export const useAddDocumentToBodsMutation = () => {
    return useMutation(addDocumentToBods, {
        onSuccess: (_, request) => {
            invalidateDatasheetDataQuery(String(request.bomId));
        }
    });
};
