import { useState } from 'react';

import { Box } from '@parspec/pixel';

import DocumentItem, { Document } from '../../../../../shared/DocumentSuggestions/DocumentItem';
import { AccordionCarousal } from './AccordionCarousal';
import { ProductCarouselProps, SearchResultProps } from '../../queries/apiTypes';

const ProductCarousel = ({ limit, title, data, setSelectedDocument, selectedDocument, isSourceDataSheet, isAllDataFetching, setPreSelectedDocument }: ProductCarouselProps) => {
    const [currentPage, setPage] = useState<number>(1);
    const currentTransform = -(currentPage - 1) * 100;
    const totalPages = Math.ceil(data.length / limit);

    return (
        <AccordionCarousal totalPages={totalPages} page={currentPage} dataLength={data.length} title={title} setPage={setPage} isAllDataFetching={isAllDataFetching}>
            <Box display={'flex'} gap={4} position={'relative'} overflow={'hidden'} width={`${limit * 328}px`} pt={5} pb={5} marginLeft={4}>
                <Box display={'flex'} width={'inherit'} gap={3} sx={{ transform: `translateX(${currentTransform}%)`, transition: 'transform 0.5s ease' }}>
                    {data.map((item: Document | SearchResultProps, index: number) => (
                        <Box key={index} paddingLeft={2}>
                            <DocumentItem
                                item={item as Document}
                                key={index}
                                index={index}
                                total={data.length}
                                dataList={data as Document[]}
                                setSelectedDocument={setSelectedDocument}
                                selectedDocument={selectedDocument}
                                setPreSelectedDocument={setPreSelectedDocument}
                                productFinderMode={true}
                                isSourceDataSheet={isSourceDataSheet}
                                subDocumentType={'datasheet'}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </AccordionCarousal>
    );
};
export default ProductCarousel;
