import { FC, useEffect, useState } from 'react';

import { Autocomplete, BodySmall, BodyXS, Box, Button, Grid, OpenInNewIcon, Skeleton, OptionType, CustomRowElProps } from '@parspec/pixel';

import { CompanyObjType } from '../ContactManagement/Company/queries/apiTypes';
import { Contact } from '../ContactManagement/Contacts/queries/apiTypes';
import useBroadCaster, { BROADCAST_MESSAGES } from './hooks/useBroadCaster';

import { invalidateCompanyDropdownQuery, invalidateContactDropdownQuery, useGetCompanyDropDownQuery, useGetContactDropDownQuery } from './queries';
import { removeCompaniesDuplicateRecords } from '../ContactManagement/shared/utils';

const companyAddOption = { name: 'Create New', id: 0, business_type: 'none' };
const contactAddOption = { name: 'Create New', id: 0, role: 'none' };

interface CompanyContactSelectorProps {
    companyInfo: any;
    setCompanyInfo: (info: any) => void;
    contactInfo: any;
    setContactInfo: (info: any) => void;
    contactError?: string;
    companyError?: string;
    locationId?: number;
    companyLabel?: string;
    contactLabel?: string;
    isLoading?: boolean;
    retainCustomer?: boolean;
}

const CompanyContactSelector: FC<CompanyContactSelectorProps> = ({
    companyInfo,
    setCompanyInfo,
    contactInfo,
    setContactInfo,
    companyError,
    contactError,
    locationId,
    companyLabel,
    contactLabel,
    isLoading,
    retainCustomer
}) => {
    const [openCompanyAutocomplete, setOpenCompanyAutocomplete] = useState(false);
    const [openContactAutocomplete, setOpenContactAutocomplete] = useState(false);
    const [companiesList, setCompaniesList] = useState<CompanyObjType[]>();
    const [contactsList, setContactsList] = useState<Contact[]>([]);
    const { message } = useBroadCaster();

    const { data: companyApiData, isLoading: companiesLoading, isFetching: companiesFetching } = useGetCompanyDropDownQuery();
    const { data: contactsApiData, isFetching: contactsFetching } = useGetContactDropDownQuery();

    useEffect(() => {
        if (companyApiData?.data) {
            const arr = removeCompaniesDuplicateRecords([...(companiesList || []), ...companyApiData?.data]);
            const sortedArr = arr.sort((a: CompanyObjType, b: CompanyObjType) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setCompaniesList(sortedArr);
        }
    }, [companyApiData]);

    useEffect(() => {
        if (contactsApiData?.data && companyInfo) {
            const filteredContacts = contactsApiData.data.filter((item: Contact) => item.company_id === companyInfo.id);
            setContactsList(filteredContacts);
        }
    }, [contactsApiData, companyInfo]);

    //to set the first option in case of deleted company
    useEffect(() => {
        if (companiesList && companyInfo?.is_deleted && !companiesList?.[0]?.is_deleted) {
            setCompaniesList((old) => [companyInfo, ...(old || [])]);
        }
    }, [companyInfo, companiesList]);

    //set the first contact in case of deleted contact
    useEffect(() => {
        if (contactInfo?.is_deleted && contactInfo?.company_id === companyInfo?.id && (!contactsList || !contactsList?.[0]?.is_deleted)) {
            setContactsList((old) => [contactInfo, ...(old || [])]);
        }
    }, [contactInfo]);

    useEffect(() => {
        if (message.startsWith(BROADCAST_MESSAGES.INVALIDATE_COMPANIES)) {
            invalidateCompanyDropdownQuery();
        }
    }, [message]);

    useEffect(() => {
        if (message.startsWith(BROADCAST_MESSAGES.INVALIDATE_CONTACTS)) {
            invalidateContactDropdownQuery();
        }
    }, [message]);

    const renderCompanyOption = (prop: CustomRowElProps) => {
        const { name, id, business_type, is_deleted } = prop.option;
        if (id === 0) {
            return (
                <Button
                    key={id}
                    variant="text"
                    color="primary"
                    fullWidth
                    startIcon={<OpenInNewIcon />}
                    onClick={() => {
                        setOpenCompanyAutocomplete(false);
                        const newWindow: Window = window.open('/v2/contactManagement') as Window;
                        newWindow.openCompanyPopup = true;
                    }}
                >
                    {name}
                </Button>
            );
        }
        return (
            <Box
                display="flex"
                width="100%"
                gap={1}
                px={3}
                py={2}
                sx={{
                    cursor: 'pointer',
                    ':hover': { bgcolor: 'neutral.light' },
                    pointerEvents: is_deleted ? 'none' : 'auto'
                }}
                onClick={() => {
                    setCompanyInfo(prop.option);
                    setOpenCompanyAutocomplete(false);
                    setContactsList([]);
                }}
                bgcolor={companyInfo?.id === id ? 'neutral.main' : ''}
                key={String(id)}
            >
                <Box maxWidth={'100%'}>
                    <BodySmall color="text.primary" noWrap>
                        {name}
                    </BodySmall>
                </Box>
                {business_type && <BodySmall color="text.secondary">({business_type})</BodySmall>}
            </Box>
        );
    };

    const renderContactOption = (prop: CustomRowElProps) => {
        const { name, id, role, is_deleted } = prop.option;
        if (id === 0) {
            return (
                <Button
                    key={id}
                    variant="text"
                    color="primary"
                    fullWidth
                    startIcon={<OpenInNewIcon />}
                    onClick={() => {
                        setOpenContactAutocomplete(false);
                        const newWindow: Window = window.open('/v2/contactManagement/contacts') as Window;
                        newWindow.openContactPopup = true;
                    }}
                >
                    {name}
                </Button>
            );
        }
        return (
            <Box
                key={String(id)}
                display="flex"
                width="100%"
                gap={1}
                px={3}
                py={2}
                sx={{
                    cursor: 'pointer',
                    ':hover': { bgcolor: 'neutral.light' },
                    pointerEvents: is_deleted ? 'none' : 'auto'
                }}
                onClick={() => {
                    setContactInfo(prop.option);
                    setOpenContactAutocomplete(false);
                }}
                bgcolor={contactInfo?.id === prop.option?.id ? 'neutral.main' : ''}
            >
                <Box>
                    <BodySmall color="text.primary" noWrap>
                        {name}
                    </BodySmall>
                </Box>
                {role && <BodySmall color="text.secondary">({role})</BodySmall>}
            </Box>
        );
    };

    const getCompanyOptions = () => {
        if (companiesList?.length) {
            const optionForSelectedLocation = companiesList?.filter((item) => {
                if (item?.visible_to_all || item?.is_deleted) return true;
                if (locationId) {
                    const locationIds = item?.visible_to?.map((itm) => Number(itm));
                    return locationId > 0 ? locationIds.includes(locationId) : true;
                }
                return true;
            });
            const options = optionForSelectedLocation?.map((item) => ({ id: item.id, name: item.name, business_type: item.business_type, is_deleted: item?.is_deleted || false }));
            return [...options, companyAddOption];
        }
        return [companyAddOption];
    };

    const getContactOptions = () => {
        if (contactsList?.length) {
            const options = contactsList?.map((item) => ({
                id: item.id,
                name: item.name || item.first_name + ' ' + item.last_name,
                role: item.role,
                is_deleted: item?.is_deleted || false,
                company_id: item?.company_info?.id
            }));
            return [...options, contactAddOption];
        }
        return [contactAddOption];
    };

    useEffect(() => {
        if (getCompanyOptions()?.length > 1 && companyInfo && !companyInfo.is_deleted && !retainCustomer) {
            const isCompanyPresent = getCompanyOptions().find((item) => item.id === companyInfo.id);
            if (!isCompanyPresent) {
                setCompanyInfo(null);
            }
        }
    }, [companyInfo, locationId]);

    //length condition is here because by default we add one option of create new in autocomplete and
    //if the company is deleted then we one more option i.e. the deleted company
    const showSelector = !isLoading && (!companiesFetching || (companiesList || [])?.length > (companyInfo?.is_deleted ? 2 : 1));

    return (
        <Box width={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {showSelector ? (
                        <>
                            <Autocomplete
                                open={openCompanyAutocomplete}
                                onOpen={() => setOpenCompanyAutocomplete(true)}
                                optionlabelkeyname="name"
                                options={getCompanyOptions()}
                                value={companyInfo || null}
                                onChange={(e: React.SyntheticEvent<Element, Event>) => {
                                    const target = e.target as HTMLInputElement;
                                    setCompanyInfo?.(target.value);
                                }}
                                label={companyLabel || 'Company'}
                                fieldSize="small"
                                error={Boolean(companyError)}
                                loading={companiesLoading || companiesFetching}
                                customRow={renderCompanyOption}
                                getOptionLabel={(option: string | { [index: string]: string | number }) => {
                                    const opt = option as { name: string; business_type?: string; id: number };
                                    return opt.id !== 0 ? `${opt?.name} ${opt?.business_type ? `(${opt?.business_type})` : ''}` : '';
                                }}
                                filterOptionsCallBack={(options: OptionType[], params) => {
                                    if (params.inputValue) {
                                        return options.filter((item) => String(item.name).toLowerCase().includes(params.inputValue.toLowerCase()));
                                    }
                                    return options;
                                }}
                                clearOnBlur
                                onBlur={() => setOpenCompanyAutocomplete(false)}
                                blurOnEmptyInput={() => setOpenCompanyAutocomplete(false)}
                                bgColor={'primary.contrastText'}
                                getOptionDisabled={(option: OptionType) => (option.is_deleted ? true : false)}
                            />
                            {companyError && (
                                <Box mt={1}>
                                    <BodyXS color={'error.main'} sx={{ fontSize: '10px' }}>
                                        {companyError}
                                    </BodyXS>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {showSelector ? (
                        <Autocomplete
                            open={openContactAutocomplete}
                            onOpen={() => setOpenContactAutocomplete(true)}
                            optionlabelkeyname="name"
                            options={getContactOptions()}
                            value={contactInfo || null}
                            onChange={(e: React.SyntheticEvent<Element, Event>) => {
                                const target = e.target as HTMLInputElement;
                                setContactInfo?.(target.value);
                            }}
                            label={contactLabel || 'Contact'}
                            fieldSize="small"
                            error={Boolean(contactError)}
                            disabled={!companyInfo?.id}
                            loading={contactsFetching}
                            customRow={renderContactOption}
                            getOptionLabel={(option: string | { [index: string]: string | number }) => {
                                const opt = option as { id: number; name: string; role: string };
                                return opt.id !== 0 ? `${opt?.name} ${opt?.role ? `(${opt?.role})` : ''}` : '';
                            }}
                            filterOptionsCallBack={(options, params) => {
                                if (params.inputValue) {
                                    return options.filter((item) => String(item.name).toLowerCase().includes(params.inputValue.toLowerCase()));
                                }
                                return options;
                            }}
                            clearOnBlur
                            onBlur={() => setOpenContactAutocomplete(false)}
                            blurOnEmptyInput={() => setOpenContactAutocomplete(false)}
                            bgColor={'primary.contrastText'}
                            getOptionDisabled={(option: OptionType) => (option.is_deleted ? true : false)}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default CompanyContactSelector;
