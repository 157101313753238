import { BodySmall, SearchIcon, IconButton, Box, Tooltip, CircularProgress } from '@parspec/pixel';

import { RouterLink } from '../../shared/RouterLink';
import { setRouteToLocalStorage } from '../../shared/utils/utils';
import { useLocation } from 'react-router-dom';

export const getCategory = (props: any) => (
    <>
        <BodySmall limit={false}>{props?.category?.toUpperCase() || ''}</BodySmall>
    </>
);

export const getManufacturer = (props: any) => (
    <>
        <BodySmall limit={false}>{props?.manufacturer?.toUpperCase() || ''}</BodySmall>
    </>
);

export const getModelNumber = (props: any) => (
    <>
        <BodySmall limit={false}>{props?.model_number?.toUpperCase() || ''}</BodySmall>
    </>
);

export const ProductFinderTemplate = (props: any) => {
    const location = useLocation();
    function handleClick() {
        setRouteToLocalStorage(`${location.pathname}?tab=bom&productId=${props?.data?.id}`);
    }
    return (
        <RouterLink to={`productFinder/${props?.data?.id}`}>
            <IconButton onClick={handleClick}>
                <SearchIcon color="primary" sx={{ width: 16, height: 16 }} />
            </IconButton>
        </RouterLink>
    );
};

export const ProductFinderDisabledTemplate = () => {
    return (
        <Box display="flex">
            <Box margin="auto">
                <Tooltip placement="top" title="Enable Product Finder access under the user management screen to access this feature">
                    <SearchIcon color="neutral" sx={{ width: 16, height: 16 }} />
                </Tooltip>
            </Box>
        </Box>
    );
};

export const loadingOverlayComponent = () => {
    return (
        <Box className="ag-loading-center">
            <Box sx={{ opacity: 1 }} aria-label="loading">
                <CircularProgress size="lg" color={'primary'} />
            </Box>
        </Box>
    );
};

export const NoRowsOverlayComponent = (props: any) => {
    const { isTableHaveFooter, helperText } = props;
    return (
        <Box className={`ag-overlay-no-rows-wrapper ${isTableHaveFooter ? '' : ' overlay-rows-footer'}`}>
            <BodySmall>No records to display</BodySmall>
            {helperText && <BodySmall>{helperText}</BodySmall>}
        </Box>
    );
};
