import { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { BodyMedium, Box, Grid, Modal, ModalHeader, BodyXS, LanguageIcon, Link, CircularProgress, BodySmall } from '@parspec/pixel';

import AnnotatedPdfViewer from 'src/features/shared/AnnotatedPdfViewer';
import Footer from './Footer';
import { useGetBodDetailQuery, useGetDatasheetDetailQuery } from '../../../BOM/queries';
import { Document } from '../DocumentItem';
import { ReuseDialogType, ReuseDialogTypeLabel } from '../../../BOM/shared/utils';
import BasicInfo from './BasicInfo';
import PdfViewer from '../../PdfViewer';
import { useGetDatasheetQuery, useGetSelectedDocumentQuery } from '../../../BOM/Datasheets/AnnotateDatasheet/queries';
import { getSanitizedUrl } from 'src/features/shared/utils/utils';

const errorText = 'This file can still be selected, but the in-app preview is currently unavailable.';
const errorText2 = 'Please click on the link below to view this file in another tab:';
interface PreviewDocumentProps {
    open: boolean;
    closePreview: () => void;
    onSelectItem: (item: Document, callback?: () => void) => void;
    index: number;
    dataList: Document[];
    productFinderMode?: boolean;
    selectedDocument?: Document;
    subDocumentType: string;
}
const PreviewDocument = (props: PreviewDocumentProps) => {
    const { open, closePreview, onSelectItem, index, dataList, productFinderMode, subDocumentType, selectedDocument } = props;
    const [previewIndex, setPreviewIndex] = useState<number>(index);
    const { bomId, productId, documentType } = useParams();
    const dataToPreview = dataList[previewIndex];
    const { data: selectedDocumentData, isFetching: isSelectedDocumentQueryFetching } = useGetSelectedDocumentQuery(bomId!, productId!, documentType!, subDocumentType!, {
        enabled: !!bomId && !!productId && !!documentType && !!subDocumentType && !!productFinderMode,
        staleTime: 0
    });
    const selectedDocumentId = selectedDocumentData?.data[0]?.id;
    const { data: datasheetData, isFetching: datasheetDataFetching } = useGetDatasheetQuery(
        bomId!,
        productId!,
        selectedDocumentId!,
        selectedDocumentData?.data[0]?.document || 0,
        documentType!,
        subDocumentType!,
        {
            enabled: !!selectedDocumentId
        }
    );
    const { data: bodData, isFetching: bodDataFetching } = useGetBodDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });
    const { data: datasheetDetail, isFetching: datasheetDetailFetching } = useGetDatasheetDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId) && subDocumentType === ReuseDialogType.DATASHEET && !productFinderMode
    });
    const isSelected = dataToPreview?.id === selectedDocument?.id;

    const documentUrl = dataToPreview.document_internal_url ?? dataToPreview.document_url;

    //called on clicking select button
    const onSelect = () => {
        onSelectItem(dataToPreview);
        closePreview();
    };
    //called on click next or previous inside the preview modal
    const updatePreviewIndex = (order: 1 | -1) => {
        // setPdfLoadError(false);
        if (previewIndex || previewIndex === 0) {
            if (order > 0) {
                if (previewIndex < dataList.length - 1) {
                    setPreviewIndex((old: number) => old + order);
                }
            } else {
                if (previewIndex > 0) {
                    setPreviewIndex((old: number) => old + order);
                }
            }
        }
    };
    const { manufacturer, user_name } = dataToPreview?.search_result_metadata || {};
    const showSplitScreen = productFinderMode && selectedDocumentId;
    const gridWidth = showSplitScreen ? 6 : 12;
    const loading = isSelectedDocumentQueryFetching || datasheetDataFetching || bodDataFetching || datasheetDetailFetching;
    const title =
        isSelectedDocumentQueryFetching || datasheetDataFetching
            ? ''
            : showSplitScreen
            ? 'Compare Datasheet'
            : `Preview ${ReuseDialogTypeLabel[subDocumentType! as keyof typeof ReuseDialogTypeLabel]}`;
    const errorUi = (
        <Box display="flex" alignItems="center" justifyContent="center" bgcolor="secondary.main" flexDirection="column" flex={1} px={5} gap={5}>
            <img src="/assets/icons/doc_exclaim.png" />
            <Box>
                <BodyMedium limit={false} textAlign="center" color="secondary.contrastText">
                    {errorText}
                </BodyMedium>
                <BodyMedium limit={false} textAlign="center" color="secondary.contrastText">
                    {errorText2}
                </BodyMedium>
            </Box>
            <Link to={getSanitizedUrl(documentUrl)} target="_blank" component={RouterLink} underline="hover">
                <Box display="flex" alignItems="center" gap={2}>
                    <LanguageIcon color="primary" />
                    <BodyXS color="primary.main" fontWeight={600}>
                        {`Verified Manufacturer - ${manufacturer || user_name || ''}`}
                    </BodyXS>
                </Box>
            </Link>
        </Box>
    );
    return (
        <Modal
            open={open}
            header={
                <ModalHeader title={title} onClose={closePreview}>
                    {bodData?.data?.description && !loading && (
                        <BodySmall lines={1} color="text.secondary">
                            {bodData?.data?.description}
                        </BodySmall>
                    )}
                </ModalHeader>
            }
            footer={
                <Footer updatePreviewIndex={updatePreviewIndex} closePreview={closePreview} onSelect={onSelect} totalDocuments={dataList.length} previewIndex={previewIndex} isSelected={isSelected} />
            }
        >
            <>
                {loading ? (
                    <Box height="70vh" width="75vw" display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size="xxl" color="primary" />
                    </Box>
                ) : (
                    <Box width="75vw" height="70vh" bgcolor="neutral.light" display="flex" flexDirection="column" pt={4} mx={-6}>
                        <Grid container height={1}>
                            {showSplitScreen && (
                                <Grid item xs={12} lg={6} md={6}>
                                    <Box height={1} width={1} display="flex" flexDirection="column">
                                        <Box ml={6}>
                                            <BasicInfo bodData={bodData?.data} tag="source" />
                                        </Box>
                                        <AnnotatedPdfViewer fileUrl={datasheetData?.data?.flat_filepath || ''} errorUi={errorUi} documentType={documentType} subDocumentType={subDocumentType} />
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={gridWidth} md={gridWidth}>
                                <Box height={1} width={1} display="flex" flexDirection="column">
                                    <Box ml={showSplitScreen ? 0 : 6} mr={6}>
                                        <BasicInfo
                                            bodData={bodData?.data}
                                            notes={!productFinderMode ? datasheetDetail?.data?.notes : ''}
                                            dataToPreview={dataToPreview}
                                            tag={productFinderMode ? 'alternate' : ''}
                                            productFinderMode={productFinderMode}
                                        />
                                    </Box>
                                    <PdfViewer url={documentUrl} errorUi={errorUi} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </>
        </Modal>
    );
};
export default PreviewDocument;
