import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { invalidateGetSelectedDocument } from '../../../BOM/Datasheets/AnnotateDatasheet/queries';
import { getDocumentsCallback, getDocumentSuggestions, selectDocument, unSelectDocument, addSuggestion, endPoints, supportManufacturer, addAlternates } from './apis';
import {
    invalidateDatasheetDataQuery,
    invalidateDatasheetStatsQuery,
    updateBomDataSelectionStatus,
    updateOmDataAddStatus,
    updateOmDataSelectionStatus,
    invalidateOmDataQuery
} from '../../../BOM/queries';
import { DocumentApiParams, DocumentSuggestionsResponse } from './apiTypes';

//keys
const documentCallKey = (keys: DocumentApiParams) => ['document-call', endPoints.documentCallUrl(keys)];

const getDocumentSuggestionsKey = (url: string) => ['document-suggestions', url];

export const useGetDocumentsCallbackQuery = (urlKeys: DocumentApiParams, options: Omit<UseQueryOptions<AxiosResponse>, 'queryKey' | 'queryFn'>) =>
    useQuery(documentCallKey(urlKeys), () => getDocumentsCallback(urlKeys), options);

export const useGetDocumentsQuery = (url: string, options: Omit<UseQueryOptions<AxiosResponse<DocumentSuggestionsResponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getDocumentSuggestionsKey(url), () => getDocumentSuggestions(url), options);

export const useSelectDocumentMutation = () =>
    useMutation(selectDocument, {
        onSuccess: (response, request) => {
            const { bomId, bodId, subDocumentType, documentType } = request;
            const { document_selection_status, id } = response.data;
            invalidateGetSelectedDocument(String(bomId), String(bodId), documentType, subDocumentType);
            if (subDocumentType === 'datasheet') {
                updateBomDataSelectionStatus({ bomId, bodId, selectionStatus: document_selection_status, all_pages_hidden: false, selectedDocumentId: id });
            } else {
                updateOmDataSelectionStatus({ bomId, bodId, selectionStatus: document_selection_status, subDocumentType, selectedDocumentId: id });
            }
            // Invalidation of submittal metadata
            invalidateDatasheetDataQuery(String(bomId));
        }
    });

export const useUnSelectDocumentMutation = () =>
    useMutation(unSelectDocument, {
        onSuccess: (_, request) => {
            const { bomId, bodId, subDocumentType, documentType } = request;
            invalidateGetSelectedDocument(String(bomId), String(bodId), documentType, subDocumentType);
            if (subDocumentType === 'datasheet') {
                updateBomDataSelectionStatus({ bomId, bodId, selectionStatus: 'NOT_CONFIRMED', annotationStatus: 'NOT_CONFIRMED' });
            } else {
                updateOmDataSelectionStatus({ bomId, bodId, selectionStatus: 'NOT_CONFIRMED', subDocumentType, annotationStatus: 'NOT_CONFIRMED' });
            }
        }
    });

export const useAddSuggestionMutation = () =>
    useMutation(addSuggestion, {
        onSuccess: (response: any, request) => {
            const { bomId, productId, subDocumentType, documentType } = request;
            const { document_selection_status, id } = response.data[0];
            invalidateGetSelectedDocument(String(bomId), String(productId[0]), documentType, subDocumentType);
            if (subDocumentType === 'datasheet') {
                updateBomDataSelectionStatus({ bomId, bodId: Number(productId[0]), selectionStatus: document_selection_status, selectedDocumentId: id });
            } else {
                updateOmDataAddStatus({ bomId, subDocumentType, response: response.data });
            }
            invalidateOmDataQuery(bomId);
            invalidateDatasheetDataQuery(bomId);
            invalidateDatasheetStatsQuery(bomId);
        }
    });

export const useSupportManufacturerMutation = () => useMutation(supportManufacturer);

export const useAddAlternatesMutation = () => useMutation(addAlternates);
