import { useEffect, useMemo, useState } from 'react';

import { BodySmall, BodyXS, Box, TextField, RichTextEditor } from '@parspec/pixel';

import EmailSkeleton from './EmailSkeleton';
import EmailInput from './EmailInput';
import Attachment from './Attachment';

import { EmailFormType } from '..';
import { SHAREABLE_LINK_TEXT } from '../constants';
import { BooleanObjType } from '../../../shared/types';

interface IComposeMailProps {
    bomId: string;
    isFetching: boolean;
    isLoggedIn: boolean;
    showSelectedFile: BooleanObjType;
    formData: EmailFormType;
    mailInputOptions: { id: string; name: string }[];
    shareableUrl: { [bomId: string]: string };
    recipientError: { [bomId: string]: string };
    ccError: { [bomId: string]: string };
    isEmailBodyReady?: boolean;
    setRecipientError: (value: { [bomId: string]: string }) => void;
    setCcError: (value: { [bomId: string]: string }) => void;
    handleChange: (name: string, value: any, bomId: string) => void;
    handleMessageChange: (htmlStr: string, bomId: string) => void;
    handleAttachmentDelete: (file: File, bomId: string) => void;
    handleAddFile: (bomId: string) => void;
    handleRemoveSelectedFile: (bomId: string) => void;
    handleAttachmentUpload: (files: FileList, bomId: string) => void;
    setSendButtonError: (val: string) => void;
}

const ComposeMail = ({
    bomId,
    isFetching,
    isLoggedIn,
    formData,
    handleChange,
    handleMessageChange,
    handleAttachmentDelete,
    handleAddFile,
    mailInputOptions,
    showSelectedFile,
    shareableUrl,
    isEmailBodyReady,
    recipientError,
    ccError,
    setCcError,
    setRecipientError,
    handleRemoveSelectedFile,
    handleAttachmentUpload,
    setSendButtonError
}: IComposeMailProps) => {
    const fromEmail = useMemo(() => localStorage.getItem('mailing-email'), [localStorage.getItem('mailing-email')]);

    const [isEditorDisabled, setIsEditorDisabled] = useState(true);

    useEffect(() => {
        if (!(isFetching || !isEmailBodyReady)) setIsEditorDisabled(false);
    }, [isFetching, !isEmailBodyReady]);

    const { to, body, attachments, subject, selectedFile, cc } = formData;

    const getDefaultEmailInput = (val: string) => {
        const optionsMap = mailInputOptions.reduce((acc: { [key: string]: string }, option) => {
            acc[option.id] = option.name;
            return acc;
        }, {});

        return val.split(',').map((email) => {
            if (optionsMap[email]) {
                return { id: email, name: optionsMap[email] };
            }
            return { id: email, name: email };
        });
    };

    const defaultToVal = useMemo(() => (to ? getDefaultEmailInput(to) : []), [to]);
    const defaultCcVal = useMemo(() => (cc ? getDefaultEmailInput(cc) : []), [cc]);

    return (
        <Box width="calc(100% - 8px)">
            {isFetching || !isEmailBodyReady ? (
                <EmailSkeleton />
            ) : (
                <Box mt={5}>
                    <Box display="flex" justifyContent="flex-end">
                        <BodyXS color="neutral.dark">Email will be sent from: {fromEmail}</BodyXS>
                    </Box>
                    <Box display="flex" flexDirection="column" rowGap={5} mt={1}>
                        <EmailInput
                            fieldLabel="Email Recipient(s) *"
                            fieldId="to"
                            disabled={!isLoggedIn}
                            handleChange={(name: string, value: string) => handleChange(name, value, bomId)}
                            options={mailInputOptions}
                            defaultValue={defaultToVal}
                            recipientError={recipientError[bomId]}
                            setRecipientError={(err) => setRecipientError({ ...recipientError, [bomId]: err })}
                            setSendButtonError={setSendButtonError}
                        />

                        <EmailInput
                            fieldLabel="Cc Recipient(s)"
                            fieldId="cc"
                            disabled={!isLoggedIn}
                            handleChange={(name: string, value: string) => handleChange(name, value, bomId)}
                            options={mailInputOptions}
                            defaultValue={defaultCcVal}
                            recipientError={ccError[bomId]}
                            setRecipientError={(err) => setCcError({ ...ccError, [bomId]: err })}
                            setSendButtonError={setSendButtonError}
                        />

                        <TextField label="Subject" value={subject} onChange={(e) => handleChange('subject', e.target.value, bomId)} name="subject" disabled={!isLoggedIn} bgColor="white" />
                        <Box>
                            <BodySmall color="neutral.dark" limit={false} sx={{ opacity: isLoggedIn ? 1 : 0.5 }} mb={2}>
                                Message
                            </BodySmall>

                            <RichTextEditor
                                onBlur={(htmlStr) => handleMessageChange(bomId, htmlStr)}
                                initialHtml={body}
                                contentEditableHeight="141px"
                                isDisable={isEditorDisabled}
                                showShareableLinkButton={true}
                                shareableLinkTitle={shareableUrl ? SHAREABLE_LINK_TEXT : ''}
                                shareableLinkUrl={shareableUrl[bomId]}
                                showAttachements={true}
                                onFileUpload={(files: FileList | null) => handleAttachmentUpload(files as FileList, bomId)}
                            />
                        </Box>

                        <Box display="flex" flexDirection="column">
                            {showSelectedFile[bomId] && selectedFile ? (
                                <Attachment file={selectedFile} isLoggedIn={isLoggedIn} handleAttachmentDelete={() => handleRemoveSelectedFile(bomId)} />
                            ) : (
                                <BodySmall color="primary.main" pb={4} sx={{ cursor: 'pointer', opacity: isLoggedIn ? '1' : '0.4' }} onClick={() => handleAddFile(bomId)}>
                                    + Attach PDF
                                </BodySmall>
                            )}
                            {attachments.length > 0 &&
                                attachments?.map((file: File) => <Attachment file={file} isLoggedIn={isLoggedIn} handleAttachmentDelete={() => handleAttachmentDelete(file, bomId)} />)}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ComposeMail;
