export const manufacturers = [
    {
        manufacturer: 'Lithonia',
        products: 131,
        totalExtSellPrice: 23,
        totalExtDiscountedCost: 45
    },
    {
        manufacturer: 'Nova',
        products: 21,
        totalExtSellPrice: 65,
        totalExtDiscountedCost: 32
    },
    {
        manufacturer: 'Yono',
        products: 123,
        totalExtSellPrice: 34,
        totalExtDiscountedCost: 34
    },
    {
        manufacturer: 'USESI',
        products: 132,
        totalExtSellPrice: 4,
        totalExtDiscountedCost: 43
    },
    {
        manufacturer: 'Graybar',
        products: 322,
        totalExtSellPrice: 43,
        totalExtDiscountedCost: 45
    }
];
