import { FC } from 'react';

import { Box } from '@parspec/pixel';

import Header from './Header';
import CalendarView from './CalendarView';

const BidBoard: FC = () => {
    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header />
            <CalendarView />
        </Box>
    );
};

export default BidBoard;
