import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { Box, Modal, ModalHeader, TextField, Button, Checkbox, Switch, Select, Grid, Info, Snackbar, BodySmall, CircularProgress, SelectChangeEvent } from '@parspec/pixel';

import { useGetSharedLinkDetails, useUpdateShareLinkDetails } from '../queries';
import { UpdateSharedLinkDetailsRequest } from '../queries/apiTypes';
import { accessOptions, actionOptions, EMAIL_REGEX, O_M, passwordSnackbarMessage } from '../../constants';
import { useTrackIntercomEvent } from 'src/features/shared/hooks/useIntercomHook';

type SnackbarMessageKey = keyof typeof passwordSnackbarMessage;
type SnackbarMessage = (typeof passwordSnackbarMessage)[SnackbarMessageKey];

const ShareLinkInfo = ({ open, onCloseClick, bomId, documentType }: { open: boolean; onCloseClick: () => void; onSuccess: () => void; bomId: string; documentType: string }) => {
    const {
        data: sharedLinkDetailsData,
        isLoading: sharedLinkDetailsDataIsLoading,
        isFetching: shareLinkDetailsIsFetching,
        error: shareLinkInfoError
    } = useGetSharedLinkDetails(Number(bomId), documentType, {
        enabled: !!bomId
    });
    const { isLoading: isUpdateShareLinkLoading, mutateAsync: updateShareLinkDetails } = useUpdateShareLinkDetails();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const [checkBoxValue, setCheckBoxValue] = useState<boolean>();
    const [permissionForAccessLink, setPermissionForAccessLink] = useState<string>();
    const [actionOptionsForDocument, setactionOptionsForDocument] = useState<boolean>();
    const [password, setPassword] = useState('');
    const [switchValue, setSwitchValue] = useState<boolean>();
    const [emailList, setEmailList] = useState<string[]>();
    const [sharedLink, setSharedLink] = useState('');
    const [snackbarMessage, setSnackbarMsg] = useState<SnackbarMessage>(passwordSnackbarMessage.NONE);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isFinalSubmittalLoading, setIsFinalSubmittalLoading] = useState(false);
    const [isPasswordError, setPasswordError] = useState(false);

    const err = shareLinkInfoError as AxiosError<{ message: string }>;

    useEffect(() => {
        if (sharedLinkDetailsData?.data) {
            setactionOptionsForDocument(sharedLinkDetailsData.data.can_download);
            setPermissionForAccessLink(sharedLinkDetailsData.data.who_has_access);
            setSharedLink(sharedLinkDetailsData.data.shared_link!);
            setCheckBoxValue(sharedLinkDetailsData.data.is_password_set);
            setPassword(sharedLinkDetailsData.data.password);
            setSwitchValue(sharedLinkDetailsData.data.is_active);
            setEmailList(sharedLinkDetailsData.data.email_access_list);
        }
    }, [sharedLinkDetailsData?.data]);

    const keyDown = (evt: any) => {
        if (['Enter'].includes(evt.key)) {
            evt.preventDefault();
            if (value.trim().length) {
                const result = validateEmailAndSetEmailList();
                if (result) {
                    setEmailList(result);
                }
            }
        }
    };

    const validateEmailAndSetEmailList = () => {
        const typedValue = value.replace(/\s/g, '');
        const formattedValueArray = typedValue.toLowerCase().split(',');

        if (formattedValueArray.every((email) => isValid(email))) {
            setValue('');
            setError(null);
            return [...emailList!, ...formattedValueArray];
        }

        return null;
    };

    const isValid = (email: string): boolean => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        const emailPrefix = email.split('@');
        if (emailPrefix[0].length > 64 || !isValidEmail(email)) {
            error = `Please enter correct email address.`;
        }

        if (error) {
            setError(error);
            return false;
        }

        return true;
    };

    const isInList = (email: string) => {
        return emailList!.includes(email);
    };

    const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

    const handleDelete = (item: number) => {
        const updatedEmailList = emailList!.filter((_email: string, idx: number) => idx !== item);
        setEmailList(updatedEmailList);
    };
    const handlePaste = (evt: any) => {
        evt.preventDefault();

        const paste = evt.clipboardData.getData('text').toLowerCase().replace(/\s/g, '');
        const emails = paste.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g);

        const result = emails.every((email: string) => {
            const emailPrefix = email.split('@');
            return emailPrefix[0].length < 64 ? true : false;
        });

        if (!result) {
            setError('Invalid email list!');
            return;
        }

        if (emails) {
            const toBeAdded = emails.filter((email: string) => !isInList(email));
            setEmailList([...emailList!, ...toBeAdded]);
            setError(null);
        }
    };

    const handleChange = (evt: any) => {
        setValue(evt.target.value.trim());
        setError(null);
    };

    const changeAccessOptions = (accessOptions: string) => {
        setPermissionForAccessLink(accessOptions);
        setError(null);
    };

    const changeActionOptions = (accessActions: string) => {
        setactionOptionsForDocument(accessActions === 'true' ? true : false);
    };

    const getPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        // to be set when isPassword is true
        if (isPasswordError) {
            setPasswordError(false);
        }
    };

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchValue(event.target.checked);
        const payload: UpdateSharedLinkDetailsRequest = {
            is_active: event.target.checked
        };
        updateShareLinkDetails({ data: payload, bomId: Number(bomId), documentType });
    };

    const onCheckBoxClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordError(false);
        if (!event.target.checked) {
            setCheckBoxValue(event.target.checked);
            const payload: UpdateSharedLinkDetailsRequest = {
                password: ''
            };
            updateShareLinkDetails({ data: payload, bomId: Number(bomId), documentType });
            return;
        }
        setCheckBoxValue(event.target.checked);
    };

    const handleFinalSubmission = async () => {
        if (checkBoxValue && !password?.trim()) {
            setPasswordError(true);
            return;
        }

        if (permissionForAccessLink === accessOptions[1]['value'] && !emailList?.length && !value) {
            setError('invalid email list!');
            return;
        }

        let refreshedEmailList = null;

        if (value.length) {
            refreshedEmailList = validateEmailAndSetEmailList();
            if (!refreshedEmailList) {
                return;
            }
            setEmailList(refreshedEmailList);
        }

        setIsFinalSubmittalLoading(true);
        const payload: UpdateSharedLinkDetailsRequest = {
            password: password?.trim(),
            email_access_list: refreshedEmailList ? JSON.stringify(refreshedEmailList) : JSON.stringify(emailList),
            who_has_access: permissionForAccessLink,
            can_download: actionOptionsForDocument
        };

        await updateShareLinkDetails({ data: payload, bomId: Number(bomId), documentType });
        setIsFinalSubmittalLoading(false);
        setIsOpen(true);
        setSnackbarMsg(passwordSnackbarMessage.SAVE_SUCCESS);
    };

    const header = (
        <ModalHeader
            title={`${documentType === O_M ? 'O&M Package' : `${documentType.substring(0, 1).toUpperCase()}${documentType.substring(1)}`} Link Settings`}
            onClose={onCloseClick}
            children={
                <Box color="neutral.dark" mb={4} fontSize={12}>
                    The settings below apply to all versions published on the shared link.
                </Box>
            }
        />
    );

    return (
        <>
            <Modal header={header} open={open}>
                <Box width={550} mb={-3}>
                    <Snackbar open={isOpen} message={snackbarMessage} onClose={() => setIsOpen(false)} />
                    <Info mb={4}>
                        <BodySmall limit={false} color="primary.main">
                            <Box component="span" fontWeight={700}>
                                This shareable link may host multiple document versions. &nbsp;
                            </Box>
                            Manage the versions displayed on this shared link from the Final Documents tab.
                        </BodySmall>
                    </Info>
                    {err?.response?.status === 404 ? (
                        <Box display={'flex'}>
                            <BodySmall color={'error.main'} lines={2}>
                                {'You need to have at least one compiled version document, to make any change in the link settings.'}
                            </BodySmall>
                        </Box>
                    ) : (
                        <>
                            <Box sx={isUpdateShareLinkLoading || shareLinkDetailsIsFetching ? { pointerEvents: 'none' } : null}>
                                {!sharedLinkDetailsDataIsLoading && sharedLinkDetailsData && switchValue !== undefined ? (
                                    <Box ml={1}>
                                        <Switch checked={switchValue} disabled={isUpdateShareLinkLoading || shareLinkDetailsIsFetching} label="Activate Link" onChange={handleSwitch} />
                                    </Box>
                                ) : (
                                    <Box width={1} display={'flex'} justifyContent={'center'}>
                                        <CircularProgress size={'xl'} color={'primary'} />
                                    </Box>
                                )}
                            </Box>

                            {switchValue ? (
                                <>
                                    <Box
                                        sx={
                                            isUpdateShareLinkLoading || shareLinkDetailsIsFetching
                                                ? {
                                                      opacity: 0.4,
                                                      pointerEvents: 'none'
                                                  }
                                                : null
                                        }
                                    >
                                        <Box display="flex" mt={3} mb={4} columnGap={2}>
                                            <Box width="50%">
                                                {!sharedLinkDetailsDataIsLoading && sharedLinkDetailsData && permissionForAccessLink ? (
                                                    <Select
                                                        value={permissionForAccessLink}
                                                        label="Who Can access the link"
                                                        options={accessOptions}
                                                        optionLabelKeyname="label"
                                                        optionValueKeyname="value"
                                                        id="accessOptions"
                                                        labelId="accessOptions"
                                                        onChange={(event: SelectChangeEvent<unknown>) => changeAccessOptions(event.target.value as string)}
                                                    />
                                                ) : null}
                                            </Box>

                                            <Box width="50%">
                                                {!sharedLinkDetailsDataIsLoading && sharedLinkDetailsData && actionOptionsForDocument !== undefined ? (
                                                    <Select
                                                        value={actionOptionsForDocument}
                                                        label="Everyone with this link can "
                                                        options={actionOptions}
                                                        optionLabelKeyname="label"
                                                        optionValueKeyname="value"
                                                        id="actionOptions"
                                                        labelId="actionOptions"
                                                        onChange={(event: SelectChangeEvent<unknown>) => changeActionOptions(event.target.value as string)}
                                                    />
                                                ) : null}
                                            </Box>
                                        </Box>

                                        {permissionForAccessLink === 'users' && (
                                            <Grid container mb={3} flexWrap={'wrap'} width={'100%'}>
                                                <Grid item xs={12} mb={1}>
                                                    <TextField
                                                        value={value}
                                                        label="Enter emails separated by commas"
                                                        required
                                                        onKeyDown={(event: any) => keyDown(event)}
                                                        onChange={handleChange}
                                                        onPaste={handlePaste}
                                                        error={Boolean(error)}
                                                        chips={emailList}
                                                        fullWidth={true}
                                                        onChipDelete={handleDelete}
                                                        helperText={error!}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box>
                                                {!sharedLinkDetailsDataIsLoading && sharedLinkDetailsData && checkBoxValue !== undefined ? (
                                                    <Checkbox checked={checkBoxValue} label="Make this link password protected" onChange={onCheckBoxClick} />
                                                ) : null}
                                            </Box>
                                        </Box>
                                        {checkBoxValue && (
                                            <Grid container columnSpacing={2} mb={3}>
                                                <Grid item flexGrow={2}>
                                                    <TextField
                                                        label="Enter password"
                                                        required
                                                        autoComplete="off"
                                                        error={isPasswordError}
                                                        helperText={isPasswordError ? 'Invalid Password!' : ''}
                                                        value={password}
                                                        onChange={(event: any) => getPassword(event)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>

                                    <Grid container mt={4} justifyContent={'flex-end'} gap={2}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color={'secondary'}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(sharedLink);
                                                    setIsOpen(true);
                                                    setSnackbarMsg(passwordSnackbarMessage.COPIED);
                                                    trackIntercomEvent('Sharable_Link');
                                                }}
                                            >
                                                Copy Link
                                            </Button>
                                        </Grid>

                                        <Grid item>
                                            <Button isLoading={isFinalSubmittalLoading} variant="contained" onClick={handleFinalSubmission}>
                                                Done
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default ShareLinkInfo;
