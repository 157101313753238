import { MutableRefObject, useState } from 'react';

import { WebViewerInstance } from '@pdftron/webviewer';

import { Button, Grid, Snackbar } from '@parspec/pixel';

import ProductInfo from '../../../shared/ProductInfo';
import ProductNotes from '../../../shared/ProductNotes';

import { AxiosResponse } from 'axios';
import { SelectedDocumentResponse } from './queries/apiTypes';
import { useSaveAnnotationMutation } from './queries';
import { useParspecNavigation } from 'src/app/Context';
import { useParams } from 'react-router-dom';

interface PdfViewerHeaderProps {
    setOpenAdjustPageRangePopup: any;
    hiddenPages: any;
    documentType: string;
    subDocumentType: string;
    isLoading: boolean;
    wvInstance: WebViewerInstance;
    bomId: string;
    bodId: string;
    data: AxiosResponse<Array<SelectedDocumentResponse>> | undefined;
    xfdf: MutableRefObject<string | undefined>;
    isDisabledAdjustPage?: boolean;
    disableSaveButton?: boolean;
}

let timerId: null | ReturnType<typeof setTimeout> = null;

const PdfViewerHeader: React.FC<PdfViewerHeaderProps> = (props) => {
    const { projectId } = useParams();
    const { pNavigate, setHasUnsavedChanges } = useParspecNavigation();
    const [message, setMessage] = useState('');
    const { setOpenAdjustPageRangePopup, hiddenPages, documentType, subDocumentType, isLoading, wvInstance, bomId, bodId, data, xfdf, isDisabledAdjustPage, disableSaveButton } = props;
    const { mutateAsync: saveAnnotation, isLoading: saveAnnotationLoading } = useSaveAnnotationMutation();
    const saveAnnotations = async () => {
        timerId && clearTimeout(timerId);
        setMessage('Saving Annotations...');
        setHasUnsavedChanges(false);
        const saveAnnotationsData = {
            bomId: bomId,
            bodId: bodId,
            documentType: documentType,
            subDocumentType: subDocumentType,
            selectedDocumentId: data?.data[0]?.id || 0,
            xfdf: xfdf.current!
        };
        try {
            await saveAnnotation(saveAnnotationsData);
            setMessage('Annotations Saved!');
            timerId = setTimeout(() => {
                setMessage('');
            }, 3000);
        } catch (err) {
            timerId = setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    };
    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" py={2} px={6} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                <Grid container item flexDirection="column" gap={3} md={8} lg={8} xs={8} sm={8} xl={9}>
                    <ProductInfo onAccept={() => pNavigate(`/v2/project/${projectId}/bom/${bomId}/product/${bodId}/${documentType}/${subDocumentType}/match?refresh=true`)} isAnnotationPage />
                    {subDocumentType === 'datasheet' && <ProductNotes />}
                </Grid>
                <Grid container item flexDirection="row" gap={2} xs={3} md={4} lg={3} sm={3} xl={3} justifyContent="flex-end" alignContent="center">
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={saveAnnotations} isLoading={saveAnnotationLoading} disabled={!wvInstance || disableSaveButton}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={() => setOpenAdjustPageRangePopup(true)} isLoading={isLoading} disabled={isDisabledAdjustPage}>
                            <>Adjust Pages ({hiddenPages.current?.length} hidden)</>
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar open={Boolean(message)} message={message} onClose={() => setMessage('')} />
            </Grid>
        </>
    );
};

export default PdfViewerHeader;
