import { rest } from 'msw';
import { projectDetails } from './projectDetails';
import { projectBoms } from './projectBoms';
import { companyDetails } from './companyDetails';
import { userDetails } from './userDetails';
import { editProjectResponse } from './editProjectResponse';
import { getOutOfSyncResponse } from './syncResponse';

export const projectHandlers = [
    rest.get('*/project', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(projectBoms));
    }),
    rest.get('*/dashboard/project/:id/bom', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(projectBoms));
    }),
    rest.get('*/project/:id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(projectDetails));
    }),
    rest.get('*/company/:id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(companyDetails));
    }),
    rest.get('*/user_info/:id', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(userDetails));
    }),
    rest.patch('*/project/:project_id/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(editProjectResponse));
    }),
    rest.get('*/dashboard/project/:bomId/bom/submittal/out_of_sync', (_req, res, ctx) => {
        return res(ctx.delay(4000), ctx.json(getOutOfSyncResponse()));
    }),
    rest.get('*/dashboard/project/:bomId/bom/o_m/out_of_sync', (_req, res, ctx) => {
        return res(ctx.delay(4000), ctx.json(getOutOfSyncResponse()));
    })
];
