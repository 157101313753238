import { getStringifiedValueForEventService } from 'src/features/shared/utils/utils';

export const insertMutation = ({ initialSelection, finalSelection }: { finalSelection?: FormData; initialSelection: Record<string, any> }) => {
    //this is needed because finalSelection is a formdata and it needs to be changed to array.
    const convertFormDataToArray = () => {
        const finalSelectionDataArr: Record<string, any> = [];
        const formFields: Record<string, any> = {};
        finalSelection!.forEach((value, key) => (formFields[key] = value));
        Object.keys(formFields || {}).forEach((key) => {
            const idx = Number(key[key.length - 1]);
            if (!isNaN(idx)) finalSelectionDataArr[idx - 1] = { ...finalSelectionDataArr[idx - 1], [key]: formFields[key] };
        });
        return finalSelectionDataArr;
    };
    const finalSelectionValue = finalSelection ? getStringifiedValueForEventService(convertFormDataToArray()) : null;
    return `
    mutation($initial_selection: jsonb = ${getStringifiedValueForEventService(initialSelection)}, $final_selection: jsonb = ${finalSelectionValue}) {
        logging_db {
          insert_bom_column_mapping_tracking(objects: {initial_selection: $initial_selection, final_selection: $final_selection}) {
            affected_rows
          }
        }
      }
`;
};
