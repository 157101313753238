export const importMoreProductsResponse = {
  id: 25630,
  filename: 'Submittal_Product_Submitt.pdf',
  file_info:
    'https://parspec-staging.s3.amazonaws.com/media/submittals/25630/Submittal_Product_Submitt.pdf',
  bound_info: '',
  ocr_extracted_info: '',
  user_reviewed_info: '',
  status: 'pending',
  filetype: '.pdf',
  file_format: 'pdf',
  is_deleted: false,
  submittal: 17542,
  type: 'schedule',
  search_status: null,
  abbreviation_replace_flag: false,
};
