import {
    FinalDocumentResponse,
    GetLinkDetailsResponse,
    PublishToShareLinkToggleRequest,
    UpdateSharedLinkDetailsRequest,
    UpdateSharedLinkDetailsResponse,
    ViewActivityResponse,
    DownloadResponseType
} from './apiTypes';

import { axios } from 'src/app/Axios';

export const endPoints = {
    finalDocument: (bomId: number, versionId: number, documentType: string) => `/bom/${bomId}/${documentType}/${versionId}`,
    sharedLinkDetails: (bomId: number, documentType: string) => `/bom/${bomId}/${documentType}/share_link`,
    activityLogs: (bomId: number, documentType: string) => `/bom/${bomId}/${documentType}/activity_logs`,
    download: (bomId: number, versionId: number, documentType: string) => `/bom/${bomId}/${documentType}/${versionId}/download?type=flattened`,
    updateShareLink: (bomId: number, documentType: string) => `/bom/${bomId}/${documentType}/share_link/`,
    publishToShareLink: (bomId: number, versionId: number, documentType?: string) => `/bom/${bomId}/${documentType}/${versionId}/`,
    getOutOfSyncUrl: (docType: string) => `/dashboard/bom/${docType}/out_of_sync`
};

export const getFinalDocument = (bomId: number, versionId: number, documentType: string) => {
    return axios.get<FinalDocumentResponse>(endPoints.finalDocument(bomId, versionId, documentType));
};

export const getSharedLinkDetails = (bomId: number, documentType: string) => {
    return axios.get<GetLinkDetailsResponse>(endPoints.sharedLinkDetails(bomId, documentType));
};

export const downloadFlattenedPDF = (bomId: number, versionId: number, documentType: string) => {
    return axios.get<DownloadResponseType>(endPoints.download(bomId, versionId, documentType));
};

export const getActivityLog = (bomId: number, documentType: string) => {
    return axios.get<ViewActivityResponse[]>(endPoints.activityLogs(bomId, documentType));
};

// mutation starts
export const updateShareLinkDetails = ({ data, bomId, documentType }: { data: UpdateSharedLinkDetailsRequest; bomId: number; documentType: string }) => {
    return axios.patch<UpdateSharedLinkDetailsResponse>(endPoints.updateShareLink(bomId, documentType), data);
};

export const publishToShareLinkToggle = ({ bom_Id, version_Id, data, documentType }: { bom_Id: number; version_Id: number; data: PublishToShareLinkToggleRequest; documentType?: string }) => {
    return axios.patch(endPoints.publishToShareLink(bom_Id, version_Id, documentType), data);
};
// mutation ends
