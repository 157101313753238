import { BodySmall } from '@parspec/pixel';

function AttributePrefillTitle() {
    return (
        <>
            <BodySmall color="secondary.light" fontWeight={500} mt={4} mb={2}>
                Attribute Pre-fill
            </BodySmall>

            <BodySmall color="text.secondary" fontWeight={400} mb={4}>
                Determine the values that we’ll use to pre-fill some of your Quote attributes.
            </BodySmall>
        </>
    );
}

export default AttributePrefillTitle;
