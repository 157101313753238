import { useState, useMemo, useRef, useEffect } from 'react';

import { Box, Snackbar } from '@parspec/pixel';

import { ManufacturersTable } from './ManufacturersTable';
import { useGetPersistedFiltersQuery } from '../../shared/queries';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { FILTERS_INITIAL_VALUE, FILTER_KEYS } from '../shared/components/Filters/constants';
import { useGetManufacturersTabDataQuery } from '../queries';
import { EVENT_SERVICE_PAGENAME_VALUES } from '../../shared/utils/constants';
import { getFilteredTabData, getFiltersForApiParams, getTableHeight, tableDataFormatter } from '../shared/utils';
import { TAB_NAMES } from '../shared/constants';
import Loader from '../../BOM/FinalDocuments/Compile/submittals&o_m/Loader';
import ManufacturersGraphs from './ManufacturersGraphs';
import { ManufacturersTabDataResponse } from '../queries/apiTypes';

export const Manufacturers = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerHeight, setContainerHeight] = useState<number>();

    const { data: userData } = useGetUserProfileInfoQuery();
    const { data: filtersData, isLoading: filtersDataLoading } = useGetPersistedFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: EVENT_SERVICE_PAGENAME_VALUES.REPORTING_MANUFACTURERS },
        {
            enabled: Boolean(userData?.data?.id)
        }
    );

    const filters = useMemo(() => {
        return filtersData?.table_settings_by_pk?.filters || FILTERS_INITIAL_VALUE;
    }, [filtersData?.table_settings_by_pk?.filters]);

    const { data: manufacturersData, isLoading: isManufacturersDataLoading } = useGetManufacturersTabDataQuery(getFiltersForApiParams(filters), {
        enabled: !filtersDataLoading,
        staleTime: 0
    });

    const tableData = useMemo(() => {
        if (!manufacturersData?.data) return [];
        const data = getFilteredTabData(manufacturersData?.data || [], filters?.[FILTER_KEYS.SEARCH_TEXT], 'manufacturer');
        return tableDataFormatter(data as ManufacturersTabDataResponse[], TAB_NAMES.MANUFACTURER);
    }, [manufacturersData?.data, filters?.[FILTER_KEYS.SEARCH_TEXT]]);

    const isDataLoading = isManufacturersDataLoading;

    useEffect(() => {
        if (containerRef?.current?.offsetHeight && !isDataLoading) {
            setContainerHeight(containerRef?.current?.offsetHeight);
        }
    }, [containerRef?.current, isDataLoading]);

    const tableHeight = getTableHeight(manufacturersData?.data || [], containerHeight);

    return (
        <>
            {isDataLoading ? (
                <Loader />
            ) : (
                <Box px={6} overflow="auto" height="100%" ref={containerRef}>
                    <ManufacturersGraphs manufacturersData={tableData as ManufacturersTabDataResponse[]} />
                    <Box width={'100%'} mt={6} height={tableHeight}>
                        <ManufacturersTable setSnackbarMessage={setSnackbarMessage} tableData={tableData} isDataLoading={isDataLoading} />
                    </Box>

                    <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
                </Box>
            )}
        </>
    );
};
