export const editBomDetailsResponse = {
    id: 123,
    primary_contact: 'new primary contact',
    secondary_contact: 'ldnlnf',
    name: '',
    project_id: 1234,
    Is_deleted: false,
    created_at: '2023-01-01',
    updated_at: '2023-01-01'
};
