import { BodyBig, BodySmall, Box, Switch, FileSelector } from '@parspec/pixel';

import { QuoteTemplateInterface } from '../../../../queries/apiTypes';
import { environment } from '../../../../../../../environments';
import { ACCEPTED_FILE_TYPES, ACCEPTED_FILE_TYPES_STRING, MAX_ALLOWED_FILE_SIZE_IN_BYTES, MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE_FOR_MULTI_UPLOAD } from '../../utils/constants';
import { IFileType } from '../../utils/helper';
import { FileAttributes } from '@parspec/pixel/dist/esm/components/FileSelector/types';

interface AttatchmentsProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    handleAttachments: (files: IFileType[]) => void;
    templateState: QuoteTemplateInterface;
}

export const Attachments = (props: AttatchmentsProps) => {
    const { handleAttachments, templateState, changeHandler } = props;

    const onFileUploadedToS3 = (files: IFileType[]) => {
        if (files.length !== templateState.attachments.documents.length) handleAttachments(files);
    };

    const handleFileDelete = (file: FileAttributes) => {
        const newUploadedFiles = templateState.attachments.documents.filter((item: any) => item.name !== file.name);
        handleAttachments(newUploadedFiles as unknown as IFileType[]);
    };

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.attachments?.is_enable}
                    onChange={(e) => changeHandler(e, 'attachments')}
                    name="is_enable"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Attachments
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Upload PDF documents in the sequence you want them to be attached to your quote.
            </BodySmall>

            <Box minWidth="350px" width="500px" m={4}>
                <FileSelector
                    placeholder="Drag and drop a file here, or:"
                    url={`${environment.b}/api/generate_signed_url/`}
                    onUpload={onFileUploadedToS3}
                    onDeleteFile={handleFileDelete}
                    maxFiles={Infinity}
                    showUploaderAlways
                    acceptedFormats={ACCEPTED_FILE_TYPES}
                    helperText={ACCEPTED_FILE_TYPES_STRING}
                    preSelectedFile={templateState.attachments.documents}
                    maxTotalFileSizeAllowed={{
                        size_in_bytes: MAX_ALLOWED_FILE_SIZE_IN_BYTES,
                        errorText: MAX_ALLOWED_FILE_SIZE_LIMIT_EXCEEDED_MESSAGE_FOR_MULTI_UPLOAD
                    }}
                />
            </Box>
        </Box>
    );
};
