import { CompanyDetailsForAddLocationInterface } from '../AddEditLocation.types';
import { SubmittalTemplateInterface, OMTemplateInterface, QuoteTemplateInterface, IQuoteAttachmentDocumentsPreset } from '../../queries/apiTypes';

const OPTION_NOTES_DEFAULT_TEXT =
    'OPTIONS:\nThe following table(s) contain options, which are suggested replacements, additions, or removals to the basis of design products. Any cost impact related to these options is not reflected in the grand total shown above.';

export const initialBranchDetailsErrorState = {
    name: { isError: false, title: '' },
    email: { isError: false, title: '' },
    phone1: { isError: false, title: '' },
    website: { isError: false, title: '' },
    address1: { isError: false, title: '' },
    address2: { isError: false, title: '' },
    city: { isError: false, title: '' },
    country: { isError: false, title: '' },
    state: { isError: false, title: '' },
    zipcode: { isError: false, title: '' }
};

export const initialBranchDetailsState = {
    name: '',
    email: '',
    phone1: '',
    website: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zipcode: ''
};

export const initialBranchBrandingState = {
    primary: { url: null, data: null, isChanged: false },
    secondary: { url: null, data: null, isChanged: false }
};

export const initialSubmittalDetails: SubmittalTemplateInterface = {
    cover_page_template_id: 1,
    cover_page_presets: {
        cover_page_section: true,
        primary_title: true,
        secondary_title: true,
        project_number: true,
        quote_number: true,
        stakeholders: false,
        prepared_by: true,
        addressed_to: false,
        date: false,
        project_address: true,
        notes: '',
        pre_fill_primary_title: 'BOM Name',
        pre_fill_secondary_title: 'Project Name',
        pre_fill_date: 'Submittal Creation Date',
        pre_fill_preparedby_logo: 'secondary',
        pre_fill_coverpage_logo: 'primary',
        pre_fill_addressed_to: 'Primary Customer'
    },
    header_footer_presets: {
        header_footer_section: true,
        job_name: true,
        page_number: true,
        document_type: true,
        date: true,
        left_footer: true,
        right_footer: true,
        hyperlinks: true,
        qty: false,
        notes: true,
        category: true,
        pre_fill_job_name: 'Project Name',
        pre_fill_header_logo: 'primary'
    },
    table_of_content_presets: {
        toc_section: true,
        type: true,
        page_number: true,
        hyperlinks: true,
        primary_title: true,
        secondary_title: true,
        project_number: false,
        quote_number: false,
        qty: false,
        section: true
    },
    notes_presets: {
        notes_section: true
    },
    layout_presets: {
        datasheet: 'REPEATED',
        installation_guide: 'EXCLUDE',
        drawing: 'EXCLUDE',
        warranty: 'EXCLUDE',
        other_document: 'EXCLUDE',
        section_breaks: 'INCLUDE',
        show_kit_components: 'INCLUDE',
        maintain_aspect_ratio: 'INCLUDE'
    }
};

export const initialOMPackageDetails: OMTemplateInterface = {
    cover_page_template_id: 1,
    cover_page_presets: {
        cover_page_section: true,
        primary_title: true,
        secondary_title: true,
        project_number: true,
        quote_number: true,
        stakeholders: false,
        prepared_by: true,
        addressed_to: false,
        date: false,
        project_address: true,
        notes: '',
        pre_fill_primary_title: 'BOM Name',
        pre_fill_secondary_title: 'Project Name',
        pre_fill_date: 'O&M Creation Date',
        pre_fill_preparedby_logo: 'secondary',
        pre_fill_coverpage_logo: 'primary',
        pre_fill_addressed_to: 'Primary Customer'
    },
    header_footer_presets: {
        header_footer_section: true,
        job_name: true,
        page_number: true,
        document_type: true,
        date: true,
        left_footer: true,
        right_footer: true,
        hyperlinks: true,
        qty: false,
        notes: true,
        category: true,
        pre_fill_job_name: 'Project Name',
        pre_fill_header_logo: 'primary'
    },
    table_of_content_presets: {
        toc_section: true,
        type: true,
        page_number: true,
        hyperlinks: true,
        primary_title: true,
        secondary_title: true,
        project_number: false,
        quote_number: false,
        qty: false,
        section: true
    },
    notes_presets: {
        notes_section: true
    },
    layout_presets: {
        datasheet: 'REPEATED',
        installation_guide: 'REPEATED',
        drawing: 'REPEATED',
        warranty: 'UNIQUE',
        other_document: 'EXCLUDE',
        section_breaks: 'INCLUDE',
        show_kit_components: 'INCLUDE',
        maintain_aspect_ratio: 'INCLUDE'
    }
};

export const initialQuoteDetails: QuoteTemplateInterface = {
    quote_header_footer: {
        header_footer_section: true,
        job_name: true,
        addressed_to: false,
        project_location: false,
        contact: false,
        quote_number: true,
        bid_date: true,
        expiration_date: true,
        left_footer: false,
        right_footer: false,
        date: false,
        page_number: true,
        hyperlink: true,
        pre_fill_job_name: 'Project Name',
        pre_fill_quote_number: 'Unique ID',
        pre_fill_bid_date: 'Quote Creation Date',
        pre_fill_expiration_date: 'Bid Date +30 days',
        pre_fill_header_logo: 'primary',
        pre_fill_addressed_to: 'Primary Customer'
    },
    quote_cover_page: {
        cover_page_section: true,
        stakeholders: false,
        prepared_by: false,
        addressed_to: false,
        opening_notes: true,
        opening_notes_text: { value: '', font: { color: '#FF0000', type: 'bold' } },
        pre_fill_preparedby_logo: 'secondary',
        pre_fill_addressed_to: 'Primary Customer'
    },
    quote_price_lead_time: {
        price_and_lead_time_section: true,
        quantity: true,
        type: true,
        manufacturer: true,
        model_number: true,
        notes: true,
        lead_time: true,
        unit_price: false,
        extended_price: false,
        section_subtotal: true,
        datasheet_hyperlink: true,
        lot_breakout: false,
        service_breakout: false,
        tax_breakout: false,
        freight_breakout: false,
        summarized_total: 'pstf_grand_total',
        show_kit_breakdown: false,
        description: false
    },
    quote_general_tnc: {
        general_tnc_section: true,
        closing_notes: true,
        closing_notes_text: { value: '', font: { color: '#FF0000', type: 'bold' } },
        project_notes: true,
        project_notes_text: '',
        general_tnc: true,
        general_tnc_text: '',
        is_general_tnc_locked: false
    },
    quote_manufacturer_tnc: {
        manufacturer_tnc_section: true
    },
    quote_price_lead_time_option: {
        quantity: true,
        type: true,
        manufacturer: true,
        model_number: true,
        notes: true,
        substituted_for: false,
        lead_time: true,
        unit_price: false,
        extended_price: false,
        add_deduct_amount: false,
        section_subtotal: true,
        datasheet_hyperlink: true,
        show_kit_breakdown: false,
        option_notes: true,
        option_notes_text: { value: OPTION_NOTES_DEFAULT_TEXT, font: { color: '#091535', type: 'bold' } },
        description: false
    },
    attachments: {
        documents: [],
        is_enable: true,
        company: null
    }
};

export interface initialCompanyDetailsForAddLocationInterface {
    data: CompanyDetailsForAddLocationInterface;
}

export const initialCompanyDetails: initialCompanyDetailsForAddLocationInterface = {
    data: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        name: '',
        email: '',
        website: '',
        phone1: '',
        picture: '',
        logo: '',
        submittal: initialSubmittalDetails,
        o_m: initialOMPackageDetails,
        quote: initialQuoteDetails
    }
};

export const TEXT_STYLE_VALUES = [
    {
        label: 'Light',
        value: 'light'
    },
    {
        label: 'Regular',
        value: 'regular'
    },
    {
        label: 'Bold',
        value: 'bold'
    }
];

export enum SECTION_BREAKS_AND_KIT_COMPONENTS_SWITCH_VALUE {
    INCLUDED = 'INCLUDE',
    EXCLUDED = 'EXCLUDE'
}

export const attachmentDocumentArrFormatter = (data: IQuoteAttachmentDocumentsPreset[]) => {
    if (!data || data?.length === 0) {
        return [];
    } else {
        const formattedData = data.map((el) => ({ name: el.name, s3_url: el.s3_url }));
        return formattedData;
    }
};
