import { axios } from 'src/app/Axios';
import { SuccessResponse } from '../../../BOM/queries/apiTypes';
import {
    AddAlternatesProps,
    AddAlternatesRequest,
    AddProductResponse,
    AddSuggestionRequest,
    AddSuggestionResponse,
    DocumentApiParams,
    DocumentSuggestionsResponse,
    SelectDataRequest,
    SelectSuggestionResponse,
    SupportManufacturerRequest,
    UnSelectDocumentRequest
} from './apiTypes';

///bom/<bom_id>/product_finder/<bod_id>/selected_datasheet/bulk?source=file_upload
export const endPoints = {
    documentCallUrl: ({ bomId, documentType, productId, subDocumentType, refresh }: DocumentApiParams) =>
        `/bom/${bomId}/${documentType}_metadata/${productId}/${subDocumentType === 'datasheet' ? '' : `${subDocumentType}/`}search?refresh=${refresh!.split('-')[0] === 'true'}`,
    selectDocument: ({ bomId, documentType, bodId, subDocumentType }: DocumentApiParams) => `/bom/${bomId}/${documentType}_metadata/${bodId}/selected_${subDocumentType}/`,
    unSelectDocument: ({ bomId, documentType, bodId, subDocumentType, selectedId }: DocumentApiParams) => `/bom/${bomId}/${documentType}_metadata/${bodId}/selected_${subDocumentType}/${selectedId}`,
    addDocument: ({ bomId, documentType, subDocumentType, actionType }: DocumentApiParams) => `/bom/${bomId}/${documentType}_metadata/selected_${subDocumentType}/bulk?source=${actionType}`,
    supportManufacture: '/support_manufacturer/',
    addAlternateDocument: ({ bomId, productId, actionType }: AddAlternatesProps) => `/bom/${bomId}/product_finder/${productId}/selected_datasheet/bulk?source=${actionType}`
};

export const getDocumentsCallback = async (keys: DocumentApiParams) => axios.post(endPoints.documentCallUrl(keys));

export const getDocumentSuggestions = async (url: string) => {
    return axios.get<DocumentSuggestionsResponse>(url);
};

export const selectDocument = async (data: SelectDataRequest) => {
    const { bomId, bodId, documentType, subDocumentType, input } = data;
    return axios.post<SelectSuggestionResponse>(endPoints.selectDocument({ bomId, bodId, documentType, subDocumentType }), input);
};

export const unSelectDocument = async (data: UnSelectDocumentRequest) => {
    const { bomId, bodId, selectedId, documentType, subDocumentType } = data;
    return axios.delete<SuccessResponse>(endPoints.unSelectDocument({ bomId, bodId, documentType, subDocumentType, selectedId }));
};

export const addSuggestion = async (data: AddSuggestionRequest) => {
    const { bomId, documentType, subDocumentType, input, actionType } = data;
    return axios.post<AddSuggestionResponse>(endPoints.addDocument({ bomId, documentType, subDocumentType, actionType }), actionType === 'file_upload' ? input : { ...input });
};

export const supportManufacturer = (payload: SupportManufacturerRequest) => {
    return axios.post(endPoints.supportManufacture, payload);
};

export const addAlternates = (data: AddAlternatesRequest) => {
    const { bomId, productId, input, actionType } = data;
    return axios.post<AddProductResponse>(endPoints.addAlternateDocument({ bomId, productId, actionType }), actionType === 'file_upload' ? input : { input });
};
