export const serviceGetJson = [
    {
        name: 'Installation',
        calculation_method: 'percentage_of_order',
        percentage_of_order: 69.0,
        flat_fee_cents: null
    },
    {
        name: 'Delivery',
        calculation_method: 'flat_fee',
        percentage_of_order: null,
        flat_fee_cents: 6900
    }
];
