import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, BodySmall, TextField, VisibilityIcon, VisibilityOffIcon, Skeleton, BodyMedium, BodyXS } from '@parspec/pixel';

import { IProfileState, IErrorStateType } from './MyProfile.types';
import { newPasswordVerifier, trimEmptySpaces } from './utils/utils';

interface IFormBodyProps {
    userProfileLoading: boolean;
    profileState: IProfileState;
    setProfileState: React.Dispatch<React.SetStateAction<IProfileState>>;
    errorState: IErrorStateType;
    setErrorState: React.Dispatch<React.SetStateAction<IErrorStateType>>;
    onSubmitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
    formRef: any;
}

export const FormBody = (props: IFormBodyProps) => {
    const { userProfileLoading, profileState, setProfileState, onSubmitHandler, formRef, errorState, setErrorState } = props;
    const navigate = useNavigate();

    const togglePasswordVisibility = (passwordFieldName: string) => {
        if (passwordFieldName === 'currentPassword') {
            setProfileState(() => ({ ...profileState, currentPassword: { ...profileState.currentPassword, showPassword: !profileState.currentPassword.showPassword } }));
        } else if (passwordFieldName === 'newPassword') {
            setProfileState(() => ({ ...profileState, newPassword: { ...profileState.newPassword, showPassword: !profileState.newPassword.showPassword } }));
        } else if (passwordFieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, confirmPassword: { ...profileState.confirmPassword, showPassword: !profileState.confirmPassword.showPassword } }));
        }
    };

    const keydownHandler = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        }
    };

    const fieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const fieldValue = event.target?.value;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(profileState, fieldName)) {
            return;
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (errorState.newPassword.isError || errorState.confirmPassword.isError) {
                setErrorState({ ...errorState, newPassword: { ...errorState.newPassword, isError: false, title: '' }, confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' } });
            }
        } else {
            if (errorState[fieldName].isError) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: false, title: '' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'currentPassword') {
            if (fieldValue.length === 0) {
                setErrorState({
                    ...errorState,
                    [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' }
                });
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            setProfileState(() => ({ ...profileState, [fieldName]: fieldValue }));
        }

        if (fieldName === 'currentPassword' || fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            setProfileState(() => ({ ...profileState, [fieldName]: { ...profileState[fieldName], data: fieldValue } }));
        }
    };

    const fieldBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const fieldName = event.target?.name;
        const eventType = event.type;

        if (!Object.prototype.hasOwnProperty.call(errorState, fieldName) && !Object.prototype.hasOwnProperty.call(profileState, fieldName)) {
            return;
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            if (profileState[fieldName].length > 0 && eventType === 'blur') {
                if (profileState[fieldName].trim().length === 0) {
                    return setErrorState({ ...errorState, [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' } });
                }
            }
        } else if (fieldName === 'currentPassword') {
            if (profileState[fieldName].data.length > 0 && eventType === 'blur') {
                if (profileState[fieldName].data.trim().length === 0) {
                    return setErrorState({ ...errorState, [fieldName]: { ...errorState[fieldName], isError: true, title: 'Required' } });
                }
            }
        }

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            const trimmedValue = trimEmptySpaces(profileState[fieldName]);
            setProfileState(() => ({ ...profileState, [fieldName]: trimmedValue }));
        }
    };

    const handleNewPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.confirmPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
                });
            }

            if (!newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }
        } else if (profileState.newPassword.data.trim().length === 0 && profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
            });
        }
    };

    const handleConfirmPasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (profileState.confirmPassword.data.trim().length > 0 && event.type === 'blur') {
            if (profileState.newPassword.data.trim().length === 0) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Required' }
                });
            }

            if (profileState.newPassword.data !== profileState.confirmPassword.data) {
                return setErrorState({
                    ...errorState,
                    confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'New and confirm password does not match' }
                });
            }

            if (profileState.newPassword.data.trim().length > 0 && !newPasswordVerifier(profileState.newPassword.data)) {
                return setErrorState({
                    ...errorState,
                    newPassword: { ...errorState.newPassword, isError: true, title: 'Password does not meet requirements' }
                });
            }
        } else if (profileState.confirmPassword.data.trim().length === 0 && profileState.newPassword.data.trim().length > 0 && event.type === 'blur') {
            return setErrorState({
                ...errorState,
                confirmPassword: { ...errorState.confirmPassword, isError: true, title: 'Required' }
            });
        }
    };

    useEffect(() => {
        if (profileState.newPassword.data.length === 0 && profileState.confirmPassword.data.length === 0) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }

        if (
            profileState.newPassword.data.length > 0 &&
            profileState.confirmPassword.data.length > 0 &&
            profileState.newPassword.data === profileState.confirmPassword.data &&
            newPasswordVerifier(profileState.confirmPassword.data)
        ) {
            setErrorState({
                ...errorState,
                newPassword: { ...errorState.newPassword, isError: false, title: '' },
                confirmPassword: { ...errorState.confirmPassword, isError: false, title: '' }
            });
        }
    }, [profileState.newPassword.data, profileState.confirmPassword.data]);

    return (
        <Box mt={8}>
            <form onSubmit={onSubmitHandler} ref={formRef} autoComplete={'off'}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <Grid item height={'72px'}>
                            {!userProfileLoading ? (
                                <TextField
                                    id={'firstName'}
                                    name={'firstName'}
                                    autoComplete={'given-name'}
                                    label={'First Name*'}
                                    value={profileState.firstName}
                                    error={errorState.firstName.isError}
                                    helperText={errorState.firstName.title}
                                    onKeyDown={keydownHandler}
                                    onBlur={fieldBlurHandler}
                                    onChange={fieldChangeHandler}
                                />
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Grid>

                        <Grid item height={'72px'}>
                            {!userProfileLoading ? (
                                <TextField
                                    id={'lastName'}
                                    name={'lastName'}
                                    autoComplete={'family-name'}
                                    label={'Last Name*'}
                                    value={profileState.lastName}
                                    error={errorState.lastName.isError}
                                    helperText={errorState.lastName.title}
                                    onKeyDown={keydownHandler}
                                    onBlur={fieldBlurHandler}
                                    onChange={fieldChangeHandler}
                                />
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Grid>
                        <Grid item height={'72px'}>
                            {!userProfileLoading ? (
                                <Box>
                                    <TextField
                                        id={'email'}
                                        name={'email'}
                                        autoComplete={'email'}
                                        label={'Email'}
                                        value={profileState.email}
                                        inputProps={{ tabIndex: -1 }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#6B7280'
                                            }
                                        }}
                                        sx={{
                                            pointerEvents: 'none',
                                            opacity: 0.5,
                                            '&:focus': {
                                                borderColor: 'transparent'
                                            },
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                // borderColor: 'black'
                                                border: '1px solid #6B7280',
                                                opacity: '0.5'
                                            },
                                            '& .MuiOutlinedInput-input:focus': {
                                                outline: 'none'
                                            }
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Grid>
                        <Grid item height={'72px'}>
                            {!userProfileLoading ? (
                                <TextField
                                    id={'currentPassword'}
                                    name={'currentPassword'}
                                    autoComplete={'new-password'}
                                    label={'Current Password*'}
                                    type={profileState.currentPassword.showPassword ? 'text' : 'password'}
                                    value={profileState.currentPassword.data}
                                    error={errorState.currentPassword.isError}
                                    helperText={errorState.currentPassword.title}
                                    onKeyDown={keydownHandler}
                                    onBlur={fieldBlurHandler}
                                    onChange={fieldChangeHandler}
                                    endIcon={
                                        profileState.currentPassword.showPassword ? (
                                            <VisibilityOffIcon
                                                cursor={'pointer'}
                                                onClick={() => {
                                                    togglePasswordVisibility('currentPassword');
                                                }}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                                cursor={'pointer'}
                                                onClick={() => {
                                                    togglePasswordVisibility('currentPassword');
                                                }}
                                            />
                                        )
                                    }
                                />
                            ) : (
                                <Skeleton variant="rectangular" height={36} />
                            )}
                        </Grid>
                        <Grid item>
                            <Box height={'72px'}>
                                {!userProfileLoading ? (
                                    <TextField
                                        id={'newPassword'}
                                        name={'newPassword'}
                                        autoComplete={'new-password-2'}
                                        label={'New Password'}
                                        type={profileState.newPassword.showPassword ? 'text' : 'password'}
                                        value={profileState.newPassword.data}
                                        error={errorState.newPassword.isError}
                                        helperText={errorState.newPassword.title}
                                        onKeyDown={keydownHandler}
                                        onChange={fieldChangeHandler}
                                        onBlur={handleNewPasswordBlur}
                                        endIcon={
                                            profileState.newPassword.showPassword ? (
                                                <VisibilityOffIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('newPassword');
                                                    }}
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('newPassword');
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" height={36} />
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box height={'72px'}>
                                {!userProfileLoading ? (
                                    <TextField
                                        id={'confirmPassword'}
                                        name={'confirmPassword'}
                                        autoComplete={'new-password-3'}
                                        label={'Confirm Password'}
                                        type={profileState.confirmPassword.showPassword ? 'text' : 'password'}
                                        value={profileState.confirmPassword.data}
                                        error={errorState.confirmPassword.isError}
                                        helperText={errorState.confirmPassword.title}
                                        onKeyDown={keydownHandler}
                                        onBlur={handleConfirmPasswordBlur}
                                        onChange={fieldChangeHandler}
                                        endIcon={
                                            profileState.confirmPassword.showPassword ? (
                                                <VisibilityOffIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('confirmPassword');
                                                    }}
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        togglePasswordVisibility('confirmPassword');
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" height={36} />
                                )}
                            </Box>
                        </Grid>
                        <Grid item height={'72px'}>
                            <BodySmall
                                width={'max-content'}
                                color="primary.main"
                                onClick={() => {
                                    navigate('/forgotPassword');
                                }}
                                sx={{ cursor: 'pointer' }}
                            >
                                Forgot Password?
                            </BodySmall>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Box bgcolor="neutral.light" p={4}>
                            <BodyMedium p={2}>Password Requirements</BodyMedium>
                            <BodyXS color="text.secondary" p={2} limit={false}>
                                1. At least 8 characters
                            </BodyXS>
                            <BodyXS color="text.secondary" p={2} limit={false}>
                                2. At least one uppercase letter
                            </BodyXS>
                            <BodyXS color="text.secondary" p={2} limit={false}>
                                3. At least one lowercase letter
                            </BodyXS>
                            <BodyXS color="text.secondary" p={2} limit={false}>
                                4. At least one number
                            </BodyXS>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
