import React, { useState, useEffect, useMemo } from 'react';

import { Box, FileSelector, FileSelectorFileType, Modal, ModalFooter, ModalHeader, RadioGroup, BodyMedium, Select, BodySmall, BodyXS, SelectChangeEvent } from '@parspec/pixel';

import { environment } from 'src/environments';
import { useCopyDataFromExistingBomMutation, useUploadDifferentFile } from './queries';
import { useParams } from 'react-router-dom';
import { useUserAccountPreferencesQuery } from '../../Settings/MyProfile/queries';
import { useGetBomSectionsQuery, useOptionSectionQuery } from '../queries';
import { useDatasheetAutoSearchMutation, useOmAutoSearchMutation } from '../queries';
import { PARSEC_EXCEL_IMPORT_WARNING } from '../shared/constants';
import ExistingBomOptionFlow from './ExistingBomOptionFlow';
import {
    AutoCompleteOption,
    EXISTING_BOM_OPTION,
    IMPORT_OPTIONS,
    SCHEDULE,
    SCHEDULE_ALLOWED_FILE_TYPES,
    SCHEDULE_ALLOWED_FILE_TYPE_STRING,
    SUBMITALL_PACKAGE_FILE_TYPE_STRING,
    SUBMITTAL_PACKAGE,
    SUBMITTAL_PACKAGE_ALLOWED_FILE_TYPES
} from './shared/constants';
import { REQUIRED_FIELD } from '../../shared/constants';

interface IFileType {
    file: FileSelectorFileType;
    s3_file_path?: string;
}

interface ImportMoreProductsProps {
    open: boolean;
    onCloseClick: () => void;
    onSuccess: (res: any, packageType: any) => void;
    onCopyExistingBomSuccess?: () => void;
}

export const ImportMoreProducts = (props: ImportMoreProductsProps) => {
    const { open, onCloseClick, onSuccess } = props;
    const { isLoading: isUploadFileLoading, mutateAsync: uploadFile } = useUploadDifferentFile();
    const { data: userPreferences } = useUserAccountPreferencesQuery();
    const { isLoading: isAutoSearchDatasheetLoading } = useDatasheetAutoSearchMutation();
    const { isLoading: isAutoSearchOMLoading } = useOmAutoSearchMutation();
    const [selectedProject, setSelectedProject] = useState<{ id: number; name: string }>();
    const [selectedBom, setSelectedBom] = useState<{ id: number; name: string }>();
    const [bomError, setBomError] = useState('');
    const [overWriteTaxes, setOverWriteTaxes] = useState(true);

    const { bomId } = useParams();

    //default value should be first option
    const [selectedSection, setSelectedSection] = useState<string | number>('');

    const [uploadedFilePath, setUploadedFilePath] = useState<string>();

    const [fileRequiredError, setFileRequiredError] = useState('');

    const [radioInputValue, setRadioInputValue] = useState(SCHEDULE);

    const acceptedFileTypes = radioInputValue === SCHEDULE ? SCHEDULE_ALLOWED_FILE_TYPES : SUBMITTAL_PACKAGE_ALLOWED_FILE_TYPES;

    const acceptedFileTypesString = radioInputValue === SCHEDULE ? SCHEDULE_ALLOWED_FILE_TYPE_STRING : SUBMITALL_PACKAGE_FILE_TYPE_STRING;

    const [uploadedFile, setUploadedFile] = useState<IFileType[] | null>();

    const isExistingBomOptionSelected = radioInputValue === EXISTING_BOM_OPTION;

    const { data: sectionData } = useGetBomSectionsQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { data: optionSectionData } = useOptionSectionQuery(Number(bomId), {
        enabled: Boolean(bomId)
    });

    const { mutateAsync: copyDataFromExistingBom, isLoading: existingBomMutationLoading } = useCopyDataFromExistingBomMutation();

    const onSelectProject = (project: AutoCompleteOption) => {
        setSelectedProject(project);
        setSelectedBom(undefined);
        setBomError('');
    };

    const onSelectBom = (bom: AutoCompleteOption) => {
        setSelectedBom(bom);
        setBomError('');
    };

    const onToggleOverWriteTaxes = (value: boolean) => setOverWriteTaxes(value);

    const optionSectionObj = useMemo(() => {
        if (!optionSectionData?.data) {
            return new Set<number>();
        }

        return optionSectionData?.data.reduce((optionsectionObj, sectionData) => optionsectionObj.add(sectionData.id), new Set<number>());
    }, [optionSectionData?.data]);

    const sectionsDropdownOptions = useMemo(() => {
        const allSectionsData = [...(sectionData?.data || []), ...(optionSectionData?.data || [])];
        return allSectionsData.map((item) => ({ name: item.name, id: item.id }));
    }, [sectionData?.data, optionSectionData?.data]);

    useEffect(() => {
        if (sectionData?.data?.length) {
            setSelectedSection(sectionData?.data?.[0].id);
        } else if (!sectionData?.data?.length && optionSectionData?.data.length) {
            setSelectedSection(optionSectionData?.data?.[0].id);
        }
    }, [sectionData?.data]);

    const onSelect = (files: FileSelectorFileType[]) => {
        if (!files.length) {
            setUploadedFile(null);
        }
        setFileRequiredError('');
    };

    const onUpload = (files: IFileType[]) => {
        if (files.length) {
            setUploadedFilePath(files[0].s3_file_path);
            setUploadedFile(files);
            setFileRequiredError('');
            return;
        }
        setFileRequiredError('');
        setUploadedFilePath('');
        setUploadedFile(null);
    };

    const radioChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioInputValue(event.target.value);
    };

    const handleExistinBomSubmit = async () => {
        if (!selectedBom) {
            return setBomError(REQUIRED_FIELD);
        }
        const input = {
            bomId: Number(bomId),
            source_bom_id: selectedBom.id,
            overwrite_taxes_and_freight: overWriteTaxes
        };
        await copyDataFromExistingBom(input);
        props.onCopyExistingBomSuccess?.();
    };

    const handleSubmit = async () => {
        if (isExistingBomOptionSelected) {
            return handleExistinBomSubmit();
        }
        if (!uploadedFile?.length) {
            setFileRequiredError('A file is required for the option selected.');
            return;
        }
        const formData = new FormData();
        formData.append('file_info', 'media/temp_files/' + uploadedFilePath!);
        if (sectionData?.data?.length || optionSectionData?.data.length) {
            formData.append('section_id', String(selectedSection));
            if (optionSectionObj.has(Number(selectedSection))) {
                formData.append('section_type', 'substitute');
            } else {
                formData.append('section_type', 'primary');
            }
        }
        // bomId will be passed by developer from route parameter.
        try {
            const res = await uploadFile({
                packageType: radioInputValue,
                bom_id: Number(bomId),
                data: formData
            });
            onSuccess(res.data, radioInputValue);
        } catch (e: any) {
            setFileRequiredError(e?.response?.data?.message);
        }
    };

    const header = <ModalHeader title="Import Products" onClose={onCloseClick} />;
    const footer = (
        <ModalFooter
            onReject={onCloseClick}
            onAccept={handleSubmit}
            continueButtonLabel="Confirm"
            isLoading={existingBomMutationLoading || (userPreferences?.data.autoselection ? isUploadFileLoading || isAutoSearchDatasheetLoading || isAutoSearchOMLoading : isUploadFileLoading)}
        />
    );
    return (
        <Modal header={header} footer={footer} open={open}>
            <form>
                <Box width="480px" position="relative" height={'100%'}>
                    {isUploadFileLoading && (
                        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                    )}

                    <Box>
                        <RadioGroup name="" options={IMPORT_OPTIONS} label="How would you like to import products?" onChange={(e: any) => radioChanged(e)} value={radioInputValue} />
                    </Box>
                    {!isExistingBomOptionSelected ? (
                        <>
                            {(sectionData?.data || []).length || (optionSectionData?.data || []).length ? (
                                <Box mt={2}>
                                    <BodySmall gutterBottom color="text.secondary">
                                        Select a section to import products to:
                                    </BodySmall>
                                    <Select
                                        label=""
                                        onChange={(e: SelectChangeEvent<unknown>) => setSelectedSection(e.target.value as string)}
                                        options={sectionsDropdownOptions}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        value={selectedSection}
                                    />
                                </Box>
                            ) : null}
                            <Box minHeight={200} mt={4}>
                                <BodyMedium fontWeight={500} marginBottom={4}>
                                    File Uploader
                                </BodyMedium>
                                {radioInputValue === SUBMITTAL_PACKAGE && (
                                    <FileSelector
                                        url={`${environment.b}/api/generate_signed_url/`}
                                        onSelect={onSelect}
                                        onUpload={onUpload}
                                        acceptedFormats={acceptedFileTypes}
                                        helperText={acceptedFileTypesString}
                                        error={fileRequiredError}
                                        placeholder="Drag and drop a file here, or:"
                                    />
                                )}

                                {radioInputValue === SCHEDULE && (
                                    <>
                                        <FileSelector
                                            url={`${environment.b}/api/generate_signed_url/`}
                                            onSelect={onSelect}
                                            onUpload={onUpload}
                                            acceptedFormats={acceptedFileTypes}
                                            helperText={acceptedFileTypesString}
                                            error={fileRequiredError}
                                            placeholder="Drag and drop a file here, or:"
                                        />
                                        <BodyXS color={'warning.main'} limit={false} mt={2}>
                                            {PARSEC_EXCEL_IMPORT_WARNING}
                                        </BodyXS>
                                    </>
                                )}
                            </Box>
                        </>
                    ) : (
                        <ExistingBomOptionFlow
                            selectedProject={selectedProject}
                            onSelectProject={onSelectProject}
                            selectedBom={selectedBom}
                            onSelectBom={onSelectBom}
                            bomError={bomError}
                            overWriteTaxes={overWriteTaxes}
                            onToggleOverWriteTaxes={onToggleOverWriteTaxes}
                        />
                    )}
                </Box>
            </form>
        </Modal>
    );
};
