import { useNavigate } from 'react-router-dom';

import { BodySmall } from '@parspec/pixel';

import CommonCard from '../shared/CommonCard';

interface DraftDocumentCardPropTypes {
    flag: string;
}

const DraftDocumentCard: React.FC<DraftDocumentCardPropTypes> = ({ flag }) => {
    const navigate = useNavigate();

    const handleCreateNew = () => {
        navigate(`finalDocuments/compile/${flag}`);
    };

    return (
        <CommonCard
            handleCreateNew={handleCreateNew}
            isAutoSync={true}
            content={
                <BodySmall color={'secondary.main'} lines={3}>
                    Changes have been made to the Bill of Materials since the latest <br /> version.
                </BodySmall>
            }
        />
    );
};

export default DraftDocumentCard;
