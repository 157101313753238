import { useOutletContext } from 'react-router-dom';
import { DashboardTableOutlet } from './constants';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { useGetUserViewsPreferenceQuery } from '../queries';
import { ViewOption } from '../queries/apiTypes';
import { useMemo } from 'react';
import { useGetAuthToken } from 'src/features/Authentication/hooks';

export function useDashboardOutletContext() {
    return useOutletContext<DashboardTableOutlet>();
}
export const useGetUserDashboardViews = () => {
    const { token } = useGetAuthToken();

    const { data: userData, isFetching: isUserDataFetching } = useGetUserProfileInfoQuery({ enabled: Boolean(token) });
    const { data: dashboardViewsData, isFetching: isDashboardViewsFetching } = useGetUserViewsPreferenceQuery(userData?.data?.id || 0, {
        enabled: Boolean(userData?.data?.id)
    });
    const defaultSelectedView: ViewOption = 'my_work';

    const dashboardSelectedViews = useMemo(() => {
        if (isDashboardViewsFetching) return null;
        if (dashboardViewsData?.user_view_preference?.length > 0) return dashboardViewsData?.user_view_preference[0]?.dashboard_view;
        return defaultSelectedView;
    }, [dashboardViewsData?.user_view_preference, isDashboardViewsFetching]);

    const isDashboardViewsLoading = isUserDataFetching || isDashboardViewsFetching;

    return { isDashboardViewsLoading, dashboardSelectedViews };
};
