import { MutableRefObject } from 'react';

import * as FileSaver from 'file-saver';

import { GetRowIdParams, ProcessCellForExportParams, ValueFormatterParams, RangeSelectionChangedEvent } from 'ag-grid-community';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { BodResponse, DatasheetTabDataResponse, hashAndVersionRequest, OmDataResponse } from '../../queries/apiTypes';
import { Internationalization } from '@syncfusion/ej2-base';
import { RECOGNISED_EXCEL_FOMRATS, SCHEDULE_FILE_TYPES } from '../constants';
import { UploadedScheduleResponse, UploadedSubmittalPackageResponse } from '../../ImportMoreProducts/queries/apiTypes';
import { UploadedDoc } from '../../../Dashboard/BOMModal/queries/apiTypes';
import { MainProductDataType } from '../../pricing&LeadTime/mainProductTable';
import { ToolBarT } from '../../../shared/CustomToolBarPanel';

export const getTableData = <T extends OmDataResponse | DatasheetTabDataResponse>(data1: BodResponse[] | undefined, data2?: T[] | undefined) => {
    if (data1?.length) {
        if (data2?.length) {
            const data = data1.map((item: BodResponse) => {
                const metaData = data2.find((meta: T) => item.id === meta.bod_id);
                return { ...item, ...metaData };
            });
            return data;
        } else {
            const data = data1.map((item: BodResponse) => {
                return { ...item };
            });
            return data;
        }
    }
    return [];
};

export const getPercent = (value: number, total: number) => {
    if (value === 0) return 0;
    return Math.ceil((value / total) * 100);
};

export const getPercentColor = (value: number) => {
    if (value > 75) return 'success.main';
    if (value >= 25 && value <= 75) return 'warning.main';
    if (value < 25) return 'error.main';
    return 'success.main';
};

export enum ReuseDialogTypeLabel {
    datasheet = 'Datasheet',
    warranty = 'Warranty',
    installation_guide = 'Installation Guide',
    drawing = 'Drawing',
    other_document = 'Other Document',
    alternates = 'product'
}

export enum ReuseDialogType {
    DATASHEET = 'datasheet',
    WARRANTY = 'warranty',
    GUIDE = 'installation_guide',
    DRAWING = 'drawing',
    OTHER = 'other_document'
}

export const deleteTitle = (selectedItems: number) => {
    if (selectedItems > 1) {
        return `Are you sure you want to delete the ${selectedItems} products selected?`;
    }
    return 'Are you sure you want to delete this product?';
};

export const deleteMessage = (mainProductOptionsInfo: Record<number, Array<number>>, deleteIds: number[]) => {
    if (isLinkedWithOptions(mainProductOptionsInfo, deleteIds)) {
        if (deleteIds.length > 1) {
            return deleteMultipleWithOptionsText;
        } else {
            return deleteContentWithOption;
        }
    } else {
        return deleteContent;
    }
};

export const areYouSure = 'Are you sure?';
export const deleteDialogTitle = 'Delete Product(s)';
export const deleteContent = 'This will delete the product throughout this BOM, and the change will be reflected on all the other tabs. ';
export const deleteContentWithOption = 'This will delete the product throughout this BOM and break any associations with linked options. This change will be reflected on all the other tabs.';
export const deleteMultipleWithOptionsText =
    'One or more products selected have associated options, and deleting these products will break this relationship. \n\n Are you sure you want to delete the selected product(s)?';

export const downloadFile = (url: string) => {
    const proxy_file = getProxyFileUrl(url);
    const fileName = url.split('/').pop();
    fetch(proxy_file)
        .then((res) => res.blob())
        .then((blob) => {
            const file = new Blob([blob], { type: 'application/pdf' });
            FileSaver.saveAs(file, fileName);
        });
};

export const options = [
    {
        label: 'Add a link to a file',
        value: 'addLink'
    },
    { label: 'Upload a file', value: 'addFile' },
    { label: 'Add a blank page', value: 'addBlankPage' },
    { label: `Reuse from another product in this BOM`, value: 'reuse' }
];
export const addProductManuallyOptions = [
    {
        label: 'Add a link to a file',
        value: 'addLink'
    },
    { label: 'Upload a file', value: 'addFile' }
];

export function getCancelButtonUrl(projectId = '', bomId = '', tabName = '', productId = '') {
    return `/v2/project/${projectId}/bom/${bomId}?tab=${tabName}&productId=${productId}`;
}

export const aggregateDocumentHashAndVersion = (datasheetTabData?: DatasheetTabDataResponse[]) => {
    if (!datasheetTabData) return;

    const documentHashAndVersion: hashAndVersionRequest[] = [];

    for (let i = 0; i < datasheetTabData.length; i++) {
        if (datasheetTabData[i].selected_datasheet_id) {
            documentHashAndVersion.push({ hash: datasheetTabData[i].selected_datasheet.document_hash, version: datasheetTabData[i].selected_datasheet.document_hash_version });
        }
    }
    return documentHashAndVersion;
};

export const isStartColBigger = (startCol: string, endCol: string) => {
    if (startCol.length === endCol.length) return startCol > endCol;

    return startCol.length > endCol.length;
};

export function getAmountForUi(amount: number) {
    const intl = new Internationalization();
    const amountForUi = intl.formatNumber(amount, { format: 'N2', useGrouping: true, minimumFractionDigits: 2 });

    return amountForUi;
}
export const isRecognisedFileFormat = (obj: UploadedScheduleResponse | UploadedSubmittalPackageResponse | UploadedScheduleResponse[] | UploadedSubmittalPackageResponse[] | UploadedDoc) => {
    const formats = [
        RECOGNISED_EXCEL_FOMRATS.OASIS_EXCEL,
        RECOGNISED_EXCEL_FOMRATS.EPISOR_EXCEL,
        RECOGNISED_EXCEL_FOMRATS.AGILE_EXCEL,
        RECOGNISED_EXCEL_FOMRATS.NORMAL_EXCEL,
        RECOGNISED_EXCEL_FOMRATS.PARSPEC_EXCEL
    ];
    if ('filetype' in obj && 'file_format' in obj) {
        return obj['filetype'] === SCHEDULE_FILE_TYPES.xmlFileType || formats.includes(String(obj['file_format']));
    }

    return false;
};

export function isLinkedWithOptions(mainProductOptionsInfo: Record<number, Array<number>>, deleteIds: number[]) {
    return deleteIds.find((id) => Object.keys(mainProductOptionsInfo).includes(String(id)));
}

export function getMainProductOptionObj(bomData: Array<BodResponse>) {
    const mainProductAndOptionsMap: Record<number, Array<number>> = {};
    for (const bodData of bomData) {
        if (bodData.product_to_replace && Array.isArray(mainProductAndOptionsMap[bodData.product_to_replace])) {
            mainProductAndOptionsMap[bodData.product_to_replace].push(bodData.id);
        } else if (bodData.product_to_replace) {
            mainProductAndOptionsMap[bodData.product_to_replace] = [bodData.id];
        }
    }

    return mainProductAndOptionsMap;
}

function getUsdFormattedString(amount: number) {
    const usDollarFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return usDollarFormatter.format(amount);
}

export function getPercentFormattedString(percent: number) {
    const percentFormatter = new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return percentFormatter.format(percent / 100);
}

export function getCellAmountFormatter(params: ValueFormatterParams<any, any>) {
    if (params?.value == null || params?.value == 0) {
        return '';
    }
    return getUsdFormattedString(params.value);
}

export function getCellPercentFormatter(params: ValueFormatterParams<any, any>) {
    if (params?.value == null) {
        return '';
    }
    return getPercentFormattedString(params.value);
}

export const handleProcessCellPasteForQty = (args: ProcessCellForExportParams) => {
    const { column, value } = args;
    const fieldName = column.getColId();
    if (fieldName === 'qty') {
        if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
            return Number(value);
        } else {
            return null;
        }
    }
    return value;
};

export const pricingCellParams = {
    precision: 2,
    preventStepping: true,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER
};

export const TextCellParams = {
    maxLength: 255
};
export const getRowId = (params: GetRowIdParams) => {
    return params?.data?.id;
};

export const prepareKitsAndComponentsData = (tableData: Array<any>) => {
    const parentBods: Record<string, any> = {};
    const childBods: Record<string, any> = {};
    const individualBods: Array<any> = [];

    for (const item of tableData) {
        item.components = [];
        if (item.is_kit) {
            parentBods[item.id] = item;
        } else if (item.kit) {
            childBods[item.id] = item;
        } else {
            individualBods.push(item);
        }
    }

    for (const key in childBods) {
        const parentId = childBods[key]['kit'];
        if (parentId && parentId in parentBods) {
            parentBods[parentId].components!.push(childBods[key]);
        }
    }

    for (const key in parentBods) {
        parentBods[key].components?.sort((a: { order: number }, b: { order: number }) => a.order - b.order);
    }

    return [...individualBods, ...Object.values(parentBods)].sort((a, b) => a.order - b.order);
};

export function getTableHeight({
    rowHeight,
    rows,
    tableMaxHeight,
    hasScroll = true,
    hasFooter = false
}: {
    rowHeight: number;
    rows: number;
    tableMaxHeight: number;
    hasScroll?: boolean;
    hasFooter?: boolean;
}) {
    // table rows height + header + footer
    if (!rows) return 150;
    let currentHeightOfTable = rowHeight * (rows || 1) + (hasScroll ? 98 : 86);
    if (hasFooter) {
        currentHeightOfTable -= 39;
    }
    return currentHeightOfTable > tableMaxHeight - 65 ? tableMaxHeight - 65 : currentHeightOfTable;
}

interface pricingDataType {
    cost: number;
    discountedCost: number;
    sellPrice: number;
    discount: number;
    margin: number;
    extendedPrice: number;
}

export const calculateKitCost = (tableData: MainProductDataType[], kitQuantity: number) => {
    const pricingData: pricingDataType = {
        cost: 0,
        discountedCost: 0,
        sellPrice: 0,
        discount: 0,
        margin: 0,
        extendedPrice: 0
    };
    const isAllNull = tableData?.every((item) => item.cost === null || item.qty === null || item.is_hidden);
    if (isAllNull) return { cost: null, discountedCost: null, discount: null, margin: null, sellPrice: null, extendedPrice: null };
    tableData.forEach((item: MainProductDataType) => {
        if (!item.is_hidden) {
            const { cost = 0, discountedCost = 0, sellPrice = 0, qty = 0 } = item;
            const quantity = (qty || 0) as any;
            pricingData.cost += (cost || 0) * quantity.toFixed(2);
            pricingData.discountedCost += (discountedCost || 0) * quantity.toFixed(2);
            pricingData.sellPrice += (sellPrice || 0) * quantity.toFixed(2);
        }
    });
    if (!pricingData.cost) {
        return { ...pricingData, discountedCost: null, discount: null, margin: null };
    }
    pricingData.discount = pricingData.cost === 0 ? 0 : Number(((1 - pricingData.discountedCost / pricingData.cost) * 100).toFixed(2));
    pricingData.margin = pricingData.sellPrice === 0 || pricingData.discountedCost === 0 ? 0 : Number(((1 - pricingData.discountedCost / pricingData.sellPrice) * 100).toFixed(2));
    pricingData.extendedPrice = Number((pricingData.sellPrice * kitQuantity).toFixed(2));
    return pricingData;
};

export const kitToolbarItems = ['add', 'delete', 'hide', 'duplicate'] as ToolBarT[];
export const DRAGABLE_TYPES = {
    SECTION: 'Section'
};

export const getAdjacentColumnNames = (tableRef: any, startColumnName: string, filterColums?: string[]) => {
    let columns = tableRef.current?.columnApi?.api?.columnModel?.columnDefs
        .filter((item: any) => item?.field || item?.colId)
        .map((el: { field?: string; colId?: string; cellDataType?: string }) => ({ field: el.field || el.colId, cellDataType: el?.cellDataType || null }));
    const sliceIndex = columns.findIndex((item: { field: string }) => item.field === startColumnName);
    if (sliceIndex !== -1) {
        columns = columns.slice(sliceIndex);
    }

    if (filterColums?.length) {
        columns = columns.filter((col: { field: string }) => !filterColums.includes(col.field));
    }
    return columns;
};

export const getParsedContentFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    if (text.length) {
        return text
            .trim()
            .split('\n')
            .map((row) => row.split('\t').map((cell) => cell.replace(/\r/g, '')));
    }
    return [];
};

const getLastUpdatedRowId = (selectedRowsRef: MutableRefObject<Set<number>>) => {
    return Array.from(selectedRowsRef.current)[selectedRowsRef.current.size - 1];
};

export const addNewRowsToTheTable = async (
    selectedRowsRef: MutableRefObject<Set<number>>,
    tableRef: any,
    startColumnName: string,
    createNewRowsFromClipboard: (lastUpdatedBodId: number, selectedColumns: Set<string> | Set<{ field: string; cellDataType: string }>, newRowsData?: string[][]) => Promise<void>,
    toRemoveColumnList?: Array<string>
) => {
    try {
        const rowsData = await getParsedContentFromClipboard();
        if (selectedRowsRef.current.size && tableRef.current && rowsData.length) {
            const columnsData: Set<{ field: string; cellDataType: string }> = new Set(getAdjacentColumnNames(tableRef, startColumnName, toRemoveColumnList));
            const lastUpdatedBodId = getLastUpdatedRowId(selectedRowsRef);
            const newRowsData = rowsData.slice(selectedRowsRef.current.size);
            createNewRowsFromClipboard(lastUpdatedBodId, columnsData, newRowsData);
            selectedRowsRef.current.clear();
        }
    } catch (error) {}
};

export const handleOnRangeSelectionChanged = (event: RangeSelectionChangedEvent, tableRef: any, selectedRowsRef: MutableRefObject<Set<number>>) => {
    const cellRanges = tableRef.current?.api.getCellRanges();
    if (!cellRanges || cellRanges.length === 0) {
        return;
    }
    selectedRowsRef.current.clear();
    let startRow = 0,
        endRow = 0;
    if (cellRanges) {
        cellRanges.forEach((range: any) => {
            startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
            endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        });
    }
    if (event.finished) {
        const tableRowIndicies = tableRef.current?.api?.rowModel?.rowsToDisplay?.map((item: { rowIndex: number }) => item.rowIndex);
        let rowIndex = endRow === startRow ? endRow : startRow;
        for (; rowIndex < tableRowIndicies.length; rowIndex++) {
            selectedRowsRef.current.add(tableRef.current?.api.getDisplayedRowAtIndex(rowIndex)?.data?.id);
        }
    }
};
