export function getLumpSumRadioOptions(flatFeeInput: () => React.ReactNode) {
    const lumpSumRadioOptions = [
        {
            value: 'flat_fee',
            label: 'Flat fee',
            helper: flatFeeInput()
        },
        {
            value: 'percentage_of_order',
            label: 'Percentage of order'
        },
        {
            value: 'freight_excluded',
            label: 'Freight excluded'
        }
    ];

    return lumpSumRadioOptions;
}
