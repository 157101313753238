import { BodyBig, BodySmall, Box, Switch } from '@parspec/pixel';

import { SubmittalTemplateInterface, OMTemplateInterface } from '../../queries/apiTypes';

interface NoteSummaryProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: SubmittalTemplateInterface | OMTemplateInterface;
    templateName: 'SUBMITTAL' | 'OM';
}

const SUBMITTAL_SECTION_DISABLED_MESSAGE = 'This section is currently disabled. By default it will not appear in your submittal.';
const OM_SECTION_DISABLED_MESSAGE = 'This section is currently disabled. By default it will not appear in your O&M.';

const NOTES_SUMMARY_SECTION_DESCRIPTION =
    'Table of types and their associated notes. Ideal for rapid communication of pending requests for information. Only products with a note are displayed. The design layout and fields for this external notes page will mimic the ones selected for the Table of Contents.';

export const NotesSummary = (props: NoteSummaryProps) => {
    const { changeHandler, templateState, templateName } = props;

    return (
        <Box mt={'24px'}>
            <Box ml="4px" display="flex" alignItems="center">
                <Switch
                    checked={templateState.notes_presets.notes_section}
                    onChange={(e) => changeHandler(e, 'notes_presets')}
                    name="notes_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Notes Summary
                        </BodyBig>
                    }
                />

                {!templateState.notes_presets.notes_section && (
                    <Box bgcolor={'warning.light'} border={'1px solid warning.main'} p={2}>
                        {templateName === 'SUBMITTAL' && <BodySmall color="warning.main">{SUBMITTAL_SECTION_DISABLED_MESSAGE}</BodySmall>}
                        {templateName === 'OM' && <BodySmall color="warning.main">{OM_SECTION_DISABLED_MESSAGE}</BodySmall>}
                    </Box>
                )}
            </Box>
            <BodySmall limit={false} color="text.secondary" mt={2}>
                {NOTES_SUMMARY_SECTION_DESCRIPTION}
            </BodySmall>
        </Box>
    );
};
