import { createContext } from 'react';

interface ContextType {
    handlePreviewImage: (arg: boolean) => void;
    accordionFieldName: string;
    presetResponse: string;
}

const PreviewContext = createContext<ContextType>({
    handlePreviewImage: () => {},
    accordionFieldName: '',
    presetResponse: ''
});

export default PreviewContext;
