import { FC, useMemo } from 'react';

import { Box, useTheme } from '@parspec/pixel';

import {
    MARGIN_REVENUE_BAR_INFO,
    PROFIT_CHART_HEADER,
    PROFIT_CHART_SUB_HEADER,
    PROFIT_MARGIN_BAR_INFO,
    PROFIT_MARGIN_LEGENDS,
    REVENUE_PROFIT_CHART_HEADER,
    REVENUE_PROFIT_LEGENDS,
    REVENUE_PROFIT_SUB_HEADER,
    TAB_NAMES,
    WIN_RATE_BAR_INFO,
    WIN_RATE_CHART_HEADER,
    WIN_RATE_CHART_SUB_HEADER,
    WIN_RATE_LEGENDS
} from '../shared/constants';
import { CustomersTabDataResponse } from '../queries/apiTypes';
import { getAverage, getProfitMarginChartData, getRevenueProfitChartData, getWinRateChartData, millionFormmater, nbspFormatter, percentageFormatter } from '../shared/utils';
import BarChart from '../shared/components/BarChart';

interface CustomerGraphsProps {
    customersData?: CustomersTabDataResponse[];
}

const CustomerGraphs: FC<CustomerGraphsProps> = ({ customersData }) => {
    const muiTheme = useTheme();

    const graphDataObject = useMemo(() => {
        if (!customersData) return;
        const winRateChartData = getWinRateChartData(customersData || [], TAB_NAMES.CUSTOMER);
        const profitMarginChartData = getProfitMarginChartData(customersData || [], TAB_NAMES.CUSTOMER);
        const revenueMarginChartData = getRevenueProfitChartData(customersData || [], TAB_NAMES.CUSTOMER);
        return { winRateGraphData: winRateChartData, profitMarginGraphData: profitMarginChartData, revenueMarginGraphData: revenueMarginChartData };
    }, [customersData]);

    const winRateAverage = useMemo(() => {
        return getAverage((customersData || [])?.map((obj) => obj.winRate) || []);
    }, [customersData]);

    const profitMarginAverage = useMemo(() => {
        const profitMargins = (customersData || [])?.map((obj) => {
            const { totalExtSellPrice, services, freight, totalExtDiscountedCost } = obj;
            const revenue = totalExtSellPrice + services + freight;
            return !revenue ? 0 : ((totalExtSellPrice - totalExtDiscountedCost) / revenue) * 100;
        });
        return getAverage(profitMargins || []);
    }, [customersData]);

    const graphDataLoading = !graphDataObject;

    return (
        <Box display="flex" gap={4} width={1}>
            <Box width={1}>
                <BarChart
                    isLoading={graphDataLoading}
                    header={WIN_RATE_CHART_HEADER(TAB_NAMES.CUSTOMER)}
                    subHeader={WIN_RATE_CHART_SUB_HEADER}
                    legends={WIN_RATE_LEGENDS}
                    data={graphDataObject?.['winRateGraphData'] || []}
                    barsInfo={WIN_RATE_BAR_INFO}
                    referenceLineInfo={[{ value: winRateAverage, color: muiTheme.palette.tertiary.main }]}
                    xAxisTickFormatter={percentageFormatter}
                    yAxisTickFormatter={nbspFormatter}
                    referenceLabelFormatter={percentageFormatter}
                    tooltipValueFormatter={percentageFormatter}
                />
            </Box>
            <Box width={1}>
                <BarChart
                    isLoading={graphDataLoading}
                    header={PROFIT_CHART_HEADER(TAB_NAMES.CUSTOMER)}
                    subHeader={PROFIT_CHART_SUB_HEADER}
                    legends={PROFIT_MARGIN_LEGENDS}
                    data={graphDataObject?.['profitMarginGraphData'] || []}
                    barsInfo={PROFIT_MARGIN_BAR_INFO}
                    referenceLineInfo={[{ value: profitMarginAverage, color: muiTheme.palette.tertiary.main }]}
                    xAxisTickFormatter={percentageFormatter}
                    yAxisTickFormatter={nbspFormatter}
                    referenceLabelFormatter={percentageFormatter}
                    tooltipValueFormatter={percentageFormatter}
                />
            </Box>
            <Box width={1}>
                <BarChart
                    isLoading={graphDataLoading}
                    header={REVENUE_PROFIT_CHART_HEADER(TAB_NAMES.CUSTOMER)}
                    subHeader={REVENUE_PROFIT_SUB_HEADER}
                    legends={REVENUE_PROFIT_LEGENDS}
                    data={graphDataObject?.['revenueMarginGraphData'] || []}
                    barsInfo={MARGIN_REVENUE_BAR_INFO}
                    xAxisTickFormatter={millionFormmater}
                    yAxisTickFormatter={nbspFormatter}
                    referenceLabelFormatter={millionFormmater}
                    tooltipValueFormatter={millionFormmater}
                />
            </Box>
        </Box>
    );
};

export default CustomerGraphs;
