import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { Modal, Box, BodyBig, BodySmall, CircularProgress } from '@parspec/pixel';

import { Footer } from './Footer';
import { QUOTE } from '../constants';
import { useGetProjectBomsQuery } from '../../../Project/queries';
import { IBomAndQuoteIds } from '../shared/types';
import { getUnifiedStrFromApi } from '../../../shared/utils/utils';
import { useGetProjectDetailsQuery } from '../../../Project/queries';

interface IDownloadQuotesModalProps {
    open: boolean;
    onClose: () => void;
    bomIds: number[];
    setSnackbarOpen: (message: string, autoHideDuration?: number | null | undefined) => void;
    onAccept: () => void;
}

export const DownloadQuotesModal: React.FC<IDownloadQuotesModalProps> = ({ open, onClose, bomIds, setSnackbarOpen, onAccept }) => {
    const { projectId = '0' } = useParams();

    const { data: projectBomsData, isLoading: isProjectBomsLoading } = useGetProjectBomsQuery(Number(projectId), {
        enabled: Boolean(Number(projectId))
    });

    const { data: projectDetails, isLoading: isProjectDetailsLoading } = useGetProjectDetailsQuery(Number(projectId), {
        enabled: Boolean(Number(projectId))
    });

    const bomAndLatestQuoteIdArr = useMemo(() => {
        const result: Array<IBomAndQuoteIds> = [];

        if (projectBomsData?.data && bomIds?.length && projectDetails?.data) {
            projectBomsData.data.forEach((bom) => {
                if (bomIds.includes(bom.id) && bom?.quote) {
                    result.push({
                        bomId: String(bom.id),
                        quoteId: bom.quote[0].id,
                        filename: getUnifiedStrFromApi([bom?.quote?.[0].id, bom?.primary_contact?.company?.name, bom?.name, projectDetails.data.name])
                    });
                }
            });
        }

        return result;
    }, [projectBomsData?.data, bomIds, projectDetails?.data]);

    return (
        <Modal open={open} footer={<Footer onAccept={onAccept} onReject={onClose} documentType={QUOTE} setSnackbarOpen={setSnackbarOpen} bomAndQuoteIds={bomAndLatestQuoteIdArr} />}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width="410px" p={4} pb={0} pt={2}>
                {isProjectBomsLoading || isProjectDetailsLoading ? (
                    <Box display="flex" alignItems="center" justifyContent="center" height={1} width={1}>
                        <CircularProgress color="primary" size="xxl" />
                    </Box>
                ) : (
                    <>
                        <Box width={'100%'} textAlign="center">
                            <BodyBig color={'secondary.main'}>Your Quote(s) are Complete!</BodyBig>
                            <Box mt={4}>
                                <BodySmall color={'neutral.dark'} lines={2} sx={{ lineHeight: '20px' }}>
                                    You can now download your document(s) or send via email to your customer(s).
                                </BodySmall>
                            </Box>
                        </Box>
                        <Box my={2}>
                            <img src="assets/images/view-files/quotesCompleteDownloadIcon.svg" alt="quotesComplete" height="240px" />
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};
