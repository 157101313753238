import { BodySmall, Box, Link, Menu } from '@parspec/pixel';
import { TableLinkTemplate } from '../../shared/utils/utils';
import { ICellRendererParams } from 'ag-grid-community';
import { getDate } from '../../Project/shared/utils';
import { CompanyObjType, VisibilityObjType } from '../Company/queries/apiTypes';

export const companyTemplate = (name: string, id: number | string, target?: '_blank') => {
    return <TableLinkTemplate to={`/v2/company/${id}`} label={name} target={target} />;
};

export const websiteTemplate = (url: string, target?: '_blank') => {
    return url.length > 0 ? (
        <Link href={url} target={target}>
            {url}
        </Link>
    ) : (
        '--'
    );
};

export const commonTemplate = (args: ICellRendererParams) => {
    const { colDef, data } = args;

    return data?.[colDef?.field || '']?.length > 0 ? (
        <Box display="flex" alignItems="center" height="100%">
            <BodySmall>{data[colDef?.field || '']}</BodySmall>
        </Box>
    ) : (
        '--'
    );
};

export const emailTemplate = (email: string, handleClick: (event: React.MouseEvent) => void) => {
    return email.length > 0 ? (
        <BodySmall limit={false} color={'primary.main'} onClick={handleClick} sx={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden' }}>
            {email}
        </BodySmall>
    ) : (
        '--'
    );
};

export const contactTemplate = (name: string, id: number | string, target?: '_blank') => {
    return <TableLinkTemplate to={`/v2/company/1/contacts/${id}`} label={name} target={target} />;
};

export const menuOptions = (props: ICellRendererParams, onEdit: (props: ICellRendererParams) => void, onDelete: (props: ICellRendererParams) => void, isAdmin: boolean) => {
    const options: Array<{ label: string; onClick: () => any; color?: string; disabled?: boolean }> = [{ label: 'Edit', onClick: () => onEdit?.(props) }];
    if (isAdmin) {
        options.push({ label: 'Delete', onClick: () => onDelete?.(props), color: 'error.main' });
    }

    return (
        <Box display="flex" justifyContent="center">
            <Menu options={options} />
        </Box>
    );
};

export const lastModifiedTemplate = (lastModifiedBy: string, lastModifiedDate: string) => {
    return (
        <Box display="flex" flexDirection="column" mt={-2}>
            <Box component="span">{lastModifiedBy || ''}</Box>
            <Box color="secondary.light" component="span" fontSize={12} mt={-6}>
                {lastModifiedDate ? getDate(lastModifiedDate) : '-'}
            </Box>
        </Box>
    );
};

export const renderCompanyDeleteTitle = (selectedCompanyCount: number) => {
    return selectedCompanyCount > 1
        ? `Are you sure you want to delete the ${selectedCompanyCount} company(ies) selected and their associated contact(s)? `
        : 'Are you sure you want to delete this company and their associated contact(s)? ';
};

export const companyDeleteBody = () => {
    return (
        <BodySmall lines={3} color={'primary.main'} limit={false}>
            <strong>These companies or contacts may be used by other teams in your org.</strong> If these companies are not relevant to you or your team, consider adjusting the visibility for these
            companies to exclude your team.
        </BodySmall>
    );
};

export const renderContactName = (args: ICellRendererParams) => {
    const { id, first_name, last_name } = args.data;
    return contactTemplate(`${first_name} ${last_name}`.trim(), id);
};

export const renderCompanyName = (args: ICellRendererParams) => {
    const { id, name } = args?.data?.company_info || {};
    return companyTemplate(name, id);
};

export const renderLastModifiedDate = (args: ICellRendererParams) => {
    const { updated_by, updated_at } = args.data;
    return lastModifiedTemplate(updated_by, updated_at);
};

export const getLastModifiedFilterText = (args: ICellRendererParams) => {
    const { updated_by, updated_at } = args.data;
    return `${updated_by} ${getDate(updated_at)}`;
};

export const getLocationsFilterText = (args: ICellRendererParams) => {
    const { visible_to_all, visible_to: locations } = args.data;

    if (visible_to_all) return 'All Locations';
    else return locations.map((el: VisibilityObjType) => el.name).join(' ');
};

export const dateComparator = (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
    const dateObj1 = new Date(nodeA.data.updated_at);
    const dateObj2 = new Date(nodeB.data.updated_at);

    if (dateObj1 < dateObj2) {
        return -1;
    } else if (dateObj1 > dateObj2) {
        return 1;
    } else {
        return 0;
    }
};

export const removeCompaniesDuplicateRecords = (arr: CompanyObjType[]) => {
    return arr.filter((value: CompanyObjType, index: number, self: CompanyObjType[]) => index === self.findIndex((item: CompanyObjType) => item.id === value.id));
};
