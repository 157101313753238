import { PropsWithChildren } from 'react';

import { USER_ROLES, USER_ROLES_VAL } from '../constants';
import { useGetUserRole } from './RBACHook';

interface RBACProps {
    allowedRoles: Array<USER_ROLES_VAL>;
    children: React.ReactNode;
    fallback?: React.ReactNode;
    loadingFallback?: React.ReactNode;
}

export const RBAC: React.FC<PropsWithChildren<RBACProps>> = function ({ children, allowedRoles, fallback, loadingFallback }) {
    const { currentUserRole = USER_ROLES.USER, isUserRoleLoading } = useGetUserRole();
    const isCurrentUserAllowed = allowedRoles.includes(currentUserRole);

    if (isUserRoleLoading && loadingFallback) {
        return <>{loadingFallback}</>;
    }

    if (!isCurrentUserAllowed) {
        if (fallback) {
            return <>{fallback}</>;
        }
        return <></>;
    }

    return <>{children}</>;
};
