import { axios } from 'src/app/Axios';
import {
    addBomDataRequest,
    BodResponse,
    BomStatsResponse,
    deleteBomDataRequest,
    duplicateBomDataRequest,
    exportBomDataRequest,
    ExportResponse,
    reorderBomDataRequest,
    reuseDatasheetRequest,
    SuccessResponse,
    toggleHideBomDataRequest,
    updateDatasheetMetaDataRequest,
    BomDetails,
    DatasheetTabStatsResponse,
    DatasheetTabDataResponse,
    ToggleHideResponse,
    DatasheetAutoSearchRequest,
    DatasheetAutoSearchResponse,
    OmDataResponse,
    OmTabStatsResponse,
    OmAutoSearchResponse,
    DatasheetDetailResponse,
    SortProductListRequest,
    SortProductListResponse,
    SectionUpdateRequest,
    SectionDeleteRequest,
    SectionCreateRequest,
    SectionMoveRequest,
    CreateKitRequest,
    SectionApiResponseType,
    updateBomDataRequest,
    ApproveProductRequest,
    ToggleHighlightRequest,
    ToggleHighlightResponse,
    UpdateSectionOrderTypes,
    AddMultipleBodsRequest,
    updateGrandTotalRequest,
    updateGrandTotalResponse
} from './apiTypes';
import { getEvent } from '../../shared/UserActivityTracking/EventService';
import { SECTION_TYPE_FOR_PAYLOAD } from '../shared/constants';

interface ISortProductList {
    sortOn: SortProductListRequest;
    bomId: string;
}

export const endPoints = {
    bodList: (bomId: string) => `/bom/${bomId}/bod/`,
    bodStats: (bomId: string) => `/bom/${bomId}/statistics`,
    bodDetail: (bomId: string, bodId: string) => `/bom/${bomId}/bod/${bodId}`,
    datasheetTabData: (bomId: string) => `/bom/${bomId}/submittal_metadata/`,
    datasheetTabStats: (bomId: string) => `/bom/${bomId}/statistics/submittal`,
    datasheetDetail: (bomId: string, bodId: string) => `/bom/${bomId}/submittal_metadata/${bodId}`,
    omTabData: (bomId: string) => `/bom/${bomId}/o_m_metadata/`,
    omTabStats: (bomId: string) => `/bom/${bomId}/statistics/o_m`,
    bomDetail: (bomId: string) => `/bom/${bomId}`,
    datasheetAutoSearch: (bomId: number) => `/bom/${bomId}/submittal_metadata/selected_datasheet/bulk?source=auto_selection`,
    omAutoSearch: (bomId: number) => `/bom/${bomId}/o_m_metadata/bulk?source=auto_selection`,
    omDetail: (bomId: string, bodId: string) => `/bom/${bomId}/o_m_metadata/${bodId}`,
    sortProductList: (bomId: string) => `/bom/${bomId}/bod/sort`,
    getSectionsUrl: (bomId: number) => `/bom/${bomId}/section/`,
    getOptionSectionsUrl: (bomId: number) => `/bom/${bomId}/substitute_section/`,
    getOptionSectionsUpdateUrl: (bomId: number, sectionId: number) => `/bom/${bomId}/substitute_section/${sectionId}/`,
    getUpdateSectionUrl: (bomId: number, sectionId: number) => `/bom/${bomId}/section/${sectionId}/`,
    getMoveToSectionUrl: (bomId: number) => `/bom/${bomId}/bulk/move`,
    getKitsUrl: (bomId: number) => `/bom/${bomId}/kit/`,
    approveOptionsProductUrl: (bomId: number) => `/bom/${bomId}/bod/approve/bulk/`,
    getDuplicateBodUrl: (bomId: string) => `/bom/${bomId}/bod/duplicate/bulk/`,
    getHighlightUrl: (bomId: string) => `/bom/${bomId}/bod/bulk/toggle_highlight`,
    sectionOrder: (bomId: string, sectionType: string) => `/bom/${bomId}/${sectionType}/order/`,
    addMultipleBods: (bomId: string) => `/bom/${bomId}/bod/bulk/`,
    unlinkBom: (bomId: string) => `/bom/${bomId}/unlink/`,
    updateGrandTotal: (bomId: string) => `/bom/${bomId}/aggregates/`
};

//Bod APis
export const getBomData = async (bomId: string) => {
    return axios.get<BodResponse[]>(endPoints.bodList(bomId));
};

export const getBomTabStats = async (bomId: string) => {
    return axios.get<BomStatsResponse>(endPoints.bodStats(bomId));
};

export const reorderBomData = async (data: reorderBomDataRequest) => {
    const { bomId, input } = data;
    return axios.patch<SuccessResponse>(`/bom/${bomId}/bod/order/`, input);
};

export const updateGrandTotal = async (data: updateGrandTotalRequest) => {
    const { bomId, input } = data;
    return axios.put<updateGrandTotalResponse>(endPoints.updateGrandTotal(bomId), input);
};

export const deleteBomData = async (data: deleteBomDataRequest) => {
    const { bomId, ids } = data;
    return axios.delete<SuccessResponse>(`/bom/${bomId}/bod/bulk/`, { data: { bod_id: ids } });
};

export const duplicateBomData = async (data: duplicateBomDataRequest) => {
    const { bomId, input } = data;
    return axios.post<BodResponse[]>(endPoints.getDuplicateBodUrl(bomId), { bod_id: input });
};

export const toggleHideBomData = async (data: toggleHideBomDataRequest) => {
    const { bomId, input } = data;
    return axios.patch<ToggleHideResponse>(`/bom/${bomId}/bod/bulk/toggle_visibility`, { bod_id: input });
};

export const addBomData = async (data: addBomDataRequest) => {
    const { bomId, input } = data;
    return axios.post<BodResponse[]>(`/bom/${bomId}/bod/`, input);
};

export const updateBomData = async (data: updateBomDataRequest) => {
    const { bomId, input } = data;
    const { id, ...rest } = input;
    if (Object.hasOwn(rest, 'kit')) {
        rest.kit_id = rest.kit;
        delete rest['kit'];
    }
    return axios.patch<SuccessResponse>(`/bom/${bomId}/bod/${id}/`, { ...rest });
};

export const exportBomData = async (data: exportBomDataRequest) => {
    const { bomId, format } = data;
    return axios.post<ExportResponse>(`/bom/${bomId}/export/`, {
        format
    });
};

export const getBodDetail = async (bomId: string, bodId: string) => {
    return axios.get(endPoints.bodDetail(bomId, bodId));
};

export const getBomSections = async (bomId: number) => {
    return axios.get<SectionApiResponseType[]>(endPoints.getSectionsUrl(bomId));
};

// Datasheet(Submittal) Tab APIs
export const getDatasheetTabData = async (bomId: string) => {
    return axios.get<DatasheetTabDataResponse[]>(endPoints.datasheetTabData(bomId));
};

export const getDatasheetTabStats = async (bomId: string) => {
    return axios.get<DatasheetTabStatsResponse>(endPoints.datasheetTabStats(bomId));
};

export const updateDatasheetMetaData = async (data: updateDatasheetMetaDataRequest) => {
    const { bomId, notes, bodId } = data;
    return axios.patch<SuccessResponse>(`/bom/${bomId}/submittal_metadata/${bodId}/`, { notes });
};

export const reuseDatasheet = async (data: reuseDatasheetRequest) => {
    const { bomId, payload, documentType } = data;
    let metadataType = '',
        endPointType = '';
    if (documentType === 'datasheet') {
        metadataType = 'submittal';
        endPointType = 'datasheet';
    } else {
        metadataType = 'o_m';
        endPointType = 'document';
    }
    return axios.post(`/bom/${bomId}/${metadataType}_metadata/selected_${documentType}/bulk?source=selected_${endPointType}`, payload);
};

export const getDatsheetDetail = async (bomId: string, bodId: string) => {
    return axios.get<DatasheetDetailResponse>(endPoints.datasheetDetail(bomId, bodId));
};

export const getOmDetail = async (bomId: string, bodId: string) => {
    return axios.get(endPoints.omDetail(bomId, bodId));
};

//O_M Tab Related API
export const getOmData = async (bomId: string) => {
    return axios.get<OmDataResponse[]>(endPoints.omTabData(bomId));
};

export const getOmTabStats = async (bomId: string) => {
    return axios.get<OmTabStatsResponse>(endPoints.omTabStats(bomId));
};

export const getBomDetails = async (bomId: string) => {
    return axios.get<BomDetails>(endPoints.bomDetail(bomId));
};

export const datasheetAutoSearch = async (data: DatasheetAutoSearchRequest) => {
    const { bomId, input } = data;
    return axios.post<DatasheetAutoSearchResponse[]>(endPoints.datasheetAutoSearch(bomId), { ...input });
};

export const omAutoSearch = async (data: DatasheetAutoSearchRequest) => {
    const { bomId, input } = data;
    return axios.post<OmAutoSearchResponse>(endPoints.omAutoSearch(bomId), { ...input });
};

export const sortProductList = ({ sortOn, bomId }: ISortProductList) => {
    return axios.post<SortProductListResponse>(endPoints.sortProductList(bomId), sortOn);
};

export const createSection = async (data: SectionCreateRequest) => {
    const { bomId, input } = data;
    return axios.post<SectionApiResponseType>(endPoints.getSectionsUrl(bomId), { ...input });
};

export const updateSectionDetails = (data: SectionUpdateRequest) => {
    const { bomId, sectionId, input } = data;
    return axios.patch<SuccessResponse>(endPoints.getUpdateSectionUrl(bomId, sectionId), { ...input });
};

export const deleteSection = (data: SectionDeleteRequest) => {
    const { bomId, sectionId } = data;
    return axios.delete<SuccessResponse>(endPoints.getUpdateSectionUrl(bomId, sectionId));
};

export const moveToSection = (data: SectionMoveRequest) => {
    const { bomId, input } = data;
    return axios.patch<SuccessResponse>(endPoints.getMoveToSectionUrl(bomId), { ...input });
};

export const createKit = async (data: CreateKitRequest) => {
    const { bomId, input } = data;
    return axios.post<BodResponse>(endPoints.getKitsUrl(bomId), { ...input });
};
// option section

export const getOptionSection = (bomId: number) => {
    return axios.get<SectionApiResponseType[]>(endPoints.getOptionSectionsUrl(bomId));
};

export const createOptionSection = async (data: SectionCreateRequest) => {
    const { bomId, input } = data;
    return axios.post<SectionApiResponseType>(endPoints.getOptionSectionsUrl(bomId), { ...input });
};

export const updateOptionSectionDetails = (data: SectionUpdateRequest) => {
    const { bomId, sectionId, input } = data;
    return axios.patch<SuccessResponse>(endPoints.getOptionSectionsUpdateUrl(bomId, sectionId), { ...input });
};

export const deleteOptionSection = (data: SectionDeleteRequest) => {
    const { bomId, sectionId } = data;
    return axios.delete<SuccessResponse>(endPoints.getOptionSectionsUpdateUrl(bomId, sectionId));
};

export const postApproveOptionProduct = ({ bomId, data }: { bomId: number; data: ApproveProductRequest }) => {
    return axios.post<SuccessResponse>(endPoints.approveOptionsProductUrl(bomId), data);
};

export const getCustomisedColumnData = (userId: number) => {
    return getEvent({
        getQuery: `query{
              events_db {
              table_settings_by_pk(page_name: "bom", user_id: ${userId}) {
                order
                page_name
                user_id
                width
              }
            }
          }`,
        responseAttribute: 'events_db'
    });
};

export const toggleHighLightBomData = async (data: ToggleHighlightRequest) => {
    const { bomId, input } = data;
    return axios.patch<ToggleHighlightResponse>(endPoints.getHighlightUrl(bomId), { bod_id: input });
};

export const updateSectionOrder = ({ bomId, sectionType = '', ...rest }: UpdateSectionOrderTypes) => {
    const { previous_section_id, section_id, previous_substitute_section_id, substitute_section_id } = rest;
    let payload: Partial<UpdateSectionOrderTypes> = {};
    if (sectionType === SECTION_TYPE_FOR_PAYLOAD.MAIN) {
        payload = { previous_section_id, section_id };
    } else {
        payload = { substitute_section_id, previous_substitute_section_id };
    }
    return axios.patch<{ message: string }>(endPoints.sectionOrder(bomId, sectionType), payload);
};

export const addMultipleBods = (request: AddMultipleBodsRequest) => {
    const { bomId, newRowsPayload, ...rest } = request;
    let payload = { ...rest };
    if (newRowsPayload) {
        payload = { ...newRowsPayload } as any;
    }
    return axios.post<SuccessResponse>(endPoints.addMultipleBods(bomId), payload);
};
export const unlinkBod = (request: { bomId: string; projectId: number }) => {
    const { bomId } = request;
    return axios.patch<SuccessResponse>(endPoints.unlinkBom(bomId));
};
