import { BodySmall, BodyXS, Box, InfoOutlinedIcon, RadioGroup, Select, SelectChangeEvent, Skeleton, StatusSelect, TextField, Tooltip } from '@parspec/pixel';
import { formDataType } from '../../CreateProduct';
import { specificationOptions } from 'src/features/shared/utils/constants';

interface ProductFormProps {
    formData: formDataType;
    isLoading: boolean;
    handleChange: (event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLInputElement>) => void;
    patchBodDetails: (payload: formDataType) => void;
    optionsError: boolean;
    optionsList: Array<{ label: string; value: string }>;
    showOptions: boolean;
    qtyError: string;
}

const TOOLTIP_TEXT = 'Selecting this option will hide the original Basis of Design product and insert this product below it.';

const ProductForm: React.FC<ProductFormProps> = ({ formData, handleChange, patchBodDetails, isLoading, optionsError, optionsList, showOptions, qtyError }) => {
    const optionsArrList = showOptions
        ? [
              {
                  label: (
                      <Box display="flex">
                          <BodySmall pr={1}>Replace product of origin</BodySmall>
                          <Tooltip title={TOOLTIP_TEXT} placement="top">
                              <InfoOutlinedIcon />
                          </Tooltip>
                      </Box>
                  ),
                  value: 'hide_line_origin'
              },
              {
                  label: 'Add as Option',
                  value: 'add_as_option'
              }
          ]
        : [{ label: 'Replace product of origin', value: 'hide_line_origin' }];

    const renderFormTextField = ({ name, label, value, type }: { name: string; label: string; value: string | number; type?: string }) => {
        return (
            <Box mt={6} bgcolor={isLoading ? '' : 'primary.contrastText'}>
                {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={name === 'description' ? 77 : 36} sx={{ borderRadius: 1 }} />
                ) : (
                    <TextField
                        label={label}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        value={value}
                        multiline={name === 'description'}
                        rows={3}
                        maxRows={10}
                        name={name}
                        onBlur={(e) => patchBodDetails({ ...formData, [e.target.name]: e.target.value })}
                        type={type}
                    />
                )}
            </Box>
        );
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;

        if (value === 'hide_line_origin') {
            handleChange({ ...event, target: { ...event.target, name: name, checked: true, type } });
        } else {
            handleChange({ ...event, target: { ...event.target, name: name, checked: false, type } });
            if (optionsList && optionsList?.length === 1) {
                handleChange({ ...event, target: { ...event.target, name: 'substituteSectionId', value: String(optionsList[0].value) } });
            }
        }
    };

    function handleQtyChange(originalEvent: React.ChangeEvent<HTMLInputElement>) {
        const event = { ...originalEvent };
        const separateDecimals = event.target.value.split('.');
        if (separateDecimals && separateDecimals[1]?.length > 0) {
            event.target.value = separateDecimals[0];
        }
        handleChange(event);
    }

    function handleQtyBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
        if (qtyError) {
            return;
        }
        patchBodDetails({ ...formData, [e.target.name]: e.target.value });
    }

    return (
        <Box>
            {renderFormTextField({ name: 'type', label: 'Type', value: formData.type })}
            <Box mt={6} bgcolor={isLoading ? '' : 'primary.contrastText'}>
                {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                ) : (
                    <TextField
                        label="Quantity"
                        onChange={handleQtyChange}
                        value={formData.qty}
                        rows={3}
                        maxRows={10}
                        name="qty"
                        onBlur={handleQtyBlur}
                        type="number"
                        error={Boolean(qtyError)}
                        helperText={qtyError}
                    />
                )}
            </Box>
            {renderFormTextField({ name: 'manufacturer', label: 'Manufacturer', value: formData.manufacturer })}
            {renderFormTextField({ name: 'modelNumber', label: 'Model Number', value: formData.modelNumber })}
            {renderFormTextField({ name: 'description', label: 'Description', value: formData.description })}
            <Box mt={6} mb={4} width="172px" bgcolor="primary.contrastText">
                {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={41} sx={{ borderRadius: 1 }} />
                ) : (
                    <StatusSelect
                        label="Specification"
                        labelId="specification"
                        onChange={(e: SelectChangeEvent<unknown>) => handleChange(e)}
                        value={formData.specification}
                        name="specification"
                        options={specificationOptions}
                    />
                )}
            </Box>
            <Box>
                {isLoading ? (
                    <Skeleton width={190} height={32} />
                ) : (
                    <RadioGroup label="" name="toHideOrigin" options={optionsArrList} size="small" onChange={handleRadioChange} value={formData.toHideOrigin ? 'hide_line_origin' : 'add_as_option'} />
                )}
            </Box>
            {optionsList && optionsList?.length > 1 && !formData.toHideOrigin && (
                <Box>
                    <BodySmall>Select the Options section this product should be added to</BodySmall>
                    <Box width="200px" pt={4}>
                        <Select
                            label="Select Options section"
                            error={optionsError}
                            name="substituteSectionId"
                            options={optionsList || []}
                            value={formData.substituteSectionId}
                            onChange={(e: SelectChangeEvent<unknown>) => handleChange(e)}
                        />
                        {optionsError && <BodyXS color="error.main">Please select an option</BodyXS>}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ProductForm;
