import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, BodyMedium, BodySmall, Radio, InfoIcon, Link, Switch } from '@parspec/pixel';

import { useContentLayoutQuery, useContentLayoutUpdateMutation, useSectionBreakMutation } from '../../queries';
import { compileLabels, ICompileLabels } from '../../../constants';

interface ILayloutState {
    datasheet: string;
    installation_guide: string;
    drawing: string;
    warranty: string;
    other_document: string;
    section_breaks: boolean;
    show_kit_components: boolean;
    maintain_aspect_ratio: boolean;
}

enum RADIO_VALUES {
    REPEATED = 'REPEATED',
    UNIQUE = 'UNIQUE',
    EXCLUDE = 'EXCLUDE'
}

enum SECTION_BREAKS {
    INCLUDED = 'INCLUDE',
    EXCLUDED = 'EXCLUDE'
}

const RADIO_FIELDS = [
    {
        label: 'Datasheets',
        value: 'datasheet'
    },
    {
        label: 'Installation Guides',
        value: 'installation_guide'
    },
    {
        label: 'Drawings',
        value: 'drawing'
    },
    {
        label: 'Warranties',
        value: 'warranty'
    },
    {
        label: 'Other Documents',
        value: 'other_document'
    }
];

const TOGGLE_FIELD = {
    label: 'Add Section Breaks',
    value: 'section_breaks'
};

enum MAINTAIN_ASPECT_RATIO {
    label = 'Maintain Aspect Ratio',
    value = 'maintain_aspect_ratio'
}

const KIT_BREAKDOWN_FIELD = {
    label: 'Show Kit Components',
    value: 'show_kit_components'
};

const AccodionBody: React.FC = () => {
    const { bomId = '', documentType = '' } = useParams();

    const { data: contentLayoutData } = useContentLayoutQuery(bomId, documentType!);

    const { mutateAsync: mutateContentFormatting, isLoading: isMutationLoading } = useContentLayoutUpdateMutation();
    const { mutateAsync: mutateSectionBreak } = useSectionBreakMutation();

    const [layoutState, setLayoutState] = useState<ILayloutState>({
        datasheet: '',
        installation_guide: '',
        drawing: '',
        warranty: '',
        other_document: '',
        section_breaks: true,
        show_kit_components: true,
        maintain_aspect_ratio: true
    });

    useEffect(() => {
        if (contentLayoutData) {
            const updatedLayoutState = {
                ...contentLayoutData,
                section_breaks: contentLayoutData.section_breaks === SECTION_BREAKS.INCLUDED,
                show_kit_components: contentLayoutData.show_kit_components === SECTION_BREAKS.INCLUDED,
                maintain_aspect_ratio: contentLayoutData.maintain_aspect_ratio === SECTION_BREAKS.INCLUDED
            };
            setLayoutState(updatedLayoutState);
        }
    }, [contentLayoutData]);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setLayoutState({ ...layoutState, [name]: value });

        await mutateContentFormatting({
            bomId: bomId,
            documentType: documentType,
            fieldName: name,
            field_value: value
        });
    };

    const handleOnToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setLayoutState({ ...layoutState, [name]: checked });

        await mutateSectionBreak({
            bomId: bomId,
            documentType: documentType,
            fieldName: name,
            field_value: checked ? SECTION_BREAKS.INCLUDED : SECTION_BREAKS.EXCLUDED
        });
    };

    return (
        <Grid container direction={'column'} gap={3}>
            <Grid item ml={2}>
                <Grid container gap={2}>
                    <Grid item>
                        <BodyMedium fontWeight={500} color={'secondary.dark'}>
                            Document Ordering
                        </BodyMedium>
                    </Grid>

                    <Grid item>
                        <Link target="_blank" href="https://knowledgebase.parspec.io/knowledge/contents-formatting" underline="none" fontSize={'small'}>
                            Learn more
                        </Link>
                        <InfoIcon color="primary" sx={{ ml: 1 }} fontSize="small" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item ml={2}>
                <BodySmall color={'text.primary'} limit={false}>
                    Choose how to display your documents. <strong>Order by Product</strong> will group the document by its associated product. <strong>Order by Document</strong> will group the
                    document by its document type. If you select <strong>Order by Document</strong> and have the same document and annotations assigned to multiple products, the document will appear
                    once and reference its associated products in its page header.
                </BodySmall>
            </Grid>

            <Grid item mt={4} sx={isMutationLoading ? { pointerEvents: 'none', opacity: '0.4' } : null}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Grid container direction={'column'} rowGap={2}>
                            <Grid item>
                                <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                    Document
                                </BodySmall>
                            </Grid>

                            <Grid item>
                                <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                    Datasheets
                                </BodySmall>
                            </Grid>

                            <Grid item>
                                <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                    Installation Guides
                                </BodySmall>
                            </Grid>

                            <Grid item>
                                <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                    Drawings
                                </BodySmall>
                            </Grid>

                            <Grid item>
                                <BodySmall p={2} color={'secondary.main'}>
                                    Warranties
                                </BodySmall>
                            </Grid>

                            <Grid item>
                                <BodySmall mt={'2px'} p={2} color={'secondary.main'}>
                                    Other Documents
                                </BodySmall>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*   Order By Product */}
                    <Grid item>
                        <Grid container direction={'column'} rowGap={2}>
                            <Grid item>
                                <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                    Order by Product
                                </BodySmall>
                            </Grid>

                            {RADIO_FIELDS.map((option) => {
                                return (
                                    <Grid item alignSelf={'center'}>
                                        <Radio
                                            checked={layoutState[option.value as keyof ILayloutState] === RADIO_VALUES.REPEATED}
                                            size={'small'}
                                            value={RADIO_VALUES.REPEATED}
                                            name={option.value}
                                            inputProps={{ 'aria-label': option.value }}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    {/*  Order By Document */}
                    <Grid item>
                        <Grid container direction={'column'} rowGap={2}>
                            <Grid item>
                                <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                    Order by Document
                                </BodySmall>
                            </Grid>

                            {RADIO_FIELDS.map((option) => {
                                return (
                                    <Grid item alignSelf={'center'}>
                                        <Radio
                                            checked={layoutState[option.value as keyof ILayloutState] === RADIO_VALUES.UNIQUE}
                                            size={'small'}
                                            value={RADIO_VALUES.UNIQUE}
                                            name={option.value}
                                            inputProps={{ 'aria-label': option.value }}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    {/* Exclude */}
                    <Grid item ml={2}>
                        <Grid container direction={'column'} rowGap={2}>
                            <Grid item>
                                <BodySmall p={2} fontWeight={500} color={'secondary.dark'}>
                                    Exclude
                                </BodySmall>
                            </Grid>

                            {RADIO_FIELDS.map((option) => {
                                return (
                                    <Grid item alignSelf={'center'}>
                                        <Radio
                                            checked={layoutState[option.value as keyof ILayloutState] === RADIO_VALUES.EXCLUDE}
                                            size={'small'}
                                            value={RADIO_VALUES.EXCLUDE}
                                            name={option.value}
                                            inputProps={{ 'aria-label': option.value }}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} ml="10px">
                <Grid container display={'flex'} flexDirection={'column'} rowSpacing={3}>
                    <Grid item>
                        <BodyMedium fontWeight={500} color={'secondary.dark'}>
                            Page Fitting
                        </BodyMedium>
                    </Grid>
                    <Grid item>
                        <BodySmall color={'text.primary'} limit={false}>
                            ‘Maintaining aspect ratio’ ensures documents, especially technical drawings, are scaled proportionately to maintain their original aspect ratio. When deactivated, documents
                            automatically adjust to fit the page, maximizing their size, but potentially altering their scale.
                        </BodySmall>
                    </Grid>
                    <Grid item ml={1}>
                        <Switch name={MAINTAIN_ASPECT_RATIO.value} label={MAINTAIN_ASPECT_RATIO.label} checked={layoutState.maintain_aspect_ratio} onChange={handleOnToggle} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} ml={'10px'}>
                <Grid container display={'flex'} flexDirection={'column'} rowSpacing={3}>
                    <Grid item>
                        <BodyMedium fontWeight={500} color={'secondary.dark'}>
                            Section Breaks
                        </BodyMedium>
                    </Grid>
                    <Grid item>
                        <BodySmall color={'text.primary'} limit={false}>
                            Ideal for ease of document navigation, Section Breaks are added before a change in section, or a change of type of document. The design layout and fields for the section
                            breaks will mimic the ones selected for the Table of Contents.
                        </BodySmall>
                    </Grid>
                    <Grid item ml={1}>
                        <Switch name={TOGGLE_FIELD.value} label={TOGGLE_FIELD.label} checked={layoutState.section_breaks} onChange={handleOnToggle} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item mt={3} ml={'10px'}>
                <Grid container display={'flex'} flexDirection={'column'} rowSpacing={3}>
                    <Grid item>
                        <BodyMedium fontWeight={500} color={'secondary.dark'}>
                            Kit Component Breakdown
                        </BodyMedium>
                    </Grid>
                    <Grid item>
                        <BodySmall color={'text.primary'} limit={false}>
                            {`Determine whether or not to show kit components in this ${compileLabels[documentType as keyof ICompileLabels]}`}
                        </BodySmall>
                    </Grid>
                    <Grid item ml={1}>
                        <Switch name={KIT_BREAKDOWN_FIELD.value} label={KIT_BREAKDOWN_FIELD.label} checked={layoutState.show_kit_components} onChange={handleOnToggle} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AccodionBody;
