import { rest } from 'msw';
import { allCompiledSubmittals } from './submittals';
import { allCompiledQuotes } from './quotes';

export const finalDocumentHandlers = [
    rest.get('*/bom/:bomId/submittal/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(allCompiledSubmittals));
    }),

    rest.get('*/bom/:bomId/quote/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(allCompiledQuotes));
    }),

    rest.patch('*/bom/:bomId/submittal/:submittalId', (_req, res, ctx) => {
        return res(ctx.delay(100), ctx.json('Update Complete'));
    })
];
