import { ICellRendererParams } from 'ag-grid-community';

import { Box, LockIcon, LockOpenIcon, Tooltip } from '@parspec/pixel';
import { PERMISSION } from '../../constants';

const LOCK_DISABLED = 'All users can edit this list';
const LOCK_ENABLED = 'Only admins can edit this list';

export function PermissionsLock(params: ICellRendererParams) {
    const { data } = params;
    const permission = data.permission;
    let iconComponent = null;
    switch (permission) {
        case PERMISSION.ADMIN:
            iconComponent = (
                <Tooltip title={LOCK_ENABLED}>
                    <LockIcon color={'tertiary'}></LockIcon>
                </Tooltip>
            );
            break;
        case PERMISSION.USER:
            iconComponent = (
                <Tooltip title={LOCK_DISABLED}>
                    <LockOpenIcon color="neutral"></LockOpenIcon>
                </Tooltip>
            );
            break;
        default:
            break;
    }

    return (
        <Box width="100%" height="100%" display="flex" alignItems="center">
            {iconComponent}
        </Box>
    );
}
