import React, { useEffect, useState } from 'react';

import { Box, Modal, ModalFooter, ModalHeader, Snackbar, TextField } from '@parspec/pixel';

import { useEditUploadedFilesForProject } from '../queries';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { SnackbarMessages } from '../../BOM/FinalDocuments/constants';
interface IRenameModalProps {
    open: boolean;
    handleCloseRenameModal: () => void;
    handleUploadedFileName: (name: string) => void;
    uploadedFileDetails: { id: number; name: string };
}
const RenameModal = (props: IRenameModalProps) => {
    const { open, handleCloseRenameModal, uploadedFileDetails, handleUploadedFileName } = props;
    const { projectId } = useParams();
    const [fileName, setFileName] = useState<string>();
    const [requiredError, setRequiredError] = useState<string | null>(null);
    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();
    useEffect(() => {
        setFileName(uploadedFileDetails.name);
    }, [uploadedFileDetails.name]);

    const { mutateAsync, isLoading: isUploadedFileEditingLoading } = useEditUploadedFilesForProject();

    const handleConfirmClicked = async () => {
        if (!fileName?.trim()) {
            return;
        }
        setSnackbarOpen(SnackbarMessages.RENAME_SUCCESS);
        await mutateAsync({ projectId: Number(projectId), documentId: uploadedFileDetails.id, payload: { filename: fileName || '' } });
        handleCloseRenameModal();
        setSnackbarClose();
        handleUploadedFileName(fileName);
    };
    const header = <ModalHeader title="Rename" onClose={handleCloseRenameModal} />;
    const footer = <ModalFooter onReject={handleCloseRenameModal} onAccept={handleConfirmClicked} continueButtonLabel="Confirm" cancelButtonLabel="Cancel" isLoading={isUploadedFileEditingLoading} />;

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(e.target.value);
        setRequiredError(null);
    };

    const handleOnBlur = () => {
        if (!fileName?.trim().length) {
            setRequiredError('Required');
        }
    };
    return (
        <>
            {open && (
                <>
                    <Modal header={header} footer={footer} open={open}>
                        <Box width={480}>
                            <TextField
                                disabled={isUploadedFileEditingLoading}
                                label="File Name*"
                                onChange={handleFileNameChange}
                                value={fileName}
                                onBlur={handleOnBlur}
                                helperText={requiredError}
                                error={requiredError ? true : false}
                            />
                        </Box>
                    </Modal>
                    <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
                </>
            )}
        </>
    );
};

export default RenameModal;
