import { Grid, VisibilityOffOutlinedIcon, Box, BodySmall, H5 } from '@parspec/pixel';

import { useQuoteContext } from './QuoteContext';

import { QuoteEntity } from '../constants';

const accordionTitleMaping = new Map<string, string>([
    [QuoteEntity['HeaderAndFooter'], 'Header & Footer'],
    [QuoteEntity['CoverPage'], 'Cover Page'],
    [QuoteEntity['PricingLeadTime'], 'Pricing & Lead Time'],
    [QuoteEntity['GeneralTermsAndCondition'], 'General Terms & Conditions'],
    [QuoteEntity['ManufactureTermsAndCondition'], 'Manufacturer Terms & Conditions']
]);

const QuoteNotVisible: React.FC<{ isAlertBannerVisible: boolean }> = ({ isAlertBannerVisible }) => {
    const { accordionFieldName } = useQuoteContext();
    return (
        <Grid
            mt={'-2px'}
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            height={isAlertBannerVisible ? 'calc(100vh - 149px)' : 'calc(100vh - 117px)'}
            rowSpacing={2}
            sx={{ padding: '20px 80px 20px 85px' }}
        >
            <Grid item>
                <VisibilityOffOutlinedIcon
                    sx={{
                        fontSize: 112
                    }}
                />
            </Grid>

            <Grid item>
                <Grid container width={400} direction={'column'} alignItems={'center'} gap={1}>
                    <Grid item>
                        <H5 sx={{ fontWeight: 700 }} color="secondary.main">
                            No Preview Available
                        </H5>
                    </Grid>
                    <Grid item>
                        <Box width={'100%'} textAlign={'center'}>
                            <BodySmall lines={3} color={'secondary.main'}>
                                The {accordionTitleMaping.get(accordionFieldName)?.toLowerCase()} is currently hidden. Click the switch next to the {accordionTitleMaping.get(accordionFieldName)}{' '}
                                section to reveal it.
                            </BodySmall>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default QuoteNotVisible;
