import { CellStyle, ColDef } from 'ag-grid-community';

export const getTemplateCellStyle = (params: any): CellStyle => {
    if (params.node.rowPinned === 'bottom') {
        return { border: 'none' };
    }
    return { backgroundColor: 'unset' };
};

export const getRowId = (params: any) => {
    return params?.data?.user_details.id;
};

export const defaultColDef: ColDef | any = {
    flex: 1,
    sortable: true,
    unSortIcon: true,
    menuTabs: [],
    resizable: true,
    suppressFillHandle: true,
    suppressMovable: true,
    filter: false,
    suppressMenu: true
};

export const getCellStyle = () => {
    return { border: 'none' };
};
