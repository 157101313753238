import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BodySmall, BodyXS, Box, FileSelector, FileSelectorFileType, Modal, ModalFooter, ModalHeader, RadioGroup } from '@parspec/pixel';

import { environment } from 'src/environments';
import { selectPdfMsg } from '../../shared/constants';
import { useAddSuggestionMutation } from '../../shared/DocumentSuggestions/queries';
import { useUploadDifferentFile } from '../ImportMoreProducts/queries';
import { BodResponse } from '../queries/apiTypes';
import { ADD_REUSE_RADIO_OPTIONS, ADD_REUSE_OPTION_VALUES } from './helper';
import { saveDataToSessionStorage } from '../../shared/utils/utils';

interface IFileType {
    file: File | FileSelectorFileType;
    s3_file_path?: string;
}

interface SelectedItemsProps extends BodResponse {
    selected_datasheet_id?: number | null;
}

interface AddReuseOptionDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    selectedItems: SelectedItemsProps[];
    onReuseDatasheetDialog: () => void;
    onSuccess: () => void;
}

const AddReuseOptionDialog: FC<AddReuseOptionDialogProps> = ({ open, onCloseDialog, onReuseDatasheetDialog, selectedItems, onSuccess }) => {
    const navigate = useNavigate();
    const [selectedOption, setSelectionOption] = useState(ADD_REUSE_OPTION_VALUES.REUSE);
    const [error, setError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState<IFileType[] | File[]>();
    const [uploadedFilePath, setUploadedFilePath] = useState<string>();
    const { bomId, projectId } = useParams();

    const { isLoading: newDocumentUploadLoading, mutateAsync: uploadNewDocument } = useAddSuggestionMutation();
    const { isLoading: submittalUploadLoading, mutateAsync: uploadExistingSubmittal } = useUploadDifferentFile();

    const handleSelectOption = (_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const enumValue = value as ADD_REUSE_OPTION_VALUES;

        setSelectionOption(enumValue);
        setError('');
        setSelectedFiles([]);
        setUploadedFilePath('');
    };

    const handleFileUploadedToS3 = (files: IFileType[]) => {
        if (files.length) {
            setSelectedFiles(files);
            setUploadedFilePath(files[0].s3_file_path);
            setError('');
            return;
        }
        setError('');
        setUploadedFilePath('');
    };

    const handleSelectNewDocument = (files: File[] | FileSelectorFileType[]) => {
        setError('');
        setSelectedFiles(files as File[]);
    };

    const handleAddNewDocument = async () => {
        const formData = new FormData();
        const selectedIds = selectedItems.map((item) => item.id);
        if (selectedFiles?.length) {
            formData.append('input', selectedFiles?.[0] as File);
            formData.append('bod_id', JSON.stringify(selectedIds));
        } else {
            return setError(selectPdfMsg);
        }
        try {
            const payload = {
                bomId: bomId!,
                actionType: 'file_upload'
            };
            await uploadNewDocument({
                ...payload,
                documentType: 'submittal',
                subDocumentType: 'datasheet',
                productId: selectedIds,
                input: formData
            });
            onSuccess();
        } catch (error: any) {
            setError(error?.response?.data?.message || 'Something went wrong!');
        }
    };

    const handleUploadExistingSubmittal = async () => {
        const formData = new FormData();
        const selectedIds = selectedItems.map((item) => item.id);
        if (!selectedFiles?.length) {
            setError('A file is required for the option selected.');
            return;
        }
        formData.append('file_info', 'media/temp_files/' + uploadedFilePath!);
        try {
            const res = await uploadExistingSubmittal({
                packageType: 'submittal_package',
                bom_id: Number(bomId),
                data: formData
            });
            saveDataToSessionStorage('selectedBodIds', JSON.stringify(selectedIds));
            navigate(`/v2/project/${projectId}/bom/${bomId}/adjustPages?showPopup=${true}&submittalPackageId=${res?.data?.id}&from=bom-detail`);
        } catch (error: any) {
            setError(error?.response?.data?.message || 'Something went wrong!');
        }
    };

    const handleSubmit = async () => {
        if (selectedOption === ADD_REUSE_OPTION_VALUES.REUSE) {
            onReuseDatasheetDialog();
            onCloseDialog();
            return;
        }

        if (selectedOption === ADD_REUSE_OPTION_VALUES.NEW_DOCUMENT) {
            return handleAddNewDocument();
        }
        if (selectedOption === ADD_REUSE_OPTION_VALUES.EXISTING_SUBMITTAL) {
            return handleUploadExistingSubmittal();
        }
    };

    const showWarning = selectedItems.some((item) => item.selected_datasheet_id);

    return (
        <Modal
            open={open}
            header={
                <ModalHeader title={`Add or Reuse Document`} onClose={onCloseDialog}>
                    <BodySmall color="text.secondary">Select the document type to add, and how you’d like to add it.</BodySmall>
                </ModalHeader>
            }
            footer={<ModalFooter onAccept={handleSubmit} onReject={onCloseDialog} isLoading={newDocumentUploadLoading || submittalUploadLoading} continueButtonLabel="Confirm" />}
        >
            <Box width={500} mt={4} position="relative">
                {(newDocumentUploadLoading || submittalUploadLoading) && (
                    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200} />
                )}
                <RadioGroup name="add-reuse-data-sheet" label={`How are you adding this document?`} options={ADD_REUSE_RADIO_OPTIONS} value={selectedOption} onChange={handleSelectOption} />
                {selectedOption === ADD_REUSE_OPTION_VALUES.NEW_DOCUMENT && (
                    <FileSelector
                        onSelect={handleSelectNewDocument}
                        acceptedFormats={['.pdf']}
                        helperText="Supported file types: PDF"
                        error={error || ''}
                        placeholder="Drag and drop a file here, or:"
                    />
                )}
                {selectedOption === ADD_REUSE_OPTION_VALUES.EXISTING_SUBMITTAL && (
                    <FileSelector
                        placeholder="Drag and drop a file here, or:"
                        url={`${environment.b}/api/generate_signed_url/`}
                        onUpload={handleFileUploadedToS3}
                        onSelect={() => setError('')}
                        acceptedFormats={['.pdf']}
                        helperText={'Supported file types: PDF'}
                        error={error}
                    />
                )}
                {showWarning && (
                    <Box mt={4} mb={2}>
                        <BodyXS
                            fontWeight="bold"
                            sx={{
                                color: 'warning.main'
                            }}
                        >
                            One or more products from your initial selection have an associated document.
                        </BodyXS>
                        <BodyXS
                            sx={{
                                color: 'warning.main'
                            }}
                        >
                            These existing documents and annotations will be overwritten.
                        </BodyXS>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default AddReuseOptionDialog;
