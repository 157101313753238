import { ContactInfo } from 'src/features/Dashboard/queries/apiTypes';
import { DocType } from '../../../constants';

export function createLogoImagePreview(data: File[], leftImageId: string, rightImageId: string) {
    const imagePreviewLeft: HTMLImageElement | null = document.querySelector(leftImageId);
    const imagePreviewRight: HTMLImageElement | null = document.querySelector(rightImageId);
    let imageSrc = '';
    if (typeof data === 'string') {
        imageSrc = data;
    } else {
        imageSrc = URL.createObjectURL(data[0]);
    }

    if (imagePreviewLeft) {
        imagePreviewLeft.src = imageSrc;
    }

    if (imagePreviewRight) {
        imagePreviewRight.src = imageSrc;
    }
}

export const optionsForNumber = [
    {
        id: 'Unique ID',
        name: 'Unique ID'
    },
    {
        id: 'Initials - Date - Unique ID',
        name: 'Initials - Date - Unique ID'
    },
    {
        id: 'Custom Entry',
        name: 'Custom Entry'
    }
];

export const optionsForName = [
    {
        id: 'Project Name',
        name: 'Project Name'
    },
    {
        id: 'BOM Name',
        name: 'BOM Name'
    },
    {
        id: 'Custom Entry',
        name: 'Custom Entry'
    }
];

export const optionsForNameWithoutCustomEntry = [
    {
        id: 'Project Name',
        name: 'Project Name'
    },
    {
        id: 'BOM Name',
        name: 'BOM Name'
    }
];

export const getCurrentTimezoneName = () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return timeZone;
};

export const optionsForLogo = [
    { id: 'primary', name: 'Primary Logo' },
    { id: 'secondary', name: 'Secondary Logo' },
    { id: 'project', name: 'Project Logo' }
];

export const getHeaderTitleforCompilePage = (documentType: DocType) => {
    if (documentType === DocType.QUOTE) {
        return `Customize document(s) by choosing a design layout and the fields you need. When complete, click ‘Next’ to select the BOMs you want to create final documents for.`;
    }

    return `Customize this document by choosing a design layout and the fields you need.`;
};

interface INotes {
    bomId: string;
    notes: string;
}

export const getOrderedNotes = (notes: Record<string, string> | null, pdfUrls: number[]) => {
    const result: INotes[] = [];
    if (notes && pdfUrls.length) {
        const pdfUrlsIndexedObj: Record<string, number> = pdfUrls.reduce((acc, curr, index) => {
            return { ...acc, [curr]: index };
        }, {});

        for (const key in notes) {
            if (key in pdfUrlsIndexedObj) {
                result[pdfUrlsIndexedObj[key]] = { bomId: key, notes: notes[key] };
            }
        }
    }
    return result.length > 1 ? result.filter((el) => el !== null && el !== undefined) : result;
};

export const getAddressToPrimaryPrefillObj = (primaryContact: ContactInfo) => {
    const nameArray = primaryContact.contact?.name?.split(' ');
    return {
        company_name: primaryContact.company?.name || '',
        first_name: nameArray?.[0] || '',
        last_name: nameArray?.[1] || '',
        email: primaryContact.contact?.email || primaryContact.company?.email || '',
        phone: primaryContact.contact?.phone || primaryContact.contact?.phone || '',
        address1: primaryContact.company?.line1 || '',
        address2: primaryContact.company?.line2 || '',
        city: primaryContact.company?.city || '',
        state: primaryContact.company?.state || '',
        zip_code: primaryContact.company?.zip || '',
        country: primaryContact.company?.country || ''
    };
};
