import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Header from './Header';
import PdfViewer from './PdfViewer';
import ExcelViewer from './ExcelViewer';
import XmlViewer from './XmlViewer';
import { useGetContentDetailQuery } from '../queries';
import { SubmittalBomType } from '../../shared/constants';
const DocumentFileType = {
    pdfFiletype: '.pdf',
    xlsxFiletype: '.xlsx',
    xlsFiletype: '.xls',
    csvFiletype: '.csv',
    xlsmFiletype: '.xlsm'
};
const DocumentParser = () => {
    const { bomId } = useParams();
    const { data, isFetching, isLoading } = useGetContentDetailQuery(Number(bomId));
    const [confirmSelection, setConfirmSelection] = useState<boolean>(false);
    const [boundInfo, setBoundInfo] = useState<string>('');
    const [changeFile, setchangeFile] = useState<boolean>(false);
    const [excelViewerKey, setExcelViewerKey] = useState<number>(0);
    const isPdf = data?.data?.type === SubmittalBomType || data?.data?.filetype === DocumentFileType.pdfFiletype;
    const isExcel =
        data?.data?.filetype === DocumentFileType.xlsxFiletype ||
        data?.data?.filetype === DocumentFileType.xlsFiletype ||
        data?.data?.filetype === DocumentFileType.csvFiletype ||
        data?.data?.filetype === DocumentFileType.xlsmFiletype;
    useEffect(() => {
        if (isFetching) return;
        setExcelViewerKey((state) => state + 1);
    }, [isFetching]);
    return (
        <>
            <Header
                confirmSelection={confirmSelection}
                boundInfo={boundInfo}
                type={data?.data?.type}
                changeFile={changeFile}
                handleChangeFile={setchangeFile}
                setConfirmSelection={setConfirmSelection}
            />
            {isPdf ? (
                <PdfViewer setConfirmSelection={setConfirmSelection} setBoundInfo={setBoundInfo} type={data?.data?.type} handleChangeFile={setchangeFile} />
            ) : isExcel && Boolean(excelViewerKey) ? (
                <ExcelViewer key={excelViewerKey} setConfirmSelection={setConfirmSelection} setBoundInfo={setBoundInfo} handleChangeFile={setchangeFile} />
            ) : !isLoading && !isExcel ? (
                <XmlViewer setConfirmSelection={setConfirmSelection} handleChangeFile={setchangeFile} />
            ) : null}
        </>
    );
};

export default DocumentParser;
