import { OutofSyncResponse } from '../../Dashboard/queries/apiTypes';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getDate = (date: string) => {
    const date_data = new Date(date);
    const d = date_data.getDate();
    const m = date_data.getMonth();
    const y = date_data.getFullYear();
    let th = date_data.getHours();
    const tm = date_data.getMinutes();
    const morning = th < 12;
    if (th > 12) {
        th = th - 12;
    }
    return `${months[m]} ${d}, ${y} at ${th < 10 ? `0${th}` : th}:${tm < 10 ? `0${tm}` : tm} ${morning ? 'am' : 'pm'}`;
};

export function getCustomerTemplate(customerName?: string) {
    return customerName || '-';
}

interface SyncInfo {
    outOfSync: boolean;
    date: string;
}
export function getSyncCacheForProject(data: OutofSyncResponse) {
    if (!Array.isArray(data)) return null;
    const result = data.reduce((acc, val) => {
        acc[val.id] = {
            outOfSync: val.out_of_sync,
            date: val.updated_at
        };
        return acc;
    }, {} as Record<number, SyncInfo>);

    return result;
}

export enum PRIORITY_VALUES {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low'
}

export const getLastModifiedValue = (params: any) => {
    const { data } = params;

    const updatedAt = data?.last_edited_at;
    const updatedBy = data?.last_edited_by;

    const dateTime = getDate(updatedAt);

    return `${updatedBy} - ${dateTime}`;
};
