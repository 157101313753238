export interface ISnackbarDataState {
    isActive: boolean;
    message: string;
}

export const initialSignInErrorState = {
    email: { isError: false, title: '' },
    password: { isError: false, title: '' }
};

export const initialSingInState = {
    email: '',
    password: ''
};

export const initialInvitedUserSignupErrorState = {
    firstName: { isError: false, title: '' },
    lastName: { isError: false, title: '' },
    newPassword: { isError: false, title: '' },
    confirmPassword: { isError: false, title: '' }
};

export const initialInvitedUserSingUpState = {
    firstName: '',
    lastName: '',
    newPassword: { showPassword: false, data: '' },
    confirmPassword: { showPassword: false, data: '' }
};

export const initialResetPasswordErrorState = {
    newPassword: { isError: false, title: '' },
    confirmPassword: { isError: false, title: '' }
};

export const initialResetPasswordState = {
    newPassword: {
        showPassword: false,
        data: ''
    },
    confirmPassword: {
        showPassword: false,
        data: ''
    }
};

export const initialBranchDetailsState = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zipcode: ''
};

export const initialBranchDetailsErrorState = {
    name: { isError: false, title: '' },
    address1: { isError: false, title: '' },
    address2: { isError: false, title: '' },
    city: { isError: false, title: '' },
    country: { isError: false, title: '' },
    state: { isError: false, title: '' },
    zipcode: { isError: false, title: '' }
};

export const initialPersonalInfoSignupErrorState = {
    firstName: { isError: false, title: '' },
    lastName: { isError: false, title: '' },
    newPassword: { isError: false, title: '' },
    confirmPassword: { isError: false, title: '' }
};

export const initialPersonalInfoSingUpState = {
    firstName: '',
    lastName: '',
    newPassword: { showPassword: false, data: '' },
    confirmPassword: { showPassword: false, data: '' }
};
