export const sortedProductList  = [
    {
        id: 985718,
        created_at: '2023-08-04T05:37:03.600546Z',
        updated_at: '2023-08-04T05:37:03.600577Z',
        is_deleted: false,
        is_hidden: false,
        order: null,
        category: 'BLANK',
        qty: null,
        manufacturer: 'EVERLIGHT',
        model_number: 'IR26-61C-L746-R-TR8',
        description: '',
        bom: 44141,
        submittal_metadata: 985721,
        o_m_metadata: 985721
    },
    {
        id: 985729,
        created_at: '2023-08-04T06:56:48.228869Z',
        updated_at: '2023-08-04T06:56:48.228921Z',
        is_deleted: false,
        is_hidden: false,
        order: 0.5,
        category: '',
        qty: null,
        manufacturer: 'NLIGHT',
        model_number: 'NPS 80 .',
        description: '',
        bom: 44141,
        submittal_metadata: 985732,
        o_m_metadata: 985732
    }
];
