import { BodySmall, Box, InfoOutlinedIcon, Tooltip } from '@parspec/pixel';

interface SubTotalProps {
    title: string;
}

export function SubtotalsNotApplicable({ title }: SubTotalProps) {
    return (
        <Box display="flex" alignItems="center" height="100%" width="100%">
            <BodySmall fontWeight="700" mr={1}>
                N/A
            </BodySmall>
            <Tooltip title={title}>
                <InfoOutlinedIcon fontSize="medium" color="secondary" />
            </Tooltip>
        </Box>
    );
}
