import { useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { endPoints, getContacts, manageBom } from './apis';
import { invalidateBomInfoInDashboard, invalidateProjectInfoInDashboard } from '../../queries';
import { refetchProjectBomsQuery, refetchProjectDetails } from '../../../Project/queries';
import { invalidateGetAllCompanyDetailsKey } from '../../../shared/CreateAndEditProjectModal/queries';
import { queryClient } from 'src/app/queryClient';
import { IContact, ManageBomListRequestPayload, contactType } from './apiTypes';
import { pushEvent } from '../../../shared/UserActivityTracking/EventService';
import { DocumentType } from '../../../BOM/FinalDocuments/constants';
import { refetchDocuments, refetchOutOfSyncQuery } from '../../../BOM/FinalDocuments/queries';
import {
    invalidateBomDataQuery,
    invalidateBomStatsQuery,
    invalidateDatasheetDataQuery,
    invalidateDatasheetStatsQuery,
    invalidateGetOptionSectionQuery,
    invalidateGetSectionsQuery,
    invalidateOmDataQuery,
    invalidateOmStatsQuery
} from '../../../BOM/queries';
import { invalidatePricingBodQuery } from '../../../BOM/pricing&LeadTime/queries';
import { invalidaLotQueries } from '../../../BOM/pricing&LeadTime/lotPriceModal/queries';
import { invalidateServiceQueries } from '../../../BOM/pricing&LeadTime/servicesModal/queries';
import { invalidaTaxesPricingQuery } from '../../../BOM/pricing&LeadTime/taxesModal/queries';
import { invalidaFreightQuery } from '../../../BOM/pricing&LeadTime/freightModal/queries';

const getBomDetailsDataKey = (bomId: number) => [endPoints.bomDetails(bomId)];
const getContactsKey = (companyId?: number) => [endPoints.contacts(companyId)];

export const useManageBOMMutation = (isFinalDocTab: boolean) => {
    return useMutation(manageBom, {
        onSuccess: async (response, request) => {
            const projectId = response.data.project_id;
            //If noprojectId that means new Project is created from Create BOM
            invalidateProjectInfoInDashboard();
            refetchProjectDetails(projectId);
            invalidateGetAllCompanyDetailsKey();
            invalidateBomInfoInDashboard();
            await queryClient.invalidateQueries(getContactsKey(request.company_id));
            request.bom_list.forEach((item: ManageBomListRequestPayload) => {
                if (item.id) {
                    invalidateGetBomDetailsDataKey(item.id);
                    const bomId = item.id.toString();
                    if (isFinalDocTab) {
                        refetchOutOfSyncQuery(item.id.toString(), DocumentType.SUBMITTAL);
                        refetchOutOfSyncQuery(item.id.toString(), DocumentType.O_M);
                        refetchOutOfSyncQuery(item.id.toString(), DocumentType.QUOTE);
                        refetchDocuments(item.id.toString(), DocumentType.QUOTE);
                    }

                    invalidateGetOptionSectionQuery(Number(bomId));
                    invalidateGetSectionsQuery(Number(bomId));
                    invalidateBomDataQuery(Number(bomId));
                    invalidateBomStatsQuery(bomId);
                    invalidateDatasheetStatsQuery(bomId);
                    invalidateOmStatsQuery(bomId);
                    invalidatePricingBodQuery(Number(bomId));
                    invalidaLotQueries(bomId);
                    invalidateDatasheetDataQuery(bomId);
                    invalidateOmDataQuery(bomId);
                    invalidateServiceQueries(Number(bomId));
                    invalidaTaxesPricingQuery(Number(bomId));
                    invalidaFreightQuery(bomId);
                }
            });
            refetchProjectBomsQuery(projectId);
        }
    });
};

export const invalidateGetBomDetailsDataKey = (bomId: number) => {
    queryClient.invalidateQueries(getBomDetailsDataKey(bomId));
};

export const useContactsQuery = (companyId?: number, options?: object) =>
    useQuery({
        queryKey: getContactsKey(companyId),
        queryFn: () => getContacts(companyId),
        select: (data: AxiosResponse) => {
            const contacts: Record<contactType, IContact[]> = {
                primary_contacts: [],
                secondary_contacts: []
            };

            let primaryId = 0,
                secondaryId = 0;

            if (data?.data) {
                contacts.primary_contacts = data.data.primary_contacts.map((item: string) => {
                    return { id: ++primaryId, name: item };
                });

                contacts.secondary_contacts = data.data.secondary_contacts.map((item: string) => {
                    return { id: ++secondaryId, name: item };
                });
            }

            return contacts;
        },
        ...options
    });

export const createBomPushEvents = async (location: number, createBOMStart: number, project: string, userId: number | undefined) => {
    const insertMutation = `
    mutation {
        events_db{
        insert_user_activity_duration( objects: { activity_type: "CreateBOM", bom_id:  1, company_id:  ${location}, duration_ms:  ${
        Date.now() - createBOMStart
    }, project_id:  ${project}, o_m_version: 1, quote_version: 1, submittal_version: 1}) {
        affected_rows
        }
    }
    }`;
    const updateMutation = `
    mutation {
        events_db{
        update_user_activity_duration(_inc: {duration_ms: ${
            Date.now() - createBOMStart
        }}, where: {user_id: {_eq: ${userId}}, activity_type: {_eq: "CreateBOM"}, project_id: {_eq: ${project}}, quote_version: {_eq: 1}, submittal_version: {_eq: 1}, o_m_version: {_eq: 1}}) {
            affected_rows
        }}
    }
`;
    pushEvent({ insertMutation, updateMutation, responseAttribute: 'user_activity_duration' });
};
