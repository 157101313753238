import { useLocation } from 'react-router-dom';

import { Box, CircularProgress } from '@parspec/pixel';

import BomHeader from './BomHeader';
import DatasheetTab from './DatasheetTab';
import BillOfMaterials from './BillOfMaterials';
import OMTab from './O&MTab';
import DocumentCards from './FinalDocuments';
import { TAB_VALUES } from './shared/constants';
import { PricingAndLeadTimeTab } from './pricing&LeadTime';
import { TrackActivityDurationWrapper } from '../shared/UserActivityTracking/TrackActivityDuration';
import { CustomerHeaderTabs } from './shared/CustomerHeaderTabs';
import { useSubscriptionInfo } from '../Settings/PlanAndPayment/queries';

const Bom = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tabValue = params?.get('tab');

    const { isFetching } = useSubscriptionInfo();

    const getTabContent = () => {
        const tabContent = {
            [TAB_VALUES.BOM_TAB_VALUE]: (
                <TrackActivityDurationWrapper key={'BomDetailPageBOMTab'} activityType={'BomDetailPageBOMTab'}>
                    <BillOfMaterials />
                </TrackActivityDurationWrapper>
            ),
            [TAB_VALUES.DATASHEET_TAB_VALUE]: (
                <TrackActivityDurationWrapper key={'BomDetailPageDatasheetTab'} activityType={'BomDetailPageDatasheetTab'}>
                    <DatasheetTab />
                </TrackActivityDurationWrapper>
            ),
            [TAB_VALUES.OM_DOCUMENTS_TAB_VALUE]: (
                <TrackActivityDurationWrapper key={'BomDetailPageAdditionalDocsTab'} activityType={'BomDetailPageAdditionalDocsTab'}>
                    <OMTab />
                </TrackActivityDurationWrapper>
            ),
            [TAB_VALUES.FINAL_DOCUMENTS_TAB_VALUE]: <DocumentCards />,
            [TAB_VALUES.PRICING_AND_LEAD_TIME]: (
                <TrackActivityDurationWrapper key={'BomDetailPagePricing&LeadTimeTab'} activityType={'BomDetailPagePricing&LeadTimeTab'}>
                    <PricingAndLeadTimeTab />
                </TrackActivityDurationWrapper>
            )
        };
        if (tabValue && tabContent[tabValue]) {
            return tabContent[tabValue];
        }
        return (
            <TrackActivityDurationWrapper key={'BomDetailPageBOMTab'} activityType={'BomDetailPageBOMTab'}>
                <BillOfMaterials />
            </TrackActivityDurationWrapper>
        );
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh" minWidth="1100px">
            {isFetching ? (
                <Box display="flex" alignItems="center" justifyContent="center" height={'100vh'}>
                    <CircularProgress color="primary" size="xxl" />
                </Box>
            ) : (
                <>
                    <BomHeader tabValue={tabValue} />
                    <CustomerHeaderTabs />
                    {getTabContent()}
                </>
            )}
        </Box>
    );
};

export default Bom;
