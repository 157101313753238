import { axios } from '../../../../app/Axios';
import { ConfirmPackageRequest, ConfirmScheduleResponse, GetXmlDetailType, SelectPackageRequest, SelectScheduleResponse, XmlAnnotationType } from './apiTypes';
export const endPoints = {
    //Same O&M
    xmlAnnotation: (submittalId: number, projectScheduleId: number) => `bom/${submittalId}/uploaded_doc/schedule/${projectScheduleId}/select_bod`,
    //Same O&M
    contentDetail: (submittalId: number) => `bom/${submittalId}/uploaded_doc/?latest=true`,
    //Same O&M
    xmlUrl: (bomId: number, url: string) => `bom/${bomId}/submittal/url_to_xml?url=${url}`,
    // Same O&M
    xmlDetails: (submittalId: number, projectScheduleId: number) => `bom/${submittalId}/uploaded_doc/schedule/${projectScheduleId}/select_bod`,
    // Same O&M
    //Not able to confirm
    submittalPackagetoc: (submittalId: number, projectScheduleId: number) => `bom/${submittalId}/uploaded_doc/submittal_package/${projectScheduleId}/select_bod`,
    submittalPackagexml: (submittalId: number, projectScheduleId: number) => `bom/${submittalId}/uploaded_doc/submittal_package/${projectScheduleId}/select_bod`
};
export const sendXmlAnnotation = (data: XmlAnnotationType) => {
    return axios.post<ConfirmScheduleResponse>(endPoints.xmlAnnotation(data.submittalId, data.projectScheduleId), data.data);
};
export const getcontentDetail = (submittalId: number) => {
    return axios.get<SelectScheduleResponse & SelectPackageRequest>(endPoints.contentDetail(submittalId));
};

export const getXmlurl = (bomId: number, url: string) => {
    return axios.get(endPoints.xmlUrl(bomId, url));
};

export const getXmlDetail = (data: GetXmlDetailType) => {
    return axios.patch(endPoints.xmlDetails(data.submittalId, data.projectScheduleId), data.data);
};
export const getSubmittalPackagetoc = (data: XmlAnnotationType) => {
    return axios.post<ConfirmPackageRequest>(endPoints.submittalPackagetoc(data.submittalId, data.projectScheduleId), data.data);
};

export const getSubmittalPackageXmlDetail = (data: GetXmlDetailType) => {
    return axios.patch(endPoints.submittalPackagexml(data.submittalId, data.projectScheduleId), data.data);
};
