import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import { BodySmall, BodyXS, Box, Button, Info, Snackbar, TableHeaderMenuIcon, ToolbarType, Tooltip } from '@parspec/pixel';

import { ICellRendererParams } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';

import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import BulkImport from '../../shared/BulkImport';
import {
    companyDeleteBody,
    companyTemplate,
    dateComparator,
    emailTemplate,
    getLastModifiedFilterText,
    getLocationsFilterText,
    menuOptions,
    removeCompaniesDuplicateRecords,
    renderCompanyDeleteTitle,
    renderLastModifiedDate,
    websiteTemplate
} from '../../shared/utils';

import { DELETE_COMPANY_TITLE, defaultColDef } from '../../shared/constant';
import { useDeleteCompanyMutation, useGetCompanyQuery } from '../queries';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import CreateCompany from '../CreateCompany';
import { deleteMsg } from '../../../shared/constants';
import { endpoints } from '../queries/api';
import { loadingOverlayComponent, NoRowsOverlayComponent } from '../../../BOM/shared/commonTemplates';
import { CompanyObjType, ImportFileResponse } from '../queries/apiTypes';

import { useGetUserProfileInfoQuery } from '../../../Settings/MyProfile/queries';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';

const CompanyTable = () => {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<CompanyObjType[]>();
    const [selectedCompanies, setSelectedCompanies] = useState<CompanyObjType[]>([]);
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [searchText, setSearchText] = useState('');
    const [tableHeight, setTableHeight] = useState<number>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [apiUrl, setApiUrl] = useState(endpoints.postCompanyDetails);

    const tableContainerRef = useRef<any>(null);
    const gridRef = useRef<any>(null);

    const { data: companyData, isFetching } = useGetCompanyQuery(apiUrl);
    const { mutateAsync: deleteCompany, isLoading } = useDeleteCompanyMutation();
    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const loadingFlag = isFetching || isAdminLoading;
    const isAdmin = userProfile?.data?.role === 'admin';

    useEffect(() => {
        if (companyData?.data && !loadingFlag) {
            const { next, results } = companyData.data;
            if (next) {
                setApiUrl(next);
            }
            if (rowData && rowData.length > 0) {
                const arr = removeCompaniesDuplicateRecords([...rowData, ...results]);
                setRowData(arr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
            } else {
                setRowData(results);
            }
        }
    }, [loadingFlag, apiUrl]);

    useEffect(() => {
        if (tableContainerRef?.current?.offsetHeight) {
            setTableHeight(tableContainerRef?.current?.offsetHeight);
        }
    }, [tableContainerRef]);

    //this useEffect handles the loader in table
    useEffect(() => {
        if (!rowData && loadingFlag) {
            gridRef?.current?.api?.showLoadingOverlay();
        } else if (rowData && rowData?.length === 0) {
            setTimeout(() => {
                gridRef?.current?.api?.showNoRowsOverlay();
            }, 0);
        } else {
            gridRef?.current?.api?.hideOverlay();
        }
    }, [rowData]);

    useEffect(() => {
        return () => {
            setRowData(undefined);
        };
    }, []);

    const toggleOpenCreateModal = () => {
        setOpenCreateModal((openCreateModal) => !openCreateModal);
    };

    // to open the create popup if user comes by clicking Add New Company
    useEffect(() => {
        if (window?.openCompanyPopup) {
            toggleOpenCreateModal();
            delete window.openCompanyPopup;
        }
    }, []);

    const onClickDelete = (props: ICellRendererParams) => {
        setDeleteIds([props.data.id]);
        setOpenDeleteDialog(true);
    };

    const onClickEdit = (props: ICellRendererParams) => {
        const { id } = props.data;
        navigate(`/v2/company/${id}`);
    };

    const onClickMultipleDelete = () => {
        const ids = selectedCompanies.map((item: CompanyObjType) => item.id);
        setDeleteIds(ids);
        setOpenDeleteDialog(true);
    };

    const handleDelete = async () => {
        await deleteCompany(deleteIds);
        setRowData((old) => old?.filter((item) => !deleteIds.includes(item.id)));
        setDeleteIds([]);
        setOpenDeleteDialog(false);
        setSnackbarOpen(deleteMsg);
        handleClearSelection();
    };

    const closeDeleteDialog = () => setOpenDeleteDialog(false);

    const handleClearSelection = () => {
        gridRef.current?.api?.deselectAll();
    };

    const renderCompanyName = (args: ICellRendererParams) => {
        const { id, name } = args.data;
        return companyTemplate(name, id);
    };

    const renderCompanyEmail = (args: ICellRendererParams) => {
        const { email } = args.data;

        const handleEmailClick = (event: React.MouseEvent) => {
            event.preventDefault();
            navigator.clipboard.writeText(email);
            setSnackbarOpen('Email copied to clipboard');
        };

        return (
            <Box display="flex" alignItems="center" height="100%">
                {emailTemplate(email, handleEmailClick)}
            </Box>
        );
    };

    const renderLocations = (args: ICellRendererParams) => {
        let { visible_to: locations } = args.data;
        const { visible_to_all } = args.data;

        if (visible_to_all) locations = [{ name: 'All Locations' }];

        const totalLocationCount = locations.length;

        let minLength;
        if (locations.length === 1) {
            minLength = 35;
        } else {
            minLength = 25;
        }

        const commaSpacedLocations = locations.map((item: { name: string }) => item.name)?.toString();

        if (totalLocationCount === 0) return null;

        return (
            <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
                <Tooltip placement="right" title={commaSpacedLocations}>
                    <span>
                        <BodyXS py={0.5} px={1.5} mx={0.5} borderRadius={0.5} bgcolor={'neutral.main'}>
                            {locations?.[0]?.name?.length > minLength ? locations[0].name.slice(0, minLength) + '...' : locations?.[0]?.name}
                        </BodyXS>
                    </span>
                </Tooltip>

                {totalLocationCount > 1 && (
                    <Tooltip placement="right" title="This user is part of multiple locations. Edit Details to view and edit the locations this user is assigned to.">
                        <span>
                            <BodyXS py={0.5} px={1.5} mx={0.5} borderRadius={0.5} bgcolor={'secondary.dark'} color={'neutral.light'} maxWidth={'max-content'}>
                                +{totalLocationCount - 1}
                            </BodyXS>
                        </span>
                    </Tooltip>
                )}
            </Box>
        );
    };

    const renderWebsite = (args: ICellRendererParams) => {
        const { website } = args.data;
        return websiteTemplate(website, '_blank');
    };

    const renderMenuList = (args: ICellRendererParams) => {
        return menuOptions(args, onClickEdit, onClickDelete, isAdmin);
    };

    const columnDefs = useMemo<any>(() => {
        return [
            {
                field: 'id',
                hide: true
            },
            { headerCheckboxSelection: true, checkboxSelection: true, minWidth: 50, width: 50, maxWidth: 50, resizable: true, hide: !isAdmin },
            {
                field: 'name',
                headerName: 'Name',
                minWidth: 145,
                cellRenderer: renderCompanyName
            },
            {
                field: 'erp_customer_id',
                headerName: 'CRM/ERP ID',
                minWidth: 145,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'business_type',
                headerName: 'Business Type',
                minWidth: 160,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'email',
                headerName: 'Email',
                minWidth: 170,
                cellRenderer: renderCompanyEmail
            },
            {
                field: 'phone',
                headerName: 'Phone Number',
                minWidth: 150,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'website',
                headerName: 'Website',
                minWidth: 170,
                cellRenderer: renderWebsite
            },
            {
                field: 'line1',
                headerName: 'Address Line 1',
                minWidth: 160,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'line2',
                headerName: 'Address Line 2',
                minWidth: 160,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'country',
                headerName: 'Country',
                minWidth: 140,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'city',
                headerName: 'City',
                minWidth: 140,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'state',
                headerName: 'State',
                minWidth: 140,
                suppressMenu: false,
                filter: 'agSetColumnFilter',
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'zip',
                headerName: 'Zip',
                minWidth: 100,
                valueFormatter: (params: ICellRendererParams) => {
                    if (!params.value) return '--';

                    return params.value;
                }
            },
            {
                field: 'locations',
                headerName: 'Associated Locations',
                minWidth: 250,
                cellRenderer: renderLocations,
                filter: 'agSetColumnFilter',
                getQuickFilterText: getLocationsFilterText,
                comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
                    const strA = nodeA.data.visible_to_all ? 'All Location' : nodeA.data.visible_to.join('');
                    const strB = nodeB.data.visible_to_all ? 'All Location' : nodeB.data.visible_to.join('');

                    if (strA.toLowerCase() == strB.toLowerCase()) return 0;
                    return strA.toLowerCase().localeCompare(strB.toLowerCase());
                }
            },
            {
                field: 'lastModified',
                headerName: 'Last Modified',
                minWidth: 180,
                cellRenderer: renderLastModifiedDate,
                cellStyle: { 'white-space': 'normal' },
                getQuickFilterText: getLastModifiedFilterText,
                comparator: dateComparator
            },
            {
                headerComponent: () => (
                    <Box mt={1}>
                        <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                    </Box>
                ),
                cellRenderer: renderMenuList,
                pinned: 'right',
                minWidth: 50,
                maxWidth: 50,
                resizable: false
            }
        ];
    }, [isAdmin]);

    const onCheckboxChange = (args: any) => {
        const selected = Array.from(args.api.selectionService.selectedNodes.values());
        const selectedCompanies: CompanyObjType[] = selected.map((i: any) => i.data);
        setSelectedCompanies(() => [...selectedCompanies]);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const clearAllFilters = () => {
        gridRef.current.api.setFilterModel(null);
    };

    const onCloseBanner = () => {
        setSelectedCompanies([]);
        handleClearSelection();
    };

    const handleImportDialog = () => {
        setOpenImportDialog(false);
    };

    const onImport = (uploadResponse: ImportFileResponse) => {
        handleImportDialog();
        setSnackbarOpen(uploadResponse.message, 1500);
        setTimeout(() => {
            if (uploadResponse.error) {
                setSnackbarOpen(uploadResponse.error, 3000);
            }
        }, 2000);
    };

    const toolbarRightSection = useMemo(() => {
        return (
            <Box display="flex" gap={2}>
                {isAdmin && (
                    <Button variant="outlined" color="secondary" onClick={() => setOpenImportDialog(true)}>
                        Bulk Import
                    </Button>
                )}
                <Button variant="contained" onClick={toggleOpenCreateModal}>
                    Create New
                </Button>
            </Box>
        );
    }, []);

    const toolBarOptions = useMemo(() => {
        return isAdmin ? (['search', 'clearFilters', 'selectedItems', 'delete'] as ToolbarType) : (['search', 'clearFilters'] as ToolbarType);
    }, [isAdmin]);

    const handleSnackbarClose = (_e: Event | SyntheticEvent<any, Event>, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarClose();
    };

    return (
        <>
            <Snackbar open={snackbarInfo.open} onClose={handleSnackbarClose} message={snackbarInfo.message} autoHideDuration={snackbarInfo.autoHideDuration} />
            <Box zIndex={1} width={'100%'} position={'relative'} p={4} pb={2} flex={1} ref={tableContainerRef}>
                {tableHeight ? (
                    <Box display="flex" flexDirection="column" height={1}>
                        <CustomToolBarPanel
                            toolBarOptions={toolBarOptions}
                            selectedRowCount={selectedCompanies.length}
                            disabledToolBarButton={selectedCompanies.length === 0}
                            onTextSearch={handleSearch}
                            onDelete={onClickMultipleDelete}
                            onClearFilter={clearAllFilters}
                            onCloseBanner={onCloseBanner}
                            toolbarRightSection={toolbarRightSection}
                        />
                        <Box height={tableHeight - 120} className="ag-theme-alpine" mt={2} flex={1}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                rowHeight={40}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressMovableColumns={true}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                suppressContextMenu={true}
                                onSelectionChanged={onCheckboxChange}
                                quickFilterText={searchText}
                                pagination={true}
                                paginationAutoPageSize={true}
                                suppressMenuHide={true}
                                loadingOverlayComponent={loadingOverlayComponent}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                                modules={[GridChartsModule]}
                            />
                        </Box>
                    </Box>
                ) : null}
            </Box>
            {openDeleteDialog && (
                <DeleteConfirmationDialog title={DELETE_COMPANY_TITLE} open={openDeleteDialog} handleDialog={closeDeleteDialog} confirmAction={handleDelete} loading={isLoading}>
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {renderCompanyDeleteTitle(selectedCompanies.length)}
                        </BodySmall>
                        <Info mt={4}>{companyDeleteBody()}</Info>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {openImportDialog && <BulkImport open={openImportDialog} handleDialog={handleImportDialog} confirmAction={onImport} />}
            {openCreateModal && <CreateCompany open={openCreateModal} onClose={toggleOpenCreateModal} />}
        </>
    );
};

export default CompanyTable;
