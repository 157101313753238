import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { endPoints, getBranchDetails, getAllBranchLocationInfo, patchBranchDetails, addNewBranchDetails, deleteBranchLocation } from './apis';
import { queryClient } from 'src/app/queryClient';
import { BranchDetailsReponse, CompanyLocationResponse } from './apiTypes';
import {
    invalidateProjectInfoInDashboard,
    invalidateBomInfoInDashboard,
    invalidateSubmittalInfoInDashboard,
    invalidateOmPackageInfoInDashboard,
    invalidateQuoteInfoInDashboard
} from '../../../Dashboard/queries';
import { invalidateAllUserInfoQuery, invalidateCompanyQuery } from '../../UserManagement/queries';
import { invalidateCompanyMfgQuery } from '../../Manufacturer/queries';
import { invalidateGetAllCompanyDetailsKey, invalidateGetAllUserLocations, invalidateGetAllLocationsListQuery } from '../../../shared/CreateAndEditProjectModal/queries';
import { invalidateAllProjectDetails } from '../../../Project/queries';
import { invalidateQuoteHeaderFooterInGeneralQuery } from '../../../BOM/FinalDocuments/Compile/compileQuote/queries';
import { AxiosResponse } from 'axios';

const companyInfoKey = [endPoints.company];
const getBranchDetailsKey = (id: number) => [endPoints.branchDetails(id)];

export const useCompanyInfoQuery = () => useQuery(companyInfoKey, getAllBranchLocationInfo);
export const useBranchDetailsQuery = (id: number, options?: Omit<UseQueryOptions<AxiosResponse<BranchDetailsReponse>>, 'queryKey' | 'queryFn'>) =>
    useQuery(getBranchDetailsKey(id), () => getBranchDetails(id), options);

export function invalidateBranchDetailsQuery(bomId: number) {
    queryClient.invalidateQueries(getBranchDetailsKey(bomId));
}
export function invalidateCompanyInfoQuery() {
    queryClient.invalidateQueries(companyInfoKey);
}

export const useUpdateBranchDetailsMutation = () =>
    useMutation(patchBranchDetails, {
        onSuccess: (_, request) => {
            const { id: companyID } = request;
            invalidateBranchDetailsQuery(Number(companyID));
            invalidateCompanyInfoQuery();
            invalidateCompanyQuery();
            invalidateGetAllCompanyDetailsKey();
            invalidateGetAllUserLocations(); //invalidated so that updated location name should be visible on project detail
            invalidateQuoteHeaderFooterInGeneralQuery();
        }
    });
export const useAddNewBranchDetailsMutation = () =>
    useMutation(addNewBranchDetails, {
        onSuccess: () => {
            invalidateCompanyInfoQuery();
            invalidateCompanyQuery();
        }
    });

export const useDeleteBranchLocationMutation = () =>
    useMutation(deleteBranchLocation, {
        onSuccess: (_data, props) => {
            queryClient.setQueryData(companyInfoKey, (oldData: any) => {
                const newData = oldData?.data.filter((item: CompanyLocationResponse) => item.id !== props.branchId);
                return { ...oldData, data: newData };
            });
            invalidateProjectInfoInDashboard();
            invalidateBomInfoInDashboard();
            invalidateSubmittalInfoInDashboard();
            invalidateOmPackageInfoInDashboard();
            invalidateQuoteInfoInDashboard();
            invalidateAllUserInfoQuery();
            invalidateCompanyQuery(); // from user management
            invalidateCompanyInfoQuery(); // from branch location
            invalidateCompanyMfgQuery();
            invalidateGetAllCompanyDetailsKey();
            invalidateGetAllLocationsListQuery(props.userId);
            invalidateAllProjectDetails();
            invalidateQuoteHeaderFooterInGeneralQuery();
        }
    });
