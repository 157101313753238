import { useContext } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Box, Link } from '@parspec/pixel';

import { ManufacturerListTableContext } from '../context';
import { PERMISSION } from '../../constants';

export function ListNameRenderer(params: ICellRendererParams) {
    const { data, column } = params;
    const { handleSingleEdit, isAdmin } = useContext(ManufacturerListTableContext) || {};
    const fieldName = column?.getColId() || 'manufacturerListName';
    const listName = data[fieldName] || '';
    const toDisableLink = !(isAdmin || data.permission === PERMISSION.USER);

    return (
        <Box display="flex" height="100%" alignItems="center">
            <Link
                component="button"
                color="primary"
                onClick={() => {
                    handleSingleEdit?.(data.id);
                }}
                underline="hover"
                disabled={toDisableLink}
                sx={{
                    '&[disabled]': {
                        color: 'secondary.main',
                        cursor: 'default',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    }
                }}
            >
                {listName}
            </Link>
        </Box>
    );
}
