import { Box, Skeleton } from '@parspec/pixel';

const EmailSkeleton = () => {
    return (
        <Box mt={5} display="flex" flexDirection="column" rowGap={5}>
            <Skeleton variant="rectangular" height={36} />
            <Skeleton variant="rectangular" height={36} />
            <Skeleton variant="rectangular" height={136} />
            <Skeleton variant="rectangular" height={36} />
        </Box>
    );
};

export default EmailSkeleton;
