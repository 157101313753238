import { useRef, useMemo, useState } from 'react';

import { Snackbar, Box, TableHeaderMenuIcon, AgGridTable, ColDef, SelectChangeEvent } from '@parspec/pixel';
import { isEqual } from 'lodash-es';

import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

import {
    getBomRowData,
    getStageTemplate,
    getSyncCache,
    getDocumentOwnerTemplate,
    getDateForTable,
    getDateComparator,
    stringComparator,
    getDateValueForUI,
    dateFilterParams
} from '../shared/utils/utils';
import {
    useGetSubmittalQuery,
    useOutOfSyncQuery,
    useDocumentTags,
    userDashboardViewMutation,
    useGetSelectedColumnsAndPersistingFiltersQuery,
    useUpdateSelectedColumnsMutation,
    useUpdatePersistingFiltersMutation
} from '../queries';
import { DashboardPageNameType } from '../queries/apiTypes';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import { SUBMITTAL_DOC } from '../../shared/constants';
import { TAB_LABEL } from '../shared/constants';
import { BomTablesDataType, SyncTypeForTable } from '../shared/utils';
import { DocInfo, SubmittalInfoForDashboard } from '../queries/apiTypes';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import { useGetUserDashboardViews } from '../shared/hooks';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { MenuOption, defaultColDef } from './components';
import { MenuContext } from './context';
import { getRowId, getSubmittalStatusTemplate } from './utils';
import TableRightSection from '../shared/components/TableRightSection';
import { ManageColumnsPopup } from '../shared/components/ManageColumnsPopup/ManageColumnsPopup';
import { submittalPageTableColumnOptions } from './utils';
import { DateTemplate, DocumentIdTemplate, BomTemplate, ProjectTemplate } from '../shared/components';

const defaultLockColumnDef: ColDef[] = [
    {
        minWidth: 60,
        maxWidth: 60,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        cellStyle: { backgroundColor: 'unset' },
        cellRenderer: MenuOption,
        editable: false,
        pinned: 'right',
        colId: 'menu',
        resizable: false,
        lockPosition: 'right',
        lockPinned: true
    }
];

const SubmittalTable = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { isDashboardViewsLoading, dashboardSelectedViews } = useGetUserDashboardViews();

    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();
    const { data: paymentDetail, isLoading: isPaymentDetailLoading } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const { data: submittalData, isLoading } = useGetSubmittalQuery(dashboardSelectedViews, {
        enabled: !isDashboardViewsLoading
    });
    const { data: submittalSyncData } = useOutOfSyncQuery(SUBMITTAL_DOC);
    const { data: documentTagsData } = useDocumentTags(SUBMITTAL_DOC);

    const { data: selectedColumnsAndFiltersQueryData, isFetching: isSelectedColumnsAndFiltersQueryDataFetching } = useGetSelectedColumnsAndPersistingFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: 'dashboard_submittal' },
        { enabled: !isDashboardViewsLoading && !isUserDataLoading }
    );
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();
    const { mutateAsync: updateSelectedFiltersMutation } = useUpdatePersistingFiltersMutation();

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);
    const tableRef = useRef<any>();

    const submittalOutOfSyncCache = useMemo(() => {
        if (submittalSyncData?.data) return getSyncCache(submittalSyncData.data);
        return null;
    }, [submittalSyncData]);

    const submittalTableData = useMemo(() => {
        if (!submittalData?.data) {
            return null;
        }

        const submittalTableDataWithSyncAndTags = submittalData.data.map((data) => {
            const bomId = data?.bom?.id;
            const currentBomData: SubmittalInfoForDashboard & SyncTypeForTable = { ...data };
            if (bomId && submittalOutOfSyncCache) {
                currentBomData.submittalOutOfSync = submittalOutOfSyncCache?.[bomId] || false;
            }

            if (bomId && documentTagsData?.data && currentBomData?.submittal) {
                currentBomData.submittal.forEach((item: DocInfo) => {
                    for (const submittalDoc of documentTagsData.data) {
                        if (item.bom_id === submittalDoc.bom_id && item.id === submittalDoc.entity_id && submittalDoc.entity_type === SUBMITTAL_DOC) {
                            item.tag_name = submittalDoc.tag_name;
                        }
                    }
                });
            }

            return currentBomData;
        });

        return submittalTableDataWithSyncAndTags;
    }, [submittalData?.data, submittalOutOfSyncCache, documentTagsData?.data]);

    const handleViewChange = (props: SelectChangeEvent<unknown>) => {
        if (userData?.data.id) userDashboardViewMutation(userData?.data?.id, props?.target?.value as string);
    };

    const defaultColumnDef: any = {
        submittalId: {
            field: 'submittalId',
            headerName: 'Submittal ID',
            minWidth: 150,
            cellRenderer: DocumentIdTemplate,
            sortable: true
        },
        status: {
            field: 'status',
            headerName: 'Status',
            cellRenderer: getSubmittalStatusTemplate,
            minWidth: 140,
            filter: 'agSetColumnFilter',
            suppressMenu: false,
        },
        quoteNumber: {
            field: 'quoteNumber',
            headerName: 'Quote ID',
            minWidth: 140,
            sortable: true
        },
        submittalVersion: {
            field: 'submittalVersion',
            headerName: 'Version',
            minWidth: 100,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        submittalCreationDate: {
            field: 'submittalCreationDate',
            headerName: 'Creation Date',
            minWidth: 200,
            cellRenderer: (params: any) => getDateForTable(params?.data?.submittalCreationDate),
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'submittalCreationDate'),
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'submittalCreationDate'),
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            },
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        submittalCreatedBy: {
            field: 'submittalCreatedBy',
            headerName: 'Created By',
            minWidth: 200,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        submittalProductCount: {
            field: 'submittalProductCount',
            headerName: 'Products Included',
            minWidth: 165,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        dueDate: {
            field: 'dueDate',
            headerName: 'Submittal Due Date',
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.dueDate} />,
            minWidth: 210,
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'dueDate'),
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'dueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        submittalOwner: {
            field: 'submittalOwner',
            headerName: 'Submittal Owner',
            minWidth: 210,
            cellRenderer: (props: ICellRendererParams) => getDocumentOwnerTemplate(props, 'SUBMITTAL'),
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        bomName: {
            field: 'bomName',
            headerName: 'Bill Of Materials',
            minWidth: 220,
            cellRenderer: (params: ICellRendererParams) => <BomTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectName: {
            field: 'projectName',
            headerName: 'Project',
            minWidth: 200,
            cellRenderer: (params: ICellRendererParams) => <ProjectTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectStage: {
            field: 'projectStage',
            headerName: 'Project Stage',
            minWidth: 180,
            cellRenderer: getStageTemplate,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        branchLocation: {
            field: 'branchLocation',
            headerName: 'Branch Location',
            minWidth: 180,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        }
    };

    function handleExportExcel() {
        const params = {
            fileName: 'Submittals_Parspec_Export.xlsx', // Specify the filename
            sheetName: 'Submittals' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number } = { pageName: 'dashboard_submittal', columnData: rowData, userId: userData?.data?.id || 0 };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const submittalDataForTable = useMemo(() => {
        let submittalTabRowData: Array<BomTablesDataType> = [];
        const submittalTableDataForUi: Array<BomTablesDataType> = [];

        if (!submittalTableData) {
            return null;
        }

        if (!isLoading && submittalTableData) {
            submittalTabRowData = submittalTableData.map(getBomRowData(TAB_LABEL.SUBMITTAL_TAB_LABEL));
            for (const item of submittalTabRowData) {
                if (item?.submittalData) {
                    for (const submittal of item.submittalData) {
                        submittalTableDataForUi.push({
                            ...item,
                            submittalId: submittal?.id || 0,
                            shareLink: submittal?.share_link || null,
                            status: submittal?.tag_name || null,
                            submittalVersion: submittal?.version || null,
                            submittalCreationDate: submittal?.created_at || null,
                            submittalCreatedBy: submittal?.compiled_by || null,
                            submittalProductCount: submittal?.product_count || null,
                            quoteNumber: submittal?.quote_number || null
                        });
                    }
                }
            }
        }

        return submittalTableDataForUi;
    }, [submittalTableData, isLoading]);

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsAndFiltersQueryData?.table_settings || isSelectedColumnsAndFiltersQueryDataFetching) {
            return;
        }

        if ((selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.prototype.hasOwnProperty.call(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [isSelectedColumnsAndFiltersQueryDataFetching, selectedColumnsAndFiltersQueryData?.table_settings, submittalDataForTable]);

    const onFirstDataRendered = (params: any) => {
        if (selectedColumnsAndFiltersQueryData?.table_settings.length === 0 || Object.keys(selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters || {}).length === 0) {
            return;
        }

        const response = selectedColumnsAndFiltersQueryData?.table_settings?.[0].filters;

        const filterKeys = Object.keys(response);
        filterKeys.forEach((key: string) => {
            params.api.getFilterInstance(key).setModel(response[key]);
        });

        params.api.onFilterChanged();
    };

    const handleFilterChanged = async (params: any) => {
        const { api } = params;
        const filterModel = api.getFilterModel();

        const apiResponse = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters;

        if (!isEqual(filterModel, apiResponse)) {
            // call mutation
            const payload: { pageName: DashboardPageNameType; filterData: any; userId: number } = { pageName: 'dashboard_submittal', filterData: filterModel, userId: userData?.data?.id || 0 };
            await updateSelectedFiltersMutation(payload);
        }
    };

    const isTableDataLoading = isLoading || isPaymentDetailLoading || isUserDataLoading || isSelectedColumnsAndFiltersQueryDataFetching || isUpdateSelectedColumnsMutationLoading;

    return (
        <>
            <Snackbar open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <MenuContext.Provider value={{ isActiveSubscription, setSnackbarMessage, handleSubscriptionPopup, tabName: 'SUBMITTAL' }}>
                    <AgGridTable
                        ref={tableRef}
                        getRowId={getRowId}
                        isLoading={isTableDataLoading}
                        rowHeight={52}
                        showToolbarPanel
                        toolBarPanelOptions={['search', 'clearFilters']}
                        onClearFilter={clearAllFilters}
                        toolbarRightSection={
                            <TableRightSection
                                selectedView={dashboardSelectedViews}
                                isMyViewLoading={isDashboardViewsLoading}
                                onMyViewChange={handleViewChange}
                                isTableLoading={isLoading}
                                onExportClick={handleExportExcel}
                                onManageColumnsClick={onManageColumnsClick}
                            />
                        }
                        rowData={submittalDataForTable}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        pagination
                        paginationAutoPageSize
                        suppressContextMenu
                        suppressMenuHide
                        onTextSearch={handleSearch}
                        quickFilterText={searchText}
                        onFirstDataRendered={onFirstDataRendered}
                        onFilterChanged={handleFilterChanged}
                    />
                </MenuContext.Provider>
            </Box>
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={submittalPageTableColumnOptions.data}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryDataFetching={isSelectedColumnsAndFiltersQueryDataFetching}
                />
            )}
        </>
    );
};

export default SubmittalTable;
