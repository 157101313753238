import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { BodyMedium, BodySmall, BodyXS, Box, dayjs, Grid, Paper } from '@parspec/pixel';

import useGetWindowHeight from '../../shared/hooks/useWindowHeight';
import { EventDataType } from '../shared/types';
import UserAvatar from '../shared/components/UserAvatar';
import { convertCostToFormattedValue } from '../../shared/utils/utils';

const fields = ['Owner', 'Total Due', 'Est. Value'];

interface Estimate {
    projectId: number;
    cost: number | null;
}

interface WeeklySummaryProps {
    bidBoardData: EventDataType[];
    selectedEventId: string;
    selectedWeekDate?: Date;
}

const WeeklySummary: FC<WeeklySummaryProps> = ({ bidBoardData, selectedEventId, selectedWeekDate }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const [isContentCompletelyShown, setIsContentCompletelyShown] = useState(false);
    const { windowHeight } = useGetWindowHeight();

    useEffect(() => {
        if ((listRef?.current?.scrollHeight || 0) > (listRef?.current?.clientHeight || 0)) {
            setIsContentCompletelyShown(false);
        } else {
            setIsContentCompletelyShown(true);
        }
    }, [windowHeight, selectedEventId, bidBoardData]);

    const currentWeekData = useMemo(() => {
        return (bidBoardData || []).filter((event) => {
            const weekStart = dayjs(selectedWeekDate).startOf('week');
            const weekEnd = dayjs(selectedWeekDate).endOf('week');
            return weekStart.isBefore(dayjs(event.date)) && weekEnd.isAfter(dayjs(event.date));
        });
    }, [bidBoardData, selectedWeekDate]);

    const weeklySummaryData = useMemo(() => {
        const data: { [key: string]: { name: string; totalDue: number; estimate: Estimate[]; ownerId: number } } = {};
        (currentWeekData || []).forEach((item) => {
            if (data[item.ownerId]) {
                const info = data[item.ownerId];
                info.totalDue += 1;
                //this is done because 3 cards can be present for 1 project but the
                // estimate should come only once for one project in a week
                const isProjectPresent = info.estimate.find(({ projectId }: { projectId: number }) => projectId === item.projectId);
                if (!isProjectPresent) info.estimate.push({ projectId: item.projectId, cost: item.cost });
            } else {
                data[item.ownerId] = {
                    name: item.owner,
                    totalDue: 1,
                    estimate: [{ projectId: item.projectId, cost: item.cost }],
                    ownerId: item.ownerId
                };
            }
        });
        const dataArray = Object.keys(data)
            .map((key) => data[key])
            .sort((a, b) => (a.totalDue > b.totalDue ? -1 : 1));
        return dataArray;
    }, [currentWeekData]);

    const userEstimate = (estimate: Estimate[]) => {
        return estimate.reduce((acc: number | null, { cost }: { cost: number | null }) => {
            if (cost !== null) {
                acc = (acc || 0) + cost;
            }
            return acc;
        }, null);
    };

    const totalEstimate = useMemo(() => {
        return (weeklySummaryData || []).reduce((acc: number | null, { estimate }: { estimate: Estimate[] }) => {
            if (userEstimate(estimate) !== null) {
                acc = (acc || 0) + (userEstimate(estimate) || 0);
            }
            return acc;
        }, null);
    }, [weeklySummaryData]);

    return (
        <Paper
            variant="outlined"
            sx={{
                borderRadius: 2,
                overflow: weeklySummaryData.length > 4 ? 'hidden' : '',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
                mt: 4,
                ...(!isContentCompletelyShown && selectedEventId ? { minHeight: '40%' } : {}),
                ...(isContentCompletelyShown || !selectedEventId ? {} : { flex: 1 })
            }}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <Box height="1px" bgcolor="neutral.main" width={1} />
                <Box>
                    <BodyMedium noWrap>Weekly Project Summary</BodyMedium>
                </Box>
                <Box height="1px" bgcolor="neutral.main" width={1} />
            </Box>
            <Box mt={4} px={2}>
                <Grid container>
                    {fields.map((field) => (
                        <Grid item xs={4} key={field}>
                            <Box pb={2} textAlign="center">
                                <BodyXS fontWeight={500} color="text.secondary">
                                    {field}
                                </BodyXS>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box height={'1px'} width="100%" bgcolor="neutral.main" />
            {currentWeekData.length ? (
                <Box mt={2} px={4} overflow="auto" ref={listRef}>
                    {(weeklySummaryData || []).map((item) => (
                        <Grid container key={item.ownerId}>
                            <Grid item xs={4} display="flex" alignItems="center">
                                <Box pb={2} display="flex" alignItems="center" gap={2}>
                                    <UserAvatar userName={item.name} userId={item.ownerId} />
                                    <BodyXS color="text.secondary">{item.name}</BodyXS>
                                </Box>
                            </Grid>
                            <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                                <Box pb={2} textAlign="center">
                                    <BodyXS color="text.secondary">{item.totalDue}</BodyXS>
                                </Box>
                            </Grid>
                            <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                                <Box pb={2} textAlign="center">
                                    <BodyXS color="text.secondary">{userEstimate(item.estimate) !== null ? convertCostToFormattedValue(userEstimate(item.estimate) || 0) : '-'}</BodyXS>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height={50}>
                    <BodySmall color="text.secondary">No Data Found!</BodySmall>
                </Box>
            )}
            {currentWeekData.length ? (
                <>
                    <Box>
                        <Grid container>
                            <Grid item xs={4} />
                            <Grid item xs={4} pl={1}>
                                <Box height="1px" bgcolor="neutral.main" width={1} />
                            </Grid>
                            <Grid item xs={4}>
                                <Box height="1px" bgcolor="neutral.main" width={1} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box px={4}>
                        <Grid container>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <Box pt={2} textAlign="center">
                                    <BodyXS fontWeight={500} color="text.secondary">
                                        {currentWeekData.length}
                                    </BodyXS>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box pt={2} textAlign="center">
                                    <BodyXS fontWeight={500} color="text.secondary">
                                        {totalEstimate !== null ? convertCostToFormattedValue(totalEstimate) : '-'}
                                    </BodyXS>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : null}
        </Paper>
    );
};

export default WeeklySummary;
