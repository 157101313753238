import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Skeleton, Snackbar } from '@parspec/pixel';

import { Header } from './Header';
import { LocationCard } from './LocationCard';
import { SnackbarDataInterface } from './utils/commonTypes';
import { useCompanyInfoQuery } from './queries';
import { useGetUserProfileInfoQuery } from '../MyProfile/queries';
import { DeleteLocationModal } from './Modals/DeleteLocationModal';

export const BranchLocations = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: userProfile, isLoading: isAdminLoading } = useGetUserProfileInfoQuery();
    const { data: companyInfo, isLoading } = useCompanyInfoQuery();
    const [snackbarData, setSnackbarData] = useState<SnackbarDataInterface>({
        isActive: false,
        message: ''
    });
    const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);

    useEffect(() => {
        const editFlag = searchParams.get('edit') === 'success';
        if (editFlag) {
            setSnackbarData({ isActive: true, message: 'Location details has been updated' });
            searchParams.delete('edit');
            setSearchParams(searchParams);
        }
        const addFlag = searchParams.get('addLocation') === 'success';
        if (addFlag) {
            setSnackbarData({ isActive: true, message: 'Company location has been successfully created' });
            searchParams.delete('addLocation');
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    return (
        <>
            <Box width="calc(100vw - 390px)" position="relative" overflow="hidden" height={'82vh'} bgcolor="primary.contrastText">
                <Box p={6} pb={0} position={'absolute'} zIndex={1} width="100%" bgcolor="primary.contrastText">
                    <Header isAdminLoading={isAdminLoading} userProfile={userProfile} />
                </Box>

                <Box p={6} mb={15} sx={{ overflowY: 'scroll' }} mt={30} height={'70vh'}>
                    <Box display={'flex'} flexWrap={'wrap'} gap={6}>
                        {!isLoading && companyInfo && companyInfo.data.length > 0 ? (
                            companyInfo.data.map((item: any) => (
                                <LocationCard
                                    userProfile={userProfile}
                                    key={item.id}
                                    locationId={item.id}
                                    locationLogo={item.logo}
                                    locationName={item?.name}
                                    locationAddress1={item?.address1}
                                    locationAddress2={item?.address2}
                                    locationEmail={item?.email}
                                    locationPhone1={item?.phone1}
                                    setIsDeleteLocationModalOpen={setIsDeleteLocationModalOpen}
                                    setSelectedLocation={setSelectedLocation}
                                />
                            ))
                        ) : (
                            <>
                                <Skeleton height={340} width={250} variant="rectangular" sx={{ borderRadius: 1 }} />
                                <Skeleton height={340} width={250} variant="rectangular" sx={{ borderRadius: 1 }} />
                                <Skeleton height={340} width={250} variant="rectangular" sx={{ borderRadius: 1 }} />
                            </>
                        )}
                    </Box>
                </Box>

                <DeleteLocationModal
                    isOpen={isDeleteLocationModalOpen}
                    setIsOpen={setIsDeleteLocationModalOpen}
                    currentLocationId={selectedLocation}
                    snackbarData={snackbarData}
                    setSnackbarData={setSnackbarData}
                />
            </Box>

            {<Snackbar open={snackbarData.isActive} message={snackbarData.message} onClose={() => setSnackbarData({ ...snackbarData, isActive: false, message: '' })} />}
        </>
    );
};
