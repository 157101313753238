import { useEffect, useState } from 'react';
import { To, useParams } from 'react-router-dom';

import { BodyXS, Box, Button, H6, Skeleton, Snackbar } from '@parspec/pixel';
import { isEqual } from 'lodash-es';

import { COMPANY_DETAIL_PAGE_DESCRIPTION, VISIBILITY_RADIO_OPTIONS } from '../../shared/constant';
import CompanyDetailsForm from './CompanyDetailsForm';
import ContactsTable from '../../Contacts/ContactsTable';
import { Logo } from '../../../shared/CreateAndEditProjectModal/queries/apiTypes';
import { useEditCompanyMutation, useGetCompanyDetails } from '../queries';
import { CANADA_STATES, COUNTRY, USA_STATES, updateMsg, REQUIRED_FIELD, EMAIL_FORMAT_INVALID, INVALIED_URL_MSG, LOGO_UPLOAD_MSG, LOCATION_REQUIRED_MESSAGE } from '../../../shared/constants';
import FormSkeleton from './FormSkeleton';
import { getProxyFileUrl, websiteValidationRegex } from 'src/features/shared/utils/utils';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import { useParspecNavigation } from 'src/app/Context';
import { CompanyDetailResponse, VisibilityObjType } from '../queries/apiTypes';
import { Contact } from '../../Contacts/queries/apiTypes';
import { Company } from '../../Contacts/utils';
import { modifiedStateList } from '../../../shared/utils/utils';

export type CompanyDetailsFormType = {
    id: number;
    name: string;
    business_type: string;
    email: string;
    phone: string;
    website: string;
    line1: string;
    line2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    notes: string;
    visiblity: string;
    logo: File | Logo | string | null;
    selectedLogo: { file?: File; s3_file_path: string };
    erp_customer_id?: string | null;
};

export type ICompanyErrorField = {
    nameErr: string;
    visibilityErr: string;
    emailErr: string;
    websiteErr: string;
    logoError: string;
};

const getData = (dataObject: CompanyDetailResponse | CompanyDetailsFormType) => {
    const { name, business_type, website, email, notes, phone, country, state, zip, line1, line2, city, logo, erp_customer_id } = dataObject;
    return { name, business_type, website, email, notes, phone, country, state, zip, line1, line2, city, logo, erp_customer_id };
};

const CompanyDetails = () => {
    const { companyId } = useParams();
    const { hasUnsavedChanges, setHasUnsavedChanges, pNavigate } = useParspecNavigation();
    const [formValues, setFormValues] = useState<CompanyDetailsFormType>({
        id: 0,
        name: '',
        business_type: '',
        email: '',
        phone: '',
        website: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        notes: '',
        visiblity: VISIBILITY_RADIO_OPTIONS[0].value,
        logo: null,
        selectedLogo: { s3_file_path: '' },
        erp_customer_id: ''
    });
    const [formError, setFormError] = useState<ICompanyErrorField>({
        nameErr: '',
        visibilityErr: '',
        emailErr: '',
        websiteErr: '',
        logoError: ''
    });
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [associatedContacts, setAssociatedContacts] = useState<Contact[]>();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [stateListOptions, setStateListOptions] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }]);

    const { data: companyDetailsData, isFetching } = useGetCompanyDetails(Number(companyId));
    const { mutateAsync: editCompany, isLoading } = useEditCompanyMutation();

    useEffect(() => {
        if (companyDetailsData?.data) {
            const initData = getData(companyDetailsData?.data);
            const stateData = getData(formValues);
            const visibilty = formValues.visiblity === VISIBILITY_RADIO_OPTIONS[0].value;
            const initialLocations = companyDetailsData?.data?.visible_to?.map((el: VisibilityObjType) => el.id.toString());
            if (!isEqual(initData, stateData) || companyDetailsData?.data?.visible_to_all !== visibilty || !isEqual(initialLocations, selectedLocations)) {
                setHasUnsavedChanges(true);
            } else {
                setHasUnsavedChanges(false);
            }
        }
    }, [companyDetailsData?.data, formValues, selectedLocations]);

    useEffect(() => {
        if (companyDetailsData && !isFetching) {
            const { id, name, business_type, website, email, notes, phone, country, state, zip, line1, line2, city, visible_to, visible_to_all, logo, contacts, erp_customer_id } =
                companyDetailsData.data;
            const locationArr: string[] = [];
            visible_to?.forEach((el: VisibilityObjType) => {
                locationArr.push(el.id.toString());
            });
            setSelectedLocations(locationArr);
            setAssociatedContacts(contacts);
            setFormValues({
                id: id,
                name: name,
                business_type,
                email: email,
                phone,
                website: website,
                line1,
                line2,
                city: city,
                country: country,
                state: state,
                zip: zip,
                notes: notes,
                visiblity: visible_to_all ? VISIBILITY_RADIO_OPTIONS[0].value : VISIBILITY_RADIO_OPTIONS[1].value,
                logo,
                selectedLogo: { s3_file_path: logo ? getProxyFileUrl(logo as string) : '' },
                erp_customer_id
            });
            if (country === COUNTRY.USA) {
                setStateListOptions(modifiedStateList(USA_STATES));
            } else if (country === COUNTRY.CANADA) {
                setStateListOptions(modifiedStateList(CANADA_STATES));
            }
        }
    }, [companyDetailsData, isFetching]);

    const handleFormSubmit = async () => {
        const { name, business_type, email, phone, website, line1, line2, city, state, country, zip, notes, visiblity, selectedLogo, logo, erp_customer_id } = formValues;
        const err: ICompanyErrorField = {
            nameErr: !name.trim() ? REQUIRED_FIELD : '',
            visibilityErr: visiblity === VISIBILITY_RADIO_OPTIONS[1].value && selectedLocations.length === 0 ? LOCATION_REQUIRED_MESSAGE : '',
            emailErr: email?.trim() && !validateEmail(email) ? EMAIL_FORMAT_INVALID : '',
            websiteErr: website?.trim() && !websiteValidationRegex().test(website.trim()) ? INVALIED_URL_MSG : '',
            logoError: selectedLogo?.file && !selectedLogo.s3_file_path ? LOGO_UPLOAD_MSG : ''
        };
        const isError = Object.keys(err).some((item) => Boolean(err[item as keyof ICompanyErrorField]));
        if (isError) {
            return setFormError(err);
        }
        const payload = {
            company_group: Number(companyDetailsData?.data.company_group),
            name: name,
            business_type,
            email: email,
            phone,
            website: website,
            line1,
            line2,
            city: city,
            country: country,
            state: state,
            zip: zip,
            notes: notes,
            visible_to_all: visiblity === VISIBILITY_RADIO_OPTIONS[0].value,
            visible_to: selectedLocations,
            logo: logo || '',
            erp_customer_id
        };

        await editCompany({ id: Number(companyId), payload: payload });

        setSnackbarMessage(updateMsg);
    };

    const companyInfo: Company = {
        id: companyDetailsData?.data?.id || 0,
        name: companyDetailsData?.data?.name || '',
        business_type: companyDetailsData?.data?.business_type || ''
    };

    const onCancel = () => {
        if (window?.history?.state?.navigationId === 1) {
            pNavigate('/v2/contactManagement');
        } else pNavigate(-1 as To);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Box height="100%">
                <Box p={6}>
                    <Box display="flex" justifyContent="space-between">
                        <H6 mb={3}>Company Details</H6>
                        <Box display="flex" gap={3}>
                            <Box height={36}>
                                <Button onClick={onCancel} color="secondary" variant="outlined">
                                    Cancel
                                </Button>
                            </Box>
                            <Box height={36}>
                                <Button variant="contained" onClick={handleFormSubmit} isLoading={isLoading} disabled={isLoading || !hasUnsavedChanges}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <BodyXS limit={false} mb={6} sx={{ whiteSpace: 'pre-line' }}>
                        {COMPANY_DETAIL_PAGE_DESCRIPTION}
                    </BodyXS>
                    {isFetching ? (
                        <FormSkeleton />
                    ) : (
                        <CompanyDetailsForm
                            formValues={formValues}
                            formError={formError}
                            setFormError={setFormError}
                            setFormValues={setFormValues}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={setSelectedLocations}
                            stateListOptions={stateListOptions}
                            setStateListOptions={setStateListOptions}
                        />
                    )}
                </Box>
                <Box>
                    <H6 pl={6} pr={6} pt={3}>
                        Associated Contacts
                    </H6>
                    <Box pl={2} pr={2}>
                        {isFetching ? (
                            <Box p={4} pb={2}>
                                <Skeleton variant="rectangular" height="330px" />
                            </Box>
                        ) : (
                            <ContactsTable isAssociatedContactTable={true} associatedTableData={associatedContacts} preSelectedCompany={companyInfo} />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CompanyDetails;
