import { useEffect, useState, useLayoutEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { BodyBig, Box, Skeleton, Snackbar } from '@parspec/pixel';

import AddSuggestion from './AddSuggestion';
import AddDocumentThumbnail from './AddDocumentThumbnail';
import DocumentItem, { Document } from './DocumentItem';
import { useGetDocumentsCallbackQuery, useGetDocumentsQuery } from './queries';
import { ReuseDialogType, options } from '../../BOM/shared/utils';
import NoDataSheetSuggestion from './NoDataSheetSuggestion';

interface DocumentProps {
    refresh: string | undefined;
    setSelectedDocument: (arg: Document | undefined) => void;
    selectedDocument: Document | undefined;
    setPreSelectedDocument: (arg: Document) => void;
    attributeOverwritePopupHandler?: () => void;
}

const skeletonsArr = [...new Array(3)];

const DocumentSuggestions = (props: DocumentProps) => {
    const { refresh, setSelectedDocument, selectedDocument, setPreSelectedDocument, attributeOverwritePopupHandler } = props;
    const [apiUrl, setApiUrl] = useState('');
    const [dataList, setDataList] = useState<Document[]>([]);
    const [addNewData, setAddNewData] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { bomId, productId, documentType, subDocumentType } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const refreshValue = searchParams.get('refresh');

    useEffect(() => {
        setSelectedDocument(undefined);
    }, [refresh]);

    const onGetApiCallbackSuccess = (data: { data: { callback: string } }) => {
        if (data?.data?.callback) setApiUrl(data?.data?.callback);
        searchParams.delete('refresh');
        setSearchParams(searchParams);
        setDataList([]);
    };

    const {
        refetch: refetchUrl,
        isLoading: callbackLoading,
        isFetching: callbackFetching
    } = useGetDocumentsCallbackQuery(
        { bomId: Number(bomId)!, documentType: documentType!, productId: Number(productId)!, subDocumentType: subDocumentType!, refresh: refreshValue || refresh! },
        {
            enabled: false,
            onSuccess: onGetApiCallbackSuccess,
            staleTime: 0
        }
    );

    useEffect(() => {
        if (Boolean(bomId) && Boolean(productId) && Boolean(documentType) && Boolean(subDocumentType)) {
            refetchUrl();
        }
    }, [refresh, bomId, productId, documentType, subDocumentType]);

    const {
        data: datasheetSuggestions,
        refetch,
        isFetching: isFetchingDatasheetSuggestions,
        isLoading: isLoadingDatasheetSuggestions,
        remove
    } = useGetDocumentsQuery(apiUrl, {
        enabled: Boolean(apiUrl),
        staleTime: 0
    });

    useLayoutEffect(() => {
        if (datasheetSuggestions?.data && !isFetchingDatasheetSuggestions) {
            const response = datasheetSuggestions?.data || {};
            const dataItems = (response?.search_results || []).map((item: any) => ({ ...item, id: uuidv4() })) || [];
            if (response?.callback) {
                setApiUrl(response?.callback);
            }
            setDataList((old) => [...old, ...dataItems]);
        }
    }, [datasheetSuggestions?.data, isFetchingDatasheetSuggestions]);

    useEffect(() => {
        if (apiUrl) {
            refetch();
        }
        return () => {
            remove();
        };
    }, [apiUrl]);

    const handleAddNewData = () => setAddNewData(!addNewData);

    const showLoading = isFetchingDatasheetSuggestions || isLoadingDatasheetSuggestions || callbackFetching || callbackLoading;

    return (
        <>
            {dataList.length !== 0 && (
                <BodyBig fontWeight={500} pl={6} mb={3} color="secondary">
                    Suggested Results({dataList.length})
                </BodyBig>
            )}
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {!showLoading && !dataList?.length && subDocumentType === ReuseDialogType.DATASHEET ? (
                <>
                    <NoDataSheetSuggestion onAddDatasheetClick={handleAddNewData} />
                </>
            ) : (
                <Box px={6} display="flex" flexWrap="wrap" gap="24px">
                    {dataList.map((item: Document, index: number) => (
                        <DocumentItem
                            item={item}
                            key={item.id}
                            index={index}
                            total={dataList.length}
                            dataList={dataList}
                            setSelectedDocument={setSelectedDocument}
                            selectedDocument={selectedDocument}
                            setPreSelectedDocument={setPreSelectedDocument}
                            subDocumentType={subDocumentType!}
                        />
                    ))}
                    {showLoading ? (
                        <>
                            {skeletonsArr.map(() => (
                                <Skeleton variant="rectangular" width="308px" height="532px" />
                            ))}
                        </>
                    ) : null}
                    <AddDocumentThumbnail handleAddDocument={handleAddNewData} subDocumentType={subDocumentType} />
                </Box>
            )}
            {addNewData && <AddSuggestion open={addNewData} handleAddNewData={handleAddNewData} options={options} attributeOverwritePopupHandler={attributeOverwritePopupHandler} />}
        </>
    );
};

export default DocumentSuggestions;
