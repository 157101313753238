export const companyDetails = {
  "id": 1,
  "phone1": "",
  "city": "Brooklyn",
  "address1": "1470 , E New York Ave 1470 , E New",
  "state": "AL - Alabama",
  "country": "USA",
  "name": "Parspec US",
  "zipcode": "2222222t",
  "address2": "Yogesh test",
  "phone2": "9557573847",
  "subscription_status": true,
  "date_joined": "2020-10-15T07:54:36.389354Z",
  "picture": "https://parspec-staging.s3.amazonaws.com/media/1/NOKIA_LOGO_WHITE_HR.png",
  "logo": "https://parspec-staging.s3.amazonaws.com/media/1/parspec-logo-dark.png",
  "website": "algoscale.com",
  "email": "s@gmail.com",
  "hubspot_company_id": "7644820764",
  "is_test_account": true,
  "subscription": 96,
  "submittal": {
    "cover_page_template_id": 1,
    "cover_page_presets": {
      "id": 1,
      "cover_page_section": true,
      "primary_title": true,
      "secondary_title": false,
      "project_number": true,
      "quote_number": true,
      "architect": false,
      "contractor": false,
      "engineer": false,
      "owner_or_dev": false,
      "distributor": true,
      "prepared_by": true,
      "addressed_to": false,
      "page_number": false,
      "date": false,
      "project_address": false,
      "company": 1
    },
    "header_footer_presets": {
      "id": 1,
      "header_footer_section": true,
      "project_name": true,
      "page_number": true,
      "date": false,
      "prepared_by": true,
      "attention_to": true,
      "hyperlinks": true,
      "manufacturer": true,
      "model": true,
      "category": true,
      "qty": true,
      "note": true,
      "logo": true,
      "alignment": "R",
      "created_at": "2022-09-07T12:54:26.333203Z",
      "updated_at": "2023-02-08T07:55:03.539826Z",
      "company": 1
    },
    "table_of_content_presets": {
      "id": 1,
      "toc_section": true,
      "type": false,
      "page_number": false,
      "hyperlinks": false,
      "logo": true,
      "primary_title": false,
      "secondary_title": false,
      "project_number": false,
      "quote_number": false,
      "created_at": "2022-09-07T12:54:26.005134Z",
      "updated_at": "2023-02-08T07:55:03.560580Z",
      "qty": false,
      "company": 1
    },
    "notes_presets": {
      "id": 18,
      "type": true,
      "qty": false,
      "notes_section": true,
      "page_number": false,
      "hyperlinks": false,
      "logo": true,
      "primary_title": false,
      "secondary_title": false,
      "project_number": false,
      "quote_number": false,
      "created_at": "2022-12-12T10:22:54.772218Z",
      "updated_at": "2023-02-08T07:55:03.662502Z",
      "company": 1
    }
  }
}



