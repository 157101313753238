import { Box, BodySmall, CloseIcon, Grid, IconButton } from '@parspec/pixel';

interface IProjectImage {
    url: string;
    onDelete: () => void;
    heading: string;
}

function ProjectImage({ url, onDelete, heading }: IProjectImage) {
    return (
        <Box width="100%" display="flex">
            <Grid container flexDirection={'column'} rowSpacing={2} p={2}>
                <Grid item>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <BodySmall fontWeight={500} paddingBottom={2} mt={2}>
                                {heading}
                            </BodySmall>
                        </Grid>

                        <Grid item>
                            <IconButton onClick={() => onDelete()}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item height={260}>
                    <img src={url} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default ProjectImage;
