import { Box } from '@parspec/pixel';

import Header from './Header';
import TabsContent from './TabsContent';

const Dashboard = () => {
    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header />
            <TabsContent />
        </Box>
    );
};

export default Dashboard;
