import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, BodyBig, BodySmall, Button, Skeleton } from '@parspec/pixel';
import ExtractAttributesPopup from '../ExtractAttributesPopup';
import { useGetSelectedDocumentQuery } from '../../../Datasheets/AnnotateDatasheet/queries';
import { useExtractRequirementsMutation, useProductRequirementGetQuery, useProductRequirementPatchMutation } from '../queries';
import { useTrackIntercomEvent } from 'src/features/shared/hooks/useIntercomHook';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { useParspecMfg, useCompanyMfgQuery } from 'src/features/Settings/Manufacturer/queries';
import { useGetBomDetails } from '../../../queries';
import { ExtractingAttribuesLoaderModal } from '../../shared/ExtractingAttributesLoaderModal';
import { checkIfReqFieldsAreEmpty } from '../utils';

interface ProductAttributesFormHeaderProps {
    dataSheetId?: number;
    isDataSheetLoading: boolean;
    getIfFieldsAreEmpty: () => boolean;
    isActionDisabled?: boolean;
    onClearAll: () => void;
    errorMessage?: string;
    errorMessageRef?: React.RefObject<HTMLDivElement> | null;
}

export function ProductAttributesFormHeader(props: ProductAttributesFormHeaderProps) {
    const { dataSheetId = 0, isDataSheetLoading, getIfFieldsAreEmpty, isActionDisabled, onClearAll, errorMessage, errorMessageRef } = props;
    const [showPopup, updatePopup] = useState(false);

    const { bomId = '0', productId = '0', projectId = '0' } = useParams();
    const { mutateAsync: extractRequirements, isLoading: isExtractLoading } = useExtractRequirementsMutation();
    const { data: dataSheetData, isLoading: isDatasheetLoading, isFetching: isDatasheetFetching } = useGetSelectedDocumentQuery(bomId, productId, 'submittal', 'datasheet', {});
    const navigate = useNavigate();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const { data: productRequirementData, isLoading: isGetReqLoading, isFetching: isGetReqFetching } = useProductRequirementGetQuery(Number(bomId), Number(productId));
    const { isFetching: parspecManufacturerLoading } = useParspecMfg();
    const { isFetching: companyManufacturerLoading } = useCompanyMfgQuery();
    const { isFetching: bomDetailsLoading, data: bomDetails } = useGetBomDetails(`${bomId || 0}`, { enabled: Boolean(bomId) });
    const { mutateAsync: updateProductRequirement, isLoading: isUpdateProductRequirementLoading } = useProductRequirementPatchMutation();

    const { isFetching: projectDetailsLoading } = useGetProjectDetailsQuery(bomDetails?.data.project || 0, { enabled: Boolean(bomDetails?.data.project) });

    const [isExtracted, setIsExtracted] = useState(false);

    const handleExtractRequriement = async () => {
        const id = dataSheetData?.data[0].id;
        await extractRequirements({ productId: Number(productId), bomId: Number(bomId), selectedDatasheetID: Number(id) });
        setIsExtracted(true);
    };

    function handleExtractAttributes() {
        if (getIfFieldsAreEmpty()) {
            handleExtractRequriement();
        } else {
            updatePopup(true);
        }
    }

    // once extract is done, OR
    // if user clicks on 'keep existing' or 'overwrite'
    // we should toggle the attribute_overwrite_popup key to false in the requirements/ patch api
    const setAttributeOverwriteKeyToFalse = async () => {
        const arrributePopupFieldDetails = productRequirementData?.data.find((el) => el.field_name === 'attribute_overwrite_popup');
        const data = {
            bomId: Number(bomId) || 0,
            productId: Number(productId) || 0,
            payload: {
                field_name: arrributePopupFieldDetails?.field_name || '',
                field_value: false,
                id: arrributePopupFieldDetails?.id || 0,
                is_locked: arrributePopupFieldDetails?.is_locked || false
            }
        };
        await updateProductRequirement(data);
    };

    const isProductRequirementDataLoadingOrFetching = isGetReqLoading || isGetReqFetching;
    const isDatasheetLoadingOrFetching = isDatasheetFetching || isDatasheetLoading;

    useEffect(() => {
        if (isProductRequirementDataLoadingOrFetching || isDatasheetLoadingOrFetching || !productRequirementData?.data || !dataSheetData?.data[0]) {
            return;
        }

        const arrributePopupFieldDetails = productRequirementData.data.find((el) => el.field_name === 'attribute_overwrite_popup');

        const datasheetID = dataSheetData?.data[0].id;

        if (datasheetID) {
            if (checkIfReqFieldsAreEmpty(productRequirementData.data) && !isExtracted) {
                // case: user selects datasheet from datasheet tab and comes to fresh PF screen
                // ###### OR ######
                // case: user is in fresh PF screen, clicks SELECT SOURCE DATASHEET
                // selects a datasheet, clicks on save --> here, on save, field_value will be TRUE
                // when user lands on PF screen again, fields are EMPTY, but field_value for overwritePopup = TRUE
                // we need to extract Attribute and not show overwrite popup even if field_value === true
                handleExtractRequriement();
            } else if (arrributePopupFieldDetails?.field_value === true) {
                // case: reqField values are populated and user selects a different datasheet
                // need to show overwrite popup
                updatePopup(true);
            }
        }
    }, [dataSheetData?.data[0], productRequirementData?.data, isProductRequirementDataLoadingOrFetching, isDatasheetLoadingOrFetching]);

    const isClearAllLoading = isGetReqLoading || isGetReqFetching || parspecManufacturerLoading || companyManufacturerLoading || bomDetailsLoading || projectDetailsLoading;

    return (
        <>
            <ExtractAttributesPopup
                showPopup={showPopup}
                updatePopup={updatePopup}
                setAttributeOverwriteKeyToFalse={setAttributeOverwriteKeyToFalse}
                isUpdateProductRequirementLoading={isUpdateProductRequirementLoading}
            />
            <Box p={6}>
                <BodyBig fontWeight="500">Overview</BodyBig>
                <BodySmall limit={false} mt={4} mb={2} color={'neutral.dark'}>
                    Search for an alternate product by filling out the attributes. Click the lock button to require that all resulting products contain at least one of the specified values.
                    <br />
                    <br />
                    You can also select a source datasheet to prefill the attributes.
                </BodySmall>
                <Box display="flex" width="100%" mb={6}>
                    {isDataSheetLoading && (
                        <>
                            <Skeleton variant="rectangular" width="182px" height="33px" />
                            <Box marginLeft={2}>
                                <Skeleton variant="rectangular" width="227px" height="33px" />
                            </Box>
                        </>
                    )}
                    {!isDataSheetLoading && (
                        <>
                            <Box>
                                <Button
                                    disabled={isActionDisabled}
                                    color={dataSheetId !== 0 ? 'secondary' : 'tertiary'}
                                    variant={dataSheetId !== 0 ? 'outlined' : 'contained'}
                                    onClick={() => {
                                        navigate(`/v2/project/${projectId}/bom/${bomId}/product/${productId}/submittal/datasheet/match?from=productFinder`);
                                        if (dataSheetId === 0) trackIntercomEvent('PF_Select_Source_Datasheet');
                                    }}
                                >
                                    {dataSheetId !== 0 ? 'Change Source Datasheet' : 'Select Source Datasheet'}
                                </Button>
                            </Box>
                            {dataSheetId !== 0 && (
                                <Box marginLeft={2}>
                                    <Button disabled={isActionDisabled} color="secondary" variant="outlined" onClick={handleExtractAttributes} isLoading={isExtractLoading}>
                                        Extract Attributes From Datasheet
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <BodyBig fontWeight="500">Attributes</BodyBig>
                    {isClearAllLoading && <Skeleton variant="rectangular" width="64px" height="33px" />}
                    {!isClearAllLoading && (
                        <Button variant="text" onClick={onClearAll} color="primary" disabled={isActionDisabled || isExtractLoading}>
                            Clear All
                        </Button>
                    )}
                </Box>
                <Box ref={errorMessageRef}>
                    {errorMessage && (
                        <BodySmall color="error" pt={4} limit={false}>
                            {errorMessage}
                        </BodySmall>
                    )}
                </Box>
            </Box>
            {isExtractLoading && <ExtractingAttribuesLoaderModal open={true} />}
        </>
    );
}
