import axios from 'axios';
import { GetMailsRequest, GetMessageByIdRequest, SendEmailRequest } from './apiTypes';

export const endpoints = {
    msEmails: (queryString: string) => `https://graph.microsoft.com/v1.0/me/messages?search="${queryString}"`,
    googleMails: (queryString: string) => `https://www.googleapis.com/gmail/v1/users/me/messages?q=${queryString}`,
    getGoogleMailById: (id: string) => `https://www.googleapis.com/gmail/v1/users/me/messages/${id}`,
    msMailSend: () => 'https://graph.microsoft.com/v1.0/me/sendMail',
    googleMailSend: () => 'https://www.googleapis.com/upload/gmail/v1/users/me/messages/send',
    getGoogleUserInfo: () => 'https://www.googleapis.com/oauth2/v1/userinfo'
};

//Apis
export const getMsEmails = async (reqObj: GetMailsRequest) => {
    const { token, queryString } = reqObj;
    return axios.get(endpoints.msEmails(queryString || ''), {
        headers: {
            Authorization: `Bearer ${token}`
        },
        transformResponse: function (data) {
            const updatedEmailData = JSON.parse(data).value?.map((dataObj: any) => {
                const { id, bodyPreview, from, sender, subject, sentDateTime, conversationId } = dataObj;
                return {
                    id,
                    body: bodyPreview,
                    from: `${from.emailAddress.name} <${from.emailAddress.address}>`,
                    to: `${sender.emailAddress.name} <${sender.emailAddress.address}>`,
                    subject: subject,
                    date: sentDateTime,
                    conversationId
                };
            });

            return updatedEmailData;
        }
    });
};

export const getGoogleMails = async (reqObj: GetMailsRequest) => {
    const { token, queryString } = reqObj;
    return axios.get(endpoints.googleMails(queryString || ''), {
        headers: {
            Authorization: `Bearer ${token}`
        },
        transformResponse: async function (data) {
            const emailResponsePromises: any = JSON.parse(data)?.messages?.map(async (messageObj: any) => {
                const payload = {
                    token: reqObj.token,
                    id: messageObj.id
                };
                const res = await getGoogleMessageById(payload);

                const { id, threadId, snippet, payload: messagePayload } = res.data;

                const emailObj: { [key: string]: string } = {
                    body: snippet,
                    id,
                    threadId
                };
                messagePayload.headers.map((header: { name: string; value: string }) => {
                    const { name, value } = header;
                    if (name === 'From' || name === 'Subject' || name === 'To' || name === 'Date') {
                        emailObj[name.toLowerCase()] = value;
                    }
                });
                return emailObj;
            });
            const emailData = await Promise.all(emailResponsePromises);

            return emailData;
        }
    });
};

export const getGoogleMessageById = async (reqObj: GetMessageByIdRequest) => {
    const { token, id } = reqObj;
    return axios.get(endpoints.getGoogleMailById(id), {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const sendMsEMail = async (reqObj: SendEmailRequest) => {
    const { token, rawMessage } = reqObj;
    return axios.post(endpoints.msMailSend(), rawMessage, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};

export const sendGoogleMail = async (reqObj: SendEmailRequest) => {
    const { token, rawMessage } = reqObj;
    return axios.post(endpoints.googleMailSend(), rawMessage, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'message/rfc822'
        }
    });
};

export const fetchS3File = async (s3Link: string) => {
    return axios.get(s3Link, { responseType: 'blob' });
};

export const fetchGoogleUserInfo = async (token: string) => {
    return axios.get(endpoints.getGoogleUserInfo(), {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};
