import { axios } from 'src/app/Axios';

import {
    IModdedUserInfoResponse,
    IModdedCompanyLocationResponse,
    ICompanyLocation,
    IUserInfo,
    ILoginInviteRequest,
    IEditUserDetailsRequest,
    ProductFinderAccessRequest,
    ProductFinderAccessResponse,
    QuotingAccessRequest,
    EditInvitedUserDetailsRequest
} from './apiTypes';
import { mapCompanyLocationData } from '../utils/utils';

export const endPoints = {
    allUserInfo: '/api/user_info/active',
    company: '/api/company',
    productFinderAccess: (userId: number) => `/api/user_info/${userId}/product_finder/`,
    quotingAccess: (userId: number) => `/api/user_info/${userId}/quoting/`,
    getActiveOrDeactivated: (tabValue?: string) => `/api/user_info/${tabValue}`,
    invitatngUser: '/api/login_invitation',
    activeInactivUser: (activeUser: string, userId: number) => `/api/user_info/${userId}/${activeUser}/`,
    allInactiveInfo: '/api/user_info/inactive'
};

export const getAllUsersData = async (): Promise<IModdedUserInfoResponse> => {
    const resp = await axios.get(endPoints.allUserInfo);

    const moddedData = mapCompanyLocationData(resp.data || []);

    return { ...resp, data: moddedData };
};

export const getAllCompanyBranchLocations = async (): Promise<IModdedCompanyLocationResponse> => {
    const resp = await axios.get(endPoints.company);

    const moddedData = resp?.data?.map((el: ICompanyLocation) => ({
        ...el,
        isChecked: false
    }));
    return { data: moddedData };
};

export const getCheckForProductFinderAccess = async (userId: number) => {
    return axios.get<ProductFinderAccessResponse>(endPoints.productFinderAccess(userId));
};

export const postSendLoginInvitation = async (data: { users: ILoginInviteRequest }) => {
    return axios.post('/api/login_invitation/', data);
};

export const deleteUser = async (userid: number) => {
    return axios.delete(`/api/user_info/${userid}/`);
};

export const edtUserDetails = async (props: { userId: number; data: IEditUserDetailsRequest }) => {
    return axios.patch(`/api/user_info/${props.userId}/`, props.data);
};

export const editProductFinderAccess = async (props: { userId: number; payload: ProductFinderAccessRequest }) => {
    return axios.put<ProductFinderAccessRequest>(endPoints.productFinderAccess(props.userId), props.payload);
};
export const editQuotingAccess = async (props: { userId: number; payload: QuotingAccessRequest }) => {
    return axios.put<QuotingAccessRequest>(endPoints.quotingAccess(props.userId), props.payload);
};

export const getActiveOrDeactivated = async (tabValue?: string) => {
    const response = await axios.get<IUserInfo[]>(endPoints.getActiveOrDeactivated(tabValue));
    const mappedData = mapCompanyLocationData(response.data || []);
    return { ...response, data: mappedData };
};

export const getInvited = async () => {
    const response = await axios.get<IUserInfo[]>(endPoints.invitatngUser);
    const mappedData = mapCompanyLocationData(response.data || []);
    return { ...response, data: mappedData };
};

export const activeDeactiveUser = async ({ activeUser, userid }: { activeUser: string; userid: number }) => {
    return axios.patch(endPoints.activeInactivUser(activeUser, userid));
};

export const editInvetedUserDetails = async (props: { userId: number; data: EditInvitedUserDetailsRequest }) => {
    return axios.patch(`/api/login_invitation/${props.userId}/`, props.data);
};

export const deleteInvitedUser = async (userid: number) => {
    return axios.delete(`/api/login_invitation/${userid}/`);
};
