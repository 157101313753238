import { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { Box, Button, Link, Menu, MoreVertIcon, Snackbar, Tooltip } from '@parspec/pixel';

import { autoSearchMsg } from '../../shared/constants';
import { useOmAutoSearchMutation } from '../queries';
import { AUTO_SEARCH_OPTIONS } from '../shared/constants';
import CreateSectionButton from '../shared/CreateSectionButton';
import ExportButton from '../shared/ExportButton';
import ReuseDatasheetDialog from '../shared/ReuseDatsheetDialog';
import ReuseOMDialog from '../shared/ReuseOMDialog';
import SortButton from '../shared/SortButton';
import AutosearchLoaderDialog from '../../shared/AutosearchLoaderDialog';
import { getOmLoadTime } from './helper';
import { ReuseDialogType } from '../shared/utils';
import { BodResponse, OmDataResponse } from '../queries/apiTypes';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';

interface OmTabHeaderProps {
    selectedItems: number[];
    unCheckSelectedLineItems: () => void;
    tableData: BodResponse[] | OmDataResponse[];
}

const OmTabHeader = ({ selectedItems, unCheckSelectedLineItems, tableData }: OmTabHeaderProps) => {

    const { bomId } = useParams();
    const [openReuseDatasheetDialog, setOpenReuseDatasheetDialog] = useState(false);
    const [showAutosearchDialog, setShowAutoSearchDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openReuseOMDialog, setOpenReuseOMDialog] = useState(false);
    const [selectedReuseDialogType, setSelectedReuseDialogType] = useState<ReuseDialogType>(ReuseDialogType.WARRANTY);

    const { mutateAsync: autoSearch, isLoading: autoSearchLoading } = useOmAutoSearchMutation();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const handleAutoSearchDialog = () => setShowAutoSearchDialog((old) => !old);

    const onAutoSearch = async (type: 'me' | 'company_users' | 'company_group_users') => {
        handleAutoSearchDialog();
        try {
            const data = {
                bomId: Number(bomId),
                input: {
                    bod_id: selectedItems,
                    user_preference: type
                }
            };
            await autoSearch(data);
            handleAutoSearchDialog();
            unCheckSelectedLineItems();
            setSnackbarMessage(autoSearchMsg);
            trackIntercomEvent('Auto_Select');
        } catch (e) {
            /* empty */
        }
    };

    const handleReuseDatasheetDialog = () => {
        setOpenReuseDatasheetDialog((old) => !old);
    };

    const onReuse = () => {
        handleReuseDatasheetDialog();
        unCheckSelectedLineItems();
    };

    const onReuseOM = (value: { reuse: boolean; document?: ReuseDialogType }) => {
        if (value.reuse) {
            setSelectedReuseDialogType(value.document || ReuseDialogType.WARRANTY);
            handleReuseDatasheetDialog();
        } else {
            unCheckSelectedLineItems();
        }
        handleReuseOMDialog();
    };

    const handleReuseOMDialog = () => {
        setOpenReuseOMDialog((old) => !old);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box display="flex" justifyContent="flex-end" minWidth="1100px">
                <Box display="flex" gap={2} alignItems="center">
                    <SortButton />
                    <CreateSectionButton />
                    <Tooltip title={selectedItems.length ? 'Add or Reuse a Document from another product' : 'Select Item(s) First'} placement={'bottom'}>
                        <span>
                            <Button variant="outlined" color="secondary" disabled={!selectedItems.length} onClick={handleReuseOMDialog}>
                                Add / Reuse Document
                            </Button>
                        </span>
                    </Tooltip>
                    <ExportButton />
                    <Tooltip title={selectedItems.length ? '' : 'Select Item(s) First'} placement={'bottom'}>
                        <span>
                            <Menu options={AUTO_SEARCH_OPTIONS(onAutoSearch)}>
                                <Button variant="outlined" color="secondary" endIcon={<MoreVertIcon />} disabled={!selectedItems.length}>
                                    Auto-Select
                                </Button>
                            </Menu>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            {openReuseDatasheetDialog && (
                <ReuseDatasheetDialog
                    open={openReuseDatasheetDialog}
                    handleDialog={handleReuseDatasheetDialog}
                    confirmAction={onReuse}
                    tableData={tableData}
                    selectedRowIds={selectedItems}
                    reuseType={selectedReuseDialogType}
                />
            )}
            {openReuseOMDialog && <ReuseOMDialog open={openReuseOMDialog} handleDialog={handleReuseOMDialog} confirmAction={onReuseOM} selectedRowIds={selectedItems} />}
            {showAutosearchDialog && autoSearchLoading && (
                <AutosearchLoaderDialog
                    open={showAutosearchDialog && autoSearchLoading}
                    onClose={handleAutoSearchDialog}
                    loadTime={getOmLoadTime(selectedItems)}
                    helperLink={
                        <Link to="/v2/settings/my-profile" component={RouterLink} underline="hover">
                            Customize behavior here.
                        </Link>
                    }
                />
            )}
        </>
    );
};

export default OmTabHeader;
