import { MutableRefObject, useEffect } from 'react';

import { AxiosResponse } from 'axios';

import { Box, Button, H5 } from '@parspec/pixel';

import { useConfirmAnnotationMutation } from './queries';
import { SelectedDocumentResponse } from './queries/apiTypes';
import CancelButton from '../../../shared/CancelButton';
import { useParspecNavigation } from 'src/app/Context';

import { ReuseDialogType, ReuseDialogTypeLabel } from '../../shared/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useTrackIntercomEvent } from '../../../shared/hooks/useIntercomHook';

const defaultXfdf = `<?xml version="1.0" encoding="utf-8"?><xfdf xml:space="preserve" xmlns="http://ns.adobe.com/xfdf/"><fields/><annots/></xfdf>`;

interface PageHeaderProps {
    bomId: string;
    bodId: string;
    documentType: string;
    subDocumentType: string;
    wvInstance: any;
    data: AxiosResponse<Array<SelectedDocumentResponse>> | undefined;
    xfdf: MutableRefObject<string | undefined>;
    disableConfirmAnnotationButton?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const { setHasUnsavedChanges, pNavigate } = useParspecNavigation();
    const { projectId } = useParams();
    const { bomId, bodId, documentType, wvInstance, data, xfdf, subDocumentType, disableConfirmAnnotationButton } = props;
    const navigate = useNavigate();
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const isDatasheetAnnotation = subDocumentType === ReuseDialogType.DATASHEET;
    const redirectionTab = isDatasheetAnnotation ? 'dataSheets' : 'additionalDocuments';
    const redirectionUrl = `/v2/project/${projectId}/bom/${bomId}?tab=${redirectionTab}&productId=${bodId}`;
    const documentLabel = ReuseDialogTypeLabel[subDocumentType as keyof typeof ReuseDialogTypeLabel];

    useEffect(() => {
        return () => {
            setHasUnsavedChanges(false);
        };
    }, []);

    const { mutateAsync: confirmAnnotation, isLoading: confirmAnnotationLoading } = useConfirmAnnotationMutation();

    const handleChangeDatasheet = () => {
        pNavigate(`/v2/project/${projectId}/bom/${bomId}/product/${bodId}/${documentType}/${subDocumentType}/match`);
    };
    const handleSkipAnnotations = () => {
        pNavigate(redirectionUrl);
    };

    const confirmAnnotations = async () => {
        setHasUnsavedChanges(false);
        const confirmAnnotationsData = {
            bomId: bomId,
            bodId: bodId,
            documentType: documentType,
            subDocumentType: subDocumentType,
            selectedDocumentId: data?.data[0]?.id || 0,
            xfdf: xfdf.current! || defaultXfdf
        };
        try {
            await confirmAnnotation(confirmAnnotationsData);
            if (subDocumentType === 'datasheet') {
                trackIntercomEvent('Confirm_Annotations');
                navigate(`/v2/project/${projectId}/bom/${bomId}?tab=dataSheets&productId=${bodId}`);
                return;
            }
            navigate(`/v2/project/${projectId}/bom/${bomId}?tab=additionalDocuments&productId=${bodId}`);
        } catch (err) {
            console.log('err', err);
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" alignItems="start" px={6} pt={6}>
            <Box display="flex">
                <H5>{`Annotate ${documentLabel}`} </H5>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2} flexShrink={0}>
                <CancelButton />
                <Button color="secondary" variant="outlined" onClick={handleChangeDatasheet}>
                    {`Change ${documentLabel}`}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleSkipAnnotations}>
                    Skip Annotations
                </Button>
                <Button variant="contained" onClick={confirmAnnotations} isLoading={confirmAnnotationLoading} disabled={!wvInstance || disableConfirmAnnotationButton}>
                    Confirm Annotations
                </Button>
            </Box>
        </Box>
    );
};

export default PageHeader;
