import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, ModalHeader, Box, BodySmall, BodyBig } from '@parspec/pixel';

import PricingHistoryBody from '../../../pricingHistory/PricingHistoryBody';
import { ModalFooter } from './footer';
import { PricingHistoryTableDataType } from '../../../pricingHistory/PricingHistoryBody/types';
import { getApiDataForPricingInfo, getUpdatedPricingInfoForChange } from '../shared/utils';
import { usePricingBodPatchMutation } from '../queries';
import { PRICING_TABLE_ENUM } from '../shared/constants';

interface PricingModalPropsType {
    open: boolean;
    onClose: (afterSave?: boolean) => void;
    pricingInfo?: {
        type?: string;
        manufacturer?: string;
        modelNo?: string;
        id?: number;
        cost?: number;
        discount?: number;
        discountedCost?: number;
        sellPrice?: number;
        margin?: number;
        qty?: number;
        extendedPrice?: number;
        lotId?: number;
        kitLotId?: number | null;
    };
}

export default function PricingHistoryModal({ open, onClose, pricingInfo = {} }: PricingModalPropsType) {
    const { type, modelNo, manufacturer, id: bodId = 0, discount = null, discountedCost = null, sellPrice = null, margin = null, qty = null, extendedPrice = null, lotId, kitLotId } = pricingInfo;
    const isLotPresent = Boolean(lotId) || Boolean(kitLotId);
    const productTitle = useMemo(() => [type, manufacturer, modelNo].filter((string) => string).join(' - '), [type, modelNo, manufacturer]);
    const showPricingExistsWarning = Boolean(pricingInfo.cost || pricingInfo.discountedCost || pricingInfo.sellPrice || pricingInfo.margin);
    const { bomId = '0' } = useParams();

    const [selectedRow, setSelectedRow] = useState<PricingHistoryTableDataType[] | null>(null);

    const { mutateAsync: updatePricingData, isLoading: isPricingMutationLoading } = usePricingBodPatchMutation();

    function handleSelectionChange(selectedRow: PricingHistoryTableDataType[]) {
        setSelectedRow(selectedRow);
    }

    async function handleUpdatePricingInfo(infoToUpdate: { cost: number | null; discount: number | null; discountedCost: number | null; margin: number | null; sellPrice: number | null }) {
        const apiReqData = getApiDataForPricingInfo(infoToUpdate);
        await updatePricingData({ bomId: Number(bomId), pricingBod: apiReqData, bodId: bodId });
        onClose(true);
    }

    function handleReuseCost() {
        if (!selectedRow) {
            return;
        }
        const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange({ cost: selectedRow?.[0].cost, discount, discountedCost, sellPrice, margin, qty, extendedPrice }, PRICING_TABLE_ENUM.COST);
        handleUpdatePricingInfo(pricingRowInfoToUpdate);
    }

    function handleReuseDiscount() {
        if (!selectedRow) {
            return;
        }
        const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange(
            { cost: selectedRow?.[0].cost, discount, discountedCost: selectedRow?.[0].discountedCost, sellPrice, margin: margin ?? 0, qty, extendedPrice },
            PRICING_TABLE_ENUM.DISCOUNT_COST
        );
        handleUpdatePricingInfo(pricingRowInfoToUpdate);
    }

    function handleReuseAll() {
        if (!selectedRow) {
            return;
        }
        handleUpdatePricingInfo({
            cost: selectedRow[0].cost,
            discount: selectedRow[0].discount,
            discountedCost: selectedRow[0].discountedCost,
            sellPrice: selectedRow[0].sellPrice,
            margin: selectedRow[0].margin
        });
    }

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Pricing History" onClose={onClose} />}
            footer={
                <ModalFooter
                    isLoading={isPricingMutationLoading}
                    isRowSelected={Array.isArray(selectedRow) && selectedRow.length > 0}
                    onReject={onClose}
                    onReuseCost={handleReuseCost}
                    onReuseCostDiscount={handleReuseDiscount}
                    onReuseAll={handleReuseAll}
                    showPricingExistsWarning={showPricingExistsWarning}
                    isLotPresent={isLotPresent}
                />
            }
        >
            <Box height="calc(83vh - 144px)" width="calc(97vw - 48px)" className="ag-theme-alpine" gap={6} display="flex" flexDirection="column">
                <Box>
                    <BodySmall color="secondary.light">These are the historical prices associated to this product, or products similar to this one. Use the search bar to refine the search.</BodySmall>
                </Box>
                <Box>
                    {productTitle && (
                        <BodyBig color="secondary.light" fontWeight="500">
                            {productTitle}
                        </BodyBig>
                    )}
                </Box>
                <Box flex={1} minHeight={0}>
                    <PricingHistoryBody modelNo={modelNo} manufacturer={manufacturer} onSelectionChange={handleSelectionChange} />
                </Box>
            </Box>
        </Modal>
    );
}
