import { Box, Button, Skeleton } from '@parspec/pixel';

interface TableRightSectionProps {
    isTableLoading: boolean;
    onManageColumnsClick: () => void;
    onExportClick: () => void;
}

export const TableRightSection = ({ isTableLoading, onManageColumnsClick, onExportClick }: TableRightSectionProps) => {
    return (
        <Box display="flex" gap={2} alignItems="flex-end" flexWrap="nowrap" minWidth="max-content">
            <Box>
                {isTableLoading ? (
                    <Skeleton variant="rectangular" width={'147px'} height={'38px'} />
                ) : (
                    <Button onClick={onManageColumnsClick} color="secondary" variant="outlined">
                        Manage Columns
                    </Button>
                )}
            </Box>
            <Box>
                {isTableLoading ? (
                    <Skeleton variant="rectangular" width={'76px'} height={'38px'} />
                ) : (
                    <Button onClick={onExportClick} color="secondary" variant="outlined">
                        Export
                    </Button>
                )}
            </Box>
        </Box>
    );
};
