import { useEffect, useState } from 'react';

import { Box, Button } from '@parspec/pixel';

import { useAddManufacturerMutation, useManufacturerTermsAndConditionsQuery } from '../../queries';
import { StoredManufacturerPairResponse } from '../../queries/apiTypes';
import Manufacturer from './Manufacturer';
import { useQuoteContext } from '../../QuoteContext';
import ApplyToAllBomsButton from '../../../shared/ApplyToAllBomsButton';
import { MANUFACTURER_SECTION_NAME, MANUFACTURER_SUB_SECTION_NAME } from '../../shared/constants';

const mfgTncSection = 'manufacturer_tnc_section';

function ManufacturerTermsAndCondition() {
    const { isInitiateApiCalls, bomId, handleUpdateChangedSections } = useQuoteContext();

    const [allManufacturerNames, setAllManufacturerNames] = useState<string[]>([]);
    const [counter, setCounter] = useState(0);
    const { data: mfgTncData } = useManufacturerTermsAndConditionsQuery(bomId, isInitiateApiCalls);
    const { mutateAsync: addManufacturer, isLoading: addLoading } = useAddManufacturerMutation();

    const [manufacturersList, setManufacturersList] = useState<StoredManufacturerPairResponse[]>([]);

    useEffect(() => {
        if (mfgTncData?.data) {
            const names: string[] = [];
            const list: StoredManufacturerPairResponse[] = [];
            mfgTncData.data.forEach((mfg: StoredManufacturerPairResponse) => {
                if (mfg.field_name !== mfgTncSection) {
                    names.push(mfg.field_name.toLowerCase());
                    list.push(mfg);
                }
            });
            setManufacturersList(list);
            setCounter(list.length + 1);
            setAllManufacturerNames(names);
        }
    }, [mfgTncData]);

    const pushChangedFields = () => {
        handleUpdateChangedSections?.({ sectionName: MANUFACTURER_SECTION_NAME, subSectionName: MANUFACTURER_SUB_SECTION_NAME, value: true });
    };

    const onAddManufacturer = async () => {
        let updatedCounter = counter;
        while (allManufacturerNames.includes('manufacturer ' + updatedCounter)) {
            updatedCounter = updatedCounter + 1;
        }
        await addManufacturer({
            bomId: bomId,
            payload: {
                field_name: 'Manufacturer ' + updatedCounter,
                field_value: null
            }
        });
        setCounter(updatedCounter + 1);
        pushChangedFields();
    };

    return (
        <>
            <Box mt={4} mb={2}>
                <ApplyToAllBomsButton sectionName={MANUFACTURER_SECTION_NAME} subSectionName={MANUFACTURER_SUB_SECTION_NAME} />
            </Box>
            <Box mb={6}>
                {manufacturersList.map((manufacturer: StoredManufacturerPairResponse) => (
                    <Manufacturer data={manufacturer} key={manufacturer.id} allManufacturers={manufacturersList} pushChangedFields={pushChangedFields} />
                ))}
            </Box>
            <Button onClick={onAddManufacturer} color="secondary" variant="outlined" isLoading={addLoading}>
                Add Manufacturer
            </Button>
        </>
    );
}

export default ManufacturerTermsAndCondition;
