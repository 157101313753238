import { axios } from 'src/app/Axios';

import { PutTaxPricingReqInfoType, TaxesPricingRequestOrResponseType } from './apiTypes';

export const endPoints = {
    getTaxPricingUrl: (bomId: number) => `/bom/${bomId}/pricing/tax/`
};

export function getTaxPricing(bomId: number) {
    return axios.get<TaxesPricingRequestOrResponseType>(endPoints.getTaxPricingUrl(bomId));
}

export function putTaxPricing({ bomId, reqBody }: PutTaxPricingReqInfoType) {
    return axios.put<TaxesPricingRequestOrResponseType>(endPoints.getTaxPricingUrl(bomId), reqBody);
}
