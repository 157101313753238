import { useContext } from 'react';

import { Box, Menu } from '@parspec/pixel';

import { ICellRendererParams } from 'ag-grid-community';

import { useLotMap } from './hooks';
import { MenuOptionsContext } from './context';

export function MenuOptions(props: ICellRendererParams) {
    const {
        isOptionTable,
        noOfOptionSections = 0,
        bomId = '0',
        onAddBelow,
        onClickMoveSection,
        onDuplicate,
        onHide,
        onClickDelete,
        handleAddOption,
        approveOption,
        onProductMoveIn,
        onHighlight
    } = useContext(MenuOptionsContext) || {};
    const lotMap = useLotMap(Number(bomId));

    const { id, category, manufacturer, model_number, modelNo, product_to_replace, replaceProductId, is_hidden, is_kit, type, is_highlighted } = props.data || {};

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    const options: Array<{ label: string; onClick: () => any; color?: string; disabled?: boolean }> = [
        { label: isOptionTable ? 'Add Option Below' : 'Add Product Below', onClick: () => onAddBelow?.(props) },
        { label: 'Change Section', onClick: () => onClickMoveSection?.(props) },
        {
            label: is_hidden ? 'Unhide' : 'Hide',
            onClick: () => onHide?.(props)
        },
        { label: 'Duplicate', onClick: () => onDuplicate?.(props) },
        { label: 'Delete', onClick: () => onClickDelete?.(props), color: 'error.main' }
    ];

    if (!is_kit) {
        onProductMoveIn && options.unshift({ label: 'Move to kit', onClick: () => onProductMoveIn(id) });
        onHighlight && options.unshift({ label: is_highlighted ? ' Unhighlight' : 'Highlight', onClick: () => onHighlight([props?.data?.id]) });
    }
    if (!isOptionTable) {
        const toDisableAddOption = Boolean(lotMap[id]) || !(category || manufacturer || model_number || modelNo || type) || noOfOptionSections < 1;
        options.unshift({ label: 'Add Option', onClick: () => handleAddOption?.(props), disabled: toDisableAddOption });
    } else if (isOptionTable) {
        options.splice(2, 0, { label: 'Approve Option', onClick: () => approveOption?.(id), disabled: !(product_to_replace || replaceProductId) });
    }

    return (
        <Box display="flex" justifyContent="center">
            <Menu options={options} />
        </Box>
    );
}

export function KitMenuOptions(props: ICellRendererParams) {
    const { onAddBelow, onDuplicate, onHide, onClickDelete, onProductMoveOut } = useContext(MenuOptionsContext) || {};

    const { is_hidden } = props.data || {};

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    const options = [
        { label: 'Remove from kit', onClick: () => (onProductMoveOut ? onProductMoveOut(props) : null) },
        { label: 'Add Product Below', onClick: () => (onAddBelow ? onAddBelow(props) : null) },
        { label: 'Duplicate', onClick: () => (onDuplicate ? onDuplicate(props) : null) },
        {
            label: is_hidden ? 'Unhide' : 'Hide',
            onClick: () => (onHide ? onHide(props) : null)
        },
        { label: 'Delete', onClick: () => (onClickDelete ? onClickDelete(props) : null), color: 'error.main' }
    ];
    return (
        <Box display="flex" justifyContent="center">
            <Menu options={options} />
        </Box>
    );
}
