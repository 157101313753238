import { Grid, BodyMedium, DeleteIcon, Box, IconButton } from '@parspec/pixel';

import { CustomUploadProps } from '../queries/apiTypes';

const FileUploaded = ({ customUpload, onClick, text }: { customUpload: CustomUploadProps | null; onClick: () => void; text: string }) => {
    return (
        <Grid container direction={'column'} rowSpacing={1}>
            <Grid item pr={2} pl={4} pt={2}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <BodyMedium mt={2} limit={true} color="secondary.main">
                            {customUpload?.name}
                        </BodyMedium>
                    </Grid>

                    <Grid onClick={onClick} item pr={4}>
                        <IconButton>
                            <DeleteIcon
                                sx={{
                                    cursor: 'pointer'
                                }}
                                fontSize={'large'}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            {customUpload?.size && (
                <Grid item pl={4}>
                    {customUpload?.size} kb
                </Grid>
            )}

            <Grid item p={2}>
                <Box width={'100%'} bgcolor={'neutral.light'} p={2}>
                    <BodyMedium lines={2} color={'primary.main'}>
                        {text}
                    </BodyMedium>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FileUploaded;
