import { BodyBig, BodySmall, Box, Switch } from '@parspec/pixel';

import { QuoteTemplateInterface } from '../../../queries/apiTypes';

interface ManufacturerTermsAndConditionsProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: QuoteTemplateInterface;
}

export const ManufacturerTermsAndConditions = (props: ManufacturerTermsAndConditionsProps) => {
    const { changeHandler, templateState } = props;

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.quote_manufacturer_tnc?.manufacturer_tnc_section}
                    onChange={(e) => changeHandler(e, 'quote_manufacturer_tnc')}
                    name="manufacturer_tnc_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Manufacturer Terms & Conditions
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose whether to display the manufacturer specific terms & conditions on your quotes by default.
            </BodySmall>
            <BodySmall limit={false} color="text.secondary">
                You can edit these details when customizing your quote.
            </BodySmall>
        </Box>
    );
};
