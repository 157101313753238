import { ReactNode, useState, useEffect } from 'react';

import { BodySmall, Box, TextField, Tooltip } from '@parspec/pixel';

interface TextEditorProps {
    inputText: string;
    children: ReactNode;
    onSave: (text: string, callBack?: (isError?: boolean) => void) => void;
    isError?: boolean;
    onErrorUpdate?: () => void;
    maxLength?: number;
}

const TextEditor = (props: TextEditorProps) => {
    const { children, inputText, onSave, isError = false, onErrorUpdate = () => {}, maxLength = 255 } = props;

    const [editTitle, setEditTitle] = useState(false);
    const [isCursorOnText, setIsCursorText] = useState(false);
    const [text, setText] = useState(inputText);

    useEffect(() => {
        setText(inputText);
    }, [inputText]);

    const toggleIsCursorOnTextTrue = () => setIsCursorText(true);
    const toggleIsCursorOnTextFalse = () => setIsCursorText(false);

    const toggleEditTitle = (e?: React.MouseEvent<HTMLDivElement>) => {
        if (e) e?.stopPropagation();
        setEditTitle(!editTitle);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
        onErrorUpdate()
    };

    const callBackAfterSubmit = (isError?: boolean) => {
        toggleEditTitle();
        if(isError){
            setText(inputText);
        }else{
            setText(text);
        }

    };

    const onSaveText = () => {
        toggleIsCursorOnTextFalse();
        if (text.trim() && text.trim() !== inputText) {
            onSave(text, callBackAfterSubmit);
        } else if(isError){
            return;
        } else {
            callBackAfterSubmit();
        }
    };

    const onPressEnter = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            onSaveText();
        }
    };

    const duplicateNameError = (
        <>
        {isError && (
            <BodySmall color={'error.main'}>Manufacturer Name needs to be unique.</BodySmall>
        )}
        </>
    )
    return (
        <>
            {!editTitle ? (
                <Tooltip title={'Rename'} placement="top">
                    <Box
                        sx={{ cursor: isCursorOnText ? 'text' : 'default' }}
                        border={'1px solid'}
                        borderColor={isCursorOnText ? 'primary' : 'white'}
                        boxSizing="border-box"
                        px={2}
                        onMouseEnter={toggleIsCursorOnTextTrue}
                        onMouseLeave={toggleIsCursorOnTextFalse}
                        onClick={toggleEditTitle}
                    >
                        {children}
                    </Box>
                </Tooltip>
            ) : (
                <Box onClick={(e) => e.stopPropagation()} width="500px">
                    <TextField 
                        inputProps={{
                            maxLength: maxLength
                        }}
                        autoFocus 
                        error={isError} 
                        helperText={duplicateNameError}
                        onKeyDown={onPressEnter} 
                        value={text || ''} 
                        onChange={handleTitleChange} 
                        size="small" 
                        label="" 
                        onBlur={onSaveText} 
                        fullWidth />
                </Box>
            )}
        </>
    );
};

export default TextEditor;
