import { Select, SelectChangeEvent } from '@parspec/pixel';
import { useEffect, useMemo, useState } from 'react';
import { useGetLotDetailsQuery } from '../lotPriceModal/queries';
import { useParams } from 'react-router-dom';

interface LotSelectProps {
    value: string;
    onChange: (event: SelectChangeEvent<unknown>, prevLotId?: string) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    fieldName?: string;
    isBulkEdit?: boolean;
    disabled?: boolean;
    size?: 'xs' | 'small' | 'medium';
}

export default function LotSelect({ value: lotVal, onChange: handleChange, onFocus: handleFocus, isBulkEdit = false, fieldName = '', disabled = false }: LotSelectProps) {
    const { bomId = '0' } = useParams();
    const [value, setValue] = useState<string | null>(null);

    const { data: lotsData } = useGetLotDetailsQuery(bomId, { enabled: Boolean(bomId) });
    const lotOptions = useMemo(() => {
        if (!lotsData?.data) {
            return [];
        }

        const lotOptions = lotsData?.data.filter((lotData) => lotData.id).map((lotData) => ({ label: lotData.name, value: lotData.id }));

        if (isBulkEdit) return [{ label: '-', value: 0 }, ...lotOptions];

        return [{ label: '-', value: '-' }, { label: 'Manage Lots', value: 'new', color: 'primary.main' }, ...lotOptions];
    }, [lotsData?.data]);

    useEffect(() => {
        setValue(lotVal);
    }, [lotVal]);

    function onChange(event: SelectChangeEvent<unknown>) {
        const currLotValue = event.target.value as string;
        if (currLotValue !== 'new') {
            setValue(currLotValue);
        }
        handleChange(event, value || '');
    }
    return <Select value={isBulkEdit ? value : value || '-'} name={fieldName} onChange={onChange} options={lotOptions} onFocus={handleFocus} label={isBulkEdit ? 'Lot' : ''} disabled={disabled} />;
}
