import { Grid, Switch, BodySmall, TextField, Select, SelectChangeEvent } from '@parspec/pixel';

import BasicTextField from './BasicTextField';
import BasicAddress from './BasicAddress';
import { ISection } from '../constants';
import Title from './Title';
import { OPTIONS_FOR_ADDRESSED_TO } from './utils/constants';
import ApplyToAllBomsButton from './ApplyToAllBomsButton';

interface ISectionProp {
    isChecked?: boolean;
    onSwitchToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    switchLabel: string;
    onTextChange: (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => void;
    onBlur: () => void;
    sectionState: Partial<ISection>;
    isEmailInvalid: boolean;
    children?: React.ReactNode;
    onFocus?: () => void;
    showAddressToPrefill?: boolean;
    prefillValue?: string;
    onSelect?: (event: SelectChangeEvent<unknown>) => void;
    readOnly?: boolean;
    sectionName?: string;
    subSectionName?: string;
}

function Section({
    isChecked,
    onSwitchToggle,
    switchLabel,
    sectionState,
    onTextChange,
    onBlur,
    isEmailInvalid,
    children,
    onFocus,
    showAddressToPrefill = false,
    prefillValue,
    onSelect,
    readOnly = false,
    sectionName,
    subSectionName
}: ISectionProp) {
    return (
        <Grid container direction={'column'}>
            <Grid container item mb={3} columnSpacing={3} alignItems="center">
                <Grid item>
                    <Switch checked={isChecked} onChange={onSwitchToggle} label={<Title title={switchLabel} />} />
                </Grid>
                {showAddressToPrefill && (
                    <Grid item width={230}>
                        <Select
                            name={'addressed_to'}
                            label={'Pre-fill Format'}
                            options={OPTIONS_FOR_ADDRESSED_TO}
                            optionLabelKeyname="name"
                            optionValueKeyname="id"
                            onChange={onSelect}
                            value={prefillValue}
                        />
                    </Grid>
                )}
                <Grid item ml={showAddressToPrefill ? 0 : -3}>
                    <ApplyToAllBomsButton sectionName={sectionName} subSectionName={subSectionName} />
                </Grid>
            </Grid>

            <Grid item>
                <Grid container direction={'column'} rowSpacing={2}>
                    <Grid item>
                        <BasicTextField
                            disabled={readOnly}
                            label="Company"
                            placeholder={'Company Name'}
                            textValue={sectionState.company_name}
                            action={onTextChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            maxLength={64}
                            fieldName="company_name"
                        />
                    </Grid>

                    <Grid item>
                        <Grid container>
                            <Grid item xs={4}>
                                <BodySmall>{'Name'}</BodySmall>
                            </Grid>

                            <Grid item xs={8}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            disabled={readOnly}
                                            label=""
                                            placeholder="First Name"
                                            name="first_name"
                                            color="secondary"
                                            value={sectionState?.first_name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onTextChange(e)}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            inputProps={{
                                                maxLength: 32
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            disabled={readOnly}
                                            label=""
                                            placeholder="Last Name"
                                            color="secondary"
                                            name="last_name"
                                            value={sectionState?.last_name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onTextChange(e)}
                                            onBlur={onBlur}
                                            onFocus={onFocus}
                                            inputProps={{
                                                maxLength: 32
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <BasicTextField
                            disabled={readOnly}
                            label="Email"
                            placeholder={'Email'}
                            textValue={sectionState?.email}
                            fieldName="email"
                            action={onTextChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            maxLength={64}
                            isError={isEmailInvalid}
                        />
                    </Grid>

                    <Grid item>
                        <BasicTextField
                            disabled={readOnly}
                            label="Phone"
                            placeholder={'Phone Number'}
                            textValue={sectionState?.phone}
                            action={onTextChange}
                            onBlur={onBlur}
                            maxLength={32}
                            fieldName="phone"
                            onFocus={onFocus}
                        />
                    </Grid>

                    <Grid item>
                        <BasicAddress disabled={readOnly} label="Address" address={sectionState} action={onTextChange} onBlur={onBlur} onFocus={onFocus} />
                    </Grid>

                    {children ? children : null}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Section;
