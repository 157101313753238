import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { BodyMedium, Box, FileSelector, FileSelectorFileType, Modal, ModalFooter, ModalHeader, Snackbar } from '@parspec/pixel';

import { environment } from 'src/environments';
import { useUpdateAddFilesForProject } from '../queries';
import { BulkUploadFilesRequest, UploadedProjectFilesResponse } from '../queries/apiTypes';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { SnackbarMessages } from '../../BOM/FinalDocuments/constants';
import { PROJECT_FILES_ALLOWED_TYPES, PROJECT_FILES_TYPE_STRING } from '../../shared/constants';
interface IUploadProjectFilesModalProps {
    openModal: boolean;
    closeProjectFilesModal: () => void;
    uploadedFileData: UploadedProjectFilesResponse[];
}

const ERROR_STRING = 'Uploading this file would exceed the project storage capacity of 1 GB. Please delete existing uploads, or upload a smaller file.';
const ProjectFilesUploadModal = (props: IUploadProjectFilesModalProps) => {
    const { projectId } = useParams();
    const [fileArray, setFileArray] = useState<BulkUploadFilesRequest[]>();
    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();
    const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
    const [error, setError] = useState('');
    const { openModal, closeProjectFilesModal, uploadedFileData } = props;
    const { mutateAsync, isLoading } = useUpdateAddFilesForProject();

    const fileSize = useMemo(() => {
        let size = 0;
        uploadedFileData.forEach((val) => {
            size += val.file_size;
        });
        return size;
    }, [uploadedFileData]);

    const handleSelect = (files: FileSelectorFileType[]) => {
        setError('');
        const result = [];
        setIsFileUploadInProgress(true);
        for (let i = 0; i < files.length; i++) {
            const lastIndexofDot = files[i].name.lastIndexOf('.');
            const fileName = files[i].name.substring(0, lastIndexofDot);
            const fileType = files[i].name.substring(lastIndexofDot + 1);
            const name = fileName;
            const type = fileType;
            const obj = {
                s3_url: '',
                filetype: type,
                filename: name,
                file_size: files[i].size
            };
            result.push(obj);
        }
        setFileArray(result as BulkUploadFilesRequest[]);
    };
    const handleUpload = (files: any) => {
        setIsFileUploadInProgress(false);
        setFileArray((prev) => prev?.map((val, i) => ({ ...val, s3_url: environment.j + files[i].s3_file_path })));
    };

    const handleConfirmClick = async () => {
        if (!fileArray?.length) return;
        try {
            await mutateAsync({ projectId: Number(projectId), payload: fileArray || [] });
            closeProjectFilesModal();
            setSnackbarOpen(SnackbarMessages.UPLOAD_SUCCESS);
        } catch (err: any) {
            setError(err.response.data.error);
        }
    };

    const header = <ModalHeader title="Upload File(s)" onClose={closeProjectFilesModal} />;
    const footer = (
        <ModalFooter onReject={closeProjectFilesModal} onAccept={handleConfirmClick} continueButtonLabel="Confirm" cancelButtonLabel="Cancel" isLoading={isLoading} disabled={isFileUploadInProgress} />
    );

    return (
        <Box>
            <Modal header={header} footer={footer} open={openModal}>
                <form>
                    <Box>
                        <Box width="450px" overflow="auto" maxHeight={400}>
                            <BodyMedium fontWeight={500} marginBottom={4}>
                                File Uploader
                            </BodyMedium>

                            <FileSelector
                                url={`${environment.b}/api/generate_signed_url/?source=project_doc&id=${projectId}`}
                                onSelect={handleSelect}
                                onUpload={handleUpload}
                                acceptedFormats={PROJECT_FILES_ALLOWED_TYPES || []}
                                helperText={PROJECT_FILES_TYPE_STRING}
                                placeholder="Drag and drop a file here, or:"
                                maxFiles={Infinity}
                                showUploaderAlways={true}
                                error={error}
                                maxTotalFileSizeAllowed={{ size_in_bytes: 1073741824 - fileSize, errorText: ERROR_STRING }}
                            />
                        </Box>
                    </Box>
                </form>
            </Modal>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
        </Box>
    );
};

export default ProjectFilesUploadModal;
