import { useMemo, useState, useRef } from 'react';

import { ICellRendererParams, ValueGetterParams, ColDef } from 'ag-grid-community';
import { isEqual } from 'lodash-es';

import { BodySmall, Box, Snackbar, TableHeaderMenuIcon, AgGridTable, SelectChangeEvent } from '@parspec/pixel';

import {
    getBomRowData,
    getStageTemplate,
    getSubmittalViewTemplate,
    getQuoteViewTemplate,
    getOMViewTemplate,
    getSyncCache,
    getCustomerContactTemplate,
    getCustomerContactValue,
    getLastModifiedDate,
    getLastModifiedValue,
    getCustomerFilterText,
    stringComparator,
    getDateComparator,
    getDateValueForUI,
    numberComparator
} from '../shared/utils/utils';
import {
    useDeleteBomMutation,
    useDuplicateBomDataMutation,
    useGetBOMQuery,
    useOutOfSyncQuery,
    userDashboardViewMutation,
    useGetSelectedColumnsAndPersistingFiltersQuery,
    useUpdateSelectedColumnsMutation,
    useUpdatePersistingFiltersMutation
} from '../queries';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { BOM_DELETE_CONFIRMATION_TITLE, OM_DOC, SUBMITTAL_DOC, deleteMsg, duplicateMsg, someThingWentWrongMsg, BOM_DELETE_HEADING, BOM_DELETE_SUB_HEADING, QUOTE, DocumentStatusEnum } from '../../shared/constants';
import { TAB_LABEL } from '../shared/constants';
import { BomTablesDataType, SyncTypeForTable, dateFilterParams } from '../shared/utils';
import { BomInfoForDashboard } from '../queries/apiTypes';
import { SubsriptionPopup } from 'src/app/ProtectedRoute';
import { useCheckAccessForProductFinderOrQuoting } from '../../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useDocumentTags } from '../queries';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import { useGetUserDashboardViews } from '../shared/hooks';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { MenuOption, ViewDocumentsTemplate, defaultColDef } from './components';
import { MenuContext } from './context';
import { getRowId } from './utils';
import TableRightSection from '../shared/components/TableRightSection';
import { ManageColumnsPopup } from '../shared/components/ManageColumnsPopup/ManageColumnsPopup';
import { bomPageTableColumnOptions } from './utils';
import { DashboardPageNameType } from '../queries/apiTypes';
import { BomTemplate, ProjectTemplate, DateTemplate } from '../shared/components';
import { getEstimatedValueTemplate, dollarFormatter, grandTotalValueTemplate } from '../ProjectsTable/utils';

const defaultLockColumnDef: ColDef[] = [
    {
        field: 'bomId',
        hide: true,
        lockPosition: 'left',
        resizable: false
    },
    {
        minWidth: 150,
        cellRenderer: ViewDocumentsTemplate
    },
    {
        minWidth: 60,
        maxWidth: 60,
        headerComponent: () => (
            <Box mt={1}>
                <TableHeaderMenuIcon color="secondary" fontSize="medium" />
            </Box>
        ),
        cellStyle: { backgroundColor: 'unset' },
        cellRenderer: MenuOption,
        editable: false,
        pinned: 'right',
        colId: 'menu',
        resizable: false,
        lockPosition: 'right',
        lockPinned: true
    }
];

const BOMTable = () => {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openManageColumnsPopup, setOpenManageColumnsPopup] = useState<boolean>(false);

    const { isDashboardViewsLoading, dashboardSelectedViews } = useGetUserDashboardViews();

    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();
    const { data: paymentDetail, isLoading: isPaymentDetailLoading } = useSubscriptionInfo();
    const isActiveSubscription = paymentDetail?.data?.active;

    const { data: bomData, isLoading } = useGetBOMQuery(dashboardSelectedViews, { enabled: !isDashboardViewsLoading });

    const { data: submittalSyncData } = useOutOfSyncQuery(SUBMITTAL_DOC);
    const { data: omSyncData } = useOutOfSyncQuery(OM_DOC);
    const { data: quoteSyncData } = useOutOfSyncQuery(QUOTE);
    const { data: submittalTagsData } = useDocumentTags(SUBMITTAL_DOC);
    const { data: omTagsData } = useDocumentTags(OM_DOC);
    const { data: quoteTagsData } = useDocumentTags(QUOTE);

    const { data: selectedColumnsAndFiltersQueryData, isFetching: isSelectedColumnsAndFiltersQueryDataFetching } = useGetSelectedColumnsAndPersistingFiltersQuery(
        { userId: userData?.data?.id || 0, pageName: 'dashboard_bom' },
        { enabled: !isDashboardViewsLoading && !isUserDataLoading }
    );

    const { mutateAsync: duplicateBom, isLoading: isDuplicateInProgress } = useDuplicateBomDataMutation();
    const { mutateAsync: deleteBom, isLoading: deleteLoading } = useDeleteBomMutation();
    const { mutateAsync: updateSelectedColumnsMutation, isLoading: isUpdateSelectedColumnsMutationLoading } = useUpdateSelectedColumnsMutation();
    const { mutateAsync: updateSelectedFiltersMutation } = useUpdatePersistingFiltersMutation();

    const tableRef = useRef<any>();

    const handleSubscriptionPopup = () => setShowSubscriptionPopup((old) => !old);

    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess, isLoading: isUserLevelQuotingAccessCheckLoading } = useCheckAccessForProductFinderOrQuoting();

    const onDeleteLineItem = async () => {
        try {
            await deleteBom(deleteId!);
            setSnackbarMessage(deleteMsg);
        } catch (error) {
            setSnackbarMessage(someThingWentWrongMsg);
        }
        setDeleteId(null);
        closeConfirmationDialog();
    };

    const onClickDelete = (props: any) => {
        if (isActiveSubscription) {
            setDeleteId(props.bomId);
            setOpenConfirmationDialog(true);
        } else handleSubscriptionPopup();
    };

    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const onDuplicate = async (props: any) => {
        if (isActiveSubscription) {
            try {
                await duplicateBom({ bomId: props?.bomId, projectId: props?.projectId });
                setSnackbarMessage(duplicateMsg);
            } catch (error) {
                setSnackbarMessage(someThingWentWrongMsg);
            }
        } else handleSubscriptionPopup();
    };

    const submittalOutOfSyncCache = useMemo(() => {
        if (submittalSyncData?.data) return getSyncCache(submittalSyncData.data);
        return null;
    }, [submittalSyncData]);

    const oMOutOfSyncCache = useMemo(() => {
        if (omSyncData?.data) return getSyncCache(omSyncData.data);
        return null;
    }, [omSyncData]);

    const quoteOutOfSyncCache = useMemo(() => {
        if (quoteSyncData?.data) return getSyncCache(quoteSyncData.data);
        return null;
    }, [quoteSyncData]);

    const bomTableData = useMemo(() => {
        if (!bomData?.data) {
            return null;
        }
        const bomTableDataWithSync = bomData?.data.map((data) => {
            const bomId = data?.bom?.id;
            const currentBomData: BomInfoForDashboard & SyncTypeForTable = { ...data, quoteStatus: '', omStatus: '', submittalStatus: '' };
            currentBomData.submittalOutOfSync = bomId && submittalOutOfSyncCache ? submittalOutOfSyncCache?.[bomId] || false : false;
            currentBomData.omOutOfSync = bomId && oMOutOfSyncCache ? oMOutOfSyncCache?.[bomId] || false : false;
            currentBomData.quoteOutOfSync = bomId && quoteOutOfSyncCache ? quoteOutOfSyncCache?.[bomId] || false : false;

            if (currentBomData?.quote && quoteTagsData?.data) {
                for (const quote of currentBomData.quote) {
                    for (const quoteTag of quoteTagsData.data) {
                        if (bomId === quoteTag.bom_id && quote.id === quoteTag.entity_id && quoteTag.entity_type === QUOTE) {
                            currentBomData.quoteStatus = quoteTag.tag_name as string;
                        }
                    }
                }
            }

            if (currentBomData?.submittal && submittalTagsData?.data) {
                for (const submittal of currentBomData.submittal) {
                    for (const submittalTag of submittalTagsData.data) {
                        if (bomId === submittalTag.bom_id && submittal.id === submittalTag.entity_id && submittalTag.entity_type === SUBMITTAL_DOC) {
                            currentBomData.submittalStatus = submittalTag.tag_name as string;
                        }
                    }
                }
            }

            if (currentBomData?.o_m && omTagsData?.data) {
                for (const om of currentBomData.o_m) {
                    for (const omTag of omTagsData.data) {
                        if (bomId === omTag.bom_id && om.id === omTag.entity_id && omTag.entity_type === OM_DOC) {
                            currentBomData.omStatus = omTag.tag_name as string;
                        }
                    }
                }
            }

            return currentBomData;
        });

        return bomTableDataWithSync;
    }, [bomData?.data, submittalOutOfSyncCache, oMOutOfSyncCache, quoteOutOfSyncCache, submittalTagsData?.data, omTagsData?.data, quoteTagsData?.data]);

    const handleViewChange = (props: SelectChangeEvent<unknown>) => {
        if (userData?.data.id) userDashboardViewMutation(userData?.data?.id, props?.target?.value as string);
    };

    const defaultColumnDef: any = {
        bomName: {
            field: 'bomName',
            headerName: 'Bill of Materials',
            minWidth: 220,
            cellRenderer: (params: ICellRendererParams) => <BomTemplate {...(params?.data || {})} />,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        projectName: {
            field: 'projectName',
            headerName: 'Project',
            cellRenderer: (params: ICellRendererParams) => <ProjectTemplate {...(params?.data || {})} />,
            minWidth: 220,
            sortable: true,
            comparator: (valueA: string, valueB: string) => stringComparator(valueA, valueB)
        },
        primary_contact: {
            field: 'primary_contact',
            headerName: 'Primary Customer',
            cellRenderer: (props: ICellRendererParams) => getCustomerContactTemplate(props, 'primary_contact'),
            minWidth: 200,
            valueGetter: (params: ValueGetterParams) => getCustomerContactValue(params, 'primary_contact'),
            filter: 'agSetColumnFilter',
            suppressMenu: false,
            getQuickFilterText: (params: any) => getCustomerFilterText(params, 'primary_contact'),
            hide: !enable_quoting
        },
        secondary_contact: {
            field: 'secondary_contact',
            headerName: 'Secondary Customer',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => getCustomerContactTemplate(props, 'secondary_contact'),
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            valueGetter: (params: ValueGetterParams) => getCustomerContactValue(params, 'secondary_contact'),
            getQuickFilterText: (params: any) => getCustomerFilterText(params, 'secondary_contact'),
            hide: !enable_quoting
        },
        projectStage: {
            field: 'projectStage',
            headerName: 'Project Stage',
            minWidth: 200,
            cellRenderer: getStageTemplate,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        estimatedProjectCost: {
            field: 'estimatedProjectCost',
            headerName: 'Estimated Value',
            cellRenderer: getEstimatedValueTemplate,
            minWidth: 150,
            valueGetter: (params: ValueGetterParams) => dollarFormatter(params.data.estimatedProjectCost),
            comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
                const valA = Number(nodeA?.data?.estimatedProjectCost);
                const valB = Number(nodeB?.data?.estimatedProjectCost);

                return numberComparator(valA, valB);
            },
            sortable: true
        },
        grandTotalCents: {
            field: 'grandTotalCents',
            headerName: 'Grand Total',
            cellRenderer: grandTotalValueTemplate,
            minWidth: 150,
            valueGetter: (params: ValueGetterParams) => dollarFormatter(params.data.grandTotalCents),
            comparator: (_valueA: string, _valueB: string, nodeA: any, nodeB: any) => {
                const valA = Number(nodeA?.data?.grandTotalCents);
                const valB = Number(nodeB?.data?.grandTotalCents);

                return numberComparator(valA, valB);
            },
            sortable: true
        },
        branchLocation: {
            field: 'branchLocation',
            headerName: 'Branch Location',
            minWidth: 210,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        quoteDueDate: {
            field: 'quoteDueDate',
            headerName: 'Quote Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.quoteDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'quoteDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'quoteDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false,
            hide: !enable_quoting
        },
        submittalDueDate: {
            field: 'submittalDueDate',
            headerName: 'Submittal Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.submittalDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'submittalDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'submittalDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        omDueDate: {
            field: 'omDueDate',
            headerName: 'O&M Due Date',
            minWidth: 200,
            cellRenderer: (props: ICellRendererParams) => <DateTemplate dueDate={props?.data?.omDueDate} />,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'omDueDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'omDueDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        quoteOwner: {
            field: 'quoteOwner',
            headerName: 'Quote Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter',
            hide: !enable_quoting
        },
        submittalOwner: {
            field: 'submittalOwner',
            headerName: 'Submittal Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        omOwner: {
            field: 'omOwner',
            headerName: 'O&M Owner',
            minWidth: 200,
            suppressMenu: false,
            filter: 'agSetColumnFilter'
        },
        salesAgent: {
            field: 'salesAgent',
            headerName: 'Outside Sales',
            minWidth: 180,
            sortable: true,
            valueFormatter: (params: ICellRendererParams) => {
                if (!params.value) return '-';

                return params.value;
            }
        },
        lastModified: {
            fieldName: 'lastModified',
            headerName: 'Last Modified',
            minWidth: 200,
            cellRenderer: (params: ICellRendererParams) => getLastModifiedDate(params, 'BOM'),
            valueGetter: (params: ValueGetterParams) => getLastModifiedValue(params),
            sortable: true,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'lastEditedAt')
        },
        bomCreationDate: {
            field: 'bomCreationDate',
            headerName: 'Creation Date',
            minWidth: 200,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => getDateComparator(valueA, valueB, nodeA, nodeB, isDescending, 'bomCreationDate'),
            sortable: true,
            valueGetter: (params: ValueGetterParams) => getDateValueForUI(params, 'bomCreationDate'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            suppressMenu: false
        },
        quoteStatus: {
            field: 'quoteStatus',
            headerName: 'Quote Status',
            minWidth: 150,
            cellRenderer: getQuoteViewTemplate,
            valueGetter: (params: ValueGetterParams) => {
                if (!params.data.quoteData && !params.data.quoteStatus) {
                    return DocumentStatusEnum.CREATENEW
                } else {
                    const status = params.data?.quoteStatus || DocumentStatusEnum.IN_PROGRESS;
                    return status
                }
            },
            hide: !(enable_quoting && quotingAccess),
            filter: 'agSetColumnFilter',
            suppressMenu: false,
        },
        submittalStatus: {
            fieldName: 'submittalStatus',
            headerName: 'Submittal Status',
            minWidth: 150,
            cellRenderer: getSubmittalViewTemplate,
            valueGetter: (params: ValueGetterParams) => params.data.submittalStatus,
        },
        omStatus: {
            fieldName: 'omStatus',
            headerName: 'O&M Status',
            minWidth: 150,
            cellRenderer: getOMViewTemplate,
            valueGetter: (params: ValueGetterParams) => params.data.omStatus
        }
    };

    function handleExportExcel() {
        const params = {
            fileName: 'BOMs_Parspec_Export.xlsx', // Specify the filename
            sheetName: 'BOMs' // Specify the sheet name (page name)
        };
        tableRef.current.api.exportDataAsExcel(params);
    }

    const clearAllFilters = () => {
        tableRef.current.api.setFilterModel(null);
    };

    const handleSearch = (searchString: string) => {
        setSearchText(searchString);
    };

    const onManageColumnsClick = () => {
        setOpenManageColumnsPopup(true);
    };

    const onColumnSelection = async (rowData: string[]) => {
        const payload: { pageName: DashboardPageNameType; columnData: string[]; userId: number } = { pageName: 'dashboard_bom', columnData: rowData, userId: userData?.data?.id || 0 };
        await updateSelectedColumnsMutation(payload);

        setOpenManageColumnsPopup(() => false);
    };

    const bomDataForTable = useMemo(() => {
        let bomTableDataForUi: Array<BomTablesDataType & SyncTypeForTable> = [];

        if (!bomTableData) {
            return null;
        }

        if (!isLoading && bomTableData) {
            bomTableDataForUi = bomTableData.map(getBomRowData(TAB_LABEL.BOM_TAB_LABEL)) || [];
        }

        return bomTableDataForUi;
    }, [bomTableData, isLoading, isDuplicateInProgress, deleteLoading]);

    const columnDefs = useMemo<any>(() => {
        if (!selectedColumnsAndFiltersQueryData?.table_settings || isSelectedColumnsAndFiltersQueryDataFetching) {
            return;
        }

        if ((selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order || [])?.length === 0) {
            const cols = Object.values(defaultColumnDef);
            return [...(cols || []), ...defaultLockColumnDef];
        } else {
            const orderedColumns = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order?.reduce((result: ColDef[], field: string) => {
                // Check if field from eventService exists in defaultColumnDef
                if (Object.prototype.hasOwnProperty.call(defaultColumnDef, field)) {
                    const column = {
                        ...defaultColumnDef[field]
                    };

                    result.push(column);
                }

                return result;
            }, []);

            return [...(orderedColumns || []), ...defaultLockColumnDef];
        }
    }, [enable_quoting, quotingAccess, isSelectedColumnsAndFiltersQueryDataFetching, selectedColumnsAndFiltersQueryData?.table_settings, bomDataForTable]);

    const allColumnOptions = useMemo(() => {
        const fieldsRequiringFeatureFlagLevelQuotingAccess = ['primary_contact', 'secondary_contact', 'quoteDueDate', 'quoteOwner'];
        const fieldsRequiringBothUserAndFeatureFlagLevelQuotingAccess = ['quoteStatus'];

        if (!enable_quoting || !quotingAccess) {
            let resultArr: {
                field: string;
                label: string;
            }[] = [];

            if (!enable_quoting) {
                resultArr = bomPageTableColumnOptions.data.filter((item) => !fieldsRequiringFeatureFlagLevelQuotingAccess.includes(item.field));
            } else {
                resultArr = bomPageTableColumnOptions.data;
            }

            if (!quotingAccess) {
                resultArr = resultArr.filter((item) => !fieldsRequiringBothUserAndFeatureFlagLevelQuotingAccess.includes(item.field));
            }

            return resultArr;
        } else {
            return bomPageTableColumnOptions.data;
        }
    }, [enable_quoting, quotingAccess]);

    const onFirstDataRendered = (params: any) => {
        if (selectedColumnsAndFiltersQueryData?.table_settings.length === 0 || Object.keys(selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters || {}).length === 0) {
            return;
        }

        const response = selectedColumnsAndFiltersQueryData?.table_settings?.[0].filters;

        const filterKeys = Object.keys(response);
        filterKeys.forEach((key: string) => {
            params.api.getFilterInstance(key).setModel(response[key]);
        });

        params.api.onFilterChanged();
    };

    const handleFilterChanged = async (params: any) => {
        const { api } = params;
        const filterModel = api.getFilterModel();

        const apiResponse = selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.filters;

        if (!isEqual(filterModel, apiResponse)) {
            // call mutation
            const payload: { pageName: DashboardPageNameType; filterData: any; userId: number } = { pageName: 'dashboard_bom', filterData: filterModel, userId: userData?.data?.id || 0 };
            await updateSelectedFiltersMutation(payload);
        }
    };

    const isTableDataLoading =
        isLoading ||
        isPaymentDetailLoading ||
        isUserDataLoading ||
        isDuplicateInProgress ||
        isUserLevelQuotingAccessCheckLoading ||
        isDashboardViewsLoading ||
        isUpdateSelectedColumnsMutationLoading ||
        isSelectedColumnsAndFiltersQueryDataFetching;

    return (
        <>
            <Snackbar open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box zIndex={1} width={'100%'} height={'100%'} position={'relative'}>
                <MenuContext.Provider value={{ onDelete: onClickDelete, onDuplicate: onDuplicate, tabName: 'BOM' }}>
                    <AgGridTable
                        ref={tableRef}
                        getRowId={getRowId}
                        isLoading={isTableDataLoading}
                        rowHeight={52}
                        showToolbarPanel
                        toolBarPanelOptions={['search', 'clearFilters']}
                        onClearFilter={clearAllFilters}
                        toolbarRightSection={
                            <TableRightSection
                                selectedView={dashboardSelectedViews}
                                isMyViewLoading={isDashboardViewsLoading}
                                onMyViewChange={handleViewChange}
                                isTableLoading={isLoading}
                                onExportClick={handleExportExcel}
                                onManageColumnsClick={onManageColumnsClick}
                            />
                        }
                        rowData={isTableDataLoading ? null : bomDataForTable}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        pagination
                        paginationAutoPageSize
                        suppressContextMenu
                        suppressMenuHide
                        onTextSearch={handleSearch}
                        quickFilterText={searchText}
                        onFirstDataRendered={onFirstDataRendered}
                        onFilterChanged={handleFilterChanged}
                    />
                </MenuContext.Provider>
            </Box>

            {openConfirmationDialog && (
                <DeleteConfirmationDialog
                    title={BOM_DELETE_CONFIRMATION_TITLE}
                    open={openConfirmationDialog}
                    handleDialog={closeConfirmationDialog}
                    confirmAction={onDeleteLineItem}
                    loading={deleteLoading}
                >
                    <Box width="40vw" my={4}>
                        <BodySmall limit={false} fontWeight="600" color="neutral.dark">
                            {BOM_DELETE_HEADING}
                        </BodySmall>
                        <BodySmall limit={false} my={1} color="neutral.dark">
                            {BOM_DELETE_SUB_HEADING}
                        </BodySmall>
                    </Box>
                </DeleteConfirmationDialog>
            )}
            {showSubscriptionPopup && <SubsriptionPopup open={showSubscriptionPopup} onClose={handleSubscriptionPopup} />}
            {openManageColumnsPopup && (
                <ManageColumnsPopup
                    isOpen={openManageColumnsPopup}
                    setIsOpen={setOpenManageColumnsPopup}
                    allColumnOptions={allColumnOptions}
                    setSnackbarData={setSnackbarMessage}
                    onColumnSelection={onColumnSelection}
                    isSelectedColumnsMutationLoading={isUpdateSelectedColumnsMutationLoading}
                    selectedColumnsData={selectedColumnsAndFiltersQueryData?.table_settings?.[0]?.order}
                    isSelectedColumnsQueryDataFetching={isSelectedColumnsAndFiltersQueryDataFetching}
                />
            )}
        </>
    );
};

export default BOMTable;
