import { Skeleton } from '@parspec/pixel';

import { RowContainer } from '../RowContainer';

export default function ProductAttributeFormSkeleton() {
    return (
        <>
            {Array.from({ length: 9 }, (_v, i) => i).map((val, index) => (
                <RowContainer forSkeleton lastRow={index === 8}>
                    <Skeleton height="46.71px" width="100%" key={val} variant="rectangular" />
                </RowContainer>
            ))}
        </>
    );
}
