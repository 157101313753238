import { BodyBig, BodyMedium, BodyXS, BodySmall, Box, Grid, Switch, TextField, Select, TransitionsColorPicker, SelectChangeEvent } from '@parspec/pixel';

import { TEXT_STYLE_VALUES } from '../../utils/helper';
import { QuoteTemplateInterface } from '../../../queries/apiTypes';
import AttributePrefillTitle from '../../CommonSections/AttributePrefillTitle';
import { PRE_FILL_ADDRESSED_TO, QUOTE_COVER_PAGE, OPENING_NOTES_TEXT } from '../utils/constants';
import { OPTIONS_FOR_ADDRESSED_TO } from 'src/features/BOM/FinalDocuments/Compile/shared/utils/constants';

interface QuoteCoverPagePreferenceProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: QuoteTemplateInterface;
    handleColorAndFontTypeChange: (value: string, presetName: string, fieldName: string, fieldType: string) => void;
}

const STAKEHOLDERS_COLUMN_OPTIONS = [{ label: 'Stakeholders', value: 'stakeholders' }];

const PREPARER_ADDRESSEE_COLUMN_OPTIONS = [
    { label: 'Prepared By Information', value: 'prepared_by' },
    { label: 'Addressed To Information', value: 'addressed_to' }
];

export const QuoteCoverPagePreference = (props: QuoteCoverPagePreferenceProps) => {
    const { changeHandler, templateState, handleColorAndFontTypeChange } = props;

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.quote_cover_page?.cover_page_section}
                    onChange={(e) => changeHandler(e, 'quote_cover_page')}
                    name="cover_page_section"
                    label={
                        <BodyBig fontWeight={500} ml={2}>
                            Cover Page
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose what information you want to show on your Cover Page.
            </BodySmall>
            <BodySmall limit={false} color="text.secondary">
                You can edit these details when customizing your quote.
            </BodySmall>

            <Box mt={6}>
                <Box>
                    <Box position="relative" height={'100%'}>
                        <Box>
                            <BodyMedium color="secondary.light" fontWeight={500}>
                                Stakeholders
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {STAKEHOLDERS_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState?.quote_cover_page[option.value]}
                                                    onChange={(e) => changeHandler(e, 'quote_cover_page')}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />{' '}
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <BodyMedium color="secondary.light" fontWeight={500}>
                                Preparer & Addressee
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {PREPARER_ADDRESSEE_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState?.quote_cover_page[option.value]}
                                                    onChange={(e) => changeHandler(e, QUOTE_COVER_PAGE)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />{' '}
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box pt={4}>
                        <Box display={'flex'}>
                            <BodyMedium fontWeight={500} mr={4}>
                                Opening Notes
                            </BodyMedium>
                            <Box>
                                <Switch checked={templateState?.quote_cover_page?.opening_notes} onChange={(e) => changeHandler(e, QUOTE_COVER_PAGE)} name="opening_notes" label={''} />
                            </Box>
                        </Box>

                        <Box mt={2} display={'flex'} alignItems="flex-start" width="100%">
                            <Box width={'85%'}>
                                <TextField
                                    label="Enter Your Notes Here..."
                                    autoComplete="off"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, QUOTE_COVER_PAGE)}
                                    value={templateState?.quote_cover_page?.opening_notes_text.value}
                                    name={OPENING_NOTES_TEXT}
                                    multiline
                                    rows={6}
                                    inputProps={{ maxLength: 10000 }}
                                />
                            </Box>
                            <Box width={'15%'} ml={3}>
                                <Box>
                                    <Select
                                        label="Text"
                                        name={OPENING_NOTES_TEXT}
                                        onChange={(e: SelectChangeEvent<unknown>) => handleColorAndFontTypeChange(e.target.value as string, QUOTE_COVER_PAGE, OPENING_NOTES_TEXT, 'type')}
                                        optionLabelKeyname="label"
                                        optionValueKeyname="value"
                                        options={TEXT_STYLE_VALUES}
                                        value={templateState?.quote_cover_page?.opening_notes_text?.font?.type}
                                    />
                                </Box>
                                <Box mt={4}>
                                    <BodyXS fontWeight={500} color={'text.secondary'} mr={4}>
                                        Font Color
                                    </BodyXS>
                                    <Box mt={2}>
                                        <TransitionsColorPicker
                                            color={templateState?.quote_cover_page?.opening_notes_text?.font?.color}
                                            onChange={(color) => handleColorAndFontTypeChange(color.hex, QUOTE_COVER_PAGE, OPENING_NOTES_TEXT, 'color')}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <AttributePrefillTitle />

                        <Box mt={4} width="250px">
                            <Select
                                name={PRE_FILL_ADDRESSED_TO}
                                label={'Addressed To Information'}
                                options={OPTIONS_FOR_ADDRESSED_TO}
                                optionLabelKeyname="name"
                                optionValueKeyname="id"
                                onChange={(e) => changeHandler(e as React.ChangeEvent<HTMLInputElement>, QUOTE_COVER_PAGE)}
                                value={templateState?.quote_cover_page.pre_fill_addressed_to}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
