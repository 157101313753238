import { useEffect, useMemo, useRef, useContext } from 'react';

import { Box, BodyMedium } from '@parspec/pixel';

import DatasheetTable from './DatasheetTable';
import { TableContext } from '../shared/context';
import { IDetailGridProps } from '../shared/types';

const DatasheetDetailGrid = ({ data, node, api, updateDetailGridContainerHeight, selectedRowsRef }: IDetailGridProps) => {
    const rowId = node.id;

    const ref = useRef(null);
    const { section, isOptionTable } = useContext(TableContext);

    useEffect(() => {
        return () => {
            if (rowId) {
                api.removeDetailGridInfo(rowId);
            }
        };
    }, []);

    function gridContainerRefCallback(node: HTMLDivElement) {
        if (node) {
            updateDetailGridContainerHeight(node.clientHeight);
        }
    }

    const onGridReady = (params: any) => {
        if (rowId) {
            const gridInfo = {
                id: rowId,
                api: params.api,
                columnApi: params.columnApi
            };

            api.addDetailGridInfo(rowId, gridInfo);
        }
    };

    const detailTableHeight = useMemo(() => {
        return (data.components.length || 1) * 40 + 57;
    }, [data.components]);

    return (
        <Box p={4} flex={1} display="flex" flexDirection="column" ref={gridContainerRefCallback}>
            <BodyMedium>Component(s)</BodyMedium>
            <Box mt={1} flex={1} minHeight={0}>
                <DatasheetTable
                    selectedRowsRef={selectedRowsRef!}
                    tableData={data.components}
                    section={section}
                    isOptionTable={isOptionTable}
                    kitTableHeight={detailTableHeight}
                    isKitTable={true}
                    isComponentsTable={true}
                    ref={ref}
                    kitData={data}
                    onGridReady={onGridReady}
                />
            </Box>
        </Box>
    );
};

export default DatasheetDetailGrid;
