import { FC, useEffect, useState, FocusEvent } from 'react';
import { To, useParams } from 'react-router-dom';

import { BodySmall, Box, Button, Grid, H6, RadioGroup, Select, SelectChangeEvent, Snackbar, TextField } from '@parspec/pixel';
import { isEqual } from 'lodash-es';

import { addressOptions, checkFormValidation, Company, ContactFormErrorType, ContactFormFields, FORM_FIELDS, getInitialState } from '../utils';
import { CANADA, modifiedStateList, USA } from '../../../Settings/BranchLocations/utils/utils';
import { CANADA_STATES, CONTACT_EDITED_MSG, COUNTRY_OPTIONS, EMAIL_FORMAT_INVALID, REQUIRED_FIELD, USA_STATES } from '../../../shared/constants';
import { useEditContactMutation, useGetContactDetailQuery } from '../queries';
import ContactDetailSkeleton from './ContactDetailSkeleton';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import { useParspecNavigation } from 'src/app/Context';
import CompanySelector from '../../shared/CompanySelector';
import { CONTACT_NUMBER_REQEX } from '../../shared/constant';

const ContactDetailPage: FC = () => {
    const { contactId } = useParams();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [formValues, setFormValues] = useState<ContactFormFields>(getInitialState());
    const [stateOptions, setStateOptions] = useState<
        {
            value: string;
            label: string;
        }[]
    >([{ value: '', label: '' }]);
    const [formErrors, setFormErrors] = useState<ContactFormErrorType>({});
    const isAddressInherited = formValues.address_inherited_from_company;
    const { hasUnsavedChanges, setHasUnsavedChanges, pNavigate } = useParspecNavigation();

    const { data: contactDetail, isLoading } = useGetContactDetailQuery(Number(contactId), { enabled: Boolean(contactId) });

    const { mutateAsync: editContact, isLoading: editContactLoading } = useEditContactMutation();

    useEffect(() => {
        if (contactDetail?.data) {
            const initialData = getInitialState(contactDetail?.data);
            if (!isEqual(initialData, formValues)) {
                setHasUnsavedChanges(true);
            } else {
                setHasUnsavedChanges(false);
            }
        }
    }, [contactDetail?.data, formValues]);

    useEffect(() => {
        if (contactDetail?.data) {
            setFormValues(getInitialState(contactDetail?.data));
        }
    }, [contactDetail?.data]);

    useEffect(() => {
        if (formValues?.country) {
            if (formValues.country.toUpperCase() === USA) {
                setStateOptions(modifiedStateList(USA_STATES));
            } else if (formValues.country === CANADA) {
                setStateOptions(modifiedStateList(CANADA_STATES));
            }
        }
    }, [formValues?.country]);

    const onChangeAddressType = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setFormValues((old: ContactFormFields) => ({ ...old, [FORM_FIELDS.ADDRESS_INHERITED]: value === 'true' }));
    };

    const handleEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name, value } = event.target;
        if (name === FORM_FIELDS.EMAIL && value.trim()) {
            setFormErrors((old) => ({ ...old, [FORM_FIELDS.EMAIL]: !validateEmail(value) ? EMAIL_FORMAT_INVALID : '' }));
        }
    };

    const onChangeFormValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
        const { name, value } = e?.target as HTMLInputElement || {};
        if (name === FORM_FIELDS.PHONE) {
            if (!CONTACT_NUMBER_REQEX.test(value)) {
                return;
            }
        }
        if (name === 'country') {
            setFormValues((old: ContactFormFields) => ({ ...old, [name]: value, state: '' }));
        } else setFormValues((old: ContactFormFields) => ({ ...old, [name]: value }));
        if (name === FORM_FIELDS.FIRST_NAME) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.FIRST_NAME]: !value.trim() ? REQUIRED_FIELD : '' });
        }
        if (name === FORM_FIELDS.LAST_NAME) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.LAST_NAME]: !value.trim() ? REQUIRED_FIELD : '' });
        }
        if (name === FORM_FIELDS.EMAIL) {
            setFormErrors({ ...formErrors, [FORM_FIELDS.EMAIL]: !value.trim() ? '' : formErrors[FORM_FIELDS.EMAIL] });
        }
    };

    const handleCompanyChange = (value: Company) => {
        setFormValues((old: ContactFormFields) => ({ ...old, company_info: value }));
        setFormErrors((old: ContactFormErrorType) => ({ ...old, company_info: undefined }));
    };

    const handleSubmit = async () => {
        const errors = checkFormValidation(formValues);
        const isError = Object.keys(errors).some((item: string) => Boolean(errors[item as keyof ContactFormErrorType]));
        if (isError) {
            return setFormErrors(errors);
        }
        const input = {
            company: formValues.company_info!.id,
            first_name: formValues.first_name,
            role: formValues.role || '',
            email: formValues.email || '',
            phone: formValues.phone || '',
            last_name: formValues.last_name,
            notes: formValues.notes || '',
            address_inherited_from_company: formValues.address_inherited_from_company,
            line1: formValues.line1 || '',
            line2: formValues.line2 || '',
            city: formValues.city || '',
            country: formValues.country || '',
            state: formValues.state || '',
            zip: formValues.zip || ''
        };
        await editContact({ id: Number(contactId), input });
        setSnackbarMessage(CONTACT_EDITED_MSG);
    };

    const onCancel = () => {
        if (window?.history?.state?.navigationId === 1) {
            pNavigate('/v2/contactManagement/contacts');
        } else pNavigate(-1 as To);
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Box height="100%">
                <Box p={6}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                        <H6 mb={3}>Contact Details</H6>
                        <Box display="flex" gap={3}>
                            <Box height={36}>
                                <Button onClick={onCancel} color="secondary" variant="outlined">
                                    Cancel
                                </Button>
                            </Box>
                            <Box height={36}>
                                <Button color="tertiary" variant="contained" onClick={handleSubmit} isLoading={editContactLoading} disabled={isLoading || !hasUnsavedChanges}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    {isLoading ? (
                        <ContactDetailSkeleton />
                    ) : (
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="column" gap={4}>
                                    <Box display="flex" gap={3}>
                                        <TextField
                                            label="First Name"
                                            name={FORM_FIELDS.FIRST_NAME}
                                            fullWidth
                                            required
                                            value={formValues?.[FORM_FIELDS.FIRST_NAME] || ''}
                                            onChange={onChangeFormValue}
                                            error={Boolean(formErrors?.[FORM_FIELDS.FIRST_NAME])}
                                            helperText={formErrors?.[FORM_FIELDS.FIRST_NAME]}
                                        />
                                        <TextField
                                            label="Last Name"
                                            name={FORM_FIELDS.LAST_NAME}
                                            fullWidth
                                            required
                                            value={formValues?.[FORM_FIELDS.LAST_NAME] || ''}
                                            onChange={onChangeFormValue}
                                            error={Boolean(formErrors?.[FORM_FIELDS.LAST_NAME])}
                                            helperText={formErrors?.[FORM_FIELDS.LAST_NAME]}
                                        />
                                    </Box>
                                    <Box>
                                        <CompanySelector companyInfo={formValues?.[FORM_FIELDS.COMPANY]} companyError={formErrors?.[FORM_FIELDS.COMPANY]} setCompanyInfo={handleCompanyChange} />
                                    </Box>
                                    <TextField label="Job Title" name={FORM_FIELDS.ROLE} value={formValues.role || ''} onChange={onChangeFormValue} />
                                    <TextField
                                        label="Email"
                                        name={FORM_FIELDS.EMAIL}
                                        value={formValues?.[FORM_FIELDS.EMAIL] || ''}
                                        onChange={onChangeFormValue}
                                        error={Boolean(formErrors?.[FORM_FIELDS.EMAIL])}
                                        helperText={formErrors?.[FORM_FIELDS.EMAIL]}
                                        onBlur={handleEmailBlur}
                                    />
                                    <TextField label="Phone Number" name={FORM_FIELDS.PHONE} value={formValues?.[FORM_FIELDS.PHONE] || ''} onChange={onChangeFormValue} />
                                    <TextField label="Contact Notes" name={FORM_FIELDS.NOTES} rows={4} multiline value={formValues?.[FORM_FIELDS.NOTES] || ''} onChange={onChangeFormValue} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'none' }}>
                                <Box display="flex" flexDirection="column" gap={4}>
                                    <BodySmall color="text.secondary" mb={-4}>
                                        Address
                                    </BodySmall>
                                    <Box ml={8}>
                                        <RadioGroup name="address-type" label={``} options={addressOptions} value={String(formValues.address_inherited_from_company)} onChange={onChangeAddressType} />
                                    </Box>
                                    {!isAddressInherited ? (
                                        <Box display="flex" flexDirection="column" gap={4}>
                                            <TextField
                                                label="Address Line 1"
                                                name={FORM_FIELDS.ADDRESS_LINE_1}
                                                fullWidth
                                                value={formValues?.[FORM_FIELDS.ADDRESS_LINE_1] || ''}
                                                onChange={onChangeFormValue}
                                            />
                                            <TextField
                                                label="Address Line 2"
                                                name={FORM_FIELDS.ADDRESS_LINE_2}
                                                fullWidth
                                                value={formValues?.[FORM_FIELDS.ADDRESS_LINE_2] || ''}
                                                onChange={onChangeFormValue}
                                            />
                                            <Box display="flex" gap={3}>
                                                <Box flex={1}>
                                                    <Select
                                                        label="Country"
                                                        name={FORM_FIELDS.COUNTRY}
                                                        optionLabelKeyname={'name'}
                                                        optionValueKeyname={'id'}
                                                        options={COUNTRY_OPTIONS}
                                                        value={formValues?.[FORM_FIELDS.COUNTRY] || ''}
                                                        onChange={onChangeFormValue}
                                                    />
                                                </Box>
                                                <Box flex={1}>
                                                    <TextField label="City" name={FORM_FIELDS.CITY} fullWidth value={formValues?.[FORM_FIELDS.CITY] || ''} onChange={onChangeFormValue} />
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={3}>
                                                <Box flex={1}>
                                                    <Select
                                                        label="State"
                                                        name={FORM_FIELDS.STATE}
                                                        optionLabelKeyname={'label'}
                                                        optionValueKeyname={'value'}
                                                        options={stateOptions}
                                                        value={formValues?.[FORM_FIELDS.STATE] || ''}
                                                        onChange={onChangeFormValue}
                                                    />
                                                </Box>
                                                <Box flex={1}>
                                                    <TextField label="Zip" name={FORM_FIELDS.ZIP} fullWidth value={formValues?.[FORM_FIELDS.ZIP] || ''} onChange={onChangeFormValue} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ContactDetailPage;
