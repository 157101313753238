import { AddIcon, BodyBig, Box, H5 } from '@parspec/pixel';

import CreateSectionButton from './CreateSectionButton';

const TITLE = 'Create a Section to Get Started';
const CONTENT = 'Once you add a section, you can start filling out product information.';

const NoSectionUi = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={1}>
            <Box fontSize={60}>
                <AddIcon fontSize="inherit" />
            </Box>
            <H5>{TITLE}</H5>
            <Box mt={3}>
                <BodyBig>{CONTENT}</BodyBig>
            </Box>
            <Box mt={6}>
                <CreateSectionButton />
            </Box>
        </Box>
    );
};

export default NoSectionUi;
