import { axios } from 'src/app/Axios';

import { BulkEditPatchRequest } from './apiTypes';

export const endpoints = {
    patchBulkEditEndpoint: (bomId: number) => `/bom/${bomId}/pricing/bulk/`
};

export const patchBulkEdit = (requestObj: BulkEditPatchRequest) => {
    const { bomId, payload } = requestObj;
    return axios.patch(endpoints.patchBulkEditEndpoint(bomId), payload);
};
