import { SelectChangeEvent } from '@parspec/pixel';
import { Dayjs } from 'dayjs';

export enum ENTITY {
    COVER_PAGE_ENTITY = 'cover_page',
    TOC_ENTITY = 'toc',
    NOTES_PAGE_ENTITY = 'notes_page',
    HEADER_FOOTER_ENTITY = 'header_footer',
    LAYOUT = 'layout'
}

export const quoteCoverPageOpeningNotesFieldName = 'opening_notes';
export const quotePricingLeadTimeOptionNotesFieldName = 'option_notes';
export const submittalsOAndMCoverPageEntity = 'cover_page';
export const acceptedFileFormats = ['.png', '.jpg'];

export enum QuoteEntity {
    HeaderAndFooter = 'header_footer_section',
    CoverPage = 'cover_page_section',
    PricingLeadTime = 'price_and_lead_time_section',
    GeneralTermsAndCondition = 'general_tnc_section',
    ManufactureTermsAndCondition = 'manufacturer_tnc_section',
    Attachments = 'attachments'
}

export interface IToggleText {
    checked?: boolean;
    switchLabel: string;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    blurAction: () => void;
    textFieldAction: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    textFieldValue: string;
    fieldName: string;
    maxLength?: number;
    focusAction?: () => void;
    disabled?: boolean;
    toggleName?: string;
    sectionName?: string;
}

export interface IDate extends Omit<IToggleText, 'placeholder' | 'textFieldValue' | 'fieldName' | 'maxLength' | 'blurAction' | 'textFieldAction'> {
    dateField: Dayjs | null;
    onDateChange: (value: Dayjs | null) => void;
}

export interface ISection {
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
}

export interface Logo {
    filename: string;
    filepath: string;
}

export interface ISectionLogo {
    isDocumentLogoLoading: boolean;
    logo?: Logo | null;
    onDelete: () => void;
    isDeleting: boolean;
    isUpdating: boolean;
    onFileUpload: (data: File[] | null, label?: string | null) => void;
    title?: string;
    disabled?: boolean;
    sectionName?: string;
    subSectionName?: string;
}

export interface FileUploadPayloadType {
    documentType: string;
    bomId: string;
    fieldName: string;
    logo?: FormData | null;
    source?: string | null;
}

export interface FileUploadProps {
    getLogoImagePreview?: (data: File[], fieldName: string) => void;
    fieldName: string;
    imagePreviewAction?: React.Dispatch<React.SetStateAction<boolean>>;
    preparedByLogoPreview?: React.Dispatch<React.SetStateAction<boolean>>;
    addressLogoPreview?: React.Dispatch<React.SetStateAction<boolean>>;
    showAddressToPrefill?: boolean;
    companyLogo?: Logo | null;
    disabled?: boolean;
}

export interface FileDeleteProps {
    bomId: string;
    documentType: string;
    fieldName: string;
}

export const QUOTE_COVERPAGE_LOGO_SECTION = {
    preparedBy: 'prepared_by',
    addressedTo: 'addressed_to'
};

export interface IColorPickerCircle {
    color: string;
    onColorChange: (color: any) => void;
    onColorBlur: () => void;
}

export interface IMultilineTextWithFontAndColor extends Omit<IColorPickerCircle, 'onColorBlur'> {
    onBlur: (isColor?: boolean) => void;
    onTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    textFieldValue: string;
    numberOfRows?: number;
    maxRows?: number;
    selectedFontValue: string | number;
    onSelect: (e: SelectChangeEvent<unknown>) => void;
    switchValue: boolean;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    title: string;
    maxLength?: number;
    onFocus?: () => void;
    onClick?: (open: boolean) => void;
    sectionName?: string;
    subSectionName?: string;
}

export enum compileLabels {
    submittal = 'Submittal',
    o_m = 'O&M Package',
    quote = 'Quote'
}

export interface ICompileLabels {
    submittal: string;
    o_m: string;
    quote: string;
}

export const PRESET_RESPONSE = {
    SUCCESS: 'preset created'
};

export enum quoteHeaderFooterFields {
    jobName = 'job_name',
    addressedTo = 'addressed_to',
    projectLocation = 'project_location',
    contact = 'contact',
    quoteNumber = 'quote_number',
    leftFooter = 'left_footer',
    rightFooter = 'right_footer',
    date = 'date',
    pageNumber = 'page_number',
    hyperLink = 'hyperlink',
    bidDate = 'bid_date',
    expirationDate = 'expiration_date'
}

export const NOTES_PLACEHOLDER = 'Enter Your Notes Here...';

export enum ERROR_KEYS {
    QUOTE_CUSTOM_ENTRY = 'quoteCustomEntry',
    SELECTED_BOMS_EMPTY = 'selectedBomsEmpty'
}
