import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodyBig, Box, H5, Link, TroubleshootIcon, CircularProgress, Grid } from '@parspec/pixel';

import PageRangePopup from '../../../shared/AdjustPageSelection';
import PageHeader from './PageHeader';
import PdfViewer from './PdfViewer';
import PdfViewerHeader from './PdfViewerHeader';
import { useGetDatasheetQuery, useGetSelectedDocumentQuery } from './queries';
import { endPoints } from './queries/apis';
import { ReuseDialogTypeLabel } from '../../shared/utils';
import { useUnsavedChangesDetection } from 'src/app/Context';

import { IGetThumbnails } from '../../../shared/utils/utils';

const AnnotateDatasheet: React.FC = () => {
    useUnsavedChangesDetection();
    const [wvInstance, setwvInstance] = useState<any>(null);
    const hiddenPages = useRef<number[]>([]);
    const [isDisabledAdjustPage, setIsDisabledAdjustPage] = useState(true);
    const xfdf = useRef<string>();
    const { projectId, bomId, productId, documentType, subDocumentType } = useParams();
    const { data: selectedDocumentData, isFetching: isSelectedDocumentQueryFetching } = useGetSelectedDocumentQuery(bomId!, productId!, documentType!, subDocumentType!, {
        enabled: !!bomId && !!productId && !!documentType && !!subDocumentType,
        staleTime: 0
    });

    const { data: datasheetData } = useGetDatasheetQuery(bomId!, productId!, selectedDocumentData?.data[0]?.id || 0, selectedDocumentData?.data[0]?.document || 0, documentType!, subDocumentType!, {
        enabled: Boolean(selectedDocumentData?.data[0]?.id)
    });
    const setHiddenPages = (pages: number[]) => {
        hiddenPages.current = pages;
    };
    const setXfdf = (newXfdf: string) => {
        xfdf.current = newXfdf;
    };
    const [openAdjustPagePopup, setOpenAdjustPageRangePopup] = useState(false);
    const [selectedPages, setSelectedPages] = useState<number[]>([]);

    const matchDocumentUrl = `/v2/project/${projectId}/bom/${bomId}/product/${productId}/${documentType}/${subDocumentType}/match`;

    const getAsyncThumbnailRef = useRef<() => IGetThumbnails>();
    useEffect(() => {
        if (selectedDocumentData?.data) {
            const { draft_xfdf } = selectedDocumentData.data[0];
            setXfdf(draft_xfdf);
        }
    }, [selectedDocumentData?.data[0].draft_xfdf]);
    useEffect(() => {
        if (selectedDocumentData?.data) {
            const { hidden_pages } = selectedDocumentData.data[0];
            setHiddenPages(hidden_pages);
        }
    }, [selectedDocumentData?.data[0].hidden_pages]);

    const documentLabel = ReuseDialogTypeLabel[subDocumentType as keyof typeof ReuseDialogTypeLabel];
    const isAllPagesHidden = datasheetData?.data.total_pages === selectedDocumentData?.data[0]?.hidden_pages?.length;
    return (
        <>
            {openAdjustPagePopup && (
                <PageRangePopup
                    open={openAdjustPagePopup}
                    onClose={setOpenAdjustPageRangePopup}
                    title="Choose the pages that should be associated with this product. Unselected pages will be hidden from compiled documents."
                    hiddenPages={hiddenPages.current}
                    setHiddenPages={setHiddenPages}
                    setSelectedPages={setSelectedPages}
                    apiDetails={{
                        endPoint: endPoints.mutateHiddenPagesUrl(bomId!, productId!, documentType!, subDocumentType!, selectedDocumentData?.data[0]?.id || 0),
                        entity: 'annotateDatasheet'
                    }}
                    bomId={bomId!}
                    headerTitle="Select Pages"
                    bodId={productId!}
                    documentType={documentType!}
                    subDocumentType={subDocumentType!}
                    getThumbnailsAsyncFunc={getAsyncThumbnailRef.current}
                />
            )}
            <PageHeader
                bomId={bomId!}
                bodId={productId!}
                documentType={documentType!}
                subDocumentType={subDocumentType!}
                data={selectedDocumentData}
                xfdf={xfdf}
                wvInstance={wvInstance}
                disableConfirmAnnotationButton={isAllPagesHidden}
            />
            <PdfViewerHeader
                setOpenAdjustPageRangePopup={setOpenAdjustPageRangePopup}
                hiddenPages={hiddenPages}
                documentType={documentType!}
                subDocumentType={subDocumentType!}
                isLoading={isSelectedDocumentQueryFetching}
                wvInstance={wvInstance}
                bomId={bomId!}
                data={selectedDocumentData}
                bodId={productId!}
                xfdf={xfdf}
                isDisabledAdjustPage={isDisabledAdjustPage}
                disableSaveButton={isAllPagesHidden}
            />
            {isSelectedDocumentQueryFetching ? (
                <Grid container height="calc(100vh - 140px)" justifyContent="center" alignItems="center">
                    <Grid item>
                        <CircularProgress size="xxl" />
                    </Grid>
                </Grid>
            ) : selectedDocumentData?.data[0]?.id ? (
                <PdfViewer
                    bomId={bomId!}
                    bodId={productId!}
                    selectedDocumentData={selectedDocumentData}
                    wvInstance={wvInstance}
                    setwvInstance={setwvInstance}
                    xfdf={xfdf}
                    setXfdf={setXfdf}
                    getThumbnailsRef={getAsyncThumbnailRef}
                    selectedPages={selectedPages}
                    hiddenPagesRef={hiddenPages}
                    documentType={documentType!}
                    subDocumentType={subDocumentType!}
                    setIsDisabledAdjustPage={setIsDisabledAdjustPage}
                />
            ) : (
                selectedDocumentData && (
                    <Box height="calc(100vh - 140px)" display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
                        <Box fontSize={80}>
                            <TroubleshootIcon fontSize="inherit" color="tertiary" />
                        </Box>
                        <H5 color="text.secondary" m={1}>
                            Kindly select {documentLabel} to continue
                        </H5>
                        <BodyBig>
                            <Link to={matchDocumentUrl} underline="hover" component={RouterLink}>
                                Click Here
                            </Link>
                        </BodyBig>
                    </Box>
                )
            )}
        </>
    );
};

export default AnnotateDatasheet;
