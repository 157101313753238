import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Box, Snackbar } from '@parspec/pixel';

import { useGetContentDetailQuery } from '../queries';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import PdfViewerHeader from './PdfViewerHeader';
import { environment } from 'src/environments';

import { isStartColBigger } from '../../shared/utils';

interface EmlViewerProps {
    setConfirmSelection: Dispatch<SetStateAction<boolean>>;
    setBoundInfo: Dispatch<SetStateAction<string>>;
    handleChangeFile: Dispatch<SetStateAction<boolean>>;
}

const ExcelViewer = (props: EmlViewerProps) => {
    const { bomId } = useParams();
    const [isExcelLoaded, setExcelLoaded] = useState<boolean>(false);
    const [isNonRecognizedFormat, setNonrecognisedFormat] = useState<boolean>(false);
    const { data, isLoading } = useGetContentDetailQuery(Number(bomId));
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const spreadsheetRef = useRef<SpreadsheetComponent>(null);

    const onCreated = () => {
        const url = data?.data?.file_info;
        const proxy_file = getProxyFileUrl(url!);
        fetch(proxy_file) // fetch the remote url
            .then((response) => {
                response.blob().then((fileBlob) => {
                    const file = new File([fileBlob], 'Sample.xlsx'); //convert the blob into file
                    spreadsheetRef.current?.open({ file: file }); // open the file into Spreadsheet
                });
            });
    };
    const onExcelLoaded = () => {
        setExcelLoaded(true);
        checkFormat();
    };

    const checkFormat = () => {
        const msg = `The sheet you are trying to edit is protected. \nTo make change, please unprotect the sheet.`;
        const recognizedFormats = ['oasis_excel', 'epicor_excel', 'agile_excel', 'normal_excel'];
        setNonrecognisedFormat(recognizedFormats.includes(data?.data?.file_format || ''));
        if (spreadsheetRef.current?.sheets[0]?.isProtected) {
            setSnackbarMessage(msg);
        }
        if (recognizedFormats.includes(data?.data?.file_format || '')) {
            props.setConfirmSelection(true);
        }
    };
    const onCellSelected = (args: any) => {
        if (isExcelLoaded) {
            let res = '';
            const startCell = args.range.split(':')[0];
            const endcell = args.range.split(':')[1];

            let colBegin = startCell.split(/[\d]+/).join('');
            let rowBegin = Number(startCell.split(/[^\d]+/).join(''));

            let colEnd = endcell.split(/[\d]+/).join('');
            let rowEnd = Number(endcell.split(/[^\d]+/).join(''));

            if (isStartColBigger(colBegin, colEnd)) {
                const temp = colEnd;
                colEnd = colBegin;
                colBegin = temp;
            }

            if (rowBegin > rowEnd) {
                const tempRow = rowEnd;
                rowEnd = rowBegin;
                rowBegin = tempRow;
            }

            res = `${colBegin}${rowBegin}:${colEnd}${rowEnd}`;

            props.setBoundInfo(res);
            props.setConfirmSelection(true);
        }
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {!isLoading && (
                <Box display="flex" width="100%" overflow="auto" bgcolor="secondary.contrastText" flexDirection="column" position="relative">
                    <PdfViewerHeader handleChangeFile={props.handleChangeFile} isPdf={false} />

                    {isNonRecognizedFormat && isExcelLoaded && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: ' translate(-50%,-50%)',
                                zIndex: '999',
                                bgcolor: 'secondary.contrastText',
                                padding: '16px',
                                fontWeight: '500',
                                fontSize: '24px',
                                lineHeight: '28px',
                                textAlign: ' center',
                                color: 'primary.main',
                                border: '2px solid',
                                borderColor: 'primary.main',
                                boxShadow: ' 0 1px 2px #1f293714'
                            }}
                        >
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                Successful upload
                                <br />
                                of your
                                {data?.data?.file_format === 'normal_excel' ? <Box>Quote</Box> : <Box component="span">{data?.data?.file_format.split('_')[0]} quote</Box>}
                                <CheckCircleOutlineIcon sx={{ fontSize: 50 }} />
                                <Box>
                                    Click 'Confirm <br />
                                    Selection(s)' to <br />
                                    finalize the import!
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box
                        sx={{
                            height: 'calc(100vh - 117px)',
                            '.e-spreadsheet': {
                                minHeight: 'auto !important',
                                display: 'flex',
                                flex: '1 1 auto',
                                flexDirection: 'column'
                            },
                            '.e-spreadsheet .e-sheet-panel': {
                                flex: ' 1 1 auto',
                                width: '100%'
                                // height: 100% !important;
                            }
                        }}
                    >
                        <SpreadsheetComponent
                            ref={spreadsheetRef}
                            allowOpen={true}
                            openUrl={environment.W}
                            created={onCreated}
                            beforeSelect={onCellSelected.bind(this)}
                            openComplete={onExcelLoaded}
                            allowEditing={false}
                            allowInsert={false}
                            allowChart={false}
                            allowSorting={false}
                            allowDelete={false}
                            allowDataValidation={false}
                            allowFiltering={false}
                            allowFindAndReplace={false}
                            allowMerge={false}
                            showRibbon={false}
                            showSheetTabs={false}
                            showFormulaBar={false}
                            allowUndoRedo={false}
                            enableKeyboardShortcut={false}
                            scrollSettings={{ isFinite: true }}
                            enableContextMenu={false}
                        ></SpreadsheetComponent>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ExcelViewer;
