import { axios } from 'src/app/Axios';
import { Contact, ContactsResponse, CreateContactRequest, EditContactRequest, SuccessResponse } from './apiTypes';

export const endpoints = {
    contacts: `/rolodex/contact/`,
    contactDetail: (contactId: number) => `/rolodex/contact/${contactId}/`
};

export const getContactsData = async (url: string) => {
    return axios.get<ContactsResponse>(url);
};

export const getContactDetail = async (contactId: number) => {
    return axios.get<Contact>(endpoints.contactDetail(contactId));
};

export const addContact = async (input: CreateContactRequest) => {
    return axios.post<Contact>(endpoints.contacts, { ...input });
};

export const editContact = async ({ id, input }: EditContactRequest) => {
    return axios.patch<Contact>(endpoints.contactDetail(id), { ...input });
};

export const deleteContacts = async (contactIds: number[]) => {
    return axios.delete<SuccessResponse>(endpoints.contacts, { data: { id: contactIds } });
};
