import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import { Box } from '@parspec/pixel';

import { useCompileDocumentPreviewQuery } from './queries';
import PdfViewer from '../shared/WebViewer';
import NotVisible from './NotVisible';
import { ENTITY, PRESET_RESPONSE } from '../constants';
import PreviewButton from '../shared/PreviewButton';
import PreviewContext from './Context';

interface PdfPreviewProps {
    accordionFieldName: string;
    isCoverPageVisible: boolean;
    isTocVisible: boolean;
    isNotesVisible: boolean;
    isHeaderAndFooterVisible: boolean;
    isFetch: boolean;
    onClick: () => void;
}

const PdfPreview = ({ accordionFieldName, isCoverPageVisible, isTocVisible, isNotesVisible, isHeaderAndFooterVisible, isFetch, onClick }: PdfPreviewProps) => {
    const { bomId = '', documentType = '' } = useParams();
    const { presetResponse } = useContext(PreviewContext);

    const { data: previewImageData, isFetching: isPreviewImageLoading } = useCompileDocumentPreviewQuery(bomId, accordionFieldName, documentType, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

    const onClickHandler = () => {
        onClick();
    };

    return (
        <Box position={'relative'}>
            <Box width={'100%'} bgcolor={'neutral.main'}>
                {accordionFieldName === ENTITY.COVER_PAGE_ENTITY && isCoverPageVisible ? (
                    <PdfViewer url={previewImageData?.data?.url} isFetching={isPreviewImageLoading} />
                ) : (
                    <>
                        {accordionFieldName === ENTITY.TOC_ENTITY && isTocVisible ? (
                            <PdfViewer url={previewImageData?.data?.url} isFetching={isPreviewImageLoading} />
                        ) : (
                            <>
                                {accordionFieldName === ENTITY.NOTES_PAGE_ENTITY && isNotesVisible ? (
                                    <PdfViewer url={previewImageData?.data?.url} isFetching={isPreviewImageLoading} />
                                ) : (
                                    <>
                                        {accordionFieldName === ENTITY.HEADER_FOOTER_ENTITY && isHeaderAndFooterVisible ? (
                                            <PdfViewer url={previewImageData?.data?.url} isFetching={isPreviewImageLoading} />
                                        ) : (
                                            <NotVisible />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Box>
            {isFetch ? <PreviewButton onClick={onClickHandler} /> : null}
        </Box>
    );
};

export default PdfPreview;
