import { useQuery, useMutation } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import {
    getPriceAndLeadTimeData,
    updatePriceAndLeadTimeToggle,
    endPoints,
    updatePriceAndLeadTimeText,
    getCoverPageData,
    deleteCoverPageLogo,
    updateCoverPageText,
    updateCoverPageToggle,
    getCoverPageLogo,
    updateCoverPageLogo,
    getGeneralTermsAndCondition,
    updateGeneralTermsAndConditionText,
    updateGeneralTermsAndConditionToggle,
    createQuotePreset,
    getQuotePdfUrl,
    getHeaderDocumentLogo,
    deleteHeaderDocumentLogo,
    updateHeaderDocumentLogo,
    getHeaderFooterPresetData,
    updateHeaderFooterToggleData,
    updateHeaderFooterTextData,
    addManufacturer,
    deleteManufacturer,
    getManufacturers,
    updateManufacturer,
    updateManufacturerToggleData,
    updatePriceAndLeadTimeOptions,
    getPriceAndLeadTimeOptionsPreset,
    updatePriceAndLeadTimeOptionsText,
    getQuoteAttachmentsData,
    updateQuoteAttachmentToggle,
    updateQuoteAttachmentDocuments,
    deleteAttachment,
    pushChangesToBoms
} from './apis';

import { StoredManufacturerPairResponse } from './apiTypes';
import { useQuoteContext } from '../QuoteContext';
import {
    transformPriceAndLeadTime,
    transformCoverPage,
    transformGeneralTermAndCondition,
    transformHeaderFooter,
    updateCacheOnMutate,
    Attribute,
    ManufacturerAttribute,
    updateManufacturerCacheOnMutate,
    transformPriceAndLeadTimeOptions
} from '../shared/utils';
import {
    ADDRESS_TO_LOGO_SUB_SECTION,
    ATTACHMENTS_SECTION_NAME,
    COVER_PAGE_SECTION_NAME,
    GENERAL_TNC_SECTION_NAME,
    HEADER_LOGO_SUB_SECTION,
    HEADER_SECTION_NAME,
    MANUFACTURER_SECTION_NAME,
    PREPARED_BY_LOGO_SUB_SECTION,
    PRICE_LEAD_OPTION_SECTION_NAME,
    PRICE_LEAD_SECTION_NAME
} from '../shared/constants';

/** Query keys */
const getPdfUrlKey = (bomId: string) => [endPoints.quotePdfUrl(bomId)];
const getPriceAndLeadTimeKey = (bomId: string) => ['price-lead-data', endPoints.priceAndLeadTimeData(bomId)];
const getPriceAndLeadTimeOptionsKey = (bomId: string) => ['price-lead-options-data', endPoints.priceAndLeadTimeOptionsPreset(bomId)];
const getCoverPageDataKey = (bomId: string) => ['cover-page-data', endPoints.coverPagePresetData(bomId)];
const getCoverPageLogoKey = (bomId: string, fieldName: string) => ['cover-page-logo', endPoints.coverPageLogo(bomId, fieldName)];
const getGeneralTermsAndConditionKey = (bomId: string) => ['general-tnc-data', endPoints.generalTermsAndCondition(bomId)];
const manufacturersListKey = (bomId: string) => ['manufacturer-data', endPoints.getManufacturers(bomId)];
const headerDocumentLogoKey = (bomId: string) => ['header-logo', endPoints.headerDocumentLogo(bomId)];
const headerFooterDataKey = (bomId: string) => ['header-footer-data', endPoints.headerFooterData(bomId)];
const getQuoteAttachmentsKey = (bomId: string) => ['attachments-data', endPoints.quoteAttachmentsData(bomId)];

/** Invalidation Functions */
export const invalidateQuoteHeaderFooterQuery = (bomId: string) => {
    queryClient.invalidateQueries(headerFooterDataKey(bomId));
};

export const invalidateQuoteHeaderFooterInGeneralQuery = () => {
    queryClient.invalidateQueries(['header-footer-data']);
};

export const invalidateHeaderLogoInGeneralQuery = () => {
    queryClient.invalidateQueries(['header-logo']);
};

export const invalidatePriceAndLeadDataInGeneralQuery = () => {
    queryClient.invalidateQueries(['price-lead-data']);
};

export const invalidatePriceAndLeadOptionsDataInGeneralQuery = () => {
    queryClient.invalidateQueries(['price-lead-options-data']);
};

export const invalidateCoverPageInGeneralQuery = () => {
    queryClient.invalidateQueries(['cover-page-data']);
};

export const invalidateCoverPageLogoInGeneralQuery = () => {
    queryClient.invalidateQueries(['cover-page-logo']);
};

export const invalidateGeneralTnCInGeneralQuery = () => {
    queryClient.invalidateQueries(['general-tnc-data']);
};

export const invalidateManufacturerDataInGeneralQuery = () => {
    queryClient.invalidateQueries(['manufacturer-data']);
};

export const invalidateAttachmentsInGeneralQuery = () => {
    queryClient.invalidateQueries(['attachments-data']);
};

/** Quote preset */

export const useQuotePresetMutation = () =>
    useMutation({
        mutationFn: createQuotePreset,
        retry: 3,
        retryDelay: 1000
    });

/** Quote pdf url */

export const useQuotePdfUrlQuery = (bomId: string, options?: object) =>
    useQuery(getPdfUrlKey(bomId), () => getQuotePdfUrl(bomId), {
        ...options,
        staleTime: 0,
        onError: (err) => {
            throw err;
        }
    });

/** Price and lead time */
export const usePriceAndLeadTimeQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: getPriceAndLeadTimeKey(bomId),
        queryFn: () => getPriceAndLeadTimeData(bomId),
        select: transformPriceAndLeadTime,
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const usePriceAndLeadTimeOptionsQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: getPriceAndLeadTimeOptionsKey(bomId),
        queryFn: () => getPriceAndLeadTimeOptionsPreset(bomId),
        select: transformPriceAndLeadTimeOptions,
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const usePriceAndLeadTimeToggleMutation = () =>
    useMutation(updatePriceAndLeadTimeToggle, {
        onMutate: (variables) => {
            updateCacheOnMutate(getPriceAndLeadTimeKey(variables.bomId), variables, Attribute.isActive);
        }
    });

export const usePriceAndLeadTimeTextMutation = () =>
    useMutation(updatePriceAndLeadTimeText, {
        onMutate: (variables) => {
            updateCacheOnMutate(getPriceAndLeadTimeKey(variables.bomId), variables, Attribute.fieldValue);
        }
    });

export const usePriceAndLeadTimeOptionsToggleMutation = () =>
    useMutation(updatePriceAndLeadTimeOptions, {
        onMutate: (variables) => {
            updateCacheOnMutate(getPriceAndLeadTimeOptionsKey(variables.bomId), variables, Attribute.isActive);
        }
    });

export const usePriceAndLeadTimeOptionsTextMutation = () =>
    useMutation(updatePriceAndLeadTimeOptionsText, {
        onMutate: (variables) => {
            updateCacheOnMutate(getPriceAndLeadTimeOptionsKey(variables.bomId), variables, Attribute.fieldValue);
        }
    });

/** Cover page */

export const useCoverPageDataQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: getCoverPageDataKey(bomId),
        queryFn: () => getCoverPageData(bomId),
        select: transformCoverPage,
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const useCoverPageLogoQuery = (bomId: string, fieldName: string, isInitiateApiCalls: boolean) =>
    useQuery({
        queryKey: getCoverPageLogoKey(bomId, fieldName),
        queryFn: () => getCoverPageLogo(bomId, fieldName),
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const useCoverPageToggleMutation = () => {
    return useMutation(updateCoverPageToggle, {
        onMutate: (variables) => {
            updateCacheOnMutate(getCoverPageDataKey(variables.bomId), variables, Attribute.isActive);
        }
    });
};

export const useCoverPageTextMutation = () =>
    useMutation(updateCoverPageText, {
        onSuccess: (_, variables) => updateCacheOnMutate(getCoverPageDataKey(variables.bomId), variables, Attribute.fieldValue)
    });

export const useCoverUpdateLogoMutation = () =>
    useMutation(updateCoverPageLogo, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(getCoverPageLogoKey(variables.bomId, variables.fieldName));
        }
    });

export const useCoverPageDeleteLogoMutation = () =>
    useMutation(deleteCoverPageLogo, {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries(getCoverPageLogoKey(variables.bomId, variables.fieldName));
        }
    });

/** Manufacturer TOC */

export const useManufacturerTermsAndConditionsQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: manufacturersListKey(bomId),
        queryFn: () => getManufacturers(bomId),
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const useAddManufacturerMutation = () =>
    useMutation(addManufacturer, {
        onSuccess: (response, request) => {
            const { bomId } = request;
            queryClient.setQueryData(manufacturersListKey(bomId), (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.concat(response.data)
                    };
                }
                return oldData;
            });
        }
    });

export const useUpdateManufacturerNameMutation = () =>
    useMutation(updateManufacturer, {
        onSuccess: (_response, request) => {
            const payload = {
                id: request.mfgId,
                ...request.payload
            };
            updateManufacturerCacheOnMutate(manufacturersListKey(request.bomId), payload, ManufacturerAttribute.fieldName);
        }
    });

export const useUpdateManufacturerNotesMutation = () =>
    useMutation(updateManufacturer, {
        onSuccess: (_response, request) => {
            const payload = {
                id: request.mfgId,
                ...request.payload
            };
            updateManufacturerCacheOnMutate(manufacturersListKey(request.bomId), payload, ManufacturerAttribute.fieldValue);
        }
    });

export const useDeleteManufacturerMutation = () =>
    useMutation(deleteManufacturer, {
        onSuccess: (_response, request) => {
            const { bomId, mfgId } = request;
            queryClient.setQueryData(manufacturersListKey(bomId), (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.filter((mfg: StoredManufacturerPairResponse) => mfgId != mfg.id)
                    };
                }
                return oldData;
            });
        }
    });

export const useManufacturerToggleMutation = () => {
    return useMutation(updateManufacturerToggleData, {
        onMutate: (variables) => {
            queryClient.setQueryData(manufacturersListKey(variables.bomId), (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData?.data.map((item: StoredManufacturerPairResponse) => {
                            if (item.field_name === variables.fieldName) {
                                item = { ...item, is_active: variables.payload.is_active };
                            }
                            return item;
                        })
                    };
                }
                return oldData;
            });
        }
    });
};
/** Header footer */
export const useHeaderDocumentLogoDeleteMutation = () => {
    return useMutation(deleteHeaderDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(headerDocumentLogoKey(variables));
        }
    });
};

export const useHeaderDocumentLogoUploadMutation = () => {
    return useMutation(updateHeaderDocumentLogo, {
        onSuccess: (_newData, variables) => {
            queryClient.invalidateQueries(headerDocumentLogoKey(variables.bomId));
        }
    });
};

export const useHeaderDocumentLogoQuery = (bomId: string) => {
    const { isInitiateApiCalls } = useQuoteContext();
    return useQuery(headerDocumentLogoKey(bomId), () => getHeaderDocumentLogo(bomId), { enabled: isInitiateApiCalls });
};

export const useHeaderFooterDataQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: headerFooterDataKey(bomId),
        queryFn: () => getHeaderFooterPresetData(bomId),
        select: transformHeaderFooter,
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false,
        retry: 3,
        retryDelay: 1000
    });

export const useUpdateHeaderFooterToggleMutation = () => {
    return useMutation(updateHeaderFooterToggleData, {
        onMutate: (variables) => {
            updateCacheOnMutate(headerFooterDataKey(variables.bomId), variables, Attribute.isActive);
        }
    });
};

export const useUpdateHeaderFooterTextMutation = () =>
    useMutation(updateHeaderFooterTextData, {
        onSuccess: (_, variables) => updateCacheOnMutate(headerFooterDataKey(variables.bomId), variables, Attribute.fieldValue)
    });

/**
 * General terms and conditions
 */
export const useGeneralTermsAndConditionQuery = (bomId: string, isInitiateApiCalls?: boolean) =>
    useQuery({
        queryKey: getGeneralTermsAndConditionKey(bomId),
        queryFn: () => getGeneralTermsAndCondition(bomId),
        select: transformGeneralTermAndCondition,
        enabled: isInitiateApiCalls ? isInitiateApiCalls : false
    });

export const useGeneralTermsAndConditionToggleMutation = () => {
    return useMutation(updateGeneralTermsAndConditionToggle, {
        onMutate: (variables) => {
            updateCacheOnMutate(getGeneralTermsAndConditionKey(variables.bomId), variables, Attribute.isActive);
        }
    });
};

export const useGeneralTermsAndConditionTextMutation = () =>
    useMutation(updateGeneralTermsAndConditionText, {
        onSuccess: (_, variables) => updateCacheOnMutate(getGeneralTermsAndConditionKey(variables.bomId), variables, Attribute.fieldValue)
    });

/**
 * Attachments
 */

export const useQuoteAttachmentsQuery = (bomId: string, enabled?: boolean) =>
    useQuery({
        queryKey: getQuoteAttachmentsKey(bomId),
        queryFn: () => getQuoteAttachmentsData(bomId),
        enabled: enabled
    });

export const useAttachmentToggleMutation = () => {
    return useMutation(updateQuoteAttachmentToggle, {
        onMutate: (variables) => {
            queryClient.setQueryData(getQuoteAttachmentsKey(variables.bomId), (oldData: any) => {
                const moddedData = { ...oldData.data, is_enable: variables.payload.is_enable };

                const newData = {
                    ...oldData,
                    data: moddedData
                };

                return newData;
            });
        }
    });
};

const invalidateQuoteAttachmentDetails = (bomId: string) => {
    queryClient.invalidateQueries(getQuoteAttachmentsKey(bomId));
};

export const useAttachmentDocumentsMutation = () => {
    return useMutation(updateQuoteAttachmentDocuments, {
        onSuccess: (_, request) => {
            invalidateQuoteAttachmentDetails(request.bomId);
        }
    });
};

export const useQuoteAttachmentDeleteMutation = () => {
    return useMutation(deleteAttachment, {
        onSuccess: (_, request) => {
            invalidateQuoteAttachmentDetails(request.bomId);
        }
    });
};

export const usePushChangesToBomsMutation = () => {
    return useMutation(pushChangesToBoms, {
        onSuccess: (_, request) => {
            const { sectionName, subSectionName } = request;

            if (sectionName === HEADER_SECTION_NAME) {
                if (subSectionName === HEADER_LOGO_SUB_SECTION) {
                    invalidateHeaderLogoInGeneralQuery();
                } else invalidateQuoteHeaderFooterInGeneralQuery();
            }
            if (sectionName === PRICE_LEAD_SECTION_NAME) {
                invalidatePriceAndLeadDataInGeneralQuery();
            }
            if (sectionName === PRICE_LEAD_OPTION_SECTION_NAME) {
                invalidatePriceAndLeadOptionsDataInGeneralQuery();
            }
            if (sectionName === COVER_PAGE_SECTION_NAME) {
                if ([PREPARED_BY_LOGO_SUB_SECTION, ADDRESS_TO_LOGO_SUB_SECTION].includes(subSectionName)) {
                    invalidateCoverPageLogoInGeneralQuery();
                } else invalidateCoverPageInGeneralQuery();
            }
            if (sectionName === GENERAL_TNC_SECTION_NAME) {
                invalidateGeneralTnCInGeneralQuery();
            }
            if (sectionName === ATTACHMENTS_SECTION_NAME) {
                invalidateAttachmentsInGeneralQuery();
            }
            if (sectionName === MANUFACTURER_SECTION_NAME) {
                invalidateManufacturerDataInGeneralQuery();
            }
        }
    });
};
