import { ColDef, ICellRendererParams } from 'ag-grid-community';

import { TableMenu } from '../shared/utils';
import { useGetUserRole } from '../../shared/RBAC';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES } from '../../shared/constants';
import { useContext } from 'react';
import { MenuContext } from './context';

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
};

export function MenuOption(props: ICellRendererParams) {
    const { data } = props;

    const navigate = useNavigate();
    const { currentUserRole } = useGetUserRole();
    const { onDelete } = useContext(MenuContext);

    const options: Array<{ label: string; onClick: () => void; color?: string }> = [
        {
            label: 'View',
            onClick: () => navigate(`/v2/project/${data?.projectId}`)
        }
    ];

    if (currentUserRole === USER_ROLES.ADMIN) {
        options.push({
            label: 'Delete',
            onClick: () => onDelete(data),
            color: 'error.main'
        });
    }

    return <TableMenu options={options} />;
}
