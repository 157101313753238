import { Box, InfoOutlinedIcon, Tooltip } from '@parspec/pixel';

export function InfoHeaderTemplateCallback(title: string, info: string) {
    return () => (
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
            <Box>{title}</Box>
            <Tooltip title={info}>
                <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
        </Box>
    );
}

export function getErpContent(props: any) {
    return props?.erp_code ? props.erp_code : '--';
}
