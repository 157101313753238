import dayjs from 'dayjs';
import { SelectFieldOptions } from '../PlanAndPayment.types';

export const initialErrorState = {
    companyName: { isError: false, title: '' },
    address1: { isError: false, title: '' },
    address2: { isError: false, title: '' },
    city: { isError: false, title: '' },
    state: { isError: false, title: '' },
    zipcode: { isError: false, title: '' },
    country: { isError: false, title: '' },
    email: { isError: false, title: '' }
};

export const initialBillingDataState = {
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    email: ''
};

export const capitalizeFirstLetter = (str: string) => {
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);
    return newStr;
};

export const getMMDDYYYY = (date: string | undefined) => {
    if (!date) return '';
    const dateObj = dayjs(date);
    const formattedDate = dateObj.format('MMM D, YYYY');
    return formattedDate;
};

export const trimEmptySpaces = (nameStr: string) => {
    return nameStr.trim().replace(/\s+/g, ' ');
};

export const countryOptions = [
    { label: 'USA', value: 'USA' },
    { label: 'Canada', value: 'Canada' }
];

export const USA = 'USA';
export const CANADA = 'Canada';

export const modifiedStateList = (countryStates: string[]) => {
    const stateOptions: SelectFieldOptions = countryStates.map((data: string) => ({ label: data, value: data }));

    return stateOptions;
};
