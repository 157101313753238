import { useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, SelectChangeEvent } from '@parspec/pixel';

import PreviewContext from '../../Context';
import FileUploadSection from '../../shared/FileUploadSection';
import { IAddressTo } from '../../queries/apiTypes';
import { useDocumentTextFieldMutation, useCoverPageTextfieldData, useCoverPageDocumentLogoQuery } from '../../queries';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { validateEmail } from 'src/features/Settings/UserManagement/utils/utils';
import Section from '../../../shared/Section';
import { submittalsOAndMCoverPageEntity, quoteHeaderFooterFields, PRESET_RESPONSE } from '../../../constants';
import { ADDRESSED_TO_SELECT_OPTIONS } from '../../../shared/utils/constants';
import { IPrefillPayload } from '../../../shared/utils/helper';
import { getAddressToPrimaryPrefillObj } from '../../../shared/utils';

interface ICoverPageSection {
    parentSwitchLabel: string;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    fieldName: string;
    coverPageEntity?: string;
    preparedByLogoPreview?: React.Dispatch<React.SetStateAction<boolean>>;
    addressLogoPreview?: React.Dispatch<React.SetStateAction<boolean>>;
    getLogoImagePreview?: (data: File[], fieldName: string) => void;
    showAddressToPrefill?: boolean;
    updateAddressToLogo?: (url: string) => void;
}

const CoverPageSection: React.FC<ICoverPageSection> = ({
    parentSwitchLabel,
    onToggle,
    value,
    fieldName,
    preparedByLogoPreview,
    addressLogoPreview,
    getLogoImagePreview,
    showAddressToPrefill,
    updateAddressToLogo
}) => {
    const { bomId = '', documentType = '' } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);

    const { data: bomData } = useGetBomDetails(bomId, {
        enabled: Boolean(bomId) && Boolean(presetResponse)
    });
    const { data: coverPageData, isLoading: isCoverPageTextFieldDataLoading } = useCoverPageTextfieldData(bomId, documentType, presetResponse);
    const { data: documentLogo } = useCoverPageDocumentLogoQuery(bomId, fieldName, documentType, {
        enabled: presetResponse === PRESET_RESPONSE.SUCCESS
    });

    const [sectionState, setSectionState] = useState<IAddressTo>({
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: ''
    });
    const [addressToPrefill, setAddressToPrefill] = useState('');
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);

    const { mutateAsync } = useDocumentTextFieldMutation();

    const companyLogo =
        addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact?.company?.logo
            ? { filename: 'Primary Customer.png', filepath: bomData?.data?.primary_contact?.company?.logo as unknown as string }
            : null;

    useEffect(() => {
        if (coverPageData && fieldName === quoteHeaderFooterFields.addressedTo) {
            if (coverPageData[fieldName]?.pre_fill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact) {
                setSectionState({ ...getAddressToPrimaryPrefillObj(bomData?.data?.primary_contact) });
            } else {
                setSectionState(coverPageData[fieldName]?.value);
            }
            setAddressToPrefill(coverPageData[fieldName]?.pre_fill);
        } else if (coverPageData) {
            setSectionState({ ...coverPageData[fieldName] });
        }
    }, [isCoverPageTextFieldDataLoading, bomData?.data?.primary_contact]);

    useEffect(() => {
        if (showAddressToPrefill && companyLogo && updateAddressToLogo) {
            updateAddressToLogo(companyLogo.filepath);
        } else if (showAddressToPrefill && updateAddressToLogo && documentLogo?.data?.logo?.filepath) {
            updateAddressToLogo(documentLogo.data.logo.filepath);
        }
    }, [companyLogo, updateAddressToLogo, showAddressToPrefill, documentLogo?.data?.logo?.filepath]);

    const handleAction = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            if (name === 'country') {
                setSectionState({ ...sectionState, [name]: value, state: '' });
            } else {
                setSectionState({ ...sectionState, [name]: value });
            }

            handlePreviewImage(false);
        },
        [sectionState]
    );

    const handleOnBlur = useCallback(async () => {
        if (sectionState?.email && !validateEmail(sectionState?.email)) {
            setIsEmailInvalid(true);
            return;
        }
        const payload: IPrefillPayload = {
            pre_fill: addressToPrefill,
            value: { ...sectionState }
        };
        await mutateAsync({
            bomId: bomId,
            fieldName,
            field_value: fieldName === quoteHeaderFooterFields.addressedTo ? payload : payload.value,
            documentType: documentType,
            entity: submittalsOAndMCoverPageEntity
        });
        handlePreviewImage(true);
    }, [sectionState]);

    const handleAddressToPrefill = useCallback(
        async (event: SelectChangeEvent<unknown>) => {
            const { value, name } = event.target;

            const payload: IPrefillPayload = {
                pre_fill: value as string,
                value: { ...sectionState }
            };
            if (value === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact) {
                payload.value = getAddressToPrimaryPrefillObj(bomData?.data?.primary_contact);
            } else if (value === ADDRESSED_TO_SELECT_OPTIONS.CUSTOM_ENTRY && !documentLogo?.data?.logo) {
                updateAddressToLogo?.('');
            }

            setSectionState(payload.value);
            setAddressToPrefill(event.target.value as string);
            await mutateAsync({
                bomId: bomId,
                fieldName: name,
                field_value: payload,
                documentType: documentType,
                entity: submittalsOAndMCoverPageEntity
            });
            handlePreviewImage(true);
        },
        [sectionState, bomData?.data?.primary_contact, documentLogo?.data?.logo]
    );

    return (
        <Section
            readOnly={addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER}
            showAddressToPrefill={showAddressToPrefill}
            onSelect={handleAddressToPrefill}
            prefillValue={addressToPrefill}
            onTextChange={(e) => handleAction(e as React.ChangeEvent<HTMLInputElement>)}
            onBlur={handleOnBlur}
            isChecked={value}
            onSwitchToggle={onToggle}
            switchLabel={parentSwitchLabel}
            isEmailInvalid={isEmailInvalid}
            sectionState={sectionState}
        >
            <Grid item mt={2}>
                <FileUploadSection
                    disabled={addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER}
                    showAddressToPrefill={showAddressToPrefill}
                    companyLogo={companyLogo}
                    getLogoImagePreview={getLogoImagePreview}
                    preparedByLogoPreview={preparedByLogoPreview}
                    addressLogoPreview={addressLogoPreview}
                    fieldName={fieldName}
                />
            </Grid>
        </Section>
    );
};

export default CoverPageSection;
