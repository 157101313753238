import { useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { useGetDatasheetTabDataQuery, useGetDatasheetTabStatsQuery } from '../queries';
import Footer from '../Footer';
import { getStats } from './helper';
import DatasheetTabHeader from './DatasheetTabHeader';
import DatasheetTable from './DatasheetTable';
import { getTableData, prepareKitsAndComponentsData } from '../shared/utils';
import TabContent from '../shared/TabContent';
import { useGetBomData, useCustomisationColumn, useProjectBomsMappedData } from '../shared/hooks';
import LinkedBomsPopup from '../shared/LinkedBomsPopup';

const DatasheetTab = () => {
    const [selectedItemsFromSections, setSelectedItemsFromSections] = useState<any[]>([]);
    const { bomId, projectId } = useParams();

    const selectedRowsRef = useRef<Set<number>>(new Set());

    const {
        data: bomData,
        isLoading,
        mainProductOptionsInfo
    } = useGetBomData(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: datasheetTabData, isLoading: tabDataLoading } = useGetDatasheetTabDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: datasheetTabStats } = useGetDatasheetTabStatsQuery(bomId!, {
        enabled: Boolean(bomId),
        staleTime: 0
    });
    const { projectBomsAndPrimaryData } = useProjectBomsMappedData(Number(projectId));
    const { projectBomsMappedData, primaryBomId } = projectBomsAndPrimaryData;

    const { handleTablesReordering, sectionRefList, handleTablesWidthChanged } = useCustomisationColumn();

    const aggregateSelectedItemsFromSections = (selectedItems: any, sectionId: number) => {
        let updatedItems = selectedItemsFromSections.filter((item) => item.section !== sectionId);
        updatedItems = [...updatedItems, ...selectedItems];
        setSelectedItemsFromSections(() => [...updatedItems]);
    };

    const unCheckSelectedLineItems = () => {
        setSelectedItemsFromSections([]);
        const gridRefs = Object.values(sectionRefList.current).map((obj) => obj.current);
        gridRefs.forEach((gridRef) => {
            gridRef.api.deselectAll();
        });
    };

    const statsData = getStats(datasheetTabStats);

    const tableData = useMemo(() => {
        if (bomData?.data && datasheetTabData?.data) {
            const result = getTableData(bomData?.data, datasheetTabData?.data);
            return prepareKitsAndComponentsData(result);
        }
        return [];
    }, [isLoading, tabDataLoading, bomData?.data, datasheetTabData?.data]);

    const isBomLinked = projectBomsMappedData.get(Number(bomId));

    return (
        <>
            {isBomLinked ? (
                <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />
            ) : (
                <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                    <Box flex={1} minHeight={0} pt={4} display="flex" flexDirection="column">
                        <TabContent
                            tableData={tableData}
                            tabCta={<DatasheetTabHeader selectedItems={selectedItemsFromSections} unCheckSelectedLineItems={unCheckSelectedLineItems} tableData={tableData || []} />}
                            ref={sectionRefList}
                        >
                            <DatasheetTable
                                bomDataLoading={isLoading}
                                datasheetDataLoading={tabDataLoading}
                                aggregateSelectedItemsFromSections={aggregateSelectedItemsFromSections}
                                optionSection={undefined as never}
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />

                            <DatasheetTable
                                bomDataLoading={isLoading}
                                datasheetDataLoading={tabDataLoading}
                                aggregateSelectedItemsFromSections={aggregateSelectedItemsFromSections}
                                optionSection={undefined as never}
                                isOptionTable
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />
                        </TabContent>
                    </Box>
                    <Footer statsData={statsData} />
                </Box>
            )}
        </>
    );
};

export default DatasheetTab;
