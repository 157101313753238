import * as Sentry from '@sentry/react';
import { environment } from 'src/environments';

export function notifyToSentry(message: string, isApiError = false) {
    if (environment.Z) {
        Sentry.captureMessage(isApiError ? 'App API Error: ' : 'App Error: ' + message);
    }

    return null;
}
