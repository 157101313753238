//
import { axios } from 'src/app/Axios';
import { LocationResponse, AllCompanyDetailsResponse, CreateProjectResponse } from './apiTypes';

export const projectModalEndPoints = {
    createProject: `/project/`,
    allLocations: (userId: number) => `/api/user_info/${userId}/`,
    allCompanyDetails: `/user/projects_info/`
};

export const createProject = async (data: FormData) => {
    return axios.post<CreateProjectResponse>(projectModalEndPoints.createProject, data);
};

export const getAllLocations = async (userId: number) => {
    return axios.get<LocationResponse[]>(projectModalEndPoints.allLocations(userId));
};

export const getAllCompanyDetails = async () => {
    return axios.get<AllCompanyDetailsResponse[]>(projectModalEndPoints.allCompanyDetails);
};

//
