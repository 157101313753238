import { useGoogleLogin } from '@react-oauth/google';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

import { BodySmall, Box, Button, Tooltip } from '@parspec/pixel';

import {
    EMAIL_SERVICE_TYPES,
    LOGIN_OPTIONS,
    MSAL_CONFIG,
    GMAIL_CONFIG,
    MSAL_TOKEN_GENERATED_AT_LS_KEY,
    GOOGLE_ACCESS_TOKE_LS_KEY,
    MAILING_EMAIL_LS_KEY,
    MSAL_ACCESSTOKEN_LS_KEY
} from '../../../../BOM/FinalDocuments/EmailPopup/constants';
import { useGetGoogleUserInfo } from '../../../../BOM/FinalDocuments/EmailPopup/queries';
import { useEffect, useState } from 'react';
import { notifyToSentry } from 'src/features/shared/SentryErrorLogger';

interface ISyncAccountProps {
    emailService: string;
    updateEmailService: (value: string) => void;
}

export const msalInstance: any = new PublicClientApplication(MSAL_CONFIG as Configuration);

const SyncAccount = ({ emailService, updateEmailService }: ISyncAccountProps) => {
    const [googleToken, setGoogleToken] = useState('');

    const { data: googleUserInfo } = useGetGoogleUserInfo(googleToken, {
        enabled: Boolean(googleToken)
    });

    useEffect(() => {
        if (googleUserInfo?.data) {
            if (localStorage.getItem(MAILING_EMAIL_LS_KEY)) {
                localStorage.removeItem(MAILING_EMAIL_LS_KEY);
            }
            localStorage.setItem(MAILING_EMAIL_LS_KEY, googleUserInfo.data.email);
        }
    }, [googleUserInfo]);

    const handleMsaLogin = async () => {
        try {
            await msalInstance.initialize();
            const loginResponse = await msalInstance.loginPopup({});
            const { accessToken, account } = loginResponse;
            localStorage.setItem(MAILING_EMAIL_LS_KEY, account.idTokenClaims.email);
            localStorage.setItem(MSAL_ACCESSTOKEN_LS_KEY, accessToken);
            localStorage.setItem(MSAL_TOKEN_GENERATED_AT_LS_KEY, String(Date.now()));
            updateEmailService(EMAIL_SERVICE_TYPES.MSAL);
        } catch (err: any) {
            notifyToSentry(`outlook account connection error: ${err?.errorMessage}`);
        }
    };

    const handleGoogleLogin = useGoogleLogin({
        scope: GMAIL_CONFIG.scopes.join(' '),
        onSuccess: (codeResponse: any) => {
            if (codeResponse.access_token) {
                setGoogleToken(codeResponse.access_token);
                localStorage.setItem(GOOGLE_ACCESS_TOKE_LS_KEY, codeResponse.access_token);
                updateEmailService(EMAIL_SERVICE_TYPES.GMAIL);
            }
        },
        onError: (error: any) => {
            notifyToSentry(`outlook account connection error: ${JSON.stringify(error)}`);
        }
    });

    const handleDisconnect = async (type: string) => {
        if (type === EMAIL_SERVICE_TYPES.GMAIL) localStorage.removeItem('googleToken');
        if (type === EMAIL_SERVICE_TYPES.MSAL) {
            msalInstance.logoutPopup({ account: await msalInstance.getAllAccounts()[0], postLogoutRedirectUri: MSAL_CONFIG.auth.redirectUri });
            localStorage.removeItem('msToken');
        }

        localStorage.removeItem(MAILING_EMAIL_LS_KEY);

        updateEmailService('');
    };

    return (
        <Box>
            <Box display="flex" columnGap={4} mt={5}>
                {LOGIN_OPTIONS.map((obj) => {
                    const { type, logo, text } = obj;
                    return (
                        <Box
                            width="50%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            border="1px solid"
                            borderColor="neutral.main"
                            borderRadius={1}
                            minWidth="228px"
                            py={3}
                            px={2}
                            sx={{ opacity: emailService !== '' && emailService !== type ? 0.5 : 1 }}
                        >
                            <Box>
                                <img src={logo} alt="" width="156px" />
                            </Box>
                            <BodySmall limit={false} align="center" color="neutral.dark" mt={4} mb={obj.type === EMAIL_SERVICE_TYPES.GMAIL ? '40px' : '22px'} whiteSpace="pre-line">
                                {text}
                            </BodySmall>
                            <Box>
                                {emailService !== '' && emailService === type ? (
                                    <Tooltip title="This action will logout you from all your microsoft accounts">
                                        <Button variant="outlined" color="secondary" onClick={() => handleDisconnect(type)} disabled={emailService !== '' && emailService !== type}>
                                            Disconnect
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => (type === EMAIL_SERVICE_TYPES.MSAL ? handleMsaLogin() : handleGoogleLogin())}
                                        disabled={emailService !== '' && emailService !== type}
                                    >
                                        Connect
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default SyncAccount;
