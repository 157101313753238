import { useContext } from 'react';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosResponse } from 'axios';

import { TableMenu } from '../shared/utils';
import { CED_NET_DOWNLOAD_FAILED, copyMsg, DOWNLOAD_FAILED_MSG } from '../../shared/constants';
import { MenuContext } from './context';
import { useFeatureFlag } from '../../shared/UserActivityTracking/useFeatureFlag';
import { useDownloadMutation } from '../../BOM/FinalDocuments/queries';
import { DownloadByVersionIdTypes } from '../../BOM/FinalDocuments/queries/apiTypes';
import { DocType, SnackbarMessages } from '../../BOM/FinalDocuments/constants';
import { callProxyURL } from '../../BOM/FinalDocuments/utils/downloadFile';

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: ['filterMenuTab'],
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
};

export function MenuOption(props: ICellRendererParams) {
    const { data } = props;

    const docLink = data?.shareLink ? data.shareLink : '';

    const { enable_cednet_format, enable_epicor_format } = useFeatureFlag();

    const { mutateAsync: downloadMutation } = useDownloadMutation();

    const { isActiveSubscription, setSnackbarMessage, handleSubscriptionPopup } = useContext(MenuContext);

    const handleDownload = async (bomId: string, documentId: number, fileType?: DownloadByVersionIdTypes['type']) => {
        try {
            setSnackbarMessage(SnackbarMessages.DOWNLOADING);
            const response: AxiosResponse = await downloadMutation({ bomId, id: documentId || 0, type: fileType ? fileType : 'flattened', documentType: DocType.QUOTE });
            await callProxyURL(response?.data.url, fileType ? fileType : 'pdf', response?.data.filename);
            setSnackbarMessage(SnackbarMessages.DOWNLOAD_SUCCESS);
        } catch (error: any) {
            if ((fileType === 'ced_xlsx' || fileType === 'jm_xlsx') && error?.response?.status === 404) {
                setSnackbarMessage(CED_NET_DOWNLOAD_FAILED);
            } else {
                setSnackbarMessage(DOWNLOAD_FAILED_MSG);
            }
        }
    };

    const getDownloadOption = (label: string, bomId: string, documentId: number, type?: DownloadByVersionIdTypes['type'], docLink?: string) => {
        return {
            label,
            onClick: () => {
                if (label === 'Copy Link') {
                    if (isActiveSubscription) {
                        navigator.clipboard.writeText(docLink || '');
                        setSnackbarMessage(copyMsg);
                    } else handleSubscriptionPopup();
                } else {
                    handleDownload(bomId, documentId, type);
                }
            }
        };
    };

    const downloadOptions = () => {
        const optionsArray = [];

        optionsArray.push(getDownloadOption('PDF', data.bomId, data.quoteId));

        optionsArray.push(getDownloadOption('Excel', data.bomId, data.quoteId, 'xlsx'));

        optionsArray.push(getDownloadOption('Copy Link', data.bomId, data.quoteId, 'NA', docLink));

        if (enable_cednet_format) {
            optionsArray.push(getDownloadOption('CEDNet', data.bomId, data.quoteId, 'ced_xlsx'));
        }

        if (enable_epicor_format) {
            optionsArray.push(getDownloadOption('Job Management (Internal)', data.bomId, data.quoteId, 'jm_xlsx'));
        }

        return optionsArray;
    };

    return <TableMenu options={downloadOptions()} />;
}
