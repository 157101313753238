import { useNavigate } from 'react-router-dom';

import { Box, Modal, BodySmall, ModalFooter, ModalHeader, Button } from '@parspec/pixel';

import { CreditCheckModalInterface } from '../utils/helper';

interface NotEnoughCreditsModalProps {
    creditCheckModalData: CreditCheckModalInterface;
    setCreditCheckModalData: React.Dispatch<React.SetStateAction<CreditCheckModalInterface>>;
    onCreateQuote: (notes: Record<string, string>) => void;
    bomNotes: Record<string, string> | null;
}

export const NotEnoughCreditsModal = (props: NotEnoughCreditsModalProps) => {
    const { creditCheckModalData, setCreditCheckModalData, onCreateQuote, bomNotes } = props;

    const navigate = useNavigate();

    const confirmHandler = async () => {
        if (bomNotes) {
            onCreateQuote(bomNotes);
        }
        closeModalHandler();
    };

    const closeModalHandler = () => {
        setCreditCheckModalData({ ...creditCheckModalData, isOpen: false });
    };

    return (
        <Box>
            <Modal
                open={creditCheckModalData.isOpen}
                footer={
                    creditCheckModalData.auto_upgrade ? (
                        <ModalFooter isLoading={false} onAccept={confirmHandler} onReject={closeModalHandler} />
                    ) : (
                        <Box textAlign={'right'}>
                            <Button color="secondary" variant="outlined" onClick={() => closeModalHandler()}>
                                Cancel
                            </Button>
                        </Box>
                    )
                }
                header={<ModalHeader onClose={closeModalHandler} title={'Not Enough Credits'}></ModalHeader>}
                onClose={closeModalHandler}
            >
                <Box width={'500px'} mt={4}>
                    {creditCheckModalData.auto_upgrade ? (
                        <Box>
                            <BodySmall limit={false} color={'text.secondary'}>
                                <strong>This action requires {creditCheckModalData.credits_to_deduct} credit(s).</strong> Because you have Auto Upgrade enabled, upon confirming, your subscription will
                                automatically upgrade to the next tier.
                            </BodySmall>

                            <Box mt={3}>
                                <BodySmall limit={false} color={'text.secondary'} display={'inline'}>
                                    Would you like to confirm this action? Learn more in
                                </BodySmall>

                                <BodySmall
                                    color={'primary.main'}
                                    limit={false}
                                    display={'inline'}
                                    ml={1}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            textDecorationLine: 'underline',
                                            textUnderlineOffset: 2
                                        }
                                    }}
                                    onClick={() => navigate('/v2/settings/plan-and-payment')}
                                >
                                    Plan & Payments
                                </BodySmall>
                            </Box>
                        </Box>
                    ) : (
                        <Box mt={3}>
                            <BodySmall limit={false} color={'text.secondary'} display={'inline'}>
                                You do not have enough credits to perform this action. Upgrade your subscription under
                            </BodySmall>

                            <BodySmall
                                color={'primary.main'}
                                limit={false}
                                display={'inline'}
                                ml={1}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecorationLine: 'underline',
                                        textUnderlineOffset: 2
                                    }
                                }}
                                onClick={() => navigate('/v2/settings/plan-and-payment')}
                            >
                                Plan & Payments
                            </BodySmall>
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};
