import { Grid, BodyXS, EditIcon } from '@parspec/pixel';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

interface CardKeyPropTypes {
    keyName: string;
    value: string | number | undefined;
    notesContent?: string;
    handleEditNotes?: (id: number, notesContent: string, flag: string) => void;
    isNotes?: boolean;
    flag?: string;
    isDate?: boolean;
}

const CardKeyValue: React.FC<CardKeyPropTypes> = ({ keyName, value, notesContent, handleEditNotes, isNotes, flag, isDate }) => {
    const handleNotes = (id: number, notesContent: string) => {
        if (handleEditNotes && flag) {
            handleEditNotes(id, notesContent, flag);
        }
    };

    let _value = value;
    if (isDate && value) {
        const date = new Date(value);
        const _month = date.getMonth();
        const _date = date.getDate();
        const year = date.getFullYear();
        _value = `${months[_month]} ${_date}, ${year}`;
    }

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                <BodyXS color={'text.secondary'} fontWeight={400}>
                    {keyName}
                </BodyXS>
            </Grid>

            {isNotes ? (
                <Grid item xs={6}>
                    <BodyXS
                        sx={{
                            cursor: 'pointer'
                        }}
                        color="primary.main"
                        onClick={() => handleNotes(Number(value), notesContent!)}
                    >
                        {'View/edit  '} <EditIcon fontSize="small" />
                    </BodyXS>
                </Grid>
            ) : (
                <Grid item xs={6}>
                    <BodyXS color={'text.primary'} fontWeight={500}>
                        {_value || '_'}
                    </BodyXS>
                </Grid>
            )}
        </Grid>
    );
};

export default CardKeyValue;
