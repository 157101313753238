import { axios } from 'src/app/Axios';

import { PricingHistoryPostPayload, PricingHistoryPostResponse } from './apiTypes';

export const endpoints = {
    postPricingHistoryUrl: '/pricing/search'
};

export function postPricingHistory({ payload, controller }: { payload: PricingHistoryPostPayload; controller?: AbortController }) {
    return axios.post<PricingHistoryPostResponse>(endpoints.postPricingHistoryUrl, payload, { signal: controller?.signal });
}
