import { IErrorStateType, IProfileState } from '../MyProfile.types';

export const initialErrorState: IErrorStateType = {
    firstName: { isError: false, title: '' },
    lastName: { isError: false, title: '' },
    currentPassword: { isError: false, title: '' },
    newPassword: { isError: false, title: '' },
    confirmPassword: { isError: false, title: '' }
};

export const initialProfileState: IProfileState = {
    firstName: '',
    lastName: '',
    email: '',
    currentPassword: {
        showPassword: false,
        data: ''
    },
    newPassword: {
        showPassword: false,
        data: ''
    },
    confirmPassword: {
        showPassword: false,
        data: ''
    }
};

export const newPasswordVerifier = (password: string) => {
    if (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\s\S]{8,255}$/.test(password)) {
        return true;
    } else {
        return false;
    }
};

export const trimEmptySpaces = (nameStr: string) => {
    return nameStr.trim().replace(/\s+/g, ' ');
};
