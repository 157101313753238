import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import { Skeleton, Box } from '@parspec/pixel';

import { useValidateNewUserSignUpInvitationMutation } from '../../queries';
import { LinkExpired } from '../../shared/components/LinkExpired';

// This component is used to validate the token mail sent to the NEW user
export const VerifyNewUserSignupInvitation = () => {
    const { token } = useParams();

    const navigate = useNavigate();

    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

    const { data: validateNewUserSignupInvitation, isLoading: isTokenValidLoading } = useValidateNewUserSignUpInvitationMutation(token || '');

    useEffect(() => {
        if (!validateNewUserSignupInvitation?.data) {
            return;
        }

        if (validateNewUserSignupInvitation.data.message === 'Link verified') {
            localStorage.setItem('company_email', validateNewUserSignupInvitation.data.company_email);
            setIsTokenValid(true);
        }

        if (validateNewUserSignupInvitation.data.state === 'company_info') {
            navigate('/signup/company_info');
        }
        if (validateNewUserSignupInvitation.data.state === 'personal_info') {
            navigate('/signup/personal_info');
        }
    }, [validateNewUserSignupInvitation?.data]);

    return (
        <Box
            width="100vw"
            minHeight="100vh"
            py={8}
            bgcolor="#091535"
            sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(assets/images/login/login-bg.png)`
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" mb={14}>
                    <Box height="45px" width="45px" mr={3}>
                        <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                    </Box>

                    <Box>
                        <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                    </Box>
                </Box>

                <Box>
                    {!isTokenValidLoading ? (
                        <>{!isTokenValid && <LinkExpired />}</>
                    ) : (
                        <Box>
                            <Skeleton variant="rectangular" color="light" width="337px" height="427px" />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
