import { BodyBig, BodySmall, Box, Info, Modal, ModalFooter } from "@parspec/pixel";

import { useRemoveMfgFromCompanyMutation } from "./queries";

interface RemoveManufacturersDialogProps {
    open: boolean;
    handleDialog: () => void;
    confirmAction: () => void;
    selectedRowIds: number[];
}

const RemoveManufacturers = (props: RemoveManufacturersDialogProps) => {
    const { open, handleDialog, confirmAction, selectedRowIds } = props;

    const { mutateAsync: removeRepresentedMfg, isLoading } = useRemoveMfgFromCompanyMutation();

    const header = (
        <BodyBig fontWeight={500}>
            Remove Manufacturer(s) from Company catalog?
        </BodyBig>
    );

    const confirmRemove = async () => {
        const payload = {
            company_group_man_ids: selectedRowIds
        }
        await removeRepresentedMfg({payload, catalogTab: false})
        confirmAction()
    }
    
    return (
        <>
            <Modal
                open={open}
                header={header}
                footer={<ModalFooter onAccept={confirmRemove} onReject={handleDialog} continueButtonLabel="Remove" continueButtonColor="error" isLoading={isLoading}/>}
            >
                <Box padding={'16px 0px'}>
                    <BodySmall fontWeight={700} color={'text.secondary'} marginBottom={'16px'}>
                        {"Are you sure you want to remove the " + selectedRowIds.length + " manufacturer(s) selected?"}
                    </BodySmall>
                    <Info>
                        <BodySmall lines={3} color={'primary.main'}>
                            <strong>This list of manufacturers is used by your entire organization.</strong> If<br />
                            you are trying to make a change specific to your account, consider<br />
                            using the preferred tag, which will not affect other users.
                        </BodySmall>
                    </Info>
                </Box>
            </Modal>
        </>
    )
}

export default RemoveManufacturers;