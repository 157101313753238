export const trimEmptySpacesHandler = (nameStr: string) => {
    if (nameStr && typeof nameStr === 'string') {
        return nameStr.trim().replace(/\s+/g, ' ');
    } else {
        return '';
    }
};

export const removeAllSpacesHandler = (nameStr: string) => {
    if (nameStr && typeof nameStr === 'string') {
        return nameStr?.trim().replace(/\s+/g, '');
    } else {
        return '';
    }
};

export const sanitizePhoneNumber = (inputValue: string): string => {
    if (inputValue && typeof inputValue === 'string') {
        return inputValue?.trim().replace(/[^0-9+-]/g, '');
    } else {
        return '';
    }
};

export const countryOptions = [
    { label: 'USA', value: 'USA' },
    { label: 'Canada', value: 'Canada' }
];

export const USA = 'USA';
export const CANADA = 'Canada';

export const modifiedStateList = (countryStates: string[]) => {
    const stateOptions: { label: string; value: string }[] = countryStates.map((data: string) => ({ label: data, value: data }));

    return stateOptions;
};

export function findChangedFieldsHandler(obj1: any, obj2: any) {
    const newObj = {};

    function compareObjects(o1: any, o2: any, result: any, fieldName: string) {
        for (const key in o2) {
            if (key in o1) {
                if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
                    if (o2[key] === null) {
                        if (fieldName === '' && (key === 'picture' || key === 'logo')) {
                            // result[key] = null;
                            //skipping these here since we will be adding them seperately using FormData() on the onSubmit handler
                            continue;
                        } else {
                            if (fieldName === 'cover_page_presets' && ['notes', 'pre_fill_primary_title', 'pre_fill_secondary_title'].includes(key)) {
                                result[key] = '';
                            } else if (fieldName === 'quote_general_tnc' && ['project_notes_text', 'general_tnc_text'].includes(key)) {
                                result[key] = '';
                            } else {
                                result[key] = false;
                            }
                        }
                    } else {
                        const fieldCondition =
                            (fieldName === 'quote_cover_page' && key === 'opening_notes_text') ||
                            (fieldName === 'quote_general_tnc' && key === 'closing_notes_text') ||
                            (fieldName === 'quote_price_lead_time_option' && key === 'option_notes_text') ||
                            fieldName === 'attachments';

                        if (fieldCondition) {
                            result[key] = o2[key];
                        } else {
                            result[key] = {};
                            compareObjects(o1[key], o2[key], result[key], key);
                        }
                    }
                } else {
                    if (key === 'cover_page_template_id' && (fieldName === 'submittal' || fieldName === 'o_m')) {
                        result[key] = o2[key] === null ? 1 : o2[key];
                    } else {
                        if (fieldName === 'attachments') {
                            result[key] = o2[key];
                        }
                        if (o1[key] !== o2[key]) {
                            result[key] = o2[key];
                        }
                    }
                }
            }
        }
    }

    compareObjects(obj1, obj2, newObj, '');
    return newObj;
}

export const payloadFormatter = (obj: any) => {
    const newObj: any = {};
    const existingObj: any = {};
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            if (obj[key] === null) {
                newObj[key] = null;
            } else {
                existingObj[key] = obj[key];
            }
        } else {
            newObj[key] = obj[key];
        }
    }

    return { company_details: newObj, ...existingObj };
};
