import { axios } from 'src/app/Axios';
import { CompanyLocationResponse, BranchDetailsReponse, BranchDetailsRequest } from './apiTypes';

export const endPoints = {
    company: '/api/company/',
    branchDetails: (id: number) => `/api/company/${id}/`,
    // deleteBranchLocation: (branchId: number) => `/api/company/${branchId}/`,
    deleteBranchLocation: (branchId: number, dstCompanyID: number) => `/api/company/${branchId}/?dst_company=${dstCompanyID}`
};

// ################### GET CALLS ##########################
export const getAllBranchLocationInfo = async () => {
    return axios.get<CompanyLocationResponse[]>(endPoints.company);
};

export const getBranchDetails = async (id: number) => {
    return axios.get<BranchDetailsReponse>(endPoints.branchDetails(id));
};

// ################### MUTATION CALLS ##########################
export const patchBranchDetails = async (props: { id: number; data: any }) => {
    return axios.patch<BranchDetailsRequest>(endPoints.branchDetails(props.id), props.data);
};

export const addNewBranchDetails = async (props: { data: any }) => {
    return axios.post<BranchDetailsRequest>(endPoints.company, props.data);
};

export const deleteBranchLocation = async (props: { branchId: number; dstCompanyID: number; userId: number }) => {
    return axios.delete(endPoints.deleteBranchLocation(props.branchId, props.dstCompanyID));
};
