import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Tabs } from '@parspec/pixel';

import { TAB_OPTIONS } from './shared/constants';
import { setRouteToLocalStorage } from '../shared/utils/utils';
import Filters from './shared/components/Filters';

const ReportingDashboardTabs: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleTabChange = (tabValue: string) => {
        setRouteToLocalStorage(tabValue);
        navigate(tabValue);
    };

    return (
        <>
            <Box display="flex" flexDirection="column" minHeight={0} borderBottom="1px solid" borderColor="neutral.main" px={4}>
                <Tabs onChange={handleTabChange} options={TAB_OPTIONS} selectedTab={pathname} />
            </Box>
            <Box py={4} px={6} bgcolor="neutral.light">
                <Filters />
            </Box>
        </>
    );
};

export default ReportingDashboardTabs;
