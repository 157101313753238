import { rest } from 'msw';

import { pricingHistoryResponseData, quoteMetadata } from './mockData';

const pricingHandlers = [
    rest.get('*/bom/:bomId/quote_metadata/', (_req, res, ctx) => {
        return res(ctx.delay(1000), ctx.json(quoteMetadata));
    }),
    rest.patch('*/bom/:bomId/quote_metadata/:bodId/', async (req, res, ctx) => {
        const reqBody = await req.json();
        const { bodId } = req.params;
        return res(ctx.json({ ...reqBody, bod_id: Number(bodId) }));
    }),
    rest.post('*/pricing_history_search', async (_req, res, ctx) => {
        return res(ctx.json(pricingHistoryResponseData));
    })
];

export { pricingHandlers };
