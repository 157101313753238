import { Grid, Skeleton, Button, FileSelector, BodySmall, BodyXS, FileSelectorFileType, Box } from '@parspec/pixel';

import LogoFileUploaded from './LogoFileUploaded';

import { ISectionLogo, acceptedFileFormats } from '../constants';
import ApplyToAllBomsButton from './ApplyToAllBomsButton';

function SectionLogo({ isDocumentLogoLoading, logo, onDelete, isDeleting, isUpdating, onFileUpload, title, disabled = false, sectionName, subSectionName }: ISectionLogo) {
    return (
        <Grid container maxHeight={200} sx={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? '0.4' : '1' }}>
            <Grid item xs={4}>
                <BodySmall lines={3}>{title ? title : 'Logo'}</BodySmall>
            </Grid>
            <Grid item xs={8}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <Grid container direction={'column'} gap={2}>
                            <Grid item>
                                {!isDocumentLogoLoading ? (
                                    <>
                                        {logo ? (
                                            <LogoFileUploaded logo={logo} onClick={onDelete} isDeleteing={isDeleting} isUpdating={isUpdating} />
                                        ) : (
                                            <FileSelector
                                                acceptedFormats={acceptedFileFormats}
                                                placeholder="  Drag and drop files here, or:"
                                                maxFiles={2}
                                                onSelect={(data: File[] | FileSelectorFileType[]) => onFileUpload(data as File[], null)}
                                                isLoading={isUpdating}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Skeleton sx={{ bgcolor: 'rgba(0,0,0,0.05)' }} animation="wave" height={109} variant="rounded" width={160} />
                                )}
                            </Grid>
                            <Grid item>
                                <BodyXS limit={false}>Supported file types: PNG, JPG</BodyXS>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item alignSelf={'center'} xs={3.5} sx={isUpdating || isDeleting ? { opacity: '0.4', pointerEvents: 'none' } : null}>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <Button variant="text" size="medium" color="primary" onClick={() => onFileUpload(null, 'primary')}>
                                    Use Primary Logo
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="text" size="medium" color="primary" onClick={() => onFileUpload(null, 'secondary')}>
                                    Use Secondary Logo
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="text" size="medium" color="primary" onClick={() => onFileUpload(null, 'project')}>
                                    Use Project Logo
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2.5}>
                        <Box display="flex" justifyContent="flex-end">
                            <ApplyToAllBomsButton sectionName={sectionName} subSectionName={subSectionName} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SectionLogo;
