import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BodyMedium, Box } from '@parspec/pixel';

import PreviewContext from '../../Context';
import { useUpdateStakeholderInfoMutation, useGetStakeholdersQuery } from '../../../../queries';
import { StakeholderInfoResponse } from '../../../../queries/apiTypes';
import StakeholderInfo from '../../../../shared/StakerHolderInfo';
import Loader from '../../Loader';
import { PRESET_RESPONSE } from '../../../constants';

const StakeHolder = () => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);
    const [stakeHoldersInfo, setStakeholdersInfo] = useState<StakeholderInfoResponse[]>();
    const [isTextFieldChanged, setIsTextValueChanged] = useState(false);
    const updatedDocumentType = documentType === 'o_m' ? 'om' : documentType;

    const { data: stakeholdersApiData, isFetching } = useGetStakeholdersQuery(Number(bomId || 0), updatedDocumentType || '', {
        enabled: Boolean(bomId) && presetResponse === PRESET_RESPONSE.SUCCESS,
        staleTime: 0
    });

    const { mutateAsync: updateStakeHolder } = useUpdateStakeholderInfoMutation();

    useEffect(() => {
        if (stakeholdersApiData?.data) {
            setStakeholdersInfo(stakeholdersApiData?.data);
        }
    }, [stakeholdersApiData?.data]);

    const updateStakeHolderState = (stakeHolder: StakeholderInfoResponse) => {
        setStakeholdersInfo((old: any) => {
            const updated = old?.map((item: StakeholderInfoResponse) => (item.id === stakeHolder.id ? stakeHolder : item));
            return updated;
        });
    };

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, stakeHolder: StakeholderInfoResponse) {
        const { name, value } = e?.target || {};
        updateStakeHolderState({ ...stakeHolder, [name]: value });
        handlePreviewImage(false);
        setIsTextValueChanged(true);
    }

    async function handleOnBlur(_e: any, stakeHolderInfo: StakeholderInfoResponse) {
        if (isTextFieldChanged) {
            await updateStakeHolder({
                bomId: Number(bomId),
                documentType: updatedDocumentType || '',
                stakeHolderInfo
            });
            handlePreviewImage(true);
        }
    }

    function handleOnFocus() {
        setIsTextValueChanged(false);
    }

    async function handleSwitch(e: React.ChangeEvent<HTMLInputElement>, stakeHolder: StakeholderInfoResponse) {
        updateStakeHolderState({ ...stakeHolder, is_active: e.target.checked });
        await updateStakeHolder({
            bomId: Number(bomId),
            documentType: updatedDocumentType || '',
            stakeHolderInfo: { ...stakeHolder, is_active: e.target.checked }
        });
        handlePreviewImage(true);
    }

    return (
        <Box display="flex" flexDirection="column" gap={3} mt={2}>
            <BodyMedium color={'secondary.main'} fontWeight={500}>
                Stakeholders Information
            </BodyMedium>

            {isFetching ? (
                <Loader />
            ) : (
                stakeHoldersInfo?.map((stakeHolder) => (
                    <StakeholderInfo
                        stakeHolderInfo={stakeHolder}
                        key={stakeHolder.id}
                        handleChange={handleOnChange}
                        handleOnFocus={handleOnFocus}
                        onToggleStakeHolder={handleSwitch}
                        blurAction={handleOnBlur}
                    />
                ))
            )}
        </Box>
    );
};

export default StakeHolder;
