import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Snackbar } from '@parspec/pixel';

import { ImportMoreProducts } from '../ImportMoreProducts';
import ExportButton from '../shared/ExportButton';
import { UploadedScheduleResponse, UploadedSubmittalPackageResponse } from '../ImportMoreProducts/queries/apiTypes';
import CreateSectionButton from '../shared/CreateSectionButton';
import SortButton from '../shared/SortButton';
import { useDatasheetAutoSearchMutation, useOmAutoSearchMutation } from '../queries';
import { isRecognisedFileFormat } from '../shared/utils';
import { useUserAccountPreferencesQuery } from '../../Settings/MyProfile/queries';
import { BOM_COPIED_MESSAGE } from '../../shared/constants';

const BomTabHeader = () => {
    const [openImportProductsModal, setOpenImportProductsModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();
    const { bomId, projectId } = useParams();

    const { data: userPreferences, isLoading: isUserPreferencesLoading } = useUserAccountPreferencesQuery();

    const { mutateAsync: autoSearchDatasheet, isLoading: isAutoSearchDatasheetLoading } = useDatasheetAutoSearchMutation();
    const { mutateAsync: autoSearchOM, isLoading: isAutoSearchOMLoading } = useOmAutoSearchMutation();

    const toggleImportProductsModal = () => {
        setOpenImportProductsModal((old) => !old);
    };

    const onFileUploadSuccess = async (res: UploadedScheduleResponse | UploadedSubmittalPackageResponse, packageType: 'schedule' | 'submittal_package') => {
        if (packageType === 'schedule') {
            if ('file_format' in res && userPreferences?.data.autoselection && res.bods.length > 0) {
                const data = {
                    bomId: Number(bomId),
                    input: {
                        bod_id: res.bods,
                        user_preference: userPreferences?.data.autoselection_user_group
                    }
                };

                setOpenImportProductsModal(false);
                await autoSearchDatasheet(data);
                await autoSearchOM(data);
            }
        }

        if (isRecognisedFileFormat(res)) {
            setOpenImportProductsModal(false);
            return;
        }
        navigate(`/v2/project/${projectId}/bom/${bomId}/addProducts`);
    };

    const onCopyExistingBomSuccess = () => {
        setSnackbarMessage(BOM_COPIED_MESSAGE);
        toggleImportProductsModal();
    };

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Box display="flex" justifyContent="flex-end">
                <Box display="flex" gap={2} alignItems="center">
                    <SortButton />
                    <CreateSectionButton />
                    <ExportButton />
                    <Button
                        onClick={() => toggleImportProductsModal()}
                        color="tertiary"
                        variant="contained"
                        isLoading={isUserPreferencesLoading || isAutoSearchDatasheetLoading || isAutoSearchOMLoading}
                    >
                        Import Products
                    </Button>
                </Box>
            </Box>
            {openImportProductsModal && (
                <ImportMoreProducts open={openImportProductsModal} onSuccess={onFileUploadSuccess} onCloseClick={toggleImportProductsModal} onCopyExistingBomSuccess={onCopyExistingBomSuccess} />
            )}
        </>
    );
};

export default BomTabHeader;
