export const productDetails = [
    {
        id: 355299,
        document: 176979,
        document_url: 'https://parspec-staging.s3.us-east-2.amazonaws.com/media/submittals/18985/Submittal_edgf_2023_03_22_flattened_V2.pdf',
        type: 'mock type',
        manufacturer: 'mock manufacturer',
        model_number: 'mock_1234',
        document_selection_status: 'CONFIRMED',
        description: 'mock Description of product',
        bod_id: 361318,
        specification: 'BOD',
        hide_origin_line: false,
        created_at: '2023-06-22T06:28:34.040079Z',
        updated_at: '2023-06-22T06:28:34.040123Z'
    }
];
