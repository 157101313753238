export const datasheetTabData = [
    {
        bod_id: 337635,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'NOT_SELECTED',
            document_selection_status: 'CONFIRMED',
            is_deleted: false,
            created_at: '',
            updated_at: '',
            document_hash: '73c8bf87857e29662317e8cd26de96ef63b632bd80d5ebae796cea91bd5d3926',
            document_hash_version: 1
        }
    },
    {
        bod_id: 2,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'NOT_SELECTED',
            document_selection_status: 'CONFIRMED',
            is_deleted: false,
            created_at: '',
            updated_at: '',
            document_hash: 'bd9d23b4ad09db5fe28d82eaef49b108dde4eca6c995b58bbf485cfeabc9d1e6',
            document_hash_version: 1
        }
    },
    {
        bod_id: 3,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'AUTO_SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: '',
            document_hash: '077e75c9bf35021bc32adf7e9386f11a95731d9cd44ddbe7d325334a4d579f2d',
            document_hash_version: 1
        }
    },
    {
        bod_id: 4,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'SAVED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    },
    {
        bod_id: 5,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    }
];

export const datasheetStatsData = {
    selected_datasheet: 50,
    annotated_datasheet: 25,
    hidden_product: 15,
    total: 100
};

export const datasheetReuseData = [
    {
        bod_id: 1,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    },
    {
        bod_id: 2,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    },
    {
        bod_id: 3,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    },
    {
        bod_id: 4,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'NOT_SELECTED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    },
    {
        bod_id: 5,
        notes: 'this is a test notes',
        selected_datasheet_id: 12,
        selected_datasheet: {
            id: 123,
            document_id: 13,
            hidden: [1, 4, 5],
            Is_blank_document: true,
            annotation_status: 'ANNOTATED',
            document_selection_status: 'SELECTED',
            is_deleted: false,
            created_at: '',
            updated_at: ''
        }
    }
];
