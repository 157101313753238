import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Tabs, Skeleton } from '@parspec/pixel';

import { TAB_VALUES, TAB_OPTIONS, PRICING_ENABLED_TAB_OPTIONS, PRICING_DISABLED_TAB_OPTIONS } from './shared/constants';
import BomBreadcrumb from './shared/BomBreadcrumb/BomBreadcrumb';
import { setRouteToLocalStorage } from '../shared/utils/utils';
import { useCheckAccessForProductFinderOrQuoting } from '../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import { useUserEventCount } from '../shared/UserActivityTracking/TrackActivityCount';
import { useFeatureFlag } from '../shared/UserActivityTracking/useFeatureFlag';

interface BomHeaderProps {
    tabValue: string | null;
}

const BomHeader = (props: BomHeaderProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { quotingAccess, isLoading } = useCheckAccessForProductFinderOrQuoting();
    const { pushUserEventCount } = useUserEventCount();

    const { tabValue } = props;
    const { enable_quoting } = useFeatureFlag();

    const PRICING_ENABLED_TAB_OPTIONS_ARR = quotingAccess ? PRICING_ENABLED_TAB_OPTIONS : TAB_OPTIONS;

    const tabOptions = enable_quoting ? PRICING_ENABLED_TAB_OPTIONS_ARR : PRICING_DISABLED_TAB_OPTIONS;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!tabValue || !tabOptions.find((tab) => tab.value === tabValue)) {
            handleTabChange('dataSheets');
            saveNavigationHistory('dataSheets');
        }
    }, [quotingAccess, enable_quoting]);

    const handleTabChange = (tabValue: string) => {
        if (tabValue === TAB_VALUES.PRICING_AND_LEAD_TIME && !enable_quoting) {
            pushUserEventCount({ eventType: 'click_diabled_p&lt_tab', needCummulation: false });
            return;
        }
        saveNavigationHistory(tabValue);
        navigate({
            search: new URLSearchParams({ tab: tabValue }).toString()
        });
    };

    function saveNavigationHistory(value: string) {
        setRouteToLocalStorage(`${pathname}?tab=${value}`);
    }

    return (
        <>
            <Box p={4} pb={0} display="flex" alignItems="center" gap={2} height="40px">
                <BomBreadcrumb />
            </Box>
            {!isLoading ? (
                <Box px={4}>
                    <Tabs onChange={handleTabChange} options={tabOptions} selectedTab={tabValue || ''} />
                </Box>
            ) : (
                <Box display={'flex'} borderBottom={'1px solid'} borderColor={'neutral.main'}>
                    {Array.from({ length: 4 }, (_, index) => (
                        <Box key={index} mx={4} py={3} px={4}>
                            <Skeleton variant="rectangular" height={'24px'} width={'95px'} />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};

export default BomHeader;
