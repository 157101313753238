import { BodyBig, BodyMedium, BodySmall, Box, Grid, Select, SelectChangeEvent, Switch } from '@parspec/pixel';

import { optionsForLogo } from 'src/features/BOM/FinalDocuments/Compile/shared/utils';
import { QuoteTemplateInterface } from '../../../queries/apiTypes';
import {
    OPTIONS_FOR_NUMBER,
    OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY,
    OPTIONS_FOR_QUOTE_FOR_BID_DATE,
    OPTIONS_FOR_EXPIRATION_DATE,
    OPTIONS_FOR_ADDRESSED_TO
} from 'src/features/BOM/FinalDocuments/Compile/shared/utils/constants';
import {
    PRE_FILL_BID_DATE,
    PRE_FILL_EXPIRATION_DATE,
    PRE_FILL_JOB_NAME,
    PRE_FILL_QUOTE_NUMBER,
    QUOTE_HEADER_FOOTER,
    QUOTE_COVER_PAGE,
    PRE_FILL_PREPARED_BY_LOGO,
    PRE_FILL_HEADER_LOGO,
    PRE_FILL_ADDRESSED_TO
} from '../utils/constants';
import AttributePrefillTitle from '../../CommonSections/AttributePrefillTitle';

interface QuoteHeaderFooterPreferenceProps {
    changeHandler: (e: SelectChangeEvent<unknown>, presetName: string, field_name?: string) => void;
    templateState: QuoteTemplateInterface;
}

const HEADER_FORMAT_COLUMN_OPTIONS = [
    { label: 'Job Name', value: 'job_name' },
    { label: 'Addressed To', value: 'addressed_to' },
    { label: 'Project Location', value: 'project_location' },
    { label: 'Contact', value: 'contact' },
    { label: 'Quote Number', value: 'quote_number' },
    { label: 'Bid Date', value: 'bid_date' },
    { label: 'Expiration Date', value: 'expiration_date' }
];

const FOOTER_FORMAT_COLUMN_OPTIONS = [
    { label: 'Left Footer', value: 'left_footer' },
    { label: 'Right Footer', value: 'right_footer' },
    { label: 'Date', value: 'date' },
    { label: 'Page Number', value: 'page_number' },
    { label: 'Hyperlink to Quote Start', value: 'hyperlink' }
];

export const QuoteHeaderFooterPreference = (props: QuoteHeaderFooterPreferenceProps) => {
    const { changeHandler, templateState } = props;

    const onChangeHeaderFooterPrefill = (event: SelectChangeEvent<unknown>) => {
        changeHandler(event, QUOTE_HEADER_FOOTER);
    };

    const handleChangeCoverPagePrefill = (event: SelectChangeEvent<unknown>) => {
        changeHandler(event, QUOTE_COVER_PAGE);
    };
    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState?.quote_header_footer?.header_footer_section}
                    onChange={(e) => changeHandler(e, QUOTE_HEADER_FOOTER)}
                    name="header_footer_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Header & Footer
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose what you want to show on your headers and footers.
            </BodySmall>
            <BodySmall limit={false} color="text.secondary">
                You can edit these details when customizing your quote.
            </BodySmall>

            <Box mt={6}>
                <Box>
                    <Box display="flex " position="relative" height={'100%'}>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Header Format
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {HEADER_FORMAT_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState?.quote_header_footer[option.value]}
                                                    onChange={(e) => changeHandler(e, QUOTE_HEADER_FOOTER)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />{' '}
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>

                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Footer Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {FOOTER_FORMAT_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState?.quote_header_footer[option.value]}
                                                    onChange={(e) => changeHandler(e, QUOTE_HEADER_FOOTER)}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />{' '}
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <AttributePrefillTitle />
                        <Grid container flexDirection={'column'} rowSpacing={4} width="50vw">
                            <Grid container item columnSpacing={4}>
                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_JOB_NAME}
                                        label={'Pre-fill Format'}
                                        options={OPTIONS_FOR_NAME_WITHOUT_CUSTOM_ENTRY}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_job_name}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_BID_DATE}
                                        label={'Bid Date'}
                                        options={OPTIONS_FOR_QUOTE_FOR_BID_DATE}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_bid_date}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_HEADER_LOGO}
                                        label={'Header Logo'}
                                        options={optionsForLogo}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_header_logo}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_ADDRESSED_TO}
                                        label={'Addressed To'}
                                        options={OPTIONS_FOR_ADDRESSED_TO}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_addressed_to}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item columnSpacing={4}>
                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_QUOTE_NUMBER}
                                        label={'Quote Number'}
                                        options={OPTIONS_FOR_NUMBER}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_quote_number}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_EXPIRATION_DATE}
                                        label={'Expiration Date'}
                                        options={OPTIONS_FOR_EXPIRATION_DATE}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={onChangeHeaderFooterPrefill}
                                        value={templateState?.quote_header_footer.pre_fill_expiration_date}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        name={PRE_FILL_PREPARED_BY_LOGO}
                                        label={'Prepared By Logo'}
                                        options={optionsForLogo}
                                        optionLabelKeyname="name"
                                        optionValueKeyname="id"
                                        onChange={handleChangeCoverPagePrefill}
                                        value={templateState?.quote_cover_page.pre_fill_preparedby_logo}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
