import { Dispatch, SetStateAction, useEffect } from 'react';

import { useParams } from 'react-router-dom';
// import 'xml-viewer-component';

import { Box } from '@parspec/pixel';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { useGetContentDetailQuery, useGetXmlDetailQuery } from '../queries';
import PdfViewerHeader from './PdfViewerHeader';

interface XmlViewerProps {
    setConfirmSelection: Dispatch<SetStateAction<boolean>>;
    handleChangeFile: Dispatch<SetStateAction<boolean>>;
}

const XmlViewer = (props: XmlViewerProps) => {
    const { bomId } = useParams();
    const { data } = useGetContentDetailQuery(Number(bomId));
    const { data: xmlData } = useGetXmlDetailQuery(Number(bomId), getProxyFileUrl(data?.data?.file_info || ''));
    useEffect(() => {
        props.setConfirmSelection(true);
    }, [data]);

    return (
        <>
            <PdfViewerHeader handleChangeFile={props.handleChangeFile} isPdf={false} />
            <Box display="flex" width="100%" height="calc(100vh - 120px)" overflow="auto">
                <xml-viewer-component xml={xmlData?.data}></xml-viewer-component>
            </Box>
        </>
    );
};

export default XmlViewer;
