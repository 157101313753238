import { axios } from 'src/app/Axios';

import { SubmittalParams, DatasheetBoundResponse, UpdateAdjustPayloadType, AddDocumentToBodsRequest } from './apiTypes';

export const endPoints = {
    dataSheetBound: (bomId: string, submitalPackageId: number) => `bom/${bomId}/uploaded_doc/submittal_package/${submitalPackageId}`,
    mutateHiddenPagesUrl: (bomId: string, datasheetResponseId: number) => `bom/${bomId}/uploaded_doc/submittal_package/${datasheetResponseId}`,
    addDocumentToBodsUrl: (bomId: number, submitalPackageId: number) => `bom/${bomId}/uploaded_doc/submittal_package/${submitalPackageId}/confirm/existing_bods/
    `
};

export const getProductDatasheetBound = async (bomId: string, submitalPackageId: number) => axios.get<DatasheetBoundResponse>(endPoints.dataSheetBound(bomId, submitalPackageId));

export const adjustPageUpdate = async ({ bomId, submittalPackageId, ...payload }: UpdateAdjustPayloadType) => {
    axios.patch(`bom/${bomId}/uploaded_doc/submittal_package/${submittalPackageId}`, payload);
};

export const confirmSubmittalPackage = async ({ bomId, submittalPackageId }: SubmittalParams) => {
    return axios.post(`bom/${bomId}/uploaded_doc/submittal_package/${submittalPackageId}/confirm`);
};

export const addDocumentToBods = async ({ bomId, submittalPackageId, selectedBodIds }: AddDocumentToBodsRequest) => {
    return axios.post(endPoints.addDocumentToBodsUrl(bomId, submittalPackageId), { bod_list: selectedBodIds });
};
