import { createContext } from 'react';

import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { SpecificationValType } from '../../../shared/utils/constants';
import { MainProductDataType, MainProductComponentsDataType } from '../../pricing&LeadTime/mainProductTable';
import { OptionDataType } from '../../pricing&LeadTime/optionsTable';
import { SectionApiResponseType } from '../../queries/apiTypes';

interface MenuOptionsContextType {
    bomId: string;
    handleAddOption?: (props: ICellRendererParams) => Promise<void>;
    onAddBelow: (props: ICellRendererParams, updateTableKey?: boolean) => Promise<void>;
    onClickMoveSection: (props: ICellRendererParams) => void;
    onHide: (props: ICellRendererParams) => Promise<void>;
    onClickDelete: (props: ICellRendererParams) => void;
    onDuplicate: (props: ICellRendererParams) => Promise<void>;
    approveOption?: (productId: number) => Promise<void>;
    noOfOptionSections?: number;
    isOptionTable: boolean;
    onClickManageKit?: (props: ICellRendererParams) => void;
    onProductMoveIn?: (currentBodId: number) => void;
    onProductMoveOut?: (params: any) => void;
    onHighlight?: (params: any) => void;
}

export const MenuOptionsContext = createContext<MenuOptionsContextType | null>(null);

interface TableContextType {
    onProductToReplaceChange?: (productToReplaceVal: number | null, bodId: number) => void;
    lotMap?: Record<number, number | null>;
    handleSpecificationChange?: (specificationVal: SpecificationValType, bodId: number) => void;
    handleLotChange?: (rowNode: IRowNode, rowData: MainProductDataType, lotInfo: { lotValue: string; bodId: number; prevLotId: string }) => void;
    handleLeadTimeUnit?: (rowNode: IRowNode, payload: Partial<MainProductDataType | OptionDataType>) => void;
    handleDataReorder?: (() => void) | null;
    section?: SectionApiResponseType;
    isOptionTable?: boolean;
    tableData?: MainProductComponentsDataType[] | null;
    onCellEdit?: (props: any) => void;
}

export const TableContext = createContext<TableContextType>({});
