import { Box, RangeSlider, Select, SelectChangeEvent, TextField } from '@parspec/pixel';

import { FormFieldAction } from '../FormFieldAction';
import { RowContainer } from '../RowContainer';
import { RangeSliderValType, DropdownOptionsType } from '../../types';

interface SliderRowProps {
    showUnit: boolean;
    sliderInfo: RangeSliderValType;
    onSliderChange: (sliderVal: [number, number]) => void;
    onUnitChange?: (val: string) => void;
    unit?: string;
    unitOptions?: DropdownOptionsType;
    onLockChange: (isLocked: boolean) => void;
    header: string;
    unitInfo?: string;
    onTextfieldBlur: (event: React.FocusEvent<HTMLInputElement, Element> | React.KeyboardEvent<HTMLElement>, value: [number, number]) => void;
    onRangeBlur: (event: React.FocusEvent<HTMLInputElement, Element>, value: [number, number]) => void;
    onUnitBlur?: () => void;
    lastRow?: boolean;
    onClear: () => void;
    steps?: number;
    leftTextFieldWidth?: number;
    rightFieldWidth?: number;
    showPlus?: boolean;
    disableRow?: boolean;
}

export function SliderRow({
    showUnit,
    sliderInfo,
    onSliderChange,
    unit,
    unitOptions,
    onUnitChange,
    onLockChange,
    header,
    unitInfo,
    steps,
    leftTextFieldWidth = 79,
    rightFieldWidth = 79,
    lastRow = false,
    showPlus = false,
    disableRow = false,
    onClear: handleClear,
    onTextfieldBlur: handleTextfieldBlur,
    onUnitBlur: handleUnitBlur,
    onRangeBlur: handleRangeBlur
}: SliderRowProps) {
    function handleUnitChange(event: SelectChangeEvent<unknown>) {
        const value = event.target.value as string;
        if (onUnitChange) {
            onUnitChange(value);
        }
    }
    const isClearDisabled = sliderInfo.values[0] === sliderInfo.minVal && sliderInfo.values[1] === sliderInfo.maxVal;
    return (
        <RowContainer lastRow={lastRow}>
            <Box flex={1} marginRight={2}>
                <RangeSlider
                    step={steps || 10}
                    value={sliderInfo.values}
                    min={sliderInfo.minVal}
                    max={sliderInfo.maxVal}
                    color="primary"
                    onChange={onSliderChange}
                    leftTextfieldWidth={leftTextFieldWidth}
                    rightTextfieldWidth={rightFieldWidth}
                    textfieldHeight={36}
                    headerTitle={header}
                    onTextfieldBlur={handleTextfieldBlur}
                    onTextfieldEnterKeyDown={handleTextfieldBlur}
                    onRangeBlur={handleRangeBlur}
                    showPlus={showPlus}
                    disabled={disableRow}
                />
            </Box>
            {showUnit && !unitInfo && (
                <Box width="130px" mt="23px">
                    <Select label={'Unit'} options={unitOptions || []} fullWidth value={unit} onChange={handleUnitChange} onBlur={handleUnitBlur} disabled={disableRow} />
                </Box>
            )}
            {unitInfo && (
                <Box width="130px" mt="23px">
                    <TextField value={unitInfo} fullWidth label={''} disabled />
                </Box>
            )}
            <Box mt="23px" display="flex" alignItems="center">
                <FormFieldAction
                    isLocked={sliderInfo.locked}
                    onLockChange={onLockChange}
                    showClear
                    onClear={handleClear}
                    isDisabled={disableRow}
                    isClearDisabled={isClearDisabled}
                    tooltip="Strict matching"
                />
            </Box>
        </RowContainer>
    );
}
