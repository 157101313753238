import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BodySmall, Box, EditIcon, IconButton, Skeleton, Snackbar, TextField } from '@parspec/pixel';
import { useGetDatasheetDetailQuery, useUpdateDatasheetDetailMutation } from '../BOM/queries';
import { someThingWentWrongMsg, updateMsg } from './constants';

const ProductNotes = () => {
    const [editNotes, setEditNotes] = useState(false);
    const [notes, setNotes] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const textfieldRef = useRef<HTMLDivElement>(null);

    const { bomId, productId } = useParams();

    const { data: datasheetDetail, isLoading } = useGetDatasheetDetailQuery(bomId!, productId!, {
        enabled: Boolean(bomId) && Boolean(productId)
    });

    useEffect(() => {
        if (datasheetDetail?.data) {
            setNotes(datasheetDetail.data.notes);
        }
    }, [datasheetDetail?.data]);

    const { mutateAsync: updateMetadata, isLoading: updateMetadataLoading } = useUpdateDatasheetDetailMutation();

    const onPressEnter = (e: any) => {
        if (e.key === 'Enter') {
            handleEditNotes();
        }
    };

    //to toggle the state between showing of notes info and fields to update the notes
    const handleEditNotes = async () => {
        if (notes.trim() !== datasheetDetail?.data.notes) {
            try {
                await updateMetadata({
                    bomId: bomId!,
                    bodId: Number(productId)!,
                    notes
                });
                setEditNotes(false);
                setSnackbarMessage(updateMsg);
            } catch (e) {
                setSnackbarMessage(someThingWentWrongMsg);
            }
        } else setEditNotes(false);
    };

    const handleOutsideClick = (e: globalThis.MouseEvent) => {
        if (editNotes) {
            if (!textfieldRef.current) return;
            if (!textfieldRef.current.contains(e.target as Node)) {
                handleEditNotes();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [editNotes, notes]);

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            {isLoading && <Skeleton width="100%" height="30px" />}
            {!isLoading && (
                <Box display="flex" alignItems="center">
                    {!editNotes ? (
                        <Box>
                            <BodySmall color={!notes ? 'text.secondary' : ''}>{notes || 'Enter notes here'}</BodySmall>
                        </Box>
                    ) : (
                        <TextField
                            label="Notes"
                            fullWidth
                            size="small"
                            ref={textfieldRef}
                            value={notes}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
                            disabled={updateMetadataLoading}
                            onKeyDown={onPressEnter}
                        />
                    )}
                    <Box ml={5}>
                        <IconButton
                            onClick={() => {
                                setEditNotes(true);
                            }}
                            size="small"
                        >
                            <EditIcon color={editNotes ? 'primary' : 'secondary'} fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ProductNotes;
