import { ReactNode } from 'react';
import { ModalHeader, Modal, ModalFooter } from '@parspec/pixel';

type DeleteConfirmationDialogProps = {
    open: boolean;
    handleDialog: () => void;
    confirmAction: () => void;
    loading?: boolean;

    children?: ReactNode;
    continueButtonLabel?: string;
    cancelButtonLabel?: string;
};

const DeactivateConfirmationDialog = (props: DeleteConfirmationDialogProps) => {
    const { open, handleDialog, confirmAction, loading, continueButtonLabel, children, cancelButtonLabel } = props;

    return (
        <Modal
            open={open}
            onClose={handleDialog}
            header={<ModalHeader title={'Deactivate User?'} onClose={handleDialog} />}
            footer={
                <ModalFooter
                    continueButtonLabel={continueButtonLabel || 'Delete'}
                    cancelButtonLabel={cancelButtonLabel}
                    onAccept={confirmAction}
                    onReject={handleDialog}
                    isLoading={loading}
                    continueButtonColor="error"
                />
            }
        >
            <>{children}</>
        </Modal>
    );
};

export default DeactivateConfirmationDialog;
