import { ColDef } from 'ag-grid-community';

import { getPercent, getPercentColor } from '../shared/utils';
import { IBodsComponent, DatasheetTabDataResponse, BodResponse } from '../queries/apiTypes';

export const getStats = (data: any) => {
    if (data?.data) {
        const { selected_datasheet, annotated_datasheet, hidden_product, total } = data.data;
        const totalValid = total - hidden_product;
        return [
            {
                title: 'Selected Datasheets',
                count: `${selected_datasheet} of ${totalValid}`,
                helperText: `${getPercent(selected_datasheet, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(selected_datasheet, totalValid))
            },
            {
                title: 'Annotated Datasheets',
                count: `${annotated_datasheet} of ${totalValid}`,
                helperText: `${getPercent(annotated_datasheet, totalValid)}%`,
                helperTextColor: getPercentColor(getPercent(annotated_datasheet, totalValid))
            },
            {
                title: 'Products Hidden',
                count: hidden_product
            }
        ];
    }
    return [];
};

export const toolBarOptions = ['delete', 'search', 'hide', 'selectedItems', 'duplicate', 'move', 'createKit', 'highlight'];

export const getDatasheetLoadTime = (selectedItems: any[]) => {
    const loadTime = 1000; //time for one bod in milliseconds;
    const validBods = selectedItems.filter((item: any) => item?.selected_datasheet?.document_selection_status !== 'CONFIRMED');
    return validBods.length * loadTime + 270; //270 milliseconds is the static time for api calling
};

export const defaultColDef: ColDef = {
    sortable: false,
    menuTabs: [],
    resizable: true,
    suppressFillHandle: true
};

export const checkIfResfreshParentTable = <T extends IBodsComponent & DatasheetTabDataResponse>(tableData: T[], localTableData: T[] | null) => {
    let result = false;
    if (Array.isArray(localTableData) && tableData?.length === localTableData.length) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].components?.length !== localTableData[i].components?.length) {
                result = true;
                break;
            }

            tableData[i].components?.forEach((component) => {
                const localComponent = localTableData[i].components?.find((item) => item.id === component.id);
                if (localComponent && (localComponent.is_hidden !== component.is_hidden || localComponent.order !== component.order)) {
                    result = true;
                }
            });
        }
    }
    return result;
};

export const getIsTableDataUpdated = <T extends BodResponse & DatasheetTabDataResponse>(apiTableData: T[], localTableData: T[]) => {
    return apiTableData.some((productData, index) => {
        return (
            productData?.all_pages_hidden !== localTableData?.[index]?.all_pages_hidden ||
            productData?.is_hidden !== localTableData?.[index]?.is_hidden ||
            productData?.manufacturer !== localTableData?.[index]?.manufacturer ||
            productData?.qty !== localTableData?.[index]?.qty ||
            productData?.category !== localTableData?.[index]?.category ||
            productData?.model_number !== localTableData?.[index]?.model_number ||
            productData?.selected_datasheet_id !== localTableData[index]?.selected_datasheet_id ||
            productData?.is_highlighted !== localTableData?.[index]?.is_highlighted
        );
    });
};

export enum ADD_REUSE_OPTION_VALUES {
    REUSE = 'reuse',
    EXISTING_SUBMITTAL = 'existingSubmittal',
    NEW_DOCUMENT = 'newDocument'
}

export const ADD_REUSE_RADIO_OPTIONS = [
    {
        label: 'Reuse from another product in this BoM',
        value: ADD_REUSE_OPTION_VALUES.REUSE
    },
    { label: 'From an existing submittal', value: ADD_REUSE_OPTION_VALUES.EXISTING_SUBMITTAL },
    { label: 'Upload a new document', value: ADD_REUSE_OPTION_VALUES.NEW_DOCUMENT }
];
