import { Box } from "@parspec/pixel";
interface PagesHeaderProps {
  children: React.ReactNode;
}
const PagesHeader: React.FC<PagesHeaderProps> = ({ children }) => {
  return (
    <Box bgcolor={"secondary.contrastText"} p={6}>
      {children}
    </Box>
  );
};
PagesHeader.defaultProps = {
  children: <></>,
};
export default PagesHeader;
