import { BodyBig, BodySmall, Box, FileSelector, FileSelectorFileType, Link, Paper } from '@parspec/pixel';

import { environment } from 'src/environments';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { IFileType } from '../../../FinalDocuments/Compile/submittals&o_m/queries/apiTypes';
import ProjectImage from 'src/features/shared/CreateAndEditProjectModal/ProjectImage';
import { ACCEPTED_REFERENCE_IMAGE_TYPE, BEST_PRACTICES_URL, SUPPORTED_FILE_TYPE_FOR_REFERENCE_IMAGE } from '../constants';

// localImgPath is to reduce image load time in project image component. it has no other purpose
interface IReferenceImageSelectorForProductFinderProps {
    s3ImgPath: string;
    localImgPath: string;
    onFileUpload: (filePath: string) => void;
    onFileSelect: (filePath: string) => void;
    clearUploadedFile: () => void;
}

const ReferenceImageSelectorForProductFinder = (props: IReferenceImageSelectorForProductFinderProps) => {
    const { s3ImgPath, localImgPath, onFileUpload, clearUploadedFile, onFileSelect } = props;

    const onFileUploadedToS3 = (files: IFileType[]) => {
        if (files.length) {
            onFileUpload(environment.j + files[0].s3_file_path);
            return;
        }
    };

    const onFileChanged = (files: File[] | FileSelectorFileType[]) => {
        if (files.length) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                onFileSelect(fileReader.result as string);
            };
            fileReader?.readAsDataURL(files[0] as File);
        }
    };

    const handleDeleteImagePreview = () => {
        clearUploadedFile();
    };

    return (
        <>
            <BodyBig fontWeight={500} mt={6} mb={4}>
                Reference Image
            </BodyBig>
            <BodySmall limit={false} color="secondary.light" mb={3}>
                Refine your search by including an image similar to the product you are looking for. See our &nbsp;recommended&nbsp;
                <Link target="_blank" href={BEST_PRACTICES_URL} underline="none" sx={{ cursor: 'pointer' }}>
                    best practices here.
                </Link>
            </BodySmall>

            {!s3ImgPath && (
                <Box width="300px" height="150px">
                    <FileSelector
                        placeholder="Drag and drop a file here, or:"
                        url={`${environment.b}/api/generate_signed_url/?source=pf`}
                        onUpload={onFileUploadedToS3}
                        acceptedFormats={ACCEPTED_REFERENCE_IMAGE_TYPE}
                        helperText={SUPPORTED_FILE_TYPE_FOR_REFERENCE_IMAGE}
                        onSelect={onFileChanged}
                    />
                </Box>
            )}
            {s3ImgPath && (
                <Paper variant="outlined" sx={{ width: 300, height: 330 }}>
                    <Box>
                        <ProjectImage onDelete={handleDeleteImagePreview} url={localImgPath || getProxyFileUrl(s3ImgPath)} heading="" />
                    </Box>
                </Paper>
            )}
        </>
    );
};

export default ReferenceImageSelectorForProductFinder;
