import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from 'src/app/queryClient';
import {
    addMfgToCompanyData,
    endPoints,
    getParspecMfgData,
    getCompanyMfgData,
    postUploadMfgFile,
    removeMfgFromCompanyData,
    patchEditManufacturers,
    getListOfManufactuerList,
    getManufactuerList,
    postManufactuerList,
    patchManufactuerList,
    deleteManufacturerList,
    duplicateManufacturerList
} from './apis';
import { CompanyMfgDetailsResponse, ImportFileResponse, ManufacturerListInfo, ParspecMfgDetailsResponse } from './apiTypes';

const companyMfgKey = [endPoints.companyManufacturer];
const parspecMfgKey = [endPoints.parspecManufacturer];
const manufacturerListKey = [endPoints.manufacturerList];
const getMfgListKey = (mfgId: number) => [endPoints.getManufacturerListEditUrl(mfgId)];

export const getParsecMfgListAndMfgQueryData = () => {
    return {
        mfgQueryData: queryClient.getQueryData<AxiosResponse<ParspecMfgDetailsResponse[]>>(parspecMfgKey),
        mfgListQueryData: queryClient.getQueryData<AxiosResponse<ManufacturerListInfo[]>>(manufacturerListKey)
    };
};

export const useParspecMfg = () => useQuery(parspecMfgKey, getParspecMfgData);

export const useCompanyMfgQuery = (options?: object) => useQuery(companyMfgKey, getCompanyMfgData, { ...options });

export const useUploadMfgFile = () => useMutation(postUploadMfgFile);

export const useEditManufacturersMutation = () =>
    useMutation(patchEditManufacturers, {
        onSuccess: (response) => {
            let conflict = false;
            response.data.forEach((card: ImportFileResponse) => {
                if (Object.keys(card.conflict).length !== 0) {
                    conflict = true;
                }
            });
            if (!conflict) {
                invalidateCompanyMfgQuery();
                invalidateParspecMfgQuery();
            }
        }
    });

export const useRemoveMfgFromCompanyMutation = () =>
    useMutation(removeMfgFromCompanyData, {
        onSuccess: (_response, request) => {
            const { payload, catalogTab } = request;
            if (catalogTab) {
                queryClient.setQueryData(parspecMfgKey, (oldData?: AxiosResponse) => {
                    if (oldData) {
                        const updatedData = oldData.data.map((mfg: ParspecMfgDetailsResponse) => {
                            if (mfg.company_group_man_id === payload.company_group_man_ids[0]) {
                                return {
                                    ...mfg,
                                    represented: false
                                };
                            }
                            return mfg;
                        });
                        return {
                            ...oldData,
                            data: updatedData
                        };
                    }
                    return oldData;
                });
            } else {
                invalidateParspecMfgQuery();
            }
            queryClient.setQueryData(companyMfgKey, (oldData?: AxiosResponse) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: oldData.data.filter((item: CompanyMfgDetailsResponse) => !payload.company_group_man_ids.includes(item.company_group_man_id))
                    };
                }
                return oldData;
            });
            invalidateListOfManufactuerListQuery();
        }
    });

export const useAddMfgToCompanyMutation = () =>
    useMutation(addMfgToCompanyData, {
        onSuccess: (response) => {
            let conflict = false;
            response.data.forEach((card: ImportFileResponse) => {
                if (Object.keys(card.conflict).length !== 0) {
                    conflict = true;
                }
            });
            if (!conflict) {
                invalidateCompanyMfgQuery();
                invalidateParspecMfgQuery();
                invalidateListOfManufactuerListQuery();
            }
        }
    });

export const useGetListOfManufactuerListQuery = () => {
    return useQuery(manufacturerListKey, getListOfManufactuerList);
};

export const useGetMfgListQuery = (mfgId: number, options?: Omit<UseQueryOptions<AxiosResponse<ManufacturerListInfo>>, 'queryKey' | 'queryFn'>) => {
    return useQuery(getMfgListKey(mfgId), () => getManufactuerList(mfgId), { ...options, staleTime: 0 } as Omit<UseQueryOptions<AxiosResponse<ManufacturerListInfo>>, 'queryKey' | 'queryFn'>);
};

export const usePostMfgListMutation = () => {
    return useMutation(postManufactuerList, {
        onSuccess() {
            invalidateListOfManufactuerListQuery();
        }
    });
};

export const usePatchMfgListMutation = (isInvalidate = true) => {
    return useMutation(patchManufactuerList, {
        onMutate: ({ mfgListId, payload: { is_starred } }) => {
            if (!isInvalidate) {
                const prevMfgList = queryClient.getQueryData<AxiosResponse<ManufacturerListInfo[]>>(manufacturerListKey);
                if (prevMfgList?.data && is_starred !== undefined) {
                    const currentMfgList = prevMfgList.data.map((list) => ({ ...list, is_starred: false }));
                    currentMfgList.forEach((list) => {
                        if (list.id === mfgListId) {
                            list.is_starred = is_starred;
                        }
                    });
                    queryClient.setQueryData(manufacturerListKey, (oldData?: AxiosResponse) => (oldData ? { ...oldData, data: currentMfgList } : oldData));
                }
            }
        },
        onSuccess() {
            if (isInvalidate) {
                invalidateListOfManufactuerListQuery();
            }
        }
    });
};

export const useDeleteMfgListMutation = () => {
    return useMutation(deleteManufacturerList, {
        onSuccess() {
            invalidateListOfManufactuerListQuery();
        }
    });
};

export const useDuplicateMfgListMutation = () => {
    return useMutation(duplicateManufacturerList, {
        onSuccess() {
            invalidateListOfManufactuerListQuery();
        }
    });
};

export const invalidateCompanyMfgQuery = () => {
    queryClient.invalidateQueries(companyMfgKey);
};

export const invalidateParspecMfgQuery = () => {
    queryClient.invalidateQueries(parspecMfgKey);
};

export const invalidateListOfManufactuerListQuery = () => {
    queryClient.invalidateQueries(manufacturerListKey);
};
