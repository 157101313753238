import { BodySmall, Box } from '@parspec/pixel';

import { Internationalization } from '@syncfusion/ej2-base';

import { LotPriceRow } from './LotPricingModal';
import { PRICING_TABLE_ENUM } from '../shared/constants';
import { getUpdatedPricingInfoForChange, getValueSetterForPricingCols } from '../shared/utils';
import { ValueSetterParams } from 'ag-grid-community';

export function getIdForNewProduct(lots: LotPriceRow[]) {
    const largestId = lots.reduce((currLargestId, currLotInfo) => {
        const id = currLotInfo.id;
        return currLargestId > id ? currLargestId : id;
    }, 0);
    const newId = largestId + 1;

    return newId;
}

export function getSumTemplate(props: any) {
    const intl = new Internationalization();
    const valueForUi = intl.formatNumber(props.Sum, { format: 'C2', useGrouping: true, minimumFractionDigits: 2 });

    return (
        <Box px="13px">
            <BodySmall fontWeight="700" width="80px">
                {valueForUi}
            </BodySmall>
        </Box>
    );
}

export function getTotalTemplate() {
    return (
        <Box position="relative" width="64px" height="30px" display="flex" alignItems="center">
            <BodySmall fontWeight="700" lines={1}>
                Subtotal
            </BodySmall>
        </Box>
    );
}

function handlePricingColumnChange(data: any, fieldName: PRICING_TABLE_ENUM) {
    const { cost, discount, discountedCost, margin, sellPrice, extendedPrice } = data || {};
    const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange({ cost, discount, discountedCost, margin, sellPrice, qty: 1, extendedPrice }, fieldName);

    return pricingRowInfoToUpdate;
}

export function getValueSetterForLotPricingCols(value: ValueSetterParams) {
    return getValueSetterForPricingCols(value, handlePricingColumnChange);
}
