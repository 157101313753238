import { useState, useEffect, FC } from 'react';

import { ColorResult, SelectChangeEvent } from '@parspec/pixel';

import MultilineTextWithFontAndColor from '../../shared/MultilineWithColorAndFont';
import { useQuoteContext } from '../../QuoteContext';
import { usePriceAndLeadTimeOptionsQuery, usePriceAndLeadTimeOptionsTextMutation, usePriceAndLeadTimeOptionsToggleMutation } from '../../queries';
import CircularLoader from '../../../../../../shared/CircularLoader';
import { quotePricingLeadTimeOptionNotesFieldName } from '../../../constants';
import { FieldNotes } from '../../queries/apiTypes';

interface OptionNotesProps {
    sectionName?: string;
    subSectionName?: string;
}

export const OptionNotes: FC<OptionNotesProps> = ({ sectionName, subSectionName }) => {
    const { handlePdfPreview, isInitiateApiCalls, isTextFieldChanged, handleTextFieldChanged, bomId, handleUpdateChangedSections } = useQuoteContext();

    const { data: priceAndLeadTimeOptionsData, isLoading } = usePriceAndLeadTimeOptionsQuery(bomId, isInitiateApiCalls);

    const { mutateAsync: mutationText } = usePriceAndLeadTimeOptionsTextMutation();
    const { mutateAsync: mutateToggle } = usePriceAndLeadTimeOptionsToggleMutation();

    const [optionNotes, setOptionNotesState] = useState<FieldNotes>({
        value: '',
        font: {
            color: '#37d67a',
            type: 'regular'
        }
    });

    useEffect(() => {
        if (priceAndLeadTimeOptionsData?.option_notes) {
            setOptionNotesState(priceAndLeadTimeOptionsData.option_notes.field_value);
        }
    }, [priceAndLeadTimeOptionsData]);

    const pushChangedFields = () => {
        if (sectionName && subSectionName) handleUpdateChangedSections?.({ sectionName, subSectionName, value: true });
    };

    async function blurAction(isColor = false) {
        if (isTextFieldChanged || isColor) {
            await mutationText({
                bomId,
                fieldName: quotePricingLeadTimeOptionNotesFieldName,
                field_value: optionNotes
            });

            handlePdfPreview(true);
            pushChangedFields();
        }
    }

    function handleOnFocus() {
        handleTextFieldChanged(false);
    }

    async function selectFontWeightHandler(e: SelectChangeEvent<unknown>) {
        const fieldValue = {
            ...optionNotes,
            font: {
                ...optionNotes.font,
                type: e.target.value as string
            }
        };

        setOptionNotesState(fieldValue);

        await mutationText({
            bomId,
            fieldName: quotePricingLeadTimeOptionNotesFieldName,
            field_value: fieldValue
        });

        handlePdfPreview(true);
        pushChangedFields();
    }

    function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        handlePdfPreview(false);
        handleTextFieldChanged(true);

        setOptionNotesState({
            ...optionNotes,
            value: e.target.value
        });
    }

    function handleColorChange(color: ColorResult) {
        setOptionNotesState({
            ...optionNotes,
            font: {
                ...optionNotes.font,
                color: color.hex
            }
        });
    }

    function handleOnColorPanelClick(isOpen: boolean) {
        if (isOpen) {
            blurAction(true);
        }
    }

    async function handleOnToggle(e: React.ChangeEvent<HTMLInputElement>) {
        await mutateToggle({
            bomId,
            fieldName: quotePricingLeadTimeOptionNotesFieldName,
            is_active: e.target.checked
        });

        handlePdfPreview(true);
        pushChangedFields();
    }

    if (isLoading) {
        return <CircularLoader />;
    }

    return (
        <>
            {priceAndLeadTimeOptionsData?.option_notes && (
                <MultilineTextWithFontAndColor
                    textFieldValue={optionNotes.value}
                    onBlur={blurAction}
                    selectedFontValue={optionNotes.font.type}
                    onTextFieldChange={(e) => handleTextFieldChange(e)}
                    onSelect={(e) => selectFontWeightHandler(e)}
                    numberOfRows={6}
                    color={optionNotes.font.color}
                    onColorChange={(color) => handleColorChange(color)}
                    switchValue={priceAndLeadTimeOptionsData?.option_notes.is_active}
                    onToggle={(e) => handleOnToggle(e)}
                    title="Option Notes"
                    onFocus={handleOnFocus}
                    onClick={handleOnColorPanelClick}
                    maxLength={500}
                    sectionName={sectionName}
                    subSectionName={subSectionName}
                />
            )}
        </>
    );
};
