import { ValueSetterParams } from 'ag-grid-community';
import { PRICING_TABLE_ENUM } from '../shared/constants';
import { SubtotalsNotApplicable } from '../shared/SubtotalsNotApplicable';
import { getUpdatedPricingInfoForChange, getValueSetterForPricingCols } from '../shared/utils';
import { OptionDataType } from './types';

export function getAddOrDeductFooterExtendedPriceSum(rowsData: OptionDataType[]) {
    let aggregrateSum = 0;
    const mainProductUsedAsReplacementSet = new Set<number>();
    let canCalculateAddDeductTotal = true;
    for (const rowData of rowsData) {
        if (!rowData?.is_hidden && (!rowData?.replaceProductId || (rowData?.replaceProductId && !mainProductUsedAsReplacementSet.has(rowData?.replaceProductId)))) {
            aggregrateSum += Number(rowData?.addDeductAmount);
            if (rowData?.replaceProductId != null) {
                mainProductUsedAsReplacementSet.add(rowData?.replaceProductId);
            }
        } else if (rowData?.replaceProductId && mainProductUsedAsReplacementSet.has(rowData?.replaceProductId)) {
            canCalculateAddDeductTotal = false;
            break;
        }
    }

    return canCalculateAddDeductTotal ? aggregrateSum : 'N/A';
}

export function AddOrDeductFooterTemplate() {
    return <SubtotalsNotApplicable title="Subtotals are not available because at least one included main product is present in multiple options" />;
}

export function getIsOptionsDataUpdated(apiData: OptionDataType[], localData: OptionDataType[]) {
    return apiData.some((value, index) => {
        return (
            value?.replaceProductId !== localData?.[index]?.replaceProductId ||
            value?.replaceProductInfo?.extendedPrice !== localData?.[index]?.replaceProductInfo?.extendedPrice ||
            value?.is_hidden !== localData?.[index]?.is_hidden ||
            value?.qty !== localData?.[index]?.qty ||
            value?.category !== localData?.[index]?.category ||
            value?.model_number !== localData?.[index]?.model_number ||
            value?.manufacturer !== localData?.[index]?.manufacturer ||
            value?.specification !== localData?.[index]?.specification ||
            value?.cost !== localData?.[index]?.cost ||
            value?.discount !== localData?.[index]?.discount ||
            value?.discountedCost !== localData?.[index]?.discountedCost ||
            value?.margin !== localData?.[index]?.margin ||
            value?.sellPrice !== localData?.[index]?.sellPrice ||
            value?.leadTimeUnit !== localData?.[index]?.leadTimeUnit ||
            value?.leadTimeValue !== localData?.[index]?.leadTimeValue ||
            value?.quoteNo !== localData?.[index]?.quoteNo ||
            value?.internalComments !== localData?.[index]?.internalComments ||
            value?.addDeductAmount !== localData?.[index]?.addDeductAmount ||
            value?.substitute_number_id !== localData?.[index]?.substitute_number_id ||
            value?.extendedPrice !== localData?.[index]?.extendedPrice ||
            value?.quoteNotes !== localData?.[index]?.quoteNotes ||
            value?.is_highlighted !== localData?.[index]?.is_highlighted
        );
    });
}

function handlePricingColumnChange(data: any, fieldName: PRICING_TABLE_ENUM) {
    const { cost, discount, discountedCost, margin, sellPrice, qty, extendedPrice } = data || {};
    const pricingRowInfoToUpdate = getUpdatedPricingInfoForChange({ cost, discount, discountedCost, margin, sellPrice, qty, extendedPrice }, fieldName);
    const addDeductAmount =
        data?.replaceProductId && data?.replaceProductInfo?.extendedPrice != null && pricingRowInfoToUpdate?.extendedPrice != null
            ? pricingRowInfoToUpdate?.extendedPrice - data?.replaceProductInfo?.extendedPrice
            : pricingRowInfoToUpdate?.extendedPrice;
    return { ...pricingRowInfoToUpdate, addDeductAmount };
}

export function getValueSetterForOptionProductPricingCols(value: ValueSetterParams) {
    return getValueSetterForPricingCols(value, handlePricingColumnChange);
}
