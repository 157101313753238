export const HEADER_SECTION_NAME = 'header_footer';
export const COVER_PAGE_SECTION_NAME = 'cover_page';
export const HEADER_LOGO_SUB_SECTION = 'logo';
export const PREPARED_BY_LOGO_SUB_SECTION = 'prepared_by_logo';
export const ADDRESS_TO_LOGO_SUB_SECTION = 'addressed_to_logo';

export const STAKE_HOLDER_SUB_SECTION = 'stakeholder';
export const OPENING_NOTES_SUB_SECTION = 'opening_notes';

export const PRICE_LEAD_SECTION_NAME = 'price_and_lead_time';
export const PRICE_LEAD_OPTION_SECTION_NAME = 'price_and_lead_time_options';
export const PRODUCT_SUB_SECTION = 'product';
export const LOT_SUB_SECTION = 'lot';
export const SUMMARISED_TOTAL_SUB_SECTION = 'summarized_total';
export const OPTION_NOTES_SUB_SECTION = 'option_notes';
export const OPTION_SECTION_SUB_SECTION = 'option_section';

export const GENERAL_TNC_SECTION_NAME = 'general_tnc';
export const CLOSING_NOTES_SUB_SECTION = 'closing_notes';
export const PROJECT_NOES_SUB_SECTION = 'project_notes';
export const GENERAL_TNC_SUB_SECTION = 'general_tnc';

export const MANUFACTURER_SECTION_NAME = 'manufacturer_tnc';
export const MANUFACTURER_SUB_SECTION_NAME = 'manufacturer';

export const ATTACHMENTS_SECTION_NAME = 'attachments';
export const ATTACHMENTS_SUB_SECTION_NAME = 'attachments';
