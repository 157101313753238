import formatCell from './formatCell';

export default (row: any, index: any) => {
  // To ensure the row number starts as in excel.
  const rowIndex = index + 1;
  const rowCells = row
    .map((cell: any, cellIndex: any) => formatCell(cell, cellIndex, rowIndex))
    .join('');

  return `<row r="${rowIndex}">${rowCells}</row>`;
};
