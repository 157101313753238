import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, H5 } from '@parspec/pixel';

import PricingHistoryBody from './PricingHistoryBody';
import { useCheckAccessForProductFinderOrQuoting } from '../shared/hooks/useCheckAccessForProductFinderOrQuoting';
import CircularLoader from '../shared/CircularLoader';
import { useFeatureFlag } from '../shared/UserActivityTracking/useFeatureFlag';

export function PricingHistory() {
    const navigate = useNavigate();

    const { quotingAccess, isFetching: isQuotingAccessFetching } = useCheckAccessForProductFinderOrQuoting();
    const { enable_quoting, isLoading: isFeatureFlagLoading } = useFeatureFlag();
    const isQuotingAccessLoading = isQuotingAccessFetching || isFeatureFlagLoading;
    const doesUserHaveQuotingAccess = quotingAccess && enable_quoting;

    useLayoutEffect(() => {
        if (!isQuotingAccessLoading && !doesUserHaveQuotingAccess) {
            navigate('/v2/dashboard');
        }
    }, [doesUserHaveQuotingAccess, isQuotingAccessLoading]);

    return (
        <Box width="100%" height="100vh" display="flex" flexDirection="column">
            {isQuotingAccessLoading && (
                <Box flex="1">
                    <CircularLoader />
                </Box>
            )}
            {!isQuotingAccessLoading && (
                <>
                    <Box px={4} py={6} bgcolor="primary.contrastText" borderBottom="1px solid" borderColor="neutral.main">
                        <H5 width={'max-content'}>Pricing History</H5>
                    </Box>
                    <Box flex="1" minHeight="0" p={4} pt={6}>
                        {!isQuotingAccessLoading && <PricingHistoryBody isForPricingHistoryTab />}
                    </Box>
                </>
            )}
        </Box>
    );
}
