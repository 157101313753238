import { AlertBanner, BodySmall, Box, Button } from '@parspec/pixel';
import { useNavigate } from 'react-router-dom';

interface PaymentFailedBannerProps {
    onClose: () => void;
}
const PaymentFailedBanner = (props: PaymentFailedBannerProps) => {
    const navigate = useNavigate();
    const { onClose } = props;

    const onManagePlanClick = () => {
        navigate(`/v2/settings/plan-and-payment`);
    };
    return (
        <Box>
            <AlertBanner
                text={'Payment declined - We were not able to charge the card on file. Please ensure the validity of your payment details to avoid subscription renewal delays.'}
                variant="filled"
                severity="error"
                onClose={onClose}
            >
                <Button variant="text" onClick={onManagePlanClick} color="error">
                    <BodySmall
                        color="secondary.contrastText"
                        sx={{
                            '&:hover': {
                                textDecorationLine: 'underline',
                                textUnderlineOffset: 2
                            }
                        }}
                    >
                        Manage Plan
                    </BodySmall>
                </Button>
            </AlertBanner>
        </Box>
    );
};

export default PaymentFailedBanner;
