// enviornment variable keys should be uppercase snakecase and all env variables should be consumed through this object
// If above step is to be followed for obfuscation to work.
export const environment = {
    a: process.env.REACT_APP_MOCK_APIS === 'true'!,
    Z: process.env.REACT_APP_PRODUCTION === 'true'!,
    b: process.env.REACT_APP_API_URL!,
    Y: process.env.REACT_APP_PDFTRON_LICENSE_KEY!,
    c: process.env.REACT_APP_AG_GRID_LICENSE_KEY!,
    X: process.env.REACT_APP_PROXY_URL!,
    d: process.env.REACT_APP_FAST_PROXY_URL!,
    W: process.env.REACT_APP_SYNCFUSION_PROXY_URL!,
    e: process.env.REACT_APP_SYNCFUSION_LICENSE_KEY!,
    V: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID!,
    f: process.env.REACT_APP_FIREBASE_API_KEY!,
    U: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
    g: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
    T: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
    h: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
    S: process.env.REACT_APP_FIREBASE_APP_ID!,
    i: process.env.REACT_APP_SSO_CLIENT_ID!,
    R: process.env.REACT_APP_FE_BASE_URL!,
    j: process.env.REACT_APP_PARSPEC_PRODUCT_URL!,
    Q: process.env.REACT_APP_FE_EVENT_SERVICE_URL!,
    P: process.env.REACT_APP_FE_EVENT_SERVICE_TOKEN_KEY!,
    l: process.env.REACT_APP_OUTLOOK_CLIENT_ID!,
    O: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
    N: process.env.REACT_APP_DEPLOYMENT_INFO_URL!,
    o: process.env.REACT_APP_REPORTING_API_URL!
} as const;
