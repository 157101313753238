import { memo, useEffect } from 'react';

import { Box } from '@parspec/pixel';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';

export interface ImageSectionType {
    is_active: boolean;
    isShowLogoPreview: boolean;
    isAddressLogoPreview: boolean;
    isPreparedByLogoPreview: boolean;
    mainLogoUrl?: string;
    addressLogoUrl?: string;
    preparedByLogoUrl?: string;
}

export function initialLogoPreview(url: string, element: HTMLImageElement | null) {
    const result = getProxyFileUrl(url);
    if (element) {
        element.src = result;
    }
}

const LeftImageSection: React.FC<ImageSectionType> = memo(({ is_active, isShowLogoPreview, mainLogoUrl, isAddressLogoPreview, addressLogoUrl, isPreparedByLogoPreview, preparedByLogoUrl }) => {
    useEffect(() => {
        const leftMainLogoElement: HTMLImageElement | null = document.querySelector('#preview-left-image-1');
        if (leftMainLogoElement && isShowLogoPreview && mainLogoUrl) {
            initialLogoPreview(mainLogoUrl, leftMainLogoElement);
        }
    }, [isShowLogoPreview, mainLogoUrl]);

    useEffect(() => {
        const leftPreparedByElement: HTMLImageElement | null = document.querySelector('#preview-left-image-2');
        if (leftPreparedByElement && isPreparedByLogoPreview && preparedByLogoUrl) {
            initialLogoPreview(preparedByLogoUrl, leftPreparedByElement);
        }
    }, [isPreparedByLogoPreview, preparedByLogoUrl]);

    useEffect(() => {
        const leftAddressElement: HTMLImageElement | null = document.querySelector('#preview-left-image-3');
        if (leftAddressElement && isAddressLogoPreview && addressLogoUrl) {
            initialLogoPreview(addressLogoUrl, leftAddressElement);
        }
    }, [isAddressLogoPreview, addressLogoUrl]);

    return (
        <Box width={178} height={192} border={`1px solid`} borderColor={is_active ? 'primary.main' : 'neutral.main'} padding={2} bgcolor={is_active ? 'primary.light' : 'neutral.light'}>
            <Box width={'100%'} height={'100%'} padding={1} bgcolor={'primary.contrastText'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <Box width={72} height={38} marginTop={2} marginLeft={2} display={'block'}>
                    <img loading="lazy" id="preview-left-image-1" style={{ objectFit: 'contain', width: '100%', maxHeight: 38, display: isShowLogoPreview ? 'block' : 'none' }} />
                </Box>
                <Box width={72} height={3} bgcolor="neutral.main" marginTop={3} marginLeft={2}></Box>
                <Box width={72} height={3} bgcolor="neutral.main" marginTop={1} marginLeft={2}></Box>

                <Box width={72} height={3} bgcolor="neutral.main" marginTop={3} marginLeft={2}></Box>
                <Box width={72} height={3} bgcolor="neutral.main" marginTop={1} marginLeft={2}></Box>

                <Box width={100} height={2} bgcolor="neutral.main" marginTop={3} marginLeft={2}></Box>
                <Box width={100} height={2} bgcolor="neutral.main" marginTop={1} marginLeft={2}></Box>

                <Box width={'100%'} padding={1} marginTop={4} display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                        <Box width={24} height={20} display={'block'}>
                            <img loading="lazy" id="preview-left-image-2" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: isPreparedByLogoPreview ? 'block' : 'none' }} />
                        </Box>
                        <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'flex-start'}>
                        <Box width={24} height={20} display={'block'}>
                            <img loading="lazy" id="preview-left-image-3" style={{ objectFit: 'contain', width: '100%', maxHeight: 20, display: isAddressLogoPreview ? 'block' : 'none' }} />
                        </Box>
                        <Box width={58} height={3} mt={1} bgcolor={'neutral.main'}></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default LeftImageSection;
