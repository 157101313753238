import { useNavigate } from 'react-router';

import { Box, BodyBig, BodySmall, Button, InfoIcon } from '@parspec/pixel';
import { ISnackbarDataState } from '../SignUp.types';

const INSTRUCTIONS = '1. Log in to Parspec\n 2. Go to settings user management\n 3. Select invite user\n 4. Enter your team member’s email';

interface TeamIsUsingParspecProps {
    snackbarData: ISnackbarDataState;
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarDataState>>;
}

export const TeamIsUsingParspec = (props: TeamIsUsingParspecProps) => {
    const { snackbarData, setSnackbarData } = props;

    const copyInstructionHandler = () => {
        const textArea = document.createElement('textarea');
        textArea.value = INSTRUCTIONS;

        // Set the position off-screen
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';

        // Ensure it's not visible
        textArea.style.opacity = '0';

        document.body.appendChild(textArea);
        textArea.select();

        document.execCommand('copy');
        document.body.removeChild(textArea);

        // navigator.clipboard.writeText(INSTRUCTIONS);
        setSnackbarData({
            ...snackbarData,
            isActive: true,
            message: 'Instructions copied to clipboard'
        });
    };
    const navigate = useNavigate();
    return (
        <Box bgcolor="secondary.contrastText" width="350px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
                <BodyBig limit={false} fontWeight={500}>
                    You’re only one step away!
                </BodyBig>
                <BodySmall limit={false} mt={4} textAlign="center" color={'text.secondary'}>
                    Follow the instructions below to get started
                </BodySmall>
            </Box>

            <Box bgcolor={'primary.light'} display={'flex'} alignItems={'flex-start'} p={2} mb={6}>
                <Box color={'primary.main'}>
                    <InfoIcon fontSize={'small'} />
                </Box>
                <Box ml={2}>
                    <BodySmall limit={false} color={'primary.main'}>
                        A team member needs to invite you
                    </BodySmall>
                    <BodySmall limit={false} color={'primary.main'}>
                        to your team’s existing account.
                    </BodySmall>
                </Box>
            </Box>

            <Box>
                <BodySmall limit={false} mt={4} mb={4}>
                    Share the instructions below with any team member to complete the sign up process.
                </BodySmall>
            </Box>
            <Box>
                <BodySmall limit={false} mb={2}>
                    1. Log in to Parspec
                </BodySmall>
                <BodySmall limit={false} mb={2}>
                    2. Go to{' '}
                    <strong>
                        <em>settings user management</em>
                    </strong>
                </BodySmall>
                <BodySmall limit={false} mb={2}>
                    3. Select{' '}
                    <strong>
                        <em>invite user</em>
                    </strong>
                </BodySmall>
                <BodySmall limit={false} mb={2}>
                    4. Enter your team member’s email
                </BodySmall>
            </Box>

            <Box width="100%" my={6}>
                <Button onClick={copyInstructionHandler} fullWidth={true}>
                    Copy Instructions
                </Button>
            </Box>

            <Box display="flex" width="100%" alignItems="center" justifyContent="center">
                <BodySmall limit={false}>Already have an account? </BodySmall>
                <BodySmall
                    limit={false}
                    color={'primary.main'}
                    mr={1}
                    onClick={() => navigate('/login')}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                            textDecoration: 'underline'
                        }
                    }}
                >
                    Log in
                </BodySmall>
            </Box>
        </Box>
    );
};
