import { Box, H5 } from '@parspec/pixel';

import { UserDataTable } from './UserDataTable';
import { ProductFinderSeatsBanner } from './Banner/ProductFinderSeatsBanner';
import { useSubscriptionInfo } from '../../Settings/PlanAndPayment/queries';
import Header from './Header';
import { useGetUserProfileInfoQuery } from '../MyProfile/queries';
import { useMemo } from 'react';
import { useGetTotalActiveInvitedAndDeactivatedQueary } from './queries';

export const UserManagement = () => {
    const { data: subscriptionInfoData } = useSubscriptionInfo();
    const { data: userProfile, isLoading: isUserProfileInfoLoading } = useGetUserProfileInfoQuery();
    const totalActiveInvitedAndDeactivated = useGetTotalActiveInvitedAndDeactivatedQueary();

    const { isApiDataLoaded, tabDataLengths } = useMemo(() => {
        const isApiDataLoaded = totalActiveInvitedAndDeactivated.every((query) => query.status === 'success');
        const tabDataLengths = totalActiveInvitedAndDeactivated.map((query) => {
            if (query.data && Array.isArray(query.data.data)) {
                return query.data.data.length; // Access the array's length
            }
            return 0; // Default length if data is not an array
        });
        return { isApiDataLoaded: !isApiDataLoaded, tabDataLengths };
    }, [totalActiveInvitedAndDeactivated]);
    const heightOfTable = useMemo(() => {
        const isPlanFreeOrEnterprise = subscriptionInfoData?.data.my_plan.plan === 'Free' || subscriptionInfoData?.data.my_plan.plan === 'Enterprise';
        const bannerHeight = isPlanFreeOrEnterprise || userProfile?.data?.role !== 'admin' ? 0 : 62;
        return `calc(100vh - ${253 + bannerHeight}px)`;
    }, [isUserProfileInfoLoading, userProfile?.data, subscriptionInfoData?.data]);

    return (
        <Box bgcolor={'primary.contrastText'} borderColor={'neutral.main'} borderRadius={1} p={4} boxSizing="border-box" display="flex" flexDirection="column" width="calc(100vw - 390px)">
            <Box mt={1} mb={8}>
                <H5>User Management</H5>
            </Box>

            <Box>
                <ProductFinderSeatsBanner />
            </Box>

            <Box>
                <Header isApiDataLoaded={isApiDataLoaded} tabDataLengths={tabDataLengths} />
            </Box>
            <Box height={heightOfTable} pt={2}>
                <UserDataTable isTabDataLoaded={isApiDataLoaded} />
            </Box>
        </Box>
    );
};
