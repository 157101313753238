import { useState } from 'react';

import { Box, AlertBanner } from '@parspec/pixel';

import PdfViewer from '../shared/WebViewer';
import QuoteNotVisible from './QuoteNotVisible';
import PreviewButton from '../shared/PreviewButton';
import { useQuoteContext } from './QuoteContext';
import { useQuotePdfUrlQuery } from './queries';
import { IConfigurationToggle } from './queries/apiTypes';

const QuotePdfPreview = ({ cover_page_section, price_and_lead_time_section, header_footer_section, general_tnc_section, manufacturer_tnc_section, attachments }: Partial<IConfigurationToggle>) => {
    const { isFetch, setIsFetch, isInitiateApiCalls, bomId } = useQuoteContext();

    const [isAlertBannerVisible, setIsAlertBannerVisible] = useState(true);

    const {
        data: pdfUrlData,
        isFetching,
        refetch
    } = useQuotePdfUrlQuery(bomId, {
        enabled: isInitiateApiCalls
    });

    const isPdfVisible = Boolean(cover_page_section || price_and_lead_time_section || header_footer_section || general_tnc_section || manufacturer_tnc_section || attachments);

    const onClickHandler = async () => {
        setIsFetch(false);
        await refetch();
    };

    return (
        <>
            {isAlertBannerVisible && (
                <AlertBanner
                    text="Pricing & Lead time information is using placeholder data, only its formatting is reflected."
                    variant="filled"
                    severity="warning"
                    onClose={() => {
                        setIsAlertBannerVisible(false);
                    }}
                />
            )}
            <Box position={'relative'}>
                <Box width={'100%'} bgcolor={'neutral.main'}>
                    {isPdfVisible ? (
                        <PdfViewer isAlertBannerVisible={isAlertBannerVisible} url={pdfUrlData?.data?.url} isFetching={isFetching} />
                    ) : (
                        <QuoteNotVisible isAlertBannerVisible={isAlertBannerVisible} />
                    )}
                </Box>
                {isFetch ? <PreviewButton onClick={onClickHandler} /> : null}
            </Box>
        </>
    );
};

export default QuotePdfPreview;
