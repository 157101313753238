import { useMemo } from 'react';

import { useAllTagsQuery } from '../../Project/queries';
import { AllTagsTransformResponse } from '../../Project/queries/apiTypes';

export const useAllTagsHook = () => {
    const { data: allTagsData } = useAllTagsQuery();

    const result = useMemo(() => {
        const dataObj: Map<number | null, string | null> = new Map();
        if (allTagsData) {
            allTagsData.forEach((tag: AllTagsTransformResponse) => {
                dataObj.set(tag.value, tag.label);
            });
        }
        return dataObj;
    }, [allTagsData]);

    return result;
};
