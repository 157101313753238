import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDirective, SearchSettingsModel } from '@syncfusion/ej2-react-treegrid';

import { DriveFileRenameOutlineIcon, DeleteOutlineIcon, BodySmall, BodyXS, Box, Button, H5, IconButton, Link, Snackbar, Status, Table, ToolbarType, Tooltip, Info } from '@parspec/pixel';

import { getMfgStatusColor } from '../../shared/constants';
import { useCompanyMfgQuery } from './queries';
import ImportList from './ImportList';
import RemoveManufacturers from './RemoveManufacturers';
import { AddManufacturer } from './AddManufacturer';
import EditManufacturers from './EditManufacturers';
import { ManufacturerUploadResponse, CompanyMfgDetailsResponse } from './queries/apiTypes';
import { useGetUserProfileInfoQuery } from '../MyProfile/queries';
import { InfoHeaderTemplateCallback, getErpContent } from './utils';

const getTableProps = (args: any) => {
    const toolBarItems: ToolbarType = ['search', 'selectedItems'];
    const searchSettings: SearchSettingsModel = {
        fields: ['manufacturer_name', 'website', 'status', 'abbreviation_str'],
        hierarchyMode: 'Both'
    };
    return {
        allowFiltering: false,
        toolBarOptions: toolBarItems,
        allowPaging: true,
        allowRowDragAndDrop: false,
        ...args,
        searchSettings,
        selectedItemsBelowSearch: true
    };
};

export const CompanyManufacturer = () => {
    const navigate = useNavigate();

    const { data: companyMfgData, isLoading, isFetching } = useCompanyMfgQuery();
    const { data: userProfile } = useGetUserProfileInfoQuery();

    const tableRef = useRef<any>();
    const isAdmin = userProfile?.data?.role === 'admin';

    const [selectedItems, setSelectedItems] = useState<CompanyMfgDetailsResponse[]>([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [openAddMfgDialog, setOpenAddMfgDialog] = useState(false);
    const [openVerifyUploadDialog, setOpenVerifyUploadDialog] = useState(false);
    const [isActionEdit, setIsActionEdit] = useState(false);
    const [bulkUploadedData, setBulkUploadedData] = useState<ManufacturerUploadResponse[] | CompanyMfgDetailsResponse[]>([]);
    const [showUnrecognizedInfo, setShowUnrecognizedInfo] = useState(false);

    useEffect(() => {
        const isNotRecognizedPresent = companyMfgData?.data.some((item) => item.status === 'Not Recognized');
        if (isNotRecognizedPresent) {
            setShowUnrecognizedInfo(true);
        } else {
            setShowUnrecognizedInfo(false);
        }
    }, [companyMfgData?.data]);

    const onCheckboxChange = (args: CompanyMfgDetailsResponse[]) => {
        setSelectedItems([...args]);
    };

    const getWebsiteContent = useCallback((props: CompanyMfgDetailsResponse) => {
        const { website } = props;
        return website ? (
            <Link href={website} target="_blank">
                {website}
            </Link>
        ) : (
            '--'
        );
    }, []);

    const getAbbreviationContent = useCallback((props: CompanyMfgDetailsResponse) => {
        const { abbreviations } = props;
        return abbreviations.length ? abbreviations.join(', ') : '--';
    }, []);

    const getStatusContent = useCallback((props: CompanyMfgDetailsResponse) => {
        const { status } = props;
        return (
            <Status color={getMfgStatusColor(status)}>
                <BodyXS color="inherit">{status}</BodyXS>
            </Status>
        );
    }, []);

    const handleImportDialog = () => {
        setOpenImportDialog(false);
    };

    const onImport = (uploadedData: ManufacturerUploadResponse[]) => {
        setBulkUploadedData(uploadedData);
        handleImportDialog();
        setIsActionEdit(false);
        setOpenVerifyUploadDialog(true);
    };

    const handleVerifyUploadDialog = () => {
        setOpenVerifyUploadDialog(false);
    };

    const onVerifyUpload = () => {
        handleVerifyUploadDialog();
        setSnackbarMessage('Company Manufacturer(s) Updated');
        tableRef?.current?.clearSelection();
    };

    const handleRemoveDialog = () => {
        setOpenRemoveDialog(false);
    };

    const onRemoveDialog = () => {
        setSnackbarMessage('Manufacturer(s) removed');
        tableRef?.current?.clearSelection();
        handleRemoveDialog();
    };

    const handleAddMfgDialog = () => {
        setOpenAddMfgDialog(false);
    };

    const onAddingMfg = () => {
        handleAddMfgDialog();
        setSnackbarMessage('Company Manufacturer(s) Updated');
    };

    const toolbarRightSection = (
        <Box display="flex" width="100%" gap={2} alignItems="center">
            {isAdmin && (
                <>
                    <Tooltip title={selectedItems.length ? 'Edit Manufacturer(s)' : 'Select Manufacturer(s) First'} placement={'bottom'}>
                        <span>
                            <IconButton
                                disabled={!selectedItems.length}
                                onClick={() => {
                                    setBulkUploadedData(selectedItems);
                                    setIsActionEdit(true);
                                    setOpenVerifyUploadDialog(true);
                                }}
                            >
                                <DriveFileRenameOutlineIcon sx={{ fontSize: 24 }} />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title={selectedItems.length ? 'Remove from Company Manufacturer(s)' : 'Select Manufacturer(s) First'} placement={'bottom'}>
                        <span>
                            <IconButton
                                disabled={!selectedItems.length}
                                onClick={() => {
                                    setOpenRemoveDialog(true);
                                }}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: 24 }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Button variant="outlined" color="secondary" onClick={() => setOpenAddMfgDialog(true)}>
                        Add Manually
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => setOpenImportDialog(true)}>
                        Import List
                    </Button>
                </>
            )}
            <Button
                onClick={() => {
                    navigate('/v2/settings/manufacturers?tab=catalog');
                }}
            >
                Choose from Catalog
            </Button>
        </Box>
    );

    const abbrevationsHeaderTemplate = useMemo(() => {
        return InfoHeaderTemplateCallback(
            'Abbreviations',
            'Enter the manufacturer abbreviations used by your vendors for seamless tool compatibility. Abbreviations are replaced by the full manufacturer name on file import.'
        );
    }, []);

    const erpHeaderTemplate = useMemo(() => {
        return InfoHeaderTemplateCallback(
            'Manufacturer ERP Code',
            'Enter the manufacturer code used in your ERP for seamless tool compatibility. Manufacturer codes will be present on non-PDF quote exports.'
        );
    }, []);

    return (
        <>
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage}></Snackbar>
            <Box pb={'26px'}>
                <Box mt={1} mb={6}>
                    <H5>Company Manufacturers</H5>
                    <BodySmall lines={2}>
                        These are the manufacturing partners your organization uses. Abbreviations are replaced by the full Manufacturer Name upon import of a product list.
                    </BodySmall>
                </Box>
                {isAdmin && showUnrecognizedInfo && (
                    <Info mb={8}>
                        <BodySmall lines={3} color={'primary.main'}>
                            <strong>Unrecognized manufacturers?</strong> We’re likely using a different name internally. Edit to browse a list of Parspec IDs and find a match, or to provide a website
                            and request support for the manufacturer. Correctly associating the Manufacturer Name to a Parspec ID will increase datasheet accuracy.
                        </BodySmall>
                    </Info>
                )}
                <Box
                    zIndex={1}
                    position={'relative'}
                    height={isAdmin && showUnrecognizedInfo ? 'calc(100vh - 372px)' : 'calc(100vh - 288px)'}
                    sx={{
                        '.e-grid .e-gridheader .e-sortfilter .e-headercelldiv': { paddingRight: 0 }
                    }}
                >
                    <Table {...getTableProps({ onCheckboxChange })} data={companyMfgData?.data || []} loading={isLoading || isFetching} ref={tableRef} toolbarRightSection={toolbarRightSection}>
                        {isAdmin && <ColumnDirective type="checkbox" width="50" headerTextAlign="Center" textAlign="Center" allowEditing={false} />}
                        <ColumnDirective allowEditing={false} field="manufacturer_name" headerText="Manufacturer" isPrimaryKey allowSorting={false} />
                        <ColumnDirective allowEditing={false} template={getErpContent} field="erp_code" headerTemplate={erpHeaderTemplate} allowSorting={false} width="200px" />
                        <ColumnDirective allowEditing={false} template={getAbbreviationContent} headerTemplate={abbrevationsHeaderTemplate} allowSorting={false} />
                        <ColumnDirective allowEditing={false} template={getWebsiteContent} headerText="Website" allowSorting={false} />
                        <ColumnDirective allowEditing={false} template={getStatusContent} headerText="Status" allowSorting={false} width="165" />
                    </Table>
                </Box>
            </Box>
            {openImportDialog && <ImportList open={openImportDialog} handleDialog={handleImportDialog} confirmAction={onImport} />}
            {openVerifyUploadDialog && (
                <EditManufacturers open={openVerifyUploadDialog} handleDialog={handleVerifyUploadDialog} confirmAction={onVerifyUpload} verifyData={bulkUploadedData} actionEdit={isActionEdit} />
            )}
            {openRemoveDialog && (
                <RemoveManufacturers
                    open={openRemoveDialog}
                    handleDialog={handleRemoveDialog}
                    confirmAction={onRemoveDialog}
                    selectedRowIds={Array.from(selectedItems, (item) => item.company_group_man_id)}
                />
            )}
            {openAddMfgDialog && <AddManufacturer open={openAddMfgDialog} handleDialog={handleAddMfgDialog} confirmAction={onAddingMfg} />}
        </>
    );
};
