import { Grid, TextField, BodySmall } from '@parspec/pixel';

interface BasicTextFieldProps {
    label: string;
    placeholder: string;
    textValue?: string;
    action?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    maxLength?: number;
    isError?: boolean;
    fieldName?: string;
    onFocus?: () => void;
    disabled?: boolean;
}

const BasicTextField = ({ label, placeholder, action, textValue, onBlur, maxLength, isError = false, fieldName, onFocus, disabled = false }: BasicTextFieldProps) => {
    return (
        <Grid container>
            <Grid item xs={4}>
                <BodySmall>{label}</BodySmall>
            </Grid>

            <Grid item xs={8}>
                <TextField
                    disabled={disabled}
                    placeholder={placeholder}
                    color="secondary"
                    label={''}
                    value={textValue}
                    onChange={action}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    inputProps={{
                        maxLength: maxLength
                    }}
                    error={isError}
                    name={fieldName}
                    helperText={isError ? 'Invalid Email' : ''}
                />
            </Grid>
        </Grid>
    );
};

export default BasicTextField;
