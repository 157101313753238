import { dayjs, MultiSelectOptionType } from '@parspec/pixel';

export enum FILTER_KEYS {
    SEARCH_TEXT = 'searchText',
    DATE_RANGE = 'dateRange',
    PROJECT_STAGES = 'projectStages',
    BRANCH_LOCATIONS = 'branchLocations',
    OWNERS = 'owners',
    MARKET_VERTICALS = 'marketVerticals'
}

export const FILTERS_INITIAL_VALUE = {
    [FILTER_KEYS.SEARCH_TEXT]: '',
    [FILTER_KEYS.DATE_RANGE]: [dayjs().subtract(1, 'year').startOf('day').format(), dayjs().endOf('day').format()],
    [FILTER_KEYS.PROJECT_STAGES]: [],
    [FILTER_KEYS.BRANCH_LOCATIONS]: [],
    [FILTER_KEYS.OWNERS]: [],
    [FILTER_KEYS.MARKET_VERTICALS]: []
};

export interface FilterType {
    [FILTER_KEYS.SEARCH_TEXT]?: string;
    [FILTER_KEYS.DATE_RANGE]: string[];
    [FILTER_KEYS.PROJECT_STAGES]: MultiSelectOptionType[];
    [FILTER_KEYS.BRANCH_LOCATIONS]: MultiSelectOptionType[];
    [FILTER_KEYS.OWNERS]: MultiSelectOptionType[];
    [FILTER_KEYS.MARKET_VERTICALS]: MultiSelectOptionType[];
}
