import { BodyMedium, BodyXS, Box, IBarInfo, IReferenceLine, Paper, BarChart as PixelBarChart, Skeleton } from '@parspec/pixel';

import { graphTooltipLabels } from '../../constants';

interface IBarChartProps {
    isLoading: boolean;
    header: string;
    subHeader: string;
    legends: { name: string; color: string }[];
    data: Record<string, string | number>[];
    barsInfo: IBarInfo[];
    referenceLineInfo?: IReferenceLine[];
    xAxisTickFormatter?: (value: string) => string;
    yAxisTickFormatter?: (value: string) => string;
    referenceLabelFormatter?: (value: string) => string;
    tooltipValueFormatter?: (value: string) => string;
}

const BarChart = ({
    isLoading,
    header,
    subHeader,
    data,
    barsInfo,
    referenceLineInfo,
    legends,
    xAxisTickFormatter,
    yAxisTickFormatter,
    referenceLabelFormatter,
    tooltipValueFormatter
}: IBarChartProps) => {
    const renderLegend = (name: string, color: string) => (
        <Box display="flex" gap={2} alignItems="center">
            <Box height="12px" width="12px" borderRadius="50%" bgcolor={color}></Box>
            <Box>
                <BodyXS>{name}</BodyXS>
            </Box>
        </Box>
    );

    return (
        <Paper>
            <Box p={4} borderBottom="1px solid" borderColor="neutral.main" display="flex" justifyContent="space-between" alignItems="baseline">
                <Box>
                    <BodyMedium fontWeight="medium">{header}</BodyMedium>
                    <BodyXS color={'text.secondary'}>{subHeader}</BodyXS>
                </Box>
                <Box pt={2} display="flex" flexDirection="column" gap={1}>
                    {legends.map(({ name, color }) => renderLegend(name, color))}
                </Box>
            </Box>
            <Box p={4} maxHeight="360px" maxWidth="100%">
                {isLoading ? (
                    <Skeleton variant="rectangular" height={300} />
                ) : (
                    <>
                        {data.length ? (
                            <PixelBarChart
                                width={468}
                                height={328}
                                data={data}
                                orientation="vertical"
                                barsInfo={barsInfo}
                                referenceLineInfo={referenceLineInfo}
                                xAxisTickFomratter={xAxisTickFormatter}
                                yAxisTickFomratter={yAxisTickFormatter}
                                getTooltipLabel={graphTooltipLabels}
                                referenceLabelFormatter={referenceLabelFormatter}
                                tooltipValueFormatter={tooltipValueFormatter}
                                tickFontSize="12px"
                            />
                        ) : (
                            <Box height="300px" display="flex" justifyContent="center" alignItems="center">
                                <BodyXS>No data to display</BodyXS>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default BarChart;
