import { createContext, useContext } from 'react';

interface IContext {
    checkBoxState: Record<string, boolean> | null;
    selectedTab: string;
    showMenu: boolean;
    showCheckbox: boolean;
    updateCheckBoxSelection: (e: React.ChangeEvent<HTMLInputElement>, selectedTab: string) => void;
    onDelete: (bomId: string, isPrimary?: boolean) => void;
    onDuplicate: (bomId: string) => void;
    newBomIds?: Set<number>;
}

export const CustomerTabsContext = createContext<IContext>({
    checkBoxState: {},
    selectedTab: '',
    showCheckbox: false,
    showMenu: false,
    newBomIds: new Set(),
    updateCheckBoxSelection: () => {},
    onDelete: () => {},
    onDuplicate: () => {}
});

export const useCustomerTabContext = () => {
    return useContext(CustomerTabsContext);
};
