export const quoteDocumentData = {
    id: 123,
    document_link: 'http://s3.pdf',
    document_link_flat: 'http://s3.pdf',
    version: 2,
    compiled_by: 'safwan',
    product_count: 233,
    quote_number: '3542-6724',
    primary_title: '',
    Secondary_title: '',
    version_notes: '',
    Is_published: true,
    Is_deleted: false,
    created_at: '2023-02-23 12:33:33.3434',
    updated_at: '2023-02-24 14:33:22.4444'
};

export const compileTimeData = {
    'Total Time': 5
};
