import { useMutation } from '@tanstack/react-query';

import { copyDataFromExistingBom, uploadDifferentFile } from './api';
import { invalidateContentDetailQuery } from '../../addProducts/queries';
import { invalidateBomDataQuery, invalidateBomStatsQuery, invalidateDatasheetDataQuery, invalidateGetOptionSectionQuery, invalidateGetSectionsQuery, invalidateOmDataQuery } from '../../queries';
import { invalidatePricingBodQuery } from '../../pricing&LeadTime/queries';
import { invalidaLotQueries } from '../../pricing&LeadTime/lotPriceModal/queries';
import { invalidateServiceQueries } from '../../pricing&LeadTime/servicesModal/queries';
import { invalidaFreightQuery } from '../../pricing&LeadTime/freightModal/queries';
import { invalidaTaxesPricingQuery } from '../../pricing&LeadTime/taxesModal/queries';

export const useUploadDifferentFile = () =>
    useMutation(uploadDifferentFile, {
        onSuccess: (_response, variables) => {
            invalidateContentDetailQuery(variables.bom_id);
            invalidateGetSectionsQuery(variables.bom_id);
            invalidateBomDataQuery(variables.bom_id);
            invalidateBomStatsQuery(String(variables.bom_id));
            invalidateGetSectionsQuery(variables.bom_id);
            invalidatePricingBodQuery(Number(variables.bom_id));
            invalidateGetOptionSectionQuery(Number(variables.bom_id));
            invalidaLotQueries(String(variables.bom_id));
            invalidateServiceQueries(variables.bom_id);
        }
    });

export const useCopyDataFromExistingBomMutation = () =>
    useMutation(copyDataFromExistingBom, {
        onSuccess: (_, variables) => {
            invalidateBomDataQuery(variables.bomId);
            invalidateBomStatsQuery(String(variables.bomId));
            invalidateGetSectionsQuery(variables.bomId);
            invalidateGetOptionSectionQuery(Number(variables.bomId));
            invalidaLotQueries(String(variables.bomId));
            invalidateServiceQueries(variables.bomId);
            invalidaFreightQuery(String(variables.bomId));
            invalidaTaxesPricingQuery(variables.bomId);
            invalidatePricingBodQuery(variables.bomId);
            invalidateDatasheetDataQuery(String(variables.bomId));
            invalidateOmDataQuery(String(variables.bomId));
        }
    });
