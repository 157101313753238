import { useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@parspec/pixel';

import { useGetOmDataQuery, useGetOmTabStatsQuery } from '../queries';
import { getStats } from './helper';
import { getTableData, prepareKitsAndComponentsData } from '../shared/utils';
import Footer from '../Footer';
import OmTabHeader from './OmTabHeader';
import OmTable from './O&MTable';
import TabContent from '../shared/TabContent';
import { useGetBomData, useCustomisationColumn, useProjectBomsMappedData } from '../shared/hooks';
import LinkedBomsPopup from '../shared/LinkedBomsPopup';

const OMTab = () => {
    const [selectedItemsFromSections, setSelectedItemsFromSections] = useState<any[]>([]);
    const { bomId, projectId } = useParams();

    const selectedRowsRef = useRef<Set<number>>(new Set());

    const {
        data: bomData,
        isLoading,
        mainProductOptionsInfo
    } = useGetBomData(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: omData, isLoading: omDataLoading } = useGetOmDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });

    const { data: omTabStats } = useGetOmTabStatsQuery(bomId!, {
        enabled: Boolean(bomId),
        staleTime: 0
    });
    const { projectBomsAndPrimaryData } = useProjectBomsMappedData(Number(projectId));
    const { projectBomsMappedData, primaryBomId } = projectBomsAndPrimaryData;

    const { handleTablesReordering, sectionRefList, handleTablesWidthChanged } = useCustomisationColumn();

    const aggregateSelectedItemsFromSections = (selectedItems: any, sectionId: number) => {
        let updatedItems = selectedItemsFromSections.filter((item) => item.section !== sectionId);
        updatedItems = [...updatedItems, ...selectedItems];
        setSelectedItemsFromSections(() => [...updatedItems]);
    };

    const unCheckSelectedLineItems = () => {
        setSelectedItemsFromSections([]);
        const gridRefs = Object.values(sectionRefList.current).map((obj) => obj.current);
        gridRefs.forEach((gridRef) => {
            gridRef.api.deselectAll();
        });
    };

    const statsData = getStats(omTabStats);
    const tableData = useMemo(() => {
        if (bomData?.data && omData?.data) {
            const result = getTableData(bomData?.data, omData?.data);
            return prepareKitsAndComponentsData(result);
        }
        return [];
    }, [isLoading, omDataLoading, bomData?.data, omData?.data]);

    const isBomLinked = projectBomsMappedData.get(Number(bomId));

    return (
        <>
            {isBomLinked ? (
                <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />
            ) : (
                <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                    {isBomLinked && <LinkedBomsPopup primaryBomId={primaryBomId || 0} isbomLoading={isLoading} />}
                    <Box flex={1} minHeight={0} pt={4} display="flex" flexDirection="column">
                        <TabContent
                            tableData={tableData}
                            tabCta={<OmTabHeader selectedItems={selectedItemsFromSections.map((item) => item.id)} unCheckSelectedLineItems={unCheckSelectedLineItems} tableData={tableData || []} />}
                            ref={sectionRefList}
                        >
                            <OmTable
                                bomDataLoading={isLoading}
                                omDataLoading={omDataLoading}
                                aggregateSelectedItemsFromSections={aggregateSelectedItemsFromSections}
                                optionSection={undefined as never}
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />

                            <OmTable
                                bomDataLoading={isLoading}
                                omDataLoading={omDataLoading}
                                aggregateSelectedItemsFromSections={aggregateSelectedItemsFromSections}
                                optionSection={undefined as never}
                                isOptionTable
                                mainProductOptionsInfo={mainProductOptionsInfo}
                                handleTablesReordering={handleTablesReordering}
                                handleTablesWidthChanged={handleTablesWidthChanged}
                                selectedRowsRef={selectedRowsRef}
                            />
                        </TabContent>
                    </Box>
                    <Footer statsData={statsData} />
                </Box>
            )}
        </>
    );
};

export default OMTab;
