import { BodyBig, BodyMedium, BodySmall, Box, Grid, Switch } from '@parspec/pixel';

import { SubmittalTemplateInterface, OMTemplateInterface } from '../../queries/apiTypes';

interface TableOfContentsProps {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>, presetName: string) => void;
    templateState: SubmittalTemplateInterface | OMTemplateInterface;
    templateName: 'SUBMITTAL' | 'OM';
}

const GENERAL_INFO_COLUMN_OPTIONS = [
    { label: 'Primary Title', value: 'primary_title' },
    { label: 'Secondary Title', value: 'secondary_title' },
    { label: 'Project Number', value: 'project_number' },
    { label: 'Quote Number', value: 'quote_number' }
];

const TABLE_OF_CONTENTS_INFO_COLUMN_OPTIONS = [
    { label: 'Sections', value: 'section' },
    { label: 'Hyperlinks', value: 'hyperlinks' },
    { label: 'Types', value: 'type' },
    { label: 'Quantities', value: 'qty' },
    { label: 'Page Numbers', value: 'page_number' }
];

export const TableOfContents = (props: TableOfContentsProps) => {
    const { changeHandler, templateState, templateName } = props;

    return (
        <Box mt={'24px'}>
            <Box ml="4px">
                <Switch
                    checked={templateState.table_of_content_presets.toc_section}
                    onChange={(e) => changeHandler(e, 'table_of_content_presets')}
                    name="toc_section"
                    label={
                        <BodyBig fontWeight={500} sx={{ ml: 2 }}>
                            Table of Contents
                        </BodyBig>
                    }
                />
            </Box>
            <BodySmall limit={false} color="text.secondary">
                Choose a design layout and the fields you want to appear by default in your table of contents.
                <br />
                {templateName === 'SUBMITTAL' && (
                    <BodySmall limit={false} color="text.secondary">
                        You can edit the layout and fields during the submittal creation process.
                    </BodySmall>
                )}
                {templateName === 'OM' && (
                    <BodySmall limit={false} color="text.secondary">
                        You can edit the layout and fields during the O&M creation process.
                    </BodySmall>
                )}
            </BodySmall>

            <Box mt={6}>
                <Box>
                    <Box display="flex " position="relative" height={'100%'}>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                General Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {GENERAL_INFO_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.table_of_content_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, 'table_of_content_presets')}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Box width="50%" pr={6}>
                            <BodyMedium color="secondary.light" sx={{ fontWeight: 500 }}>
                                Table of Contents Information
                            </BodyMedium>
                            <Box>
                                <Grid container direction={'column'} sx={{ marginTop: '10px' }}>
                                    {TABLE_OF_CONTENTS_INFO_COLUMN_OPTIONS.map((option) => {
                                        return (
                                            <Box ml="4px" key={option.value}>
                                                <Switch
                                                    checked={templateState.table_of_content_presets[option.value]}
                                                    onChange={(e) => changeHandler(e, 'table_of_content_presets')}
                                                    name={option.value}
                                                    label={<BodySmall>{option.label}</BodySmall>}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
