import { useNavigate } from 'react-router';

import { Box, BodyBig, BodySmall, InfoIcon } from '@parspec/pixel';

export const EmailAlreadyInUse = () => {
    const navigate = useNavigate();
    return (
        <Box bgcolor="secondary.contrastText" width="350px" padding={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
                <BodyBig limit={false} fontWeight={500}>
                    Email Already in Use
                </BodyBig>
                <BodySmall limit={false} mt={4} textAlign="center" color={'text.secondary'}>
                    This email is associated with an account.
                </BodySmall>
            </Box>

            <Box bgcolor={'warning.light'} display={'flex'} alignItems={'flex-start'} p={2} mb={6}>
                <Box color={'warning.main'}>
                    <InfoIcon fontSize={'small'} />
                </Box>
                <Box ml={2}>
                    <BodySmall limit={false} color={'warning.main'}>
                        If you think this is an error, please
                    </BodySmall>
                    <BodySmall limit={false} color={'warning.main'}>
                        email us at support@parspec.io.
                    </BodySmall>
                </Box>
            </Box>

            <Box display="flex" width="100%" alignItems="center" justifyContent="center">
                <BodySmall
                    limit={false}
                    color={'primary.main'}
                    mr={1}
                    onClick={() => navigate('/login')}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                            textDecoration: 'underline'
                        }
                    }}
                >
                    Log in
                </BodySmall>
                <BodySmall limit={false} mx={2}>
                    or{' '}
                </BodySmall>
                <BodySmall
                    limit={false}
                    color={'primary.main'}
                    mr={1}
                    onClick={() => navigate('/forgotPassword')}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                            textDecoration: 'underline'
                        }
                    }}
                >
                    reset password
                </BodySmall>
            </Box>
        </Box>
    );
};
