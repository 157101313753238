import { ReactNode } from 'react';

import { Box, BodySmall } from '@parspec/pixel';

interface IListItem {
    imageDimension?: { width: string };
    imageUrl?: string;
    listTitle?: string;
    Icon?: ReactNode;
    onClick?: () => void;
    isSelected?: boolean;
    open?: boolean;
}

export default function ListItem({ imageDimension, imageUrl, listTitle, Icon, onClick, isSelected, open }: IListItem) {
    return (
        <Box
            onClick={onClick}
            sx={{
                backgroundColor: isSelected ? 'rgba(255,255,255,0.1)' : null,
                borderRadius: isSelected ? 1 : null,
                '&:hover': {
                    bgcolor: 'rgba(255,255,255,0.1)',
                    borderRadius: 1,
                    cursor: 'pointer'
                }
            }}
            display={'flex'}
            gap={5}
            p={2}
            justifyContent={open ? 'flex-start' : 'center'}
        >
            <Box>{Icon ? Icon : <img loading="lazy" src={imageUrl} style={{ width: imageDimension?.width, objectFit: 'contain' }} />}</Box>

            {open && (
                <Box mt={1}>
                    <BodySmall className="textSideNavBarExpand" limit={false} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', opacity: 0.8 }} color={'primary.light'}>
                        {listTitle}
                    </BodySmall>
                </Box>
            )}
        </Box>
    );
}
