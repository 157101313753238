export enum TAX_CALC_VALUES {
    MANUAL_ENTRY = 'manual_entry',
    TAX_EXEMPT = 'tax_exempt'
}

const TAX_CALCULATION_LABEL = {
    [TAX_CALC_VALUES.MANUAL_ENTRY]: 'Manual entry',
    [TAX_CALC_VALUES.TAX_EXEMPT]: 'Taxes excluded'
};

export const RADIO_OPTIONS = [
    { value: TAX_CALC_VALUES.MANUAL_ENTRY, label: TAX_CALCULATION_LABEL[TAX_CALC_VALUES.MANUAL_ENTRY] },
    { value: TAX_CALC_VALUES.TAX_EXEMPT, label: TAX_CALCULATION_LABEL[TAX_CALC_VALUES.TAX_EXEMPT] }
];

export enum QUOTES_SECTION {
    PRODUCTS = 'products',
    SERVICES = 'services',
    FREIGHT = 'freight'
}

export const TAXES_LABELS = {
    [QUOTES_SECTION.PRODUCTS]: 'Products (Extended Price)',
    [QUOTES_SECTION.SERVICES]: 'Services',
    [QUOTES_SECTION.FREIGHT]: 'Freight'
};

export const INIT_CHECKBOX_OPTIONS = [
    {
        name: QUOTES_SECTION.PRODUCTS,
        label: TAXES_LABELS[QUOTES_SECTION.PRODUCTS],
        checked: false
    },
    {
        name: QUOTES_SECTION.SERVICES,
        label: TAXES_LABELS[QUOTES_SECTION.SERVICES],
        checked: false
    },
    {
        name: QUOTES_SECTION.FREIGHT,
        label: TAXES_LABELS[QUOTES_SECTION.FREIGHT],
        checked: false
    }
];
