import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodySmall, Box, Menu, Modal, ModalFooter, ModalHeader, Snackbar, TableHeaderMenuIcon } from '@parspec/pixel';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, RowDragEvent, IRowNode, CellValueChangedEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';

import { useGetLotDetailsQuery, usePutLotsMutation } from './queries';
import { PutLotsRequest, addLotRowType, editLotRowType } from './queries/apiTypes';

import {
    TextCellParams,
    getCellAmountFormatter,
    getCellPercentFormatter,
    getRowId,
    pricingCellParams,
    getTableHeight,
    getParsedContentFromClipboard,
    getAdjacentColumnNames,
    handleOnRangeSelectionChanged
} from '../../shared/utils';
import {
    PRICING_TABLE_ENUM,
    LEAD_TIME_UNITS,
    LEAD_TIME_VALUE_TOOLTIP_MSG,
    EXTENDED_SELL_TOOLTIP_MSG,
    DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG,
    priceRelatedCols,
    serviceRelatedCols
} from '../shared/constants';
import { convertToCents, convertToDollar } from '../../../shared/utils/utils';
import {
    getColorHandlerForPricingColumns,
    getEditableHandlerForPricingColumns,
    getFooterDisabled,
    getTemplateCellStyle,
    getUpdatedPricingInfoForChange,
    getValueGetterForPricingCols
} from '../shared/utils';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { VALIDATION_ERROR_MESSAGES } from './constants';
import { getIdForNewProduct, getValueSetterForLotPricingCols } from './util';
import { useNinetyViewportHeight } from '../../shared/hooks';
import CustomToolBarPanel from '../../../shared/CustomToolBarPanel';
import { isLeadTimeValueEditable } from '../shared/utils';
import { NoRowsOverlayComponent } from '../../shared/commonTemplates';
import { LotLeadTimeUnitTemplate } from '../shared/LeadTimeUnitTemplate';
import { useUserEventCount } from '../../../shared/UserActivityTracking/TrackActivityCount';
import { CustomToolTipForTable } from '../../shared/CustomTooltipForTable';

interface LotPricingModalInterface {
    open: boolean;
    onClose: () => void;
}

export interface LotPriceRow {
    lotName: string | null;
    productCount: number | null;
    cost: number | null;
    discount: number | null;
    discountedCost: number | null;
    margin: number | null;
    sellPrice: number | null;
    extendedPrice: number | null;
    leadTimeUnit: string | null;
    leadTimeValue: number | null;
    sourceQuoteNumber: string | null;
    id: number;
    newRow?: boolean;
}

const defaultColDef: ColDef = {
    cellStyle: getFooterDisabled,
    suppressFillHandle: true,
    tooltipComponent: CustomToolTipForTable
};

const LotPricingModal = ({ open, onClose }: LotPricingModalInterface) => {
    const { bomId = '0' } = useParams();
    const { pushUserEventCount } = useUserEventCount();
    const tableRef = useRef<any>();
    const { data: lotdata, isLoading: isDataLoading } = useGetLotDetailsQuery(bomId, { enabled: Boolean(bomId) });
    const { mutateAsync: saveLotDetails, isLoading: isSaveLoading } = usePutLotsMutation();

    const LotTableColumnDefs: ColDef[] = [
        { headerName: 'id', field: 'id', hide: true },
        {
            headerCheckboxSelection: true,
            showDisabledCheckboxes: true,
            width: 20,
            resizable: false,
            enableRowGroup: true,
            checkboxSelection: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true,
            colSpan: (params) => (params.node?.rowPinned === 'bottom' ? 3 : 0),
            cellStyle: getTemplateCellStyle
        },
        {
            headerName: 'Lot Name',
            field: 'lotName',
            editable: (params) => !params.node.rowPinned,
            width: 133,
            minWidth: 133,
            resizable: true,
            sortable: false,
            menuTabs: [],
            cellEditorParams: TextCellParams,
            cellDataType: 'text'
        },
        { headerName: 'Products', field: 'productCount', cellDataType: 'number', width: 120, minWidth: 120, resizable: true, sortable: false, menuTabs: [] },
        {
            headerName: 'Cost',
            colId: PRICING_TABLE_ENUM.COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForLotPricingCols,
            editable: (params) => !params.node.rowPinned,
            cellDataType: 'number',
            valueFormatter: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            width: 117,
            minWidth: 117,
            resizable: true,
            sortable: false,
            menuTabs: []
        },
        {
            headerName: 'Discount (%)',
            colId: PRICING_TABLE_ENUM.DISCOUNT,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForLotPricingCols,
            editable: getEditableHandlerForPricingColumns,
            valueFormatter: getCellPercentFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellDataType: 'number',
            cellStyle: getColorHandlerForPricingColumns,
            width: 134,
            minWidth: 134,
            resizable: true,
            sortable: false,
            menuTabs: [],
            tooltipValueGetter: (params) => {
                if (!getEditableHandlerForPricingColumns(params) && !params?.node?.rowPinned) return DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG;
                return '';
            }
        },
        {
            headerName: 'Discounted Cost',
            colId: PRICING_TABLE_ENUM.DISCOUNT_COST,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForLotPricingCols,
            editable: getEditableHandlerForPricingColumns,
            cellDataType: 'number',
            valueFormatter: getCellAmountFormatter,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            cellStyle: getColorHandlerForPricingColumns,
            width: 154,
            minWidth: 154,
            resizable: true,
            sortable: false,
            menuTabs: [],
            tooltipValueGetter: (params) => {
                if (!getEditableHandlerForPricingColumns(params) && !params?.node?.rowPinned) return DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG;
                return '';
            }
        },
        {
            headerName: 'Margin (%)',
            colId: PRICING_TABLE_ENUM.MARGIN,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForLotPricingCols,
            editable: getEditableHandlerForPricingColumns,
            cellDataType: 'number',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            valueFormatter: getCellPercentFormatter,
            cellStyle: getColorHandlerForPricingColumns,
            width: 128,
            minWidth: 128,
            resizable: true,
            sortable: false,
            menuTabs: [],
            tooltipValueGetter: (params) => {
                if (!getEditableHandlerForPricingColumns(params) && !params?.node?.rowPinned) return DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG;
                return '';
            }
        },
        {
            headerName: 'Sell Price',
            colId: PRICING_TABLE_ENUM.SELL_PRICE,
            valueGetter: getValueGetterForPricingCols,
            valueSetter: getValueSetterForLotPricingCols,
            editable: (params) => !params.node.rowPinned,
            cellDataType: 'number',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: pricingCellParams,
            valueFormatter: getCellAmountFormatter,
            width: 140,
            minWidth: 140,
            resizable: true,
            sortable: false,
            menuTabs: []
        },
        {
            headerName: 'Ext. Sell Price',
            field: 'extendedPrice',
            cellDataType: 'number',
            editable: false,
            valueFormatter: getCellAmountFormatter,
            width: 140,
            minWidth: 140,
            resizable: true,
            sortable: false,
            menuTabs: [],
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            tooltipValueGetter: (params) => (!params?.node?.rowPinned ? EXTENDED_SELL_TOOLTIP_MSG : '')
        },
        {
            headerName: 'Lead time Value',
            field: PRICING_TABLE_ENUM.LEAD_TIME_VALUE,
            minWidth: 145,
            cellEditor: 'agNumberCellEditor',
            cellClassRules: {
                'ag-grid-disable-cell': (args) => !isLeadTimeValueEditable(args) && !args?.node?.rowPinned,
                'ag-grid-footer-cell': (args) => args?.node?.rowPinned === 'bottom'
            },
            cellEditorParams: {
                precision: 0,
                max: 2147483647,
                preventStepping: true
            },
            editable: isLeadTimeValueEditable,
            tooltipValueGetter: (params) => {
                if (!isLeadTimeValueEditable(params)) return LEAD_TIME_VALUE_TOOLTIP_MSG;
                return '';
            },
            resizable: true,
            sortable: false,
            menuTabs: [],
            cellDataType: 'number'
        },
        {
            headerName: 'Lead time Unit',
            field: PRICING_TABLE_ENUM.LEAD_TIME_UNIT,
            minWidth: 145,
            editable: false,
            cellStyle: { padding: '4px 0px' },
            cellRenderer: (params: any) => LotLeadTimeUnitTemplate(params, handleLeadTimeUnit),
            resizable: true,
            sortable: false,
            menuTabs: []
        },
        {
            headerName: 'Source Quote Number',
            field: 'sourceQuoteNumber',
            editable: (params) => !params.node.rowPinned,
            width: 186,
            minWidth: 186,
            resizable: true,
            sortable: false,
            menuTabs: [],
            cellDataType: 'text'
        },
        {
            minWidth: 50,
            maxWidth: 50,
            editable: false,
            pinned: 'right',
            cellStyle: getTemplateCellStyle,
            cellRenderer: (props: ICellRendererParams) => {
                if (props.node.rowPinned === 'bottom') {
                    return null;
                }
                const {
                    context: { handleSingleDelete },
                    data
                } = props;
                const options = [{ label: 'Delete', onClick: () => handleSingleDelete(data), color: 'error.main' }];
                return (
                    <Box display="flex" justifyContent="center">
                        <Menu options={options} />
                    </Box>
                );
            },
            headerComponent: () => (
                <Box mt={1}>
                    <TableHeaderMenuIcon color="secondary" fontSize="medium" />
                </Box>
            )
        }
    ];

    const [lotPrices, setLotPrices] = useState<LotPriceRow[]>([]);
    const [tableData, setTableData] = useState<LotPriceRow[] | undefined>();
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [deletedIdsArr, setDeletedIdsArr] = useState<number[]>([]);
    const [lotSaveInProgress, setLotSaveInProgress] = useState(false);
    const [selectedItems, setSelectedItems] = useState<LotPriceRow[]>([]);
    const selectedRowsSet = useRef<Set<number>>(new Set());

    const modules = useMemo(() => [ClipboardModule, GridChartsModule], []);

    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    {
        /** maxHeight is kept 90vh - 210px so that modal occupies 90vh & 231px is the height in the modal occupied by other elements*/
    }
    const rowHeight = 40;
    const modalMaxHeight = useNinetyViewportHeight();
    const tableMaxHeight = modalMaxHeight - 231;
    const tableHeight = getTableHeight({ rowHeight: rowHeight, rows: lotPrices.length, tableMaxHeight: tableMaxHeight });

    const totalExtendedPrice = useMemo(() => {
        return lotPrices.reduce((acc, currLotInfo) => acc + (currLotInfo?.extendedPrice || 0), 0);
    }, [lotPrices]);

    useEffect(() => {
        if (isTableLoading) {
            tableRef?.current?.api?.showLoadingOverlay();
        } else {
            tableRef?.current?.api?.hideOverlay();
        }
    }, [isTableLoading]);

    useLayoutEffect(() => {
        if (!isDataLoading) {
            setIsTableLoading(true);
            const lotArr: LotPriceRow[] =
                lotdata?.data?.map((rowData) => {
                    return {
                        lotName: rowData.name,
                        productCount: rowData.n_products,
                        cost: rowData.cost_cents && convertToDollar(rowData.cost_cents),
                        discount: rowData.discount_percentage,
                        discountedCost: rowData.discounted_cost_cents && convertToDollar(rowData.discounted_cost_cents),
                        margin: rowData.margin_percentage,
                        sellPrice: rowData.sell_price_cents && convertToDollar(rowData.sell_price_cents),
                        extendedPrice: rowData.sell_price_cents && convertToDollar(rowData.sell_price_cents),
                        leadTimeUnit: rowData.lead_time_unit,
                        leadTimeValue: rowData.lead_time_value,
                        sourceQuoteNumber: rowData.source_quote_number,
                        id: rowData.id
                    };
                }) || [];
            setLotPrices(lotArr);
            setTableData(lotArr);
            setIsTableLoading(false);
        }
    }, [isDataLoading, lotdata?.data]);

    useEffect(() => {}, [lotPrices]);

    // this is used to fix scenario where table is in edit state and modal save is clicked
    useEffect(() => {
        if (lotSaveInProgress) {
            saveLotsInfo();
        }
    }, [lotSaveInProgress]);

    function validateLots() {
        const lotNameSet = new Set();
        const areLotsValid = lotPrices.every((lotInfo) => {
            const lotName = lotInfo.lotName?.trim().toLowerCase();
            if (!lotName || lotNameSet.has(lotName)) {
                return false;
            }
            lotNameSet.add(lotName);

            return true;
        });

        return areLotsValid;
    }

    function handleLeadTimeUnit(rowNode: IRowNode, params: Partial<LotPriceRow>) {
        rowNode.setData(params);
        setLotPrices((lotPrices) => {
            return lotPrices.map((row) => {
                if (row.id === params.id) {
                    const newRow = { ...row, ...params };
                    return newRow;
                }
                return { ...row };
            });
        });
    }

    const handleOnSave = async () => {
        setTimeout(() => {
            setLotSaveInProgress(true);
        });
    };

    async function saveLotsInfo() {
        const areLotsValid = validateLots();
        if (!areLotsValid) {
            setLotSaveInProgress(false);
            setSnackbarOpen(VALIDATION_ERROR_MESSAGES.SAME_LOT_OR_EMPTY_NAME);
            return;
        }

        const newRows: addLotRowType[] = [];
        const updatedRows: editLotRowType[] = [];

        lotPrices?.forEach((lotPriceRow) => {
            const rowObj = {
                name: lotPriceRow.lotName || '',
                cost_cents: lotPriceRow.cost ? convertToCents(lotPriceRow.cost) : lotPriceRow.cost,
                discount_percentage: lotPriceRow.discount,
                discounted_cost_cents: lotPriceRow.discountedCost ? convertToCents(lotPriceRow.discountedCost) : lotPriceRow.discountedCost,
                margin_percentage: lotPriceRow.margin,
                sell_price_cents: lotPriceRow.sellPrice ? convertToCents(lotPriceRow.sellPrice) : lotPriceRow.sellPrice,
                lead_time_unit: lotPriceRow.leadTimeUnit,
                lead_time_value: lotPriceRow.leadTimeValue,
                source_quote_number: lotPriceRow.sourceQuoteNumber
            };

            if (lotPriceRow.newRow) {
                newRows.push(rowObj);
            } else {
                updatedRows.push({ ...rowObj, id: lotPriceRow.id });
            }
        });

        const request: PutLotsRequest = {
            bomId: bomId!,
            payload: {
                add: newRows,
                edit: updatedRows,
                delete: deletedIdsArr
            }
        };

        await saveLotDetails(request);
        pushUserEventCount({ eventType: 'lot_line_created', needCummulation: false, incrementBy: newRows.length });
        pushUserEventCount({ eventType: 'lot_line_deleted', needCummulation: false, incrementBy: deletedIdsArr.length });
        setLotSaveInProgress(false);
        onClose();
    }

    const handleAddLot = () => {
        setLotPrices((lotPrices) => {
            const newLotPrices = [...lotPrices];
            const newLotPriceRow: LotPriceRow = {
                lotName: '',
                productCount: 0,
                cost: null,
                discount: null,
                discountedCost: null,
                margin: null,
                sellPrice: null,
                extendedPrice: null,
                leadTimeUnit: null,
                leadTimeValue: null,
                sourceQuoteNumber: '',
                id: getIdForNewProduct(newLotPrices),
                newRow: true
            };

            newLotPrices.push(newLotPriceRow);
            setTableData(newLotPrices);
            return newLotPrices;
        });
    };

    const handleMultipleDelete = () => {
        const idsToDelete = new Set(selectedItems.map((row) => row.id));
        const existingDeletedRows: number[] = [...deletedIdsArr];

        const updateLotPrices = lotPrices.filter((row) => !idsToDelete.has(row.id));
        lotPrices.map((row) => {
            if (!row.newRow && idsToDelete.has(row.id)) existingDeletedRows.push(row.id);
        });

        setDeletedIdsArr(existingDeletedRows);
        setLotPrices(updateLotPrices);
        setTableData(updateLotPrices);
    };

    const handleSingleDelete = (props: any) => {
        setLotPrices((lotPrices) => {
            const updateLotPrices = lotPrices.filter((row) => row.id !== props.id);
            setTableData(updateLotPrices);
            return updateLotPrices;
        });

        setDeletedIdsArr((deletedIdsArr) => {
            const existingDeletedRows: number[] = [...deletedIdsArr];
            if (!props.newRow) existingDeletedRows.push(props.id);
            return existingDeletedRows;
        });
    };

    function handleEdit(params: CellValueChangedEvent) {
        const fieldName = params.column.getColId();
        const lotId = params.data.id;
        let updatedRowData = { ...params.data };

        if (fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE && params.data.leadTimeUnit === null && params.data.leadTimeValue !== null) {
            updatedRowData = { ...updatedRowData, leadTimeUnit: LEAD_TIME_UNITS.WEEKS.toLowerCase() };
            params.node.setData(updatedRowData);
        }

        setLotPrices((lotPrices) => {
            return lotPrices.map((row) => {
                if (row.id === lotId) {
                    const newRow = { ...row, ...updatedRowData };
                    return newRow;
                }

                return { ...row };
            });
        });
    }

    function handleRowDragEnd(params: RowDragEvent<any, any>) {
        const { api } = params;
        const updatedRowData: LotPriceRow[] = [];

        api.forEachNode((node) => {
            updatedRowData.push({ ...node.data });
        });
        setLotPrices(updatedRowData);
    }
    const handleCheckboxChange = (args: any) => {
        const selectedBods: any = Array.from(args.api.selectionService.selectedNodes.values()).map((i: any) => i.data);
        setSelectedItems(selectedBods);
    };

    const handleProcessCellPasteForPricing = (args: ProcessCellForExportParams) => {
        const { column, value } = args;
        const fieldName = column.getColId();
        async function generateNewRowsForTable() {
            const rowsFromClipBoard = await getParsedContentFromClipboard();
            if (selectedRowsSet.current.size && rowsFromClipBoard.length) {
                const newRowData = rowsFromClipBoard.slice(selectedRowsSet.current.size);
                if (newRowData.length) {
                    const selectedColArr = getAdjacentColumnNames(tableRef, fieldName, ['id']);
                    const newFromattedRows = newRowData.flatMap((valueArr: string[], i: number) =>
                        valueArr.reduce((acc: any, value: string, index: number) => {
                            if (selectedColArr[index]['cellDataType'] === 'number') {
                                acc[selectedColArr[index]['field']] = Number(value.replace(/[,$%a-zA-Z]/g, ''));
                            } else {
                                acc[selectedColArr[index]['field']] = value;
                            }

                            acc = { ...acc, id: getIdForNewProduct(lotPrices) + i, newRow: true, productCount: 0, leadTimeUnit: null, leadTimeValue: null, qty: 1 };
                            return acc;
                        }, {})
                    );

                    for (let i = 0; i < newFromattedRows.length; i++) {
                        let item = newFromattedRows[i];
                        if (item && item?.cost) {
                            const { cost, discount = null, discountedCost = null, margin = null, sellPrice = null, extendedPrice = null, qty } = item;
                            const updatedItem = getUpdatedPricingInfoForChange({ cost, discount, discountedCost, margin, sellPrice, extendedPrice, qty }, PRICING_TABLE_ENUM.COST);
                            newFromattedRows[i] = { ...item, ...updatedItem };
                        }
                    }

                    const newLotsData = [...lotPrices, ...newFromattedRows];
                    setLotPrices(newLotsData as LotPriceRow[]);
                    setTableData(newLotsData as LotPriceRow[]);
                    selectedRowsSet.current.clear();
                }
            }
        }
        generateNewRowsForTable();

        if (fieldName === 'qty') {
            if (value !== null && value !== '' && !isNaN(value as unknown as number)) {
                return Number(value);
            } else {
                return null;
            }
        } else if (value === '' || value == null) {
            return fieldName === PRICING_TABLE_ENUM.LEAD_TIME_VALUE ? null : '';
        }
        const processCellPasteCols = new Set([...priceRelatedCols, ...serviceRelatedCols]);
        if (processCellPasteCols.has(fieldName)) {
            const newValue: string = value.replace(/\$|,|%/g, '');
            if (newValue !== '' && !isNaN(newValue as unknown as number)) {
                return parseFloat(newValue);
            }
            return null;
        }
        return value;
    };

    return (
        <>
            <Snackbar
                open={snackbarInfo.open}
                message={snackbarInfo.message}
                onClose={(_e, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setSnackbarClose();
                }}
                autoHideDuration={snackbarInfo.autoHideDuration}
            />
            <Modal
                open={open}
                header={<ModalHeader title="Lot Pricing" onClose={onClose} />}
                footer={<ModalFooter onAccept={handleOnSave} onReject={onClose} continueButtonLabel="Save" isLoading={isSaveLoading} />}
            >
                <Box pt={6} pb={3} width="1120px">
                    <Box>
                        <BodySmall>The following are your lots for the manufacturers on this project.</BodySmall>
                    </Box>
                    <Box pt={3}>
                        <Box>
                            <CustomToolBarPanel
                                title="Lot Prices"
                                toolBarOptions={['add', 'delete']}
                                selectedRowCount={selectedItems.length}
                                disabledToolBarButton={selectedItems.length === 0}
                                onAdd={handleAddLot}
                                onDelete={handleMultipleDelete}
                            ></CustomToolBarPanel>
                        </Box>
                        <Box style={{ height: tableHeight, width: '100%' }} className="ag-theme-alpine">
                            <AgGridReact
                                ref={tableRef}
                                rowData={tableData}
                                getRowId={getRowId}
                                gridOptions={{
                                    rowHeight,
                                    enableRangeSelection: true,
                                    enableFillHandle: true,
                                    tooltipShowDelay: 0,
                                    tooltipInteraction: true,
                                    undoRedoCellEditing: true,
                                    enterNavigatesVerticallyAfterEdit: true,
                                    enterNavigatesVertically: true,
                                    rowDragManaged: true,
                                    suppressRowClickSelection: true,
                                    stopEditingWhenCellsLoseFocus: true,
                                    getRowStyle: (params) => {
                                        if (params.node.rowPinned) {
                                            return { backgroundColor: '#f8f8f8', fontWeight: 700 };
                                        }
                                        return undefined;
                                    },
                                    context: {
                                        handleSingleDelete
                                    },
                                    suppressContextMenu: true
                                }}
                                rowSelection={'multiple'}
                                isRowSelectable={(params) => !params.rowPinned}
                                columnDefs={LotTableColumnDefs}
                                modules={modules}
                                onCellValueChanged={handleEdit}
                                onRowDragEnd={handleRowDragEnd}
                                onRowDragLeave={handleRowDragEnd}
                                processCellFromClipboard={handleProcessCellPasteForPricing}
                                onSelectionChanged={handleCheckboxChange}
                                pinnedBottomRowData={[{ checkbox: 'Subtotal', extendedPrice: totalExtendedPrice }]}
                                defaultColDef={defaultColDef}
                                noRowsOverlayComponent={(props: any) => <NoRowsOverlayComponent {...props} />}
                                noRowsOverlayComponentParams={{
                                    isTableHaveFooter: true
                                }}
                                reactiveCustomComponents
                                onRangeSelectionChanged={(params) => handleOnRangeSelectionChanged(params, tableRef, selectedRowsSet)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default LotPricingModal;
