import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    BodyMedium,
    BodyXS,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    CloseIcon,
    FileSelector,
    FileSelectorFileType,
    IconButton,
    Modal,
    ModalFooter,
    ModalHeader,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField
} from '@parspec/pixel';

import { useNinetyViewportHeight } from '../../../BOM/shared/hooks';
import { BUSINESS_TYPE_OPTIONS, VISIBILITY_RADIO_OPTIONS, acceptedFileTypes, acceptedFileTypesString, CONTACT_NUMBER_REQEX } from '../../shared/constant';
import { environment } from 'src/environments';
import { CANADA_STATES, COUNTRY, COUNTRY_OPTIONS, EMAIL_FORMAT_INVALID, INVALIED_URL_MSG, LOCATION_REQUIRED_MESSAGE, LOGO_UPLOAD_MSG, REQUIRED_FIELD, USA_STATES } from '../../../shared/constants';
import { ICompanyLocation } from '../../../Settings/UserManagement/queries/apiTypes';
import { validateEmail } from '../../../Settings/UserManagement/utils/utils';
import { websiteValidationRegex } from 'src/features/shared/utils/utils';
import { useCreateCompanyMutation } from '../queries';
import { useCompanyInfoQuery } from '../../../Settings/BranchLocations/queries';
import { modifiedStateList } from '../../../shared/utils/utils';
import useBroadCaster, { BROADCAST_MESSAGES } from '../../../shared/hooks/useBroadCaster';

type ICreateCompanyProps = {
    open: boolean;
    onClose: () => void;
};

type IErrorField = {
    nameErr: string;
    visibilityErr: string;
    emailErr: string;
    websiteErr: string;
    logoError: string;
};

export type CreateCompanyFormType = {
    name: string;
    businessType: string;
    email: string;
    phoneNo: string;
    website: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    notes: string;
    visiblity: string;
    logo: { file?: File; s3_file_path: string };
    erp_customer_id?: string | null;
};

const CreateCompany = ({ open, onClose }: ICreateCompanyProps) => {
    const navigate = useNavigate();
    const { postMessage } = useBroadCaster();

    const [stateListOptions, setStateListOptions] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }]);
    const [formValues, setFormValues] = useState<CreateCompanyFormType>({
        name: '',
        businessType: '',
        email: '',
        phoneNo: '',
        website: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        notes: '',
        visiblity: VISIBILITY_RADIO_OPTIONS[0].value,
        logo: { s3_file_path: '' },
        erp_customer_id: ''
    });
    const [locationData, setLocationData] = useState<ICompanyLocation[]>();
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [formError, setFormError] = useState<IErrorField>({
        nameErr: '',
        visibilityErr: '',
        emailErr: '',
        websiteErr: '',
        logoError: ''
    });
    const [allSelected, setAllSelected] = useState(false);

    const { data: allBranchLocations, isLoading: locationsLoading } = useCompanyInfoQuery();
    const { mutateAsync: createCompany, isLoading: createCompanyLoading } = useCreateCompanyMutation();

    useEffect(() => {
        const locationData = (allBranchLocations?.data as unknown[] as ICompanyLocation[])?.map((el: ICompanyLocation) => {
            return { ...el, isChecked: false };
        });
        setLocationData(locationData);
    }, [allBranchLocations?.data]);

    const modalMaxHeight = useNinetyViewportHeight();
    const boxMaxHeight = modalMaxHeight - 120;
    const formHeight = boxMaxHeight - 64;

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
        const { name } = event.target;
        const value = event.target?.value as string;

        if (name === 'name') {
            if (formError.nameErr.length > 0) {
                setFormError({ ...formError, nameErr: '' });
            }
        }

        if (name === 'email') {
            if (formError.emailErr.length > 0) {
                setFormError({ ...formError, emailErr: '' });
            }
        }

        if (name === 'website') {
            if (formError.websiteErr.length > 0) {
                setFormError({ ...formError, websiteErr: '' });
            }
        }

        if (name === 'phoneNo') {
            if (!CONTACT_NUMBER_REQEX.test(value)) {
                return;
            }
        }

        if (name === 'country') {
            let stateOptions: { id: string; name: string }[] = [];
            if (value === COUNTRY.USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (value === COUNTRY.CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            setStateListOptions(stateOptions);
        }

        const updatedData = { ...formValues, [name]: value };
        setFormValues(updatedData);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name } = event.target;
        if (name === 'email' && email.length > 0) {
            setFormError({ ...formError, emailErr: !validateEmail(email) ? EMAIL_FORMAT_INVALID : '' });
        }
        if (name === 'website' && website.length > 0) {
            setFormError({ ...formError, websiteErr: !websiteValidationRegex().test(website.trim()) ? INVALIED_URL_MSG : '' });
        }
    };

    const checkboxHandler = (_event: React.ChangeEvent<HTMLInputElement>, eachLocationData: ICompanyLocation) => {
        const checkedData: string[] = [];
        const locDataArr = locationData?.map((el: ICompanyLocation) => {
            if (el.id === eachLocationData?.id) {
                if (!el.isChecked) {
                    checkedData.push(el.id.toString());
                }
                return { ...el, isChecked: !el.isChecked };
            } else {
                if (el.isChecked) {
                    checkedData.push(el.id.toString());
                }
                return el;
            }
        });

        if (checkedData.length === locationData?.length) setAllSelected(true);
        else setAllSelected(false);
        setSelectedLocations(checkedData);
        setLocationData(locDataArr);
        setFormError({ ...formError, visibilityErr: '' });
    };

    const handleSelectAll = () => {
        if (allSelected) {
            const allSelectedLocations = locationData?.map((locObj) => {
                locObj.isChecked = false;
                return locObj;
            });
            setSelectedLocations([]);
            setLocationData(allSelectedLocations);
            setAllSelected(false);
        } else {
            const allSelectedLocations = locationData?.map((locObj) => {
                locObj.isChecked = true;
                return locObj;
            });
            const selectedLocId: string[] = (allSelectedLocations?.length && allSelectedLocations.map((el) => String(el.id))) || [];
            setSelectedLocations(selectedLocId);
            setLocationData(allSelectedLocations);
            setAllSelected(true);
        }
        setFormError({ ...formError, visibilityErr: '' });
    };

    const onSelectLogo = (files: File[] | FileSelectorFileType[]) => {
        const values = { ...formValues, logo: { file: files[0] as File, s3_file_path: '' } };
        setFormValues(values);
    };

    const onUploadLogo = (files: { file: File | FileSelectorFileType; s3_file_path?: string }[]) => {
        if (files?.[0]) {
            const { file, s3_file_path } = files[0];
            const values = { ...formValues, logo: { file: file as File, s3_file_path: s3_file_path as string } };
            setFormValues(values);
        }
        setFormError((old) => ({ ...old, logoError: '' }));
    };

    const { name, businessType, email, phoneNo, website, address1, address2, city, state, country, zip, notes, visiblity, logo, erp_customer_id } = formValues;

    const handleFormSubmit = async () => {
        const err: IErrorField = {
            nameErr: !name.trim() ? REQUIRED_FIELD : '',
            visibilityErr: visiblity === VISIBILITY_RADIO_OPTIONS[1].value && selectedLocations.length === 0 ? LOCATION_REQUIRED_MESSAGE : '',
            emailErr: email?.trim() && !validateEmail(email) ? EMAIL_FORMAT_INVALID : '',
            websiteErr: website?.trim() && !websiteValidationRegex().test(website.trim()) ? INVALIED_URL_MSG : '',
            logoError: logo?.file && !logo.s3_file_path ? LOGO_UPLOAD_MSG : ''
        };
        const isError = Object.keys(err).some((item) => Boolean(err[item as keyof IErrorField]));
        if (isError) {
            return setFormError(err);
        }
        const payload = {
            name: name.trim(),
            business_type: businessType,
            email: email.trim(),
            phone: phoneNo.trim(),
            website: website.trim(),
            line1: address1.trim(),
            line2: address2.trim(),
            city: city.trim(),
            country: country.trim(),
            state: state.trim(),
            zip: zip.trim(),
            notes: notes.trim(),
            visible_to_all: visiblity === VISIBILITY_RADIO_OPTIONS[0].value,
            visible_to: selectedLocations,
            logo: logo?.s3_file_path || '',
            erp_customer_id: erp_customer_id || ''
        };
        const response = await createCompany(payload);
        postMessage(BROADCAST_MESSAGES.INVALIDATE_COMPANIES + new Date().toISOString());
        const { id } = response.data;
        navigate(`/v2/company/${id}/`);
    };

    const handleDeleteImagePreview = useCallback(() => {
        setFormValues({ ...formValues, logo: { s3_file_path: '' } });
    }, [formValues]);

    return (
        <Modal
            open={open}
            header={<ModalHeader title="Create Company" onClose={onClose} />}
            footer={<ModalFooter continueButtonLabel="Confirm" onReject={onClose} onAccept={handleFormSubmit} isLoading={createCompanyLoading} />}
        >
            <Box width="550px" pt={4} pb={4}>
                <Box maxHeight={formHeight} overflow="scroll">
                    <Box display="flex" width="98%" flexDirection="column" rowGap={3}>
                        <BodyMedium>Details</BodyMedium>
                        <TextField
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                            label="Name*"
                            name="name"
                            error={Boolean(formError.nameErr)}
                            helperText={formError.nameErr}
                        />
                        <TextField value={erp_customer_id} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="CRM/ERP ID" name="erp_customer_id" />
                        <Box display="flex" flexDirection="column" rowGap={1}>
                            <Select value={businessType} options={BUSINESS_TYPE_OPTIONS} onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)} label="Business Type" name="businessType" />
                        </Box>
                        <TextField
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                            label="Email"
                            name="email"
                            onBlur={handleBlur}
                            error={Boolean(formError.emailErr)}
                            helperText={formError.emailErr}
                        />
                        <TextField value={phoneNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Phone Number" name="phoneNo" />
                        <TextField
                            value={website}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                            label="Website"
                            name="website"
                            onBlur={handleBlur}
                            error={Boolean(formError.websiteErr)}
                            helperText={formError.websiteErr}
                        />
                    </Box>

                    <Box display="flex" width="98%" flexDirection="column" rowGap={3} mt={4}>
                        <BodyMedium>Address</BodyMedium>
                        <TextField value={address1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Address Line 1" name="address1" />
                        <TextField value={address2} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Address Line 2" name="address2" />
                        <Box display="flex" columnGap={3}>
                            <Select
                                value={country}
                                onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)}
                                options={COUNTRY_OPTIONS}
                                optionLabelKeyname="name"
                                optionValueKeyname="id"
                                label="Country"
                                name="country"
                            />
                            <TextField value={city} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="City" name="city" />
                        </Box>
                        <Box display="flex" columnGap={3}>
                            <Select
                                value={state}
                                options={stateListOptions}
                                optionLabelKeyname="name"
                                optionValueKeyname="id"
                                onChange={(e: SelectChangeEvent<unknown>) => handleFormChange(e)}
                                label="State"
                                name="state"
                            />
                            <TextField value={zip} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Zip" name="zip" />
                        </Box>
                    </Box>

                    <Box display="flex" width="98%" flexDirection="column" rowGap={3} mt={4}>
                        <BodyMedium>Notes</BodyMedium>
                        <TextField value={notes} multiline={true} rows={3} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)} label="Company Notes" name="notes" />
                    </Box>

                    <Box display="flex" width="98%" flexDirection="column" mt={4}>
                        <BodyMedium>Visibility</BodyMedium>
                        <Box px={0.5}>
                            <RadioGroup options={VISIBILITY_RADIO_OPTIONS} value={visiblity} onChange={handleFormChange} name="visiblity" label="" />
                        </Box>
                        {visiblity === VISIBILITY_RADIO_OPTIONS[1].value && (
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex" alignItems="baseline">
                                        <BodyMedium>Location Access</BodyMedium>
                                        <BodyXS mt={1} color={'error.main'}>
                                            *
                                        </BodyXS>
                                    </Box>
                                    <Box>
                                        <Button variant="outlined" color="secondary" size="small" onClick={handleSelectAll}>
                                            {allSelected ? 'Deselect All' : 'Select All'}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box my={3} position={'relative'} maxHeight={'194px'} bgcolor={'neutral.light'} p={4} sx={{ overflow: 'hidden', overflowY: 'scroll' }}>
                                    {locationsLoading && (
                                        <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                                            <CircularProgress size="lg" color={'primary'} thickness={3.6} />
                                        </Box>
                                    )}
                                    <Box>
                                        {locationData &&
                                            locationData?.length !== 0 &&
                                            locationData.map((eachLocationData: ICompanyLocation) => {
                                                return (
                                                    <Box
                                                        key={eachLocationData.id}
                                                        sx={{
                                                            '.MuiFormControlLabel-label': {
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                width: '375px'
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox checked={eachLocationData.isChecked} label={eachLocationData.name} onChange={(event) => checkboxHandler(event, eachLocationData)} />
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                </Box>
                                {Boolean(formError.visibilityErr) && (
                                    <Box mb={2}>
                                        <BodyXS color={'error.main'}>{formError.visibilityErr}</BodyXS>
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>

                    <Box display="flex" width="98%" flexDirection="column" rowGap={3} mt={4}>
                        <Box display="flex" justifyContent="space-between" alignItems="baseline">
                            <BodyMedium>Company Logo</BodyMedium>
                            {logo.s3_file_path && (
                                <Box>
                                    <IconButton onClick={() => handleDeleteImagePreview()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        {logo.s3_file_path && logo.file && <img src={URL.createObjectURL(logo.file) || ''} style={{ objectFit: 'contain', width: '100%', maxHeight: 236 }} />}

                        {!logo.s3_file_path && (
                            <FileSelector
                                placeholder="Drag and drop a file here, or:"
                                url={`${environment.b}/api/generate_signed_url/`}
                                onUpload={onUploadLogo}
                                onSelect={onSelectLogo}
                                acceptedFormats={acceptedFileTypes}
                                helperText={acceptedFileTypesString}
                                error={formError.logoError}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateCompany;
