import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BodyBig, Box, BoxProps, H5, WarningIcon } from '@parspec/pixel';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import { getArrayBetweenNumbers, getParsedXFDFForWebviewer, getProxyFileUrl } from 'src/features/shared/utils/utils';
import { environment } from 'src/environments';
import { WEBVIEWER_FOLDER_NAME } from '../constants';
import { useGetDatasheetQuery, useGetSelectedDocumentQuery } from '../../BOM/Datasheets/AnnotateDatasheet/queries';

import { HIDDEN_XFDF } from 'src/features/shared/utils/constants';

interface PlainPdfViewerProps extends Omit<BoxProps, 'ref'> {
    fileUrl: string;
    documentType?: string;
    subDocumentType?: string;
    errorUi?: ReactNode;
    isTargetDoc?: boolean;
}

export default function AnnotatedPdfViewer({ fileUrl, documentType, subDocumentType, errorUi, isTargetDoc = false, ...rest }: PlainPdfViewerProps) {
    const params = useParams();
    const { bomId, productId } = params;

    const [pdfLoadError, setPdfLoadError] = useState(false);

    const pdfViewerRef = useRef<HTMLDivElement>();
    const hiddenPages = useRef<number[]>([]);
    const xfdf = useRef<string>();
    const hiddenXfdf = useRef<string>(HIDDEN_XFDF);
    const totalPages = useRef<number>(0);
    const pdfViewerInstanceRef = useRef<WebViewerInstance | null>(null);

    const { data: selectedDocumentData } = useGetSelectedDocumentQuery(bomId!, productId!, documentType!, subDocumentType!, {});
    const selectedDatasheetId = selectedDocumentData?.data[0].id || 0;
    const datasheetId = selectedDocumentData?.data[0].document || 0;

    const { data: datasheetFileData } = useGetDatasheetQuery(`${bomId}`, `${productId}`, selectedDatasheetId, datasheetId, documentType!, subDocumentType!, {
        enabled: Boolean(selectedDocumentData?.data[0].id)
    });

    const setHiddenPages = (pages: number[]) => {
        hiddenPages.current = pages;
    };
    const setXfdf = (newXfdf: string) => {
        xfdf.current = newXfdf;
    };

    useEffect(() => {
        if (selectedDocumentData?.data) {
            const { draft_xfdf } = selectedDocumentData.data[0];
            setXfdf(draft_xfdf);
        }
    }, [selectedDocumentData?.data[0].draft_xfdf]);

    useEffect(() => {
        if (selectedDocumentData?.data) {
            const { hidden_pages } = selectedDocumentData.data[0];
            setHiddenPages(hidden_pages);
        }
    }, [selectedDocumentData?.data[0].hidden_pages]);

    const handlePdfViewer = useCallback((ref: HTMLDivElement) => {
        pdfViewerRef.current = ref;
        WebViewer(
            {
                path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                initialDoc: getProxyFileUrl(fileUrl),
                licenseKey: environment.Y,
                useDownloader: false,
                disabledElements: [
                    'selectToolButton',
                    'toolbarGroup-Edit',
                    'toolsHeader',
                    'header',
                    'panToolButton',
                    'toggleNotesButton',
                    'notesPanel',
                    'menuButton',
                    'highlightToolGroupButton',
                    'underlineToolGroupButton',
                    'strikeoutToolGroupButton',
                    'stickyToolGroupButton',
                    'freeHandToolGroupButton',
                    'toolsOverlay',
                    'viewControlsButton',
                    'leftPanelButton',
                    'toolbarGroup-Insert',
                    'toolbarGroup-Shapes',
                    'squigglyToolGroupButton',
                    'freeTextToolGroupButton',
                    'errorModal',
                    'annotationPopup',
                    'ribbons',
                    'contextMenuPopup',
                    'linkAnnotationPopup'
                ]
            },
            pdfViewerRef.current
        ).then((webviewerInstance) => {
            pdfViewerInstanceRef.current = webviewerInstance;
            const { documentViewer, annotationManager } = webviewerInstance.Core;
            webviewerInstance.UI.enableElements(['header']);
            setHeaderItems(webviewerInstance);
            documentViewer.enableReadOnlyMode();

            const UIEvents = webviewerInstance.UI.Events;
            webviewerInstance.UI.addEventListener(UIEvents.LOAD_ERROR, () => {
                setPdfLoadError(true);
            });

            documentViewer.addEventListener('documentLoaded', async () => {
                totalPages.current = documentViewer.getPageCount();
                webviewerInstance.UI.closeElements(['loadingModal']);
                webviewerInstance.UI.setFitMode(webviewerInstance.UI.FitMode.FitWidth);
                await webviewerInstance.Core.documentViewer.getDocument().removePages([...(hiddenPages?.current || [])]);
                if (!isTargetDoc) importAnnotations(webviewerInstance);
            });

            annotationManager.addEventListener('annotationChanged', (annotations: any, _action: any, { imported }: any) => {
                if (imported) {
                    annotations.forEach((annot: any) => {
                        annot.BlendMode = 'multiply';
                    });

                    return;
                }
            });
        });
    }, []);

    const importAnnotations = async (wvInstance: any) => {
        const { annotationManager } = wvInstance.Core;
        annotationManager.enableReadOnlyMode(true);
        const selectedPages = getArrayBetweenNumbers(1, totalPages.current).filter((item) => !hiddenPages.current?.includes(item));
        if (xfdf.current) {
            hiddenXfdf.current = HIDDEN_XFDF;
            const { mutatedXfdf, hiddenXfdf: parsedHiddenXfdf } = getParsedXFDFForWebviewer(xfdf.current, selectedPages, hiddenPages.current, hiddenXfdf.current);
            setXfdf(mutatedXfdf);
            hiddenXfdf.current = parsedHiddenXfdf;
            await annotationManager.importAnnotations(mutatedXfdf);
        }
    };

    const openInNewTab = () => {
        window.open(getProxyFileUrl(fileUrl), '_blank');
    };

    function setHeaderItems(instance: WebViewerInstance) {
        let searchButtonElem = {};
        instance.UI.setHeaderItems((header: any) => {
            const items = header.getItems().filter((item: Record<string, any>) => {
                if (item.dataElement === 'zoomOverlayButton') {
                    return true;
                }
                if (item.dataElement === 'searchButton') {
                    searchButtonElem = { ...item };
                }
                return false;
            });
            items.unshift({ type: 'spacer' });
            items.unshift({
                type: 'actionButton',
                img: '../../../../../../../../../assets/icons/open_in_new.svg',
                onClick: openInNewTab,
                title: 'Open in new tab',
                dataElement: 'alertButton'
            });

            items.push({ type: 'spacer' });
            items.push(searchButtonElem);
            header.update(items);
        });
    }

    useEffect(() => {
        if (pdfViewerInstanceRef.current && fileUrl) {
            pdfViewerInstanceRef.current.UI.loadDocument(getProxyFileUrl(fileUrl));
        }
    }, [fileUrl]);

    const isShowPdfView = datasheetFileData && selectedDocumentData ? !(datasheetFileData?.data.total_pages === selectedDocumentData?.data?.[0]?.hidden_pages?.length) : true;

    return (
        <>
            {isShowPdfView ? (
                !pdfLoadError ? (
                    <Box ref={handlePdfViewer} height="100%" {...rest}></Box>
                ) : (
                    <>{errorUi}</> || null
                )
            ) : (
                <Box height="100%" display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
                    <Box fontSize={80}>
                        <WarningIcon fontSize="inherit" color="tertiary" />
                    </Box>
                    <H5 color="text.secondary" m={1}>
                        No pages recognized for this product
                    </H5>
                    <BodyBig color="text.secondary"> Adjust page selection or change the datasheet to continue</BodyBig>
                </Box>
            )}{' '}
        </>
    );
}
