export const omTabData = [
  {
    bod_id: 1,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'AUTO_SELECTED',
      document_selection_status: 'AUTO_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 2,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 3,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 4,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 5,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
];

export const omStatsData = {
  selected_instalation_guide: 12,
  selected_installation_drawing: 123,
  selected_warranty: 45,
  hidden_product: 45,
  total: 100,
};

export const omReuseWarrantyData = [
  {
    bod_id: 1,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 2,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 3,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'ANNOTATED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 4,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
  {
    bod_id: 5,
    selected_warranty: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_installation_guide: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_drawing: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
    selected_other_document: {
      id: 123,
      document_id: 13,
      o_m_metadata_id: 1,
      hidden: [1, 4, 5],
      Is_blank_document: true,
      annotation_status: 'NOT_SELECTED',
      document_selection_status: 'NOT_SELECTED',
      is_deleted: false,
      created_at: '',
      updated_at: '',
    },
  },
];
