import { ColDef } from 'ag-grid-community';

export const getRowId = (params: any) => {
    return params?.data?.field;
};

export const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    suppressFillHandle: true,
    suppressMenu: true,
    sortable: false,
    unSortIcon: true,
    menuTabs: [],
    wrapHeaderText: true,
    suppressMovable: true,
    editable: false
};
