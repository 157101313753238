import { Box, CircularProgress } from '@parspec/pixel';

const TabLabel = ({ label, count, isLoading }: { label: string; count: number; isLoading: boolean }) => (
    <Box display="flex" alignItems="center" gap={1}>
        {label} {isLoading ? <CircularProgress color="primary" size="xs" /> : <>({count})</>}
    </Box>
);

export const TAB_OPTIONS = (isLoading: boolean, totalCount: number[]) => {
    // Destructure totalCount for easier use
    const [activeCount = 0, invitedCount = 0, deactivatedCount = 0] = totalCount;

    return [
        {
            label: <TabLabel label="Active" count={activeCount} isLoading={isLoading} />,
            value: 'active'
        },
        {
            label: <TabLabel label="Invited" count={invitedCount} isLoading={isLoading} />,
            value: 'invited'
        },
        {
            label: <TabLabel label="Deactivated" count={deactivatedCount} isLoading={isLoading} />,
            value: 'inactive'
        }
    ];
};
