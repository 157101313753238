import { getEvent, pushEvent } from '../UserActivityTracking/EventService';
import { axios } from '../../../app/Axios';
import { getStringifiedValueForEventService } from '../../shared/utils/utils';
import { environment } from 'src/environments';

const endpoint = '/api/hasura/auth';
const dropdownEndpoints: { [key: string]: string } = {
    company: '/rolodex/company_drop_down/',
    contact: '/rolodex/contact_drop_down/'
};

export const getFeatureFlagQuery = (email?: string, company_group_id?: number) => {
    return getEvent({
        getQuery: `query  {
                      feature_flag_db {
                          flags(where: {flag_segments: {segment: {_or: [
                              {attribute: {_eq: "email"}, values: {_contains: "{${email}}"} },
                              {attribute: {_eq: "company_group_id"}, values: {_contains: "{${company_group_id}}"}}
                          ]}}}) {
                              name
                          }
                      }
                  }`,
        responseAttribute: 'feature_flag_db'
    });
};

const getQuery = `
query {
    events_db {
      app_maintenance {
        end_date_time
        is_under_maintenance
      }
    }
  }
`;

export const getMaintenanceData = () => {
    return getEvent({ getQuery, responseAttribute: 'events_db' });
};

export const getSelectedFilters = (userId: number, pageName: string) => {
    return getEvent({
        getQuery: `query{
          events_db {
            table_settings_by_pk(page_name: "${pageName}", user_id: ${userId}) {
              filters
            }
          }
        }`,
        responseAttribute: 'events_db'
    });
};

const insertSelectedFiltersMutation = (filters: any, pageName: string) => {
    return `mutation($filters: jsonb = ${getStringifiedValueForEventService(filters)}) {
  events_db {
    insert_table_settings(objects: {filters: $filters, page_name: "${pageName}"}) {
      affected_rows
    }
  }
}`;
};

const updateFiltersMutation = (filters: any, userId: number, pageName: string) => {
    return `mutation($filters: jsonb = ${getStringifiedValueForEventService(filters)}) {
      events_db {
        update_table_settings(_set: {filters: $filters}, where: {user_id: {_eq: ${userId}},  page_name: {_eq: "${pageName}"}}) {
          affected_rows
        }
      }
    }`;
};

export const updateSelectedFilters = ({ filters, userId, pageName }: any) => {
    return pushEvent({
        insertMutation: insertSelectedFiltersMutation(filters, pageName),
        updateMutation: updateFiltersMutation(filters, userId, pageName),
        responseAttribute: 'table_settings'
    });
};

export const fetchHasuraToken = () => {
    return axios.post(endpoint);
};

export const getDeploymentInfo = async () => {
    const res = await fetch(environment.N, {
        headers: {
            'Cache-Control': 'no-cache'
        }
    });

    const data = await res.json();
    return data;
};

export const getCompanyDropdownList = () => {
    return axios.get(dropdownEndpoints.company);
};

export const getContactDropdownList = () => {
    return axios.get(dropdownEndpoints.contact);
};
