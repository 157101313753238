export const getOutOfSyncResponse = () => [
    {
        id: 17611,
        out_of_sync: Math.random() < 0.5,
        updated_at: '2011-10-05T14:48:00.000Z',
        created_at: '2011-10-05T14:48:00.000Z'
    },
    {
        id: 17706,
        out_of_sync: Math.random() < 0.5,
        updated_at: '2011-10-05T14:48:00.000Z',
        created_at: '2011-10-05T14:48:00.000Z'
    },
    {
        id: 177061,
        out_of_sync: Math.random() < 0.5,
        updated_at: '2011-10-05T14:48:00.000Z',
        created_at: '2011-10-05T14:48:00.000Z'
    },
    {
        id: 1761,
        out_of_sync: Math.random() < 0.5,
        updated_at: '2011-10-05T14:48:00.000Z',
        created_at: '2011-10-05T14:48:00.000Z'
    }
];
