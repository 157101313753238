export const deleteMsg = 'Successfully deleted!';
export const duplicateMsg = 'Successfully duplicated!';
export const someThingWentWrongMsg = 'Something went wrong!';
export const reorderMsg = 'Successfully Reordered!';
export const hideMsg = 'Successfully hidden/unhidden';
export const addMsg = 'Successfully added!';
export const updateMsg = 'Successfully updated!';
export const savedSuccessfully = 'Saved successfully';
export const saving = 'Saving...';
export const copyMsg = 'Copied to Clipboard!';
export const selectPdfMsg = 'Select a valid pdf file!';
export const selectDocumentMsg = 'Document selected successfully!';
export const unselectDocumentMsg = 'Document unselected successfully!';
export const autoSearchMsg = 'Autosearch completed successfully!';
export const NO_CHANGES_TO_SAVE_MSG = 'No changes to save';
export const BOM_DELETE_CONFIRMATION_TITLE = 'Delete Bill of Materials?';
export const BOM_DELETE_HEADING = 'Are you sure you want to delete this bill of materials?';
export const BOM_DELETE_SUB_HEADING = 'Deleting this bill of materials will also delete all documents created in this bill of materials.';
export const PROJECT_DELETE_TITLE = 'Delete Project?';
export const PROJECT_DELETE_HEADING = 'Are you sure you want to delete this project?';
export const PROJECT_DELETE_SUB_HEADING = 'Deleting this project will also delete all bills of materials in this project, and all documents created in these bills of materials.';
export const SUCCESSFULLY_FEEDBACK_SUBMITION = 'Thank you for your feedback!';
export const SECTION_UPDATE_MSG = 'Section updated successfully!';
export const SECTION_DELETE_CONTENT = 'Deleting this section will remove it across all the tabs of this Bill of Materials';
export const SECTION_DELETE_MSG = 'Section deleted successfully!';
export const SECTION_CREATE_MSG = 'Section created successfully!';
export const OPTION_CREATE_MSG = 'Option created successfully!';
export const MOVE_SECTION_MSG = 'Items moved to selected section successfully!';
export const SELECT_ONE_SECTION_MSG = 'Please select at least one section to proceed.';
export const SUCCESSFULY_MOVED_PRODUCT = 'Successfully moved product!';
export const REMOVE_PRODUCT_FROM_KIT = 'Product moved out successfully!';
export const REQUIRED_FIELD = 'This field is required.';
export const EMAIL_FORMAT_INVALID = 'Email is invalid.';
export const CONTACT_CREATED_MSG = 'Contact created successfully.';
export const CONTACT_EDITED_MSG = 'Contact updated successfully.';
export const CONTACT_DELETED_MSG = 'Contact(s) deleted successfully.';
export const EMAIL_COPIED_MSG = 'Email copied to clipboard';
export const INVALIED_URL_MSG = 'Invalid Url';
export const LOGO_UPLOAD_MSG = 'Please wait till logo is uploaded.';
export const LOCATION_REQUIRED_MESSAGE = 'Please select at least one location';
export const DOWNLOAD_FAILED_MSG = 'Download Failed.';
export const CED_NET_DOWNLOAD_FAILED = 'Format unsupported for quotes created prior to 02/05/24. Please recompile for CEDNet export';
export const EMAIL_SENT_SUCCESSFULLY = 'Email sent successfully';
export const EMAIL_SESSION_EXPIRED = 'You session is expired, please reconnect you email client and try sending email again';
export const HIGHLIGHT_MSG = 'Successfully highlighted/unhighlighted';
export const SECTION_REORDER_MESSAGE = 'Section order successfully updated';
export const ROWS_ADDED_MSG = 'Rows added successfully.';
export const CORRECT_NUMBER_MSG = 'Please enter number between 1 and 100.';
export const DATASHEET_SELECTED_MESSAGE = 'Datasheet added successfully.';
export const BOM_COPIED_MESSAGE = 'Bom copied successfully.';
export const COMPILE_ERROR_FOR_BOMS_MESSAGE = 'one or more BOM(s) failed to compile !';
export const PRIMARY_START_ICON_MESSAGE = 'The Primary BOM will act as the template for linked BOMs.';
export const LINKED_START_ICON_MESSAGE = 'Linked BOMs will inherit all details from the Primary. You can unlink BOMs later if custom changes are required.';
export const DUPLICATING_MESSAGE = 'Duplicating...';
export const BOM_DATA_PUSHED_MESSAGE = 'Successfully applied to all BOMs in this project';
export const ANNOTATION_SAVED_SUCCESSFULLY = 'Annotations saved successfully';
export const CROPPING_REGION_PROMPT = `Select a cropping region first.`;
export const ANNOTATION_APPLIED_SUCCESSFULLY = 'Annotations applied successfully';
export const CONFIRMED_SUCCESSFULLY = `Confirmed successfully !!`;
export const CROPPING_REGION_COPPIED_SUCCESSFULLY = `Cropping Region Successfully Copied!`;

export const USER_ROLES = {
    ADMIN: 'admin',
    USER: 'user'
};

export const SubDocumentType = {
    DATASHEET: 'datasheet',
    WARRANTY: 'warranty',
    INSTALLATION_GUIDE: 'installation_guide',
    DRAWING: 'drawing',
    OTHER: 'other_document'
};
export const SUBMITTAL_DOC = 'submittal';
export const OM_DOC = 'o_m';
export const QUOTE = 'quote';

type USER_ROLES_KEY = keyof typeof USER_ROLES;
export type USER_ROLES_VAL = (typeof USER_ROLES)[USER_ROLES_KEY];
export type docType = typeof SUBMITTAL_DOC | typeof OM_DOC | typeof QUOTE;

export enum PROJECT_STAGES_LABELS_ENUM {
    SPECIFICATION_STAGE = 'Specification',
    QUOTING_STAGE = 'Quoting',
    QUOTE_SENT = 'Quote(s) Sent',
    REBID = 'Rebid',
    SUBMITTAL_STAGE = 'Submittal',
    OM_STAGE = 'O&M',
    CLOSED_LOST = 'Closed - Lost',
    CLOSED_WON = 'Closed - Won',
    NO_BID = 'No Bid',
    ON_HOLD = 'On Hold'
}

export const PROJECT_STAGES_VALUES = Object.values(PROJECT_STAGES_LABELS_ENUM);

export const STATUS_TO_COLOR_MAP = {
    [PROJECT_STAGES_LABELS_ENUM.SPECIFICATION_STAGE]: 'tertiary',
    [PROJECT_STAGES_LABELS_ENUM.QUOTING_STAGE]: 'tertiary',
    [PROJECT_STAGES_LABELS_ENUM.QUOTE_SENT]: 'tertiary',
    [PROJECT_STAGES_LABELS_ENUM.REBID]: 'tertiary',
    [PROJECT_STAGES_LABELS_ENUM.SUBMITTAL_STAGE]: 'info',
    [PROJECT_STAGES_LABELS_ENUM.OM_STAGE]: 'primary',
    [PROJECT_STAGES_LABELS_ENUM.CLOSED_WON]: 'success',
    [PROJECT_STAGES_LABELS_ENUM.CLOSED_LOST]: 'error',
    [PROJECT_STAGES_LABELS_ENUM.NO_BID]: 'neutral',
    [PROJECT_STAGES_LABELS_ENUM.ON_HOLD]: 'neutral'
};

export enum DocumentStatusEnum {
    WON = 'Won',
    LOST = 'Lost',
    ABANDONED = 'Abandoned',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    PENDING = '-',
    IN_PROGRESS = 'In Progress',
    SENT = 'Sent',
    CREATENEW = '+ Create New'
}

export const DOCUMENT_STATUS_COLOR_MAP = {
    [DocumentStatusEnum.WON]: 'success',
    [DocumentStatusEnum.LOST]: 'error',
    [DocumentStatusEnum.ABANDONED]: 'neutral',
    [DocumentStatusEnum.APPROVED]: 'success',
    [DocumentStatusEnum.REJECTED]: 'error',
    [DocumentStatusEnum.PENDING]: 'info',
    [DocumentStatusEnum.IN_PROGRESS]: 'tertiary',
    [DocumentStatusEnum.SENT]: 'info'
};

export const USA_STATES = [
    'AK - Alaska',
    'AL - Alabama',
    'AR - Arkansas',
    'AZ - Arizona',
    'CA - California',
    'CO - Colorado',
    'CT - Connecticut',
    'DC - District of Columbia',
    'DE - Delaware',
    'FL - Florida',
    'GA - Georgia',
    'HI - Hawaii',
    'IA - Iowa',
    'ID - Idaho',
    'IL - Illinois',
    'IN - Indiana',
    'KS - Kansas',
    'KY - Kentucky',
    'LA - Louisiana',
    'MA - Massachusetts',
    'MD - Maryland',
    'ME - Maine',
    'MI - Michigan',
    'MN - Minnesota',
    'MO - Missouri',
    'MS - Mississippi',
    'MT - Montana',
    'NC - North Carolina',
    'ND - North Dakota',
    'NE - Nebraska',
    'NH - New Hampshire',
    'NJ - New Jersey',
    'NM - New Mexico',
    'NV - Nevada',
    'NY - New York',
    'OH - Ohio',
    'OK - Oklahoma',
    'OR - Oregon',
    'PA - Pennsylvania',
    'RI - Rhode Island',
    'SC - South Carolina',
    'SD - South Dakota',
    'TN - Tennessee',
    'TX - Texas',
    'UT - Utah',
    'VA - Virginia',
    'VT - Vermont',
    'WA - Washington',
    'WI - Wisconsin',
    'WV - West Virginia',
    'WY - Wyoming'
];
export const CANADA_STATES = [
    'AB - Alberta',
    'BC - British Columbia',
    'MB - Manitoba',
    'NB - New Brunswick',
    'NL - Newfoundland and Labrador',
    'NS - Nova Scotia',
    'NT - Northwest Territories',
    'NU - Nunavut',
    'ON - Ontario',
    'PE - Prince Edward Island',
    'QC - Quebec',
    'SK - Saskatchewan',
    'YT - Yukon'
];

export type ColorCode = 'primary' | 'info' | 'success' | 'error' | 'tertiary' | 'secondary';

export const getStatusColor = (status: string) => {
    return STATUS_TO_COLOR_MAP[status as keyof typeof STATUS_TO_COLOR_MAP] as ColorCode;
};

export enum MfgStatusLabelsEnum {
    SUPPORTED = 'Supported',
    UNABLE_TO_SUPPORT = 'Unable to Support',
    UNDER_REVIEW = 'Under Review',
    REQUESTED = 'Requested',
    NOT_RECOGNIZED = 'Not Recognized'
}
export const MFG_STATUS_TO_COLOR_MAP = {
    [MfgStatusLabelsEnum.SUPPORTED]: 'success',
    [MfgStatusLabelsEnum.UNABLE_TO_SUPPORT]: 'error',
    [MfgStatusLabelsEnum.UNDER_REVIEW]: 'primary',
    [MfgStatusLabelsEnum.REQUESTED]: 'tertiary',
    [MfgStatusLabelsEnum.NOT_RECOGNIZED]: 'error'
};
export const getMfgStatusColor = (status: string) => {
    return MFG_STATUS_TO_COLOR_MAP[status as keyof typeof MFG_STATUS_TO_COLOR_MAP] as ColorCode;
};
export const WEBVIEWER_FOLDER_NAME = 'wv-resources@10.7.0-20240205';

export const MAX_VALUE_SQUARE_FOOTAGE = 2147483647;

export const CONSTRUCTION_OPTIONS = [
    {
        id: 'New Construction',
        name: 'New Construction'
    },
    {
        id: 'Renovation',
        name: 'Renovation'
    }
];

export const CONSTRUCTION_SCOPE_OPTIONS = [
    {
        id: 'Multi-Family Apartment',
        name: 'Multi-Family Apartment'
    },
    {
        id: 'Multi-Family Condo',
        name: 'Multi-Family Condo'
    },
    {
        id: 'Hotel',
        name: 'Resort'
    },
    {
        id: 'Retail',
        name: 'Retail'
    },
    {
        id: 'Commercial Core + Shell',
        name: 'Commercial Core + Shell'
    },
    {
        id: 'Commercial Tenant Improvement',
        name: 'Commercial Tenant Improvement'
    },
    {
        id: 'Single Bldg Residential',
        name: 'Single Bldg Residential'
    },
    {
        id: 'Healthcare',
        name: 'Healthcare'
    },
    {
        id: 'Multi-Bldg Residential',
        name: 'Multi-Bldg Residential'
    },
    {
        id: 'Industrial',
        name: 'Industrial'
    },
    {
        id: 'Roadway',
        name: 'Roadway'
    },
    {
        id: 'Site',
        name: 'Site'
    },
    {
        id: 'Government',
        name: 'Government'
    },
    {
        id: 'K-12',
        name: 'K-12'
    },
    {
        id: 'Higher Education',
        name: 'Higher Education'
    },
    {
        id: 'Religious',
        name: 'Religious'
    },
    {
        id: 'Judicial',
        name: 'Judicial'
    }
];

export const PROJECT_STAGE_OPTIONS = [
    {
        label: PROJECT_STAGES_LABELS_ENUM.SPECIFICATION_STAGE,
        value: 'Specification',
        type: 'tertiary'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.QUOTING_STAGE,
        value: 'Quoting',
        type: 'tertiary'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.QUOTE_SENT,
        value: 'Quote Sent',
        type: 'tertiary'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.REBID,
        value: 'Rebid',
        type: 'tertiary'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.SUBMITTAL_STAGE,
        value: 'Submittal',
        type: 'info'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.OM_STAGE,
        value: 'O&M',
        type: 'primary'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.CLOSED_WON,
        value: 'Closed Won',
        type: 'success'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.CLOSED_LOST,
        value: 'Closed Lost',
        type: 'error'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.NO_BID,
        value: 'No Bid',
        type: 'neutral'
    },
    {
        label: PROJECT_STAGES_LABELS_ENUM.ON_HOLD,
        value: 'On Hold',
        type: 'neutral'
    }
];

export const PROJECT_STAGE_OPTIONS_MAP = PROJECT_STAGE_OPTIONS.reduce((currMap, projStageInfo) => {
    currMap[projStageInfo.value] = projStageInfo.label;
    return currMap;
}, {} as Record<string, string>);

interface IProjectStageOptions {
    label: string;
    value: string;
    type?: string;
}

export const QUOTES_LEVEL_STAGES = ['Quoting', 'Quote Sent', 'Rebid'];
export const PROJECT_STAGE_TRIGGER_VALUES = ['Closed Won', 'Closed Lost', 'No Bid'];
export const PROJECT_STAGE_TRIGGER_LABELS = ['Closed - Won', 'Closed - Lost', 'No Bid'];

const createProjectStages = (editMode: boolean) => (!editMode ? PROJECT_STAGE_OPTIONS.filter((option) => !PROJECT_STAGE_TRIGGER_VALUES.includes(option.value)) : PROJECT_STAGE_OPTIONS);

export const getProjectStages = (editMode: boolean, isQuotingEnabled: boolean) => {
    let options: Array<IProjectStageOptions> = createProjectStages(editMode);

    if (!isQuotingEnabled) {
        options = options.filter((option) => !QUOTES_LEVEL_STAGES.includes(option.value));
    }

    return options;
};

export const COUNTRY_OPTIONS = [
    { id: 'USA', name: 'USA' },
    { id: 'Canada', name: 'Canada' }
];

export const ACCEPTED_FILE_TYPES = ['.png', '.jpg'];

export const INITIAL_PROJECT_STAGE = PROJECT_STAGE_OPTIONS[0].label;

export enum COUNTRY {
    USA = 'USA',
    CANADA = 'Canada'
}
export const LAUNCH_PRODUCT_FINDER_FOR_ALL = true;

interface MAX_LOCATION_PERMITTED_BY_PLAN_INTERFACE {
    Free: number;
    Silver: number;
    Gold: number;
    Platinum: number;
    Diamond: number;
    Enterprise_limited: number;
    Enterprise: number;
}

export const MAX_LOCATION_PERMITTED_BY_PLAN: MAX_LOCATION_PERMITTED_BY_PLAN_INTERFACE = {
    Free: 1,
    Silver: 1,
    Gold: 3,
    Platinum: 5,
    Diamond: 8,
    Enterprise_limited: Infinity,
    Enterprise: Infinity
};

export const PROJECT_FILES_ALLOWED_TYPES = ['.pdf', '.xlsx', '.xls', '.txt', '.png', '.jpeg', '.jpg', '.doc', '.docx', '.ppt', '.pptx', '.eml', '.msg'];
export const PROJECT_FILES_TYPE_STRING = 'Supported file types: XLS, XLSX, PDF, JPG, JPEG, PNG, TXT, DOC, DOCX, PPT, PPTX, EML, MSG';
