import { ToolbarType } from '@parspec/pixel';

export enum PRICING_TABLE_ENUM {
    ID = 'id',
    TYPE = 'category',
    QTY = 'qty',
    MANUFACTURER = 'manufacturer',
    MODEL_NO = 'model_number',
    QUOTE_NOTES = 'quoteNotes',
    SPECIFICATION = 'specification',
    LOT = 'lotId',
    COST = 'cost',
    DISCOUNT = 'discount',
    DISCOUNT_COST = 'discountedCost',
    MARGIN = 'margin',
    SELL_PRICE = 'sellPrice',
    EXTENDED_PRICE = 'extendedPrice',
    LEAD_TIME_VALUE = 'leadTimeValue',
    LEAD_TIME_UNIT = 'leadTimeUnit',
    QUOTE_NO = 'quoteNo',
    LOT_INFO = 'lotInfo',
    IS_HIDDEN = 'is_hidden',
    SECTION = 'section',
    OPTION_SECTION = 'substitute_section',
    PRODUCT_TO_REPLACE = 'replaceProductId',
    PRODUCT_TO_REPLACE_LABEL = 'product_to_replace_label',
    PRODUCT_TO_REPLACE_INFO = 'replaceProductInfo',
    ADD_DEDUCT_AMT = 'addDeductAmount',
    INTERNAL_COMMENTS = 'internalComments',
    HAS_OPTION = 'hasOptions',
    KIT = 'kit',
    IS_KIT = 'is_kit',
    ORDER = 'order',
    HISTORY = 'history',
    IS_HIGHLIGHTED = 'is_highlighted'
}

export const priceRelatedCols = new Set([PRICING_TABLE_ENUM.COST, PRICING_TABLE_ENUM.DISCOUNT, PRICING_TABLE_ENUM.DISCOUNT_COST, PRICING_TABLE_ENUM.MARGIN, PRICING_TABLE_ENUM.SELL_PRICE]);

export const serviceRelatedCols = new Set(['magnitude']);

export const pricingBodInfoCols = new Set([
    PRICING_TABLE_ENUM.QUOTE_NOTES,
    PRICING_TABLE_ENUM.INTERNAL_COMMENTS,
    PRICING_TABLE_ENUM.LOT,
    PRICING_TABLE_ENUM.LEAD_TIME_UNIT,
    PRICING_TABLE_ENUM.LEAD_TIME_VALUE,
    PRICING_TABLE_ENUM.QUOTE_NO
]);
export const bomCols = new Set([
    PRICING_TABLE_ENUM.TYPE,
    PRICING_TABLE_ENUM.QTY,
    PRICING_TABLE_ENUM.MANUFACTURER,
    PRICING_TABLE_ENUM.MODEL_NO,
    PRICING_TABLE_ENUM.SPECIFICATION,
    PRICING_TABLE_ENUM.PRODUCT_TO_REPLACE
]);

export enum CALC_METHODS {
    FLAT_FEE = 'flat_fee',
    PERCENTAGE_OF_ORDER = 'percentage_of_order',
    FREIGHT_EXCLUDED = 'freight_excluded'
}

export const toolBaroptions: ToolbarType = ['delete', 'search', 'hide', 'unhide', 'selectedItems', 'duplicate', 'move', 'createKit', 'highlight'];

export const qtyParams = { params: { format: 'N', decimals: 0, validateDecimalOnType: true } };
export const BULK_EDIT_FIELD_NAMES: { [key: string]: string } = {
    margin: 'Margin (%)',
    sellPrice: 'Sell Price',
    discount: 'Discount (%)',
    discountedCost: 'Discounted Cost'
};

export enum LEAD_TIME_UNITS {
    DAYS = 'Days',
    WEEKS = 'Weeks',
    MONTHS = 'Months',
    YEARS = 'Years',
    IN_STOCK = 'In Stock',
    WORKING_DAYS = 'Working Days'
}

export interface IOptions {
    [index: string]: string;
}

export const DISCOUNT_DISCOUNT_COST_MARGIN_TOOLTIP_MSG = 'A cost entry is required in order to enter a discount and/or margin.';
export const EXTENDED_SELL_TOOLTIP_MSG = 'Extended Sell Price is auto-calculated based on Qty, Cost, Discount and Margin.';
export const KIT_LINE_TOOLTIP_MSG = 'These fields are auto-calculated to reflect the contents of this kit.';
export const COMPONENT_LOTS_TOOLTIP_MESSAGE = 'Kit components cannot be assigned to lots.';
export const LOT_ASSIGNED_TOOLTIP_MSG = 'Pricing can be managed in the lot this item is assigned to.';
export const LOT_ASSIGNED_KIT_MAIN_LINE_ITEM = 'Pricing can be managed in the lot this kit is assigned to.';
export const LEAD_TIME_VALUE_TOOLTIP_MSG = `Change lead time unit 'In Stock' to other value. `;
export const ADD_DEDUCT_AMOUNT_TOOLTIP_MESSAGE = 'Add/Deduct Amount is auto-calculated based on pricing of the option and of the product it is replacing.';
