import { useEffect, useState } from 'react';

import { Box, Autocomplete, Skeleton, BodySmall, Tooltip, OptionType } from '@parspec/pixel';

import { useParspecMfg } from './queries';
import { searchManufacturer } from '../../shared/utils/utils';
import { ParspecMfgDetailsResponse } from './queries/apiTypes';
import { ManufacturerDropdownType } from '../../BOM/productFinder/setRequirements/types';

interface ManufacturerSearchProps {
    parspecId: string | null;
    disabled: boolean;
    handleParpsecIdChange: (props?: ParspecMfgDetailsResponse) => void;
    placeholder: string;
    label: string;
    showWarning: boolean;
    showHelperText: boolean;
}

const ManufacturerSearch = (props: ManufacturerSearchProps) => {
    const { parspecId, handleParpsecIdChange, label, placeholder, disabled, showWarning, showHelperText } = props;

    const { data: companyMfgData, isLoading: isMfgListLoading } = useParspecMfg();

    const [mfgNameList, setMfgNameList] = useState<OptionType<ParspecMfgDetailsResponse>[]>([]);
    const [formError, setFormError] = useState<boolean>(false);
    const [selectedParspecId, setSelectedParspecId] = useState(parspecId);

    useEffect(() => {
        if (companyMfgData?.data.length) {
            setMfgNameList([...(companyMfgData.data as OptionType<ParspecMfgDetailsResponse>[])]);
        }
    }, [companyMfgData]);

    useEffect(() => {
        setFormError(showWarning);
    }, [showWarning]);

    useEffect(() => {
        setSelectedParspecId(parspecId);
    }, [parspecId]);

    useEffect(() => {
        if (!selectedParspecId && !disabled) {
            setFormError(true);
        } else if (selectedParspecId) {
            setFormError(false);
        }
    }, [selectedParspecId, disabled]);

    const onParpsecIdChange = (event: any) => {
        if (event.target.value) {
            handleParpsecIdChange(event.target.value);
            setFormError(false);
        } else {
            handleParpsecIdChange();
            setFormError(true);
        }
    };

    const filterHandler = (options: OptionType<ManufacturerDropdownType>[] | { [index: string]: string | number; }[], params: { inputValue: string }) => {
        return searchManufacturer(options as OptionType<ManufacturerDropdownType>[], params.inputValue.trim());
    };

    return (
        <>
            <Box
                sx={{
                    '.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFA72B !important'
                    },
                    '.MuiFormLabel-root': {
                        color: formError && !disabled ? '#FFA72B !important' : ''
                    },
                    '.MuiFormHelperText-root': {
                        margin: '0px !important'
                    }
                }}
            >
                {!isMfgListLoading ? (
                    <Tooltip
                        title={
                            formError && !disabled && !showHelperText
                                ? 'No matching name found in the Parspec database. Edit to view suggested manufacturer names.'
                                : disabled && showHelperText
                                ? 'Please select a manufacturer first.'
                                : ''
                        }
                        placement={'top'}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    ...(!disabled && { bgcolor: 'warning.light' }),
                                    ...(!disabled && { color: 'warning.main' })
                                }
                            }
                        }}
                    >
                        <Autocomplete
                            value={selectedParspecId}
                            options={mfgNameList}
                            onChange={onParpsecIdChange}
                            placeholder={placeholder}
                            id="parspecId"
                            label={label}
                            optionlabelkeyname="parspec_id"
                            freeSolo
                            helperText={
                                formError && !disabled && showHelperText ? (
                                    <Box paddingTop={'6px'}>
                                        <BodySmall lines={2} color={'warning.main'}>
                                            <strong>No matching name found in the Parspec database</strong> <br />
                                            Edit to view suggested manufacturer names
                                        </BodySmall>
                                    </Box>
                                ) : (
                                    ''
                                )
                            }
                            error={formError && !disabled}
                            disabled={disabled}
                            clearOnBlur
                            filterOptionsCallBack={filterHandler}
                        />
                    </Tooltip>
                ) : (
                    <Skeleton variant="rectangular" width="100%" height={36} sx={{ borderRadius: 1 }} />
                )}
            </Box>
        </>
    );
};

export default ManufacturerSearch;
