import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { BodySmall, BodyXS, Box, Checkbox, Info, Modal, ModalHeader, Snackbar } from '@parspec/pixel';

import ComposeMail from './ComposeMail';
import Footer from './Footer';
import EmailSkeleton from './ComposeMail/EmailSkeleton';
import SyncAccount from '../../../Settings/MyProfile/EmailPreferences/SyncAccount';

import { useSendGoogleMailMutation, useSendMsMailMutation } from './queries';
import {
    ATTACHMENT_ALLOWED_SIZE,
    ATTACHMENT_ERROR_TEXT,
    BOM_EMAIL_ERROR,
    BOM_SELECTION_ERROR,
    BULK_ATTACHMENT_ERROR,
    BULK_BOM_EMAIL_ERROR,
    BULK_EMAIL_HEADER_SUBTITLE,
    BULK_NO_RECEPIENT_ERROR,
    EMAIL_BATCH_SIZE,
    EMAIL_SERVICE_TYPES,
    GMAIL_CONFIG,
    NO_RECEPIENT_ERROR,
    SEND_MAIL_LIMIT_EXCEED_ERROR_CODE,
    SEND_MAIL_LIMIT_EXCEED_ERROR_MESSAGE
} from './constants';
import { getUpdatedMsalAccessToken, makeGoogleMailBody, makeMsEmailBody, makeRecipientsList } from './utils';
import { EMAIL_SENT_SUCCESSFULLY, EMAIL_SESSION_EXPIRED } from '../../../shared/constants';
import { CompanyObjType } from '../../../ContactManagement/Company/queries/apiTypes';
import { Contact } from '../../../ContactManagement/Contacts/queries/apiTypes';
import { useUserAccountPreferencesQuery } from '../../../Settings/MyProfile/queries';
import { useGetProjectBomsQuery, useGetProjectDetailsQuery } from '../../../Project/queries';
import { DocInfo, ProjectBomInfo } from '../../../Project/queries/apiTypes';
import { getSharedLinkDetails } from '../Preview/queries/api';
import { fetchS3File } from './queries/apis';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { notifyToSentry } from '../../../shared/SentryErrorLogger';
import { BooleanObjType } from '../../shared/types';
import { CustomerTab } from '../../../shared/CustomerTab';
import { useSnackbar } from '../../../shared/hooks/useSnackbar';
import { useUserEventCount } from 'src/features/shared/UserActivityTracking/TrackActivityCount';
import { useGetCompanyDropDownQuery, useGetContactDropDownQuery } from 'src/features/shared/queries';

interface IEmailPopupProps {
    open: boolean;
    onClose: (message?: string) => void;
    projectId?: number;
    selectedBomdIds: number[];
    isBulkAction?: boolean;
    documentType?: string;
    docId?: string;
}

export interface EmailFormType {
    to: string;
    cc: string;
    subject: string;
    body: string;
    attachments: File[];
    selectedFile: File;
}

const EmailPopup = ({ open, onClose, projectId, selectedBomdIds, isBulkAction = false, documentType = 'quote', docId }: IEmailPopupProps) => {
    const navigate = useNavigate();

    const [emailService, setEmailService] = useState<string | null>(null);
    const [fileError, setFileError] = useState<{ [bomId: string]: string }>({});
    const [recipientError, setRecipientError] = useState<{ [bomId: string]: string }>({});
    const [ccError, setCcError] = useState<{ [bomId: string]: string }>({});
    const [emailBodyReady, setEmailBodyReady] = useState<boolean>(false);
    const [showSelectedFile, setShowSelectedFile] = useState<BooleanObjType>({});
    const [mailInputOptions, setMailInputOptions] = useState<{ id: string; name: string }[]>([]);
    const [companyArr, setCompanyArr] = useState<CompanyObjType[]>([]);
    const [contactsArr, setContactsArr] = useState<Contact[]>([]);
    const [bomList, setBomList] = useState<ProjectBomInfo[]>([]);
    const [composeFormData, setComposeFormData] = useState<{ [bomId: string]: EmailFormType }>({});
    const [shareableLinks, setShareableLinks] = useState<{ [bomId: string]: string }>({});
    const [activeBomMail, setActiveBomMail] = useState<string>(selectedBomdIds[0].toString());
    const [sendButtonError, setSendButtonError] = useState<string>('');
    const [checkedBoms, setCheckedBoms] = useState<Record<string, boolean>>({});

    const { pushUserEventCount } = useUserEventCount();
    const { snackbarInfo, setSnackbarOpen, setSnackbarClose } = useSnackbar();

    const { data: userPreferences, isFetching: userPreferenceFetching } = useUserAccountPreferencesQuery();
    const { data: projectBoms, isLoading: isProjectBomsLoading } = useGetProjectBomsQuery(Number(projectId)!, {
        enabled: Boolean(projectId)
    });

    const { data: projectDetails, isLoading: isEditProjectLoading } = useGetProjectDetailsQuery(projectId || 0, {
        enabled: Boolean(projectId)
    });
    const { data: companyData, isFetching: companyFetching } = useGetCompanyDropDownQuery();
    const { data: contactsData, isFetching: contactsFetching } = useGetContactDropDownQuery();

    const { mutateAsync: msEmailMutation, isLoading: sendMsMailLoading } = useSendMsMailMutation();
    const { mutateAsync: googleMailMutation, isLoading: sendGoogleMailLoading } = useSendGoogleMailMutation();

    const locationId = useMemo(() => projectDetails?.data.company || -1, [projectDetails?.data]);
    const projectName = useMemo(() => projectDetails?.data.name || -1, [projectDetails?.data]);

    useEffect(() => {
        if (!isProjectBomsLoading && !userPreferenceFetching && userPreferences && projectBoms) {
            const checkedBomsObj: Record<string, boolean> = {};
            const selectedBoms = projectBoms.data
                .filter((bomData) => selectedBomdIds.includes(bomData.id))
                .map((bomData) => {
                    const { id } = bomData;
                    setCcError({ ...ccError, [id]: '' });
                    setRecipientError({ ...recipientError, [id]: '' });
                    setFileError({ ...fileError, [id]: '' });
                    checkedBomsObj[id] = isBulkAction ? false : true;
                    return bomData;
                });

            setCheckedBoms(checkedBomsObj);
            setBomList(selectedBoms);

            const prepareFormData = async () => {
                const promiseArr = selectedBoms.map(async (bomObj) => {
                    const bomMailObj = {
                        to: '',
                        cc: '',
                        subject: '',
                        body: '',
                        attachments: [],
                        selectedFile: {} as File
                    };
                    const { id, quote, primary_contact } = bomObj;
                    const resultObj: any = { id };
                    const docArr = documentType == 'o_m' ? (bomObj['om'] as DocInfo[]) : (bomObj[(documentType as keyof ProjectBomInfo) || ''] as DocInfo[]);

                    const selectedDocObj = isBulkAction ? docArr?.[0] : docArr.find((obj) => obj.id.toString() === docId);

                    const sharedLinkDetails = await getSharedLinkDetails(id, documentType);
                    const s3File = await fetchS3File(getProxyFileUrl(selectedDocObj?.document_link_flat || ''));
                    const fileObj = new File([s3File.data], selectedDocObj?.flat_filename || 'Test.pdf', { type: s3File.data.type });
                    bomMailObj.selectedFile = fileObj;

                    if (fileObj.size > ATTACHMENT_ALLOWED_SIZE) {
                        resultObj.fileError = ATTACHMENT_ERROR_TEXT;
                    }

                    if (userPreferences.data.is_pdf_attached) {
                        resultObj.showSelectedFile = true;
                    }

                    let emailBody =
                        userPreferences?.data.email_body && userPreferences?.data.email_body !== '<p class="richTextParagraph"><br></p>'
                            ? userPreferences?.data.email_body
                            : '<p class="richTextParagraph" ><span style="white-space: pre-wrap;">Hello,</span></p><p class="richTextParagraph"><br></p><p class="richTextParagraph" ><span style="white-space: pre-wrap;">You can access this document and its previous versions on this shareable link: </span><a href="https://" target="_blank" title="Shareable Link" class="cursor-pointer"><span style="white-space: pre-wrap;">Shareable Link</span></a></p>';
                    if (emailBody.includes(`<a href="https://" target="_blank" title="Shareable Link" class="cursor-pointer">`)) {
                        emailBody = emailBody.replace(
                            '<a href="https://" target="_blank" title="Shareable Link" class="cursor-pointer">',
                            `<a href="${sharedLinkDetails?.data.shared_link}" target="_blank" title="Shareable Link" class="cursor-pointer" >`
                        );
                    }

                    resultObj.sharedLink = sharedLinkDetails?.data.shared_link;

                    bomMailObj.body = emailBody;
                    bomMailObj.subject = quote && quote?.length > 0 ? `${projectName}: ${quote?.[0]?.quote_number}` : `${projectName}`;
                    if (primary_contact.contact?.email) {
                        bomMailObj.to = primary_contact.contact?.email;
                    } else if (primary_contact.company?.email) {
                        bomMailObj.to = primary_contact.company?.email;
                    } else {
                        bomMailObj.to = '';
                    }
                    resultObj.composeFormData = bomMailObj;

                    return resultObj;
                });

                const resultArr = await Promise.all(promiseArr);

                const composeFormObj: { [bomId: string]: EmailFormType } = {};
                const shearableLinkObj: { [bomId: string]: string } = {};
                const showSelectedFileObj: BooleanObjType = {};
                const fileErrorObj: { [bomId: string]: string } = {};

                resultArr.forEach((obj) => {
                    const { id, composeFormData, sharedLink, showSelectedFile: displaySelectedFile, fileError } = obj;
                    composeFormObj[id] = composeFormData;
                    shearableLinkObj[id] = sharedLink;
                    showSelectedFileObj[id] = displaySelectedFile;
                    fileErrorObj[id] = fileError;
                });

                setComposeFormData(composeFormObj);
                setShareableLinks(shearableLinkObj);
                setShowSelectedFile(showSelectedFileObj);
                setFileError(fileErrorObj);

                setEmailBodyReady(true);
            };

            prepareFormData();
        }
    }, [userPreferences, projectName, selectedBomdIds, projectBoms]);

    useEffect(() => {
        if (companyData?.data && !companyFetching) {
            setCompanyArr(companyData?.data);
        }
    }, [companyData?.data, companyFetching]);

    useEffect(() => {
        if (contactsData?.data && !contactsFetching) {
            setContactsArr(contactsData?.data);
        }
    }, [contactsData?.data, contactsFetching]);

    useEffect(() => {
        const receipientOptions = makeRecipientsList(companyArr, contactsArr, locationId);
        setMailInputOptions(receipientOptions);
    }, [companyArr, contactsArr]);

    const handleAddFile = (bomId: string) => {
        if (emailService) {
            setShowSelectedFile({ ...showSelectedFile, [bomId]: !showSelectedFile[bomId] });
            if (checkAttachmentError(composeFormData[bomId].attachments, bomId, composeFormData?.[bomId].selectedFile.size)) {
                setFileError({ ...fileError, [bomId]: ATTACHMENT_ERROR_TEXT });
            } else {
                setFileError({ ...fileError, [bomId]: '' });
            }
        }
    };

    const handleComposeFormChange = (name: any, value: any, bomId: string) => {
        setSendButtonError('');
        setComposeFormData((prevState) => ({
            ...prevState,
            [bomId]: { ...prevState[bomId], [name]: value }
        }));
    };

    const handleMessageChange = (bomId: string, htmlStr: string) => {
        setComposeFormData((prevState) => ({
            ...prevState,
            [bomId]: { ...prevState[bomId], body: htmlStr }
        }));
    };

    const handleAttachmentUpload = (files: FileList | File[], bomId: string) => {
        let fileArr = Array.from(files);

        fileArr = fileArr.map((obj) => {
            return new File([obj], `${Date.now()}-${obj.name}`, { type: obj.type });
        });

        if (checkAttachmentError([...composeFormData[bomId].attachments, ...fileArr], bomId, showSelectedFile[bomId] ? composeFormData[bomId].selectedFile?.size || 0 : 0)) {
            setFileError({ ...fileError, [bomId]: ATTACHMENT_ERROR_TEXT });
        } else {
            setFileError({ ...fileError, [bomId]: '' });
        }

        setComposeFormData((prevState) => ({
            ...prevState,
            [bomId]: { ...prevState[bomId], attachments: [...prevState[bomId].attachments, ...fileArr] }
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('msToken')) {
            setEmailService(EMAIL_SERVICE_TYPES.MSAL);
        } else if (localStorage.getItem('googleToken')) {
            setEmailService(EMAIL_SERVICE_TYPES.GMAIL);
        } else {
            setEmailService('');
        }
    }, []);

    const handleSendEmail = () => {
        const err = getSendButtonError();
        if (err) {
            setSendButtonError(err);
            return;
        }

        pushUserEventCount({ eventType: 'send_email', needCummulation: false });

        if (emailService) {
            if (emailService === EMAIL_SERVICE_TYPES.MSAL) {
                sendMsMail();
            } else if (emailService === EMAIL_SERVICE_TYPES.GMAIL) {
                sendGoogleMail();
            }
        }
    };

    const sendMsMail = async () => {
        const selectedBoms = bomList.filter((bom) => checkedBoms[bom.id]);
        const batchSize = EMAIL_BATCH_SIZE;
        const successIds: number[] = [];
        let failedCount = 0;
        let errorMessage = '';

        for (let i = 0; i < selectedBoms.length; i += batchSize) {
            const currentBatch = selectedBoms.slice(i, i + batchSize);
            const batchResults = await Promise.allSettled(
                currentBatch.map(async (bomObj) => {
                    const { id } = bomObj;
                    const mailData = {
                        ...composeFormData[id],
                        attachments: showSelectedFile[id] && composeFormData[id].selectedFile ? [...composeFormData[id].attachments, composeFormData[id].selectedFile] : composeFormData[id].attachments
                    };
                    const token = await getUpdatedMsalAccessToken();
                    const rawMessage = await makeMsEmailBody(mailData);
                    try {
                        const res = await msEmailMutation({ token, rawMessage });
                        if (res.status === 202) {
                            return { id, success: true };
                        } else {
                            return { id, success: false };
                        }
                    } catch (error: any) {
                        const { response } = error;
                        notifyToSentry(`outlook account connection error: ${JSON.stringify(response?.message)}`);
                        if (response.status === 401) {
                            localStorage.removeItem('msToken');
                            throw new Error(EMAIL_SESSION_EXPIRED);
                        } else if (response.status === 429) {
                            const { code } = response.data.error;
                            if (code === SEND_MAIL_LIMIT_EXCEED_ERROR_CODE) {
                                errorMessage = SEND_MAIL_LIMIT_EXCEED_ERROR_MESSAGE;
                                throw new Error(SEND_MAIL_LIMIT_EXCEED_ERROR_MESSAGE);
                            }
                        }

                        return { id, success: false };
                    }
                })
            );

            // Process results of the current batch
            batchResults.forEach((result) => {
                if (result.status === 'fulfilled') {
                    successIds.push(result.value.id);
                } else {
                    failedCount++;
                }
            });
        }

        // Final handling based on all batches results
        if (successIds.length === selectedBoms.length) {
            onClose(EMAIL_SENT_SUCCESSFULLY);
        } else {
            const updatedCheckedState = { ...checkedBoms };
            selectedBoms.forEach((bom) => {
                if (!successIds.includes(bom.id)) {
                    updatedCheckedState[bom.id] = true; // Mark failed BOMs as still checked for retry
                } else {
                    updatedCheckedState[bom.id] = false; // Uncheck successful BOMs
                }
            });
            setCheckedBoms(updatedCheckedState);
            if (errorMessage) setSnackbarOpen(errorMessage);
            else setSnackbarOpen(`${successIds.length}/${selectedBoms.length} emails sent successfully. ${failedCount} failed, please retry.`);
        }
    };

    const sendGoogleMail = async () => {
        for (const bomObj of bomList) {
            const { id } = bomObj;
            if (checkedBoms[id]) {
                const mailData = {
                    ...composeFormData[id],
                    attachments: showSelectedFile && composeFormData[id].selectedFile ? [...composeFormData[id].attachments, composeFormData[id].selectedFile] : composeFormData[id].attachments
                };
                const token = localStorage.getItem('googleToken') || '';
                const rawMessage = await makeGoogleMailBody(mailData);
                try {
                    const res = await googleMailMutation({ token, rawMessage });
                    if (res.status === 200) {
                        onClose(EMAIL_SENT_SUCCESSFULLY);
                    }
                } catch (error: any) {
                    onClose(EMAIL_SENT_SUCCESSFULLY);
                }
            }
        }
    };

    const handleAttachmentDelete = (file: File, bomId: string) => {
        if (emailService) {
            const filteredrFiles = composeFormData[bomId].attachments.filter((obj) => obj.name !== file.name);

            setComposeFormData((prevState) => ({
                ...prevState,
                [bomId]: { ...prevState[bomId], attachments: [...filteredrFiles] }
            }));

            if (checkAttachmentError(filteredrFiles, bomId, showSelectedFile[bomId] ? composeFormData[bomId].selectedFile?.size || 0 : 0)) {
                setFileError({ ...fileError, [bomId]: ATTACHMENT_ERROR_TEXT });
            } else {
                setFileError({ ...fileError, [bomId]: '' });
            }
            setSendButtonError('');
        }
    };

    const handleRemoveSelectedFile = (bomId: string) => {
        setShowSelectedFile({ ...showSelectedFile, [bomId]: false });
        if (checkAttachmentError(composeFormData[bomId].attachments, bomId, 0)) {
            setFileError({ ...fileError, [bomId]: ATTACHMENT_ERROR_TEXT });
        } else {
            setFileError({ ...fileError, [bomId]: '' });
        }
        setSendButtonError('');
    };

    const checkAttachmentError = (fileArr: File[], _bomId: string, totalFileSize: number) => {
        for (let i = 0; i < fileArr.length; i++) {
            totalFileSize += fileArr[i].size;
        }

        return totalFileSize > ATTACHMENT_ALLOWED_SIZE;
    };

    const isLoading = useMemo(() => userPreferenceFetching || isEditProjectLoading, [userPreferenceFetching, isEditProjectLoading]);

    const handleBomSelect = (params: Record<string, boolean>) => {
        setSendButtonError('');
        setCheckedBoms(params);
    };

    const handleTabChange = (bomId: string) => {
        setRecipientError({ ...recipientError, [bomId]: '' });
        setCcError({ ...ccError, [bomId]: '' });
        setSendButtonError('');
        setActiveBomMail(bomId);
    };

    const renderComposeMail = () => {
        return (
            <Box width={isBulkAction ? '80%' : '100%'} key={activeBomMail} sx={{ backgroundColor: 'neutral.light' }} px={2} maxHeight="480px" overflow="auto">
                <ComposeMail
                    bomId={activeBomMail}
                    isFetching={isLoading}
                    isEmailBodyReady={emailBodyReady}
                    isLoggedIn={Boolean(emailService)}
                    formData={composeFormData[activeBomMail]}
                    mailInputOptions={mailInputOptions}
                    handleChange={handleComposeFormChange}
                    handleMessageChange={handleMessageChange}
                    handleAttachmentDelete={handleAttachmentDelete}
                    handleAddFile={handleAddFile}
                    showSelectedFile={showSelectedFile}
                    shareableUrl={shareableLinks}
                    handleRemoveSelectedFile={handleRemoveSelectedFile}
                    handleAttachmentUpload={handleAttachmentUpload}
                    recipientError={recipientError}
                    ccError={ccError}
                    setCcError={setCcError}
                    setRecipientError={setRecipientError}
                    setSendButtonError={setSendButtonError}
                />
            </Box>
        );
    };

    const getSendButtonError = useCallback(() => {
        const anyBomSelected = bomList.some((bom) => checkedBoms[bom.id]);

        if (isBulkAction && !anyBomSelected) {
            return BOM_SELECTION_ERROR;
        }

        const bomLevelEmailError = bomList.some((bom) => (checkedBoms[bom.id] && Boolean(recipientError[bom.id])) || (checkedBoms[bom.id] && Boolean(ccError[bom.id])));

        if (bomLevelEmailError) {
            return isBulkAction ? BULK_BOM_EMAIL_ERROR : BOM_EMAIL_ERROR;
        }

        const noReciepentAdded = bomList.some((bom) => checkedBoms[bom.id] && !composeFormData[bom.id].to);

        if (noReciepentAdded) {
            return isBulkAction ? BULK_NO_RECEPIENT_ERROR : NO_RECEPIENT_ERROR;
        }

        const bomLevelFileError = bomList.some((bom) => Boolean(fileError[bom.id]));

        if (bomLevelFileError) {
            return isBulkAction ? BULK_ATTACHMENT_ERROR : ATTACHMENT_ERROR_TEXT;
        }

        return '';
    }, [activeBomMail, composeFormData, fileError, recipientError, ccError, bomList, checkedBoms]);

    const handleSelectAll = (event: any) => {
        const selectAllBoms: Record<string, boolean> = {};
        bomList?.forEach((item) => {
            selectAllBoms[item.id] = true;
        });

        const selectState = event.target.checked;

        if (selectState) {
            setCheckedBoms(selectAllBoms);
        } else {
            setCheckedBoms({});
        }
    };

    return (
        <>
            <Snackbar open={snackbarInfo.open} message={snackbarInfo.message} onClose={() => setSnackbarClose()} />
            <Modal
                open={open}
                header={<ModalHeader title="Share Via Email" onClose={() => onClose()} />}
                footer={
                    <Footer
                        isLoggedIn={Boolean(emailService)}
                        fileError={fileError[activeBomMail]}
                        onAccept={handleSendEmail}
                        isLoading={emailService === EMAIL_SERVICE_TYPES.GMAIL ? sendGoogleMailLoading : sendMsMailLoading}
                        onReject={() => onClose()}
                        sendButtonError={sendButtonError}
                    />
                }
            >
                <GoogleOAuthProvider clientId={GMAIL_CONFIG.clientId}>
                    <Box width={isBulkAction ? 1004 : 820}>
                        {isBulkAction && (
                            <BodyXS mt={-2} mb={4} color="#64748B">
                                {BULK_EMAIL_HEADER_SUBTITLE}
                            </BodyXS>
                        )}
                        {emailService !== null && emailBodyReady && !isLoading ? (
                            emailService ? (
                                <Box display="flex" gap={2}>
                                    {isBulkAction && (
                                        <Box display={'flex'} flexDirection={'column'} width="20%">
                                            <Box sx={{ margin: '0px', marginLeft: '11px' }}>
                                                <Checkbox
                                                    label={'Select all'}
                                                    size={'medium'}
                                                    onChange={handleSelectAll}
                                                    checked={checkedBoms && bomList ? (Object.values(checkedBoms).filter((el) => el)?.length === bomList.length ? true : false) : false}
                                                    indeterminate={
                                                        checkedBoms && bomList
                                                            ? Object.values(checkedBoms).filter((el) => el)?.length > 0 &&
                                                              (Object.values(checkedBoms).filter((el) => el)?.length !== bomList.length ? true : false)
                                                            : false
                                                    }
                                                />
                                            </Box>

                                            <Box height="435px" border={'1px solid'} borderColor={'neutral.main'}>
                                                <CustomerTab
                                                    showCheckbox={true}
                                                    projectId={projectId || 0}
                                                    defaultSelectedBomId={activeBomMail}
                                                    onTabChange={handleTabChange}
                                                    selectedBomIds={new Set(selectedBomdIds)}
                                                    orientation="vertical"
                                                    checkBoxState={checkedBoms}
                                                    onCheckboxSelect={(params) => handleBomSelect(params)}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {renderComposeMail()}
                                </Box>
                            ) : (
                                <Box display="flex" flexDirection="column" alignItems={'center'}>
                                    <Info width="100%">
                                        <BodySmall limit={false} color="primary.main">
                                            <Box component="span" fontWeight={700} display="block" width="100%">
                                                To send your document through Parspec, we need to connect to your email.
                                            </Box>
                                            More email presets are available in{' '}
                                            <Box component="span" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/v2/settings/my-profile')}>
                                                Profile Settings
                                            </Box>
                                            .
                                        </BodySmall>
                                    </Info>

                                    <SyncAccount emailService={emailService || ''} updateEmailService={setEmailService} />
                                </Box>
                            )
                        ) : (
                            <EmailSkeleton />
                        )}
                    </Box>
                </GoogleOAuthProvider>
            </Modal>
        </>
    );
};

export default EmailPopup;
