import { BodyXS, Box } from '@parspec/pixel';

import { SortProductListRequest } from '../queries/apiTypes';
import { PricingLeadTimeTabDisabledLabel } from './components';

export const TAB_VALUES = {
    BOM_TAB_VALUE: 'bom',
    LEAD_TAB_VALUE: 'leadTime',
    DATASHEET_TAB_VALUE: 'dataSheets',
    OM_DOCUMENTS_TAB_VALUE: 'additionalDocuments',
    FINAL_DOCUMENTS_TAB_VALUE: 'finalDocuments',
    PRICING_AND_LEAD_TIME: 'pricingAndLeadtime'
};

export const PRICING_AND_LEADTIME_ENABLED_TAB_OPTION = { label: 'Pricing & Lead Time', value: TAB_VALUES.PRICING_AND_LEAD_TIME };

const PRICING_AND_LEADTIME_DISABLED_TAB_OPTION = {
    label: <PricingLeadTimeTabDisabledLabel />,
    value: TAB_VALUES.PRICING_AND_LEAD_TIME
};

export const TAB_OPTIONS = [
    { label: 'Bill of Materials', value: TAB_VALUES.BOM_TAB_VALUE },
    { label: 'Datasheets', value: TAB_VALUES.DATASHEET_TAB_VALUE },
    {
        label: (
            <Box display="flex" alignItems="center" gap="4px" data-testid="additional-documents-beta-label">
                <BodyXS color="inherit" fontWeight="500">
                    Additional Documents
                </BodyXS>{' '}
            </Box>
        ),
        value: TAB_VALUES.OM_DOCUMENTS_TAB_VALUE
    },
    { label: 'Final Documents', value: TAB_VALUES.FINAL_DOCUMENTS_TAB_VALUE }
];

export const PRICING_ENABLED_TAB_OPTIONS = [...TAB_OPTIONS];
PRICING_ENABLED_TAB_OPTIONS.splice(1, 0, PRICING_AND_LEADTIME_ENABLED_TAB_OPTION);

export const PRICING_DISABLED_TAB_OPTIONS = [...TAB_OPTIONS];
PRICING_DISABLED_TAB_OPTIONS.splice(1, 0, PRICING_AND_LEADTIME_DISABLED_TAB_OPTION);

export const AUTO_SEARCH_OPTIONS = (onClick: (type: 'me' | 'company_users' | 'company_group_users') => void) => [
    { label: 'My History', onClick: () => onClick('me') },
    { label: 'Location History', onClick: () => onClick('company_users') },
    { label: 'Team History', onClick: () => onClick('company_group_users') }
];

export const EXPORT_OPTIONS = (onClick: (type: string) => void) => [
    { label: 'CSV', onClick: () => onClick('csv') },
    { label: 'Excel', onClick: () => onClick('xls') },
    { label: 'Agile XML', onClick: () => onClick('agile_xml') }
];

export const CREATE_SECTION_OPTIONS = (onClick: (type: string) => void) => [
    { label: 'Products section', onClick: () => onClick('products') },
    { label: 'Options section', onClick: () => onClick('options') }
];

export const CREATE_DOCUMENT_OPTIONS = (onClick: (type: string) => void) => [
    { label: 'Create Submittal', onClick: () => onClick('submittal') },
    { label: 'Create O&M', onClick: () => onClick('o_m') }
];

export const DocumentTypes = {
    DATASHEET: 'datasheet',
    WARRANTY: 'warranty',
    DRAWING: 'drawing',
    INSTALLATION_GUIDE: 'installation_guide',
    OTHER_DOCUMENT: 'other_document'
};

export enum Statuses {
    confirmed = 'CONFIRMED',
    inProgress = 'IN_PROGRESS',
    notConfirmed = 'NOT_CONFIRMED'
}
export const SubmittalBomType = 'submittal_package';

type OnOptionClick = (sortBy: SortProductListRequest) => void;

export const sortOptions = (onOptionClick: OnOptionClick) => [
    { label: 'Type (A-Z)', onClick: () => onOptionClick({ sorting_field_name: 'category', sorting_order: 'asc' }) },
    { label: 'Type (Z-A)', onClick: () => onOptionClick({ sorting_field_name: 'category', sorting_order: 'desc' }) },
    { label: 'Manufacturer (A-Z)', onClick: () => onOptionClick({ sorting_field_name: 'manufacturer', sorting_order: 'asc' }) },
    { label: 'Manufacturer (Z-A)', onClick: () => onOptionClick({ sorting_field_name: 'manufacturer', sorting_order: 'desc' }) }
];

export const SCHEDULE_FILE_TYPES = {
    xmlFileType: '.xml',
    xlsxFileType: '.xlsx',
    xlsFileType: '.xls',
    csvFileType: '.csv',
    xlsmFileType: '.xlsm'
};
export const RECOGNISED_EXCEL_FOMRATS = {
    OASIS_EXCEL: 'oasis_excel',
    EPISOR_EXCEL: 'epicor_excel',
    AGILE_EXCEL: 'agile_excel',
    NORMAL_EXCEL: 'normal_excel',
    PARSPEC_EXCEL: 'parspec_quote_excel'
};

//Kit Creation Error Dialog Text
export const KIT_CREATE_ERROR_TEXT = 'We weren’t able to create a kit with the selected line(s) because one or more of them is already a kit.';
export const KIT_CREATE_ERROR_HELPER_TEXT = 'Please de-select all kit lines and try again.';
export const KIT_CREATE_ERROR_OPTION_TEXT =
    'We weren’t able to create a kit with the selected line(s) because one or more of them has one or more associated options in it. Please remove those line(s) and try creation kit.';
export const KIT_CREATE_ERROR_OPTION_HELPER_TEXT = 'Please de-select all option lines and try again.';

export enum KIT_ERROR_TYPE {
    KIT = 'kit',
    OPTION = 'option'
}

export const KIT_ERROR = {
    [KIT_ERROR_TYPE.KIT]: { text: KIT_CREATE_ERROR_TEXT, helperText: KIT_CREATE_ERROR_HELPER_TEXT },
    [KIT_ERROR_TYPE.OPTION]: { text: KIT_CREATE_ERROR_OPTION_TEXT, helperText: KIT_CREATE_ERROR_OPTION_HELPER_TEXT }
};

//Kit popup description text
export const PRICE_LEAD_TIME_DESCRIPTION_TEXT =
    'Enter the information for this Kit and its components. The cost cells for the Kit line will be automatically filled based on the cost of the Component lines, and only the Kit line will appear on the Pricing & Lead Time tab.';
export const BOM_DESCRIPTION_TEXT = 'Enter the information for this Kit and its components. Only the Kit line will appear on the BOM tab.';
export const DATASHEET_DESCRIPTION_TEXT = 'Enter the information for this Kit and its components. Only the Kit line will appear on the Datasheet tab.';
export const OM_DESCRIPTION_TEXT = 'Enter the information for this Kit and its components. Only the Kit line will appear on the Other Documents tab.';

export const PARSEC_EXCEL_IMPORT_WARNING = 'Importing a Parspec Excel quote will NOT import Tax, or Freight values.';

export enum SECTION_TYPE {
    MAIN = 'primary',
    OPTION = 'substitute'
}
export const DEFAULT_COLUMN_DETAILS = {
    order: [
        'category',
        'qty',
        'manufacturer',
        'model_number',
        'description',
        'specification',
        'product_to_replace',
        'quoteNotes',
        'internalComments',
        'specificationPlTab',
        'replaceProductId',
        'productFinder',
        'lotId',
        'history',
        'cost',
        'discount',
        'discountedCost',
        'margin',
        'sellPrice',
        'extendedPrice',
        'addDeductAmount',
        'leadTimeValue',
        'leadTimeUnit',
        'quoteNo',
        'notes',
        'datasheet',
        'annotations',
        'installationGuide',
        'drawing',
        'warranty',
        'otherDocument'
    ],
    width: {
        category: 200,
        qty: 200,
        manufacturer: 200,
        modelNo: 200,
        description: 200,
        replaceProductId: 300,
        specification: 208,
        productFinder: 100,
        quoteNotes: 200,
        internalComments: 200,
        lotId: 200,
        cost: 200,
        discount: 120,
        discountedCost: 143,
        margin: 100,
        sellPrice: 130,
        extendedPrice: 145,
        leadTimeValue: 145,
        leadTimeUnit: 145,
        quoteNo: 173
    }
};

export enum SECTION_TYPE_FOR_PAYLOAD {
    MAIN = 'section',
    OPTION = 'substitute_section'
}

export const NO_BOD_MSG = 'Import products or add lines to get started!';

export const BODS_COLUMN_MAP: { [index: string]: string } = {
    type: 'category',
    category: 'category',
    qty: 'qty',
    manufacturer: 'manufacturer',
    model_number: 'model_number',
    description: `description`
};

export const PRICE_RELATED_COLS = {
    cost: 'cost_cents',
    discountedCost: 'discounted_cost_cents',
    sellPrice: 'sell_price_cents'
};

export const QUOTE_META_DATA_COLS_MAP = {
    ...PRICE_RELATED_COLS,
    margin: 'margin_percentage',
    discount: 'discount_percentage',
    internalComments: 'internal_notes',
    quoteNotes: 'notes',
    sourceQuoteNumber: 'source_quote_number',
    quoteNo: 'source_quote_number'
};

export const SUBMITTAL_META_DATA_COLS_MAP = {
    notes: 'notes'
};
