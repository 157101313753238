export const coverPageConfigurations = [
  {
    field_name: 'date',
    is_active: true,
  },
  {
    field_name: 'primary_title',
    is_active: true,
  },
  {
    field_name: 'secondary_title',
    is_active: true,
  },
  {
    field_name: 'project_number',
    is_active: true,
  },
  {
    field_name: 'quote_number',
    is_active: true,
  },
  {
    field_name: 'project_address',
    is_active: true,
  },
  {
    field_name: 'architect',
    is_active: true,
  },
  {
    field_name: 'engineer',
    is_active: true,
  },
  {
    field_name: 'distributor',
    is_active: true,
  },
  {
    field_name: 'contractor',
    is_active: true,
  },
  {
    field_name: 'owner',
    is_active: true,
  },
  {
    field_name: 'notes',
    is_active: true,
  },
  {
    field_name: 'prepared_by',
    is_active: true,
  },
  {
    field_name: 'address_to',
    is_active: true,
  },
  {
    field_name: 'is_custom_cover_page',
    is_active: true,
  },
  {
    field_name: 'cover_page_section',
    is_active: false,
  },
];

export const coverPageData = [
  {
    field_name: 'cover_page_template',
    field_value: 0,
  },
  {
    field_name: 'date',
    field_value: '02/28/2023',
  },
  {
    field_name: 'primary_title',
    field_value: 'Walnut Creek medical Center',
  },
  {
    field_name: 'secondary_title',
    field_value: 'Submittal Name ',
  },
  {
    field_name: 'project_number',
    field_value: '<project-number>',
  },
  {
    field_name: 'quote_number',
    field_value: '<quote_number>',
  },
  {
    field_name: 'project_address',
    field_value: {
      address_line_1: '<Address line 1>',
      address_line_2: '<Address line 2>',
      city: '<city>',
      state: '<state>',
      zip: '<zip>',
    },
  },
  {
    field_name: 'architect',
    field_value: '<value>',
  },
  {
    field_name: 'engineer',
    field_value: '<value>',
  },
  {
    field_name: 'distributor',
    field_value: '<value>',
  },
  {
    field_name: 'contractor',
    field_value: '<value>',
  },
  {
    field_name: 'owner',
    field_value: '<value>',
  },
  {
    field_name: 'notes',
    field_value: '<value>',
  },
  {
    field_name: 'prepared_by',
    field_value: {
      company: '<value>',
      name: '<value>',
      email: '<value>',
      phone: '<value>',
      address_line_1: '<Address line 1>',
      address_line_2: '<Address line 2>',
      city: '<city>',
      state: '<state>',
      zip: '<zip>',
    },
  },
  {
    field_name: 'address_to',
    field_value: {
      company: '<value>',
      name: '<value>',
      email: '<value>',
      phone: '<value>',
      address_line_1: '<Address line 1>',
      address_line_2: '<Address line 2>',
      city: '<city>',
      state: '<state>',
      zip: '<zip>',
    },
  },
];

export const customCoverPage = [
  {
    field_name: 'is_custom_cover_page',
    field_value: true,
  },
  {
    field_name: 'custom_cover_page',
    field_value: {
      filename: 'my_custom.pdf',
      filepath: 'https://www.africau.edu/images/default/sample.pdf',
    },
  },
  {
    field_name: 'size',
    field_value: '87',
  },
];
