import { Grid, Switch, TextField, Box } from '@parspec/pixel';

import { IToggleText } from '../constants';
import ApplyToAllBomsButton from './ApplyToAllBomsButton';

function ToggleText({
    checked,
    switchLabel,
    onToggle,
    placeholder,
    textFieldValue,
    fieldName,
    maxLength,
    blurAction,
    textFieldAction,
    focusAction,
    disabled = false,
    toggleName = '',
    sectionName
}: IToggleText) {
    return (
        <Grid container width={1}>
            <Grid item xs={4}>
                <Switch name={toggleName} checked={checked} label={switchLabel} onChange={(e) => onToggle(e)} />
            </Grid>

            <Grid item xs={8}>
                <Box display="flex" alignItems="center" gap={2}>
                    <TextField
                        inputProps={{
                            maxLength: maxLength
                        }}
                        disabled={disabled}
                        onBlur={blurAction}
                        onFocus={focusAction}
                        label={''}
                        //@ts-ignore
                        value={typeof textFieldValue === 'object' ? textFieldValue?.value : textFieldValue}
                        placeholder={placeholder}
                        color="secondary"
                        onChange={textFieldAction}
                        name={fieldName}
                    />
                    <ApplyToAllBomsButton sectionName={sectionName} subSectionName={toggleName} />
                </Box>
            </Grid>
        </Grid>
    );
}

export default ToggleText;
