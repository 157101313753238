import { useState, useEffect } from 'react';

import { ColorResult, SelectChangeEvent } from '@parspec/pixel';

import MultilineTextWithFontAndColor from '../../shared/MultilineWithColorAndFont';
import { useQuoteContext } from '../../QuoteContext';
import { useCoverPageDataQuery, useCoverPageTextMutation, useCoverPageToggleMutation } from '../../queries';
import CircularLoader from '../../../../../../shared/CircularLoader';
import { quoteCoverPageOpeningNotesFieldName } from '../../../constants';
import { FieldNotes } from '../../queries/apiTypes';
import { OPENING_NOTES_SUB_SECTION } from '../../shared/constants';

interface OpeningNotesProps {
    sectionName?: string;
}

function OpeningNotes({ sectionName }: OpeningNotesProps) {
    const { handlePdfPreview, isInitiateApiCalls, isTextFieldChanged, handleTextFieldChanged, bomId, handleUpdateChangedSections } = useQuoteContext();

    const { data: coverPageData, isLoading } = useCoverPageDataQuery(bomId, isInitiateApiCalls);

    const { mutateAsync: mutationText } = useCoverPageTextMutation();
    const { mutateAsync: mutateToggle } = useCoverPageToggleMutation();

    const [openingNotesState, setOpeningNotesState] = useState<FieldNotes>({
        value: '',
        font: {
            color: '#37d67a',
            type: 'regular'
        }
    });

    useEffect(() => {
        if (coverPageData?.opening_notes) {
            setOpeningNotesState(coverPageData.opening_notes.field_value);
        }
    }, [isLoading]);

    const pushChangedFields = (fieldName: string) => {
        if (sectionName) {
            handleUpdateChangedSections?.({ sectionName: sectionName, subSectionName: fieldName, value: true });
        }
    };

    async function blurAction(isColor = false) {
        if (isTextFieldChanged || isColor) {
            await mutationText({
                bomId,
                fieldName: quoteCoverPageOpeningNotesFieldName,
                field_value: openingNotesState
            });

            handlePdfPreview(true);
            pushChangedFields(OPENING_NOTES_SUB_SECTION);
        }
    }

    function handleOnFocus() {
        handleTextFieldChanged(false);
    }

    async function selectFontWeightHandler(e: SelectChangeEvent<unknown>) {
        const fieldValue = {
            ...openingNotesState,
            font: {
                ...openingNotesState.font,
                type: e.target.value as string
            }
        };

        setOpeningNotesState(fieldValue);
        await mutationText({
            bomId,
            fieldName: quoteCoverPageOpeningNotesFieldName,
            field_value: fieldValue
        });

        handlePdfPreview(true);
        pushChangedFields(OPENING_NOTES_SUB_SECTION);
    }

    function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        handlePdfPreview(false);
        handleTextFieldChanged(true);

        setOpeningNotesState({
            ...openingNotesState,
            value: e.target.value
        });
    }

    function handleColorChange(color: ColorResult) {
        setOpeningNotesState({
            ...openingNotesState,
            font: {
                ...openingNotesState.font,
                color: color.hex
            }
        });
    }

    function handleOnColorPanelClick(isOpen: boolean) {
        if (isOpen) {
            blurAction(true);
        }
    }

    async function handleOnToggle(e: React.ChangeEvent<HTMLInputElement>) {
        await mutateToggle({
            bomId,
            fieldName: quoteCoverPageOpeningNotesFieldName,
            is_active: e.target.checked
        });

        handlePdfPreview(true);
        pushChangedFields(OPENING_NOTES_SUB_SECTION);
    }

    if (isLoading) {
        return <CircularLoader />;
    }

    return (
        <>
            {coverPageData?.opening_notes && (
                <MultilineTextWithFontAndColor
                    textFieldValue={openingNotesState.value}
                    onBlur={blurAction}
                    selectedFontValue={openingNotesState.font.type}
                    onTextFieldChange={(e) => handleTextFieldChange(e)}
                    onSelect={(e) => selectFontWeightHandler(e)}
                    numberOfRows={6}
                    color={openingNotesState.font.color}
                    onColorChange={(color) => handleColorChange(color)}
                    switchValue={coverPageData?.opening_notes.is_active}
                    onToggle={(e) => handleOnToggle(e)}
                    title="Opening Notes"
                    onFocus={handleOnFocus}
                    onClick={handleOnColorPanelClick}
                    maxLength={10000}
                    sectionName={sectionName}
                    subSectionName={OPENING_NOTES_SUB_SECTION}
                />
            )}
        </>
    );
}

export default OpeningNotes;
