import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Base64 } from 'js-base64';

import { Skeleton, Box } from '@parspec/pixel';

import { useValidateInvitedUserSignUpInvitationMutation } from '../../queries';
import { LinkExpired } from '../../shared/components/LinkExpired';

// This component is used to validate the token mail sent to the INREACT_APPD user
export const ValidateInvitedUserSignupInvitation = () => {
    const { token } = useParams();

    const navigate = useNavigate();

    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

    const decoded_token_from_URL = Base64.decode(token || '');
    const tokenFromURL = Base64.encode(decoded_token_from_URL);

    const userToken = tokenFromURL ? tokenFromURL : localStorage.getItem('user_token') || '';

    const { data: getValidateSignupInvitation, isLoading: isTokenValidLoading } = useValidateInvitedUserSignUpInvitationMutation(userToken);

    useEffect(() => {
        if (!getValidateSignupInvitation?.data) {
            return;
        }

        if (getValidateSignupInvitation.data.message === 'Link verified') {
            localStorage.setItem('user_token', getValidateSignupInvitation.data.token);
            setIsTokenValid(true);
            navigate('/signup/invited-user-signup');
        }
    }, [getValidateSignupInvitation?.data]);

    return (
        <Box
            width="100vw"
            minHeight="100vh"
            py={8}
            bgcolor="#091535"
            sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(assets/images/login/login-bg.png)`
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" mb={14}>
                    <Box height="45px" width="45px" mr={3}>
                        <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                    </Box>

                    <Box>
                        <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                    </Box>
                </Box>

                <Box>
                    {!isTokenValidLoading ? (
                        <>{!isTokenValid && <LinkExpired />}</>
                    ) : (
                        <Box>
                            <Skeleton variant="rectangular" color="light" width="337px" height="427px" />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
