import { useEffect, useState } from 'react';
import { Routes, Route, Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Box, CircularProgress } from '@parspec/pixel';

import { useSubscriptionInfo } from '../features/Settings/PlanAndPayment/queries';
import MatchDatasheet from '../features/BOM/Datasheets/Match';
import Bom from '../features/BOM';
import Dashboard from '../features/Dashboard';
import ProjectDetails from '../features/Project';
import ProjectsTable from '../features/Dashboard/ProjectsTable';
import SubmittalTable from '../features/Dashboard/SubmittalTable';
import BOMTable from '../features/Dashboard/BOMTable';
import AnnotateDatasheet from '../features/BOM/Datasheets/AnnotateDatasheet';
import Compile from '../features/BOM/FinalDocuments/Compile';
import AggregatorCropping from '../features/BOM/AggregatorCropping';
import DocumentParser from '../features/BOM/addProducts/DocumentParser';
import OmPackageTable from '../features/Dashboard/OmPackageTable';
import PdfViewer from '../features/BOM/FinalDocuments/Preview';
import { ProtectedRoute } from './ProtectedRoute';
import ProductFinder from '../features/BOM/productFinder';
import CreateProduct from '../features/BOM/productFinder/createProduct';
import SetRequirements from '../features/BOM/productFinder/setRequirements';
import SideNavBar from '../features/SideNavBar';
import SelectProducts from '../features/BOM/productFinder/selectProduct/SelectProducts';
import { Settings, MyProfile, AddLocation, EditLocation, BranchLocations, UserManagement, Manufacturer, PlanAndPayment } from '../features/Settings';
import QuotesTables from '../features/Dashboard/QuotesTable/QuotesTable';
import { TrackActivityDurationWrapper } from '../features/shared/UserActivityTracking/TrackActivityDuration';
import { useInitializeEventService, useIntervalForPerformance } from '../features/shared/UserActivityTracking/EventService';
import { useInitialiseIntercom } from '../features/shared/hooks/useIntercomHook';
import { PricingHistory } from '../features/pricingHistory';
import ContactManagement from '../features/ContactManagement';
import CompanyTable from '../features/ContactManagement/Company/CompanyTable';
import ContactsTable from '../features/ContactManagement/Contacts/ContactsTable';
import CompanyDetails from '../features/ContactManagement/Company/CompanyDetails';
import ContactDetailPage from '../features/ContactManagement/Contacts/ContactDetailPage';
import { useFeatureFlag } from '../features/shared/UserActivityTracking/useFeatureFlag';
import BidBoard from '../features/BidBoard';
import {
    SignIn,
    ForgotPassword,
    ResetPassword,
    SSOAuthentication,
    SignupOutlet,
    SignUp,
    InvitedUsersSignup,
    ValidateInvitedUserSignupInvitation,
    VerifyNewUserSignupInvitation,
    CompanyInfo,
    PersonalInfo
} from '../features/Authentication';
import { UnauthorisedContent } from '../features/shared/Unauthorised';
import { useGetAuthToken } from '../features/Authentication/hooks';
import { isURLMatchHandler } from './Axios';
import { ForceRefreshModal } from '../features/shared/ForceRefreshModal';
import { ReportingDashboard, Customers, BranchLocations as ReportingBranchLocations, QuoteOwners, Manufacturers } from 'src/features/ReportingDashboard';
import { useGetDeploymentInfoQuery, useGetMaintenanceDataQuery } from '../features/shared/queries/index';
import { MaintenancePage } from 'src/features/shared/MaintenancePage';

const AppRoutes = () => {
    const [deploymentInfo, setDeploymentInfo] = useState({ 'last-modified': 0 });
    const [isOpen, setIsOpen] = useState(false);

    const { token } = useGetAuthToken();

    const { data: deploymentData } = useGetDeploymentInfoQuery({ refetchOnWindowFocus: 'always' });
    const { data: paymentDetail, isFetching } = useSubscriptionInfo({ enabled: Boolean(token) });
    const isActiveSubscription = paymentDetail?.data?.active;
    const { enable_quoting } = useFeatureFlag();
    const location = useLocation();
    const navigate = useNavigate();

    const { data: maintenanceAPIData, refetch: refetchMaintenanceData } = useGetMaintenanceDataQuery();

    const getDeploymentInfo = async () => {
        if (deploymentInfo['last-modified'] === 0) {
            setDeploymentInfo(deploymentData);
        } else if (deploymentInfo['last-modified'] !== deploymentData['last-modified']) {
            setIsOpen(true);
        }
        setDeploymentInfo(deploymentData);
    };

    useEffect(() => {
        if (deploymentData) getDeploymentInfo();
    }, [deploymentData]);

    useEffect(() => {
        const PUBLIC_ROUTES = ['/login', '/signup', '/signup/*'];
        if (token && isURLMatchHandler(location.pathname, PUBLIC_ROUTES)) {
            navigate('/');
        }
    }, []);

    if (location.pathname.startsWith('/validate_invitation/')) {
        if (token) {
            navigate('/');
        }
    }

    useInitializeEventService();
    useInitialiseIntercom();
    useIntervalForPerformance();

    const maintenanceData = maintenanceAPIData?.app_maintenance?.length ? maintenanceAPIData.app_maintenance[0] : { is_under_maintenance: false, end_date_time: '' };

    return (
        <Box bgcolor="secondary.contrastText" minWidth={'942px'}>
            {maintenanceData.is_under_maintenance ? (
                <MaintenancePage date={maintenanceData.end_date_time} refetchMaintenanceData={refetchMaintenanceData} />
            ) : (
                <>
                    <Routes>
                        <Route path="/" element={<Navigate to="/v2/dashboard" />} />
                        <Route
                            path="/v2/"
                            element={
                                <ProtectedRoute permitted={true}>
                                    <SideNavBar />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                path="dashboard/"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <Dashboard />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<ProjectsTable />} />
                                <Route path="bom" element={<BOMTable />} />
                                <Route path="submittals" element={<SubmittalTable />} />
                                <Route path="omPackages" element={<OmPackageTable />} />
                                {enable_quoting && <Route path="quotes" element={<QuotesTables />} />}
                            </Route>

                            <Route
                                path="contactManagement/"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <ContactManagement />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<CompanyTable />} />
                                <Route path="contacts" element={<ContactsTable />} />
                            </Route>

                            <Route path="company/:companyId/">
                                <Route index element={<CompanyDetails />} />
                                <Route path="contacts/:contactId" element={<ContactDetailPage />} />
                            </Route>

                            <Route
                                path="reportingDashboard/"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <ReportingDashboard />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<Customers />} />
                                <Route path="branchLocations" element={<ReportingBranchLocations />} />
                                <Route path="quoteOwners" element={<QuoteOwners />} />
                                <Route path="manufacturers" element={<Manufacturers />} />
                            </Route>

                            <Route
                                path="project/:projectId"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <Outlet />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<ProjectDetails />} />
                                <Route
                                    path="bom/:bomId/"
                                    element={
                                        <>
                                            {isFetching ? (
                                                <Box display="flex" alignItems="center" justifyContent="center" height={'100vh'}>
                                                    <CircularProgress color="primary" size="xxl" />
                                                </Box>
                                            ) : (
                                                <ProtectedRoute permitted={isActiveSubscription}>
                                                    <Outlet />
                                                </ProtectedRoute>
                                            )}
                                        </>
                                    }
                                >
                                    <Route index element={<Bom />} />
                                    <Route
                                        path="addProducts"
                                        element={
                                            <TrackActivityDurationWrapper key={'SelectProductListPage'} activityType={'SelectProductListPage'}>
                                                <DocumentParser />
                                            </TrackActivityDurationWrapper>
                                        }
                                    />
                                    <Route
                                        path="adjustPages"
                                        element={
                                            <TrackActivityDurationWrapper key={'SelectPageContentPage'} activityType={'SelectPageContentPage'}>
                                                <AggregatorCropping />
                                            </TrackActivityDurationWrapper>
                                        }
                                    />

                                    <Route path="product/:productId/:documentType/:subDocumentType/" element={<Outlet />}>
                                        <Route
                                            path="match"
                                            element={
                                                <TrackActivityDurationWrapper key={'MatchDatasheet'} activityType={'MatchDatasheet'}>
                                                    <MatchDatasheet />
                                                </TrackActivityDurationWrapper>
                                            }
                                        />
                                        <Route
                                            path="annotate/"
                                            element={
                                                <TrackActivityDurationWrapper key={'AnnotateDatasheet'} activityType={'AnnotateDatasheet'}>
                                                    <AnnotateDatasheet />
                                                </TrackActivityDurationWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route path="finalDocuments/" element={<Outlet />}>
                                        <Route path="compile/:documentType" element={<Compile />} />
                                        <Route path="share/:documentType/:version" element={<PdfViewer />} />
                                    </Route>
                                    <Route path="productFinder/:productId" element={<Outlet />}>
                                        <Route index element={<ProductFinder />}></Route>
                                        <Route
                                            path=":documentType/:subDocumentType/selectAlternate"
                                            element={
                                                <TrackActivityDurationWrapper key={'ProductFinderSelectAlternateDatasheetPage'} activityType={'ProductFinderSelectAlternateDatasheetPage'}>
                                                    <SelectProducts />
                                                </TrackActivityDurationWrapper>
                                            }
                                        ></Route>
                                        <Route
                                            path="setRequirements"
                                            element={
                                                <TrackActivityDurationWrapper key={'ProductFinderSetRequirementPage'} activityType={'ProductFinderSetRequirementPage'}>
                                                    <SetRequirements />
                                                </TrackActivityDurationWrapper>
                                            }
                                        ></Route>
                                        <Route
                                            path="create"
                                            element={
                                                <TrackActivityDurationWrapper key={'ProductFinderCreateProductPage'} activityType={'ProductFinderCreateProductPage'}>
                                                    <CreateProduct />
                                                </TrackActivityDurationWrapper>
                                            }
                                        ></Route>
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="bid-board"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <BidBoard />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="settings/"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <Settings />
                                    </ProtectedRoute>
                                }
                            >
                                <Route path="my-profile" element={<MyProfile />} />
                                <Route path="user-management" element={<UserManagement />} />
                                <Route path="manufacturers" element={<Manufacturer />} />
                                <Route path="company-locations" element={<BranchLocations />} />
                                <Route path="edit-location/:locationId" element={<EditLocation />} />
                                <Route path="add-location" element={<AddLocation />} />
                                <Route path="plan-and-payment" element={<PlanAndPayment />} />
                            </Route>

                            <Route
                                path="pricing-history"
                                element={
                                    <ProtectedRoute permitted={true}>
                                        <PricingHistory />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>

                        {/* Authentication Routes */}
                        <Route path="login" element={<SignIn />} />
                        <Route path="forgotPassword" element={<ForgotPassword />} />
                        <Route path="reset_password/:token" element={<ResetPassword />} />
                        <Route path="login/sso/authorize" element={<SSOAuthentication />} />
                        <Route path="unauthorised" element={<UnauthorisedContent />} />
                        <Route path="signup/" element={<SignupOutlet />}>
                            <Route path="" element={<SignUp />} />
                            <Route path="invited-user-signup" element={<InvitedUsersSignup />} />
                            <Route path="company_info" element={<CompanyInfo />} />
                            <Route path="personal_info" element={<PersonalInfo />} />
                        </Route>
                        <Route path="validate_invitation/:token" element={<ValidateInvitedUserSignupInvitation />} />
                        <Route path="invitation/:token" element={<VerifyNewUserSignupInvitation />} />

                        <Route path="*" element={<Navigate to="/v2/dashboard" />} />
                    </Routes>

                    <ForceRefreshModal isOpen={isOpen} />
                </>
            )}
        </Box>
    );
};

export default AppRoutes;
