import { useCallback, useMemo, useRef } from 'react';
import { Mutation, UseQueryOptions, useIsMutating } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { BodResponse, ColumnWidthAndReorderResponse } from '../queries/apiTypes';
import { getIfKeyIsTableMutationKey, useGetBomDataQuery, useGetCustomisedColumnQuery, useGetDatasheetTabDataQuery, useGetOmDataQuery, useAddMultipeBodsMutation } from '../queries';
import { usePricingAndLeadTimeBodList } from '../pricing&LeadTime/queries';
import { getMainProductOptionObj } from './utils';
import { useGetLotDetailsQuery } from '../pricing&LeadTime/lotPriceModal/queries';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { DEFAULT_COLUMN_DETAILS, BODS_COLUMN_MAP, QUOTE_META_DATA_COLS_MAP, SUBMITTAL_META_DATA_COLS_MAP, PRICE_RELATED_COLS } from './constants';
import { useGetProjectBomsQuery } from '../../Project/queries';

export function useNinetyViewportHeight() {
    return useMemo(() => (window.innerHeight * 90) / 100, []);
}

export function useGetBomData(bomId: string, options?: UseQueryOptions<AxiosResponse<BodResponse[]>>) {
    const { data: bomReqData, isLoading, isFetching } = useGetBomDataQuery(bomId, options);

    const mainProductOptionsInfo = useMemo(() => {
        if (!bomReqData?.data) {
            return {};
        }
        const bomData = Array.isArray(bomReqData.data) ? bomReqData.data : [];
        const mainProductAndOptionsMap = getMainProductOptionObj(bomData);

        return mainProductAndOptionsMap;
    }, [bomReqData?.data]);

    return { data: bomReqData, isLoading, isFetching, mainProductOptionsInfo };
}

export function useLotMap(bomId: number) {
    const { data: pricingAndLeadTimeData } = usePricingAndLeadTimeBodList(bomId, { enabled: Boolean(bomId) });
    const lotMap = useMemo(() => {
        if (!pricingAndLeadTimeData?.data) {
            return {};
        }

        return pricingAndLeadTimeData.data.reduce((lotMap, currPricingData) => {
            lotMap[currPricingData.bod_id] = currPricingData.lot_id;
            return lotMap;
        }, {} as Record<number, number | null>);
    }, [pricingAndLeadTimeData?.data]);

    return lotMap;
}

export function useIsTableApisInProgress(bomId: string) {
    const isTableMutaionInProgress =
        useIsMutating({
            predicate: (mutation: Mutation) => {
                return getIfKeyIsTableMutationKey(bomId, (mutation.options.mutationKey?.[0] as string | undefined) || '');
            }
        }) > 0;
    const { isFetching: isBomDataLoading } = useGetBomData(bomId, {
        enabled: Boolean(bomId)
    });
    const { isFetching: isPricingBodListLoading } = usePricingAndLeadTimeBodList(Number(bomId), { enabled: Boolean(bomId) });
    const { isFetching: isLotDataLoading } = useGetLotDetailsQuery(bomId, { enabled: Boolean(bomId) });
    const { isFetching: isTabDataLoading } = useGetDatasheetTabDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });
    const { isFetching: omDataLoading } = useGetOmDataQuery(bomId, {
        enabled: Boolean(bomId)
    });

    return isTableMutaionInProgress || isBomDataLoading || isPricingBodListLoading || isLotDataLoading || isTabDataLoading || omDataLoading;
}

export function useGetCustomisedColumnData(bomId: string) {
    const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileInfoQuery();
    const { data: columnOrderDetails, isLoading: isColumnDataLoading } = useGetCustomisedColumnQuery(userData?.data?.id || 0, {
        enabled: Boolean(bomId) && Boolean(userData?.data?.id)
    });
    const isDataFetchingComplete = !isUserDataLoading && !isColumnDataLoading;

    const tableColumnDetails = useMemo(() => {
        if (!isDataFetchingComplete) return null;
        if (isDataFetchingComplete && columnOrderDetails?.table_settings_by_pk) {
            const { order, width } = columnOrderDetails.table_settings_by_pk;

            if (Array.isArray(order) && order.length > 0 && width && typeof width === 'object') {
                return { order, width } as ColumnWidthAndReorderResponse;
            }
        }
        return DEFAULT_COLUMN_DETAILS as ColumnWidthAndReorderResponse;
    }, [columnOrderDetails?.table_settings_by_pk]);

    return { columnOrderDetails: tableColumnDetails, isColumnDataLoading: isColumnDataLoading };
}

export const useCustomisationColumn = () => {
    const sectionRefList = useRef<{ [key: number]: { current: any } }>({});

    const handleTablesReordering = useCallback((columnOrder: string[]) => {
        Object.values(sectionRefList.current).forEach((ref: any) => {
            const columnDefs = ref.current?.api?.getColumnDefs();
            if (columnDefs?.length) {
                const sortedColumnDefs = [...columnDefs].sort((columnA, columnB) => {
                    const positionA = columnOrder.indexOf(columnA.colId);
                    const positionB = columnOrder.indexOf(columnB.colId);
                    return positionA - positionB;
                });
                ref?.current?.api?.setColumnDefs(sortedColumnDefs);
            }
        });
    }, []);

    const handleTablesWidthChanged = useCallback((targetedColumnId: string, newWidth?: number) => {
        if (sectionRefList && targetedColumnId && newWidth) {
            Object.values(sectionRefList.current).map((ref: any) => {
                const columnDefs = ref.current.api.getColumnDefs();
                const columnIndex = columnDefs.findIndex((col: any) => col.colId === targetedColumnId);
                if (columnIndex !== -1 && !(columnDefs[columnIndex].width === newWidth)) {
                    columnDefs[columnIndex].width = newWidth;
                    ref.current.columnApi.setColumnWidth(targetedColumnId, newWidth, false);
                }
            });
        }
    }, []);

    return { handleTablesWidthChanged, handleTablesReordering, sectionRefList };
};

export const useProjectBomsMappedData = (projectId: number) => {
    const { data: projectBomsData } = useGetProjectBomsQuery(Number(projectId), {
        enabled: Boolean(projectId)
    });
    const projectBomsAndPrimaryData = useMemo(() => {
        if (projectBomsData?.data) {
            const projectBomsMappedData = projectBomsData.data.reduce((acc, item) => {
                acc.set(item.id, item.is_linked);
                return acc;
            }, new Map<number, boolean>());

            const primaryBom = projectBomsData.data.find((item) => item.is_primary);
            const primaryBomId = primaryBom ? primaryBom.id : undefined;

            return { projectBomsMappedData, primaryBomId };
        } else {
            return { projectBomsMappedData: new Map<number, boolean>(), primaryBomId: undefined };
        }
    }, [projectBomsData]);

    return { projectBomsAndPrimaryData };
};

export const useCreateNewBods = (bomId: string) => {
    const { data: bodsData } = useGetBomDataQuery(bomId!, {
        enabled: Boolean(bomId)
    });
    const { mutateAsync: addMultipleRows, isLoading } = useAddMultipeBodsMutation();

    const prepareBodsPayload = (newRowsData: string[][], selectedColArr: any[], colsMap: { [index: string]: string }, lastUpdatedBod?: BodResponse) => {
        return newRowsData.flatMap((valueArr) => {
            return valueArr.reduce((acc: any, value: string, index: number) => {
                if (colsMap[selectedColArr?.[index]?.['field']]) {
                    if (colsMap[selectedColArr[index]['field']] === 'qty' && typeof value === 'string') {
                        acc[colsMap[selectedColArr[index]['field']]] = Number(value);
                    } else if (selectedColArr[index]['field'] in PRICE_RELATED_COLS) {
                        let priceValue = Number(value.replace(/[,$%a-zA-Z]/g, ''));
                        acc[colsMap[selectedColArr[index]['field']]] = priceValue === 0 ? null : priceValue * 100;
                    } else if (selectedColArr[index]['cellDataType'] === 'number') {
                        acc[colsMap[selectedColArr[index]['field']]] = Number(value.replace(/[,$%a-zA-Z]/g, ''));
                    } else {
                        acc[colsMap[selectedColArr[index]['field']]] = value;
                    }
                }

                if (lastUpdatedBod) {
                    ['section', 'substitute_section', 'kit'].forEach((item) => {
                        if (lastUpdatedBod.hasOwnProperty(item)) {
                            acc[item] = (lastUpdatedBod as any)[item];
                        }
                    });
                }
                return acc;
            }, {});
        });
    };

    const createNewRowsFromClipboard = async (lastUpdatedBodId: number, selectedColumns: Set<string> | Set<{ field: string; cellDataType: string }>, newRowsData?: string[][]) => {
        if (newRowsData?.length && bodsData?.data) {
            const lastUpdatedBod = bodsData.data.find((item) => item.id === lastUpdatedBodId);
            const selectedColsArr = [...selectedColumns];

            const bodsPayload = prepareBodsPayload(newRowsData, selectedColsArr, BODS_COLUMN_MAP, lastUpdatedBod);
            const quotesMetaPayload = prepareBodsPayload(newRowsData, selectedColsArr, QUOTE_META_DATA_COLS_MAP);
            const submittalsMetaPayload = prepareBodsPayload(newRowsData, selectedColsArr, SUBMITTAL_META_DATA_COLS_MAP);

            try {
                // Performing addMultipleRows mutation after a 500 ms delay so that the query cache for BODs is updated first and then we invalidate the BODs API after the addMutlipleRows mutation is successfull.
                setTimeout(async () => {
                    await addMultipleRows({
                        bomId,
                        newRowsPayload: {
                            bod_id_list: bodsPayload,
                            quote_metadata: quotesMetaPayload,
                            submittal_metadata: submittalsMetaPayload,
                            last_updated_bod_id: lastUpdatedBodId
                        }
                    });
                }, 500);
            } catch (e) {}
        }
    };

    return {
        createNewRowsFromClipboard,
        isLoading
    };
};
