import { TABLE_COLUMN_NAMES } from '../../shared/constants';

export const CUSTOMERS_TABLE_COLUMN_OPTIONS = {
    data: [
        { field: 'projects', label: TABLE_COLUMN_NAMES.PROJECTS },
        { field: 'quotes', label: TABLE_COLUMN_NAMES.QUOTES },
        { field: 'submittalsAndOMs', label: TABLE_COLUMN_NAMES.SUBMITTALS_AND_OM },
        { field: 'winRate', label: TABLE_COLUMN_NAMES.WIN_RATE },
        { field: 'revenue', label: TABLE_COLUMN_NAMES.REVENUE },
        { field: 'margin', label: TABLE_COLUMN_NAMES.MARGIN },
        { field: 'profitMargin', label: TABLE_COLUMN_NAMES.PROFIT_MARGIN }
    ]
};

export const PRIMARY_FIELD_VALUE = 'customer';
