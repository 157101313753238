import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Tabs } from '@parspec/pixel';

import { TAB_OPTIONS } from './shared/constant';
import { setRouteToLocalStorage } from '../shared/utils/utils';

const CMSTabs: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleTabChange = (tabValue: string) => {
        setRouteToLocalStorage(tabValue);
        navigate(tabValue);
    };

    return (
        <Box display="flex" flexDirection="column" minHeight={0}>
            <Tabs onChange={handleTabChange} options={TAB_OPTIONS} selectedTab={pathname} />
        </Box>
    );
};

export default CMSTabs;
