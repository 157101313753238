import { axios } from 'src/app/Axios';
import { IPreviewQuoteResponse } from './apiTypes';

export const endPoints = {
    getPreviewQuote: (bomId: string) => `/bom/${bomId}/quote/document_preview/`
};

export const getQuotePreview = (bomId: string) => {
    return axios.post<IPreviewQuoteResponse>(endPoints.getPreviewQuote(bomId));
};
