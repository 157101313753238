import { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import WebViewer, { Core } from '@pdftron/webviewer';

import { BodySmall, Box, Button, Grid, Snackbar, BodyXS, Skeleton, H5 } from '@parspec/pixel';

import PageRangePopup from 'src/features/shared/AdjustPageSelection';
import CancelButton from '../../shared/CancelButton';
import { useDatasheetBoundQuery, useConfirmSubmittalPackageMutation, useUpdateAdjustPageMutation, useAddDocumentToBodsMutation } from './queries';
import { getParsedXFDFForAPIPayload, getParsedXFDFForWebviewer, getArrayBetweenNumbers, getProxyFileUrl } from 'src/features/shared/utils/utils';
import { HIDDEN_XFDF } from 'src/features/shared/utils/constants';
import { endPoints } from './queries/apis';
import {
    WEBVIEWER_FOLDER_NAME,
    someThingWentWrongMsg,
    ANNOTATION_SAVED_SUCCESSFULLY,
    CROPPING_REGION_PROMPT,
    ANNOTATION_APPLIED_SUCCESSFULLY,
    CONFIRMED_SUCCESSFULLY,
    CROPPING_REGION_COPPIED_SUCCESSFULLY
} from '../../shared/constants';
import { environment } from 'src/environments';
import { loadCanvasWrapper, IGetThumbnails, removeDataFromSessionStorage, getDataFromSessionStorage } from '../../shared/utils/utils';

enum ImportAnnotationStagesEnum {
    APPLY_TO_RANGE,
    ADJUST_PAGE,
    APPLY_TO_ALL
}

let adjustPageTimerId: any;
let pageRangeTimerId: any;

const PAGE_RANGE_POPUP_TITLE_FOR_ADDING_DATASHEETS = 'Choose the datasheet pages to associate with the selected products. Unselect cover page, table of contents, and any other pages to discard.';

const AggregatorCropping = () => {
    const { bomId, projectId } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [submittalPackageId, setSubmittalPackageId] = useState<number>();
    const { data: datasheetResponse, isLoading: isDatasheetResponseLoading } = useDatasheetBoundQuery(bomId!, submittalPackageId!, {
        enabled: !!submittalPackageId
    });
    const { mutate: mutateDatasheetBound } = useUpdateAdjustPageMutation();
    const { mutate: mutateConfirmSubmittal, isLoading: isConfirmLoading } = useConfirmSubmittalPackageMutation();
    const { mutateAsync: addDocumentToBods, isLoading: addDocumentToBodsLoading } = useAddDocumentToBodsMutation();

    const [openAdjustPagePopup, setOpenAdjustPageRangePopup] = useState(false);
    const [isLoadingAdjustPageRange, setIsLoadingAdjustPageRange] = useState(true);
    const [selectedPages, setSelectedPages] = useState<number[]>([]);
    const [openApplyRangePopup, setOpenApplyRangePopup] = useState(false);
    const [isLoadingApplyRange, setIsLoadingApplyRange] = useState(true);
    const [applyRangeSelectedPages, setApplyRangeSelectedPages] = useState<number[]>([]);
    const [applyRangeHiddenPages, setApplyRangeHiddenPages] = useState<number[]>([]);
    const viewer = useRef<HTMLDivElement>(null);
    const [cropRangeStatus, setCropRangeStatus] = useState(false);
    const [highlightAnnotations, setHighlightAnnotations] = useState<any>(null);
    const importAnnotationCurrentStage = useRef<ImportAnnotationStagesEnum | null>(null);
    const wvInstance = useRef<any>(null);
    const hiddenPages = useRef<number[]>([]);
    const setHiddenPages = (oldHiddenPages: number[]) => {
        hiddenPages.current = oldHiddenPages;
    };

    const [thumbnailImages, setThumbnailImages] = useState<string[]>([]);
    const hiddenXfdf = useRef(HIDDEN_XFDF);
    const xfdf = useRef('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const getAsyncThumbnailRef = useRef<() => IGetThumbnails>();
    const getThumbnailsAsyncAdjustPageRef = useRef<() => IGetThumbnails>();

    const [webViewerDocument, setWebViewerDocument] = useState<Core.Document>();

    const [totalPageLength, setTotalPageLength] = useState(Infinity);
    const [adjustPageLength, setAdjustPageLength] = useState(0);

    const from = searchParams.get('from');

    useEffect(() => {
        return () => {
            removeDataFromSessionStorage('selectedBodIds');
        };
    }, []);

    useEffect(() => {
        const showPopup = searchParams.get('showPopup');
        const id = searchParams.get('submittalPackageId');
        setSubmittalPackageId(Number(id));
        if (showPopup === 'true') {
            setOpenAdjustPageRangePopup(true);
        }
    }, []);

    useEffect(() => {
        if (selectedPages.length) {
            setThumbnailImages([]);
            wvInstance.current.UI.openElements(['loadingModal']);
            const proxyFile = getProxyFileUrl(datasheetResponse?.data.file_info || '');
            importAnnotationCurrentStage.current = ImportAnnotationStagesEnum.ADJUST_PAGE;
            wvInstance.current.Core.documentViewer.loadDocument(proxyFile, {
                useDownloader: false
            });
        }
    }, [selectedPages]);

    useEffect(() => {
        if (applyRangeSelectedPages.length > 0) {
            const annotatedArray: number[] = [];
            applyRangeSelectedPages.forEach((page) => {
                wvInstance.current.Core.annotationManager
                    .getAnnotationsList()
                    .filter(({ PageNumber }: { PageNumber: number }) => PageNumber === page)
                    .forEach((e: any) => {
                        annotatedArray.push(e.PageNumber);
                        annotatedArray.sort((a, b) => a - b);
                        e.X = highlightAnnotations[0].X;
                        e.Y = highlightAnnotations[0].Y;
                        e.Width = highlightAnnotations[0].Width;
                        e.Height = highlightAnnotations[0].Height;
                        wvInstance.current.Core.annotationManager.redrawAnnotation(e);
                    });
                if (!annotatedArray.includes(page)) drawAnnotation(page);
            });
            exportAnnotation();
        }
    }, [applyRangeSelectedPages]);

    useEffect(() => {
        if (!isDatasheetResponseLoading && datasheetResponse && wvInstance.current) {
            setHiddenPages([...datasheetResponse.data.hidden_pages]);
            xfdf.current = datasheetResponse.data.datasheet_bound_info;
            const proxyFile = getProxyFileUrl(datasheetResponse.data.file_info) || '';
            if (wvInstance) {
                wvInstance.current.Core.documentViewer.loadDocument(proxyFile, {
                    useDownloader: false
                });
            }
        }
    }, [isDatasheetResponseLoading, datasheetResponse, wvInstance.current]);

    useEffect(() => {
        adjustPageTimerId = setInterval(() => {
            if (getThumbnailsAsyncAdjustPageRef.current) {
                const data = getThumbnailsAsyncAdjustPageRef.current();

                if (data?.isExecutionComplete) {
                    clearInterval(adjustPageTimerId);
                }

                setAdjustPageLength(data?.thumbnails.length);
            }
        }, 800);

        return () => {
            clearInterval(adjustPageTimerId);
        };
    }, []);

    useEffect(() => {
        pageRangeTimerId = setInterval(() => {
            if (getAsyncThumbnailRef.current) {
                const data = getAsyncThumbnailRef.current();

                if (data?.isExecutionComplete) {
                    clearInterval(pageRangeTimerId);
                }

                setThumbnailImages([...data.thumbnails]);
            }
        }, 800);

        return () => {
            clearInterval(pageRangeTimerId);
        };
    }, [webViewerDocument]);

    useEffect(() => {
        if (datasheetResponse) {
            WebViewer(
                {
                    licenseKey: environment.Y,
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    disableLogs: true,
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'annotationPopup',
                        'freeTextToolGroupButton',
                        'ribbons',
                        'linkAnnotationPopup'
                    ],
                    initialDoc: getProxyFileUrl(datasheetResponse.data.file_info)
                },
                viewer.current as HTMLDivElement
            )
                .then(async (instance) => {
                    wvInstance.current = instance;
                    let searchButtonElem = {};
                    instance.UI.setHeaderItems((header: any) => {
                        const items = header.getItems().filter((item: Record<string, any>) => {
                            if (item.dataElement === 'zoomOverlayButton') {
                                return true;
                            }
                            if (item.dataElement === 'searchButton') {
                                searchButtonElem = { ...item };
                            }
                            return false;
                        });
                        items.unshift({ type: 'spacer' });
                        items.push({ type: 'spacer' });
                        items.push(searchButtonElem);
                        header.update(items);
                    });

                    const { documentViewer, annotationManager } = instance.Core;
                    const { setFitMode, FitMode, disableElements } = instance.UI;
                    setFitMode(FitMode.FitWidth);
                    disableElements(['contextMenuPopup', 'contextMenuPopupButton']);

                    const fullDocument = await instance.Core.createDocument(getProxyFileUrl(datasheetResponse.data.file_info));

                    setTotalPageLength(fullDocument.getPageCount());
                    const { createThumbnails, getThumbnailsAsync } = loadCanvasWrapper(fullDocument, fullDocument.getPageCount());
                    createThumbnails();
                    getThumbnailsAsyncAdjustPageRef.current = getThumbnailsAsync;
                    setIsLoadingAdjustPageRange(false);

                    annotationManager.addEventListener('annotationChanged', (_annotations: [], action: string) => {
                        if (action === 'modify') setCropRangeStatus(true);
                    });

                    annotationManager.addEventListener('annotationSelected', (annotations: [], action: string) => {
                        if (action === 'selected') {
                            setHighlightAnnotations([...annotations]);
                        }
                    });

                    documentViewer.addEventListener('documentLoaded', async () => {
                        setFitMode(FitMode.FitWidth);
                        await documentViewer.getDocument().removePages(hiddenPages.current);
                        instance.UI.closeElements(['loadingModal']);
                        importAnnotations(xfdf.current, instance);
                        if (importAnnotationCurrentStage.current === ImportAnnotationStagesEnum.ADJUST_PAGE) {
                            exportAnnotation();
                        }
                        const doc = documentViewer.getDocument();
                        setWebViewerDocument(doc);
                        setApplyRangeHiddenPages(getArrayBetweenNumbers(1, doc.getPageCount()));
                        setIsLoadingApplyRange(false);
                    });
                })
                .catch(() => {});
        }
    }, [datasheetResponse]);

    useEffect(() => {
        if (webViewerDocument && totalPageLength === adjustPageLength) {
            const { createThumbnails, getThumbnailsAsync } = loadCanvasWrapper(webViewerDocument, webViewerDocument.getPageCount());
            createThumbnails();
            getAsyncThumbnailRef.current = getThumbnailsAsync;
        }
    }, [webViewerDocument, adjustPageLength]);

    const importAnnotations = (newXfdf: string, instance: any) => {
        if (datasheetResponse) {
            xfdf.current = newXfdf;
            const selectedPages = getArrayBetweenNumbers(1, datasheetResponse.data['num_pages']).filter((item) => !hiddenPages.current?.includes(item));

            if (xfdf.current) {
                hiddenXfdf.current = HIDDEN_XFDF;
                const { mutatedXfdf, hiddenXfdf: newHiddenXfdf } = getParsedXFDFForWebviewer(xfdf.current, selectedPages, hiddenPages.current, hiddenXfdf.current);
                xfdf.current = mutatedXfdf;
                hiddenXfdf.current = newHiddenXfdf;
                instance.Core.annotationManager.importAnnotations(xfdf.current);
            }
        }
    };
    const drawAnnotation = (pageNumber: number) => {
        const annotNew = new wvInstance.current.Core.Annotations.RectangleAnnotation();

        annotNew.StrokeColor = highlightAnnotations[0].StrokeColor;
        annotNew.FillColor = highlightAnnotations[0].FillColor;
        annotNew.StrokeThickness = highlightAnnotations[0].StrokeThickness;
        annotNew.Opacity = highlightAnnotations[0].Opacity;
        annotNew.X = highlightAnnotations[0].X;
        annotNew.Width = highlightAnnotations[0].Width;
        annotNew.Y = highlightAnnotations[0].Y;
        annotNew.Height = highlightAnnotations[0].Height;
        annotNew.PageNumber = pageNumber;
        wvInstance.current.Core.annotationManager.addAnnotation(annotNew);
        wvInstance.current.Core.annotationManager.redrawAnnotation(annotNew);
    };

    const exportAnnotation = async () => {
        importAnnotationCurrentStage.current = null;
        if (datasheetResponse) {
            const selectedPages = getArrayBetweenNumbers(1, datasheetResponse.data['num_pages']).filter((item) => !hiddenPages.current.includes(item));
            wvInstance.current.Core.annotationManager.exportAnnotations().then((xfdfString: string) => {
                const mutatedXfdf = getParsedXFDFForAPIPayload(xfdfString, selectedPages, hiddenXfdf.current);
                xfdf.current = mutatedXfdf;
                mutateDatasheetBound(
                    { bomId: bomId!, submittalPackageId: Number(submittalPackageId), datasheet_bound_info: mutatedXfdf },
                    {
                        onSuccess: () => {
                            setIsLoadingApplyRange(false);
                        },

                        onError: () => {
                            setMessage(someThingWentWrongMsg);
                            setOpen(true);
                        }
                    }
                );
            });
        }
    };

    const applyToAllHandler = () => {
        if (!cropRangeStatus) {
            setMessage(CROPPING_REGION_PROMPT);
            setOpen(true);
            return;
        }
        const annots = wvInstance.current.Core.annotationManager.getAnnotationsList();

        wvInstance.current.Core.annotationManager.deleteAnnotations(annots);
        const doc = wvInstance.current.Core.documentViewer.getDocument();
        const pageCount = doc.getPageCount();

        for (let i = 1; i <= pageCount; i++) {
            drawAnnotation(i);
        }
        exportAnnotation();
        setMessage(ANNOTATION_APPLIED_SUCCESSFULLY);
        setOpen(true);
    };
    const applyToRangeHandler = () => {
        if (!cropRangeStatus) {
            setMessage(CROPPING_REGION_PROMPT);
            setOpen(true);
            return;
        }
        setOpenApplyRangePopup(true);
    };
    const adjustPageHandler = () => {
        setOpenAdjustPageRangePopup(true);
    };

    const confirmSubmittalPackageHandler = async () => {
        if (searchParams.get('from') === 'bom-detail') {
            let selectedBodIds = getDataFromSessionStorage('selectedBodIds');
            if (selectedBodIds) {
                selectedBodIds = JSON.parse(selectedBodIds);
            }
            if (selectedBodIds?.length) {
                await addDocumentToBods({ bomId: Number(bomId) || 0, submittalPackageId: submittalPackageId || 0, selectedBodIds: selectedBodIds as unknown as number[] });
                navigate(`/v2/project/${projectId}/bom/${bomId}?tab=datasheet`);
            }
            //this case should not come but this is kept only as precaution measue.
            return setMessage('Something went wrong, please restart the process by selecting products');
        }
        await exportAnnotation();
        mutateConfirmSubmittal(
            { bomId: bomId!, submittalPackageId: Number(submittalPackageId) },
            {
                onSuccess: () => {
                    setMessage(CONFIRMED_SUCCESSFULLY);
                    setOpen(true);
                    navigate(`/v2/project/${projectId}/bom/${bomId}?tab=bom`);
                },
                onError: () => {
                    setMessage(someThingWentWrongMsg);
                    setOpen(true);
                }
            }
        );
    };

    const handleAdjustPopupClose = () => {
        const showPopup = searchParams.get('showPopup');
        if (showPopup === 'true') {
            setSearchParams({ showPopup: 'false', submittalPackageId: `${submittalPackageId}`, ...(from ? { from } : {}) });
        }
        setOpenAdjustPageRangePopup(false);
    };

    function saveAnnotationHandler() {
        exportAnnotation();
        setMessage(ANNOTATION_SAVED_SUCCESSFULLY);
        setOpen(true);
    }
    const isButtonLoading = isConfirmLoading || addDocumentToBodsLoading;

    return (
        <>
            <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setOpen(false)} message={message}></Snackbar>
            {openAdjustPagePopup && (
                <PageRangePopup
                    open={openAdjustPagePopup}
                    headerTitle={from ? 'Select Datasheets' : 'Select Data Sheet Pages'}
                    onClose={handleAdjustPopupClose}
                    title={from ? PAGE_RANGE_POPUP_TITLE_FOR_ADDING_DATASHEETS : 'Select data sheets to include in the submittal package (unselect cover page, table of contents, etc.).'}
                    hiddenPages={hiddenPages.current}
                    getThumbnailsAsyncFunc={getThumbnailsAsyncAdjustPageRef.current}
                    setHiddenPages={setHiddenPages}
                    bomId={bomId}
                    submittalPackageId={submittalPackageId}
                    setSelectedPages={setSelectedPages}
                    apiDetails={{
                        endPoint: endPoints.mutateHiddenPagesUrl(bomId!, datasheetResponse?.data?.id || 0),
                        entity: 'aggregatorCrop'
                    }}
                />
            )}
            {openApplyRangePopup && (
                <PageRangePopup
                    open={openApplyRangePopup}
                    headerTitle="Select Pages"
                    onClose={setOpenApplyRangePopup}
                    getThumbnailsAsyncFunc={getAsyncThumbnailRef.current}
                    title="Select pages for which you want to apply the same cropping area as the selected area."
                    hiddenPages={applyRangeHiddenPages}
                    setSelectedPages={(x) => {
                        setMessage(CROPPING_REGION_COPPIED_SUCCESSFULLY);
                        setOpen(true);
                        setApplyRangeSelectedPages(x);
                    }}
                />
            )}
            <Grid container direction="column">
                <Grid item>
                    <Grid container px={6} py={6} justifyContent={'space-between'}>
                        <Grid item xs={7}>
                            <Grid container direction={'column'} alignItems="flex-start" flexWrap="nowrap">
                                <Grid item>
                                    <Grid container direction="column" gap={2}>
                                        <Grid item>
                                            <H5>Select the Desired Page Content</H5>
                                        </Grid>
                                        <Grid item>
                                            <BodySmall color="secondary.light">
                                                Click and drag corners of the orange selections to exclude header and footer regions if you need to replace them with new branding.
                                            </BodySmall>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container direction={'column'} alignItems={'flex-end'} gap={2}>
                                <Grid item>
                                    <Grid container gap={2} justifyContent={'flex-end'}>
                                        <Grid item mr={-1}>
                                            <CancelButton />
                                        </Grid>

                                        <Grid item>
                                            <Button variant="outlined" color="secondary" disabled={!cropRangeStatus} onClick={saveAnnotationHandler}>
                                                Save
                                            </Button>
                                        </Grid>

                                        <Grid item>
                                            <Button variant="outlined" color="secondary" disabled={isButtonLoading ? true : false} onClick={adjustPageHandler} isLoading={isLoadingAdjustPageRange}>
                                                Adjust Page Selection
                                            </Button>
                                        </Grid>

                                        <Grid item>
                                            <Button variant="contained" onClick={confirmSubmittalPackageHandler} isLoading={isButtonLoading}>
                                                Confirm & Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container gap={2} justifyContent={'flex-end'}>
                                        <Grid item>
                                            <Button disabled={isButtonLoading ? true : false} variant="outlined" color="secondary" onClick={applyToRangeHandler} isLoading={isLoadingApplyRange}>
                                                Apply to Range
                                            </Button>
                                        </Grid>

                                        <Grid item>
                                            <Button disabled={isButtonLoading ? true : false} variant="contained" onClick={applyToAllHandler}>
                                                Apply to All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* viewer and thumbnail */}
                <Grid container item flexWrap="nowrap" height="calc(100vh - 122px)">
                    <Grid container item xs="auto" direction="column" flexWrap="nowrap" gap={2} p={2} bgcolor="secondary.light" overflow="auto">
                        {thumbnailImages.length
                            ? thumbnailImages?.map((image, index) => {
                                  return (
                                      <Grid container item key={index} gap={2} flexDirection="column" p={2}>
                                          <img
                                              src={image}
                                              width="168px"
                                              height="220px"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => wvInstance?.current.Core.documentViewer.setCurrentPage(index + 1)}
                                          />
                                          <BodyXS color="neutral.light">Page {index + 1}</BodyXS>
                                      </Grid>
                                  );
                              })
                            : getArrayBetweenNumbers(1, 3).map((_page, index) => {
                                  return (
                                      <Grid item key={index} p={2}>
                                          <Skeleton variant="rectangular" width={168} height={220} key={index} color="light" />
                                      </Grid>
                                  );
                              })}
                    </Grid>

                    <Grid item width={1} height={1}>
                        {!wvInstance.current && <Skeleton variant="rectangular" width="100%" height="100%" />}
                        <Box className="webviewer" height={wvInstance.current ? 1 : 0} ref={viewer} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AggregatorCropping;
