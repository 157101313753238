import React, { useEffect, useState } from 'react';

import { BodyMedium, BodySmall, Box, H5, Skeleton, Switch, RichTextEditor } from '@parspec/pixel';

import SyncAccount from './SyncAccount';

import { SnackbarDataType } from '../Preferences/Preferences';
import { useEditUserPreferencesMutation, useUserAccountPreferencesQuery } from '../queries';
import { EMAIL_SERVICE_TYPES, SHAREABLE_LINK_TEXT } from '../../../BOM/FinalDocuments/EmailPopup/constants';
import { someThingWentWrongMsg } from '../../../shared/constants';

interface IEmailPreferenceProps {
    snackbarData: SnackbarDataType;
    setSnackbarData: React.Dispatch<React.SetStateAction<SnackbarDataType>>;
}

const EmailPreferences = ({ snackbarData, setSnackbarData }: IEmailPreferenceProps) => {
    const [emailService, setEmailService] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [emailPreferenceState, setEmailPreferenceState] = useState<{ message: string; includePdf: boolean }>({
        message: '',
        includePdf: true
    });

    const { data: userPreferences, isLoading } = useUserAccountPreferencesQuery();
    const { mutateAsync: editUserPreferencesMutation } = useEditUserPreferencesMutation();

    useEffect(() => {
        if (!userPreferences?.data) {
            return;
        }

        const { is_pdf_attached, email_body } = userPreferences.data;

        setEmailPreferenceState({
            message: email_body || '',
            includePdf: is_pdf_attached
        });
        setIsDisabled(false);
    }, [userPreferences?.data]);

    useEffect(() => {
        if (localStorage.getItem('msToken')) {
            setEmailService(EMAIL_SERVICE_TYPES.MSAL);
        } else if (localStorage.getItem('googleToken')) {
            setEmailService(EMAIL_SERVICE_TYPES.GMAIL);
        } else {
            setEmailService('');
        }
    }, []);

    const updateEmailService = (value: string) => {
        setEmailService(value);
    };

    const onTextChange = async (value: string) => {
        setEmailPreferenceState((prev) => ({ ...prev, message: value }));

        if (userPreferences) {
            const res = await editUserPreferencesMutation({
                email_body: value,
                autoselection: userPreferences?.data.autoselection,
                autoselection_user_group: userPreferences?.data.autoselection_user_group
            });

            if (res.status !== 200)
                setSnackbarData({
                    ...snackbarData,
                    isActive: true,
                    message: someThingWentWrongMsg
                });
        }
    };

    const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        setEmailPreferenceState((prev) => ({ ...prev, [name]: checked }));

        const res = await editUserPreferencesMutation({
            email_body: emailPreferenceState.message,
            is_pdf_attached: checked,
            autoselection: userPreferences?.data.autoselection,
            autoselection_user_group: userPreferences?.data.autoselection_user_group
        });

        if (res.status !== 200)
            setSnackbarData({
                ...snackbarData,
                isActive: true,
                message: someThingWentWrongMsg
            });
    };

    return (
        <Box mt={2} mb={4}>
            <H5>Email Preferences</H5>
            <BodySmall color="text.secondary">To share documents from Parspec, connect to your email provider below.</BodySmall>
            <Box mt={6} width="50%">
                <SyncAccount emailService={emailService} updateEmailService={updateEmailService} />
            </Box>
            <Box mt={6}>
                <BodyMedium fontWeight={600}>Email Template</BodyMedium>
            </Box>
            {!isLoading ? (
                <RichTextEditor
                    initialHtml={
                        userPreferences?.data.email_body && userPreferences?.data.email_body !== '<p class="richTextParagraph"><br></p>'
                            ? userPreferences?.data.email_body
                            : '<p class="richTextParagraph" ><span style="white-space: pre-wrap;">Hello,</span></p><p class="richTextParagraph"><br></p><p class="richTextParagraph" ><span style="white-space: pre-wrap;">You can access this document and its previous versions on this shareable link: </span><a href="https://" target="_blank" title="shearableLink" class="cursor-pointer"><span style="white-space: pre-wrap;">Shareable Link</span></a></p>'
                    }
                    onBlur={onTextChange}
                    contentEditableHeight="180px"
                    showAttachements={false}
                    showShareableLinkButton={true}
                    shareableLinkTitle={SHAREABLE_LINK_TEXT}
                    isDisable={isDisabled}
                />
            ) : (
                <Skeleton variant="rectangular" height="230px" />
            )}
            <Box mt={6}>
                {!isLoading ? (
                    <Switch
                        color="primary"
                        label={<BodyMedium fontWeight={600}>Include PDF Attachment</BodyMedium>}
                        name="includePdf"
                        onChange={handleToggle}
                        checked={emailPreferenceState.includePdf}
                    />
                ) : (
                    <Skeleton variant="rectangular" height="32px" />
                )}
            </Box>
        </Box>
    );
};

export default EmailPreferences;
