import { axios } from 'src/app/Axios';
import {
    AddSuggestionRequest,
    AddSuggestionResponse,
    AlternatesDetailsResponse,
    DocumentApiParams,
    SelectAlternatesProps,
    SelectDataRequest,
    SearchRefreshResultResponse,
    ProductSelectionHistoryRequest,
    FeedbackDataRequest,
    FeedbackDataResponse
} from './apiTypes';

export const endPoints = {
    alternatesDetails: (bomId: string, bodId: string) => `/bom/${bomId}/product_finder/${bodId}/search_results`,
    addDocument: ({ bomId, bodId, actionType }: DocumentApiParams) => `/bom/${bomId}/product_finder/${bodId}/selected_datasheet/bulk?source=${actionType}`,
    addselectAlternate: ({ bomId, productId }: SelectAlternatesProps) => `/bom/${bomId}/product_finder/${productId}/selected_datasheet/`,
    searchDetails: (bomId: string, bodId: string) => `/bom/${bomId}/product_finder/${bodId}/search?refresh=true`,
    productSearchHistory: (bomId: string, bodId: string) => `/bom/${bomId}/product_finder/${bodId}/selection_history`,
    addFeedback: ({ bomId, productId }: SelectAlternatesProps) => `/bom/${bomId}/product_finder/${productId}/search_feedback/`
};

export const getAlternatesDataSheet = async (bomId: string, bodId: string) => {
    return axios.get<AlternatesDetailsResponse>(endPoints.alternatesDetails(bomId, bodId));
};

export const getSearchResult = async (bomId: string, bodId: string) => {
    return axios.post<SearchRefreshResultResponse>(endPoints.searchDetails(bomId, bodId));
};

export const fetchProductSearchHistory = async (data: ProductSelectionHistoryRequest) => {
    const { bomId, bodId, input } = data;
    return axios.post(endPoints.productSearchHistory(bomId, bodId), input);
};

export const addSuggestion = async (data: AddSuggestionRequest) => {
    const { bomId, bodId, input, actionType } = data;
    return axios.post<AddSuggestionResponse>(endPoints.addDocument({ bomId, bodId, actionType }), actionType === 'file_upload' ? input : { ...input });
};

export const addselectAlternates = async (data: SelectDataRequest) => {
    const { bomId, productId, input } = data;
    return axios.post<AddSuggestionResponse>(endPoints.addselectAlternate({ bomId, productId }), input);
};

export const fetchProductFeedback = async (data: FeedbackDataRequest) => {
    const { bomId, productId, payload } = data;
    return axios.post<FeedbackDataResponse>(endPoints.addFeedback({ bomId, productId }), payload);
};
