import { useEffect, useState } from 'react';

import { Grid, Select, TextField, Switch, SelectChangeEvent, Box } from '@parspec/pixel';

import ApplyToAllBomsButton from 'src/features/BOM/FinalDocuments/Compile/shared/ApplyToAllBomsButton';

import { useQuoteContext } from '../../compileQuote/QuoteContext';
import { ERROR_KEYS } from '../../constants';

export interface ITokenForFieldsProps {
    options: { id: string; name: string }[];
    fieldValue?: string;
    handleFieldGenerationRule: (val: SelectChangeEvent<unknown>) => void;
    selectedOption: string;
    handleOnBlur?: (val: string) => void;
    onTextFieldFocus?: () => void;
    maxLength?: number;
    label?: string;
    showTextAndInput: boolean;
    errorType?: ERROR_KEYS;
    isToggled?: boolean;
    toggleLabel?: string;
    onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    toggleName?: string;
    sectionName?: string;
}
const TokenForFields: React.FC<ITokenForFieldsProps> = ({
    options,
    fieldValue,
    handleFieldGenerationRule,
    selectedOption,
    handleOnBlur,
    onTextFieldFocus,
    maxLength,
    label,
    showTextAndInput,
    errorType,
    isToggled = true,
    toggleLabel = '',
    toggleName = '',
    onToggle = () => {},
    sectionName
}) => {
    const { handlePdfPreview, handleTextFieldChanged, error, handleError } = useQuoteContext();

    const [preFillFormat, setPreFillFormat] = useState(selectedOption);

    const [customEntry, setCustomEntry] = useState('');

    useEffect(() => {
        if (fieldValue == null) {
            return;
        }
        setCustomEntry(fieldValue);
    }, [fieldValue]);

    useEffect(() => {
        if (!selectedOption) {
            return;
        }
        setPreFillFormat(selectedOption);
    }, [selectedOption]);

    const onSelectOptionChanged = (event: SelectChangeEvent<unknown>) => {
        handleFieldGenerationRule(event);
        setPreFillFormat(event.target.value as string);
    };

    const onCustomEntryChanged = (event: any) => {
        if (errorType) {
            handleError(errorType, '');
        }
        setCustomEntry(event.target.value);
        handlePdfPreview(false);
        handleTextFieldChanged(true);
    };

    const onTextFieldBlur = (event: any) => {
        if (handleOnBlur) {
            handleOnBlur(event.target.value);
            setCustomEntry(event.target.value);
        }
    };
    return (
        <Grid container justifyContent={'space-between'}>
            <Grid container item xs={4}>
                <Switch name={toggleName} checked={isToggled} label={toggleLabel} onChange={onToggle} />
            </Grid>
            <Grid container item xs={8} columnSpacing={2}>
                <Grid item xs={4}>
                    <Select
                        label={!showTextAndInput ? label : 'Pre-fill Format'}
                        options={options}
                        optionLabelKeyname="name"
                        optionValueKeyname="id"
                        onChange={onSelectOptionChanged}
                        value={preFillFormat}
                    />
                </Grid>

                <Grid item xs={8}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            disabled={preFillFormat !== 'Custom Entry'}
                            inputProps={{
                                maxLength
                            }}
                            label=""
                            value={customEntry}
                            onChange={onCustomEntryChanged}
                            onBlur={onTextFieldBlur}
                            onFocus={onTextFieldFocus}
                            error={errorType ? Boolean(error[errorType]) : false}
                            helperText={errorType ? error[errorType] : ''}
                        />
                        <ApplyToAllBomsButton sectionName={sectionName} subSectionName={toggleName} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TokenForFields;
