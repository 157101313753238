import { BodyMedium, Box, H5, PlagiarismOutlinedIcon } from '@parspec/pixel';

export default function SearchSection() {
    return (
        <Box width="100%" height="100%" bgcolor="neutral.main" display="flex" justifyContent="center" alignItems="center" p={10} flexDirection="column">
            <PlagiarismOutlinedIcon color="secondary" sx={{ fontSize: '112px' }} />
            <Box mt={6} textAlign="center">
                <H5>No Source Datasheet Selected</H5>
                <BodyMedium limit={false} mb={6} width="401px">
                    Select a source datasheet to pre-fill the attributes and see a preview of the documents.
                </BodyMedium>
            </Box>
        </Box>
    );
}
