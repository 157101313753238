import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Box, Skeleton } from '@parspec/pixel';

import { useResetPasswordMailTokenValidationQuery } from '../queries';
import { ResetPasswordForm } from './ResetPasswordForm';
import { LinkExpired } from '../shared/components/LinkExpired';

export const ResetPassword = () => {
    const { token } = useParams();

    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

    const { data: getResetPasswordValidation, isLoading: isTokenValidLoading } = useResetPasswordMailTokenValidationQuery(token || '');

    useEffect(() => {
        if (!getResetPasswordValidation) {
            return;
        }

        if (getResetPasswordValidation.data.auth) {
            setIsTokenValid(true);
        }
    }, [getResetPasswordValidation]);

    return (
        <Box
            width="100vw"
            minHeight="100vh"
            py={8}
            bgcolor="#091535"
            sx={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(assets/images/login/login-bg.png)`
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" mb={14}>
                    <Box height="45px" width="45px" mr={3}>
                        <img src={'assets/images/login/vector.png'} alt="parspec-logo" />
                    </Box>

                    <Box>
                        <img src={'assets/images/login/parspec.png'} alt="parspec-text" vertical-align="middle" />
                    </Box>
                </Box>

                {!isTokenValidLoading ? (
                    <>{isTokenValid ? <ResetPasswordForm token={token || ''} /> : <LinkExpired />}</>
                ) : (
                    <Skeleton variant="rectangular" color="light" width="337px" height="427px" />
                )}
            </Box>
        </Box>
    );
};
