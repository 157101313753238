import { MouseEvent, useMemo } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { StarIcon, Box, Tooltip } from '@parspec/pixel';

import { usePatchMfgListMutation, useGetListOfManufactuerListQuery } from '../../../queries';

const DISABLED_STAR_MESSAGE = 'Favorite this list to make it your default for product finder searches';
const ENABLED_STAR_MESSAGE = 'This is currently your default list for product finder searches';

function StarMfList(params: ICellRendererParams) {
    const {
        data: { id }
    } = params;

    const { data: manufacturerList } = useGetListOfManufactuerListQuery();
    const { mutateAsync } = usePatchMfgListMutation(false);

    const filteredManufacturer = useMemo(() => {
        if (manufacturerList?.data) {
            return manufacturerList.data.find((item) => item.id === id);
        }

        return null;
    }, [manufacturerList?.data]);

    const handleColorChange = async (e: MouseEvent) => {
        e.preventDefault();
        if (filteredManufacturer) {
            await mutateAsync({
                mfgListId: id,
                payload: {
                    is_starred: filteredManufacturer.is_starred ? false : true,
                    is_locked: filteredManufacturer.is_locked,
                    name: filteredManufacturer.name,
                    description: filteredManufacturer.description,
                    company_group_man_id: filteredManufacturer.company_group_man_id,
                    visibility: filteredManufacturer.visibility,
                    company_id: filteredManufacturer.company_id,
                    permission: filteredManufacturer.permission,
                    company_group_id: filteredManufacturer.company_group_id
                }
            });
        }
    };
    return (
        <Box>
            <Tooltip title={filteredManufacturer?.is_starred ? ENABLED_STAR_MESSAGE : DISABLED_STAR_MESSAGE}>
                <StarIcon onClick={handleColorChange} color={filteredManufacturer?.is_starred ? 'tertiary' : 'neutral'} />
            </Tooltip>
        </Box>
    );
}

export default StarMfList;
