import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { Box, CircularProgress, Tabs } from '@parspec/pixel';

import { TAB_OPTIONS, TAB_OPTIONS_WITHOUT_QUOTE } from './shared/constants';
import { useEffect, useRef, useState } from 'react';
import { useSubscriptionInfo } from '../Settings/PlanAndPayment/queries';
import InactiveAccountBanner from './InactiveAccountBanner';
import FewCreditsLeftBanner from './FewCreditsLeftBanner';
import PaymentFailedBanner from './PaymentFailedBanner';
import { setRouteToLocalStorage } from '../shared/utils/utils';
import { useFeatureFlag } from '../shared/UserActivityTracking/useFeatureFlag';
import { useUserEventCount } from '../shared/UserActivityTracking/TrackActivityCount';
import { useGetUserProfileInfoQuery } from '../Settings/MyProfile/queries';

const TabsContent: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tableContainerRef = useRef<any>();
    const [fewCreditsRemainingBanner, setFewCreditsRemainingBanner] = useState<boolean>(false);
    const [showNoActiveSubscriptionBanner, setShowNoActiveSubscriptionBanner] = useState<boolean>(false);
    const [showPaymentFailedBanner, setShowPaymentFailedBanner] = useState<boolean>(false);

    const { data: paymentDetail, isFetching } = useSubscriptionInfo();
    const { data: userProfile } = useGetUserProfileInfoQuery();

    const { enable_quoting } = useFeatureFlag();
    const { pushUserEventCount } = useUserEventCount();

    const handleTabChange = (tabValue: string) => {
        setRouteToLocalStorage(tabValue);
        navigate(tabValue);
    };
    const onCloseFewCreditsBanner = () => {
        setFewCreditsRemainingBanner((old) => !old);
    };
    const onCloseInactiveAccount = () => {
        setShowNoActiveSubscriptionBanner((old) => !old);
    };
    const onClosePaymentFailedBanner = () => {
        setShowPaymentFailedBanner((old) => !old);
    };

    useEffect(() => {
        if (paymentDetail?.data) {
            if (paymentDetail.data.my_plan.plan != 'Free' && paymentDetail.data.my_plan.plan != 'Enterprise' && !paymentDetail.data.payment_failed) {
                const totalCredits: number = Number(paymentDetail.data.my_plan.submittals);
                const remainingCredits: number = paymentDetail.data.submittal_credit_left;
                const percentageOfUsedCredits = ((totalCredits - remainingCredits) / totalCredits) * 100;

                if (percentageOfUsedCredits >= 90) {
                    setFewCreditsRemainingBanner(() => true);
                }
            }
            if (!paymentDetail.data.active) {
                setShowNoActiveSubscriptionBanner(true);
            }
            if (paymentDetail.data.payment_failed) {
                setShowPaymentFailedBanner(true);
            }
        }
    }, [paymentDetail?.data]);

    useEffect(() => {
        const isSSOLogin = Boolean(localStorage.getItem('ssoLogin'));
        if (isSSOLogin && userProfile?.data) {
            pushUserEventCount({ eventType: 'sso_login', needCummulation: false });
            localStorage.removeItem('ssoLogin');
        }
    }, [userProfile?.data]);

    return (
        <Box display="flex" flexDirection="column" p={4} flex={1} minHeight={0}>
            {showNoActiveSubscriptionBanner && <InactiveAccountBanner onClose={onCloseInactiveAccount} />}
            {!showNoActiveSubscriptionBanner && showPaymentFailedBanner && <PaymentFailedBanner onClose={onClosePaymentFailedBanner} />}
            {!showNoActiveSubscriptionBanner && !showPaymentFailedBanner && fewCreditsRemainingBanner && <FewCreditsLeftBanner onClose={onCloseFewCreditsBanner} />}
            <Box>
                {enable_quoting && <Tabs onChange={handleTabChange} options={TAB_OPTIONS} selectedTab={pathname} />}

                {!enable_quoting && <Tabs onChange={handleTabChange} options={TAB_OPTIONS_WITHOUT_QUOTE} selectedTab={pathname} />}
            </Box>

            <Box mt={2} flex={1} ref={tableContainerRef} minHeight={0}>
                {isFetching ? (
                    <Box display="flex" alignItems="center" justifyContent="center" height={1}>
                        <CircularProgress color="primary" size="xxl" />
                    </Box>
                ) : (
                    <Outlet />
                )}
            </Box>
        </Box>
    );
};

export default TabsContent;
