import { useParams } from 'react-router-dom';

import { Button, Menu, SortIcon, Tooltip } from '@parspec/pixel';

import { sortOptions } from './constants';
import { useGetBomDataQuery, useSortProductListMutation } from '../queries';
import { SortProductListRequest } from '../queries/apiTypes';
import { useTrackIntercomEvent } from '../../shared/hooks/useIntercomHook';

const SortButton = () => {
    const { bomId = '0' } = useParams();

    const { data: bomData } = useGetBomDataQuery(bomId, {
        enabled: Boolean(bomId)
    });
    const { trackIntercomEvent } = useTrackIntercomEvent();

    const { mutateAsync: sortProductList, isLoading: isSortProductListLoading } = useSortProductListMutation();

    const onSort = async (sortOn: SortProductListRequest) => {
        if (!bomId) {
            return;
        }
        trackIntercomEvent('Sort_By');
        await sortProductList({ sortOn, bomId });
    };

    return (
        <Menu options={sortOptions(onSort)}>
            <Tooltip title={bomData?.data.length ? '' : 'Add Item(s) First'}>
                <span>
                    <Button variant="outlined" color="secondary" endIcon={<SortIcon />} isLoading={isSortProductListLoading} disabled={!bomData?.data.length}>
                        Sort by
                    </Button>
                </span>
            </Tooltip>
        </Menu>
    );
};

export default SortButton;
