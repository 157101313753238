import { useEffect, useRef, useState } from 'react';

import { GraphQLClient } from 'graphql-request';

import { environment } from 'src/environments';
import { notifyToSentry } from '../SentryErrorLogger';
import { PerformanceQueryString } from '../queries/apiTypes';
import { fetchHasuraToken } from '../queries/apis';

export const graphQLClient = new GraphQLClient(environment.Q, {
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem(environment.P)
    }
});

async function getUpdatedHasuraToken() {
    const newToken = await fetchHasuraToken();
    localStorage.setItem(environment.P, newToken.data.token);
    setGraphqlHeaders();
}

function setGraphqlHeaders() {
    graphQLClient.setHeaders({
        Authorization: 'Bearer ' + localStorage.getItem(environment.P)
    });
}

export const useInitializeEventService = () => {
    const [isTokenAvailable, setTokenAvailable] = useState(false);

    useEffect(() => {
        async function checkAndUpdateToken() {
            const hasToken = Boolean(localStorage.getItem(environment.P));

            if (!hasToken) {
                await getUpdatedHasuraToken();
            }

            setTokenAvailable(Boolean(localStorage.getItem(environment.P)));
        }
        const isValidatedUser = Boolean(localStorage.getItem('token'));

        if (isValidatedUser) {
            checkAndUpdateToken();
        }
    }, []);

    return { isTokenAvailable };
};

export const pushEvent = async (props: { insertMutation: string; updateMutation: string | null; responseAttribute: string }) => {
    const { insertMutation, updateMutation, responseAttribute } = props;

    try {
        // @ts-ignore
        if (Boolean(localStorage.getItem(environment.P)) && graphQLClient.requestConfig.headers?.Authorization === 'Bearer null') {
            setGraphqlHeaders();
        }
        const mutationRequest = async (query: any, variables: any) => {
            try {
                return await graphQLClient.request<any>(query, variables);
            } catch (e: any) {
                // Check if the error is due to an expired JWT token
                if (e.response.errors.some((err: any) => err.message.toLowerCase().includes('JWTExpired'))) {
                    await getUpdatedHasuraToken(); // Refresh the token
                    setGraphqlHeaders(); // Update the headers in the GraphQL client
                    return await graphQLClient.request<any>(query, variables); // Retry the request
                } else {
                    throw e; // If the error is not related to token expiration, rethrow it
                }
            }
        };
        if (updateMutation) {
            const result = await mutationRequest(updateMutation, {});
            if (result?.['events_db']?.['update_' + responseAttribute]?.affected_rows === 0) {
                return mutationRequest(insertMutation, {});
            }
            // This entire if will be remoed.
            if (result?.['logging_db']?.['update_' + responseAttribute]?.affected_rows === 0) {
                return mutationRequest(insertMutation, {});
            }

            return Promise.resolve(result);
        } else {
            return mutationRequest(insertMutation, {});
        }
    } catch (e: any) {
        const slackMessage = `URL: ${e.request?.query}\nToken: ${JSON.stringify(graphQLClient.requestConfig.headers)}\nUpdate Query:${updateMutation}\nError: ${JSON.stringify(e.response.errors)}`;
        notifyToSentry(slackMessage);
    }
};
export const getEvent = async (props: { getQuery: string; responseAttribute: string }) => {
    const { getQuery, responseAttribute } = props;
    try {
        // @ts-ignore
        if (Boolean(localStorage.getItem(environment.P)) && graphQLClient.requestConfig.headers?.Authorization === 'Bearer null') {
            setGraphqlHeaders();
        }
        const result = await graphQLClient.request<any>(getQuery, {});
        return Promise.resolve(result?.[responseAttribute]);
    } catch (e: any) {
        // Check if the error is due to an expired JWT token
        if (e.response.errors.some((err: any) => err.message.includes('JWTExpired'))) {
            await getUpdatedHasuraToken(); // Refresh the token
            setGraphqlHeaders(); // Update the headers in the GraphQL client
            const result = await graphQLClient.request<any>(getQuery, {});
            return Promise.resolve(result?.[responseAttribute]);
        } else {
            const slackMessage = `URL: ${e.request.query}\nGraphQL Token: ${JSON.stringify(graphQLClient.requestConfig.headers)}\nLocal Token: ${localStorage.getItem(
                environment.P
            )}\nError: ${JSON.stringify(e.response.errors)}`;
            notifyToSentry(slackMessage);
            throw e; // If the error is not related to token expiration, rethrow it
        }
    }
};

export const useIntervalForPerformance = () => {
    const timerId = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const intervalId = setInterval(bulkPushEvent, 900000);
        timerId.current = intervalId;

        return () => {
            if (timerId.current) {
                clearInterval(timerId.current);
            }
        };
    }, []);

    const bulkPushEvent = () => {
        const apiList = localStorage.getItem('fe_event_service_performance_api_list');
        if (apiList) {
            localStorage.removeItem('fe_event_service_performance_api_list');
            const jsonString =
                '[' +
                JSON.parse(apiList)
                    .map((obj: PerformanceQueryString) => {
                        return (
                            '{' +
                            Object.entries(obj)
                                .map(([key, value]) => key + ':' + value)
                                .join(',') +
                            '}'
                        );
                    })
                    .join(',') +
                ']';

            const query = `mutation {
                events_db{
                insert_app_performance_v2(objects: ${jsonString}) {
                  affected_rows
                }}
              }`;
            pushEvent({
                insertMutation: query,
                updateMutation: null,
                responseAttribute: 'insert_app_performance_v2'
            });
        }
    };
};
