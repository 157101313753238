import JSZip from 'jszip';
import generatorRows from './scripts/generatorRows';

import workbookXML from './statics/workbook.xml';
import workbookXMLRels from './statics/workbook.xml.rels';
import rels from './statics/rels';
import contentTypes from './statics/[Content_Types].xml';
import templateSheet from './templates/worksheet.xml';

export const generateXMLWorksheet = (rows: any) => {
    const XMLRows = generatorRows(rows);
    return templateSheet.replace('{placeholder}', XMLRows);
};

export default async (config: any) => {
    const zip = new JSZip();
    const xl = zip.folder('xl');
    xl?.file('workbook.xml', workbookXML);
    xl?.file('_rels/workbook.xml.rels', workbookXMLRels);
    zip.file('_rels/.rels', rels);
    zip.file('[Content_Types].xml', contentTypes);

    const worksheet = generateXMLWorksheet(config.sheet.data);
    xl?.file('worksheets/sheet1.xml', worksheet);

    const blob = await zip
        .generateAsync({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        .then((blob) => {
            return blob;
        });

    return blob;
};
