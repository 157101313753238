import React, { FC, useEffect, useState } from 'react';

import { Autocomplete, BodyXS, Box } from '@parspec/pixel';

import { CompanyObjType } from '../Company/queries/apiTypes';
import { useGetCompanyDropDownQuery } from 'src/features/shared/queries';

interface CompanySelectorProps {
    companyInfo: any;
    setCompanyInfo: (info: any) => void;
    companyError?: string;
    isCompanySelectorDisabled?: boolean;
}

const CompanySelector: FC<CompanySelectorProps> = ({ companyInfo, setCompanyInfo, companyError, isCompanySelectorDisabled }) => {
    const [companiesData, setCompaniesData] = useState<CompanyObjType[]>([]);

    const { data: companyApiData, isFetching } = useGetCompanyDropDownQuery();

    useEffect(() => {
        if (companyApiData?.data) {
            const sortedArr = companyApiData?.data.sort((a: CompanyObjType, b: CompanyObjType) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setCompaniesData(sortedArr);
        }
    }, [companyApiData]);

    return (
        <Box width={1}>
            <Autocomplete
                optionlabelkeyname="name"
                options={companiesData.map((item) => ({ id: item.id, name: item.name, business_type: item.business_type })) || []}
                value={companyInfo || null}
                onChange={(e: any) => setCompanyInfo(e.target.value)}
                label="Company*"
                fieldSize="small"
                error={Boolean(companyError)}
                loading={isFetching}
                disabled={isCompanySelectorDisabled}
            />
            {companyError && (
                <Box mt={1}>
                    <BodyXS color={'error.main'} sx={{ fontSize: '10px' }}>
                        {companyError}
                    </BodyXS>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(CompanySelector);
