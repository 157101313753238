import { AxiosResponse } from 'axios';

export const transformToggleConfigurationData = (data: AxiosResponse) => {
    const result: any = {};

    for (const item of data.data) {
        result[item['field_name']] = item['is_active'];
    }

    return result;
};

export const transformCompileDocumentResponse = (data: AxiosResponse) => {
    const result: any = {};

    for (const item of data.data) {
        result[item['field_name']] = item.field_value;
    }

    return result;
};

export const transformDocumentPreset = (data: AxiosResponse) => {
    const result: any = {};
    for (const item of data.data) {
        result[item['field_name']] = { field_value: item.field_value, is_active: item.is_active };
    }

    return result;
};
