import { useEffect, useState } from 'react';

const channel = new BroadcastChannel('my-channel');

export enum BROADCAST_MESSAGES {
    INVALIDATE_COMPANIES = 'invalidate_companies',
    INVALIDATE_CONTACTS = 'invalidate_contacts'
}

const useBroadCaster = () => {
    const [message, setMessage] = useState('');

    const postMessage = (message: string) => {
        channel.postMessage(message);
    };

    useEffect(() => {
        const handleMessage = (event: any) => {
            setMessage(event.data);
        };
        channel.addEventListener('message', handleMessage);

        return () => {
            setMessage('');
            channel.removeEventListener('message', handleMessage);
        };
    }, []);

    return { postMessage, message };
};

export default useBroadCaster;
