import { FC, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { BodySmall, Box, CircularProgress, Snackbar, TextField } from '@parspec/pixel';

import { useAddMultipeBodsMutation } from '../queries';
import { CORRECT_NUMBER_MSG, ROWS_ADDED_MSG, someThingWentWrongMsg } from '../../shared/constants';

interface AddMultipleRowsProps {
    sectionId: number;
    isOptionTable: boolean;
}

const AddMultipleRows: FC<AddMultipleRowsProps> = ({ sectionId, isOptionTable }) => {
    const { bomId } = useParams();
    const [rows, setRows] = useState(10);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const inputRef = useRef<HTMLInputElement>();

    const { mutateAsync: addMultipleRows, isLoading } = useAddMultipeBodsMutation();

    const handleChangeRows = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) || value === '') {
            if (Number(value) > 100) {
                setRows(100);
            } else setRows(Number(value));
        }
    };

    const handlePressEnter = (e: any) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (rows && Number(rows) !== 0) {
            const request = {
                bomId: String(bomId || 0),
                section_id: isOptionTable ? null : sectionId,
                total_bods: Number(rows),
                substitute_section_id: isOptionTable ? sectionId : null
            };
            try {
                await addMultipleRows(request);
                setSnackbarMessage(ROWS_ADDED_MSG);
                inputRef?.current?.blur();
            } catch (e) {
                setSnackbarMessage(someThingWentWrongMsg);
            }
        } else {
            setSnackbarMessage(CORRECT_NUMBER_MSG);
        }
    };

    return (
        <Box position="relative">
            {isLoading && (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" position="absolute" sx={{ bgcolor: 'rgba(255,255,255,0.5)' }} zIndex={200}>
                    <CircularProgress color="primary" size={'xxl'} />
                </Box>
            )}
            <Snackbar open={Boolean(snackbarMessage)} onClose={() => setSnackbarMessage('')} message={snackbarMessage} />
            <Box px={4} py={2} bgcolor="neutral.light" border="1px solid" borderColor="neutral.main" display="flex" alignItems="center">
                <Box onClick={handleSubmit}>
                    <BodySmall color="primary" sx={{ cursor: 'pointer' }}>
                        Add
                    </BodySmall>
                </Box>
                <Box width={55} ml={4}>
                    <TextField
                        value={rows}
                        onChange={handleChangeRows}
                        onKeyDown={handlePressEnter}
                        label=""
                        inputProps={{ min: 1, max: 100 }}
                        size="small"
                        inputRef={inputRef}
                        sx={{ bgcolor: 'secondary.contrastText' }}
                    />
                </Box>
                <Box ml={2}>
                    <BodySmall>New Line(s)</BodySmall>
                </Box>
            </Box>
        </Box>
    );
};

export default AddMultipleRows;
