import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { getCompanyDropdownList, getContactDropdownList, getFeatureFlagQuery, getMaintenanceData, getSelectedFilters, updateSelectedFilters } from './apis';
import { queryClient } from 'src/app/queryClient';
import { FiltersType } from 'src/features/BidBoard/shared/types';
import { getDeploymentInfo } from './apis';

//Using 'any' because this is graphQL response instead of AxiosResponse which doesn't have 'data' attribute
export const useGetFeatureFlagQuery = (email?: string, company_group_id?: number, options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) => {
    return useQuery(['feature-flag'], () => getFeatureFlagQuery(email, company_group_id), options);
};

export const useGetMaintenanceDataQuery = () => useQuery(['maintenanceKey'], () => getMaintenanceData(), { refetchOnWindowFocus: 'always' });

const perisistedFiltersApiKey = (pageName: string) => ['filters', pageName];

export const useGetPersistedFiltersQuery = ({ userId, pageName }: any, options: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) =>
    useQuery(perisistedFiltersApiKey(pageName), () => getSelectedFilters(userId, pageName), options);

export const useGetCompanyDropDownQuery = () => useQuery(['company_dropdown'], () => getCompanyDropdownList());
export const useGetContactDropDownQuery = () => useQuery(['contact_dropdown'], () => getContactDropdownList());

export const invalidateCompanyDropdownQuery = () => {
    queryClient.invalidateQueries(['company_dropdown']);
};
export const invalidateContactDropdownQuery = () => {
    queryClient.invalidateQueries(['contact_dropdown']);
};

export const usePersistedFiltersMutation = () =>
    useMutation(updateSelectedFilters, {
        onMutate: (variables) => {
            queryClient.setQueryData(perisistedFiltersApiKey(variables.pageName), (oldData?: { table_settings_by_pk: { filters: FiltersType } }) => {
                return {
                    ...(oldData || {}),
                    table_settings_by_pk: { filters: variables.filters }
                };
            });
        }
    });

export const useGetDeploymentInfoQuery = (options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>) => {
    return useQuery(['deployment-info'], () => getDeploymentInfo(), options);
};
