import { useState, useEffect, useCallback } from 'react';

import { Grid, SelectChangeEvent } from '@parspec/pixel';

import Section from '../../../shared/Section';
import { validateEmail } from 'src/features/Settings/UserManagement/utils/utils';
import { useQuoteContext } from '../../QuoteContext';
import { useCoverPageDataQuery, useCoverPageTextMutation, useCoverPageToggleMutation } from '../../queries';
import CircularLoader from '../../../../../../shared/CircularLoader';
import { ISection, quoteHeaderFooterFields } from '../../../constants';
import { ADDRESSED_TO_SELECT_OPTIONS } from '../../../shared/utils/constants';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { IRenderLogoProps, SectionType } from '../../../shared/utils/helper';
import { IPrefillPayload } from '../../../shared/utils/helper';
import { getAddressToPrimaryPrefillObj } from '../../../shared/utils';

interface ICoverPageSection {
    sectionType: SectionType;
    sectionLabel: string;
    renderLogo: (props: IRenderLogoProps) => React.ReactNode;
    showAddressToPrefill?: boolean;
    sectionName?: string;
}

function CoverPageSection({ sectionType, sectionLabel, renderLogo, showAddressToPrefill, sectionName }: ICoverPageSection) {
    const { handlePdfPreview, isInitiateApiCalls, isTextFieldChanged, handleTextFieldChanged, bomId, handleUpdateChangedSections } = useQuoteContext();

    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [sectionState, setSectionState] = useState<ISection>({
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: ''
    });
    const [addressToPrefill, setAddressToPrefill] = useState('');

    const { data: coverPageData, isLoading: isCoverPageLoading } = useCoverPageDataQuery(bomId, isInitiateApiCalls);
    const { data: bomData } = useGetBomDetails(bomId, {
        enabled: Boolean(bomId) && isInitiateApiCalls
    });

    const { mutateAsync: textMutation } = useCoverPageTextMutation();
    const { mutateAsync: toggleMutation } = useCoverPageToggleMutation();

    useEffect(() => {
        if (coverPageData && sectionType === quoteHeaderFooterFields.addressedTo) {
            if (coverPageData[sectionType].field_value?.pre_fill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact) {
                setSectionState({ ...getAddressToPrimaryPrefillObj(bomData?.data?.primary_contact) });
            } else {
                setSectionState(coverPageData[sectionType].field_value.value);
            }
            setAddressToPrefill(coverPageData[sectionType].field_value.pre_fill);
        } else if (coverPageData) {
            setSectionState(coverPageData[sectionType].field_value);
        }
    }, [isCoverPageLoading, bomData?.data?.primary_contact]);

    const pushChangedFields = (fieldName: string) => {
        if (sectionName) {
            handleUpdateChangedSections?.({ sectionName: sectionName, subSectionName: fieldName, value: true });
        }
    };

    const handleOnTextChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (coverPageData) {
                const { name, value } = event.target;
                if (name === 'email') {
                    setIsEmailInvalid(false);
                }

                if (name === 'country') {
                    setSectionState({ ...sectionState, [name]: value, state: '' });
                } else {
                    setSectionState({ ...sectionState, [name]: value });
                }

                handlePdfPreview(false);
                handleTextFieldChanged(true);
            }
        },
        [coverPageData, sectionState]
    );

    const handleOnBlur = useCallback(async () => {
        if (sectionState && isTextFieldChanged) {
            if (sectionState.email && !validateEmail(sectionState.email)) {
                setIsEmailInvalid(true);
                return;
            }

            const payload: IPrefillPayload = {
                pre_fill: addressToPrefill,
                value: { ...sectionState }
            };

            await textMutation({
                bomId: bomId,
                fieldName: sectionType,
                field_value: sectionType === quoteHeaderFooterFields.addressedTo ? payload : payload.value
            });
            handlePdfPreview(true);
            pushChangedFields(sectionType);
        }
    }, [sectionState, isTextFieldChanged]);

    const handleOnFocus = useCallback(() => {
        handleTextFieldChanged(false);
    }, []);

    const handleOnSwitchToggle = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        await toggleMutation({
            bomId: bomId,
            fieldName: sectionType,
            is_active: event.target.checked
        });
        handlePdfPreview(true);
        pushChangedFields(sectionType);
    }, []);

    const handleAddressToPrefill = useCallback(
        async (event: SelectChangeEvent<unknown>) => {
            const { value, name } = event.target;

            const payload: IPrefillPayload = {
                pre_fill: value as string,
                value: { ...sectionState }
            };
            if (value === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact) {
                payload.value = getAddressToPrimaryPrefillObj(bomData.data.primary_contact);
            }
            setSectionState(payload.value);
            setAddressToPrefill(event.target.value as string);
            await textMutation({
                bomId,
                fieldName: name,
                field_value: payload
            });
            handlePdfPreview(true);
            pushChangedFields(sectionType);
        },
        [bomData?.data?.primary_contact, sectionState]
    );

    const companyLogo =
        addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER && bomData?.data?.primary_contact?.company?.logo
            ? { filename: 'Primary Customer.png', filepath: bomData?.data?.primary_contact?.company?.logo as unknown as string }
            : null;

    if (isCoverPageLoading) {
        return <CircularLoader />;
    }

    return (
        <>
            {coverPageData && (
                <Section
                    readOnly={addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER}
                    onTextChange={(e) => handleOnTextChange(e as React.ChangeEvent<HTMLInputElement>)}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    isChecked={coverPageData[sectionType].is_active}
                    onSwitchToggle={handleOnSwitchToggle}
                    switchLabel={sectionLabel}
                    isEmailInvalid={isEmailInvalid}
                    sectionState={sectionState}
                    onSelect={handleAddressToPrefill}
                    prefillValue={addressToPrefill}
                    showAddressToPrefill={showAddressToPrefill}
                    sectionName={sectionName}
                    subSectionName={sectionType}
                >
                    <Grid item mt={2}>
                        {renderLogo({
                            sectionType,
                            sectionLabel,
                            showAddressToPrefill,
                            companyLogo,
                            disabled: addressToPrefill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER
                        })}
                    </Grid>
                </Section>
            )}
        </>
    );
}

export default CoverPageSection;
