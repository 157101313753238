export const bomScheduleResponse = {
  id: 123,
  primary_contact: 'djdfn',
  secondary_contact: 'ldnlnf',
  name: '',
  project_id: 1234,
  is_deleted: false,
  is_submittal_out_of_sync: false,
  is_o_m_out_of_sync: false,
  is_quote_out_of_sync: false,
  created_at: '2023-01-01',
  updated_at: '2023-01-01',
  uploaded_doc: {
    id: 37273,
    filename: 'hash_example1_slight_chan.pdf',
    file_info:
      'https://parspec-product-url.s3.amazonaws.com/media/submittals/37273/hash_example1_slight_chan.pdf',
    bound_info: '',
    ocr_extracted_info: '',
    user_reviewed_info: '',
    status: 'pending',
    filetype: '.pdf',
    file_format: 'pdf',
    is_deleted: false,
    bom_id: 24126,
    type: 'schedule',
    search_status: null,
    abbreviation_replace_flag: false,
  },
};

export const bomAggregatorResponse = {
  id: 123,
  primary_contact: 'djdfn',
  secondary_contact: 'ldnlnf',
  name: '',
  project_id: 1234,
  is_deleted: false,
  is_submittal_out_of_sync: false,
  is_o_m_out_of_sync: false,
  is_quote_out_of_sync: false,
  created_at: '2023-01-01',
  updated_at: '2023-01-01',
  uploaded_doc: {
    id: 1588,
    filename: 'Submittal_26th_october_su.pdf',
    file_info:
      'https://parspec-product-url.s3.us-east-2.amazonaws.com/media/cas/fc9a7118081e6892e10e70431ad61136f833ab6359e4d59bba8d261f1fe2283f.pdf',
    processed_submittal_package_link: null,
    flat_processed_submittal_package_link: null,
    parsed_submittal_data_link: null,
    predicted_range_excel_data_link: null,
    ai_predicted_result_data_link: null,
    hidden_pages: [],
    bound_info: null,
    datasheet_bound_info: '<?xml version="1.0" encoding="UTF-8"?><xfdf></xfdf>',
    error_message: null,
    parsing_status: null,
    prediction_status: null,
    num_pages: 31,
    is_deleted: false,
    bom_id: 24185,
    type: 'submittal_package',
    abbreviation_replace_flag: false,
  },
};

export const bomManualResponse = {
  id: 123,
  primary_contact: 'djdfn',
  secondary_contact: 'ldnlnf',
  name: '',
  project_id: 1234,
  is_deleted: false,
  is_submittal_out_of_sync: false,
  is_o_m_out_of_sync: false,
  is_quote_out_of_sync: false,
  created_at: '2023-01-01',
  updated_at: '2023-01-01',
  uploaded_doc: {
    id: 1588,
    filename: 'Submittal_26th_october_su.pdf',
    file_info:
      'https://parspec-product-url.s3.us-east-2.amazonaws.com/media/cas/fc9a7118081e6892e10e70431ad61136f833ab6359e4d59bba8d261f1fe2283f.pdf',
    processed_submittal_package_link: null,
    flat_processed_submittal_package_link: null,
    parsed_submittal_data_link: null,
    predicted_range_excel_data_link: null,
    ai_predicted_result_data_link: null,
    hidden_pages: [],
    bound_info: null,
    datasheet_bound_info: '<?xml version="1.0" encoding="UTF-8"?><xfdf></xfdf>',
    error_message: null,
    parsing_status: null,
    prediction_status: null,
    num_pages: 31,
    is_deleted: false,
    bom_id: 24185,
    type: 'manual',
    abbreviation_replace_flag: false,
  },
};
