import { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';

import { BodySmall, BodyXS, Box, CustomDateTimePicker, FileSelector, Grid, Select, Skeleton, TextField, dayjs, Dayjs, StatusSelect, SelectChangeEvent, FileSelectorFileType } from '@parspec/pixel';

import { useCheckAccessForProductFinderOrQuoting } from '../hooks/useCheckAccessForProductFinderOrQuoting';

import { useGetAllLocationsListQuery, useGetAllCompanyDetailsQuery } from './queries';
import { useGetProjectDetailsQuery, useGetUserDetails, useAllTagsQuery, useGetProjectBomsQuery } from '../../Project/queries';
import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';

import {
    ACCEPTED_FILE_TYPES,
    COUNTRY_OPTIONS,
    getProjectStages,
    CONSTRUCTION_SCOPE_OPTIONS,
    CONSTRUCTION_OPTIONS,
    INITIAL_PROJECT_STAGE,
    USA_STATES,
    CANADA_STATES,
    COUNTRY,
    MAX_VALUE_SQUARE_FOOTAGE,
    PROJECT_STAGE_TRIGGER_VALUES,
    QUOTE,
    PROJECT_STAGES_LABELS_ENUM
} from '../constants';
import { getOwnerCacheListForLocation, modifiedStateList, getDocumentOptions, IProjectDetailState, IEditProjectDetail } from '../utils/utils';
import { getIfWeekendDates, FIELD_NAMES } from './utils';
import ProjectImage from './ProjectImage';
import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import UpdateProjectQuoteStatusModal from '../UpdateProjectQuoteStatusModal';
import CompanyContactSelector from '../CompanyContactSelector';
import { useFeatureFlag } from '../UserActivityTracking/useFeatureFlag';
import { convertToDollar } from '../utils/utils';
import { CustomDollarFormatterComponent } from '../CustomDollarFormatterComponent';
import { PRIORITY_OPTIONS } from '../../BidBoard/shared/constants';
import { PRIORITY_VALUES } from '../../Project/shared/utils';
import { PriorityFilterOption } from '../../BidBoard/shared/components/FilterOption';
import { UpdateQuoteDueDateModal } from '../../Project/UpdateQuoteDueDateModal';

enum FormTextfields {
    Address1 = 'address1',
    Address2 = 'address2',
    City = 'city',
    Zip = 'zip',
    Size = 'size',
    ESTIMATED_PROJECT_COST = 'estimated_project_cost'
}

const TEXT_FIELDS_OBJ = Object.fromEntries(Object.values(FormTextfields).map((item) => [item, item]));

function EditProjectDetail({
    editMode,
    setProjectStateRef,
    formErrors,
    onError,
    onBlurAction = () => {},
    isUpdatingProjectDetails = false,
    stakeholdersInfo,
    updateStakeholderInfo,
    isStakeHoldersInfoFetching,
    showOnlyProjectInfo,
    selectedProjectId
}: IEditProjectDetail) {
    const { projectId: paramsProjectId } = useParams();
    const { enable_quoting } = useFeatureFlag();
    const { quotingAccess } = useCheckAccessForProductFinderOrQuoting();
    const projectId = paramsProjectId || selectedProjectId;

    const { data: userProfile } = useGetUserProfileInfoQuery();

    const {
        isLoading: isLocationLoading,
        data: companyLocations,
        isFetching: isLocationFetching
    } = useGetAllLocationsListQuery(userProfile?.data.id || 0, {
        enabled: Boolean(userProfile?.data.id)
    });
    const { isLoading: isOwnerListLoading, data: allCompanyDetails } = useGetAllCompanyDetailsQuery();
    const { data: projectDetails, isLoading: isEditProjectLoading } = useGetProjectDetailsQuery(Number(projectId), {
        enabled: Boolean(editMode)
    });

    const [submittalOwnerData, OMOwnerData, quoteData] = useGetUserDetails(
        [projectDetails?.data?.submittal_owner || -1, projectDetails?.data.o_m_owner || -1, projectDetails?.data.quote_owner || -1],
        {
            enabled: !isEditProjectLoading && Boolean(projectDetails?.data.submittal_owner) && Boolean(projectDetails?.data.o_m_owner) && Boolean(projectDetails?.data.quote_owner)
        }
    );
    const { data: allTagsData, isLoading: isAllTagsLoading } = useAllTagsQuery();

    const { data: projectBomsData } = useGetProjectBomsQuery(Number(projectId)!, {
        enabled: Boolean(projectId),
        staleTime: 0
    });

    const isAnyQuoteInTheProject = useMemo(() => {
        let result = false;
        if (projectBomsData?.data) {
            result = projectBomsData.data.some((bom) => bom?.quote && bom.quote.length);
        }
        return result;
    }, [projectBomsData?.data]);

    const [stateListOptions, setStateListOptions] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [hasTextfieldChanged, setHasTextfieldChanged] = useState(false);
    const [selectedProjectStage, setSelectedProjectStage] = useState(INITIAL_PROJECT_STAGE as string);
    const [showQuoteDueDatePopup, setShowQuoteDueDatePopup] = useState(false);
    const [projectDetailState, setProjectDetailState] = useState<IProjectDetailState>({
        name: '',
        company: -1,
        stage: INITIAL_PROJECT_STAGE,
        quote_owner: -1,
        quote_due_date: null,
        submittal_owner: -1,
        submittal_due_date: null,
        o_m_owner: -1,
        o_m_due_date: null,
        address1: '',
        address2: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        building_type: '',
        construction_scope: '',
        size: '',
        logo: null,
        estimated_project_cost: null,
        priority: PRIORITY_VALUES.MEDIUM
    });

    const [isSubmittalOwnerDeleted, setIsSubmittalOwnerDeleted] = useState(false);
    const [isOMOwnerDeleted, setIsOMOwnerDeleted] = useState(false);
    const [isQuoteOwnerDeleted, setIsQuoteOwnerDeleted] = useState(false);
    const optionsForLocations = companyLocations?.data[0].company_details;
    const ownerCacheList = useMemo(() => getOwnerCacheListForLocation(allCompanyDetails?.data || []), [allCompanyDetails]);
    const ownerLocationList = ownerCacheList[projectDetailState.company] || [];

    const onCloseDueDatePopup = useCallback(() => setShowQuoteDueDatePopup(false), []);

    useEffect(() => {
        if (quoteData.data) {
            setIsQuoteOwnerDeleted(quoteData.data?.data[0].user_details.is_deleted);
        }
    }, [quoteData.data]);

    useEffect(() => {
        if (OMOwnerData.data) {
            setIsOMOwnerDeleted(OMOwnerData.data?.data[0].user_details.is_deleted);
        }
    }, [OMOwnerData.data]);

    useEffect(() => {
        if (submittalOwnerData.data) {
            setIsSubmittalOwnerDeleted(submittalOwnerData.data?.data[0].user_details.is_deleted);
        }
    }, [submittalOwnerData.data]);

    const handleDeleteImagePreview = useCallback(() => {
        setImagePreview(null);
        setProjectStateRef({ ...projectDetailState, logo: null });
        onBlurAction();
    }, [projectDetailState]);

    const handleOnClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        setProjectStateRef(projectDetailState);
    }, [projectDetailState]);

    useEffect(() => {
        if (companyLocations?.data && !editMode) {
            setProjectDetailState({
                ...projectDetailState,
                company: companyLocations.data[0].company_details[0].company_id,
                quote_owner: companyLocations?.data[0].user_details.id,
                submittal_owner: companyLocations?.data[0].user_details.id,
                o_m_owner: companyLocations?.data[0].user_details.id
            });
        }
    }, [companyLocations?.data, editMode]);

    useEffect(() => {
        if (projectDetails?.data) {
            // This if condition is because we do not want the form values to get overwritten every time user edits any of the form field.
            // Once component mounts we will set the local state from the query cache and that's it.
            if (projectDetails?.data?.company !== projectDetailState.company || showOnlyProjectInfo) {
                if (projectDetails.data.logo?.filepath) {
                    setImagePreview(getProxyFileUrl(projectDetails.data.logo.filepath));
                }

                setProjectDetailState({
                    ...projectDetailState,
                    address1: projectDetails.data.address1 || '',
                    address2: projectDetails.data.address2 || '',
                    country: projectDetails.data.country || '',
                    city: projectDetails.data.city || '',
                    zip: projectDetails.data.zip || '',
                    construction_scope: projectDetails.data.construction_scope || '',
                    size: projectDetails.data.size || '',
                    quote_due_date: projectDetails.data.quote_due_date ? dayjs(projectDetails.data.quote_due_date) : null,
                    submittal_due_date: projectDetails.data.submittal_due_date ? dayjs(projectDetails.data.submittal_due_date) : null,
                    o_m_due_date: projectDetails.data.o_m_due_date ? dayjs(projectDetails.data.o_m_due_date) : null,
                    state: projectDetails.data.state?.substring(0, 2) || '',
                    name: projectDetails.data.name || '',
                    company: projectDetails.data.company,
                    stage: projectDetails.data.stage,
                    quote_owner: projectDetails.data.quote_owner,
                    submittal_owner: projectDetails.data.submittal_owner,
                    o_m_owner: projectDetails.data.o_m_owner,
                    building_type: projectDetails.data.building_type || '',
                    logo: projectDetails.data.logo,
                    estimated_project_cost: projectDetails.data.estimated_project_cost !== null ? convertToDollar(projectDetails.data.estimated_project_cost) : null,
                    priority: projectDetails.data.priority || PRIORITY_VALUES.MEDIUM
                });
            }

            if (projectDetails.data.country === COUNTRY.USA) {
                setStateListOptions(modifiedStateList(USA_STATES));
            } else if (projectDetails.data.country === COUNTRY.CANADA) {
                setStateListOptions(modifiedStateList(CANADA_STATES));
            }
        }
    }, [projectDetails?.data]);

    function handleProjectStage(e: SelectChangeEvent<unknown>) {
        const { name, value } = e.target;

        if (editMode && isQuoteEnabled && isAnyQuoteInTheProject && PROJECT_STAGE_TRIGGER_VALUES.some((option) => option === value)) {
            setSelectedProjectStage(value as string);
            setOpen(true);
        } else {
            if (editMode && isQuoteEnabled && value === PROJECT_STAGES_LABELS_ENUM.REBID) {
                setShowQuoteDueDatePopup(true);
            }
            setProjectDetailState({ ...projectDetailState, [name]: value });
            setProjectStateRef({ ...projectDetailState, [name]: value });
            onBlurAction();
        }
    }

    function handleProjectStageValue(stageValue: string) {
        if (stageValue === PROJECT_STAGES_LABELS_ENUM.REBID) {
            setShowQuoteDueDatePopup(true);
        }
        setProjectDetailState({ ...projectDetailState, stage: stageValue });
        setProjectStateRef({ ...projectDetailState, stage: stageValue });
        onBlurAction();
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) {
        const { name } = e.target;
        const value = e.target.value as string;
        let updatedProjectDetailState = { ...projectDetailState };

        if (name === FIELD_NAMES.size.value) {
            const onlyNumberRegex = new RegExp('^[0-9]*$');
            if (onlyNumberRegex.test(value) && Number(value) <= MAX_VALUE_SQUARE_FOOTAGE) {
                updatedProjectDetailState.size = value;
            }
        } else if (name === FIELD_NAMES.country.value) {
            updatedProjectDetailState = { ...updatedProjectDetailState, [name]: value, state: '' };
            let stateOptions: { id: string; name: string }[] = [];
            if (value === COUNTRY.USA) {
                stateOptions = modifiedStateList(USA_STATES);
            } else if (value === COUNTRY.CANADA) {
                stateOptions = modifiedStateList(CANADA_STATES);
            }
            setStateListOptions(stateOptions);
        } else if (name === FIELD_NAMES.o_m_owner.value) {
            updatedProjectDetailState.o_m_owner = Number(value);
            if (isOMOwnerDeleted) {
                setIsOMOwnerDeleted(false);
            }
        } else if (name === FIELD_NAMES.submittal_owner.value) {
            updatedProjectDetailState.submittal_owner = Number(value);
            if (isSubmittalOwnerDeleted) {
                setIsSubmittalOwnerDeleted(false);
            }
        } else if (name === FIELD_NAMES.quote_owner.value) {
            updatedProjectDetailState.quote_owner = Number(value);
            if (isQuoteOwnerDeleted) {
                setIsQuoteOwnerDeleted(false);
            }
        } else if (name === FIELD_NAMES.company.value) {
            updatedProjectDetailState.company = Number(value);
            if (userProfile?.data.id) {
                const userId = Number(userProfile?.data.id);
                let quoteOwner, omOwner, submittalOwner;
                if (ownerCacheList[Number(value)]?.some((item) => item.id === updatedProjectDetailState.quote_owner)) {
                    quoteOwner = updatedProjectDetailState.quote_owner;
                } else {
                    quoteOwner = userId;
                }
                if (ownerCacheList[Number(value)]?.some((item) => item.id === updatedProjectDetailState.o_m_owner)) {
                    omOwner = updatedProjectDetailState.o_m_owner;
                } else {
                    omOwner = userId;
                }
                if (ownerCacheList[Number(value)]?.some((item) => item.id === updatedProjectDetailState.submittal_owner)) {
                    submittalOwner = updatedProjectDetailState.submittal_owner;
                } else {
                    submittalOwner = userId;
                }
                updatedProjectDetailState = { ...updatedProjectDetailState, submittal_owner: submittalOwner, o_m_owner: omOwner, quote_owner: quoteOwner };
            }
        } else if (name === FIELD_NAMES.name.value) {
            updatedProjectDetailState.name = value;
            onError({ ...formErrors, projectNameError: '' });
        } else if (name === FIELD_NAMES.estimated_project_cost.value) {
            const formatedValue = value === '' || value === null ? null : Number(value);
            updatedProjectDetailState = { ...updatedProjectDetailState, [name]: formatedValue };
        } else {
            updatedProjectDetailState = { ...updatedProjectDetailState, [name]: value };
        }

        setProjectDetailState(updatedProjectDetailState);
        setProjectStateRef(updatedProjectDetailState);

        if (name in TEXT_FIELDS_OBJ) {
            setHasTextfieldChanged(true);
        } else {
            onBlurAction();
        }
    }

    function projectFocusOut() {
        if (!projectDetailState.name.trim()) {
            onError({ ...formErrors, projectNameError: 'Required' });
            return;
        }

        onBlurAction();
    }

    function handleOnTextFieldBlur() {
        if (hasTextfieldChanged) {
            onBlurAction();
            setHasTextfieldChanged(false);
        }
    }

    function handleOnDateAccept(value: Dayjs | null, fieldName: string) {
        const updatedProjectDetailState = { ...projectDetailState, [fieldName]: value };
        setProjectDetailState(updatedProjectDetailState);
        setProjectStateRef(updatedProjectDetailState);
        onBlurAction();
    }

    const handleOnDateChange = (value: Dayjs | null, fieldName: string) => {
        if (value === null || (value !== null && value?.format().length === 25)) {
            const updatedProjectDetailState = { ...projectDetailState, [fieldName]: value };
            setProjectDetailState(updatedProjectDetailState);
            setProjectStateRef(updatedProjectDetailState);
            onBlurAction();
        }
    };

    const handleOnUpdateQuoteDueDate = useCallback(
        (value: Dayjs | null) => {
            handleOnDateAccept(value, FIELD_NAMES.quote_due_date.value);
            onCloseDueDatePopup();
        },
        [projectDetailState, selectedProjectStage]
    );

    function onFileChanged(file: File[] | FileSelectorFileType[]) {
        const updatedProjectDetailState = { ...projectDetailState };

        if (file.length) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setImagePreview(fileReader.result as string);
            };
            fileReader.readAsDataURL(file[0] as File);
            updatedProjectDetailState.logo = file[0] as File;
            setProjectStateRef(updatedProjectDetailState);
            onBlurAction();
        }

        setProjectDetailState(updatedProjectDetailState);
    }

    const documentStatusOptions = getDocumentOptions(allTagsData, QUOTE);
    const isQuoteEnabled = enable_quoting && quotingAccess;

    return (
        <>
            {open && documentStatusOptions && (
                <UpdateProjectQuoteStatusModal
                    onUpdateProjectStage={handleProjectStageValue}
                    quotesStatusOptions={documentStatusOptions}
                    open={open}
                    onClose={handleOnClose}
                    isUpdatingProjectDetails={isUpdatingProjectDetails}
                    selectedProjectStage={selectedProjectStage}
                    projectId={projectId}
                />
            )}
            {showQuoteDueDatePopup && (
                <UpdateQuoteDueDateModal
                    showPopup={showQuoteDueDatePopup}
                    onClose={onCloseDueDatePopup}
                    onAcceptDate={handleOnUpdateQuoteDueDate}
                    initialDateValue={projectDetailState.quote_due_date}
                />
            )}
            <Box width={showOnlyProjectInfo ? '100%' : '90vw'} position="relative" pr={1}>
                <Grid container>
                    <Grid item md={showOnlyProjectInfo ? 12 : 3} xs={showOnlyProjectInfo ? 12 : 3} paddingRight={6} paddingLeft={6} mt={!editMode ? 2 : 0}>
                        {!editMode && (
                            <Box>
                                <TextField
                                    value={projectDetailState.name}
                                    label={FIELD_NAMES.name.label}
                                    required
                                    onChange={handleOnChange}
                                    onBlur={projectFocusOut}
                                    autoComplete="off"
                                    helperText={formErrors.projectNameError}
                                    name={FIELD_NAMES.name.value}
                                    error={Boolean(formErrors.projectNameError)}
                                />
                            </Box>
                        )}

                        <Box mt={!editMode ? 6 : 0}>
                            <Grid container spacing={6}>
                                <Grid item lg={12} xl={12} sx={{ maxHeight: 100 }}>
                                    {!isLocationLoading && !isLocationFetching && companyLocations && optionsForLocations && ((editMode && !isEditProjectLoading) || !editMode) ? (
                                        <Select
                                            value={projectDetailState.company}
                                            label={FIELD_NAMES.company.label}
                                            id={FIELD_NAMES.company.label}
                                            labelId={FIELD_NAMES.company.label}
                                            name={FIELD_NAMES.company.value}
                                            options={optionsForLocations}
                                            optionLabelKeyname="company_name"
                                            optionValueKeyname="company_id"
                                            onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>
                                <Grid item lg={12} xl={12}>
                                    {!isAllTagsLoading && ((editMode && !isEditProjectLoading) || !editMode) ? (
                                        <StatusSelect
                                            value={projectDetailState.stage}
                                            label={FIELD_NAMES.stage.label}
                                            id={FIELD_NAMES.stage.label}
                                            labelId={FIELD_NAMES.stage.label}
                                            name={FIELD_NAMES.stage.value}
                                            options={[...(getProjectStages(editMode!, isQuoteEnabled) as any)]}
                                            optionLabelKeyname="label"
                                            optionValueKeyname="value"
                                            onChange={(e: SelectChangeEvent<unknown>) => handleProjectStage(e)}
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>

                                <Grid item lg={12} xl={6}>
                                    {!editMode || (editMode && !isEditProjectLoading) ? (
                                        <Select
                                            value={projectDetailState.priority}
                                            label={FIELD_NAMES.priority.label}
                                            id={FIELD_NAMES.priority.label}
                                            labelId={FIELD_NAMES.priority.label}
                                            name={FIELD_NAMES.priority.value}
                                            options={PRIORITY_OPTIONS}
                                            optionLabelKeyname="label"
                                            optionValueKeyname="value"
                                            onChange={(e: SelectChangeEvent<unknown>) => handleProjectStage(e)}
                                            customOption={(option: { [index: string]: string | number | boolean | undefined }) =>
                                                PriorityFilterOption({ option: option as { label: string; value: string } })
                                            }
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>

                                <>
                                    {editMode && (
                                        <Grid item lg={12} xl={6}>
                                            {!isEditProjectLoading && (
                                                <TextField
                                                    value={projectDetailState.estimated_project_cost !== null ? projectDetailState.estimated_project_cost : ''}
                                                    label={FIELD_NAMES.estimated_project_cost.label}
                                                    name={FIELD_NAMES.estimated_project_cost.value}
                                                    onChange={handleOnChange}
                                                    onBlur={handleOnTextFieldBlur}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        inputComponent: CustomDollarFormatterComponent
                                                    }}
                                                />
                                            )}
                                            {isEditProjectLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                        </Grid>
                                    )}

                                    {!editMode && (
                                        <Grid item lg={12} xl={6}>
                                            {
                                                <TextField
                                                    value={projectDetailState.estimated_project_cost}
                                                    label={FIELD_NAMES.estimated_project_cost.label}
                                                    name={FIELD_NAMES.estimated_project_cost.value}
                                                    onChange={handleOnChange}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        inputComponent: CustomDollarFormatterComponent
                                                    }}
                                                />
                                            }
                                        </Grid>
                                    )}
                                </>
                            </Grid>
                        </Box>

                        {isQuoteEnabled && (
                            <Box>
                                <Grid container spacing={6} pt={6}>
                                    <Grid item lg={12} xl={6}>
                                        {!isOwnerListLoading ? (
                                            <>
                                                <Select
                                                    value={projectDetailState.quote_owner}
                                                    label={FIELD_NAMES.quote_owner.label}
                                                    id={FIELD_NAMES.quote_owner.label}
                                                    labelId={FIELD_NAMES.quote_owner.label}
                                                    options={ownerLocationList}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    name={FIELD_NAMES.quote_owner.value}
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                    {...(isQuoteOwnerDeleted && { borderColor: 'warning' })}
                                                />
                                                {isQuoteOwnerDeleted && (
                                                    <BodyXS color="warning.main" mt={1}>
                                                        Quote Owner Deleted
                                                    </BodyXS>
                                                )}
                                            </>
                                        ) : (
                                            <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                        )}
                                    </Grid>
                                    <Grid item lg={12} xl={6}>
                                        {(editMode && !isEditProjectLoading) || !editMode ? (
                                            <CustomDateTimePicker
                                                value={projectDetailState.quote_due_date}
                                                label={FIELD_NAMES.quote_due_date.label}
                                                onAccept={(value: Dayjs | null) => handleOnDateAccept(value, FIELD_NAMES.quote_due_date.value)}
                                                onChange={(value: Dayjs | null) => handleOnDateChange(value, FIELD_NAMES.quote_due_date.value)}
                                                shouldDisableDate={getIfWeekendDates}
                                            />
                                        ) : (
                                            <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        <Box>
                            <Grid container spacing={6} pt={6}>
                                <Grid item lg={12} xl={6}>
                                    {!isOwnerListLoading && ((editMode && !isEditProjectLoading) || !editMode) ? (
                                        <>
                                            <Select
                                                value={projectDetailState.submittal_owner}
                                                label={FIELD_NAMES.submittal_owner.label}
                                                id={FIELD_NAMES.submittal_owner.label}
                                                labelId={FIELD_NAMES.submittal_owner.label}
                                                options={ownerLocationList}
                                                optionLabelKeyname="name"
                                                optionValueKeyname="id"
                                                name={FIELD_NAMES.submittal_owner.value}
                                                onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                {...(isSubmittalOwnerDeleted && { borderColor: 'warning' })}
                                            />
                                            {isSubmittalOwnerDeleted && (
                                                <BodyXS color="warning.main" mt={1}>
                                                    Submittal Owner Deleted
                                                </BodyXS>
                                            )}
                                        </>
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>
                                <Grid item lg={12} xl={6}>
                                    {(editMode && !isEditProjectLoading) || !editMode ? (
                                        <CustomDateTimePicker
                                            value={projectDetailState.submittal_due_date}
                                            label={FIELD_NAMES.submittal_due_date.label}
                                            onAccept={(value: Dayjs | null) => handleOnDateAccept(value, FIELD_NAMES.submittal_due_date.value)}
                                            onChange={(value: Dayjs | null) => handleOnDateChange(value, FIELD_NAMES.submittal_due_date.value)}
                                            shouldDisableDate={getIfWeekendDates}
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={6} pt={6}>
                                <Grid item lg={12} xl={6}>
                                    {!isOwnerListLoading && ((editMode && !isEditProjectLoading) || !editMode) ? (
                                        <>
                                            <Select
                                                value={projectDetailState.o_m_owner}
                                                label={FIELD_NAMES.o_m_owner.label}
                                                id={FIELD_NAMES.o_m_owner.label}
                                                labelId={FIELD_NAMES.o_m_owner.label}
                                                options={ownerLocationList}
                                                optionLabelKeyname="name"
                                                optionValueKeyname="id"
                                                name={FIELD_NAMES.o_m_owner.value}
                                                onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                {...(isOMOwnerDeleted && { borderColor: 'warning' })}
                                            />
                                            {isOMOwnerDeleted && (
                                                <BodyXS color="warning.main" mt={1}>
                                                    O&M Owner Deleted
                                                </BodyXS>
                                            )}
                                        </>
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>
                                <Grid item lg={12} xl={6}>
                                    {(editMode && !isEditProjectLoading) || !editMode ? (
                                        <CustomDateTimePicker
                                            value={projectDetailState.o_m_due_date}
                                            label={FIELD_NAMES.o_m_due_date.label}
                                            onAccept={(value: Dayjs | null) => handleOnDateAccept(value, FIELD_NAMES.o_m_due_date.value)}
                                            onChange={(value: Dayjs | null) => handleOnDateChange(value, FIELD_NAMES.o_m_due_date.value)}
                                            shouldDisableDate={getIfWeekendDates}
                                        />
                                    ) : (
                                        <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {!showOnlyProjectInfo && (
                        <>
                            <Grid
                                item
                                xs={3}
                                md={3}
                                sx={{
                                    borderRight: '1px solid',
                                    borderColor: 'text.secondary',
                                    borderLeft: '1px solid',
                                    paddingLeft: 6,
                                    paddingRight: 6
                                }}
                                mt={!editMode ? 2 : 0}
                            >
                                <Box>
                                    <TextField
                                        value={projectDetailState.address1}
                                        onBlur={handleOnTextFieldBlur}
                                        label={FIELD_NAMES.address1.label}
                                        name={FIELD_NAMES.address1.value}
                                        onChange={handleOnChange}
                                        autoComplete="off"
                                    />
                                </Box>
                                <Box marginTop={6}>
                                    <TextField
                                        value={projectDetailState.address2}
                                        onBlur={handleOnTextFieldBlur}
                                        label={FIELD_NAMES.address2.label}
                                        name={FIELD_NAMES.address2.value}
                                        onChange={handleOnChange}
                                        autoComplete="off"
                                    />
                                </Box>
                                <Grid container pt={6} spacing={6}>
                                    {editMode && (
                                        <Grid item md={6}>
                                            {!isEditProjectLoading && (
                                                <Select
                                                    value={projectDetailState.country}
                                                    label={FIELD_NAMES.country.label}
                                                    id={FIELD_NAMES.country.label}
                                                    labelId={FIELD_NAMES.country.label}
                                                    options={COUNTRY_OPTIONS}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    name={FIELD_NAMES.country.value}
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                />
                                            )}
                                            {isEditProjectLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                        </Grid>
                                    )}

                                    {!editMode && (
                                        <Grid item md={6}>
                                            {
                                                <Select
                                                    value={projectDetailState.country}
                                                    label={FIELD_NAMES.country.label}
                                                    id={FIELD_NAMES.country.label}
                                                    labelId={FIELD_NAMES.country.label}
                                                    options={COUNTRY_OPTIONS}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    name={FIELD_NAMES.country.value}
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                />
                                            }
                                        </Grid>
                                    )}

                                    <Grid item md={6}>
                                        <TextField
                                            value={projectDetailState.city}
                                            onBlur={handleOnTextFieldBlur}
                                            label={FIELD_NAMES.city.label}
                                            name={FIELD_NAMES.city.value}
                                            onChange={handleOnChange}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container pt={6} spacing={6}>
                                    {editMode && (
                                        <Grid item md={6}>
                                            {!isEditProjectLoading && (
                                                <Select
                                                    value={projectDetailState.state}
                                                    label={FIELD_NAMES.state.label}
                                                    id={FIELD_NAMES.state.label}
                                                    labelId={FIELD_NAMES.state.label}
                                                    options={stateListOptions}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                    disabled={Boolean(!projectDetailState.country)}
                                                    name={FIELD_NAMES.state.value}
                                                />
                                            )}
                                            {isEditProjectLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                        </Grid>
                                    )}

                                    {!editMode && (
                                        <Grid item md={6}>
                                            {
                                                <Select
                                                    value={projectDetailState.state}
                                                    label={FIELD_NAMES.state.label}
                                                    id={FIELD_NAMES.state.label}
                                                    labelId={FIELD_NAMES.state.label}
                                                    options={stateListOptions}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                    disabled={Boolean(!projectDetailState.country)}
                                                    name={FIELD_NAMES.state.value}
                                                />
                                            }
                                        </Grid>
                                    )}
                                    <Grid item md={6}>
                                        <TextField
                                            onBlur={handleOnTextFieldBlur}
                                            value={projectDetailState.zip}
                                            label={FIELD_NAMES.zip.label}
                                            name={FIELD_NAMES.zip.value}
                                            onChange={handleOnChange}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                </Grid>

                                {editMode && (
                                    <Box pt={6}>
                                        {!isEditProjectLoading && (
                                            <Select
                                                value={projectDetailState.building_type}
                                                label={FIELD_NAMES.building_type.label}
                                                id={FIELD_NAMES.building_type.label}
                                                labelId={FIELD_NAMES.building_type.label}
                                                options={CONSTRUCTION_SCOPE_OPTIONS}
                                                optionLabelKeyname="name"
                                                optionValueKeyname="id"
                                                name={FIELD_NAMES.building_type.value}
                                                onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                            />
                                        )}
                                        {isEditProjectLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                    </Box>
                                )}

                                {!editMode && (
                                    <Box pt={6}>
                                        {
                                            <Select
                                                value={projectDetailState.building_type}
                                                label={FIELD_NAMES.building_type.label}
                                                id={FIELD_NAMES.building_type.label}
                                                labelId={FIELD_NAMES.building_type.label}
                                                options={CONSTRUCTION_SCOPE_OPTIONS}
                                                optionLabelKeyname="name"
                                                optionValueKeyname="id"
                                                name={FIELD_NAMES.building_type.value}
                                                onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                            />
                                        }
                                    </Box>
                                )}

                                <Grid container pt={6} spacing={6}>
                                    {editMode && (
                                        <Grid item lg={12} xl={12} width={'100%'}>
                                            {!isEditProjectLoading && (
                                                <Select
                                                    value={projectDetailState.construction_scope}
                                                    label={FIELD_NAMES.construction_scope.label}
                                                    id={FIELD_NAMES.construction_scope.label}
                                                    labelId={FIELD_NAMES.construction_scope.label}
                                                    options={CONSTRUCTION_OPTIONS}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                    name={FIELD_NAMES.construction_scope.value}
                                                />
                                            )}
                                            {isEditProjectLoading && <Skeleton width="100%" height={36} variant="rectangular" sx={{ borderRadius: 1 }} />}
                                        </Grid>
                                    )}

                                    {!editMode && (
                                        <Grid item lg={12} xl={12} width={'100%'}>
                                            {
                                                <Select
                                                    value={projectDetailState.construction_scope}
                                                    label={FIELD_NAMES.construction_scope.label}
                                                    id={FIELD_NAMES.construction_scope.label}
                                                    labelId={FIELD_NAMES.construction_scope.label}
                                                    options={CONSTRUCTION_OPTIONS}
                                                    optionLabelKeyname="name"
                                                    optionValueKeyname="id"
                                                    onChange={(e: SelectChangeEvent<unknown>) => handleOnChange(e)}
                                                    name={FIELD_NAMES.construction_scope.value}
                                                />
                                            }
                                        </Grid>
                                    )}
                                    <Grid item lg={12} xl={12}>
                                        <TextField
                                            value={projectDetailState.size}
                                            onBlur={handleOnTextFieldBlur}
                                            label={FIELD_NAMES.size.label}
                                            name={FIELD_NAMES.size.value}
                                            onChange={handleOnChange}
                                            autoComplete="off"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} xs={3} pl={6} pr={6} mt={!editMode ? 2 : 0}>
                                <Grid container flexDirection={'column'}>
                                    <Grid item>
                                        <BodySmall fontWeight={500}>Project Stakeholders</BodySmall>
                                        <Box display="flex" flexDirection="column" gap={4}>
                                            {(stakeholdersInfo || []).map((stakeholder, index) => {
                                                return (
                                                    <Box key={index} mt={3}>
                                                        <CompanyContactSelector
                                                            companyInfo={stakeholder?.company || null}
                                                            setCompanyInfo={(companyInfo) => updateStakeholderInfo?.({ ...stakeholder, company: companyInfo, contact: null }, index)}
                                                            contactInfo={stakeholder?.contact || null}
                                                            setContactInfo={(contactInfo) => updateStakeholderInfo?.({ ...stakeholder, contact: contactInfo }, index)}
                                                            locationId={projectDetailState?.company || -1}
                                                            companyLabel={`Company ${index + 1}`}
                                                            contactLabel={`Contact ${index + 1}`}
                                                            isLoading={isStakeHoldersInfoFetching}
                                                        />
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={3}
                                sx={{
                                    borderColor: 'text.secondary',
                                    borderLeft: '1px solid',
                                    paddingLeft: 6,
                                    paddingRight: 3
                                }}
                                mt={!editMode ? 2 : 0}
                            >
                                <Box>
                                    {imagePreview && <ProjectImage onDelete={handleDeleteImagePreview} url={imagePreview} heading="Project Logo" />}

                                    {!imagePreview && (
                                        <Box width={1} height={300}>
                                            <BodySmall fontWeight={500} paddingBottom={2}>
                                                Project Logo
                                            </BodySmall>
                                            <Box pr={3} width={1} height={'100%'}>
                                                <FileSelector
                                                    placeholder="Drop a file or click to browse"
                                                    helperText="Supported file types: PNG, JPG"
                                                    onSelect={onFileChanged}
                                                    acceptedFormats={ACCEPTED_FILE_TYPES}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </>
    );
}

export default EditProjectDetail;
