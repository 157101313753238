import { ValueGetterParams, ICellRendererParams, IDateFilterParams } from 'ag-grid-community';

import { Box, Tooltip, SyncIcon, Menu, IconButton, MoreVertIcon, Status, BodyXS, dayjs } from '@parspec/pixel';

import { TAB_LABEL } from '../constants';
import { BomTablesDataType, SyncTypeForTable, Option } from './types';
import { BomInfoForDashboard, DocInfo, OMInfoForDashboard, OutofSyncResponse, QuoteDocInfo, QuoteInfoDashBoard, SubmittalInfoForDashboard } from '../../queries/apiTypes';
import { DOCUMENT_STATUS_COLOR_MAP, ColorCode } from '../../../shared/constants';
import { StatusTemplate } from '../../../shared/utils/utils';
import { Customer } from '../components';
import { LastModified } from '../components';
import { getDate } from '../../../Project/shared/utils';
import { ViewTemplate, DateTemplate } from '../components';

type TabLableKey = keyof typeof TAB_LABEL;
type TabLabelVal = (typeof TAB_LABEL)[TabLableKey];

export const getCustomerContactTemplate = (props: any, customerType: 'primary_contact' | 'secondary_contact') => {
    const { data } = props;

    return Customer(data[customerType]);
};

export function getDateForTable(date: string | null) {
    if (!date) {
        return null;
    }
    return dayjs(date).format('MMM D, YYYY hh:mm A');
}

export function getBomRowData(tabType: TabLabelVal) {
    return (apiData: (BomInfoForDashboard | SubmittalInfoForDashboard | OMInfoForDashboard | QuoteInfoDashBoard) & SyncTypeForTable): BomTablesDataType => {
        const projectId = apiData.project.id;
        const projectName = apiData.project.name;
        const bomId = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.id : (apiData as SubmittalInfoForDashboard | OMInfoForDashboard | QuoteInfoDashBoard)?.bom?.id;
        const bomName = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.name : (apiData as SubmittalInfoForDashboard | OMInfoForDashboard | QuoteInfoDashBoard)?.bom?.name;
        const primaryContact = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.primary_contact : (apiData as QuoteInfoDashBoard)?.bom?.primary_contact;
        const dueDate =
            tabType === TAB_LABEL.SUBMITTAL_TAB_LABEL
                ? apiData.project.submittal_due_date
                : tabType === TAB_LABEL.OM_TAB_LABEL
                ? apiData.project.o_m_due_date
                : tabType === TAB_LABEL.QUOTE_LABEL
                ? apiData.project.quote_due_date
                : null;
        const dueDateForUi = getDateForTable(dueDate);
        let submittalData: DocInfo[] | null | undefined;
        let omData: DocInfo[] | null | undefined;
        let quoteData: QuoteDocInfo[] | null | undefined;
        if (tabType === TAB_LABEL.SUBMITTAL_TAB_LABEL) {
            submittalData = (apiData as SubmittalInfoForDashboard).submittal;
        } else {
            submittalData = (apiData as BomInfoForDashboard).submittal ? (apiData as BomInfoForDashboard).submittal : null;
        }

        if (tabType === TAB_LABEL.OM_TAB_LABEL) {
            omData = (apiData as OMInfoForDashboard).o_m;
        } else {
            omData = (apiData as BomInfoForDashboard).o_m ? (apiData as BomInfoForDashboard).o_m : null;
        }

        if (tabType === TAB_LABEL.QUOTE_LABEL) {
            quoteData = (apiData as QuoteInfoDashBoard).quote;
        } else {
            quoteData = (apiData as QuoteInfoDashBoard).quote ? (apiData as BomInfoForDashboard).quote : null;
        }

        const secondary_contact = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.secondary_contact : '';
        const salesAgent = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.sales_agent : null;
        const lastEditedAt = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.last_edited_at : null;
        const lastEditedBy = tabType === TAB_LABEL.BOM_TAB_LABEL ? (apiData as BomInfoForDashboard)?.bom?.last_edited_by : null;

        return {
            omData,
            bomName,
            projectStage: apiData.project.stage,
            branchLocation: apiData.company.name,
            submittalOwner: apiData.project.submittal_owner,
            omOwner: apiData.project.o_m_owner,
            omOutOfSync: apiData.omOutOfSync,
            submittalOutOfSync: apiData.submittalOutOfSync,
            quoteOutOfSync: apiData.quoteOutOfSync,
            submittalData,
            dueDate,
            dueDateForUi,
            projectId,
            projectName,
            bomId,
            quoteData,
            quoteOwner: apiData.project.quote_owner,
            primary_contact: primaryContact,
            quoteStatus: (apiData as BomInfoForDashboard).quoteStatus,
            submittalStatus: (apiData as BomInfoForDashboard).submittalStatus,
            omStatus: (apiData as BomInfoForDashboard).omStatus,
            secondary_contact,
            salesAgent,
            lastEditedAt,
            lastEditedBy,
            submittalDueDate: apiData.project.submittal_due_date,
            omDueDate: apiData.project.o_m_due_date,
            quoteDueDate: apiData.project.quote_due_date,
            estimatedProjectCost: apiData.project.estimated_project_cost,
            grandTotalCents: apiData.bom.grand_total_cents,
            isPrimary: apiData.bom.is_primary,
            isLinked: apiData.bom.is_linked,
            bomCreationDate: apiData.bom.created_at
        };
    };
}

export const getStageTemplate = (props: ICellRendererParams) => {
    const { data } = props;
    return StatusTemplate(data?.projectStage);
};

export const DocumentStatusTemplate = (props: any) => {
    const status = props?.status || '-';
    const color = DOCUMENT_STATUS_COLOR_MAP[status as keyof typeof DOCUMENT_STATUS_COLOR_MAP] as ColorCode;
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" alignItems="center">
                <Box>
                    <Status color={color}>
                        <BodyXS color={color}>{status}</BodyXS>
                    </Status>
                </Box>

                {props.isOutOfSync && (
                    <Box ml={4} display={'flex'}>
                        <Tooltip title="Changes have been made to the Bill of Materials since the latest version.">
                            <SyncIcon color={'tertiary' as 'primary'} />
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export const getDueDateTemplate = (props: any) => {
    const { data } = props;
    return <DateTemplate dueDate={data?.dueDate} />;
};

export const getSubmittalViewTemplate = (props: any) => {
    const { data } = props;

    const payload = {
        isOutOfSync: data.submittalOutOfSync,
        data: data.submittalData,
        bomId: data.bomId,
        documentType: 'submittal',
        status: data.submittalStatus,
        projectId: data.projectId
    };

    return <ViewTemplate {...(payload || {})} />;
};
export const getOMViewTemplate = (props: any) => {
    const { data } = props;

    const payload = {
        isOutOfSync: data.omOutOfSync,
        data: data.omData,
        bomId: data.bomId,
        documentType: 'o_m',
        status: data.omStatus,
        projectId: data.projectId
    };

    return <ViewTemplate {...(payload || {})} />;
};

export const getQuoteViewTemplate = (props: any) => {
    const { data } = props;

    const payload = {
        isOutOfSync: data.quoteOutOfSync,
        data: data.quoteData,
        bomId: data.bomId,
        documentType: 'quote',
        status: data.quoteStatus,
        projectId: data.projectId
    };

    return <ViewTemplate {...(payload || {})} />;
};

export function TableMenu({ options }: { options: Option[] }) {
    const optionsLength = options.length > 0;

    return (
        <Box display="flex" justifyContent="center">
            {optionsLength ? (
                <Menu options={options} />
            ) : (
                <IconButton disabled>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
            )}
        </Box>
    );
}

export function getSyncCache(data: OutofSyncResponse) {
    const result = data.reduce((acc, val) => {
        acc[val.id] = val.out_of_sync;
        return acc;
    }, {} as Record<number, boolean>);

    return result;
}

export const getDocumentOwnerTemplate = (props: any, tabName: 'SUBMITTAL' | 'OM' | 'QUOTE') => {
    const { data } = props;

    if (tabName === 'SUBMITTAL') {
        return data?.submittalOwner || '-';
    } else if (tabName === 'OM') {
        return data?.omOwner || '-';
    } else if (tabName === 'QUOTE') {
        return data?.quoteOwner || '-';
    } else {
        return '-';
    }
};

export const dateComparator = (date1: string, date2: string) => {
    const date1Number = date1 ? new Date(date1).getTime() : 0;
    const date2Number = date2 ? new Date(date2).getTime() : 0;

    if (date1Number == null && date2Number == null) {
        return 0;
    }

    if (date1Number == null) {
        return -1;
    } else if (date2Number == null) {
        return 1;
    }

    return date1Number - date2Number;
};

export const stringComparator = (valueA: string, valueB: string) => {
    return valueA?.toLowerCase().trim() > valueB?.toLowerCase().trim() ? 1 : -1;
};

export const numberComparator = (valueA: number, valueB: number) => {
    if (valueA > valueB) {
        return 1;
    }
    if (valueA < valueB) {
        return -1;
    }
    return 0;
};

export const getDateComparator = (
    _valueA: string,
    _valueB: string,
    nodeA: any,
    nodeB: any,
    _isDescending: boolean,
    fieldName: 'quoteDueDate' | 'submittalDueDate' | 'omDueDate' | 'quoteCreationDate' | 'submittalCreationDate' | 'omCreationDate' | 'lastEditedAt' | 'dueDate' | 'last_edited_at' | 'bomCreationDate'
) => {
    const date1 = nodeA?.data?.[fieldName] || '';
    const date2 = nodeB?.data?.[fieldName] || '';

    return dateComparator(date1, date2);
};

export const getCustomerContactValue = (params: ValueGetterParams, contactType: 'primary_contact' | 'secondary_contact') => {
    const { data } = params;
    return data[contactType]?.company?.name;
};

export const getDateValueForUI = (
    params: ValueGetterParams,
    dateField: 'quoteDueDate' | 'submittalDueDate' | 'omDueDate' | 'dueDate' | 'quoteCreationDate' | 'submittalCreationDate' | 'omCreationDate' | 'bomCreationDate'
) => {
    // Takes in date values and converts them to redable UI format:
    // For ex: 2024-04-29T16:11:00Z ---> Apr 29, 2024 04:11 PM
    const { data } = params;
    const dateForUi = getDateForTable(data[dateField]);

    return dateForUi;
};

export const getStakeholderValue = (params: any, stakeholderType: '0' | '1' | '2' | '3' | '4') => {
    const { data } = params;
    return data?.stakeholders[stakeholderType]?.company?.name;
};

export const getLastModifiedDate = (args: ICellRendererParams, tabName: 'BOM' | 'QUOTE' | 'SUBMITTAL' | 'OM') => {
    let updatedAt = '';
    let updatedBy = '';

    let keyName = '';
    if (tabName === 'QUOTE') {
        keyName = 'quoteLastModified';
    } else if (tabName === 'SUBMITTAL') {
        keyName = 'submittalLastModified';
    } else if (tabName === 'OM') {
        keyName = 'omLastModified';
    }

    if (tabName === 'BOM') {
        updatedAt = args?.data?.lastEditedAt;
        updatedBy = args?.data?.lastEditedBy;
    } else {
        updatedAt = args?.data[keyName].createdAt;
        updatedBy = args?.data[keyName].compiledBy;
    }

    const propData = { updatedBy, updatedAt };

    return <LastModified {...(propData || {})} />;
};

export const getLastModifiedValue = (params: any) => {
    const { data } = params;

    const updatedAt = data?.lastEditedAt;
    const updatedBy = data?.lastEditedBy;

    const dateTime = getDate(updatedAt);

    return `${updatedBy} - ${dateTime}`;
};

export const getCustomerFilterText = (args: any, customerType: 'primary_contact' | 'secondary_contact') => {
    const { company, contact } = args.data[customerType];
    const { name: companyName, business_type: businessType } = company || {};
    const { name: contactName, role } = contact || {};

    return `${companyName} ${contactName} ${businessType} ${role}`;
};

export const dateFilterParams: IDateFilterParams = {
    maxNumConditions: 1,
    buttons: ['clear', 'apply'],
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        if (cellValue == null) return -1;

        // Parse the cell value into a Date object
        const cellDate = new Date(cellValue);

        // Extract the date parts without time
        const cellDateWithoutTime = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

        // Extract the filter date parts without time
        const filterDateWithoutTime = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());

        // Compare the date parts without time
        if (cellDateWithoutTime.getTime() === filterDateWithoutTime.getTime()) {
            return 0;
        }
        if (cellDateWithoutTime < filterDateWithoutTime) {
            return -1;
        }
        if (cellDateWithoutTime > filterDateWithoutTime) {
            return 1;
        }
        return 0;
    }
};

export const getTimeStampForAPI = () => {
    const d = new Date();
    const timeStamp =
        d.getFullYear().toString() +
        '-' +
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + d.getDate()).slice(-2) +
        ' ' +
        ('0' + d.getHours()).slice(-2) +
        ':' +
        ('0' + d.getMinutes()).slice(-2) +
        ':' +
        ('0' + d.getSeconds()).slice(-2);

    return timeStamp;
};
