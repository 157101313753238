import { Grid, Switch, RadioGroup, Box } from '@parspec/pixel';

import Title from '../../../shared/Title';
import { usePriceAndLeadTimeToggleMutation, usePriceAndLeadTimeTextMutation, usePriceAndLeadTimeQuery, usePriceAndLeadTimeOptionsQuery, usePriceAndLeadTimeOptionsToggleMutation } from '../../queries';
import { useQuoteContext } from '../../QuoteContext';
import { useUserEventCount } from 'src/features/shared/UserActivityTracking/TrackActivityCount';
import { OptionNotes } from './OptionNotes';
import ApplyToAllBomsButton from '../../../shared/ApplyToAllBomsButton';
import {
    LOT_SUB_SECTION,
    OPTION_NOTES_SUB_SECTION,
    OPTION_SECTION_SUB_SECTION,
    PRICE_LEAD_OPTION_SECTION_NAME,
    PRICE_LEAD_SECTION_NAME,
    PRODUCT_SUB_SECTION,
    SUMMARISED_TOTAL_SUB_SECTION
} from '../../shared/constants';

enum Type {
    Quantity = 'quantity',
    Type = 'type',
    Manufacture = 'manufacturer',
    LeadTime = 'lead_time',
    UnitPrice = 'unit_price',
    ExtendedPrice = 'extended_price',
    SectionSubtotal = 'section_subtotal',
    DatasheetHyperlinks = 'datasheet_hyperlink',
    ServicesBreakout = 'service_breakout',
    TaxesBreakdown = 'tax_breakout',
    FreightBreakout = 'freight_breakout',
    LotBreakout = 'lot_breakout',
    summarizeTotal = 'summarized_total',
    ModelNumber = 'model_number',
    Notes = 'notes',
    SHOW_KIT_BREAKDOWN = 'show_kit_breakdown',
    Description = 'description'
}

enum Options {
    Qunatity = 'quantity',
    Type = 'type',
    Manufacturer = 'manufacturer',
    ModelNumber = 'model_number',
    QuoteNotes = 'notes',
    SubstitutedFor = 'substituted_for',
    LeadTime = 'lead_time',
    UnitPrice = 'unit_price',
    ExtendedPrice = 'extended_price',
    AddDeductAmount = 'add_deduct_amount',
    SectionSubtotal = 'section_subtotal',
    DatasheetHyperLinks = 'datasheet_hyperlink',
    ShowKitBreakdown = 'show_kit_breakdown',
    Description = 'description'
}

function PricingAndLeadTime() {
    const { handlePdfPreview, isInitiateApiCalls, bomId, handleUpdateChangedSections } = useQuoteContext();
    const { pushUserEventCountAtBOM } = useUserEventCount();
    const { data: priceAndLeadTimeData } = usePriceAndLeadTimeQuery(bomId, isInitiateApiCalls);
    const { data: priceAndLeadTimeOptionsData } = usePriceAndLeadTimeOptionsQuery(bomId, isInitiateApiCalls);

    const { mutateAsync: mutateToggle } = usePriceAndLeadTimeToggleMutation();
    const { mutateAsync: mutateText } = usePriceAndLeadTimeTextMutation();
    const { mutateAsync: mutateOptionsToggle } = usePriceAndLeadTimeOptionsToggleMutation();

    const pushChangedFields = (sectionName: string, fieldName: string) => {
        handleUpdateChangedSections?.({ sectionName, subSectionName: fieldName, value: true });
    };

    async function handleToggleAction(e: React.ChangeEvent<HTMLInputElement>, fieldName: string, sectionName: string, subSectionName: string) {
        const payload = {
            bomId: bomId,
            fieldName,
            is_active: e.target.checked
        };
        if (fieldName === 'notes') {
            pushUserEventCountAtBOM({ eventType: 'quote_notes_toggled', count: Number(e.target.checked) });
        } else {
            pushUserEventCountAtBOM({ eventType: fieldName + '_toggled', count: Number(e.target.checked) });
        }
        await mutateToggle(payload);
        handlePdfPreview(true);
        pushChangedFields(sectionName, subSectionName);
    }

    async function handleOptionsToggle(e: React.ChangeEvent<HTMLInputElement>, fieldName: string, sectionName: string, subSectionName: string) {
        await mutateOptionsToggle({
            bomId: bomId,
            fieldName,
            is_active: e.target.checked
        });
        handlePdfPreview(true);
        pushChangedFields(sectionName, subSectionName);
    }

    async function handleRadioButtons(e: React.ChangeEvent<HTMLInputElement>, sectionName: string, subSectionName: string) {
        const payload = {
            bomId,
            field_value: e.target.value,
            fieldName: Type.summarizeTotal
        };

        await mutateText(payload);
        if (e.target.value === 'pstf_grand_total') {
            pushUserEventCountAtBOM({ eventType: 'Prod_services_tax_freight_total_selected', count: 1 });
            pushUserEventCountAtBOM({ eventType: 'grand_total_only_selected', count: 0 });
        } else if (e.target.value === 'grand_total') {
            pushUserEventCountAtBOM({ eventType: 'Prod_services_tax_freight_total_selected', count: 0 });
            pushUserEventCountAtBOM({ eventType: 'grand_total_only_selected', count: 1 });
        }
        handlePdfPreview(true);
        pushChangedFields(sectionName, subSectionName);
    }

    return (
        <>
            {priceAndLeadTimeData && (
                <Grid container direction="column" gap={2}>
                    <Grid item>
                        <Box display="flex" alignItems="center" gap={3}>
                            <Title title="Product Section Details" />
                            <ApplyToAllBomsButton sectionName={PRICE_LEAD_SECTION_NAME} subSectionName={PRODUCT_SUB_SECTION} />
                        </Box>
                    </Grid>

                    <Grid item width={1}>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item xs={6}>
                                {/* left */}
                                <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                    <Grid item>
                                        <Switch
                                            label="Quantity"
                                            checked={priceAndLeadTimeData.quantity.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.Quantity, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Type"
                                            checked={priceAndLeadTimeData.type.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.Type, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Manufacturer"
                                            checked={priceAndLeadTimeData.manufacturer.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.Manufacture, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Model Number"
                                            checked={priceAndLeadTimeData.model_number.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.ModelNumber, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Description"
                                            checked={priceAndLeadTimeData?.description?.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.Description, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Quote Notes (External)"
                                            checked={priceAndLeadTimeData.notes.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.Notes, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                    <Grid item>
                                        <Switch
                                            label="Lead Time"
                                            checked={priceAndLeadTimeData.lead_time.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.LeadTime, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            label="Unit Price"
                                            checked={priceAndLeadTimeData.unit_price.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.UnitPrice, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Extended Price"
                                            checked={priceAndLeadTimeData.extended_price.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.ExtendedPrice, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Section Subtotal"
                                            checked={priceAndLeadTimeData.section_subtotal.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.SectionSubtotal, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Datasheet Hyperlinks"
                                            checked={priceAndLeadTimeData.datasheet_hyperlink.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.DatasheetHyperlinks, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Switch
                                            label="Show Kit Breakdown"
                                            checked={priceAndLeadTimeData.show_kit_breakdown?.is_active}
                                            onChange={(e) => handleToggleAction(e, Type.SHOW_KIT_BREAKDOWN, PRICE_LEAD_SECTION_NAME, PRODUCT_SUB_SECTION)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Box display="flex" alignItems="center" gap={3}>
                            <Title title="Lot & Financial Breakouts" />
                            <ApplyToAllBomsButton sectionName={PRICE_LEAD_SECTION_NAME} subSectionName={LOT_SUB_SECTION} />
                        </Box>
                    </Grid>

                    <Grid item width={1}>
                        <Grid container direction={'column'} gap={1}>
                            <Grid item>
                                <Switch
                                    label="Lot breakout"
                                    checked={priceAndLeadTimeData.lot_breakout.is_active}
                                    onChange={(e) => handleToggleAction(e, Type.LotBreakout, PRICE_LEAD_SECTION_NAME, LOT_SUB_SECTION)}
                                />
                            </Grid>

                            <Grid item>
                                <Switch
                                    label="Services breakout"
                                    checked={priceAndLeadTimeData.service_breakout.is_active}
                                    onChange={(e) => handleToggleAction(e, Type.ServicesBreakout, PRICE_LEAD_SECTION_NAME, LOT_SUB_SECTION)}
                                />
                            </Grid>

                            <Grid item>
                                <Switch
                                    label="Taxes breakout"
                                    checked={priceAndLeadTimeData.tax_breakout.is_active}
                                    onChange={(e) => handleToggleAction(e, Type.TaxesBreakdown, PRICE_LEAD_SECTION_NAME, LOT_SUB_SECTION)}
                                />
                            </Grid>

                            <Grid item>
                                <Switch
                                    label="Freight breakout"
                                    checked={priceAndLeadTimeData.freight_breakout.is_active}
                                    onChange={(e) => handleToggleAction(e, Type.FreightBreakout, PRICE_LEAD_SECTION_NAME, LOT_SUB_SECTION)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Box display="flex" alignItems="center" gap={3}>
                            <Title title="Summarized Total" />
                            <ApplyToAllBomsButton sectionName={PRICE_LEAD_SECTION_NAME} subSectionName={SUMMARISED_TOTAL_SUB_SECTION} />
                        </Box>
                    </Grid>

                    <Grid item width={1}>
                        <RadioGroup
                            label=""
                            name="summarized_total"
                            onChange={(e) => handleRadioButtons(e, PRICE_LEAD_SECTION_NAME, SUMMARISED_TOTAL_SUB_SECTION)}
                            options={[
                                {
                                    label: 'Products, Services, Taxes, Freight, and Grand Total ',
                                    value: 'pstf_grand_total'
                                },
                                {
                                    label: 'Grand Total',
                                    value: 'grand_total'
                                },
                                {
                                    label: 'No Total',
                                    value: 'no_total'
                                }
                            ]}
                            size="small"
                            value={priceAndLeadTimeData.summarized_total.field_value}
                        />
                    </Grid>

                    <Grid item>
                        <Title title="Options (Add/Deducts)" />
                    </Grid>

                    {priceAndLeadTimeOptionsData && (
                        <Grid item width={1}>
                            <Box mt={2} mb={3}>
                                <OptionNotes sectionName={PRICE_LEAD_OPTION_SECTION_NAME} subSectionName={OPTION_NOTES_SUB_SECTION} />
                            </Box>

                            <Box display="flex" alignItems="center" gap={3} mb={2}>
                                <Title title="Options Section Details" />
                                <ApplyToAllBomsButton sectionName={PRICE_LEAD_OPTION_SECTION_NAME} subSectionName={OPTION_SECTION_SUB_SECTION} />
                            </Box>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item xs={6}>
                                    {/* left */}
                                    <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                        <Grid item>
                                            <Switch
                                                label="Quantity"
                                                checked={priceAndLeadTimeOptionsData.quantity.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.Qunatity, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Type"
                                                checked={priceAndLeadTimeOptionsData.type.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.Type, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Manufacturer"
                                                checked={priceAndLeadTimeOptionsData.manufacturer.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.Manufacturer, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Model Number"
                                                checked={priceAndLeadTimeOptionsData.model_number.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.ModelNumber, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Description"
                                                checked={priceAndLeadTimeOptionsData?.description?.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.Description, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Quote Notes (External)"
                                                checked={priceAndLeadTimeOptionsData.notes.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.QuoteNotes, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Lead Time"
                                                checked={priceAndLeadTimeOptionsData.lead_time.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.LeadTime, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container direction={'column'} alignItems={'flex-start'} gap={1}>
                                        <Grid item>
                                            <Switch
                                                label="Unit Price"
                                                checked={priceAndLeadTimeOptionsData.unit_price.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.UnitPrice, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Extended Price"
                                                checked={priceAndLeadTimeOptionsData.extended_price.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.ExtendedPrice, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Product to Replace"
                                                checked={priceAndLeadTimeOptionsData.substituted_for.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.SubstitutedFor, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Add/Deduct Amount"
                                                checked={priceAndLeadTimeOptionsData.add_deduct_amount.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.AddDeductAmount, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Section Subtotal"
                                                checked={priceAndLeadTimeOptionsData.section_subtotal.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.SectionSubtotal, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Datasheet Hyperlinks"
                                                checked={priceAndLeadTimeOptionsData.datasheet_hyperlink.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.DatasheetHyperLinks, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Switch
                                                label="Show Kit Breakdown"
                                                checked={priceAndLeadTimeOptionsData.show_kit_breakdown?.is_active}
                                                onChange={(e) => handleOptionsToggle(e, Options.ShowKitBreakdown, PRICE_LEAD_OPTION_SECTION_NAME, OPTION_SECTION_SUB_SECTION)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
}

export default PricingAndLeadTime;
