import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { BodySmall, BodyXS, Box, Menu, Paper, Avatar } from '@parspec/pixel';

import { getProxyFileUrl } from 'src/features/shared/utils/utils';
import { IUserInfoResponse } from '../MyProfile/queries/apiTypes';
import { useCompanyInfoQuery } from './queries';

interface LocationCardProps {
    locationId: number;
    locationLogo: string;
    locationName: string;
    locationAddress1: string;
    locationAddress2: string;
    locationEmail: string;
    locationPhone1: string;
    userProfile: AxiosResponse<IUserInfoResponse, any> | undefined;
    setIsDeleteLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedLocation: React.Dispatch<React.SetStateAction<number>>;
}

export const LocationCard = (props: LocationCardProps) => {
    const { locationId, locationLogo, locationName, locationAddress1, locationAddress2, locationEmail, locationPhone1, userProfile, setIsDeleteLocationModalOpen, setSelectedLocation } = props;
    const navigate = useNavigate();

    const { data: companyInfo } = useCompanyInfoQuery();

    const MENU_OPTIONS =
        companyInfo && companyInfo.data.length > 1
            ? [
                  {
                      label: 'Edit',
                      onClick: () => {
                          navigate(`/v2/settings/edit-location/${locationId}`);
                      }
                  },
                  {
                      label: 'Delete',
                      onClick: () => {
                          setIsDeleteLocationModalOpen(true);
                          setSelectedLocation(() => locationId);
                      },
                      color: 'error.main'
                  }
              ]
            : [
                  {
                      label: 'Edit',
                      onClick: () => {
                          navigate(`/v2/settings/edit-location/${locationId}`);
                      }
                  }
              ];

    return (
        <Paper variant="elevation" elevation={2}>
            <Box height={'340px'} width={'250px'} justifyContent="center" p={4} position={'relative'}>
                <Box color="text.secondary" top={4} right={2} position="absolute">
                    {userProfile?.data.role === 'admin' && <Menu options={MENU_OPTIONS}></Menu>}
                </Box>

                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent="center" height={'80%'} width={'100%'}>
                    <Avatar color={'primary'} src={getProxyFileUrl(locationLogo)} alt={locationName.toUpperCase()} sx={{ width: 60, height: 60 }} />

                    <BodySmall mt={4}>{locationName}</BodySmall>
                    <BodySmall my={1} color="text.secondary">
                        {locationAddress1}
                    </BodySmall>
                    <BodySmall my={1} color="text.secondary">
                        {locationAddress2}
                    </BodySmall>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent="center">
                        <BodyXS my={1} color="text.secondary">
                            {locationEmail}
                        </BodyXS>
                        <BodyXS my={1} color="text.secondary">
                            {locationPhone1}
                        </BodyXS>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
