import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ToggleText from '../../shared/ToggleText';
import PreviewContext from '../Context';
import { UpdateTextFieldProps } from '../queries/apiTypes';
import { useDocumentTextFieldMutation } from '../queries';
import { ENTITY } from '../../constants';

const ENTITY_TYPES = {
    job_name: 'header_footer',
    left_footer: 'header_footer',
    right_footer: 'header_footer'
};

interface SwitchTextfieldPropTypes {
    checked?: boolean;
    switchLabel: string;
    action: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    textValue: string;
    fieldName: string;
    maxLength?: number;
}

const SwitchTextFiled = ({ fieldName, textValue, checked, switchLabel, action, placeholder }: SwitchTextfieldPropTypes) => {
    const { bomId, documentType } = useParams();
    const { handlePreviewImage } = useContext(PreviewContext);

    const [textFieldState, setTextFieldState] = useState(textValue);

    const { mutate } = useDocumentTextFieldMutation();

    useEffect(() => {
        setTextFieldState(textValue);
    }, [textValue]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldState(event.target.value);
        handlePreviewImage(false);
    };

    const handleOnBlur = () => {
        if (typeof textFieldState === 'string') {
            const payload: UpdateTextFieldProps = {
                bomId: bomId!,
                fieldName: fieldName,
                field_value: switchLabel === 'Quote Number' ? { pre_fill: 'Custom Entry', value: textFieldState } : textFieldState,
                documentType: documentType!,
                entity: Object.hasOwn(ENTITY_TYPES, fieldName) ? ENTITY.HEADER_FOOTER_ENTITY : ENTITY.COVER_PAGE_ENTITY
            };
            mutate(payload, {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            });
        }
    };

    return (
        <ToggleText
            blurAction={handleOnBlur}
            onToggle={action}
            textFieldAction={handleOnChange}
            placeholder={placeholder}
            fieldName={fieldName}
            switchLabel={switchLabel}
            checked={checked}
            textFieldValue={textFieldState}
        />
    );
};

export default SwitchTextFiled;
