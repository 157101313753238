export const addCanvasThumbnail = (pageNumber: any, canvas: any, docViewer: any, thumbnailContainer: any) => {
    const ele = document.createElement('div');
    ele.classList.add('thumbnail-box');
    canvas.classList.add('markup-canvas');

    const thumbnailBox = document.createElement('div');
    thumbnailBox.classList.add('position-relative');
    thumbnailBox.appendChild(canvas);

    const footer = document.createElement('div');
    footer.classList.add('thumbnail-footer');

    const pageNum = document.createElement('span');

    pageNum.innerHTML = 'Page ' + pageNumber;
    footer.appendChild(pageNum);

    ele.appendChild(thumbnailBox);
    ele.appendChild(footer);

    canvas.onclick = () => {
        docViewer.setCurrentPage(pageNumber);
    };
    thumbnailContainer.appendChild(ele);
};
