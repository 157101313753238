import { useEffect, useRef, useState, Dispatch, SetStateAction, MutableRefObject } from 'react';

import WebViewer, { Core } from '@pdftron/webviewer';
import { AxiosResponse } from 'axios';

import { BodySmall, Box, Modal, ModalHeader, ModalFooter, Grid, Skeleton, BodyXS, WarningIcon, H5, BodyBig } from '@parspec/pixel';

import { getArrayBetweenNumbers, getParsedCoordinatesForWebviewer, getParsedXFDFForAPIPayload, getParsedXFDFForWebviewer, getProxyFileUrl } from 'src/features/shared/utils/utils';
import { loadCanvasWrapper } from '../../../shared/utils/utils';

import {
    useGetToolPresetStylesQuery,
    useGetCustomRubberStampQuery,
    useGetShowSuggestionStateQuery,
    useShowSuggestionStateMutation,
    useCustomRubberStampMutation,
    useToolPresetStylesMutation,
    useGetDatasheetQuery
} from './queries';
import { environment } from 'src/environments';
import { HIDDEN_XFDF } from 'src/features/shared/utils/constants';
import { SelectedDocumentResponse } from './queries/apiTypes';
import { WEBVIEWER_FOLDER_NAME } from '../../../shared/constants';
import { ReuseDialogTypeLabel } from '../../shared/utils';
import { useParspecNavigation } from 'src/app/Context';

const getOpacity = (val: any) => {
    return !val || isNaN(val) ? 1 : val;
};

enum ImportAnnotationStagesEnum {
    APPLY_TO_RANGE,
    ADJUST_PAGE,
    APPLY_TO_ALL
}

interface PdfViewerProps {
    wvInstance: any;
    setwvInstance: Dispatch<SetStateAction<any | undefined>>;
    selectedDocumentData: AxiosResponse<Array<SelectedDocumentResponse>> | undefined;
    xfdf: MutableRefObject<string | undefined>;
    setXfdf: (newXfdf: string) => void;
    selectedPages: number[];
    bomId: string;
    bodId: string;
    hiddenPagesRef: any;
    documentType: string;
    subDocumentType: string;
    getThumbnailsRef: any;
    setIsDisabledAdjustPage: React.Dispatch<React.SetStateAction<boolean>>;
}
const PdfViewer: React.FC<PdfViewerProps> = (props) => {
    const { setHasUnsavedChanges } = useParspecNavigation();

    const { selectedDocumentData, xfdf, setXfdf, selectedPages, bomId, bodId, wvInstance, setwvInstance, hiddenPagesRef, documentType, subDocumentType, getThumbnailsRef, setIsDisabledAdjustPage } =
        props;
    const { data: datasheetData } = useGetDatasheetQuery(bomId, bodId, selectedDocumentData?.data[0]?.id || 0, selectedDocumentData?.data[0]?.document || 0, documentType, subDocumentType, {
        enabled: !!selectedDocumentData?.data[0]?.id
    });
    const { data: toolPresetStyles } = useGetToolPresetStylesQuery();
    const { mutate: patchToolPresetStyles } = useToolPresetStylesMutation();
    const { data: customRubberStampsData } = useGetCustomRubberStampQuery();
    const { mutate: patchCustomRubberStampsData } = useCustomRubberStampMutation();
    const { data: showSuggestionStateData } = useGetShowSuggestionStateQuery();
    const { mutate: patchShowSuggestionStateData } = useShowSuggestionStateMutation();

    const viewer = useRef<HTMLDivElement | null>(null);
    const hiddenXfdf = useRef<string>(HIDDEN_XFDF);

    const [opacities, setOpacities] = useState<any>(null);
    const [rectanglePresetsData, setRectanglePresetsData] = useState<any>(null);
    const [rectanglePresets, setRectanglePresets] = useState<any>(null);
    const [customRubberStamps, setCustomRubberStamps] = useState<any>(null);
    const [showSuggestionState, setShowSuggestionStateData] = useState<boolean | undefined>(true);
    const [thumbnailImages, setThumbnailImages] = useState<string[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [webViewerDocument, setWebViewerDocument] = useState<Core.Document>();
    const [isLoadThumbnail, setIsLoadThumbnail] = useState(false);
    const [customStampsSetToTool, setCustomStampsSetToTool] = useState(false);

    const importAnnotationCurrentState = useRef<ImportAnnotationStagesEnum | null>(null);
    const toolsPresets = useRef<any>({});
    const highlightAnnotations = useRef<any>([]);
    const selectedRectanglePreset = useRef<string>('');
    const popupObserverOptions = useRef<any>({});
    const isStroke = useRef<boolean>(true);
    const annotationPopupObserver = useRef<any>();
    const totalPages = useRef<number>(0);
    const markupRemaining = useRef<number[]>([]);
    const suggestionsRemaining = useRef<any[]>([]);

    const defaultOpacity = 1;
    const selectedDatasheet = datasheetData?.data?.flat_filepath || '';
    const coordinates = selectedDocumentData?.data[0]?.suggestion?.coordinates;
    const auto_markup = selectedDocumentData?.data[0]?.suggestion?.auto_markup;

    const [openModal, setOpenModal] = useState(false);
    const onCancelDelete = () => setOpenModal(false);
    const onDelete = () => {
        const allAnnotations = wvInstance?.Core.annotationManager.getAnnotationsList();
        wvInstance?.Core.annotationManager.deleteAnnotations(allAnnotations);
        setOpenModal(false);
    };
    const openModalFunction = () => setOpenModal(true);
    const header = <ModalHeader title="Delete Annotations" onClose={onCancelDelete} />;
    const footer = <ModalFooter onReject={onCancelDelete} onAccept={onDelete} continueButtonLabel="Delete" />;

    useEffect(() => {
        if (selectedPages.length > 0) {
            setThumbnailImages([]);
            wvInstance.UI.openElements(['loadingModal']);
            importAnnotationCurrentState.current = ImportAnnotationStagesEnum.ADJUST_PAGE;
            wvInstance.Core.documentViewer.loadDocument(getProxyFileUrl(selectedDatasheet), {
                useDownloader: false
            });
            setIsLoadThumbnail(true);
        }
    }, [selectedPages]);

    useEffect(() => {
        if (selectedDatasheet && viewer.current) {
            WebViewer(
                {
                    licenseKey: environment.Y,
                    path: `/assets/${WEBVIEWER_FOLDER_NAME}/lib`,
                    disableLogs: true,
                    useDownloader: false,
                    initialDoc: getProxyFileUrl(selectedDatasheet),
                    disabledElements: [
                        'leftPanelButton',
                        'viewControlsButton',
                        'selectToolButton',
                        'menuButton',
                        'strikeoutToolGroupButton',
                        'squigglyToolGroupButton',
                        'markInsertTextGroupButton',
                        'markReplaceTextGroupButton',
                        'freeHandHighlightToolGroupButton',
                        'contextMenuPopup',
                        'contextMenuPopupButton',
                        'shapeToolGroupButton',
                        'richTextPopup',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'toolbarGroup-View',
                        'toolbarGroup-Annotate',
                        'toolbarGroup-Edit',
                        'toolbarGroup-FillAndSign',
                        'toolbarGroup-Forms',
                        'ribbons',
                        'stampToolGroupButton',
                        'fileAttachmentToolGroupButton',
                        'linkAnnotationPopup'
                    ]
                },

                viewer.current as HTMLDivElement
            )
                .then(async (instance) => {
                    setwvInstance(instance);
                    const proxyUrl = getProxyFileUrl(selectedDatasheet);
                    const fullDocument = await instance.Core.createDocument(proxyUrl);
                    const { createThumbnails, getThumbnailsAsync } = loadCanvasWrapper(fullDocument, fullDocument.getPageCount());
                    createThumbnails();
                    getThumbnailsRef.current = getThumbnailsAsync;
                    setIsDisabledAdjustPage(false);
                })
                .catch(() => {});
        }
    }, [selectedDatasheet, viewer.current]);

    useEffect(() => {
        if (wvInstance) {
            const { UI } = wvInstance;
            const { documentViewer, annotationManager, Tools, Math: wvMath, Annotations } = wvInstance.Core;
            const { registerTool } = UI;
            let annotationStyleIsStroke = true;
            let isAnnotationSelected = false;
            UI.disableToolDefaultStyleUpdateFromAnnotationPopup();
            class CustomRectangleAnnotation extends Annotations.CustomAnnotation {
                constructor() {
                    super('custom-rectangle-annotation');
                    this.Subject = 'CustomRectangleAnnotation';

                    const padding = this.getPadding();
                    (this as any)['fringe'] = new wvMath.Rect(padding, padding, padding, padding);
                }

                getPadding() {
                    return 0;
                }

                draw(ctx: any, pageMatrix: any) {
                    ctx.beginPath();

                    this.setStyles(ctx, pageMatrix);

                    const rect = this.getRect();
                    rect['x1'] += this.getPadding();
                    rect['y1'] += this.getPadding();
                    rect['x2'] -= this.getPadding();
                    rect['y2'] -= this.getPadding();

                    const topLeftCorner = new wvMath.Point(rect['x1'], rect['y1']);
                    const topRightCorner = new wvMath.Point(rect['x2'], rect['y1']);
                    const bottomLeftCorner = new wvMath.Point(rect['x1'], rect['y2']);
                    const bottomRightCorner = new wvMath.Point(rect['x2'], rect['y2']);

                    if (!this.ToolName) this.ToolName = this.getCustomData('toolName');
                    this.BlendMode = 'multiply';
                    this.setCustomData('isMultiply', true);
                    let stroke = parseFloat(this.getCustomData('customStrokeOpacity'));
                    let fill = parseFloat(this.getCustomData('customFillOpacity'));
                    if (!this.getCustomData('customStrokeOpacity')) {
                        const toolname = this.ToolName;
                        const index = rectanglePresets?.indexOf(toolname);
                        this.setCustomData('customStrokeOpacity', opacities[index]?.stroke ?? 1);
                        this.setCustomData('customFillOpacity', opacities[index]?.fill ?? 1);
                        stroke = opacities[index]?.stroke ?? 1;
                        fill = opacities[index]?.fill ?? 1;
                    } else if (annotationManager.isAnnotationSelected(this) && iframe.getElementsByClassName('AnnotationStylePopup').length) {
                        const popup = iframe.getElementsByClassName('AnnotationStylePopup')[0];
                        const opacityContainer = popup.querySelector('[data-element="opacitySlider"]');
                        const opValue = opacityContainer?.getElementsByClassName('slider-input-field')[0].value;
                        const formattedOp = parseFloat((parseFloat(opValue.replace('%', '')) / 100).toFixed(2));

                        if (annotationStyleIsStroke && stroke != formattedOp) {
                            this.setCustomData('customStrokeOpacity', formattedOp);
                            stroke = formattedOp;
                        } else if (!annotationStyleIsStroke && fill != formattedOp) {
                            this.setCustomData('customFillOpacity', formattedOp);
                            fill = formattedOp;
                        }
                    }

                    ctx.fillStyle = this.FillColor.toString().replace(',1)', `,${fill})`);
                    ctx.strokeStyle = this.StrokeColor.toString().replace(',1)', `,${stroke})`);
                    ctx.globalAlpha = 1;

                    const isNotNarrow = this.getWidth() >= 1 && this.getHeight() >= 1;

                    ctx.lineWidth *= isNotNarrow ? 2 : 0.5;
                    ctx.beginPath();
                    ctx.moveTo(topLeftCorner['x'], topLeftCorner['y']);
                    ctx.lineTo(topRightCorner['x'], topRightCorner['y']);
                    ctx.lineTo(bottomRightCorner['x'], bottomRightCorner['y']);
                    ctx.lineTo(bottomLeftCorner['x'], bottomLeftCorner['y']);
                    ctx.closePath();
                    if (isNotNarrow) {
                        ctx.clip();
                    }
                    ctx.fill();
                    ctx.stroke();
                }

                serialize(element: any, pageMatrix: any) {
                    if (this.ToolName) {
                        this.setCustomData('toolName', `${this.ToolName}`);
                    }
                    return super.serialize(element, pageMatrix);
                }

                deserialize(element: any, pageMatrix: any) {
                    super.deserialize(element, pageMatrix);
                }
            }

            CustomRectangleAnnotation.OutputImagePadding = 0;
            CustomRectangleAnnotation.QualityScale = 2;
            CustomRectangleAnnotation.prototype.elementName = 'custom-rectangle-annotation';

            annotationManager.registerAnnotationType(CustomRectangleAnnotation.prototype.elementName, CustomRectangleAnnotation);

            class CustomRectangleCreateTool extends Tools.GenericAnnotationCreateTool {
                constructor(documentViewer: Core.DocumentViewer) {
                    super(documentViewer, CustomRectangleAnnotation);
                }
            }

            const customRectangleTool = new CustomRectangleCreateTool(documentViewer);
            registerTool(
                {
                    toolName: 'AnnotationCreateCustomRectangle',
                    toolObject: customRectangleTool,
                    buttonImage: 'icon-tool-shape-rectangle',
                    buttonName: 'customRectangleToolButton',
                    tooltip: 'Rectangle',
                    buttonGroup: 'customRectangleTools',
                    showColor: 'always'
                },
                CustomRectangleAnnotation
            );

            const customRectangleTool2 = new CustomRectangleCreateTool(documentViewer);
            registerTool(
                {
                    toolName: 'AnnotationCreateCustomRectangle2',
                    toolObject: customRectangleTool2,
                    buttonImage: 'icon-tool-shape-rectangle',
                    buttonName: 'customRectangleToolButton2',
                    tooltip: 'Rectangle 2',
                    buttonGroup: 'customRectangleTools',
                    showColor: 'always'
                },
                CustomRectangleAnnotation
            );

            const customRectangleTool3 = new CustomRectangleCreateTool(documentViewer);
            registerTool(
                {
                    toolName: 'AnnotationCreateCustomRectangle3',
                    toolObject: customRectangleTool3,
                    buttonImage: 'icon-tool-shape-rectangle',
                    buttonName: 'customRectangleToolButton3',
                    tooltip: 'Rectangle 3',
                    buttonGroup: 'customRectangleTools',
                    showColor: 'always'
                },
                CustomRectangleAnnotation
            );

            const customRectangleTool4 = new CustomRectangleCreateTool(documentViewer);
            registerTool(
                {
                    toolName: 'AnnotationCreateCustomRectangle4',
                    toolObject: customRectangleTool4,
                    buttonImage: 'icon-tool-shape-rectangle',
                    buttonName: 'customRectangleToolButton4',
                    tooltip: 'Rectangle 4',
                    buttonGroup: 'customRectangleTools',
                    showColor: 'always'
                },
                CustomRectangleAnnotation
            );
            UI.hotkeys.on('R', (e: any) => {
                setToolModeToAnnotation();
            });
            UI.setToolbarGroup('toolbarGroup-Annotate');
            const iframe = UI.iframeWindow.document.body;
            setTimeout(() => {
                UI.setHeaderItems((header: any) => {
                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'customRectangleTools',
                        dataElement: 'customRectangleToolGroupButton',
                        title: 'Rectangle (R)'
                    });
                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'ellipseTools',
                        dataElement: 'ellipseToolGroupButton',
                        title: 'annotation.ellipse'
                    });
                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'cloudTools',
                        dataElement: 'cloudToolGroupButton',
                        title: 'Cloud'
                    });
                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'lineTools',
                        dataElement: 'lineToolGroupButton',
                        title: 'annotation.line'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'arrowTools',
                        dataElement: 'arrowToolGroupButton',
                        title: 'annotation.arrow'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'signatureTools',
                        dataElement: 'signatureToolGroupButton',
                        title: 'Signature'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'rubberStampTools',
                        dataElement: 'rubberStampToolGroupButton',
                        title: 'Rubber Stamp'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'stampTools',
                        dataElement: 'stampToolGroupButton',
                        title: 'Image (I)'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'fileAttachmentTools',
                        dataElement: 'fileAttachmentToolGroupButton',
                        title: 'File Attachment'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toolGroupButton',
                        toolGroup: 'calloutTools',
                        dataElement: 'calloutToolGroupButton',
                        title: 'Callout (C)'
                    });

                    header.getHeader('toolbarGroup-Annotate').push({
                        type: 'toggleElementButton',
                        dataElement: 'toggleNotesButton',
                        element: 'notesPanel',
                        img: 'icon-header-chat-line',
                        title: 'component.notesPanel'
                    });

                    header.push({
                        type: 'actionButton',
                        img: '/assets/icons/clear-all.svg',
                        onClick: openModalFunction,
                        title: 'Remove all existing annotations',
                        dataElement: 'alertButton'
                    });

                    header.push({
                        type: 'statefulButton',
                        initialState: showSuggestionState ? 'Show' : 'Hide',
                        states: {
                            Show: {
                                img: '/assets/icons/eye.svg',
                                onClick: (update: any) => {
                                    wvInstance.Core.annotationManager.hideAnnotations(highlightAnnotations.current);
                                    setShowSuggestionStateData(false);
                                    patchShowSuggestionStateData({ annotation_suggestions_status: false });
                                    update('Hide');
                                },
                                title: 'Hide suggested annotations'
                            },
                            Hide: {
                                img: '/assets/icons/off.svg',
                                onClick: (update: any) => {
                                    wvInstance.Core.annotationManager.showAnnotations(highlightAnnotations.current);
                                    setShowSuggestionStateData(true);
                                    patchShowSuggestionStateData({ annotation_suggestions_status: true });
                                    update('Show');
                                },
                                title: 'Show suggested annotations'
                            },

                            dataElement: 'showAnnotationButton'
                        }
                    });

                    header.getHeader('default').delete(1);
                    header.update();

                    header.getHeader('default').delete(3);
                    header.update();
                    header.getHeader('default').delete(4);
                    header.update();

                    header.getHeader('default').delete('toggleNotesButton');
                    header.update();
                });

                UI.setHeaderItems((header: any) => {
                    let searchButtonElem = {};
                    const items = header.getItems().filter((item: Record<string, any>) => {
                        if (item.dataElement === 'zoomOverlayButton' || item.toolName === 'Pan') {
                            return true;
                        }
                        if (item.dataElement === 'searchButton') {
                            searchButtonElem = { ...item };
                        }
                        return false;
                    });
                    items.unshift({ type: 'spacer' });
                    items.push({ type: 'spacer' });
                    items.push(searchButtonElem);
                    header.update(items);
                });
            }, 0);
            documentViewer.addEventListener('toolModeUpdated', (e: any) => {
                updateSelectedRectanglePreset(e.name);
            });
            documentViewer.addEventListener('toolUpdated', (e: any) => {
                if (toolsPresets.current[e.name]) {
                    if (toolsPresets.current[e.name].init) {
                        toolsPresets.current[e.name].init = false;
                    } else {
                        const isRectangleTool = e.name.includes('Rectangle');
                        const presetToSend = { ...e.defaults };
                        if (isRectangleTool) {
                            const index = rectanglePresets.indexOf(e.name);
                            if (index >= 0) {
                                if (isStroke.current) {
                                    const oldValue = opacities[index].stroke;
                                    const newValue = e.defaults.Opacity;
                                    if (oldValue !== newValue) {
                                        const newOp = { ...opacities };
                                        newOp[index].stroke = newValue;
                                        setOpacities(newOp);
                                    }
                                    presetToSend.StrokeOpacity = opacities[index].stroke;
                                    presetToSend.Opacity = opacities[index].fill;
                                } else {
                                    const oldValue = opacities[index].fill;
                                    const newValue = e.defaults.Opacity;
                                    if (oldValue !== newValue) {
                                        const newOp = { ...opacities };
                                        newOp[index].fill = newValue;
                                        setOpacities(newOp);
                                    }
                                    presetToSend.Opacity = opacities[index].fill;
                                    presetToSend.StrokeOpacity = opacities[index].stroke;
                                }
                            }
                            changeAnnotationsGlobalVar(presetToSend, e.name);
                        }
                        if (e.name.includes('AnnotationCreateFreeText')) {
                            presetToSend.isAutoSizeFont = presetToSend.isAutoSizeFont.toString();
                        }
                        const formData = new FormData();
                        formData.append('tool_name', isRectangleTool ? e.name.replace('CreateCustomRectangle', 'CreateRectangle') : e.name);
                        formData.append('preset', JSON.stringify(presetToSend));
                        patchToolPresetStyles(formData);
                    }
                }
            });
            const rubberStampTool = documentViewer.getToolModeMap()['AnnotationCreateRubberStamp'];
            rubberStampTool.addEventListener(Tools.RubberStampCreateTool.Events.STAMPS_UPDATED, () => {
                saveCustomStamps();
            });
            documentViewer.addEventListener('documentLoaded', async () => {
                setPageIndex(0);
                setWebViewerDocument(documentViewer.getDocument());
                UI.setFitMode(UI.FitMode.FitWidth);
                totalPages.current = documentViewer.getPageCount();
                setPresetsData();
                const defaultRectangle = iframe.querySelector('[data-element="rectangleToolButton"]');
                if (defaultRectangle) {
                    setToolModeToAnnotation();
                }
                await wvInstance.Core.documentViewer.getDocument().removePages(hiddenPagesRef?.current);
                wvInstance.UI.closeElements(['loadingModal']);
                updateSelectedRectanglePreset(documentViewer.getToolMode().name || '');
                //import
                importAnnotations();
                exportAndParseAnnotations();
                //coordinate highlights
                if (importAnnotationCurrentState.current !== ImportAnnotationStagesEnum.ADJUST_PAGE) {
                    markupRemaining.current = [...hiddenPagesRef.current];
                    for (const key in coordinates) {
                        const value = coordinates[key];
                        const lowerCasePdfExtensionKey = key.slice(0, key.length - 3) + key.slice(key.length - 3).toLowerCase();
                        const lowerCasePdfExtensionPdfUrl = selectedDatasheet.slice(0, selectedDatasheet.length - 3) + selectedDatasheet.slice(selectedDatasheet.length - 3).toLowerCase();
                        if (lowerCasePdfExtensionPdfUrl == lowerCasePdfExtensionKey) {
                            const selectedPages = getArrayBetweenNumbers(1, totalPages.current).filter((item) => !hiddenPagesRef.current?.includes(item));

                            const { mutatedCoordinates, hiddenSuggestions } = getParsedCoordinatesForWebviewer(value, selectedPages);
                            const adjustedCoordinatesValue = [...mutatedCoordinates];
                            suggestionsRemaining.current = hiddenSuggestions;
                            for (const i in adjustedCoordinatesValue) {
                                addCoordinateSuggestionHighlight(adjustedCoordinatesValue[i]);
                            }
                        }
                    }
                    showSuggestionState ? annotationManager.showAnnotations(highlightAnnotations.current) : annotationManager.hideAnnotations(highlightAnnotations.current);
                    if (auto_markup) {
                        addMarkupSuggestionHighlights(false, []);
                    }
                } else {
                    const selectedPages = getArrayBetweenNumbers(1, totalPages.current).filter((item) => !hiddenPagesRef.current?.includes(item));
                    if (suggestionsRemaining.current.length) {
                        const { mutatedCoordinates, hiddenSuggestions } = getParsedCoordinatesForWebviewer(suggestionsRemaining.current, selectedPages);
                        const adjustedCoordinatesValue = [...mutatedCoordinates];
                        suggestionsRemaining.current = hiddenSuggestions;
                        for (const i in adjustedCoordinatesValue) {
                            addCoordinateSuggestionHighlight(adjustedCoordinatesValue[i]);
                        }
                        showSuggestionState ? annotationManager.showAnnotations(highlightAnnotations.current) : annotationManager.hideAnnotations(highlightAnnotations.current);
                    }
                    const newIndices: any[] = [];
                    markupRemaining.current.forEach((ele: any) => {
                        if (!hiddenPagesRef.current?.includes(ele)) {
                            newIndices.push(ele);
                        }
                    });

                    const markupPages: any[] = [];
                    newIndices.forEach((ele: any) => {
                        const arrayIndex = markupRemaining.current.indexOf(ele);
                        if (arrayIndex > -1) {
                            markupRemaining.current.splice(arrayIndex, 1);
                        }
                        const newIndex = selectedPages.indexOf(ele);
                        markupPages.push(newIndex + 1);
                    });
                    if (newIndices.length && auto_markup) {
                        addMarkupSuggestionHighlights(true, markupPages);
                    }
                }
                importAnnotationCurrentState.current = null;
                //toolpopup
                setTimeout(() => {
                    const mList = iframe.getElementsByClassName('ToolsOverlay')[0];

                    popupObserverOptions.current = {
                        childList: true,
                        attributes: true,
                        attributeFilter: ['class'],
                        subtree: true
                    };

                    const observer = new MutationObserver((mutations: any) => {
                        if (selectedRectanglePreset.current) {
                            for (const mutation of mutations) {
                                if (mutation.type === 'childList' && mutation.addedNodes.length && mutation.addedNodes[0].className === 'ToolStylePopup') {
                                    const borderStyle = mList.getElementsByClassName('StyleOption');
                                    if (borderStyle.length) {
                                        borderStyle[0].style.display = 'none'; //removing style option from popup, ie:solid, cloudy
                                    }
                                    const activeTab = iframe.getElementsByClassName('palette-options-button active');
                                    if (activeTab.length && activeTab[0].innerText === 'Fill') {
                                        isStroke.current = false;
                                    } else {
                                        isStroke.current = true;
                                    }
                                    if (toolsPresets?.current?.[selectedRectanglePreset.current]?.init) {
                                        toolsPresets.current[selectedRectanglePreset.current].init = true;
                                    }
                                    if (isStroke.current) {
                                        documentViewer.getTool(selectedRectanglePreset.current).setStyles(() => ({
                                            Opacity: opacities[rectanglePresets.indexOf(selectedRectanglePreset.current)]?.stroke ?? defaultOpacity
                                        }));
                                    } else {
                                        documentViewer.getTool(selectedRectanglePreset.current).setStyles(() => ({
                                            Opacity: opacities[rectanglePresets.indexOf(selectedRectanglePreset.current)]?.fill ?? defaultOpacity
                                        }));
                                    }
                                } else if (mutation.type === 'attributes' && mutation.target.classList.contains('active') && mutation.target.classList.contains('palette-options-button')) {
                                    toolsPresets.current[selectedRectanglePreset.current].init = true;
                                    if (mutation.target.innerText === 'Stroke') {
                                        isStroke.current = true;
                                        documentViewer.getTool(selectedRectanglePreset.current).setStyles(() => ({
                                            Opacity: opacities[rectanglePresets.indexOf(selectedRectanglePreset.current)]?.stroke ?? defaultOpacity
                                        }));
                                    } else if (mutation.target.innerText === 'Fill') {
                                        isStroke.current = false;
                                        documentViewer.getTool(selectedRectanglePreset.current).setStyles(() => ({
                                            Opacity: opacities[rectanglePresets.indexOf(selectedRectanglePreset.current)]?.fill ?? defaultOpacity
                                        }));
                                    }
                                }
                            }
                        }
                    });
                    observer?.observe(mList, popupObserverOptions.current);
                }, 0);

                //annotation popup
                annotationPopupObserver.current = new MutationObserver((mutations: any) => {
                    if (isAnnotationSelected) {
                        for (const mutation of mutations) {
                            if (mutation.type === 'childList' && mutation.addedNodes.length && mutation.addedNodes[0].classList.contains('AnnotationStylePopup')) {
                                const parentElement = iframe.getElementsByClassName('AnnotationPopup')[0];
                                const borderStyle = parentElement.getElementsByClassName('StyleOption');
                                if (borderStyle.length) {
                                    borderStyle[0].style.display = 'none';
                                }
                                const activeTab = iframe.getElementsByClassName('palette-options-button active');
                                if (activeTab.length && activeTab[0].innerText === 'Fill') {
                                    annotationStyleIsStroke = false;
                                } else {
                                    annotationStyleIsStroke = true;
                                }
                                const selectedAnnots = annotationManager.getSelectedAnnotations();
                                if (annotationStyleIsStroke) {
                                    selectedAnnots[0].Opacity = getOpacity(selectedAnnots[0].getCustomData('customStrokeOpacity'));
                                } else {
                                    selectedAnnots[0].Opacity = getOpacity(selectedAnnots[0].getCustomData('customFillOpacity'));
                                }
                            } else if (mutation.type === 'attributes' && mutation.target.classList.contains('active') && mutation.target.classList.contains('palette-options-button')) {
                                const selectedAnnots = annotationManager.getSelectedAnnotations();
                                if (mutation.target.innerText === 'Stroke') {
                                    annotationStyleIsStroke = true;
                                    selectedAnnots[0].Opacity = getOpacity(selectedAnnots[0].getCustomData('customStrokeOpacity'));
                                } else if (mutation.target.innerText === 'Fill') {
                                    annotationStyleIsStroke = false;
                                    selectedAnnots[0].Opacity = getOpacity(selectedAnnots[0].getCustomData('customFillOpacity'));
                                }
                            }
                        }
                    }
                });
            });

            annotationManager.addEventListener('annotationChanged', (annotations: any, _action: any, { imported }: any) => {
                if (imported) {
                    annotations.forEach((annot: any) => {
                        if (annot instanceof Annotations.RectangleAnnotation) {
                            const annotNew = new CustomRectangleAnnotation();
                            annotNew.setCustomData('toolName', annot.ToolName.replace('CreateRectangle', 'CreateCustomRectangle'));
                            annotNew.setCustomData('customStrokeOpacity', annot.getCustomData('newStrokeOpacity'));
                            annotNew.setCustomData('customFillOpacity', annot.getCustomData('newFillOpacity'));

                            annotNew.StrokeColor = annot.StrokeColor;
                            annotNew.StrokeColor.A = 1;
                            annotNew.FillColor = annot.FillColor;
                            if (annot.FillColor.A !== 0) annotNew.FillColor.A = 1;
                            annotNew.StrokeThickness = annot.StrokeThickness;
                            annotNew.Opacity = 1;
                            annotNew.X = annot.X;
                            annotNew.Width = annot.Width;
                            annotNew.Y = annot.Y;
                            annotNew.Height = annot.Height;
                            annotNew.PageNumber = annot.PageNumber;

                            annotationManager.addAnnotation(annotNew);
                            annotationManager.deleteAnnotation(annot);
                        }
                    });

                    return;
                }
                // auto saving annotations (excluding custom search ones)
                if (!annotations?.[0]?.getCustomData('IsCustomSearch')) {
                    setHasUnsavedChanges(true);
                    exportAndParseAnnotations();
                }
            });
            annotationManager.addEventListener('annotationSelected', (annots: any, action: any) => {
                if (action === 'deselected') {
                    isAnnotationSelected = false;
                    annotationPopupObserver.current?.disconnect();
                } else if (action === 'selected' && annots[0].ToolName?.includes('Rectangle')) {
                    isAnnotationSelected = true;
                    setTimeout(() => {
                        const popupObserverList = iframe.getElementsByClassName('AnnotationPopup')[0];
                        annotationPopupObserver?.current?.observe(popupObserverList, popupObserverOptions.current);
                    }, 500);
                }
                if (action === 'selected') {
                    if (!annots) return;

                    const toolHeader = iframe.getElementsByClassName('HeaderToolsContainer');
                    const rectangleTool = toolHeader[0].querySelector('[data-element="customRectangleToolGroupButton"]');
                    let selectedPresetTool: any;
                    let toolPresetButtons: any = [];

                    const toolPresetContainer = toolHeader[0].querySelector('.tool-buttons-container');

                    if (rectangleTool.classList.contains('active')) {
                        toolPresetButtons = toolPresetContainer.querySelectorAll('.tool-button-container');
                        selectedPresetTool = toolPresetButtons[0];
                        toolPresetButtons.forEach((preset: any) => {
                            if (preset.getElementsByTagName('button')[0].classList.contains('active')) {
                                selectedPresetTool = preset;
                            }
                        });
                    }

                    annots.forEach((annot: any) => {
                        // check that our custom 'IsCustomSearch' property is set
                        let needClick: boolean;
                        if (annot instanceof Annotations.TextHighlightAnnotation && annot.getCustomData('IsCustomSearch')) {
                            UI.disableElements(['annotationPopup']);
                            const textQuad = annot.Quads[0];

                            const annotNew = new CustomRectangleAnnotation();
                            if (selectedRectanglePreset.current) {
                                annotNew.setCustomData('toolName', `${selectedRectanglePreset.current}`);
                                annotNew.setCustomData('isMultiply', true);
                                needClick = false;
                                const index = rectanglePresets.indexOf(selectedRectanglePreset.current);
                                const strokecolor = rectanglePresetsData[index].strokeColor;
                                const fillcolor = rectanglePresetsData[index].fillColor;
                                annotNew.setCustomData('customStrokeOpacity', opacities[index].stroke);
                                annotNew.setCustomData('customFillOpacity', opacities[index].fill);
                                annotNew.StrokeColor = new Annotations.Color(strokecolor.R, strokecolor.G, strokecolor.B, 1);
                                annotNew.FillColor = new Annotations.Color(fillcolor.R, fillcolor.G, fillcolor.B, fillcolor.A !== 0 ? 1 : 0);
                                annotNew.StrokeThickness = rectanglePresetsData[index].strokeThickness;
                            } else {
                                annotNew.setCustomData('toolName', 'AnnotationCreateCustomRectangle');
                                annotNew.setCustomData('customStrokeOpacity', opacities[0].stroke);
                                annotNew.setCustomData('customFillOpacity', opacities[0].fill);
                                needClick = true;
                                rectangleTool.click();
                                const strokecolor = rectanglePresetsData[0].strokeColor;
                                const fillcolor = rectanglePresetsData[0].fillColor;
                                annotNew.StrokeColor = new Annotations.Color(strokecolor.R, strokecolor.G, strokecolor.B, 1);
                                annotNew.FillColor = new Annotations.Color(fillcolor.R, fillcolor.G, fillcolor.B, fillcolor.A !== 0 ? 1 : 0);
                                annotNew.StrokeThickness = rectanglePresetsData[0].strokeThickness;
                            }

                            annotNew.Opacity = 1;

                            if (annot.getCustomData('IsAutoMarkup')) {
                                annotNew.X = textQuad.x1 - annotNew.StrokeThickness;
                                annotNew.Width = textQuad.x2 - textQuad.x1 + 2 * annotNew.StrokeThickness;

                                annotNew.Y = textQuad.y4 - annotNew.StrokeThickness - 1;
                                annotNew.Height = textQuad.y1 - textQuad.y4 + 2 * annotNew.StrokeThickness;
                            } else {
                                annotNew.X = textQuad.x1;
                                annotNew.Width = textQuad.x2 - textQuad.x1;

                                annotNew.Y = textQuad.y4;
                                annotNew.Height = textQuad.y1 - textQuad.y4;
                            }

                            annotNew.PageNumber = annot.PageNumber;

                            annotationManager.addAnnotation(annotNew);
                            setToolModeToAnnotation();
                            if (needClick) {
                                const toolPresetContainer = toolHeader[0].querySelector('.tool-buttons-container');
                                const toolPresetButtons = toolPresetContainer.querySelectorAll('.tool-button-container');
                                toolPresetButtons[2].getElementsByTagName('button')[0].click();
                                toolPresetButtons[0].getElementsByTagName('button')[0].click();
                            } else if (selectedPresetTool !== toolPresetButtons[0]) {
                                selectedPresetTool.getElementsByTagName('button')[0].click();
                            }
                            annotationManager.deleteAnnotations([annot]);
                        } else {
                            UI.enableElements(['annotationPopup']);
                        }
                    });
                }
            });
        }
    }, [wvInstance]);

    /** Load thumbnails  */
    useEffect(() => {
        if (webViewerDocument) {
            const batchSize = 5;
            const totalPageCount = webViewerDocument?.getPageCount();

            const loadNextBatch = async (startIndex: number) => {
                if (isLoadThumbnail) {
                    setThumbnailImages([]);
                    setIsLoadThumbnail(false);
                    return;
                }
                const endIndex = Math.min(startIndex + batchSize, totalPageCount);
                const totalUnhiddenPages = totalPageCount - hiddenPagesRef.current.length;
                for (let i = startIndex; i < endIndex; i++) {
                    try {
                        await new Promise<void>((resolve, reject) => {
                            webViewerDocument?.loadThumbnail(i + 1, (canvas: HTMLCanvasElement) => {
                                const imageUrl = canvas.toDataURL();
                                setThumbnailImages((prevImages) => [...prevImages, imageUrl]);
                                resolve();
                            });

                            reject();
                        });
                    } catch (e: any) {
                        /* empty */
                    }
                }

                if (endIndex < totalUnhiddenPages) {
                    setPageIndex(endIndex);
                    loadNextBatch(endIndex);
                }
            };

            loadNextBatch(pageIndex);
        }
    }, [webViewerDocument, isLoadThumbnail]);

    useEffect(() => {
        if (toolPresetStyles) {
            const opacities = [],
                rectanglePresetsData = [],
                rectanglePresets = [];
            const rectPresetTool = toolPresetStyles.data.find((tool) => tool.tool_name === 'AnnotationCreateRectangle');
            if (rectPresetTool) {
                for (const pre in rectPresetTool.preset) {
                    opacities.push({
                        stroke: rectPresetTool.preset[pre].StrokeOpacity ?? 1,
                        fill: rectPresetTool.preset[pre].Opacity
                    });
                    rectanglePresetsData.push({
                        strokeColor: rectPresetTool.preset[pre].StrokeColor,
                        fillColor: rectPresetTool.preset[pre].FillColor,
                        strokeThickness: rectPresetTool.preset[pre].StrokeThickness || 2
                    });
                    rectanglePresets.push(pre.replace('CreateRectangle', 'CreateCustomRectangle'));
                }
                setOpacities(opacities);
                setRectanglePresetsData(rectanglePresetsData);
                setRectanglePresets(rectanglePresets);
            }
        }
    }, [toolPresetStyles]);

    useEffect(() => {
        if (customRubberStampsData) {
            setCustomRubberStamps(JSON.parse(customRubberStampsData.data[0].preset));
            if (wvInstance && !customStampsSetToTool) {
                const { documentViewer } = wvInstance.Core;
                const toolRS = documentViewer.getTool('AnnotationCreateRubberStamp');
                toolRS.setCustomStamps(JSON.parse(customRubberStampsData.data[0].preset));
                setCustomStampsSetToTool(true);
            }
        }
    }, [customRubberStampsData?.data]);

    useEffect(() => {
        setShowSuggestionStateData(showSuggestionStateData?.data.annotation_suggestions_status);
    }, [showSuggestionStateData]);

    const setPresetsData = () => {
        if (toolPresetStyles) {
            const { Core } = wvInstance;
            const { documentViewer, Annotations } = Core;
            for (let i = 0; i < toolPresetStyles.data.length; i++) {
                Object.entries<any>(toolPresetStyles?.data[i]?.['preset'] || {}).forEach(([key, value]) => {
                    const tool = key.includes('CreateRectangle') ? key.replace('CreateRectangle', 'CreateCustomRectangle') : key;
                    toolsPresets.current[tool] = { init: true };
                    documentViewer.getTool(tool).setStyles({
                        TextColor: new Annotations.Color(value?.TextColor?.R, value?.TextColor?.G, value?.TextColor?.B, value?.TextColor?.A),
                        StrokeColor: new Annotations.Color(value?.StrokeColor?.R, value?.StrokeColor?.G, value?.StrokeColor?.B, 1),
                        FillColor: new Annotations.Color(value?.FillColor?.R, value?.FillColor?.G, value?.FillColor?.B, value?.FillColor?.A !== 0 ? 1 : 0),
                        Font: value?.Font,
                        FontSize: value?.FontSize,
                        ...(hasStroke(toolPresetStyles.data[i]['tool_name']) && {
                            StrokeThickness: value?.StrokeThickness
                        }),
                        Opacity: key.includes('Rectangle') ? 1 : value?.Opacity,
                        ...(value.RichTextStyle && {
                            RichTextStyle: value.RichTextStyle
                        }),
                        TextAlign: value?.TextAlign,
                        TextVerticalAlign: value?.TextVerticalAlign,
                        ...(value.isAutoSizeFont && {
                            isAutoSizeFont: value.isAutoSizeFont === 'true' ? true : false
                        })
                    });
                });
            }
        }
        if (customRubberStamps) {
            const { documentViewer } = wvInstance.Core;
            const toolRS = documentViewer.getTool('AnnotationCreateRubberStamp');
            toolRS.setCustomStamps(customRubberStamps);
            setCustomStampsSetToTool(true);
        }
    };

    const hasStroke = (tool: string) => {
        const noStroke = ['AnnotationCreateTextHighlight', 'AnnotationCreateTextUnderline', 'AnnotationCreateSticky'];
        if (noStroke.includes(tool)) {
            return false;
        }
        return true;
    };

    const saveCustomStamps = () => {
        const toolRS = wvInstance.Core.documentViewer.getTool('AnnotationCreateRubberStamp');
        const customStamps = toolRS.getCustomStamps();
        customStamps.forEach((stamp: any) => {
            const color = stamp.textColor;
            if (typeof color !== 'string' && color.R === 255 && color.G === 255 && color.B === 255) {
                stamp.textColor = '#ffffff';
            }
        });
        const formData = new FormData();
        formData.append('tool_name', 'AnnotationCreateRubberStamp');
        formData.append('preset', JSON.stringify(customStamps));
        patchCustomRubberStampsData(formData);
    };
    const updateSelectedRectanglePreset = (e: string) => {
        if (e.includes('Rectangle')) {
            selectedRectanglePreset.current = e;
        } else {
            selectedRectanglePreset.current = '';
        }
    };
    const importAnnotations = async () => {
        const { annotationManager } = wvInstance.Core;
        const selectedPages = getArrayBetweenNumbers(1, totalPages.current).filter((item) => !hiddenPagesRef.current?.includes(item));
        if (xfdf.current) {
            hiddenXfdf.current = HIDDEN_XFDF;
            const { mutatedXfdf, hiddenXfdf: parsedHiddenXfdf } = getParsedXFDFForWebviewer(xfdf.current, selectedPages, hiddenPagesRef.current, hiddenXfdf.current);
            setXfdf(mutatedXfdf);
            hiddenXfdf.current = parsedHiddenXfdf;
            await annotationManager.importAnnotations(mutatedXfdf);
            if (importAnnotationCurrentState.current === ImportAnnotationStagesEnum.ADJUST_PAGE) updateSuggestionsState();
        }
    };

    const updateSuggestionsState = () => {
        const { Core } = wvInstance;
        const { annotationManager } = Core;
        const allAnnotations = annotationManager.getAnnotationsList();
        highlightAnnotations.current = [];
        allAnnotations?.forEach((annotation: any) => {
            if (annotation.getCustomData('IsCustomSearch')) {
                highlightAnnotations.current.push(annotation);
            }
        });
        showSuggestionState ? annotationManager.showAnnotations(highlightAnnotations.current) : annotationManager.hideAnnotations(highlightAnnotations.current);
    };

    const exportAndParseAnnotations = async () => {
        const selectedPages = getArrayBetweenNumbers(1, totalPages.current).filter((item) => !hiddenPagesRef.current.includes(item));
        const { annotationManager } = wvInstance.Core;
        annotationManager.exportAnnotations().then(async (xfdfString: any) => {
            const parsedXfdf = getParsedXFDFForAPIPayload(xfdfString, selectedPages, hiddenXfdf.current);
            setXfdf(parsedXfdf);
        });
    };

    const addCoordinateSuggestionHighlight = (suggestion: any) => {
        const { Core } = wvInstance;
        const { annotationManager, Annotations } = Core;
        const highlight = new Annotations.TextHighlightAnnotation();

        highlight.PageNumber = parseInt(suggestion['page']) + 1;

        highlight.FillColor = new Annotations.Color(127, 255, 0, 1);

        highlight.StrokeColor = new Annotations.Color(127, 255, 0, 1);

        highlight.Quads = [new Annotations.Quad(suggestion['x1'], suggestion['y1'], suggestion['x2'], suggestion['y1'], suggestion['x2'], suggestion['y2'], suggestion['x1'], suggestion['y2'])];

        highlight.Opacity = 0.3;
        highlight.IsCustomSearch = true;
        highlight.setCustomData('IsCustomSearch', 'true');

        annotationManager.addAnnotation(highlight);
        annotationManager.drawAnnotations(highlight.PageNumber);
        highlightAnnotations.current.push(highlight);
    };
    const addMarkupSuggestionHighlights = (adjustedSelection: boolean, pages: number[]) => {
        const { Core, UI } = wvInstance;
        const { Annotations, documentViewer, annotationManager } = Core;
        const regexFromMyArray = auto_markup?.filter((ele: any) => ele).join('|');
        // documentViewer.setSearchHighlightColors({
        //     searchResult: 'rgba(0, 255, 0, 0.5)',
        //     activeSearchResult: 'rgba(0, 255, 0, 0.5)'
        // });
        UI.closeElements(['searchPanel']);

        const mode = Core.Search.Mode.PAGE_STOP | Core.Search.Mode.HIGHLIGHT | Core.Search.Mode.REGEX;
        const searchOptions = {
            // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
            fullSearch: true,
            // The callback function that is called when the search returns a result.
            onResult: (result: any) => {
                if (adjustedSelection && !pages.includes(result.pageNum)) {
                    return;
                }
                // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
                Core.Search.ResultCode.PAGE = result.pageNum;

                const textQuad = result.quads[0].getPoints();
                const annot = new Annotations.TextHighlightAnnotation();
                const pageNum = result.pageNum;

                annot.X = textQuad.x1 - 3;
                annot.Width = textQuad.x2 - textQuad.x1 + 6;

                annot.Y = textQuad.y3;
                annot.Height = textQuad.y1 - textQuad.y3;
                annot.FillColor = new Annotations.Color(127, 255, 0, 1);
                annot.StrokeColor = new Annotations.Color(127, 255, 0, 1);
                annot.Opacity = 0.3;
                annot.Quads = [textQuad];
                annot.PageNumber = pageNum;
                // add custom property so we can check in annotationSelected function
                annot.setCustomData('IsCustomSearch', 'true');
                annot.setCustomData('IsAutoMarkup', 'true');
                annotationManager.addAnnotation(annot);
                annotationManager.drawAnnotations(pageNum);
                highlightAnnotations.current.push(annot);
            },
            onDocumentEnd: () => {
                exportAndParseAnnotations();
                showSuggestionState ? annotationManager.showAnnotations(highlightAnnotations.current) : annotationManager.hideAnnotations(highlightAnnotations.current);
            }
        };
        documentViewer.textSearchInit(regexFromMyArray, mode, searchOptions);
    };

    const setToolModeToAnnotation = () => {
        const { documentViewer } = wvInstance.Core;
        documentViewer.setToolMode(documentViewer.getTool('AnnotationCreateCustomRectangle'));
    };

    const changeAnnotationsGlobalVar = (presetDefaults: any, selectedPreset: string) => {
        const index = rectanglePresets.indexOf(selectedPreset);
        const rpd = { ...rectanglePresetsData };
        rpd[index].strokeColor = presetDefaults.StrokeColor;
        rpd[index].fillColor = presetDefaults.FillColor;
        rpd[index].strokeThickness = presetDefaults.StrokeThickness;
        setRectanglePresetsData(rpd);
    };
    const isShowPdfView = !(datasheetData?.data.total_pages === selectedDocumentData?.data[0].hidden_pages.length);
    const documentLabel = ReuseDialogTypeLabel[subDocumentType as keyof typeof ReuseDialogTypeLabel];

    return (
        <>
            <Grid container flexWrap="nowrap" height={`calc(100vh - ${documentType === 'o_m' ? '107px' : '140px'})`} position="relative" display={isShowPdfView ? 'flex' : 'none'}>
                <Grid container item xs="auto" flexDirection="column" flexWrap="nowrap" gap={2} p={2} bgcolor="secondary.light" overflow="auto">
                    {thumbnailImages.length
                        ? thumbnailImages?.map((image, index) => {
                              return (
                                  <Grid container item key={index} gap={2} flexDirection="column" p={2}>
                                      <img
                                          src={image}
                                          width="168px"
                                          height="220px"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => wvInstance?.Core.documentViewer.setCurrentPage(index + 1)}
                                          alt="thumbnail"
                                      />
                                      <BodyXS color="neutral.light">Page {index + 1}</BodyXS>
                                  </Grid>
                              );
                          })
                        : getArrayBetweenNumbers(1, 3).map((_page, index) => {
                              return (
                                  <Grid item key={index} p={2}>
                                      <Skeleton variant="rectangular" width={168} height={220} key={index} color="light" />
                                  </Grid>
                              );
                          })}
                </Grid>
                <Grid item width={1} height={1}>
                    {!wvInstance && <Skeleton variant="rectangular" width="100%" height="100%" />}
                    <Box className="webviewer" height={wvInstance ? 1 : 0} ref={viewer}></Box>
                </Grid>
            </Grid>
            {!isShowPdfView && (
                <Box height="calc(100vh - 141px)" display={'flex'} flexDirection="column" justifyContent="center" alignItems="center">
                    <Box fontSize={80}>
                        <WarningIcon fontSize="inherit" color="tertiary" />
                    </Box>
                    <H5 color="text.secondary" m={1}>
                        No pages recognized for this product
                    </H5>
                    <BodyBig color="text.secondary"> Adjust page selection or change the {documentLabel} to continue</BodyBig>
                </Box>
            )}
            <Modal onClose={onCancelDelete} header={header} footer={footer} open={openModal}>
                <BodySmall>Are you sure you want to delete all annotations made on this datasheet?</BodySmall>
            </Modal>
        </>
    );
};
export default PdfViewer;
