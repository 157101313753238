import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';

import { endPoints, getTaxPricing, putTaxPricing } from './api';
import { queryClient } from 'src/app/queryClient';
import { TaxesPricingRequestOrResponseType } from './apiTypes';

const getTaxesPricingKey = (bomId: number) => [endPoints.getTaxPricingUrl(bomId)];

export function invalidaTaxesPricingQuery(bomId: number) {
    queryClient.invalidateQueries(getTaxesPricingKey(bomId));
}

export function useTaxesQuery(bomId: number, queryOptions: Omit<UseQueryOptions<AxiosResponse<TaxesPricingRequestOrResponseType>>, 'queryKey' | 'queryFn'>) {
    return useQuery(getTaxesPricingKey(bomId), () => getTaxPricing(bomId), queryOptions);
}

export function useTaxesPutMutation() {
    return useMutation(putTaxPricing, {
        onSuccess: (data, variables) => {
            const { bomId } = variables;
            queryClient.setQueryData(getTaxesPricingKey(bomId), data);
        }
    });
}
