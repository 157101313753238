import React, { useState, useEffect, useCallback } from 'react';

import { BodyMedium, Box, dayjs, Dayjs, Grid, SelectChangeEvent, Switch } from '@parspec/pixel';

import ToggleText from '../../../shared/ToggleText';
import { useHeaderFooterDataQuery, useUpdateHeaderFooterTextMutation, useUpdateHeaderFooterToggleMutation } from '../../queries';
import { useQuoteContext } from '../../QuoteContext';
import ToggleDateWithFontAndColor from '../../shared/ToggleDateWithFontAndColor';
import HeaderLogo from './HeaderLogo';
import { DateFontType, FontSelectOptions } from '../../queries/apiTypes';
import TokenForFields from 'src/features/BOM/FinalDocuments/Compile/shared/TokenForFields';
import { quoteHeaderFooterFields, ERROR_KEYS } from '../../../constants';
import { optionsForNumber } from '../../shared/utils';
import { useGetBomDetails } from 'src/features/BOM/queries';
import { useGetProjectDetailsQuery } from 'src/features/Project/queries';
import { OPTIONS_FOR_ADDRESSED_TO, OPTIONS_FOR_NAME, ADDRESSED_TO_SELECT_OPTIONS } from '../../../shared/utils/constants';
import { IPrefillPayload } from '../../../shared/utils/helper';
import { getUnifiedStrFromApi } from 'src/features/shared/utils/utils';
import ApplyToAllBomsButton from '../../../shared/ApplyToAllBomsButton';
import { HEADER_SECTION_NAME } from '../../shared/constants';

type HeaderFooterSectionType = 'job_name' | 'addressed_to' | 'project_location' | 'contact' | 'quote_number' | 'left_footer' | 'right_footer' | 'date' | 'page_number' | 'hyperlink';

type DateSectionType = 'bid_date' | 'expiration_date';

type TextStateType = {
    [index in HeaderFooterSectionType]: any;
};

type Dates = {
    [index in DateSectionType]: DateFontType;
};

enum headerFooterLabels {
    jobName = 'Job Name',
    addressTo = 'Addressed To',
    projectLocation = 'Project Location',
    contact = 'Contact',
    quoteNumber = 'Quote Number',
    leftFooter = 'Left Footer',
    rightFooter = 'Right Footer',
    pageNumber = 'Page Number',
    bidDate = 'Bid Date',
    expiryDate = 'Expiration Date',
    date = 'Date',
    hyperLink = 'Hyperlink to Quote Start'
}

const footerFields = ['left_footer', 'right_footer', 'date', 'page_number', 'hyperlink'];

function HeaderAndFooter() {
    const { handlePdfPreview, isInitiateApiCalls, isTextFieldChanged, handleTextFieldChanged, bomId = '', handleUpdateChangedSections } = useQuoteContext();

    const { data: headerFooterData, isLoading } = useHeaderFooterDataQuery(bomId, isInitiateApiCalls);
    const { data: bomData } = useGetBomDetails(bomId, {
        enabled: Boolean(bomId) && isInitiateApiCalls
    });
    const { data: projectData } = useGetProjectDetailsQuery(Number(bomData?.data.project)!, {
        enabled: Boolean(bomData?.data.project) && isInitiateApiCalls
    });

    const { mutateAsync: updateToggle } = useUpdateHeaderFooterToggleMutation();
    const { mutateAsync: mutateText } = useUpdateHeaderFooterTextMutation();

    const [quoteNumberGenerationRule, setQuoteNumberGenerationRule] = useState<string>('');
    const [jobNameGenerationRule, setJobNameGenerationRule] = useState<string>('');
    const [addressedToGenRule, setAddressedToGenRule] = useState('');
    const [textState, setTextState] = useState<TextStateType>({
        job_name: '',
        addressed_to: '',
        project_location: '',
        contact: '',
        quote_number: '',
        left_footer: '',
        right_footer: '',
        date: '',
        page_number: '',
        hyperlink: ''
    });
    const [quoteNumberCustomEntry, setQuoteNumberCustomEntry] = useState('');
    const [isInitialQuoteNoFilled, setIsInitialQuoteNoFilled] = useState(false);
    const [dates, setDates] = useState<Dates>({
        bid_date: {
            value: null,
            font: {
                color: '#37d67a',
                type: 'regular'
            }
        },
        expiration_date: {
            value: null,
            font: {
                color: '#37d67a',
                type: 'regular'
            }
        }
    });

    const bidDate = dates.bid_date.value !== null ? dayjs(dates.bid_date.value) : null;
    const expirationDate = dates.expiration_date.value !== null ? dayjs(dates.expiration_date.value) : null;

    useEffect(() => {
        if (headerFooterData && projectData?.data && bomData?.data) {
            const { job_name, addressed_to, project_location, contact, quote_number, left_footer, right_footer, date, page_number, hyperlink, bid_date, expiration_date } = headerFooterData;
            const updatedTextstate = {
                addressed_to: addressed_to.field_value.value || '',
                project_location: project_location.field_value,
                contact: contact.field_value,
                quote_number: quote_number.field_value?.value || '',
                left_footer: left_footer.field_value,
                right_footer: right_footer.field_value,
                date: date.field_value,
                page_number: page_number.field_value,
                hyperlink: hyperlink.field_value,
                job_name: job_name.field_value.value || ''
            };
            let currentJobGenerationRule = 'Custom Entry';

            if (job_name.field_value.pre_fill === 'Project Name') {
                updatedTextstate.job_name = projectData.data.name || '';
                currentJobGenerationRule = 'Project Name';
            } else if (job_name.field_value.pre_fill === 'BOM Name') {
                updatedTextstate.job_name = bomData.data.name || '';
                currentJobGenerationRule = 'BOM Name';
            }

            if (quote_number.field_value.pre_fill === optionsForNumber[0].name) {
                updatedTextstate.quote_number = quote_number.field_value.unique_id || '';
            } else if (quote_number.field_value.pre_fill === optionsForNumber[1].name) {
                updatedTextstate.quote_number = quote_number.field_value.initials_date_unique_id || '';
            }

            if (addressed_to.field_value.pre_fill === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER) {
                const primaryEmail = bomData?.data.primary_contact?.company?.email || bomData?.data.primary_contact?.contact?.email;
                updatedTextstate.addressed_to = getUnifiedStrFromApi([bomData?.data.primary_contact?.contact?.name, primaryEmail, bomData?.data.primary_contact?.company?.name], ' | ');
            }

            setTextState(updatedTextstate);
            setJobNameGenerationRule(currentJobGenerationRule);
            setQuoteNumberGenerationRule(quote_number.field_value.pre_fill);
            setAddressedToGenRule(addressed_to.field_value.pre_fill);
            setDates({
                bid_date: bid_date.field_value,
                expiration_date: expiration_date.field_value
            });
        }
    }, [isLoading, projectData?.data, bomData?.data, headerFooterData]);

    useEffect(() => {
        if (!isInitialQuoteNoFilled && headerFooterData?.quote_number && headerFooterData?.quote_number?.field_value?.pre_fill === optionsForNumber[2].name) {
            const initialQuoteNumber = headerFooterData.quote_number?.field_value?.value || '';
            setQuoteNumberCustomEntry(initialQuoteNumber);
            setTextState((old) => ({
                ...old,
                quote_number: initialQuoteNumber
            }));
            setIsInitialQuoteNoFilled(true);
        }
    }, [headerFooterData]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (headerFooterData) {
            const { name, value } = event.target;
            setTextState({ ...textState, [name]: value });
            handlePdfPreview(false);
            handleTextFieldChanged(true);
        }
    };

    const pushChangedFields = (fieldName: string) => {
        const isFooterField = footerFields.includes(fieldName);
        const subSectionName = isFooterField ? 'footer' : fieldName;
        const isAddressedTo = subSectionName === 'addressed_to';
        if (!isAddressedTo) {
            handleUpdateChangedSections?.({ sectionName: HEADER_SECTION_NAME, subSectionName, value: true });
        }
    };

    async function handleSwitchChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {
            target: { name, checked }
        } = e;
        await updateToggle({
            bomId: bomId,
            fieldName: name,
            is_active: checked
        });
        handlePdfPreview(true);
        pushChangedFields(name);
    }

    async function handleOnBlur(field: HeaderFooterSectionType) {
        if (headerFooterData && isTextFieldChanged) updateFieldValue(field, textState[field]);
    }

    function handleOnFocus() {
        handleTextFieldChanged(false);
    }

    async function updateFieldValue(
        field: HeaderFooterSectionType | DateSectionType,
        field_value: string | DateFontType | { pre_fill: string; value?: string; unique_id?: string; initials_date_unique_id?: string }
    ) {
        if (headerFooterData) {
            await mutateText({
                bomId: bomId,
                fieldName: field,
                field_value: field_value
            });
            handlePdfPreview(true);
            pushChangedFields(field);
        }
    }

    function handleDateChange(value: Dayjs | null, field: DateSectionType) {
        if (headerFooterData) {
            const newFieldValue: DateFontType = {
                ...dates[field],
                value: dayjs(value).isValid() ? dayjs(value).format() : null
            };

            setDates({ ...dates, [field]: newFieldValue });
            updateFieldValue(field, newFieldValue);
            handlePdfPreview(false);
        }
    }

    function handleFontChange(field: DateSectionType, value: FontSelectOptions) {
        if (headerFooterData) {
            const newFieldValue = {
                ...dates[field],
                font: {
                    ...dates[field].font,
                    type: value
                }
            };

            setDates({ ...dates, [field]: newFieldValue });
            updateFieldValue(field, newFieldValue);
        }
    }

    function handleColorChange(field: DateSectionType, value: string) {
        if (headerFooterData) {
            const newFieldValue = {
                ...dates[field],
                font: {
                    ...dates[field].font,
                    color: value
                }
            };

            setDates({ ...dates, [field]: newFieldValue });
            handlePdfPreview(false);
        }
    }

    function handleOnClick(open: boolean, fieldName: DateSectionType) {
        if (open) {
            updateFieldValue(fieldName, dates[fieldName]);
        }
    }

    const handleQuoteNumberGenerationRule = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const { value } = event.target;

            const payload: IPrefillPayload = {
                pre_fill: value as string,
                unique_id: headerFooterData?.quote_number.field_value.unique_id || '',
                initials_date_unique_id: headerFooterData?.quote_number.field_value.initials_date_unique_id || ''
            };

            const currentTextState = { ...textState };

            if (value === optionsForNumber[0].name) {
                currentTextState.quote_number = headerFooterData?.quote_number.field_value.unique_id || '';
            } else if (value === optionsForNumber[1].name) {
                currentTextState.quote_number = headerFooterData?.quote_number.field_value.initials_date_unique_id || '';
            } else {
                payload.value = quoteNumberCustomEntry;
                currentTextState.quote_number = quoteNumberCustomEntry;
            }

            updateFieldValue('quote_number', payload);
            setTextState(currentTextState);
            setQuoteNumberGenerationRule(value as string);
        },
        [textState, headerFooterData?.quote_number, quoteNumberCustomEntry, handleUpdateChangedSections]
    );

    const handleJobNameGenerationRule = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const { value } = event.target;

            const payload: IPrefillPayload = { pre_fill: value as string };
            if (value === 'Project Name') {
                setTextState((old) => ({
                    ...old,
                    job_name: projectData?.data.name || ''
                }));
            } else if (value === 'BOM Name') {
                setTextState((old) => ({
                    ...old,
                    job_name: bomData?.data.name || ''
                }));
            } else {
                payload.value = textState.job_name;
                const bomName = bomData?.data.name || '';
                const projectName = projectData?.data.name || '';
                setTextState((old) => ({
                    ...old,
                    job_name: jobNameGenerationRule === 'BOM Name' ? bomName : projectName
                }));
            }

            updateFieldValue(quoteHeaderFooterFields.jobName, payload);
            setJobNameGenerationRule(value as string);
        },
        [jobNameGenerationRule, projectData?.data, bomData?.data, textState, handleUpdateChangedSections]
    );

    const handleAddressedToGenerationRule = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            const { value } = e.target;
            const payload: IPrefillPayload = { pre_fill: value as string };

            const primaryContact = bomData?.data.primary_contact;
            const currentTextState = { ...textState };
            if (value === ADDRESSED_TO_SELECT_OPTIONS.PRIMARY_CUSTOMER) {
                const primaryEmail = primaryContact?.company?.email || primaryContact?.contact?.email;
                currentTextState.addressed_to = getUnifiedStrFromApi([primaryContact?.contact?.name, primaryEmail, primaryContact?.company?.name], ' | ');
            }
            payload.value = currentTextState.addressed_to;
            setTextState(currentTextState);
            updateFieldValue(quoteHeaderFooterFields.addressedTo, payload);
            setAddressedToGenRule(value as string);
        },
        [textState, bomData?.data.primary_contact]
    );

    const handleOnBlurJobName = (val: string) => {
        if (headerFooterData && isTextFieldChanged) {
            updateFieldValue(quoteHeaderFooterFields.jobName, { pre_fill: 'Custom Entry', value: val });
        }
    };

    const handleOnBlurQuoteNumber = (val: string) => {
        if (headerFooterData && isTextFieldChanged) {
            setQuoteNumberCustomEntry(val);
            updateFieldValue('quote_number', {
                pre_fill: 'Custom Entry',
                value: val,
                unique_id: headerFooterData?.quote_number.field_value.unique_id || '',
                initials_date_unique_id: headerFooterData?.quote_number.field_value.initials_date_unique_id || ''
            });
        }
    };

    const handleOnBlurAddressTo = (val: string) => {
        if (headerFooterData && isTextFieldChanged) {
            updateFieldValue(quoteHeaderFooterFields.addressedTo, { pre_fill: 'Custom Entry', value: val });
        }
    };

    return (
        <>
            {headerFooterData && (
                <>
                    <Box mb={8}>
                        <BodyMedium fontWeight={500} mb={2}>
                            Header Format
                        </BodyMedium>
                        <Grid container direction={'column'}>
                            <Grid item mb={2}>
                                <HeaderLogo />
                            </Grid>
                            <Grid item mb={2}>
                                <TokenForFields
                                    options={OPTIONS_FOR_NAME}
                                    fieldValue={textState.job_name}
                                    handleFieldGenerationRule={handleJobNameGenerationRule}
                                    selectedOption={jobNameGenerationRule}
                                    handleOnBlur={handleOnBlurJobName}
                                    onTextFieldFocus={handleOnFocus}
                                    maxLength={128}
                                    showTextAndInput={true}
                                    onToggle={handleSwitchChange}
                                    toggleLabel={headerFooterLabels.jobName}
                                    isToggled={headerFooterData.job_name.is_active}
                                    toggleName={quoteHeaderFooterFields.jobName}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>
                            <Grid item mb={2} maxHeight={'36px'}>
                                <TokenForFields
                                    options={OPTIONS_FOR_ADDRESSED_TO}
                                    fieldValue={textState.addressed_to}
                                    handleFieldGenerationRule={handleAddressedToGenerationRule}
                                    selectedOption={addressedToGenRule}
                                    handleOnBlur={handleOnBlurAddressTo}
                                    onTextFieldFocus={handleOnFocus}
                                    maxLength={72}
                                    showTextAndInput={true}
                                    onToggle={handleSwitchChange}
                                    toggleLabel={headerFooterLabels.addressTo}
                                    isToggled={headerFooterData.addressed_to.is_active}
                                    toggleName={quoteHeaderFooterFields.addressedTo}
                                />
                            </Grid>
                            <Grid item mb={2} maxHeight={'36px'}>
                                <ToggleText
                                    checked={headerFooterData.project_location.is_active}
                                    placeholder={headerFooterLabels.projectLocation}
                                    switchLabel={headerFooterLabels.projectLocation}
                                    textFieldAction={handleTextChange}
                                    blurAction={() => handleOnBlur(quoteHeaderFooterFields.projectLocation)}
                                    fieldName={quoteHeaderFooterFields.projectLocation}
                                    onToggle={handleSwitchChange}
                                    textFieldValue={textState.project_location}
                                    maxLength={128}
                                    focusAction={handleOnFocus}
                                    toggleName={quoteHeaderFooterFields.projectLocation}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>
                            <Grid item mb={2}>
                                <ToggleText
                                    checked={headerFooterData.contact.is_active}
                                    placeholder={headerFooterLabels.contact}
                                    switchLabel={headerFooterLabels.contact}
                                    textFieldAction={handleTextChange}
                                    blurAction={() => handleOnBlur(quoteHeaderFooterFields.contact)}
                                    fieldName={quoteHeaderFooterFields.contact}
                                    onToggle={handleSwitchChange}
                                    textFieldValue={textState.contact}
                                    maxLength={64}
                                    focusAction={handleOnFocus}
                                    toggleName={quoteHeaderFooterFields.contact}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>
                            <Grid item mb={6} maxHeight={'36px'} mt={1}>
                                <TokenForFields
                                    options={optionsForNumber}
                                    fieldValue={textState.quote_number || ''}
                                    handleFieldGenerationRule={handleQuoteNumberGenerationRule}
                                    selectedOption={quoteNumberGenerationRule}
                                    handleOnBlur={handleOnBlurQuoteNumber}
                                    onTextFieldFocus={handleOnFocus}
                                    maxLength={85}
                                    showTextAndInput={true}
                                    errorType={ERROR_KEYS.QUOTE_CUSTOM_ENTRY}
                                    isToggled={headerFooterData.quote_number.is_active}
                                    toggleLabel={headerFooterLabels.quoteNumber}
                                    onToggle={handleSwitchChange}
                                    toggleName={quoteHeaderFooterFields.quoteNumber}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>
                            <Grid item mb={2}>
                                <ToggleDateWithFontAndColor
                                    checked={headerFooterData.bid_date.is_active}
                                    dateField={bidDate}
                                    onDateChange={(value: Dayjs | null) => handleDateChange(value, quoteHeaderFooterFields.bidDate)}
                                    switchLabel={headerFooterLabels.bidDate}
                                    color={dates.bid_date.font.color}
                                    onColorChange={(color: any) => handleColorChange(quoteHeaderFooterFields.bidDate, color.hex)}
                                    onColorBlur={() => updateFieldValue(quoteHeaderFooterFields.bidDate, dates[quoteHeaderFooterFields.bidDate])}
                                    onToggle={handleSwitchChange}
                                    onSelect={(e: SelectChangeEvent<unknown>) => handleFontChange(quoteHeaderFooterFields.bidDate, e.target.value as FontSelectOptions)}
                                    selectFieldValue={dates.bid_date.font.type}
                                    onClick={(open) => handleOnClick(open, quoteHeaderFooterFields.bidDate)}
                                    toggleName={quoteHeaderFooterFields.bidDate}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>

                            <Grid item mb={2}>
                                <ToggleDateWithFontAndColor
                                    checked={headerFooterData.expiration_date.is_active}
                                    dateField={expirationDate}
                                    onDateChange={(value: Dayjs | null) => handleDateChange(value, quoteHeaderFooterFields.expirationDate)}
                                    switchLabel={headerFooterLabels.expiryDate}
                                    color={dates.expiration_date.font.color}
                                    onColorChange={(color: any) => handleColorChange(quoteHeaderFooterFields.expirationDate, color.hex)}
                                    onColorBlur={() => updateFieldValue(quoteHeaderFooterFields.expirationDate, dates[quoteHeaderFooterFields.expirationDate])}
                                    onToggle={handleSwitchChange}
                                    onSelect={(e: SelectChangeEvent<unknown>) => handleFontChange(quoteHeaderFooterFields.expirationDate, e.target.value as FontSelectOptions)}
                                    selectFieldValue={dates.expiration_date.font.type}
                                    onClick={(open) => handleOnClick(open, quoteHeaderFooterFields.expirationDate)}
                                    toggleName={quoteHeaderFooterFields.expirationDate}
                                    sectionName={HEADER_SECTION_NAME}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box display="flex" alignItems="center" gap={3} mb={3}>
                            <BodyMedium fontWeight={500}>Footer Format</BodyMedium>
                            <ApplyToAllBomsButton sectionName={HEADER_SECTION_NAME} subSectionName="footer" />
                        </Box>
                        <Grid container direction={'column'}>
                            <Grid item mb={2}>
                                <ToggleText
                                    checked={headerFooterData.left_footer.is_active}
                                    placeholder={headerFooterLabels.leftFooter}
                                    switchLabel={headerFooterLabels.leftFooter}
                                    textFieldAction={handleTextChange}
                                    blurAction={() => handleOnBlur(quoteHeaderFooterFields.leftFooter)}
                                    fieldName={quoteHeaderFooterFields.leftFooter}
                                    onToggle={handleSwitchChange}
                                    textFieldValue={textState.left_footer}
                                    maxLength={64}
                                    focusAction={handleOnFocus}
                                    toggleName={quoteHeaderFooterFields.leftFooter}
                                />
                            </Grid>
                            <Grid item mb={2} maxHeight={'36px'}>
                                <ToggleText
                                    checked={headerFooterData.right_footer.is_active}
                                    placeholder={headerFooterLabels.rightFooter}
                                    switchLabel={headerFooterLabels.rightFooter}
                                    textFieldAction={handleTextChange}
                                    blurAction={() => handleOnBlur(quoteHeaderFooterFields.rightFooter)}
                                    fieldName={quoteHeaderFooterFields.rightFooter}
                                    onToggle={handleSwitchChange}
                                    textFieldValue={textState.right_footer}
                                    maxLength={64}
                                    focusAction={handleOnFocus}
                                    toggleName={quoteHeaderFooterFields.rightFooter}
                                />
                            </Grid>
                            <Grid item mb={2}>
                                <Switch name={quoteHeaderFooterFields.date} checked={headerFooterData.date.is_active} onChange={handleSwitchChange} label={headerFooterLabels.date} />
                            </Grid>
                            <Grid item mb={2}>
                                <Switch
                                    checked={headerFooterData.page_number.is_active}
                                    onChange={handleSwitchChange}
                                    label={headerFooterLabels.pageNumber}
                                    name={quoteHeaderFooterFields.pageNumber}
                                />
                            </Grid>
                            <Grid item>
                                <Switch checked={headerFooterData.hyperlink.is_active} onChange={handleSwitchChange} label={headerFooterLabels.hyperLink} name={quoteHeaderFooterFields.hyperLink} />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
}

export default HeaderAndFooter;
