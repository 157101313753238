import { axios } from 'src/app/Axios';
import {
    CompanyAddPayload,
    CompanyEditPayload,
    CompanyMfgDetailsResponse,
    ImportFileResponse,
    ManufacturerAddResponse,
    ManufacturerDelOrDupPayload,
    ManufacturerListInfo,
    ManufacturerListPayload,
    ParspecMfgDetailsResponse,
    SuccessResponse
} from './apiTypes';

export const endPoints = {
    companyManufacturer: '/dashboard/manufacturer_dashboard',
    uploadManufacturerFile: '/company_group/manufacturer/?action=upload',
    parspecManufacturer: '/dashboard/parspec_manufacturer',
    companyGroupManufacturer: '/company_group/manufacturer/',
    manufacturerList: '/company_group/manufacturer_list/',
    getManufacturerListEditUrl: (mfgListId: number) => `${endPoints.manufacturerList}${mfgListId}`,
    getManufacturerDelUrl: '/company_group/manufacturer_list/bulk/'
};

export const getParspecMfgData = async () => {
    const response = await axios.get<ParspecMfgDetailsResponse[]>(endPoints.parspecManufacturer);
    return { data: response?.data };
};

export const getCompanyMfgData = async () => {
    const resp = await axios.get<CompanyMfgDetailsResponse[]>(endPoints.companyManufacturer);
    const modifiedData = resp.data.map((mfg: CompanyMfgDetailsResponse) => {
        return {
            ...mfg,
            abbreviation_str: mfg.abbreviations?.join(',') || ''
        };
    });
    return { data: modifiedData };
};

export const postUploadMfgFile = async (data: FormData) => {
    return axios.post<ImportFileResponse>(endPoints.uploadManufacturerFile, data);
};

export const addMfgToCompanyData = async (payload: CompanyAddPayload) => {
    return axios.post<ManufacturerAddResponse[]>(endPoints.companyGroupManufacturer, payload);
};

export const patchEditManufacturers = async (payload: CompanyAddPayload) => {
    return axios.patch<ManufacturerAddResponse[]>(endPoints.companyGroupManufacturer, payload);
};

export const removeMfgFromCompanyData = async (data: { payload: CompanyEditPayload; catalogTab: boolean }) => {
    const { payload } = data;
    return axios.delete<SuccessResponse>(endPoints.companyGroupManufacturer, { data: payload });
};

export const getListOfManufactuerList = () => {
    return axios.get<ManufacturerListInfo[]>(endPoints.manufacturerList);
};

export const postManufactuerList = (payload: ManufacturerListPayload) => {
    return axios.post<SuccessResponse>(endPoints.manufacturerList, payload);
};

export const patchManufactuerList = ({ mfgListId, payload }: { mfgListId: number; payload: ManufacturerListPayload }) => {
    return axios.patch<SuccessResponse>(endPoints.getManufacturerListEditUrl(mfgListId), payload);
};

export const getManufactuerList = (mfgListId: number) => {
    return axios.get<ManufacturerListInfo>(endPoints.getManufacturerListEditUrl(mfgListId));
};

export const deleteManufacturerList = (payload: ManufacturerDelOrDupPayload) => {
    return axios.delete<SuccessResponse>(endPoints.getManufacturerDelUrl, { data: payload });
};

export const duplicateManufacturerList = (payload: ManufacturerDelOrDupPayload) => {
    return axios.post<ManufacturerListInfo[]>(endPoints.getManufacturerDelUrl, payload);
};
