import { useContext } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import { ColDef, ICellRendererParams } from 'ag-grid-community';

import { Box, Skeleton, SyncProblemIcon, Tooltip, Status, BodyXS, LinkIcon, Button, Link, StarIcon, BodySmall, Menu } from '@parspec/pixel';

import { TableLinkTemplate, setRouteToLocalStorage } from '../../shared/utils/utils';
import { DOCUMENT_STATUS_COLOR_MAP, ColorCode, DocumentStatusEnum, PRIMARY_START_ICON_MESSAGE, LINKED_START_ICON_MESSAGE } from '../../shared/constants';
import { useBOMSyncForProject } from '../shared/hooks';
import { MenuContext } from './context';

export const defaultColDef: ColDef | any = {
    flex: 1,
    sortable: true,
    unSortIcon: true,
    menuTabs: [],
    resizable: true,
    suppressFillHandle: true,
    suppressMovable: true
};

export const BomTemplate = (props: { id: number; name: string; project: number; is_linked?: boolean; is_primary?: boolean }) => {
    const { id: bomId, name: bomName, project: projectId, is_primary, is_linked } = props;

    return (
        <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%" onClick={() => setRouteToLocalStorage(`/v2/project/${projectId}/bom/${bomId}?tab=bom`)}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
                <Box color={'text.secondary'} fontSize={'16px'}>
                    {is_primary && (
                        <Box mr={2}>
                            <Tooltip title={PRIMARY_START_ICON_MESSAGE}>
                                <StarIcon fontSize={'inherit'} color={'inherit'} />
                            </Tooltip>
                        </Box>
                    )}
                    {is_linked && (
                        <Box mr={2}>
                            <Tooltip title={LINKED_START_ICON_MESSAGE}>
                                <LinkIcon fontSize={'inherit'} color={'inherit'} />
                            </Tooltip>
                        </Box>
                    )}
                </Box>

                <Box>
                    <TableLinkTemplate to={`/v2/project/${projectId}/bom/${bomId}?tab=bom`} label={bomName} />
                </Box>
            </Box>
        </Box>
    );
};

export const DocumentStatusTemplate = (props: any) => {
    const { pathname, search } = useLocation();
    const { bomId, documentType, data, projectId } = props;
    const { isSyncLoading, outOfSyncCache } = useBOMSyncForProject(projectId, documentType);
    const syncInfo = outOfSyncCache ? outOfSyncCache[bomId] : null;
    const isOutOfSync = syncInfo?.outOfSync || false;
    const status = props.status || DocumentStatusEnum.IN_PROGRESS;
    const color = DOCUMENT_STATUS_COLOR_MAP[status as keyof typeof DOCUMENT_STATUS_COLOR_MAP] as ColorCode;

    return (
        <>
            {data ? (
                <Box display="flex" justifyContent="flex-start" alignItems="center" height="100%">
                    <Status color={color}>
                        <BodyXS color={color}>{status}</BodyXS>
                    </Status>
                    {isSyncLoading ? (
                        <Box color="tertiary" ml={3}>
                            <Skeleton variant="rectangular" height={21} width={21} />
                        </Box>
                    ) : (
                        isOutOfSync && (
                            <Box ml={3} display="flex">
                                <Tooltip title="Changes have been made to the Bill of Materials since the latest version.">
                                    <SyncProblemIcon color="tertiary" fontSize="medium" />
                                </Tooltip>
                            </Box>
                        )
                    )}
                </Box>
            ) : (
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    height="100%"
                    onClick={() => {
                        setRouteToLocalStorage(`${pathname}${search}`);
                    }}
                >
                    <TableLinkTemplate to={`/v2/project/${projectId}/bom/${bomId}/finalDocuments/compile/${documentType}`} label="+ Create New" />
                </Box>
            )}
        </>
    );
};

export const ViewDocumentsTemplate = (props: any) => {
    const { data } = props;

    return (
        <Box>
            <Link to={`/v2/project/${data?.project}/bom/${data?.id}?tab=finalDocuments`} underline="hover" component={RouterLink}>
                <Button variant="outlined" color="secondary" size="small" onClick={() => setRouteToLocalStorage(`/v2/project/${data?.projectId}`)}>
                    View Documents
                </Button>
            </Link>
        </Box>
    );
};

export const CustomNoRowsOverlayComponent = (props: any) => {
    const { isTableHaveFooter, api } = props;
    const unpinnedRowsCount = api.getDisplayedRowCount();
    const isPinnedRowPresent = api.getPinnedTopRow(0)?.data;

    if (isPinnedRowPresent && unpinnedRowsCount === 0) {
        return <Box className={`ag-overlay-no-rows-wrapper ${isTableHaveFooter ? '' : ' overlay-rows-footer'}`}></Box>;
    } else {
        return (
            <Box className={`ag-overlay-no-rows-wrapper ${isTableHaveFooter ? '' : ' overlay-rows-footer'}`}>
                <BodySmall>No records to display</BodySmall>
            </Box>
        );
    }
};

export function MenuTemplate(props: ICellRendererParams) {
    const { data } = props;
    const { onClickDelete, onDuplicate, isActiveSubscription, handleSubscriptionPopup, bomDataLength, TOOL_TIP_MESSAGE } = useContext(MenuContext);

    const options: {
        label: React.ReactNode | string;
        onClick: () => void;
        color?: string | undefined;
        disabled?: boolean | undefined;
        toolTipMessage?: string | undefined;
    }[] = [
        {
            label: 'Duplicate',
            color: '',
            onClick: () => {
                if (isActiveSubscription) {
                    onDuplicate(data);
                } else handleSubscriptionPopup();
            }
        }
    ];

    if (data.is_primary && bomDataLength > 1) {
        options.push({
            label: (
                <BodySmall color={'error.main'} sx={{ opacity: '0.4', cursor: 'default' }}>
                    Delete
                </BodySmall>
            ),
            onClick: () => {},
            toolTipMessage: TOOL_TIP_MESSAGE
        });
    } else {
        options.push({
            label: 'Delete',
            color: 'error.main',
            onClick: () => {
                if (isActiveSubscription) {
                    onClickDelete(data);
                } else handleSubscriptionPopup();
            }
        });
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Menu options={options} />
        </Box>
    );
}
