import { useContext, useEffect, useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Box, SelectChangeEvent, StatusSelect } from '@parspec/pixel';

import { SpecificationValType, specificationOptions } from '../../shared/utils/constants';
import { TableContext } from './context';

export function SpecificationDropdown(props: ICellRendererParams<any, any, any>) {
    const { value, data } = props;
    const { handleSpecificationChange } = useContext(TableContext) || {};
    const [dropdownValue, setDropdownValue] = useState(specificationOptions[0].value);

    useEffect(() => {
        if (dropdownValue !== value) {
            setDropdownValue(value);
        }
    }, [value]);

    function handleChange(event: SelectChangeEvent<unknown>) {
        const specificationVal = event.target.value as SpecificationValType;
        setDropdownValue(specificationVal);
        handleSpecificationChange?.(specificationVal, data.id);
    }

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    return (
        <Box display="flex" alignItems="center" height="100%">
            <StatusSelect options={specificationOptions} value={dropdownValue} onChange={handleChange} size="small" sx={{ height: 30 }} />
        </Box>
    );
}
