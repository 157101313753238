export const mockLotsData = [
    {
        id: 420,
        name: 'Stuff',
        n_products: 69,
        cost_cents: 420,
        discount_percentage: 69.42,
        discounted_cost_cents: 350,
        margin_percentage: 42,
        sell_price_cents: 69420,
        extended_sell_price_cents: 69420,
        lead_time: '6-9 weeks',
        source_quote_number: 'SN-420'
    },
    {
        id: 421,
        name: 'Stuff 2',
        n_products: 69,
        cost_cents: 420,
        discount_percentage: 69.42,
        discounted_cost_cents: 350,
        margin_percentage: 42,
        sell_price_cents: 69420,
        extended_sell_price_cents: 69420,
        lead_time: '6-9 weeks',
        source_quote_number: 'SN-420'
    },
    {
        id: 422,
        name: 'Stuff 3',
        n_products: 69,
        cost_cents: 420,
        discount_percentage: 69.42,
        discounted_cost_cents: 350,
        margin_percentage: 42,
        sell_price_cents: 69420,
        extended_sell_price_cents: 69420,
        lead_time: '6-9 weeks',
        source_quote_number: 'SN-420'
    },
    {
        id: 423,
        name: 'Stuff 4',
        n_products: 69,
        cost_cents: 420,
        discount_percentage: 69.42,
        discounted_cost_cents: 350,
        margin_percentage: 42,
        sell_price_cents: 69420,
        extended_sell_price_cents: 69420,
        lead_time: '6-9 weeks',
        source_quote_number: 'SN-420'
    }
];
