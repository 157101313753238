import { axios } from 'src/app/Axios';

import { SubscriptionInfoResponse, BillingInfoResponse, ParspecOperatingRegionsResponse, AutoUpgradeSubscriptionRequest, BillingInfoRequest } from './apiTypes';

export const endPoints = {
    subscriptionInfo: '/api/subscription/get/',
    billingInfo: '/api/billing/',
    parspecOperatingRegionsInfo: '/api/parspec_operating_regions/',
    manageSubscription: `/api/subscription/edit/?back_url=${window.location.origin}/v2/settings/plan-and-payment`,
    autoUpgradeSubscription: '/api/subscription/auto_upgrade_to_next_tier/'
};

// Queries
export const getSubscriptionInfo = async () => {
    return axios.get<SubscriptionInfoResponse>(endPoints.subscriptionInfo);
};
export const getBillingInfo = async () => {
    return axios.get<BillingInfoResponse>(endPoints.billingInfo);
};
export const getParspecOperatingRegionsInfo = async () => {
    return axios.get<ParspecOperatingRegionsResponse>(endPoints.parspecOperatingRegionsInfo);
};

export const getManageSubscription = async () => {
    return axios.get(endPoints.manageSubscription);
};

// Mutations
export const patchBillingInfo = async (data: BillingInfoRequest) => {
    return axios.patch(endPoints.billingInfo, data);
};

export const postAutoUpgradeSubscription = async (data: AutoUpgradeSubscriptionRequest) => {
    return axios.post(endPoints.autoUpgradeSubscription, data);
};
