import { useParams } from 'react-router-dom';

import { Button, Menu, MoreVertIcon } from '@parspec/pixel';

import { useExportDataMutation } from '../queries';
import { EXPORT_OPTIONS } from './constants';

const ExportButton = () => {
    const { bomId } = useParams();

    const { mutateAsync: exportData, isLoading: exportLoading } = useExportDataMutation();

    const onExport = async (format: string) => {
        try {
            const data = {
                format,
                bomId: bomId!
            };
            await exportData(data);
        } catch (e) {
            /* empty */
        }
    };

    return (
        <Menu options={EXPORT_OPTIONS(onExport)}>
            <Button variant="outlined" color="secondary" endIcon={<MoreVertIcon />} isLoading={exportLoading}>
                Export
            </Button>
        </Menu>
    );
};

export default ExportButton;
