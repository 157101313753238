import { BodyBig, BodySmall, Box, Button, NoProductFoundIcon } from '@parspec/pixel';
interface NoProductFoundProps {
    onEditRequirement: () => void;
    handleAddNewData: () => void;
    dataLength?: number;
}
const NoProductFound = (props: NoProductFoundProps) => {
    const { onEditRequirement, handleAddNewData } = props;
    return (
        <>
            <Box bgcolor="secondary.contrastText" height={'100%'} borderRadius={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="16px">
                    <Box fontSize="60px">
                        <NoProductFoundIcon fontSize="inherit" />
                    </Box>
                    <BodyBig fontWeight={'500'}>No Products Found</BodyBig>
                    <BodySmall limit={false} textAlign="center">
                        We couldn’t find any products matching your requirements.
                    </BodySmall>
                    <Box>
                        <BodySmall> Try unlocking some product requirements, editing the </BodySmall>
                        <BodySmall> requirements, or manually uploading a product datasheet.</BodySmall>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} padding={'0 10px'} height={52} gap={2}>
                        <Button variant="outlined" color="secondary" onClick={onEditRequirement}>
                            Edit Requirements
                        </Button>
                        <Button variant="contained" color="tertiary" onClick={handleAddNewData}>
                            Add Product Manually
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default NoProductFound;
