import { useGetUserProfileInfoQuery } from '../../Settings/MyProfile/queries';
import { FeatureFlags } from '../queries/apiTypes';
import { useMemo } from 'react';
import { useGetFeatureFlagQuery } from '../queries';
import { useInitializeEventService } from './EventService';

const flagNames = ['enable_quoting', 'enable_cednet_format', 'enable_epicor_format', 'enable_reporting'];

export const useFeatureFlag = () => {
    const { isTokenAvailable } = useInitializeEventService();
    const { data: userProfile, isLoading: userProfileLoading } = useGetUserProfileInfoQuery({ enabled: isTokenAvailable });
    const { email, company_group_id } = userProfile?.data || {};
    const { data: flag, isLoading: flagLoading } = useGetFeatureFlagQuery(email, company_group_id, {
        enabled: !!userProfile?.data?.email && !!userProfile?.data?.company_group_id && isTokenAvailable,
        refetchOnWindowFocus: 'always'
    });

    const flagResults = useMemo(
        () =>
            flagNames.reduce((result, flagName) => {
                result[flagName as keyof FeatureFlags] = Boolean(flag?.flags?.find((flag: { name: string }) => flag.name === flagName));
                return result;
            }, {} as FeatureFlags),
        [flag, flagNames]
    );

    return {
        ...flagResults,
        isLoading: userProfileLoading || flagLoading
    };
};
