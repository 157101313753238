import { rest } from 'msw';
import { preview } from './preview';
import { generateSubmittalResponse, CompileTimeResponse } from './generateSubmittal';

import { coverPageConfigurations, coverPageData, customCoverPage } from './coverPage';
import { tocConfigurations } from './toc';

export const compileSubmittalHandlers = [
    rest.post('*/bom/:bomId/:documentTypePreset/:fieldName/preview/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(preview));
    }),

    // for textfield
    rest.patch('*/bom/:bomId/submittal_preset/cover_page/data/:fieldName', (_req, res, ctx) => {
        return res(ctx.delay(100), ctx.json('Update Complete'));
    }),

    //for toggle updates
    rest.patch('*/bom/:bomId/submittal_preset/cover_page/configuration/:fieldName', (_req, res, ctx) => {
        return res(ctx.delay(100), ctx.json('Update Complete'));
    }),

    rest.get('*/bom/:bomId/submittal_preset/cover_page/configuration/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(coverPageConfigurations));
    }),
    rest.get('*/bom/:bomId/submittal_preset/cover_page/data/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(coverPageData));
    }),
    rest.get('*/bom/:bomId/:documentTypePreset/cover_page/custom/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(customCoverPage));
    }),
    rest.get('*/bom/:bomId/submittal_preset/toc/configuration/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(tocConfigurations));
    }),

    rest.patch('*/bom/:bomId/submittal_preset/toc/configuration/:fieldName', (_req, res, ctx) => {
        return res(ctx.delay(100), ctx.json('Update Complete'));
    }),

    rest.post('*/bom/:bomId/submittal', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(generateSubmittalResponse));
    }),

    rest.get('*/bom/:bomId/submittal/compile_time', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json(CompileTimeResponse));
    }),

    rest.post('*/bom/:bomId/submittal_preset/cover_page/custom/', (_req, res, ctx) => {
        return res(ctx.delay(2000), ctx.json({}));
    })
];
