import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Box, BodyBig, BodyMedium, FileSelector, Grid, FileSelectorFileType } from '@parspec/pixel';

import FileUploaded from '../../shared/FileUploaded';
import { useUploadCustomPageMutation, useDeleteCustomPageMutation, useCustomPageQuery } from '../../queries';
import { CustomNotesPageUploadProps } from '../../queries/apiTypes';
import PreviewContext from '../../Context';

import { ENTITY } from '../../../constants';
import { compileLabels, ICompileLabels } from '../../../constants';

const AccordionBody = () => {
    const { bomId = '', documentType = '' } = useParams();
    const { handlePreviewImage, presetResponse } = useContext(PreviewContext);

    const { mutate: customNotesPageUpdateMutation, isLoading: isNotesPageUploading } = useUploadCustomPageMutation();
    const { mutate: customNotesPageDeleteMutation } = useDeleteCustomPageMutation();

    const { data: customNotesPageData } = useCustomPageQuery(bomId, documentType, ENTITY.NOTES_PAGE_ENTITY, presetResponse);

    const [customUpload, setCustomUpload] = useState<CustomNotesPageUploadProps | null>(null);

    useEffect(() => {
        if (customNotesPageData?.custom_notes_page) {
            setCustomUpload({
                name: customNotesPageData?.custom_notes_page?.filename,
                size: customNotesPageData?.custom_notes_page?.size,
                is_custom_notes_page: customNotesPageData?.custom_notes_page?.is_custom_notes_page
            });
        }
    }, [customNotesPageData]);

    const customFileUploadHandler = (data: File[] | FileSelectorFileType[]) => {
        if (data.length) {
            const formData = new FormData();
            formData.append('notes_page', data[0] as File);

            customNotesPageUpdateMutation(
                {
                    bomId: bomId,
                    formData: formData,
                    documentType: documentType,
                    entity: ENTITY.NOTES_PAGE_ENTITY
                },
                {
                    onSuccess: () => {
                        setCustomUpload({
                            name: data[0]['name'],
                            size: String(data[0]['size']),
                            is_custom_notes_page: true
                        });
                        handlePreviewImage(true);
                    }
                }
            );
        }
    };

    const customFileDeleteHanlder = () => {
        setCustomUpload(null);
        customNotesPageDeleteMutation(
            {
                bomId: bomId,
                documentType: documentType,
                entity: ENTITY.NOTES_PAGE_ENTITY
            },
            {
                onSuccess: () => {
                    handlePreviewImage(true);
                }
            }
        );
    };

    return (
        <>
            {customUpload ? (
                <FileUploaded text={'You uploaded a notes page. Delete the uploaded file to revert to our template'} customUpload={customUpload} onClick={customFileDeleteHanlder} />
            ) : (
                <Grid container padding={2} direction={'column'} alignItems="flex-start">
                    <Grid item>
                        <BodyBig marginLeft={2} color={'#283453'} fontWeight={500}>
                            Notes Summary Layout
                        </BodyBig>
                    </Grid>

                    <Grid item mt={2}>
                        <Box ml={2}>
                            <FileSelector placeholder="  Upload Your Own Notes Page" acceptedFormats={['.pdf']} maxFiles={2} onSelect={customFileUploadHandler} isLoading={isNotesPageUploading} />
                        </Box>
                    </Grid>

                    <Grid item mt={2}>
                        <Box ml={2}>
                            <BodyMedium limit={false} color={'text.secondary'} fontWeight={400}>
                                The design layout and the fields displayed on this external notes page will mimic the ones selected for the Table of Contents.{' '}
                                {compileLabels[documentType as keyof ICompileLabels]} notes will be used by default.
                            </BodyMedium>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default AccordionBody;
