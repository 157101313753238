import { useContext } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Box, SelectChangeEvent, Tooltip } from '@parspec/pixel';

import LotSelect from '../../lotSelect';
import { TableContext } from '../../../shared/context';
import { PRICING_TABLE_ENUM } from '../../shared/constants';

export function LotTemplate(props: ICellRendererParams) {
    const { data } = props;
    const value = data?.[PRICING_TABLE_ENUM.LOT] || null;
    const { handleLotChange } = useContext(TableContext);
    function handleChange(event: SelectChangeEvent<unknown>, prevLotId?: string) {
        const currLotId = event.target.value as string;
        handleLotChange?.(props?.node, props?.data, { bodId: data?.id, prevLotId: prevLotId || '0', lotValue: currLotId });
    }

    if (props?.node?.rowPinned === 'bottom') {
        return null;
    }

    const isLotDisabled = data?.hasOptions || data.kit ? true : false;

    return (
        <Tooltip
            title="This product has one or more associated options. Remove the option or its link to this product in order to add a lot, as options and lots are not compatible features"
            disableFocusListener
            disableHoverListener={!data?.hasOptions}
        >
            <Box display="flex" alignItems="center" height="100%">
                <LotSelect value={value} onChange={handleChange} disabled={isLotDisabled} size={'xs'} />
            </Box>
        </Tooltip>
    );
}
